type claimItemConst = {
	data: {
		claimItemId: string;
		category: string;
		originalAmt: string;
		currentAmt: string;
		latestCreditorPositionAmt: string;
		latestDebtorPositionAmt: string;
		resolvedAmt: number;
		creditorName: string;
		claimReference: string;
		claimItemReference: string;
	};
	node: {
		rowPinned: boolean;
		data: {
			columnField1: React.FC;
			columnField2: React.FC;
			columnField3: React.ReactElement;
			columnField4: React.ReactElement;
			columnField5: React.ReactElement;
			columnField6: React.ReactElement;
			columnField7: React.ReactElement;
		};
	};
};
export const useCustomCellRenderers = () => {
	const customActionHeader = {
		claimItemReference: function (param: claimItemConst) {
			if (param.node.rowPinned) {
				return param.node.data.columnField1;
			} else {
				return param.data.claimItemReference;
			}
		},
	};
	return customActionHeader;
};
