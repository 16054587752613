import React from 'react';
import {useTranslation} from 'react-i18next';

const MyProfile = () =>{
  const {t} = useTranslation(); 
  return (
    <div>{t("My Profile")}</div>
  )
}

export default MyProfile;