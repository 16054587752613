export interface ClaimCategory {
  claimCategoryId: string;
  claimCategoryName: string;
}

export interface ClaimStage {
  id: string;
  name: string;
  description: string;
  nameFrench: string;
  descriptionFrench: string;
  position: number;
  sortOrder: number;
  claimStatuses: ClaimStatus[];
}

export interface ClaimStatus {
  id: string;
  name: string;
  description: string;
  nameFrench: string;
  descriptionFrench: string;
  sortOrder: number;
  isInitialStatus: boolean;
  isInitialPortalStatus: boolean;
  isDocReleaseStatus: boolean;
  isDocRequiredStatus: boolean;
  isResolveStatus: boolean;
  timeLimitDays: number;
  timeLimitDate: string;
  portalDisplayStatus: string;
  isValidMove: boolean;
}

export interface FormValues {
  currentStage: string;
  currentActionStatus: string;
  debtorName: string;
  claimReferenceNumber: string;
  creditorName: string;
  category: string;
  overdue: string;
  claimUpdatedDateStart?: Date | undefined;
  claimUpdatedDateEnd?: Date | undefined;
  source: string;
  task: string;
  natureOfClaim: string[];
  [key: string]: any;
}

export interface ClaimTask {
    id: string,
    name: string,
}

export enum OverDue {
    No = "0",
    Yes = "1"
}

export enum Source {
    Internal = "0",
    External = "1"
}
