import { ccwpApi } from "@/services";

export type SectionFieldsData = {
  id?: string;
  name?: string;
  caseId: string;
  sectionDefinitionId: string;
  customAttributeDefinitionId: string;
  displayName: string;
  displayNameFrench: string;
  tooltip: null | string;
  tooltipFrench: null | string;
  sortOrder: number;
  isRequired: boolean;
};

export enum SectionMapping {
  creditor = 1,
  claim = 2,
  claimItem = 3,
}

export type SectionCustomAttributeDefaults = {
  attributeId: string;
  attributeName: string;
  attributeFrenchName: string;
  isRequired: boolean;
};

export type FormSetupFormData = {
  name: string;
  nameFrench: string;
  description: string;
  columns: number;
  sortOrder: number;
  mapping: SectionMapping;
  fields: SectionFieldsData[];
};

export type SectionRequestBody = {
  sectionId?: string;
  caseId?: string;
  name: string;
  nameFrench: string;
  description: string;
  mapping: number;
  columns: number;
  createSectionAttributeFields?: SectionFieldsData[];
};

export type SectionSortRequestBody = {
  sectionDefinitionId: string;
  sortOrder: number;
};

export type SectionItemData = {
  id?: string;
  sectionId?: string;
  caseId: string;
  name: string;
  nameFrench: string;
  description: string;
  mapping: number;
  columns: number;
};

export type SectionItemResponse = {
  id: string;
  name: string;
  nameFrench: string;
  description: string;
  sortOrder: number;
  columns: number;
  sectionMapping: number;
};

export const formSetupApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    listCustomAttributes: builder.query<
      SectionCustomAttributeDefaults[],
      { caseId: undefined | string; entityType: number }
    >({
      query: ({ caseId, entityType }) => ({
        url: `Section/ListCustomAttributeNamesByCase`,
        method: "GET",
        params: { CaseId: caseId, EntityType: entityType },
        headers: {
          accept: "application/json",
        },
      }),
      transformResponse: (response: SectionCustomAttributeDefaults[]) => {
        const withCustomField = [
          {
            attributeId: "customDisplayField",
            attributeName: "Custom Display Field",
            attributeFrenchName: "Custom Display Field",
            isRequired: false,
          },
        ].concat(response);
        return withCustomField;
      },
    }),
    listSections: builder.query({
      query: (formId: undefined | string) => ({
        url: `Section/ListSectionsByForm`,
        method: "GET",
        params: { formId },
        headers: {
          accept: "application/json",
        },
      }),
    }),
    createSection: builder.mutation({
      query: (requestBody: SectionRequestBody) => ({
        url: `Section/CreateSection`,
        method: "POST",
        body: requestBody,
        headers: {
          accept: "application/json",
        },
      }),
    }),
    updateSection: builder.mutation({
      query: (requestBody: SectionRequestBody) => ({
        url: `Section/UpdateSection`,
        method: "PUT",
        body: requestBody,
        headers: {
          accept: "application/json",
        },
      }),
    }),
    deleteSection: builder.mutation({
      query: (sectionId: string) => ({
        url: `Section/DeleteSection`,
        method: "DELETE",
        params: { sectionId },
      }),
    }),
    sortSections: builder.mutation({
      query: (requestBody: SectionSortRequestBody[]) => ({
        url: `Section/UpdateSectionOrder`,
        method: "PUT",
        body: requestBody,
        headers: {
          accept: "application/json",
        },
      }),
    }),
    listSectionAttributes: builder.query<
      SectionCustomAttributeDefaults[],
      string
    >({
      query: (sectionId: string) => ({
        url: `Section/ListSectionAttributesBySection`,
        method: "GET",
        params: { sectionId },
        headers: {
          accept: "application/json",
        },
      }),
      // sort by sortOrder
      transformResponse: (response: SectionCustomAttributeDefaults[]) => {
        const sorted = response.sort(
          (a: any, b: any) => a.sortOrder - b.sortOrder
        );
        return sorted;
      },
    }),
  }),
});

export const mapUpdateSectionRequest = (
  section: SectionItemData,
  fields: SectionFieldsData[]
) => {
  return {
    ...section,
    createSectionAttributeFields: fields,
  };
};

export const mapFormToRequestBody = (
  formData: FormSetupFormData,
  selectedSection: SectionItemData
) => {
  const selectedFields: SectionFieldsData[] = formData?.fields.filter(
    (field: SectionFieldsData) => {
      return field.sectionDefinitionId === selectedSection.id;
    }
  );
  const requestBody: SectionRequestBody = {
    sectionId: selectedSection?.id,
    name: formData.name,
    nameFrench: formData.nameFrench,
    description: formData.description,
    columns: formData?.columns || 1,
    mapping: selectedSection?.mapping,
    createSectionAttributeFields: selectedFields.map(
      (field: SectionFieldsData, index: number) => {
        return {
          // use 00000000-0000-0000-0000-000000000000 for new fields
          // other wise, pass the id of the field
          id: field.id,
          caseId: field.caseId,
          sectionDefinitionId: field.sectionDefinitionId,
          customAttributeDefinitionId: field.customAttributeDefinitionId,
          displayName: field.displayName,
          displayNameFrench: field.displayNameFrench,
          tooltip: field.tooltip,
          tooltipFrench: field.tooltipFrench,
          sortOrder: index,
          isRequired: field.isRequired,
        };
      }
    ),
  };
  return requestBody;
};


export const {
  useListCustomAttributesQuery,
  useCreateSectionMutation,
  useUpdateSectionMutation,
  useListSectionsQuery,
  useSortSectionsMutation,
  useDeleteSectionMutation,
  useListSectionAttributesQuery,
} = formSetupApi;
