import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	MotifIcon,
	MotifButton,
	MotifCard,
	MotifCardHeader,
	MotifCardBody,
	MotifCardFooter,
	MotifFormField,
	MotifSelect,
	MotifOption,
	MotifLabel,
	MotifProgressLoader,
	MotifErrorMessage,
} from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import {
	useReadSchemeQuery,
	useListSchemeVersionsQuery,
	useUpdateSchemeEffectiveMutation,
} from "@/services/scheme/schemeReducer";
import { useLazyExportDocumentQuery } from "@/services/exports/exportsReducer";
import { useGetClassListQuery } from "@/services/class/classReducer";
import { useGetRulesListQuery } from "@/services/rules/rulesReducer";
import Class from "./Class/Class";
import Rules from "./Rules/Rules";
import "./Schemes.scss";
import EditScheme from "./EditScheme";
import NotificationGrid from "@/components/Notification/NotificationGrid";

const SchemeManage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [schemeVersion, setSchemeVersion] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [statusMsg, setStatusMsg] = useState("");
	const [isDraft, setIsDraft] = useState(false);
	const { caseId, schemeId } = useParams<{
		caseId: string;
		schemeId: string;
	}>();
	const { data, refetch } = useReadSchemeQuery(schemeId ?? "");
	const {
		data: schemeVersions,
		isLoading: isListSchemeVersionLoading,
		refetch: schemeVersionRefetch,
		error: schemeVersionError,
	} = useListSchemeVersionsQuery(schemeId ?? "");
	const {
		data: initialClassData,
		refetch: classRefetch,
		isLoading: isClassListLoading,
		error: classError,
	} = useGetClassListQuery(schemeVersion, {
		skip: !schemeVersion,
	});
	const {
		data: initialRulesData,
		refetch: RuleRefetch,
		isLoading: isRuleListLoading,
		error: ruleError,
	} = useGetRulesListQuery(schemeVersion, {
		skip: !schemeVersion,
	});
	const [updateSchemeEffective] = useUpdateSchemeEffectiveMutation();
	const [exportDocument] = useLazyExportDocumentQuery();
	const schemeDetails = [
		{ title: "Name", value: data?.name },
		{ title: "Description", value: data?.description },
		{ title: "Name (French) ", value: data?.nameFrench },
		{ title: "Description (French)", value: data?.descriptionFrench },
	];

	useEffect(() => {
		if (schemeVersions?.length) {
			const draftScheme = schemeVersions.find(
				(version: any) => version.name === "Current Daft Scheme"
			);
			if (draftScheme) {
				setSchemeVersion(draftScheme.schemeVersionId);
				setIsDraft(draftScheme.isDraft);
			}
		}
		// eslint-disable-next-line
	}, [schemeVersions]);
	useEffect(() => {
		if (schemeVersion) {
			RuleRefetch();
		}
	}, [schemeVersion, RuleRefetch]);

	const makeSchemeEffective = () => {
		setStatusMsg("");
		setIsLoading(true);
		updateSchemeEffective(schemeVersion).then((response: any) => {
			if (response.data) {
				setStatusMsg("Scheme made effective successfully");
				exportDocument({
					schemeId,
					url: "/Scheme/ExportClaimItemClass",
				});
				schemeVersionRefetch();
			} else {
				setErrorMessage(
					t("pages.configuration.scheme.makeSchemeEffectiveErrorMessage")
				);
			}
			setIsLoading(false);
		});
	};

	const handleSchemeVersionChange = (value: string) => {
		setSchemeVersion(value);
		const selectedVersion = schemeVersions?.find(
			(version: any) => version.schemeVersionId === value
		);
		setIsDraft(selectedVersion.isDraft);
	};

	if (isListSchemeVersionLoading) {
		return <MotifProgressLoader show variant="default" />;
	}
	if (schemeVersionError) {
		return (
			<MotifErrorMessage>Scheme version could not be loaded</MotifErrorMessage>
		);
	}

	const handlePreviewClick = () => {
		navigate(`/case/${caseId}/scheme-manage/${schemeId}/preview`, {
			state: { schemeVersion },
		});
	};

	return (
		<>
			<div className="scheme-manage-container">
				<MotifCard variant="card-no-border">
					<MotifCardHeader>
						<div
							style={{ cursor: "pointer" }}
							onClick={() => navigate(`/case/${caseId}/?activeTab=5`)}
						>
							<Breadcrumb
								items={[
									{
										text: t("pages.configuration.attributes.backButton"),
										url: "",
										icon: <MotifIcon src={IconoirArrowLeft} />,
									},
								]}
								ariaLabel="Breadcrumb navigation"
							/>
						</div>
						<div className="motif-h3-default-bold">
							{t("pages.configuration.scheme.manageSetup")}
						</div>
					</MotifCardHeader>
					<MotifCardBody>
						{statusMsg && (
							<NotificationGrid
								show={true}
								message={statusMsg}
								type={"success"}
							/>
						)}
						{errorMessage && (
							<NotificationGrid
								show={true}
								message={errorMessage}
								type={"error"}
							/>
						)}
						<div className="motif-container scheme-summary-section pad-v-10 mar-t-20">
							<div className="motif-row justify-content-between align-items-center">
								<div className="motif-col-4">
									<div className="motif-body1-default-regular summary-title">
										{t("pages.configuration.scheme.schemeDetails")}
									</div>
									<div className="motif-body2-default-regular summary-description">
										{t("pages.configuration.scheme.detailDescription")}
									</div>
								</div>
								<div className="motif-col-4">
									<MotifButton
										onClick={() => setShow(true)}
										size="small"
										type="submit"
										variant="secondary"
									>
										{t("pages.configuration.scheme.edit")}
									</MotifButton>
								</div>
							</div>
						</div>
						<div className="motif-container scheme-summary-details pad-v-20">
							<div className="motif-row">
								{schemeDetails.map((detail, colIndex) => (
									<div key={colIndex} className="motif-col-lg-3">
										<div>
											<div className="title">{detail.title}</div>
											<div className="motif-body2-default-bold">
												{detail.value}
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="motif-container manage-section">
							<div className="motif-row justify-content-between align-items-center">
								<div className="motif-col-4">
									<div className="motif-h5-default-regular margin-title">
										{t("pages.configuration.scheme.manageRuleAndClass")}
									</div>
									<div className="motif-body2-default-regular margin-description">
										{t(
											"pages.configuration.scheme.manageRulesAndClassDescription"
										)}
									</div>
								</div>
								<div className="motif-col-4">
									<div className="preview-div">
										<div className="preview-button">
											<MotifButton
												onClick={handlePreviewClick}
												size="small"
												type="submit"
												variant="secondary"
												disabled={!initialRulesData?.length}
											>
												{t("pages.configuration.scheme.preview")}
											</MotifButton>
										</div>
										<MotifFormField className="manage-type-select">
											<MotifLabel>
												{t("pages.configuration.scheme.viewVersion")}
											</MotifLabel>
											<MotifSelect
												onChange={(value) => handleSchemeVersionChange(value)}
												value={schemeVersion}
											>
												{schemeVersions?.map((version: any) => (
													<MotifOption
														key={version.schemeVersionId}
														value={version.schemeVersionId}
													>
														{version.name}
													</MotifOption>
												))}
											</MotifSelect>
										</MotifFormField>
									</div>
								</div>
							</div>
						</div>
						{schemeVersion && (
							<>
								{isClassListLoading ? (
									<MotifProgressLoader show variant="default" />
								) : classError ? (
									<MotifErrorMessage>
										Data could not be loaded
									</MotifErrorMessage>
								) : (
									<Class
										schemeVersionId={schemeVersion}
										initialClassData={initialClassData}
										classRefetch={classRefetch}
										isDraft={isDraft}
									/>
								)}

								{isRuleListLoading ? (
									<MotifProgressLoader show variant="default" />
								) : ruleError ? (
									<MotifErrorMessage>
										Data could not be loaded
									</MotifErrorMessage>
								) : (
									<Rules
										initialRulesData={initialRulesData}
										ruleRefetch={RuleRefetch}
										classRefetch={classRefetch}
										isDraft={isDraft}
										schemeName={data?.name}
									/>
								)}
							</>
						)}
					</MotifCardBody>
					<MotifCardFooter>
						<div className="manage-footer-buttons">
							<MotifButton
								size="small"
								variant="primary"
								type={isLoading ? "loader" : "submit"}
								disabled={
									!(initialRulesData?.length && initialClassData?.length)
								}
								onClick={makeSchemeEffective}
							>
								{t("pages.configuration.scheme.schemeEffective")}
							</MotifButton>
						</div>
					</MotifCardFooter>
				</MotifCard>
			</div>
			<EditScheme
				show={show}
				setShow={setShow}
				editData={data}
				refetch={refetch}
				setStatusMsg={setStatusMsg}
			/>
		</>
	);
};
export default SchemeManage;
