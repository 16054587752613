import {
  CLAIMITEMINCASESUMMARY,
  CLAIMSTAGEANDSTATUS,
  CLAIMSUMMARY,
  COMMENTHISTORY,
  CREDITORCONTACTS,
  CREDITORCONTACTSDATA,
  CREDITORQUICKSEARCH,
  DEBTORQUICKSEARCH,
  DEFAULTUNITBYCASE,
  DOCUMENTCASESUMMARY,
  TASKCASESUMMARY,
  GETCLAIMSTATUS,
} from "@/services/constant/apiPath";
import { ccwpApi } from "@/services";

export const CaseSummaryViewApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getCaseSummaryData: builder.query<any, any>({
      query: (arg) =>
        CLAIMITEMINCASESUMMARY + arg.caseId + "&ClaimId=" + arg.claimId,
    }),
  }),
});

export const { useGetCaseSummaryDataQuery } = CaseSummaryViewApi;

export const TaskViewApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getTaskViewData: builder.query<any, any>({
      query: (arg) => TASKCASESUMMARY + "?ClaimId=" + arg.claimId,
    }),
    createClaimTask: builder.mutation({
      query: (data) => ({
        url: "/ClaimTask/CreateClaimTask",
        method: "POST",
        body: data,
      }),
    }),
    updateClaimTask: builder.mutation({
      query: (data) => ({
        url: "/ClaimTask/UpdateClaimTask",
        method: "PUT",
        body: data,
      }),
    }),
    deleteClaimTask: builder.mutation({
      query: (data) => ({
        url: "/ClaimTask/DeleteClaimTask",
        method: "DELETE",
        body: data,
      }),
    }),
  }),
});
export const {
  useGetTaskViewDataQuery,
  useCreateClaimTaskMutation,
  useUpdateClaimTaskMutation,
  useDeleteClaimTaskMutation,
} = TaskViewApi;

export const DocumentViewApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentViewData: builder.query<any, any>({
      query: (arg) => DOCUMENTCASESUMMARY + "claimId=" + arg.claimId,
    }),
  }),
});
export const { useGetDocumentViewDataQuery } = DocumentViewApi;

export const commentHistoryViewApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getCommentHistoryViewApiData: builder.query<any, any>({
      query: (arg) => COMMENTHISTORY + "EntityId=" + arg.claimId,
    }),
    createComment: builder.mutation({
      query: (data) => ({
        url: "Comment/CreateComment",
        method: "POST",
        body: data,
      }),
    }),
  }),
});
export const {
  useGetCommentHistoryViewApiDataQuery,
  useCreateCommentMutation,
} = commentHistoryViewApi;

export const claimSummaryViewApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getClaimSummaryViewApiData: builder.query<any, any>({
      query: (claimId) => CLAIMSUMMARY + claimId,
    }),
    updateClaim: builder.mutation({
      query: (data) => ({
        url: "/Claim/UpdateClaim",
        method: "PUT",
        body: data,
      }),
    }),
    createClaimItem: builder.mutation({
      query: (data) => ({
        url: "Claim/CreateClaimItem",
        method: "POST",
        body: data,
      }),
    }),
    updateClaimItem: builder.mutation({
      query: (data) => ({
        url: "Claim/UpdateClaimItem",
        method: "PUT",
        body: data,
      }),
    }),
    deleteClaimItem: builder.mutation({
      query: (data) => ({
        url: "Claim/DeleteClaimItem",
        method: "DELETE",
        body: data,
      }),
    }),
  }),
});
export const {
  useGetClaimSummaryViewApiDataQuery,
  useCreateClaimItemMutation,
  useUpdateClaimMutation,
  useUpdateClaimItemMutation,
  useDeleteClaimItemMutation,
} = claimSummaryViewApi;

export const debtorQuickSearch = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getDebtorQuickSearchApiData: builder.query<any, any>({
      query: (arg) => {
        let newUrl = DEBTORQUICKSEARCH + arg.caseId;
        if (arg.debtorText) {
          newUrl += "&SearchText=" + arg.debtorText;
        }
        return newUrl;
      },
      transformResponse: (response: { results: any[] }) => response.results,
    }),
  }),
});
export const { useGetDebtorQuickSearchApiDataQuery } = debtorQuickSearch;

export const creditorQuickSearch = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getCreditorQuickSearchApiData: builder.query<any, any>({
      query: (arg) => {
        let newUrl = CREDITORQUICKSEARCH + arg.caseId;
        if (arg.debtorText) {
          newUrl += "&SearchText=" + arg.debtorText;
        }
        return newUrl;
      },
      transformResponse: (response: { results: any[] }) => response.results,
    }),
  }),
});
export const { useGetCreditorQuickSearchApiDataQuery } = creditorQuickSearch;

export const claimStageAndDocumentApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getclaimStageAndDocumentApiData: builder.query<any, any>({
      query: (claimId) => CLAIMSTAGEANDSTATUS + claimId,
    }),
  }),
});
export const { useGetclaimStageAndDocumentApiDataQuery } =
  claimStageAndDocumentApi;

export const creditorContactsApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getcreditorContactsApiData: builder.query<any, any>({
      query: (creditorId) => CREDITORCONTACTS + creditorId,
    }),
  }),
});
export const { useGetcreditorContactsApiDataQuery } = creditorContactsApi;

export const creditorContactsDataApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getcreditorContactsDataApiData: builder.query<any, any>({
      query: (contactId) => CREDITORCONTACTSDATA + contactId,
    }),
  }),
});
export const { useGetcreditorContactsDataApiDataQuery } =
  creditorContactsDataApi;

export const getUnitByCaseIdDataApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnitByCaseIdDataApiData: builder.query<any, any>({
      query: (caseId) => DEFAULTUNITBYCASE + caseId,
    }),
  }),
});
export const {
  useGetUnitByCaseIdDataApiDataQuery,
  useLazyGetUnitByCaseIdDataApiDataQuery,
} = getUnitByCaseIdDataApi;

export const configCategoryApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getconfigCategoryApiData: builder.query<any, any>({
      query: (args) => ({url:"ClaimItemCategory/ListClaimItemCategoriesByCase",method:"GET",params:{...args}}),
    }),
    deleteClaimItemCategory: builder.mutation({
      query: (categoryId) => ({
        url: `ClaimItemCategory/DeleteClaimItemCategory?id=${categoryId}`,
        method: "DELETE",
      }),
    }),
    createClaimItemCategory: builder.mutation({
      query: (data) => ({
        url: "ClaimItemCategory/CreateClaimItemCategory",
        method: "POST",
        body: data,
      }),
    }),
    updateClaimItemCategory: builder.mutation({
      query: (data) => ({
        url: "ClaimItemCategory/UpdateClaimItemCategory",
        method: "PUT",
        body: data,
      }),
    }),
  }),
});
export const {
  useGetconfigCategoryApiDataQuery,
  useDeleteClaimItemCategoryMutation,
  useCreateClaimItemCategoryMutation,
  useUpdateClaimItemCategoryMutation,
} = configCategoryApi;

export const getTaskByIdApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getTaskByIdApiData: builder.query<any, any>({
      query: (taskId) => `ClaimTask/ReadClaimTask?ClaimTaskId=${taskId}`,
    }),
  }),
});
export const { useGetTaskByIdApiDataQuery } = getTaskByIdApi;

export const CaseAttributeHistory = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getClaimAttributeHistoryData: builder.query<any, any>({
      query: (arg) => ({url:"Claim/GetClaimAttributeHistoryList",method:"GET",params:{...arg}}),
    }),
  }),
});
export const { useGetClaimAttributeHistoryDataQuery } = CaseAttributeHistory;
export const getClaimStatus = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getClaimStatusApiData: builder.query<any, any>({
      query: (definitionId) => GETCLAIMSTATUS + definitionId,
      transformResponse: (response: { results: any[] }) => response.results,
    }),
  }),
});
export const { useGetClaimStatusApiDataQuery } = getClaimStatus;

export const CaseClaimsHistory = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getClaimsHistoryData: builder.query<any, any>({
      query: (arg) =>({url:"Claim/ListClaimHistory",method:"GET",params:{...arg}}),
    }),
  }),
});
export const {
  useGetClaimsHistoryDataQuery,
  useLazyGetClaimsHistoryDataQuery,
} = CaseClaimsHistory;

export const CaseClaimsItemHistory = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getClaimsItemsHistoryData: builder.query<any, any>({
      query: (args) =>({url:"Claim/ListClaimItemHistory",method:"GET",params:{...args}}),
    }),
  }),
});
export const {
  useGetClaimsItemsHistoryDataQuery,
  useLazyGetClaimsItemsHistoryDataQuery,
} = CaseClaimsItemHistory;
