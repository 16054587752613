import { useEffect, useState } from "react";
import Table from "@/components/Table/table";
import { useCreditorVotesDefs } from "./CreditorVotesDef";
import { useParams } from "react-router-dom";
import {
  useLazyGetBallotResultByIdQuery,
  useLazyGetVotesByBallotIdQuery,
} from "./votingReducer";
import {
  MotifProgressLoader,
  MotifButton,
  MotifDropdown,
  MotifIcon,
  MotifDropdownItem,
} from "@ey-xd/motif-react";
import RecordVote from "./RecordVote";
import { IconoirPlus } from "@ey-xd/motif-icon";
import { useTranslation } from "react-i18next";
import { useLazyExportDocumentQuery } from "@/services/exports/exportsReducer";
import BulkVotingImports from "./VotingForm/BulkVotingImports";
import { useLazyDownloadTemplateDocumentQuery } from "@/services/manualDataSource/ManualDataSourceReducer";
import { useCase } from "@/features/case/use-case";

export default function CreditorVotesTab({
  isReadonly,
  BallotVerionSelect,
  setIsRefreshVersionList,
  ballot,
  readBallot,
}: any) {
  const [isCurrentBallotSelect, setIsCurrentBallotSelect] = useState(true);
  const { editId } = useParams<{
    editId: string;
  }>();
  const caseData = useCase("", true);
  const [getVotes, { data: votersData, isLoading, isFetching }] =
    useLazyGetVotesByBallotIdQuery();
  const [isRefreshCall, setIsRefreshCall] = useState(true);
  const [getBallotResultById, { isFetching: ballotResultLoading }] =
    useLazyGetBallotResultByIdQuery();

  const triggerGetVotes = () => {
    getBallotResultById({ ballotId: editId }).then(async (value) => {
      setIsRefreshCall(true);
      setIsRefreshVersionList(true);
      readBallot();
    });
  };
  const TitleComplement = () => {
    const { editId } = useParams<{
      editId: string;
    }>();
    const [exportDocument] = useLazyExportDocumentQuery();
    const [showModal, setShowModal] = useState(false);
    const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
    const votesTitleMenu1 = [{label:"pages.voting.creditorVotes.moreOptions.label1",value:"Manual Recording"},{label:"pages.voting.creditorVotes.moreOptions.label2",value:"Bulk Import"} ];
    const [downloadDocument] = useLazyDownloadTemplateDocumentQuery();
    const [bulkimportFileStatus, setBulkimportFileStatus] = useState("");
    const handleDownloadVotes = (ballotId: any) => {
      exportDocument({
        ballotId,
        url: "/Ballot/ExportCreditorVotes",
      });
    };
    const handleDownloadBulkImportTemplate = () => {
      downloadDocument({
        entityType: "4",
      });
    };

    const handleMenuClick = (menuName: string) => {
      if (menuName === "Manual Recording") {
        setShowModal(true);
      } else {
        setShowBulkUploadModal(true);
      }
    };
    useEffect(() => {
      if (BallotVerionSelect && BallotVerionSelect !== "Current Ballot") {
        setIsCurrentBallotSelect(false);
      } else {
        setIsCurrentBallotSelect(true);
      }
    }, []);

    const refachVotesList = () => {
      if (bulkimportFileStatus === "Success") {
        setIsRefreshCall(false);
        getVotes({
          ballotId: editId,
          isRefresh: false,
        });
      }
    };
    return (
      <div style={{ display: "flex", gap: "1rem" }}>
        <MotifButton
          variant="text"
          type="button"
          onClick={() => handleDownloadVotes(editId)}
        >
          {t("pages.voting.creditorVotes.export")}
        </MotifButton>
        <MotifDropdown
          ariaLabelledby="dropdown-trigger-1"
          id="dropdown-1"
          trigger={
            <MotifButton
              aria-label="dropdown button"
              id="dropdown-trigger-1"
              type="button"
              variant="text"
              disabled={!isReadonly || !isCurrentBallotSelect}
            >
              <MotifIcon iconFunction={IconoirPlus} />
            </MotifButton>
          }
        >
          <ul role="menu">
            {votesTitleMenu1.map((menuName: any) => {
              return (
                <li role="menuitem">
                  <MotifDropdownItem
                    indexItem={1}
                    onClick={() => handleMenuClick(menuName.value)}
                  >
                    {t(menuName.label)}
                  </MotifDropdownItem>
                </li>
              );
            })}
          </ul>
        </MotifDropdown>
        <RecordVote
          showModal={showModal}
          setShowModal={setShowModal}
          readBallot={readBallot}
        />
        {showBulkUploadModal && (
          <BulkVotingImports
            showModal={true}
            setShowModal={setShowBulkUploadModal}
            title={t("pages.voting.creditorVotes.bulkVoting.title")}
            description={t("pages.voting.creditorVotes.bulkVoting.description")}
            onDownload={handleDownloadBulkImportTemplate}
            uploadLabel={t("pages.voting.creditorVotes.bulkVoting.uploadLabel")}
            uploadDescription={t("pages.voting.creditorVotes.bulkVoting.uploadDescription")}
                        accept=".pdf,.jpg,.jpeg,.doc,.docx"
            maxFiles={1}
            ballotId={editId || ""}
            onClose={() => {
              setShowBulkUploadModal(false);
              refachVotesList();
            }}
            setBulkimportFileStatus={setBulkimportFileStatus}
          />
        )}
      </div>
    );
  };
const creditorVotesDefs=useCreditorVotesDefs(caseData)
  const { t } = useTranslation();

  useEffect(() => {
    getVotes({
      ballotId: editId,
    });
  }, [editId, getVotes]);

  return (
    <>
      {isLoading ||
      (!isLoading &&
        !isFetching &&
        !ballotResultLoading &&
        !setIsRefreshVersionList) ? (
        <MotifProgressLoader show variant="default" className="mar-b-20" />
      ) : (
        <>
          <div className="ccwp_claim_claimlisttable">
            <Table
              columnDefs={creditorVotesDefs}
              cellRenderers={
                isRefreshCall
                  ? ballot?.refreshBallot?.creditorVotesList
                  : votersData?.results
              }
              TitleComplement={TitleComplement}
              title={"pages.voting.creditorVotes.tableTitle"}
              description={
"pages.voting.creditorVotes.tableDescription"      
 }
              pagination={true}
              overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "flex-end",
            }}
          >
            <MotifButton
              onClick={triggerGetVotes}
              size="small"
              type={isFetching || ballotResultLoading ? "loader" : "button"}
              variant="primary"
              disabled={!isReadonly || !isCurrentBallotSelect}
            >
              {t("global.globalActions.refresh")}
            </MotifButton>
          </div>
        </>
      )}
    </>
  );
}
