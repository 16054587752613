import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
  activeTab: 0,
};

const tabPanelSlice = createSlice({
  name: "tabpanel",
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<any>) {
      return { activeTab: action.payload };
    },
  },
});

export const {setActiveTab} = tabPanelSlice.actions;
export default tabPanelSlice.reducer;
