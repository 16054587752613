import { useEffect } from "react";
import { MotifSearch } from "@ey-xd/motif-react";
import { Props } from "@ey-xd/motif-react/components/forms/search/search.d";

function debounceInput(fn: any, delay: number) {
  let timer: any;
  return function (this: any, ...args: any) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}

export default function SearchInput({ id, onChange, ...props }: Props) {
  const handleChange = debounceInput((e: any) => {
    if (onChange) {
      onChange(e);
    }
  }, 250);

  useEffect(() => {
    // find the first search box and set the maxlength to 100
    const searchBox = document.getElementsByClassName("motif-typeahead-input");
    if (searchBox && searchBox.length > 0) {
      for (let i = 0; i < searchBox.length; i++) {
        const element = searchBox[i] as HTMLInputElement;
        element.setAttribute("maxlength", "100");
      }
    }
  }, []);

  return <MotifSearch {...props} onChange={handleChange} />;
}
