import {
  MotifFormField,
  MotifIcon,
  MotifOption,
  MotifSelect,
} from "@ey-xd/motif-react";
import "./CustomPagination.scss";
import { IconoirNavArrowLeft, IconoirNavArrowRight } from "@ey-xd/motif-icon";
import { useTranslation } from "react-i18next";
type CustomPaginationProps = {
  currentpage: number;
  pageSize: number;
  onChangePageSize: (page: number) => void;
  disableNext: boolean;
  pageSizeSelector: number[];
  onChangeNextPage?: () => void;
  onChangePreviousPage?: () => void;
};

export default function CustomPagination({
  currentpage,
  pageSize,
  onChangePageSize,
  disableNext,
  pageSizeSelector,
  onChangeNextPage,
  onChangePreviousPage,
}: CustomPaginationProps) {
  let { t } = useTranslation();
  return (
    <div className="pagination-row">
      <div className="left-row"></div>
      <div className="paginationPanel">
        <div className="select-label">
          {" "}
          {`${t("pages.pagination.pageSize")}:`}
        </div>
        <MotifFormField>
          <MotifSelect
            ariaLabelledBy="select-label"
            onChange={onChangePageSize}
            placeholder=""
            value={String(pageSize)}
          >
            {pageSizeSelector.map((size) => (
              <MotifOption key={String(size)} value={String(size)}>
                {String(size)}
              </MotifOption>
            ))}
          </MotifSelect>
        </MotifFormField>
        <nav
          aria-label="Pagination Navigation"
          role="navigation"
          className="motif-pagination"
          data-motif-pagination="true"
        >
          <ul className="motif-pagination-list">
            <li className="motif-pagination-item"></li>
            <li className="motif-pagination-item">
              <button
                title="Go to previous page"
                type="button"
                className="motif-icon-button motif-icon-button-small"
                disabled={currentpage === 1 ? true : false}
                data-motif-icon-button="true"
                onClick={onChangePreviousPage}
              >
                <span className="motif-icon" title="" data-motif-icon="true">
                  <MotifIcon
                    iconFunction={IconoirNavArrowLeft}
                    title="Left"
                    size="24"
                  />
                </span>
              </button>
            </li>
            <li className="motif-pagination-item current-page">
              {currentpage}
            </li>
            <li className="motif-pagination-item">
              <button
                title="Go to next page"
                type="button"
                className="motif-icon-button motif-icon-button-small"
                disabled={disableNext}
                data-motif-icon-button="true"
                onClick={onChangeNextPage}
              >
                <span className="motif-icon" title="" data-motif-icon="true">
                  <MotifIcon
                    iconFunction={IconoirNavArrowRight}
                    title="Right"
                    size="24"
                  />
                </span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
