import React from 'react';
import CaseList from '@/features/caselist/CaseList';
const Home = () => {
  return (
    <>
      <CaseList/>
    </>
  )
}

export default Home;
