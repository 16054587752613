import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DistributionsTab, IDistributionsState } from "./DistributionsType";

export const initialState: IDistributionsState = {
  currentTab: DistributionsTab.Distribution,
  distributionStatus: undefined
};

const distributionsSlice = createSlice({
  name: "distributions",
  initialState,
  reducers: {
    setCurrentTab(state: IDistributionsState, action: PayloadAction<DistributionsTab>) {
      return { ...state, currentTab: action.payload };
    },
    setDistributionStatus(state: IDistributionsState, action: PayloadAction<number | null>) {
      return { ...state, distributionStatus: action.payload };
    },
  },
});

export const { setCurrentTab ,setDistributionStatus} = distributionsSlice.actions;
export default distributionsSlice.reducer;
