 import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactDetailsType, CreditorSetupFormType, CreditorSetupType } from './type';

export const initialState:CreditorSetupType = {
    creditorSetupForm: {
        creditorType: "individual",
        firstName: "",
        lastName: "",
        prefix: "",
        businessName: "",
        description: "",
        relationShipToDebtor: "",
    },
    contactDetails: {
        fieldArray: [
            {
                firstName: "",
                lastName: "",
                prefix: "",
                address1: "", 
                address2: "", 
                city: "",
                state: "",
                country: "",
                email: "",
                phone: "",
                fax: "",
                postalCode: "",
                setAsPrimaryContact: false,
                addPaymentDetails: false,
                institutionNumber:"",
                transitNumber:"",
                accountNumber:"",
                ibanNumber:"",
                swiftCode:"",
                routingCode:"",
                achCode:"",
                relationShipToCreditor: "",
                usedAsAdditionalPayeeType:false,
                additionalPayeeTypeId:null,
            }
        ]
    },
    customAttributes: [],
    stage: 0,
    formSubmitIndex: 0,
    caseId:""
}

export const CreditorSetupSlice = createSlice({
    name: 'creditorSetup',
    initialState,
    reducers: { 
      setCreditorSetFormData: (state, action: PayloadAction<CreditorSetupFormType>) => {
        return {...state, creditorSetupForm: action.payload}; 
      },
      resetCreditorSetup: () => {
        return initialState;
      },
      updateStage: (state, action: PayloadAction<number>) => {
        return {...state, stage: action.payload};
      },
      setContactDetails: (state, action: PayloadAction<ContactDetailsType>) => {
          return {...state, contactDetails: action.payload}; 
      },
      setCustomAttributes: (state, action: PayloadAction<any>) => {
        return {...state, customAttributes: action.payload}; 
      },
      updateFormSubmitIndex: (state, action: PayloadAction<number>) => {
        return {...state, formSubmitIndex: action.payload};
      },
    },
})

export const { setCreditorSetFormData, resetCreditorSetup, updateStage, setContactDetails, setCustomAttributes, updateFormSubmitIndex } = CreditorSetupSlice.actions;
export default CreditorSetupSlice.reducer;