
import TabPanel from "@/components/TabPanel/TabPanel";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import ClaimsTabContent from "@/features/claims/claimsTab/claimsTabContent";

export default function ClaimGrid() {
  const tabPanelData = {
    title: ["Summary","Debtors", "Creditors", "Claims", "Voting", "Distributions", "Payment Status","Configuration"],
    content: [
      <div>Content for Summary</div>,
      <div>Content for Debtors</div>,
      <div>Content for Creditors</div>,
      <ClaimsTabContent/>,
      <div>Content for Voting</div>,
      <div>Content for Distributions</div>,
      <div>Content for Payment Status</div>,
      <div>Content for Configuration</div>,
    ],
  };
  const breadcrumbItems = [
    {
      label: 'External Submission',
      value: '10 New Creditor Submission',
      elementKey: 'submission',
      text: "Back to Home",
      url: "/",
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];
  return (
    <>
      <div className="ccwp_table_wrapper">
        <Breadcrumb  items={breadcrumbItems} ariaLabel="Breadcrumb navigation"/>
        {/* <Banner
          bannerName={t(creditorBanner.creditorName)}
          bannerNameValue={t(creditorBanner.creditorTtl)}
          elements={bannerElements}
        /> */}
        {/* <GridBanner/> */}
        <div className="ccwp_table_tabpanel">
          <TabPanel data={tabPanelData} defaultActiveKey={3}/>
        </div>
      </div>
    </>
  );
}
