import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MotifFileUploader, MotifFormField } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import Button from "@/components/Form/Button/Button";
import { SECONDARY } from "@/Constant";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import useResetAllClaimItemsStore from "@/pages/CaseDetail/Claim/claimSetup/useResetAllClaimItemsStore";
import useFormDefaults from "@/features/formDefaults/useFormDefaults";
import { setClaimDocument } from "@/pages/CaseDetail/Claim/claimSetup/Description/descriptionSlice";
import PageSubTitle from "@/components/Form/PageSubTitle/PageSubTitle";

import "./UploadDocument.scss";
import FileList from "./FileList";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
import { fileExtensions } from "@/services/documents/documentsReducer";

type UploadDocumentProps = {
  showSetReviewPage: () => void;
  files: File[] | [];
  setFiles: (files: File[]) => void;
};

export default function UploadDocument({
  showSetReviewPage,
  files,
  setFiles,
}: UploadDocumentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { caseId } = useParams();
  const { claim } = useFormDefaults();
  const { documentTypes } = claim;
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  let [filesUploaded, setFilesUploaded] = useState<any>([...files]);
  const disableButton =
    filesUploaded &&
    filesUploaded.find((file: any) => file?.size >= fileExtensions.maxFileSize)
      ? true
      : false;

  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: true }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      let claimDocumentData: any = [];
      claimDocumentData = filesUploaded.filter((file: any) => file);
      setFiles([...claimDocumentData]);
    };
  }, [filesUploaded, setFiles]);

  //const { control, setValue, getValues } = useForm<ClaimSetupUploadDoc>();
  const resetAllClaimItemsStore = useResetAllClaimItemsStore();

  const confirmationModalCancelHandler = () => {
    setIsVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));
  };

  const confirmationModalDeleteHandler = () => {
    setIsVisible(false);
    resetAllClaimItemsStore();
    navigate(`/case/${caseId}?activeTab=3`);
  };

  const handleFileChange = (newFiles: any) => {
    setFilesUploaded([...filesUploaded, ...newFiles]);
  };

  const handleFileDownload = (
    e: MouseEventHandler<HTMLButtonElement>,
    index: number
  ) => {
    const file = filesUploaded[index];
    if (file) {
      const filePath = window.URL.createObjectURL(file);
      // download file in new window?
      window.open(filePath);
    }
  };

  const saveAndReview = () => {
    // handleFileChange(files);

    //showSetReviewPage();
    const claimDocumentData: any = [];
    filesUploaded.forEach((file: any) => {
      if (file) {
        claimDocumentData.push({
          caseId: caseId,
          documentTypeId: null,
          comment: "",
          documentType: "2",
          document: file.name,
          displayName: file ? file.name : null,
        });
      }
    });
    dispatch(setClaimDocument([...claimDocumentData]));
    showSetReviewPage();
  };

  const removeItem = (index: number) => {
    const newFiles = [...filesUploaded];
    newFiles.splice(index, 1, null);

    setFilesUploaded([...newFiles]);
  };

  const returnFileList = (files: File[]) => {
    return (
      files &&
      files.length > 0 &&
      files.map((file, index) => (
        <FileList
          key={index}
          file={file}
          index={index}
          removeItem={removeItem}
          handleFileDownload={handleFileDownload}
        />
      ))
    );
  };
  if (!documentTypes) {
    return null;
  }
  return (
    <>
      <div className="upload-doc">
        <PageSubTitle
          title={"pages.claims.claimCreate.uploadDocumentTab.title"}
          description={""}
        />
        {/* <div className="motif-row">
          <div className="motif-col-lg-6">
            {documentTypes && documentTypes[0]?.id && (
              <ControlSelectUploadDoc
                name={t(DOC_TYPE_FIELD)}
                control={control}
                showLabel={true}
                label={t(DOC_TYPE)}
                options={documentTypes}
                required={false}
                selectedValue={claimDocumentState?.documentTypeId}
                setValue={setValue}
                getValues={getValues}
              />
            )}
          </div>
          <div className="motif-col-lg-6">
            <ControlInputBoxUploadDoc
              name={t(COMMENT_NAME)}
              control={control}
              required={false}
              isTextArea={false}
              label={COMMENT_LABEL}
              showLabel={true}
              value={claimDocumentState?.comment}
            />
          </div>
        </div> */}
        <div className="motif-row mar-t-30">
          <div className="motif-col-lg-12">
            <MotifFormField>
              <MotifFileUploader
                id="my-id"
                label={t("pages.claims.claimCreate.uploadDocumentTab.uploadLabel")}
                labelDescription={t(
                  "pages.claims.claimCreate.uploadDocumentTab.uploadDescription"
                )}
                onDrop={(files) => handleFileChange(files)}
                maxFileSize={100}
                accept={fileExtensions.default}
              >
                {returnFileList(filesUploaded)}
              </MotifFileUploader>
            </MotifFormField>
          </div>
        </div>

        <div className="display-flex justify-content-end mar-t-70">
          <div className="pad-r-10">
            <Button
              type="button"
              label={t("global.globalActions.cancel")}
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={() => {
                setIsVisible(true);
                dispatch(setDiscardEditMode({ editMode: false }));
              }}
            />
          </div>
          <div className="">
            <Button
              label={t("global.globalActions.next")}
              variant="primary"
              disabled={disableButton}
              onClickHandler={saveAndReview}
            />
          </div>
        </div>
        <ConfirmationModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          cancelBtnApproveHandler={confirmationModalDeleteHandler}
          cancelBtnRejectHandler={confirmationModalCancelHandler}
        />
      </div>
    </>
  );
}
