import { dateFunc } from "@/components/Functions/dateFunc";
import { useTranslation } from "react-i18next";

export const useGetColumnDefsRateTierTemplate = (timeZone: string) =>{
   const {t}=useTranslation()
    return [
    {
        
        			comparator: () => 0,
field: "name",
        headerName: t("pages.distributions.rateTire.tableColumnDetails.column1"),
        flex: 2,
        unSortIcon: true,
        sortable: true,
        type:"nameType",
    },
    {
        
        			comparator: () => 0,
field: "year",
        headerName: t("pages.distributions.rateTire.tableColumnDetails.column2"),
        type:"yearType",
        flex: 1,
        unSortIcon: true,
        sortable: true,
    },
    {
        
        			comparator: () => 0,
field: "jurisdiction",
        headerName: t("pages.distributions.rateTire.tableColumnDetails.column3"),
        type:"jurisdictionType",
        flex: 2,
        unSortIcon: true,
        sortable: true,
    },
    {
        
        			comparator: () => 0,
field: "createdOn",
        headerName: t("pages.distributions.rateTire.tableColumnDetails.column4"),
        type:"createdOnType",
        flex: 3,
        sortable: true,
        unSortIcon: true,
        cellRendererParams: {
            timeZoneName: timeZone, 
          },
          cellRenderer: function (params: any) {
            return dateFunc(params.data.createdOn).formattedDateTime + " " +params.timeZoneName;
          }, 
    },
    {
        
        			comparator: () => 0,
field: "createdBy",
        headerName: t("pages.distributions.rateTire.tableColumnDetails.column5"),
        type:"createdByType",
        flex: 2,
        unSortIcon: true,
        sortable: true,
    },
    {
        
        			comparator: () => 0,
field: "isActive",
        headerName: t("pages.distributions.rateTire.tableColumnDetails.column6"),
        flex: 1,
        sortable: false,
        cellRenderer: "actionRenderer",
        cellStyle: { justifyContent: "right" },
    },
]};
