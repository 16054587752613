import Table from "@/components/Table/table";
import { useParams } from "react-router-dom";
import { useGetClaimAttributeHistoryDataQuery } from "@/services/claimSummary/caseSummaryReducer";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { useState } from "react";
import { dateFunc } from "@/components/Functions/dateFunc";
import "@/features/creditor/claimsTab/Constant/attributeHistory.scss";
import {  customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import { useCase } from "@/features/case/use-case";
import { useTranslation } from "react-i18next";
export const TitleComplement = () => {
  return <></>;
};

export default function AttributeHistoryTab() {
  const {t}=useTranslation()
  const { claimId } = useParams<{
    claimId: string;
  }>();
  const paginationPageSize: number = customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
  let tableLoadingData=customTableLoadingData
  const [paginationObj, setPaginationObj] = useState({
    "ClaimId":claimId,
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",
    SortOrder: "desc",
    SortColumn: "CreatedDate",
  });
  const { data, isLoading,isFetching,error } =
    useGetClaimAttributeHistoryDataQuery(paginationObj);
  const sortedData = data?.results?data?.results:[];
  const headerData = {
    title: "Attribute History X",
    description: "Descriptive text that describes your page or content.",
  };
 
let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({
  createdDateType:{sort:null}
})

function handlePaginationData(data:any,columnTypesData:any){
  setPaginationObj({...data})
  setSortedColumnTypes({...columnTypesData})
}

    const caseData = useCase();

  const getColumnHistoryDefs = (timeZone: string) => [
    {
      cellRenderer: "attributeName",
      			comparator: () => 0,

      field: "attributeName",
      type:"attributeNameType",
      headerName: t("pages.claims.attributeHistoryTab.tableColumnsDetails.column1"),
      sortable: true,
      unSortIcon: true,

        
    },
    {
      			comparator: () => 0,

      field: "createdDate",
      type:"createdDateType",
      headerName: t("pages.claims.attributeHistoryTab.tableColumnsDetails.column2"),
      flex: 1,
      cellRendererParams: {
        timeZoneName: timeZone, 
      },
      cellRenderer: function (params: any) {
        return dateFunc(params.data.createdDate).formattedDateTime + " " +params.timeZoneName;
      },
      sortable: true,
      unSortIcon: true,
        
  
    },
    {
      			comparator: () => 0,

      field: "createdBy",
      type:"createdByType",
      headerName: t("pages.claims.attributeHistoryTab.tableColumnsDetails.column3"),
      flex: 1,
      sortable: true,
      unSortIcon: true,
  
    },
  
    {
      			comparator: () => 0,

      field: "oldValue",
      type:"oldValueType",
      headerName:t("pages.claims.attributeHistoryTab.tableColumnsDetails.column4"),
      flex: 1,
      width: 350,
      cellRenderer: function (params: any) {
        return <div className="text-wrap ">{params.data.oldValue || "-"}</div>;
      },
      sortable: true,
      unSortIcon: true,
        
  
    },
    {
      			comparator: () => 0,

      field: "newValue",
      type:"newValueType",
      headerName: t("pages.claims.attributeHistoryTab.tableColumnsDetails.column5"),
      flex: 1,
      width: 350,
      cellRenderer: function (params: any) {
        return <div className="text-wrap ">{params.data.newValue || "-"}</div>;
      },
      unSortIcon: true,

        
  
    },
  ];
  return (
    <>
      {isLoading ? (
        <MotifProgressLoader show variant="default" className="mar-b-20" />
      ) : (
        <div className="mar-b-20">
          <Table
            columnDefs={getColumnHistoryDefs(caseData?.abbreviation)}
            columnTypes={{...sortedColumnTypes}}
            handlePaginationData={handlePaginationData}
                paginationObj={paginationObj}
            cellRenderers={isFetching?[]:sortedData}
            TitleComplement={TitleComplement}
            title={"pages.claims.attributeHistoryTab.title"}
            description={
              "pages.claims.attributeHistoryTab.description"}
            caseRenderers={headerData}
            pagination={false}
            overlayNoRowsTemplate={isFetching?t(tableLoadingData):error?t("global.globalNotifications.wentWrong")
              : t("global.globalNotifications.noRecords")}

          />
            <CustomPagination
          currentpage={paginationObj.PageNumber}
          pageSize={paginationObj.PageSize}
          disableNext={!data?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
        </div>
      )}
    </>
  );
}