import { useState, useEffect } from "react";
import {
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifButton,
} from "@ey-xd/motif-react";

interface SessionPopupProps {
  isModalVisible: boolean;
  setModalVisibility: (isVisible: boolean) => void;
  handleLogout: () => void;
  handleExtend: (renewNow: boolean) => void;
  warningTime: number;
}

function SessionPopup({
  warningTime,
  isModalVisible,
  setModalVisibility,
  handleLogout,
  handleExtend,
}: SessionPopupProps) {
  const [time, setTime] = useState(warningTime); // initial time in seconds

  useEffect(() => {
    setTime(warningTime);
  }, [handleExtend, warningTime]);

  useEffect(() => {
    let timer: number;
    if (isModalVisible) {
      timer = window.setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => window.clearInterval(timer);
  }, [isModalVisible]);

  useEffect(() => {
    if (time <= 0) {
      handleLogout();
      setModalVisibility(false);
    }
  }, [time, handleLogout, setModalVisibility]);

  const displayedTime =
    time > 60
      ? `${Math.floor(time / 60)} minutes ${time % 60} seconds`
      : `${time} seconds`;

  return (
    <MotifModal
      show={isModalVisible}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={() => {
        setModalVisibility(false);
        sessionStorage.setItem("popupDismissed", "true");
      }}
    >
      <MotifModalHeader>Your session expires soon!</MotifModalHeader>
      <MotifModalBody>
        <p>
          To keep your data safe, we will log you out of the application in{" "}
          {displayedTime}.
        </p>
        <p>
          If you are still working, you can extend your session now. Otherwise,
          you will be logged out automatically.
        </p>
      </MotifModalBody>
      <MotifModalFooter>
        <MotifButton
          size="medium"
          type="button"
          onClick={() => {
            handleExtend(true);
            setModalVisibility(false);
            sessionStorage.setItem("popupDismissed", "true");
          }}
        >
          Extend session
        </MotifButton>
        <MotifButton
          size="medium"
          variant="secondary"
          type="button"
          onClick={() => {
            handleLogout();
            setModalVisibility(false);
            sessionStorage.setItem("popupDismissed", "true");
          }}
        >
          Log out now
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
}

export default SessionPopup;
