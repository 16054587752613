import { useDispatch } from "react-redux";
import { resetDescriptionState } from "./Description/descriptionSlice";
import { resetDebtorState } from "./Debtor/debtorSlice";
import { resetCreditorState } from "./Creditor/creditorSlice";
import { resetAssigneeState } from "./SelectAssignee/selectAssigneeSlice";
import { resetClaimItemsState } from "./ClaimItems/claimItemsSlice";

const useResetAllClaimItemsStore = () => {
  const dispatch = useDispatch();

  const resetAll = () => {
    dispatch(resetDescriptionState());
    dispatch(resetDebtorState());
    dispatch(resetCreditorState());
    dispatch(resetAssigneeState());
    dispatch(resetClaimItemsState());
  };

  return resetAll;
};

export default useResetAllClaimItemsStore;