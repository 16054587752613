import TabPanel from "@/components/TabPanel/TabPanel";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Banner, { BannerElement } from "@/components/Banner/Banner";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import { useParams } from "react-router-dom";
import FormManagementSummary from "./FormManagement/FromManagementSummary";
import { useGetReadCustomFormApiQuery } from "./FormManagement/PortalConfigReducer";
import FormSetup from "./FormManagement/FormSetup/FormSetup";

export default function FormManagementTabs() {
  const { caseId, editId } = useParams();
  const editView = editId ? true : false;
  const { data } = useGetReadCustomFormApiQuery(editId, {
    refetchOnMountOrArgChange: true,
    skip: !editId,
  });

  const formData = data ? data : {};

  const tabPanelData = {
    title: ["Summary", "Form Setup"],
    content: [
      <FormManagementSummary formData={{ editView: editView }} />,
      <FormSetup />,
    ],
  };
  const tabPanelDatas = {
    title: ["Summary"],
    content: [
      <FormManagementSummary formData={{ editView: editView}} />,
    ],
  };

  const bannerElement: BannerElement[] = [
    {
      label: "Status",
      value: formData?.status,
      elementKey: "caseID",
    },
  ];
  const breadcrumbItems = [
    {
      label: "External Submission",
      value: "10 New Creditor Submission",
      elementKey: "submission",
      text: "Back",
      url: `/case/${caseId}/?activeTab=8`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];

  if (!editView) {
    return (
      <>
        <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
        <h1>Create a new Form</h1>
        <div className="ccwp_table_wrapper ccwp_table_dropdown_wrapper">
          <TabPanel key="create" data={tabPanelDatas} />
        </div>
      </>
    );
  }

  return (
    <>
      <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
      <Banner
        bannerName={"Form Name"}
        bannerNameValue={formData?.name}
        elements={bannerElement}
      />
      <div className="ccwp_table_wrapper ccwp_table_dropdown_wrapper">
        <TabPanel key="edit" data={tabPanelData} />
      </div>
    </>
  );
}
