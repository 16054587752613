import { Controller } from "react-hook-form";
import { MotifIcon, MotifInput } from "@ey-xd/motif-react";
import {
  MotifActionIcVisibilityOff24px,
  MotifActionIcVisibility24px,
} from "@ey-xd/motif-icon";

type paymentInputFieldProps = {
    control: any;
    showField: any;
    handleViewIcon: (name:string)=>void;
    name: string;
}
export default function PaymentInputField({
  control,
  showField,
  handleViewIcon,
  name,
}: paymentInputFieldProps) {
  return (
    <div className="form-field">
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <MotifInput
              //@ts-ignore
              type={showField[name] ? "text" : "password"}
              className="unmaskedFiledsinput"
              value={value||""}
              onChange={onChange}
              hideClearButton={true}
            ></MotifInput>
          );
        }}
        name={name}
      ></Controller>
      <div className="unmaskedFiledsEye" onClick={() => handleViewIcon(name)}>
        <MotifIcon
          src={
            showField[name]
              ? MotifActionIcVisibilityOff24px
              : MotifActionIcVisibility24px
          }
        ></MotifIcon>
      </div>
    </div>
  );
}
