import React, { useEffect } from "react";
import {
  MotifCard,
  MotifCardBody,
  MotifCardHeader,
  MotifIconButton,
  MotifIcon,
} from "@ey-xd/motif-react";
import {
  IconoirArrowUp,
  IconoirArrowDown,
  IconoirTrash,
} from "@ey-xd/motif-icon";
import "./sectionAttributesPanel.scss";
import ControlInputBox from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "react-hook-form";
import { SectionAttributeFormData } from "../Fields/fields-helper";
import { updateForm } from "@/features/form-setup/formSetupSlice";
import logger from "@/libs/logger";
import ControlCheckBox from "../../ControlCheckBox";
import { SectionCustomAttributeDefaults } from "@/services/formSetup/formSetupReducers";
import CustomDisplayField from "./CustomDisplayField";
import { useTranslation } from "react-i18next";

interface SectionAttributesProps {
  control: any;
  setValue: any;
  formState: any;
  append: (data: Field) => void;
  prepend: (data: Field) => void;
  remove: (index: number) => void;
  swap: (indexA: number, indexB: number) => void;
  move: (from: number, to: number) => void;
  insert: (index: number, data: Field) => void;
  fields: any;
  fetchedAttributes: any;
  refetch: any;
}

const SectionAttributes: React.FC<SectionAttributesProps> = ({
  control,
  setValue,
  fetchedAttributes,
  refetch,
  formState,
  append,
  prepend,
  remove,
  swap,
  move,
  insert,
  fields,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formSetup = useSelector((state: any) => state.formSetup);
  const { blockNavigation } = useSelector((state: any) => state.confirmation);
  // const { errors } = formState;
  const sectionName = formSetup.sectionId
    ? formSetup?.sections.find(
        (section: any) => section.id === formSetup.sectionId
      )?.name
    : "Select a section";
  const { customFieldsDefaults } = formSetup;

  useEffect(() => {
    if (
      fetchedAttributes?.length > 0 &&
      fetchedAttributes[0].sectionDefinitionId !== formSetup.sectionId
    ) {
      refetch();
    }
    logger.info("fetchedAttributes", fetchedAttributes);
    logger.info("formSetup.sectionId", formSetup.sectionId);
  }, [formSetup.sectionId, refetch, fetchedAttributes]);

  useEffect(() => {
    logger.info("fetchedAttributes", fetchedAttributes);
    if (fetchedAttributes) {
      setValue("fields", fetchedAttributes);
      dispatch(updateForm({ key: "fields", value: fetchedAttributes }));
    }
  }, [fetchedAttributes, setValue, dispatch]);

  useEffect(() => {
    const allRequiredIds = customFieldsDefaults
      ?.filter((field: SectionCustomAttributeDefaults) => {
        return field.isRequired;
      })
      ?.map((field: SectionCustomAttributeDefaults) => {
        return field.attributeId;
      });
    fields.forEach((field: SectionAttributeFormData, index: number) => {
      const isRequiredByDefault = allRequiredIds?.includes(
        field.customAttributeDefinitionId
      );
      if (isRequiredByDefault) {
        setValue(`fields[${index}].isRequired`, isRequiredByDefault);
      }
    });

    console.log("fields", fields);
  }, [fields, setValue, customFieldsDefaults]);

  return (
    <div className="section-attributes-panel">
      <div className="card">
        <div className="card-header">
          <h3>
            {sectionName}
            {` `}
            {blockNavigation && <i>{`(Unsaved)`}</i>}
          </h3>
        </div>
        <div className="card-body">
          {fields.length > 0 ? (
            fields.map((field: SectionAttributeFormData, index: number) => (
              <div key={field.id} className="attribute-management-container">
                <div className="attribute-tile-container">
                  <MotifCard
                    className="attribute-tile"
                    orientation="horizontal"
                    variant="card-border"
                  >
                    <MotifCardHeader>
                      <span className="motif-body1-default-bold">
                        {field.displayName}
                      </span>
                      <div className="attribute-tile-actions">
                        <div className="action-icons">
                          <MotifIconButton
                            onClick={() => remove(index)}
                            type="button"
                          >
                            <MotifIcon iconFunction={IconoirTrash} />
                          </MotifIconButton>
                        </div>
                        <div className="arrow-icons">
                          <MotifIconButton
                            onClick={() => move(index, index - 1)}
                            type="button"
                            size="small"
                            disabled={index === 0}
                          >
                            <MotifIcon iconFunction={IconoirArrowUp} />
                          </MotifIconButton>

                          <MotifIconButton
                            onClick={() => move(index, index + 1)}
                            type="button"
                            size="small"
                            disabled={index + 1 === fields?.length}
                          >
                            <MotifIcon iconFunction={IconoirArrowDown} />
                          </MotifIconButton>
                        </div>
                      </div>
                    </MotifCardHeader>
                    <MotifCardBody>
                      <div className="attribute-tile-body">
                        {field.displayName === "Custom Display Field" ? (
                          <CustomDisplayField
                            index={index}
                            control={control}
                            field={field}
                            setValue={setValue}
                          />
                        ) : (
                          <>
                            <div className="motif-row">
                              <div className="motif-col-md-3">
                                <div className="form-field ">
                                  <ControlInputBox
                                    name={`fields.[${index}].displayName`}
                                    control={control}
                                    required={false}
                                    isTextArea={false}
                                    label={t(
                                      "pages.portalConfig.labels.displayName"
                                    )}
                                    showLabel={true}
                                  />
                                </div>
                              </div>
                              <div className="motif-col-md-3">
                                <div className="form-field">
                                  <ControlInputBox
                                    name={`fields.[${index}].displayNameFrench`}
                                    control={control}
                                    isTextArea={false}
                                    label={t(
                                      "pages.portalConfig.labels.displayNameFR"
                                    )}
                                    showLabel={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="motif-row">
                              <div className="motif-col-md-3 ">
                                <div className="form-field ">
                                  <ControlInputBox
                                    name={`fields.[${index}].tooltip`}
                                    control={control}
                                    required={false}
                                    isTextArea={false}
                                    label={t(
                                      "pages.portalConfig.labels.tooltip"
                                    )}
                                    showLabel={true}
                                  />
                                </div>
                              </div>
                              <div className="motif-col-md-3">
                                <div className="form-field">
                                  <ControlInputBox
                                    name={`fields.[${index}].tooltipFrench`}
                                    control={control}
                                    isTextArea={false}
                                    label={t(
                                      "pages.portalConfig.labels.tooltipFR"
                                    )}
                                    showLabel={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="motif-row">
                              <div className="motif-col-md-6">
                                <ControlCheckBox
                                  name={`fields[${index}].isRequired`}
                                  control={control}
                                  checked={field.isRequired}
                                  value={field.isRequired}
                                  required={false}
                                  label={t(
                                    "pages.portalConfig.labels.required"
                                  )}
                                  disabled={
                                    customFieldsDefaults?.find(
                                      (
                                        fieldDefault: SectionCustomAttributeDefaults
                                      ) => {
                                        return (
                                          fieldDefault.attributeId ===
                                          field.customAttributeDefinitionId
                                        );
                                      }
                                    )?.isRequired
                                  }
                                  onChange={() => {
                                    setValue(
                                      `fields[${index}].isRequired`,
                                      !field.isRequired
                                    );
                                  }}
                                  setIsFrenchSelected={() => {}}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </MotifCardBody>
                  </MotifCard>
                </div>
              </div>
            ))
          ) : (
            <div className="empty-message">
              Please add a field from the Fields panel on the left
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionAttributes;
