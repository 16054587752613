export const getClassColumnDefs = (isDraft: boolean) => [
	{
		cellRenderer: "sortingCellRenderers",
		cellRendererParams: {
			isDraft: isDraft,
		},
		field: "action",
		headerName: "",
		flex: 1,
		sortable: false,
	},
	{
		field: "name",
		headerName: "Class Name",
		flex: 1,
	},
	{
		field: "ruleCount",
		headerName: "Rule Count",
		flex: 1,
		sortable: false,
	},
	{
		field: "isDefault",
		headerName: "Default",
		flex: 1,
		sortable: false,
		cellRenderer: "agCheckboxCellRenderer",
	},
	{
		field: "description",
		flex: 1,
		sortable: false,
		headerName: "Description",
	},
	{
		cellRenderer: "ruleLinkCellRenderers",
		field: "action",
		headerName: "",
		flex: 1,
		sortable: false,
		hide: !isDraft,
	},
	{
		cellRenderer: "actionCellRenderers",
		field: "action",
		headerName: "",
		flex: 1,
		sortable: false,
		hide: !isDraft,
	},
];
