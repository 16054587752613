const voting = {
  tableTitle: "Ballot",
  tableDescription: "Descriptive text that describes your page or content.",
  createNewBallot: "Create New Ballot",
  createBallotTitle: "Create a new Ballot",
  viewVersion:"View Version",

  creditorVotes:{
    manualRecording:{
        title:"Record creditor votes",
        creditorSearch:"Search for a Creditor",
        vote:"Vote",
        yes:"Yes",
        no:"No",
        proxy:"Proxy",
         },
    bulkVoting:{
title:"Import Creditor Votes",
description:"Download template to create the import file",
uploadLabel:"Upload Import File",
uploadDescription:
"The accepted document format is XLSX only. The maximum size per file is 250 MB.",
    },
    moreOptions:{
label1:"Manual Recording",
label2:"Bulk Import"
    },
export:"Export",
tableTitle: "Creditor Votes",
    tableDescription: "Descriptive text that describes your page or content.",
columnDetails:{
    column1: "Creditor Name",

    column2: "Creditor ID",

    column3: "Proxy Name",

    column4: "Vote Result",

    column5: "Eligible",
    column6:"Latest Creditor Position Amount ({{unitName}})",
    column7:"Latest Debtor Position Amount ({{unitName}})",
    column8:"Resolved Amount ({{unitName}})"

}
  },
  bannerDetails:{
ballotName:"Ballot Name",
ballotStatus:"Ballot Status",
class:"Class",
meeting:"Meeting",
  },
  ballotResult: {
    tableTitle: "Ballot Result",
    tableDescription: "Descriptive text that describes your page or content.",
    columnDetails:{
        column1: "Votes",

        column2: "Votes Count",
    
        column3: "Votes Count %",
    
        column4: "Claim Value ({{unitName}})",
    
        column5: "Claim Value %",
    
    }
  },
  validationMessages: {
    ballotNameRequired: "Ballot Name is required",
    ballotNameFrenchRequired: "Ballot French Name is required",
    schemeRequired: "Scheme is required",
    classRequired: "Class is required",
    meetingRequired: "Meeting is required",
    statusRequired: "Status is required",
  },
  formLabels: {
    nameEnglish: "Ballot Name (English)",
    nameFrench: "Ballot Name (French)",
    attributeDescriptionEnglish: "Attribute Description (English)",
    attributeDescriptionFrench: "Attribute Description (French)",
    scheme: "Scheme",
    allowedClass: "Allowed Class",
    export: "Export",
    meeting: "Meeting",
    proxy: "Attendance By Proxy Required",
    ballotStatus: "BallotStatus",
    votedYes: "% Who Voted Yes",
    claimValue: "% Claim Value",
    conditionForSuccess: "Condition For Success",
  },
  ballotStatusOptions: {
    label1: "Select Type",
    label2: "Open",
    label3: "Locked",
  },
  tabPannelDetails: {
    summary: "Summary",
    creditorVotes: "Creditor Votes",
  },
  tableColumnsDetails: {
    column1: "Ballot Name",
    column2: "Class",
    column3: "Meeting",
    column4: "Ballot status",
    column5: "% Voted Yes",
    column6: "% Claim Value",
    column7: "Attendance By Proxy Required",
  },
};

export default voting;
