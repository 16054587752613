import { useState, useEffect, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  MotifFormField,
  MotifLabel,
  MotifSelect,
  MotifOption,
  MotifInput,
  MotifButton,
} from "@ey-xd/motif-react";
import { setShowBasicSeach } from "../../claimsSlice";
import "../style.css";
import {
  ClaimCategory,
  ClaimStage,
  ClaimStatus,
  FormValues,
  ClaimTask,
  OverDue,
  Source,
} from "./type";
import { useLazyGetStagesQuery } from "@/services/stages/stagesReducer";
import { useLazyGetClaimCategoryNatureOfClaimQuery } from "@/services/claimDetail/claimDetailReducer";
import { useCase } from "@/features/case/use-case";
import FormattedDatePicker from "@/components/FormattedDatePicker/FormattedDatePicker";
import { useGetDebtorQuickSearchApiDataQuery } from "@/services/claimSummary/caseSummaryReducer";
import ControlMultiSelect from "@/components/Form/MultiSelectBox/ControlMultiSelect";
import { useForm } from "react-hook-form";
import { NATURE_OF_CLAIM_FIELD } from "@/pages/CaseDetail/Claim/ClaimDetails/Constants";
import SearchInput from "@/components/SearchInput/SearchInput";

export default function BasicSearch({
  onApply,
}: {
  onApply: (params: any) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState<FormValues>({
    currentStage: "",
    currentActionStatus: "",
    debtorName: "",
    claimReferenceNumber: "",
    creditorName: "",
    category: "",
    overdue: "",
    claimUpdatedDateStart: undefined,
    claimUpdatedDateEnd: undefined,
    source: "",
    task: "",
    natureOfClaim: [],
  });

  const caseData = useCase();
  const { caseId } = caseData;
  const [currentStageOptions, setCurrentStageOptions] = useState<ClaimStage[]>(
    []
  );
  const [currentActionStatusOptions, setCurrentActionStatusOptions] = useState<
    ClaimStatus[]
  >([]);
  const [categoryOptions, setCategoryOptions] = useState<ClaimCategory[]>([]);
  const [natureOfClaimOptions, setNatureOfClaimOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const { control, getValues, setValue } = useForm<FormValues>();

  const defaultTaskOptions: ClaimTask[] = [
    { id: "0", name: "Open" },
    { id: "1", name: "Closed" },
    { id: "2", name: "Complete" },
  ];
  const [taskOptions] = useState<ClaimTask[]>(defaultTaskOptions);
  const [getStagesQuery] = useLazyGetStagesQuery();
  const [getCategoryAndNatureOfClaimQuery] =
    useLazyGetClaimCategoryNatureOfClaimQuery();

  const { data }: any = useGetDebtorQuickSearchApiDataQuery({
    caseId: caseId,
  });
  const debtorNames =
    (data && data?.map((items: { name: string; id: any }) => items.name)) || [];
  useEffect(() => {
    getStagesQuery(caseId).then((response) => {
      setCurrentStageOptions(response.data || []);
    });

    getCategoryAndNatureOfClaimQuery(caseId).then((response) => {
      if (response.data) {
        setCategoryOptions(response.data.categories || []);
        setNatureOfClaimOptions([
          ...response.data.natureOfClaim?.map((noc: string) => ({
            label: noc,
            value: noc,
          })),
        ]);
      }
    });

    // eslint-disable-next-line
  }, [caseId]);

  useEffect(() => {
    if (currentStageOptions && currentStageOptions.length > 0) {
      setCurrentActionStatusOptions(currentStageOptions[0].claimStatuses || []);
    } else {
      setCurrentActionStatusOptions([]);
    }
  }, [currentStageOptions]);

  const handleChange = (name: string, value: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: prevValues[name] === value ? undefined : value,
      ...(name === "currentStage" ? { currentActionStatus: "" } : {}),
    }));

    if (name === "currentStage") {
      setCurrentActionStatusOptions([]);

      const selectedStage = currentStageOptions.find(
        (stage) => stage.id === value
      );
      setCurrentActionStatusOptions(selectedStage?.claimStatuses || []);
    }
  };

  const handleNOCChange = (selectedValue: string[]) => {
    let selectedOptions = [...selectedValue];

    const hasSecured = selectedOptions
      .map((value) => value.toLowerCase())
      .includes("secured");
    const hasUnsecured = selectedOptions
      .map((value) => value.toLowerCase())
      .includes("unsecured");

    if (hasSecured && hasUnsecured) {
      const lastValue = selectedOptions[selectedOptions.length - 1];

      selectedOptions = selectedOptions.filter((value) => {
        if (value === "Secured" && lastValue === "Unsecured") {
          return false;
        } else if (value === "Unsecured" && lastValue === "Secured") {
          return false;
        }
        return true;
      });
    }
    //@ts-ignore
    setValue(`natureOfClaim`, selectedOptions, { shouldValidate: true });

    setFormValues((prevValues) => ({
      ...prevValues,
      natureOfClaim: selectedOptions,
    }));
  };

  const handleSubmit = async () => {
    const {
      currentStage,
      currentActionStatus,
      debtorName,
      claimReferenceNumber,
      creditorName,
      claimUpdatedDateStart,
      claimUpdatedDateEnd,
      source,
      task,
      overdue,
      category,
    } = formValues;

    const params: any = {
      CaseId: caseId,
      ClaimReference: claimReferenceNumber || "",
      CreditorName: creditorName || "",
      DebtorName: debtorName || "",
      ClaimStageId: currentStage || "",
      ClaimStatusId: currentActionStatus || "",
      TaskStatus: task || "",
      NatureOfClaim: formValues.natureOfClaim || [],
      IsOverDue: overdue ? (overdue === OverDue.Yes ? true : false) : "",
      IsFromPortal: source ? (source === Source.External ? true : false) : "",
      CategoryId: category || "",
      ClaimUpdatedDateStart: "",
      ClaimUpdatedDateEnd: "",
    };

    if (claimUpdatedDateStart) {
      params.ClaimUpdatedDateStart = claimUpdatedDateStart
        .toISOString()
        .substring(0, 10);
    }

    if (claimUpdatedDateEnd) {
      params.ClaimUpdatedDateEnd = claimUpdatedDateEnd
        .toISOString()
        .substring(0, 10);
    }

    onApply(params);
    dispatch(setShowBasicSeach(false));
  };
  function getDebtorName(props: any) {
    const debtorID = data?.find((items: { name: string; id: any }) => {
      if (items.name === props) return true;
      else return false;
    });

    handleChange("debtorName", debtorID && debtorID?.name);
  }

  function handleGetDebtorName(eventData: ChangeEvent<HTMLInputElement>) {
    handleChange("debtorName", eventData.target.value);
  }

  return (
    <div className="claims-basic-search-container">
      <div className="motif-row">
        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifLabel>
            {t("pages.claims.basicSearch.claimReferenceNumber")}
          </MotifLabel>
          <MotifFormField>
            <MotifInput
              //@ts-ignore
              onChange={(e) =>
                handleChange("claimReferenceNumber", e.target.value)
              }
              value={formValues.claimReferenceNumber}
            />
          </MotifFormField>
        </div>

        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifLabel>{t("pages.claims.basicSearch.creditorName")}</MotifLabel>
          <MotifFormField>
            <MotifInput
              //@ts-ignore
              onChange={(e) => handleChange("creditorName", e.target.value)}
              value={formValues.creditorName}
            />
          </MotifFormField>
        </div>

        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifLabel>{t("pages.claims.basicSearch.debtorName")}</MotifLabel>
          <MotifFormField>
            <SearchInput
              aria-label="Search"
              className="debtorSearch"
              id="states-search"
              items={debtorNames}
              onBlur={function Ua() {}}
              onChange={(e: any) => handleGetDebtorName(e)}
              onSelect={(e: any) => getDebtorName(e)}
              placeholder="Search for..."
              value={formValues.debtorName}
              disabled={false}
            />
          </MotifFormField>
        </div>

        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifFormField>
            <MotifLabel>
              {t("pages.claims.basicSearch.currentStage")}
            </MotifLabel>
            <MotifSelect
              onChange={(value) => handleChange("currentStage", value)}
              value={formValues.currentStage}
              visibleOptions={3}
            >
              {currentStageOptions.map((option) => (
                <MotifOption key={option.id} value={option.id}>
                  {option.name}
                </MotifOption>
              ))}
            </MotifSelect>
          </MotifFormField>
        </div>

        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifFormField>
            <MotifLabel>
              {t("pages.claims.basicSearch.currentActionStatus")}
            </MotifLabel>
            <MotifSelect
              onChange={(value) => handleChange("currentActionStatus", value)}
              value={formValues.currentActionStatus}
              visibleOptions={3}
              disabled={
                !formValues.currentStage ||
                currentActionStatusOptions.length === 0
              }
            >
              {currentActionStatusOptions.map((option) => (
                <MotifOption key={option.id} value={option.id}>
                  {option.name}
                </MotifOption>
              ))}
            </MotifSelect>
          </MotifFormField>
        </div>

        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifFormField>
            <MotifLabel>{t("pages.claims.basicSearch.category")}</MotifLabel>
            <MotifSelect
              onChange={(value) => handleChange("category", value)}
              value={formValues.category}
              visibleOptions={3}
            >
              {categoryOptions.map((option) => (
                <MotifOption
                  key={option.claimCategoryId}
                  value={option.claimCategoryId}
                >
                  {option.claimCategoryName}
                </MotifOption>
              ))}
            </MotifSelect>
          </MotifFormField>
        </div>

        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifFormField>
            <MotifLabel>{t("pages.claims.basicSearch.overdue")}</MotifLabel>
            <MotifSelect
              onChange={(value) => handleChange("overdue", value)}
              value={formValues.overdue}
              visibleOptions={3}
            >
              <MotifOption value={OverDue.Yes}>
                {t("pages.claims.basicSearch.yes")}
              </MotifOption>
              <MotifOption value={OverDue.No}>
                {t("pages.claims.basicSearch.no")}
              </MotifOption>
            </MotifSelect>
          </MotifFormField>
        </div>

        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifFormField>
            <MotifLabel>
              {t("pages.claims.basicSearch.claimUpdatedDate")}
            </MotifLabel>
            <FormattedDatePicker
              onChange={(value) => {
                if (value && Array.isArray(value)) {
                  handleChange("claimUpdatedDateStart", value[0]);
                  handleChange("claimUpdatedDateEnd", value[1]);
                }
              }}
              todayMark={true}
              range={true}
            />
          </MotifFormField>
        </div>

        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifFormField>
            <MotifLabel>{t("pages.claims.basicSearch.source")}</MotifLabel>
            <MotifSelect
              onChange={(value) => handleChange("source", value)}
              value={formValues.source}
            >
              <MotifOption value={Source.External}>
                {t("pages.claims.basicSearch.external")}
              </MotifOption>
              <MotifOption value={Source.Internal}>
                {t("pages.claims.basicSearch.internal")}
              </MotifOption>
            </MotifSelect>
          </MotifFormField>
        </div>

        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifFormField>
            <MotifLabel>{t("pages.claims.basicSearch.taskStatus")}</MotifLabel>
            <MotifSelect
              onChange={(value) => handleChange("task", value)}
              value={formValues.task}
            >
              {taskOptions.map((option) => (
                <MotifOption key={option.id} value={option.id}>
                  {option.name}
                </MotifOption>
              ))}
            </MotifSelect>
          </MotifFormField>
        </div>

        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <ControlMultiSelect
            name={NATURE_OF_CLAIM_FIELD}
            control={control}
            showLabel={true}
            label={"pages.claims.basicSearch.natureOfClaim"}
            options={natureOfClaimOptions}
            setValue={setValue}
            getValues={getValues}
            value={formValues.natureOfClaim}
            multiple={true}
            // @ts-ignore
            onChange={(selectedValue) => handleNOCChange(selectedValue)}
          />
        </div>
      </div>
      <div className="basic-search-buttons">
        <MotifButton
          onClick={() => {
            dispatch(setShowBasicSeach(false));
          }}
          size="small"
          variant="secondary"
        >
          {t("pages.claims.basicSearch.cancelButton")}
        </MotifButton>

        <MotifButton onClick={handleSubmit} size="small" variant="primary">
          {t("pages.claims.basicSearch.applyButton")}
        </MotifButton>
      </div>
    </div>
  );
}