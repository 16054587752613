import { ccwpApi } from "@/services";
import { CREDITOR_CONTACT } from "@/services/constant/apiPath";

export const creditorContactApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    listCreditorContact: builder.query<any, string>({
      query: (creditorId) => ({
        url: `${CREDITOR_CONTACT}/${creditorId}`,
        method: "GET",
      }),
      providesTags: ["CreditorContact"],
    }),
  }),
});

export const { useListCreditorContactQuery } = creditorContactApi;
