import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	MotifButton,
	MotifProgressLoader,
	MotifErrorMessage,
} from "@ey-xd/motif-react";
import Tree from "@/components/Tree/Tree";
import { useForm, FormProvider } from "react-hook-form";
import {
	useGetClaimListAttributeQuery,
} from "@/services/advanceSearch/advanceSearchReducer";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { processTreeRows } from "@/utility/common";

type AdvanceSearchData = Array<{ [key: string]: any }>;

interface AdvanceSearchProps {
	setAdvanceSearchData?: (data: AdvanceSearchData) => void;
	setShowAdvanceSearch: (show: boolean) => void;
	setIsAdvanceSearchActive: (show: boolean) => void;
	setAdvanceSearchPayload :(payload:any)=>void;
	handleReset:()=>void
}

const AdvanceSearch = ({
	setShowAdvanceSearch,
	setIsAdvanceSearchActive,
	setAdvanceSearchPayload,
	handleReset
}: AdvanceSearchProps) => {
	const { t } = useTranslation();
	const { caseId } = useParams();
	const [isApplyLoading, setIsApplyLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [attributes, setAttributes] = useState([]);

	const methods = useForm({
		defaultValues: {
			logicalOperator: "0",
			treeRows: [{ attribute: "", operator: "", value: "", type: "row" }],
		},
	});
	const {
		handleSubmit,
		formState: { errors },
	} = methods;
	const errorList = Object.keys(errors);
	const {
    data: attributesData,
    isLoading,
    error,
  } = useGetClaimListAttributeQuery(caseId, { skip: !caseId });

	useEffect(() => {
		if (attributesData) {
			setAttributes(attributesData);
		}
	}, [attributesData]);

	if (isLoading) {
		return <MotifProgressLoader show variant="default" />;
	}
	if (error) {
		return <MotifErrorMessage>Data could not be loaded</MotifErrorMessage>;
	}
	const onSubmit = async (data: any) => {
		const { rules, groups } = processTreeRows(data.treeRows, caseId);
		const payload = {
			CaseId: caseId,
			rules,
			groups: groups.length > 0 ? groups : null,
			LogicalOperator: parseInt(data.logicalOperator),
			PageNumber: 1,
			PageSize: 10000,
		};
		setErrorMessage("");
		setIsApplyLoading(true);
		setAdvanceSearchPayload(payload)
		setShowAdvanceSearch(false);
		setIsAdvanceSearchActive(true);
		if(handleReset){
			handleReset()
		}
	};

	return (
		<div className="claims-basic-search-container">
			{errorMessage && (
				<NotificationGrid show={true} message={errorMessage} type="error" />
			)}
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Tree attributes={attributes} />
					<div className="error-container">
						<MotifErrorMessage>
							{errorList.length
								? t("pages.configuration.scheme.rule.validationError")
								: ""}
						</MotifErrorMessage>
					</div>
					<div className="basic-search-buttons">
						<MotifButton
							size="small"
							type="button"
							variant="secondary"
							onClick={() => setShowAdvanceSearch(false)}
						>
							{t("pages.claims.advancedSearch.cancel")}
						</MotifButton>

						<MotifButton
							size="small"
							variant="primary"
							type={isApplyLoading ? "loader" : "submit"}
							onClick={handleSubmit(onSubmit)}
						>
							{t("pages.claims.advancedSearch.apply")}
						</MotifButton>
					</div>
				</form>
			</FormProvider>
		</div>
	);
};
export default AdvanceSearch;
