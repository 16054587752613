import { CreditorsContacts } from "@/features/claimsummary/summary/component/creditorsContacts";
import { ClaimItems } from "@/features/claimsummary/summary/component/claimItems";
import { Documents } from "@/features/claimsummary/summary/component/documents";
import { Tasks } from "@/features/claimsummary/summary/component/task";
import { CommentHistory } from "@/features/claimsummary/summary/component/commentHistory";
import { ClaimSummary } from "@/features/claimsummary/summary/component/claimSummary";
import { ENTITYTYPE } from "@/appConstants";
import { useParams } from "react-router-dom";

export const Summary = ({setEditPage, resolvedStatus}:any) => {
  const parameter = useParams();
  return (
    <>
      <ClaimSummary setEditPage={setEditPage}/>
      <CreditorsContacts />
      <ClaimItems resolvedStatus={resolvedStatus}/>
      <Documents />
      <Tasks />
      <CommentHistory entityType = {ENTITYTYPE.claim} dataId={parameter?.claimId}/>
    </>
  );
};
