import { useParams } from "react-router-dom";
import {
  useGetCalculationStepDataQuery,
  useGetListCalculationStepQuery,
} from "./calculationStepReducer";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import CalculationStepHeaderEdit from "./CalculationStepHeaderEdit";
import { useState } from "react";
import { renderAccountList } from "./Form/calculationSummaryHelper";
import { CalculationStepViewSummary } from "./CalculationStepViewSummary";
import { useGetStepCalculationDataByIdApiDataQuery } from "@/services/distribution/distributionReducers";
import { useTranslation } from "react-i18next";

const CalculationStepSummary = () => {
  const { caseId, distributionId, stepId } = useParams<{
    caseId: string;
    distributionId: string;
    stepId: string;
  }>();
  const {t}=useTranslation()
  const [editForm, setEditForm] = useState(false);
  const { data, isLoading, error } = useGetListCalculationStepQuery({
    distributionId: distributionId,
  },{ skip: !distributionId });

  const { data: accountData, isLoading: isLoadingAccount } =
    useGetCalculationStepDataQuery({ caseId: caseId });
  const { data: dataByStepId, refetch } =
    useGetStepCalculationDataByIdApiDataQuery(stepId);
let caluclationStepData = data && data?.results?data?.results:[];
  const selectedStep = caluclationStepData?.find((step: any) => step.id === stepId);
  const accountList = renderAccountList(accountData?accountData:[]);

  if (isLoading || isLoadingAccount) {
    return <div>{t("global.globalNotifications.loadingMessage")}</div>;
  }
  if (error) {
    return <div> {t("global.globalNotifications.unExpectedError")}</div>;
  }
  const breadcrumbItems = [
    {
      label: "Back",
      elementKey: "Back",
      text: t("global.globalActions.back"),
      url: `/case/${caseId}/distribution/${distributionId}?activeTab=1`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];
  const summaryData = {
    stepName: dataByStepId?.stepName || "",
    account: dataByStepId?.accountId || "",
    subAccount: dataByStepId?.subAccountId || "",
    description: dataByStepId?.description || "",
    payables: dataByStepId?.isMemo === true ? "1" : "0",
    calculationStepId: dataByStepId?.id || "",
    comment: dataByStepId?.comment,
    calculationGroupBy: dataByStepId?.calculationGroupBy,
    payeeTypeId: dataByStepId?.payeeTypeId,
    isCapZeroLimit: dataByStepId?.isCapZeroLimit,
    isFloorZeroLimit: dataByStepId?.isFloorZeroLimit,
    limitAmountOrder: dataByStepId?.limitAmountOrder,
  };
  return (
    <>
      <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
      <CalculationStepHeaderEdit
        create={false}
        selectedStep={selectedStep}
        distributionId={distributionId}
      />
      <CalculationStepViewSummary
        dataByStepId={dataByStepId}
        summaryData={summaryData}
        setEditForm={setEditForm}
        accountList={accountList}
        caseId={caseId || ""}
        distributionId={distributionId || ""}
        stepId={stepId || ""}
        editForm={editForm}
        refetch={refetch}
      />
    </>
  );
};

export default CalculationStepSummary;
