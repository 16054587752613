
import { formatAmountUnitDecimalPlace } from "@/utility/common";
import { useTranslation } from "react-i18next";



export const useBallotResultDef=(caseDataDetails:any) => 
  {
    const {t}=useTranslation()
  
  const CustomHeader = () => {
    const { defaultUnit } = caseDataDetails;
         return `${defaultUnit?.name}`;
  };
  
  const amountCellRenderer = (field: string) => (params: any) => {
    const { defaultUnit } = caseDataDetails;
    const decimalPlace =
      field === "claim"
        ?  defaultUnit?.decimalPlace
        : 2;
    const amount = params.data[field];
    return (
      formatAmountUnitDecimalPlace(amount, decimalPlace) +
      `${field !== "claim" ? "%" : ""}`
    );
  };
   return [
  {
    field: "voteType",
    headerName: t("pages.voting.ballotResult.columnDetails.column1"),
    flex: 1,
    sortable: true,
    unSortIcon: true,
  },
  {
    field: "votesCount",
    headerName:t("pages.voting.ballotResult.columnDetails.column2"),
    flex: 1,
    sortable: true,
    unSortIcon: true,
  },
  {
    field: "votesCountPercentage",
    headerName: t("pages.voting.ballotResult.columnDetails.column3"),
    flex: 1,
    cellRenderer: amountCellRenderer("votesCountPercentage"),
  },
  {
    field: "claim",
    headerName: t("pages.voting.ballotResult.columnDetails.column4",{unitName:CustomHeader()}),
    flex: 1,
    headerComponentParams: { params: "Claim Value" },
    cellRenderer: amountCellRenderer("claim"),
  },
  {
    field: "claimPercentage",
    headerName:t("pages.voting.ballotResult.columnDetails.column5"),
    flex: 1,
    cellRenderer: amountCellRenderer("claimPercentage"),
  },
]};
