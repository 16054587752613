import React from "react";
import { Control, Controller, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputBoxNumber from "./InputBoxNumber";

type ControlInputBoxNumberWithRangeProps = {
    control: Control<any>;
    name: string;
    label: string;
    showLabel?: boolean;
    required?: boolean;
    min?: number;
    max?: number;
    digitAfterDecimal?: number;
    onChangeAmount?: (value: string) => void;
    readonly?: boolean;
};

export default function ControlInputBoxNumberWithRange({
    control,
    name,
    label,
    showLabel = true,
    required = false,
    min,
    max,
    digitAfterDecimal = 0,
    onChangeAmount,
    readonly,
}: ControlInputBoxNumberWithRangeProps) {
    const { t } = useTranslation();

    const validateRange = (value: string) => {
        const numericValue = parseFloat(value);
        if (isNaN(numericValue)) return t("global.globalNotifications.invalidNumber");
        if (min !== undefined && numericValue < min) return t("global.globalNotifications.minNumber",{minValue:min});
        if (max !== undefined && numericValue > max) return t("global.globalNotifications.maxNumber",{maxValue:max});
        return true;
    };

    const { field } = useController({
        name,
        control,
        rules: {
            required: required ? t("global.globalNotifications.filedRequired") : undefined,
            validate: validateRange,
        },
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;

        if (digitAfterDecimal === 0) {
            value = value.replace(/\D/g, ""); 
        } else {
            
            const regex = new RegExp(`^\\d+(\\.\\d{0,${digitAfterDecimal}})?$`);
            if (!regex.test(value)) return; 
        }

        field.onChange(value); 
    };

    return (
        <>
            {showLabel && (
                <div className="formFieldLabel">
                    <label htmlFor={`input-${name}`} aria-label={label}>
                        {t(label)}
                        {required && <span className="requiredSymbol">*</span>}
                    </label>
                </div>
            )}
            <Controller
                name={field.name}
                control={control}
                render={({ field: { onChange, onBlur, value, ...rest }, fieldState: { error } }) => (
                    <>
                        <InputBoxNumber
                            id={`InputBoxNumber-${field.name}`}
                            required={required}
                            value={value || ""}
                            //@ts-ignore
                            onChange={handleInputChange}
                            //@ts-ignore
                            onBlur={(e) => {
                                onBlur(); 
                                validateRange(value); 
                            }}
                            digitAfterDecimal={digitAfterDecimal}
                            onChangeAmount={onChangeAmount}
                            readonly={readonly}
                            {...rest}
                        />
                    </>
                )}
            />
        </>
    );
}
