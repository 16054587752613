export enum SetupStage {
  Create = 0,
  Review = 1,
}

export enum EntityType {
  Creditor = 1,
  Claim = 2,
  ClaimItem = 3,
}

export enum AttributeType {
  FreeText = 1,
  Number = 2,
  Boolean = 3,
  Date = 4,
  SingleSelect = 5,
  MutliSelect = 6,
  Currency = 7,
  Document = 8,
}

interface IPicklist {
  id?: string;
  name: string;
  nameFrench: string;
  displayOrder: number;
}

export interface IAttributeState {
  id?: string;
  attributeType?: AttributeType;
  entityType?: number;
  name?: string;
  nameFrench?: string;
  description?: string;
  descriptionFrench?: string;
  isRequired: boolean;
  isDisplayOnCreate: boolean;
  picklistDefinitions: IPicklist[];
  metaDataJSON: {
    [key: string]: any;
  };
}
