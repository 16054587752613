import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifButton,
  MotifButtonGroup,
  MotifChip,
  MotifDropdownItem,
  MotifDropdownPortal,
  MotifFormField,
  MotifIcon,
} from "@ey-xd/motif-react";
import { IconoirNavArrowDown } from "@ey-xd/motif-icon";
import SearchInput from "@/components/SearchInput/SearchInput";

interface TitleComplementProps {
  setShowBasicSearch: any;
  showBasicSearch: any;
  clearBasicSearch: any;
  clearSearch: any;
  setClearSearch: any;
  onSearch: (searchValue: string) => void;
  handleQuickSearchClear: () => void;
  searchQuery: string;
  isPreviewScheme: boolean;
  handlePreviewSearch: () => void;
}

export default function TitleComplement({
  setShowBasicSearch,
  showBasicSearch,
  clearBasicSearch,
  clearSearch,
  setClearSearch,
  onSearch,
  handleQuickSearchClear,
  searchQuery,
  isPreviewScheme,
  handlePreviewSearch,
}: TitleComplementProps) {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearchValue(event.target.value);
  };

  function handleOnSearch() {
    onSearch(searchValue);
  }

  return (
    <>
      <div className={"show-quick-search"}>
        <MotifFormField>
          <div
            style={
              !showBasicSearch
                ? { visibility: "visible" }
                : { visibility: "hidden" }
            }
          >
            {!showBasicSearch ? (
              <SearchInput
                aria-label="Search"
                onChange={handleSearchChange}
                placeholder={t("Search")}
                onFocusShow={false}
                value={searchQuery}
                onEnter={handleOnSearch}
                onClear={handleQuickSearchClear}
              />
            ) : (
              <></>
            )}
          </div>
          <div
            style={
              !showBasicSearch
                ? { visibility: "visible" }
                : { visibility: "hidden" }
            }
          >
            <div className="preview-button">
              <MotifButton
                variant="primary"
                onClick={handlePreviewSearch}
                disabled={!isPreviewScheme}
              >
                Preview Scheme
              </MotifButton>
            </div>
          </div>

          <MotifButtonGroup>
            <MotifButton variant="secondary" onClick={handleOnSearch}>
              {t("Filter")}
            </MotifButton>
            <MotifDropdownPortal
              open={showMenu}
              hideArrow={true}
              handleClickOutside={() => setShowMenu(false)}
              trigger={
                <MotifButton
                  type="button"
                  onClick={() => setShowMenu(!showMenu)}
                  aria-label="Dropdown button"
                  aria-haspopup="true"
                  variant="secondary"
                >
                  <MotifIcon
                    aria-label="Dropdown icon"
                    fill="none"
                    iconFunction={IconoirNavArrowDown}
                  />
                </MotifButton>
              }
            >
              <MotifDropdownItem
                onClick={() => {
                  handleQuickSearchClear();
                  setShowBasicSearch(true);
                }}
              >
                {t("Basic search")}
              </MotifDropdownItem>
            </MotifDropdownPortal>
          </MotifButtonGroup>
          {clearSearch ? (
            <MotifChip
              onClick={() => {
                clearBasicSearch();
                setClearSearch(false);
              }}
              title="Clear Search"
              variant="monochrome-dark"
            >
              Clear Search
            </MotifChip>
          ) : (
            <></>
          )}
        </MotifFormField>
      </div>
    </>
  );
}
