import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  caseId: null,
  form: {},
  sectionId: null,
  sections: [],
  fields: [],
  customFieldsDefaults: [],
  notifications: {
    info: null,
    success: null,
    error: null,
  },
};

const formSetup = createSlice({
  name: "formSetup",
  initialState,
  reducers: {
    setForm: (state: any, action: PayloadAction<any>) => {
      state = action.payload;
      return state;
    },
    updateForm: (state: any, action: PayloadAction<any>) => {
      const { key, value } = action.payload;
      state[key] = value;
      return state;
    },
    resetForm: (state: any) => {
      state = initialState;
      return state;
    },
    setNotification: (state: any, action: PayloadAction<any>) => {
      const { type, notifications } = action.payload;
      state.notifications[type] = notifications;
      return state;
    },
    resetNotification: (state: any) => {
      state.notifications = {
        info: null,
        success: null,
        error: null,
      };
      return state;
    },
    updateSectionOrder: (state: any, action: PayloadAction<any>) => {
      const { sections } = state;
      const { sectionId, targetIndex } = action.payload;
      const section = sections.find((section: any) => section.id === sectionId);
      const currentIndex = sections.indexOf(section);
      sections.splice(currentIndex, 1);
      sections.splice(targetIndex, 0, section);
      return state;
    },
  },
});

export const {
  setForm,
  resetForm,
  updateForm,
  updateSectionOrder,
  setNotification,
  resetNotification,
} = formSetup.actions;
export default formSetup.reducer;
