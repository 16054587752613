import { useParams } from "react-router-dom";
import { convertDateTimeinDate,formatAmountUnitDecimalPlace } from "@/utility/common";
import { useGetUnitByCaseIdDataApiDataQuery } from "@/services/claimSummary/caseSummaryReducer";
import "./attributeHistory.scss";
import { useTranslation } from "react-i18next";


const useDefaultUnitDetails = () => {
  const { caseId } = useParams<{
    caseId: string;
  }>();
  const { data : defaultUnitData,} = useGetUnitByCaseIdDataApiDataQuery(caseId);
  const decimalPlace = defaultUnitData?.decimalPlace;
  const defaultUnitName = defaultUnitData?.name;
  return { decimalPlace,defaultUnitName };
};

const amountCellRenderer = (baseField: string,enteredField: string,unitField:string,decimalField:string) => (params: any) => {
  const { decimalPlace,defaultUnitName } = useDefaultUnitDetails();
  const baseAmount = params.data[baseField];
  const enteredAmount = params.data[enteredField];
  const unit = params.data[unitField];
  const decimalPlaceInUnit = params.data[decimalField];
  const formattedEnteredAmount = unit ? formatAmountUnitDecimalPlace(enteredAmount, decimalPlaceInUnit) : "-";
  const formattedDefaultAmount = formatAmountUnitDecimalPlace(baseAmount, decimalPlace);

  return (
    <>
      <div className="default-unit">{formattedDefaultAmount !== "-" ? `${formattedDefaultAmount} ${defaultUnitName}` : formattedDefaultAmount}</div>
      <div className="historical-unit">{formattedEnteredAmount !== "-" ? `${formattedEnteredAmount} ${unit}` : formattedEnteredAmount}</div>
    </>
  );
};


export const useColumnCalimsItemHistoryDefs=(timeZone: string)=>{
const {t}=useTranslation()
  return [
  {
    cellRenderer: "claimItemReference",
    			comparator: () => 0,
    field: "claimItemReference",
    type:"claimItemReferenceType",
    headerName: t("pages.claims.claimHistory.claimItemHistoryTable.tableColumnsDetails.column1"),
    width: 150,
    sortable: true,
    unSortIcon: true,
      
  },
  {
    			comparator: () => 0,
    field: "categoryName",
    type: "categoryNameType",
    headerName: t("pages.claims.claimHistory.claimItemHistoryTable.tableColumnsDetails.column2"),
    width: 150,
    sortable: true,
    unSortIcon: true,
      
  },
  {
    cellRenderer: amountCellRenderer('baseOriginalAmount','originalAmount','originalAmountUnit','originalAmountDecimalPlace'),
    			comparator: () => 0,
    field: "baseOriginalAmount",
    type:"baseOriginalAmountType",
    width: 200,
    sortable: true,
    unSortIcon: true, 
       headerName: t("pages.claims.claimHistory.claimItemHistoryTable.tableColumnsDetails.column3"),
    
    cellClass: "align-right",
  },
  {
    cellRenderer: amountCellRenderer('baseCurrentAmount','currentAmount','currentAmountUnit','currentAmountDecimalPlace'),
    			comparator: () => 0,
    field: "baseCurrentAmount",
    type:"baseCurrentAmountType",
    sortable: true,
    unSortIcon: true, 
       headerName: t("pages.claims.claimHistory.claimItemHistoryTable.tableColumnsDetails.column4"),
    
    width: 230,
    cellClass: "align-right",
    headerClass: 'align-right',
  },
  {
    cellRenderer: amountCellRenderer('baseUnresolvedCreditorAmount','unresolvedCreditorAmount','unresolvedCreditorAmountUnit','unresolvedCreditorAmountDecimalPlace'),
    			comparator: () => 0,
    field: "baseUnresolvedCreditorAmount",
    type:"baseUnresolvedCreditorAmountType",
    sortable: true,
    unSortIcon: true,
        headerName: t("pages.claims.claimHistory.claimItemHistoryTable.tableColumnsDetails.column5"),
    
    cellClass: "align-right",
    width: 230,
    headerClass: 'align-right',
  },
  {
    cellRenderer: amountCellRenderer('baseUnresolvedDebtorAmount','unresolvedDebtorAmount','unresolvedDebtorAmountUnit','unresolvedDebtorAmountDecimalPlace'),
    			comparator: () => 0,
    field: "baseUnresolvedDebtorAmount",
    type:"baseUnresolvedDebtorAmountType",
    sortable: true,
    unSortIcon: true,
        headerName: t("pages.claims.claimHistory.claimItemHistoryTable.tableColumnsDetails.column6"),
    width: 230,
    
    cellClass: "align-right",
    headerClass: 'align-right',
  },
  {
    cellRenderer: amountCellRenderer('baseResolvedAmount','resolvedAmount','resolvedAmountUnit','resolvedAmountDecimalPlace'),
    			comparator: () => 0,
    field: "baseResolvedAmount",
    type:"baseResolvedAmountType",
    sortable: true,
    unSortIcon: true,
    headerName: t("pages.claims.claimHistory.claimItemHistoryTable.tableColumnsDetails.column7"),
    
    width: 230,
    cellClass: "align-right",
    headerClass: 'align-right',
  },
  {
    			comparator: () => 0,
    field: "modifiedBy",
    type:"modifiedByType",
    headerName:t("pages.claims.claimHistory.claimItemHistoryTable.tableColumnsDetails.column8"),
    width: 150,
    sortable: true,
    unSortIcon: true,
      
  },
  {
    			comparator: () => 0,
    field: "modifiedOn",
    type:"modifiedOnType",
    headerName: t("pages.claims.claimHistory.claimItemHistoryTable.tableColumnsDetails.column9"),
    width: 350,
    sortable: true,
    unSortIcon: true,
    cellClass: "align-left",
    headerClass: 'align-left',
    cellRendererParams: {
			timeZoneName: timeZone, 
		},
    cellRenderer: function (params: any) {
      return convertDateTimeinDate(params.data.modifiedOn) + " " + params.timeZoneName;
    },
      
  },
]};
