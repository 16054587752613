import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "@/config";
import fetchFn from "@/libs/fetchFn";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://graph.microsoft.com/v1.0",
    fetchFn: fetchFn,
  }),
  endpoints: (builder) => ({
    // query an image data from `/me/photo/$value`,
    getUserPhoto: builder.query({
      query: () => ({
        url: `/me/photo/$value`,
        method: "GET",
      }),
    }),
    createUserSession: builder.mutation<
      { sessionStart: string; sessionExpiration: string },
      void
    >({
      query: () => ({
        url: config.backend.baseUrl + "/UserSession/CreateSession",
        method: "POST",
      }),
    }),
    endUserSession: builder.mutation<void, void>({
      query: () => ({
        url: config.backend.baseUrl + "/UserSession/EndSession",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLazyGetUserPhotoQuery,
  useCreateUserSessionMutation,
  useEndUserSessionMutation,
} = userApi;
