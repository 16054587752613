const globalActions = {
  edit: "Edit",
  add: "Add",
  save: "Save",
  cancel: "Cancel",
  approve: "Approve",
  discard: "Discard",
  addNew: "Add New",
  yes: "Yes",
  no: "No",
  next: "Next",
  update: "Update",
  updating: "Updating...",
  remove: "Remove",
  backToCase: "Back to case",
  back: "Back",
  backToHome: "Back to Home",
  clearSearch: "Clear search",
  upload: "Upload",
  uploading: "Uploading...",
  delete: "Delete",
  post: "Post",
  apply: "Apply",
  confirm: "Confirm",
  refresh: "Refresh",
  optionDefault: "Please Select",
  uploadNewDocument: "Upload New Document",
  prefixOptionsListLabels: {
    mr: "Mr.",

    ms: "Ms.",

    mrs: "Mrs.",

    miss: "Miss.",

    other: "Other",
  },
};

export default globalActions;
