import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	MotifButton,
	MotifFormField,
	MotifLabel,
	MotifInput,
	MotifSelect,
	MotifOption,
} from "@ey-xd/motif-react";
import FormattedDatePicker from "@/components/FormattedDatePicker/FormattedDatePicker";
import { PaymentStatusObject, PaymentPrefix, Account } from "./type";
import { formattedDate } from "@/utility/common";
export const Filter = ({
	accountList,
	setShowFilter,
	onApply,
	formValues,
	setFormValues,
	clearFilter,
}: {
	accountList: Account[];
	setShowFilter: (showBasicSearch: boolean) => void;
	onApply: (params: any) => void;
	formValues: any;
	setFormValues: (formValues: any) => void;
	clearFilter: () => void;
}) => {
	const { t } = useTranslation();
	const { caseId } = useParams();
	const defaultStatusOptions: PaymentStatusObject[] = [
		{ id: "0", name: "Draft" },
		{ id: "1", name: "Issued" },
		{ id: "2", name: "Cashed" },
		{ id: "3", name: "Replaced" },
		{ id: "4", name: "Stop" },
		{ id: "5", name: "Undelivered" },
		{ id: "6", name: "Void" },
	];
	const [paymentStatusOptions] =
		useState<PaymentStatusObject[]>(defaultStatusOptions);
	const defaultPrefixOptions: PaymentPrefix[] = [
		{ id: "0", name: "Cheque" },
		{ id: "1", name: "Statement" },
		{ id: "2", name: "Wire" },
	];
	const [paymentPrefixOptions] =
		useState<PaymentPrefix[]>(defaultPrefixOptions);

	const handleChange = (name: string, value: any) => {
		setFormValues((prev: any) => ({ ...prev, [name]: value }));
	};
	const handleSubmit = async () => {
		const {
			payeeName,
			accountName,
			prefix,
			paymentReferenceStartWith,
			paymentReferenceEndWith,
			issueStartDate,
			issueEndDate,
			lowerBound,
			upperBound,
			postedDateStart,
			postedDateEnd,
			paymentStatus,
		} = formValues;

		const params: any = {
			CaseId: caseId,
			PayeeName: payeeName,
			AccountName: accountName,
			Prefix: prefix,
			PaymentReferenceStartWith: paymentReferenceStartWith,
			PaymentReferenceEndWith: paymentReferenceEndWith,
			StartDate: issueStartDate ? formattedDate(issueStartDate) : "",
			EndDate: issueEndDate ? formattedDate(issueEndDate) : "",
			LowerBound: lowerBound,
			upperBound: upperBound,
			PostedDateStart: postedDateStart ? formattedDate(postedDateStart) : "",
			PostedDateEnd: postedDateEnd ? formattedDate(postedDateEnd) : "",
			PaymentStatus: paymentStatus,
		};
		onApply(params);
	};

	return (
		<div className="claims-basic-search-container">
			<div className="motif-row">
				<div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
					<MotifLabel>
						{t("pages.paymentStatus.filterForm.payeeName")}
					</MotifLabel>
					<MotifFormField>
						<MotifInput
							//@ts-ignore
							onChange={(e) => handleChange("payeeName", e.target.value)}
							value={formValues.payeeName}
							hideClearButton={true}
						/>
					</MotifFormField>
				</div>
				<div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
					<MotifLabel>
						{t("pages.paymentStatus.filterForm.accountName")}
					</MotifLabel>
					<MotifFormField>
						<MotifSelect
							onChange={(value) => handleChange("accountName", value)}
							value={formValues.accountName}
							visibleOptions={3}
							filter={true}
						>
							{accountList.map((option) => (
								<MotifOption key={option.id} value={option.name}>
									{option.name}
								</MotifOption>
							))}
						</MotifSelect>
					</MotifFormField>
				</div>
				<div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
					<MotifLabel>{t("pages.paymentStatus.filterForm.prefix")} </MotifLabel>
					<MotifFormField>
						<MotifSelect
							onChange={(value) => handleChange("prefix", value)}
							value={formValues.prefix}
						>
							{paymentPrefixOptions.map((option) => (
								<MotifOption key={option.id} value={option.id}>
									{option.name}
								</MotifOption>
							))}
						</MotifSelect>
					</MotifFormField>
				</div>
				<div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
					<MotifLabel>
						{t("pages.paymentStatus.filterForm.paymentReferenceStart")}
					</MotifLabel>
					<MotifFormField>
						<MotifInput
							//@ts-ignore
							onChange={(e) =>
								handleChange("paymentReferenceStartWith", e.target.value)
							}
							value={formValues.paymentReferenceStartWith}
							hideClearButton={true}
						/>
					</MotifFormField>
				</div>
				<div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
					<MotifLabel>
						{t("pages.paymentStatus.filterForm.paymentReferenceEnd")}
					</MotifLabel>
					<MotifFormField>
						<MotifInput
							//@ts-ignore
							onChange={(e) =>
								handleChange("paymentReferenceEndWith", e.target.value)
							}
							value={formValues.paymentReferenceEndWith}
							hideClearButton={true}
						/>
					</MotifFormField>
				</div>
				<div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
					<MotifLabel>
						{t("pages.paymentStatus.filterForm.issueDate")}
					</MotifLabel>
					<MotifFormField>
						<FormattedDatePicker
							todayMark={true}
							range={true}
							onChange={(value) => {
								if (value && Array.isArray(value)) {
									handleChange("issueStartDate", value[0]);
									handleChange("issueEndDate", value[1]);
								}
							}}
						/>
					</MotifFormField>
				</div>
				<div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
					<MotifLabel>
						{t("pages.paymentStatus.filterForm.amountInLowerBound")}
					</MotifLabel>
					<MotifFormField>
						<MotifInput
							//@ts-ignore
							onChange={(e) => handleChange("lowerBound", e.target.value)}
							value={formValues.lowerBound}
							hideClearButton={true}
						/>
					</MotifFormField>
				</div>
				<div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
					<MotifLabel>
						{t("pages.paymentStatus.filterForm.amountInUpperBound")}
					</MotifLabel>
					<MotifFormField>
						<MotifInput
							//@ts-ignore
							onChange={(e) => handleChange("upperBound", e.target.value)}
							value={formValues.upperBound}
							hideClearButton={true}
						/>
					</MotifFormField>
				</div>
				<div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
					<MotifLabel>
						{t("pages.paymentStatus.filterForm.postedDate")}
					</MotifLabel>
					<MotifFormField>
						<FormattedDatePicker
							onChange={(value) => {
								if (value && Array.isArray(value)) {
									handleChange("postedDateStart", value[0]);
									handleChange("postedDateEnd", value[1]);
								}
							}}
							todayMark={true}
							range={true}
						/>
					</MotifFormField>
				</div>
				<div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
					<MotifLabel>{t("pages.paymentStatus.filterForm.status")} </MotifLabel>
					<MotifFormField>
						<MotifSelect
							onChange={(value) => handleChange("paymentStatus", value)}
							value={formValues.paymentStatus}
							visibleOptions={3}
						>
							{paymentStatusOptions.map((option) => (
								<MotifOption key={option.id} value={option.id}>
									{option.name}
								</MotifOption>
							))}
						</MotifSelect>
					</MotifFormField>
				</div>
			</div>
			<div className="basic-search-buttons">
				<MotifButton
					size="small"
					type="button"
					variant="secondary"
					onClick={() => {
						setShowFilter(false);
						clearFilter();
					}}
				>
					{t("pages.paymentStatus.filterForm.cancel")}
				</MotifButton>

				<MotifButton size="small" variant="primary" onClick={handleSubmit}>
					{t("pages.paymentStatus.filterForm.apply")}
				</MotifButton>
			</div>
		</div>
	);
};
