import { MotifButton, MotifIcon } from "@ey-xd/motif-react";
import { IconoirPlus } from "@ey-xd/motif-icon";
import { useState } from "react";
import { useGetPayeeTypeSummaryApiDataQuery } from "./calculationStepReducer";
import FormModalResultSetting from "./FormModal/FormModalResultSetting";
import { useTranslation } from "react-i18next";

export const ResultSetting = ({ caseId, stepId,distributionStatus }: any) => {
  const [createShow, setCreateShow] = useState(false);
  const {t}=useTranslation()
  const [resultSetData, setResultSetData] = useState({});
  const [editId, setEditId] = useState("");
  const { data: payeeTypeSummary, refetch } =
    useGetPayeeTypeSummaryApiDataQuery(stepId);
  return (
    <>
      <div className="motif-container case-summary-form pad-v-20 mar-t-20">
        <div className="motif-row justify-content-between align-items-center">
          <div className="motif-col-4">
            <div className="motif-body1-default-regular page-title">
              {t("pages.distributions.distributionStepsTab.resultSettingTab.title")}
            </div>
            <div className="motif-body2-default-regular page-description">
            {t("pages.distributions.distributionStepsTab.resultSettingTab.description")}
            </div>
          </div>
          <div className="motif-col-4">
            {payeeTypeSummary?.calculationStepId !== "" ? (
              <MotifButton
                onClick={() => {
                  setCreateShow(true);
                  setEditId(payeeTypeSummary?.calculationStepId);
                  setResultSetData(payeeTypeSummary);
                }}
                size="small"
                type="submit"
                variant="secondary"
                disabled={distributionStatus}
              >
                {t("global.globalActions.edit")}
              </MotifButton>
            ) : (
              <MotifButton
                onClick={() => {
                  setCreateShow(true);
                }}
                size="small"
                type="submit"
                variant="secondary"
              >
                <MotifIcon iconFunction={IconoirPlus} />
              </MotifButton>
            )}
          </div>
        </div>
      </div>
      <div
        className={
          createShow ? "ccwp_createshow_wrapper" : "ccwp_createhide_wrapper"
        }
      >
        {createShow ? (
          <FormModalResultSetting
            createShow={createShow}
            setCreateShow={setCreateShow}
            caseId={caseId}
            stepId={stepId}
            refetch={refetch}
            editId={editId}
            payeeTypeSummary={resultSetData}
          />
        ) : (
          <>
            <div className="motif-row ">
              <div className="motif-col-lg-4">
                <div>
                  <div className="title">{t("pages.distributions.distributionStepsTab.resultSettingTab.formLabels.multipleIssue")}</div>
                  <div className="motif-body2-default-bold">
                    {payeeTypeSummary?.denomination
                      ? payeeTypeSummary?.denomination
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="motif-col-lg-4">
                <div>
                  <div className="title">{t("pages.distributions.distributionStepsTab.resultSettingTab.formLabels.rounding")}</div>
                  <div className="motif-body2-default-bold">
                    {payeeTypeSummary?.rounding === 0
                      ? "Round up"
                      : payeeTypeSummary?.rounding === 1
                      ? "Round down"
                      : payeeTypeSummary?.rounding === 2
                      ? "Round to nearest"
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="motif-col-lg-4">
                <div>
                  <div className="title">{t("pages.distributions.distributionStepsTab.resultSettingTab.formLabels.minimumIssueAmount")}</div>
                  <div className="motif-body2-default-bold">
                    {Number(payeeTypeSummary?.minimumIssueAmount) !== null
                      ? Number(payeeTypeSummary?.minimumIssueAmount)
                      : "-"}
                  </div>
                </div>
              </div>
              {/* <div className="motif-col-lg-4 mar-t-20">
                <div>
                  <div className="title">Payee Type</div>
                  <div className="motif-body2-default-bold">
                    {payeeTypeSummary?.payeeType
                      ? payeeTypeSummary?.payeeType
                      : "-"}
                  </div>
                </div>
              </div> */}
              {/* <div className="motif-col-lg-4 mar-t-20">
                <div>
                  <div className="title">Payment grouping</div>
                  <div className="motif-body2-default-bold">
                    {payeeTypeSummary?.paymentGrouping === 0
                      ? "Split by Creditor"
                      : payeeTypeSummary?.paymentGrouping === 1
                      ? "Split by Payee"
                      : payeeTypeSummary?.paymentGrouping === 2
                      ? "Claim Owner"
                      : "-"}
                  </div>
                </div>
              </div> */}
            </div>
          </>
        )}
      </div>
    </>
  );
};
