import React from 'react';
import {useTranslation} from 'react-i18next';

const HelpSupport = () =>{
  const {t} = useTranslation(); 
  return (
    <div>{t("Help Support")}</div>
  )
}

export default HelpSupport;