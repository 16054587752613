import {
  MotifErrorMessage,
  MotifFormField,
  MotifProgressLoader,
  MotifTable,
} from "@ey-xd/motif-react";
import useColumnDefinitions from "@/features/caselist/components/Constants/colHeaderConst";
import useCustomCellRenderers from "@/features/caselist/components/Constants/customCellConst";
// import { tableDetails } from "@/features/caselist/components/Constants/tableDetailsConst";
import { useTranslation } from "react-i18next";
import { useSearchCaseListDataQuery } from "@/services/cases/casesReducer";
import { useEffect, useState } from "react";
import CustomPagination from "@/components/Pagination/CustomPagination";
import SearchInput from "@/components/SearchInput/SearchInput";
import { useDispatch } from "react-redux";
import { useGetTimeZoneListQuery } from "@/pages/CaseDetail/Configuration/CaseTimeZones/caseTimeZoneReducer";
import { setTimeZone } from "@/pages/CaseDetail/Configuration/CaseTimeZones/caseTimeZoneSlice";
import {
  customPaginationPageSize,
  customPaginationPageSizeSelector,
  customTableLoadingData,
} from "@/components/Table/CustomPaginationConstants";
export default function CaseListTable() {
  const dispatchEvent = useDispatch();
  const { data } = useGetTimeZoneListQuery({});
  useEffect(() => {
    if (data && data.length > 1) dispatchEvent(setTimeZone(data));
  }, [data, dispatchEvent]);
  const { t } = useTranslation();
  let tableLoadingData = customTableLoadingData;
  const paginationPageSize: number = customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
  const [paginationObj, setPaginationObj] = useState<any>({
    currentPage: 1,
    pageSize: paginationPageSize,
    searchText: "",
  });

  const cellRenderers = useCustomCellRenderers();

  const columnDefs = useColumnDefinitions();

  const [searchedData, setSearchData] = useState([]);
  let caseListPaginationData: any = {
    PageNumber: paginationObj.currentPage,
    PageSize: paginationObj.pageSize,
    SearchText: paginationObj.searchText,
  };
  if (paginationObj?.SortOrder && paginationObj.SortColumn) {
    caseListPaginationData.SortOrder = paginationObj.SortOrder;
    caseListPaginationData.SortColumn = paginationObj.SortColumn;
  }

  const {
    data: searchDataObj,
    error,
    isLoading: quickSearchLoader,
    isFetching,
  } = useSearchCaseListDataQuery({ ...caseListPaginationData });
  const searchData = searchDataObj?.results;
  const hasMoreRecords = searchDataObj?.hasMoreRecords;

  function handleSearch() {
    setSearchData(searchData);
    setPaginationObj({ ...paginationObj, currentPage: 1 });
    resetSortStatus();
  }
  let [sortedColumnTypes, setSortedColumnTypes] = useState<any>({});

  function handlePaginationData(data: any, columnTypesData: any) {
    setPaginationObj({ ...data });
    setSortedColumnTypes({ ...columnTypesData });
  }
  function handleMotifSorting(e: any, paginationObj: any) {
    let sortedColumnsLength: any = e?.columns ? e.columns.length : "";
    if (sortedColumnsLength !== "") {
      let coumnName = e?.columns?.[sortedColumnsLength - 1]?.colId;
      let sortName = e?.columns?.[sortedColumnsLength - 1]?.sort;
      let typeName = e?.columns?.[sortedColumnsLength - 1]?.colDef?.type;
      let paginationJson: any = { ...paginationObj };
      let columnTypesCustom: any = {};
      if (typeName) {
        columnTypesCustom[typeName] = { sort: sortName };
      }
      paginationJson.SortOrder = sortName;
      paginationJson.SortColumn =
        coumnName?.charAt(0)?.toUpperCase() + coumnName?.slice(1);
      if (!sortName) {
        delete paginationJson.SortOrder;
        delete paginationJson.SortColumn;
      }
      if (e?.columns[0]?.colDef?.field) {
        if (handlePaginationData) {
          handlePaginationData(paginationJson, columnTypesCustom);
        }
      }
    }
  }
  function resetSortStatus() {
    let json: any = { ...sortedColumnTypes };
    for (const key in json) {
      json[key] = { sort: null };
    }

    setSortedColumnTypes({ ...json });
  }
  return (
    <>
      <div
        style={
          isFetching || searchData?.length === 0 ? { height: "150px" } : {}
        }
        className="ccwp_caselist_motif_table"
      >
        {error ? (
          <MotifErrorMessage
            style={{
              textAlign: "center",
              display: "block",
              alignItem: "center",
            }}
          >
            {t("global.globalNotifications.wentWrong")}
          </MotifErrorMessage>
        ) : quickSearchLoader ? (
          <MotifProgressLoader show variant="default" />
        ) : searchedData ? (
          <>
            <MotifTable
              // onGridReady={onGridReady}
              overlayNoRowsTemplate={
                isFetching
                  ? t(tableLoadingData)
                  : error
                  ? t("global.globalNotifications.wentWrong")
                  : t("global.globalNotifications.noRecords")
              }
              columnTypes={sortedColumnTypes ? { ...sortedColumnTypes } : {}}
              onSortChanged={(e: any) => {
                handleMotifSorting(e, paginationObj);
              }}
              paginateChildRows={true}
              columnDefs={columnDefs}
              components={cellRenderers}
              description={t("pages.home.tableDescription")}
              rowData={searchData && !isFetching ? searchData : []}
              title={t("pages.home.tableTile")}
              titleComplement={
                <MotifFormField>
                  <SearchInput
                    aria-label={t("Search")}
                    onChange={(e: any) => {
                      setPaginationObj({
                        ...paginationObj,
                        searchText: e.target.value,
                        currentPage: 1,
                      });
                      resetSortStatus();
                    }}
                    onClear={() => {
                      setPaginationObj({
                        ...paginationObj,
                        searchText: "",
                        currentPage: 1,
                        pageSize: paginationPageSize,
                      });
                      setSearchData([]);
                      resetSortStatus();
                    }}
                    onEnter={() => handleSearch()}
                    placeholder={t(
                      "global.globalPlaceholder.quickSearchPlaceHolder"
                    )}
                  />
                </MotifFormField>
              }
            />
            <CustomPagination
              currentpage={paginationObj.currentPage}
              pageSize={paginationObj.pageSize}
              disableNext={!hasMoreRecords}
              onChangePageSize={(page: number) => {
                setPaginationObj({
                  ...paginationObj,
                  pageSize: page,
                  currentPage: 1,
                });
              }}
              onChangeNextPage={() => {
                setPaginationObj({
                  ...paginationObj,
                  currentPage: paginationObj.currentPage + 1,
                });
              }}
              onChangePreviousPage={() => {
                setPaginationObj({
                  ...paginationObj,
                  currentPage: paginationObj.currentPage - 1,
                });
              }}
              pageSizeSelector={paginationPageSizeSelector}
            />
          </>
        ) : null}
      </div>
    </>
  );
}
