import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifInput,
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifIcon,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { actionIcDescription24px } from "@ey-xd/motif-react/assets/icons";
import "./UploadEditCreditorDoc.scss";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useCase } from "@/features/case/use-case";
import {
  useLazyGetDocumentTypesQuery,
  useUploadDocumentMutation,
  useUpdateDocumentMutation,
  fileExtensions,
} from "@/services/documents/documentsReducer";
import Button from "@/components/Form/Button/Button";
import Label from "@/components/Form/Label/Label";

export default function UploadEditCreditorDoc({
  show,
  setShow,
  creditorId,
  isEdit,
  document,
}: {
  show: boolean;
  setShow: (isVisible: boolean) => void;
  creditorId: string;
  isEdit: boolean;
  document: any;
}) {
  const { t } = useTranslation();
  const caseData = useCase();
  const { caseId } = caseData;
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [showDiscardMessage, setShowDiscardMessage] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [comment, setComment] = useState("");
  const [fileName, setFileName] = useState("");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [getDocumentTypesQuery] = useLazyGetDocumentTypesQuery();
  const [uploadDocument] = useUploadDocumentMutation();
  const [updateDocument] = useUpdateDocumentMutation();

  useEffect(() => {
    getDocumentTypesQuery().then((response) => {
      if (response.data) {
        setDocumentTypes(response.data);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEdit) {
      const type: any = documentTypes.filter((x: any) => {
        return x.name === document.documentType;
      });
      setFileName(document.displayName);
      setDocumentType(type.length !== 0 ? type[0].id : "");
      setComment(document.comment);
      setFile(new File([""], document.fileName));
    }
    // eslint-disable-next-line
  }, [documentTypes]);

  const handleFileChange = (files: any) => {
    const file = files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleSubmit = useCallback(() => {
    const formData = new FormData();
    setIsUploading(true);
    formData.append("CaseId", caseId);
    formData.append("DisplayName", fileName);
    formData.append("Comment", comment);
    formData.append("DocumentTypeId", documentType);

    if (isEdit) {
      formData.append("DocumentId", document.id);
      if (file && file.size !== 0) {
        formData.append("file", file);
      }
      updateDocument(formData).then(() => {
        setIsUploading(false);
        setShow(false);
      });
    } else {
      formData.append("EntityId", creditorId);
      formData.append("EntityType", "1");

      if (file) {
        formData.append("file", file);
      }
      uploadDocument(formData).then(() => {
        setIsUploading(false);
        setShow(false);
      });
    }
  }, [
    caseId,
    fileName,
    comment,
    documentType,
    isEdit,
    document.id,
    file,
    updateDocument,
    setShow,
    creditorId,
    uploadDocument,
  ]);

  return (
    <>
      <MotifModal
        className="upload-edit-document-container"
        show={show}
        onClose={() => setShowDiscardMessage(true)}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <MotifModalHeader>
          {isEdit
            ? t("pages.creditors.creditorDocumentUpload.documentPopup.editTitle")
            : t("pages.creditors.creditorDocumentUpload.documentPopup.title")}
        </MotifModalHeader>
        <MotifModalBody>
          {documentTypes.length === 0 ? (
            <MotifProgressLoader show variant="default" />
          ) : (
            <>
              {t("pages.creditors.creditorDocumentUpload.documentPopup.subtitle")}
              <div className="motif-row document-inputs">
                <div className="motif-col-md-2 motif-col-lg-4">
                  <Label
                    title={"pages.creditors.creditorDocumentUpload.documentPopup.fileName"}
                    required={true}
                  />
                  <MotifFormField aria-label="Input" role="group">
                    <MotifInput
                      // @ts-ignore
                      onChange={(e) => setFileName(e.target.value)}
                      value={fileName}
                    />
                  </MotifFormField>
                </div>
                <div className="motif-col-md-2 motif-col-lg-4">
                  <MotifFormField>
                    <Label
                      title={"pages.creditors.creditorDocumentUpload.documentPopup.documentType"}
                      required={true}
                    />
                    <MotifSelect
                      onChange={(value) => setDocumentType(value)}
                      value={documentType}
                      visibleOptions={3}
                    >
                      {documentTypes.map((option: any) => {
                        return (
                          <MotifOption value={option.id} key={option.id}>
                            {option.name}
                          </MotifOption>
                        );
                      })}
                    </MotifSelect>
                  </MotifFormField>
                </div>
                <div className="motif-col-md-2 motif-col-lg-4">
                  <Label title={"pages.creditors.creditorDocumentUpload.documentPopup.comment"} />
                  <MotifFormField aria-label="Input" role="group">
                    <MotifInput
                      // @ts-ignore
                      onChange={(e) => setComment(e.target.value)}
                      value={comment}
                    />
                  </MotifFormField>
                </div>
              </div>

              <div className="document-uploader">
                <MotifFileUploader
                  label={t("pages.creditors.creditorDocumentUpload.documentPopup.uploadText")}
                  labelDescription={t(
                    "pages.creditors.creditorDocumentUpload.documentPopup.uploadDescription"
                  )}
                  onDrop={(files) => handleFileChange(files)}
                  maxFiles={1}
                  disabled={isEdit}
                  accept={fileExtensions.default}
                >
                  {file && (
                    <MotifFileUploaderItem
                      fileIcon={<MotifIcon src={actionIcDescription24px} />}
                      fileName={file.name}
                      uploaded={true}
                      uploadedText={
                        t("pages.creditors.creditorDocumentUpload.documentPopup.displayName") + fileName
                      }
                      error={
                        (file && file.size >= fileExtensions.maxFileSize) ||
                        false
                      }
                      errorText={t(
                        "pages.creditors.creditorDocumentUpload.stageStatusPopup.filesizeExceedsLimit",
                        {
                          filesize: "250",
                        }
                      )}
                    />
                  )}
                </MotifFileUploader>
              </div>
            </>
          )}
        </MotifModalBody>
        <MotifModalFooter>
          <div className="document-footer">
            <Button
              variant="secondary"
              onClickHandler={() => setShowDiscardMessage(true)}
              label={t("pages.creditors.creditorDocumentUpload.documentPopup.cancel")}
            />
            <Button
              type={isUploading ? "loader" : "submit"}
              variant="primary"
              onClickHandler={handleSubmit}
              label={
                isEdit
                  ? t("pages.creditors.creditorDocumentUpload.documentPopup.save")
                  : t("pages.creditors.creditorDocumentUpload.documentPopup.upload")
              }
              disabled={!documentType || !file || !fileName || isUploading}
            />
          </div>
        </MotifModalFooter>
      </MotifModal>

      <ConfirmationModal
        isVisible={showDiscardMessage}
        setIsVisible={setShowDiscardMessage}
        cancelBtnApproveHandler={() => setShow(false)}
        cancelBtnRejectHandler={() => setShowDiscardMessage(false)}
      />
    </>
  );
}
