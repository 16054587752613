import {
  MotifButton,
  MotifIcon,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { Link } from "react-router-dom";
import { IconoirEditPencil, IconoirTrash } from "@ey-xd/motif-icon";

type claimItemConst = {
  data: {
    creditorId: any;
    claimItemId: string;
    categoryName: string;
    originalAmt: string;
    currentAmt: string;
    latestCreditorPositionAmt: string;
    latestDebtorPositionAmt: string;
    resolvedAmt: number;
    creditorName: string;
    claimId: string;
    updatedOn: string;
    claimReference: string;
  };
  node: {
    rowPinned: boolean;
    data: {
      columnField1: React.FC;
      columnField2: React.FC;
      columnField3: React.ReactElement;
      columnField4: React.ReactElement;
      columnField5: React.ReactElement;
      columnField6: React.ReactElement;
      columnField7: React.ReactElement;
    };
  };
};


export const useCustomCellRenderers = (
  caseId: undefined | string,
  setClaimItemId: any,
  setShowModal: any,
  resolvedStatus: any
) => {
  const customActionHeader = {
    actionRenderer: function (param: any) {
      if (param.node.rowPinned) return false;
      else
        return (
          <>
            {param.data.creditorName !== undefined &&
            param.data.claimReference !== undefined &&
            param.data.updatedOn !== undefined ? (
              <>
                <MotifButton
                  size="small"
                  type="submit"
                  variant="secondary"
                  style={{ padding: "0px 8px" }}
                >
                  <Link
                    to={
                      `/case/${caseId}/claim/details/` +
                      param.data.claimItemId +
                      `?claimId=${param.data.claimId}&creditorName=${param.data.creditorName}&updatedOn=${param.data.updatedOn}&claimReference=${param.data.claimReference}&creditorId=${param.data.creditorId}`
                    }
                    style={{ display: "block" }}
                  >
                    <MotifIcon
                      iconFunction={IconoirEditPencil}
                      stroke="blue"
                      strokeWidth="2"
                      size="20"
                    />
                  </Link>
                </MotifButton>
                &nbsp;&nbsp;
                {param.data.resolvedAmt === 0 ||
                param.data.resolvedAmt === null ? (
                  <MotifButton
                    size="small"
                    type="submit"
                    variant="secondary"
                    onClick={() => {
                      setClaimItemId(param.data.claimItemId);
                      setShowModal(true);
                    }}
                  >
                    <MotifIcon
                      iconFunction={IconoirTrash}
                      stroke="blue"
                      strokeWidth="2"
                      size="24"
                    />
                  </MotifButton>
                ) : (
                  <MotifButton
                    size="small"
                    type="submit"
                    variant="secondary"
                    onClick={() => {
                      setClaimItemId("");
                      setShowModal(true);
                    }}
                  >
                    <MotifIcon
                      iconFunction={IconoirTrash}
                      stroke="blue"
                      strokeWidth="2"
                      size="24"
                    />
                  </MotifButton>
                )}
              </>
            ) : (
              <MotifProgressLoader variant="circular" size="xs" />
            )}
            {/* // <MotifDropdown
          //   ariaLabelledby="dropdown-trigger-1"
          //   id="dropdown-1"
          //   trigger={
          //     <MotifButton
          //       aria-label="Fruits"
          //       id="dropdown-trigger-1"
          //       onClick={function Ga() {}}
          //       type="button"
          //       variant="text"
          //     >
          //       <MotifIcon iconFunction={IconoirMoreHoriz} />
          //     </MotifButton>
          //   }
          // >
          //   <ul aria-label="fruits" role="menu">
          //     {debtorTitleMenu.map((menuName: string) => {
          //       return (
          //         <li role="menuitem">
          //           <MotifDropdownItem indexItem={1} onClick={function Ga() {}}>
          //             {menuName}
          //           </MotifDropdownItem>
          //         </li>
          //       );
          //     })}
          //   </ul>
          // </MotifDropdown> */}
          </>
        );
    },
    categoryName: function (param: claimItemConst) {
      if (param.node.rowPinned) {
        return param.node.data.columnField2;
      } else {
        return param.data.categoryName;
      }
    },
    claimItemId: function (param: claimItemConst) {
      if (param.node.rowPinned) {
        return param.node.data.columnField1;
      } else {
        return param.data.claimItemId;
      }
    },
    claimItemReference: function (param: claimItemConst) {
      return (
        <Link
          to={
            `/case/${caseId}/claim/details/` +
            param?.data?.claimItemId +
            `?claimId=${param?.data?.claimId}&creditorName=${param?.data?.creditorName}&updatedOn=${param?.data?.updatedOn}&claimReference=${param?.data?.claimReference}&creditorId=${param?.data?.creditorId}`
          }
        >
          {param?.data?.claimReference}
        </Link>
      );
    },
  };
  return customActionHeader;
};
