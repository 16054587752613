import { dateFunc } from "@/components/Functions/dateFunc";

export const useColumnImportandExportDefs=() => [
  {
    cellRenderer: "nameCellRenderer",
    			comparator: () => 0,

    field: "type",
    type: "typeType",
    headerName: "Type",
    flex:1,
    // width: 120,
    sortable: true,
    unSortIcon: true,

  },
  {
    			comparator: () => 0,

    field: "documentName",
    type:"documentNameType",
    headerName: "Document Name",
    sortable: true,
    unSortIcon: true,
    flex:1,
  },
  {
    cellRenderer: "entityTypeCellRenderer",
    			comparator: () => 0,

    field: "entityType",
    type:"entityTypeType",
    headerName: "Entity Type",
    sortable: true,
    unSortIcon: true,
    flex:1,
  },
  {
    			comparator: () => 0,

    field: "totalRecords",
    type:"totalRecordsType",
    headerName: "Total Records",
    flex:1,
    sortable: true,
    unSortIcon: true,    // headerComponent:CustomHeaderImport
  },

  {
    			comparator: () => 0,
          flex:1,
    field: "recordsFailed",
    type:"recordsFailedType",
    headerName: "Falied",
  
    
    cellRenderer: "recordsFailed",
    sortable: true,
    unSortIcon: true,
    // headerComponent:CustomHeaderImport

  },
  {
    			comparator: () => 0,

    field: "status",
    type:"statusType",
    headerName: "Status",
    sortable: true,
    unSortIcon: true,
    flex:1,
    // headerComponent:CustomHeaderImport,

    cellRenderer: function (params: any) {
      if (params.data.status === 1) {
        return "In Progress";
      } else if (params.data.status === 2) {
        return "Success";
      } else if (params.data.status === 3) {
        return "Failed";
      } else if (params.data.status === 0) {
        return "Queued";
      } else {
        return "-";
      }
    },
  },
  {
    			comparator: () => 0,
          flex:1,
    field: "createdBy",
    type:"createdByType",
    headerName: "Created By",
    cellRenderer: "createdBy",
    sortable: true,
    unSortIcon: true,
    
  },
  {
    			comparator: () => 0,
          flex:1,
    field: "createdOn",
    type:"createdOnType",
    headerName: "Created On",
    sortable: true,
    unSortIcon: true,
    cellRenderer: function (params: any) {
      return dateFunc(params.data.createdOn).formattedDateOnly;
    }
    },
  
];
