import ControlInputBox from "@/components/Form/InputBox/ControlInputBox";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./calculationForm.scss";
import { CASE_NAME, CASE_ID_F } from "./Constant";
import Button from "@/components/Form/Button/Button";
import { useForm } from "react-hook-form";
import ControlSelect from "@/components/Form/SelectBox/ControlSelect";
import { CalculationStepEditFormType } from "./type";
import { MotifErrorMessage, MotifToast } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import {
  FRENCH,
  SECONDARY,
  UPDATE_SUCCESSFUL,
} from "@/Constant";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
// import SubAccount from "./SubAccount";
import ControlSelectAccount from "./SelectBox/ControlSelectAccount";
import ChildDropdown from "./ChildDropdown";
import {
  useGetCalculationStepSubAccountDataQuery,
  useGetPayeeTypeApiDataQuery,
  useUpdateCalCulcationStepMutation,
} from "../calculationStepReducer";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import { renderOptionList } from "./calculationSummaryHelper";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

type summaryData = {
  calculationGroupBy: any;
  comment: string;
  stepName: string;
  account: string;
  subAccount: string;
  description: string;
  payables: string;
  calculationStepId: string;
  payeeTypeId: string;
};

type CalculationFormType = {
  summaryData: summaryData;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  accountList: any;
  caseId: string;
  distributionId: string;
  stepId?: string;
  calculationStepId?: string;
  refetch?: any;
};
const CalculationEditForm = ({
  summaryData,
  accountList,
  refetch,
  caseId,
  setIsEdit,
}: CalculationFormType) => {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });
  const { data: resultSetting } = useGetPayeeTypeApiDataQuery({CaseId:caseId});

  /* Child Dropdown */
  const [childOptions, setChildOptions] = useState<any>([
    { label: "Please Select", value: "" },
  ]);

  /* Child Dropdown */
  const { t } = useTranslation();
  const claculationGroupByOptions = [

    { label: t("pages.distributions.distributionStepsTab.claculationGroupByOptions.option1"), value: "" },
    { label: t("pages.distributions.distributionStepsTab.claculationGroupByOptions.option2"), value: "0" },
    { label:t("pages.distributions.distributionStepsTab.claculationGroupByOptions.option3"), value: "1" },
    { label: t("pages.distributions.distributionStepsTab.claculationGroupByOptions.option4"), value: "2" },
    { label: t("pages.distributions.distributionStepsTab.claculationGroupByOptions.option5"), value: "3" },
  ];
  const payableOptions = [
    { value: "0", label: t("pages.distributions.distributionStepsTab.payableOptions.option1") },
    { value: "1", label: t("pages.distributions.distributionStepsTab.payableOptions.option2")},
  ];
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors,dirtyFields },
    setValue,
    getValues,
    watch,
  } = useForm<any>({
    defaultValues: {
      stepName: summaryData.stepName || "",
      account: summaryData.account || "",
      description: summaryData.description || "",
      payables: summaryData.payables || "",
      comment: summaryData.comment || "",
      calculationGroupBy: summaryData.calculationGroupBy.toString(),
    },
  });
  const { data } = useGetCalculationStepSubAccountDataQuery({
    accountId: watch("account"),
  });

  /* Child Dropdown */
  useEffect(() => {
    if (data && data.length > 0) {
      const subAccountList = data.map((item: any) => {
        return {
          label: item.subAccountName,
          value: item.subAccountId,
        };
      });
      setChildOptions(subAccountList);
      setValue("subAccount", summaryData.subAccount || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  /* Child Dropdown */
let dirtyFieldsLength=Object.keys(dirtyFields)?.length;
  const dispatch=useDispatch()
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: dirtyFieldsLength>0?true:false }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,dirtyFieldsLength]);

  const errorList = Object.keys(errors);
  const errorsShow = errorList
    .join(", ")
    .replace(CASE_ID_F, `${CASE_NAME} (${FRENCH})`);

  const [updateCalCulcationStep] = useUpdateCalCulcationStepMutation();
  const onSubmit = async (formData: CalculationStepEditFormType) => {
    let iseMemoUpdated = formData.payables === "1" ? true : false;
    let schemeEditData = {
      id: summaryData.calculationStepId,
      name: formData.stepName,
      description: formData.description,
      subAccountId: formData.subAccount,
      isMemo: iseMemoUpdated,
      comment: formData?.comment,
      calculationGroupBy: Number(formData?.calculationGroupBy),
      payeeTypeId: formData?.payeeType === "-1" || formData?.payeeType === "" ? null : formData?.payeeType,
    };
    setLoading(true);
    const response = await updateCalCulcationStep(schemeEditData);
    if (response.hasOwnProperty("data")) {
      refetch();
      setIsEdit(false);
      setLoading(false);
    } else {
      setLoading(false);
      setIsEdit(false);
    }
  };

  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    reset({
      stepName: summaryData.stepName,
      account: summaryData.account,
      subAccount: summaryData.subAccount,
      description: summaryData.description,
      payables: summaryData.payables,
    });
    setIsVisible(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
    setIsEdit(false);
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const renderMessage = () => {
    if (showMessage.show && !isVisible) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={showMessage.type}
        >
          {" "}
          {showMessage.type === "error"
            ? t(showMessage.message)
            : t(UPDATE_SUCCESSFUL)}
        </MotifToast>
      );
    }
  };

  /* Child Dropdown */
  const onAccountChange = () => {
    setValue("subAccount", "");
  };
  const calculationGroupByValue = watch("calculationGroupBy");
  const allowedPayeeTypeGroup = ["0", "2", "3"] ; 
  const displayPayee = allowedPayeeTypeGroup.includes(calculationGroupByValue);
  function filterPayeeType() {
    const getFilteredData = resultSetting?.results.filter((items: any) => {
      if (calculationGroupByValue === "") return false;
      if ((Number(calculationGroupByValue) === 0 || Number(calculationGroupByValue) === 2) && items.payeeLevel === 0) 
        return true;
      if ((Number(calculationGroupByValue) === 3 ) && items.payeeLevel === 1) 
        return true;
      else return false;
    });
    return getFilteredData;
  }
  let resultSettingList = renderOptionList(
    filterPayeeType(),
    "name",
    "id"
  );

  if (calculationGroupByValue === "0" ||  calculationGroupByValue === "2")
     resultSettingList.splice(1, 0, { label: "Creditor", value: "-1" })
 

  useEffect(() => {
    if (calculationGroupByValue !== summaryData.calculationGroupBy.toString()) {
      setValue("payeeType", "");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculationGroupByValue]);

  
  useEffect(() => {
    if (resultSetting) {
      setValue("payeeType", summaryData.payeeTypeId === null ? "-1" : summaryData.payeeTypeId || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSetting]);

  return (
    <>
      {renderMessage()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-row mar-t-20">
          <div className="motif-col-lg-4">
            <div className="form-field">
              <ControlInputBox
                name={"stepName"}
                //@ts-ignore
                control={control}
                required={true}
                isTextArea={false}
                label={"pages.distributions.distributionStepsTab.stepFormLabels.stepName"}
                showLabel={true}
                readonly={false}
              />
            </div>
          </div>
          <div className="motif-col-lg-4">
            <div className="form-field">
              <ControlSelectAccount
                name={"account"}
                //@ts-ignore
                control={control}
                showLabel={true}
                label={"pages.distributions.distributionStepsTab.stepFormLabels.account"}
                options={accountList}
                required={true}
                //@ts-ignore
                setValue={setValue}
                //@ts-ignore
                getValues={getValues}
                onChangeHandler={onAccountChange}
              />
            </div>
          </div>
          <div className="motif-col-lg-4">
            <div className="form-field">
              {/* Child Dropdown */}
              <ChildDropdown
                control={control}
                setValue={setValue}
                getValues={getValues}
                name="subAccount"
                label={"pages.distributions.distributionStepsTab.stepFormLabels.subAccountNew"}
                required={true}
                options={childOptions}
              />
              {/* Child Dropdown */}
            </div>
          </div>
          <div className="motif-col-lg-4">
            <div className="form-field"></div>
          </div>
        </div>
        <div className="motif-row next-row mar-t-20">
          <div className="motif-col-lg-4">
            <div className="form-field">
              <ControlSelect
                name={"calculationGroupBy"}
                //@ts-ignore
                control={control}
                showLabel={true}
                label={t("pages.distributions.distributionStepsTab.stepFormLabels.calculationGroupBy")}
                options={claculationGroupByOptions}
                required={true}
                //@ts-ignore
                setValue={setValue}
                //@ts-ignore
                getValues={getValues}
              />
            </div>
          </div>
          {displayPayee ? (
            <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
              <ControlSelect
                //@ts-ignore
                name={"payeeType"}
                //@ts-ignore
                control={control}
                required={true}
                showLabel={true}
                label={t("pages.distributions.distributionStepsTab.stepFormLabels.payeeType")}
                //@ts-ignore
                options={resultSettingList}
                getValues={getValues}
                //@ts-ignore
                setValue={setValue}
              />
              {errors["payeeType"] &&
                errors["payeeType"].type === "required" && (
                  <MotifErrorMessage>
                      {t("pages.distributions.distributionStepsTab.validationMessages.payeeTypeRequired")}
                      </MotifErrorMessage>
                )}
            </div>
          ) : (
            ""
          )}
          <div className="motif-col-lg-4">
            <div className="form-field">
              <ControlSelect
                name={"payables"}
                //@ts-ignore
                control={control}
                showLabel={true}
                label={t("pages.distributions.distributionStepsTab.stepFormLabels.memo")}
                options={payableOptions}
                required={true}
                //@ts-ignore
                setValue={setValue}
                //@ts-ignore
                getValues={getValues}
              />
            </div>
          </div>
          <div className="motif-col-lg-6 mar-t-20">
            <div className="form-field">
              <ControlTextAreaWithCharLimit
                name={"description"}
                //@ts-ignore
                control={control}
                isTextArea={true}
                label={"pages.distributions.distributionStepsTab.stepFormLabels.description"}
                showLabel={true}
              />
            </div>
          </div>
          <div className="motif-col-lg-6 mar-t-20">
            <div className="form-field">
              <ControlTextAreaWithCharLimit
                name={"comment"}
                //@ts-ignore
                control={control}
                isTextArea={true}
                label={"pages.distributions.distributionStepsTab.stepFormLabels.comment"}
                showLabel={true}
              />
            </div>
          </div>
        </div>
        <div className="error-container">
          <MotifErrorMessage>
            {errorList.length > 0
 ?t("pages.distributions.distributionStepsTab.validationMessages.mainMessage",{fieldNames:errorsShow})              : ""}
          </MotifErrorMessage>
        </div>
        <div className="step-edit-form">
          <Button label={t("global.globalActions.save")} loading={loading} disabled={loading} />
          <Button
            type="button"
            label={t("global.globalActions.cancel")}
            variant={SECONDARY}
            className="cancelBtn"
            onClickHandler={(e) => {
              if(dirtyFieldsLength>0){
                setIsVisible(true);
                setShowMessage({ ...showMessage, show: false, type: "error" });
              }
              else{
                cancelBtnApproveHandler(e)
              }
          
            }}
          />
        </div>
        <div className="motif-row next-row">
          <div className="motif-col-lg-4">
            <div className="form-title-field"></div>
          </div>
        </div>
        <div className="motif-row next-row"></div>
      </form>
      <ConfirmationModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
    </>
  );
};

export default CalculationEditForm;
