import React, { useEffect, useMemo, useState } from "react";
import Button from "@/components/Form/Button/Button";
import { useForm } from "react-hook-form";
import "./VotingForm.scss";

import {
  VotingEditFormType,
  VotingMeetingFormType,
  VotingMeetingRecordType,
  VotingRecordType,
} from "./type";
import {
  MotifButton,
  MotifErrorMessage,
  MotifIcon,
  MotifLabel,
  MotifModal,
  MotifModalBody,
  MotifModalHeader,
  MotifProgressLoader,
  MotifToast,
} from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import {
  PRIMARY,
  SECONDARY,
} from "@/Constant";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { IconoirPlus } from "@ey-xd/motif-icon";
import ControlInputBoxVoting from "./ControlInputBoxVoting";
import { useNavigate, useParams } from "react-router-dom";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import {
  BALLOT_CLAIM_ID,
  BALLOT_CLASS_ID,
  BALLOT_DESCRIPTION_FRENCH_ID,
  BALLOT_DESCRIPTION_ID,
  BALLOT_ID,
  BALLOT_ID_FRENCH,
  BALLOT_MEETING_ID,
  BALLOT_PROXY_ID,
  BALLOT_SCHEME_ID,
  BALLOT_STATUS,
  BALLOT_VOTED_ID,
} from "./Constant";
import ControlSelect from "./VotingFieldSelectBox";
import ControlCheckBox from "./ControlCheckBox";
import {
  useGetEffectiveSchemesQuery,
  useGetListSchemeBySchemeVersionIDQuery,
  useGetListOfMeetingQuery,
  useLazyGetBallotResultByIdQuery,
  useUpdateBallotMutation,
  useCreateBallotMutation,
} from "../votingReducer";
import { CreateMeetingAllInputFields } from "./CreateMeetingAllInputFields";
import ChildDropdown from "./ChildDropdown";
import ControlSelectScheme from "./ControlSelectScheme";
import { useCase } from "@/features/case/use-case";
import BallotResult from "../BallotResult/BallotResult";
import { useLazyExportDocumentQuery } from "@/services/exports/exportsReducer";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

export interface BannerData {
  debtorId: string;
  caseId: string;
  name: string;
  nameFrench: string;
  description: string;
  descriptionFrench: string;
  schemeVersionId: string;
  classId: string;
  meetingId: string;
  isProxyRequired: boolean;
  status: any;
  successConditionCreditorCount: number;
  successConditionClaimValue: number;
  editView: boolean;
  refreshBallot: [];
}

interface BallotFormProps {
  ballotData: BannerData;
  setIsRefreshVersionList: any;
  BallotVerionSelect: string;
  handleHistoryApi?: any;
}

const VotingSummary = ({
  ballotData,
  setIsRefreshVersionList,
  BallotVerionSelect,
  handleHistoryApi,
}: // readBallot,
BallotFormProps) => {
  const { caseId, editId } = useParams<{ caseId: string; editId: string }>();
  const caseData = useCase();
  const { t } = useTranslation();
  const returnUri = caseId ? `/case/${caseId}?activeTab=4` : "/";
  const [isVisible, setIsVisible] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProxySelected, setIsProxySelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const [isReadonly, setIsReadonly] = useState("false");
  const [isCurrentBallotSelect, setIsCurrentBallotSelect] = useState(true);
  const [exportDocument, { isLoading: exportLoading, isError: exportError }] =
    useLazyExportDocumentQuery();
  const handleDownloadVotes = async (ballotId: any) => {
    await exportDocument({
      ballotId,
      url: "/Ballot/ExportCreditorListByBallotClass",
    });
  };
  /* Child Dropdown */
  const [childOptions, setChildOptions] = useState<any>([
    { label: t("global.globalActions.optionDefault"), value: "" },
  ]);
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });

  const editView = ballotData?.editView ? true : false;
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors,dirtyFields },
  } = useForm<VotingEditFormType, VotingMeetingFormType>();
  const [meetingvalue, setMeetingValue] = useState<any>(null);
  const {
    data: meetingData,
    isLoading: MeetingLoading,
    refetch,
  } = useGetListOfMeetingQuery({ caseId }, { skip: !caseId });
  const { data: dataSchemes, isLoading: isLoadingSchemes } =
    useGetEffectiveSchemesQuery({ caseId }, { skip: !caseId });

  const getDataByScheme = { schemeVersionId: watch(BALLOT_SCHEME_ID) };
  const { data, isFetching: classLoading } =
    useGetListSchemeBySchemeVersionIDQuery(getDataByScheme);
  const [getBallotResultById, { isLoading: ballotResultLoading }] =
    useLazyGetBallotResultByIdQuery();
    let touchedFieldsArray=Object.keys(dirtyFields)
    let touchedFieldsFlag=touchedFieldsArray.length>0?true:false
    const dispatch=useDispatch()
    useEffect(() => {
      dispatch(setDiscardEditMode({ editMode: touchedFieldsFlag }));
      return () => {
        dispatch(setDiscardEditMode({ editMode: false }));
      };
    }, [dispatch,touchedFieldsFlag]);
  const SCHEME_OPTIONS: any[] = useMemo(() => {
    if (dataSchemes && !isLoadingSchemes) {
      return dataSchemes.map((item: VotingRecordType) => {
        return {
          label: item?.name || "",
          value: item?.schemeVersionId || "",
        };
      });
    }

    return [];
  }, [isLoadingSchemes, dataSchemes]);
  const defaultOption = { label:  t("global.globalActions.optionDefault"), value: "" };
  const SchemeOptions = [defaultOption, ...SCHEME_OPTIONS];
  let [schemeChanged, setSchemeChanged] = useState(false);
  let schemeId = getValues("schemeVersionId");
  useEffect(() => {
    setChildOptions([{ label:  t("global.globalActions.optionDefault"), value: "" }]);
    if (data && data.length > 0) {
      const subAccountList = data.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      if (!schemeChanged) {
        setValue("classId", ballotData.classId);
      } else {
        setValue("classId", "");
      }
      setChildOptions(subAccountList);
    }
  }, [
    data,
    ballotData.classId,
    ballotData?.status,
    setValue,
    schemeChanged,
    schemeId,t
  ]);

  const MEETING_OPTIONS: any[] =
    meetingData && !MeetingLoading
      ? meetingData.map((item: VotingMeetingRecordType) => {
          return {
            label: item?.name || "",
            value: item?.id || "",
          };
        })
      : [];
  const meetingOptions = [defaultOption, ...MEETING_OPTIONS];

  const [updateBallot] = useUpdateBallotMutation();
  const [createBallot] = useCreateBallotMutation();

  const onSubmit = async (data: VotingEditFormType) => {
    setIsLoading(true);
    setIsCancel(false);
    const votingApiParam = editView
      ? {
          id: editId,
          name: data?.name || "",
          description: data?.description || "",
          nameFrench: data?.nameFrench,
          descriptionFrench: data?.descriptionFrench,
          schemeVersionId: data?.schemeVersionId,
          classId: data?.classId,
          meetingId: data?.meetingId,
          isProxyRequired: data?.isProxyRequired,
          status: data?.status === "true",
          successConditionCreditorCount: !isNaN(
            Number(data?.successConditionCreditorCount)
          )
            ? Number(data.successConditionCreditorCount)
            : 0,
          successConditionClaimValue: !isNaN(
            Number(data?.successConditionClaimValue)
          )
            ? Number(data.successConditionClaimValue)
            : 0,
        }
      : {
          caseId: caseData.caseId,
          name: data?.name || "",
          description: data?.description || "",
          nameFrench: data?.nameFrench,
          descriptionFrench: data?.descriptionFrench,
          schemeVersionId: data?.schemeVersionId,
          classId: data?.classId,
          meetingId: data?.meetingId,
          isProxyRequired: data?.isProxyRequired,
          status: data?.status === "true",
          successConditionCreditorCount: !isNaN(
            Number(data?.successConditionCreditorCount)
          )
            ? Number(data.successConditionCreditorCount)
            : 0,
          successConditionClaimValue: !isNaN(
            Number(data?.successConditionClaimValue)
          )
            ? Number(data.successConditionClaimValue)
            : 0,
        };
        dispatch(setDiscardEditMode({ editMode: false }));

    if (editView) {
      const responseApi = await updateBallot(votingApiParam);
      //@ts-ignore
      if (responseApi.data) {
        if (handleHistoryApi) {
          handleHistoryApi();
        }
        setSchemeChanged(false);

        setIsLoading(false);
      } else {
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          //@ts-ignore
          message: responseApi?.error?.message,
        });
        dispatch(setDiscardEditMode({ editMode: true }));

        setIsLoading(false);
      }
    } else {
      const responseApi = await createBallot(votingApiParam);
      //@ts-ignore
      if (responseApi.data) {
        //@ts-ignore
        navigate(
          //@ts-ignore
          caseId ? `/case/${caseId}/voting/edit/` + responseApi.data : "/",
          {
            state: {
              showMessage: true,
              update: editView ? true : false,
              defaultActiveKey: 1,
            },
          }
        );
        setIsLoading(false);
      } else {
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          //@ts-ignore
          message: responseApi.error.message,
        });
        dispatch(setDiscardEditMode({ editMode: true }));

        setIsLoading(false);
      }
    }
  };

  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    navigate(returnUri, {
      state: { showMessage: false, defaultActiveKey: 1 },
    });
    dispatch(setDiscardEditMode({ editMode: true }));

  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsCancel(true);
    setIsVisible(false);
  };

  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false, type: "error", message: "" });
  };

  const renderMessage = () => {
    if (showMessage.show && !isVisible && !isCancel) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={showMessage.type}
        >
          {" "}
          {showMessage.message !== ""
            ? showMessage.message
            : showMessage.type === "error"
            ? t("global.globalNotifications.unExpectedError")
            : t("global.globalNotifications.updatedSuccessfully")}
        </MotifToast>
      );
    }
  };
  const checkBoxHandler = (e?: React.ReactEventHandler) => {
    setIsProxySelected(!isProxySelected);
  };
  /* Child Dropdown */
  const onAccountChange = () => {
    setSchemeChanged(true);
  };
  /* Child Dropdown */
  useEffect(() => {
    if (editView) {
      setValue("name", ballotData?.name);
      setValue("nameFrench", ballotData?.nameFrench);
      setValue("description", ballotData?.description);
      setValue("descriptionFrench", ballotData?.descriptionFrench);
      setValue("schemeVersionId", ballotData?.schemeVersionId);
      setValue("meetingId", ballotData?.meetingId);
      setValue("status", ballotData?.status ? "true" : "false");
      setValue("isProxyRequired", ballotData?.isProxyRequired);
      setValue(
        "successConditionCreditorCount",
        ballotData?.successConditionCreditorCount
      );
      setValue(
        "successConditionClaimValue",
        ballotData?.successConditionClaimValue
      );
      setIsReadonly(ballotData?.status);
    }
  }, [ballotData, editView, setValue]);

  useEffect(() => {
    if (BallotVerionSelect && BallotVerionSelect !== "Current Ballot") {
      setIsCurrentBallotSelect(false);
    } else {
      setIsCurrentBallotSelect(true);
    }
  }, [BallotVerionSelect]);
  useEffect(() => {
    if (isRefetch) {
      refetch();
      setIsRefetch(false);
    }
  }, [isRefetch, refetch]);
  useEffect(() => {
    meetingvalue !== "" && setValue("meetingId", meetingvalue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingData]);
  if (
    editView &&
    (!dataSchemes || ballotResultLoading
      ? true
      : false || !data || isLoadingSchemes || meetingOptions?.length <= 0)
  ) {
    return <MotifProgressLoader />;
  }

  const handleBallotResultRefresh = async () => {
    reset();
    getBallotResultById({ ballotId: editId }).then(async (value) => {
      setIsRefreshVersionList(true);

      if (handleHistoryApi) {
        await handleHistoryApi().then(() => {
          setSchemeChanged(false);

          setChildOptions([{ label: t("global.globalActions.optionDefault"), value: "" }]);
        });
      }
      setIsRefreshVersionList(true);
    });
  };
  return (
    <>
      {renderMessage()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`motif-container main-container debtor-form pad-t-20 ${
            !editView ? "create" : ""
          }`}
        >
          <div className="motif-row">
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlInputBoxVoting
                  name={BALLOT_ID}
                  control={control}
                  required={true}
                  isTextArea={false}
                  label={"pages.voting.formLabels.nameEnglish"}
                  showLabel={true}
                  readonly={!isReadonly || !isCurrentBallotSelect}
                />
                {errors[BALLOT_ID] && errors[BALLOT_ID].type === "required" && (
                  <MotifErrorMessage>{t("pages.voting.validationMessages.ballotNameRequired")}</MotifErrorMessage>
                )}
              </div>
            </div>
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlInputBoxVoting
                  name={BALLOT_ID_FRENCH}
                  control={control}
                  required={true}
                  isTextArea={false}
                  label={"pages.voting.formLabels.nameFrench"}                  showLabel={true}
                  readonly={!isReadonly || !isCurrentBallotSelect}
                />
                {errors[BALLOT_ID_FRENCH] &&
                  errors[BALLOT_ID_FRENCH].type === "required" && (
                    <MotifErrorMessage>
{t("pages.voting.validationMessages.ballotNameFrenchRequired")}                    </MotifErrorMessage>
                  )}
              </div>
            </div>
          </div>
          <div className="motif-row next-row">
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlTextAreaWithCharLimit
                  name={BALLOT_DESCRIPTION_ID}
                  control={control}
                  required={false}
                  isTextArea={true}
                  label={"pages.voting.formLabels.attributeDescriptionEnglish"} 
                  showLabel={true}
                  readonly={!isReadonly || !isCurrentBallotSelect}
                />
              </div>
            </div>
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlTextAreaWithCharLimit
                  name={BALLOT_DESCRIPTION_FRENCH_ID}
                  control={control}
                  required={false}
                  isTextArea={true}
                  label={"pages.voting.formLabels.attributeDescriptionFrench"}                   showLabel={true}
                  readonly={!isReadonly || !isCurrentBallotSelect}
                />
              </div>
            </div>
          </div>
          <div className="motif-row next-row">
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlSelectScheme
                  name={"schemeVersionId"}
                  //@ts-ignore
                  control={control}
                  showLabel={true}
                  label={"pages.voting.formLabels.scheme"} 
                  options={SchemeOptions}
                  required={true}
                  //@ts-ignore
                  setValue={setValue}
                  //@ts-ignore
                  getValues={getValues}
                  onChangeHandler={onAccountChange}
                  disabled={!isReadonly || !isCurrentBallotSelect}
                />
                {errors[BALLOT_SCHEME_ID] &&
                  errors[BALLOT_SCHEME_ID].type === "required" && (
                    <MotifErrorMessage>{t("pages.voting.validationMessages.schemeRequired")}</MotifErrorMessage>
                  )}
              </div>
            </div>
          </div>
          <div className="motif-row next-row">
            <div className="motif-col-md-3">
              <div className="form-field">
                <ChildDropdown
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  name={BALLOT_CLASS_ID}
                  label={"pages.voting.formLabels.allowedClass"} 
                  required={true}
                  options={childOptions}
                  disabled={!isReadonly || !isCurrentBallotSelect}
                />
                {errors[BALLOT_CLASS_ID] &&
                  errors[BALLOT_CLASS_ID].type === "required" && (
                    <MotifErrorMessage>{t("pages.voting.validationMessages.classRequired")}</MotifErrorMessage>
                  )}
                {classLoading ? <span>{t("global.globalNotifications.loadingMessage")}</span> : <></>}
              </div>
            </div>
            {editView ? (
              <div className="motif-col-md-3 exportcon">
                <MotifButton
                  type="button"
                  loading={exportLoading}
                  onClick={() => handleDownloadVotes(editId)}
                  disabled={!watch("classId")}
                  variant="primary"
                >
                  {t("pages.voting.formLabels.export")}
                </MotifButton>
                {exportError ? (
                  <MotifErrorMessage>{t("global.globalNotifications.wentWrong")}</MotifErrorMessage>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="motif-row next-row">
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlSelect
                  name={BALLOT_MEETING_ID}
                  //@ts-ignore
                  control={control}
                  required={true}
                  showLabel={true}
                  readonly={!isReadonly || !isCurrentBallotSelect}
                  label={"pages.voting.formLabels.meeting"}
                  placeholder={"placeholder"}
                  options={meetingOptions}
                  // setValue={setValue}
                  // getValues={getValues}
                />
                {errors[BALLOT_MEETING_ID] &&
                  errors[BALLOT_MEETING_ID].type === "required" && (
                    <MotifErrorMessage>{t("pages.voting.validationMessages.meetingRequired")}</MotifErrorMessage>
                  )}
              </div>
            </div>
            <div className="motif-col-md-3">
              <div className="form-field" style={{ marginTop: "30px" }}>
                <MotifButton
                  aria-label="Search Dropdown"
                  id="dropdown-trigger-1"
                  type="button"
                  disabled={!isReadonly || !isCurrentBallotSelect}
                  variant="text"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <MotifIcon iconFunction={IconoirPlus} />
                </MotifButton>
              </div>
            </div>
          </div>
          <div className="motif-row" style={{ marginTop: "20px" }}>
            <div className="motif-col-md-3">
              <div className="form-field">
                <span>
                  <ControlCheckBox
                    id="checkbox-french-selection"
                    onChange={checkBoxHandler}
                    checked={isProxySelected}
                    value={isProxySelected}
                    name={BALLOT_PROXY_ID}
                    control={control}
                    required={false}
                    disabled={!(isReadonly ?? false) || !isCurrentBallotSelect}
                    label={t("pages.voting.formLabels.proxy")}
                    setIsFrenchSelected={setIsProxySelected}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="motif-row" style={{ marginTop: "20px" }}>
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlSelect
                  name={BALLOT_STATUS}
                  //@ts-ignore
                  control={control}
                  required={true}
                  showLabel={true}
                  readonly={!(isReadonly ?? false) || !isCurrentBallotSelect}
                  label={"pages.voting.formLabels.ballotStatus"}
                  placeholder={"placeholder"}
                  options={[
                    { label: t("pages.voting.ballotStatusOptions.label1"), value: "" },
                    { label: t("pages.voting.ballotStatusOptions.label2"), value: "true" },
                    { label: t("pages.voting.ballotStatusOptions.label3"), value: "false" },
                 
                  ]}
                />
                {errors[BALLOT_STATUS] &&
                  errors[BALLOT_STATUS].type === "required" && (
                    <MotifErrorMessage>{t("pages.voting.validationMessages.statusRequired")}</MotifErrorMessage>
                  )}
              </div>
              
            </div>
          </div>
          <div className="motif-row next-row">
            <div className="motif-col-md-3">
              <MotifLabel children={t("pages.voting.formLabels.conditionForSuccess")}></MotifLabel>
            </div>
          </div>
          <div className="motif-row">
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlInputBoxVoting
                  name={BALLOT_VOTED_ID}
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.voting.formLabels.votedYes"}
                  showLabel={true}
                  readonly={!isReadonly || !isCurrentBallotSelect}
                />
              </div>
            </div>
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlInputBoxVoting
                  name={BALLOT_CLAIM_ID}
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.voting.formLabels.claimValue"}
                  showLabel={true}
                  readonly={!isReadonly || !isCurrentBallotSelect}
                />
              </div>
            </div>
          </div>
          <BallotResult
            ballotResult={ballotData.refreshBallot}
            isFetching={ballotResultLoading ? true : false}
          />
        </div>

        <div className="">
          {editView && (
            <Button
              type={isLoading ? "loader" : "submit"}
              label={t("global.globalActions.refresh")}
              variant={PRIMARY}
              className="refreshancelBtn"
              onClickHandler={handleBallotResultRefresh}
              disabled={!isReadonly || !isCurrentBallotSelect}
            />
          )}
          <Button
            label={t("global.globalActions.save")}
            type={isLoading ? "loader" : "submit"}
            disabled={!isReadonly || !isCurrentBallotSelect}
            variant={SECONDARY}
            loading={isLoading}
          />
          <Button
            type="button"
            label={t("global.globalActions.cancel")}
            variant={SECONDARY}
            className="cancelBtn"
            disabled={!isReadonly || !isCurrentBallotSelect}
            onClickHandler={(e) => {
              setShowMessage({
                ...showMessage,
                show: false,
                type: "error",
                message: "",
              });
              if(touchedFieldsFlag){
              setIsVisible(true);
              dispatch(setDiscardEditMode({ editMode: false }));
              }
              else{
                cancelBtnApproveHandler(e)
              }
            }}
          />
        </div>
      </form>
      <ConfirmationModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
      <MotifModal
        onClose={() => setShowModal(false)}
        show={showModal}
        size={"xl"}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <MotifModalHeader>{"Add New Meeting"}</MotifModalHeader>
        <div className="confirmationModalWrapper creditor_disabling_model">
          <MotifModalBody style={{ paddingTop: "20px" }}>
            <CreateMeetingAllInputFields
              setShowModal={setShowModal}
              setMeetingValue={setMeetingValue}
              meetingOptions={meetingOptions}
              isRefetch={isRefetch}
              setIsRefetch={setIsRefetch}
            />
          </MotifModalBody>
        </div>
      </MotifModal>
    </>
  );
};

export default VotingSummary;
