import { UnitDetailsForm } from "./type";

interface UnitConfigurationData {
  id: string;
  caseId: string;
  name: string;
  exchangeRate: string;
  decimalPlace: string;
  description: string;
  nameFrench: string;
  descriptionFrench: string;
  isDefaultCurrency: boolean;
  isActive: boolean;
  IsFrenchCheckboxChecked: boolean;
  isNewUnit: boolean;
}

export function postUnitConfiguration(data: UnitDetailsForm) {
  const postData: UnitConfigurationData = {
    id: data.id,
    caseId: data.caseId,
    name: data.unitName,
    exchangeRate: data.unitRate,
    decimalPlace: data.unitDecimalPlace,
    description: data.unitDescription,
    nameFrench: data.unitNameFrench,
    descriptionFrench: data.unitDescriptionFrench,
    isDefaultCurrency: data.isDefaultCurrency,
    isActive: data.isActive,
    IsFrenchCheckboxChecked: data.IsFrenchCheckboxChecked,
    isNewUnit: data.isNewUnit,
  };
  return postData;
}
