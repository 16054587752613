import { CreditorSetupPostType, CreditorSetupType } from "./type";
import { CreditorFormType } from "../CreditorSummaryUpdate/types";

export default function CrediotorSetupHelper() {
  const data: CreditorSetupType = {
    creditorSetupForm: {
      creditorType: "individual",
      firstName: "",
      lastName: "",
      prefix: "",
      businessName: "",
      description: "",
      relationShipToDebtor: "",
    },
    contactDetails: {
      fieldArray: [
        {
          firstName: "",
          lastName: "",
          prefix: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          country: "",
          email: "",
          phone: "",
          fax: "",
          postalCode: "",
          setAsPrimaryContact: false,
          addPaymentDetails: false,
          institutionNumber: "",
          transitNumber: "",
          accountNumber: "",
          ibanNumber: "",
          swiftCode: "",
          routingCode: "",
          achCode: "",
          relationShipToCreditor: "",
          usedAsAdditionalPayeeType:false,
          additionalPayeeTypeId:null,
        },
      ],
    },
    customAttributes: [],
    stage: 0,
    formSubmitIndex: 0,
    caseId: "",
  };
  return data;
}

export function postCreditorSetupData(data: CreditorSetupType) {
  const { creditorSetupForm, contactDetails, customAttributes } = data;
  const {
    creditorType,
    prefix,
    firstName,
    lastName,
    businessName,
    relationShipToDebtor,
    description,
  } = creditorSetupForm;
  const { fieldArray } = contactDetails;
  const postData: CreditorSetupPostType = {
    caseId: data.caseId,
    isOrganization: creditorType === "organization",
    prefix: prefix === "" ? null : prefix,
    firstName: firstName || "",
    lastName: lastName || "",
    businessName: businessName || "",
    description: description || "",
    relationShipToDebtor:relationShipToDebtor || "",
    isActive: true,
    //@ts-ignore
    contactList: fieldArray.map((contact) => {
      return {
        firstName: contact.firstName,
        lastName: contact.lastName,
        prefix: contact.prefix === "" ? null : contact.prefix,
        address1: contact.address1,
        address2: contact.address2,
        city: contact.city,
        provinceStateId: contact.state === "" ? null : contact.state,
        countryId: contact.country === "" ? null : contact.country,
        email: contact.email,
        phone: contact.phone,
        fax: contact.fax,
        postalCode: contact.postalCode,
        isPrimaryContact: contact.setAsPrimaryContact,
        relationshipToCreditor: contact.relationShipToCreditor,
        institutionNumber:contact.institutionNumber,
        addPaymentDetails: contact.addPaymentDetails,
        transitNumber:contact.addPaymentDetails ? contact.transitNumber : "",
        accountNumber:contact.addPaymentDetails ? contact.accountNumber : "",
        ibanNumber:contact.addPaymentDetails ? contact.ibanNumber : "",
        swiftCode:contact.addPaymentDetails ? contact.swiftCode : "",
        routingCode:contact.addPaymentDetails ? contact.routingCode : "",
        achCode:contact.addPaymentDetails ? contact.achCode : "",
        usedAsAdditionalPayeeType:contact.usedAsAdditionalPayeeType,
        additionalPayeeTypeId:contact.additionalPayeeTypeId || null,
      };
    }),
    customAttributes: customAttributes,
  };
  return postData;
}

export function postCreditorUpdateData(data: CreditorFormType) {
  const {
    firstName,
    lastName,
    businessName,
    relationShipToDebtor,
    description,
    displayName,
    creditorId,
    isOrganization,
    customAttributes,
  } = data;
  const { fieldArray } = data;

  const postData: CreditorSetupPostType = {
    creditorId: data.creditorId,
    displayName: displayName,
    firstName: firstName || "",
    lastName: lastName || "",
    businessName: businessName || "",
    description: description || "",
    relationShipToDebtor: relationShipToDebtor || "",
    isActive: true,
    isOrganization: isOrganization,
    customAttributes: customAttributes,
    prefix: data.prefix === "" ? null : data.prefix,
    //@ts-ignore
    creditorContacts: fieldArray.map((contact) => {
      return {
        firstName: contact.firstName,
        lastName: contact.lastName,
        prefix: contact.prefix === "" ? null : contact.prefix,
        address1: contact.address1,
        address2: contact.address2,
        city: contact.city,
        provinceStateId:
          contact.state === "" ||
          contact.state === "00000000-0000-0000-0000-000000000000"
            ? null
            : contact.state,
        countryId: contact.country === "" ? null : contact.country,
        email: contact.email,
        phone: contact.phone,
        fax: contact.fax,
        postalCode: contact.postalCode,
        isPrimaryContact: contact.setAsPrimaryContact,
        relationshipToCreditor: contact.relationShipToCreditor,
        creditorId: creditorId,
        creditorContactId: contact.creditorContactId,
        isActive: true,
        institutionNumber:contact.institutionNumber,
        transitNumber:contact.transitNumber,
        accountNumber:contact.accountNumber,
        ibanNumber:contact.ibanNumber,
        swiftCode:contact.swiftCode,
        routingCode:contact.routingCode,
        achCode:contact.achCode,
        usedAsAdditionalPayeeType:contact.usedAsAdditionalPayeeType,
        additionalPayeeTypeId:contact.additionalPayeeTypeId === "" ? null : contact.additionalPayeeTypeId,
      };
    }),
    creditorDistributionStatus: null,
  };
  return postData;
}
