export type CreateSchemeFormType = {
    schemeName: string;
    schemeDescName?:string;
    schemeFrName?:string;
    shemeDescFrName?:string;
}
export interface ClassType {
    id?: string;
    name?: string;
    description?: string;
    schemeVersionId?: string;
    isDefault?: boolean;
    displayOrder?: number;
    
}

export const EmptyClass : ClassType = {
    id: undefined,
    name: undefined,
    description: "",
    schemeVersionId: undefined,
    isDefault: false,
    displayOrder: 0
};