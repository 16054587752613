import React, { Dispatch, SetStateAction, useState } from "react";
import {
  MotifErrorMessage,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "@/components/Form/Button/Button";
import { SECONDARY } from "@/Constant";
import { AddDebtorFormType } from "./type";
import "./FormModal.scss";
import { compareNumeric, isNumeric } from "@/utility/validation";
// import { capitalizeWordsInSentence } from "@/utility/common";
import ControlInputBoxNumber from "@/components/Form/InputBox/ControlInputBoxNumber";
import { RateTierType } from "../type";
import { useCreateRateTierApiMutation } from "../calculationStepReducer";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";

type Props = {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  formData?: AddDebtorFormType | null;
  caseId: string;
  stepId: string;
  setIsRefetch: Dispatch<SetStateAction<boolean>>;
};

export default function FormModalRateTier({
  isVisible,
  setIsVisible,
  stepId,
  caseId,
  setIsRefetch,
}: Props) {
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleCancel, setIsVisibleCancel] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors, isDirty,dirtyFields },
  } = useForm<any>({
    defaultValues: {
      rate: 1,
    },
  });
  let dirtyFieldsLength = Object.keys(dirtyFields).length;
  // const errorList = Object.keys(errors);
  // const errorsShow = capitalizeWordsInSentence(errorList.join(", "));
  let [createRateTierApi] = useCreateRateTierApiMutation();

  // //                {errors["rate"] && errors["rate"].type === "required" && (
  //   <MotifErrorMessage>Rate is required</MotifErrorMessage>
  // )}

  const checkEmptyFields = Object.keys(errors).filter((key) => {
    return errors[key]?.type === "required";
  });
  
  const setFormat = (value: any) => {
    return value === "" || value == null || value === 0 ? null : Number(value);
  };

  const onSubmit = async (data: RateTierType) => {
    const diff = compareNumeric(data?.maxAmount, data?.minAmount);
    if (diff < 0) {
      setError("maxAmount", {
        type: "manual",
        message: "greater",
      });
      return;
    }
    setIsLoading(true);
    try {
      let postData = {
        caseId: caseId,
        calculationStepId: stepId,
        maxAmount:setFormat(data?.maxAmount),
        minAmount: setFormat(data?.minAmount),
        rate: data?.rate,
        adjustment: data?.adjustment === "" ? null : Number(data?.adjustment),
        // rateTierGroupBy: Number(data?.rateTierGroupBy),
      };
      const responseApi = await createRateTierApi(postData);

      if (responseApi.hasOwnProperty("data")) {
        reset({
          //@ts-ignore
          maxAmount: "",
          //@ts-ignore
          minAmount: "",
          //@ts-ignore
          rate: 1,
          //@ts-ignore
          adjustment: "",
        });
        setIsLoading(false);
        setIsRefetch(true);
        setIsVisible(false);
      } else {
        const error = t("global.globalNotifications.unExpectedError");
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          message: t(error),
        });
        setIsLoading(false);
      }
    } catch (error) {
      setShowMessage({ ...showMessage, show: true, type: "error" });
    }
  };
  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    setIsVisibleCancel(false);
    reset()
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisibleCancel(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  return (
    <>
      <MotifModal
        onClose={() => {  if(dirtyFieldsLength && dirtyFieldsLength>0){
          setIsVisibleCancel(true);

        }
        else{
          setIsVisible(false);
          setIsVisibleCancel(false);
          reset()
          setShowMessage({ ...showMessage, show: false, type: "error" });
        }}}
        show={isVisible}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        {/*@ts-ignore*/}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formModalWrapper">
            <MotifModalHeader>
              <span className="discard_heading">{t("pages.distributions.distributionStepsTab.rateTierTab.formLabels.createTitle")}</span>
            </MotifModalHeader>
            <MotifModalBody>
              <div className="formModal">
                {/* <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlSelect
                      name={"rateTierGroupBy"}
                      //@ts-ignore
                      control={control}
                      required={true}
                      showLabel={true}
                      readonly={true}
                      label={t("Type")}
                      //@ts-ignore
                      placeholder={"placeholder"}
                      options={[
                        { label: "Select Type", value: "" },
                        { label: "Creditor", value: "0" },
                        { label: "Claim", value: "1" },
                        { label: "Creditor/Assignee", value: "2" },
                      ]}
                    />
                  </div>
                </div> */}

                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlInputBoxNumber
                      name={"maxAmount"}
                      // @ts-ignore
                      control={control}
                      required={false}
                      isTextArea={false}
                      label={"pages.distributions.distributionStepsTab.rateTierTab.formLabels.tierMaxAmount"}
                      showLabel={true}
                      // setValue={setValue}
                      isNumber={true}
                      digitAfterDecimal={2}
                      isAmount={true}
                      onChangeAmount={() => {}}
                    />
                  </div>
                </div>
                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlInputBoxNumber
                      name={"minAmount"}
                      // @ts-ignore
                      control={control}
                      required={false}
                      isTextArea={false}
                      label={"pages.distributions.distributionStepsTab.rateTierTab.formLabels.tierMinAmount"}
                      showLabel={true}
                      // setValue={setValue}
                      isNumber={true}
                      digitAfterDecimal={2}
                      isAmount={true}
                      onChangeAmount={() => {}}
                    />
                  </div>
                </div>
                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlInputBoxNumber
                      name={"rate"}
                      // @ts-ignore
                      control={control}
                      required={true}
                      isTextArea={false}
                      label={"pages.distributions.distributionStepsTab.rateTierTab.formLabels.rate"}
                      showLabel={true}
                      // setValue={setValue}
                      isNumber={true}
                      digitAfterDecimal={2}
                      isAmount={true}
                      onChangeAmount={() => {}}
                      validate={isNumeric}
                    />
                  </div>
                </div>
                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlInputBoxNumber
                      name={"adjustment"}
                      // @ts-ignore
                      control={control}
                      required={false}
                      isTextArea={false}
                      label={"pages.distributions.distributionStepsTab.rateTierTab.formLabels.adjustment"}
                      showLabel={true}
                      // setValue={setValue}
                      isNumber={true}
                      digitAfterDecimal={2}
                      isAmount={true}
                      onChangeAmount={() => {}}
                      // validate={isNumeric}
                    />
                  </div>
                </div>
              </div>
              <div className="error-container">
                {checkEmptyFields?.length > 0 && (
                  <MotifErrorMessage>
                    {t("pages.distributions.distributionStepsTab.rateTierTab.validationMessages.mainMessage")}
                  </MotifErrorMessage>
                )}
                {!isDirty && errors["maxAmount"]?.message === "greater" && (
                  <MotifErrorMessage>
                                       {t("pages.distributions.distributionStepsTab.rateTierTab.maxAmountWarningMessage")}

                  </MotifErrorMessage>
                )}
              </div>
            </MotifModalBody>
            <MotifModalFooter>
              <div className="button-container-add">
                <Button label={t("global.globalActions.save")} disabled={isLoading} />
                <Button
                  label={t("global.globalActions.cancel")}
                  type="button"
                  variant={SECONDARY}
                  className="cancelBtn"
                  onClickHandler={() => {
                    if(dirtyFieldsLength && dirtyFieldsLength>0){
                      setIsVisibleCancel(true);
                    

                    }
                    else{
                      setIsVisible(false);
    setIsVisibleCancel(false);
    reset()
    setShowMessage({ ...showMessage, show: false, type: "error" });

                    }
                  }}
                />
              </div>
            </MotifModalFooter>
          </div>
        </form>
      </MotifModal>
      <ConfirmationModal
        isVisible={isVisibleCancel}
        setIsVisible={setIsVisibleCancel}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
    </>
  );
}
