import { useEffect, useState } from "react";
import {
  MotifHeader,
  MotifHeaderLogo,
  MotifIcon,
  MotifButton,
} from "@ey-xd/motif-react";
import { IconoirMenu } from "@ey-xd/motif-icon";
import { useTranslation } from "react-i18next";
import { HEADER } from "@/appConstants";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { CaseState } from "@/features/case/case-slice";
import UserAvatar from "@/features/auth/user-avatar";

import EYLogo from "./icons/ey_logo.json";
import "./header.scss";
interface HeaderProps {
  toggleCollapse: () => void;
  showAvatar: boolean;
}

const Header: React.FC<HeaderProps> = ({ toggleCollapse, showAvatar }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const caseData: CaseState = useSelector((state: any) => state.case);
  const [showCaseName, setShowCaseName] = useState(false);

  useEffect(() => {
    if (pathname && pathname.includes(`case/${caseData?.caseId}`)) {
      setShowCaseName(true);
    } else {
      setShowCaseName(false);
    }
    return () => {
      setShowCaseName(false);
    };
  }, [pathname, caseData]);

  return (
    <>
      <MotifHeader
        className="motif-header header-component motif-subtitle-default-regular"
        logo={
          <MotifHeaderLogo>
            <a aria-label="EY Logo - Home" href="/">
              <MotifIcon
                src={EYLogo.base64}
                aria-hidden="true"
                stroke="8"
                size="30"
              />
            </a>
          </MotifHeaderLogo>
        }
        leftComponents={
          <MotifButton
            onClick={toggleCollapse}
            size="small"
            type="submit"
            className="hamburger-menu-button motif-button motif-button-primary"
          >
            <MotifIcon src={IconoirMenu} />
          </MotifButton>
        }
        rightComponents={
          <div className="display-flex align-items-baseline">
            <div className="motif-h6-default-light mar-r-5 case-id">{""}</div>
            {/* Remove case in header for now until global redux state is implemented */}
            <div className="motif-h6-default-regular mar-l-5 case-name">
              {(showCaseName && caseData?.caseName) || ""}
            </div>
            {showAvatar && <UserAvatar />}
          </div>
        }
        appHeaderName={t("pages.header.applicationHeading", {
          applicationHeading: HEADER.applicationNameInHeader,
        })}
      />
    </>
  );
};

export default Header;
