import { ccwpApi } from "@/services";

export const ClassApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getClassList: builder.query<any, string>({
      query: (schemeId) => {
        return {
          url:`Class/ListClassBySchemeVersion?schemeVersionId=${schemeId}`
        };
      },
    }),
    createClass: builder.mutation({
      query: (classData) => ({
        url: "Class/CreateClass",
        method: "POST",
        body: classData,
      }),
    }),
    UpdateClass: builder.mutation({
      query: (classData) => ({
        url: "Class/UpdateClass",
        method: "PUT",
        body: classData,
      }),
    }),
    deleteClass: builder.query({
      query: (id) => ({
        url: `Class/DeleteClass?classId=${id}`,
        method: "DELETE",
      }),
    }),

  }),
});

export const { useGetClassListQuery, useCreateClassMutation, useUpdateClassMutation, useLazyDeleteClassQuery} = ClassApi;