import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PERMISSION_TYPE } from "./type";

export const initialState: PERMISSION_TYPE = {
    Creditor: ["Read"],
    Debtor: ["Read"],
    Unit: ["Read"],
    ClaimItemCategory: ["Read"],
    ClaimStageDefinition: ["Read"],
    ClaimStatusDefinition: ["Read"],
    RateTier: ["Read"],
    RateTierTemplate: ["Read"],
    CaseType: ["Read"],
    User: ["Read"],
    UserCaseGroup: ["Read"],
    AttributeDefinition: ["Read"],
    AttributeValue: ["Read"],
    Scheme: ["Read"],
    SchemeVersion: ["Read"],
    Class: ["Read"],
    Rule: ["Read"],
    ClaimStageInstance: ["Read"],
    ClaimStatusInstance: ["Read"],
    Claim: ["Read"],
    ClaimItem: ["Read"],
    ClaimHistorySnapshot: ["Read"],
    ClaimItemHistorySnapshot: ["Read"],
    Account: ["Read"],
    CalculationStep: ["Read"],
    CalculationSettings: ["Read"],
    Distribution: ["Read"],
    StepClassFilter: ["Read"],
    StepDataSource: ["Read"],
    StepLimitAmount: ["Read"],
    PayeeType: ["Read"],
    Ballot: ["Read"]
};

const permissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions(_state: PERMISSION_TYPE, action: PayloadAction<PERMISSION_TYPE>) {
      return  action.payload;
    },
  },
});

export const { setPermissions } = permissionSlice.actions;
export default permissionSlice.reducer;