import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AttributesItemsState {
  attributes: any[];
}

const initialState = {
  attributes: [],
};

export const claimSetupAttributes = createSlice({
  name: "claimSetupAttribute",
  initialState,
  reducers: {
    setClaimItemsAttributes: (state, action: PayloadAction<any>) => {
      state.attributes = action.payload;
    },
  },
});

export const { setClaimItemsAttributes } = claimSetupAttributes.actions;

export default claimSetupAttributes.reducer;
