import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  MotifButton,
  MotifChip,
  MotifIcon,
  MotifProgressLoader,
  MotifErrorMessage,
  MotifAccordion,
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react";
import { IconoirEditPencil } from "@ey-xd/motif-icon";
import {
  useGetclaimStageAndDocumentApiDataQuery,
  useGetClaimStatusApiDataQuery,
  useGetClaimSummaryViewApiDataQuery,
} from "@/services/claimSummary/caseSummaryReducer";
import {  useNavigate, useParams } from "react-router-dom";
import { dateFunc } from "@/components/Functions/dateFunc";
import StageStatusPopup from "./stageStatusPopup/statgeStatusPopup";
import { DocumentPopUp } from "./documentPopUp/documentPopUp";
import CustomAttributeValue from "@/components/CustomAttribute/CustomAttributeValue";
import { useLazyDownloadDocumentQuery } from "@/services/documents/documentsReducer";
import { useCase } from "@/features/case/use-case";

export const ClaimSummary = ({ setEditPage }: any) => {
  const parameter = useParams();
  const { t } = useTranslation();
  const navigate=useNavigate()
  const { data, isLoading, error } = useGetClaimSummaryViewApiDataQuery(
    parameter?.claimId
  );
  const { data: claimStageData, refetch } =
    useGetclaimStageAndDocumentApiDataQuery(parameter?.claimId);
  const [downloadDocument] = useLazyDownloadDocumentQuery();
  const [showStageEdit, setShowEdit] = useState(false);
  const [showDocumentEdit, setDocumentEdit] = useState(false);
  const stageDocId = claimStageData
    ? claimStageData.instanceData.stageDocumentId
    : undefined;
  const caseId = data ? data?.claimSummary?.caseId : undefined;
  const { data: getClaimStatus } = useGetClaimStatusApiDataQuery(
    claimStageData?.definitionIds.claimStatusDefinitionId,
    { skip: !claimStageData?.definitionIds.claimStatusDefinitionIds }
  );
const caseData = useCase();
  const handleFileDownload = (e: any) => {
    e.preventDefault();
    downloadDocument({
      caseId: caseId,
      documentId: stageDocId,
      fileName: claimStageData?.instanceData?.stageDocumentName,
    });
  };

  useEffect(() => {
    if (!showStageEdit && !showDocumentEdit) {
      refetch();
    }
    // eslint-disable-next-line
  }, [showStageEdit, showDocumentEdit]);
  function claimAssigneeContact(getAssignneData: any) {
    let filteredData = getAssignneData?.filter((getPrimaryData: any) => {
      if (getPrimaryData?.isPrimaryContact === true) {
        return true;
      } else {
        return false;
      }
    });
    return filteredData;
  }
  function handlecreditornav(creditorId: string, pathName: string) {
    if(creditorId){
      navigate(pathName, { state: { from: creditorId } });
    }
  }
  return (
    <>
      <div className="motif-container case-summary-form pad-v-20 mar-t-20">
        <div className="motif-row justify-content-between align-items-center">
          <div className="motif-col-4">
            <div className="motif-body1-default-regular page-title">
              {t("pages.claims.claimsviewTab.title")}
            </div>
            <div className="motif-body2-default-regular page-description">
            {t("pages.claims.claimsviewTab.description")}
            </div>
          </div>
          <div className="motif-col-4">
            <MotifButton
              size="small"
              type="submit"
              variant="secondary"
              onClick={() => setEditPage(true)}
            >
              {t("global.globalActions.edit")}
            </MotifButton>
          </div>
        </div>
      </div>
      <div className="motif-container main-container ccwp_claim_summary_form pad-t-20 mar-b-20 pad-b-20">
        {data?.claimSummary.length === 0 ? (
          <p>{t("global.globalNotifications.noDatFound")}</p>
        ) : isLoading ? (
          <MotifProgressLoader show variant="default" className="mar-b-20" />
        ) : data?.claimSummary ? (
          <div className="ccwp_claim_summary_form_subwrapper">
            <div className="motif-row">
              <div className="motif-col-lg-8 pad-0">
                <ul className="ccwp_claim_summary_details">
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.claimId")}</label>
                    <p>{data?.claimSummary?.claimReference}</p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.lastUpdatedDate")}</label>
                    <p>{dateFunc(data?.claimSummary?.updatedOn).formattedDateTime + ' ' + caseData?.abbreviation}</p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.initialFileDate")}</label>
                    <p>{dateFunc(data?.claimSummary?.initialFileDate).formattedDateOnly}</p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.source")}</label>
                    <p>{data?.claimSummary?.source}</p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.debtorName")}</label>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handlecreditornav(
                          data?.claimSummary?.debtorId,
                          `/case/${caseId}/debtor/${data?.claimSummary?.debtorId}`
                        )
                      }
                    >
                      {data?.claimSummary?.debtorName}
                    </p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.creditorName")}</label>

                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handlecreditornav(
                          data?.claimSummary?.creditorId,
                          `/case/${caseId}/creditor/${data?.claimSummary?.creditorId}`
                        )
                      }
                    >
                      {data?.claimSummary?.creditorName}
                    </p>
                  </li>
                </ul>
              </div>
              <div className="motif-col-lg-4 pad-0">
                <ul className="ccwp_claim_summary_status">
                  {" "}
                  <li>
                    <label>{t("pages.claims.claimsviewTab.formLabelDetails.claimStage")}</label>
                    <MotifChip onRemove={function Ua() {}} title="Chip Title">
                      {claimStageData?.instanceData?.claimStageName}
                    </MotifChip>
                  </li>
                  <li>
                    <label>{t("pages.claims.claimsviewTab.formLabelDetails.actionStatus")}</label>
                    <MotifChip
                      onRemove={function Ua() {}}
                      title="Chip Title"
                      variant="success"
                    >
                      {claimStageData?.instanceData?.claimStatusName}
                    </MotifChip>
                    <span
                      className="stage-status-edit"
                      onClick={() => setShowEdit(true)}
                    >
                      <MotifIcon
                        iconFunction={IconoirEditPencil}
                        stroke="#FFFFFF"
                        strokeWidth="2"
                        size="15"
                      />
                    </span>
                  </li>
                  <li>
                    <label>{t("pages.claims.claimsviewTab.formLabelDetails.stageDocument")}</label>
                    <label
                      className="stage-docoument"
                      style={
                        getClaimStatus?.isResolveStatus
                          ? { pointerEvents: "none", cursor: "none" }
                          : {}
                      }
                    >
                      {claimStageData?.instanceData?.stageDocumentName ? (
                        <a
                          href="/"
                          title={
                            claimStageData?.instanceData?.stageDocumentName
                          }
                          onClick={handleFileDownload}
                        >
                          {claimStageData?.instanceData?.stageDocumentName}
                        </a>
                      ) : (
                        <span>{t("pages.claims.claimsviewTab.formLabelDetails.noDocument")}</span>
                      )}
                      <button onClick={() => setDocumentEdit(true)}>
                        <MotifIcon
                          iconFunction={IconoirEditPencil}
                          stroke="#FFFFFF"
                          strokeWidth="2"
                          size="15"
                        />
                      </button>
                    </label>
                  </li>
                  <li className="pad-r-0">
                    <label>{t("pages.claims.claimsviewTab.formLabelDetails.changeStatusDate")}</label>
                    <p>
                      {dateFunc(claimStageData?.instanceData?.statusUpdatedOn).formattedDateTime + ' ' + caseData?.abbreviation}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : null}

        <div className="ccwp_claim_summary_black_accordian">
          {/* <MotifAccordion
            onClose={function Ua() {}}
            onOpen={function Ua() {}}
            useChevronIcon={true}
            alignIconRight={true}
          >
            <MotifAccordionTrigger>Attributes</MotifAccordionTrigger>
            <MotifAccordionContent className="ccwp_claim_summary_accordian_wrapper">
              <div className="motif-row justify-content-between">
                <div className="motif-col-4 ccwp_claim_summary_accordian_subwrapper">
                  <label>Claim ID</label>
                  <p>14657</p>
                </div>
                <div className="motif-col-4 ccwp_claim_summary_accordian_subwrapper">
                  <label>Claim ID</label>
                  <p>Lorem ipsum.</p>
                </div>
                <div className="motif-col-4 ccwp_claim_summary_accordian_subwrapper">
                  <label>Last Updated Date</label>
                  <p>Dec 20, 2023</p>
                </div>
                <div className="motif-col-4 ccwp_claim_summary_accordian_subwrapper">
                  <label>Debtor</label>
                  <p>New case company - 1023</p>
                </div>
              </div>
            </MotifAccordionContent>
          </MotifAccordion> */}
          {error ? (
            <MotifErrorMessage
              style={{
                textAlign: "center",
                display: "block",
                alignItem: "center",
              }}
            >
{t("global.globalNotifications.wentWrong")}            </MotifErrorMessage>
          ) : isLoading ? (
            <MotifProgressLoader show variant="default" />
          ) : data ? (
            <>
              <MotifAccordion
                onClose={function Ua() {}}
                onOpen={function Ua() {}}
                useChevronIcon={true}
                alignIconRight={true}
              >
                <MotifAccordionTrigger>{t("pages.claims.commonFieldLabels.attributes")}</MotifAccordionTrigger>
                <MotifAccordionContent className="ccwp_claim_summary_accordian_wrapper">
                  <div className="ccwp_claim_summary_white_accordian">
                    <ul className="ccwp_claim_summary_details">
                      {data?.customAttributes.length === 0 ? (
                        <span>{t("pages.claims.noAttributesLabel")}</span>
                      ) : (
                        data?.customAttributes.map((attribute: any) => {
                          return (
                            <li>
                              <label>{attribute.name}</label>
                              <p>
                                <CustomAttributeValue
                                  attributeType={attribute.value.attributeType}
                                  value={attribute.value}
                                  picklistDefinitions={
                                    attribute.picklistDefinitions
                                  }
                                />
                              </p>
                            </li>
                          );
                        })
                      )}
                    </ul>
                  </div>
                </MotifAccordionContent>
              </MotifAccordion>

              <MotifAccordion
                onClose={function Ua() {}}
                onOpen={function Ua() {}}
                useChevronIcon={true}
                alignIconRight={true}
                open={true}
              >
                <MotifAccordionTrigger>{t("pages.claims.commonFieldLabels.assignees")}</MotifAccordionTrigger>
                <MotifAccordionContent className="ccwp_claim_summary_accordian_wrapper">
                  <div className="ccwp_claim_summary_white_accordian">
                    {data?.claimAssignee ? (
                      <MotifAccordion
                        onClose={function Ua() {}}
                        onOpen={function Ua() {}}
                        style={{
                          flex: "1",
                        }}
                        alignIconRight={true}
                        useChevronIcon={true}
                      >
                        <MotifAccordionTrigger>
                          <div className="ccwp_claim_summary_white">
                            <label>{t("pages.claims.commonFieldLabels.asigneeName")}</label>
                            <p>{data?.claimAssignee?.assigneeName}</p>
                          </div>
                        </MotifAccordionTrigger>
                        <MotifAccordionContent>
                          {claimAssigneeContact(
                            data?.claimAssignee?.contact
                          ).map((getPrimaryData: any) => {
                            return (
                              <>
                                <ul className="ccwp_claim_summary_details">
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.firstName")}</label>
                                    <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handlecreditornav(
                                        data?.claimAssignee?.assigneeId,
                                        `/case/${caseId}/creditor/${data?.claimAssignee?.assigneeId}`
                                      )
                                    }
                                    >
                                      {getPrimaryData?.firstName &&
                                        getPrimaryData?.firstName}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.lastName")}</label>
                                    <p>
                                      {!getPrimaryData?.lastName
                                        ? "-"
                                        : getPrimaryData?.lastName}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.address1")}</label>
                                    <p>
                                      {!getPrimaryData?.address1
                                        ? "-"
                                        : getPrimaryData?.address1}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.address2")}</label>
                                    <p>
                                      {!getPrimaryData?.address2
                                        ? "-"
                                        : getPrimaryData?.address2}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.city")}</label>
                                    <p>
                                      {!getPrimaryData?.city
                                        ? "-"
                                        : getPrimaryData?.city}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.provincestate")}</label>
                                    <p>
                                      {!getPrimaryData?.provinceState
                                        ? "-"
                                        : getPrimaryData?.provinceState}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.postalCode")}</label>
                                    <p>
                                      {!getPrimaryData?.postalCode
                                        ? "-"
                                        : getPrimaryData?.postalCode}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.phone")}</label>
                                    <p>
                                      {!getPrimaryData?.phone
                                        ? "-"
                                        : getPrimaryData?.phone}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.email")}</label>
                                    <p>
                                      {!getPrimaryData?.email
                                        ? "-"
                                        : getPrimaryData?.email}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.lastUpdatedOn")}</label>
                                    <p>
                                      {!getPrimaryData?.updatedOn
                                        ? "-"
                                        : dateFunc(getPrimaryData?.updatedOn).formattedDateOnly}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.lastUpdatedBy")}</label>
                                    <p>
                                      {!getPrimaryData?.updatedBy
                                        ? "-"
                                        : getPrimaryData?.updatedBy}
                                    </p>
                                  </li>
                                </ul>
                              </>
                            );
                          })}
                        </MotifAccordionContent>
                      </MotifAccordion>
                    ) : (
                      <></>
                    )}
                  </div>
                </MotifAccordionContent>
              </MotifAccordion>
            </>
          ) : null}
        </div>
      </div>
      <StageStatusPopup
        key={parameter?.claimId}
        show={showStageEdit}
        setShow={setShowEdit}
        claimId={parameter?.claimId!}
      />
      <DocumentPopUp
        show={showDocumentEdit}
        setShow={setDocumentEdit}
        caseId={caseId}
        currentInstanceData={claimStageData}
        resolvedStatus={getClaimStatus?.isResolveStatus}
      />
    </>
  );
};
