import React from "react";
import { Control, Controller } from "react-hook-form";
import CheckBox from "@/components/Form/CheckBox/CheckBox";
import { useController } from "react-hook-form";
import { VotingEditFormType } from "./type";
type CheckBoxType = {
  id?: string | undefined;
  className?: string | undefined;
  name: string;
  checked: boolean;
  required?: boolean;
  value: boolean;
  disabled?: boolean;
  label?: string | undefined;
  onChange: () => void;
  control: Control<VotingEditFormType>;
  setIsFrenchSelected: (value: React.SetStateAction<boolean>) => void;
};

export default function ControlCheckBox({
  id,
  className,
  name,
  checked,
  required,
  label,
  control,
  disabled,
  setIsFrenchSelected,
}: CheckBoxType) {
  const { field } = useController({
    // @ts-ignore
    name,
    control,
    rules: { required: required },
  });

  return (
    <>
      <Controller
        name={field.name}
        control={control}
        rules={{ required }}
        render={({ field: { onChange, value, ...args } }) => {
          return (
            <>
              <CheckBox
                id={id}
                className={className}
                checked={checked}
                required={required}
                value={value ? true : false}
                onChange={onChange}
                disabled={disabled ?? false}
                label={label}
                setIsFrenchSelected={setIsFrenchSelected}
                {...args}
              />
            </>
          );
        }}
      />
    </>
  );
}
