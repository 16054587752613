import PageSubTitle from '@/components/Form/PageSubTitle/PageSubTitle'
import React, { useEffect, useState } from 'react'
import {  CLAIM_STAGE_NAME, FILE_DISPLAY_NAME, STAGE_COMMENT_NAME } from '../Constants'
import { ClaimSetupDescription } from './type'
import { useForm } from 'react-hook-form'
import { MotifProgressLoader, MotifErrorMessage, MotifFormField, MotifFileUploader, MotifFileUploaderItem, MotifIcon, MotifButton } from '@ey-xd/motif-react'
import ControlSelect from "./ControlSelectClaimSetupDescription";
import { useListClaimStagesByCaseQuery } from "@/services/stages/claimStagesByCaseReducer";
import Button from '@/components/Form/Button/Button'
import {  SECONDARY } from '@/Constant'
import { useDispatch, useSelector } from "react-redux";
import { setStageSelectedItems, resetDescriptionState, setStageDocument } from './descriptionSlice';
import { useTranslation } from 'react-i18next'
import { DescriptionItemsState } from './descriptionSlice';
import ConfirmationModal from '@/components/Modal/ConfirmationModal/ConfirmationModal'
import { useNavigate, useParams } from "react-router-dom";
import useResetAllClaimItemsStore from "../useResetAllClaimItemsStore";
import ControlInputBox from '../ClaimItems/ControlInputBoxClaimItems'
import {
  actionIcDescription24px,
  editorIcVerticalAlignBottom24px,
} from "@ey-xd/motif-react/assets/icons";
import { setDiscardEditMode } from '@/components/Modal/ConfirmationModal/discardchanges'
import { fileExtensions } from "@/services/documents/documentsReducer";

interface RootState {
  claimSetupDescription: DescriptionItemsState;
}
interface DebtorProps {
  goToNextTab: () => void;
  file: File | null;
  setFile: (file: File | null) => void;
}
export default function Description({
  goToNextTab,
  file,
  setFile,
}: DebtorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { caseId } = useParams();
  const safeCaseId = caseId ?? "";
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const resetAllClaimItemsStore = useResetAllClaimItemsStore();

  const stageDocumentState = useSelector(
    (state: any) => state.claimSetupDescription?.stageDocument
  );

  const filename = file?.name || stageDocumentState?.document;

  const stageName = useSelector(
    (state: RootState) =>
      state.claimSetupDescription.selectedStageCompleteDetail.name
  );

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    watch,
    reset,
  } = useForm<ClaimSetupDescription>({
    defaultValues: {
      //claimStage: stageName,
      fileDislayName: stageDocumentState?.displayName || "",
      stageDocumentComment: stageDocumentState?.comment || "",
    },
  });
  const {
    data: stageData,
    isLoading: isStageDataLoading,
    error: errorInStageData,
  } = useListClaimStagesByCaseQuery(safeCaseId);

  let CLAIM_STAGE_OPTIONS = [];
  if (!isStageDataLoading && !errorInStageData && stageData) {
    const claimStageNames = stageData
      .map((item: { name: string }) => item.name)
      .filter((name: string) => name !== "");
    CLAIM_STAGE_OPTIONS = claimStageNames.map((name: string) => ({
      label: name,
      value: name,
    }));
  } else {
    CLAIM_STAGE_OPTIONS = [{ label: "", value: "" }];
  }

  if (isStageDataLoading) {
    <MotifProgressLoader show variant="default" />;
  }

  if (errorInStageData) {
    <MotifErrorMessage
      style={{
        textAlign: "center",
        display: "block",
        alignItem: "center",
      }}
    >
    {t("global.globalNotifications.wentWrong")}
    </MotifErrorMessage>;
  }

  const watchStageSelection = watch(CLAIM_STAGE_NAME);

  const selectedStageCompleteDetail = stageData?.find(
    (stage: { name: string }) => stage.name === watchStageSelection
  );

  const onSubmit = async () => {
    dispatch(setStageSelectedItems(selectedStageCompleteDetail));
  };

  const moveDataToStoreandMoveToNextTab = () => {
    dispatch(setStageSelectedItems(selectedStageCompleteDetail));
    dispatch(
      setStageDocument({
        caseId: caseId,
        fileName: file ? file.name : null,
        displayName: watch(FILE_DISPLAY_NAME),
        comment: watch(STAGE_COMMENT_NAME),
        document: file?.name || null,
      })
    );
    goToNextTab();
  };

  const handleDescriptionCancel = () => {
    setIsVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));
  };

  const handleDescriptionReset = () => {
    dispatch(resetDescriptionState());
    setIsVisible(false);
    reset({
      claimStage: "",
    });
    resetAllClaimItemsStore();
    navigate(`/case/${caseId}?activeTab=3`);
  };

  useEffect(() => {
    if (stageName && stageData) {
      const stage = stageName;
      setValue(CLAIM_STAGE_NAME, stage || "", { shouldDirty: false });
    }
  }, [stageName, setValue, stageData]);

  const handleFileChange = (files: any) => {
    const file = Array.isArray(files) ? files[0] : files;
    setFile(file);
  };

  const handleFileDownload = () => {
    if (file) {
      const filePath = window.URL.createObjectURL(file);
      // download file in new window?
      window.open(filePath);
    }
  };
  let discardTrig = Object.keys(dirtyFields).length > 0 ? true : false;

  useEffect(() => {
    dispatch(
      setDiscardEditMode({ editMode: discardTrig || file ? true : false })
    );
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch, discardTrig, file]);
  return (
    <>
      <PageSubTitle
        title={"pages.claims.claimCreate.detailsTab.title"}
        description={"pages.claims.claimCreate.detailsTab.description"}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-row pad-b-20">
          <div className="motif-col-lg-6">
            <ControlSelect
              name={CLAIM_STAGE_NAME}
              control={control}
              required={true}
              showLabel={true}
              label={"pages.claims.claimCreate.detailsTab.formLabelsDetails.claimStage"}
              options={CLAIM_STAGE_OPTIONS}
              setValue={setValue}
              getValues={getValues}
            />
            {errors[CLAIM_STAGE_NAME] &&
              errors[CLAIM_STAGE_NAME].type === "required" && (
                <MotifErrorMessage>
                  {t("pages.claims.claimCreate.detailsTab.validationMessages.claimStageRequired")}
                </MotifErrorMessage>
              )}
          </div>
        </div>

        <div className="motif-row pad-b-20">
          <div className="motif-col-lg-9">
            {/* <UploadStageDocument
              files={files}
              setFiles={setFiles}
            />  */}
            <div className="upload-doc">
              <PageSubTitle
                title={"pages.claims.claimCreate.detailsTab.stageDocumentTitle"}
                description={""}
              />
              <div className="motif-row">
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    /*@ts-ignore */
                    name={FILE_DISPLAY_NAME}
                    /*@ts-ignore */
                    control={control}
                    required={true}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.detailsTab.formLabelsDetails.displayFileName"}
                    showLabel={true}
                    //value={}
                  />
                </div>
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    name={STAGE_COMMENT_NAME}
                    /*@ts-ignore */
                    control={control}
                    required={false}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.detailsTab.formLabelsDetails.comment"}
                    showLabel={true}
                    //value={stageDocumentState?.comment}
                  />
                </div>
              </div>
              <div className="motif-row mar-t-30">
                <div className="motif-col-lg-12">
                  <MotifFormField>
                    <MotifFileUploader
                      id="my-id"
                      label={t("pages.claims.claimCreate.detailsTab.formLabelsDetails.uploadTitle")}
                      labelDescription={t(
                        "pages.claims.claimCreate.detailsTab.formLabelsDetails.UploadDescription"
                      )}
                      onDrop={(files) => handleFileChange(files)}
                      maxFileSize={10000}
                      accept={fileExtensions.default}
                    >
                      {file && (
                        <div className="mar-t-30">
                          <MotifFileUploaderItem
                            fileIcon={
                              <MotifIcon src={actionIcDescription24px} />
                            }
                            customButton={
                              <MotifButton
                                onClick={handleFileDownload}
                                variant="primary-alt"
                                type="button"
                              >
                                <MotifIcon
                                  fill="none"
                                  iconFunction={editorIcVerticalAlignBottom24px}
                                />
                              </MotifButton>
                            }
                            removable={true}
                            fileName={filename}
                            onRemove={() => {
                              handleFileChange(null);
                            }}
                            error={
                              (file &&
                                file.size >= fileExtensions.maxFileSize) ||
                              false
                            }
                            errorText={t(
                              "pages.claims.stageStatusPopup.filesizeExceedsLimit",
                              {
                                filesize: "250",
                              }
                            )}
                          />
                        </div>
                      )}
                    </MotifFileUploader>
                  </MotifFormField>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="display-flex justify-content-end mar-t-70">
          <div className="pad-r-10">
            <Button
              type="button"
              label={t("global.globalActions.cancel")}
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={() => {
                if (discardTrig || file ? true : false) {
                  setIsVisible(true);
                  dispatch(setDiscardEditMode({ editMode: false }));
                } else {
                  handleDescriptionReset();
                }
              }}
            />
          </div>
          <div className="">
            <Button
              label={t("global.globalActions.next")}
              variant="primary"
              disabled={
                (file && file.size >= fileExtensions.maxFileSize) || false
              }
              onClickHandler={moveDataToStoreandMoveToNextTab}
            />
          </div>
        </div>
        <ConfirmationModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          cancelBtnApproveHandler={handleDescriptionReset}
          cancelBtnRejectHandler={handleDescriptionCancel}
        />
      </form>
    </>
  );
}
