// @ts-nocheck
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifErrorMessage,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import React, { useState, useEffect } from "react";
import {
  AMOUNT_PER_PROOF_OF_CLAIM_FIELD,
  ASSIGNEE,
  ASSIGNEE_FIELD,
  RESOLVE_STATUS_FIELD,

  UNIT,
} from "./Constants";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ClaimDetailsType } from "./type";
import ControlSelect from "./ControlSelect";
import "./ClaimDetailsContent.scss";
import Button from "@/components/Form/Button/Button";
import {

 
  SECONDARY,
} from "@/Constant";
import { useGetUnitByCaseIdQuery } from "@/services/claimDetail/claimDetailReducer";
import { SearchCreditor } from "./searchCreditor";
import ClaimItemIdentification from "./components/ClaimItemIdentification";
import "./ClaimDetailsContent.scss";
import ControlInputBox from "@/components/Form/InputBox/ControlInputBox";
// import { CommentHistory } from "./components/commentHistory";
import { useCase } from "@/features/case/use-case";
//import NotificationGrid from '@/components/Notification/NotificationGrid';
import { getCurrencyOptions, postClaimItemData } from "./ClaimDetailsHelper";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useListAttributesByEntityQuery } from "@/services/customAtrributes/customAttributesReducer";
import CustomAttribute from "@/components/CustomAttribute/CustomAtrribute";
import { AttributeType } from "../../Configuration/Attributes/type";
import ControlInputBoxNumber from "@/components/Form/InputBox/ControlInputBoxNumber";
import { checkAmountDecimalError } from "@/utility/common";
import { isNumeric } from "@/utility/validation";
// import { ENTITYTYPE } from "@/appConstants";
import {
  useCreateClaimItemMutation,
  useGetClaimSummaryViewApiDataQuery,
} from "@/services/claimSummary/caseSummaryReducer";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
import { useDispatch } from "react-redux";
// import ControlInputBoxNumber from '@/components/Form/InputBox/ControlInputBoxNumber';

export default function ClaimItemCreate({ claimId }: { claimId: string }) {
  const [isIdentificationTabOpen, setIsIdentificationTabOpen] =
    React.useState(true);
  const [isAmountTabOpen, setIsAmountTabOpen] = React.useState(true);
  const [isCommentTabOpen, setIsCommentTabOpen] = React.useState(true);
  const [isAttributesTabOpen, setIsAttributesTabOpen] = React.useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attributesValue, setAttributesValue] = useState([]);
  const [intialAttributesValue, setIntiaslAttributesValue] = useState([]);
  const [isAttributesError, setIsAttributesError] = useState(false);
  const [isDecimalError, setIsDecimalError] = useState(false);
  const [defaultUnit, setDefaultUnit] = useState<any>(null);

  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const caseData = useCase();
  const caseId = caseData.caseId;
  const [decimalLimit, setDecimalLimit] = useState(
    caseData?.defaultUnitDecimal
  );

  const [createClaimItem] = useCreateClaimItemMutation();
  const { data: attributesData } = useListAttributesByEntityQuery({
    CaseId: caseData.caseId,
    EntityType: 3,
    IsCreate: true,
  });

  const {
    data: currencyData,
    error: curencyError,
    isLoading: currencyIsLoading,
  } = useGetUnitByCaseIdQuery(caseId);

  const { data: claimSummary } = useGetClaimSummaryViewApiDataQuery(claimId);

  const currencyOptions =
    currencyData && getCurrencyOptions(currencyData, caseData);
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors ,isDirty},
  } = useForm<ClaimDetailsType>({
    defaultValues:{
      assignee: claimSummary?.claimAssignee?.assigneeId
    }
  });
  const dispatch=useDispatch()
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty || !(JSON.stringify(intialAttributesValue)===JSON.stringify(attributesValue)) }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,isDirty,attributesValue,intialAttributesValue]);
  // useEffect(() => {
  //   setValue(ASSIGNEE_FIELD, claimSummary?.claimAssignee?.assigneeId);
  // }, [claimSummary?.claimAssignee, setValue]);

  useEffect(() => {
    if (currencyData) {
      const unit = currencyData?.find((item: any) => item.isDefaultCurrency);
      setDefaultUnit(unit?.name);
    }
  }, [currencyData, caseData]);

  useEffect(() => {
    if (attributesData) {
      setAttributesValue(attributesData);
      setIntiaslAttributesValue(attributesData)
    }
    // eslint-disable-next-line
  }, [attributesData]);

  const [currencyUnit, amountPerProofOfClaim] = watch([
    "Unit",
    "amountPerProofOfClaim",
  ]);

  useEffect(() => {
    const currencyUnit = watch("Unit");
    //@ts-ignore
    currencyData &&
      currencyData.forEach((item) => {
        if (item.id === currencyUnit) {
          setDecimalLimit(item.decimalPlace);
          const currentAmountLatest = watch("amountPerProofOfClaim");
          if (
            currentAmountLatest &&
            checkAmountDecimalError(currentAmountLatest, item.decimalPlace)
          ) {
            setIsDecimalError(true);
            setTimeout(() => {
              setIsDecimalError(false);
            }, 5000);
          } else {
            setIsDecimalError(false);
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyUnit, amountPerProofOfClaim]);

  if (currencyIsLoading) return <MotifProgressLoader show variant="default" />;
  if (curencyError)
    return <MotifErrorMessage>{t("global.globalNotifications.unExpectedError")}</MotifErrorMessage>;

  const handleChange = (name: string, value: string) => {
    setValue(ASSIGNEE_FIELD, value);
  };

  const errorList = Object.keys(errors);
  const errorsShow = errorList
    .join(", ")
    .replace(AMOUNT_PER_PROOF_OF_CLAIM_FIELD, "Current Amount");
  const onSubmit = async (data: ClaimDetailsType) => {
    if (attributesError()) {
      setIsAttributesError(true);
    } else {
      setIsLoading(true);
      setIsAttributesError(false);
      const attributes = attributesValue.map((value: any) => value.value);
      dispatch(setDiscardEditMode({ editMode: false }));

      try {
        const itemData = postClaimItemData({ ...data, attributes, caseId });
        itemData.claimId = claimId;
        const responseApi = await createClaimItem(itemData).unwrap();

        if (responseApi.claimItemId) {
          setShowMessage({ ...showMessage, show: true, type: "success" });

          navigate(`/case/${caseId}/claim/${claimId}`, {
            state: { showMessage: true, update: false, defaultActiveKey: 3 },
          });
          setIsLoading(false);
        } else {
          setShowMessage({ ...showMessage, show: true, type: "error" });
          setIsLoading(false);
          dispatch(setDiscardEditMode({ editMode: true }));

        }
      } catch (error) {
        setShowMessage({ ...showMessage, show: true, type: "error" });
        setIsLoading(false);
        dispatch(setDiscardEditMode({ editMode: true }));

      }
    }
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const cancelBtnApproveHandler = (
    event:any
  ) => {
    event.preventDefault();
    setIsVisible(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
    navigate(-1);
  };

  const attributesError = () => {
    var isError = false;
    const required = attributesValue.filter((x: any) => {
      return x.isRequired;
    });

    required.forEach((x: any) => {
      switch (x.value.attributeType) {
        case AttributeType.FreeText:
          isError = !x.value.textValue;
          break;
        case AttributeType.Number:
          isError = !x.value.numberValue;
          break;
        case AttributeType.Boolean:
          isError = !x.value.booleanValue;
          break;
        case AttributeType.Date:
          isError = !x.value.dateValue;
          break;
        case AttributeType.SingleSelect:
          isError = !x.value.selectedValue;
          break;
        case AttributeType.MutliSelect:
          isError =
            !x.value.selectedValues || x.value.selectedValues.length === 0;
          break;
        case AttributeType.Currency:
          isError = !x.value.amount;
          break;
        case AttributeType.Document:
          isError = !x.value.displayName;
          break;
        default:
          isError = false;
      }
    });

    return isError;
  };
  const onChangeAmount = (value: string) => {
    if (value && checkAmountDecimalError(value, decimalLimit)) {
      setIsDecimalError(true);
      setTimeout(() => {
        setIsDecimalError(false);
      }, 5000);
    } else {
      setIsDecimalError(false);
    }
  };
 
  return (
    <>
      {showMessage && showMessage.type === "error" && (
        <NotificationGrid show={showMessage.show} type={showMessage.type} />
      )}
      <div className="claim-details-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <MotifAccordion
            onClose={() => setIsIdentificationTabOpen(false)}
            onOpen={() => setIsIdentificationTabOpen(true)}
            style={{
              flex: "1",
            }}
            open={isIdentificationTabOpen}
            useChevronIcon={true}
            alignIconRight={true}
          >
            <MotifAccordionTrigger>
              {t("pages.claims.claimItemCreate.subTitle1")}
            </MotifAccordionTrigger>
            <MotifAccordionContent>
              <div className="motif-row">
                <ClaimItemIdentification
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                />
                <div className="motif-col-lg-4">
                  <div className="formFieldLabel">
                    <label aria-label={ASSIGNEE}>{t("pages.claims.claimItemCommonLabels.assignee")}</label>
                  </div>
                  <div className="form-field search-creditor">
                    <SearchCreditor
                      handleChange={handleChange}
                      value={claimSummary?.claimAssignee?.assigneeName}
                    />
                    <span className="hide">
                      {/*@ts-ignore*/}
                      <ControlInputBox
                        name={ASSIGNEE_FIELD}
                        control={control}
                        required={false}
                        isTextArea={false}
                        label={t("")}
                        showLabel={false}
                        setValue={setValue}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </MotifAccordionContent>
          </MotifAccordion>
          <MotifAccordion
            onClose={() => setIsAmountTabOpen(false)}
            onOpen={() => setIsAmountTabOpen(true)}
            style={{
              flex: "1",
            }}
            open={isAmountTabOpen}
            useChevronIcon={true}
            alignIconRight={true}
          >
            <MotifAccordionTrigger>
            {t("pages.claims.claimItemCreate.subTitle2")}
            </MotifAccordionTrigger>
            <MotifAccordionContent>
              <div className="motif-row">
                <div className="motif-col-sm"></div>
                <div className="motif-col-sm">
                  <div className="title align-right">
                  {t("pages.claims.claimItemCommonLabels.originalAmount")}                    </div>
                </div>
                <div className="motif-col-sm">
                  <div className="title align-right">                  {t("pages.claims.claimItemCommonLabels.stageAmount")}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="title align-right">
                  {t("pages.claims.claimItemCommonLabels.latestCreditorPosition")}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="title align-right">
                  {t("pages.claims.claimItemCommonLabels.latestDebtorPosition")}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="title align-right">
                    {t("pages.claims.claimItemCommonLabels.resolvedAmount")}
                  </div>
                </div>
              </div>
              <div className="motif-row pad-t-10">
                <div className="motif-col-sm">{defaultUnit}</div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    - {defaultUnit}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    - {defaultUnit}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    - {defaultUnit}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    - {defaultUnit}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    - 
                  </div>
                </div>
              </div>
              <div className="motif-row pad-t-10">
                <div className="motif-col-sm">{t("pages.claims.claimItemCommonLabels.Entered")}</div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    - 
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    - 
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    - 
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    - 
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    - 
                  </div>
                </div>
              </div>
              <div className={`motif-row pad-t-30`}>
                <div className="motif-col-lg-4">
                  <div className="form-field">
                    {/*@ts-ignore*/}
                    <ControlInputBoxNumber
                      name={AMOUNT_PER_PROOF_OF_CLAIM_FIELD}
                      control={control}
                      required={true}
                      isTextArea={false}
                      label={"pages.claims.claimItemCommonLabels.currentAmount"}
                      showLabel={true}
                      setValue={setValue}
                      isNumber={true}
                      digitAfterDecimal={decimalLimit}
                      isAmount={true}
                      onChangeAmount={onChangeAmount}
                      validate={isNumeric}
                    />
                    <MotifErrorMessage>
                      {isDecimalError ? t("pages.claims.claimItemCommonLabels.decimalErrorMessage") : ""}
                    </MotifErrorMessage>
                  </div>
                </div>
                <div className="motif-col-lg-4">
                  <div className="form-field">
                    <ControlSelect
                      name={UNIT}
                      control={control}
                      required={true}
                      showLabel={true}
                      label={"pages.claims.claimItemCommonLabels.unit"}
                      options={currencyOptions}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  </div>
                </div>
                <div className="motif-col-lg-4">
                  <div className="form-field">
                    <ControlSelect
                      name={RESOLVE_STATUS_FIELD}
                      control={control}
                      required={true}
                      showLabel={true}
                      label={"pages.claims.claimItemCommonLabels.resolveStatus"}
                      options={[
                        { label: t("pages.claims.claimItemCommonLabels.unresolved"), value: "Unresolved" },
                        { label: t("pages.claims.claimItemCommonLabels.resolved"), value: "Resolved" },
                      ]}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  </div>
                </div>
              </div>
            </MotifAccordionContent>
          </MotifAccordion>

          <MotifAccordion
            onClose={() => setIsAttributesTabOpen(false)}
            onOpen={() => setIsAttributesTabOpen(true)}
            style={{
              flex: "1",
            }}
            open={isAttributesTabOpen}
            useChevronIcon={true}
            alignIconRight={true}
          >
            <MotifAccordionTrigger>
              {t("pages.claims.claimItemAttributes")}
            </MotifAccordionTrigger>
            <MotifAccordionContent>
              {attributesData && attributesData.length === 0 ? (
                <span>{t("pages.claims.noAttributesLabel")}</span>
              ) : (
                <div className="motif-row pad-t-20 mar-b-20">
                  {attributesData &&
                  attributesValue.length === attributesData.length ? (
                    attributesData.map((attribute: any, index: number) => {
                      return (
                        <div className="motif-col-md-4">
                          <CustomAttribute
                            customAttributeDetails={{
                              ...attribute,
                              metadataJSON: JSON.parse(attribute.metadataJSON),
                            }}
                            customAttributeType={attribute.value.attributeType}
                            attributeValue={attributesValue}
                            setAttributeValue={setAttributesValue}
                            index={index}
                            key={index}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </MotifAccordionContent>
          </MotifAccordion>

          <MotifAccordion
            onClose={() => setIsCommentTabOpen(false)}
            onOpen={() => setIsCommentTabOpen(true)}
            style={{
              flex: "1",
            }}
            open={isCommentTabOpen}
            useChevronIcon={true}
            alignIconRight={true}
          >
            {/* <MotifAccordionTrigger>{t(Comments)}www</MotifAccordionTrigger>
            <MotifAccordionContent>
              <div className="pad-t-20">
                <CommentHistory
                  entityId={claimId}
                  entityType={ENTITYTYPE.claimItem}
                />
              </div>
            </MotifAccordionContent> */}
          </MotifAccordion>
          <div className="error-container">
            <MotifErrorMessage>
              {errorList.length > 0
                ? t("pages.claims.claimItemCommonLabels.requiredMessage",{filedNames:errorsShow}
                  )
                : ""}
            </MotifErrorMessage>
            <MotifErrorMessage>
              {isAttributesError
                ? t("pages.configuration.attributes.requiredFields")
                : ""}
            </MotifErrorMessage>
          </div>
          <div className="button-container">
            <Button
              label={t("global.globalActions.save")}
              type={isLoading ? "loader" : "submit"}
              disabled={isLoading}
            />
            <Button
              type="button"
              label={t("global.globalActions.cancel")}
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={(e) => {
                if(isDirty || !(JSON.stringify(intialAttributesValue)===JSON.stringify(attributesValue))){
                  setIsVisible(true);
                  dispatch(setDiscardEditMode({ editMode: false }));

                }
                else{
                  cancelBtnApproveHandler(e)

                }
              }}
            />
          </div>
        </form>
        <ConfirmationModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          cancelBtnApproveHandler={cancelBtnApproveHandler}
          cancelBtnRejectHandler={cancelBtnRejectHandler}
        />
      </div>
    </>
  );
}
