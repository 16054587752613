import { j as m } from "./jsx-runtime-BwWtxLpl.js";
import I, { useState as k, useContext as ae, useRef as L, useEffect as w } from "react";
import n from "prop-types";
import Ve from "classnames";
import { usePopper as Be } from "react-popper";
import { IconoirNavArrowDown as We, IconoirXmark as Ie } from "@ey-xd/motif-icon";
import "./components/button/button.js";
import "./components/dropdown-portal/dropdown-portal.js";
import { MotifIcon as re } from "./components/icon/icon.js";
import { IconContext as Me } from "./components/icon/icon.context.js";
import { MotifIconButton as $e } from "./components/button/icon-button/icon-button.js";
import "./components/forms/form-field/form-field.js";
import { FormFieldContext as _e } from "./components/forms/form-field/form-field.context.js";
import "./components/forms/templates/people-template.js";
import { SelectProvider as Fe, SelectContext as He } from "./components/forms/select/select-context.js";
import { SelectCheckbox as Ue } from "./components/forms/select/checkbox/checkbox.js";
import "./components/forms/templates/avatar-group-template.js";
import { DefaultOptionTemplate as Ke } from "./components/forms/templates/default-template.js";
import "./components/forms/templates/dynamic-input-template.js";
import { SelectSearch as Le } from "./components/forms/select/search/search.js";
import { SelectAllOptions as qe } from "./components/forms/select/select-all/select-all.js";
const Ge = {
  clear: Ie
};
let Ee;
Ee = 0;
const Xe = 14, ze = 46 / Xe, ce = (h) => Object.keys(h).length === 0, Je = ({
  children: h,
  icons: j = {},
  id: A = "",
  value: V = "",
  placeholder: _ = "",
  visibleOptions: C = 5,
  // eslint-disable-next-line no-empty-function
  onChange: r = () => {
  },
  multiple: u = !1,
  showSelectAllButton: q = !1,
  filter: P = !1,
  searchPlaceholder: G = "Search",
  disabled: F = !1,
  ariaLabelledBy: X = "",
  triggerButtonProps: ee = {},
  noResultsTemplate: z = je,
  className: de = "",
  i18n: J = { deselect: "Deselect All", select: "Select All" },
  ...Q
}) => {
  const te = (e, t) => !!(t && t.target && e && e.current && !e.current.contains(t.target)), [b, S] = k(!1), [Y, x] = k(u ? [] : -1), [i, N] = k(-1), [c, d] = k(V || ""), [g, O] = k(u ? [] : ""), [H, Z] = k(""), [M, U] = k({
    selected: !1,
    indeterminate: !1
  }), [a, v] = k(h), [E, y] = k(""), [D, B] = k(!1), { select: pe } = ae(Me);
  let K;
  pe ? K = {
    ...pe,
    ...j
  } : K = {
    ...Ge,
    ...j
  };
  const se = L(), W = L(), ue = L(), oe = L(), fe = L(), me = I.Children.count(a), f = ae(_e), { styles: Ae, attributes: Ce, forceUpdate: Ye } = Be(se.current, ue.current, {
    placement: "auto",
    modifiers: [
      {
        name: "offset",
        enabled: !0,
        options: {
          offset: [0, 8]
        }
      },
      {
        name: "preventOverflow",
        options: {
          padding: 8
        }
      },
      {
        name: "flip",
        options: {
          padding: 8,
          allowedAutoPlacements: ["top", "bottom"]
        }
      }
    ]
  }), T = I.Children.map(h, (e, t) => {
    const {
      props: { value: s }
    } = e, o = Array.isArray(c) ? c.includes(s) : c === s;
    return I.cloneElement(e, {
      isCurrentSelected: c === s,
      optionIndex: t,
      isSelected: o
    });
  }), be = (e) => {
    if (u || Array.isArray(e)) {
      const t = T.reduce(
        (s, { props: { value: o, children: l, label: p, optionIndex: R } }) => (e.includes(o) && (s[0].push(typeof l == "object" ? p : l), s[1].push(R)), s),
        [[], []]
      );
      O(t[0]), x(t[1]);
    } else {
      const t = T.reduce(
        (s, { props: { value: o, children: l, label: p, optionIndex: R } }) => (o === e && (s.displayedValue = typeof l == "object" ? p : l, s.optionSelected = R, s.optionCurrent = R), s),
        {}
      );
      O(ce(t) ? "" : t.displayedValue), x(ce(t) ? -1 : t.optionSelected), N(ce(t) ? -1 : t.optionCurrent);
    }
  };
  w(() => {
    V ? d(V) : (d(""), O(u ? [] : ""));
  }, [V]), w(() => (c && be(c), v(T), f.setDisabled(F), f.setFormElementType("selectInput"), f.setHasPlaceholder(_ && _.length > 0), A ? f.setId(A) : f.setId(`motif-select-${Ee++}`), document.addEventListener("click", ye), () => {
    document.removeEventListener("click", ye);
  }), []), w(() => {
    f.setValue(g), f.setHasContent(g.length > 0 || Object.keys(g).length > 0);
  }, [g]), w(() => {
    D || v(T), B(!1);
  }, [h]), w(() => {
    if (u) {
      const e = a.map((t) => ({
        ...t,
        props: {
          ...t.props,
          isSelected: c.includes(t.props.value)
        }
      }));
      v(e);
    }
    if (u && (q || P)) {
      const e = a.map(({ props: { value: t } }) => t);
      U({
        selected: c.length > 0 && e.every((t) => c.includes(t)),
        indeterminate: e.some((t) => c.includes(t))
      });
    }
    be(c);
  }, [c]);
  const ne = (e) => {
    const t = e.filter(({ props: { children: s, label: o, value: l } }) => typeof P == "function" && typeof l == "object" ? P(E, l) : (typeof s == "object" ? o : s).toLowerCase().includes(E.toLowerCase()));
    return I.Children.map(
      t,
      (s, o) => I.cloneElement(s, {
        optionIndex: o
      })
    );
  }, Oe = (e) => {
    const t = e.filter(({ props: { isSelected: l } }) => l), s = e.filter(({ props: { isSelected: l } }) => !l), o = [...t, ...s];
    return I.Children.map(
      o,
      (l, p) => I.cloneElement(l, {
        optionIndex: p
      })
    );
  };
  w(() => {
    if (b)
      Z(`${f.id}-${i}`);
    else {
      if (Z(""), E && u) {
        const e = ne(T), t = Oe(e);
        v(t);
      }
      if (E && !u) {
        const e = ne(T);
        v(e);
      }
      if (!E && u) {
        const e = Oe(T);
        v(e);
      }
    }
  }, [b]), w(() => {
    const e = ne(T);
    if (u) {
      const t = e.map(({ props: { value: s } }) => s);
      U({
        selected: t.length > 0 && t.every((s) => c.includes(s)),
        indeterminate: t.some((s) => c.includes(s))
      });
    }
    v(e), N(-1);
  }, [E]);
  const ye = (e) => {
    !b && te(se, e) && S(!1);
  };
  w(() => {
    i >= 0 && document.getElementById(`${f.id}-${i}`) && document.getElementById(`${f.id}-${i}`).focus({ focusVisible: !0 });
  }, [i]);
  const he = (e) => {
    e.preventDefault();
    const t = 38, s = 40, o = e.keyCode;
    o === t && i > 0 && N(i - 1), o === s && i < me - 1 && N(i + 1);
  }, le = (e) => {
    if (!a[e].props.disabled) {
      const s = a[e].props.value, o = typeof a[e].props.children == "object" ? a[e].props.label : a[e].props.children;
      x(e), d(s), O(o), r && r(s);
    }
  }, ie = (e) => {
    if (!a[e].props.disabled) {
      const s = a[e].props.value, o = typeof a[e].props.children == "object" ? a[e].props.label : a[e].props.children;
      d((l) => {
        if (l.includes(s)) {
          const p = l.filter((R) => R !== s);
          return r && r(p), p;
        }
        return r && r([...l, s]), [...l, s];
      }), O((l) => l.includes(o) ? l.filter((p) => p !== o) : [...l, o]), B(!0);
    }
  }, ke = (e) => {
    switch (e.keyCode) {
      case 40:
      case 38:
        b && he(e);
        break;
      case 27:
        S(!1), W.current.focus();
        break;
      case 13:
        E !== "" && i === -1 && a.length > 0 && (le(i + 1), W.current.focus()), i !== -1 && a.length > 0 && (le(i), W.current.focus());
      case 32:
        document.activeElement !== oe.current && (e.preventDefault(), S(!b), i > -1 && le(i), W.current.focus());
        break;
      case 9:
        b && S(!1);
        break;
    }
  }, xe = (e) => {
    switch (e.keyCode) {
      case 40:
      case 38:
        b && he(e);
        break;
      case 27:
        S(!1), N(-1), W.current.focus();
        break;
      case 13:
        E !== "" && i === -1 && a.length > 0 && (e.preventDefault(), b || S(!0), ie(i + 1)), P && i > -1 && a.length > 0 && (e.preventDefault(), b || S(!0), ie(i));
      case 32:
        document.activeElement !== oe.current && (e.preventDefault(), b || S(!0), i > -1 && ie(i));
        break;
      case 9:
        b && S(!1);
        break;
    }
  }, De = () => {
    if (c.length)
      d([]), O([]), x([]), U({ selected: !1, indeterminate: !1 }), r([]);
    else {
      const e = T.reduce(
        (t, { props: { value: s, children: o, label: l, optionIndex: p, disabled: R } }) => {
          if (!R) {
            const $ = typeof o == "object" ? l : o;
            t.selectValue = t.selectValue ? [...t.selectValue, s] : [s], t.displayedValue = t.displayedValue ? [...t.displayedValue, $] : [$], t.optionSelected = t.optionSelected ? [...t.optionSelected, p] : [p];
          }
          return t;
        },
        {}
      );
      d([...c, ...e.selectValue]), O([...g, ...e.displayedValue]), x([...Y, ...e.optionSelected]), U({ selected: !0, indeterminate: !1 }), r(e.selectValue);
    }
  }, Te = (e) => {
    y(e.target.value);
  }, we = () => {
    const e = a.reduce(
      (o, { props: { value: l, children: p, label: R, optionIndex: $, disabled: Pe } }) => {
        if (Pe)
          o.disabledOptions = o.disabledOptions ? [...o.disabledOptions, l] : [l];
        else {
          const ge = typeof p == "object" ? R : p;
          o.selectValue = o.selectValue ? [...o.selectValue, l] : [l], o.displayedValue = o.displayedValue ? [...o.displayedValue, ge] : [ge], o.optionSelected = o.optionSelected ? [...o.optionSelected, $] : [$];
        }
        return o;
      },
      {}
    ), t = c && c.filter((o) => !e.selectValue.includes(o)), s = g && g.filter((o) => !e.displayedValue.includes(o));
    !M.selected && M.indeterminate ? e.disabledOptions && e.disabledOptions.length > 0 ? (d([...t]), r && r([...t]), O([...s])) : (d([.../* @__PURE__ */ new Set([...c, ...e.selectValue])]), r && r([.../* @__PURE__ */ new Set([...c, ...e.selectValue])]), O([.../* @__PURE__ */ new Set([...g, ...e.displayedValue])])) : M.selected && M.indeterminate ? (d([...t]), r && r([...t]), O([...s])) : (d([...t, ...e.selectValue]), r && r([...t, ...e.selectValue]), O([...s, ...e.displayedValue])), B(!0);
  }, Se = (e) => {
    e.preventDefault(), d(""), O(""), N(-1), r && r(""), W.current.focus();
  }, Ne = (e) => {
    switch (e.keyCode) {
      case 13:
      case 32:
        Se(e);
        break;
    }
  }, ve = Ve({
    "motif-select": !0,
    "motif-select-open": b,
    "motif-select-disabled": F,
    "motif-select-invalid": f.invalid,
    [de]: !0
  });
  return /* @__PURE__ */ m.jsx(
    Fe,
    {
      value: {
        id: f.id,
        optionSelected: Y,
        setOptionSelected: x,
        selectValue: c,
        setSelectValue: d,
        setDisplayedValue: O,
        isOpen: b,
        setIsOpen: S,
        selectTriggerRef: W,
        optionCurrent: i,
        setOptionCurrent: N,
        multiple: u,
        searchPlaceholder: G,
        searchOption: E,
        handleClickSelectAll: De,
        handleChangeSearch: Te,
        hasSelectedAllOptions: M,
        handleClickSearchCheckbox: we,
        totalOptions: me,
        setHasSelectedMultipleOption: B,
        selectSearchRef: oe,
        onChange: r,
        listBoxRef: fe
      },
      children: /* @__PURE__ */ m.jsxs(
        "div",
        {
          ...Q,
          id: f.id,
          ref: se,
          className: ve,
          onKeyDownCapture: u ? xe : ke,
          onFocus: () => f.toggleFocus(!0),
          onBlur: () => f.toggleFocus(!1),
          "data-motif-select": !0,
          children: [
            /* @__PURE__ */ m.jsxs(
              "button",
              {
                ref: W,
                role: "combobox",
                disabled: F,
                className: "motif-select-input",
                onClick: () => S(!b),
                "aria-labelledby": X,
                "aria-controls": `${f.id}-listbox`,
                "aria-haspopup": "listbox",
                "aria-expanded": b,
                "aria-activedescendant": H,
                ...ee,
                children: [
                  /* @__PURE__ */ m.jsx("span", { className: "motif-select-input-place-holder", children: !f.hasContent && _ }),
                  /* @__PURE__ */ m.jsx("div", { className: "motif-select-input-text", children: u ? g.filter(Boolean).join(", ") : g }),
                  !u && P && g && g.length > 0 && /* @__PURE__ */ m.jsx(
                    $e,
                    {
                      className: "motif-select-clean-value",
                      onClick: Se,
                      "aria-label": "Remove option selected",
                      onKeyDown: Ne,
                      children: /* @__PURE__ */ m.jsx(re, { iconFunction: K.previous })
                    }
                  ),
                  typeof K.previous == "function" ? /* @__PURE__ */ m.jsx(re, { iconFunction: K.previous, className: "motif-select-input-arrow" }) : K.dropdown || /* @__PURE__ */ m.jsx(re, { iconFunction: We, className: "motif-select-input-arrow" })
                ]
              }
            ),
            /* @__PURE__ */ m.jsxs(
              "div",
              {
                className: "motif-select-wrapper-options",
                ref: ue,
                style: Ae.popper,
                ...Ce.popper,
                children: [
                  u && q && /* @__PURE__ */ m.jsx(qe, { i18n: J }),
                  P && /* @__PURE__ */ m.jsx(Le, {}),
                  /* @__PURE__ */ m.jsx(
                    "div",
                    {
                      role: "listbox",
                      ref: fe,
                      id: `${f.id}-listbox`,
                      tabIndex: "-1",
                      className: "motif-select-options",
                      "aria-labelledby": X,
                      style: { maxHeight: `${C * ze}rem` },
                      children: a.length > 0 ? a : /* @__PURE__ */ m.jsx(z, { message: "No results were found", options: T, searchTerm: E })
                    }
                  )
                ]
              }
            )
          ]
        }
      )
    }
  );
};
Je.propTypes = {
  children: n.node,
  icons: n.shape({
    clear: n.node,
    dropdown: n.node
  }),
  id: n.string,
  value: n.oneOfType([n.string, n.array, n.object]),
  placeholder: n.string,
  visibleOptions: n.oneOfType([n.string, n.number]),
  onChange: n.func,
  multiple: n.bool,
  showSelectAllButton: n.bool,
  disabled: n.bool,
  filter: n.oneOfType([n.bool, n.func]),
  searchPlaceholder: n.string,
  ariaLabelledBy: n.string,
  triggerButtonProps: n.instanceOf(Object),
  noResultsTemplate: n.func,
  className: n.string,
  i18n: n.shape({
    deselect: n.string,
    select: n.string
  })
};
const Qe = (h, j) => {
  const A = j.getBoundingClientRect(), V = h.getBoundingClientRect();
  return V.top >= A.top && V.left >= A.left && V.bottom <= A.bottom && V.right <= A.right;
}, Re = ({
  children: h,
  value: j = "",
  disabled: A = !1,
  label: V = "",
  onClick: _ = null,
  optionIndex: C = 0,
  isSelected: r = !1,
  isCurrentSelected: u = !1,
  className: q = "",
  ...P
}) => {
  const [G, F] = k(r), {
    id: X,
    optionSelected: ee,
    setOptionSelected: z,
    selectValue: de,
    setSelectValue: J,
    setDisplayedValue: Q,
    setIsOpen: te,
    selectTriggerRef: b,
    optionCurrent: S,
    setOptionCurrent: Y,
    multiple: x,
    searchOption: i,
    totalOptions: N,
    setHasSelectedMultipleOption: c,
    onChange: d,
    listBoxRef: g
  } = ae(He), O = L(), H = C === S, Z = i !== "" && C === 0 && S === -1, M = (y) => typeof y == "object" ? I.cloneElement(h, {
    searchTerm: i,
    value: V
  }) : /* @__PURE__ */ m.jsx(Ke, { searchTerm: i, value: y });
  w(() => {
    H && !Qe(O.current, g.current) && O.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }, [H]), w(() => {
    F(r);
  }, [r]);
  const U = () => {
    J(j), Q(typeof h == "object" ? V : h), z(C), Y(C), te(!1), b.current.focus(), d && d(j);
  }, a = () => {
    J((y) => {
      if (y.includes(j)) {
        const D = y.filter((B) => B !== j);
        return d && d(D), D;
      }
      return d && d([...y, j]), [...y, j];
    }), Q((y) => {
      const D = typeof h == "object" ? V : h;
      return y.includes(D) ? y.filter((B) => B !== D) : [...y, D];
    }), z((y) => y.includes(C) ? y.filter((D) => D !== C) : [...y, C]), c(!0), F(!G);
  }, v = () => {
    A || (x ? a() : U(), _ && _());
  }, E = Ve({
    "motif-option": !0,
    "motif-option-disabled": A,
    "motif-option-current": H || u || Z,
    [q]: !0
  });
  return /* @__PURE__ */ m.jsxs(
    "div",
    {
      ...P,
      id: `${X}-${C}`,
      ref: O,
      role: "option",
      "aria-selected": x ? r : C === ee,
      tabIndex: H || u ? "0" : "-1",
      "aria-disabled": A,
      className: E,
      onClick: v,
      "data-motif-select-option": !0,
      children: [
        x && N > 0 && /* @__PURE__ */ m.jsx(Ue, { selected: G }),
        M(h)
      ]
    }
  );
};
Re.propTypes = {
  children: n.node,
  value: n.oneOfType([n.string, n.object]),
  disabled: n.bool,
  label: n.string,
  onClick: n.func,
  optionIndex: n.number,
  isSelected: n.bool,
  isCurrentSelected: n.bool,
  className: n.string
};
const je = ({ message: h }) => /* @__PURE__ */ m.jsx(Re, { disabled: !0, children: h });
je.propTypes = {
  message: n.string
};
export {
  je as D,
  Je as M,
  Re as a
};
