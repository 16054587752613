import { Link } from "react-router-dom";
import { MotifButton, MotifIcon } from "@ey-xd/motif-react";
import { IconoirEditPencil } from "@ey-xd/motif-icon";

export const useCustomCellRenderers = (handleEditClick: (data: any)=> void) => {
    const caseRenderers = {
        nameCellRenderer: function (param: any) {
            return (
              <>
                {
                  <Link
                    to={`./scheme-manage/${param.data.id}`}
                    state={{ from: param.data.id }}
                  >
                    {param.data.name}
                  </Link>
                }
              </>
            );
          },
        actionCellRenderers: function (param: any) {
            return (
                <div className="motif-row">
                    {/* <div className="motif-col-lg-6">
                        <MotifButton
                            size="small"
                            variant="primary"
                            onClick={() => {
                                console.log('Delete claim');
                            }}
                        >
                            <MotifIcon
                                iconFunction={IconoirTrash}
                                stroke="blue"
                                strokeWidth="2"
                                size="24"
                            />
                        </MotifButton>
                    </div> */}
                    <div className="motif-col-lg-6">
                        <MotifButton
                            onClick={()=> handleEditClick(param.data.id)}
                            size="small"
                            type="submit"
                            variant="primary"
                        >
                            <MotifIcon
                                iconFunction={IconoirEditPencil}
                                stroke="blue"
                                strokeWidth="2"
                                size="24"
                            />
                        </MotifButton>
                    </div>
                </div>
            );
        },
    }
    return caseRenderers;
};