import configuration from "./en/pages.configuration";
import claims from "./en/pages.claims";
import home from "./en/home";
import distributions from "./en/pages.ditributions";
import portalConfig from "./en/pages.portalConfig";
import paymentStatus from "./en/pages.paymentStatus";
import errors from "./en/global.errors";
import globalNotifications from "./en/globalNotifications";
import globalPlaceholder from "./en/globalPlaceholder";
import header from "./en/header";
import navBar from "./en/navBar";
import pagination from "./en/pagination";
import caseOverView from "./en/caseOverView";
import globalBanner from "./en/globalBanner";
import caseSummary from "./en/caseSummary";
import globalActions from "./en/globalActions";
import userManagementPage from "./en/userManagementPage";
import debtors from "./en/debtors";
import creditors from "./en/creditors";
import comment from "./en/comment";
import voting from "./en/voting";

const english = {
  pages: {
    userManagementPage,
    caseOverView,
    pagination,
    navBar,
    voting,
    home,
    configuration,
    distributions,
    claims,
    debtors,
    creditors,
    portalConfig,
    paymentStatus,
    header,
    caseSummary,
  },
  commonComponent:{
    comment
  },
  global: {
    globalActions,
    globalBanner,
    errors,
    globalNotifications,
    globalPlaceholder,
  },
};
// t("global.globalNotifications.wentWrong")
// : t("global.globalNotifications.noRecords")
export default english;
