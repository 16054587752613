import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifCard,
  MotifCardBody,
  MotifCardHeader,
  MotifErrorMessage,
  MotifIconButton,
  MotifIcon,
  MotifCheckbox,
  MotifLabel,
  MotifFormField,
  MotifInput,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import {
  IconoirEditPencil,
  IconoirTrash,
  IconoirArrowDown,
  IconoirArrowUp,
} from "@ey-xd/motif-icon";
import { useUpdateStatusMutation } from "@/services/stages/stagesReducer";
import "./Stages.css";
import DeleteStageStatus from "./DeleteStageStatus";
import { StatusType, OrderChange, DueDateType } from "./StagesType";
import CreateEditStatus from "./CreateEditStatus";
import FormattedDatePicker from "@/components/FormattedDatePicker/FormattedDatePicker";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import {handleDateError} from "@/utility/common";
import { MotifTooltip } from "@/libs/@ey-xd/motif-react/main";
import {toolTipInformationJson } from "./Constants"
import helpCircleImg from "@/features/caselist/images/help-circle.svg";
export default function StatusTile({
  statusData,
  getStageData,
  handleOrderChange,
  numStatuses,
  setFilteredStatuses,
  filteredStatuses,
  selectedStage,
  setSelectedStage
}: {
  statusData: StatusType;
  getStageData: (isCreate: boolean) => void;
  handleOrderChange: (change: OrderChange, id: string) => void;
  numStatuses: number;
  setFilteredStatuses: (statuses: any[]) => void;
  filteredStatuses: any[];
  selectedStage: string;
  setSelectedStage: (stageId: string) => void;

}) {
  const { t } = useTranslation();
  const informationJson:any={...toolTipInformationJson}
  const [inputValues, setInputValues] = useState(statusData);
  const [showCreateStatus, setShowCreateStatus] = useState(false);
  const [showDeleteStatus, setShowDeleteStatus] = useState(false);
  const [dueDate, setDueDate] = useState(DueDateType.TimeLimit);
  const [updateStatusQuery] = useUpdateStatusMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const [initialStatusUsedMessage, setInitialStatusUsedMessage] = useState("");
  
  const handleDueDateChange = (e: any) => {
    if (e === dueDate) {
      setDueDate(DueDateType.None);
      return;
    }
    setDueDate(e);
  }
  const handleInputChange = () => {
    const requestBody = { ...inputValues };
    updateStatusQuery(requestBody).then(() => {
      getStageData(false);
    });
  };

  const handleInitialStatusChange = () => {
    setInitialStatusUsedMessage("");
    if (!inputValues.isInitialStatus) {
      const updatedStatuses = filteredStatuses.map((status) => ({
        ...status,
        isInitialStatus: status.id === statusData.id,
      }));
      setFilteredStatuses(updatedStatuses);
      setInputValues({ ...inputValues, isInitialStatus: true });
    }
  };

  const handleResolveStatusChange = () => {
    const updatedStatuses = filteredStatuses.map((status) => ({
      ...status,
      isResolveStatus: status.id === statusData.id ? !status.isResolveStatus : false,
    }));
    setFilteredStatuses(updatedStatuses);
    setInputValues({ ...inputValues, isResolveStatus: !inputValues.isResolveStatus });
  
  };

  useEffect(() => {
    setInputValues({
      ...statusData,
      isInitialStatus: numStatuses === 1 ? true : statusData.isInitialStatus,
    });
    // eslint-disable-next-line
  }, [statusData, numStatuses]);

  useEffect(() => {
    if (dueDate === 0) {
      setInputValues({
        ...inputValues,
        timeLimitDays: undefined,
        timeLimitDate: "",
      })
    }
    // eslint-disable-next-line
  }, [dueDate])

  useEffect(() => {
    handleInputChange();
    // eslint-disable-next-line
  }, [inputValues]);

  const handleStatusDeleteEvent = () => {
    setInitialStatusUsedMessage("");
    setTimeout(() => {
      if (statusData.isInitialStatus) {
        setInitialStatusUsedMessage(t("pages.configuration.stages.initialStatusUsedMessage"));
      } else {
        setShowDeleteStatus(true);
      }
    }, 0);
  };
function handleInformationIcon(keyName:string){
  let messageString=informationJson?.[keyName]?informationJson[keyName]:null
 return  messageString?<MotifTooltip  variant="alt" placement="right"   hideCloseButton={true} allowHover trigger={<div className="imgMainCon"><img className="imageCon" alt="tooltipimg" src={helpCircleImg}/></div>}>
  <span className="motif-caption-default-regular">
    {messageString.split(' ').length > 15 ? (
      <>
        {t(messageString.split(' ').slice(0, 15).join(' '))} <br />
        {t(messageString.split(' ').slice(15).join(' '))}
      </>
    ) : (
      t(messageString)
    )}
  </span>
       </MotifTooltip>:<></>
}
  return (
    <>
      <div className="status-tile-container">
        {deleteErrorMessage && (
          <NotificationGrid
            show={true}
            message={deleteErrorMessage}
            type="error"
          />
        )}
        {initialStatusUsedMessage && (
          <NotificationGrid
            show={true}
            message={initialStatusUsedMessage}
            type="error"
          />
        )}
        <MotifCard
          className="status-tile"
          orientation="horizontal"
          variant="card-border"
        >
          <MotifCardHeader>
            <span className="motif-body1-default-bold">{inputValues.name}</span>
            <div className="status-tile-actions">
              <div className="action-icons">
                <MotifIconButton
                  onClick={() => setShowCreateStatus(true)}
                  type="button"
                >
                  <MotifIcon iconFunction={IconoirEditPencil} />
                </MotifIconButton>
                <MotifIconButton
                  onClick={handleStatusDeleteEvent}
                  type="button"
                >
                  <MotifIcon iconFunction={IconoirTrash} />
                </MotifIconButton>
              </div>
              <div className="arrow-icons">
                <MotifIconButton
                  onClick={() => handleOrderChange(OrderChange.Up, statusData.id!)}
                  type="button"
                  size="small"
                >
                  <MotifIcon iconFunction={IconoirArrowUp} />
                </MotifIconButton>
                <MotifIconButton
                  onClick={() => handleOrderChange(OrderChange.Down, statusData.id!)}
                  type="button"
                  size="small"
                >
                  <MotifIcon iconFunction={IconoirArrowDown} />
                </MotifIconButton>
              </div>
            </div>
          </MotifCardHeader>
          <MotifCardBody>
            <div className="status-tile-body">
              <div className="motif-row">
              <MotifCheckbox
                onChange={handleInitialStatusChange}
                checked={inputValues.isInitialStatus}
                disabled={inputValues.isInitialStatus}
              >
                {t("pages.configuration.stages.initialPortal")}{handleInformationIcon("initialPortal")}

               
                
              </MotifCheckbox>
                    </div>

                    <div className="motif-row">

              <MotifCheckbox
                 onChange={() => {
                  setInputValues({
                    ...inputValues,
                    isDocRequiredStatus: !inputValues.isDocRequiredStatus,
                  });
                }}
                checked={inputValues.isDocRequiredStatus}
              >
                {t("pages.configuration.stages.requireDocument")}{handleInformationIcon("requireDocument")}

              </MotifCheckbox>
                    </div>
                    <div className="motif-row ">

              <MotifCheckbox
                onChange={() => {
                  setInputValues({
                    ...inputValues,
                    isDocReleaseStatus: !inputValues.isDocReleaseStatus,
                  });
                }}
                checked={inputValues.isDocReleaseStatus}
              >
                {t("pages.configuration.stages.releaseDocument")}{handleInformationIcon("releaseDocument")}
              </MotifCheckbox>
              
           
              </div>
              <div className="motif-row">
              <MotifCheckbox
                onChange={handleResolveStatusChange}
                checked={inputValues.isResolveStatus}
              >
                {t("pages.configuration.stages.resolveStatus")}{handleInformationIcon("resolveStatus")}
              </MotifCheckbox>
             
              </div>
              <div className="motif-row">

              <div className="motif-col-sm-3">
              <MotifLabel aria-label="Input" for="input">
                {t("pages.configuration.stages.portalStatus")}

                {handleInformationIcon("portalStatus")}
              </MotifLabel>
              <MotifFormField aria-label="Input" role="group">
           
                {/*@ts-ignore */}
                <MotifInput onChange={(e: any) =>
                    setInputValues({
                      ...inputValues,
                      portalDisplayStatus: e.target.value,
                    })
                  }
                  value={inputValues.portalDisplayStatus}
                  onBlur={handleInputChange}
                />
              </MotifFormField>
              </div>
              </div>
              <div className="motif-container">
                <div className="motif-row">
                  <div className="motif-col-sm-3">
                    <MotifFormField>
                      <MotifLabel id="select-label">
                        {t("pages.configuration.stages.dueDateType")}
                      </MotifLabel>
                      <MotifSelect
                        onChange={(e) => handleDueDateChange(e)}
                        value={dueDate}
                      >
                        <MotifOption value={DueDateType.TimeLimit}>
                          {t("pages.configuration.stages.timeLimit")}
                        </MotifOption>
                        <MotifOption value={DueDateType.Deadline}>
                          {t("pages.configuration.stages.deadline")}
                        </MotifOption>
                      </MotifSelect>
                    </MotifFormField>
                  </div>
                  </div>
                  &nbsp;
                  <div className="motif-row">

                  <div className="motif-col-sm-3">
                    {dueDate === DueDateType.TimeLimit ? (
                      <>
                        <MotifLabel aria-label="Input" for="input">
                          {t("pages.configuration.stages.timeLimitDays")}
                          {handleInformationIcon("timeLimitDays")}

                        </MotifLabel>
                        <MotifFormField aria-label="Input" role="group">
                          {/*@ts-ignore */}
                          <MotifInput onChange={(e: any) => {
                              const value = e.target.value;

                              if (value < 0) {
                                setErrorMessage("Time limit days cannot be negative");
                                return;
                              }
                              setErrorMessage("");
                              setInputValues({
                                ...inputValues,
                                timeLimitDays: value,
                              })
                            }}
                            value={inputValues.timeLimitDays ? inputValues.timeLimitDays : ""}
                            type="number"
                            onBlur={handleInputChange}
                          />
                        </MotifFormField>
                      </>
                    ) : (
                      <>
                        <MotifFormField aria-label="Form Field Date Picker">
                          <MotifLabel aria-label="Date">
                            {t("pages.configuration.stages.deadlineDate")}
                            {handleInformationIcon("deadlineDate")}

                          </MotifLabel>
                          <FormattedDatePicker
                            onChange={(value: any) => {
                              handleDateError(value, setErrorMessage, setInputValues, inputValues);
                            }}
                            value={inputValues.timeLimitDate ? new Date(inputValues.timeLimitDate!) : undefined}
                            onBlur={handleInputChange}
                            minDate={new Date()}
                          />
                        </MotifFormField>
                      </>
                    )}
                    <MotifErrorMessage>{errorMessage}</MotifErrorMessage>
                  </div>
                </div>
              </div>
            </div>
          </MotifCardBody>
        </MotifCard>
      </div>

      <CreateEditStatus
        show={showCreateStatus}
        setShow={setShowCreateStatus}
        statusData={inputValues}
        isEdit={true}
        getStageData={getStageData}
        numStatuses={0}
      />

      <DeleteStageStatus
        show={showDeleteStatus}
        setShow={setShowDeleteStatus}
        id={statusData.id}
        getStageData={getStageData}
        isStage={false}
        selectedStage={selectedStage}
        setSelectedStage={setSelectedStage}
        setDeleteErrorMessage={setDeleteErrorMessage}
     />
    </>
  );
}
