import React, { useEffect } from "react";
import TabPanel from "@/components/TabPanel/TabPanel";
import SummaryCaseDetails from "@/pages/CaseDetail/Summary/SummaryCaseDetails/SummaryCaseDetails";
import DebtorTabContent from "@/features/debtor/debtorTab/DebtorTabContent";
import CreditorTabContent from "@/features/creditor/creditorsTab/creditorTabContent";
import ClaimsTabContent from "@/features/claims/claimsTab/claimsTabContent";
import Configuration from "@/pages/CaseDetail/Configuration/Configuration";
import Voting from "@/pages/CaseDetail/Voting/Voting";
import Distributions from "@/pages/CaseDetail/Distributions/Distributions";
import ImportAndExport from "@/pages/CaseDetail/Import&Export/ImportandExport";
import PortalConfiguration from "@/pages/CaseDetail/PortalConfiguration/PortalConfiguration";
import PaymentStatus from "@/pages/CaseDetail/PaymentStatus/PaymentStatus";
import Reporting from "@/pages/CaseDetail/Reporting/Reporting";

import { ENEXPECTED_ERROR } from "@/Constant";
import { useParams } from "react-router-dom";
import { useCase } from "@/features/case/use-case";
import { useGetCaseSummaryQuery } from "@/services/caseSummary/caseSummaryReducer";
import { useDispatch } from 'react-redux'
import { setPermissions } from "@/services/cases/permissionReducer";
import { MotifProgressLoader } from "@ey-xd/motif-react"; 
interface DefinedTabPanelProps { 
  defaultActiveKey?: number;
}
interface BannerData {
  debtorId: string;
  caseId: string;
  name: string;
  description: string;
  createdBy: string;
  updatedBy: string;
  isActive: boolean;
}

const bannerData: BannerData = {
  debtorId: "d05e52a1-6b55-4ad2-9c34-f5aaadd806fb",
  caseId: "536be3c1-f9b0-4c4d-b530-e516d75f347e",
  name: "Anjana Dharmasiri",
  description: "Test Anjana ",
  createdBy: "CA013334848",
  updatedBy: "CA013334848",
  isActive: true,
};
const bannerDataArray: BannerData[] = [bannerData];

const tabPanelData = {
  title: [
    "pages.caseOverView.tabPannelDetails.summaryLabel",
    "pages.caseOverView.tabPannelDetails.debtorsLabel",
    "pages.caseOverView.tabPannelDetails.creditorsLabel",
    "pages.caseOverView.tabPannelDetails.claimsLabel",
    "pages.caseOverView.tabPannelDetails.votingLabel",
    "pages.caseOverView.tabPannelDetails.configurationLabel",
    "pages.caseOverView.tabPannelDetails.distributionsLabel",
    "pages.caseOverView.tabPannelDetails.importHistoryLabel",
    "pages.caseOverView.tabPannelDetails.portalConfigurationLabel",
    "pages.caseOverView.tabPannelDetails.paymentStatusLabel",
    "pages.caseOverView.tabPannelDetails.reportingLabel",
  ],
  content: [
    <SummaryCaseDetails />,
    <DebtorTabContent bannerData={bannerDataArray} />,
    <CreditorTabContent />,
    <ClaimsTabContent />,
    <div>
      <Voting />
    </div>,
    <Configuration />,
    <Distributions />,
    <ImportAndExport />,
    <PortalConfiguration />,
    <PaymentStatus />,
    <Reporting />,
  ],
};


const DefinedTabPanel: React.FC<DefinedTabPanelProps> = ({
  defaultActiveKey,
}) => {
  const { caseId } = useParams<{ caseId: string }>();
  const caseData = useCase();
  const { data, error, isLoading } = useGetCaseSummaryQuery(
    caseId || caseData?.caseId
  );
  
  const dispatch = useDispatch();
  const permissionData =  {
    Creditor: ["Read",  "Write", "Delete", "Add"],
    Debtor: ["Read", "Write", "Delete", "Add"],
    Unit: ["Read",  "Write", "Delete", "Add"],
    ClaimItemCategory: ["Read",  "Write", "Delete", "Add"],
    ClaimStageDefinition: ["Read",  "Write", "Delete", "Add"],
    ClaimStatusDefinition: ["Read",  "Write", "Delete", "Add"],
    RateTier: ["Read",  "Write", "Delete", "Add"],
    RateTierTemplate: ["Read"],
    CaseType: ["Read",  "Write", "Delete", "Add"],
    User: ["Read"],
    UserCaseGroup: ["Read"],
    AttributeDefinition: ["Read"],
    AttributeValue: ["Read"],
    Scheme: ["Read"],
    SchemeVersion: ["Read"],
    Class: ["Read"],
    Rule: ["Read"],
    ClaimStageInstance: ["Read"],
    ClaimStatusInstance: ["Read"],
    Claim: ["Read",  "Write", "Delete", "Add"],
    ClaimItem: ["Read",  "Write", "Delete", "Add"],
    ClaimHistorySnapshot: ["Read"],
    ClaimItemHistorySnapshot: ["Read"],
    Account: ["Read"],
    CalculationStep: ["Read"],
    CalculationSettings: ["Read"],
    Distribution: ["Read"],
    StepClassFilter: ["Read"],
    StepDataSource: ["Read"],
    StepLimitAmount: ["Read"],
    PayeeType: ["Read"],
    Ballot: ["Read"]
};

  useEffect(() => {
    
    if (data) {
      
      dispatch(setPermissions(permissionData));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  
  if (isLoading) {
    return <MotifProgressLoader variant="circular" size="xs" />;
  }
  if (error) {
    return <div> {ENEXPECTED_ERROR}</div>;
  }
  console.log("permissionData", "useEffect");
  return (
    <>
      <div className="ccwp_table_wrapper ccwp_table_dropdown_wrapper">
        <TabPanel data={tabPanelData} defaultActiveKey={defaultActiveKey} />
      </div>
    </>
  );
};

export default DefinedTabPanel;  
 