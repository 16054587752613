import { useEffect, useState } from 'react';
import { customComparator, formatAmountUnitDecimalPlace } from '@/utility/common';
import { useTranslation } from 'react-i18next';
export const useColumnDefs = (caseData?: any) => {
  const {t}=useTranslation();
  const CustomHeader = () => {
    const { defaultUnit } = caseData;
    const [unitName, setUnitName] = useState(defaultUnit?.name);
  
    useEffect(() => {
      setUnitName(defaultUnit?.name);
    }, [defaultUnit]);
  
     return  `(${unitName})`;
  };
  const amountCellRenderer = (field: string) => (params: any) => {
    const { defaultUnit } = caseData;
    const decimalPlace = defaultUnit?.decimalPlace;
    const amount = params.data[field];
    return formatAmountUnitDecimalPlace(amount, decimalPlace);
  };
  return [
    {
      cellRenderer: "nameCellRenderer",
      field: "creditorName",
      type:"creditorNameType",
      headerName: t("pages.creditors.creditorColumnsDetails.name"),
      sortable: true,
      comparator: customComparator,
      unSortIcon: true,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
      },
   
    },
    {
      cellRenderer: "totalClaimsCellRenderers",
      field: "totalClaims",
      type:"totalClaimsType",
      headerName: t("pages.creditors.creditorColumnsDetails.totalClaims"),
      sortable: true,
      unSortIcon: true,
   
    },
    {
      cellRenderer: amountCellRenderer('originalAmount'),
      field: "originalAmount",
      type:"originalAmountType",
      sortable: true,
      unSortIcon: true,
      headerName: t("pages.creditors.creditorColumnsDetails.originalAmount", { unitName: CustomHeader() }),
      headerComponentParams: { params: "Original Amount" },
      headerHeight: 150,
      cellClass: 'align-right',
      headerClass: 'align-right',
    
    },
    {
      cellRenderer: amountCellRenderer('claimAmount'),
      field: "claimAmount",
      type:"claimAmountType",
      headerName:t("pages.creditors.creditorColumnsDetails.claimAmount", { unitName: CustomHeader() }),
      headerComponentParams: { params: "Claim Amount" },
      headerHeight: 150,
      sortable: true,
      unSortIcon: true,
            cellClass: 'align-right',
      headerClass: 'align-right',
    
    },
    {
      cellRenderer: amountCellRenderer('latestCreditorPositionAmount'),
    
      field: "latestCreditorPositionAmount",
      type:"latestCreditorPositionAmountType",
      headerName: t("pages.creditors.creditorColumnsDetails.latestCreditorPositionAmount", { unitName: CustomHeader() }),
      headerComponentParams: { params: "Latest Creditor Position Amount" },
      headerHeight: 150,
      sortable: true,
      unSortIcon: true,
            cellClass: 'align-right',
      headerClass: 'align-right',
    },
    {
      cellRenderer: amountCellRenderer('latestDebtorPositionAmount'),
    
      field: `latestDebtorPositionAmount`,
      type:"latestDebtorPositionAmountType",
      headerName: t("pages.creditors.creditorColumnsDetails.latestDebtorPositionAmount", { unitName: CustomHeader() }),
      headerComponentParams: { params: "Latest Debtor Position Amount" },
      headerHeight: 150,
      sortable: true,
      unSortIcon: true,
            cellClass: 'align-right',
      headerClass: 'align-right',
    },
    {
            field: "acceptedForDistribution",
      type:"acceptedForDistributionType",
      headerName: t("pages.creditors.creditorColumnsDetails.resolvedAmount", { unitName: CustomHeader() }),
      headerComponentParams: { params: "Resolved Amount" },
      headerHeight: 150,
      sortable: true,
      unSortIcon: true,
            cellClass: 'align-right',
      headerClass: 'align-right',
    },
    {
      cellRenderer: "actionCellRenderers",
flex:1,
minWidth:200,
      field: "action",
      headerName: "",
      sortable: false,
    },
  ];
};
