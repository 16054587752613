const portalConfig = {
  title: "Portal Config",
  formManagement: {
    title: "Form Management",
  },
  formSummary: {
    title: "Form Summary",
  },
  formSetup: {
    title: "Form Set Up",
    sections: {
      heading: "Sections",
      list: "Section List",
      settings: "Section Settings",
      popup: {
        title: "Add a new section",
      },
    },
    fields: {
      heading: "Field",
    },
    sectionConfig: {
      heading: "Section Settings",
      fieldsDesc:
        "You will be able to select fields that apply to the above entity and create display only fields.",
    },
    messages: {
      sections: {
        sort: {
          success: "Section order updated successfully",
          error: "Failed to update section order",
        },
        delete: {
          success: "Section deleted successfully",
          error: "Failed to delete section",
        },
        create: {
          success: "Section created successfully",
          error: "Failed to create section",
        },
        save: {
          success: "Section saved successfully",
          error: "Failed to save section",
        },
      },
    },
  },
  sectionMapping: {
    creditor: "Creditor",
    claim: "Claim",
    claimItem: "Claim Item",
  },
  labels: {
    selectField: "Select Field Type",
    label: "Label",
    displayName: "Display Name",
    displayNameFR: "Display Name (French)",
    tooltip: "Tool-tip (English)",
    tooltipFR: "Tool-tip (French)",
    description: "Description",
    selectMapping: "Select mapping",
    fieldType: "Field Type",
    text: "Text",
    freeText: "Free Text",
    number: "Number",
    date: "Date",
    boolean: "Boolean",
    required: "Make this field required",
    yes: "Yes",
    no: "No",
    defaultValue: "Default Value",
    displayOnPDF: "Display on PDF",
    forSignature: "For Signature",
    useEntireRow: "Use Entire Row",
    textEN: "Text (English)",
    textFR: "Text (French)",
    embededdedValue: "Embedded Value",
  },
  sectionLabels: {
    displayName: "Section Display Name",
    displayNameFR: "Section Display Name (French)",
    description: "Section Description",
    selectMapping: "Add fields for",
    columns: "Number of columns",
  },
  controls: {
    save: "Save",
    cancel: "Cancel",
    add: "Add",
  },
};

export default portalConfig;
