export const BALLOT_ID = "name";
export const BALLOT_NAME = "Ballot Name(English)";
export const BALLOT_ID_FRENCH = "nameFrench";
export const BALLOT_NAME_FRENCH = "Ballot Name(French)";
export const BALLOT_DESCRIPTION = "Attribute Description";
export const BALLOT_DESCRIPTION_ID = "description";
export const BALLOT_DESCRIPTION_FRENCH_ID = "descriptionFrench";
export const BALLOT_DESCRIPTION_FRENCH = "Attribute Description";
export const BALLOT_SCHEME_ID = "schemeVersionId";
export const BALLOT_SCHEME_LABEL = "Scheme";
export const BALLOT_CLASS_ID = "classId";
export const BALLOT_CLASS_LABEL = "Allowed Class";
export const BALLOT_MEETING_ID = "meetingId";
export const BALLOT_MEETING_LABEL = "Meeting";
export const BALLOT_PROXY_ID = "isProxyRequired";
export const BALLOT_PROXY_LABEL = "Attendance By Proxy Required";
export const BALLOT_STATUS = "status";
export const BALLOT_STATUS_LABEL = "BallotStatus";
export const BALLOT_VOTED_ID = "successConditionCreditorCount";
export const BALLOT_VOTED_LABEL = "% Who Voted Yes";
export const BALLOT_CLAIM_ID = "successConditionClaimValue";
export const BALLOT_ClAIM_LABEL = "% Claim Value";
export const BALLOT_MEETING_NAME_ID = "name";
export const BALLOT_MEETING_NAME = "Meeting Name";
export const BALLOT_MEETING_TIME_ID = "meetingDate";
export const BALLOT_MEETING_TIME = "Meeting Date";

export const BALLOT_VERSION_ID = "id";
export const BALLOT_VERSION_NAME = "View Version";
