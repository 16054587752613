import Table from "@/components/Table/table";
import {
  MotifButton,
  MotifErrorMessage,
  MotifIcon,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { IconoirWarningTriangle, IconoirEditPencil } from "@ey-xd/motif-icon";
import { useEffect, useState } from "react";
import { actionIcDelete24px } from "@ey-xd/motif-react/assets/icons";
import {
  useGetRateTierListByCaseQuery,
  useDeleteRateTierMutation,
} from "./calculationStepReducer";
import { RateTierType } from "./type";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import FormModalRateTier from "./FormModal/FormModalRateTier";
import "@/features/distributions/style.css";
import RateTierEditForm from "./FormModal/RateTierEditForm";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import FormModalRateTierTemplate from "./FormModal/FormModalRateTierTemplate";
import FormModalCreateTemplate from "./FormModal/FormModalCreateRateTierTemplate";
import { useGetRateTierListByTemplateQuery } from "@/services/rateTierTemplate/RateTierTemplateReducer";
import { useTranslation } from "react-i18next";

type ClassFilterProps = {
  resolvedStatus: boolean;
  caseId: string;
  stepId: string;
  distributionStatus: boolean;
};

export const RateTier = ({
  resolvedStatus,
  caseId,
  stepId,
  distributionStatus
}: ClassFilterProps) => {
const {t}=useTranslation()
  const [isVisibleCancel, setIsVisibleCancel] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isEditVisible, setEditVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const [isRefetch, setIsRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [isTemplateModalVisible, setTemplateModalVisible] = useState(false);
  const [isCreateTemplateModalVisible, setCreateTemplateModalVisible] = useState(false);
  const [sourceData, setSourceData] = useState<RateTierType[]>([]);
  const [tableTitle, setTableTitle] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const { data, isLoading, error, refetch } = useGetRateTierListByCaseQuery({
    CalculationStepId: stepId,
    CaseId: caseId,
  });
  const { data: templateData, isFetching: isTemplateFetching } =
    useGetRateTierListByTemplateQuery(
      { RateTierTemplateId: selectedTemplateId!, CaseId: caseId },
      { skip: !selectedTemplateId }
    );

  useEffect(() => {
    if (isRefetch) {
      refetch();
      setIsRefetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetch]);

  const handleTemplateApply = (templateId: string, templateName: string) => {
    setSelectedTemplateId(templateId);
    setTableTitle(templateName);
    setIsDirty(false);
  };

  useEffect(() => {
    const activeData = templateData && selectedTemplateId ? templateData : data;

    if (activeData && activeData.length > 0) {
      const formattedData: RateTierType[] = [];
      // eslint-disable-next-line array-callback-return
      activeData.map((item: any) => {
        let obj = {
          maxAmount: "",
          minAmount: "",
          rate: "",
          adjustment: "",
          id: "",
          rateTierGroupBy: "",
        };
        obj.maxAmount = item?.maxAmount;
        obj.minAmount = item.minAmount;
        obj.rate = item.rate ? item.rate : "-";
        obj.adjustment = item.adjustment ? item.adjustment : "-";
        obj.id = item.id ? item.id : "-";
        obj.rateTierGroupBy = item.rateTierGroupBy;
        formattedData.push(obj);
      });
      setSourceData(formattedData);
      setIsDirty(false);
    } else {
      setSourceData([]);
      setIsDirty(false);
    }
  }, [data, templateData, selectedTemplateId]);
  

  const [deleteRateTier] = useDeleteRateTierMutation();

  async function deleteRateTierHandler(Id: string) {
    const response = await deleteRateTier({ id: Id, calculationStepId: stepId });
    if (response.hasOwnProperty("data")) {
      refetch();
    }
  }

  const TitleComplement = () => {
    const isGridNotBlank = sourceData.length > 0;
    const isTemplateSelected = !!selectedTemplateId; 
  
    const renderButtons = () => {
      if (!isGridNotBlank) {
        return (
          <MotifButton
            onClick={() => setTemplateModalVisible(true)}
            size="small"
            type="submit"
            variant="secondary"
            disabled={resolvedStatus}
          >
          {t("pages.distributions.distributionStepsTab.rateTierTab.createTemplate")}
          </MotifButton>
        );
      }
  
      if (isTemplateSelected && !isDirty) {
        return (
          <MotifButton
            onClick={() => setTemplateModalVisible(true)}
            size="small"
            type="submit"
            variant="secondary"
            disabled={resolvedStatus}
          >
          {t("pages.distributions.distributionStepsTab.rateTierTab.createTemplate")}
          </MotifButton>
        );
      }
  
      if (isDirty) {
        return (
          <MotifButton
            onClick={() => setCreateTemplateModalVisible(true)}
            size="small"
            type="submit"
            variant="secondary"
            disabled={resolvedStatus}
          >
                    {t("pages.distributions.distributionStepsTab.rateTierTab.saveTemplete")}

          </MotifButton>
        );
      }
  
      return (
        <MotifButton
          onClick={() => setTemplateModalVisible(true)}
          size="small"
          type="submit"
          variant="secondary"
          disabled={resolvedStatus}
        >
          {t("pages.distributions.distributionStepsTab.rateTierTab.createTemplate")}
        </MotifButton>
      );
    };
  
    return (
      <div style={{ display: "flex", gap: "1rem" }}>
        {renderButtons()}
        <MotifButton
          onClick={() => {
            setIsVisible(true);
          }}
          size="small"
          type="submit"
          variant="secondary"
          disabled={resolvedStatus}
        >
          +
        </MotifButton>
      </div>
    );
  };
  
  
  

  
  const handleGridModification = () => {
    setIsDirty(true);
  };

  useEffect(() => {
    if (sourceData.length > 0) {
      handleGridModification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceData]);

  const handleTemplateSave = (templateName: string, year: string, jurisdiction: string) => {
    const title = `${templateName}, ${year}${jurisdiction ? `, ${jurisdiction}` : ""}`;
    setTableTitle(title);
    setIsDirty(false);
    //setSelectedTemplateId(null);
  };
  

  const headerData = {
    title: "Rate Tier",
    description: "Descriptive text that describes your page or content.",
  };
  const ActionsCellRenderer = (params: any) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <MotifButton
            className="action-delete-button"
            size="small"
            variant="primary"
            disabled={distributionStatus}
            onClick={() => {
              setShowModal(true);
              setDeleteId(params?.data?.id);
            }}
          >
            <MotifIcon
              title="Disable Unit"
              fill="none"
              iconFunction={actionIcDelete24px}
            />
          </MotifButton>
          <MotifButton
            size="small"
            disabled={distributionStatus}
            variant="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setEditVisible(true);
              setEditData(params?.data);
            }}
          >
            <MotifIcon
              iconFunction={IconoirEditPencil}
              stroke="blue"
              strokeWidth="2"
              size="20"
            />
          </MotifButton>
        </div>
      </>
    );
  };

  const columnClassFilterDefs = [
    // {
    //   field: "rateTierGroupBy",
    //   headerName: "Group By",
    //   width: "100",
    //   cellRenderer: function (params: any) {
    //     return (
    //       <>
    //         {params?.data.rateTierGroupBy === 0
    //           ? "Creditor"
    //           : params?.data.rateTierGroupBy === 1
    //           ? "Claim"
    //           : "Creditor/Assignee"}
    //       </>
    //     );
    //   },
    // },
    {
      field: "maxAmount",
      headerName: t("pages.distributions.distributionStepsTab.rateTierTab.columnDetails.column1"),
      cellClass: "align-right",
      flex: 1,
      headerClass: "stepHeaderClass",
      cellRenderer: function (params: any) {
        return params?.data.maxAmount === null
          ? "Unlimited"
          : params?.data.maxAmount;
      },
    },
    {
      field: "minAmount",
      headerName: t("pages.distributions.distributionStepsTab.rateTierTab.columnDetails.column2"),
      cellClass: "align-right",
      flex: 1,
      headerClass: "stepHeaderClass",
      cellRenderer: function (params: any) {
        return params?.data.minAmount === null
          ? "Unlimited"
          : params?.data.minAmount;
      },
    },
    {
      field: "rate",
      headerName: t("pages.distributions.distributionStepsTab.rateTierTab.columnDetails.column3"),
      cellClass: "align-center",
      flex: 1,
      headerClass: "stepHeaderCenterClass",
      cellRenderer: function (params: any) {
        return params.data.rate === "-" ? "0" : params.data.rate;
      },
    },
    {
      field: "adjustment",
      headerName: t("pages.distributions.distributionStepsTab.rateTierTab.columnDetails.column4"),
      cellClass: "align-center",
      flex: 1,
      headerClass: "stepHeaderCenterClass",
      cellRenderer: function (params: any) {
        return params?.data.adjustment === null ? "-" : params?.data.adjustment;
      },
    },
    {
      cellRenderer: ActionsCellRenderer,
      field: "action",
      headerName: "",
      flex: 1,
      sortable: false,
      cellClass: "align-center",
    },
  ];

  if (error) {
    return (
      <MotifErrorMessage
        style={{
          textAlign: "center",
          display: "block",
          alignItem: "center",
        }}
      >
      {t("global.globalNotifications.wentWrong")}
      </MotifErrorMessage>
    );
  }
  if (isLoading) {
    return <MotifProgressLoader show variant="default" />;
  }

  const cancelBtnApproveHandler = () => {
    setIsVisibleCancel(false);
  };
  return (
    <>
      {successMsg && (
        <NotificationGrid
          show={true}
          type="success"
          message="pages.distributions.distributionStepsTab.rateTierTab.successMessage"

        />
      )}
      <div>
        {isLoading || isTemplateFetching ? (
          <MotifProgressLoader show variant="default" className="mar-b-20" />
        ) : (
          <div className="mar-b-20 mar-t-20">
            <MotifModal
              onClose={() => setShowModal(false)}
              show={showModal}
              focusTrapOptions={{
                tabbableOptions: {
                  displayCheck: "none",
                },
              }}
            >
              <div className="confirmationModalWrapper creditor_disabling_model">
                <MotifModalHeader
                  headerIcon={
                    <>
                      <span
                        style={{
                          backgroundColor: "rgb(252, 245, 243)",
                          borderRadius: "50px",
                          padding: "5px 8px 2px 8px",
                        }}
                      >
                        <MotifIcon
                          iconFunction={IconoirWarningTriangle}
                          strokeWidth="1"
                          size="15"
                          stroke="red"
                        />{" "}
                      </span>
                      <span
                        style={{
                          fontSize: 16,
                          display: "block",
                          width: "100%",
                          marginLeft: "10px",
                        }}
                      >
                        {t("global.globalNotifications.warning")}
                      </span>
                    </>
                  }
                ></MotifModalHeader>

                <MotifModalBody style={{ paddingTop: "0px" }}>
                  <span style={{ fontWeight: "300" }}>
{t("pages.distributions.distributionStepsTab.rateTierTab.deleteWarningMessage")}                  </span>
                </MotifModalBody>
                <MotifModalFooter>
                  <MotifButton
                    size="small"
                    type="submit"
                    variant="secondary"
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      deleteRateTierHandler(deleteId);
                      setShowModal(false);
                    }}
                  >
                    {t("global.globalActions.yes")}
                  </MotifButton>
                  <MotifButton
                    className=""
                    onClick={() => setShowModal(false)}
                    size="small"
                    type="button"
                  >
                    {t("global.globalActions.cancel")}
                  </MotifButton>
                </MotifModalFooter>
              </div>
            </MotifModal>
            <Table
              columnDefs={columnClassFilterDefs}
              cellRenderers={sourceData.sort((a, b) => Number(a.minAmount) - Number(b.minAmount))}
              TitleComplement={TitleComplement}
              title={"pages.distributions.distributionStepsTab.rateTierTab.tableTitle"}
              titleExt={tableTitle}
              description={
"pages.distributions.distributionStepsTab.rateTierTab.tableDescription"              }
              caseRenderers={headerData}
              pagination={false}
              overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
            />
          </div>
        )}
        <ConfirmationModal
          isVisible={isVisibleCancel}
          setIsVisible={setIsVisibleCancel}
          cancelBtnApproveHandler={cancelBtnApproveHandler}
          cancelBtnRejectHandler={() => {
            setIsVisibleCancel(false);
          }}
        />
        <FormModalRateTier
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          formData={null}
          caseId={caseId}
          stepId={stepId}
          setIsRefetch={setIsRefetch}
        />
        <RateTierEditForm
          isEditVisible={isEditVisible}
          setEditVisible={setEditVisible}
          formData={null}
          caseId={caseId}
          stepId={stepId}
          refetch={refetch}
          editData={editData}
          setSuccessMsg={setSuccessMsg}
        />
        <FormModalRateTierTemplate
          isVisible={isTemplateModalVisible}
          setIsVisible={setTemplateModalVisible}
          caseId={caseId}
          stepId={stepId}
          setIsRefetch={setIsRefetch}
          onApplyTemplate={handleTemplateApply}
        />
        <FormModalCreateTemplate
          isVisible={isCreateTemplateModalVisible}
          setIsVisible={setCreateTemplateModalVisible}
          caseId={caseId}
          stepId={stepId}
          onTemplateSave={handleTemplateSave}
        />
      </div>
    </>
  );
};
export default RateTier;
