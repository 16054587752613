import Table from "@/components/Table/table";
import { ClaimItemColumnDefs } from "./ClaimItemColumnDefination";
import { useCustomCellRenderers } from "./ClaimItemColumnActions";
const Claim = ({ previewData }: { previewData: any }) => {
	const pinnedBottomRowData = (claim: any) => {
		return [
			{
				columnField1: <>Total</>,
				columnField2: <></>,
				columnField3: (
					<>{claim && claim?.claimItemTotalAmount?.totalOriginalAmt}</>
				),
				columnField4: (
					<>{claim && claim?.claimItemTotalAmount?.totalCurrentAmt}</>
				),
				columnField5: (
					<>
						{claim &&
							claim?.claimItemTotalAmount?.totalLatestCreditorPositionAmt}
					</>
				),
				columnField6: (
					<>
						{claim && claim?.claimItemTotalAmount?.totalLatestDebtorPositionAmt}
					</>
				),
				columnField7: (
					<>{claim && claim?.claimItemTotalAmount?.totalResolvedAmt}</>
				),
			},
		];
	};
	const caseRenderers = useCustomCellRenderers();
	return (
		<div className="claim_item_table">
			{previewData.map((claim: any, index: number) => (
				<div className="mar-b-20" key={index}>
					<Table
						columnDefs={ClaimItemColumnDefs}
						cellRenderers={claim && claim?.claimItems}
						TitleComplement={() => <></>}
						title={`Claim ref # ${claim.claimReference}`}
						description={`Claim item list for claim ref ${claim.claimReference}`}
						caseRenderers={caseRenderers}
						pagination={false}
						pinnedBottomRowData={
							claim?.claimItems && claim?.claimItems.length > 0
								? pinnedBottomRowData(claim)
								: null
						}
					/>
				</div>
			))}
		</div>
	);
};
export default Claim;
