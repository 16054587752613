import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
} from "@/libs/@ey-xd/motif-react";

import {
  useLazyDeleteStageQuery,
  useLazyDeleteStatusQuery,
} from "@/services/stages/stagesReducer";

import "./Stages.css";
interface ApiResponse {
  data?: any;
  error?: any;
}

export default function DeleteStageStatus({
  show,
  setShow,
  id,
  getStageData,
  isStage,
  selectedStage,
  setSelectedStage,
  setDeleteErrorMessage,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  id: any;
  getStageData: (isCreate: boolean) => void;
  isStage: boolean;
  selectedStage: string;
  setSelectedStage: (stageId: string) => void;
  setDeleteErrorMessage: (message: string) => void;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteStageQuery] = useLazyDeleteStageQuery();
  const [deleteStatusQuery] = useLazyDeleteStatusQuery();
  const { caseId } = useParams();
  const claimStatusDefinitionId = id;

  const handleDelete = () => {
    const deleteQuery = isStage ? deleteStageQuery : deleteStatusQuery;
    setDeleteErrorMessage("");
    setIsLoading(true);
    deleteQuery({ claimStatusDefinitionId, caseId }).then(
      (response: ApiResponse) => {
        if (response.data) {
          if (isStage && selectedStage === id) {
            setSelectedStage("");
            getStageData(false);
          } else {
            getStageData(true);
          }
        } else {
          const errorMessage = response.error.data.message;
          setDeleteErrorMessage(errorMessage);
        }
        setIsLoading(false);
        setShow(false);
      }
    );
  };

  const handleCancel = () => {
    setShow(false);
    setDeleteErrorMessage("");
  };

  return (
    <>
      <MotifModal
        onClose={handleCancel}
        show={show}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <MotifModalHeader>
          {isStage
            ? t("pages.configuration.stages.deleteStage")
            : t("pages.configuration.stages.deleteStatus")}
        </MotifModalHeader>

        <MotifModalBody>
          {isStage
            ? t("pages.configuration.stages.deleteStageMessage")
            : t("pages.configuration.stages.deleteStatusMessage")}
        </MotifModalBody>

        <MotifModalFooter>
          <MotifButton onClick={handleCancel} type="button" variant="secondary">
            {t("pages.configuration.stages.cancel")}
          </MotifButton>
          <MotifButton
            onClick={handleDelete}
            type={isLoading ? "loader" : "submit"}
            variant="primary"
          >
            {t("pages.configuration.stages.confirm")}
          </MotifButton>
        </MotifModalFooter>
      </MotifModal>
    </>
  );
}
