import React, { useEffect } from "react";
import { Control, Controller, FieldValues, PathValue, useController, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import SelectBox from "./SelectBox";
import { Path } from "react-hook-form";

type ControlSelectType<T extends FieldValues> = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<T>;
    name: Path<T>;
    showLabel: boolean;
    label: string;
    options: {
        label: string,
        value: string,
    }[];
    setValue: UseFormSetValue<T>;
    getValues: UseFormGetValues<T>;
    defaultValue?: T[Path<T>];
    visibleOptions?: number;
    filter?: boolean;
    searchPlaceholder?: string;
    multiple?: boolean;
    onChange?: (selectedValue: string | string[]) => void;
    disabled?: boolean;
    value?: T[Path<T>];
}

export default function ControlMultiSelect<T extends FieldValues>({ name, control, required = false, options, showLabel, label, defaultValue, visibleOptions, setValue, filter, searchPlaceholder, multiple = true, onChange, disabled=false, value }: ControlSelectType<T>) {
    const {
        field,
    } = useController({
        name,
        control,
        rules: { required: required },
        defaultValue : value? value : defaultValue,
    });
    const { t } = useTranslation();
    const translatedOptions = options.map(option => {
        return {
            label: option.label,
            value: option.value,
        };
    })

    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const controllerDefaultValue = defaultValue !== undefined ? defaultValue : (multiple ? [] : options[0]?.value);

    return (
        <>
            {
                showLabel && <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={label}>{t(label)}{required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
            <Controller
                name={field.name}
                control={control}
                rules={{ required }}            
                defaultValue={controllerDefaultValue as PathValue<T, Path<T>>}
                render={({ field: { onChange: controllerOnChange, value } }) => {
                    return (
                        <>                            
                            <SelectBox
                                multiple={multiple}
                                filter={filter}
                                searchPlaceholder={searchPlaceholder}
                                visibleOptions={visibleOptions}
                                options={translatedOptions}
                                value={value}
                                defaultValue={defaultValue}
                                onChange={(selectedValue: string | string[]) => {
                                    controllerOnChange(selectedValue);
                                    if (onChange) {
                                        onChange(selectedValue);
                                    }
                                }}  
                                disabled={disabled}                                                                            
                            />
                        </>
                    );
                }}
            />
        </>
    );
}