import React from "react";
import "./banner.scss";
import { useTranslation } from "react-i18next";
import { MotifChip } from "@ey-xd/motif-react";
import { Link } from "react-router-dom";
import { useCase } from "@/features/case/use-case";

export interface BannerElement {
  label: string;
  value: string;
  elementKey?: string;
  addedClassName?: string;
  id?: string;
}

interface BannerProps {
  bannerName: string;
  bannerNameValue: string;
  elements: BannerElement[];
  addedClassName?: string;
  headerClassName?: string;
}

export default function Banner({
  bannerName,
  bannerNameValue,
  elements,
  addedClassName,
  headerClassName,
}: BannerProps) {
  const { t } = useTranslation();
  let { caseId } = useCase();

  return (
    <div className="display-flex align-items-center banner pad-v-20 pad-h-40">
      <div
        className={!headerClassName ? "pad-r-60 banner-title" : headerClassName}
      >
        <h2 className="motif-subtitle-default-regular mar-t-0 mar-b-10">
          {t(bannerName)}
        </h2>
        <h3 className="motif-h3-default-regular banner-title-value mar-v-0">
          {bannerNameValue}
        </h3>
      </div>
      <div className="display-flex">
        {elements.map((element, index) => (
          <div key={index} className={`pad-l-60`}>
            <div className="motif-body1-default-regular">
              {t(element.label)}
            </div>
            {element?.value?.toLowerCase() === "open" ? (
              <MotifChip
                dotSide="left"
                title="Case Status"
                className="pad-t-5"
                type="chip"
                variant="success"
              >
                {t("global.globalBanner.statusOpen")}
              </MotifChip>
            ) : element?.value?.toLowerCase() === "close" ? (
              <MotifChip
                dotSide="left"
                title="Case Status"
                className="pad-t-5"
                type="chip"
                variant="warning"
              >
                {t("global.globalBanner.statusClose")}
              </MotifChip>
            ) : element?.value?.toLowerCase() === "failed" ? (
              <MotifChip
                dotSide="left"
                title="Case Status"
                className="pad-t-5"
                type="chip"
                variant="error"
              >
                {t("global.globalBanner.statusFailed")}
              </MotifChip>
            ) : (
              <div
                className={`motif-h6-default-regular banner-${element.elementKey} ${addedClassName}`}
              >
                {element?.id ? (
                  <Link
                    style={{ textDecoration: "none" }}
                    className={`motif-h6-default-regular banner-${element.elementKey} ${addedClassName}`}
                    to={`/case/${caseId}/creditor/${element.id}`}
                    state={{ from: element.id }}
                  >
                    {element.value}
                  </Link>
                ) : (
                  element.value
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
