import PageSubTitle from "@/components/Form/PageSubTitle/PageSubTitle";
import React, { useEffect, useState } from "react";

import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
} from "@ey-xd/motif-react";
import EditIcon from "./EditIcon";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@/components/Form/Button/Button";
import {
  SECONDARY,
} from "@/Constant";
import { useTranslation } from "react-i18next";
import { DescriptionItemsState } from "@/pages/CaseDetail/Claim/claimSetup/Description/descriptionSlice";
import { DebtorSearchData } from "@/pages/CaseDetail/Claim/claimSetup/Debtor/debtorSlice";
import { CreditorSearchData } from "@/pages/CaseDetail/Claim/claimSetup/Creditor/creditorSlice";
import { AssigneeSearchData } from "@/pages/CaseDetail/Claim/claimSetup/SelectAssignee/selectAssigneeSlice";
import { ClaimItemsState } from "@/pages/CaseDetail/Claim/claimSetup/ClaimItems/claimItemsSlice";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { AttributesItemsState } from "../Attributes/attributeSlice";
import CustomAttributeValue from "@/components/CustomAttribute/CustomAttributeValue";
import useResetAllClaimItemsStore from "@/pages/CaseDetail/Claim/claimSetup/useResetAllClaimItemsStore";
import { useCreateClaimMutation } from "@/pages/CaseDetail/Claim/claimSetup/claimSetupReducer";
import { useUpdateCreditorContactMutation } from "@/services/creditorContactUpdate/creditorContactUpdateReducer";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

interface RootState {
  claimSetupDescription: DescriptionItemsState;
  claimSetupDebtorSearch: DebtorSearchData;
  claimSetupCreditorSearch: CreditorSearchData;
  claimSetupAssigneeSearch: AssigneeSearchData;
  claimSetupClaimItems: ClaimItemsState;
  claimSetupAttributes: AttributesItemsState;
}

interface ClaimCreationRequestDTO {
  caseId: string | undefined;
  debtorId: string;
  creditorId?: string;
  defaultAssigneeId: string;
  claimStageDefinitionId: string;
  claimContactId?: string;
  customAttributes: any[];
}

interface CreateClaimItemRequestDTO {
  //claimId: string;
  categoryId: string;
  natureOfClaim: string;
  assigneeId: string | null;
  currentAmount: string | number;
  currentAmountUnitId: string;
}

interface EditCreditorContactRequestDTO {
  creditorContactId: string;
  creditorId: string;
  firstName: string;
  lastName: string;
  prefix: string;
  address1: string;
  address2: string | null;
  city: string;
  provinceStateId: string | null;
  postalCode: string;
  countryId?: string | undefined | null;
  phone: string;
  fax: string;
  email: string;
  relationshipToCreditor: string;
}

interface CreateCreditorRequestDTO {
  caseId: string | undefined;
  isOrganization: boolean;
  prefix: string;
  firstName: string;
  lastName: string;
  businessName: string;
  description: string;
  relationshipToDebtor: string;
  contactList: any[];
  IsRequestedForAssignee?: boolean,
}
interface DocumentCreateRequestDTO {
  caseId: string | undefined;
  entityId?: string | null;
  documentTypeId: string | null;
  displayName: string;
  comment?: string;
  FileName?: string;
}

export interface ClaimSetupPostData {
  claimCreationRequestDTO: ClaimCreationRequestDTO;
  createClaimItemRequestDTO: CreateClaimItemRequestDTO[];
  editCreditorContactRequestDTO?: EditCreditorContactRequestDTO;
  createCreditorRequestDTO?: CreateCreditorRequestDTO[];
  stageDocumentCreateRequestDTO: DocumentCreateRequestDTO | null;
  claimDocumentsDTO: DocumentCreateRequestDTO[] | null;
}

export default function ReviewAndSubmit({
  stageDoc,
  files,
}: {
  stageDoc: File | null;
  files: File[] | null;
}) {
  const { t } = useTranslation();
  const { caseId } = useParams();
  const [isVisible, setIsVisible] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const resetAllClaimItemsStore = useResetAllClaimItemsStore();

  const handleReviewDiscardAllData = () => {
    resetAllClaimItemsStore();
    setIsVisible(false);
    navigate(`/case/${caseId}?activeTab=3`);
  };

  const handleReviewCancelData = () => {
    setIsVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));

  };

  const claimSetupStageCompleteData = useSelector(
    (state: RootState) =>
      state.claimSetupDescription.selectedStageCompleteDetail
  );
  const claimDocumentData = useSelector(
    (state: RootState) => state.claimSetupDescription.claimDocument
  );
  const stageDocumentData = useSelector(
    (state: RootState) => state.claimSetupDescription.stageDocument
  );
  const claimSetupDebtorCompleteData = useSelector(
    (state: RootState) =>
      state.claimSetupDebtorSearch.selectedDebtorCompleteDetail
  );
  // const claimSetupCreditorContactID = useSelector(
  //   (state: RootState) =>
  //     state.claimSetupCreditorSearch.selectedCreditorContactID
  //       .creditorContactIDFromCreditor
  // );
  const claimSetupCreditorIsNewCreditorType = useSelector(
    (state: RootState) => state.claimSetupCreditorSearch.isNewCreditor
  );
  const claimSetupNewCreditorData = useSelector(
    (state: RootState) => state.claimSetupCreditorSearch.newCreditorDetail
  );
  const claimSetupAssigneeCompleteData = useSelector(
    (state: RootState) =>
      state.claimSetupAssigneeSearch.selectedAssigneeCompleteDetail
  );
  const claimSetupAssigneeIsNewAssigneeType = useSelector(
    (state: RootState) => state.claimSetupAssigneeSearch.isNewAssignee
  );
  const claimSetupNewAssigneeData = useSelector(
    (state: RootState) => state.claimSetupAssigneeSearch.newAssigneeDetail
  );
  const claimSetupClaimItemsData = useSelector(
    (state: RootState) => state.claimSetupClaimItems.claimItems
  );

  const claimSetupClaimItemsCreditorContactDetails = useSelector(
    (state: RootState) => {
      return state.claimSetupCreditorSearch.searchCreditorContactDetailsData;
    }
  );
  const claimSetupClaimItemsCompleteLabelDetails = useSelector(
    (state: RootState) => state.claimSetupClaimItems.claimItemsCompleteDetail
  );
  const claimSetupCreditorCountryState = useSelector(
    (state: RootState) => state.claimSetupCreditorSearch.selectedCountryState
  );
  // const claimSetupCategoryCompleteData = useSelector(
  //   (state: RootState) =>
  //     state.claimSetupClaimItems.selectedCategoryCompleteDetail
  // );
  // const claimSetupCurrencyCompleteData = useSelector(
  //   (state: RootState) =>
  //     state.claimSetupClaimItems.selectedCurrencyCompleteDetail
  // );
  const claimSetupAttributesData = useSelector(
    (state: RootState) => state.claimSetupAttributes.attributes
  );
  const navigate = useNavigate();
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(true);
  const [isDebtorOpen, setIsDebtorOpen] = useState(true);
  const [isCreditorOpen, setIsCreditorOpen] = useState(true);
  const [isSelectAssigneeOpen, setIsSelectAssigneeOpen] = useState(true);
  const [isClaimItemsOpen, setIsClaimItemsOpen] = useState(true);
  const [isClaimAttributeOpen, setIsClaimAttributeOpen] = useState(true);
  const [isDocumentOpen, setIsDocumentOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [updateCreditorContactDetails] = useUpdateCreditorContactMutation();
  const [createClaim] = useCreateClaimMutation();
  const dispatch=useDispatch()
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: true }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch]);
  const stageDocumentCreateRequestDTO =
    stageDocumentData &&
    stageDocumentData !== null &&
    stageDocumentData !== undefined
      ? {
          caseId: caseId,
          // entityId: null,
          // entityType: 2,
          displayName: stageDocumentData?.displayName,
          comment: stageDocumentData?.comment,
          documentTypeId: null,
          fileName: stageDocumentData?.fileName,
        }
      : null;

  const claimDocumentsDTO = claimDocumentData
    ? claimDocumentData.map((item: any) => ({
        caseId: caseId,
        // entityId: null,
        // entityType: 2,
        displayName: item?.displayName,
        comment: "",
        documentTypeId: null,
        FileName: item?.displayName,
      }))
    : [];

  const createClaimItemRequestDTO = claimSetupClaimItemsData.map(
    (item: any) => ({
      categoryId: item.categoryName,
      natureOfClaim: item.natureOfClaimName,
      assigneeId:
        item.assigneeSelected ||
        item.assigneeData ||
        claimSetupAssigneeCompleteData?.assigneeItems?.creditorId ||
        null,
      originalAmt: parseFloat(item.claimAmount),
      currentAmountUnitId: item.currency,
      currentAmount: parseFloat(item.claimAmount),
    })
  );

  const createRequestBody = () => {
    const attributes = claimSetupAttributesData.map(
      (value: any) => value.value
    );

    const postData: ClaimSetupPostData = {
      claimCreationRequestDTO: {
        caseId: caseId,
        debtorId: claimSetupDebtorCompleteData?.debtorId,
        creditorId: !claimSetupCreditorIsNewCreditorType
          ? claimSetupClaimItemsCreditorContactDetails?.creditorId
          : undefined,
        defaultAssigneeId: !claimSetupAssigneeIsNewAssigneeType ? 
          claimSetupAssigneeCompleteData?.assigneeItems?.creditorId : undefined,
        claimStageDefinitionId: claimSetupStageCompleteData?.id,
        claimContactId: !claimSetupCreditorIsNewCreditorType
          ? claimSetupClaimItemsCreditorContactDetails?.creditorContactId
          : undefined,
        customAttributes: attributes,
      },
      createClaimItemRequestDTO: createClaimItemRequestDTO,
      stageDocumentCreateRequestDTO: stageDocumentCreateRequestDTO,
      claimDocumentsDTO: claimDocumentsDTO,
    };

 

    if (files && claimDocumentData) {
      // postData.documentCreateRequestDTO = {
      //   caseId: caseId,
      //   documentTypeId: claimDocumentData.documentTypeId,
      //   displayName: files[0].name,
      //   comment: claimDocumentData?.comment,
      // };
    }

    if (claimSetupCreditorIsNewCreditorType) {
      postData.createCreditorRequestDTO = [
        {
          caseId: caseId,
          isOrganization: claimSetupNewCreditorData.isOrg,
          prefix: claimSetupNewCreditorData.prefix,
          firstName: claimSetupNewCreditorData.firstName,
          lastName: claimSetupNewCreditorData.lastName,
          businessName: claimSetupNewCreditorData.businessName,
          description: claimSetupNewCreditorData.description,
          relationshipToDebtor:
            claimSetupNewCreditorData.relationshipToDebtor || "",
          contactList: [
            {
              firstName: claimSetupNewCreditorData.contact.firstName,
              lastName: claimSetupNewCreditorData.contact.lastName,
              prefix: claimSetupNewCreditorData.contact.prefix,
              address1: claimSetupNewCreditorData.contact.address1,
              address2: claimSetupNewCreditorData.contact.address2,
              city: claimSetupNewCreditorData.contact.city,
              postalCode: claimSetupNewCreditorData.contact.postalCode,
              countryId: claimSetupNewCreditorData.contact.countryId === "" ? null : claimSetupNewCreditorData.contact.countryId,
              provinceStateId:
                claimSetupNewCreditorData.contact.provinceStateId === ""
                  ? null
                  : claimSetupNewCreditorData.contact.provinceStateId,
              phone: claimSetupNewCreditorData.contact.phone,
              fax: claimSetupNewCreditorData.contact.fax,
              email: claimSetupNewCreditorData.contact.email,
              isPrimaryContact: true,
              relationshipToCreditor: claimSetupNewCreditorData.contact.relationshipToCreditor,
            },
          ],
        },
      ];
    } else {
      postData.editCreditorContactRequestDTO = {
        creditorContactId:
          claimSetupClaimItemsCreditorContactDetails?.creditorContactId,
        creditorId: claimSetupClaimItemsCreditorContactDetails?.creditorId,
        firstName: claimSetupClaimItemsCreditorContactDetails?.firstName,
        lastName: claimSetupClaimItemsCreditorContactDetails?.lastName,
        prefix: claimSetupClaimItemsCreditorContactDetails?.prefix,
        address1: claimSetupClaimItemsCreditorContactDetails?.address1,
        address2: claimSetupClaimItemsCreditorContactDetails?.address2,
        city: claimSetupClaimItemsCreditorContactDetails?.city,
        provinceStateId:
          claimSetupClaimItemsCreditorContactDetails?.provinceStateId === ""
            ? null
            : claimSetupClaimItemsCreditorContactDetails?.provinceStateId,
        postalCode: claimSetupClaimItemsCreditorContactDetails?.postalCode,
        countryId: claimSetupClaimItemsCreditorContactDetails?.countryId === "" ? null : claimSetupClaimItemsCreditorContactDetails?.countryId,
        phone: claimSetupClaimItemsCreditorContactDetails?.phone,
        fax: claimSetupClaimItemsCreditorContactDetails?.fax,
        email: claimSetupClaimItemsCreditorContactDetails?.email,
        relationshipToCreditor: claimSetupClaimItemsCreditorContactDetails?.relationshipToCreditor,
      };
    }

    if (claimSetupAssigneeIsNewAssigneeType) {
      if (postData.createCreditorRequestDTO === undefined) {
        postData.createCreditorRequestDTO = [];
      }
      
      postData.createCreditorRequestDTO.push({
          IsRequestedForAssignee: true,
          caseId: caseId,
          isOrganization: claimSetupNewAssigneeData.isOrg,
          prefix: claimSetupNewAssigneeData.prefix,
          firstName: claimSetupNewAssigneeData.firstName,
          lastName: claimSetupNewAssigneeData.lastName,
          businessName: claimSetupNewAssigneeData.businessName,
          description: claimSetupNewAssigneeData.description,
          relationshipToDebtor:
            claimSetupNewAssigneeData.relationshipToDebtor || "",
          contactList: [
            {
              firstName: claimSetupNewAssigneeData.contact.firstName,
              lastName: claimSetupNewAssigneeData.contact.lastName,
              prefix: claimSetupNewAssigneeData.contact.prefix,
              address1: claimSetupNewAssigneeData.contact.address1,
              address2: claimSetupNewAssigneeData.contact.address2,
              city: claimSetupNewAssigneeData.contact.city,
              postalCode: claimSetupNewAssigneeData.contact.postalCode,
              countryId: claimSetupNewAssigneeData.contact.countryId === "" ? null : claimSetupNewAssigneeData.contact.countryId,
              provinceStateId:
              claimSetupNewAssigneeData.contact.provinceStateId === ""
                  ? null
                  : claimSetupNewAssigneeData.contact.provinceStateId,
              phone: claimSetupNewAssigneeData.contact.phone,
              fax: claimSetupNewAssigneeData.contact.fax,
              email: claimSetupNewAssigneeData.contact.email,
              isPrimaryContact: true,
              relationshipToCreditor:
                claimSetupNewAssigneeData.contact.relationshipToCreditor,
            },
          ],
        });
    } 
    return postData;
  };

  const handleEditButton = async (index: number) => {
    navigate(`/case/${caseId}/claim/claim-setup`, {
      state: { activeTabIndex: index },
    });
  };

  const saveClaimCreation = async () => {
    setIsLoading(true);
    const requestBody = createRequestBody();
    const formData = new FormData();
    formData.append("request", JSON.stringify(requestBody));

    if (stageDocumentData?.document && stageDoc) {
      formData.append("file", stageDoc);
    }

    if (claimDocumentData?.length > 0 && files && files?.length > 0) {
      files.forEach((item: any) => {
        formData.append("file", item);
      });
    }
    dispatch(setDiscardEditMode({ editMode: false }));

  
    try {
      const response = await createClaim(formData).unwrap();
      if (response?.length > 0) {
        setNotificationVisible(true);
        resetAllClaimItemsStore();
        setIsLoading(false);
        navigate(`/case/${caseId}/claim/${response}`);
      }
      if (!claimSetupCreditorIsNewCreditorType) {
        updateCreditorContactDetails(claimSetupClaimItemsCreditorContactDetails);

      }
    } catch (error) {
      console.error("Claim creation failed:", error);
      dispatch(setDiscardEditMode({ editMode: true }));

    } finally {
      setIsLoading(false);
    }
  };

  const renderDescriptionItems = () => {
    const item = claimSetupStageCompleteData;
    return (
      <>
        <div className={"motif-row mar-t-20"}>
          <div className="motif-col-lg-12">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.stage")}</div>
            <div className="motif-body2-default-bold">{item.name}</div>
          </div>
        </div>
        <div className={"motif-row mar-t-20"}>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.documentDisplayName")}</div>
            <div className="motif-body2-default-bold">
              {stageDocumentData?.displayName || "-"}
            </div>
          </div>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.documentComment")}</div>
            <div className="motif-body2-default-bold">
              {stageDocumentData?.comment || "-"}
            </div>
          </div>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.documentFileName")}</div>
            <div className="motif-body2-default-bold">
              {stageDocumentData?.fileName || "-"}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderDebtorItems = () => {
    const { name, description } = claimSetupDebtorCompleteData;
    return (
      <div className={"motif-row mar-t-20"}>
        <div className="motif-col-lg-6">
          <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.debtorName")}</div>
          <div className="motif-body2-default-bold">{name}</div>
        </div>
        <div className="motif-col-lg-6">
          <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.description")}</div>
          <div className="motif-body2-default-bold">{description}</div>
        </div>
      </div>
    );
  };

  const renderCreditorItems = () => {
    const { firstName, lastName } = claimSetupClaimItemsCreditorContactDetails;
    const item = claimSetupClaimItemsCreditorContactDetails;
    const { countryName, stateName } = claimSetupCreditorCountryState;
    return (
      <>
        <div className={"motif-row mar-t-20"}>
          <div className="motif-col-lg-6">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.creditorName")}</div>
            <div className="motif-body2-default-bold">
              {firstName + " " + lastName}
            </div>
          </div>
        </div>
        <div className={"motif-row mar-t-20"}>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.firstName")}</div>
            <div className="motif-body2-default-bold">{item.firstName}</div>
          </div>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.lastName")}</div>
            <div className="motif-body2-default-bold">{item.lastName}</div>
          </div>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.prefix")}</div>
            <div className="motif-body2-default-bold">{item.prefix || "-"}</div>
          </div>
        </div>
        <div className={"motif-row mar-t-20"}>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.address1")}</div>
            <div className="motif-body2-default-bold">{item.address1}</div>
          </div>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.address2")}</div>
            <div className="motif-body2-default-bold">{item.address2}</div>
          </div>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.city")}</div>
            <div className="motif-body2-default-bold">{item.city}</div>
          </div>
        </div>
        <div className={"motif-row mar-t-20"}>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.email")}</div>
            <div className="motif-body2-default-bold">{item.email}</div>
          </div>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.postalCode")}</div>
            <div className="motif-body2-default-bold">{item.postalCode}</div>
          </div>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.relationshipToCreditor")}</div>
            <div className="motif-body2-default-bold">
              {item.relationshipToCreditor}
            </div>
          </div>
        </div>
        <div className={"motif-row mar-t-20"}>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.provinceState")}</div>
            <div className="motif-body2-default-bold">{stateName}</div>
          </div>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.country")}</div>
            <div className="motif-body2-default-bold">{countryName}</div>
          </div>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.phone")}</div>
            <div className="motif-body2-default-bold">{item.phone}</div>
          </div>
        </div>
        <div className={"motif-row mar-t-20"}>
          <div className="motif-col-lg-4">
            <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.fax")}</div>
            <div className="motif-body2-default-bold">{item.fax}</div>
          </div>
        </div>
      </>
    );
  };

  const renderNewCreditorItems = () => {
    const data = claimSetupNewCreditorData;
    return (
      <div className="new-creditor-container">
        <div className={"motif-row mar-t-20"}>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.creditorType")}
            </div>
            <div className="motif-body2-default-bold">
              {data.isOrg ? "Organization" : "Individual"}
            </div>
          </div>
          <div className={`motif-col-md-2 ${data.isOrg ? "hide" : ""}`}>
            <div className="title">{t("pages.claims.createClaim.prefix")}</div>
            <div className="motif-body2-default-bold">{data.prefix || "-"}</div>
          </div>
          <div className={`motif-col-md-2 ${data.isOrg ? "hide" : ""}`}>
            <div className="title">
              {t("pages.claims.createClaim.firstName")}
            </div>
            <div className="motif-body2-default-bold">{data.firstName}</div>
          </div>
          <div className={`motif-col-md-2 ${data.isOrg ? "hide" : ""}`}>
            <div className="title">
              {t("pages.claims.createClaim.lastName")}
            </div>
            <div className="motif-body2-default-bold">{data.lastName}</div>
          </div>
          <div className={`motif-col-md-2 ${!data.isOrg ? "hide" : ""}`}>
            <div className="title">
              {t("pages.claims.createClaim.businessName")}
            </div>
            <div className="motif-body2-default-bold">{data.businessName}</div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.description")}
            </div>
            <div className="motif-body2-default-bold">{data.description}</div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.relationshipToDebtor")}
            </div>
            <div className="motif-body2-default-bold">
              {data.relationshipToDebtor}
            </div>
          </div>
        </div>
        <div>{t("pages.claims.createClaim.primaryContact")}</div>
        <div className={"motif-row mar-t-20"}>
          <div className="motif-col-md-2">
            <div className="title">{t("pages.claims.createClaim.prefix")}</div>
            <div className="motif-body2-default-bold">
              {data.contact.prefix || "-"}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.firstName")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.firstName}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.lastName")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.lastName}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.relationshipToCreditor")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.relationshipToCreditor
              }
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.address1")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.address1}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.address2")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.address2}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">{t("pages.claims.createClaim.city")}</div>
            <div className="motif-body2-default-bold">{data.contact.city}</div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.provinceState")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.provinceState}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">{t("pages.claims.createClaim.country")}</div>
            <div className="motif-body2-default-bold">
              {data.contact.country}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.postalCode")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.postalCode}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">{t("pages.claims.createClaim.phone")}</div>
            <div className="motif-body2-default-bold">{data.contact.phone}</div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">{t("pages.claims.createClaim.email")}</div>
            <div className="motif-body2-default-bold">{data.contact.email}</div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">{t("pages.claims.createClaim.fax")}</div>
            <div className="motif-body2-default-bold">{data.contact.fax}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderAssigneeItems = () => {
    if (!claimSetupAssigneeCompleteData?.assigneeItems) {
      return null;
    }
    const {
      creditorName,
      creditorReference,
      description,
      relationshipToDebtor,
    } = claimSetupAssigneeCompleteData?.assigneeItems;
    return (
      <div className={"motif-row mar-t-20"}>
        <div className="motif-col-lg-3">
          <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.assigneeName")}</div>
          <div className="motif-body2-default-bold">{creditorName || "-"}</div>
        </div>
        <div className="motif-col-lg-3">
          <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.referenceID")}</div>
          <div className="motif-body2-default-bold">
            {creditorReference || "-"}
          </div>
        </div>
        <div className="motif-col-lg-3">
          <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.description")}</div>
          <div className="motif-body2-default-bold">{description || "-"}</div>
        </div>
        <div className="motif-col-lg-3">
          <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.relationshipToDebtor")}</div>
          <div className="motif-body2-default-bold">
            {relationshipToDebtor}
          </div>
        </div>
      </div>
    );
  };

  const renderNewAssigneeItems = () => {
    const data = claimSetupNewAssigneeData;
    return (
      <div className="new-creditor-container">
        <div className={"motif-row mar-t-20"}>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.creditorType")}
            </div>
            <div className="motif-body2-default-bold">
              {data.isOrg ? "Organization" : "Individual"}
            </div>
          </div>
          <div className={`motif-col-md-2 ${data.isOrg ? "hide" : ""}`}>
            <div className="title">{t("pages.claims.createClaim.prefix")}</div>
            <div className="motif-body2-default-bold">{data.prefix || "-"}</div>
          </div>
          <div className={`motif-col-md-2 ${data.isOrg ? "hide" : ""}`}>
            <div className="title">
              {t("pages.claims.createClaim.firstName")}
            </div>
            <div className="motif-body2-default-bold">{data.firstName}</div>
          </div>
          <div className={`motif-col-md-2 ${data.isOrg ? "hide" : ""}`}>
            <div className="title">
              {t("pages.claims.createClaim.lastName")}
            </div>
            <div className="motif-body2-default-bold">{data.lastName}</div>
          </div>
          <div className={`motif-col-md-2 ${!data.isOrg ? "hide" : ""}`}>
            <div className="title">
              {t("pages.claims.createClaim.businessName")}
            </div>
            <div className="motif-body2-default-bold">{data.businessName}</div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.description")}
            </div>
            <div className="motif-body2-default-bold">{data.description}</div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.relationshipToDebtor")}
            </div>
            <div className="motif-body2-default-bold">
              {data.relationshipToDebtor}
            </div>
          </div>
        </div>
        <div>{t("pages.claims.createClaim.primaryContact")}</div>
        <div className={"motif-row mar-t-20"}>
          <div className="motif-col-md-2">
            <div className="title">{t("pages.claims.createClaim.prefix")}</div>
            <div className="motif-body2-default-bold">
              {data.contact.prefix || "-"}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.firstName")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.firstName}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.lastName")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.lastName}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.relationshipToCreditor")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.relationshipToCreditor}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.address1")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.address1}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.address2")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.address2}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">{t("pages.claims.createClaim.city")}</div>
            <div className="motif-body2-default-bold">{data.contact.city}</div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.provinceState")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.provinceState}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">{t("pages.claims.createClaim.country")}</div>
            <div className="motif-body2-default-bold">
              {data.contact.country}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">
              {t("pages.claims.createClaim.postalCode")}
            </div>
            <div className="motif-body2-default-bold">
              {data.contact.postalCode}
            </div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">{t("pages.claims.createClaim.phone")}</div>
            <div className="motif-body2-default-bold">{data.contact.phone}</div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">{t("pages.claims.createClaim.email")}</div>
            <div className="motif-body2-default-bold">{data.contact.email}</div>
          </div>
          <div className="motif-col-md-2">
            <div className="title">{t("pages.claims.createClaim.fax")}</div>
            <div className="motif-body2-default-bold">{data.contact.fax}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderClaimItems = () => {
    return claimSetupClaimItemsCompleteLabelDetails.map((item, index) => (
      <div key={index} className={"motif-row mar-t-20"}>
        <div className="motif-col-lg-3">
          <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.category")}</div>
          <div className="motif-body2-default-bold">{item.categoryName}</div>
        </div>
        <div className="motif-col-lg-2">
          <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.claimNature")}</div>
          <div className="motif-body2-default-bold">
            {Array.isArray(item.natureOfClaimItems)
              ? item.natureOfClaimItems.join(", ")
              : item.natureOfClaimItems}
          </div>
        </div>
        <div className="motif-col-lg-2">
          <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.claimAmount")}</div>
          <div className="motif-body2-default-bold">{item.claimAmount}</div>
        </div>
        <div className="motif-col-lg-2">
          <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.currency")}</div>
          <div className="motif-body2-default-bold">{item.currency}</div>
        </div>
        <div className="motif-col-lg-3">
          <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.assignee")}</div>
          <div className="motif-body2-default-bold">
            {item.assigneeData
              ? item.assigneeData
              : claimSetupAssigneeCompleteData?.assigneeItems?.creditorName ||
                "-"}
          </div>
        </div>
      </div>
    ));
  };

  const renderClaimAttributes = () => {
    return (
      <div className={"motif-row mar-t-20"}>
        {claimSetupAttributesData.map((attribute: any) => {
          return (
            <div className="motif-col-md-2">
              <div className="title">{attribute.name}</div>
              <div className="motif-body2-default-bold">
                <CustomAttributeValue
                  attributeType={attribute.value.attributeType}
                  value={attribute.value}
                  picklistDefinitions={attribute.picklistDefinitions}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderDocuments = () => {
    if (!claimDocumentData || claimDocumentData.length === 0) {
      return null;
    }
    return claimDocumentData.map((item, index) => {
      return (
        <div className="motif-col-lg-12 mar-t-20">
          <div className="title">{t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.documentSeries",{docNo:index + 1})}</div>
          <div className="motif-body2-default-bold">{item.displayName}</div>
        </div>
      );
    });
  };

  return (
    <>
   
      {notificationVisible && (
        <NotificationGrid
        show={true}
        message={"global.globalNotifications.createdSuccessfully"}
        type="success"
      />
      )}
      <PageSubTitle
        title={"pages.claims.claimCreate.reviewAndSubmitTab.title"}
        description={"pages.claims.claimCreate.reviewAndSubmitTab.description"}
      />

      <div>
        <MotifAccordion
          alignIconRight={true}
          useChevronIcon={true}
          className="accordion"
          onClose={() => {
            setIsDescriptionOpen(false);
          }}
          onOpen={() => {
            setIsDescriptionOpen(true);
          }}
          open={isDescriptionOpen}
        >
          <MotifAccordionTrigger onClick={() => handleEditButton(0)}>
            <EditIcon /> {t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.description")}
          </MotifAccordionTrigger>
          <MotifAccordionContent>
            {renderDescriptionItems()}
          </MotifAccordionContent>
        </MotifAccordion>
      </div>
      <div>
        <MotifAccordion
          alignIconRight={true}
          useChevronIcon={true}
          className="accordion"
          onClose={() => {
            setIsDebtorOpen(true);
          }}
          onOpen={() => {
            setIsDebtorOpen(true);
          }}
          open={isDebtorOpen}
        >
          <MotifAccordionTrigger onClick={() => handleEditButton(1)}>
            <EditIcon /> {t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.debtorConfirmation")}
          </MotifAccordionTrigger>
          <MotifAccordionContent>{renderDebtorItems()}</MotifAccordionContent>
        </MotifAccordion>
      </div>
      <div>
        <MotifAccordion
          alignIconRight={true}
          useChevronIcon={true}
          className="accordion"
          onClose={() => {
            setIsCreditorOpen(true);
          }}
          onOpen={() => {
            setIsCreditorOpen(true);
          }}
          open={isCreditorOpen}
        >
          <MotifAccordionTrigger onClick={() => handleEditButton(2)}>
            <EditIcon /> {t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.creditorConfirmation")}
          </MotifAccordionTrigger>
          <MotifAccordionContent>
            {claimSetupCreditorIsNewCreditorType
              ? renderNewCreditorItems()
              : renderCreditorItems()}
          </MotifAccordionContent>
        </MotifAccordion>
      </div>
      <div>
        <MotifAccordion
          alignIconRight={true}
          useChevronIcon={true}
          className="accordion"
          onClose={() => {
            setIsSelectAssigneeOpen(true);
          }}
          onOpen={() => {
            setIsSelectAssigneeOpen(true);
          }}
          open={isSelectAssigneeOpen}
        >
          <MotifAccordionTrigger onClick={() => handleEditButton(3)}>
            <EditIcon /> {t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.assignee")}
          </MotifAccordionTrigger>
          <MotifAccordionContent>
            {claimSetupAssigneeIsNewAssigneeType
              ? renderNewAssigneeItems()
              : renderAssigneeItems()}
          </MotifAccordionContent>
        </MotifAccordion>
      </div>
      <div>
        <MotifAccordion
          alignIconRight={true}
          useChevronIcon={true}
          className="accordion"
          onClose={() => {
            setIsClaimItemsOpen(true);
          }}
          onOpen={() => {
            setIsClaimItemsOpen(true);
          }}
          open={isClaimItemsOpen}
        >
          <MotifAccordionTrigger onClick={() => handleEditButton(4)}>
            <EditIcon /> {t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.claimItems")}
          </MotifAccordionTrigger>
          <MotifAccordionContent>{renderClaimItems()}</MotifAccordionContent>
        </MotifAccordion>
      </div>

      <div>
        <MotifAccordion
          alignIconRight={true}
          useChevronIcon={true}
          className="accordion"
          onClose={() => {
            setIsClaimAttributeOpen(false);
          }}
          onOpen={() => {
            setIsClaimAttributeOpen(true);
          }}
          open={isClaimAttributeOpen}
        >
          <MotifAccordionTrigger onClick={() => handleEditButton(5)}>
            <EditIcon /> {t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.claimAttributes")}
          </MotifAccordionTrigger>
          <MotifAccordionContent>
            {renderClaimAttributes()}
          </MotifAccordionContent>
        </MotifAccordion>
      </div>

      <div>
        <MotifAccordion
          alignIconRight={true}
          useChevronIcon={true}
          className="accordion"
          onClose={() => {
            setIsDocumentOpen(false);
          }}
          onOpen={() => {
            setIsDocumentOpen(true);
          }}
          open={isDocumentOpen}
        >
          <MotifAccordionTrigger onClick={() => handleEditButton(6)}>
            <EditIcon /> {t("pages.claims.claimCreate.reviewAndSubmitTab.formLabelsDetails.documents")}
          </MotifAccordionTrigger>
          <MotifAccordionContent>
            <div className="motif-row">{renderDocuments()}</div>
          </MotifAccordionContent>
        </MotifAccordion>
      </div>

      <div className="display-flex justify-content-end mar-t-70">
        <div className="pad-r-10">
          <Button
            type="button"
            label={t("global.globalActions.cancel")}
            variant={SECONDARY}
            className="cancelBtn"
            onClickHandler={() =>{setIsVisible(true);      dispatch(setDiscardEditMode({ editMode: false }));
          }}
          />
        </div>
        <div className="">
          <Button
            label={t("global.globalActions.save")}
            variant="primary"
            onClickHandler={() => saveClaimCreation()}
            type={isLoading ? "loader" : "submit"}
            disabled={isLoading}
          />
        </div>
      </div>
      <ConfirmationModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        cancelBtnApproveHandler={handleReviewDiscardAllData}
        cancelBtnRejectHandler={handleReviewCancelData}
      />
    </>
  );
}