import { useEffect, useState } from "react";
import { useParams,Link } from "react-router-dom";
import Table from "@/components/Table/table";
import {
    MotifButton,
    MotifIcon,
    MotifProgressLoader,
} from "@ey-xd/motif-react";
import {
    IconoirCloudDownload,
    IconoirPlus,
    IconoirEditPencil,
} from "@ey-xd/motif-icon";
import { useGetColumnDefsManualDataSource } from "./manualDataSourceConst";
import ManualDataSourceUploader from "./ManualDataSourceUploader";
import { useLazyDownloadManualSourceDocumentQuery, useListManualDataSourceQuery } from "@/services/manualDataSource/ManualDataSourceReducer";
import { useCase } from "@/features/case/use-case";
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { useTranslation } from "react-i18next";

export const ManualDataSource = () => {
    const parameter = useParams();
    const { t } = useTranslation();
    let tableLoadingData=customTableLoadingData
    const paginationPageSize: number = customPaginationPageSize;
    const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
    const [paginationObj, setPaginationObj] = useState({
        CaseId: parameter?.caseId ?? "",
        PageNumber: 1,
        PageSize: paginationPageSize,
        SearchText: "",
        SortOrder: "desc",
        SortColumn: "UpdatedOn",
      });
    const { data,isFetching,isError, isLoading, refetch } = useListManualDataSourceQuery(paginationObj);
    let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({
        updatedOnType:{sort:"desc"}
      })
      function handlePaginationData(data:any,columnTypesData:any){
        setPaginationObj({...data})
        setSortedColumnTypes({...columnTypesData})
      }
    const caseDara = useCase();
    const getColumnDefsManualDataSource=useGetColumnDefsManualDataSource(caseDara?.abbreviation)

    const [showUploadNew, setShowUploadNew] = useState(false);
    //const [selectedDocument, setSelectedDocuement] = useState<any>({})
    const [downloadManualSourceDocument] = useLazyDownloadManualSourceDocumentQuery();

    useEffect(() => {
        if (showUploadNew === false) {
            refetch();
        }
        // eslint-disable-next-line
    }, [showUploadNew]);

    function TitleComplement() {
        return (
            <MotifButton
                onClick={handleUploadNew}
                size="small"
                type="submit"
                variant="secondary"
            >
                <MotifIcon iconFunction={IconoirPlus} />
            </MotifButton>
        );
    }

    const customActionHeader = {
        editCellRenderer: function (params: any) {
            return (
              <>
                {
                   <Link
                   to={`./manual-data-source-edit/${params.data.id}`}
                >
                    {params.data.manualDataSourceName}
                  </Link>
                }
              </>
            );
          },
        actionRenderer: function (props: any) {
            return (
                <>
                    <div className="ccwp_document_customheader_wrapper">
                        <MotifButton
                            onClick={() => handleDownload(props.data)}
                            size="small"
                            type="submit"
                            variant="primary"
                        >
                            <MotifIcon fill="none" iconFunction={IconoirCloudDownload} />
                        </MotifButton>
                        <Link
                            to={`./manual-data-source-edit/${props.data.id}`}
                        >
                            <MotifButton
                                size="small"
                                type="submit"
                                variant="primary"
                            >
                                <MotifIcon fill="none" iconFunction={IconoirEditPencil} />
                            </MotifButton>
                        </Link>
                    </div>
                </>
            );
        },
    };

    const handleUploadNew = () => {
        //setSelectedDocuement({})
        setShowUploadNew(true);
    };

    const handleDownload = (documentData: any) => {
        downloadManualSourceDocument({
            manualDataSourceRecordId: documentData.id,
        });
    };

    return (
        <>
            <div className="mar-b-20">
                {isLoading ? (
                    <MotifProgressLoader show variant="default" className="mar-b-20" />
                ) : (
                  <>
                    <Table
                      columnTypes={{...sortedColumnTypes}}
                      handlePaginationData={handlePaginationData}
                          paginationObj={paginationObj}
                        columnDefs={getColumnDefsManualDataSource}
                        cellRenderers={data?.results && !isFetching?data.results:[]}
                        TitleComplement={TitleComplement}
                        title={"pages.distributions.manualDataSource.tableTitle"}
                        description={
                            "pages.distributions.manualDataSource.tableDescription"}
                        caseRenderers={customActionHeader}
                        pagination={false}
                        overlayNoRowsTemplate={isFetching?t(tableLoadingData):isError? t("global.globalNotifications.wentWrong")
                             : t("global.globalNotifications.noRecords")}

                    />
                    <CustomPagination
          currentpage={paginationObj.PageNumber}
          pageSize={paginationObj.PageSize}
          disableNext={!data?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
        </>
                )}
            </div>

            {showUploadNew && (
                <ManualDataSourceUploader
                    show={showUploadNew}
                    setShow={setShowUploadNew}
                />
            )}
        </>
    );
};
