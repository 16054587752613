import React from "react";
import { MotifFormField, MotifLabel, MotifTimeInput } from "@ey-xd/motif-react";

type TimeInputFieldType = {
  id?: string;
  className?: string;
  value: Date ; // The value should be a Date object with the desired hours and minutes set
  disabled?: boolean;
  required?: boolean;
  onChange: (date: Date) => void; // The onChange handler will receive a Date object with updated hours and minutes
};

const TimeInputField: React.FC<TimeInputFieldType> = ({
  id,
  className,
  value,
  disabled,
  required,
  onChange,
}) => {
  const handleChange = (date: Date) => {
    const updatedDate = new Date(value);
    updatedDate.setHours(date.getHours(), date.getMinutes());
    onChange(updatedDate);
  };

  return (
    <MotifFormField aria-labelledby={id ? `${id}-label` : undefined}>
      <MotifLabel id={id ? `${id}-label` : undefined}>Time</MotifLabel>
      <MotifTimeInput
        id={id}
        className={className}
        value={value}
        required={required}
        disabled={disabled}
        onChange={handleChange}
      />
    </MotifFormField>
  );
};

export default TimeInputField;
