// @ts-nocheck
// TODO: fix ts errors
import {
  MotifCheckbox,
  MotifErrorMessage,
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifButton,
  MotifIcon,
} from "@ey-xd/motif-react";
import "./CreditorSummary.scss";
import { useGetCreditorSummaryDataQuery } from "./CreditorSummaryReducer";
import { useParams } from "react-router-dom";
import {
  SECONDARY,
} from "@/Constant";

import { FieldArrayWithId, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlInputBox from "../CreditorSetup/Form/ControlInputBox";
import ControlSelect from "@/pages/CaseDetail/Claim/ClaimDetails/ControlSelect";
import { useEffect, useState, useMemo } from "react";
import { useGetCountryStateListDataQuery } from "@/services/countryState/countryStateReducer";
import {
  ContactDetailsRowType,
  ContactDetailsType,
  countryRecordType,
} from "../CreditorSetup/type";
import { postCreditorUpdateData } from "../CreditorSetup/CreditorSetupHelper";
import {
  ADDRESS1,
  ADDRESS2,
  BUSINESS_NAME,
  CITY,
  COUNTRY,

  EMAIL,
  FAX,
  FIRST_NAME,
 
  LAST_NAME,

  PHONE,

  POSTAL_CODE,
  PREFIX,
  RELATIONSHIP_TO_CREDITOR,

  SET_AS_PRIMARY_CONTACT,
 
  STATE,
  INSTITUTION_NUMBER,

  ROUTING_CODE,
  SWIFT_CODE,
  TRANSIT_NUMBER,
  ACCOUNT_NUMBER,
  
  IBAN_NUMBER,

  ACH_CODE,

  USE_AS_ADDITIONAL_PAYEE,
  ADDITIONAL_PAYEE_TYPE,

} from "../CreditorSetup/Constants";
import ControlSelectCountry from "../CreditorSetup/Form/SelectBox/ControlSelectCountry";
import ControlSelectState from "../CreditorSetup/Form/SelectBox/ControlSelectState";
import Button from "@/components/Form/Button/Button";
import { CreditorFormType } from "./types";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";

import NotificationGrid from "@/components/Notification/NotificationGrid";
import { useNavigate } from "react-router-dom";
import CustomAttribute from "@/components/CustomAttribute/CustomAtrribute";
import { AttributeType } from "../../Configuration/Attributes/type";
import { useCase } from "@/features/case/use-case";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import {
  useLazyGetProvincesQuery,
  useUpdateCreditorMutation,
} from "@/services/creditors/creditorsReducers";
import { useGetPayeeTypeApiDataQuery } from "@/features/distributions/steps/calculationStepReducer";
import { IconoirPlus } from "@ey-xd/motif-icon";
import ControlSelectPayee from "../../Claim/ClaimDetails/ControlSelectPayee";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
import { useDispatch } from "react-redux";
import PaymentInputField from "../CreditorSetup/PaymentInputField";

function CreditiorUpdate() {
  const { caseId, creditorId } = useParams();
  const navigate = useNavigate();
  const caseData = useCase(caseId);
  const propData = {
    caseId: caseData.caseId,
    creditorId: creditorId || "",
  };
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "success",
  });

  const [notificationVisible, setNotificationVisible] = useState(false);

  const { t } = useTranslation();
  const PREFIX_OPTIONS_LIST = [
    { 
        label: t("global.globalActions.optionDefault"), 
        value: "" },
    {
        label:t("global.globalActions.prefixOptionsListLabels.mr"),
        value: "Mr.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.ms"),
        value: "Ms.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.mrs"),
        value: "Mrs.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.miss"),
        value: "Miss.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.other"),
        value: "other",
    }
]
  const [isVisible, setIsVisible] = useState(false);
  const [updateCreditor, { isLoading: updateIsLoading }] =
    useUpdateCreditorMutation();
  const { data, error, isLoading } = useGetCreditorSummaryDataQuery(propData, {
    refetchOnMountOrArgChange: true,
  });
  const { data: payeeTypeData, isLoading: isPayeeTypeDataLoading } =
    useGetPayeeTypeApiDataQuery({
      CaseId: caseData.caseId,
    });
  const [isOrg, setIsOrg] = useState(false);
  const [isPrimary, setIsPrimary] = useState(true);
  const [countryLoaded, setCountryLoaded] = useState(false);
  const [attributesValue, setAttributesValue] = useState([]);
  const [intialAttributesValue, setIntialAttributesValue] = useState([]);
  const [isAttributesError, setIsAttributesError] = useState(false);
  const [PaymentDetailsOpen, setPaymentDetailsOpen] = useState(false);
  const [initialPayeeList, setInitialPayeeList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      // setIsOrg(data.creditorIdentifier.isOrganization)
      setAttributesValue(data.customAttributes);
      setIntialAttributesValue([...data.customAttributes]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const defaultPrimaryContactIndex =
    data &&
    data?.creditorContactList.findIndex((contact: ContactDetailsType) => {
      //@ts-ignore
      return contact.contactId === data.creditorIdentifier.primaryContactId;
    });

  const [countryArray, setCountryArry] = useState([
    [
      {
        label: t("global.globalActions.optionDefault"),
        value: "",
      },
    ],
  ]);

  const [isChecked, setIsChecked] = useState({
    index: defaultPrimaryContactIndex || 0,
    value: true,
  });
  const [showField, setShowField] = useState<any>({});
  let defaultOption = {
    label: t("global.globalActions.optionDefault"),
    value: "",
  };
  const { data: countryList, isLoading: isLoadingCountryList } =
    useGetCountryStateListDataQuery("");
  const [getProvinces] = useLazyGetProvincesQuery();

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<CreditorFormType>({
    defaultValues: {
      creditorId: "",
      businessName: "",
      firstName: "",
      lastName: "",
      relationShipToDebtor: "",
      creditorType: "",
      displayName: "",
      description: "",
      prefix: "",
      fieldArray: [],
    },
  });
  useEffect(() => {
    dispatch(
      setDiscardEditMode({
        editMode:
          isDirty ||
          JSON.stringify(intialAttributesValue) !==
            JSON.stringify(attributesValue),
      })
    );
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch, isDirty, attributesValue, intialAttributesValue]);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "fieldArray",
  });

  const watchFieldArray = watch("fieldArray");

  const controlledFields = fields?.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const creditorType = watch("creditorType");

  useEffect(() => {
    if (creditorType === "individual") {
      setValue("businessName", "");
    } else if (creditorType === "organization") {
      setValue("prefix", "");
      setValue("firstName", "");
      setValue("lastName", "");
    }
  }, [creditorType, setValue]);

  useEffect(() => {
    setIsOrg(creditorType === "organization");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditorType]);
  const onChangeHandlerCountryIntial = async (
    index: number,
    countryId: string
  ) => {
    if (!countryId) {
      return;
    }

    const responseApi = await getProvinces(countryId).unwrap();
    if (responseApi.length > 0) {
      let stateListBycounrty: { label: string; value: string }[] = [];
      responseApi.forEach(
        (stateObj: { id: string; name: string; code: string }) => {
          stateListBycounrty.push({
            label: stateObj.name,
            value: stateObj.id,
          });
        }
      );
      return stateListBycounrty;
    } else {
      return [defaultOption];
    }
  };
  useEffect(() => {
    if (data) {
      const creditorIdentifier = data.creditorIdentifier;
      const creditorContactList = data.creditorContactList;
      const getWithPromiseAll = async () => {
        let contactListData: any[] = [];
        if (creditorContactList) {
          contactListData = await Promise.all(
            creditorContactList.map(
              async (contact: ContactDetailsType, index: number) => {
                let jsonh = {
                  //@ts-ignore
                  firstName: contact.firstName,
                  //@ts-ignore
                  lastName: contact.lastName,
                  //@ts-ignore
                  prefix: contact.prefix,
                  //@ts-ignore
                  address1: contact.address1,
                  //@ts-ignore
                  address2: contact.address2,
                  //@ts-ignore
                  city: contact.city,
                  //@ts-ignore
                  state: contact.provinceStateId,
                  //@ts-ignore
                  country: contact.countryId,
                  //@ts-ignore
                  email: contact.email,
                  //@ts-ignore
                  phone: contact.phone,
                  //@ts-ignore
                  fax: contact.fax,
                  //@ts-ignore
                  postalCode: contact.postalCode,
                  //@ts-ignore
                  setAsPrimaryContact: contact.setAsPrimaryContact,
                  relationShipToCreditor: contact.relationshipToCreditor || "",
                  //@ts-ignore
                  creditorContactId: contact.contactId,
                  //@ts-ignore
                  stateoptions: [defaultOption],
                  isPaymentDetails: contact.addPaymentDetails,
                  swiftCode: contact.swiftCode,
                  routingCode: contact.routingCode,
                  institutionNumber: contact.institutionNumber,
                  transitNumber: contact.transitNumber,
                  accountNumber: contact.accountNumber,
                  ibanNumber: contact.ibanNumber,
                  achCode: contact.achCode,
                  usedAsAdditionalPayeeType: contact.usedAsAdditionalPayeeType,
                  additionalPayeeTypeId: contact.additionalPayeeTypeId,
                  payeeOptions: [defaultOption, ...initialPayeeList],
                };

                let stateOptions = await onChangeHandlerCountryIntial(
                  index,
                  contact.countryId
                );
                jsonh.stateoptions = stateOptions
                  ? stateOptions
                  : [defaultOption];
                //@ts-ignore
                return jsonh;
              }
            )
          );
        }

        reset({
          creditorId: creditorIdentifier.creditorReference,
          businessName: creditorIdentifier.businessName,
          firstName: creditorIdentifier.firstName,
          lastName: creditorIdentifier.lastName,
          relationShipToDebtor: creditorIdentifier.relationshipToDebtor || "",
          creditorType: creditorIdentifier.isOrganization
            ? "organization"
            : "individual",
          displayName: creditorIdentifier.displayName,
          description: creditorIdentifier.description,
          prefix: creditorIdentifier.prefix,
          fieldArray: [...contactListData],
        });
      };
      getWithPromiseAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, initialPayeeList]);

  useEffect(() => {
    if (countryList !== undefined) {
      // eslint-disable-next-line array-callback-return
      const countryOptions = countryList.map((record: countryRecordType) => {
        return {
          label: record.countryName,
          value: record.countryId,
        };
      });
      setCountryArry(countryOptions);
      setCountryLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, countryList]);

  useEffect(() => {
    const payeeListCreditor = payeeTypeData?.results?.filter(
      (payee) => payee.payeeLevel === 0
    );

    let payeeListInitial: { label: string; value: string }[] = [];

    payeeListCreditor?.forEach((stateObj: { id: string; name: string }) => {
      payeeListInitial.push({
        label: stateObj.name,
        value: stateObj.id,
      });
    });

    setInitialPayeeList(payeeListInitial);
  }, [payeeTypeData]);

  const onChangeHandlerPayee = () => {};

  const navigateToPayeeCreate = () => {
    navigate(`/case/${caseId}/configuration/payee/create?payeeLevel=0`);
  };

  const selectedPayeesList =
    Array.isArray(watchFieldArray) &&
    watchFieldArray?.map((i) => i.additionalPayeeTypeId);
  const addMoreRow = () => {
    append({
      firstName: "",
      lastName: "",
      prefix: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      email: "",
      phone: "",
      fax: "",
      postalCode: "",
      setAsPrimaryContact: false,
      relationShipToCreditor: "",
      creditorContactId: "00000000-0000-0000-0000-000000000000",
      stateoptions: [defaultOption],
      swiftCode: "",
      routingCode: "",
      institutionNumber: "",
      transitNumber: "",
      accountNumber: "",
      ibanNumber: "",
      achCode: "",
      addPaymentDetails: false,
      usedAsAdditionalPayeeType: false,
      additionalPayeeTypeId: null,
      payeeOptions: [defaultOption, ...initialPayeeList],
    });
  };
  const removeRow = (index: number) => {
    remove(index);
  };

  const cancelBtnApproveHandler = (event: any) => {
    event.preventDefault();
    controlledFields.map((field, index) => {
      field && remove(index);
      return true;
    });
    navigate(`/case/${caseId}/creditor/${creditorId}`);
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));
  };

  const attributesError = () => {
    var isError = false;
    const required = attributesValue.filter((x: any) => {
      return x.isRequired;
    });

    required.forEach((x: any) => {
      switch (x.value.attributeType) {
        case AttributeType.FreeText:
          isError = !x.value.textValue;
          break;
        case AttributeType.Number:
          isError = !x.value.numberValue;
          break;
        case AttributeType.Boolean:
          isError = !x.value.booleanValue;
          break;
        case AttributeType.Date:
          isError = !x.value.dateValue;
          break;
        case AttributeType.SingleSelect:
          isError = !x.value.selectedValue;
          break;
        case AttributeType.MutliSelect:
          isError =
            !x.value.selectedValues || x.value.selectedValues.length === 0;
          break;
        case AttributeType.Currency:
          isError = !x.value.amount;
          break;
        case AttributeType.Document:
          isError = !x.value.displayName;
          break;
        default:
          isError = false;
      }
    });

    return isError;
  };

  const errorList = Object.keys(errors);


  //@ts-ignore
  const onSubmit = async (data) => {
    if (attributesError()) {
      setIsAttributesError(true);
    } else {
      const attributes = attributesValue.map((value: any) => value.value);
      if (data.fieldArray) {
        const fieldArrayLength = data.fieldArray.length;
        // eslint-disable-next-line array-callback-return
        data.fieldArray.forEach(
          (
            field: FieldArrayWithId<ContactDetailsType, "fieldArray", "id">,
            index: number
          ) => {
            if (index === isChecked.index || fieldArrayLength === 1) {
              field.setAsPrimaryContact = true;
            }
          }
        );
        data = {
          ...data,
          isOrganization: isOrg,
          creditorId: creditorId || "",
          customAttributes: attributes,
        };
        dispatch(setDiscardEditMode({ editMode: false }));

        try {
          // const responseApi = await postCreditorUpdateApi(data);
          const creditorData = postCreditorUpdateData(data);
          const responseApi = await updateCreditor(creditorData).unwrap();
          if (responseApi?.creditorId) {
            navigate(`/case/${caseId}/creditor/${creditorId}`, {
              state: { showMessage: true, update: true },
            });
          } else {
            const error_message = "global.globalNotifications.unExpectedError";
            setNotificationVisible(true);
            setNotification({
              ...notification,
              ...notification,
              show: true,
              message: error_message,
              type: "error",
            });
            dispatch(setDiscardEditMode({ editMode: true }));
          }
        } catch (error) {
          const error_message = "global.globalNotifications.unExpectedError";
          setNotificationVisible(true);
          setNotification({
            ...notification,
            ...notification,
            show: true,
            message: error_message,
            type: "error",
          });
          dispatch(setDiscardEditMode({ editMode: true }));
        }
      } else {
        setIsPrimary(false);
      }
    }
  };
  const checkBoxHandler = (e?: React.ChangeEvent<HTMLInputElement>) => {
    const index = e?.target.name.split("-")[1];
    if (index === undefined) {
      return false;
    }
    e?.target.checked
      ? setIsChecked({ index: parseInt(index), value: true })
      : setIsChecked({ index: 0, value: true });
  };

  const checkBoxIsChecked = (index: number) => {
    return isChecked.index === index && isChecked.value;
  };
  const onChangeHandlerCountry = async (index: number, countryId: string) => {
    if (!countryId) {
      return;
    }
    const watchFieldArray = watch("fieldArray");
    const controlObj = watchFieldArray[index];
    const responseApi = await getProvinces(countryId).unwrap();
    let stateListBycounrty: { label: string; value: string }[] = [];
    if (responseApi.length > 0) {
      // eslint-disable-next-line array-callback-return

      responseApi.forEach(
        (stateObj: { id: string; name: string; code: string }) => {
          stateListBycounrty.push({
            label: stateObj.name,
            value: stateObj.id,
          });
        }
      );

      update(index, {
        ...controlObj,
        state: stateListBycounrty.length === 0 ? "" : controlObj.state,
        stateoptions: stateListBycounrty,
      });
    } else {
      update(index, {
        ...controlObj,
        state: "",
        stateoptions: [defaultOption],
      });
    }
  };

  const getAvailablePayeeOptions = useMemo(
    () =>
      (index: number): { label: string; value: string }[] => {
        return controlledFields[index]?.payeeOptions.filter(
          (payee) =>
            !selectedPayeesList.includes(payee.value) ||
            payee.value === controlledFields[index]?.additionalPayeeTypeId
        );
      },
    [controlledFields, selectedPayeesList]
  );

  const handleUseAsAdditionalPayeeType = (
    e?: React.ChangeEvent<HTMLInputElement>
  ) => {
    const index = e?.target.name.split("-")[1];

    const watchFieldArray = watch("fieldArray");
    if (index !== undefined) {
      const controlObj = watchFieldArray[Number(index)];
      //@ts-ignore
      update(Number(index), {
        ...controlObj,
        usedAsAdditionalPayeeType: e?.target.checked,
      });

      if (!e?.target.checked) {
        //@ts-ignore
        update(Number(index), {
          ...controlObj,
          usedAsAdditionalPayeeType: e?.target.checked,
        });
      }
    }
  };

  const additionalPayeeTypeChecked = (index: number) => {
    const watchFieldArray = watch("fieldArray");

    const controlObj = watchFieldArray[Number(index)];

    return controlObj?.usedAsAdditionalPayeeType;
  };

  const handleViewIcon = (name: string) => {
    setShowField((prev: any) => ({ ...prev, [name]: !prev[name] }));
  };

  const renderContactRow = (field: ContactDetailsRowType, index: number) => {
    return (
      <>
        <div className="motif-row pad-t-20">
          <div className="motif-col-xl-4" style={{ display: "flex" }}>
            <MotifCheckbox
              id={`${SET_AS_PRIMARY_CONTACT}-${index}`}
              name={`${SET_AS_PRIMARY_CONTACT}-${index}`}
              //@ts-ignore
              onChange={checkBoxHandler}
              value={"setAsPrimaryContact"}
              checked={checkBoxIsChecked(index)}
            >
              {t("pages.creditors.creditorForm.formLabelDetails.checkBoxPrimaryContact")}
            </MotifCheckbox>
          </div>

          <div className="motif-col-xl-4" style={{ display: "flex" }}>
            <MotifCheckbox
              id={`${USE_AS_ADDITIONAL_PAYEE}-${index}`}
              name={`${USE_AS_ADDITIONAL_PAYEE}-${index}`}
              //@ts-ignore
              onChange={handleUseAsAdditionalPayeeType}
              value={"usedAsAdditionalPayeeType"}
              checked={additionalPayeeTypeChecked(index)}
            >
              {t("pages.creditors.creditorForm.formLabelDetails.checkBoxAdditionalPayee")}
            </MotifCheckbox>
          </div>
        </div>
        <div className="motif-row pad-t-20">
          {additionalPayeeTypeChecked(index) && (
            <div
              className="motif-col-lg-4"
              style={{ display: "flex", paddingLeft: "0px" }}
            >
              <div className="motif-col-lg-10">
                <div className="form-field">
                  <ControlSelectPayee
                    name={`fieldArray.${index}.${ADDITIONAL_PAYEE_TYPE}`}
                    control={control}
                    required={additionalPayeeTypeChecked(index)}
                    showLabel={true}
                    label={"pages.creditors.creditorForm.formLabelDetails.additionalPayeeType"}
                    options={getAvailablePayeeOptions(index)}
                    setValue={setValue}
                    getValues={getValues}
                    onChangeHandlerPayee={onChangeHandlerPayee}
                    index={index}
                  />
                  {errors &&
                    errors?.fieldArray?.[index]?.[ADDITIONAL_PAYEE_TYPE] &&
                    errors?.fieldArray?.[index]?.[ADDITIONAL_PAYEE_TYPE]
                      ?.type === "required" && (
                      <MotifErrorMessage>
                        {" "}
                        {t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.additionalPayeeTypeMessage")}
                      </MotifErrorMessage>
                    )}
                </div>
              </div>
              <div className="motif-col-lg-2 payeeAddBtn">
                <MotifButton
                  aria-label="dropdown button"
                  id="dropdown-trigger-1"
                  type="button"
                  variant="text"
                  onClick={navigateToPayeeCreate}
                >
                  <MotifIcon iconFunction={IconoirPlus} />
                </MotifButton>
              </div>
            </div>
          )}
          <div className="motif-col-lg-4">
            <div className="form-field">
              <ControlInputBox
                name={`fieldArray.${index}.${RELATIONSHIP_TO_CREDITOR}`}
                //@ts-ignore
                control={control}
                required={false}
                isTextArea={false}
                label={"pages.creditors.creditorForm.formLabelDetails.relationshipToCreditor"}
                showLabel={true}
              />
            </div>
          </div>
        </div>
        <div className="motif-row pad-t-20">
          <div className="motif-col-lg-4">
            <div className="form-field">
              {/**@ts-ignore */}
              <ControlSelect
                name={`fieldArray.${index}.${PREFIX}`}
                control={control}
                required={false}
                showLabel={true}
                label={"pages.creditors.creditorForm.formLabelDetails.prefix"}
                options={PREFIX_OPTIONS_LIST}
                setValue={setValue}
                getValues={getValues}
                value={field.prefix}
              />
            </div>
          </div>
          <div className="motif-col-lg-4">
            <div className="form-field">
              {/**@ts-ignore */}
              <ControlInputBox
                name={`fieldArray.${index}.${FIRST_NAME}`}
                control={control}
                required={true}
                isTextArea={false}
                label={"pages.creditors.creditorForm.formLabelDetails.firstName"}
                showLabel={true}
              />
              {errors &&
                errors?.fieldArray?.[index]?.[FIRST_NAME] &&
                errors?.fieldArray?.[index]?.[FIRST_NAME]?.type ===
                  "required" && (
                  <MotifErrorMessage>
                    {" "}
                    {t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.firstNameMessage")}
                    </MotifErrorMessage>
                )}
            </div>
          </div>
          <div className="motif-col-lg-4">
            <div className="form-field">
              {/**@ts-ignore */}
              <ControlInputBox
                name={`fieldArray.${index}.${LAST_NAME}`}
                control={control}
                required={true}
                isTextArea={false}
                label={"pages.creditors.creditorForm.formLabelDetails.lastName"}
                showLabel={true}
              />
              {errors &&
                errors?.fieldArray?.[index]?.[LAST_NAME] &&
                errors?.fieldArray?.[index]?.[LAST_NAME]?.type ===
                  "required" && (
                  <MotifErrorMessage>
                    {" "}
                    {t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.lastNameMessage")}
                    </MotifErrorMessage>
                )}
            </div>
          </div>
        </div>
        <div className="motif-row pad-t-20">
          <div className="motif-col-lg-4">
            <div className="form-field">
              {/**@ts-ignore */}
              <ControlInputBox
                name={`fieldArray.${index}.${EMAIL}`}
                control={control}
                required={false}
                isTextArea={false}
                label={"pages.creditors.creditorForm.formLabelDetails.email"}
                showLabel={true}
              />
            </div>
          </div>
          <div className="motif-col-lg-4">
            <div className="form-field">
              <ControlInputBox
                name={`fieldArray.${index}.${FAX}`}
                control={control}
                required={false}
                isTextArea={false}
                label={"pages.creditors.creditorForm.formLabelDetails.fax"}
                showLabel={true}
              />
            </div>
          </div>
          <div className="motif-col-lg-4">
            <div className="form-field">
              {/**@ts-ignore */}
              <ControlInputBox
                name={`fieldArray.${index}.${PHONE}`}
                control={control}
                required={false}
                isTextArea={false}
                label={"pages.creditors.creditorForm.formLabelDetails.phoneNumber"}
                showLabel={true}
              />
            </div>
          </div>
        </div>

        <div className="motif-container ccwp_claim_summary_white_accordian mar-t-20 pad-0">
          <MotifAccordion
            onClose={function Ua() {}}
            onOpen={function Ua() {}}
            style={{
              flex: "1",
            }}
            alignIconRight={true}
            useChevronIcon={true}
            className="mar-b-20"
            open={true}
          >
            <MotifAccordionTrigger>
              <div className="ccwp_claim_summary_white">
                <label>{t("pages.creditors.creditorForm.formLabelDetails.address")}</label>
              </div>
            </MotifAccordionTrigger>
            <MotifAccordionContent className="sub-accordian">
              {/* adress fields start */}
              <div className="motif-row pad-t-20">
                <div className="motif-col-lg-8">
                  <div className="form-field">
                    {/**@ts-ignore */}
                    <ControlInputBox
                      name={`fieldArray.${index}.${ADDRESS1}`}
                      control={control}
                      required={false}
                      isTextArea={false}
                      label={"pages.creditors.creditorForm.formLabelDetails.address1"}
                      showLabel={true}
                    />
                  </div>
                </div>
              </div>

              <div className="motif-row pad-t-20">
                <div className="motif-col-lg-8">
                  <div className="form-field">
                    {/**@ts-ignore */}
                    <ControlInputBox
                      name={`fieldArray.${index}.${ADDRESS2}`}
                      control={control}
                      required={false}
                      isTextArea={false}
                      label={"pages.creditors.creditorForm.formLabelDetails.address2"}
                      showLabel={true}
                    />
                  </div>
                </div>
              </div>

              <div className="motif-row pad-t-20">
                <div className="motif-col-lg-4">
                  <div className="form-field">
                    {/**@ts-ignore */}
                    <ControlInputBox
                      name={`fieldArray.${index}.${CITY}`}
                      control={control}
                      required={false}
                      isTextArea={false}
                      label={"pages.creditors.creditorForm.formLabelDetails.city"}
                      showLabel={true}
                    />
                  </div>
                </div>
                <div className="motif-col-lg-4">
                  <div className="form-field">
                    {/**@ts-ignore */}
                    <ControlSelectState
                      name={`fieldArray.${index}.${STATE}`}
                      control={control}
                      required={false}
                      showLabel={true}
                      label={"pages.creditors.creditorForm.formLabelDetails.provinceState"}
                      options={
                        controlledFields[index]?.stateoptions || [defaultOption]
                      }
                      filter={true}
                    />
                    {/**@ts-ignore */}
                    <div className="hide">
                      <ControlInputBox
                        name={`fieldArray.${index}.${"stateoptions"}`}
                        control={control}
                        required={false}
                        isTextArea={false}
                        label={""}
                        showLabel={false}
                      />
                    </div>
                  </div>
                  <div className="hide">
                    {/**@ts-ignore */}
                    <ControlInputBox
                      name={`fieldArray.${index}.${"creditorContactId"}`}
                      control={control}
                      required={false}
                      isTextArea={false}
                      label={""}
                      showLabel={false}
                    />
                  </div>
                </div>
                <div className="motif-col-lg-4">
                  <div className="form-field">
                    {/**@ts-ignore */}
                    <ControlInputBox
                      name={`fieldArray.${index}.${POSTAL_CODE}`}
                      control={control}
                      required={false}
                      isTextArea={false}
                      label={"pages.creditors.creditorForm.formLabelDetails.postalCode"}
                      showLabel={true}
                    />
                  </div>
                </div>
              </div>
              <div className="motif-row pad-t-20">
                <div className="motif-col-lg-4">
                  <div className="form-field">
                    {/**@ts-ignore */}
                    <ControlSelectCountry
                      name={`fieldArray.${index}.${COUNTRY}`}
                      control={control}
                      required={false}
                      showLabel={true}
                      label={"pages.creditors.creditorForm.formLabelDetails.country"}
                      options={countryArray}
                      setValue={setValue}
                      getValues={getValues}
                      onChangeHandlerCountry={onChangeHandlerCountry}
                      index={index}
                      filter={true}
                    />
                  </div>
                </div>
              </div>
            </MotifAccordionContent>
          </MotifAccordion>
        </div>
        <div className="motif-container ccwp_claim_summary_white_accordian mar-t-20 pad-0">
          <MotifAccordion
            onClose={() => setPaymentDetailsOpen(false)}
            onOpen={() => setPaymentDetailsOpen(true)}
            style={{
              flex: "1",
            }}
            alignIconRight={true}
            useChevronIcon={true}
            className="mar-b-20"
            open={PaymentDetailsOpen}
          >
            {/*  Payment details start */}

            <MotifAccordionTrigger>
              <div className="ccwp_claim_summary_white">
                <label>{t("pages.creditors.creditorForm.formLabelDetails.payementDetails")}</label>
              </div>
            </MotifAccordionTrigger>
            <MotifAccordionContent className="sub-accordian payment-details-wrapper">
              <div className="motif-row">
                <div className="motif-col-lg-3">
                  <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.institutionNumber")}</div>
                  <PaymentInputField
                    control={control}
                    showField={showField}
                    handleViewIcon={handleViewIcon}
                    name={`fieldArray.${index}.${INSTITUTION_NUMBER}`}
                  />
                </div>
                <div className="motif-col-lg-3">
                  <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.transitNumber")}</div>
                  <PaymentInputField
                    control={control}
                    showField={showField}
                    handleViewIcon={handleViewIcon}
                    name={`fieldArray.${index}.${TRANSIT_NUMBER}`}
                  />
                </div>
                <div className="motif-col-lg-3">
                  <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.accountNumber")}</div>
                  <PaymentInputField
                    control={control}
                    showField={showField}
                    handleViewIcon={handleViewIcon}
                    name={`fieldArray.${index}.${ACCOUNT_NUMBER}`}
                  />
                </div>
                <div className="motif-col-lg-3">
                  <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.IBANNumber")}</div>
                  <PaymentInputField
                    control={control}
                    showField={showField}
                    handleViewIcon={handleViewIcon}
                    name={`fieldArray.${index}.${IBAN_NUMBER}`}
                  />
                </div>
              </div>
              <div className="motif-row">
                <div className="motif-col-lg-3">
                  <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.routingCode")}</div>
                  <PaymentInputField
                    control={control}
                    showField={showField}
                    handleViewIcon={handleViewIcon}
                    name={`fieldArray.${index}.${ROUTING_CODE}`}
                  />
                </div>
                <div className="motif-col-lg-3">
                  <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.SWIFTCode")}</div>
                  <PaymentInputField
                    control={control}
                    showField={showField}
                    handleViewIcon={handleViewIcon}
                    name={`fieldArray.${index}.${SWIFT_CODE}`}
                  />
                </div>
                <div className="motif-col-lg-3">
                  <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.ACHCode")}</div>
                  <PaymentInputField
                    control={control}
                    showField={showField}
                    handleViewIcon={handleViewIcon}
                    name={`fieldArray.${index}.${ACH_CODE}`}
                  />
                </div>
              </div>
            </MotifAccordionContent>
          </MotifAccordion>
        </div>
        <div className="motif-row pad-t-20 mar-t-30 section-border"></div>
        {index > 0 && (
          <div className="motif-row">
            <div className="motif-col-lg-12">
              <Button
                type="button"
                variant="secondary"
                label={t("global.globalActions.remove")}
                onClickHandler={() => {
                  removeRow(index);
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  if (
    isLoading ||
    isLoadingCountryList ||
    !countryLoaded ||
    isPayeeTypeDataLoading
  )
    return <div>{t("global.globalNotifications.loadingMessage")}</div>;
  if (error) return <div>{t("global.globalNotifications.unExpectedError")}</div>;

  return (
    <>
      {notificationVisible && (
        <NotificationGrid
          show={notification.show}
          message={notification.message}
          type={notification.type}
        />
      )}
      <div className="motif-container case-summary-form pad-v-20">
        <div className="motif-row justify-content-between align-items-center">
          <div className="motif-col-4">
            <div className="motif-body1-default-regular page-title">
              {t("pages.creditors.creditorUpdate.title")}
            </div>
            <div className="motif-body2-default-regular page-description">
            {t("pages.creditors.creditorUpdate.description")}
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-container main-container creditor-summary-view">
          <div className="motif-row">
            <div className="motif-col-lg-4">
              <div className="form-field">
                {/*@ts-ignore*/}
                <ControlInputBox
                  name={"creditorId"}
                  control={control}
                  required={true}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.creditorId"}
                  showLabel={true}
                  disabled={true}
                  readonly={true}
                />
              </div>
            </div>
            <div className="motif-col-lg-4">
              <div className="form-field">
                {/*@ts-ignore*/}
                <ControlSelect
                  name={"creditorType"}
                  control={control}
                  required={true}
                  options={[
                    { label: t("pages.creditors.creditorForm.formLabelDetails.radiobuttonsLabels.individual"), value: "individual" },
                    { label: t("pages.creditors.creditorForm.formLabelDetails.radiobuttonsLabels.organization"), value: "organization" },
                  ]}
                  label={"pages.creditors.creditorForm.formLabelDetails.creditorType"}
                  setValue={setValue}
                  getValues={getValues}
                  showLabel={true}
                />
              </div>
            </div>
            <div className={`motif-col-lg-4 ${isOrg ? "hide" : ""}`}>
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlSelect
                  name={`${PREFIX}`}
                  control={control}
                  required={false}
                  showLabel={true}
                  label={"pages.creditors.creditorForm.formLabelDetails.prefix"}
                  options={PREFIX_OPTIONS_LIST}
                  setValue={setValue}
                  getValues={getValues}
                />
              </div>
            </div>
          </div>
          <div className="motif-row pad-t-30">
            <div className={`motif-col-lg-4 ${isOrg ? "" : "hide"}`}>
              <div className="form-field">
                {/*@ts-ignore*/}
                <ControlInputBox
                  name={BUSINESS_NAME}
                  control={control}
                  required={isOrg}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.businessName"}
                  showLabel={true}
                />
                {errors[BUSINESS_NAME] &&
                  errors[BUSINESS_NAME].type === "required" && (
                    <MotifErrorMessage>
                      {t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.businessNameMessage")}
                     </MotifErrorMessage>
                  )}
              </div>
            </div>
            <div className={`motif-col-lg-4 ${isOrg ? "hide" : ""}`}>
              <div className="form-field">
                {/*@ts-ignore*/}
                <ControlInputBox
                  name={"firstName"}
                  control={control}
                  required={!isOrg}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.firstName"}
                  showLabel={true}
                />
                {errors["firstName"] &&
                  errors["firstName"].type === "required" && (
                    <MotifErrorMessage>
                    {t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.firstNameMessage")}
                  </MotifErrorMessage>
                  )}
              </div>
            </div>
            <div className={`motif-col-lg-4 ${isOrg ? "hide" : ""}`}>
              <div className="form-field">
                {/*@ts-ignore*/}
                <ControlInputBox
                  name={"lastName"}
                  control={control}
                  required={!isOrg}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.lastName"}
                  showLabel={true}
                />
                {errors["lastName"] &&
                  errors["lastName"].type === "required" && (
                    <MotifErrorMessage>
                     {t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.lastNameMessage")}
                     </MotifErrorMessage>
                  )}
              </div>
            </div>
            <div className="motif-col-lg-4">
              <div className="form-field">
                {/*@ts-ignore*/}
                <ControlInputBox
                  name={"relationShipToDebtor"}
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.relationshipToDebtor"}
                  showLabel={true}
                />
              </div>
            </div>
          </div>
          <div className="motif-row pad-t-30">
            <div className="motif-col-lg-4">
              <div className="form-field">
                {/* <ControlInputBox
                  name={"description"}
                  control={control}
                  required={true}
                  isTextArea={true}
                  label={t(DESCRIPTION_LABEL)}
                  showLabel={true}
                /> */}
                <ControlTextAreaWithCharLimit
                  name={"description"}
                  control={control}
                  required={false}
                  isTextArea={true}
                  label={"pages.creditors.creditorForm.formLabelDetails.description"}
                  showLabel={true}
                />
              </div>
            </div>
            <div className="motif-col-lg-4">
              <div className="form-field">
                {/*@ts-ignore*/}
                <ControlInputBox
                  name={"displayName"}
                  control={control}
                  required={true}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.displayName"}
                  showLabel={true}
                  readonly={true}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="heading">
            <h1>{t("pages.creditors.creditorUpdate.creditorAttributesTitle")}</h1>
            <div className="motif-container section-border">
              {data.customAttributes && data.customAttributes === 0 ? (
                <span>{t("pages.claims.noAttributesLabel")}</span>
              ) : (
                <div className="motif-row pad-t-20 mar-b-20">
                  {data.customAttributes &&
                  attributesValue.length === data.customAttributes.length ? (
                    data.customAttributes.map(
                      (attribute: any, index: number) => {
                        return (
                          <div className="motif-col-md-4">
                            <CustomAttribute
                              customAttributeDetails={{
                                ...attribute,
                                metadataJSON: JSON.parse(
                                  attribute.metadataJSON
                                ),
                              }}
                              customAttributeType={
                                attribute.value.attributeType
                              }
                              attributeValue={attributesValue}
                              setAttributeValue={setAttributesValue}
                              setIntialAttributesValue={
                                setIntialAttributesValue
                              }
                              index={index}
                              key={index}
                            />
                          </div>
                        );
                      }
                    )
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="heading">
            <h1> {t("pages.creditors.creditorUpdate.creditorContactDetailsTitle")}</h1>
            <div className="motif-container section-border">
              {controlledFields.map((field, index) => {
                return renderContactRow(field, index);
              })}
            </div>
          </div>
          <div className="motif-row">
            <div className="motif-col-xl-6 btn-left">
              <Button
                type="button"
                label={t("pages.creditors.creditorForm.formLabelDetails.addAnotherContact")}
                variant={SECONDARY}
                onClickHandler={addMoreRow}
              />
            </div>
          </div>
          <div className="error-container">
            <MotifErrorMessage>
              {isPrimary ? "" :t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.requiredPrimaryContact")}
            </MotifErrorMessage>
            <MotifErrorMessage>
              {errorList.length > 0 && !isVisible
                ? t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.contactDetailsMessage")
                : ""}
            </MotifErrorMessage>
            <MotifErrorMessage>
              {isAttributesError
                ? t("pages.configuration.attributes.requiredFields")
                : ""}
            </MotifErrorMessage>
          </div>
          <div className="button-container mar-t-20 mar-b-20">
            <Button
              label={t("global.globalActions.update")}
              type={updateIsLoading ? "loader" : "submit"}
            />
            <Button
              type="button"
              label={t("global.globalActions.cancel")}
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={(e) => {
                if (
                  isDirty ||
                  JSON.stringify(intialAttributesValue) !==
                    JSON.stringify(attributesValue)
                ) {
                  setIsVisible(true);
                  dispatch(setDiscardEditMode({ editMode: false }));
                } else {
                  cancelBtnApproveHandler(e);
                }
              }}
            />
          </div>
          <ConfirmationModal
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            cancelBtnApproveHandler={cancelBtnApproveHandler}
            cancelBtnRejectHandler={cancelBtnRejectHandler}
            type={2}
          />
        </div>
      </form>
    </>
  );
}

export default CreditiorUpdate;
