import { MotifTabNavigation, MotifTabControl, MotifDropdown, MotifButton, MotifIcon, MotifDropdownItem } from '@ey-xd/motif-react';
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IconoirNavArrowDown } from "@ey-xd/motif-icon";
import TabPanelContent from "@/components/TabPanelContent/TabPanelContent";
import { TABPANEL } from "@/appConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDiscardChangesData } from "../Modal/ConfirmationModal/discardchanges";
import { setConfirmation } from "@/features/confirmation/confirmation-slice";

interface TabPanelProps {
  data: {
    title: string[];
    content: React.ReactNode[];
    defaultActiveKey?: number;
  };
  defaultActiveKey?: number;
  orientation?: "horizontal" | "vertical" | undefined;
}

const TabPanel: React.FC<TabPanelProps> = ({
  data,
  defaultActiveKey,
  orientation = "horizontal",
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { blockNavigation } = useSelector((state: any) => state.confirmation);
  const tabRef = React.useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = React.useState(defaultActiveKey || 0);
  const [visibleTabs, setVisibleTabs] = React.useState(
    data.title.slice(0, TABPANEL.maxTabs)
  );
  const [dropdownTabs, setDropdownTabs] = React.useState(
    data.title.slice(TABPANEL.maxTabs)
  );
  let discardDetails = useSelector((e: any) => e.discardChangesDatas.data);

  const doNavigation = (index: number) => {
    if (blockNavigation) {
      dispatch(setConfirmation({ isVisible: true, blockNavigation: true }));
      return;
    }
    let tabIndexName = index;
    if (index === visibleTabs.length - 1) {
      let tabName = visibleTabs[index];
      tabIndexName = data.title.indexOf(tabName);
    }

    if (discardDetails.editMode) {
      dispatch(
        setDiscardChangesData({
          showModal: true,
          editMode: false,
          navPath: `?activeTab=${tabIndexName}`,
          navState: "",
        })
      );
    } else if (!discardDetails.editMode) {
      navigate(`?activeTab=${tabIndexName}`);
      setActiveTab(tabIndexName);
    }
  };
  const handleTabClick = (index: number) => {
    doNavigation(index);
  };

  const handleDropdownItemClick = (index: number) => {
    let tabName = dropdownTabs[index];
    let tabIndexName = data.title.indexOf(tabName);
    // console.log(newActiveTab,"indexa",data.title,dropdownTabs)
    // Replace the last visible tab with the selected dropdown item
    const updatedVisibleTabs = [...visibleTabs];
    updatedVisibleTabs[TABPANEL.maxTabs - 1] = dropdownTabs[index];
    setVisibleTabs(updatedVisibleTabs);

    // Update the dropdown items to exclude the selected item
    const updatedDropdownTabs = data.title.filter(
      (_, tabIndex) => !updatedVisibleTabs.includes(data.title[tabIndex])
    );
    setDropdownTabs(updatedDropdownTabs);
    if (discardDetails.editMode) {
      dispatch(
        setDiscardChangesData({
          showModal: true,
          editMode: false,
          navPath: `?activeTab=${tabIndexName}`,
          navState: "",
        })
      );
    } else if (!discardDetails.editMode) {
      navigate(`?activeTab=${tabIndexName}`);
      setActiveTab(tabIndexName);
    }

    // navigate(`?activeTab=${tabIndexName}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newTab = parseInt(searchParams.get("activeTab") || "0");
    if (newTab) {
      setActiveTab(newTab);
      if (
        data &&
        data.title &&
        newTab >= TABPANEL.maxTabs &&
        !visibleTabs.includes(data.title[newTab])
      ) {
        const updatedVisibleTabs = [...visibleTabs];
        updatedVisibleTabs[TABPANEL.maxTabs - 1] = data.title[newTab];
        setVisibleTabs(updatedVisibleTabs);

        const updatedDropdownTabs = data.title.filter(
          (_, tabIndex) => !updatedVisibleTabs.includes(data.title[tabIndex])
        );
        setDropdownTabs(updatedDropdownTabs);
      }
    }
  }, [location, visibleTabs, data, activeTab, setActiveTab]);

  return (
    <>
      <div className="display-flex pad-t-20">
        <MotifTabNavigation
          defaultActiveKey={0}
          orientation={orientation}
          currentTab={activeTab}
          ref={tabRef}
          controlled={true}
        >
          {visibleTabs.map((title, index) => {
            return (
              <MotifTabControl
                // disabled={index === activeTab }
                className={`${
                  index === activeTab ||
                  (index === visibleTabs?.length - 1 &&
                    activeTab >= visibleTabs?.length - 1)
                    ? "motif-active"
                    : ""
                }`}
                tabKey={index}
                key={index}
                onClick={() => handleTabClick(index)}
              >
                {t(title)}
              </MotifTabControl>
            );
          })}
        </MotifTabNavigation>

        {dropdownTabs.length > 0 && (
          <MotifDropdown
            className="pad-l-30"
            trigger={
              <MotifButton type="button" aria-label="More Tabs" variant="text">
                <MotifIcon
                  iconFunction={IconoirNavArrowDown}
                  strokeWidth="2px"
                />
              </MotifButton>
            }
          >
            <ul role="menu">
              {dropdownTabs.map((title, index) => (
                <li key={index} role="menuitem">
                  <MotifDropdownItem
                    onClick={() => handleDropdownItemClick(index)}
                  >
                    {t(title)}
                  </MotifDropdownItem>
                </li>
              ))}
            </ul>
          </MotifDropdown>
        )}
      </div>
      <TabPanelContent content={data.content[activeTab]} />
    </>
  );
};

export default TabPanel;