import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import config from "@/config";

// MSAL configuration
const configuration: Configuration = config.msalConfig;

const pca = new PublicClientApplication(configuration);

const AppMsalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <MsalProvider instance={pca}>{children}</MsalProvider>;
};

export default AppMsalProvider;
