import logger from "@/libs/logger";
import {
  createSlice,
  Middleware,
  MiddlewareAPI,
  PayloadAction,
} from "@reduxjs/toolkit";

export interface APIError {
  status: number;
  requestId: string;
  name: string;
  data: {
    type: string;
    arg: any;
    request: {
      url: string;
      status: number;
      method: string;
      headers?: string;
      body: any;
    };
    response: any;
  };
}

export interface RtkAPIErrors {
  ui: {
    show: boolean;
    heading: string | null;
    message: string | null;
  };
  [key: number]: APIError[];
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (action.type.endsWith("rejected")) {
      if (action.error.name === "ConditionError") {
        return next(action);
      }
      logger.error("An error occurred in an RTK Query request:", action);
      api.dispatch(
        addError({
          status: action.payload?.status || 500,
          requestId: action.meta.requestId,
          name: action.meta.arg.endpointName,
          data: {
            type: action.type,
            arg: action.meta.arg,
            request: {
              url: action.meta.baseQueryMeta?.request?.url,
              status: action.payload?.status,
              method: action.meta.baseQueryMeta?.request?.method,
              headers: JSON.stringify(
                action.meta.baseQueryMeta?.request?.headers
              ),
              body: action.meta.baseQueryMeta?.request?.body,
            },
            response: action.payload,
          },
        })
      );
    }

    return next(action);
  };

const initialState = {
  ui: {
    show: false,
    heading: null,
    message: null,
  },
  400: [] as APIError[],
  401: [] as APIError[],
  403: [] as APIError[],
  404: [] as APIError[],
  500: [] as APIError[],
};

const errors = createSlice({
  name: "errors",
  initialState,
  reducers: {
    addError: (state: RtkAPIErrors, action: PayloadAction<APIError>) => {
      const { status } = action.payload;
      if (!state[status]) {
        logger.error(`Unknown error status: ${status}`);
        return;
      }
      state[status].push(action.payload);
    },
    removeError: (state: RtkAPIErrors, action) => {
      const { status, requestId, name } = action.payload;
      state[status] = state[status].filter(
        (error: APIError) =>
          error.requestId !== requestId && error.name !== name
      );
    },
    setErrorUI: (state: RtkAPIErrors, action) => {
      const { show, heading, message } = action.payload;
      state.ui.show = show;
      state.ui.heading = heading;
      state.ui.message = message;
    },
    resetError: (state: any) => {
      state = initialState;
      return state;
    },
  },
});

export const { addError, removeError, setErrorUI, resetError } = errors.actions;

export default errors.reducer;
