import Table from '@/components/Table/table'
import { MotifAccordion, MotifAccordionContent, MotifAccordionTrigger, MotifButton, MotifCheckbox, MotifIcon, MotifProgressLoader } from '@ey-xd/motif-react'
import React, { useState } from 'react';
import {
  contentIcCreate24px,
  actionIcDelete24px,
} from "@ey-xd/motif-react/assets/icons";
import './UnitConversionManagement.scss';
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteUnitMutation,useGetUnitsListDataQuery } from "@/services/unit/unitReducers";
import ConfirmationModalUnit from './ConfimationModalUnit';
import ConfimationModalOK from './ConfimationModalOK';
import {  customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from '@/components/Table/CustomPaginationConstants';
import CustomPagination from '@/components/Pagination/CustomPagination';
import { useTranslation } from 'react-i18next';

interface Unit {
  default: string;
  unitName: string;
  description: string;
  rate: string;
  decimalPlace: string;
  setFetch?: any;
  isDefaultCurrency?: boolean;
  id?: string;
}
interface CheckBoxCellRenderProps {
  data: {
    name: string;
    isDefaultCurrency: boolean;
    isChecked: boolean;
  };
}
export default function UnitConversionManagement() {
  const { caseId } = useParams<{ caseId: string }>();
  const [isVisible, setIsVisible] = useState(false);
  const [isOKVisible, setIsOKVisible] = useState(false);
 const {t}=useTranslation()
  const [selectedCheckbox, setSelectedCheckbox] = useState<string | null>(null);
  const navigate = useNavigate();
  const [deleteUnit] = useDeleteUnitMutation();
  const [unitToDelete, setUnitToDelete] = useState<Unit | null>(null);
  const [okConfirmationMessage, setOkConfirmationMessage] = useState("Default Currency cannot be deleted");

    const paginationPageSize: number =customPaginationPageSize;
    const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
    let tableLoadingData=customTableLoadingData
    const [paginationObj, setPaginationObj] = useState({
      caseId: caseId,
      PageNumber: 1,
      PageSize: paginationPageSize,
      SearchText: "",
    });
    const { data, isLoading,isFetching,error, refetch } = useGetUnitsListDataQuery(
      paginationObj
    );
    let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({    })
  
    function handlePaginationData(data:any,columnTypesData:any){
      setPaginationObj({...data})
      setSortedColumnTypes({...columnTypesData})
    }



  const headerData = {
    title: "pages.configuration.units.tableTitle",
    description: "pages.configuration.units.tableDescription",
  };
  const [accordionOpen, setAccordionOpen] = useState(true);

  const CheckBoxCellRender = (props: CheckBoxCellRenderProps) => {
    const { name, isDefaultCurrency } = props.data;
    const isChecked =
      selectedCheckbox === name ||
      (isDefaultCurrency && selectedCheckbox === null);
    return (
      <>
        <div className="checkbox-dark">
          <MotifCheckbox
            id={`checkbox-${name}`}
            name={`checkbox-${name}`}
            checked={isChecked}
            onChange={() => handleCheckboxChange(name, isChecked)}
            hideLabels={true}
            disabled={true}
          >
            <></>
          </MotifCheckbox>
        </div>
      </>
    );
  };

  if (isLoading) {
    return <MotifProgressLoader show variant="default" />;
  }

  const handleDeleteClick = (unitData: Unit) => {
    if (unitData.isDefaultCurrency) {
      setIsOKVisible(true);
      return;
    }
    setUnitToDelete(unitData);
    setIsVisible(true);
  };

  const ActionsCellRenderer = (props: any) => {
    const unitData = props.data;
    return (
      <>
        <div className="pad-r-10">
          <MotifButton
            className="action-delete-button"
            size="small"
            variant="primary"
            onClick={() => {
              handleDeleteClick(unitData);
            }}
          >
            <MotifIcon title='Disable Unit' fill="none" iconFunction={actionIcDelete24px} />
          </MotifButton>
        </div>
        <MotifButton
          size="small"
          variant="primary"
          onClick={() => handleEditClick(unitData)}
        >
          <MotifIcon
            title="Edit Unit"
            fill="none"
            iconFunction={contentIcCreate24px}
          />
        </MotifButton>
      </>
    );
  };
  const handleCellClick = (params: any) => {
    const data = params.data;
    navigate(`/case/${caseId}/configuration/unit-setup`, { state: { data } });
  };

  const columnDefs = (handleCellClick: (params: any) => void)=> [
    {
      cellRenderer: CheckBoxCellRender,
      			comparator: () => 0,
      field: "isDefaultCurrency",
      headerName: t("pages.configuration.units.tableColumnDetails.column1"),
      flex: 1,
      sortable: false,
      unSortIcon: true,
    },
    {
      cellRenderer: "unitNameCellRenderers",
      			comparator: () => 0,
      field: "name",
      type:"unitNameType",
      headerName: t("pages.configuration.units.tableColumnDetails.column2"),
      flex: 1,
      sortable: true,
      unSortIcon: true,
	  	onCellClicked:handleCellClick,
      cellStyle: {cursor: "pointer"},
    },
    {
      cellRenderer: "descriptionCellRenderers",
      			comparator: () => 0,
      field: "description",
      type:"descriptionType",
      headerName: t("pages.configuration.units.tableColumnDetails.column3"),
      flex: 1,
      sortable: true,
      unSortIcon: true,

    },
    {
      cellRenderer: "rateCellRenderers",
      			comparator: () => 0,
      field: "exchangeRate",
      type:"rateType",
      headerName: t("pages.configuration.units.tableColumnDetails.column4"),
      flex: 1,
      sortable: true,
      unSortIcon: true,    },
    {
      cellRenderer: "decimalPlaceCellRenderers",
      			comparator: () => 0,
      field: "decimalPlace",
      type:"decimalPlaceType",
      headerName: t("pages.configuration.units.tableColumnDetails.column5"),
      flex: 1,
      sortable: true,
      unSortIcon: true,    },
    {
      cellRenderer: ActionsCellRenderer,
      field: "action",
      headerName: "",
      flex: 1,
      sortable: false,
    },
  ];

  const handleCreateNew = () => {
    navigate(`/case/${caseId}/configuration/unit-setup`);
  };

  const handleEditClick = (data: Unit) => {
    navigate(`/case/${caseId}/configuration/unit-setup`, { state: { data } });
  };

  const handleCheckboxChange = (name: string, isChecked: boolean) => {
    setSelectedCheckbox(selectedCheckbox === name ? null : name);
  };

  const cancelBtnHandler = () => {
    setIsVisible(false);
  };

  const confirmBtnHandler = async () => {
    if (unitToDelete) {
      try {
        await deleteUnit(unitToDelete.id).unwrap();
        setIsVisible(false);
        refetch()
      } catch (error) {
        setIsVisible(false);
        setIsOKVisible(true);
        const errorMessage = (error as { data?: { message?: string } }).data?.message || "Unit cannot be deleted";
        setOkConfirmationMessage(errorMessage);
        if ((error as { status?: number })?.status === 400) {       
          console.error("Error 400: Bad Request", errorMessage);
        } else {
          console.error("Failed to delete the unit", errorMessage);
        }
      }
    }
  };

  const confirmationMessage = "Are you sure you want to delete this unit?";  

  const handleOkButton = () => {
    setIsOKVisible(false);
  };

  return (
    <>
      <MotifAccordion
        alignIconRight={true}
        useChevronIcon={true}
        className="accordion"
        onClose={() => {
          setAccordionOpen(false);
        }}
        onOpen={() => {
          setAccordionOpen(true);
        }}
        open={accordionOpen}
      >
        <MotifAccordionTrigger className="accordion-trigger-button">
          {t("pages.configuration.units.mainTitle")}
        </MotifAccordionTrigger>
        <MotifAccordionContent>
          <Table
            columnDefs={columnDefs(handleCellClick)}
            columnTypes={{...sortedColumnTypes}}
            handlePaginationData={handlePaginationData}
                paginationObj={paginationObj}
            cellRenderers={isFetching?[]:data?.results?data?.results:[]}
            TitleComplement={() => (
              <MotifButton variant="secondary" onClick={handleCreateNew}>
                {t("global.globalActions.addNew")}
              </MotifButton>
            )}
            title={headerData.title}
            description={headerData.description}
            pagination={false}
            overlayNoRowsTemplate={isFetching?t(tableLoadingData):error? t("global.globalNotifications.wentWrong"): t("global.globalNotifications.noRecords")}

          />
           <CustomPagination
          currentpage={paginationObj.PageNumber}
          pageSize={paginationObj.PageSize}
          disableNext={!data?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
        </MotifAccordionContent>
      </MotifAccordion>

      <ConfimationModalOK
        isVisible={isOKVisible}
        setIsVisible={setIsVisible}
        handleOkButton={handleOkButton}
        confirmationMessage={okConfirmationMessage}
      />
      <ConfirmationModalUnit
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        cancelBtnHandler={cancelBtnHandler}
        confirmBtnHandler={confirmBtnHandler}
        confirmationMessage={confirmationMessage}
      />
    </>
  );
}