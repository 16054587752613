import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import SelectBox from "./SelectBox";
import { useTranslation } from "react-i18next";

type ControlSelectType = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<any>;
  name: string;
  showLabel: boolean;
  label: string;
  disabled?: boolean;
  value?: any;
  options: {
    label: string;
    value: any;
  }[];
  setValue?: UseFormSetValue<any>;
  getValues?: UseFormGetValues<any>;
  filter?: boolean;
  handleChange?: (e: any) => void;
};

export default function ControlSelect({
  name,
  control,
  required = false,
  options,
  showLabel,
  disabled,
  label,
  filter,
  handleChange,
  value
}: ControlSelectType) {
  const  initial_value = value || options[0]?.value;
  const translatedOptions = options.map((option) => {
    return {
      label: option.label,
      value: option.value,
    };
  });
  const { t } = useTranslation();

  return (
    <>
      {showLabel && (
        <div className="formFieldLabel">
          <label htmlFor="input" aria-label={label}>
            {label}
            {required ? <span className="requiredSymbol">*</span> : ""}
          </label>
        </div>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        defaultValue={initial_value}
        render={({ field: { onChange, value } }) => { 
          return (
            <>
              <SelectBox
                options={translatedOptions}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  if (handleChange) {
                    handleChange(e);
                  }
                }}
                disabled={disabled}
                filter={filter}
                searchPlaceholder={t("global.globalPlaceholder.quickSearchPlaceHolder")}
              />
            </>
          );
        }}
      />
    </>
  );
}
