import { useState } from "react";
import { MotifTabControl, MotifTabNavigation } from "@ey-xd/motif-react";
import Classification from "../Classfication";

export const TabComponent = ({ tabPanelData, renderComponent }: any) => {
  const [showContent, setShowContent] = useState<any>(0);
  return (
    <div className="pad-t-20">
      <MotifTabNavigation
        defaultActiveKey={0}
        orientation="horizontal"
        size="sm"
      >
        {tabPanelData?.title?.map((item: any, index: number) => {
          return (
            <MotifTabControl
              onClick={() => setShowContent(index)}
              tabKey={index}
            >
              {item}
            </MotifTabControl>
          );
        })}
      </MotifTabNavigation>
      <div
        className="tab-panel-content pad-t-20"
        style={showContent === 0 ? { display: "block" } : { display: "none" }}
      >
        {renderComponent}
      </div>
      <div
        className="tab-panel-content pad-t-20"
        style={showContent === 1 ? { display: "block" } : { display: "none" }}
      >
        <Classification />
      </div>
    </div>
  );
};
