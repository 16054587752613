import { ccwpApi } from "@/services";
import {
  DELETE_COMMENT,
  UPDATE_COMMENT,
} from "../constant/apiPath";

export const commentsApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({

    updateComment: builder.mutation({
        query: (formData) => ({
          url: UPDATE_COMMENT,
          method: "PUT",
          body: formData,
        }),
      }),
    
    deleteComment: builder.mutation({
      query: ({ CommentId }) => ({
        url: DELETE_COMMENT,
        method: "DELETE",
        params: { CommentId },
      }),
    }),
  }),
});

export const {
  useUpdateCommentMutation,
  useDeleteCommentMutation,
} = commentsApi;
