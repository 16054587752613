import { useForm } from "react-hook-form";
import "./FormSummary.scss";
import ControlInputBoxVoting from "./Components/ControlInputBoxVoting";
import {
  FORM_ACTION_TYPE,
  FORM_ACTION_TYPE_NAME,
  FORM_DESCRIPTION_FRENCH,
  FORM_DESCRIPTION_FRENCH_ID,
  FORM_DUE_DATE,
  FORM_DUE_DATE_NAME,
  FORM_ID,
  FORM_ID_DESCRIPTION,
  FORM_ID_FRENCH,
  FORM_IS_REVIEW,
  FORM_IS_REVIEW_NAME,
  FORM_IS_SIGNATURE,
  FORM_IS_SIGNATURE_NAME,
  FORM_NAME,
  FORM_NAME_DESCRIPTION,
  FORM_NAME_FRENCH,
  FORM_PAST_DUE_DATE,
  FORM_PAST_DUE_DATE_NAME,
  FORM_PUBLISH_LOGIC,
  FORM_PUBLISH_LOGIC_NAME,
  FORM_STAGE,
  FORM_STAGE_NAME,
  FORM_STATUS,
  FORM_STATUS_NAME,
  FORM_TYPE,
  FORM_TYPE_NAME,
} from "./Constant";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import ControlSelect from "../../Claim/ClaimDetails/ControlSelect";
import ControlCheckBox from "./ControlCheckBox";
import { useEffect, useState } from "react";
import { FormSummaryEditFormType } from "./type";
import { CANCEL, PRIMARY, SAVE, SECONDARY } from "@/Constant";
import Button from "@/components/Form/Button/Button";
import FormattedDatePicker from "@/components/FormattedDatePicker/FormattedDatePicker";
import { useLazyGetStagesQuery } from "@/services/stages/stagesReducer";
import { useNavigate, useParams } from "react-router-dom";
import { useCase } from "@/features/case/use-case";
import {
  useCreateClaimFormMutation,
  useGetReadCustomFormApiQuery,
  useUpdateClaimFormMutation,
} from "./PortalConfigReducer";
import TimeInputField from "./Components/TimeImput";
import { MotifErrorMessage, MotifProgressLoader } from "@ey-xd/motif-react";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
export interface formData {
  editView: boolean;
}

interface formDataProps {

  formData: formData;
}
type ActionTypeOption = {
  label: string;
  value: string;
};
type PublishLogicOption = {
  label: string;
  value: string;
};

export const Action_Type: ActionTypeOption[] = [
  { label: "Select Type", value: "" },
  { label: "Amend form", value: "0" },
  { label: "Dispute form", value: "1" },
  { label: "Withdraw form", value: "2" },
];
export const Action_Type1: ActionTypeOption[] = [
  { label: "Select Type", value: "" },
];
export const Publich_LOGIC: PublishLogicOption[] = [
  { label: "Select Type", value: "" },
  { label: "Previous in-progress forms will not be invalidated", value: "0" },
  {
    label: "Invalidate all in-progress forms and replace with new version",
    value: "1",
  },
];
export const PUBLISH_LOGIC1: PublishLogicOption[] = [
  { label: "Select Type", value: "" },
];
const FormManagementSummary = ({ formData }: formDataProps) => {
  const { editId } = useParams<{ editId: string }>();


  const { data:formDataReport } = useGetReadCustomFormApiQuery(editId, {
    refetchOnMountOrArgChange: true,
    skip: !editId,
  });
  const navigate = useNavigate();
  const editView = formData?.editView ? true : false;
  const [isReviewSelected, setReviewSelected] = useState(false);
  const [isSignatureSelected, setIsSignatureSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ActionType, setActionType] = useState<ActionTypeOption[]>([]);
  const [PublishType, setPublishType] = useState<PublishLogicOption[]>([]);
  const [isshowPublish, setIsShowPublish] = useState(false);
  const [formType, setFormType] = useState("");
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });
  const [dueDate, setDueDate] = useState<Date | undefined>(undefined);

  const Time = new Date("0000-00-00T00:00:00.000Z");
  const [currentStageOptions, setCurrentStageOptions] = useState<
    FormSummaryEditFormType[]
  >([]);

  const caseData = useCase();

  const { caseId } = caseData;
  const defaultOption = { label: "Select Type", value: "" };

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors ,isDirty},
  } = useForm<FormSummaryEditFormType>(
    {
      defaultValues: {
        name:"",
        description:"",
        nameFrench:"",
        descriptionFrench:"",
        type:"",
        actionSubType:"",
        status:"0",
        publishedDate:"",
        dueDate:"",
        stage:"",
        pastDueAction:"",
        isReview:undefined,
        isSignature:undefined,
        publishingLogic:""
      }
    }
  );
  const [CreateClaim] = useCreateClaimFormMutation();
  const [UpdateClaim] = useUpdateClaimFormMutation();

  const onSubmit = async (data: FormSummaryEditFormType) => {
    const ClaimApiParam = editView
      ? {
          caseId: caseId,
          id: editId,
          name: data?.name,
          description: data?.description || "",
          nameFrench: data?.nameFrench,
          descriptionFrench: data?.descriptionFrench || "",
          type: parseInt(data?.type, 10),
          actionSubType: parseInt(data?.actionSubType, 10),
          status: parseInt(data?.status, 10),
          dueDate: dueDate,
          stage: data?.stage,
          pastDueAction: parseInt(data?.pastDueAction, 10),
          isReview: data?.isReview,
          isSignature: data?.isSignature,
          publishingLogic: parseInt(data?.publishingLogic, 10) || 0,
        }
      : {
          caseId: caseId,
          name: data?.name,
          description: data?.description || "",
          nameFrench: data?.nameFrench,
          descriptionFrench: data?.descriptionFrench || "",
          type: parseInt(data?.type, 10),
          actionSubType: parseInt(data?.actionSubType, 10),
          status: parseInt(data?.status, 10),
          dueDate: dueDate,
          stage: data?.stage,
          pastDueAction: parseInt(data?.pastDueAction, 10),
          isReview: data?.isReview,
          isSignature: data?.isSignature,
        };
        dispatch(setDiscardEditMode({ editMode: false }));

    if (editView) {
      const responseApi = await UpdateClaim(ClaimApiParam);
      //@ts-ignore
      if (responseApi.data) {
        navigate(caseId ? `/case/${caseId}/form/edit/` + editId : "/", {
          state: {
            showMessage: true,
            defaultActiveKey: 1,
          },
        });
        reset(getValues(),{keepDirty:false});
        setIsLoading(false);

      } else {
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          //@ts-ignore
          message: responseApi.error.message,
        });
        setIsLoading(false);
        dispatch(setDiscardEditMode({ editMode: true }));

      }
    } else {
      try {
        const responseApi = await CreateClaim(ClaimApiParam).unwrap();
        if (responseApi && responseApi.customFormId) {
          navigate(
            caseId
              ? `/case/${caseId}/form/edit/` + responseApi.customFormId
              : "/",
            {
              state: {
                showMessage: true,
                defaultActiveKey: 1,
              },
            }
          );
          setIsLoading(false);
        }
        else{
          dispatch(setDiscardEditMode({ editMode: true }));

        }
      } catch (error: any) {
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          message: error.message,
        });
        setIsLoading(false);
        dispatch(setDiscardEditMode({ editMode: false }));

      }
    }
  };
  const checkBoxHandler = (e?: React.ReactEventHandler) => {
    setReviewSelected(!isReviewSelected);
  };
  const checkBoxsingnatureHandler = (e?: React.ReactEventHandler) => {
    setIsSignatureSelected(!isSignatureSelected);
  };
  const [getStagesQuery] = useLazyGetStagesQuery();

  const STAGE_OPTIONS: any[] = currentStageOptions
    ? currentStageOptions.map((item: any) => {
        return {
          label: item?.name || "",
          value: item?.id || "",
        };
      })
    : [];
  const StageOptions = [defaultOption, ...STAGE_OPTIONS];
  const dispatch=useDispatch()
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,isDirty]);
  useEffect(() => {
    getStagesQuery(caseId).then((response) => {
      setCurrentStageOptions(response.data || []);
    });
    // eslint-disable-next-line
  }, [caseId]);
  const Type = watch(FORM_TYPE);
  useEffect(() => {
    if (Type === "1") {
      setActionType(Action_Type);
      setFormType(Type);
    } else if (Type === "0" || Type === "2") {
      setFormType(Type);
      setActionType(Action_Type1);
      setValue("actionSubType", "");
    }
    else{
      setFormType("");
    }
  }, [Type,setValue]);
  const PublishLogic = watch(FORM_STATUS);
  useEffect(() => {
    if (PublishLogic === "1" || PublishLogic === "2") {
      setPublishType(Publich_LOGIC);
      setIsShowPublish(true);
    } else if (PublishLogic === "0" || PublishLogic === "3") {
      setPublishType(PUBLISH_LOGIC1);
      setIsShowPublish(false);
      setValue("publishingLogic", "");
    }
    // }
  }, [PublishLogic,setValue]);
  useEffect(() => {
    if (editView) {
      reset({
      "name": formDataReport?.name,
      "nameFrench": formDataReport?.nameFrench,
      "description": formDataReport?.description,
      "descriptionFrench": formDataReport?.descriptionFrench,
      
        "type":
        formDataReport?.type === "Action Form"
          ? "1"
          : formDataReport?.type === "Claim Form"
          ? "0"
          : formDataReport?.type === "Other form"
          ? "2"
          : "",
      
      
        "actionSubType":
        formDataReport?.actionSubType === "Amend form"
          ? "0"
          : formDataReport?.actionSubType === "Dispute form"
          ? "1"
          : formDataReport?.actionSubType === "Withdraw form"
          ? "2"
          : "",
      
          
        "status":
        formDataReport?.status === "Draft"
          ? "0"
          : formDataReport?.status === "Published for testing"
          ? "1"
          : formDataReport?.status === "Publish"
          ? "2"
          : formDataReport?.status === "Hidden"
          ? "3"
          : "",
      
      "publishedDate": formDataReport?.publishedDate,
      "dueDate": formDataReport?.dueDate,
    
      "stage": formDataReport?.stage,
      
        "pastDueAction":
        formDataReport?.pastDueAction ===
          "Hide form, but allow in-progress submissions"
          ? "0"
          : formDataReport?.pastDueAction === "Hide form, no submissions allowed"
          ? "1"
          : formDataReport?.pastDueAction === "Warn portal users on deadline only"
          ? "2"
          : "",
      
      "isReview": formDataReport?.isReview,
      "isSignature": formDataReport?.isSignature,
      
        "publishingLogic":
        formDataReport?.publishingLogic ===
          "Previous in-progress forms will not be invalidated"
          ? "0"
          : formDataReport?.publishingLogic ===
            "Invalidate all in-progress forms and replace with new version"
          ? "1"
          : ""});
      
          const dueDateString = formDataReport?.dueDate; 
          if (dueDateString) {
            const parsedDate = new Date(dueDateString);
            setDueDate(parsedDate);
          }
    }
    
  }, [formDataReport, editView,reset]);

  if (editView && !currentStageOptions) {
    return <MotifProgressLoader />;
  }

  return (
    <>
      <div className="motif-container case-summary-form pad-v-20">
        <div className="motif-row justify-content-between align-items-center">
          <div className="motif-col-4">
            <div className="motif-body1-default-regular page-title">
              Form Configuration
            </div>
            <div className="motif-body2-default-regular page-description">
              Descriptive text that describes your page or content.
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`motif-container main-container creditor-summary-view${
            !editView ? "create" : ""
          }`}
        >
          <div className="motif-row heights">
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlInputBoxVoting
                  control={control}
                  required={true}
                  isTextArea={false}
                  label={FORM_NAME}
                  showLabel={true}
                  readonly={false}
                  name={FORM_ID}
                />
                {errors[FORM_ID] && errors[FORM_ID].type === "required" && (
                  <MotifErrorMessage>Form Name is required</MotifErrorMessage>
                )}
              </div>
            </div>
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlInputBoxVoting
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={FORM_NAME_FRENCH}
                  showLabel={true}
                  readonly={false}
                  name={FORM_ID_FRENCH}
                />
              </div>
            </div>
          </div>
          <div
            className="motif-row next-row heights
          "
          >
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlTextAreaWithCharLimit
                  name={FORM_ID_DESCRIPTION}
                  control={control}
                  required={false}
                  isTextArea={true}
                  label={FORM_NAME_DESCRIPTION}
                  showLabel={true}
                  readonly={false}
                />
              </div>
            </div>
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlTextAreaWithCharLimit
                  name={FORM_DESCRIPTION_FRENCH_ID}
                  control={control}
                  required={false}
                  isTextArea={true}
                  label={FORM_DESCRIPTION_FRENCH}
                  showLabel={true}
                  readonly={false}
                />
              </div>
            </div>
          </div>
          <div className="motif-row heights">
            <div className="motif-col-lg-3">
              <div className="form-field">
                {editView && (
                  <ControlSelect
                    name={FORM_TYPE}
                    //@ts-ignore
                    control={control}
                    required={true}
                    showLabel={true}
                    disabled={true}
                    label={FORM_TYPE_NAME}
                    placeholder={"placeholder"}
                    options={[
                      { label: "Select Type", value: "" },
                      { label: "Claim Form", value: "0" },
                      { label: "Claim action form", value: "1" },
                      { label: "Other form", value: "2" },
                    ]}
                  />
                )}
                {!editView && (
                  <ControlSelect
                    name={FORM_TYPE}
                    //@ts-ignore
                    control={control}
                    required={true}
                    showLabel={true}
                    disabled={false}
                    label={FORM_TYPE_NAME}
                    placeholder={"placeholder"}
                    options={[
                      { label: "Select Type", value: "" },
                      { label: "Claim Form", value: "0" },
                      { label: "Claim action form", value: "1" },
                      { label: "Other form", value: "2" },
                    ]}
                  />
                )}
                {errors[FORM_TYPE] && errors[FORM_TYPE].type === "required" && (
                  <MotifErrorMessage>Form Type is required</MotifErrorMessage>
                )}
              </div>
            </div>
            {formType === "1" && (
            <div className="motif-col-lg-3">
              <div className="form-field">
                {editView && (
                  <ControlSelect
                    name={FORM_ACTION_TYPE}
                    //@ts-ignore
                    control={control}
                    required={false}
                    showLabel={true}
                    disabled={true}
                    label={FORM_ACTION_TYPE_NAME}
                    placeholder={"placeholder"}
                    options={ActionType}
                  />
                )}
                {!editView && (
                  <ControlSelect
                    name={FORM_ACTION_TYPE}
                    //@ts-ignore
                    control={control}
                    required={false}
                    showLabel={true}
                    disabled={false}
                    label={FORM_ACTION_TYPE_NAME}
                    placeholder={"placeholder"}
                    options={ActionType}
                  />
                )}
              </div>
            </div>
          )}
            <div className="motif-col-lg-3">
              <div className="form-field">
                <ControlSelect
                  name={FORM_STAGE}
                  //@ts-ignore
                  control={control}
                  required={true}
                  showLabel={true}
                  readonly={false}
                  label={FORM_STAGE_NAME}
                  placeholder={"placeholder"}
                  options={StageOptions}
                />
                {errors[FORM_STAGE] &&
                  errors[FORM_STAGE].type === "required" && (
                    <MotifErrorMessage>
                      Form Satge is required
                    </MotifErrorMessage>
                  )}
              </div>
            </div>
          </div>
          <div className="motif-row heights">
            <div className="motif-col-md-2">
              <div className="form-field">
                <div className="hidedatepicker">
                  <ControlInputBoxVoting
                    name={FORM_DUE_DATE}
                    control={control}
                    required={false}
                    isTextArea={false}
                    label={FORM_DUE_DATE_NAME}
                    showLabel={true}
                  />
                </div>
                <FormattedDatePicker
                  todayMark={true}
                  //@ts-ignore
                  value={dueDate}
                  onChange={(value: any) => {
                    const selectedDate = new Date(value);
                    selectedDate.setDate(selectedDate.getDate() + 1);
                    setDueDate(value);
                  }}
                />
              </div>
            </div>
            <div className="motif-col-1">
              <div className="form-field">
                <TimeInputField
                  id="my-time-input"
                  value={Time}
                  required={true}
                  onChange={function (date: Date): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              </div>
            </div>
          </div>

          <div className="motif-row heights">
            {dueDate && (           
            <div className="motif-col-lg-3">
              <div className="form-field">
                <ControlSelect
                  name={FORM_PAST_DUE_DATE}
                  //@ts-ignore
                  control={control}
                  required={true}
                  showLabel={true}
                  readonly={false}
                  label={FORM_PAST_DUE_DATE_NAME}
                  placeholder={"placeholder"}
                  options={[
                    { label: "Select Type", value: "" },
                    {
                      label: "Hide form, but allow in-progress submissions",
                      value: "0",
                    },
                    { label: "Hide form, no submissions allowed", value: "1" },
                    { label: "Warn portal users on deadline only", value: "2" },
                  ]}
                />
                {errors[FORM_PAST_DUE_DATE] &&
                  errors[FORM_PAST_DUE_DATE].type === "required" && (
                    <MotifErrorMessage>
                      Past Due Submission is required
                    </MotifErrorMessage>
                  )}
              </div>
            </div>
            )}
            <div className="motif-col-lg-3">
              <div className="form-field">
                {editView && (
                  <ControlSelect
                    name={FORM_STATUS}
                    //@ts-ignore
                    control={control}
                    required={true}
                    showLabel={true}
                    disabled={false}
                    label={FORM_STATUS_NAME}
                    placeholder={"placeholder"}
                    options={[
                      { label: "Draft", value: "0" },
                      { label: "Published for testing", value: "1" },
                      { label: "Publish", value: "2" },
                      { label: "Hidden", value: "3" },
                    ]}
                  />
                )}
                {!editView && (
                  <ControlSelect
                    name={FORM_STATUS}
                    //@ts-ignore
                    control={control}
                    required={true}
                    showLabel={true}
                    disabled={true}
                    label={FORM_STATUS_NAME}
                    placeholder={"placeholder"}
                    options={[
                      { label: "Draft", value: "0" },
                      { label: "Published for testing", value: "1" },
                      { label: "Publish", value: "2" },
                      { label: "Hidden", value: "3" },
                    ]}
                  />
                )}
                {errors[FORM_STATUS] &&
                  errors[FORM_STATUS].type === "required" && (
                    <MotifErrorMessage>
                      Form Status is required
                    </MotifErrorMessage>
                  )}
              </div>
            </div>
            <div className="motif-col-lg-3">
              <div className="form-field">
                {editView && isshowPublish && (
                  <ControlSelect
                    name={FORM_PUBLISH_LOGIC}
                    //@ts-ignore
                    control={control}
                    required={false}
                    showLabel={true}
                    disabled={false}
                    label={FORM_PUBLISH_LOGIC_NAME}
                    placeholder={"placeholder"}
                    options={PublishType}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="motif-row heights">
            <div className="motif-col-md-3">
              <div className="form-field">
                <span>
                  <ControlCheckBox
                    id="checkbox-french-selection"
                    onChange={checkBoxHandler}
                    checked={isReviewSelected}
                    value={isReviewSelected}
                    name={FORM_IS_REVIEW}
                    control={control}
                    required={false}
                    disabled={false}
                    label={FORM_IS_REVIEW_NAME}
                    setIsFrenchSelected={setReviewSelected}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="motif-row heights">
            <div className="motif-col-md-3">
              <div className="form-field">
                <span>
                  <ControlCheckBox
                    id="checkbox-french-selection"
                    onChange={checkBoxsingnatureHandler}
                    checked={isSignatureSelected}
                    value={isSignatureSelected}
                    name={FORM_IS_SIGNATURE}
                    control={control}
                    required={false}
                    disabled={false}
                    label={FORM_IS_SIGNATURE_NAME}
                    setIsFrenchSelected={setIsSignatureSelected}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <Button
            label={SAVE}
            type={isLoading ? "loader" : "submit"}
            disabled={false}
            variant={PRIMARY}
            loading={isLoading}
          />
          <Button
            type="button"
            label={CANCEL}
            variant={SECONDARY}
            className="cancelBtn"
            disabled={false}
            onClickHandler={()=>
           {
                  navigate(`/case/${caseData.caseId}?activeTab=8`)
           }
                }
              
          />
        </div>
      </form>
     
    </>
  );
};
export default FormManagementSummary;
