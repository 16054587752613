import ControlSelectContact from "../ControlSelectContact";
import { Control } from "react-hook-form";
import { ClaimCreditorContactFormType } from "../ContactForm";

type ContactFormType = {
    name: string;
    selectedId?: string;
    control: Control<ClaimCreditorContactFormType, any>;
    options: {
        label: string;
        value: string;
    }[];
    label: string;
    required?: boolean;
    filter?:boolean;
    placeHolder?:string;
    onChangeHandler?: (val: string) => void;
}

 const ContactSelect = ({label, name, selectedId, control, options, required=false, placeHolder="", filter=false, onChangeHandler}:ContactFormType) => {  
    return (
        <>
            <ControlSelectContact
                name={name}
                //@ts-ignore
                control={control}
                isTextArea={false}
                label={label}
                showLabel={true}
                required={required}
                options={options}
                value={selectedId}
                filter={filter}
                searchPlaceholder={placeHolder}
                selectedId={selectedId}
                onChangeHandler={onChangeHandler}
            />
        </>
    );
}

export default ContactSelect;