import PageSubTitle from "@/components/Form/PageSubTitle/PageSubTitle";
import React, { useEffect, useMemo, useState } from "react";
import {
  BUSINESS_NAME_FIELD,
  DESCRIPTION_FIELD,
  NEW_CREDITOR_FIRST_NAME,
  NEW_CREDITOR_LAST_NAME,
  NEW_CREDITOR_PREFIX_NAME,
  RELATIONSHIP_TO_DEBTOR_FIELD,

} from "../Constants";
import {
  MotifFormField,
  MotifLabel,
  MotifErrorMessage,
  MotifButton,
  MotifIcon,
  MotifRadioButtonGroup,
  MotifRadioButton,
} from "@ey-xd/motif-react";
import { useDispatch, useSelector } from "react-redux";
import {
  AssigneeSearchData,
  setSelectedAssigneeCompleteDetail,
  resetAssigneeState,
  setIsNewAssigneeType,
  setNewAssigneeDetail,
  resetNewAssigneeDetail,
} from "./selectAssigneeSlice";
import { useGetCreditorListQuery } from "@/services/creditors/creditorsReducers";
import Button from "@/components/Form/Button/Button";
import {  SECONDARY } from "@/Constant";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import useResetAllClaimItemsStore from "../useResetAllClaimItemsStore";
import { IconoirPlus } from "@ey-xd/motif-icon";
import {
  INDIVIDUAL,
  ORGANIZATION,
  CREDITOR_TYPE,
  RELATIONSHIP_TO_DEBTOR_LABEL,
} from "@/pages/CaseDetail/Creditor/CreditorSetup/Constants";

import {
  ADDRESS_1_FIELD,

  ADDRESS_2_FIELD,

  CITY_FIELD,

  COUNTRY_NAME,
  EMAIL_FIELD,

  FAX_FIELD,


  FIRST_NAME_FIELD,
 
  LAST_NAME_FIELD,
  PHONE_NUMBER_FIELD,

  POSTAL_CODE_FIELD,
  PREFIX_NAME,
  PROVINCE_STATE_NAME,
  RELATIONSHIP_TO_CREDITOR_NAME,
} from "@/pages/CaseDetail/Claim/claimSetup/Constants";
import { useForm } from "react-hook-form";
import ControlSelect from "@/pages/CaseDetail/Claim/claimSetup/Creditor/ControlSelectClaimSetupCreditor";
import ControlInputBox from "@/pages/CaseDetail/Claim/claimSetup/Creditor/ControlInputBoxClaimSetupCreditor";
import {
  ClaimSetupCreditorFormType,
  CountryRecordType,
} from "@/pages/CaseDetail/Claim/claimSetup/Creditor/type";
import {
  useGetCountryStateListDataQuery,
  useGetStateByCountryIdQuery,
} from "@/services/countryState/countryStateReducer";
import "@/pages/CaseDetail/Claim/claimSetup/Creditor/Creditor.scss";
import { useCase } from "@/features/case/use-case";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import SearchInput from "@/components/SearchInput/SearchInput";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

interface AssigneeSearchProps {
  creditorName: string;
  description: string;
  relationshipToDebtor?: string;
  creditorReference?: string;
}
interface AssigneeProps {
  goToNextTab: () => void;
}

interface RootState {
  claimSetupAssigneeSearch: AssigneeSearchData;
}
export default function SelectAssignee({ goToNextTab }: AssigneeProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { caseId } = useParams();
  const dispatch = useDispatch();
  const caseData = useCase();
  const PREFIX_OPTIONS_LIST = [
    { 
        label: t("global.globalActions.optionDefault"), 
        value: "" },
    {
        label:t("global.globalActions.prefixOptionsListLabels.mr"),
        value: "Mr.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.ms"),
        value: "Ms.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.mrs"),
        value: "Mrs.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.miss"),
        value: "Miss.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.other"),
        value: "other",
    }
]
  const {
    data: assigneeData,
    isLoading: isAssigneeDataLoading,
    error: errorAssigneeDataFetch,
  } = useGetCreditorListQuery(caseData.caseId);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  // eslint-disable-next-line
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchCharCount, setSearchCharCount] = useState(0);
  const resetAllClaimItemsStore = useResetAllClaimItemsStore();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm<ClaimSetupCreditorFormType>();

  const claimSetupAssigneeIsNewAssigneeType = useSelector(
    (state: RootState) => state.claimSetupAssigneeSearch.isNewAssignee
  );

  const claimSetupNewAssigneeData = useSelector(
    (state: RootState) => state.claimSetupAssigneeSearch.newAssigneeDetail
  );

  const [isNewAssignee, setIsNewAssignee] = useState(
    claimSetupAssigneeIsNewAssigneeType || false
  );
  const [creditorType, setCreditorType] = useState(
    claimSetupNewAssigneeData.isOrg === true ? ORGANIZATION : INDIVIDUAL
  );
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: true }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch]);
  const [selectedAssignee, setSelectedAssignee] =
    useState<AssigneeSearchProps | null>(null);

  const selectedAssigneeFromStore = useSelector(
    (state: RootState) =>
      state.claimSetupAssigneeSearch.selectedAssigneeCompleteDetail
  );

  let searchItems = [];

  if (!isAssigneeDataLoading && !errorAssigneeDataFetch && assigneeData) {
    searchItems = assigneeData.map(
      (assignee: AssigneeSearchProps) =>
        `${assignee.creditorName} | ${assignee.description}`
    );
  }

  const { data: countryListData, isLoading: isCountryListLoading } =
    useGetCountryStateListDataQuery("");

  const [stateList, setStateList] = useState<CountryRecordType[]>([
    {
      label: "Please Select",
      value: "",
    },
  ]);

  const COUNTRY_OPTIONS: any[] = useMemo(() => {
    if (!isCountryListLoading && countryListData) {
      return countryListData.map((country: CountryRecordType) => {
        return {
          label: country?.countryName || "",
          value: country?.countryId || "",
        };
      });
    } else {
      return [{ label: "", value: "" }];
    }
  }, [isCountryListLoading, countryListData]);

  const selectedCountryID = watch(COUNTRY_NAME);

  const {
    data: stateListData,
    isLoading: isStateListLoading,
    error: errorInFetchingData,
  } = useGetStateByCountryIdQuery(selectedCountryID, {
    skip: !selectedCountryID,
  });

  useEffect(() => {
    if (selectedCountryID && stateListData && !isStateListLoading) {
      const stateOptions = stateListData.map((state: CountryRecordType) => {
        return {
          label: state?.name || "",
          value: state?.id || "",
        };
      });
      setStateList(stateOptions);
    }
    if (stateListData && stateListData.length === 0 && !isStateListLoading) {
      setStateList([{ label: "Data Not Available Now", value: "" }]);
    } else if (!isStateListLoading && !stateListData && !errorInFetchingData) {
      setStateList([{ label: "Please Select", value: "" }]);
    }
  }, [
    selectedCountryID,
    stateListData,
    isStateListLoading,
    errorInFetchingData,
  ]);

  const handleSelectAssigneeCancel = () => {
    setIsVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));

  };

  const handleSelectAssigneeReset = () => {
    dispatch(resetAssigneeState());
    setIsVisible(false);
    resetAllClaimItemsStore();
    navigate(`/case/${caseId}?activeTab=3`);
  };

  const handleSearchEnterInput = () => {
    if (searchCharCount < 3) {
      setShowErrorMessage(true);
      setInputErrorMessage("pages.claims.claimCreate.assigneeTab.formLabelsDetails.serachWarningMessage");
    }
    if (searchCharCount >= 3) {
      setShowErrorMessage(false);
    }
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    setSearchCharCount(inputValue?.length);
    if (inputValue?.length === 0) {
      setSelectedAssignee(null);
      setShowErrorMessage(false);
    }
  };

  const handleSearchDropdownSelect = (selectedItem: string) => {
    dispatch(resetNewAssigneeDetail());
    dispatch(setIsNewAssigneeType(false));
    setInputValue(selectedItem);
    setSearchCharCount(selectedItem?.length);
    setShowErrorMessage(false);
    const selectedObject = assigneeData.find(
      (assignee: { creditorName: any; description: any }) => {
        const itemLabel = `${assignee.creditorName} | ${assignee.description}`;
        return itemLabel === selectedItem;
      }
    );
    setSelectedAssignee(selectedObject);
    dispatch(
      setSelectedAssigneeCompleteDetail({
        assigneeItems: selectedObject,
      })
    );
  };

  useEffect(() => {
    if (selectedAssigneeFromStore && selectedAssigneeFromStore.assigneeItems) {
      setSelectedAssignee(selectedAssigneeFromStore.assigneeItems);
      const inputValueFromStore = `${selectedAssigneeFromStore.assigneeItems.creditorName} | ${selectedAssigneeFromStore.assigneeItems.description}`;
      setInputValue(inputValueFromStore);
    }
  }, [selectedAssigneeFromStore]);

  const handleNewCreditorClick = () => {
    setIsNewAssignee(true);
    dispatch(setIsNewAssigneeType(true));
  };

  useEffect(() => {
    if (claimSetupNewAssigneeData) {
      setValue(
        NEW_CREDITOR_PREFIX_NAME,
        claimSetupNewAssigneeData.prefix || ""
      );
      setValue(
        NEW_CREDITOR_FIRST_NAME,
        claimSetupNewAssigneeData.firstName || ""
      );
      setValue(
        NEW_CREDITOR_LAST_NAME,
        claimSetupNewAssigneeData.lastName || ""
      );
      setValue(
        BUSINESS_NAME_FIELD,
        claimSetupNewAssigneeData.businessName || ""
      );
      setValue(DESCRIPTION_FIELD, claimSetupNewAssigneeData.description || "");
      setValue(
        RELATIONSHIP_TO_DEBTOR_FIELD,
        claimSetupNewAssigneeData.relationshipToDebtor || ""
      );
      setValue(
        FIRST_NAME_FIELD,
        claimSetupNewAssigneeData.contact.firstName || ""
      );
      setValue(
        LAST_NAME_FIELD,
        claimSetupNewAssigneeData.contact.lastName || ""
      );
      setValue(PREFIX_NAME, claimSetupNewAssigneeData.contact.prefix || "");
      setValue(
        ADDRESS_1_FIELD,
        claimSetupNewAssigneeData.contact.address1 || ""
      );
      setValue(
        ADDRESS_2_FIELD,
        claimSetupNewAssigneeData.contact.address2 || ""
      );
      setValue(CITY_FIELD, claimSetupNewAssigneeData.contact.city || "");
      setValue(
        PROVINCE_STATE_NAME,
        claimSetupNewAssigneeData.contact.provinceStateId || ""
      );
      setValue(
        POSTAL_CODE_FIELD,
        claimSetupNewAssigneeData.contact.postalCode || ""
      );
      setValue(COUNTRY_NAME, claimSetupNewAssigneeData.contact.countryId || "");
      setValue(
        PHONE_NUMBER_FIELD,
        claimSetupNewAssigneeData.contact.phone || ""
      );
      setValue(FAX_FIELD, claimSetupNewAssigneeData.contact.fax || "");
      setValue(EMAIL_FIELD, claimSetupNewAssigneeData.contact.email || "");
      setValue(
        RELATIONSHIP_TO_CREDITOR_NAME,
        claimSetupNewAssigneeData.contact.relationshipToCreditor || ""
      );
    }
  }, [claimSetupNewAssigneeData, setValue]);

  useEffect(() => {
    if (creditorType === INDIVIDUAL) {
      setValue("businessName", "");
    } else {
      setValue("prefix", "");
      setValue("firstName", "");
      setValue("lastName", "");
    }
  }, [creditorType, setValue]);

  const onSubmit = async (data: ClaimSetupCreditorFormType) => {
    if (isNewAssignee) {
      onNewAssigneeSubmit(data);
    }
    setInputValue(inputValue);
    setShowErrorMessage(false);
    goToNextTab();
  };

  const onNewAssigneeSubmit = (data: any) => {
    const country = countryListData.filter((country: any) => {
      return country.countryId === getValues("country");
    });
    const state = stateList.filter((state: any) => {
      return state.value === getValues("provinceState");
    });
    const newAssigneeDetail = {
      isOrg: creditorType === ORGANIZATION,
      prefix: getValues("creditorPrefix"),
      firstName: getValues("creditorFirstName"),
      lastName: getValues("creditorLastName"),
      businessName: getValues("businessName"),
      description: getValues("description"),
      relationshipToDebtor: getValues("relationshipToDebtor"),
      contact: {
        firstName: getValues("firstName"),
        lastName: getValues("lastName"),
        prefix: getValues("prefix"),
        address1: getValues("address1"),
        address2: getValues("address2"),
        city: getValues("city"),
        provinceStateId: getValues("provinceState"),
        provinceState: state[0].value === "" ? "" : state[0].label,
        postalCode: getValues("postalCode"),
        countryId: getValues("country"),
        country: country[0]?.countryName || "",
        phone: getValues("phone"),
        fax: getValues("fax"),
        email: getValues("email"),
        relationshipToCreditor: getValues("relationshipToCreditor"),
      },
    };
    //@ts-ignore
    dispatch(setNewAssigneeDetail(newAssigneeDetail));
  };

  return (
    <>
      <PageSubTitle
        title={"pages.claims.claimCreate.assigneeTab.title"}
        description={"pages.claims.claimCreate.assigneeTab.description"}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-row">
          <div className="motif-col-lg-6">
            <div className="formFieldLabel">
              <MotifLabel>{t("pages.claims.claimCreate.assigneeTab.formLabelsDetails.searchAssignee")}</MotifLabel>
            </div>
            <MotifFormField>
              <SearchInput
                aria-label="Search for a Assignee"
                id="states-search"
                placeholder={t("pages.claims.claimCreate.assigneeTab.formLabelsDetails.assigneeSearchPlaceholder")}
                className={`search-dropdown ${
                  searchCharCount >= 3 ? "show-suggestions" : ""
                }`}
                items={searchItems}
                onEnter={handleSearchEnterInput}
                onChange={handleSearchInputChange}
                onSelect={(selectedItem: any) => {
                  handleSearchDropdownSelect(selectedItem);
                }}
                visibleOptions={3}
                value={inputValue}
              />
            </MotifFormField>
          </div>
        </div>
        {showErrorMessage && (
          <MotifErrorMessage>{t(inputErrorMessage)}</MotifErrorMessage>
        )}

        {!isNewAssignee && (
          <div className="new-creditor-button">
            <MotifButton
              onClick={handleNewCreditorClick}
              size="small"
              type="button"
              variant="secondary"
            >
              <MotifIcon iconFunction={IconoirPlus} title="Icon Button" />
{t("pages.claims.claimCreate.assigneeTab.formLabelsDetails.newAssignee")}            </MotifButton>
          </div>
        )}

        {selectedAssignee && (
          <div className="display-flex align-content-center mar-t-15">
            <div className="small-banner">
              <div className="formFieldLabel">
                <MotifLabel>{t("pages.claims.claimCreate.assigneeTab.formLabelsDetails.assigneeName")}</MotifLabel>
              </div>
              <div className="motif-body2-default-regular">
                {selectedAssignee.creditorName}
              </div>
            </div>
            <div className="small-banner mar-l-2">
              <div className="formFieldLabel">
                <MotifLabel>{t("pages.claims.claimCreate.assigneeTab.formLabelsDetails.referenceId")}</MotifLabel>
              </div>
              <div className="motif-body2-default-regular">
                {selectedAssignee.creditorReference}
              </div>
            </div>
            <div className="small-banner mar-l-2">
              <div className="formFieldLabel">
                <MotifLabel>{t("pages.claims.claimCreate.assigneeTab.formLabelsDetails.description")}</MotifLabel>
              </div>
              <div className="motif-body2-default-regular">
                {selectedAssignee.description}
              </div>
            </div>
            <div className="small-banner mar-l-2">
              <div className="formFieldLabel">
                <MotifLabel>{t("pages.claims.claimCreate.assigneeTab.formLabelsDetails.relationshipToDebtor")}</MotifLabel>
              </div>
              <div className="motif-body2-default-regular">
                {selectedAssignee.relationshipToDebtor}
              </div>
            </div>
          </div>
        )}

        {isNewAssignee && (
          <>
            <div className="new-creditor-container">
              <div>
                <p className="form-description">{t("pages.claims.claimCreate.assigneeTab.newAssigneeDescription")}</p>
              </div>
              <div>
                <MotifRadioButtonGroup
                  name={CREDITOR_TYPE}
                  required={true}
                  defaultValue={INDIVIDUAL}
                  options={[INDIVIDUAL, ORGANIZATION]}
                >
                  <MotifRadioButton
                    checked={creditorType === INDIVIDUAL}
                    onChange={() => setCreditorType(INDIVIDUAL)}
                    value={INDIVIDUAL}
                  >
                    {t("pages.claims.claimCreate.assigneeTab.formLabelsDetails.radiobuttonsLabels.individual")}
                  </MotifRadioButton>
                  <MotifRadioButton
                    checked={creditorType === ORGANIZATION}
                    onChange={() => setCreditorType(ORGANIZATION)}
                    value={ORGANIZATION}
                  >
                    {t("pages.claims.claimCreate.assigneeTab.formLabelsDetails.radiobuttonsLabels.organization")}
                    </MotifRadioButton>
                </MotifRadioButtonGroup>
              </div>
              <div
                className={`motif-row mar-t-30 ${
                  creditorType === ORGANIZATION ? "hide" : ""
                }`}
              >
                <div className="motif-col-lg-6">
                  <ControlSelect
                    name={NEW_CREDITOR_PREFIX_NAME}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.prefix"}
                    showLabel={true}
                    options={PREFIX_OPTIONS_LIST}
                    setValue={setValue}
                    getValues={getValues}
                  />
                  {errors[NEW_CREDITOR_PREFIX_NAME] &&
                    errors[NEW_CREDITOR_PREFIX_NAME].type === "required" && (
                      <MotifErrorMessage>
                        {t("pages.claims.claimCreate.assigneeTab.validationMessages.prefixMessage"
                        )}
                      </MotifErrorMessage>
                    )}
                </div>
              </div>
              <div
                className={`motif-row mar-t-30 ${
                  creditorType === ORGANIZATION ? "hide" : ""
                }`}
              >
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    name={NEW_CREDITOR_FIRST_NAME}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.firstName"}
                    showLabel={true}
                    required={creditorType === ORGANIZATION ? false : true}
                  />
                  {errors[NEW_CREDITOR_FIRST_NAME] &&
                    errors[NEW_CREDITOR_FIRST_NAME].type === "required" && (
                      <MotifErrorMessage>
                        {t("pages.claims.claimCreate.assigneeTab.validationMessages.firstNameMessage")}
                      </MotifErrorMessage>
                    )}
                </div>
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    name={NEW_CREDITOR_LAST_NAME}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.lastName"}
                    showLabel={true}
                    required={creditorType === ORGANIZATION ? false : true}
                  />
                  {errors[NEW_CREDITOR_LAST_NAME] &&
                    errors[NEW_CREDITOR_LAST_NAME].type === "required" && (
                      <MotifErrorMessage>
                        {t("pages.claims.claimCreate.assigneeTab.validationMessages.lastNameMessage")}
                        </MotifErrorMessage>
                    )}
                </div>
              </div>
              <div
                className={`motif-row mar-t-30 ${
                  creditorType === ORGANIZATION ? "" : "hide"
                }`}
              >
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    name={BUSINESS_NAME_FIELD}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.businessName"}
                    showLabel={true}
                    required={creditorType === ORGANIZATION ? true : false}
                  />
                  {errors[BUSINESS_NAME_FIELD] &&
                    errors[BUSINESS_NAME_FIELD].type === "required" && (
                      <MotifErrorMessage>
                        {t("pages.claims.claimCreate.assigneeTab.validationMessages.businessNameMessage")}
                        </MotifErrorMessage>
                    )}
                </div>
              </div>
              <div className="motif-row mar-t-30">
                <div className="motif-col-lg-6">
                  {/* <ControlInputBox
                    name={DESCRIPTION_FIELD}
                    control={control}
                    isTextArea={true}
                    label={t(DESCRIPTION_LABEL)}
                    showLabel={true}
                    required={true}
                  /> */}
                  <ControlTextAreaWithCharLimit
                    name={DESCRIPTION_FIELD}
                    control={control}
                    isTextArea={true}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.description"}
                    showLabel={true}
                    required={true}
                  />
                  {errors[DESCRIPTION_FIELD] &&
                    errors[DESCRIPTION_FIELD].type === "required" && (
                      <MotifErrorMessage>
                        {t("pages.claims.claimCreate.assigneeTab.validationMessages.descriptionMessage")}
                        </MotifErrorMessage>
                    )}
                </div>
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    //@ts-ignore
                    name={RELATIONSHIP_TO_DEBTOR_FIELD}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.relationshipToDebtor"}
                    showLabel={true}
                    required={false}
                  />
                  {errors[RELATIONSHIP_TO_DEBTOR_FIELD] &&
                    errors[RELATIONSHIP_TO_DEBTOR_FIELD].type ===
                      "required" && (
                      <MotifErrorMessage>
                        {t(`${RELATIONSHIP_TO_DEBTOR_LABEL} is required`)}
                      </MotifErrorMessage>
                    )}
                </div>
              </div>
              <PageSubTitle title={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.contactDetails"} />
              <div className="motif-body1-default-regular primary-contact-title">
                {t("pages.claims.claimCreate.assigneeTab.formLabelsDetails.primaryContactDetails")}
              </div>
              <div className="motif-row mar-t-30">
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    name={FIRST_NAME_FIELD}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.firstName"}
                    showLabel={true}
                    required={true}
                  />
                  {errors[FIRST_NAME_FIELD] &&
                    errors[FIRST_NAME_FIELD].type === "required" && (
                      <MotifErrorMessage>
                        {t("pages.claims.claimCreate.assigneeTab.validationMessages.firstNameMessage")}
                        </MotifErrorMessage>
                    )}
                </div>
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    name={LAST_NAME_FIELD}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.lastName"}
                    showLabel={true}
                    required={true}
                  />
                  {errors[LAST_NAME_FIELD] &&
                    errors[LAST_NAME_FIELD].type === "required" && (
                      <MotifErrorMessage>
                        {t("pages.claims.claimCreate.assigneeTab.validationMessages.lastNameMessage")}
                        </MotifErrorMessage>
                    )}
                </div>
              </div>
              <div className="motif-row mar-t-30">
                <div className="motif-col-lg-6">
                  <ControlSelect
                    name={PREFIX_NAME}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.prefix"}
                    showLabel={true}
                    options={PREFIX_OPTIONS_LIST}
                    setValue={setValue}
                    getValues={getValues}
                  />
                  {errors[PREFIX_NAME] &&
                    errors[PREFIX_NAME].type === "required" && (
                      <MotifErrorMessage>
                        {t("Prefix selection is required")}
                      </MotifErrorMessage>
                    )}
                </div>
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    name={RELATIONSHIP_TO_CREDITOR_NAME}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.relationshipToCreditor"}
                    showLabel={true}
                    required={false}
                  />
                  {errors[RELATIONSHIP_TO_CREDITOR_NAME] &&
                    errors[RELATIONSHIP_TO_CREDITOR_NAME].type ===
                      "required" && (
                      <MotifErrorMessage>
                        {t("Relation to Creditor is required")}
                      </MotifErrorMessage>
                    )}
                </div>
              </div>
              <div className="motif-row mar-t-30">
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    name={PHONE_NUMBER_FIELD}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.phoneNumber"}
                    showLabel={true}
                  />
                  {errors[PHONE_NUMBER_FIELD] &&
                    errors[PHONE_NUMBER_FIELD].type === "pattern" && (
                      <MotifErrorMessage>
                        {t("Phone number must be a number")}
                      </MotifErrorMessage>
                    )}
                </div>
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    name={FAX_FIELD}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.fax"}
                    showLabel={true}
                    rules={{
                      pattern: {
                        value: /^[.0-9]+$/,
                      },
                    }}
                  />
                  {errors[FAX_FIELD] &&
                    errors[FAX_FIELD].type === "pattern" && (
                      <MotifErrorMessage>
                        {t("pages.claims.claimCreate.assigneeTab.validationMessages.faxPatternMessage")}
                      </MotifErrorMessage>
                    )}
                </div>
              </div>
              <div className="motif-row mar-t-30">
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    name={ADDRESS_1_FIELD}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.address1"}
                    showLabel={true}
                    required={false}
                  />
                  {errors[ADDRESS_1_FIELD] &&
                    errors[ADDRESS_1_FIELD].type === "required" && (
                      <MotifErrorMessage>
                        {t("Address 1 is required")}
                      </MotifErrorMessage>
                    )}
                </div>
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    name={ADDRESS_2_FIELD}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.address2"}
                    showLabel={true}
                  />
                </div>
              </div>
              <div className="motif-row mar-t-30">
                <div className="motif-col-lg-6">
                  <ControlInputBox
                    name={EMAIL_FIELD}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.email"}
                    showLabel={true}
                    rules={{
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: t("pages.claims.claimCreate.assigneeTab.validationMessages.emailPatternMessage"),
                      },
                    }}
                  />
                  {errors[EMAIL_FIELD] &&
                    errors[EMAIL_FIELD].type === "pattern" && (
                      <MotifErrorMessage>
                        {errors[EMAIL_FIELD].message}
                      </MotifErrorMessage>
                    )}
                </div>
                <div className="motif-col-lg-3">
                  <ControlInputBox
                    name={CITY_FIELD}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.city"}
                    showLabel={true}
                    required={false}
                  />
                  {errors[CITY_FIELD] &&
                    errors[CITY_FIELD].type === "required" && (
                      <MotifErrorMessage>
                        {t("City is required")}
                      </MotifErrorMessage>
                    )}
                </div>
                <div className="motif-col-lg-3">
                  <ControlSelect
                    name={COUNTRY_NAME}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.country"}
                    showLabel={true}
                    options={COUNTRY_OPTIONS}
                    setValue={setValue}
                    getValues={getValues}
                    required={false}
                    filter={true}
                    searchPlaceholder={t("global.globalPlaceholder.countryPlaceholder")}
                  />
                  {errors[COUNTRY_NAME] &&
                    errors[COUNTRY_NAME].type === "required" && (
                      <MotifErrorMessage>
                        {t("Country is required")}
                      </MotifErrorMessage>
                    )}
                </div>
              </div>
              <div className="motif-row justify-content-end mar-t-30">
                <div className="motif-col-lg-3">
                  <ControlSelect
                    name={PROVINCE_STATE_NAME}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.provinceState"}
                    showLabel={true}
                    options={stateList}
                    setValue={setValue}
                    getValues={getValues}
                    required={false}
                    filter={true}
                    searchPlaceholder={t("global.globalPlaceholder.statePlaceholder")}
                  />
                  {errors[PROVINCE_STATE_NAME] &&
                    errors[PROVINCE_STATE_NAME].type === "required" && (
                      <MotifErrorMessage>
                        {t("Province is required")}
                      </MotifErrorMessage>
                    )}
                </div>
                <div className="motif-col-lg-3">
                  <ControlInputBox
                    name={POSTAL_CODE_FIELD}
                    control={control}
                    isTextArea={false}
                    label={"pages.claims.claimCreate.assigneeTab.formLabelsDetails.postalCode"}
                    showLabel={true}
                    required={false}
                  />
                  {errors[POSTAL_CODE_FIELD] &&
                    errors[POSTAL_CODE_FIELD].type === "required" && (
                      <MotifErrorMessage>
                        {t("Postal Code is required")}
                      </MotifErrorMessage>
                    )}
                </div>
              </div>
            </div>
          </>
        )}

        <div className="display-flex justify-content-end mar-t-70">
          <div className="pad-r-10">
            <Button
              type="button"
              label={t("global.globalActions.cancel")}
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={() => {setIsVisible(true);      dispatch(setDiscardEditMode({ editMode: false }));
            }}
            />
          </div>
          <div className="pad-r-10">
            <Button label={t("global.globalActions.next")} variant="primary" />
          </div>
        </div>

        <ConfirmationModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          cancelBtnApproveHandler={handleSelectAssigneeReset}
          cancelBtnRejectHandler={handleSelectAssigneeCancel}
        />
      </form>
    </>
  );
}
