export const DEFAULT_CURRENCY_NAME = "defaultCurrency";
export const DEFAULT_CURRENCY_ID = "Default Currency Name";
export const DEFAULT_EXCHANGE_RATE = "defaultExchangeRate";
export const DEFAULT_EXCHANGE_RATE_ID = "Exchange Rate";
export const DEFAULT_DECIMAL_POINT = "defaultDecimalPoint";
export const DEFAULT_DECIMAL_POINT_ID = "Decimal Point";
export const ORIGINAL_CURRENCY_NAME = "originalCurrency";
export const ORIGINAL_CURRENCY_Id = "Currency Name";
export const ORIGINAL_EXCHANGE_RATE = "originalExchangeRate";
export const ORIGINAL_EXCHANGE_RATE_ID = "Exchange Rate";
export const ORIGINAL_DECIMAL_POINT_ID = "Decimal Point";
export const ORIGINAL_DECIMAL_POINT = "decimalPoint";
export const ORIGINAL_Currency_ROW = "OriginalCurrencyRow";
export const ADD_ANOTHER = "Add Another";
export const FIELD_ARRAY = "fieldArray";


