import React from "react";
import {
  Control,
  Controller,
  FieldValues, Path, PathValue
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import SelectBox from "./SelectBox";
import { NonEmptyString } from "@/utility/common";

type ControlSelectType<T extends FieldValues> = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<T>;
  name: Path<T> ;
  showLabel: boolean;
  label: string;
  options: {
    label: string;
    value: string;
  }[];
  setValue: any;
  getValues: any;
  value?: string;
  disabled?: boolean;
  multiple?: boolean;
  index: number;
  onChangeHandlerPayee : (index: number, payeeId: string) => void;
};

export default function ControlSelectPayee<T extends FieldValues>({
  name,
  control,
  required = false,
  options,
  showLabel,
  label,
  value,
  disabled,
  multiple,
  onChangeHandlerPayee,
  index

}: ControlSelectType<T>) {
  const { t } = useTranslation();
  if (options === undefined) {
    return null;
  }

  const translatedOptions =
    options &&
    options.map((option) => {
      return {
        label: option.label,
        value: option.value,
      };
    });

  const defaultValue = NonEmptyString(value) ? (value as PathValue<T, Path<T>>) : (options[0]?.value as PathValue<T, Path<T>>);
  return (
    <>
      {showLabel && (
        <div className="formFieldLabel">
          <label htmlFor="input" aria-label={label}>
            {t(label)}
            {required ? <span className="requiredSymbol">*</span> : ""}
          </label>
        </div>
      )}
      <Controller
        // @ts-ignore
        name={name}
        control={control}
        rules={{ required }}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <SelectBox
                options={translatedOptions}
                value={value}
                onChange={onChange}
                onChangeHandlerPayee={onChangeHandlerPayee}
                disabled={disabled}
                multiple={multiple}
                index={index}
              />
            </>
          );
        }}
      />
    </>
  );
}
