import TabPanel from "@/components/TabPanel/TabPanel";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Banner, { BannerElement } from "@/components/Banner/Banner";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import { useParams } from "react-router-dom";
import VotingSummary from "./votingSummary";
import {
  useGetListBallotVersionsByBallotQuery,
  useGetListReadBallotHistoryQuery,
} from "../votingReducer";
import CreditorVotesTab from "@/pages/CaseDetail/Voting/CreditorVotesTab";
import { dateFunc } from "@/components/Functions/dateFunc";
import { BALLOT_VERSION_ID, } from "./Constant";
import { VotingVersionFormType, VotingVersionsFormType } from "./type";
import { useForm } from "react-hook-form";
import VersionControlSelect from "./VersionFieldSelectBox";
import { useEffect, useState } from "react";
import { useCase } from "@/features/case/use-case";
import { useTranslation } from "react-i18next";
export default function VotingFormGrid() {
  const { caseId, editId } = useParams();
  const [isRefreshVersionList, setIsRefreshVersionList] = useState(false);
  const [isReadBallotList, IsSetisReadBallotList] = useState(false);
const {t}=useTranslation()
  const editView = editId ? true : false;
  const getDataByUrl = { ballotId: editId };
  const { control, watch, setValue } = useForm<VotingVersionFormType>();
  const { data: Versionoptions, refetch: refetchBallotVersions } =
    useGetListBallotVersionsByBallotQuery(getDataByUrl, {
      refetchOnMountOrArgChange: true,
    });
  const [options, setOptions] = useState([
    { value: "", label: t("global.globalActions.optionDefault"), ballotSequenceId: "" },
  ]);
  const BallotVerion = watch(BALLOT_VERSION_ID);
  const BallotVerionSelect = options.find(
    ({ value }) => BallotVerion === value
  );
  const caseData = useCase();
  console.log(caseData,"caseData")
  const timeZone = caseData?.abbreviation;
  const getDataByselect = {
    ballotId: BallotVerionSelect?.value || "",
    ballotSequenceId: BallotVerionSelect?.ballotSequenceId || "",
  };

  const { refetch: readBallot, data: Ballot } =
    useGetListReadBallotHistoryQuery(getDataByselect, {
      skip:
        getDataByselect.ballotId === "" &&
        getDataByselect.ballotSequenceId === "",
    });
  async function handleHistoryApi() {
    await readBallot();
  }
  const ballotData = Ballot?.ballot ? Ballot?.ballot : {};
  const refreshBallot = Ballot?.refreshBallot ? Ballot?.refreshBallot : {};

  useEffect(() => {
    if (Versionoptions && Versionoptions.length > 0) {
      const VERSION_OPTIONS: any[] = Versionoptions.map(
        (item: VotingVersionsFormType) => {
          return {
            label: item?.name || "",
            value: item?.id || "",
            ballotSequenceId: item?.ballotSequenceId || "",
          };
        }
      );
      setOptions(VERSION_OPTIONS);
    }
    if (Versionoptions && Versionoptions[0]) {
      setValue("id", Versionoptions[0].id);
    }
  }, [Versionoptions, setValue]);

  useEffect(() => {
    if (isRefreshVersionList) {
      refetchBallotVersions();
      setIsRefreshVersionList(false);
    }
  }, [isRefreshVersionList, refetchBallotVersions]);
  useEffect(() => {
    if (isReadBallotList) {
      readBallot();
      IsSetisReadBallotList(false);
    }
  }, [isReadBallotList, readBallot]);
  const tabPanelData = {
    title: ["pages.voting.tabPannelDetails.summary", "pages.voting.tabPannelDetails.creditorVotes"],
    content: [
      <>
        <VotingSummary
          ballotData={{ editView: editView, ...ballotData, refreshBallot }}
          setIsRefreshVersionList={setIsRefreshVersionList}
          BallotVerionSelect={BallotVerionSelect?.label || ""}
          handleHistoryApi={handleHistoryApi}
        />
        ,
      </>,
      <CreditorVotesTab
        isReadonly={Ballot?.ballot.status}
        BallotVerionSelect={BallotVerionSelect?.label || ""}
        setIsRefreshVersionList={setIsRefreshVersionList}
        IsSetisReadBallotList={IsSetisReadBallotList}
        readBallot={readBallot}
        refetchBallotVersions={refetchBallotVersions}
        ballot={Ballot}
      />,
    ],
  };
  const tabPanelDatas = {
    title: ["pages.voting.tabPannelDetails.summary"],
    content: [
      <>
        <VotingSummary
          ballotData={{ editView: editView, ...ballotData, refreshBallot }}
          setIsRefreshVersionList={setIsRefreshVersionList}
          BallotVerionSelect={BallotVerionSelect?.label || ""}
        />
        ,
      </>,
    ],
  };

  const bannerElement: BannerElement[] = [
    {
      label: "pages.voting.bannerDetails.ballotStatus",
      value:
        Ballot?.ballot.status === true
          ? "Open"
          : Ballot?.ballot.status === false
          ? "Locked"
          : "-",
      elementKey: "caseID",
    },
    {
      label: "pages.voting.bannerDetails.class",
      value: Ballot?.ballot.className ? String(Ballot?.ballot.className) : "-",
      elementKey: "caseID",
    },
    {
      label: "pages.voting.bannerDetails.meeting",
      value: Ballot?.ballot.meetingDate
        ? dateFunc(Ballot?.ballot.meetingDate).formattedDateOnly
        : "-",
      elementKey: "caseID",
    },
  ];
  const breadcrumbItems = [
    {
      label: "External Submission",
      value: "10 New Creditor Submission",
      elementKey: "submission",
      text: t("global.globalActions.back"),
      url: `/case/${caseId}/?activeTab=4`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];
  if (!editView) {
    return (
      <>
        <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
        <h1>{t("pages.voting.createBallotTitle")}</h1>
        <div className="ccwp_table_wrapper ccwp_table_dropdown_wrapper">
          <TabPanel key="create" data={tabPanelDatas} />
        </div>
      </>
    );
  }
  return (
    <>
      <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
      <div className="motif-row">
        <div className="motif-col-md-6">
          <Banner
            bannerName={"pages.voting.bannerDetails.ballotName"}
            bannerNameValue={Ballot?.ballot.name}
            elements={bannerElement}
          />
        </div>
        <div className="motif-col-md-2">
          <div className="form-field">
          <VersionControlSelect
              name={BALLOT_VERSION_ID}
              //@ts-ignore
              control={control}
              required={false}
              showLabel={true}
              readonly={false}
              label={"pages.voting.viewVersion"}
              options={options.map(option => ({
              ...option,
              label: option.label === "Current Ballot" ? option.label : `${option.label} ${timeZone}`
              }))}
            />
          </div>
        </div>
      </div>
      <div className="ccwp_table_wrapper ccwp_table_dropdown_wrapper">
        <TabPanel key="edit" data={tabPanelData} />
      </div>
    </>
  );
}
