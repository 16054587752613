import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    MotifErrorMessage,
    MotifModal,
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader,
    MotifProgressLoader,
} from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "@/components/Form/Button/Button";
import {
    CANCEL,
    REQUIRED_MESSAGE,
    SAVE,
    SECONDARY,
} from "@/Constant";
import ControlSelect from "./SelectBox/editFieldSelectBox";
import { renderOptionList } from "../Form/calculationSummaryHelper";
import "./FormModal.scss";
import { useGetRateTierTemplateListByCaseQuery } from "@/services/rateTierTemplate/RateTierTemplateReducer";

type Props = {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    caseId: string;
    stepId: string;
    setIsRefetch: Dispatch<SetStateAction<boolean>>;
    onApplyTemplate: (templateId: string, templateName: string) => void;
};

const FormModalRateTierTemplate = ({
    isVisible,
    setIsVisible,
    caseId,
    stepId,
    setIsRefetch,
    onApplyTemplate,
}: Props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [templateOptions, setTemplateOptions] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState("");

    const { control, handleSubmit, setValue } = useForm({
        defaultValues: {
            template: "",
        },
    });

    const { data: templateList, isLoading: isLoadingTemplateList, refetch: refetchTemplateList, } =
        useGetRateTierTemplateListByCaseQuery(caseId);

        useEffect(() => {
            if (isVisible) {
                refetchTemplateList();
            }
        }, [isVisible, refetchTemplateList]);

    useEffect(() => {
        if (templateList && templateList.length > 0) {
            const options = renderOptionList(templateList, "templateName", "id");
            setTemplateOptions(options);
        }
    }, [templateList]);

    const onSubmit = async (data: any) => {
        if (!data.template) {
            setErrorMessage(t(`${REQUIRED_MESSAGE} Template`));
            return;
        }
        setIsLoading(true);
        try {
            const selectedTemplate = templateOptions.find(
                (option) => option.value === data.template
            );

            if (!selectedTemplate) {
                setErrorMessage("Selected template not found.");
                return;
            }

            onApplyTemplate(data.template, selectedTemplate.label);

            setIsRefetch(true);
            setIsVisible(false);
        } catch (error) {
            setErrorMessage(
                "An unexpected error occurred while applying the template."
            );
        } finally {
            setIsLoading(false);
        }
    };


    const cancelHandler = () => {
        setValue("template", "");
        setErrorMessage("");
        setIsVisible(false);
    };

    return (
        <MotifModal
            onClose={cancelHandler}
            show={isVisible}
            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: "none",
                },
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formModalWrapper">
                    <MotifModalHeader>
                        <span className="discard_heading">{t("Create from Template")}</span>
                    </MotifModalHeader>
                    <MotifModalBody>
                        {isLoadingTemplateList ? (
                            <MotifProgressLoader show variant="default" />
                        ) : (
                            <div className="formModal">
                                <div className="motif-row mar-t-20">
                                    <div className="motif-col-md-5">
                                        {isLoadingTemplateList ? (
                                            <MotifProgressLoader show variant="default" />
                                        ) : templateOptions.length === 0 ? (
                                            <p>{t("No templates available.")}</p>
                                        ) : (
                                            <ControlSelect
                                                name={"template"}
                                                //@ts-ignore
                                                control={control}
                                                required={true}
                                                showLabel={true}
                                                label={t("Select Rate Tier Template")}
                                                placeholder={t("Please Select")}
                                                options={templateOptions}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {errorMessage && (
                            <div className="error-container">
                                <MotifErrorMessage>{errorMessage}</MotifErrorMessage>
                            </div>
                        )}
                    </MotifModalBody>
                    <MotifModalFooter>
                        <div className="button-container-add">
                            <Button
                                label={t(SAVE)}
                                disabled={isLoading}
                            />
                            <Button
                                label={t(CANCEL)}
                                type="button"
                                variant={SECONDARY}
                                className="cancelBtn"
                                onClickHandler={cancelHandler}
                            />
                        </div>
                    </MotifModalFooter>
                </div>
            </form>
        </MotifModal>
    );
};

export default FormModalRateTierTemplate;
