import Button from "@/components/Form/Button/Button";
import { SECONDARY } from "@/Constant";
import {
  MotifErrorMessage,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlSelect from "./SelectBox/editFieldSelectBox";
import ControlInputBoxNumber from "@/components/Form/InputBox/ControlInputBoxNumber";
import ControlCheckBox from "./Checkbox";
import { renderOptionList } from "../Form/calculationSummaryHelper";
import {
  useCreateLimitAmountMutation,
  useGetDistributionApiDataQuery,
  useGetListCalculationStepQuery,
} from "../calculationStepReducer";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";

export default function LimitAmountFormNew({
  caseId,
  stepId,
  createShow,
  setCreateShow,
  refetch,
  manualDataSource,
}: any) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm<any>();
  const [isFrenchSelected, setIsFrenchSelected] = useState(false);

  const checkBoxHandler = (e?: React.ReactEventHandler) => {
    setIsFrenchSelected(!isFrenchSelected);
  };
  const resetData = {
    limitType: "",
    isZeroLimit: "",
    dataSourceType: "",
    isEntitlement: false,
    isFilterApplicableClasses: false,
    weight: "",
    sourceCalculationStepId: "",
    sourceManualDataSourceId: "",
  };
  const { data: distribution } = useGetDistributionApiDataQuery(caseId);
  const [isVisibleCancel, setIsVisibleCancel] = useState(false);
  const [createLimitAmount] = useCreateLimitAmountMutation();
  const distributionId = watch("distribution");
  const { data: calculationStep } = useGetListCalculationStepQuery({
    distributionId: distributionId,
  },{ skip: !distributionId });
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });

  const getDataSource = watch("dataSource");
  const manualDataSourceList = renderOptionList(manualDataSource, "name", "id");
  const distributionList = renderOptionList(distribution?.results?distribution.results:[], "name", "id");
  const calculationStepList = renderOptionList(calculationStep?.results?calculationStep.results:[], "name", "id");
  function isNumeric(value: any) {
   return /^\d+(\.\d+)?$/.test(value);
  }
  const onSubmit = async (data: any) => {
    let inputValue = data?.weightingFactor;
    if (isNumeric(inputValue) || inputValue === "") {
      console.log("Value is numeric");
    } else {
      // Optionally handle the case where the input is not numeric
      setError("weightingFactor", {
        type: "manual",
        message: "notNumeric",
      });
      return;
    }
    let formData: any = {
      caseId: caseId,
      calculationStepId: stepId,
      limitType: Number(data?.type),
      dataSourceType: Number(data?.dataSource),
      isEntitlement: data?.entitleMentSet === "0" ? true : false,
      isFilterApplicableClasses: data?.filterApplicableClasses || false,
      weight: Number(data?.weightingFactor),
      sourceCalculationStepId: data?.calculationStep || null,
      sourceManualDataSourceId: data?.manualDataSource || null,
    };

    setIsLoading(true);
    const response = await createLimitAmount(formData);
    if (response.hasOwnProperty("data")) {
      setCreateShow(false);
      setIsLoading(false);
      refetch();
      reset(resetData);
    } else {
      setIsLoading(false);
    }
  };
  const dataSources = watch("dataSource");
  const entitleMentSet = watch("entitleMentSet");

  useEffect(() => {
    if (dataSources === "1" && entitleMentSet === undefined) {
      setValue("entitleMentSet", "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSources]);

  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    reset(resetData);
    setCreateShow(false);
    setIsVisibleCancel(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisibleCancel(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  return (
    <>
      <MotifModal
        onClose={() => {setCreateShow(false); reset(resetData);}}
        show={createShow}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        size={"xl"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formModalWrapper">
            <MotifModalHeader>
              <span className="discard_heading">{t("pages.distributions.distributionStepsTab.limitAmountTab.limitFormCreate.title")}</span>
            </MotifModalHeader>
            <MotifModalBody>
              <div className="formModal">
                <div className="motif-row">
                  <div className="motif-col-xs-1 motif-col-md-2 motif-col-lg-4">
                    <ControlSelect
                      name={"type"}
                      //@ts-ignore
                      control={control}
                      required={true}
                      showLabel={true}
                      readonly={true}
                      label={"pages.distributions.distributionStepsTab.limitAmountTab.formLabels.type"}
                      //@ts-ignore
                      placeholder={"placeholder"}
                      options={[
                        { label: t("pages.distributions.distributionStepsTab.limitAmountTab.typeOptions.label"), value: "" },
                        { label: t("pages.distributions.distributionStepsTab.limitAmountTab.typeOptions.label1"), value: "0" },
                        { label: t("pages.distributions.distributionStepsTab.limitAmountTab.typeOptions.label2"), value: "1" },
                      ]}
                    />
                    {errors["type"] && errors["type"].type === "required" && (
                      <MotifErrorMessage>
                            {t("pages.distributions.distributionStepsTab.limitAmountTab.validationMessages.typeRequired")}
                            </MotifErrorMessage>
                    )}
                  </div>
                  <div className="motif-col-xs-1 motif-col-md-2 motif-col-lg-4">
                    <ControlSelect
                      name={"dataSource"}
                      //@ts-ignore
                      control={control}
                      required={true}
                      showLabel={true}
                      readonly={true}
                      label={"pages.distributions.distributionStepsTab.limitAmountTab.formLabels.datasource"}
                      disabled={false}
                      //@ts-ignore
                      placeholder={"placeholder"}
                      options={[
                        { label:  t("pages.distributions.distributionStepsTab.limitAmountTab.dataSourceOptions.label1"), value: "" },
                        { label: t("pages.distributions.distributionStepsTab.limitAmountTab.dataSourceOptions.label2"), value: "0" },
                        { label: t("pages.distributions.distributionStepsTab.limitAmountTab.dataSourceOptions.label3"), value: "1" },
                        { label: t("pages.distributions.distributionStepsTab.limitAmountTab.dataSourceOptions.label4"), value: "2" },
                      ]}
                    />
                    {errors["dataSource"] &&
                      errors["dataSource"].type === "required" && (
                        <MotifErrorMessage>
                            {t("pages.distributions.distributionStepsTab.limitAmountTab.validationMessages.dataSourceRequired")}
                            </MotifErrorMessage>
                      )}
                  </div>
                  {getDataSource === "1" && (
                    <div className="motif-col-xs-1 motif-col-md-2 motif-col-lg-4">
                      <ControlSelect
                        name={"entitleMentSet"}
                        //@ts-ignore
                        control={control}
                        required={true}
                        showLabel={true}
                        readonly={true}
                        label={"pages.distributions.distributionStepsTab.limitAmountTab.formLabels.entitlement"}
                        //@ts-ignore
                        placeholder={"placeholder"}
                        options={[
                          { label: t("pages.distributions.distributionStepsTab.limitAmountTab.entitlementOptions.label1"), value: "" },
                          { label: t("pages.distributions.distributionStepsTab.limitAmountTab.entitlementOptions.label2"), value: "0" },
                          { label: t("pages.distributions.distributionStepsTab.limitAmountTab.entitlementOptions.label3"), value: "1" },
                        ]}
                      />
                      {errors["entitleMentSet"] &&
                        errors["entitleMentSet"].type === "required" && (
                          <MotifErrorMessage>
                            {t("pages.distributions.distributionStepsTab.limitAmountTab.validationMessages.entitlementRequired")}
                            </MotifErrorMessage>
                        )}
                    </div>
                  )}

                  {getDataSource === "2" && (
                    <div className="motif-col-xs-1 motif-col-md-2 motif-col-lg-4">
                      <ControlSelect
                        name={"manualDataSource"}
                        //@ts-ignore
                        control={control}
                        required={true}
                        showLabel={true}
                        readonly={true}
                        disabled={false}
                        label={"pages.distributions.distributionStepsTab.limitAmountTab.formLabels.manualDataSource"}
                        //@ts-ignore
                        placeholder={"placeholder"}
                        options={manualDataSourceList}
                      />
                      {errors["manualDataSource"] &&
                        errors["manualDataSource"].type === "required" && (
                          <MotifErrorMessage>
                            {t("pages.distributions.distributionStepsTab.limitAmountTab.validationMessages.manualDataSourceRequired")}
                            </MotifErrorMessage>
                        )}
                    </div>
                  )}

                  {getDataSource === "1" && (
                    <>
                      <div
                        className="motif-col-xs-1 motif-col-md-2 motif-col-lg-4"
                        style={
                          getDataSource === "1" ? { marginTop: "20px" } : {}
                        }
                      >
                        <ControlSelect
                          name={"distribution"}
                          //@ts-ignore
                          control={control}
                          required={true}
                          showLabel={true}
                          readonly={true}
                          label={"pages.distributions.distributionStepsTab.limitAmountTab.formLabels.distribution"}
                          //@ts-ignore
                          placeholder={"placeholder"}
                          options={distributionList}
                          disabled={false}
                        />
                        {errors["distribution"] &&
                          errors["distribution"].type === "required" && (
                            <MotifErrorMessage>
                            {t("pages.distributions.distributionStepsTab.limitAmountTab.validationMessages.distributionRequired")}
                            </MotifErrorMessage>
                          )}
                      </div>
                      <div
                        className="motif-col-xs-1 motif-col-md-2 motif-col-lg-4"
                        style={{ marginTop: "20px" }}
                      >
                        <ControlSelect
                          name={"calculationStep"}
                          //@ts-ignore
                          control={control}
                          required={true}
                          showLabel={true}
                          readonly={true}
                          label={"pages.distributions.distributionStepsTab.limitAmountTab.formLabels.calculationStep"}
                          disabled={false}
                          //@ts-ignore
                          placeholder={"placeholder"}
                          options={calculationStepList}
                        />
                        {errors["calculationStep"] &&
                          errors["calculationStep"].type === "required" && (
                            <MotifErrorMessage>
                            {t("pages.distributions.distributionStepsTab.limitAmountTab.validationMessages.caluclationStepRequired")}
                            </MotifErrorMessage>
                          )}
                      </div>
                    </>
                  )}

                  <div
                    className="motif-col-xs-1 motif-col-md-2 motif-col-lg-4"
                    style={
                      getDataSource === "1" || getDataSource === "2"
                        ? { marginTop: "20px" }
                        : {}
                    }
                  >
                    <ControlInputBoxNumber
                      name={"weightingFactor"}
                      // @ts-ignore
                      control={control}
                      required={true}
                      isTextArea={false}
                      label={"pages.distributions.distributionStepsTab.limitAmountTab.formLabels.weightingFactor"}
                      showLabel={true}
                      isNumber={true}
                      digitAfterDecimal={2}
                      isAmount={true}
                      onChangeAmount={() => {}}
                      // validate={isNumeric}
                    />
                    {errors["weightingFactor"] &&
                      errors["weightingFactor"].type === "required" && (
                        <MotifErrorMessage>
                            {t("pages.distributions.distributionStepsTab.limitAmountTab.validationMessages.weighingRequired")}
                            </MotifErrorMessage>
                      )}
                    {errors["weightingFactor"]?.message === "notNumeric" && (
                      <MotifErrorMessage>
                            {t("pages.distributions.distributionStepsTab.limitAmountTab.validationMessages.weighingNumericRequired")}
                            </MotifErrorMessage>
                    )}
                  </div>
                  <div className="motif-col-xs-1 motif-col-md-2 motif-col-lg-4 mar-t-20">
                    <div style={{ position: "relative", top: "10px" }}>
                      <ControlCheckBox
                        id="checkbox-french-selection"
                        onChange={checkBoxHandler}
                        checked={isFrenchSelected}
                        value={isFrenchSelected}
                        name={"filterApplicableClasses"}
                        control={control}
                        required={false}
                        label={t("pages.distributions.distributionStepsTab.limitAmountTab.formLabels.applicable")}
                        setIsFrenchSelected={setIsFrenchSelected}
                      />
                      {errors["filterApplicableClasses"] &&
                        errors["filterApplicableClasses"].type ===
                          "required" && (
                          <MotifErrorMessage>
                            {t("pages.distributions.distributionStepsTab.limitAmountTab.validationMessages.applicableRequired")}
                          </MotifErrorMessage>
                        )}
                    </div>
                    
                  </div>
                </div>
              </div>
            </MotifModalBody>
            <MotifModalFooter>
              <div className="button-container-add">
                <Button label={t("global.globalActions.save")} disabled={isLoading} />
                <Button
                  label={t("global.globalActions.cancel")}
                  type="button"
                  variant={SECONDARY}
                  className="cancelBtn"
                  onClickHandler={() => {
                    setIsVisibleCancel(true);
                    // setCreateShow(false);
                  }}
                />
              </div>
            </MotifModalFooter>
          </div>
        </form>
      </MotifModal>
      <ConfirmationModal
        isVisible={isVisibleCancel}
        setIsVisible={setIsVisibleCancel}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
    </>
  );
}
