import PageSubTitle from "@/components/Form/PageSubTitle/PageSubTitle";
import CreditorSearch from "./Components/CreditorSearch";
import { CreditorSearchData } from "./creditorSlice";
import { useSelector } from "react-redux";
import { useState } from "react";

interface CreditorProps {
  goToNextTab: () => void;
  CreditorID?: string;
  CreditorCaseID?: any;
  CreditorDataFromCreditorTab?: {
    caseId: string;
    creditorId: string;
  };
}

interface RootState {
  claimSetupCreditorSearch: CreditorSearchData;
}

export default function Creditor({
  goToNextTab,
  CreditorID,
  CreditorCaseID,
}: CreditorProps) {

  const selectedCreditorContactDetailsFromStore = useSelector(
    (state: RootState) => {
      return state.claimSetupCreditorSearch.searchCreditorContactDetailsData;
    }
  );

  const CreditorDataFromCreditorTab = {
    caseId: CreditorCaseID,
    creditorId: CreditorID || "",
  };

  const [dataInStore, setDataInStore] = useState(selectedCreditorContactDetailsFromStore.creditorId !== undefined || "" ? true : false);

  return (
    <>
      <div>
        <PageSubTitle
          title={"pages.claims.claimCreate.creditorTab.title"}
          description={"pages.claims.claimCreate.creditorTab.description"}
        />
        <CreditorSearch goToNextTab={goToNextTab} CreditorDataFromCreditorTab={CreditorDataFromCreditorTab} dataInStore={dataInStore} setDataInStore={setDataInStore} />
      </div>
    </>
  );
}  