import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { setForm, setNotification } from "@/features/form-setup/formSetupSlice";
import Sections from "./Sections/Sections";
import Fields from "./Fields/Fields";
import SectionAttributes from "./SectionAttributesPanel/SectionAttributes";
import logger from "@/libs/logger";

import "./formSetup.scss";
import { useFieldArray, useForm } from "react-hook-form";
import { setBlockNavigation } from "@/features/confirmation/confirmation-slice";
import { MotifButton } from "@ey-xd/motif-react";
import SectionConfig from "./SectionConfig/SectionConfig";
import {
  FormSetupFormData,
  mapFormToRequestBody,
  SectionItemData,
  SectionRequestBody,
  useListSectionAttributesQuery,
  useUpdateSectionMutation,
} from "@/services/formSetup/formSetupReducers";


// TODO: embed this into the form management page as a tab
// translation: EY.CCWP.App.Frontend/src/i18n/translations/en/pages.portalConfig.ts
const FormSetup: React.FC = () => {
  const { t } = useTranslation();
  const { caseId, editId: formId } = useParams();
  const { sections } = useSelector((state: any) => state.formSetup);
  const searchString = useLocation().search;
  const searchparams = useMemo(() => {
    return new URLSearchParams(searchString);
  }, [searchString]);
  const sectionId =
    searchparams.get("sectionId") || (sections && sections[0]?.sectionId);
  const formSetup = useSelector((state: any) => state.formSetup);
  const dispatch = useDispatch();
  const { data: fetchedAttributes, refetch: refetchAttributes } =
    useListSectionAttributesQuery(formSetup.sectionId, {
      skip: !formSetup.sectionId,
    });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState,
    setError,
    clearErrors,
    reset,
    //  watch,
  } = useForm<FormSetupFormData>({
    defaultValues: {
      name: sections[sectionId]?.name,
      nameFrench: sections[sectionId]?.nameFrench,
      description: sections[sectionId]?.description,
      columns: sections[sectionId]?.columns,
      sortOrder: sections[sectionId]?.sortOrder,
      mapping: sections[sectionId]?.sectionMapping,
      fields: fetchedAttributes,
    },
  });
  const { fields, append, prepend, remove, swap, move, insert } =
    useFieldArray<any>({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "fields", // unique name for your Field Array
    });
  const { errors, isDirty, isLoading, dirtyFields } = formState;

  const [updateSection, { isLoading: isSavingForm }] =
    useUpdateSectionMutation();

  function onSubmit(data: any) {
    const formData: FormSetupFormData = getValues();
    logger.info("Form Data", formData);

    // TODO: exclude custom display fields in api for now
    const modifiedFormData = {
      ...formData,
      fields: formData.fields.filter((field) => {
        return field.displayName !== "Custom Display Field";
      }),
    };

    const selectedSection = formSetup.sections.find(
      (section: SectionItemData) => {
        return section.id === formSetup.sectionId;
      }
    );
    const requestBody: SectionRequestBody = mapFormToRequestBody(
      modifiedFormData,
      selectedSection
    );
    console.log("requestBody", selectedSection, requestBody);
    updateSection(requestBody)
      .unwrap()
      .then((response) => {
        dispatch(
          setNotification({
            type: "success",
            notifications: {
              message: t(
                "pages.portalConfig.formSetup.messages.sections.save.success"
              ),
            },
          })
        );
        // performs a full page reload for now
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      });
  }

  useEffect(() => {
    if (formId && caseId && formSetup?.formId !== formId) {
      dispatch(setForm({ ...formSetup, formId, caseId, sectionId }));
    }
  }, [formId, caseId, formSetup, dispatch, sectionId]);

  useEffect(() => {
    logger.info("formId", formId);
    logger.info("FormSetup", formSetup);
    logger.info("searchparams", searchparams.toString());
  }, [formSetup, formId, searchparams]);

  useEffect(() => {
    // if touch field is an empty object then reset the form
    if (
      // Object.keys(formState.touchedFields).length > 0 ||
      dirtyFields.fields &&
      dirtyFields?.fields.length > 0
    ) {
      dispatch(setBlockNavigation(true));
    } else {
      dispatch(setBlockNavigation(false));
    }
  }, [formState, dispatch, dirtyFields]);

  return (
    <div className="motif-container form-setup mar-h-0 pad-h-0">
      <div className="motif-row">
        <div className="motif-col-md-3 motif-col-lg-3">
          <Sections reset={reset} />
          <Fields
            append={append}
            prepend={prepend}
            remove={remove}
            swap={swap}
            move={move}
            insert={insert}
            fields={fields}
          />
          <SectionConfig
            control={control}
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            setError={setError}
            errors={errors}
          />
        </div>
        <div className="motif-col-md-9 motif-col-lg-9">
          <SectionAttributes
            setValue={setValue}
            control={control}
            formState={formState}
            append={append}
            prepend={prepend}
            remove={remove}
            swap={swap}
            move={move}
            insert={insert}
            fields={fields}
            fetchedAttributes={fetchedAttributes}
            refetch={refetchAttributes}
          />
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="footer-controls">
          <div className="left">
            <MotifButton type="button" variant="secondary" onClick={() => {}}>
              Preview
            </MotifButton>
          </div>
          <div className="right">
            <MotifButton
              type="button"
              variant="secondary"
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </MotifButton>
            <MotifButton
              type="submit"
              variant="primary"
              loading={isSavingForm}
              disabled={!isDirty || isLoading}
            >
              Save
            </MotifButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormSetup;
