import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IUserState {
  id?: string;
  name?: string;
  email?: string;
  photo?: IUserPhoto | null;
  accessToken?: IMSALAccessToken;
  backendAccessToken?: IMSALAccessToken;
  sessionActive?: boolean;
  session?: {
    sessionStart: string;
    sessionExpiration: string;
  };
}

export interface IUserPhoto {
  data: string;
}

export interface IMSALAccessToken {
  cachedAt: string;
  clientId: string;
  credentialType: string;
  environment: string;
  expiresOn: string;
  extendedExpiresOn: string;
  homeAccountId: string;
  realm: string;
  secret: string;
  target: string;
  tokenType: string;
  Bearer: string;
}

const initialState = {} as IUserState;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state: IUserState, action: PayloadAction<IUserState>) {
      state = action.payload;
      return state;
    },
    setPhoto(state: IUserState, action: PayloadAction<IUserPhoto>) {
      state.photo = action.payload;
      return state;
    },
    updateUser(state: any, action: PayloadAction<{ key: string; value: any }>) {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const { setUser, setPhoto, updateUser } = userSlice.actions;
export default userSlice.reducer;
