import { ADD_PERMISSION, DELETE_PERMISSION, RELATION_TO_DEBTOR_OPTIONS, WRITE_PERMISSION } from "@/Constant";
import { isNumeric } from "./validation";
import { CaseTimeZoneType } from "@/pages/CaseDetail/Configuration/CaseTimeZones/caseTimeZoneType";

export const NumberInMoneyFormat = (amount: number) => {
  if (amount === null || amount === undefined) {
    return "-";
  }
  if (!isNumeric(String(amount))) {
    return amount;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const fomattedAMount = formatter.format(amount);
  return fomattedAMount.replace("$", "");
};

export const NonEmptyString = (value: string | undefined) => {
  return value !== "" && value !== null && value !== undefined;
};

export const isEmpty = (value: string | undefined) => {
  return value === "" || value === null || value === undefined;
};

export const formattedDate = (date: string) => {
  const now = new Date(date);
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getOnlyDate = (date: string) => {
  const now = new Date(date);
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getRelationshipToDebtor = (relationshipToDebtor: number) => {
  return RELATION_TO_DEBTOR_OPTIONS[relationshipToDebtor];
};

function capitalizeFirstLetter(string: any) {
  return (
    string.charAt(0).toUpperCase() +
    string
      .slice(1)
      .replace(/([A-Z])/g, " $1")
      .trim()
      .toLowerCase()
  );
}

export const capitalizeAllWordsFirstChar = (phrase: string) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};



export function capitalizeWordsInSentence(sentence: any) {
  return sentence
    .split(",")
    .map((word: any) => {
      return word.trim();
    })
    .map(capitalizeFirstLetter)
    .join(", ");
}

export function getNumbersAndDecimals(n: string | number) {
  if (n === null || n === undefined || n === "") {
    return 0;
  }
  var decPart = (n + "").split(".")[1];
  return decPart?.length || 0;
}

export const checkAmountDecimalError = (
  value: string,
  decimalLimit: number
) => {
  if (!isNumeric(value)) return true;
  const n = (value + "").split(".");
  if (n.length > 1 && n[1]?.length === 0) {
    return false;
  }
  if(decimalLimit === undefined || decimalLimit === 0)
  return false;
  let regex = new RegExp("^[0-9]+(.[0-9]{1," + decimalLimit + "})?$");
  return !!(value && !regex.test(value));
};
export const checkIntegerError = (value: string) => {
  if (!isNumeric(value)) return true;
  //let regex = new RegExp('^[0-9]$');
  const n = (value + "").split(".");
  return n.length > 1;
};

export const trimString = (value: string) => {
  return value.trim();
};

export const ltrimString = (value: string) => {
  return value.trimStart();
};

export function objectTrim(obj: {}) {
  const newObject = {};
  Object.keys(obj).forEach((key) => {
    // @ts-ignore
    newObject[key] = trimString(obj[key]);
  });
  return newObject;
}

//   console.log(objectMap(myObject, mapFn)); // { a: 4, b: 8, c: 12 }

export function convertDateTimeinDate(getDate: any) {
  const date = new Date(getDate);

  // Define an array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the parts of the date
  const day = date.getDate().toString().padStart(2, "0");
  const month = monthNames[date.getMonth()]; // getMonth() returns 0-11
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  // Combine the parts into the desired format "Dec 08, 2024 hh:mm:ss"
  return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
}

const ZERO_HOURS = 0;
const ZERO_MINUTES = 0;
const ZERO_SECONDS = 0;
const ZERO_MILLISECONDS = 0;

/**
 * Checks if the provided date string is a past date.
 *
 * @param value - The date string to check.
 * @returns boolean - True if the date is in the past, otherwise false.
 */
export function isPastDate(value: string): boolean {
  const selectedDate = new Date(value);
  const today = new Date();
  today.setHours(ZERO_HOURS, ZERO_MINUTES, ZERO_SECONDS, ZERO_MILLISECONDS);
  return selectedDate < today;
}

/**
 * Handles the date validation and sets appropriate error messages and input values.
 *
 * @param value - The input date string.
 * @param setErrorMessage - Function to set the error message.
 * @param setInputValues - Function to set the input values.
 * @param inputValues - The current input values.
 */
export function handleDateError(
  value: string,
  setErrorMessage: (msg: string) => void,
  setInputValues: (values: any) => void,
  inputValues: any
) {
  if (isPastDate(value)) {
    setErrorMessage("You can't use a past date as a deadline");
  } else {
    setErrorMessage("");
    // @ts-ignore
    setInputValues({ ...inputValues, timeLimitDate: value.toISOString() });
  }
}

export function formatDateToDDMMYYYY(dateStr : any): string {

  if(dateStr === null || dateStr === undefined) return "-";

  // Define month names
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  // Split the input date string by "-"
  const parts = dateStr.split('-');

  // Check if the input has three parts
  if (parts.length !== 3) {
    return "";
  }

  // Parse day, month, and year
  const day = parts[0];
  const monthIndex = parseInt(parts[1], 10) - 1; // Convert to 0-indexed month
  const year = parseInt(parts[2], 10);

  // Check for valid day and month
  if (isNaN(monthIndex) || monthIndex < 0 || monthIndex > 11 || isNaN(year)) {
    return "";
  }

  // Convert year to full year format
  const fullYear = year < 100 ? year + 2000 : year;

  // Return the formatted date as DD-MMM-YYYY
  return `${day}-${monthNames[monthIndex]}-${fullYear}`;
}

// Function to format the amount with the corresponding UNIT's decimal place
export const formatAmountUnitDecimalPlace = (
  amount: number | null | undefined,
  decimalPlace: number
) => {
  if (amount === null || amount === undefined) return "-";
  return amount?.toFixed(decimalPlace);
};

export const getStatusDescription = (status: number) => {
  switch (status) {
    case 1:
      return "Unlocked";
    case 2:
      return "Locked";
    case 3:
      return "Committed";
    default:
      return "Unknown";
  }
};

export const formatAmountWithCommas = (
  amount: number | null | undefined,
  decimalPlace: number
) => {
  if (amount === null || amount === undefined) return "";
  const numericAmount = Number(amount);
  if (isNaN(numericAmount)) return "";

  // Split the number into integer and decimal parts
  let [integerPart, decimalPart] = numericAmount.toString().split('.');

  // Add commas to the integer part for thousands, millions, etc.
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Ensure the decimal part has the exact number of digits as specified by decimalPlace
  if (decimalPlace > 0) {
    decimalPart = (decimalPart || '').padEnd(decimalPlace, '0');
    // Combine the two parts
    return `${integerPart}.${decimalPart}`;
  } else {
    return integerPart;
  }
};

export const customComparator = (valueA: string, valueB: string) => {
  console.log("customComparator", valueA, valueB);
  valueA = String(valueA);
  valueB = String(valueB);
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

export const processTreeRows = (treeRows: any[], caseId:string | undefined): any => {
  const rules:any = [];
  const groups:any = [];
  treeRows.forEach((row: any) => {
    if (row.type === "group") {
      const processedGroup = {
        CaseId: caseId,
        rules: row.treeRows.filter((rule: any) => rule.type === "row").map((rule: { type: string; [key: string]: any }) => {
          const { type, ...rest } = rule;
          return rest;
        }),
        groups: null,
        logicalOperator: parseInt(row.logicalOperator),
        pageNumber: 1,
        pageSize: 1000,
      };
      groups.push(processedGroup);
    } else {
      const { type, ...rest } = row;
      rules.push(rest);
    }
  });
  return { rules, groups };
};
export const getPaymentStatus = (status: number) => {
  switch (status) {
    case 0:
      return "Draft";
    case 1:
      return "Issued";
    case 2:
      return "Cashed";
    case 3:
      return "Replaced";
    case 4:
      return "Stop";
    case 5:
      return "Undelivered";
    case 6:
      return "Void";
    default:
      return "-";
  }
};
export const getPaymentPrefix = (prefix: number) => {
  switch (prefix) {
    case 0:
      return "Cheque";
    case 1:
      return "Statement";
    case 2:
      return "Wire";
    default:
      return "-";
  }
};

export const getTimezones = (data:CaseTimeZoneType[],defaultOpt?:any[]) => {
  let timeZonesOptions = defaultOpt?defaultOpt:[{label:"Please Select", value:""}];
  if (!data || data === undefined) return timeZonesOptions; 
  const options =  data.map((timeZone:CaseTimeZoneType) => {
    return { label: timeZone.timeZoneName, value: timeZone.timeZoneId };
  });
  return [...timeZonesOptions, ...options];
}

//this function removes unwanted null instead of empty string
export const cleanPayload = (payload: any) => {
  const cleanedPayload: any = {};
  for (const key in payload) {
    if (payload[key] === null) {
      cleanedPayload[key] = "";
    } else {
      cleanedPayload[key] = payload[key];
    }
  }
  return cleanedPayload;
};

export  const havingEditPermission = (permissions: string[]) => { 
  return permissions.includes(WRITE_PERMISSION) ;
}

export  const havingDeletePermission = (permissions: string[]) => { 
  return permissions.includes(DELETE_PERMISSION) ;
}

export  const havingAddPermission = (permissions: string[]) => { 
  return permissions.includes(ADD_PERMISSION) ;
}