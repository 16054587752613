import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AttributesItemsState {
  attributes: any[];
}

const initialState = {
  attributes: [],
};

export const creditorAttributes = createSlice({
  name: "creditorSetupAttribute",
  initialState,
  reducers: {
    setCreditorAttributes: (state, action: PayloadAction<any>) => {
      state.attributes = action.payload;
    },
  },
});

export const { setCreditorAttributes } = creditorAttributes.actions;

export default creditorAttributes.reducer;