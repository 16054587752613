import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MotifIcon } from "@/libs/@ey-xd/motif-react";
import { contentIcCreate24px } from "@ey-xd/motif-react/assets/icons";

import "./Attributes.css";
import { EntityType, AttributeType } from "./type";

export default function ReviewAttribute() {
  const { t } = useTranslation();
  const attributeData = useSelector((state: any) => state.attribute);

  const renderTypeFields = () => {
    switch (attributeData.attributeType) {
      case AttributeType.FreeText:
        return (
          <>
            <div className="review-attribute-subsection">
              <span className="motif-body2-default-light review-field-name">
                {t("pages.configuration.attributes.createAttribute.format")}
              </span>
              <span className="review-field-value">
                {attributeData.metaDataJSON.format ?? ""}
              </span>
            </div>
            {attributeData.metaDataJSON.format === "None" &&
              <div className="review-attribute-subsection">
                <span className="motif-body2-default-light review-field-name">
                  {t("pages.configuration.attributes.createAttribute.maxCharacter")}
                </span>
                <span className="review-field-value">
                  {attributeData.metaDataJSON.maxCharacter ?? ""}
                </span>
              </div>
            }
          </>
        );
      case AttributeType.SingleSelect:
      case AttributeType.MutliSelect:
        return (
          <>
            <div className="review-attribute-subsection">
              <span className="motif-body2-default-light review-field-name">
                {t("pages.configuration.attributes.createAttribute.value")}
              </span>
              {attributeData.picklistDefinitions.map((x:any) => x.name).join(', ')}
            </div>
            {attributeData.metaDataJSON.default && (
              <div className="review-attribute-subsection">
                <span className="motif-body2-default-light review-field-name">
                  {t("pages.configuration.attributes.createAttribute.defaultOption")}
                </span>
                <span className="review-field-value">
                  {attributeData.metaDataJSON.default ?? ""}
                </span>
              </div>
            )}
          </>
        );
      case AttributeType.Boolean:
        return (
          <>
            {attributeData.metaDataJSON.default && (
              <div className="review-attribute-subsection">
                <span className="motif-body2-default-light review-field-name">
                  {t("pages.configuration.attributes.createAttribute.defaultValue")}
                </span>
                <span className="review-field-value">
                  {attributeData.metaDataJSON.default === "true" ? "Yes" : "No"}
                </span>
              </div>
            )}
          </>
        );
      case AttributeType.Number:
        return (
          <>
            <div className="review-attribute-subsection">
              <span className="motif-body2-default-light review-field-name">
                {t("pages.configuration.attributes.createAttribute.numberFormat")}
              </span>
              <span className="review-field-value">
                {attributeData.metaDataJSON.format ?? ""}
              </span>
            </div>
            <div className="review-attribute-subsection">
              <span className="motif-body2-default-light review-field-name">
                {t("pages.configuration.attributes.createAttribute.minNumber")}
              </span>
              <span className="review-field-value">
                {attributeData.metaDataJSON.minimum ?? ""}
              </span>
            </div>
            <div className="review-attribute-subsection">
              <span className="motif-body2-default-light review-field-name">
                {t("pages.configuration.attributes.createAttribute.maxNumber")}
              </span>
              <span className="review-field-value">
                {attributeData.metaDataJSON.maximum ?? ""}
              </span>
            </div>
          </>
        );
      case AttributeType.Document:
        return (
          <>
            {attributeData.metaDataJSON.fileFormat && (
              <div className="review-attribute-subsection">
                <span className="motif-body2-default-light review-field-name">
                  {t("pages.configuration.attributes.createAttribute.fileFormat")}
                </span>
                <span className="review-field-value">
                  {attributeData.metaDataJSON.fileFormat ?? ""}
                </span>
              </div>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="review-attribute-container">
      <div className="motif-h5-default-regular">
      {EntityType[attributeData.entityType!]}{" "}
        {t("pages.configuration.attributes.createAttribute.title2")}
      </div>
      <div className="attribute-review-subtitle">
        <MotifIcon iconFunction={contentIcCreate24px} stroke="#F3F3F5" />
        {t("pages.configuration.attributes.createAttribute.attributeDetails")}
      </div>
      <div className="review-attribute-section">
        <div className="review-attribute-subsection">
          <span className="motif-body2-default-light review-field-name">
            {t("pages.configuration.attributes.createAttribute.attributeName")}
          </span>
          <span className="review-field-value">{attributeData.name}</span>
        </div>
        <div className="review-attribute-subsection">
          <span className="motif-body2-default-light review-field-name">
            {t("pages.configuration.attributes.createAttribute.attributeDescription")}
          </span>
          <span className="review-field-value">
            {attributeData.description}
          </span>
        </div>
      </div>
      <div className="review-attribute-section">
        <div className="review-attribute-subsection">
          <span className="motif-body2-default-light review-field-name">
            {t("pages.configuration.attributes.createAttribute.attributeType")}
          </span>
          <span className="review-field-value">
            {AttributeType[attributeData.attributeType!]}
          </span>
        </div>
        {renderTypeFields()}
      </div>
    </div>
  );
}
