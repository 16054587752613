import React from 'react';

interface TabPanelContentProps {
    content: React.ReactNode;
}

const TabPanelContent: React.FC<TabPanelContentProps> = ({ content }) => {
  return (
    <div className="tab-panel-content pad-t-20">
      {content}
    </div>
  );
}

export default TabPanelContent;