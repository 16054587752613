import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  MotifLabel,
  MotifFormField,
  MotifInput,
  MotifTextArea,
  MotifSelect,
  MotifOption,
  MotifCheckbox,
  MotifButton,
  MotifIcon,
} from "@/libs/@ey-xd/motif-react";
import { actionIcDelete24px } from "@ey-xd/motif-react/assets/icons";

import "./Attributes.css";
import { EntityType, AttributeType } from "./type";
import { setAttribute } from "./AttributesSlice";

export default function CreateAttributes() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const attributeData = useSelector((state: any) => state.attribute);
  const [selectOptions, setSelectOptions] = useState(
    attributeData.picklistDefinitions
  );
  const isEdit = attributeData.id ? true : false;

  const updateSelectOption = (value: string, index: number) => {
    let options = [...selectOptions];
    let option = {
      ...options[index],
      name: value,
    };
    options[index] = option;
    setSelectOptions(options);
  };

  useEffect(() => {
    dispatch(
      setAttribute({
        ...attributeData,
        picklistDefinitions: selectOptions,
      })
    );
  // eslint-disable-next-line
  }, [selectOptions]);

  const renderTypeFields = () => {
    switch (attributeData.attributeType) {
      case AttributeType.FreeText:
        return (
          <>
            <div className="create-attribute-section">
              <MotifFormField>
                <MotifLabel>
                  {t("pages.configuration.attributes.createAttribute.format")}
                  <span className="required-input">*</span>
                </MotifLabel>
                <MotifSelect
                  onChange={(value) =>
                    dispatch(
                      setAttribute({
                        ...attributeData,
                        metaDataJSON: {
                          ...attributeData.metaDataJSON,
                          format: value,
                        },
                      })
                    )
                  }
                  value={attributeData.metaDataJSON.format ?? undefined}
                  visibleOptions={6}
                  disabled={isEdit}
                >
                  <MotifOption value="SIN">
                    {t("pages.configuration.attributes.createAttribute.sinNumber")}
                  </MotifOption>
                  <MotifOption value="Email">
                    {t("pages.configuration.attributes.createAttribute.email")}
                  </MotifOption>
                  <MotifOption value="Regex">
                    {t(
                      "pages.configuration.attributes.createAttribute.regularExpression"
                    )}
                  </MotifOption>
                  <MotifOption value="Phone">
                    {t("pages.configuration.attributes.createAttribute.telephone")}
                  </MotifOption>
                  <MotifOption value="Postal">
                    {t("pages.configuration.attributes.createAttribute.postalCode")}
                  </MotifOption>
                  <MotifOption value="None">
                    {t("pages.configuration.attributes.createAttribute.none")}
                  </MotifOption>
                </MotifSelect>
              </MotifFormField>
            </div>
            
            {(attributeData.metaDataJSON.format === undefined || attributeData.metaDataJSON.format === "None") &&
              <div className="create-attribute-section">
                <MotifLabel>
                  {t("pages.configuration.attributes.createAttribute.maxCharacter")}
                  <span className="required-input">*</span>
                </MotifLabel>
                <MotifFormField>
                  <MotifInput
                    type="number"
                    onChange={(e) =>
                      dispatch(
                        setAttribute({
                          ...attributeData,
                          metaDataJSON: {
                            ...attributeData.metaDataJSON,
                            maxCharacter: Number(
                              e.target.value.replace(/[^0-9]/g, "")
                            ),
                          },
                        })
                      )
                    }
                    value={attributeData.metaDataJSON.maxCharacter ?? undefined}
                    disabled={isEdit}
                  />
                </MotifFormField>
              </div>
            }

            <div className="create-attribute-section">
              <MotifCheckbox
                onChange={() =>
                  dispatch(
                    setAttribute({
                      ...attributeData,
                      metaDataJSON: {
                        ...attributeData.metaDataJSON,
                        maskInput: !attributeData.metaDataJSON.maskInput,
                      },
                    })
                  )
                }
                checked={attributeData.metaDataJSON.maskInput ?? false}
              >
                {t("pages.configuration.attributes.createAttribute.maskInput")}
              </MotifCheckbox>
            </div>
          </>
        );

      case AttributeType.SingleSelect:
      case AttributeType.MutliSelect:
        return (
          <>
            <div className="create-attribute-section">
              {selectOptions.map((value: any, index: number) => (
                <div key={index}>
                  <MotifLabel>
                    {t("pages.configuration.attributes.createAttribute.value")}
                    <span className="required-input">*</span>
                  </MotifLabel>
                  <MotifFormField>
                    <div className="select-type-option-container">
                      <MotifInput
                        key={index}
                        className="select-type-option"
                        onChange={(event) =>
                          updateSelectOption(event.target.value, index)
                        }
                        value={value.name}
                      />
                      <MotifButton
                        onClick={() =>
                          setSelectOptions(
                            selectOptions.filter(
                              (v: any, i: number) => i !== index
                            )
                          )
                        }
                        variant="primary-alt"
                        disabled={isEdit}
                      >
                        <MotifIcon
                          iconFunction={actionIcDelete24px}
                          size="24"
                        />
                      </MotifButton>
                    </div>
                  </MotifFormField>
                </div>
              ))}
              <MotifButton
                size="small"
                variant="secondary"
                onClick={() =>
                  setSelectOptions([
                    ...selectOptions,
                    {
                      id: undefined,
                      name: "",
                      nameFrench: "",
                      displayOrder: 0,
                    },
                  ])
                }
              >
                {t("pages.configuration.attributes.createAttribute.addValue")}
              </MotifButton>
            </div>
            <div className="create-attribute-section">
              <MotifFormField>
                <MotifLabel>
                  {t("pages.configuration.attributes.createAttribute.defaultOption")}
                </MotifLabel>
                <MotifSelect
                  onChange={(value) => {
                    if (value === attributeData.metaDataJSON.default) {
                      dispatch(
                        setAttribute({
                          ...attributeData,
                          metaDataJSON: {
                            ...attributeData.metaDataJSON,
                            default: undefined,
                          },
                        })
                      )
                    } else {
                      dispatch(
                        setAttribute({
                          ...attributeData,
                          metaDataJSON: {
                            ...attributeData.metaDataJSON,
                            default: value,
                          },
                        })
                      )
                    }
                  }
                  }
                  value={attributeData.metaDataJSON.default ?? undefined}
                  visibleOptions={6}
                >
                  {selectOptions.map((value: any, index: number) => {
                    return value.name !== "" ? (
                      <MotifOption value={value.name} key={index}>
                        {value.name}
                      </MotifOption>
                    ) : (
                      <></>
                    );
                  })}
                </MotifSelect>
              </MotifFormField>
            </div>
          </>
        );
      case AttributeType.Boolean:
        return (
          <>
            <div className="create-attribute-section">
              <MotifFormField>
                <MotifLabel>
                  {t("pages.configuration.attributes.createAttribute.defaultValue")}
                </MotifLabel>
                <MotifSelect
                  onChange={(value) => {
                    if(value === attributeData.metaDataJSON.default) {
                      dispatch(
                        setAttribute({
                          ...attributeData,
                          metaDataJSON: {
                            ...attributeData.metaDataJSON,
                            default: undefined,
                          },
                        })
                      )
                    } else {
                      dispatch(
                        setAttribute({
                          ...attributeData,
                          metaDataJSON: {
                            ...attributeData.metaDataJSON,
                            default: value,
                          },
                        })
                      )
                    }
                  }
                  }
                  value={attributeData.metaDataJSON.default ?? undefined}
                >
                  <MotifOption value="true">
                    {t("pages.configuration.attributes.createAttribute.yes")}
                  </MotifOption>
                  <MotifOption value="false">
                    {t("pages.configuration.attributes.createAttribute.no")}
                  </MotifOption>
                </MotifSelect>
              </MotifFormField>
            </div>
          </>
        );
      case AttributeType.Number:
        return (
          <>
            <div className="create-attribute-section">
              <MotifFormField>
                <MotifLabel>
                  {t("pages.configuration.attributes.createAttribute.numberFormat")}
                  <span className="required-input">*</span>
                </MotifLabel>
                <MotifSelect
                  onChange={(value) =>
                    dispatch(
                      setAttribute({
                        ...attributeData,
                        metaDataJSON: {
                          ...attributeData.metaDataJSON,
                          format: value,
                        },
                      })
                    )
                  }
                  value={attributeData.metaDataJSON.format ?? undefined}
                  disabled={isEdit}
                >
                  <MotifOption value="Integer">
                    {t("pages.configuration.attributes.createAttribute.integer")}
                  </MotifOption>
                  <MotifOption value="Decimal">
                    {t("pages.configuration.attributes.createAttribute.decimal")}
                  </MotifOption>
                </MotifSelect>
              </MotifFormField>
            </div>
            <div className="create-attribute-section">
              <MotifLabel>
                {t("pages.configuration.attributes.createAttribute.minNumber")}
                <span className="required-input">*</span>
              </MotifLabel>
              <MotifFormField>
                <MotifInput
                  type="number"
                  onChange={(e) =>
                    dispatch(
                      setAttribute({
                        ...attributeData,
                        metaDataJSON: {
                          ...attributeData.metaDataJSON,
                          minimum: Number(e.target.value),
                        },
                      })
                    )
                  }
                  value={attributeData.metaDataJSON.minimum ?? undefined}
                />
              </MotifFormField>
            </div>
            <div className="create-attribute-section">
              <MotifLabel>
                {t("pages.configuration.attributes.createAttribute.maxNumber")}
                <span className="required-input">*</span>
              </MotifLabel>
              <MotifFormField>
                <MotifInput
                  type="number"
                  onChange={(e) =>
                    dispatch(
                      setAttribute({
                        ...attributeData,
                        metaDataJSON: {
                          ...attributeData.metaDataJSON,
                          maximum: Number(e.target.value),
                        },
                      })
                    )
                  }
                  value={attributeData.metaDataJSON.maximum ?? undefined}
                />
              </MotifFormField>
            </div>
          </>
        );
      case AttributeType.Document:
        return (
          <>
            <div className="create-attribute-section">
              <MotifLabel>
                {t("pages.configuration.attributes.createAttribute.fileFormat")}
              </MotifLabel>
              <MotifFormField>
                <MotifInput
                  onChange={(e) =>
                    dispatch(
                      setAttribute({
                        ...attributeData,
                        metaDataJSON: {
                          ...attributeData.metaDataJSON,
                          fileFormat: e.target.value,
                        },
                      })
                    )
                  }
                  value={attributeData.metaDataJSON.fileFormat ?? undefined}
                  disabled={isEdit}
                />
              </MotifFormField>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="create-attribute-container">
      <div className="motif-h5-default-regular">
        {t("pages.configuration.attributes.createAttribute.title1")}{" "}
        {EntityType[attributeData.entityType!]}{" "}
        {t("pages.configuration.attributes.createAttribute.title2")}
      </div>
      <div className="create-attribute-section">
        <MotifLabel>
          {t("pages.configuration.attributes.createAttribute.attributeName")}
          <span className="required-input">*</span>
        </MotifLabel>
        <MotifFormField>
          <MotifInput
            onChange={(e) =>
              dispatch(
                setAttribute({
                  ...attributeData,
                  name: e.target.value,
                })
              )
            }
            value={attributeData.name}
          />
        </MotifFormField>
      </div>
      <div className="create-attribute-section">
        <MotifLabel>
          {t("pages.configuration.attributes.createAttribute.attributeDescription")}
        </MotifLabel>
        <MotifFormField>
          <MotifTextArea
            onChange={(e) =>
              dispatch(
                setAttribute({
                  ...attributeData,
                  description: e.target.value,
                })
              )
            }
            value={attributeData.description}
          />
        </MotifFormField>
      </div>
      <div className="create-attribute-section">
        <MotifLabel>
          {t(
            "pages.configuration.attributes.createAttribute.attributeNameFrench"
          )}
        </MotifLabel>
        <MotifFormField>
          <MotifInput
            onChange={(e) =>
              dispatch(
                setAttribute({
                  ...attributeData,
                  nameFrench: e.target.value,
                })
              )
            }
            value={attributeData.nameFrench}
          />
        </MotifFormField>
      </div>
      <div className="create-attribute-section">
        <MotifLabel>
          {t(
            "pages.configuration.attributes.createAttribute.attributeDescriptionFrench"
          )}
        </MotifLabel>
        <MotifFormField>
          <MotifTextArea
            onChange={(e) =>
              dispatch(
                setAttribute({
                  ...attributeData,
                  descriptionFrench: e.target.value,
                })
              )
            }
            value={attributeData.descriptionFrench}
          />
        </MotifFormField>
      </div>
      <div className="create-attribute-section">
        <MotifFormField>
          <MotifLabel>
            {t("pages.configuration.attributes.createAttribute.attributeType")}
            <span className="required-input">*</span>
          </MotifLabel>
          <MotifSelect
            onChange={(value) =>
              dispatch(
                setAttribute({
                  ...attributeData,
                  attributeType: value,
                })
              )
            }
            value={attributeData.attributeType}
            visibleOptions={6}
            disabled={isEdit}
          >
            <MotifOption value={AttributeType.FreeText}>
              {t("pages.configuration.attributes.createAttribute.freeText")}
            </MotifOption>
            <MotifOption value={AttributeType.Date}>
              {t("pages.configuration.attributes.createAttribute.date")}
            </MotifOption>
            <MotifOption value={AttributeType.SingleSelect}>
              {t("pages.configuration.attributes.createAttribute.singleSelect")}
            </MotifOption>
            <MotifOption value={AttributeType.MutliSelect}>
              {t("pages.configuration.attributes.createAttribute.multiSelect")}
            </MotifOption>
            <MotifOption value={AttributeType.Boolean}>
              {t("pages.configuration.attributes.createAttribute.boolean")}
            </MotifOption>
            <MotifOption value={AttributeType.Currency}>
              {t("pages.configuration.attributes.createAttribute.currency")}
            </MotifOption>
            <MotifOption value={AttributeType.Number}>
              {t("pages.configuration.attributes.createAttribute.number")}
            </MotifOption>
            <MotifOption value={AttributeType.Document}>
              {t("pages.configuration.attributes.createAttribute.document")}
            </MotifOption>
          </MotifSelect>
        </MotifFormField>
      </div>

      <div>{renderTypeFields()}</div>

      <div className="create-attribute-section create-attribute-checkboxes">
        <MotifCheckbox
          onChange={() =>
            dispatch(
              setAttribute({
                ...attributeData,
                isRequired: !attributeData.isRequired,
              })
            )
          }
          checked={attributeData.isRequired}
        >
          {t("pages.configuration.attributes.createAttribute.requiredAttribute")}
        </MotifCheckbox>
        <MotifCheckbox
          onChange={() =>
            dispatch(
              setAttribute({
                ...attributeData,
                isDisplayOnCreate: !attributeData.isDisplayOnCreate,
              })
            )
          }
          checked={attributeData.isDisplayOnCreate}
        >
          {t("pages.configuration.attributes.createAttribute.displayAttribute")}
        </MotifCheckbox>
      </div>
    </div>
  );
}
