import PageTitle from "@/components/Form/PageTitle/PageTitle";
import CustomTabPanel from "./CustomTabPanel";
import ClaimItems from "./ClaimItems/ClaimItems";
import UploadDocument from "./UploadDocument/UploadDocument";
import { MotifIcon } from "@ey-xd/motif-react";
import ReviewAndSubmit from "./ReviewAndSubmit/ReviewAndSubmit";
import { useEffect, useState } from "react";
import Description from "./Description/Description";
import Debtor from "./Debtor/Debtor";
import Creditor from "./Creditor/Creditor";
import SelectAssignee from "./SelectAssignee/SelectAssignee";
import Attributes from "./Attributes/Attributes";
import "./ClaimSetup.scss";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import { useLocation } from "react-router-dom";
import Button from "@/components/Form/Button/Button";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import useResetAllClaimItemsStore from "./useResetAllClaimItemsStore";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function ClaimSetup() {
  const location = useLocation();
  const {t}=useTranslation()
  const { caseId } = useParams();
  const navigate = useNavigate();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showReviewAndSubmit, setShowReviewAndSubmit] = useState(false);
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);
  const [stageDoc, setStageDoc] = useState<any>();
  const [files, setFiles] = useState<File[]>([]);
  const CreditorIDFromCreditorClaim = location.state;
  const CreditorID = CreditorIDFromCreditorClaim?.creditorId;
  const CreditorCaseID = CreditorIDFromCreditorClaim?.caseID;
  const DebtorID = CreditorIDFromCreditorClaim?.debtorId;
  const resetAllClaimItemsStore = useResetAllClaimItemsStore();
  const dispatch=useDispatch()

  useEffect(() => {
    if (location.state?.activeTabIndex !== undefined) {
      setActiveTabIndex(location.state.activeTabIndex);
      setShowReviewAndSubmit(false);
    }
  }, [location]);

  const [formSubmissionStatus, setFormSubmissionStatus] = useState({
    details: false,
    debtor: false,
    creditor: false,
    assignee: false,
    claimItems: false,
    claimAttribute: false,
    uploadDocument: false,
  });

  const updateFormSubmissionStatus = (formName: any, status: any) => {
    setFormSubmissionStatus((prevStatus) => ({
      ...prevStatus,
      [formName]: status,
    }));
  };
  const showReviewPage = () => {
    setShowReviewAndSubmit(true);
  };

  const handleBackButtonClick = () => {
    setShowReviewAndSubmit(false);
  };
  let discardDetails = useSelector((e: any) => e.discardChangesDatas.data);

  const handleCancelDiscard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsDiscardVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }))
  };

  const handleConfirmDiscard = (event:any) => {
    event.preventDefault();
        navigate(`/case/${caseId}?activeTab=3`);
    resetAllClaimItemsStore();
    setIsDiscardVisible(false);
  };

  const goToNextTab = () => {
    if (activeTabIndex < tabs.length - 1) {
      setActiveTabIndex((prevIndex) => prevIndex + 1);
      const currentFormName = tabs[activeTabIndex].name;
      updateFormSubmissionStatus(currentFormName, true);
    } else {
      setShowReviewAndSubmit(true);
    }
  };
  const tabs = [
    {
      name: "Details",
      label: t("pages.claims.claimCreate.sideTabPanelDetails.details"),
      component: (
        <Description
          goToNextTab={goToNextTab}
          file={stageDoc}
          setFile={setStageDoc}
        />
      ),
    },
    {
      name: "debtor",
      label: t("pages.claims.claimCreate.sideTabPanelDetails.debtor"),
      component: <Debtor goToNextTab={goToNextTab} DebtorID={DebtorID} />,
    },
    {
      name: "creditor",
      label:t("pages.claims.claimCreate.sideTabPanelDetails.creditor"),
      component: (
        <Creditor
          goToNextTab={goToNextTab}
          CreditorCaseID={CreditorCaseID}
          CreditorID={CreditorID}
        />
      ),
    },
    {
      name: "select-an-assignee",
      label: t("pages.claims.claimCreate.sideTabPanelDetails.assignee"),
      component: <SelectAssignee goToNextTab={goToNextTab} />,
    },
    {
      name: "claim-items",
      label: t("pages.claims.claimCreate.sideTabPanelDetails.claimIteams"),
      component: <ClaimItems goToNextTab={goToNextTab} />,
    },
    {
      name: "claim-attribute",
      label: t("pages.claims.claimCreate.sideTabPanelDetails.claimAttribute"),
      component: <Attributes goToNextTab={goToNextTab} />,
    },
    {
      name: "upload-document",
      label: t("pages.claims.claimCreate.sideTabPanelDetails.uploadDocumnet"),
      component: (
        <UploadDocument
          showSetReviewPage={showReviewPage}
          files={files}
          setFiles={setFiles}
        />
      ),
    },
  ];

  const handleTabChange = (index: number) => {
    setActiveTabIndex(index);
  };

  const breadcrumbItem = [
    {
      text:t("global.globalActions.back"),
      url: "",
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];


  return (
    <>
      {!showReviewAndSubmit && (
        <div
          style={{ cursor: "pointer" }}
          onClick={(e) =>{
            if(discardDetails.editMode){
              setIsDiscardVisible(true);      dispatch(setDiscardEditMode({ editMode: false }));

            }
            else{
              handleConfirmDiscard(e)
            }
        }}
        >
          <Breadcrumb items={breadcrumbItem} />{" "}
        </div>
      )}
      <div className="display-flex align-items-lg-start">
        {showReviewAndSubmit && (
          <Button
            label={t("global.globalActions.back")}
            variant="ghost"
            onClickHandler={handleBackButtonClick}
          />
        )}
      </div>
      <PageTitle title={"pages.claims.claimCreate.title"} description={"pages.claims.claimCreate.description"} />
      {!showReviewAndSubmit && (
        <CustomTabPanel
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          onTabChange={handleTabChange}
          formSubmissionStatus={formSubmissionStatus}
        />
      )}
      {showReviewAndSubmit && (
        <ReviewAndSubmit stageDoc={stageDoc} files={files} />
      )}

      <ConfirmationModal
        isVisible={isDiscardVisible}
        setIsVisible={setIsDiscardVisible}
        cancelBtnApproveHandler={handleConfirmDiscard}
        cancelBtnRejectHandler={handleCancelDiscard}
      />
    </>
  );
}

