import {
  MotifCheckbox,
  MotifErrorMessage,
  MotifIcon,
  MotifIconButton,
  MotifToast,
} from "@ey-xd/motif-react";
import { IconoirTrash } from "@ey-xd/motif-icon";
import PageSubTitle from "@/components/Form/PageSubTitle/PageSubTitle";

import "./ClaimItems.scss";
import { ClaimItem, ClaimSetupClaimItemsType } from "./type";
import { useFieldArray, useForm } from "react-hook-form";
import ControlInputBoxClaimItems from "@/pages/CaseDetail/Claim/claimSetup/ClaimItems/ControlInputBoxClaimItems";
import ControlSelect from "./ControlSelectClaimItems";
import ControlMultiSelect from "@/components/Form/MultiSelectBox/ControlMultiSelect";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import Button from "@/components/Form/Button/Button";
import {
 
  SECONDARY,
} from "@/Constant";
import { setCategorySelectedItems, setClaimItems, setCurrencySelectedItems, resetClaimItemsState, setClaimItemsCompleteDetail } from "./claimItemsSlice";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { ClaimItemsState } from "@/pages/CaseDetail/Claim/claimSetup/ClaimItems/claimItemsSlice";
import { useGetUnitsQuery } from "@/services/unit/unitReducers";
import { useGetCreditorListQuery as useGetAssigneeListDataQuery } from "@/services/creditors/creditorsReducers";
import { useGetClaimCategoryAndNatureOfClaimListQuery } from "@/services/claimCategoryAndNatureOfClaimList/claimCategoryAndNatureOfClaimListReducer";
import { useNavigate, useParams } from "react-router-dom";
import { useCase } from "@/features/case/use-case";
import useResetAllClaimItemsStore from "../useResetAllClaimItemsStore";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

interface RootState {
  claimSetupClaimItems: ClaimItemsState;
}

interface DebtorProps {
  goToNextTab: () => void;
}

interface DropdownOption {
  label: string;
  value: string;
}

interface AssigneeSearchProps {
  creditorId: any;
  creditorName: string;
}

export default function ClaimItems({ goToNextTab }: DebtorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { caseId } = useParams();
  const caseData = useCase();
  const resetAllClaimItemsStore = useResetAllClaimItemsStore();

  const claimItemsData = useSelector(
    (state: RootState) => state.claimSetupClaimItems.claimItems
  );

  const {
    data: claimCategoryAndNatureOfClaimListData,
    isLoading: claimCategoryAndNatureOfClaimListIsLoading,
    error: claimCategoryAndNatureOfClaimListError,
  } = useGetClaimCategoryAndNatureOfClaimListQuery(caseData.caseId);
  const {
    data: assigneeData,
    isLoading: isAssigneeDataLoading,
    error: errorAssigneeDataFetch,
  } = useGetAssigneeListDataQuery(caseData.caseId);
  const {
    data: unitData,
    isLoading: unitIsLoading,
    error: errorUnitDataFetch,
  } = useGetUnitsQuery(caseData.caseId);
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFormInitialized, setIsFormInitialized] = useState(false);

  let defaultCurrency = "";

  if (
    unitData &&
    unitData.length > 0 &&
    !unitIsLoading &&
    !errorUnitDataFetch
  ) {
    const defaultCurrencyItem = unitData?.find(
      (item: { isDefaultCurrency: any }) => item.isDefaultCurrency
    );
    if (defaultCurrencyItem) {
      defaultCurrency = defaultCurrencyItem.name;
    }
  }

  const defaultClaimItems = useMemo(() => {
    return claimItemsData.length > 0
      ? claimItemsData
      : [
        {
          categoryName: "",
          natureOfClaimName: "",
          claimAmount: "",
          currency: defaultCurrency,
          showAssigneeSearch: false,
          assigneeData: "",
        },
      ];
  }, [claimItemsData, defaultCurrency]);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<ClaimSetupClaimItemsType>({
    defaultValues: {
      fieldArray: defaultClaimItems,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fieldArray",
  });
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: true }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isFormInitialized) {
      const updatedClaimItems = defaultClaimItems?.map((item) => ({
        ...item,
        currency: item.currency,
      }));
      reset({ fieldArray: updatedClaimItems });
      setIsFormInitialized(true);
    }
  }, [defaultCurrency, isFormInitialized, reset, defaultClaimItems]);

  const onSubmit = async (data: ClaimSetupClaimItemsType) => {
    dispatch(setClaimItems(data.fieldArray));
    setShowMessage({ ...showMessage, show: true, type: "success" });
    dispatch(
      setCategorySelectedItems({
        selectedCategoryCompleteDetail: selectedCategoryCompleteDetail,
      })
    );
    dispatch(
      setCurrencySelectedItems({
        selectedCurrencyCompleteDetail: selectedCurrencyCompleteDetail,
      })
    );
    dispatch(setClaimItemsCompleteDetail(labelValues));
    goToNextTab();
  };

  const watchFieldArray = watch("fieldArray");

  const watchCategorySelection = watchFieldArray?.map(
    (item: { categoryName: any }) => item.categoryName
  );

  const watchCurrencySelection = watchFieldArray?.map(
    (item: { currency: any }) => item.currency
  );

  const selectedCategoryCompleteDetail =
    claimCategoryAndNatureOfClaimListData?.categories?.filter(
      (category: { claimCategoryName: any }) =>
        watchCategorySelection.includes(category.claimCategoryName)
    );

  const selectedCurrencyCompleteDetail = unitData?.filter(
    (unit: { name: any }) => watchCurrencySelection.includes(unit.name)
  );

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const handleAddNewClaimItem = () => {
    append({
      categoryName: "",
      natureOfClaimName: "",
      claimAmount: "",
      currency: defaultCurrency,
      showAssigneeSearch: false,
      assigneeData: "",
    });
  };

  const handleDeleteClaim = (index: number) => {
    remove(index);
  };

  const handleAddAssignee = (index: number) => {
    const currentValues = getValues(`fieldArray.${index}.showAssigneeSearch`);
    setValue(`fieldArray.${index}.showAssigneeSearch`, !currentValues);
  };

  const handleClaimItemsReset = () => {
    dispatch(resetClaimItemsState());
    resetAllClaimItemsStore();
    setIsModalVisible(false);
    reset({
      fieldArray: [
        {
          categoryName: "",
          natureOfClaimName: "",
          claimAmount: "",
          currency: defaultCurrency,
          showAssigneeSearch: false,
          assigneeData: "",
        },
      ],
    });
    navigate(`/case/${caseId}?activeTab=3`);
  };
  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false });
  };

  const renderMessage = () => {
    if (showMessage.show && !isModalVisible) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={showMessage.type}
        >
          {" "}
          {showMessage.type === "error"
            ? t("global.globalNotifications.unExpectedError")
            : t("global.globalNotifications.updatedSuccessfully")}
        </MotifToast>
      );
    }
  };

  const handleClaimItemsCancel = () => {
    setIsModalVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));

  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let CATEGORY_OPTIONS: DropdownOption[] = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let NATURE_OF_CLAIM_OPTIONS: DropdownOption[] = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let CURRENCY_OPTIONS: { label: string; value: string }[] = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let ASSIGNEE_OPTIONS: any[] = [];

  if (
    claimCategoryAndNatureOfClaimListData &&
    !claimCategoryAndNatureOfClaimListIsLoading &&
    !claimCategoryAndNatureOfClaimListError
  ) {
    CATEGORY_OPTIONS = claimCategoryAndNatureOfClaimListData.categories?.map(
      (item: { claimCategoryId: number; claimCategoryName: string }) => {
        const claimCategoryNameOptions = `${item.claimCategoryName}`;
        const claimCategoryId = item.claimCategoryId;
        return {
          label: claimCategoryNameOptions,
          value: claimCategoryId,
        };
      }
    );
  } else {
    CATEGORY_OPTIONS = [{ label: "", value: "" }];
  }

  if (
    claimCategoryAndNatureOfClaimListData &&
    !claimCategoryAndNatureOfClaimListIsLoading &&
    !claimCategoryAndNatureOfClaimListError
  ) {
    NATURE_OF_CLAIM_OPTIONS =
      claimCategoryAndNatureOfClaimListData.natureOfClaim?.map(
        (item: { natureOfClaim: string }) => {
          const natureOfClaimsOptions = `${item}`;
          return {
            label: natureOfClaimsOptions,
            value: natureOfClaimsOptions,
          };
        }
      );
  } else {
    NATURE_OF_CLAIM_OPTIONS = [{ label: "", value: "" }];
  }


  if (unitData && !unitIsLoading && !errorUnitDataFetch) {
    CURRENCY_OPTIONS = unitData.map(
      (item: { id: number; isDefaultCurrency: boolean; name: string }) => {
        const unitName = item.isDefaultCurrency
          ? `${item.name} (Default)`
          : item.name;
        const unitId = item?.id;
        return {
          label: unitName,
          value: unitId,
        };
      }
    );
  } else {
    CURRENCY_OPTIONS = [{ label: "", value: "" }];
  }

  if (!isAssigneeDataLoading && !errorAssigneeDataFetch && assigneeData) {
    ASSIGNEE_OPTIONS = assigneeData.map((assignee: AssigneeSearchProps) => {
      const assigneeName = `${assignee.creditorName}`;
      const assigneeId = assignee.creditorId;
      return {
        label: assigneeName,
        value: assigneeId,
      };
    });
  }


  const claimAmountDecimalPlaceValidation = (value: number, index: number) => {
    const selectedCurrencyId = getValues(`fieldArray.${index}.currency`);
    const selectedCurrencyData = unitData?.find((unit: { id: string }) => unit.id === selectedCurrencyId);
    const selectedCurrencyName = selectedCurrencyData ? selectedCurrencyData.name : "";
    const decimalPlace = selectedCurrencyData ? selectedCurrencyData.decimalPlace : null;
    if (decimalPlace !== null) {
      const decimalPattern = new RegExp(`^\\d*(\\.\\d{0,${decimalPlace}})?$`);
      return decimalPattern.test(String(value)) || ` ${selectedCurrencyName} allows only ${decimalPlace} decimal place(s)`;
    }
    return true;
  }


  const getDefaultNatureOfClaim = (index: number) => {
    const natureOfClaimName = claimItemsData[index]?.natureOfClaimName;
    return natureOfClaimName ? natureOfClaimName : ["Unsecured"];
  };
  
  const handleNOCChange = (selectedValue: string[], index: number) => {
    
    let selectedOptions = [...selectedValue]; 

    const hasSecured = selectedOptions?.map(value => value.toLowerCase()).includes("secured");
    const hasUnsecured = selectedOptions?.map(value => value.toLowerCase()).includes("unsecured");
  
    if (hasSecured && hasUnsecured) {
      const lastValue = selectedOptions[selectedOptions.length - 1];

      selectedOptions = selectedOptions.filter((value) => {
        if (value === "Secured" && lastValue === "Unsecured") {
          return false;
        } else if (value === "Unsecured" && lastValue === "Secured") {
          return false;
        }
        return true;
      });
    }
    //@ts-ignore
    setValue(`fieldArray.${index}.natureOfClaimName`, selectedOptions, { shouldValidate: true });
  };

  const renderClaimItemRow = (field: ClaimItem, index: number) => {
    return (
      <div className="claim-item pad-v-25" key={field.id}>
        <div className="motif-row align-items-center">
          <div className="motif-col-lg-3">
            <ControlSelect
              name={`fieldArray.${index}.categoryName`}
              control={control}
              showLabel={true}
              label={"pages.claims.claimCreate.cliemItemsTab.formLabelsDetails.category"}
              options={CATEGORY_OPTIONS}
              required={true}
              setValue={setValue}
              getValues={getValues}
            />
            {errors.fieldArray?.[index]?.categoryName?.type === "required" && (
              <MotifErrorMessage>
                {t("pages.claims.claimCreate.cliemItemsTab.validationMessages.categorySelectMessage")}
              </MotifErrorMessage>
            )}
          </div>
          <div className="motif-col-lg-2">
            <ControlMultiSelect
              name={`fieldArray.${index}.natureOfClaimName`}
              control={control}
              showLabel={true}
              label={"pages.claims.claimCreate.cliemItemsTab.formLabelsDetails.claimNature"}
              options={NATURE_OF_CLAIM_OPTIONS}
              required={true}
              setValue={setValue}
              getValues={getValues}
              multiple={true}
              defaultValue={getDefaultNatureOfClaim(index)}              
              // @ts-ignore
              onChange={(selectedValue)=>handleNOCChange(selectedValue, index)}
            />
            {errors.fieldArray?.[index]?.natureOfClaimName?.type === "required" && (
              <MotifErrorMessage>
                {t("pages.claims.claimCreate.cliemItemsTab.validationMessages.claimNatureMessage")}
                </MotifErrorMessage>
            )}
          </div>
          <div className="motif-col-lg-2">
            <ControlInputBoxClaimItems
              name={`fieldArray.${index}.claimAmount`}
              control={control}
              required={true}
              isTextArea={false}
              label={"pages.claims.claimCreate.cliemItemsTab.formLabelsDetails.claimAmount"}
              showLabel={true}
              rules={{
                pattern: {
                  value: /^[0-9]*(\.[0-9]+)?$/,
                },
                validate: {
                  decimalPlaceValidation: (value: number) => claimAmountDecimalPlaceValidation(value, index),
                },
              }}
            />
            {errors.fieldArray?.[index]?.claimAmount?.type === "pattern" && (
              <MotifErrorMessage>
                {t("pages.claims.claimCreate.cliemItemsTab.validationMessages.claimAmountPatter")}
                </MotifErrorMessage>
            )}
            {errors.fieldArray?.[index]?.claimAmount?.type === "required" && (
              <MotifErrorMessage>
                {t("pages.claims.claimCreate.cliemItemsTab.validationMessages.claimAmmountMessage")}
                </MotifErrorMessage>
            )}
            {errors.fieldArray?.[index]?.claimAmount?.type === "decimalPlaceValidation" && (
              <MotifErrorMessage>
                {errors.fieldArray?.[index]?.claimAmount?.message}
              </MotifErrorMessage>
            )}          
          </div>
          <div className="motif-col-lg-2">
            <ControlSelect
              name={`fieldArray.${index}.currency`}
              control={control}
              showLabel={true}
              label={"pages.claims.claimCreate.cliemItemsTab.formLabelsDetails.currency"}
              options={CURRENCY_OPTIONS}
              required={true}
              setValue={setValue}
              getValues={getValues}
            />
            {errors.fieldArray?.[index]?.currency?.type === "required" && (
              <MotifErrorMessage>
                {t("pages.claims.claimCreate.cliemItemsTab.validationMessages.currencyMessage")}
                </MotifErrorMessage>
            )}
          </div>
          <div className="motif-col-lg-2 mar-t-30">
            <MotifCheckbox
              id={`checkbox-assignee-${index}`}
              name={`fieldArray.${index}.showAssigneeSearch`}
              checked={field.showAssigneeSearch}
              onChange={() => handleAddAssignee(index)}
            >
              {t("pages.claims.claimCreate.cliemItemsTab.formLabelsDetails.addAssignee")}
            </MotifCheckbox>
          </div>
          <div className="motif-col-lg-1 mar-t-30">
            {index !== 0 && (
              <MotifIconButton
                type="button"
                aria-label="Delete Claim Item"
                onClick={() => handleDeleteClaim(index)}
              >
                <MotifIcon
                  iconFunction={IconoirTrash}
                  title="Delete Claim Item"
                />
              </MotifIconButton>
            )}
          </div>
        </div>
        {field.showAssigneeSearch && (
          <div className="motif-row mar-t-25">
            <div className="motif-col-lg-12">
              <ControlSelect
                name={`fieldArray.${index}.assigneeData`}
                control={control}
                showLabel={true}
                label={"pages.claims.claimCreate.cliemItemsTab.formLabelsDetails.assigneeSearch"}
                options={ASSIGNEE_OPTIONS}
                setValue={setValue}
                getValues={getValues}
                visibleOptions={3}
                filter={true}
                searchPlaceholder="Search for Assignee..."
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const getLabelFromOptions = (options: DropdownOption[], value: string) => {
    const option = options?.find((option) => option.value === value);
    return option ? option.label : "";
  };

  const labelValues = useMemo(() => {
    return watchFieldArray.map((item: any) => ({
      categoryName: getLabelFromOptions(CATEGORY_OPTIONS, item.categoryName),
      natureOfClaimName: getLabelFromOptions(
        NATURE_OF_CLAIM_OPTIONS,
        item.natureOfClaimName
      ),
      natureOfClaimItems : item.natureOfClaimName,
      claimAmount: item.claimAmount,
      currency: getLabelFromOptions(CURRENCY_OPTIONS, item.currency),
      assigneeData: getLabelFromOptions(ASSIGNEE_OPTIONS, item.assigneeData),
    }));
  }, [
    watchFieldArray,
    CATEGORY_OPTIONS,
    NATURE_OF_CLAIM_OPTIONS,
    CURRENCY_OPTIONS,
    ASSIGNEE_OPTIONS,
  ]);

  if (
    unitIsLoading ||
    claimCategoryAndNatureOfClaimListIsLoading ||
    isAssigneeDataLoading
  ) {
    return <div>{t("global.globalNotifications.loadingMessage")}</div>;
  }

  if (
    errorUnitDataFetch ||
    claimCategoryAndNatureOfClaimListError ||
    errorAssigneeDataFetch
  ) {
    return <div>{t("global.globalNotifications.wentWrong")}</div>;
  }

  return (
    <>
      {renderMessage()}
      <PageSubTitle
        title={"pages.claims.claimCreate.cliemItemsTab.title"}
        description={"pages.claims.claimCreate.cliemItemsTab.description"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        {controlledFields.map((field, index) => {
          return renderClaimItemRow(field, index);
        })}
        <div className="motif-row">
          <div className="motif-col-lg-12">
            <div className="mar-t-25">
              <Button
                label={t("pages.claims.claimCreate.cliemItemsTab.formLabelsDetails.newClaimItem")}
                variant="secondary"
                className="float-left new-claim-item-button"
                onClickHandler={handleAddNewClaimItem}
              />
            </div>
          </div>
        </div>
        <div className="display-flex justify-content-end mar-t-70">
          <div className="pad-r-10">
            <Button
              type="button"
              label={t("global.globalActions.cancel")}
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={() => {setIsModalVisible(true);      dispatch(setDiscardEditMode({ editMode: false }));
            }}
            />
          </div>
          <div className="">
            <Button label={t("global.globalActions.next")} variant="primary" />
          </div>
        </div>
        <ConfirmationModal
          isVisible={isModalVisible}
          setIsVisible={setIsModalVisible}
          cancelBtnApproveHandler={handleClaimItemsReset}
          cancelBtnRejectHandler={handleClaimItemsCancel}
        />
      </form>
    </>
  );
}
