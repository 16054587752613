import {useTranslation} from 'react-i18next';
import SelectBox from "./SelectBox";
import { STATUS_FIELD } from "@/Constant";
import { Control, Controller, FieldValues, Path, PathValue } from "react-hook-form";

type ControlSelectType<T extends FieldValues> = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<T>;
    name:  Path<T>  ;
    showLabel: boolean;
    label: string;
    options: {
        label: string,
        value: string,
    }[];
    setValue:any;
    getValues: any;
    onChangeHandlerCountry: (index: number, countryId: string) => void;
    defaultValue?: string;
    filter?: boolean | ((x?: any) => void) | undefined;
    searchPlaceholder? : string;
}

export default function ControlSelectCountry<T extends FieldValues>({name, control, required=false, options, showLabel, label, setValue, getValues, onChangeHandlerCountry, filter, searchPlaceholder}: ControlSelectType<T>) {
    const {t} = useTranslation();
    const status_initial_value = getValues(STATUS_FIELD);
    if (status_initial_value === undefined || status_initial_value === "" || status_initial_value === null) {
        setValue(STATUS_FIELD, t(options[0].value));
    }
    const translatedOptions = options.map((option: any) => { 
        return {
            label: option.label,
            value: option.value,
        };
    })
    return (
        <> 
            {
                showLabel && <div className="formFieldLabel">
                    <label  htmlFor="input" aria-label={label}>{t(label)}{ required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
           <Controller
                name={name}
                control={control}
                rules={{ required }}
                defaultValue={options[0]?.value as PathValue<T, Path<T>>}
                render={({ field: { onChange, value }}) => {
                        return (
                        <>
                        <SelectBox   options={translatedOptions} value={value} onChange={onChange}  onChangeHandlerCountry={onChangeHandlerCountry} filter={filter} searchPlaceholder={searchPlaceholder}/>
                        </>
                        );
                    }}
            />
        </>
        
    );
}
