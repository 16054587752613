import { j as x } from "../../../jsx-runtime-BwWtxLpl.js";
import s, { useState as a, useEffect as E, useMemo as k } from "react";
import v from "prop-types";
import I from "classnames";
import { MotifVerticalNavigationSubmenu as b } from "./vertical-navigation-submenu.js";
import { useVerticalNavigationContext as j } from "../vertical-navigation-context.js";
const y = ({ className: C = "", children: u }) => {
  const { collapse: e } = j(), [c, n] = a(null), [i, p] = a(!1), [l, m] = a(null);
  E(() => {
    e && n(null);
  }, [e]);
  const d = I({
    "motif-vertical-navigation-menu": !0,
    [C]: !0
  }), g = k(
    () => s.Children.map(u, (t, o) => {
      if (t.type !== b)
        return t;
      const M = o === l, f = o === c, r = !e && i ? !!t.props.selected : f;
      return s.cloneElement(t, {
        onClick: () => {
          i ? p(!1) : l === null && p(!0), n(r ? null : o), t.props.onClick && t.props.onClick();
        },
        onMouseEnter: () => {
          m(o), e && n(r ? null : o);
        },
        onMouseLeave: () => {
          m(null), e && n(null);
        },
        onOutsideClick: () => {
          f && n(null);
        },
        open: e && M || r,
        children: s.Children.map(
          t.props.children,
          (N) => s.cloneElement(N, { child: !0 })
        )
      });
    }),
    [u, c, i, e, l]
  );
  return /* @__PURE__ */ x.jsx("nav", { role: "menubar", className: d, "data-motif-vertical-navigation-menu": !0, children: g });
};
y.propTypes = {
  className: v.string,
  children: v.node.isRequired
};
export {
  y as MotifVerticalNavigationMenu
};
