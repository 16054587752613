import Banner, { BannerElement } from "../Banner/Banner";
import { useSelector } from "react-redux";
import { useCase } from "@/features/case/use-case";
import { useParams } from "react-router-dom";

export const GridBanner = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const thisCase = useCase(caseId, true);
  const { caseData } = useSelector((state: any) => state.case);
  const caseName =
    thisCase?.caseId !== caseData?.caseId ? "-" : caseData?.caseName || "-";
  const caseShortName =
    thisCase?.caseId !== caseData?.caseId
      ? "-"
      : caseData?.caseShortName || "-";
  const caseStatus =
    thisCase?.caseId !== caseData?.caseId ? "-" : caseData?.caseStatus || false;

  const bannerElements: BannerElement[] = [
    {
      label: "pages.caseOverView.bannerDetails.caseShortName",
      // value: data?.caseId || caseData?.caseId || "-",
      value: caseShortName || "-",
      elementKey: "caseID",
    },
    {
      label: "pages.caseOverView.bannerDetails.caseStatus",
      value: caseStatus === true ? "Open" : "Close",
    },
  ];

  return (
    <Banner
      bannerName="pages.caseOverView.bannerDetails.caseName"
      bannerNameValue={caseName}
      elements={bannerElements}
    />
  );
};
