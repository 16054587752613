import ControlInputBox from "@/components/Form/InputBox/ControlInputBox";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function CustomDisplayLabel({
  index,
  control,
  field,
  setValue,
}: {
  index: number;
  control: Control<any>;
  field: any;
  setValue: any;
}) {
  const { t } = useTranslation();
  const { customFieldsDefaults } = useSelector((state: any) => state.formSetup);
  const [focusField, setFocusField] = useState<{
    name: string;
    value: string;
  }>({ name: "", value: "" });

  const setFocus = (e: any) => {
    const fieldname = e.target.getAttribute("name");
    console.log(fieldname, "fieldname");
    setFocusField({ name: fieldname, value: e.target.value });
  };
  function handleEmbededValue(e: any) {
    if (focusField.name === "") {
      return;
    }
    const value = e.target.getAttribute("data-embedkey");
    setFocusField({
      name: focusField.name,
      value: `${focusField.value} {{${value}}} `,
    });
    setValue(focusField.name, `${focusField.value} {{${value}}} `);
  }

  return (
    <>
      <div className="motif-row">
        <div className="motif-col-lg-6">
          <div className="form-field">
            <ControlInputBox
              name={`fields[${index}].description`}
              control={control}
              required={false}
              isTextArea={true}
              onChange={setFocus}
              label={t("pages.portalConfig.labels.textEN")}
              showLabel={true}
            />
            <br />
            <ControlInputBox
              name={`fields[${index}].descriptionFR`}
              control={control}
              required={false}
              isTextArea={true}
              onChange={setFocus}
              label={t("pages.portalConfig.labels.textFR")}
              showLabel={true}
            />
          </div>
        </div>
        <div className="motif-col-lg-6 fields">
          <div className="panel">
            <div className="panel-header">
              <h3>{t("pages.portalConfig.labels.embededdedValue")}</h3>
            </div>
            <div className="panel-body">
              <ul>
                {customFieldsDefaults.map((field: any, index: number) => {
                  // remove all spaces from the attribute name
                  const entity = "entity";
                  const embedKey = field.attributeName.replace(/\s/g, "");

                  return (
                    <li
                      key={index}
                      onClick={handleEmbededValue}
                      data-embedkey={`${entity}.${embedKey}`}
                    >
                      {field.attributeName}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
