import Table from "@/components/Table/table";
import { useColumnBallotDefs} from "./ballotDf";
import { useNavigate, useParams } from "react-router-dom";
import { useGetVotingBallotByCaseIDQuery } from "./votingReducer";
import { MotifButton, MotifProgressLoader } from "@ey-xd/motif-react";
import { useCustomCellRenderers } from "./constant/votingBallotCellconst";
import { useCallback, useEffect, useState } from "react";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import { useTranslation } from "react-i18next";

const TitleComplement = () => {
  const navigate = useNavigate();
  const { caseId } = useParams<{
    caseId: string;
  }>();
  const {t}=useTranslation()
  
  return (
    <MotifButton
      onClick={() => {
        navigate(`/case/${caseId}/voting/create`, {
          state: { caseId },
        });
      }}
      size="small"
      type="submit"
      variant="secondary"
    >
{t("pages.voting.createNewBallot")}    </MotifButton>
  );
};
export default function VotingTab() {
  const navigate = useNavigate();
  const {t}=useTranslation()
  const [isEdit, setIsEdit] = useState(false);
  const { caseId } = useParams<{
    caseId: string;
  }>();
  let tableLoadingData=customTableLoadingData
  const paginationPageSize: number = customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
    const [paginationObj, setPaginationObj] = useState({
      CaseId:caseId,
      PageNumber: 1,
      PageSize: paginationPageSize,
      SearchText: "",
  
      // Type: "quick search",
    });
   
    let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({    })
  
    function handlePaginationData(data:any,columnTypesData:any){
      setPaginationObj({...data})
      setSortedColumnTypes({...columnTypesData})
    }
  
  const { data, isLoading,isFetching,error } = useGetVotingBallotByCaseIDQuery(paginationObj);

  const sortedData = data?.results? data?.results:[]

  const handleEditClick = useCallback(() => {
    setIsEdit(true);
  }, []);
  useEffect(() => {
    if (isEdit) {
      navigate(`/case/${caseId}/voting/edit`);
    }
  }, [isEdit, caseId, navigate]);
  const caseRenderers = useCustomCellRenderers(handleEditClick);
  const columnBallotDefs=useColumnBallotDefs()
  return (
    <>
      {isLoading ? (
        <MotifProgressLoader show variant="default" className="mar-b-20" />
      ) : (
        <div className="mar-b-20">
          <Table
            columnTypes={{...sortedColumnTypes}}
            handlePaginationData={handlePaginationData}
                paginationObj={paginationObj}
            columnDefs={columnBallotDefs}
            cellRenderers={isFetching?[]:sortedData}
            TitleComplement={TitleComplement}
            title={"pages.voting.tableTitle"}
            description={
              "pages.voting.tableDescription"}
            caseRenderers={caseRenderers}
            pagination={false}
            overlayNoRowsTemplate={isFetching?t(tableLoadingData):error?t("global.globalNotifications.wentWrong") : t("global.globalNotifications.noRecords")}

          />
           <CustomPagination
          currentpage={paginationObj.PageNumber}
          pageSize={paginationObj.PageSize}
          disableNext={!data?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
        </div>
      )}
    </>
  );
}
