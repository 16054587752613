import { MotifButton, MotifIcon } from "@ey-xd/motif-react";
import { IconoirTrash } from "@ey-xd/motif-icon";
import { dateFunc } from "@/components/Functions/dateFunc";
import { getPaymentStatus, getPaymentPrefix } from "@/utility/common";

export const getPaymentStatusColumnDefs = (
	handleCellClick: (params: any) => void,
	handleDeleteEvent: (paymentId: string) => void
) => [
	{
		checkboxSelection: true,
		headerCheckboxSelection: true,
		headerCheckboxSelectionFilteredOnly: true,
		width: 50,
		suppressSizeToFit: true,
	},
	{
		field: "paymentReference",
		headerName: "Payment Reference",
		width: 150,
		sortable: true,
		unSortIcon: true,
		type: "paymentReferenceType",
		onCellClicked: handleCellClick,
	},
	{
		field: "payeeName",
		type: "payeeNameType",
		headerName: "Payee Name",
		width: 150,
		sortable: true,
		unSortIcon: true,
		onCellClicked: handleCellClick,
	},
	{
		field: "accountName",
		type: "accountNameType",
		headerName: "Account Name",
		sortable: true,
		unSortIcon: true,
		width: 150,
		onCellClicked: handleCellClick,
	},
	{
		field: "amount",
		type: "amountType",
		headerName: "Amount",
		width: 100,
		headerClass: "align-right",
		cellClass: "align-right",
		sortable: true,
		unSortIcon: true,
		onCellClicked: handleCellClick,
	},
	{
		field: "paymentStatus",
		type: "paymentStatusType",
		headerName: "Payment Status",
		width: 150,
		sortable: true,
		unSortIcon: true,
		cellRenderer: function (params: any) {
			return getPaymentStatus(params.data.paymentStatus);
		},
		onCellClicked: handleCellClick,
	},
	{
		field: "prefix",
		type: "prefixType",
		headerName: "Prefix",
		width: 100,
		cellRenderer: function (params: any) {
			return getPaymentPrefix(params.data.prefix);
		},
		sortable: true,
		unSortIcon: true,
		onCellClicked: handleCellClick,
	},
	{
		field: "replacedPaymentReference",
		type: "replacementIdType",
		headerName: "Replacement ID",
		width: 150,
		sortable: true,
		unSortIcon: true,
		cellRenderer: function (params: any) {
			return params.data.replacedPaymentReference || "-";
		},
		onCellClicked: handleCellClick,
	},
	{
		field: "issueDate",
		type: "issueDateType",
		headerName: "Issue Date",
		width: 150,
		sortable: true,
		unSortIcon: true,
		cellRenderer: function (params: any) {
			return dateFunc(params.data.issueDate).formattedDateOnly;
		},
		onCellClicked: handleCellClick,
	},
	{
		field: "postedDate",
		type: "postedDateType",
		headerName: "Posted Date",
		width: 150,
		sortable: true,
		unSortIcon: true,
		cellRenderer: function (params: any) {
			return dateFunc(params.data.postedDate).formattedDateOnly;
		},
		onCellClicked: handleCellClick,
	},
	{
		field: "statusUpdateDate",
		type: "statusUpdateDateType",
		headerName: "Status Update Date",
		width: 150,
		sortable: true,
		unSortIcon: true,
		cellRenderer: function (params: any) {
			return dateFunc(params.data.statusUpdateDate).formattedDateOnly;
		},
		onCellClicked: handleCellClick,
	},
	{
		field: "action",
		headerName: "",
		width: 100,
		sortable: false,
		cellRenderer: function (params: any) {
			const style = {
				display: params.data.paymentStatus === 0 ? "block" : "none",
			};
			return (
				<div className="motif-row" style={style}>
					<div className="motif-col-lg-6">
						<MotifButton
							size="small"
							variant="primary"
							onClick={() => {
								handleDeleteEvent(params.data.paymentId);
							}}
						>
							<MotifIcon
								iconFunction={IconoirTrash}
								stroke="blue"
								strokeWidth="2"
								size="24"
							/>
						</MotifButton>
					</div>
				</div>
			);
		},
	},
];