import Table from "@/components/Table/table";
import {
  useListCalculationStepsQuery,
} from "@/services/distribution/distributionReducers";
import { MotifButton, MotifProgressLoader } from "@ey-xd/motif-react";
import { Link, useParams } from "react-router-dom";
import { useColumnDistributionSteps } from "./constants/columnDistributionSteps";
import { useNavigate } from "react-router-dom";
import { useCase } from "@/features/case/use-case";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import GenerateDraftCalculation from "../summery/GenerateDraftCalculation";
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import { useState } from "react";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { useTranslation } from "react-i18next";

const TitleComplement: React.FC<{ distributionStatus: number }> = ({ distributionStatus }) => {
  const navigate = useNavigate();
  const {t}=useTranslation()
  const { distributionId, caseId } = useParams<{
    caseId: string;
    distributionId: string;
  }>();
  return (
    <MotifButton
      onClick={() => {
        navigate(
          `/case/${caseId}/distribution/${distributionId}/calculation-step/create`,
          {
            state: { distributionId },
          }
        );
      }}
      size="small"
      type="submit"
      variant="secondary"
      disabled={distributionStatus === 3}
    >
{t("pages.distributions.distributionStepsTab.addNewStep")}    </MotifButton>
  );
};

const DistributionSteps = () => {
  const navigate = useNavigate();
  const caseData = useCase();
  const { distributionId } = useParams();
  const paginationPageSize: number =customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
  const {t}=useTranslation()
  let tableLoadingData=customTableLoadingData
  const [paginationObj, setPaginationObj] = useState({
    distributionId:distributionId,
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",

  });
  const columnDistributionSteps=useColumnDistributionSteps()

  const { data, isLoading,isFetching,error } = useListCalculationStepsQuery(paginationObj
  );
  const distributionStatus = useSelector((state: RootState) => state.distributions.distributionStatus);
  const handleRowClick = (params: any) => {
    navigate(
      `/case/${caseData.caseId}/distribution/${distributionId}/calculation-step/${params.data.id}/step-summary`
    );
  };
  const caseRenderers = {
    stepNameRenderer: function (params: any) {
      return (
        <>
          <Link
            to={`/case/${caseData.caseId}/distribution/${distributionId}/calculation-step/${params.data.id}/step-summary`}
          >
            {params.data.name}
          </Link>
        </>
      );
    },
  };



  return (
    <>
      {isLoading ? (
        <MotifProgressLoader show variant="default" className="mar-b-20" />
      ) : (
        <>
          <div className="mar-b-10">
            <Table
              columnDefs={columnDistributionSteps}
              cellRenderers={isFetching?[]:data?data?.results:[]}
              TitleComplement={() => <TitleComplement distributionStatus={distributionStatus} />}
              caseRenderers={caseRenderers}
              title={"pages.distributions.distributionStepsTab.tableTitle"}
              description={
"pages.distributions.distributionStepsTab.tableDescription"              }
              pagination={false}
              onRowClicked={handleRowClick}
              overlayNoRowsTemplate={isFetching?t(tableLoadingData):error?t("global.globalNotifications.wentWrong")
                : t("global.globalNotifications.noRecords")}

            />

             <CustomPagination
          currentpage={paginationObj.PageNumber}
          pageSize={paginationObj.PageSize}
          disableNext={!data?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
          </div>
          <GenerateDraftCalculation/>

        </>
      )}
    </>
  );
};

export default DistributionSteps;
