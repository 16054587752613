import { Link } from "react-router-dom";

export const useColumnFormClaimDef = () => [
  {
    cellRenderer: (params: any) => (
      <Link to={`./form/edit/` + params?.data?.id}>
        {params?.data?.formName}
      </Link>
    ),
    comparator: () => 0,
    field: "formName",
    type: "formNameType",
    headerName: "Form Name",
    sortable: true,
    unSortIcon: true,
    width: "150px",
    cellClass: "align-left",
    headerClass: "align-left",
  },
  {
    comparator: () => 0,
    field: "formType",
    type: "formTypeType",
    cellRenderer: "formType",
    headerName: "Form Type",
    flex: 1,
    cellClass: "align-left",
    headerClass: "align-left",
    sortable: true,
    unSortIcon: true,
  },

  {
    cellRenderer: "status",
    comparator: () => 0,
    field: "status",
    type: "statusType",
    headerName: "Status",
    flex: 1,
    cellClass: "align-left",
    headerClass: "align-left",
    sortable: true,
    unSortIcon: true,
  },
  {
    cellRenderer: "description",
    comparator: () => 0,
    field: "description",
    type: "descriptionType",
    headerName: "Description ",
    flex: 1,
    sortable: true,
    unSortIcon: true,
  },
  {
    cellRenderer: "dueDate",
    comparator: () => 0,
    field: "dueDate",
    type: "dueDateType",
    headerName: "Due Date",
    flex: 1,
    cellClass: "align-left",
    headerClass: "align-left",
    sortable: true,
    unSortIcon: true,
  },

  {
    cellRenderer: "publishedDate",
    comparator: () => 0,
    field: "publishedDate",
    type: "publishedDateType",
    headerName: "Published Date",
    flex: 1,
    cellClass: "align-left",
    headerClass: "align-left",
    sortable: true,
    unSortIcon: true,
  },
  {
    cellRenderer: "actionCellRenderers",
    comparator: () => 0,
    field: "action",
    headerName: "",
    flex: 1,
    sortable: false,
  },
];
