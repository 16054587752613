import React, { useEffect, useState } from "react";
import {
  DISTRIBUTION_DESCRIPTION_ID,
  DISTRIBUTION_DESCRIPTION_ID_FRENCH,
  DISTRIBUTION_ID,
  DISTRIBUTION_ID_FRENCH,

} from "../../constants/Constants";
import { DistributionSetupFormType } from "../../constants/type";
import { useForm } from "react-hook-form";
import ControlInputBox from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import Button from "@/components/Form/Button/Button";
import { SECONDARY } from "@/Constant";
import { useTranslation } from "react-i18next";
import { MotifErrorMessage, MotifToast } from "@ey-xd/motif-react";
import { useParams } from "react-router-dom";
import ControlSelect from "@/components/Form/SelectBox/ControlSelect";
import { useUpdateDistributionMutation } from "@/services/distribution/distributionReducers";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import FormattedDatePicker from "@/components/FormattedDatePicker/FormattedDatePicker";
import "./DistributionEditForm.scss";import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

//@ts-ignore
const DistributionEditForm = ({ distributionData,setIsEdit,setNotification,notification,refetch}) => {
  const [isVisible, setIsVisible] = useState(false);
  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
    setIsEdit(false);
    setNotification({
      ...notification,
      ...notification,
      show: false,
      message: "",
      type: "success",
    });
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
    dispatch(setDiscardEditMode({ editMode: true }));

  };

  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors,isDirty },
  } = useForm<DistributionSetupFormType, any>({
    defaultValues: {
      name: distributionData.name || "",
      nameFrench: distributionData.nameFrench || "",
      description: distributionData.description || "",
      descriptionFrench: distributionData.descriptionFrench || "",
      distributionStatus: distributionData?.distributionStatus.toString(),
      distributionDate:distributionData?.distributionDate
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });
  const { caseId, distributionId } = useParams<{
    caseId: string;
    distributionId: string;
  }>();
  const payableOptions = [
    { value: "1", label: t("pages.distributions.distribution.statusOptions.unlocked") },
    { value: "2", label: t("pages.distributions.distribution.statusOptions.locked") },
    { value: "3", label: t("pages.distributions.distribution.statusOptions.committed") },
  ];
  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };
  const renderMessage = () => {
    if (showMessage.show) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={showMessage.type}
        >
          {" "}
          {showMessage.type === "error"
            ? t(showMessage.message)
            : t("global.globalNotifications.updatedSuccessfully")}
        </MotifToast>
      );
    }
  };
  const dispatch=useDispatch()
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,isDirty]);
  const [updateDistribution] = useUpdateDistributionMutation();
  const onSubmit = async (data: DistributionSetupFormType) => {
    setIsLoading(true);
    const requestBody = {
      caseId,
      id: distributionId,
      name: data.name,
      description: data.description,
      nameFrench: data.nameFrench,
      descriptionFrench: data.descriptionFrench,
      distributionStatus: Number(data.distributionStatus),
      distributionDate: data?.distributionDate?data.distributionDate:null,
    };
    dispatch(setDiscardEditMode({ editMode: false }));

    await updateDistribution(requestBody).then((response: any) => {
      if (response.data) {
        setNotification({
          ...notification,
          show: true,
          message: response.data.message,
          type: "success",
        });
        setIsEdit(false);
        refetch();
      } else if (response.error) {
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          message:
            response.error.data.message || "global.globalNotifications.unExpectedError",
        });
        setIsLoading(false);
        dispatch(setDiscardEditMode({ editMode: true }));

      }
    });
  };
  function handleChange(value: any) {
    var selectedDate = new Date(value);
    setValue("distributionDate",`${selectedDate.getFullYear()}-${selectedDate.getMonth()+1}-${selectedDate.getDate()?.toString()?.padStart(2,`0`)}`,{shouldDirty:true});
  }
  return (
    <>
      {renderMessage()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-container case-summary-form pad-v-20 mar-t-20">
          <div className="motif-row justify-content-between align-items-center">
            <div className="motif-col-4">
              <div className="motif-body1-default-regular page-title">
{t("pages.distributions.distribution.distributionEditPage.title")}              </div>
              <div className="motif-body2-default-regular page-description">
              {t("pages.distributions.distribution.distributionEditPage.description")}              
              </div>
            </div>
          </div>
          <div className="motif-row pad-v-20">
            <div className="motif-col-lg-5 ">
              <div className="form-field ">
                <ControlInputBox
                  name={DISTRIBUTION_ID}
                  control={control}
                  required={true}
                  isTextArea={false}
                label={"pages.distributions.distribution.distributionFormLabels.distributionName"}
                  showLabel={true}
                />
                {errors[DISTRIBUTION_ID] &&
                  errors[DISTRIBUTION_ID].type === "required" && (
                    <MotifErrorMessage>
{t("pages.distributions.distribution.validationMessages.distributionNameReq")}
                    </MotifErrorMessage>
                  )}
              </div>
            </div>
            <div className="motif-col-lg-5">
              <div className="form-field">
                <ControlInputBox
                  name={DISTRIBUTION_ID_FRENCH}
                  control={control}
                  isTextArea={false}
                  label={"pages.distributions.distribution.distributionFormLabels.distributionNameFrench"}
                  showLabel={true}
                />
              </div>
            </div>
          </div>

          <div className="motif-row next-row">
            <div className="motif-col-lg-5">
              <div className="form-field">
                <ControlTextAreaWithCharLimit
                  name={DISTRIBUTION_DESCRIPTION_ID}
                  control={control}
                  isTextArea={true}
                  label={"pages.distributions.distribution.distributionFormLabels.description"}
                  showLabel={true}
                />
              </div>
            </div>
            <div className="motif-col-lg-5">
              <div className="form-field ">
                <ControlTextAreaWithCharLimit
                  name={DISTRIBUTION_DESCRIPTION_ID_FRENCH}
                  control={control}
                  isTextArea={true}
                  label={"pages.distributions.distribution.distributionFormLabels.descriptionFrench"}
                  showLabel={true}
                />
              </div>
            </div>

            <div className="motif-col-lg-5">
              <div className="form-field">
                <ControlSelect
                  name={"distributionStatus"}
                  //@ts-ignore
                  control={control}
                  showLabel={true}
                  label={t("pages.distributions.distribution.distributionFormLabels.status")}
                  options={payableOptions}
                  required={true}
                  //@ts-ignore
                  setValue={()=>{}}
                  //@ts-ignore
                  getValues={getValues}
                  value={getValues("distributionStatus")}
                />
              </div>
            </div>
            <div className="motif-col-lg-5 ">
              <div className="form-field">
              <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={"Distribution Date"}>
                        {t("pages.distributions.distribution.distributionFormLabels.distributionDate")}
                        {/* {required ? <span className="requiredSymbol">*</span> : ''} */}
                    </label>
                </div>
        <FormattedDatePicker
            onChange={(value) =>{
              handleChange(value)
            }
            }
            value={
               watch("distributionDate")
                ? new Date( watch("distributionDate")+"")
                : undefined
            }
            className="distributionFormatedDatePicker"

          />
              </div>
            </div>
          </div>
        </div>
        <div className="button-container">
          <Button
            label={t("global.globalActions.save")}
            type={isLoading ? "loader" : "submit"}
            // disabled={isLoading}
          />
          <Button
            type="button"
            label={t("global.globalActions.cancel")}
            variant={SECONDARY}
            className="cancelBtn"
            onClickHandler={(e) => {
              if(isDirty){
              setIsVisible(true);
              setShowMessage({ ...showMessage, show: false, type: "error" });
              dispatch(setDiscardEditMode({ editMode: false }));
              }
              else{
                cancelBtnApproveHandler(e)
              }
            }}
          />
        </div>
      </form>

      <ConfirmationModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
    </>
  );
};

export default DistributionEditForm;
