import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClaimSetupCreditorFormType, ClaimSetupNewCreditorFormType } from './type';

type CountryStateType = {
  countryName : string;
  stateName : string;
}

export interface CreditorSearchData {
  searchCreditorContactDetailsData: ClaimSetupCreditorFormType;
  searchCreditorValue: {
    creditorItem: string;
  };
  selectedCreditorCompleteDetail: any;
  selectedCreditorContactID: any;
  isNewCreditor: boolean;
  newCreditorDetail: ClaimSetupNewCreditorFormType;
  selectedCountryState: CountryStateType;
}

const initialState: CreditorSearchData = {
  searchCreditorContactDetailsData: {
    creditorContactId: "",
    creditorId: "",
    firstName: "",
    lastName: "",
    prefix: "",
    address1: "",
    address2: "",
    city: "",
    provinceStateId: null,
    provinceState: "",
    postalCode: "",
    countryId: undefined,
    country: "",
    phone: "",
    fax: "",
    email: "",
    relationshipToCreditor: "",
    isPrimaryContact: false,
    selectAContact: "",
  },
  searchCreditorValue: {
    creditorItem: "",
  },
  selectedCreditorCompleteDetail: "",
  selectedCreditorContactID: 0,
  isNewCreditor: false,
  newCreditorDetail: {
    isOrg: false,
    prefix: "",
    firstName: "",
    lastName: "",
    businessName: "",
    description: "",
    relationshipToDebtor: "",
    contact: {
      firstName: "",
      lastName: "",
      prefix: "",
      address1: "",
      address2: "",
      city: "",
      provinceStateId: null,
      provinceState: "",
      postalCode: "",
      countryId: undefined,
      country: "",
      phone: "",
      fax: "",
      email: "",
      relationshipToCreditor: "",
    },
  },
  selectedCountryState: { countryName: "", stateName: "" },
};

export const claimSetupCreditorSearch = createSlice({
  name: "claimSetupCreditorSearch",
  initialState,
  reducers: {
    setClaimSetupCreditorSelectedValue: (
      state,
      action: PayloadAction<{ creditorItem: string }>
    ) => {
      state.searchCreditorValue = action.payload;
    },
    setSelectedCreditorCompleteDetail: (state, action: PayloadAction<any>) => {
      state.selectedCreditorCompleteDetail = action.payload;
    },
    setSelectedCreditorContactID: (state, action: PayloadAction<any>) => {
      state.selectedCreditorContactID = action.payload;
    },
    setSelectedCountryState: (
      state,
      action: PayloadAction<CountryStateType>
    ) => {
      state.selectedCountryState = action.payload;
    },
    setClaimSetupCreditorContactDetailsData: (
      state,
      action: PayloadAction<ClaimSetupCreditorFormType>
    ) => {
      state.searchCreditorContactDetailsData = action.payload;
    },
    resetCreditorState: (state) => {
      state.searchCreditorContactDetailsData =
        initialState.searchCreditorContactDetailsData;
      state.searchCreditorValue = initialState.searchCreditorValue;
      state.selectedCountryState = initialState.selectedCountryState;
      state.selectedCreditorCompleteDetail =
        initialState.selectedCreditorCompleteDetail;
      state.selectedCreditorContactID = initialState.selectedCreditorContactID;
      state.isNewCreditor = false;
      state.newCreditorDetail = initialState.newCreditorDetail;
    },
    setIsNewCreditorType: (state, action: PayloadAction<any>) => {
      state.isNewCreditor = action.payload;
    },
    setNewCreditorDetail: (state, action: PayloadAction<any>) => {
      state.newCreditorDetail = action.payload;
    },
    resetNewCreditorDetail: (state) => {
      state.newCreditorDetail = initialState.newCreditorDetail;
    },
  },
});

export const {
  setClaimSetupCreditorSelectedValue,
  setSelectedCreditorCompleteDetail,
  setSelectedCreditorContactID,
  setClaimSetupCreditorContactDetailsData,
  setSelectedCountryState,
  resetCreditorState,
  setIsNewCreditorType,
  setNewCreditorDetail,
  resetNewCreditorDetail,
} = claimSetupCreditorSearch.actions;

export default claimSetupCreditorSearch.reducer;
