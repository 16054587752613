import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Banner, { BannerElement } from "@/components/Banner/Banner";
import {  MotifFormField, MotifIcon, MotifOption, MotifSelect, MotifTable } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import { useParams } from "react-router-dom";
import { columnImportSummaryDefs } from "./component/ImportSummaryColumnDef";
import {
  useGetImportSummaryHeaderDataQuery,
  useGetReadImportSummaryListDataQuery,
} from "./ImportandExportReducers";
import { useEffect, useState } from "react";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { useTranslation } from "react-i18next";
import { MotifChip } from "@/libs/@ey-xd/motif-react/main";
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";

import { dateFunc } from "@/components/Functions/dateFunc";
import ErrorMessage from "../Distributions/ManualDataSource/ErrorMessage/ErrorMessage";

export default function ImportSummary() {
  const { caseId, importJobId } = useParams();
  const paginationPageSize = customPaginationPageSize;
  const { t } = useTranslation();

  const paginationPageSizeSelector = customPaginationPageSizeSelector;
  let tableLoadingData=customTableLoadingData

const filterOptions=[
  {
    value:2,
    name:"Successful Records"
  },
  {
    value:1,
    name:"Failed Records"
  }
]
  const [paginationObj, setPaginationObj] = useState({
    ImportJobId: importJobId,
    currentPage: 1,
    pageSize: paginationPageSize,
    searchText: "",
    sortOrder: "asc",
    sortColumn: "caseId",
    isStatusFilter: false,
    isSuccess: true,
  });
  const { data: HeaderData } = useGetImportSummaryHeaderDataQuery(importJobId);
  const { data, isLoading,isFetching, refetch,error } =
    useGetReadImportSummaryListDataQuery(paginationObj);

  const headerData = {
    title: "Import Records",
    description: "Descriptive text that describes your page or content.",
  };
  let datarow=data?.results.length

 let showFailedScreen=HeaderData?.errorMessage && !isFetching && !isLoading && datarow===0 && paginationObj.currentPage===1 && !paginationObj.isStatusFilter
  const bannerElement: BannerElement[] = [
    {
      label: "Document File Name",
      value: HeaderData?.fileName ? String(HeaderData.fileName) : "-",
      elementKey: "caseID",
    },
    {
      label: showFailedScreen?"Status":"Entity Type",
      value: showFailedScreen?"Failed":HeaderData?.entityType
        ? HeaderData.entityType === 1
          ? "Creditor"
          : HeaderData.entityType === 2
          ? "Claim"
          : HeaderData.entityType === 3
          ? "Claim Item"
          : HeaderData.entityType === 4
          ? "Creditor Votes"
          : HeaderData.entityType === 5
          ? "Manual Data Source"
          : "-"
        : "-",
      elementKey: "caseID",
    },
    {
      label: "Created On",
      value: HeaderData?.createdOn
        ? dateFunc(HeaderData?.createdOn).formattedDateOnly
        : "-",
      elementKey: "caseID",
    },
  ];
  const breadcrumbItems = [
    {
      label: "External Submission",
      value: "10 New Creditor Submission",
      elementKey: "submission",
      text: "Back",
      url: `/case/${caseId}/?activeTab=7`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];
  let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({
  })

  function handlePaginationData(data:any,columnTypesData:any){
    setPaginationObj({...data})
    setSortedColumnTypes({...columnTypesData})
  }
  function handleMotifSorting(e:any,paginationObj:any){
    let sortedColumnsLength:any=e?.columns?e.columns.length:""
    if(sortedColumnsLength!==""){
  let coumnName=e?.columns?.[sortedColumnsLength-1]?.colId
  let sortName=e?.columns?.[sortedColumnsLength-1]?.sort
  let typeName=e?.columns?.[sortedColumnsLength-1]?.colDef?.type
    let paginationJson:any={...paginationObj}
    let columnTypesCustom:any={}
    if(typeName){
      columnTypesCustom[typeName]={sort:sortName}
    }
    paginationJson.SortOrder=sortName
    paginationJson.SortColumn=coumnName?.charAt(0)?.toUpperCase() + coumnName?.slice(1) 
    if(!sortName){
     delete paginationJson.SortOrder 
     delete paginationJson.SortColumn
    }
    if(e?.columns[0]?.colDef?.field){ 
    if(handlePaginationData){
    handlePaginationData(paginationJson,columnTypesCustom)
    }
  }
}
   
}
  let resetSortStatus=()=>{
    let json:any={...sortedColumnTypes}
    for (const key in json) {
                  json[key]={sort:null}
    }
    setSortedColumnTypes({...json})
  }
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading) {
    return <div> ...Loading </div>;
  }

  function handleTypeOfRecors(e: any) {
    setPaginationObj({
      ImportJobId: importJobId,
      currentPage: 1,
      pageSize: paginationPageSize,
      searchText: "",
      sortOrder: "asc",
      sortColumn: "",
      isStatusFilter: e ? true : false,
      isSuccess: e === 1 ? false : true,
    });
    resetSortStatus()
  }

  return (
    <>
      <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
      <Banner
        bannerName={" Type"}
        bannerNameValue={HeaderData?.type}
        elements={bannerElement}
      />
      <div>
      {(showFailedScreen)?<ErrorMessage errorMessage={HeaderData?.errorMessage?HeaderData?.errorMessage:"Someting Went wrong while uploading"} />:

        <div className="mar-b-50" style={  data?.results?.length===0 || !data || isFetching ?{ marginTop: "50px",height:"180px" }:{ marginTop: "50px" }}>
          <MotifTable
            columnTypes={{...sortedColumnTypes}}
            onSortChanged={(e:any) => {
              handleMotifSorting(e,paginationObj)
          
            }}
            paginateChildRows={true}
            columnDefs={columnImportSummaryDefs}
            rowData={isFetching?[]:data?data.results:[]}
            titleComplement={ (

              <div style={{width:"50%",display:"flex",justifyContent:"flex-end"}}>
                            

               {paginationObj.isStatusFilter ? (
                  <MotifChip
                    onClick={() => {
                      setPaginationObj({
                        ImportJobId: importJobId,
                        currentPage: 1,
                        pageSize: paginationPageSize,
                        searchText: "",
                        sortOrder: "asc",
                        sortColumn: "",
                        isStatusFilter: false,
                        isSuccess: true,
                      });
                    }}
                    title="Reset Filter"
                    variant="monochrome-dark"
                  >
                    Reset Filter
                  </MotifChip>
                ) : (
                  <></>
                )}
            
                 
                <MotifFormField className="motif-col-md-5" style={{paddingRight:'0px'}}>
                  <MotifSelect
                    aria-label={t("Select Role")}
                    placeholder={t("Status Filter")}
                    visibleOptions={3}
                    onChange={(e) => handleTypeOfRecors(e)}
                    value={
                      paginationObj.isStatusFilter
                        ? paginationObj.isSuccess
                          ? 2
                          : 1
                        : ""
                    }
                    triggerButtonProps={{ type: "button" }}
                  >
                    {filterOptions.map((option) => (
                      <MotifOption key={option.value} value={option.value}>
                        {option.name}
                      </MotifOption>
                    ))}
                  </MotifSelect>
                </MotifFormField>

               
              </div>
  )}
            title={headerData.title}
            description={headerData.description}
            components={[]}
            overlayNoRowsTemplate={isFetching?tableLoadingData:error?"Something Went Wrong":"No records found"}

            // pagination={true}
          />
           <CustomPagination
          currentpage={paginationObj.currentPage}
          pageSize={paginationObj.pageSize}
          disableNext={!data?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              pageSize: page,
              currentPage: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              currentPage: paginationObj.currentPage + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              currentPage: paginationObj.currentPage - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />

        </div>}
      </div>
    </>
  );
}
