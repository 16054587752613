import ControlSelect from "@/components/Form/SelectBox/ControlSelect";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { VotingEditFormType } from "./type";

type Props = {
  control: any;
  options: { label: string; value: string }[];
  name: string;
  label: string;
  disabled?: boolean;
  required: boolean;
  setValue: UseFormSetValue<VotingEditFormType>;
  getValues: UseFormGetValues<VotingEditFormType>;
};

function ChildDropdown({
  name,
  label,
  control,
  options,
  required,
  disabled,
  setValue,
  getValues,
}: Props) {
  const { t } = useTranslation();
  return (
    <ControlSelect
      // @ts-ignore
      name={name}
      control={control}
      showLabel={true}
      label={t(label)}
      options={options}
      required={required}
      //@ts-ignore
      getValues={getValues}
      //@ts-ignore
      setValue={setValue}
      disabled={disabled}
    />
  );
}

export default ChildDropdown;
