export const FIRSTNAME_LABEL = "First Name";
export const FIRSTN_NAME = "firstName";
export const LASTNAME_LABEL = "Last Name";
export const LASTN_NAME = "lastName";
export const ADDRESSONE_LABEL = "Adress 1";
export const ADDRESSONE_NAME = "address1";
export const ADDRESSTWO_LABEL = "Adress 2";
export const ADDRESSTWO_NAME = "address2";
export const CITY_LABEL = "City";
export const CITY_NAME = "city";
// export const PROVINCE_LABEL = "Province/State";
// export const PROVINCE_NAME = "provinceName";
export const POSTAL_LABEL = "Postal/ZIP Code";
export const POSTAL_NAME = "postalCode";
export const PHONE_LABEL = "Phone";
export const PHONE_NAME = "phone";
export const EMAIL_LABEL = "Email";
export const EMAIL_NAME = "email";
export const PREFIX_LABEL = "Prefix";
export const PREFIX_NAME = "prefix";
export const FAX_LABEL = "Fax";
export const FAX_NAME = "fax";
export const CREDITORCONTACTID ="creditorContactId";
export const CREDITORID ="creditortId";
export const CREDITORID_LABEL ="Creditort Id";
export const PROVINCESTATEID = "provinceStateId";
export const COUNTRYID = "countryId";
export const RELATIONSHIPTOCREDITOR = "relationshipToCreditor";
export const DISPLAY_NAME_LABEL = "Display Name";
