import { useTranslation } from "react-i18next";
import {
  MotifButton,
  MotifDropdown,
  MotifDropdownItem,
  MotifFormField,
  MotifIcon,
} from "@ey-xd/motif-react";
import { IconoirMoreVert } from "@ey-xd/motif-icon";
import { useNavigate, useParams } from "react-router-dom";
import { SetStateAction, useCallback, useState } from "react";
import SearchInput from "@/components/SearchInput/SearchInput";
import { useLazyExportDocumentQuery } from "@/services/exports/exportsReducer";

interface TitleComplementProps {
  caseId: string;
  onSearch: (searchValue: string) => void;
  searchQuery: string;
  handleQuickSearchClear: () => void;
  isBasicSearch: boolean;
  handleClearBasicSearch: () => void;
}

export default function TitleComplement({
  onSearch,
  caseId,
  handleQuickSearchClear,
  searchQuery,
}: TitleComplementProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { creditorId } = useParams();

  const [searchValue, setSearchValue] = useState("");

  const creditorTitleMenu = [
    // "pages.creditors.creditorClaimTab.moreOptions.bulkUpload", // commented for later use
    "pages.creditors.creditorClaimTab.moreOptions.addNewClaim",
    // "pages.creditors.creditorClaimTab.moreOptions.bulkStatusUpdate",  // commented for later use
    "pages.creditors.creditorClaimTab.moreOptions.export",

  ];

  const propData = {
    creditorId: creditorId || "",
    caseID: caseId || "",
  };
  const [exportDocument] =
    useLazyExportDocumentQuery();
  const handleMenuClick = async( caseId: string,indexNo:number) => {
    if(indexNo === 1) {
    await exportDocument({
      caseId,
      url: "/Creditor/ExportCreditorClaimsList",
    });} else{
    const navigationPaths: { [key: string]: { path: string; state?: object } } =
      {
        0: {
          path: `/case/${caseId}/claim/claim-setup`,
          state: propData,
        },
      };

    const navigation = navigationPaths[indexNo];
    if (navigation.state) {
      navigate(navigation.path, { state: navigation.state });
    } else {
      navigate(navigation.path);
    }
  }
  };

  const handleSearchChange = useCallback(
    (event: { target: { value: SetStateAction<string> } }) => {
      setSearchValue(event.target.value);
    },
    []
  );

  const handleOnSearch = useCallback(() => {
    onSearch(searchValue);
  }, [onSearch, searchValue]);

  if (!caseId) {
    return null;
  }

  return (
    <>
      <MotifFormField>
        <SearchInput
          aria-label="Search"
          onChange={handleSearchChange}
          onClear={handleQuickSearchClear}
          onFocusShow={false}
          value={searchQuery}
          onEnter={handleOnSearch}
          placeholder={t("pages.claims.search.placeholder")}
        />
        {/* <MotifButtonGroup>
          <MotifButton
            variant="secondary"
            onClick={() => {
              dispatch(setShowBasicSeach(false));
            }}
          >
            {t("pages.claims.search.title")}
          </MotifButton>
          <MotifDropdownPortal
            open={showMenu}
            hideArrow={true}
            handleClickOutside={() => setShowMenu(false)}
            trigger={
              <MotifButton
                type="button"
                onClick={() => setShowMenu(!showMenu)}
                aria-label="Dropdown button"
                aria-haspopup="true"
                variant="secondary"
              >
                <MotifIcon
                  aria-label="Dropdown icon"
                  fill="none"
                  iconFunction={IconoirNavArrowDown}
                />
              </MotifButton>
            }
          >
            <MotifDropdownItem
              onClick={() => dispatch(setShowBasicSeach(true))}
            >
              {t("pages.claims.basicSearch.title")}
            </MotifDropdownItem>
            <MotifDropdownItem
              onClick={() => {
                dispatch(setShowBasicSeach(false));
              }}
            >
              {t("pages.claims.advancedSearch.title")}
            </MotifDropdownItem>
          </MotifDropdownPortal>
        </MotifButtonGroup> */}
        <MotifDropdown
          ariaLabelledby="dropdown-trigger-1"
          id="dropdown-1"
          trigger={
            <MotifButton
              aria-label="Fruits"
              id="dropdown-trigger-1"
              onClick={function Ga() {}}
              type="button"
              variant="text"
            >
              <MotifIcon iconFunction={IconoirMoreVert} />
            </MotifButton>
          }
        >
          <ul role="menu">
            {creditorTitleMenu.map((menuName: string,indexNo) => {
              return (
                <li role="menuitem">
                  <MotifDropdownItem
                    indexItem={1}
                    onClick={() => handleMenuClick( caseId,indexNo)}
                  >
                    {t(menuName)}
                  </MotifDropdownItem>
                </li>
              );
            })}
          </ul>
        </MotifDropdown>
      </MotifFormField>
    </>
  );
}
