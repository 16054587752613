
import { formatAmountUnitDecimalPlace } from "@/utility/common";
import { useTranslation } from "react-i18next";



export const useCreditorVotesDefs=(caseDetails:any)=>{
 
  const {t}=useTranslation()
  const CustomHeader = () => {
    const { defaultUnit } = caseDetails;
      return `${defaultUnit?.name}`;
  };
  
  const amountCellRenderer = (field: string) => (params: any) => {
    const { defaultUnit } = caseDetails;
    const decimalPlace = defaultUnit?.decimalPlace;
    const amount = params.data[field];
    return amount === null
      ? "-"
      : formatAmountUnitDecimalPlace(amount, decimalPlace);
  };
  return [
  {
    field: "name",
    headerName: t("pages.voting.creditorVotes.columnDetails.column1"),
    flex: 1,
    sortable: true,
    unSortIcon: true,
    cellStyle: { justifyContent: "start" },
  },
  {
    field: "creditorReference",
    headerName: t("pages.voting.creditorVotes.columnDetails.column2"),
    flex: 1,
    sortable: true,
    unSortIcon: true,
    cellStyle: { justifyContent: "start" },
  },
  {
    field: "proxyName",
    headerName: t("pages.voting.creditorVotes.columnDetails.column3"),
    flex: 1,
    sortable: true,
    unSortIcon: true,
    cellStyle: { justifyContent: "start" },
  },
  {
    field: "voteResult",
    headerName: t("pages.voting.creditorVotes.columnDetails.column4"),
    flex: 1,
    cellStyle: { justifyContent: "start" },
    cellRenderer: function (param: any) {
      const { voteResult } = param.data;
      switch (voteResult) {
        case 0:
          return "Yes";
        case 1:
          return "No";
        case 2:
          return "Did Not Vote";
      }
    },
  },
  {
    field: "isEligible",
    headerName: t("pages.voting.creditorVotes.columnDetails.column5"),
    flex: 1,
    cellStyle: { justifyContent: "start" },
    cellRenderer: function (param: any) {
      let { isEligible } = param.data;
      return isEligible === 1 ? "Yes" : isEligible === 0 ? "No" : "-";
    },
  },
  {
    field: "unresolvedCreditorAmount",
    flex: 1,
    cellStyle: { justifyContent: "end" },
    cellRenderer: amountCellRenderer("unresolvedCreditorAmount"),
    headerName: t("pages.voting.creditorVotes.columnDetails.column6",{unitName:CustomHeader()}),
    headerComponentParams: { params: "Latest Creditor Position Amount" },
    cellClass: "align-right",
    headerClass: "align-right",
  },
  {
    field: "unresolvedDebtorAmount",
    flex: 1,
    cellStyle: { justifyContent: "end" },
    cellRenderer: amountCellRenderer("unresolvedDebtorAmount"),
    headerName: t("pages.voting.creditorVotes.columnDetails.column7",{unitName:CustomHeader()}),
    headerComponentParams: { params: "Latest Debtor Position Amount" },
    cellClass: "align-right",
    headerClass: "align-right",
  },
  {
    field: "resolvedAmount",
    flex: 1,
    cellStyle: { justifyContent: "end" },
    width: 500,
    cellRenderer: amountCellRenderer("resolvedAmount"),
    headerName: t("pages.voting.creditorVotes.columnDetails.column8",{unitName:CustomHeader()}),
    headerComponentParams: { params: "Resolved Amount" },
    cellClass: "align-right",
    headerClass: "align-right",
  },
]};
