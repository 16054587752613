export function isNumeric(str: string | number) {
  str = str?.toString(); // Ensure the value is a string
  return !isNaN(Number(str)) && !isNaN(parseFloat(str));
}

/**
 * compares two strings or numbers, numbers could have decimal points
 * @param a
 * @param b
 * @returns the difference between the two values
 */
export function compareNumeric(a: number | string, b: number | string) {
  const numberA = parseFloat(a?.toString());
  const numberB = parseFloat(b?.toString());
  return numberA - numberB;
}

export const validateNumer = (value: string): boolean => {
  const matches = value.match(
    /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
  );
  return matches !== null && matches.length > 0;
};

export const isEmptyOrSpaces = (str: string): boolean => {
  return !str || str.trim().length === 0;
};