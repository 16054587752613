import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifButton,
  MotifInput,
  MotifProgressLoader,
  MotifToast,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import { useGetClaimSummaryViewApiDataQuery, useUpdateClaimMutation } from "@/services/claimSummary/caseSummaryReducer";
import { useParams } from "react-router-dom";
import { SearchDebtor } from "./searchDebtor";
import { SearchCreditor } from "./searchCreditor";
import { dateFunc } from "@/components/Functions/dateFunc";
import { Tasks } from "../summary/component/task";
import { CommentHistory } from "../summary/component/commentHistory";
import { Documents } from "../summary/component/documents";
import { CreditorContactsEdit } from "./creditorContactsEdit";
import { ENTITYTYPE } from "@/appConstants";
import CustomAttribute from "@/components/CustomAttribute/CustomAtrribute";
import { AttributeType } from "@/pages/CaseDetail/Configuration/Attributes/type";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { ClaimItems } from "../summary/component/claimItems";
import FormattedDatePicker from "@/components/FormattedDatePicker/FormattedDatePicker";
import { useLazyGetCreditorSummaryDataQuery } from "../../../pages/CaseDetail/Creditor/CreditorSummaryView/CreditorSummaryReducer";
import { useCase } from "@/features/case/use-case";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
import { useDispatch } from "react-redux";

export type EditSummaryType = {
  setEditPage: any;
  setSuccessMsg: any;
  resolvedStatus: any;
};

export const EditSummary = ({
  setEditPage,
  setSuccessMsg,
  resolvedStatus,
}: EditSummaryType) => {
  const {claimId,caseId} = useParams();
  const { t } = useTranslation();
  const { data, isLoading, refetch } = useGetClaimSummaryViewApiDataQuery(
    claimId
  );

  const [
    triggerCreditorSummaryData,
    {
      data: updatedAssigneeData,
      isLoading: updatedAssigneeDataLoading,
      isFetching:updatedAssigneeDataFetching
    },
  ] = useLazyGetCreditorSummaryDataQuery();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [customAttributeValues, setCustomAttributeValues] = useState<any>();
  const [intialCustomAttributeValues, setIntialCustomAttributeValues] = useState<any>();

  const [isAttributesError, setIsAttributesError] = useState(false);
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);
  const [formValues, setFormValues] = useState<any>({
    claimReference: data?.claimSummary?.claimReference,
    initialFileDate: data?.claimSummary?.initialFileDate,
    debtorId: data?.claimSummary?.debtorId,
    defaultAssigneeId: data?.claimAssignee?.assigneeId,
  });
  const [intialFormValues] = useState<any>({
    claimReference: data?.claimSummary?.claimReference,
    initialFileDate: data?.claimSummary?.initialFileDate,
    debtorId: data?.claimSummary?.debtorId,
    defaultAssigneeId: data?.claimAssignee?.assigneeId,
  });
  let updatedObject: any = {
    claimId: data?.claimSummary?.claimId,
    initialFileDate: formValues?.initialFileDate,
    debtorId: formValues?.debtorId,
    defaultAssigneeId: formValues?.defaultAssigneeId,
  };

  
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });
  const [selectedAssigneeId, setSelectedAssigneeId] = useState("");
  const [selectedAssigneeName, setSelectedAssigneeName] = useState("");
  const [selectedAsigneeData, setSelectedAssigneeData] = useState({});
  const dispatch=useDispatch()
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: (JSON.stringify(intialFormValues)!==JSON.stringify(formValues)) || (JSON.stringify(intialCustomAttributeValues)!==JSON.stringify(customAttributeValues)) }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,formValues,intialFormValues,customAttributeValues,intialCustomAttributeValues]);
  useEffect(() => {
    const propData = {
      caseId: caseId || "",
      creditorId: selectedAssigneeId || "",
    };
    if (selectedAssigneeId) {
      triggerCreditorSummaryData(propData)
        .unwrap()
        .catch((error) => {
        });
    } else {
      setSelectedAssigneeData({});
      setSelectedAssigneeName("");
    }
  }, [selectedAssigneeId, triggerCreditorSummaryData, caseId]);

  useEffect(() => {
    if (data) {
      setIntialCustomAttributeValues(data.customAttributes);
      setCustomAttributeValues(data.customAttributes);
      setSelectedAssigneeData(data?.claimAssignee?.contact)
      setSelectedAssigneeName(data?.claimAssignee?.assigneeName)
    }
  }, [data]);
 
  useEffect(()=>{
    if(updatedAssigneeData && selectedAssigneeId){
      setSelectedAssigneeData(updatedAssigneeData?.creditorContactList)
      setSelectedAssigneeName(
        updatedAssigneeData?.creditorIdentifier?.displayName
      )
    }
   
  },[updatedAssigneeData, selectedAssigneeId])

  const handleChange = (name: string, value: any) => {
    setFormValues({ ...formValues, [name]: value });
  };

  let attributesError =useCallback(() => {
    var isError = false;
    const required = customAttributeValues?.filter((x: any) => {
      return x.isRequired;
    });

    required?.forEach((x: any) => {
      switch (x.value.attributeType) {
        case AttributeType.FreeText:
          isError = !x.value.textValue;
          break;
        case AttributeType.Number:
          isError = !x.value.numberValue;
          break;
        case AttributeType.Boolean:
          isError = !x.value.booleanValue;
          break;
        case AttributeType.Date:
          isError = !x.value.dateValue;
          break;
        case AttributeType.SingleSelect:
          isError = !x.value.selectedValue;
          break;
        case AttributeType.MutliSelect:
          isError =
            !x.value.selectedValues || x.value.selectedValues.length === 0;
          break;
        case AttributeType.Currency:
          isError =x.value.amount===0 || x.value.amount?false:true;
          break;
        case AttributeType.Document:
          isError = !x.value.displayName;
          break;
        default:
          isError = false;
      }
    });

    return isError;
  },[customAttributeValues])
  useEffect(()=>{
    if (!attributesError() ) {
      setIsAttributesError(false);
    }
  },[customAttributeValues,attributesError])
  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };
 

  const renderMessage = () => {
    if (showMessage.show === true) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={showMessage.type}
        >
          {" "}
          {showMessage.type === "error"
            ? t(showMessage.message)
            : t("global.globalNotifications.updatedSuccessfully")}
        </MotifToast>
      );
    }
  };

  const [updateClaim,{isLoading:updateClaimLoad}] = useUpdateClaimMutation();

  async function editClaimSummary() {
    if (attributesError()) {
      setIsAttributesError(true);
    } else {
      const attributes = customAttributeValues.map((value: any) => value.value);
      const jsonData = {
        ...updatedObject,
        customAttributes: attributes,
      };
      const response = await updateClaim(jsonData);
      if (response.hasOwnProperty("data")) {
          refetch();
          setFormSubmitted(true);
          setEditPage(false);
          setSuccessMsg(true);
      }else{
          const error = "global.globalNotifications.unExpectedError";
          setShowMessage({
            ...showMessage,
            show: true,
            type: "error",
            message: error,
          });
      }
    }
  }
  function claimAssigneeContact(getAssignneData: any) {
    let filteredData = getAssignneData?.filter((getPrimaryData: any) => {
      if (getPrimaryData?.isPrimaryContact === true) {
        return true;
      } else {
        return false;
      }
    });
    return filteredData;
  }
  const casedata = useCase();
  return (
    <>
    {renderMessage()}
      {formSubmitted && (
        <>
          <MotifToast onClose={() => setFormSubmitted(false)} variant="success">
          {t("pages.claims.claimsEditTab.editedSuccessfully")}           </MotifToast>
        </>
      )}
      {isLoading ? (
        <MotifProgressLoader show variant="default" />
      ) : (
        <>
          <div className="motif-container case-summary-form pad-v-20 mar-t-20">
            <div className="motif-row justify-content-between align-items-center">
              <div className="motif-col-4">
                <div className="motif-body1-default-regular page-title">
                {t("pages.claims.claimsEditTab.title")}         
                </div>
                <div className="motif-body2-default-regular page-description">
                {t("pages.claims.claimsEditTab.description")}                  </div>
              </div>
            </div>
          </div>
          <div className="motif-container main-container ccwp_claim_summary_form mar-b-20 pad-b-20">
            <div className={`motif-row pad-t-20`}>
              <div className="motif-col-md-3">
                <div className="form-field">
                  <label>   {t("pages.claims.commonFieldLabels.claimId")}   </label>
                  <MotifInput
                    /**@ts-ignore **/
                    id="input"
                    placeholder="Claim ID"
                    onChange={(e: any) =>
                      handleChange("claimReference", e.target.value)
                    }
                    hideClearButton={true}
                    disabled
                    value={formValues?.claimReference}
                  />
                </div>
              </div>
              <div className="motif-col-md-3">
                <div className="form-field">
                  <label>{t("pages.claims.commonFieldLabels.creditorName")} </label>
                  <MotifInput
                    /**@ts-ignore **/
                    id="input"
                    placeholder="Creditor"
                    onBlur={function Ua() {}}
                    onFocus={function Ua() {}}
                    hideClearButton={true}
                    disabled
                    value={data?.claimSummary?.creditorName}
                  />
                </div>
              </div>
              <div className="motif-col-md-2">
                <div className="form-field">
                  <label>{t("pages.claims.claimsEditTab.formLabelDetails.createdOn")} </label>
                  <MotifInput
                    /**@ts-ignore **/
                    id="input"
                    placeholder="Source"
                    onBlur={function Ua() {}}
                    onFocus={function Ua() {}}
                    hideClearButton={true}
                    value={dateFunc(data?.claimSummary?.createdOn).formattedDateTime}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className={`motif-row pad-t-20`}>
              <div className="motif-col-md-4">
                <div className="form-field">
                  <label>{t("pages.claims.commonFieldLabels.source")}</label>
                  <MotifInput
                    /**@ts-ignore **/
                    id="input"
                    placeholder="Source"
                    onBlur={function Ua() {}}
                    onFocus={function Ua() {}}
                    hideClearButton={true}
                    value={data?.claimSummary?.source}
                    disabled
                  />
                </div>
              </div>
              <div className="motif-col-md-4">
                <div className="form-field">
                  <label>{t("pages.claims.commonFieldLabels.lastUpdatedDate")}</label>
                  {/* <MotifDatePicker
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YY"
                    format="dd-MMM-yyyy"
                    //{dateFunc(data?.claimSummary?.updatedOn).formattedDateTime + ' ' + caseData?.abbreviation}
                    value={data?.claimSummary?.updatedOn}
                    disabled
                  /> */}
                  <MotifInput
                    /**@ts-ignore **/
                    hideClearButton={true}
                    value={dateFunc(data?.claimSummary?.updatedOn).formattedDateTime + ' ' + casedata?.abbreviation}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className={`motif-row pad-t-20`}>
              <div className="motif-col-md-4">
                <div className="form-field">
                  <label>{t("pages.claims.commonFieldLabels.initialFileDate")}</label>
                  <FormattedDatePicker
                    todayMark={true}
                    value={formValues?.initialFileDate}
                    onChange={(value) => {
                      // Create a Date object from the selected value
                      const selectedDate = new Date(value);

                      // Add one day to the selected date
                      selectedDate.setDate(selectedDate.getDate() + 1);
                      // Convert the updated date to ISO string and take the first 10 characters (YYYY-MM-DD)
                      const updatedDate: any = selectedDate
                        .toISOString()
                        .substring(0, 10);
                      handleChange("initialFileDate", updatedDate);
                    }}
                    disabled={resolvedStatus}
                  />
                </div>
              </div>
              <div className="motif-col-md-4">
                <div className="form-field">
                  <label>{t("pages.claims.claimsEditTab.formLabelDetails.debtor")}</label>
                  <SearchDebtor
                    handleChange={handleChange}
                    debtorId={formValues?.debtorId}
                    debtorData={data}
                    resolvedStatus={resolvedStatus}
                  />
                </div>
              </div>
            </div>
            <div className="ccwp-edit-summary-sub-heading">
              <h4>{t("pages.claims.commonFieldLabels.attributes")}</h4>
            </div>
            <div className={`motif-row pad-t-20 mar-b-20`}>
              {data && data.customAttributes.length !== 0 ? (
                data.customAttributes.map((attribute: any, index: number) => {
                  return (
                    <div className="motif-col-md-4">
                      <CustomAttribute
                        customAttributeDetails={{
                          ...attribute,
                          metadataJSON: JSON.parse(attribute.metadataJSON),
                        }}
                        customAttributeType={attribute.value.attributeType}
                        attributeValue={customAttributeValues}
                        setAttributeValue={setCustomAttributeValues}
                        index={index}
                        key={index}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="motif-col-md-4">
                  {t("pages.claims.noAttributesLabel")}
                </div>
              )}
            </div>
            <div className="ccwp-edit-summary-sub-heading">
              <h4>{t("pages.claims.claimsEditTab.formLabelDetails.defaultAssignee")}</h4>
            </div>
            {isLoading ? (
              <MotifProgressLoader />
            ) : (
                  <div className="ccwp_claim_summary_white_accordian">
                    <div className="motif-row  mar-b-20">
              <div className="motif-col-md-4">
                <div className="form-field">
                  <label>{t("pages.claims.commonFieldLabels.asigneeName")}</label>
                  <SearchCreditor
                    handleChange={handleChange}
                    creditorData={data}
                    debtorId={formValues?.defaultAssigneeId}
                    resolvedStatus={resolvedStatus}
                            setSelectedAssigneeId={setSelectedAssigneeId}
                  />
                </div>
              </div>
            </div>
                    { updatedAssigneeDataLoading ||updatedAssigneeDataFetching ? <MotifProgressLoader show /> :
                    <>
                    {selectedAssigneeName ? (
                      <MotifAccordion
                        onClose={function Ua() {}}
                        onOpen={function Ua() {}}
                        style={{
                          flex: "1",
                        }}
                        alignIconRight={true}
                        useChevronIcon={true}
                      >
                          <>
                        <MotifAccordionTrigger>
                          <div className="ccwp_claim_summary_white">
                            <label>{t("pages.claims.commonFieldLabels.asigneeName")}</label>
                                <p>{selectedAssigneeName}</p>
                          </div>
                        </MotifAccordionTrigger>
                        <MotifAccordionContent>
                              {selectedAsigneeData &&
                              Object.keys(selectedAsigneeData).length
                                ? claimAssigneeContact(selectedAsigneeData).map(
                                    (getPrimaryData: any) => {
                            return (
                              <>
                                <ul className="ccwp_claim_summary_details">
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.firstName")}</label>
                                    <p>
                                      {getPrimaryData?.firstName &&
                                        getPrimaryData?.firstName}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.lastName")}</label>
                                    <p>
                                      {!getPrimaryData?.lastName
                                        ? "-"
                                        : getPrimaryData?.lastName}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.address1")}</label>
                                    <p>
                                      {!getPrimaryData?.address1
                                        ? "-"
                                        : getPrimaryData?.address1}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.address2")}</label>
                                    <p>
                                      {!getPrimaryData?.address2
                                        ? "-"
                                        : getPrimaryData?.address2}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.city")}</label>
                                    <p>
                                      {!getPrimaryData?.city
                                        ? "-"
                                        : getPrimaryData?.city}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.provincestate")} </label>
                                    <p>
                                      {!getPrimaryData?.provinceState
                                        ? "-"
                                        : getPrimaryData?.provinceState}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.postalCode")}</label>
                                    <p>
                                      {!getPrimaryData?.postalCode
                                        ? "-"
                                        : getPrimaryData?.postalCode}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.phone")}</label>
                                    <p>
                                      {!getPrimaryData?.phone
                                        ? "-"
                                        : getPrimaryData?.phone}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.email")}</label>
                                    <p>
                                      {!getPrimaryData?.email
                                        ? "-"
                                        : getPrimaryData?.email}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.lastUpdatedOn")}</label>
                                    <p>
                                      {!getPrimaryData?.updatedOn
                                        ? "-"
                                                  : dateFunc(
                                                      getPrimaryData?.updatedOn
                                                    ).formattedDateOnly}
                                    </p>
                                  </li>
                                  <li>
                                    <label>{t("pages.claims.commonFieldLabels.lastUpdatedBy")}</label>
                                    <p>
                                      {!getPrimaryData?.updatedBy
                                        ? "-"
                                        : getPrimaryData?.updatedBy}
                                    </p>
                                  </li>
                                </ul>
                              </>
                            );
                                    }
                                  )
                                : " "}
                            </MotifAccordionContent>{" "}
                          </>
                      
                      </MotifAccordion>
                    ) : (
                      <></>
                    )}
                    </>}
                  </div>
            )}
          </div>
          <CreditorContactsEdit
            formSubmitted={formSubmitted}
            resolvedStatus={resolvedStatus}
          />
          <div>
            <MotifErrorMessage>
              {isAttributesError
                ? t("pages.configuration.attributes.requiredFields")
                : ""}
            </MotifErrorMessage>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              marginBottom: "20px",
            }}
          >
            <MotifButton
              size="small"
              type="submit"
              variant="secondary"
              onClick={() => 
                {
                  if((JSON.stringify(intialFormValues)!==JSON.stringify(formValues)) || (JSON.stringify(intialCustomAttributeValues)!==JSON.stringify(customAttributeValues)) ){
                    setIsDiscardVisible(true)

                  }
                  else{
                    setEditPage(false)
                  }
                  
                
                }}
            >
              {t("global.globalActions.cancel")}
            </MotifButton>
            &nbsp;&nbsp;
            <MotifButton
              onClick={() => {
                editClaimSummary();
              }}
              type="button"
              loading={updateClaimLoad}
              size="small"
              variant="primary"
            >
              {t("global.globalActions.save")}
              </MotifButton>
          </div>
          <ClaimItems resolvedStatus={resolvedStatus} />
          <Documents />
          <Tasks />
          <CommentHistory entityType={ENTITYTYPE.claim} dataId={claimId} />
        </>
      )}
      <ConfirmationModal
        isVisible={isDiscardVisible}
        setIsVisible={setIsDiscardVisible}
        cancelBtnApproveHandler={() => setEditPage(false)}
        cancelBtnRejectHandler={() => setIsDiscardVisible(false)}
      />
    </>
  );
};
