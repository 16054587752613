import Table from "@/components/Table/table";
import { IconoirEditPencil, IconoirTrash } from "@ey-xd/motif-icon";
import { useDeleteClaimItemCategoryMutation, useGetconfigCategoryApiDataQuery } from "@/services/claimSummary/caseSummaryReducer";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { useState } from "react";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import {
  MotifButton,
  MotifIcon,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";
import { IconoirWarningTriangle } from "@ey-xd/motif-icon";
import { useNavigate , Link} from "react-router-dom";
import { useCase } from "@/features/case/use-case";
import {  customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { useTranslation } from "react-i18next";

export const CategoryGrid = () => {
  const caseData = useCase();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const paginationPageSize: number =customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
  let tableLoadingData=customTableLoadingData
  const [paginationObj, setPaginationObj] = useState({
    CaseId:caseData?.caseId,
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",

  });

  
      let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({    })
  
    function handlePaginationData(data:any,columnTypesData:any){
      setPaginationObj({...data})
      setSortedColumnTypes({...columnTypesData})
    }

  const { data, isLoading,isFetching,error, refetch } = useGetconfigCategoryApiDataQuery(
    paginationObj
  );
  const [statusMsg, setStatusMsg] = useState<any>(null);
  const [showModal, setShowModal] = useState<any>(null);
  const [deleteClaimItemCategory] = useDeleteClaimItemCategoryMutation();
  async function editCategory(categoryId: string) {
    const response = await deleteClaimItemCategory(categoryId);
    if (response.hasOwnProperty("data")) {
      //@ts-ignore
      setStatusMsg(response?.data?.message);
      refetch();
    } else {
      setShowModal(true);
    }
  }

  const caseRenderers = {
    actionCellRenderer: function (params: any) {
      return (
        <>
          <div className="pad-r-10">
            <MotifButton
              size="small"
              variant="primary"
              onClick={() => editCategory(params.data.id)}
            >
              <MotifIcon
                iconFunction={IconoirTrash}
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
          </div>
          <MotifButton
            size="small"
            type="submit"
            variant="primary"
            onClick={() =>
              navigate(
                `/case/${caseData?.caseId}/edit-category/${params.data.id}`
              )
            }
          >
            <MotifIcon
              iconFunction={IconoirEditPencil}
              stroke="blue"
              strokeWidth="2"
              size="24"
            />
          </MotifButton>
        </>
      );
    },
  };
  const columnDefs = [
    {
      cellRenderer: (params:any)=><Link to={`./edit-category/${params?.data?.id}`} >{params?.data?.name}</Link>,
      comparator: () => 0,
      field: "name",
      type:"categoryNameType",  
      headerName: t("pages.configuration.category.columnDetails.column1"),
      sortable: true,
      unSortIcon: true,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
      },
      autoHeight: true,
      flex: 1,
    },
    {
      cellRenderer: "descriptionCellRenderer",
      			comparator: () => 0,
      field: "description",
      type:"descriptionType",
      headerName: t("pages.configuration.category.columnDetails.column2"),
      sortable: true,
      unSortIcon: true,
      flex: 1,
    },
    {
      cellRenderer: "actionCellRenderer",
      field: "action",
      headerName: "",
      sortable: false,
      unSortIcon: true,      cellStyle: {
        "justify-content": "right",
      },
    },
  ];
  function TitleComplement() {
    return (
      <MotifButton
        variant="secondary"
        onClick={() => navigate(`/case/${caseData?.caseId}/create-category`)}
      >
        {t("pages.configuration.category.createCategory")}
      </MotifButton>
    );
  }
  const categoryData=data?.results?data.results:[]
  return (
    <>
      <MotifModal
        onClose={() => setShowModal(false)}
        show={showModal}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <div className="confirmationModalWrapper creditor_disabling_model">
          <MotifModalHeader
            headerIcon={
              <>
                <span
                  style={{
                    backgroundColor: "rgb(252, 245, 243)",
                    borderRadius: "50px",
                    padding: "5px 8px 2px 8px",
                  }}
                >
                  <MotifIcon
                    iconFunction={IconoirWarningTriangle}
                    strokeWidth="1"
                    size="15"
                    stroke="red"
                  />{" "}
                </span>
                <span
                  style={{
                    fontSize: 16,
                    display: "block",
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  {t("global.globalNotifications.warning")}
                </span>
              </>
            }
          ></MotifModalHeader>

          <MotifModalBody style={{ paddingTop: "0px" }}>
            <span style={{ fontWeight: "300" }}>
{t("pages.configuration.category.deleteMesseage")}            </span>
          </MotifModalBody>
          <MotifModalFooter>
            <MotifButton
              size="small"
              type="submit"
              variant="primary"
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              onClick={() => setShowModal(false)}
            >
                  {t("global.globalActions.confirm")}
                  </MotifButton>
          </MotifModalFooter>
        </div>
      </MotifModal>
      {window.scrollTo(0, 0)}
      {statusMsg && (
        <NotificationGrid
          show={true}
          message="pages.configuration.category.deleteSuccessfully"
          type="success"
        />
      )}
      <br />
      {isLoading ? (
        <MotifProgressLoader show variant="default" />
      ) : (
        <div>
        <Table
          columnDefs={columnDefs}
          columnTypes={{...sortedColumnTypes}}
          handlePaginationData={handlePaginationData}
              paginationObj={paginationObj}
          cellRenderers={isFetching?[]:categoryData}
          TitleComplement={TitleComplement}
          title={"pages.configuration.category.title"}
          description="pages.configuration.category.tableDescription"
          pagination={false}
          caseRenderers={caseRenderers}
          overlayNoRowsTemplate={isFetching?t(tableLoadingData):error? t("global.globalNotifications.wentWrong")
             : t("global.globalNotifications.noRecords")}

        />
         <CustomPagination
          currentpage={paginationObj.PageNumber}
          pageSize={paginationObj.PageSize}
          disableNext={!data?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
        </div>
      )}
    </>
  );
};
