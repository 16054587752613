import Banner from "@/components/Banner/Banner";
import { CalculationStepType } from "./type";
import { useGetDistributionSummaryByIdQuery } from "@/services/distribution/distributionReducers";
import { formatDate } from "@/components/Functions/dateFunc";
type CalculationStepHeaderProps = {
    create: boolean;
    selectedStep?: CalculationStepType;
    distributionId?: string;
}

export default function CalculationStepHeaderEdit({create = false, selectedStep, distributionId=""}: CalculationStepHeaderProps) { 
    const { data } = useGetDistributionSummaryByIdQuery({
        id: distributionId,
      }, {skip: create}); 
    const bannerElements: {label: string, value: string}[] = [
        {
          label: "pages.distributions.distributionStepsTab.stepBannerDetails.label1",
          value: `${data?.name}`,
        },
        {
            label: "pages.distributions.distributionStepsTab.stepBannerDetails.label2",
            value: `${formatDate(selectedStep?.updatedOn)}`,
          },
    ];

    return (
        <div className="claim-details-header mar-b-20">
            <div className="claim-details-header__title">
                <Banner
                    bannerName="pages.distributions.distributionStepsTab.stepBannerDetails.title"
                    bannerNameValue={`${selectedStep?.name}`}
                    elements={bannerElements}
                />  
            </div>
        </div>
    );
}