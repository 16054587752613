import React from "react";
import { MotifCheckbox } from "@ey-xd/motif-react";
type CheckBoxType = {
  id?: string | undefined;
  className?: string | undefined;
  name: string;
  checked: boolean;
  required?: boolean;
  value: boolean;
  disabled?: boolean;
  label?: string | undefined;
  onChange: (e?: React.ReactEventHandler) => void;
  setIsFrenchSelected?: (value: React.SetStateAction<boolean>) => void;
};

export default function CheckBox({
  id = "",
  className = "",
  name,
  label,
  required = false,
  value,
  disabled,
  onChange,
  setIsFrenchSelected,
}: CheckBoxType) {
  const handleChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    onChange(e);
    if (setIsFrenchSelected) {
      setIsFrenchSelected(!value);
    }
  };
  return (
    <>
      <MotifCheckbox
        id={id}
        className={className}
        name={name}
        //@ts-ignore
        checked={value}
        required={required}
        //@ts-ignore
        value={value}
        //@ts-ignore
        onChange={handleChange}
        disabled={disabled}
      >
        {label}
      </MotifCheckbox>
    </>
  );
}
