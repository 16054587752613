export const PRIMARY_TITLE = "Claim Create";
export const PRIMARY_DESCRIPTION ="Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed aliquam ligula placerat massa. At interdum lectus auctor nibh dui massa tristique. Eget nisi potenti aenean ultrices nunc sit quam leo. Non risus donec et enim semper.";
export const SECONDARY_TITLE_CLAIM_ITEMS = "Claim Items";
export const SECONDARY_TITLE_UPLOAD_DOCUMENT = "Upload Documents";
export const SECONDARY_TITLE_STAGE_UPLOAD_DOCUMENT = "Upload Stage Document";
export const SECONDARY_DESCRIPTION = "Lorem ipsum dolor sit amet consectetur. Fringilla pulvinar in sed volutpat. Nunc iaculis cras amet condimentum. Bibendum.";
export const ADD_ASSIGNEE = "Add Assignee";
export const COMMENT_LABEL = "Comment";
export const STAGE_COMMENT_NAME = "stageDocumentComment";
export const COMMENT_NAME = "claimDocumentComment";
export const FILE_DISPLAY_NAME = "fileDislayName";
export const FILE_DISPLAY_LABEL = "File Display Name";
export const CLAIM_AMOUNT = "Claim Amount";
export const CLAIM_AMOUNT_NAME = "claimAmount";
export const CURRENCY = "Currency";
export const CURRENCY_NAME = "currency";
export const DOC_TYPE = "Document Type";
export const DOC_TYPE_FIELD = "claimDocumentTypeId";
export const CATEGORY = "Category";
export const CATEGORY_NAME = "categoryName";
export const NATURE_OF_CLAIM = "Nature of Claim";
export const NATURE_OF_CLAIM_NAME = "natureOfClaimName";
export const SECONDARY_TITLE_REVIEW_AND_SUBMIT = "Review and Submit";
export const FIELD_ARRAY = "fieldArray";
export const SECONDARY_TITLE_CLAIM_DESCRIPTION = "Details";
export const CLAIM_DESCRIPTION = "Description";
export const CLAIM_STAGE_LABEL = "Claim Stage";
export const CLAIM_STAGE_NAME = "claimStage";
export const CLAIM_STATUS_NAME = "claimStatus";
export const CLAIM_STATUS_LABEL = "Claim Status";
export const DEBTOR_LABEL = "Search for a Debtor";
export const DEBTOR_NAME = "debtor";
export const CREDITOR_LABEL = "Search for a Creditor";
export const CREDITOR_NAME = "creditor";
export const SECONDARY_TITLE_CLAIM_DEBTOR = "Debtor Detail";
export const SECONDARY_TITLE_CREDITOR = "Creditor Detail";
export const SECONDARY_TITLE_SELECT_ASSIGNEE = "Search for an Assignee";
export const SECONDARY_DESCRIPTION_SELECT_ASSIGNEE ="If the claim has been assigned, search for the assignee as an existing creditor, or create a new creditor for the assignee. If the claim is not assigned, just press the Next button";
export const SECONDARY_TITLE_ATTRIBUTE = "Claim Attributes";
export const SELECT_A_CONTACT_LABEL = "Select a Contact";
export const SELECT_A_CONTACT_NAME = "selectAContact";
export const PREFIX_LABEL = "Prefix";
export const PREFIX_NAME = "prefix";
export const NEW_CREDITOR_PREFIX_LABEL = "Prefix";
export const NEW_CREDITOR_PREFIX_NAME = "creditorPrefix";
export const NEW_CREDITOR_FIRST_NAME_LABEL = "First Name";
export const NEW_CREDITOR_FIRST_NAME = "creditorFirstName";
export const NEW_CREDITOR_LAST_NAME_LABEL = "Last Name";
export const NEW_CREDITOR_LAST_NAME = "creditorLastName";
export const RELATIONSHIP_TO_CREDITOR_LABEL = "Relationship to Creditor";
export const RELATIONSHIP_TO_CREDITOR_NAME = "relationshipToCreditor";
export const RELATIONSHIP_TO_DEBTOR_LABEL = "Relationship to Debtor";
export const RELATIONSHIP_TO_DEBTOR_FIELD = "relationshipToDebtor";
export const PHONE_NUMBER_LABEL = "Phone Number";
export const PHONE_NUMBER_FIELD = "phone";
export const FAX_LABEL = "Fax";
export const FAX_FIELD = "fax";
export const EMAIL_LABEL = "Email";
export const EMAIL_FIELD = "email";
export const PROVINCE_STATE_LABEL = "Province State";
export const PROVINCE_STATE_NAME = "provinceState";
export const COUNTRY_LABEL = "Country";
export const COUNTRY_NAME = "country";
export const FIRST_NAME = "First Name";
export const FIRST_NAME_FIELD = "firstName";
export const CONTACT_ID_FIELD = "contactId";
export const LAST_NAME = "Last Name";
export const LAST_NAME_FIELD = "lastName";
export const DESCRIPTION_LABEL = "Description";
export const DESCRIPTION_FIELD = "description";
export const BUSINESS_NAME_LABEL = "Business Name";
export const BUSINESS_NAME_FIELD = "businessName";
export const ADDRESS_1 = "Address 1";
export const ADDRESS_1_FIELD = "address1";
export const ADDRESS_2 = "Address 2";
export const ADDRESS_2_FIELD = "address2";
export const CITY = "City";
export const CITY_FIELD = "city";
export const POSTAL_CODE = "Postal Code";
export const POSTAL_CODE_FIELD = "postalCode";
export const DELETE_ALL_DETAILS = "Are you sure to delete all details?";
export const CLAIM_REFERENCE_ID_LABEL = "Claim ID";
export const CLAIM_REFERENCE_ID_NAME = "claimReferenceId";

export const PREFIX_OPTIONS = [
  {
    label: "Mr.",
    value: "Mr.",
  },
  {
    label: "Mrs.",
    value: "Mrs.",
  },
  {
    label: "Miss.",
    value: "Miss.",
  },
  {
    label: "Other",
    value: "other",
  }
]


export const DOC_TYPE_OPTIONS = [
  {
    label: "Stage document",
    value: "stage-document",
  },
  {
    label: "Other documents",
    value: "Other documents",
  },
];


