import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	MotifInput,
	MotifLabel,
	MotifFormField,
	MotifButton,
	MotifErrorMessage,
	MotifProgressLoader,
	MotifIcon,
} from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Tree from "@/components/Tree/Tree";
import { useGetClaimListAttributeQuery } from "@/services/advanceSearch/advanceSearchReducer";
import {
	useReadRuleQuery,
	useUpdateRuleMutation,
} from "@/services/rules/rulesReducer";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Banner from "@/components/Banner/Banner";
import { dateFunc } from "@/components/Functions/dateFunc";
import { processTreeRows } from "@/utility/common";
import "./Rules.scss";

const EditRules = () => {
	const { t } = useTranslation();
	const { caseId, schemeId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { schemeName, ruleId, className } = location.state;
	const [attributes, setAttributes] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [isDiscardVisible, setIsDiscardVisible] = useState(false);
	const [initiallyLoaded, setInitiallyLoaded] = useState(true);
	const {
		data: viewRulesData,
		isLoading: isViewRuleLoading,
		error: viewError,
		refetch: refetchViewRule,
	} = useReadRuleQuery(ruleId);
	const { data: attributesData } =
		useGetClaimListAttributeQuery(caseId, { skip: !caseId });
	const [updateRule, { isLoading: isUpdating }] = useUpdateRuleMutation();

	const transformViewRulesData = (data: any) => {
		const transformRules = (rules: any[]) => {
			return rules.map((rule: any) => ({
				...rule,
				attribute: `${rule.entityName}.${rule.propertyName}`,
				type: "row",
			}));
		};

		const transformGroups = (groups: any[]): any => {
			return groups.map((group: any) => ({
				...group,
				logicalOperator: group.logicalOperator.toString(),
				treeRows: [...transformRules(group.rules)],
				type: "group",
			}));
		};

		return {
			id: data?.id || 0,
			ruleName: data?.name || "",
			description: data?.description || "",
			sortOrder: data?.sortOrder || 0,
			outputClassId: data?.outputClassId || 0,
			logicalOperator: data?.searchRuleGroup?.logicalOperator.toString() || "0",
			treeRows: [
				...transformRules(data?.searchRuleGroup?.rules || []),
				...(Array.isArray(data?.searchRuleGroup?.groups)
					? transformGroups(data.searchRuleGroup.groups)
					: []),
			],
		};
	};
	const methods = useForm({
		defaultValues: transformViewRulesData(viewRulesData),
	});

	const breadcrumbItems = [
		{
			label: "Back",
			elementKey: "Back",
			text: "Back",
			url: `/case/${caseId}/scheme-manage/${schemeId}`,
			icon: <MotifIcon src={IconoirArrowLeft} />,
		},
	];
	const bannerElements: { label: string; value: string }[] = [
		{
			label: "Class Name",
			value: className,
		},
		{
			label: "Scheme Name",
			value: schemeName,
		},
		{
			label: "Updated On",
			value: dateFunc(viewRulesData?.updatedOn).formattedDateOnly,
		},
	];
	const {
		handleSubmit,
		formState: { errors },
		reset,
	} = methods;
	const onSubmit = async (data: any) => {
		const { rules, groups } = processTreeRows(data.treeRows, caseId);
		const payload = {
			id: data.id,
			outputClassId: data.outputClassId,
			CaseId: caseId,
			name: data.ruleName,
			description: data.description,
			sortOrder: data.sortOrder,
			pageNumber: 1,
			pageSize: 100,
			searchRuleGroup: {
				rules,
				groups: groups.length > 0 ? groups : null,
				logicalOperator: parseInt(data.logicalOperator),
				CaseId: caseId,
			},
		};
		setErrorMessage("");
		setSuccessMessage("");
		try {
			await updateRule(payload);
			setSuccessMessage("Rule updated successfully.");
			refetchViewRule();
			setInitiallyLoaded(false);
		} catch {
			setErrorMessage(
				"An error occurred while processing the update. Please try again later."
			);
		}
	};

	const handleClose = () => {
		setIsDiscardVisible(false);
		navigate(`/case/${caseId}/scheme-manage/${schemeId}`);
	};

	useEffect(() => {
		if (viewRulesData && initiallyLoaded) {
			reset(transformViewRulesData(viewRulesData));
		}
		// eslint-disable-next-line
	}, [viewRulesData, reset]);

	useEffect(() => {
		if (attributesData) {
			setAttributes(attributesData);
		}
	}, [attributesData]);

	const errorList = Object.keys(errors);
	return (
		<>
			<div>
				<Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
			</div>
			<Banner
				bannerName={"Rule Name"}
				bannerNameValue={viewRulesData?.name}
				elements={bannerElements}
			/>
			<div className="rules-setup-container">
				<div className="tree-div">
					{isViewRuleLoading ? (
						<div style={{ minHeight: "300px" }}>
							<MotifProgressLoader show variant="default">
								Loading rule configuration...
							</MotifProgressLoader>
						</div>
					) : viewError ? (
						<MotifErrorMessage>Data could not be loaded</MotifErrorMessage>
					) : (
						<FormProvider {...methods}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="motif-row">
									<div className="motif-col-md-2 motif-col-lg-4">
										<MotifLabel aria-label="Input" htmlFor="ruleName">
											{t("pages.configuration.scheme.rule.ruleName")}
											<span className="required-input">*</span>
										</MotifLabel>
										<MotifFormField aria-label="Input" role="group">
											<Controller
												name="ruleName"
												control={methods.control}
												rules={{ required: true }}
												render={({ field }) => <MotifInput {...field} />}
											/>
										</MotifFormField>
									</div>
									<div className="motif-col-md-2 motif-col-lg-4">
										<MotifLabel aria-label="Input" htmlFor="description">
											{t("pages.configuration.scheme.rule.description")}
										</MotifLabel>
										<MotifFormField aria-label="Input" role="group">
											<Controller
												name="description"
												control={methods.control}
												render={({ field }) => <MotifInput {...field} />}
											/>
										</MotifFormField>
									</div>
								</div>
								<div>
									<div className="rule-tree-header-section">
										<div className="motif-row justify-content-between align-items-center">
											<div className="motif-col-4">
												<div className="motif-body1-default-regular rule-title">
													{t("pages.configuration.scheme.rule.ruleLogic")}
												</div>
												<div className="motif-body2-default-regular rule-description">
													{t(
														"pages.configuration.scheme.rule.ruleLogicDescription"
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="rule-tree-details">
										<Tree attributes={attributes} />
									</div>
								</div>
							</form>
						</FormProvider>
					)}
					{successMessage && (
						<div className="mar-t-20">
							<NotificationGrid
								show={true}
								message={successMessage}
								type={"success"}
							/>
						</div>
					)}
					{errorMessage && (
						<div className="mar-t-20">
							<NotificationGrid
								show={true}
								message={errorMessage}
								type={"error"}
							/>
						</div>
					)}
					<div className="mar-t-20">
						<MotifErrorMessage>
							{errorList.length
								? t("pages.configuration.scheme.rule.validationError")
								: ""}
						</MotifErrorMessage>
					</div>
				</div>
				<div className="button-div">
					<div className="rules-footer-buttons">
						{successMessage === "" ? (
							<MotifButton
								variant="secondary"
								onClick={() => setIsDiscardVisible(true)}
							>
								{t("pages.configuration.scheme.rule.cancel")}
							</MotifButton>
						) : (
							<MotifButton variant="primary" onClick={handleClose}>
								{t("pages.configuration.scheme.rule.close")}
							</MotifButton>
						)}
						<MotifButton
							variant="primary"
							type={isUpdating ? "loader" : "submit"}
							onClick={handleSubmit(onSubmit)}
							disabled={isUpdating}
						>
							{t("pages.configuration.scheme.rule.save")}
						</MotifButton>
					</div>
				</div>
			</div>
			<ConfirmationModal
				isVisible={isDiscardVisible}
				setIsVisible={setIsDiscardVisible}
				cancelBtnApproveHandler={handleClose}
				cancelBtnRejectHandler={() => setIsDiscardVisible(false)}
			/>
		</>
	);
};

export default EditRules;