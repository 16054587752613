import React, { useCallback, useEffect, useState } from "react";
import Table from "@/components/Table/table";
import { useGetClaimListByCaseIdQuery } from "./PortalConfigReducer";
import { useNavigate, useParams } from "react-router-dom";
import { MotifButton, MotifProgressLoader } from "@ey-xd/motif-react";
import { useColumnFormClaimDef } from "./FormHeader";
import { useCustomCellRenderers } from "./Components/FormMgmtCellconst";
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import CustomPagination from "@/components/Pagination/CustomPagination";

const TitleComplement = () => {
  const navigate = useNavigate();

  const { caseId } = useParams<{
    caseId: string;
  }>();
  return (
    <MotifButton
      size="small"
      type="submit"
      variant="secondary"
      onClick={() => {
        navigate(`/case/${caseId}/form/create`, {
          state: { caseId },
        });
      }}
    >
      Build New Form
    </MotifButton>
  );
};

export default function Distribution() {
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();

  const { caseId } = useParams<{
    caseId: string;
  }>();
  const paginationPageSize: number =customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;

  let tableLoadingData=customTableLoadingData
  const [paginationObj, setPaginationObj] = useState({
    CaseId:caseId,
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",

  });


    
      let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({    })
  
      function handlePaginationData(data:any,columnTypesData:any){
        setPaginationObj({...data})
        setSortedColumnTypes({...columnTypesData})
      }

 
  const columnFormClaimDef = useColumnFormClaimDef()
  const { data, isLoading,isFetching,error } = useGetClaimListByCaseIdQuery(paginationObj);
  const handleEditClick = useCallback(() => {
    setIsEdit(true);
  }, []);
  useEffect(() => {
    if (isEdit) {
      navigate(`/case/${caseId}/form/edit`);
    }
  }, [isEdit, caseId, navigate]);
  const caseRenderers = useCustomCellRenderers(handleEditClick);

  return (
    <div>
      {isLoading ? (
        <MotifProgressLoader show variant="default" className="mar-b-20" />
      ) : (
        <div className="mar-b-20">
          <Table
           columnTypes={{...sortedColumnTypes}}
           handlePaginationData={handlePaginationData}
               paginationObj={paginationObj}
            columnDefs={columnFormClaimDef}
            cellRenderers={isFetching?[]:data?data?.results:[]}
            TitleComplement={TitleComplement}
            title={"Forms"}
            description={
              "Descriptive text that describes your page or content."
            }
            pagination={false}
            caseRenderers={caseRenderers}
            overlayNoRowsTemplate={isFetching?tableLoadingData:error?"Something Went Wrong":"No records found"}

          />
           <CustomPagination
          currentpage={paginationObj.PageNumber}
          pageSize={paginationObj.PageSize}
          disableNext={!data?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
        </div>
      )}
    </div>
  );
}
