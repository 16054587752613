import React, { useState } from "react";
import {
  MotifAvatar,
  MotifDropdown,
  MotifDropdownItem,
  MotifIconButton,
  MotifIcon,
} from "@ey-xd/motif-react";
import { actionIcExitToApp24px } from "@ey-xd/motif-react/assets/icons";
import SessionPopup from "@/features/auth/session-popup";
import { useUser } from "@/features/auth/user-hook";
import config from "@/config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { features } = config;

const UserAvatar = () => {
  const {
    user,
    photo,
    setSessionTimers,
    sessionPopupVisible,
    setSessionPopupVisible,
    warningTime,
  } = useUser();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  function handleLogout() {
    navigate("/logout");
  }

  if (!features.sso) {
    return null;
  }
  if (user && user.accessToken && user.name) {
    return (
      <>
        <div className="avatar-container">
          <MotifDropdown
            open={dropdownVisible}
            // handleClickOutside={() => setDropdownVisible(false)}
            trigger={
              <MotifIconButton
                type="button"
                onClick={() => {
                  setDropdownVisible(true);
                }}
              >
                {photo ? (
                  <MotifAvatar
                    src={photo.data}
                    userName={user.name || ""}
                    status="online"
                  />
                ) : (
                  <MotifAvatar userName={user.name || ""} status="online" />
                )}
              </MotifIconButton>
            }
          >
            <MotifDropdownItem onClick={handleLogout}>
              <MotifIcon src={actionIcExitToApp24px} />
              {t("pages.header.logout")}
            </MotifDropdownItem>
          </MotifDropdown>
        </div>
        <SessionPopup
          isModalVisible={sessionPopupVisible}
          setModalVisibility={setSessionPopupVisible}
          handleLogout={handleLogout}
          warningTime={warningTime}
          handleExtend={setSessionTimers}
        />
      </>
    );
  }

  return <div className="avatar-container" />;
};

export default UserAvatar;
