import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CASE__SHORT_NAME,
  CASE_DESCRIPTION,
  CASE_IDENTIFIERS,
  CASE_NAME,
  CURRENCY_CONFIGURATION,
  DEBTOR_DESCRIPTION_TITLE,
  DEBTOR_INFORMATION,
  DEBTOR_NAME,
  DISPLAY_CURRENCY,
  FORM_DESCRIPTON_REVIEW,
  FORM_TITLE_REVIEW,
  ADDITIONAL_CURRENCY,
  CASE_TIME_ZONE_LABEL,
} from "../Constants";
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifToast,
} from "@ey-xd/motif-react";
import {
  CANCEL,
  ENEXPECTED_ERROR,
  ENGLISH,
  SAVE,
  SECONDARY,
  Create_SUCCESSFUL,
  UPDATE_SUCCESSFUL,
} from "@/Constant";
import { CurrencyConfigurationType } from "../CaseSetupType";
import Button from "@/components/Form/Button/Button";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import EditIcon from "./EditIcon";
import { useCreateCaseMutation } from "@/services/cases/casesReducer";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_DECIMAL_POINT_ID,
  DEFAULT_EXCHANGE_RATE_ID,
} from "../CurrencyConfiguration/Constants";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@/app/store";
import { resetCaseSetup, updateStage } from "../caseSetupSlice";
import { postCaseSetupData } from "../CaseSetupHelper";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

export default function ReviewForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const CaseSetupData = useSelector((state: RootState) => state.caseSetup);
  const [createCase] = useCreateCaseMutation();
  const { caseSetupForm, debtorData, currencyConfiguration } = CaseSetupData;
  const { caseName, caseDescription, caseShortName, caseTimeZoneName } = caseSetupForm;
  const { t } = useTranslation();
  const [isFormOpen, setIsFormOpen] = useState(true);
  const [isFormOpenDebtor, setIsFormOpenDebtor] = useState(true);
  const [isFormOpenCurrency, setIsFormOpenCurrency] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "success",
  });
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
  });
  const moveToFirstTab = () => {
    dispatch(updateStage(0));
  };
  const cancelBtnApproveHandler = () => {
    setIsVisible(false);
    dispatch(resetCaseSetup());
    navigate("/");
  };
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: true }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch]);
  const saveBtnHandler = async () => {
    setIsLoading(true);

    if (
      !CaseSetupData.caseSetupForm.caseName ||
      CaseSetupData.caseSetupForm.caseName.trim() === ""
    ) {
      setShowMessage({
        ...showMessage,
        show: true,
        type: "error",
      });
      setNotification({
        ...notification,
        show: true,
        message: t("The case name cannot be empty or contain only spaces."),
        type: "error",
      });
      setIsLoading(false);
      return;
    }
    dispatch(setDiscardEditMode({ editMode: false }));


    try {
      const createCaseData = postCaseSetupData(CaseSetupData);
      const responseApi = await createCase(createCaseData).unwrap();

      if (responseApi.caseId) {
        dispatch(resetCaseSetup());
        setShowMessage({ ...showMessage, show: true, type: "success" });
        setNotification({
          ...notification,
          show: true,
          message: t(Create_SUCCESSFUL),
          type: "success",
        });

        navigate(`/case/${responseApi.caseId}`, {
          state: { showMessage: true },
        });
        setIsLoading(false);
      } else {
        const error = responseApi.data?.message;
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
        });
        setNotification({
          ...notification,
          show: true,
          message: t(error) || t(ENEXPECTED_ERROR),
          type: "error",
        });
        setIsLoading(false);
        dispatch(setDiscardEditMode({ editMode: true }));

      }
    } catch (error: any) {
      const errorMessage = error.data;
      setNotification({
        ...notification,
        show: true,
        message: t(errorMessage) || t(ENEXPECTED_ERROR),
        type: "error",
      });
      setShowMessage({ ...showMessage, show: true, type: "error" });
      setIsLoading(false);
      dispatch(setDiscardEditMode({ editMode: true }));

    }
  };

  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const renderMessage = () => {
    if (showMessage.show) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={showMessage.type}
        >
          {" "}
          {showMessage.type === "error"
            ? t(notification.message)
            : t(UPDATE_SUCCESSFUL)}
        </MotifToast>
      );
    }
  };

  const renderCurrencyConfiguration = (
    currencyConfiguration: CurrencyConfigurationType
  ) => {
    if (currencyConfiguration) {
      return (
        <>
          <div className="motif-row pad-l-40 pad-r-20">
            <div className="motif-col-lg-3">
              <div>
                <div>
                  <div className="title">{t(DISPLAY_CURRENCY)}</div>
                  <div className="motif-body2-default-bold">
                    {currencyConfiguration.defaultCurrency}
                  </div>
                </div>
              </div>
            </div>
            <div className="motif-col-lg-3">
              <div>
                <div>
                  <div className="title">{t(DEFAULT_EXCHANGE_RATE_ID)}</div>
                  <div className="motif-body2-default-bold">
                    {currencyConfiguration.defaultExchangeRate}
                  </div>
                </div>
              </div>
            </div>
            <div className="motif-col-lg-3">
              <div>
                <div>
                  <div className="title">{t(DEFAULT_DECIMAL_POINT_ID)}</div>
                  <div className="motif-body2-default-bold">
                    {currencyConfiguration.defaultDecimalPoint}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {currencyConfiguration.fieldArray.map((currency: any) => (
            <div className="motif-row pad-l-40 pad-r-20 mar-t-20">
              <div className="motif-col-lg-3">
                <div>
                  <div>
                    <div className="title">{t(ADDITIONAL_CURRENCY)}</div>
                    <div className="motif-body2-default-bold">
                      {currency.originalCurrency}
                    </div>
                  </div>
                </div>
              </div>
              <div className="motif-col-lg-3">
                <div>
                  <div>
                    <div className="title">{t(DEFAULT_EXCHANGE_RATE_ID)}</div>
                    <div className="motif-body2-default-bold">
                      {currency.originalExchangeRate}
                    </div>
                  </div>
                </div>
              </div>
              <div className="motif-col-lg-3">
                <div>
                  <div>
                    <div className="title">{t(DEFAULT_DECIMAL_POINT_ID)}</div>
                    <div className="motif-body2-default-bold">
                      {currency.originalDecimalPoint}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      );
    }
  };
  return (
    <>
      <div className="motif-container pad-l-40 pad-r-20">
        {renderMessage()}
        <div className="motif-row">
          <div className="motif-col-lg-12  main-container">
            <div className="motif-row t-b-padding-21">
              <div className="motif-col-xs-4">
                <p className="form-title">{t(FORM_TITLE_REVIEW)}</p>
                <p className="form-description">{t(FORM_DESCRIPTON_REVIEW)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="motif-row t-b-padding-21">
          <div className="motif-col-xs-4">
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              <MotifAccordion
                onClose={() => setIsFormOpen(true)}
                onOpen={() => setIsFormOpen(true)}
                style={{
                  flex: "1",
                }}
                open={isFormOpen}
                useChevronIcon={true}
                alignIconRight={true}
                onClick={moveToFirstTab}
              >
                <MotifAccordionTrigger>
                  <EditIcon />
                  {t(CASE_IDENTIFIERS)} ({t(ENGLISH)})
                </MotifAccordionTrigger>
                <MotifAccordionContent>
                  <div className="motif-row">
                    <div className="motif-col-lg-3">
                      <div>
                        <div className="title">{t(CASE_NAME)}</div>
                        <div className="motif-body2-default-bold">
                          {caseName}
                        </div>
                      </div>
                    </div>
                    <div className="motif-col-lg-3">
                      <div>
                        <div className="title">{t(CASE__SHORT_NAME)}</div>
                        <div className="motif-body2-default-bold">
                          {caseShortName}
                        </div>
                      </div>
                    </div>
                    <div className="motif-col-lg-6">
                      <div>
                        <div className="title">{t(CASE_DESCRIPTION)}</div>
                        <div className="motif-body2-default-bold">
                          {caseDescription}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="motif-row pad-t-20">
                    <div className="motif-col-lg-3">
                      <div>
                        <div className="title">{t(CASE_TIME_ZONE_LABEL)}</div>
                        <div className="motif-body2-default-bold">
                          {caseTimeZoneName}
                        </div>
                      </div>
                    </div>
                  </div>
                </MotifAccordionContent>
              </MotifAccordion>
            </div>
          </div>
        </div>
        <div className="motif-row t-b-padding-21 mar-t-30">
          <div className="motif-col-xs-4">
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              <MotifAccordion
                onClose={() => setIsFormOpenDebtor(true)}
                onOpen={() => setIsFormOpenDebtor(true)}
                style={{
                  flex: "1",
                }}
                open={isFormOpenDebtor}
                useChevronIcon={true}
                alignIconRight={true}
                onClick={moveToFirstTab}
              >
                <MotifAccordionTrigger>
                  <EditIcon />
                  {t(DEBTOR_INFORMATION)}
                </MotifAccordionTrigger>
                <MotifAccordionContent>
                  {debtorData.map((debtor, colIndex) => (
                    <div
                      className={`motif-row ${colIndex > 0 && "mar-t-20"}`}
                      key={colIndex}
                    >
                      <div className="motif-col-lg-3">
                        <div>
                          <div className="title">{t(DEBTOR_NAME)}</div>
                          <div className="motif-body2-default-bold">
                            {debtor.legalDebtorName}
                          </div>
                        </div>
                      </div>
                      <div className="motif-col-lg-6">
                        <div>
                          <div className="title">
                            {t(DEBTOR_DESCRIPTION_TITLE)}
                          </div>
                          <div className="motif-body2-default-bold">
                            {debtor.debtorDescription}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </MotifAccordionContent>
              </MotifAccordion>
            </div>
          </div>
        </div>
        <div className="motif-row t-b-padding-21 mar-t-30">
          <div className="motif-col-xs-4">
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              <MotifAccordion
                onClose={() => setIsFormOpenCurrency(true)}
                onOpen={() => setIsFormOpenCurrency(true)}
                style={{
                  flex: "1",
                }}
                open={isFormOpenCurrency}
                useChevronIcon={true}
                alignIconRight={true}
                onClick={moveToFirstTab}
              >
                <MotifAccordionTrigger>
                  <EditIcon />
                  {t(CURRENCY_CONFIGURATION)}
                </MotifAccordionTrigger>
                <MotifAccordionContent>
                  {renderCurrencyConfiguration(currencyConfiguration)}
                </MotifAccordionContent>
              </MotifAccordion>
            </div>
          </div>
        </div>
        <div className="button-container">
          <Button
            label={t(SAVE)}
            onClickHandler={() => saveBtnHandler()}
            type={isLoading ? "loader" : "submit"}
            disabled={isLoading}
          />
          <Button
            type="button"
            label={t(CANCEL)}
            variant={SECONDARY}
            className="cancelBtn"
            onClickHandler={() => setIsVisible(true)}
          />
        </div>
        <ConfirmationModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          cancelBtnApproveHandler={cancelBtnApproveHandler}
          cancelBtnRejectHandler={() => setIsVisible(false)}
        />
      </div>
    </>
  );
}
