const comment = {
    title: "Comments",
    commentPlaceholder: "Sample Text",
    tableTitle:"Comment History",
    tableDescription:"Descriptive text that describes your page or content.",
    deleteMessage: "Are you sure you want to delete this comment?",
    commentEdit:{
        title: "Edit Comment",
        requiredMessage: "Comment is required",
        label: "Comment",

    },
    tableCoumnsDetails:{
         comment: "Comment",

         postedOn: "Posted On",
    
         postedBy: "Posted By",
    
      
    }
  };
  
  export default comment;
  