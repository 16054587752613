import Table from "@/components/Table/table";
import { useColumnImportandExportDefs } from "./ImportAndExportColumnDef";
import { useParams } from "react-router-dom";
import {
  useGetImportAndExportListDataQuery,
} from "./ImportandExportReducers";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { useCustomCellRenderers } from "./ImportandExportCustomCellConst";
import { useCallback, useState } from "react";
import { useCase } from "@/features/case/use-case";
import TitleComplement from "./component/titleComplement";
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import CustomPagination from "@/components/Pagination/CustomPagination";
import './importAndExport.scss';

function ImportAndExport() {
  const { caseId } = useParams<{
    caseId: string;
  }>();
  const caseData = useCase(caseId);
  const paginationPageSize: number =customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
  let tableLoadingData=customTableLoadingData
  const [paginationObj, setPaginationObj] = useState <any>({ 
    CaseId:caseData?.caseId,
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",
    SortOrder: "desc",
    SortColumn: "CreatedOn",
  });

 
      
      let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({ createdOnType:{sort:"desc"}   })
      let resetSortStatus=useCallback((defaultSort:string)=>{
        let json:any={...sortedColumnTypes}
        for (const key in json) {
                      json[key]={sort:null}
        }
        json[defaultSort]={sort:"desc"}
        setSortedColumnTypes({...json})
      },[sortedColumnTypes])
      function handlePaginationData(data:any,columnTypesData:any){
        setPaginationObj({...data})
        setSortedColumnTypes({...columnTypesData})
      }


  const columnImportandExportDefs=useColumnImportandExportDefs()
  const { data, isLoading,isFetching,error } =
    useGetImportAndExportListDataQuery(paginationObj);



  const headerData = {
    title: "Import History",
    description: "Descriptive text that describes your page or content.",
  };
  const caseRenderers = useCustomCellRenderers();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = useCallback(
    (searchValue: string) => {
      if (searchValue.trim() === "") {

        setPaginationObj({
          CaseId:caseData?.caseId,
          PageNumber: 1,
          PageSize: paginationPageSize,
          SearchText: "",
          SortOrder: "desc",
          SortColumn: "CreatedOn",

        })
        resetSortStatus("createdOnType")
      } else {
        setPaginationObj({
          CaseId:caseData?.caseId,
          PageNumber: 1,
          PageSize: paginationPageSize,
          SearchText: searchValue,
          SortOrder: "desc",
          SortColumn: "CreatedOn",

        })
        resetSortStatus("createdOnType")      }
      setSearchQuery(searchValue);
    },
    [paginationPageSize, caseData,resetSortStatus]
  );
  const handleQuickSearchClear = useCallback(() => {
    setSearchQuery("");
    setPaginationObj({
      CaseId:caseData?.caseId,
      PageNumber: 1,
      PageSize: paginationPageSize,
      SortOrder: "desc",
      SortColumn: "CreatedOn",
      SearchText: "",
    })
    resetSortStatus("createdOnType")

  }, [paginationPageSize,caseData?.caseId,resetSortStatus]);

  if (isLoading ) {
    return <div> ...Loading </div>;
  }
  const tableData = data?.results?data.results:[]
  return (
    <>
      {isLoading ? (
        <MotifProgressLoader show variant="default" className="mar-b-20" />
      ) : (
        <div className="mar-b-20 import-export-table">
          <Table
            columnDefs={columnImportandExportDefs}
            columnTypes={{...sortedColumnTypes}}
            handlePaginationData={handlePaginationData}
                paginationObj={paginationObj}
            cellRenderers={isFetching?[]:tableData}
            TitleComplement={() => (
              <TitleComplement
                onSearch={handleSearch}
                handleQuickSearchClear={handleQuickSearchClear}
                searchQuery={searchQuery}
              />
            )}
            title={headerData.title}
            description={headerData.description}
            caseRenderers={caseRenderers}
            pagination={false}
            overlayNoRowsTemplate={isFetching?tableLoadingData:error?"Something Went Wrong":"No records found"}
          />
          <CustomPagination
          currentpage={paginationObj.PageNumber}
          pageSize={paginationObj.PageSize}
          disableNext={!data?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
        </div>
      )}
    </>
  );
}
export default ImportAndExport;
