import React from 'react'
import DistributionSummaryTab from './DistributionSummaryTab'

const Summary = () => {
  return (
    <DistributionSummaryTab/>
  )
}

export default Summary
