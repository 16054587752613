import React from "react";
import { Control, Controller, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import {useTranslation} from 'react-i18next';
import SelectBox from "./SelectBox";
import { CaseEditFormType } from "@/type";
import { STATUS_FIELD } from "@/Constant";

type ControlSelectType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<CaseEditFormType>;
    name: typeof STATUS_FIELD;
    showLabel: boolean;
    label: string;
    options: {
        label: string,
        value: string,
    }[];
    setValue: UseFormSetValue<CaseEditFormType>;
    getValues: UseFormGetValues<CaseEditFormType>;
}

export default function ControlSelect ({name, control, required=false, options, showLabel, label, setValue, getValues}: ControlSelectType) {
    const {t} = useTranslation();
    const status_initial_value = getValues(STATUS_FIELD);
    if (status_initial_value === undefined || status_initial_value === "" || status_initial_value === null) {
        setValue(STATUS_FIELD, t(options[0].value),{shouldDirty: false});
    }
    const translatedOptions = options.map(option => { 
        return {
            label: option.label,
            value: option.value,
        };
    })
    return (
        <> 
            {
                showLabel && <div className="formFieldLabel">
                    <label  htmlFor="input" aria-label={label}>{t(label)}{ required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
           <Controller
                name={name}
                control={control}
                rules={{ required }}
                defaultValue={options[0].value}
                render={({ field: { onChange, value }}) => {
                          return (
                          <>
                            <SelectBox   options={translatedOptions} value={value} onChange={onChange} />
                          </>
                          );
                    }}
            />
        </>
        
    );
}
