import React, { useEffect, useState } from "react";
import { MotifErrorMessage } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "@/components/Form/Button/Button";
import {  SECONDARY } from "@/Constant";
import "./FormModal.scss";
import ControlInputBoxNumber from "@/components/Form/InputBox/ControlInputBoxNumber";
import {
  useCreateCalculationSettingMutation,
  useGetCalculationStepSummaryApiDataQuery,
  useUpdateCalculationSettingMutation,
} from "../calculationStepReducer";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { compareNumeric } from "@/utility/validation";
//import { useCreateCalculationSettingsMutation } from "../calculationStepReducer";

type Props = {
  createShow: boolean;
  setCreateShow: React.Dispatch<React.SetStateAction<boolean>>;
  caseId: string;
  stepId: string;
  refetch: any;
  editId: any;
};

export default function FormModalCalculationSetting({
  setCreateShow,
  caseId,
  stepId,
  refetch,
  editId,
}: Props) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleCancel, setIsVisibleCancel] = useState(false);
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });
  const { data: calcSetting } =
    useGetCalculationStepSummaryApiDataQuery(editId);
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<any>();

  const [createCalculationSetting] = useCreateCalculationSettingMutation();
  const [updateCalculationSetting] = useUpdateCalculationSettingMutation();

  const setFormat = (value: any) => {
    return value === "" || value == null || value === 0 ? null : Number(value);
  };
  const onSubmit = async (data: any) => {
    const diff = compareNumeric(data?.capAmount, data?.floorAmount);
    if (diff < 0) {
      setError("capAmount", {
        type: "manual",
        message: "greater",
      });
      return;
    }
    let formData = {
      caseId: caseId,
      calculationStepId: stepId,
      capAmount: setFormat(data?.capAmount),
      floorAmount: setFormat(data?.floorAmount),
    };
    let editFormData = {
      id: editId,
      capAmount: setFormat(data?.capAmount),
      floorAmount: setFormat(data?.floorAmount),
    };
    setIsLoading(true);
    if (editId !== "") {
      const response = await updateCalculationSetting(editFormData);
      if (response.hasOwnProperty("data")) {
        setCreateShow(false);
        setIsLoading(false);
        refetch();
      } else {
        setIsLoading(false);
        setCreateShow(false);
      }
    } else {
      const response = await createCalculationSetting(formData);
      if (response.hasOwnProperty("data")) {
        setCreateShow(false);
        setIsLoading(false);
        refetch();
      } else {
        setIsLoading(false);
        setCreateShow(false);
      }
    }
  };
  useEffect(() => {
    setValue("capAmount", calcSetting?.capAmount);
    setValue("floorAmount", calcSetting?.floorAmount);
  }, [calcSetting, setValue]);
  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setCreateShow(false);
    setIsVisibleCancel(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisibleCancel(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };
  return (
    <>
      {/*@ts-ignore*/}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formModalWrapper">
          <div className="formModal">
            <div className="motif-row">
              <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
                <ControlInputBoxNumber
                  name={"capAmount"}
                  // @ts-ignore
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.distributions.distributionStepsTab.cap_Floor_AmountTab.formLabels.capAmount"}
                  showLabel={true}
                  isNumber={false}
                  digitAfterDecimal={8}
                  isAmount={true}
                  onChangeAmount={() => {}}
                  // validate={isNumeric}
                />
                {errors["capAmount"]?.message === "greater" && (
                  <MotifErrorMessage>
{t("pages.distributions.distributionStepsTab.cap_Floor_AmountTab.validationMessage.capAmountRequired")}                  </MotifErrorMessage>
                )}
              </div>
              <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
                <ControlInputBoxNumber
                  name={"floorAmount"}
                  // @ts-ignore
                  control={control}
                  required={false}
                  isTextArea={false}

                  label={"pages.distributions.distributionStepsTab.cap_Floor_AmountTab.formLabels.floorAmount"}
                  showLabel={true}
                  isNumber={false}
                  digitAfterDecimal={8}
                  isAmount={true}
                  onChangeAmount={() => {}}
                  // validate={isNumeric}
                />
              </div>
            </div>
          </div>
          <div className="button-container-add">
            <Button label={t("global.globalActions.save")} disabled={isLoading} />
            <Button
              label={t("global.globalActions.cancel")}
              type="button"
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={() => {
                setIsVisibleCancel(true);
                // setCreateShow(false);
              }}
            />
          </div>
        </div>
      </form>
      <ConfirmationModal
        isVisible={isVisibleCancel}
        setIsVisible={setIsVisibleCancel}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
    </>
  );
}
