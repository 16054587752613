import { Route, Routes } from "react-router-dom";
import Summary from "@/pages/CaseDetail/Summary/Summary";
import Debtor from "@/pages/CaseDetail/Debtor/Debtor";
import Creditor from "@/pages/CaseDetail/Creditor/Creditor";
import Claim from "@/pages/CaseDetail/Claim/Claim";
import Configuration from "@/pages/CaseDetail/Configuration/Configuration";
import Voting from "@/pages/CaseDetail/Voting/Voting";
import PaymentStatus from "@/pages/CaseDetail/PaymentStatus/PaymentStatus";
import AttributeSetup from "@/pages/CaseDetail/Configuration/Attributes/AttributeSetup";
import DebtorFormGrid from "@/pages/CaseDetail/Debtor/DebtorForm/DebtorFormGrid";
import UnitSetup from "@/pages/CaseDetail/Configuration/Units/UnitSetup";
import CreditorSummaryView from "@/pages/CaseDetail/Creditor/CreditorSummaryView/CreditorSummaryView";
import ClaimDetails from "@/pages/CaseDetail/Claim/ClaimDetails/ClaimDetails";
import ClaimSetup from "@/pages/CaseDetail/Claim/claimSetup/ClaimSetup";
import CreditorSetupGrid from "@/pages/CaseDetail/Creditor/CreditorSetup/CreditorSetupGrid";
import DebtorSummaryView from "@/pages/CaseDetail/Debtor/DebtorSummaryView";
import CreditorSummaryUpdate from "@/pages/CaseDetail/Creditor/CreditorSummaryUpdate/CreditorSummaryUpdate";
import CaseSetupGrid from "@/pages/CaseDetail/CaseSetup/CaseSetupGrid";
import { ClaimSummary } from "@/features/claimsummary/claimSummary";
import CreateCategory from "@/pages/CaseDetail/Configuration/category/createCategory";
import { EditCategoryAllInputs } from "@/pages/CaseDetail/Configuration/category/editCategoryAllInputs";
import SchemeSetup from "@/pages/CaseDetail/Configuration/Schemes/SchemeSetup";
import SchemeManage from "@/pages/CaseDetail/Configuration/Schemes/SchemeManage";
import VotingFormGrid from "@/pages/CaseDetail/Voting/VotingForm/votingFormGrid";
import DistributionSummary from "@/features/distributions/summery/DistributionSummary";
import DistributionSetupGrid from "@/features/distributions/distributionsSetup/DistributionSetupGrid";
import CalculationStepSummary from "@/features/distributions/steps/CalculationStepSummary";
import CalculationStepCreate from "@/features/distributions/steps/CalculationStepCreate";
import Distributions from "@/pages/CaseDetail/Distributions/Distributions";
import Distribution from "@/pages/CaseDetail/Distributions/Distribution/Distribution";
import SchemePreview from "@/pages/CaseDetail/Configuration/Schemes/Preview";
import ImportSummary from "@/pages/CaseDetail/Import&Export/ImportSummary";
import FormManagementTabs from "@/pages/CaseDetail/PortalConfiguration/FormMangementTabs";
import PayeeTypeForm from "@/pages/CaseDetail/Distributions/PayeeType/PayeeTypeForm";
import DuplicateDistribution from "@/features/distributions/distributionsSetup/DuplicateDistribution";
import ManualDataSourceEdit from "@/pages/CaseDetail/Distributions/ManualDataSource/ManualDataSourceEdit";
import RulesSetup from "@/pages/CaseDetail/Configuration/Schemes/Rules/RulesSetup";
import EditRules from "@/pages/CaseDetail/Configuration/Schemes/Rules/EditRules";
import PaymentSummary from "@/pages/CaseDetail/PaymentStatus/PaymentSummary";

export default function CaseRoutes() {
  return (
    <Routes>
      {/* /case/:caseId */}
      <Route path="/setup" element={<CaseSetupGrid />} />
      <Route path="/:caseId" element={<Summary />} />
      <Route path="/:caseId/summary" element={<Summary />} />
      <Route path="/:caseId/debtor" element={<Debtor />} />
      <Route path="/:caseId/debtor/edit/:editId" element={<DebtorFormGrid />} />
      <Route path="/:caseId/debtor/create" element={<DebtorFormGrid />} />
      <Route path="/:caseId/creditor" element={<Creditor />} />
      <Route
        path="/:caseId/creditor/creditor-setup"
        element={<CreditorSetupGrid />}
      />
      <Route
        path="/:caseId/creditor/summary-view/:creditorId"
        element={<CreditorSummaryView />}
      />
      <Route
        path="/:caseId/creditor/:creditorId/edit"
        element={<CreditorSummaryUpdate />}
      />
      <Route
        path="/:caseId/creditor/:creditorId"
        element={<CreditorSummaryView />}
      />
      <Route path="/:caseId/debtor/:debtorId" element={<DebtorSummaryView />} />
      <Route
        path="/:caseId/debtor/:debtorId/claim/:claimId"
        element={<ClaimSummary />}
      />
      <Route
        path="/:caseId/creditor/:creditorId/claim/:claimId"
        element={<ClaimSummary />}
      />
      <Route path="/:caseId/claim" element={<Claim />} />
      <Route
        path="/:caseId/claim/details/:claimItemId"
        element={<ClaimDetails />}
      />
      <Route path="/:caseId/claim/details/create" element={<ClaimDetails />} />
      <Route path="/:caseId/claim/claim-setup" element={<ClaimSetup />} />
      <Route path="/:caseId/claim/:claimId" element={<ClaimSummary />} />
      <Route path="/:caseId/configuration" element={<Configuration />} />
      <Route path="/:caseId/distributions" element={<Distributions />} />
      <Route
        path="/:caseId/configuration/attributes"
        element={<AttributeSetup />}
      />
      <Route
        path="/:caseId/configuration/payee/create"
        element={<PayeeTypeForm />}
      />
      <Route
        path="/:caseId/configuration/payee/edit/:payeeId"
        element={<PayeeTypeForm />}
      />
      <Route path="/:caseId/configuration/unit-setup" element={<UnitSetup />} />
      <Route path="/:caseId/create-category" element={<CreateCategory />} />
      <Route
        path="/:caseId/edit-category/:categoryId"
        element={<EditCategoryAllInputs />}
      />
      <Route path="/:caseId/voting" element={<Voting />} />
      <Route path="/:caseId/voting/create" element={<VotingFormGrid />} />
      <Route path="/:caseId/voting/edit/:editId" element={<VotingFormGrid />} />
      <Route path="/:caseId/voting/edit/:editId" element={<VotingFormGrid />} />
      <Route path="/:caseId/form/create" element={<FormManagementTabs />} />
      <Route
        path="/:caseId/form/edit/:editId"
        element={<FormManagementTabs />}
      />
      <Route path="/:caseId/distribution" element={<Distribution />} />
      <Route
        path="/:caseId/distribution/:distributionId"
        element={<DistributionSummary />}
      />
      <Route
        path="/:caseId/manual-data-source-edit/:manualDataSourceId"
        element={<ManualDataSourceEdit />}
      />
      {/* <Route path="/:caseId/distribution/:distributionId/calculation-step/:stepId" element={<CalculationStepSummary />} /> */}
      <Route
        path="/:caseId/distribution/:distributionId/calculation-step/create"
        element={<CalculationStepCreate />}
      />
      <Route
        path="/:caseId/distribution/:distributionId/calculation-step/:stepId/step-summary"
        element={<CalculationStepSummary />}
      />
      <Route path="/:caseId/payment-status" element={<PaymentStatus />} />
      <Route path="/:caseId/payment/:paymentId" element={<PaymentSummary />} />
      <Route
        path="/:caseId/configuration/scheme-setup"
        element={<SchemeSetup />}
      />
      <Route
        path="/:caseId/scheme-manage/:schemeId"
        element={<SchemeManage />}
      />
      <Route
        path="/:caseId/distribution/create"
        element={<DistributionSetupGrid />}
      />
      <Route
        path="/:caseId/scheme-manage/:schemeId/preview"
        element={<SchemePreview />}
      />
      <Route
        path="/:caseId/Import/importJobId/:importJobId"
        element={<ImportSummary />}
      />
      <Route
        path="/:caseId/duplicate-distribution/create"
        element={<DuplicateDistribution />}
      />
       <Route
        path="/:caseId/scheme-manage/:schemeId/create-rule"
        element={<RulesSetup />}
      />
      <Route
        path="/:caseId/scheme-manage/:schemeId/edit-rule"
        element={<EditRules />}
      />
    </Routes>
  );
}
