import React from "react";
import { Control, Controller, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import {useTranslation} from 'react-i18next';
import { CaseEditFormType } from "@/type";
import { STATUS_FIELD } from "@/Constant";
import SelectBox from "./SelectBox";

type ControlSelectType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<CaseEditFormType>;
    name: typeof STATUS_FIELD;
    showLabel: boolean;
    label: string;
    options: {
        label: string,
        value: string,
    }[];
    setValue: UseFormSetValue<CaseEditFormType>;
    getValues: UseFormGetValues<CaseEditFormType>;
    onChangeHandlerState?: (index: number, stateId: string, stateOptions:{label:string, value: string}[]) => void;
    index?: number;
    filter?: boolean | ((x?: any) => void) | undefined;
    searchPlaceholder? : string;
}
 

export default function ControlSelectState ({name, control, required=false, options, showLabel, label, onChangeHandlerState, index, filter, searchPlaceholder}: ControlSelectType) {
    const {t} = useTranslation();
    let defaultOption = {
        label: t("global.globalActions.optionDefault"),
        value: "",
      };
    if (options === undefined) {
        options =[defaultOption];
    }
  
    const translatedOptions = options.map(option => { 
        return {
            label: option.label,
            value: option.value,
        };
    })
    return (
        <> 
            {
                showLabel && <div className="formFieldLabel">
                    <label  htmlFor="input" aria-label={label}>{t(label)}{ required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
           <Controller
                name={name}
                control={control}
                rules={{ required }}
                defaultValue={translatedOptions[0]?.value}
                render={({ field: { onChange, value }}) => {
                            if (translatedOptions[0]?.value === undefined || translatedOptions[0]?.value === "" || translatedOptions[0]?.value === null) {
                                value = ""
                            }
                            return (
                            <>
                                <SelectBox   options={translatedOptions} value={value} onChange={onChange} onChangeHandlerState={onChangeHandlerState} index={index} filter={filter} searchPlaceholder={searchPlaceholder}/>
                            </>
                        );
                    }}
            />
        </>
        
    );
}
