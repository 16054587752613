import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
    MotifTabNavigation,
    MotifTabControl,
    MotifIcon,
} from "@ey-xd/motif-react";

import "./Distributions.css";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import { setCurrentTab } from "./DistributionsSlice";
import { DistributionsTab } from "./DistributionsType";
import { AccountSetUp } from "./Accounts/AccountSetUp";
import Distribution from "./Distribution/Distribution";
import { ManualDataSource } from "./ManualDataSource/ManualDataSource";
import { PayeeType } from "./PayeeType/PayeeType";
import RateTierTemplate from "./RateTierTemplate/RateTierTemplate";
export default function Distributions() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedTab =
        useSelector(
            (state: any) => state.distributions.currentTab
        );

    const renderTabContent = () => {
        switch (selectedTab) {
            case DistributionsTab.Distribution:
                return <Distribution />;
            case DistributionsTab.AccountSetUp:
                return <AccountSetUp />;
            case DistributionsTab.ManageDataSource:
                return <ManualDataSource />;
            case DistributionsTab.PayeeType:
                return <PayeeType />;
            case DistributionsTab.RateTierTemplate:
                return <RateTierTemplate />;
            default:
                return <></>;
        }
    };

    return (
        <>
            {window.location.pathname.includes("/distributions") && (
                <Breadcrumb
                    items={[
                        {
                            text: t("pages.distributions.backButton"),
                            url: "/",
                            icon: <MotifIcon src={IconoirArrowLeft} />,
                        },
                    ]}
                />
            )}
            <div className="distributions-container">
                <MotifTabNavigation
                    defaultActiveKey={selectedTab}
                    orientation="vertical"
                    controlled={true}
                >
                    <MotifTabControl
                        className={
                            selectedTab === DistributionsTab.Distribution ? "motif-active" : ""
                        }
                        key={DistributionsTab.Distribution}
                        onClick={() => dispatch(setCurrentTab(DistributionsTab.Distribution))}
                    >
                        {t("pages.distributions.distribution.title")}
                    </MotifTabControl>

                    <MotifTabControl
                        className={
                            selectedTab === DistributionsTab.ManageDataSource
                                ? "motif-active"
                                : ""
                        }
                        key={DistributionsTab.ManageDataSource}
                        onClick={() =>
                            dispatch(setCurrentTab(DistributionsTab.ManageDataSource))
                        }
                    >
                        {t("pages.distributions.manageDataSource.title")}
                    </MotifTabControl>

                    <MotifTabControl
                        className={
                            selectedTab === DistributionsTab.AccountSetUp ? "motif-active" : ""
                        }
                        key={DistributionsTab.AccountSetUp}
                        onClick={() => dispatch(setCurrentTab(DistributionsTab.AccountSetUp))}
                    >
                        {t("pages.distributions.accounts.title")}
                    </MotifTabControl>


                    <MotifTabControl
                        className={
                            selectedTab === DistributionsTab.PayeeType ? "motif-active" : ""
                        }
                        key={DistributionsTab.PayeeType}
                        onClick={() => dispatch(setCurrentTab(DistributionsTab.PayeeType))}
                    >
                        {t("pages.distributions.PayeeType.title")}
                    </MotifTabControl>

                    <MotifTabControl
                        className={
                            selectedTab === DistributionsTab.RateTierTemplate ? "motif-active" : ""
                        }
                        key={DistributionsTab.RateTierTemplate}
                        onClick={() => dispatch(setCurrentTab(DistributionsTab.RateTierTemplate))}
                    >
                        {t("pages.distributions.rateTierTemplate.title")}
                    </MotifTabControl>


                </MotifTabNavigation>

                <div className="distributions-tab-content">{renderTabContent()}</div>
            </div>
        </>
    );
}
