import TabPanel from "@/components/TabPanel/TabPanel";
import DebtorTabContent from "@/features/debtor/claimsTab/claimsTabContent";
import DebtorSummaryHeader from "./DebtorSummaryHeader";

export default function DebtorSummaryView() {
  const tabPanelData = {
    title: ["Summary", "Claims"],
    content: [<>Debtor Summary</>, <DebtorTabContent />],
  };

  return (
    <>
      <DebtorSummaryHeader />
      <div className="ccwp_table_wrapper ccwp_table_dropdown_wrapper">
        <TabPanel data={tabPanelData} />
      </div>
    </>
  );
}
