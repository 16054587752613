import { PostProcessorModule } from "i18next";

const showTranslated = (): PostProcessorModule => {
  return {
    type: "postProcessor",
    name: "showTranslated",
    process: function (
      value: string,
      key: string,
      options: any,
      translator: any
    ) {
      /* return manipulated value */
      // console.log("key", key, value);
      if (key && key[0] === value) {
        return `{{${key}}}`;
      }
      return `_${value}_`;
    },
  };
};

export default showTranslated;
