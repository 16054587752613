import FormattedDatePicker from "@/components/FormattedDatePicker/FormattedDatePicker";
import {
  useGetCountryStateListDataQuery,
  useGetStateByCountryIdQuery,
} from "@/services/countryState/countryStateReducer";
import {
  MotifButton,
  MotifFormField,
  MotifLabel,
  MotifInput,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const BasicSearch = ({
  handleChange,
  creditorName,
  creditorId,
  claimId,
  debtorName,
  country,
  province,
  postalCode,
  phoneNumber,
  claimUpdateDate,
  setShowBasicSearch,
  handleSubmit,
}: any) => {
  const {t}=useTranslation();
  const [countryId, setCountryId] = useState("");
  const { data: countryList, isLoading } = useGetCountryStateListDataQuery("");
  const { data: stateList, isLoading: stateLoader } =
    useGetStateByCountryIdQuery(countryId, { skip: !countryId });
  const [countryArray, setCountryArry] = useState([]);
  const [stateArray, setStateArry] = useState([]);
  useEffect(() => {
    if (countryList !== undefined) {
      setCountryArry(countryList || []);
    }
  }, [countryList, isLoading]);
  useEffect(() => {
    if (stateList !== undefined) {
      setStateArry(stateList || []);
    }
  }, [stateArray,stateLoader,stateList]);
  return (
    <div className="claims-basic-search-container">
      <div className="motif-row">
        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifLabel>{t("pages.creditors.creditorBasicSearch.creditorName")}</MotifLabel>
          <MotifFormField>
            <MotifInput
              /*@ts-ignore */
              onChange={(e: any) =>
                handleChange("creditorName", e.target.value)
              }
              value={creditorName}
              hideClearButton={true}
            />
          </MotifFormField>
        </div>
        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifLabel>{t("pages.creditors.creditorBasicSearch.creditorId")}</MotifLabel>
          <MotifFormField>
            <MotifInput
              /*@ts-ignore */
              onChange={(e: any) => handleChange("creditorId", e.target.value)}
              value={creditorId}
              hideClearButton={true}
            />
          </MotifFormField>
        </div>
        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifLabel>{t("pages.creditors.creditorBasicSearch.claimId")}</MotifLabel>
          <MotifFormField>
            <MotifInput
              /*@ts-ignore */
              onChange={(e: any) => handleChange("claimId", e.target.value)}
              value={claimId}
              hideClearButton={true}
            />
          </MotifFormField>
        </div>
        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifLabel>{t("pages.creditors.creditorBasicSearch.debtorName")}</MotifLabel>
          <MotifFormField>
            {/*@ts-ignore */}
            <MotifInput
              /*@ts-ignore */
              onChange={(e: any) => handleChange("debtorName", e.target.value)}
              value={debtorName}
              hideClearButton={true}
            />
          </MotifFormField>
        </div>
        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifLabel>{t("pages.creditors.creditorBasicSearch.country")} </MotifLabel>
          <MotifFormField>
            {/*@ts-ignore */}

            {/* <MotifInput
              @ts-ignore
              onChange={(e: any) => handleChange("country", e.target.value)}
              value={country}
              hideClearButton={true}
            /> */}
            <MotifSelect
              onChange={(value) => {
                setCountryId(value);
                handleChange("country", value);
              }}
              value={country}
              visibleOptions={3}
              placeholder={t("global.globalPlaceholder.countryPlaceholder")}
              filter={true}
            >
              {countryArray.map((option: any) => (
                <MotifOption value={option.countryId}>
                  {option.countryName}
                </MotifOption>
              ))}
            </MotifSelect>
          </MotifFormField>
        </div>
        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifLabel>{t("pages.creditors.creditorBasicSearch.provinceState")} </MotifLabel>
          <MotifFormField>
            {/*@ts-ignore */}
            {/* <MotifInput
              /*@ts-ignore
              onChange={(e: any) => handleChange("province", e.target.value)}
              value={province}
              hideClearButton={true}
            /> */}
            <MotifSelect
              onChange={(value) => {
                handleChange("province", value);
              }}
              value={province}
              visibleOptions={3}
              placeholder={t("global.globalPlaceholder.statePlaceholder")}
              filter={true}
            >
              {stateArray.map((option: any) => (
                <MotifOption value={option.id}>{option.name}</MotifOption>
              ))}
            </MotifSelect>
          </MotifFormField>
        </div>
        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifLabel>{t("pages.creditors.creditorBasicSearch.postalCode")} </MotifLabel>
          <MotifFormField>
            {/*@ts-ignore */}
            <MotifInput
              /*@ts-ignore */
              onChange={(e: any) => handleChange("postalCode", e.target.value)}
              value={postalCode}
              hideClearButton={true}
            />
          </MotifFormField>
        </div>
        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifLabel>{t("pages.creditors.creditorBasicSearch.phoneNumber")} </MotifLabel>
          <MotifFormField>
            {/*@ts-ignore */}
            <MotifInput
              /*@ts-ignore */
              onChange={(e: any) => handleChange("phoneNumber", e.target.value)}
              value={phoneNumber}
              hideClearButton={true}
            />
          </MotifFormField>
        </div>
        <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
          <MotifLabel>{t("pages.creditors.creditorBasicSearch.claimUpdatedDate")}</MotifLabel>
          <MotifFormField>
            <FormattedDatePicker
              todayMark={true}
              range={false}
              onChange={(value) => handleChange("claimUpdateDate", value)}
              value={claimUpdateDate}
            />
          </MotifFormField>
        </div>
        <div className="basic-search-buttons">
          <MotifButton
            size="small"
            type="button"
            variant="secondary"
            onClick={() => setShowBasicSearch(false)}
          >
            {t("global.globalActions.cancel")}
          </MotifButton>

          <MotifButton
            size="small"
            variant="primary"
            onClick={() => {
              setShowBasicSearch(false);
              handleSubmit();
            }}
          >
                        {t("global.globalActions.apply")}

          </MotifButton>
        </div>
      </div>
    </div>
  );
};
