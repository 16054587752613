import GreetingSection from "@/features/caselist/components/GreetingSection";
import QuickActions from "@/features/caselist/components/QuickActions";
import CaseListTable from "@/features/caselist/components/CaseListTable";
import "@/features/caselist/style.scss";
export default function CaseList() {
  return (
    <div className="ccwp_caselist_wrapper">
      <GreetingSection />
      <div className="ccwp_caselist_subwrapper">
        <QuickActions/>
        <CaseListTable />
      </div>
    </div>
  );
}
