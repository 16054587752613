import {
  CATEGORY_DESC_NAME,
  CATEGORY_NAME,

  CATEGORY_DESC_FR_NAME,
  CATEGORY_NAME_FR,
} from "./constant";
import { useForm } from "react-hook-form";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import { EditSummaryFormType } from "./type";
import ControlInputBox from "./createCategoryInputField";
import Button from "@/components/Form/Button/Button";
import {
  MotifButton,
  MotifCard,
  MotifCardBody,
  MotifCardFooter,
  MotifCardHeader,
  MotifErrorMessage,
  MotifIcon,
} from "@ey-xd/motif-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCategoryByIdApiDataQuery } from "@/services/configuration/configuration";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useCase } from "@/features/case/use-case";
import { useUpdateClaimItemCategoryMutation } from "@/services/claimSummary/caseSummaryReducer";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
import { useTranslation } from "react-i18next";

export const EditCategoryAllInputs = () => {
  const navigate = useNavigate();
  const categoryId = useParams();
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);
  const { data } = useGetCategoryByIdApiDataQuery(categoryId.categoryId);
  const [isLoading, setIsLoading] = useState(false);
  const caseData = useCase();
  const {t}=useTranslation()
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors ,isDirty},
  } = useForm<EditSummaryFormType>(
    {
      defaultValues: {  
        "categoryName": data?.name,
        "caetegoryDescName": data?.description,
        "categoryFrName": data?.nameFrench,
        "categoryDescFrName": data?.descriptionFrench
      }
    }
  );
  const [updateClaimItemCategory] = useUpdateClaimItemCategoryMutation();
  const onSubmit = async (formData: EditSummaryFormType) => {
    let categoryData = {
      id: categoryId.categoryId,
      name: formData.categoryName,
      nameFrench: formData.categoryFrName,
      description: formData.caetegoryDescName,
      descriptionFrench: formData.categoryDescFrName,
    };
    setIsLoading(true);
    dispatch(setDiscardEditMode({ editMode: false }));

    const response = await updateClaimItemCategory(categoryData);
    if (response.hasOwnProperty("data")) {
      navigate(`/case/${caseData.caseId}?activeTab=5`);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      dispatch(setDiscardEditMode({ editMode: true }));

      console.log("error in creating category");
    }
  };
  const dispatch=useDispatch()
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,isDirty]);
  useEffect(() => {
    reset( {  
      "categoryName": data?.name,
      "caetegoryDescName": data?.description,
      "categoryFrName": data?.nameFrench,
      "categoryDescFrName": data?.descriptionFrench
    })
  
  }, [data,reset]);
  return (
    <>
      {" "}
      <div className="attribute-setup-container">
        <MotifCard variant="card-no-border">
          <MotifCardHeader>
            <div className="attributes-back-button">
              <Breadcrumb
                items={[
                  {
                    text: t("global.globalActions.back"),
                    icon: <MotifIcon src={IconoirArrowLeft} />,
                    url: `/case/${caseData?.caseId}?activeTab=5`,
                  },
                ]}
                ariaLabel="Breadcrumb navigation"
              />
            </div>
            <div className="motif-h3-default-bold">{t("pages.configuration.category.categoryEditTitle")}</div>
          </MotifCardHeader>
          <MotifCardBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="motif-col-md-4  mar-t-20">
                {" "}
                <div className="form-field mar-b-20">
                  <ControlInputBox
                    name={CATEGORY_NAME}
                    control={control}
                    required={true}
                    isTextArea={false}
                    label={"pages.configuration.category.formLabels.categoryName"}
                    showLabel={true}
                  />
                  {errors[CATEGORY_NAME] &&
                    errors[CATEGORY_NAME].type === "required" && (
                      <MotifErrorMessage>{t("pages.configuration.category.validationMessages.categoryRequired")}</MotifErrorMessage>

                    )}
                </div>
                <div className="form-field mar-b-20">
                  <ControlInputBox
                    name={CATEGORY_DESC_NAME}
                    control={control}
                    isTextArea={true}
                    label={"pages.configuration.category.formLabels.categoryDescription"}
                    showLabel={true}
                  />
                </div>
                <div className="form-field mar-b-20">
                  <ControlInputBox
                    name={CATEGORY_NAME_FR}
                    control={control}
                    isTextArea={false}
                    label={"pages.configuration.category.formLabels.categoryNameFrench"}
                    showLabel={true}
                  />
                  {errors[CATEGORY_NAME_FR] &&
                    errors[CATEGORY_NAME_FR].type === "required" && (
                      <MotifErrorMessage>
                        Task Name is required
                      </MotifErrorMessage>
                    )}
                </div>
                <div className="form-field">
                  <ControlInputBox
                    name={CATEGORY_DESC_FR_NAME}
                    control={control}
                    isTextArea={true}
                    label={"pages.configuration.category.formLabels.categoryDescriptionFrench"}
                    showLabel={true}
                  />
                  {errors[CATEGORY_DESC_FR_NAME] &&
                    errors[CATEGORY_DESC_FR_NAME].type === "required" && (
                      <MotifErrorMessage>
                        Task Description is required
                      </MotifErrorMessage>
                    )}
                </div>
              </div>
            </form>
          </MotifCardBody>{" "}
          <MotifCardFooter>
            <div className="attributes-footer-buttons">
              <MotifButton
                onClick={() => {
                  if(isDirty){
                    setIsDiscardVisible(true);      dispatch(setDiscardEditMode({ editMode: false }));

                  }
                  else{
                    navigate(`/case/${caseData.caseId}?activeTab=5`);

                  }
              }}
                size="small"
                variant="ghost"
                style={{ marginTop: "10px" }}
              >
                            {t("global.globalActions.cancel")}

              </MotifButton>
              <Button
            label={t("global.globalActions.save")}
            variant="primary"
                className="motif-button"
                onClickHandler={handleSubmit(onSubmit)}
                type={isLoading ? "loader" : "submit"}
                disabled={isLoading}
              />
            </div>
          </MotifCardFooter>
        </MotifCard>
        <ConfirmationModal
          isVisible={isDiscardVisible}
          setIsVisible={setIsDiscardVisible}
          cancelBtnApproveHandler={() => {
            navigate(`/case/${caseData.caseId}?activeTab=5`);
          }}
          cancelBtnRejectHandler={() => {
            dispatch(setDiscardEditMode({ editMode: true }));

            setIsDiscardVisible(false);
          }}
        />
      </div>
    </>
  );
};
