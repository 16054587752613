import { useGetDistributionSummaryByIdQuery } from "@/services/distribution/distributionReducers";
import { getStatusDescription } from "@/utility/common";
import {
  MotifButton,
  MotifProgressLoader,
  MotifToast,
} from "@ey-xd/motif-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DistributionEditForm from "../distributionsSetup/distributionEdit/DistributionEditForm";
import { useTranslation } from "react-i18next";
import { dateFunc } from "@/components/Functions/dateFunc";
import { setDistributionStatus } from "@/pages/CaseDetail/Distributions/DistributionsSlice";
import { useDispatch } from "react-redux";
import GenerateDraftCalculation from "./GenerateDraftCalculation";

const DistributionSummaryTab = () => {
  const { distributionId } = useParams();
  const { data, isLoading, refetch } = useGetDistributionSummaryByIdQuery({
    id: distributionId,
  });
  const closeMessageHandler = () => {
    setNotification({ ...notification, show: false });
  };
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const renderMessage = () => {
    if (notification.show) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={notification.type}
        >
          {" "}
          {notification.type === "error"
            ? t("global.globalNotifications.unExpectedError")
            : t("global.globalNotifications.updatedSuccessfully")}
        </MotifToast>
      );
    }
  };
  const caseDetails = [
    { title: t("pages.distributions.distribution.distributionFormLabels.distributionName"), value: data?.name },
    { title: t("pages.distributions.distribution.distributionFormLabels.distributionNameFrench"), value: data?.nameFrench },
    { title: t("pages.distributions.distribution.distributionFormLabels.description"), value: data?.description },
    {
      title: t("pages.distributions.distribution.distributionFormLabels.descriptionFrench"),
      value: data?.descriptionFrench,
    },
    { title: t("pages.distributions.distribution.distributionFormLabels.createdFromPreviousDistribution"), value: "" },
    { title:t("pages.distributions.distribution.distributionFormLabels.status"), value: getStatusDescription(data?.distributionStatus) },
    { title: t("pages.distributions.distribution.distributionFormLabels.distributionDate"), value: dateFunc(data?.distributionDate).formattedDateOnly },

    { title: t("pages.distributions.distribution.distributionFormLabels.effectiveDate"), value: dateFunc(data?.effectiveDate).formattedDateOnly },
    { title:  t("pages.distributions.distribution.distributionFormLabels.createdOn"), value: dateFunc(data?.createdOn).formattedDateTime },
    { title:  t("pages.distributions.distribution.distributionFormLabels.entitlementCalculation"), value: dateFunc(data?.lastEntitlementRunDate).formattedDateOnly },
    { title: t("pages.distributions.distribution.distributionFormLabels.lastSettlementCalculation"), value: dateFunc(data?.lastSettlementRunDate).formattedDateOnly },
  ];
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "success",
  });
  useEffect(() => {
    if (data?.distributionStatus !== undefined) {
      dispatch(setDistributionStatus(data.distributionStatus));
    }
    //eslint-disable-next-line
  }, [notification,data]);

  if (isEdit) {
    //@ts-ignore
    return (
      <DistributionEditForm
        distributionData={data}
        setIsEdit={setIsEdit}
        notification={notification}
        setNotification={setNotification}
        refetch={refetch}
      />
    );
  }
  interface CaseDetail {
    title: string;
    value: string;
  }

  const numberOfColumns = 4;
  const chunkArray = (array: CaseDetail[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const caseDetailsFields = chunkArray(caseDetails, numberOfColumns);

  function handleSummaryApiAfterSettlementDone(){
    refetch();
  }

  return (
    <div>
      {isLoading ? (
        <MotifProgressLoader show variant="default" className="mar-b-20" />
      ) : (
        <>
          {renderMessage()}
          <div className="motif-container case-summary-form pad-v-20 mar-t-20">
            <div className="motif-row justify-content-between align-items-center">
              <div className="motif-col-4">
                <div className="motif-body1-default-regular page-title">
                {t("pages.distributions.distribution.distributionViewPage.title")}
                </div>
                <div className="motif-body2-default-regular page-description">
                {t("pages.distributions.distribution.distributionViewPage.description")}
                </div>
              </div>
              <div className="motif-col-4">
                <MotifButton
                  size="small"
                  type="submit"
                  variant="secondary"
                  onClick={() => setIsEdit(true)}
                  disabled={data?.distributionStatus === 3}
                >
                  {t("global.globalActions.edit")}
                </MotifButton>
              </div>
            </div>
          </div>
          <div className="motif-container main-container pad-v-60">
            {caseDetailsFields.map((caseDetailsField, rowIndex) => (
              <div
                key={rowIndex}
                className={`motif-row ${rowIndex > 0 ? "pad-t-40" : ""}`}
              >
                {caseDetailsField.map((detail, colIndex) => (
                  <div key={colIndex} className="motif-col-lg-3">
                    <div>
                      <div className="title">{detail.title}</div>
                      <div className="motif-body2-default-bold">
                        {detail.value}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
         <GenerateDraftCalculation
         handleSummaryApiAfterSettlementDone={handleSummaryApiAfterSettlementDone}
         />
        </>
      )}     
    </div>
  );
};

export default DistributionSummaryTab;
