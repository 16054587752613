import React from "react";

import "./error.scss";

interface ErrorBoundaryState {
  hasError: boolean;
  children?: any;
}

class ErrorBoundary extends React.Component<
  { children?: React.ReactNode },
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
    this.handleNav = this.handleNav.bind(this);
  }

  handleNav() {
    this.setState({ hasError: false });
    window.location.href = "/";
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    // logErrorToMyService(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-container">
          <h1>Something went wrong.</h1>
          <p>
            Try refreshing the page or{" "}
            <a href="/" onClick={this.handleNav}>
              click here
            </a>{" "}
            to go back to the home page.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
