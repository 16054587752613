import { ccwpApi } from "@/services";
// Define a service using a base URL and expected endpoints
export const TimeZoneListApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getTimeZoneList: builder.query<any, any>({
      query: () => `/Common/ListTimeZone`,
    }),
  }),
});

export const {
  useGetTimeZoneListQuery,
  useLazyGetTimeZoneListQuery,
} = TimeZoneListApi;

 


