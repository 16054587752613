import ControlSelect from "@/components/Form/SelectBox/ControlSelect";
import CustomDisplayBoolean from "./CustomDisplayBoolean";
import ControlCheckBox from "../../ControlCheckBox";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./customDisplayField.scss";
import CustomDisplayText from "./CustomDisplayText";
import CustomDisplayLabel from "./CustomDisplayLabel";

export default function CustomDisplayField({
  index,
  control,
  field,
  setValue,
}: {
  index: number;
  control: any;
  field: any;
  setValue: any;
}) {
  const { t } = useTranslation();
  const [fieldState, setFieldState] = useState<any>(field);

  function handleChange(value: string) {
    if (value) {
      setFieldState({ ...fieldState, fieldType: value });
    }
  }

  function renderField(fieldType: string, field = fieldState) {
    if (!fieldType) {
      return "";
    }

    switch (fieldType) {
      case "":
        return "";
      case "freeText":
        return <CustomDisplayText index={index} control={control} />;
      case "boolean":
        return <CustomDisplayBoolean index={index} control={control} />;
      case "label":
        return (
          <CustomDisplayLabel
            index={index}
            control={control}
            field={field}
            setValue={setValue}
          />
        );
      default:
        return "";
    }
  }
  useEffect(() => {
    console.log(field, "field 1");
  }, [field]);

  return (
    <div className="motif-container custom-display-field">
      <div className="motif-row align-items-center">
        <div className="motif-col-lg-4">
          <ControlSelect
            label={t("pages.portalConfig.labels.fieldType")}
            name={`fields[${index}].fieldType`}
            control={control}
            required={true}
            showLabel={true}
            handleChange={handleChange}
            options={[
              {
                value: "",
                label: t("pages.portalConfig.labels.selectField"),
              },
              {
                value: "boolean",
                label: t("pages.portalConfig.labels.boolean"),
              },
              {
                value: "freeText",
                label: t("pages.portalConfig.labels.freeText"),
              },
              {
                value: "label",
                label: t("pages.portalConfig.labels.label"),
              },
            ]}
            value={field.fieldType || ""}
          />
        </div>
        {fieldState.fieldType === "label" ? (
          <>
            <div className="motif-col-lg-2">
              <br />
              <ControlCheckBox
                name={`fields[${index}].forSignature`}
                control={control}
                checked={field.forSignature}
                value={field.forSignature}
                required={false}
                label={t("pages.portalConfig.labels.forSignature")}
                onChange={() => {}}
              />
            </div>
            <div className="motif-col-lg-2">
              <br />
              <ControlCheckBox
                name={`fields[${index}].displayOnPDF`}
                control={control}
                checked={field.displayOnPDF}
                value={field.displayOnPDF}
                required={false}
                label={t("pages.portalConfig.labels.displayOnPDF")}
                onChange={() => {}}
              />
            </div>
            <div className="motif-col-lg-2">
              <br />
              <ControlCheckBox
                name={`fields[${index}].useEntireRow`}
                control={control}
                checked={field.useEntireRow}
                value={field.useEntireRow}
                required={false}
                label={t("pages.portalConfig.labels.useEntireRow")}
                onChange={() => {}}
              />
            </div>
          </>
        ) : (
          <div className="motif-col-lg-8">
            <br />
            <ControlCheckBox
              name={`fields[${index}].isRequired`}
              control={control}
              checked={field.isRequired}
              value={field.isRequired}
              required={false}
              label={t("pages.portalConfig.labels.required")}
              onChange={() => {}}
            />
          </div>
        )}
      </div>
      {renderField(fieldState.fieldType)}
    </div>
  );
}
