import Table from "@/components/Table/table";
import { useState } from "react";
import { useColumnCalimsItemHistoryDefs } from "@/features/creditor/claimsTab/Constant/ClaimsAttributeHistory";
import { useParams } from "react-router-dom";
import {
  useGetClaimsHistoryDataQuery,
  useGetClaimsItemsHistoryDataQuery,
} from "@/services/claimSummary/caseSummaryReducer";
import {
  formatAmountUnitDecimalPlace,
} from "@/utility/common";
import {
  MotifFormField,
  MotifProgressLoader,
  MotifRadioButton,
  MotifToggleSwitch,
} from "@ey-xd/motif-react";

import { dateFunc } from "@/components/Functions/dateFunc";
import CustomPagination from "@/components/Pagination/CustomPagination";
import {
  customPaginationPageSize,
  customPaginationPageSizeSelector,
  customTableLoadingData,
} from "@/components/Table/CustomPaginationConstants";
import { useCase } from "@/features/case/use-case";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const TitleComplement = () => {
  return <></>;
};

export default function ClaimsHistoryTab() {
  const [showHistoryGrid, setshowHistoryGrid] = useState("");
  const [isDefaultUnit, setIsDefaultUnit] = useState(true);

  const { claimId } = useParams<{
    claimId: string;
    caseId: string;
  }>();
  const paginationPageSize: number = customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
  let tableLoadingData = customTableLoadingData;

  let [sortedColumnTypesIteams, setSortedColumnTypesIteams] = useState<any>({});
  const [paginationObj, setPaginationObj] = useState<any>({
    ClaimId: claimId,
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",
    SortOrder: "desc",
    SortColumn: "ModifiedOn",
  });
  let [sortedColumnTypes, setSortedColumnTypes] = useState<any>({
    modifiedOnType: { sort: "desc" },
  });

  function handlePaginationData(data: any, columnTypesData: any) {
    setPaginationObj({ ...data });
    setSortedColumnTypes({ ...columnTypesData });
  }

  const [paginationObjClaimItem, setPaginationObjClaimItem] = useState<any>({
    ClaimHistorySnapshotId: "",
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",
  });
  const caseData = useCase();
  function handlePaginationDataCliemItem(data: any, orderData: any) {
    setPaginationObjClaimItem({ ...data });
    setSortedColumnTypesIteams({ ...orderData });
  }
  const columnCalimsItemHistoryDefs = useColumnCalimsItemHistoryDefs(
    caseData?.abbreviation
  );

  const { data, isLoading, isFetching, error } =
    useGetClaimsHistoryDataQuery(paginationObj);
  const { defaultUnit } = useSelector((state: any) => state.case);
  const decimalPlace = defaultUnit?.decimalPlace;
  const defaultUnitName = defaultUnit?.name;
  const amountCellRenderer = (field: string) => (params: any) => {
    const amount = params.data[field];
    const formattedDefaultAmount = formatAmountUnitDecimalPlace(
      amount,
      decimalPlace
    );
    return (
      <div>
        {formattedDefaultAmount !== "-"
          ? `${formattedDefaultAmount} ${defaultUnitName}`
          : formattedDefaultAmount}
      </div>
    );
  };

  const sortedData = data?.results ? data.results : [];
  const headerData = {
    title: "Attribute History",
    description: "Descriptive text that describes your page or content.",
  };    
  const {t}=useTranslation()


  const getColumnCalimsHistoryDefs = (timeZone: string) =>{
    return [
    {
      cellRenderer: function (params: any) {
        return (
          <>
            <MotifFormField>
              <MotifRadioButton
                name="reactiveOption"
                onChange={function Ua(event) {
                  setPaginationObjClaimItem({
                    ClaimHistorySnapshotId: event.target.value,
                    PageNumber: 1,
                    PageSize: paginationPageSize,
                    SearchText: "",
                  });
                  setshowHistoryGrid(event.target.value);
                }}
                supportText=""
                value={params.data.id}
                checked={params.data.id === showHistoryGrid}
              >
                {" "}
              </MotifRadioButton>
            </MotifFormField>
          </>
        );
      },
      field: "id",
      headerName: t("pages.claims.claimHistory.claimHistoryTable.tableColumnsDetails.column1"),
      maxWidth: 80,
      sortable: false,
      unSortIcon: true,
    },
    {
      comparator: () => 0,
      field: "claimStageName",
      type: "claimStageNameType",
      headerName: t("pages.claims.claimHistory.claimHistoryTable.tableColumnsDetails.column2"),

      sortable: true,
      unSortIcon: true,
    },
    {
      comparator: () => 0,
      field: "claimStatusName",
      type: "claimStatusNameType",
      headerName: t("pages.claims.claimHistory.claimHistoryTable.tableColumnsDetails.column3"),

      sortable: true,
      unSortIcon: true,
    },
    {
      comparator: () => 0,
      field: "comment",
      type: "commentType",
      headerName:t("pages.claims.claimHistory.claimHistoryTable.tableColumnsDetails.column4"),

      sortable: true,
      unSortIcon: true,
    },
    {
      cellRenderer: amountCellRenderer("endOfDayCurrentAmount"),
      comparator: () => 0,
      field: "endOfDayCurrentAmount",
      type: "endOfDayCurrentAmountType",
      headerName: t("pages.claims.claimHistory.claimHistoryTable.tableColumnsDetails.column5"),
      cellClass: "align-right",

      headerClass: "align-right",
      sortable: true,
      unSortIcon: true,
    },
    {
      cellRenderer: amountCellRenderer("endOfDayResolvedAmount"),
      comparator: () => 0,
      field: "endOfDayResolvedAmount",
      type: "endOfDayResolvedAmountType",
      headerName:t("pages.claims.claimHistory.claimHistoryTable.tableColumnsDetails.column6"),
      cellClass: "align-right",
      headerClass: "align-right",

      sortable: true,
      unSortIcon: true,
    },
    {
      comparator: () => 0,
      field: "endOfDayClaimItemCount",
      type: "endOfDayClaimItemCountType",
      headerName: t("pages.claims.claimHistory.claimHistoryTable.tableColumnsDetails.column7"),

      sortable: true,
      unSortIcon: true,
      headerClass: "align-right",
      cellClass: "align-right",
      cellRenderer: function (params: any) {
        return (
          <div>
            {params.data?.endOfDayClaimItemCount === null
              ? "-"
              : params.data?.endOfDayClaimItemCount}
          </div>
        );
      },
    },
    {
      comparator: () => 0,
      field: "endOfDayResolvedClaimItemCount",
      type: "endOfDayResolvedClaimItemCountType",
      headerName: t("pages.claims.claimHistory.claimHistoryTable.tableColumnsDetails.column8"),

      sortable: true,
      unSortIcon: true,
      headerClass: "align-right",
      cellClass: "align-right",
      cellRenderer: function (params: any) {
        return (
          <div>
            {params.data?.endOfDayResolvedClaimItemCount === null
              ? "-"
              : params.data?.endOfDayResolvedClaimItemCount}
          </div>
        );
      },
    },
    {
      comparator: () => 0,
      field: "modifiedBy",
      type: "modifiedByType",
      headerName:t("pages.claims.claimHistory.claimHistoryTable.tableColumnsDetails.column9"),

      headerClass: "align-right",
      sortable: true,
      unSortIcon: true,
    },
    {
      comparator: () => 0,
      field: "modifiedOn",
      type: "modifiedOnType",
      headerName: t("pages.claims.claimHistory.claimHistoryTable.tableColumnsDetails.column10"),
      flex: 1,
      minWidth: 200,
      headerClass: "align-right",
      sortable: true,
      unSortIcon: true,
      cellRendererParams: {
        timeZoneName: timeZone,
      },
      cellRenderer: function (params: any) {
        return (
          dateFunc(params.data.modifiedOn).formattedDateTime +
          " " +
          params.timeZoneName
        );
      },
    },
  ]};
  const {
    data: claimHistoryResult,
    isFetching: claimItemFetching,
    error: claimItemError,
  } = useGetClaimsItemsHistoryDataQuery(paginationObjClaimItem, {
    skip: showHistoryGrid === "",
  });
  const claimHistoryData = claimHistoryResult?.results
    ? claimHistoryResult.results
    : [];
  return (
    <>
      <div className={"ccwp_claim_claimlisttable"}>
        {isLoading ? (
          <MotifProgressLoader show variant="default" className="mar-b-20" />
        ) : (
          <div className="mar-b-20">
            <Table
              columnDefs={getColumnCalimsHistoryDefs(caseData?.abbreviation)}
              columnTypes={{ ...sortedColumnTypes }}
              handlePaginationData={handlePaginationData}
              paginationObj={paginationObj}
              cellRenderers={isFetching ? [] : sortedData}
              TitleComplement={TitleComplement}
              title={"pages.claims.claimHistory.claimHistoryTable.title"}
              description={
                "pages.claims.claimHistory.claimHistoryTable.description"}
              caseRenderers={headerData}
              pagination={false}
              overlayNoRowsTemplate={
                isFetching
                  ? t(tableLoadingData)
                  : error
                  ? t("global.globalNotifications.wentWrong")
                  : t("global.globalNotifications.noRecords")
              }
            />
            <CustomPagination
              currentpage={paginationObj.PageNumber}
              pageSize={paginationObj.PageSize}
              disableNext={!data?.hasMoreRecords}
              onChangePageSize={(page: number) => {
                setPaginationObj({
                  ...paginationObj,
                  PageSize: page,
                  PageNumber: 1,
                });
              }}
              onChangeNextPage={() => {
                setPaginationObj({
                  ...paginationObj,
                  PageNumber: paginationObj.PageNumber + 1,
                });
              }}
              onChangePreviousPage={() => {
                setPaginationObj({
                  ...paginationObj,
                  PageNumber: paginationObj.PageNumber - 1,
                });
              }}
              pageSizeSelector={paginationPageSizeSelector}
            />
          </div>
        )}
      </div>
      <div
        className={`ccwp_claim_claimlisttable ${
          isDefaultUnit ? "with-default-unit" : "with-historical-unit"
        }`}
      >
        <Table
          columnDefs={columnCalimsItemHistoryDefs}
          columnTypes={{ ...sortedColumnTypesIteams }}
          handlePaginationData={handlePaginationDataCliemItem}
          paginationObj={paginationObjClaimItem}
          cellRenderers={claimItemFetching ? [] : claimHistoryData}
          TitleComplement={() => (
            <div>
              <MotifFormField>
                <MotifToggleSwitch
                  id="defaultUnitToggle"
                  onLabel=""
                  offLabel="Default Unit"
                  checked={isDefaultUnit}
                  onChange={() => {
                    setIsDefaultUnit((prev) => !prev);
                  }}
                />
              </MotifFormField>
            </div>
          )}
          title={"pages.claims.claimHistory.claimItemHistoryTable.title"}
          description={"pages.claims.claimHistory.claimItemHistoryTable.description"}
          caseRenderers={headerData}
          pagination={false}
          overlayNoRowsTemplate={
            claimItemFetching
              ? t(tableLoadingData)
              : claimItemError
              ? t("global.globalNotifications.wentWrong")
              : t("global.globalNotifications.noRecords")
          }
        />
        <CustomPagination
          currentpage={paginationObjClaimItem.PageNumber}
          pageSize={paginationObjClaimItem.PageSize}
          disableNext={!claimHistoryResult?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObjClaimItem({
              ...paginationObjClaimItem,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObjClaimItem({
              ...paginationObjClaimItem,
              PageNumber: paginationObjClaimItem.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObjClaimItem({
              ...paginationObjClaimItem,
              PageNumber: paginationObjClaimItem.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
      </div>
    </>
  );
}