import React, { useState, useCallback } from "react";
import {
  MotifButton,
  MotifButtonGroup,
  MotifDropdown,
  MotifDropdownItem,
  MotifDropdownPortal,
  MotifFormField,
  MotifIcon,
  MotifChip,
} from "@ey-xd/motif-react";
import {
  IconoirNavArrowDown,
  IconoirMoreVert,
  IconoirPlus,
} from "@ey-xd/motif-icon";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setShowBasicSeach } from "../../../claims/claimsSlice";
import { useNavigate } from "react-router-dom";
import {
  useExportAdvanceSearchMutation,
  useLazyExportDocumentQuery,
} from "@/services/exports/exportsReducer";
import ClaimBulkImport from "./ClaimBulkImport";
import UpdateStatus from "./UpdateStatus";
import { useLazyDownloadTemplateDocumentQuery } from "@/services/manualDataSource/ManualDataSourceReducer";
import SearchInput from "@/components/SearchInput/SearchInput";

interface TitleComplementProps {
  caseId: string;
  onSearch: (searchValue: string) => void;
  searchQuery: string;
  handleQuickSearchClear: () => void;
  isBasicSearch: boolean;
  handleClearBasicSearch: () => void;
  setShowAdvanceSearch: (isVisible: boolean) => void;
  isAdvanceSearch: boolean;
  showAdvanceSearch: boolean;
  showBasicSearch: boolean;
  advanceSearchPayload: any;
  basicSearchPayload: any;
  selectedClaimIds: any;
  clearSelectedClaimIds?: (closeTrigger: boolean) => void;
  allClaimsSelected: boolean;
  isAdvanceSearchActive: boolean;
  isBasicSearchActive: boolean;
  isSearchActive: boolean;
  paginationObj: any;
}

const TitleComplement: React.FC<TitleComplementProps> = ({
  caseId,
  onSearch,
  searchQuery,
  handleQuickSearchClear,
  isBasicSearch,
  handleClearBasicSearch,
  setShowAdvanceSearch,
  isAdvanceSearch,
  showAdvanceSearch,
  showBasicSearch,
  advanceSearchPayload,
  basicSearchPayload,
  selectedClaimIds,
  clearSelectedClaimIds = () => { },
  allClaimsSelected,
  isAdvanceSearchActive,
  isBasicSearchActive,
  isSearchActive,
  paginationObj,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [searchValue, setSearchValue] = useState(searchQuery);
  const [exportAdvanceSearch] = useExportAdvanceSearchMutation();
  const [exportDocument] = useLazyExportDocumentQuery();
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);

  const [validationError, setValidationError] = useState<string | null>(null);
  const debtorTitleMenu = ["pages.claims.claimsTable.moreOptions.export", "pages.claims.claimsTable.moreOptions.bulkImport","pages.claims.claimsTable.moreOptions.bulkStatusUpdate"];
  // const claimTitleMenu = ["Add New Claim", "Bulk Import"];
  const [downloadDocument] = useLazyDownloadTemplateDocumentQuery();

  const handleMenuClick = (indexNo: number) => {
    if (indexNo === 0) {
      if (isAdvanceSearch) {
        exportAdvanceSearch({
          url: "ClaimAdvancedSearch/ExportAdvancedClaims",
          data: advanceSearchPayload,
        });
        return;
      } else {
        exportDocument(
          isBasicSearch
            ? {
              url: "Claim/ExportBasicClaims",
              ...basicSearchPayload,
            }
            : {
              url: "/Claim/ExportClaims",
              caseId,
              SearchText: searchQuery,
            }
        );
        return;
      }
    }

    if (indexNo === 2) {
      if (!validateSelectedClaims(selectedClaimIds)) {
        setValidationError(
"pages.claims.claimsTable.bulkUpdateStatus.bulkStatusUpdateMessage"       );
        setShowUpdateStatusModal(true);
      } else {
        setValidationError(null);
        setShowUpdateStatusModal(true);
      }
      return;
    }
    if (indexNo === 1) {
      setShowBulkUploadModal(true);
      return;
    }
  };

  // const handleDropdownMenuClick = (menuName: string) => {
  //   if (menuName === "Add New Claim") {
  //     navigate(`/case/${caseId}/claim/claim-setup`);
  //   }
  //   if (menuName === "Bulk Import") {
  //     setShowBulkUploadModal(true);
  //   }
  // };

  const handleSearchChange = useCallback(
    (event: { target: { value: React.SetStateAction<string> } }) => {
      setSearchValue(event.target.value);
    },
    []
  );

  const validateSelectedClaims = (selectedClaims: any[]) => {
    if (selectedClaims.length === 0) return true;

    const { actionStatusName, claimStageName } = selectedClaims[0];

    for (let i = 1; i < selectedClaims.length; i++) {
      if (
        selectedClaims[i].actionStatusName !== actionStatusName ||
        selectedClaims[i].claimStageName !== claimStageName
      ) {
        return false;
      }
    }

    return true;
  };

  const handleDownloadBulkImportTemplate = () => {
    downloadDocument({
      entityType: "2",
    });
  };

  const handleOnSearch = useCallback(() => {
    onSearch(searchValue);
  }, [onSearch, searchValue]);

  const filteredDebtorTitleMenu =
    selectedClaimIds.length > 1
      ? debtorTitleMenu
      : debtorTitleMenu.filter((menuName,index) => index !== 2);

  return (
    <>
      <div
        className={
          showAdvanceSearch ? "show-and-hide-quicksearch" : "show-quick-search"
        }
      >
        <MotifFormField>
          {!showAdvanceSearch && !showBasicSearch ? (
            <SearchInput
              aria-label="Search"
              onChange={handleSearchChange}
              onClear={handleQuickSearchClear}
              onFocusShow={false}
              value={searchQuery}
              onEnter={handleOnSearch}
              placeholder={t("pages.claims.search.placeholder")}
            />
          ) : (
            ""
          )}
          <MotifButtonGroup>
            <MotifButton
              variant="secondary"
              onClick={() => {
                dispatch(setShowBasicSeach(false));
              }}
            >
              {t("pages.claims.search.title")}
            </MotifButton>
            <MotifDropdownPortal
              open={showMenu}
              hideArrow={true}
              handleClickOutside={() => setShowMenu(false)}
              trigger={
                <MotifButton
                  type="button"
                  onClick={() => setShowMenu(!showMenu)}
                  aria-label="Dropdown button"
                  aria-haspopup="true"
                  variant="secondary"
                >
                  <MotifIcon
                    aria-label="Dropdown icon"
                    fill="none"
                    iconFunction={IconoirNavArrowDown}
                  />
                </MotifButton>
              }
            >
              <MotifDropdownItem
                onClick={() => {
                  dispatch(setShowBasicSeach(true));
                  setShowAdvanceSearch(false);
                }}
              >
                {t("pages.claims.basicSearch.title")}
              </MotifDropdownItem>
              <MotifDropdownItem
                onClick={() => {
                  dispatch(setShowBasicSeach(false));
                  setShowAdvanceSearch(true);
                }}
              >
                {t("pages.claims.advancedSearch.title")}
              </MotifDropdownItem>
            </MotifDropdownPortal>
          </MotifButtonGroup>

          {isBasicSearch || isAdvanceSearch ? (
            <MotifChip
              className="clear-search-chip"
              onClick={handleClearBasicSearch}
              variant="monochrome-dark"
            >
              {t("pages.claims.basicSearch.clearSearch")}
            </MotifChip>
          ) : (
            ""
          )}

          <MotifDropdown
            ariaLabelledby="dropdown-trigger-1"
            id="dropdown-1"
            trigger={
              <MotifButton
                aria-label="Search Dropdown"
                id="dropdown-trigger-1"
                onClick={function Ga() { }}
                type="button"
                variant="text"
              >
                <MotifIcon iconFunction={IconoirMoreVert} />
              </MotifButton>
            }
          >
            <ul role="menu">
              {filteredDebtorTitleMenu.map((menuName: string,indexNo:number) => {
                // const isBulkStatusUpdate = menuName === "Bulk Status Update";
                // const isDisabled =
                //   isBulkStatusUpdate && selectedClaimIds.length === 0;
                return (
                  <li role="menuitem" key={menuName}>
                    <MotifDropdownItem
                      indexItem={1}
                      onClick={() =>handleMenuClick(indexNo)}
                    >
                      {t(menuName)}
                    </MotifDropdownItem>
                  </li>
                );
              })}
            </ul>
          </MotifDropdown>
          <div style={{ "marginTop": "14px", "cursor": "pointer", "opacity": 0.8 }} onClick={() => {
            navigate(`/case/${caseId}/claim/claim-setup`)

          }}>
            <MotifIcon size="19" iconFunction={IconoirPlus} />
          </div>
          {/* <MotifDropdown
            ariaLabelledby="dropdown-trigger-1"
            id="dropdown-1"
            trigger={
              <MotifButton
                aria-label="dropdown button"
                id="dropdown-trigger-1"
                type="button"
                variant="text"
              >
                <MotifIcon iconFunction={IconoirPlus} />
              </MotifButton>
            }
          >
            <ul role="menu">
              {claimTitleMenu.map((menuName: string) => {
                return (
                  <li role="menuitem" key={menuName}>
                    <MotifDropdownItem
                      indexItem={1}
                      onClick={() => handleDropdownMenuClick(menuName)}
                    >
                      {t(menuName)}
                    </MotifDropdownItem>
                  </li>
                );
              })}
            </ul>
          </MotifDropdown> */}
        </MotifFormField>
      </div>
      {showBulkUploadModal && (
        <ClaimBulkImport
          showModal={true}
          setShowModal={setShowBulkUploadModal}
          title={t("pages.claims.claimsTable.bulkImport.title")}
          description={t("pages.claims.claimsTable.bulkImport.description")}
          onDownload={handleDownloadBulkImportTemplate}
          uploadLabel={t("pages.claims.claimsTable.bulkImport.uploadLabel")}
          uploadDescription={t("pages.claims.claimsTable.bulkImport.uploadDescription")}
          accept={t("pages.claims.claimsTable.bulkImport.accept")}
          maxFiles={1}
          onClose={() => {
            setShowBulkUploadModal(false);
          }}
        />
      )}
      {showUpdateStatusModal && (
        <UpdateStatus
          showModal={true}
          setShowModal={setShowUpdateStatusModal}
          title="pages.claims.claimsTable.bulkUpdateStatus.title"
          onClose={() => {
            setShowUpdateStatusModal(false);
          }}
          isError={validationError ?? ""}
          selectedClaimIds={selectedClaimIds}
          clearSelectedClaimIds={clearSelectedClaimIds}
          isAdvanceSearchActive={isAdvanceSearchActive}
          advanceSearchPayload={advanceSearchPayload}
          isBasicSearchActive={isBasicSearchActive}
          basicSearchPayload={basicSearchPayload}
          isSearchActive={isSearchActive}
          paginationObj={paginationObj}
          allClaimsSelected={allClaimsSelected}
        />
      )}
    </>
  );
};

export default TitleComplement;
