import { useTranslation } from "react-i18next";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import CreditorDetails from "./CreditorDetails";
import "./CreditorSetup.scss";
import PageTitle from "@/components/Form/PageTitle/PageTitle";
import { useState } from "react";
import ContactDetails from "./ContactDetails";
import ReviewForm from "./ReviewForm/ReviewForm";
import CreditorAttributes from "./CreditorAttributes";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "@/app/store";
import { useSelector } from "react-redux";
import TabPanelCreditor from "./TabPanelCreditor/TabPanelCreditor";

export default function CreditorSetupGrid() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { caseId } = useParams();
 
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);
  const stage = useSelector(
    (state: RootState) => state.creditorSetup.stage
  );


  const tabPanelData = {
    title: ["pages.creditors.creditorForm.tabPannelDetails.creditorDetails", "pages.creditors.creditorForm.tabPannelDetails.contactDetails", "pages.creditors.creditorForm.tabPannelDetails.creditorAttributes"],
    content: [
      <CreditorDetails
      />,
      <ContactDetails
      />,
      <CreditorAttributes
      />,
    ],
  };
  const breadcrumbItems = [
    {
      label: "External Submission",
      value: "10 New Creditor Submission",
      elementKey: "submission",
      text: t("global.globalActions.backToHome"),
      url: "",
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];
  return (
    <>
      <div className="ccwp_creditor-setup_wrapper">
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>navigate(`/case/${caseId}?activeTab=2`)
        }
        >
          <Breadcrumb
            items={breadcrumbItems}
            ariaLabel="Breadcrumb navigation"
          />
        </div>
        <div className="motif-container creditor-setup pad-l-60 pad-t-40 pad-r-40">
          <div className="motif-row t-b-padding-21">
            <div className="motif-col-xs-4">
              <PageTitle
                title={"pages.creditors.creditorForm.createFormTitle"}
                description={"pages.creditors.creditorForm.createFormDescription"}
              />
            </div>
          </div>
        </div>
        <div className="ccwp_creditor_setup_tabpanel   pad-t-60">
          {stage > 2 ? (
            <ReviewForm
            />
          ) : (
            <TabPanelCreditor
              data={tabPanelData}
              orientation="vertical"
            />
          )}
        </div>
      </div>
      <ConfirmationModal
        isVisible={isDiscardVisible}
        setIsVisible={setIsDiscardVisible}
        cancelBtnApproveHandler={() => {
          navigate(`/case/${caseId}?activeTab=2`);
        }}
        cancelBtnRejectHandler={() => {
          setIsDiscardVisible(false);
        }}
      />
    </>
  );
}