import { MotifButton, MotifIcon } from "@ey-xd/motif-react";
import { IconoirEditPencil } from "@ey-xd/motif-icon";
import { Link } from "react-router-dom";

export const useCustomCellRenderers = (
  handleEditClick: any
) => {
  const caseRenderers = {
    actionCellRenderers: function (param: any) {
      return (
        <>
          {/* <div className="pad-r-10">
            <MotifButton
              size="small"
              variant="primary"
            >
              <MotifIcon
                iconFunction={IconoirTrash}
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
          </div> */}
          <Link to={`./voting/edit/` + param.data.id}>
            <MotifButton
              onClick={() => {
                // setShowModal(true);
                // setActiveClaim(param.data.totalNumberOfClaims);
                // setDebtorId(param.data.id);
                handleEditClick();
              }}
              size="small"
              type="submit"
              variant="primary"
              // disabled={param.data.isActive == true ? false : true}
            >
              <MotifIcon
                iconFunction={IconoirEditPencil}
                // fill="#2E2E38"
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
          </Link>
        </>
      );
    },
  };
  return caseRenderers;
};
