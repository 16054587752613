const caseSummary = {
  title: "Case details",
  description: "Descriptive text that describes your page or content.",
  fieldLabelDetails: {
    caseName: "Case Name",
    caseDescription: "Case Description",
    caseShortName: "Case Short Name",
    status: "Status",
    statusOpen: "Open",
    statusClose: "Close",
    caseNameFrench: "Case Name (French)",
    caseDescriptionFrench: "Case Description (French)",
    caseTeam: "Case Team",
    teamMember: "Team Member",
    role: "Role",
    caseIdentifiers: "Case Identifiers",
    caseTeamSetup: "Case Team Setup",
    inviteUserPlaceholder: "Search For Team Member within",
    appDirectory: "App Directory",
    eyDirectory: "EY Directory",
    directoryPlaceholder: "Directory",
    rolePlaceHolder: "Select Role",
    currentTeamMembers: "Current Team Members",
    userDeleteMessage: "Do you want to remove the user: {{userName}}",
    caseViewer: "Case Viewer",
    caseContributor: "Case Contributor",
    caseManager: "Case Manager",
    userExistMessage: "User already exists in the case",
    userInvitedMessage: "User Invited Successfully",
    updatedRoleMessage: "User Role Updated Successfully",
    fillAllFieldsMessage:
      "Please complete all required fields to continue. {{notFilledFields}} cannot be left blank",
    caseTeamSetupErrorMessages: "Select {{notFilledFields}}",
  },
};

export default caseSummary;
