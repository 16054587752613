import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClaimSetupDebtor } from './type';

export interface DebtorSearchData {
  searchDebtorData: ClaimSetupDebtor;
  selectedDebtorCompleteDetail: any,
}

const initialState: DebtorSearchData = {
  searchDebtorData: { debtorItem: "", debtor: "" },
  selectedDebtorCompleteDetail: ""
};

export const claimSetupDebtorSearch = createSlice({
  name: 'claimSetupDebtorSearch',
  initialState,
  reducers: {
    setSearchValue: (state, action: PayloadAction<ClaimSetupDebtor>) => {
      state.searchDebtorData = action.payload;
    },
    setSelectedDebtorCompleteDetail: (state, action: PayloadAction<any>) => {
      state.selectedDebtorCompleteDetail = action.payload;
    },
    resetDebtorState: (state) => {
      return initialState;
    },
  },
});

export const { setSearchValue, setSelectedDebtorCompleteDetail, resetDebtorState } = claimSetupDebtorSearch.actions;

export default claimSetupDebtorSearch.reducer;
