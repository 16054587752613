import { ccwpApi } from "@/services";
import {
  GETSTAGES,
  CREATESTAGE,
  UPDATESTAGE,
  CREATESTATUS,
  UPDATESTATUS,
  GETCLAIMSTAGESTATUS,
  GETVALIDSTAGEMOVES,
  UPDATECLAIMSTAGESTATUS,
  CREATECLAIMSTAGESTATUS,
} from "../constant/apiPath";

export const stagesApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getStages: builder.query<any, string>({
      query: (caseId) => ({
        url: GETSTAGES + caseId + "&IncludeChildStatuses=true",
        method: "GET",
      }),
    }),

    createStage: builder.query({
      query: (stageData) => ({
        url: CREATESTAGE,
        method: "POST",
        body: stageData,
      }),
    }),

    updateStage: builder.mutation({
      query: (stageData) => ({
        url: UPDATESTAGE,
        method: "PUT",
        body: stageData,
      }),
    }),

    deleteStage: builder.query({
      query: ({claimStatusDefinitionId, caseId}) => ({
        url: `ClaimStage/DeleteClaimStage?claimStageDefinitionId=${claimStatusDefinitionId}&caseId=${caseId}`,
        method: "DELETE",
      }),
    }),

    createStatus: builder.mutation({
      query: (statusData) => ({
        url: CREATESTATUS,
        method: "POST",
        body: statusData,
      }),
    }),

    updateStatus: builder.mutation({
      query: (statusData) => ({
        url: UPDATESTATUS,
        method: "PUT",
        body: statusData,
      }),
    }),

    deleteStatus: builder.query({
      query: ({claimStatusDefinitionId, caseId}) => ({
        url: `ClaimStage/DeleteClaimStatus?claimStatusDefinitionId=${claimStatusDefinitionId}&caseId=${caseId}`,
        method: "DELETE",
      }),
    }),

    getClaimStageStatus: builder.query({
      query: (claimId) => ({
        url: GETCLAIMSTAGESTATUS + claimId,
        method: "GET",
      }),
    }),

    getValidStageMoves: builder.query({
      query: ({ CaseId, ClaimId }) => ({
        url: GETVALIDSTAGEMOVES,
        method: "GET",
        params: { CaseId, ClaimId },
      }),
    }),

    updateClaimStageStatus: builder.mutation({
      query: (formData) => ({
        url: UPDATECLAIMSTAGESTATUS,
        method: "POST",
        body: formData,
      }),
    }),

    createClaimStageStatus: builder.mutation({
      query: (formData) => ({
        url: CREATECLAIMSTAGESTATUS,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const {
  useLazyGetStagesQuery,
  useGetStagesQuery,
  useLazyCreateStageQuery,
  useUpdateStageMutation,
  useLazyDeleteStageQuery,
  useCreateStatusMutation,
  useUpdateStatusMutation,
  useLazyDeleteStatusQuery,
  useLazyGetClaimStageStatusQuery,
  useGetValidStageMovesQuery,
  useLazyGetValidStageMovesQuery,
  useUpdateClaimStageStatusMutation,
  useCreateClaimStageStatusMutation,
} = stagesApi;
