// a react hook to get case from redux or server using caseId
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLazyGetCaseSummaryQuery } from "@/services/caseSummary/caseSummaryReducer";
import { useLazyGetUnitsQuery } from "@/services/unit/unitReducers";
import { RootState } from "@/app/store";
import { CaseState, setCase } from "@/features/case/case-slice";

export const useCase = (currentCaseId?: string, doFetch = false) => {
  const dispatch = useDispatch();
  const { caseId } = useParams<{ caseId: string }>();
  const currentCaseData = useSelector((state: RootState) => state.case);
  const thisCaseId = currentCaseId || caseId;
  const [getCase, { data: caseData, isLoading }] = useLazyGetCaseSummaryQuery();
  const [getUnits, { isLoading: isUnitsLoading }] = useLazyGetUnitsQuery();

  const updateCase = useCallback(
    async (caseId: string) => {
      const [caseData, caseUnits] = await Promise.all([
        getCase(caseId).unwrap(),
        getUnits(caseId).unwrap(),
      ]);

      const newCaseData: CaseState = {
        caseId: caseData?.caseId,
        caseName: caseData?.caseName,
        caseNameFrench: caseData?.caseNameFrench || "",
        caseDescription: caseData?.caseDescription,
        caseDescriptionFrench: caseData?.caseDescriptionFrench,
        caseShortName: caseData?.caseShortName,
        timeZone: caseData?.timeZone,
        abbreviation: caseData?.abbreviation,
        caseStatus: caseData?.caseStatus,
        caseData,
        caseUnits,
        defaultUnit: null,
      };
      if (caseUnits) {
        newCaseData.defaultUnit =
          caseUnits?.find((unit: any) => unit.isDefaultCurrency) || null;
      }

      dispatch(setCase(newCaseData));
    },
    [dispatch, getCase, getUnits]
  );

  useEffect(() => {
    if (doFetch && thisCaseId && !currentCaseData) {
      updateCase(thisCaseId);
      return;
    }

    if (
      doFetch &&
      !isLoading &&
      !isUnitsLoading &&
      thisCaseId &&
      thisCaseId !== currentCaseData.caseId
    ) {
      updateCase(thisCaseId);
    }

    return () => {
      // cleanup
    };
  }, [
    thisCaseId,
    currentCaseData,
    isLoading,
    isUnitsLoading,
    updateCase,
    doFetch,
  ]);

  return {
    caseUnits: currentCaseData.caseUnits,
    defaultUnit: currentCaseData.defaultUnit,
    caseId: currentCaseId || caseId || caseData?.caseId,
    caseName: currentCaseData?.caseName,
    caseNameFrench: currentCaseData?.caseNameFrench,
    caseDescription: currentCaseData?.caseDescription,
    caseDescriptionFrench: currentCaseData?.caseDescriptionFrench,
    caseShortName: currentCaseData?.caseShortName,
    timeZone: currentCaseData?.timeZone,
    abbreviation: currentCaseData?.abbreviation,
    caseStatus: currentCaseData?.caseStatus,
    ...caseData,
  };
};
