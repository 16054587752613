import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	MotifModal,
	MotifModalHeader,
	MotifModalBody,
	MotifLabel,
	MotifFormField,
	MotifInput,
	MotifTextArea,
	MotifModalFooter,
	MotifButton,
	MotifCheckbox,
} from "@ey-xd/motif-react";
import {
	useCreateClassMutation,
	useUpdateClassMutation,
} from "@/services/class/classReducer";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { ClassType } from "../type";
const CreateEditClass = ({
	show,
	setShow,
	classData,
	isEdit,
	refetch,
	classRowData,
}: {
	show: boolean;
	setShow: (show: boolean) => void;
	classData: ClassType;
	isEdit: boolean;
	refetch: () => void;
	classRowData: ClassType[];
}) => {
	const { t } = useTranslation();
	const [inputValues, setInputValues] = useState(classData);
	const [isLoading, setIsLoading] = useState(false);
	const [createClass] = useCreateClassMutation();
	const [updateClass] = useUpdateClassMutation();
	const [isDiscardVisible, setIsDiscardVisible] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	useEffect(() => {
		if (show) {
			setInputValues(classData);
		}
		// eslint-disable-next-line
	}, [show]);

	const handleSubmit = async () => {
		setIsLoading(true);
		setErrorMessage("");
		const defaultClass = classRowData.find(
			(classItem: ClassType) => classItem.isDefault
		);

		if (isEdit) {
			const requestBody = { ...inputValues };
			delete requestBody.schemeVersionId;
			requestBody.displayOrder = inputValues.displayOrder;
			await updateClass(requestBody).then((response: any) => {
				if (response.data) {
					refetch();
					handleClose();
				} else {
					if (response.error.data?.title) {
						setErrorMessage(response.error.data.title);
					} else {
						setErrorMessage(response.error.data?.message);
					}
					setIsLoading(false);
				}
			});
		} else {
			if (defaultClass && inputValues.isDefault) {
				const defaultClassUpdate = { ...defaultClass, isDefault: false };
				await updateClass(defaultClassUpdate);
			}
			const requestBody = {
				...inputValues,
			};
			delete requestBody.id;
			requestBody.displayOrder = classRowData.length + 1;
			await createClass(requestBody).then((response: any) => {
				if (response.data) {
					refetch();
					handleClose();
				} else {
					if (response.error.data?.title) {
						setErrorMessage(response.error.data.title);
					} else {
						setErrorMessage(response.error.data.message);
					}
					setIsLoading(false);
				}
			});
		}
	};

	const handleClose = () => {
		setIsLoading(false);
		setIsDiscardVisible(false);
		setShow(false);
	};

	return (
		<>
			<MotifModal
				className="create-edit-stage"
				onClose={() => setIsDiscardVisible(true)}
				show={show}
				focusTrapOptions={{
					tabbableOptions: {
						displayCheck: "none",
					},
				}}
			>
				<MotifModalHeader>
					{isEdit ? (
						<span>{t("pages.configuration.scheme.class.editClass")}</span>
					) : (
						<span>{t("pages.configuration.scheme.class.addClass")}</span>
					)}
				</MotifModalHeader>

				<MotifModalBody>
					{errorMessage && (
						<NotificationGrid
							show={true}
							message={errorMessage}
							type={"error"}
						/>
					)}
					<MotifLabel>
						{t("pages.configuration.scheme.class.className")}
						<span className="required-input">*</span>
					</MotifLabel>
					<MotifFormField>
						<MotifInput
							//@ts-ignore
							onChange={(e) =>
								setInputValues({ ...inputValues, name: e.target.value })
							}
							value={inputValues.name}
						/>
					</MotifFormField>

					<MotifLabel>
						{t("pages.configuration.scheme.class.classDescription")}
					</MotifLabel>
					<MotifFormField>
						<MotifTextArea
							onChange={(e) =>
								setInputValues({ ...inputValues, description: e.target.value })
							}
							value={inputValues.description}
						/>
					</MotifFormField>
					<MotifCheckbox
						onChange={() => {
							setInputValues({
								...inputValues,
								isDefault: !inputValues.isDefault,
							});
						}}
						checked={inputValues.isDefault}
					>
						{t("pages.configuration.scheme.class.defaultClass")}
					</MotifCheckbox>
				</MotifModalBody>
				<MotifModalFooter>
					<MotifButton
						onClick={() => setIsDiscardVisible(true)}
						type="button"
						variant="secondary"
					>
						{t("pages.configuration.scheme.class.cancel")}
					</MotifButton>
					<MotifButton
						onClick={handleSubmit}
						type={isLoading ? "loader" : "submit"}
						variant="primary"
						disabled={!inputValues.name}
					>
						{t("pages.configuration.scheme.class.save")}
					</MotifButton>
				</MotifModalFooter>
			</MotifModal>
			<ConfirmationModal
				isVisible={isDiscardVisible}
				setIsVisible={setIsDiscardVisible}
				cancelBtnApproveHandler={handleClose}
				cancelBtnRejectHandler={() => setIsDiscardVisible(false)}
			/>
		</>
	);
};
export default CreateEditClass;
