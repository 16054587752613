import './CreditorSummary.scss';
import { MotifIcon } from '@ey-xd/motif-react';
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb';
import { useGetCreditorSummaryHeaderDataQuery } from './CreditorSummaryReducer';
import Banner, { BannerElement } from '@/components/Banner/Banner';
import { useCase } from "@/features/case/use-case";
import { useTranslation } from 'react-i18next';

function CreditorSummaryHeader({
  caseId,
  creditorId,
}: {
  caseId: string;
  creditorId: string;
}) {
  const propData = {
    caseId: caseId,
    creditorId: creditorId || "",
  };
  const {t}=useTranslation();
  const { data, error, isLoading } =
    useGetCreditorSummaryHeaderDataQuery(propData);
  const caseData = useCase(caseId);
  if (isLoading) return <div>{t("global.globalNotifications.loadingMessage")}</div>;
  if (error) return <div>{t("global.globalNotifications.dataNotFoundMessage")}</div>;
  const breadcrumbItems = [
    {
      label: "External Submission",
      value: "10 New Creditor Submission",
      elementKey: "submission",
      text: caseData?.caseName || t("pages.creditors.creditorSummaryBreadCrums.backToCase"),
      url: `/case/${caseId}?activeTab=2`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
    {
      label: "External Submission",
      value: "10 New Creditor Submission",
      elementKey: "submission",
      text: data?.creditorName ? data?.creditorName : "",
      url: "/",
      disabled: true,
    },
  ];

  const bannerElements: BannerElement[] = [
    {
      label: "pages.creditors.creditorSummaryBanner.creditorId",
      // value: `${propData.creditorId}`,
      value: `${
        data?.creditorReference === null ? "-" : data?.creditorReference
      }`,
    },
    {
      label: "pages.creditors.creditorSummaryBanner.distributionStatus",
      value: `${
        data?.distributionStatus === null ? "" : data?.distributionStatus
      }`,
    },
    {
      label: "pages.creditors.creditorSummaryBanner.totalClaims",
      value: `${data?.totalClaims}`,
    },
  ];

  return (
    <>
      <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
      <Banner
        bannerName="pages.creditors.creditorSummaryBanner.bannerTitle"
        bannerNameValue={data?.creditorName}
        elements={bannerElements}
      />
    </>
  );
}

export default CreditorSummaryHeader;