import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifCard,
  MotifCardBody,
  MotifIconButton,
  MotifIcon,
} from "@/libs/@ey-xd/motif-react";
import {
  IconoirEditPencil,
  IconoirTrash,
  IconoirArrowDown,
  IconoirArrowUp,
} from "@ey-xd/motif-icon";
import "./Stages.css";

import { StageType, OrderChange, PositionType } from "./StagesType";
import CreateEditStage from "./CreateEditStage";
import DeleteStageStatus from "./DeleteStageStatus";
import NotificationGrid from "@/components/Notification/NotificationGrid";

export default function StageTile({
  stageData,
  selectedStage,
  setSelectedStage,
  getStageData,
  handleOrderChange,
}: {
  stageData: StageType;
  selectedStage: string;
  setSelectedStage: (stageId: string) => void;
  getStageData: (isCreate: boolean) => void;
  handleOrderChange: (change: OrderChange, id: string) => void;
}) {
  const { t } = useTranslation();
  const [showCreateStage, setShowCreateStage] = useState(false);
  const [showDeleteStage, setShowDeleteStage] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");

  return (
    <>
      <div className="stage-tile-container">
        <MotifCard
          className={
            "stage-tile" + (selectedStage === stageData.id ? " active" : "")
          }
          orientation="horizontal"
          variant="card-border"
        >
         {deleteErrorMessage && (
            <NotificationGrid
              show={true}
              message={deleteErrorMessage}
              type="error"
            />
          )}
          <MotifCardBody className="stage-tile-body">
            <div
              className="stage-tile-header"
              onClick={() => setSelectedStage(stageData.id!)}
            >
              <span className="motif-body1-default-bold">{stageData.name}</span>
              <span>
                {stageData.position === PositionType.DebtorPosition
                  ? t("pages.configuration.stages.debtorPosition")
                  : t("pages.configuration.stages.creditorPosition")}
              </span>
            </div>
            <div className="stage-tile-actions">
              <div className="action-icons">
                <MotifIconButton
                  onClick={() => setShowDeleteStage(true)}
                  type="button"
                >
                  <MotifIcon iconFunction={IconoirTrash} />
                </MotifIconButton>
                <MotifIconButton
                  onClick={() => setShowCreateStage(true)}
                  type="button"
                >
                  <MotifIcon iconFunction={IconoirEditPencil} />
                </MotifIconButton>
              </div>
              <div className="arrow-icons">
                <MotifIconButton
                  onClick={() =>
                    handleOrderChange(OrderChange.Up, stageData.id!)
                  }
                  type="button"
                  size="small"
                >
                  <MotifIcon iconFunction={IconoirArrowUp} />
                </MotifIconButton>
                <MotifIconButton
                  onClick={() =>
                    handleOrderChange(OrderChange.Down, stageData.id!)
                  }
                  type="button"
                  size="small"
                >
                  <MotifIcon iconFunction={IconoirArrowDown} />
                </MotifIconButton>
              </div>
            </div>
          </MotifCardBody>
        </MotifCard>
      </div>

      <CreateEditStage
        show={showCreateStage}
        setShow={setShowCreateStage}
        stageData={stageData}
        isEdit={true}
        getStageData={getStageData}
      />

      <DeleteStageStatus
        show={showDeleteStage}
        setShow={setShowDeleteStage}
        id={stageData.id}
        getStageData={getStageData}
        isStage={true}
        selectedStage={selectedStage}
        setSelectedStage={setSelectedStage}
        setDeleteErrorMessage={setDeleteErrorMessage}
        
      />
    </>
  );
}
