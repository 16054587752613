import { Link } from "react-router-dom";

type CreditorsCell = {
  data: {
    type: string;
    importJobId: String;
  };
};
export const useCustomCellRenderers = () => {
  function getEntityName(entityId:any){
    const entityDetails:any={"1":"Creditor",
      "2":"Claim",
      "3":"ClaimItem",
      "4":"CreditorVotes",
      "5":"ManualDataSource",
      "0":"-"}
      return entityDetails[entityId.toString()]

  }
  const caseRenderers = {
    entityTypeCellRenderer: function (param: any) {
      return (
        <>
          {getEntityName(param.data.entityType)} 
        </>
      );
    },
    nameCellRenderer: function (param: CreditorsCell) {
      return (
        <>
          {
            <Link
              to={`./Import/importJobId/${param.data.importJobId}`}
              state={{ from: param.data.importJobId }}
            >
              {param.data.type}
            </Link>
          }
        </>
      );
    },
  };
  return caseRenderers;
};
