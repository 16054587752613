import { ccwpApi } from "@/services";

export const RateTierTemplateApi = ccwpApi.injectEndpoints({
    endpoints: (builder) => ({
        listRateTierTemplate: builder.query<any, any>({
            query: (args) => ({ url: "RateTier/ListRateTierTemplatesByCaseId", method: "GET", params: { ...args } }),
        }),
        toggleRateTierStatus: builder.mutation<any, { id: string; isActive: boolean }>({
            query: ({ id, isActive }) => ({
                url: `RateTier/DisableRateTierTemplate?rateTierTemplateId=${id}&isActive=${isActive}`,
                method: "DELETE",
            }),
        }),
        getRateTierTemplateListByCase: builder.query<any, any>({
            query: (caseId) => `/RateTier/GetRateTierTemplateList?CaseId=${caseId}`,
        }),
        getRateTierListByTemplate: builder.query<any, { RateTierTemplateId: string; CaseId: string }>({
            query: ({ RateTierTemplateId, CaseId }) =>
                `/RateTier/GetRateTierList?RateTierTemplateId=${RateTierTemplateId}&CaseId=${CaseId}`,
        }),
        createRateTierTemplate: builder.mutation({
            query: (data) => ({
                url: `/RateTier/CreateRateTierTemplate`,
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const {
    useListRateTierTemplateQuery,
    useToggleRateTierStatusMutation,
    useGetRateTierTemplateListByCaseQuery,
    useGetRateTierListByTemplateQuery,
    useCreateRateTierTemplateMutation
} = RateTierTemplateApi;
