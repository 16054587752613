import { useTranslation } from "react-i18next";
import "./DataSourceUploadStatus.scss";
interface DataSourceUploadStatusProps {
  importStatus: {
    importFileName: string;
    statusName: string;
    recordsSuccessful: number;
    recordsFailed: number;
    status: string;
  };
}

function DataSourceUploadStatus({importStatus}:DataSourceUploadStatusProps) {
const {t}=useTranslation();
  return (
    <div className="data-source-upload-status">
        <div className="loader-container">
          <span className="loader"></span>
          <div className="import-status">{t("pages.claims.claimsTable.bulkImport.progress")}</div>
        </div>
      <div className="motif-container section-container pad-t-20 pad-b-20 pad-l-40">
        <div className="motif-row">
          <div className="motif-col-lg-3">
            <div className="title">{t("pages.claims.claimsTable.bulkImport.fileName")}</div>
            <div className="motif-body2-default-bold text-wrap">{importStatus?.importFileName}</div>
          </div>
          <div className="motif-col-lg-3">
            <div className="title">{t("pages.claims.claimsTable.bulkImport.status")}</div>
            <div className="motif-body2-default-bold">{importStatus?.statusName}</div>
          </div>
          <div className="motif-col-lg-3">
            <div className="title">{t("pages.claims.claimsTable.bulkImport.recordsSuccessful")}</div>
            <div className="motif-body2-default-bold">{importStatus?.recordsSuccessful}</div>
          </div>
          <div className="motif-col-lg-3">
            <div className="title">{t("pages.claims.claimsTable.bulkImport.recordsFailed")}</div>
            <div className="motif-body2-default-bold">{importStatus?.recordsFailed}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataSourceUploadStatus;
