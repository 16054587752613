import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TimeZoneState {
  timeZoneAbbreviation: string;
  timeZoneCode: string;
  timeZoneId: number;
  timeZoneName: string;
}

const initialState: TimeZoneState[] = [{
  timeZoneAbbreviation: "",
  timeZoneCode: "",
  timeZoneId: 0,
  timeZoneName: "",
}];

export const timeZoneSlice = createSlice({
  name: "timezone",
  initialState,
  reducers: {
    setTimeZone: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setTimeZone } = timeZoneSlice.actions;

export default timeZoneSlice.reducer;
