import {
  BALLOT_MEETING_TIME_ID,
  BALLOT_MEETING_TIME,
  BALLOT_MEETING_NAME_ID,
  BALLOT_MEETING_NAME,
} from "./Constant";
import { useForm } from "react-hook-form";
import { VotingMeetingFormType } from "./type";
import { SAVE } from "@/Constant";
import ControlInputBox from "./ControlInputBoxMeeting";
import Button from "@/components/Form/Button/Button";
import { MotifErrorMessage } from "@ey-xd/motif-react";
import { useEffect, useState } from "react";
import FormattedDatePicker from "@/components/FormattedDatePicker/FormattedDatePicker";
import { useCase } from "@/features/case/use-case";
import { useCreateMeetingMutation } from "../votingReducer";

type CreateMeetingAllInputFieldsProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  isRefetch?: boolean;
  setIsRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  setMeetingValue: any;
  meetingOptions?: any[];
};
export const CreateMeetingAllInputFields = ({
  setShowModal,
  setIsRefetch,
  setMeetingValue,
}: CreateMeetingAllInputFieldsProps) => {
  const [dueDate, setDueDate] = useState(undefined);
  const [updatedDueDate, setUpdatedDueDate] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const caseData = useCase();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<VotingMeetingFormType>();

  const [createMeeting] = useCreateMeetingMutation();

  const onSubmit = async (formData: VotingMeetingFormType) => {
    let taskData = {
      caseId: caseData.caseId,
      name: formData.name,
      dueDate: formData.meetingDate,
    };
    setIsLoading(true);
    const response = await createMeeting(taskData);
    if (response) {
      setShowModal(false);
      setIsLoading(false);
      setIsRefetch(true);
      //@ts-ignore
      setMeetingValue(response.data);
    }else {
      setShowModal(false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setValue(BALLOT_MEETING_TIME_ID, updatedDueDate);
  }, [dueDate, updatedDueDate, setUpdatedDueDate, setValue]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="motif-row" style={{ marginBottom: "20px" }}></div>
      <div className="motif-row">
        <div className="motif-col-md-4">
          <div className="form-field">
            <ControlInputBox
              name={BALLOT_MEETING_NAME_ID}
              control={control}
              required={true}
              isTextArea={false}
              label={BALLOT_MEETING_NAME}
              showLabel={true}
            />
            {errors[BALLOT_MEETING_NAME_ID] &&
              errors[BALLOT_MEETING_NAME_ID].type === "required" && (
                <MotifErrorMessage>Meeting Name is required</MotifErrorMessage>
              )}
          </div>
        </div>
        <div className="motif-col-md-4">
          <div className="form-field">
            <div className="hidedatepicker">
              <ControlInputBox
                name={BALLOT_MEETING_TIME_ID}
                control={control}
                required={true}
                isTextArea={false}
                label={BALLOT_MEETING_TIME}
                showLabel={true}
              />
            </div>
            <FormattedDatePicker
              todayMark={true}
              //@ts-ignore
              value={dueDate}
              onChange={(value: any) => {
                const selectedDate = new Date(value);

                selectedDate.setDate(selectedDate.getDate() + 1);
                const updatedDate: any = selectedDate
                  .toISOString()
                  .substring(0, 10);
                setUpdatedDueDate(updatedDate);
                setDueDate(value);
              }}
            />
            {errors[BALLOT_MEETING_TIME_ID] &&
              dueDate === undefined &&
              errors[BALLOT_MEETING_TIME_ID].type === "required" && (
                <MotifErrorMessage>Meeting Date is required</MotifErrorMessage>
              )}
          </div>
        </div>
      </div>
      <div className="motif-row" style={{ marginBottom: "50px" }}></div>
      <Button
        label={SAVE}
        variant="primary"
        className="motif-button"
        onClickHandler={handleSubmit(onSubmit)}
        type={isLoading ? "loader" : "submit"}
        disabled={isLoading}
      />
      <div className="motif-row" style={{ marginBottom: "150px" }}></div>
    </form>
  );
};
