import { useState, useEffect } from "react";
import {
  MotifButton,
  MotifIcon,
  MotifProgressLoader,
} from "@/libs/@ey-xd/motif-react";
import { useTranslation } from "react-i18next";

import StageTile from "./StageTile";
import CreateEditStage from "./CreateEditStage";
import StatusTile from "./StatusTile";
import CreateEditStatus from "./CreateEditStatus";
import { StageType, EmptyStage, EmptyStatus, OrderChange } from "./StagesType";
import {
  useLazyGetStagesQuery,
  useUpdateStageMutation,
  useUpdateStatusMutation,
} from "@/services/stages/stagesReducer";
import "./Stages.css";
import { useCase } from "@/features/case/use-case";
import { IconoirPlus } from "@ey-xd/motif-icon";

export default function StageManagement() {
  const { t } = useTranslation();
  const [stagesData, setStagesData] = useState<StageType[]>([]);
  const [filteredStatuses, setFilteredStatuses] = useState<any[]>([]);
  const [showCreateStage, setShowCreateStage] = useState(false);
  const [showCreateStatus, setShowCreateStatus] = useState(false);
  const [addStatusButtonDisable, setAddStatusButtonDisable] = useState(false);

  const [selectedStage, setSelectedStage] = useState("");
  const [getStagesQuery] = useLazyGetStagesQuery();
  const [updateStageQuery] = useUpdateStageMutation();
  const [updateStatusQuery] = useUpdateStatusMutation();
  const caseData = useCase();

  const getStagesData = (isCreate: boolean) => {
    getStagesQuery(caseData.caseId).then((response) => {
      if (response.data && response.data.length !== 0) {
        setAddStatusButtonDisable(true);
        const stages = [...response.data].sort(
          (a: StageType, b: StageType) => a.sortOrder - b.sortOrder
        );
        setStagesData(stages);
        if (selectedStage === "") {
          setSelectedStage(stages[0].id);
        }
        if (isCreate) {
          updateStageOrder(stages);
          setSelectedStage(stages[0].id);
        }
      } else{
        setStagesData([]);
        setFilteredStatuses([]);
        setAddStatusButtonDisable(false);
      }
    });
  };

  const handleStageOrderChange = (change: OrderChange, id: string) => {
    const index = stagesData.findIndex((x) => x.id === id);
    if (change === OrderChange.Up && index !== 0) {
      const tempData = [...stagesData];
      tempData.splice(index - 1, 0, tempData.splice(index, 1)[0]);
      setStagesData([...tempData]);
      updateStageOrder(tempData);
    } else if (change === OrderChange.Down && index !== stagesData.length - 1) {
      const tempData = [...stagesData];
      tempData.splice(index + 1, 0, tempData.splice(index, 1)[0]);
      setStagesData([...tempData]);
      updateStageOrder(tempData);
    }
  };

  const handleStatusOrderChange = (change: OrderChange, id: string) => {
    const index = filteredStatuses.findIndex((x) => x.id === id);
    if (change === OrderChange.Up && index !== 0) {
      const tempData = [...filteredStatuses];
      tempData.splice(index - 1, 0, tempData.splice(index, 1)[0]);
      setFilteredStatuses([...tempData]);
      updateStatusOrder(tempData);
    } else if (
      change === OrderChange.Down &&
      index !== filteredStatuses.length - 1
    ) {
      const tempData = [...filteredStatuses];
      tempData.splice(index + 1, 0, tempData.splice(index, 1)[0]);
      setFilteredStatuses([...tempData]);
      updateStatusOrder(tempData);
    }
  };

  const updateStageOrder = (tempData: any) => {
    tempData.map(async (stage: any, index: number) => {
      const requestBody = { ...stage, sortOrder: index + 1 };
      await updateStageQuery(requestBody);
    });
  };

  const updateStatusOrder = (tempData: any) => {
    tempData.map(async (status: any, index: number) => {
      const requestBody = { ...status, sortOrder: index + 1 };
      await updateStatusQuery(requestBody).then(() => {
        getStagesData(false);
      });
    });
  };

  const filterStatuses = () => {
    if (stagesData) {
      const tempData: any = [...stagesData].filter((stage) => {
        return stage.id === selectedStage;
      });

      if (tempData.length !== 0) {
        const statuses = [...tempData[0].claimStatuses].sort(
          (a: any, b: any) => a.sortOrder - b.sortOrder
        );
        setFilteredStatuses(statuses);
      }
    }
  };

  useEffect(() => {
    getStagesData(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterStatuses();
    // eslint-disable-next-line
  }, [selectedStage, stagesData]);

  return (
    <>
      {!stagesData ? (
        <MotifProgressLoader show variant="default" />
      ) : (
        <>
          <div className="stage-management-container">
            <div className="motif-body1-default-regular stage-management-title">
              {t("pages.configuration.stages.subtitle")}
            </div>
            <div className="stage-management-body">
              <div className="stages-container">
                <div className="stages-header">
                  <div className="motif-body1-default-bold">
                    {t("pages.configuration.stages.title")}
                  </div>
                  <MotifButton
                    onClick={() => setShowCreateStage(true)}
                    size="small"
                    type="submit"
                    variant="text"
                  >
                    <MotifIcon iconFunction={IconoirPlus} />
                  </MotifButton>
                </div>
                {stagesData.map((stage: StageType) => {
                  return (
                    <StageTile
                      stageData={stage}
                      selectedStage={selectedStage}
                      setSelectedStage={setSelectedStage}
                      getStageData={getStagesData}
                      handleOrderChange={handleStageOrderChange}
                      key={stage.id}
                    />
                  );
                })}
              </div>
              <div className="status-container">
                <div className="status-header">
                  <div className="motif-body1-default-bold">
                    {t("pages.configuration.stages.status")}
                  </div>
                  <MotifButton
                    onClick={() => setShowCreateStatus(true)}
                    size="small"
                    type="submit"
                    variant="text"
                    disabled={!addStatusButtonDisable}
                  >
                    <MotifIcon iconFunction={IconoirPlus} />
                  </MotifButton>
                </div>
                <div className="status-body">
                  {filteredStatuses.map((status) => {
                    return (
                      <StatusTile
                        statusData={status}
                        getStageData={getStagesData}
                        handleOrderChange={handleStatusOrderChange}
                        numStatuses={filteredStatuses.length}
                        setFilteredStatuses={setFilteredStatuses}
                        filteredStatuses={filteredStatuses}
                        selectedStage={selectedStage}
                        setSelectedStage={setSelectedStage}
                        key={status.id}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <CreateEditStage
        show={showCreateStage}
        setShow={setShowCreateStage}
        stageData={{ ...EmptyStage, caseId: caseData.caseId }}
        isEdit={false}
        getStageData={getStagesData}
      />

      <CreateEditStatus
        show={showCreateStatus}
        setShow={setShowCreateStatus}
        statusData={{
          ...EmptyStatus,
          caseId: caseData.caseId,
          claimStageDefinitionId: selectedStage,
        }}
        isEdit={false}
        getStageData={getStagesData}
        numStatuses={filteredStatuses.length}
      />
    </>
  );
}
