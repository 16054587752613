import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Table from "@/components/Table/table";
import { getRuleColumnDefs } from "./RulesColumnDefination";
import { useCustomCellRenderers } from "./RulesColumnActions";
import { useUpdateRuleOrderMutation } from "@/services/rules/rulesReducer";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import DeleteRule from "./DeleteRule";

const Rules = ({
	initialRulesData,
	ruleRefetch,
	classRefetch,
	isDraft,
	schemeName,
}: {
	initialRulesData: any;
	ruleRefetch: () => void;
	classRefetch: () => void;
	isDraft: boolean;
	schemeName: string;
}) => {
	const { t } = useTranslation();
	const [rulesData, setRulesData] = useState(
		[...initialRulesData].sort((a, b) => a.sortOrder - b.sortOrder)
	);
	const [showDeleteRulePopup, setShowDeleteRulePopup] = useState(false);
	const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
	const [ruleId, setRuleId] = useState("");
	const [updateRuleOrder] = useUpdateRuleOrderMutation();

	useEffect(() => {
		setRulesData(
			[...initialRulesData].sort((a, b) => a.sortOrder - b.sortOrder)
		);
	}, [initialRulesData]);

	const handleShiftRow = (index: number, direction: "up" | "down") => {
		setRulesData((prevRulesData: any[]) => {
			const newRulesData = [...prevRulesData];
			if (direction === "up" && index > 0) {
				newRulesData.splice(index - 1, 0, newRulesData.splice(index, 1)[0]);
				const upperRow = { ...newRulesData[index - 1], sortOrder: index };
				const lowerRow = { ...newRulesData[index], sortOrder: index + 1 };
				newRulesData[index - 1] = upperRow;
				newRulesData[index] = lowerRow;
				updateRuleSortOrder([upperRow, lowerRow]);
			} else if (direction === "down" && index < newRulesData.length - 1) {
				newRulesData.splice(index + 1, 0, newRulesData.splice(index, 1)[0]);
				const upperRow = { ...newRulesData[index], sortOrder: index + 1 };
				const lowerRow = { ...newRulesData[index + 1], sortOrder: index + 2 };
				newRulesData[index] = upperRow;
				newRulesData[index + 1] = lowerRow;
				updateRuleSortOrder([upperRow, lowerRow]);
			}
			return newRulesData;
		});
	};
	const updateRuleSortOrder = (updatedRows: any) => {
		updatedRows.map(async (row: any) => {
			const requestBody = {
				ruleId: row.id,
				sortOrder: row.sortOrder,
			};
			await updateRuleOrder(requestBody);
		});
	};

	const caseRenderers = useCustomCellRenderers({
		handleDeleteEvent: (ruleId: string) => {
			setRuleId(ruleId);
			setShowDeleteRulePopup(true);
		},
		schemeName: schemeName,
		handleShiftRow: handleShiftRow,
	});

	return (
		<>
			<div className="mar-t-20">
				{deleteErrorMessage && (
					<NotificationGrid
						show={true}
						message={deleteErrorMessage}
						type="error"
					/>
				)}
				<Table
					columnDefs={getRuleColumnDefs(isDraft)}
					cellRenderers={rulesData}
					TitleComplement={() => <></>}
					title={t("pages.configuration.scheme.rules")}
					description={t("pages.configuration.scheme.detailDescription")}
					caseRenderers={caseRenderers}
					pagination={false}
					overlayNoRowsTemplate={t("pages.configuration.scheme.noRecord")}
				/>
			</div>
			{showDeleteRulePopup && (
				<DeleteRule
					show={true}
					setShow={setShowDeleteRulePopup}
					id={ruleId}
					setDeleteErrorMessage={setDeleteErrorMessage}
					ruleRefetch={ruleRefetch}
					classRefetch={classRefetch}
				/>
			)}
		</>
	);
};
export default Rules;
