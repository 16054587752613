import { dateFunc } from "@/components/Functions/dateFunc";
import { UpdatedDateCell } from "./claimTabType";
import { Link } from "react-router-dom";

export const caseRenderers = {
  updatedDateRenderer: function (param: UpdatedDateCell) {
    return <>{dateFunc(param.data.updatedOn).formattedDateOnly}</>;
  },
  claimCellRenderer: function (param: any) {
    return (
      <>
        {
          <Link
            to={`./claim/${param.data.claimId}`}
            state={{ from: param.data.claimId }}
          >
            {param.data.claimReferenceId}
          </Link>
        }
      </>
    );
  },
};
