import Banner, { BannerElement } from "@/components/Banner/Banner";
import { useGetDebtorEditViewApiDataQuery } from "@/services/debtors/debtorsReducers";
import { useParams } from "react-router-dom";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
function DebtorSummaryHeader() {
  const { debtorId, caseId } = useParams();
  const { data, error, isLoading } = useGetDebtorEditViewApiDataQuery(debtorId);
  const getCaseDetails = useSelector(
    (state: any) => state.caseDetails?.caseData
  );
  const breadcrumbItems = [
    {
      label: "External Submission",
      value: "10 New Creditor Submission",
      elementKey: "submission",
      text: getCaseDetails?.caseName,
      url: `/case/${caseId}?activeTab=1`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
    {
      label: "External Submission",
      value: "10 New Creditor Submission",
      elementKey: "submission",
      text: data?.name ? data?.name : "",
      url: "/",
      disabled: true,
    },
  ];
  if (isLoading) return <div>Loading...</div>;

  if (error) return <div> Data not Found</div>;

  const bannerElements: BannerElement[] = [
    {
      label: "Debtor ID",
      value: `${debtorId}`,
    },
    {
      label: "Distribution Status",
      value: ``,
    },
    {
      label: "Total Claims",
      value: `${data?.totalClaims}`,
    },
  ];

  return (
    <>
      <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
      <Banner
        bannerName="Debtor"
        bannerNameValue={data?.name}
        elements={bannerElements}
      />
    </>
  );
}

export default DebtorSummaryHeader;
