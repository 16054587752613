import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	MotifButton,
	MotifModal,
	MotifModalBody,
	MotifModalFooter,
	MotifModalHeader,
	MotifInput,
	MotifLabel,
} from "@ey-xd/motif-react";
import { PaymentStatusProps, PaymentStatusValue } from "../type";
import {
	useUpdatePaymentStatusMutation,
	useUpdateBulkPaymentStatusMutation,
} from "@/services/paymentStatus/paymentStatusReducer";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import "../PaymentStatus.css";
const UpdatePaymentStatus = ({
	showModal,
	validationError,
	currentStatusLabel,
	setShowUpdateStatuModal,
	availableStatuses,
	selectedPaymentIds,
	clearSelectedPayemntIds,
	refreshAfterPaymentUpdate,
	allPaymentSelected,
	isFilterActive,
	filterPayload,
}: PaymentStatusProps) => {
	const { t } = useTranslation();
	const { caseId } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [updatedStatus, setUpdatedStatus] = useState<string>("");
	const [errorMessage, setErrorMessage] = useState("");
	const [postedDate, setPostedDate] = useState("");
	const [updatePaymentStatus] = useUpdatePaymentStatusMutation();
	const [updateBulkPaymentStatus] = useUpdateBulkPaymentStatusMutation();

	const processFilterPayload = (payload: any) => {
		const processedPayload: any = {};
		for (const key in payload) {
			if (payload[key] === "") {
				processedPayload[key] = null;
			} else {
				processedPayload[key] = payload[key];
			}
		}
		return processedPayload;
	};
	const handleSubmit = async () => {
		setIsLoading(true);
		setErrorMessage("");
		let requestBody: any;
		if (allPaymentSelected) {
			requestBody = {
				updatePaymentStatusRequestDTO: {
					newPaymentStatus:
						PaymentStatusValue[
							updatedStatus as keyof typeof PaymentStatusValue
						],
					currentPaymentStatus:
						PaymentStatusValue[
							currentStatusLabel as keyof typeof PaymentStatusValue
						],
					postedDate: postedDate ? new Date(postedDate).toISOString() : null,
				},
				isExportAll: true,
				paymentSearchType: isFilterActive ? 1 : 0,
			};

			if (isFilterActive) {
				requestBody.paymentFilterListRequestDTO =
					processFilterPayload(filterPayload);
			} else {
				requestBody.getPaymentRecordsListRequestDTO = {
					caseId: caseId,
				};
			}
		} else {
			requestBody = {
				paymentIds: selectedPaymentIds.map((payment: any) => payment.paymentId),
				currentPaymentStatus:
					PaymentStatusValue[
						currentStatusLabel as keyof typeof PaymentStatusValue
					],
				newPaymentStatus:
					PaymentStatusValue[updatedStatus as keyof typeof PaymentStatusValue],
				postedDate: postedDate ? postedDate : null,
			};
		}
		if (allPaymentSelected) {
			await updateBulkPaymentStatus(requestBody).then((response: any) => {
				if (response?.data?.isSuccess) {
					setIsLoading(false);
					setShowUpdateStatuModal(false);
					clearSelectedPayemntIds();
					refreshAfterPaymentUpdate();
				} else {
					setErrorMessage("Error occured in updating payment status");
				}
			});
		} else {
			await updatePaymentStatus(requestBody).then((response: any) => {
				if (response.data) {
					setIsLoading(false);
					setShowUpdateStatuModal(false);
					clearSelectedPayemntIds();
					refreshAfterPaymentUpdate();
				} else {
					setIsLoading(false);
					if (response.error.data?.title) {
						setErrorMessage(response.error.data.title);
					} else {
						setErrorMessage(response.error.data?.message);
					}
				}
			});
		}
	};
	return (
		<MotifModal
			onClose={() => setShowUpdateStatuModal(false)}
			className="payment-status-popup-container"
			show={showModal}
			size="lg"
			focusTrapOptions={{
				tabbableOptions: {
					displayCheck: "none",
				},
			}}
		>
			<MotifModalHeader
				closeButtonProps={{
					"aria-label": "Custom Close Button aria-label",
					title: "Custom Close Button title",
				}}
			>
				{validationError
					? t("pages.paymentStatus.updateStatusLabel")
					: t("pages.paymentStatus.updateStatusLabelWithStatus", {
							currentStatus: currentStatusLabel,
					  })}
			</MotifModalHeader>
			{validationError ? (
				<div className="mar-b-20">
					{validationError && (
						<NotificationGrid
							show={true}
							message={validationError}
							type="error"
						/>
					)}
				</div>
			) : (
				<>
					<MotifModalBody className="pad-t-10">
						<div className="status-div">
							{t("pages.paymentStatus.updateStatusToLabel")}
						</div>
						<div className="status-buttons-container">
							{availableStatuses.map((status: any) => {
								return (
									<MotifButton
										className={
											"status-button " +
											(updatedStatus === status ? "active-status" : "")
										}
										key={status}
										variant="primary-alt"
										onClick={() => setUpdatedStatus(status)}
									>
										{status}
									</MotifButton>
								);
							})}
						</div>
						<div className="motif-row">
							<div className="motif-col-md-4">
								<MotifLabel aria-label="Input" htmlFor="input">
									{t("pages.paymentStatus.postedDate")}
								</MotifLabel>
								<MotifInput
									//@ts-ignore
									type="date"
									hideClearButton={true}
									onChange={(e: any) => setPostedDate(e.target.value)}
									value={postedDate}
								/>
							</div>
						</div>
						{errorMessage && (
							<div className="mar-t-20">
								<NotificationGrid
									show={true}
									message={errorMessage}
									type={"error"}
								/>
							</div>
						)}
					</MotifModalBody>
					<MotifModalFooter>
						<div className="document-footer display-flex ">
							<MotifButton
								type="button"
								variant="secondary"
								className="mar-r-10"
								onClick={() => setShowUpdateStatuModal(false)}
							>
								{t("pages.paymentStatus.filterForm.cancel")}
							</MotifButton>
							<MotifButton
								onClick={handleSubmit}
								variant="primary"
								disabled={isLoading || !updatedStatus}
								type={isLoading ? "loader" : "submit"}
							>
								{t("pages.paymentStatus.filterForm.save")}
							</MotifButton>
						</div>
					</MotifModalFooter>
				</>
			)}
		</MotifModal>
	);
};
export default UpdatePaymentStatus;
