import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ClaimItem {
  categoryName: string;
  natureOfClaimName: string;
  claimAmount: string;
  currency: string;
  showAssigneeSearch?: boolean;
  assigneeData: string;
  currentAmountUnitId?: string;
  categoryId?: string;
  assigneeId?: string;
}

interface ClaimItemsCompleteDetailsType {
  categoryName: string;
  natureOfClaimName: string;
  natureOfClaimItems: any;
  claimAmount: any;
  currency: string;
  assigneeData: string;
}

export interface ClaimItemsState {
  claimItems: ClaimItem[];
  selectedCategoryCompleteDetail: any;
  selectedCurrencyCompleteDetail: any;
  selectedAssigneeCompleteDetailClaimItems: any;
  claimItemsCompleteDetail: ClaimItemsCompleteDetailsType[];
}

const initialState: ClaimItemsState = {
  claimItems: [],
  selectedCategoryCompleteDetail: "",
  selectedCurrencyCompleteDetail: "",
  selectedAssigneeCompleteDetailClaimItems: "",
  claimItemsCompleteDetail: [],
};

export const claimItemsSlice = createSlice({
  name: 'claimSetupClaimItems',
  initialState,
  reducers: {
    setClaimItems: (state, action: PayloadAction<ClaimItem[]>) => {
      state.claimItems = action.payload;
    },
    setClaimItemsCompleteDetail: (state, action: PayloadAction<ClaimItemsCompleteDetailsType[]>) => {
      state.claimItemsCompleteDetail = action.payload;
    },
    setCategorySelectedItems: (state, action: PayloadAction<any>) => {
      state.selectedCategoryCompleteDetail = action.payload
    },
    setCurrencySelectedItems: (state, action: PayloadAction<any>) => {
      state.selectedCurrencyCompleteDetail = action.payload
    },
    setAssigneeSelectedItems: (state, action: PayloadAction<any>) => {
      state.selectedAssigneeCompleteDetailClaimItems = action.payload
    },
    resetClaimItemsState: (state) => {
      state.claimItems = initialState.claimItems;
      state.selectedCategoryCompleteDetail = initialState.selectedCategoryCompleteDetail;
      state.selectedCurrencyCompleteDetail = initialState.selectedCurrencyCompleteDetail;
      state.selectedAssigneeCompleteDetailClaimItems = initialState.selectedAssigneeCompleteDetailClaimItems;
      state.claimItemsCompleteDetail = initialState.claimItemsCompleteDetail;
    },
  }
});

export const { setClaimItems, setCategorySelectedItems, setCurrencySelectedItems,setClaimItemsCompleteDetail, setAssigneeSelectedItems, resetClaimItemsState } = claimItemsSlice.actions;

export default claimItemsSlice.reducer;
