const globalNotifications = {
  wentWrong: "Something went wrong!",
  noRecords: "No records found.",
  fetchingMessage: "Loading Data",
  updatedSuccessfully: "Successfully updated",
  createdSuccessfully: "Successfully Created",
  unExpectedError: "An unexpected error occurred",
  tryAgainMessage: "Something went wrong!.Try again..",
  roleChangeMessage: "Do you want to Change the Role?",
  loadingMessage: "Loading...",
  noUserMatchMessage: "No matching application user",
  userDeletedMessage: "User Deleted Successfully",
  warning: "Warning",
  unsavedChanges: "Unsaved Changes",
  discardMessage: "Are you sure you want to discard your changes?",
  dataNotFoundMessage: "Data not Found",
  noDatFound: "No data found",
  attributes: "Attributes",
  assignees: "Assignees",
  invalidNumber:"Please enter a valid number",
  minNumber: "Value must be at least {{minValue}}",
  maxNumber: "Value must be at least {{maxValue}}",
  filedRequired: "This field is required",
};

export default globalNotifications;
