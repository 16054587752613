import { MotifFormField } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { memo, SetStateAction, useCallback, useState } from "react";
import SearchInput from "@/components/SearchInput/SearchInput";

interface TitleComplementProps {
  onSearch: (searchValue: string) => void;
  handleQuickSearchClear: () => void;
  searchQuery: string;
}

const TitleComplement = memo(
  ({ onSearch, handleQuickSearchClear, searchQuery }: TitleComplementProps) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState("");

    const handleSearchChange = useCallback(
      (event: { target: { value: SetStateAction<string> } }) => {
        setSearchValue(event.target.value);
      },
      []
    );

    const handleOnSearch = useCallback(() => {
      onSearch(searchValue);
    }, [onSearch, searchValue]);

    return (
      <>
        <MotifFormField>
          <SearchInput
            aria-label="Search"
            onChange={handleSearchChange}
            onClear={handleQuickSearchClear}
            placeholder={t("Search")}
            onFocusShow={false}
            value={searchQuery}
            onEnter={handleOnSearch}
          />
        </MotifFormField>
      </>
    );
  }
);

export default TitleComplement;
