import React, { useState, useEffect } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import InputBox from "./InputBox";
import './InputBox.scss';
import { useController } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { characterLimitationOfTextArea } from '@/Constant';

type ControlInputBoxType<T extends FieldValues> = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<T>;
    name: Path<T>;
    showLabel: boolean;
    label: string;
    readonly?: boolean;
    defaultValue?: string;
    setValue?: any;
    charLimit?: number;
}

export default function ControlInputBox<T extends FieldValues>({
    name,
    control,
    required = false,
    isTextArea = true,
    showLabel,
    label,
    readonly = false,
    defaultValue = "",
    setValue,
    charLimit = characterLimitationOfTextArea
}: ControlInputBoxType<T>) {
    const { field } = useController({
        name,
        control,
        rules: { required: required },
    });
    const { t } = useTranslation();

    const [currentChar, setCurrentChar] = useState(0);

    useEffect(() => {
        setCurrentChar(field.value?.length || 0);
    }, [field.value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const charCount = e.target.value.length;
        if (charCount > charLimit) {
            e.target.value = e.target.value.slice(0, charLimit);
            setCurrentChar(charLimit);
        } else {
            setCurrentChar(charCount);
        }
        field.onChange(e);
    };

    return (
        <>
            {showLabel && (
                <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={label}>
                        {t(label)}
                        {required ? <span className="requiredSymbol">*</span> : ''}
                    </label>
                </div>
            )}
            <Controller
                name={field.name}
                control={control}
                rules={{ required }}
                render={({ field: { onChange, value = field.value, ...args } }) => {
                    return (
                      <>
                        <div>
                          <InputBox
                            id={name as string}
                            required={true}
                            value={
                              typeof value === "string" ||
                              typeof value === "number"
                                ? value
                                : ""
                            }
                            onChange={(
                              e: React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >
                            ) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            isTextArea={isTextArea}
                            readonly={readonly}
                            maxLength={charLimit.toString()}
                            {...args}
                          />
                          {isTextArea && (
                            <div className="text-right">{`${currentChar}/${charLimit}`}</div>
                          )}
                        </div>
                      </>
                    );
                }}
            />
        </>
    );
}