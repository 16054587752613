import { Control, Controller, useController } from "react-hook-form";
import './../../../../components/Form/InputBox/InputBox.scss';
import { useTranslation } from 'react-i18next';
import { COMMENT } from "@/Constant";
import InputBox from "@/components/Form/InputBox/InputBox";

type ControlInputBoxType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<any>; 
    name: typeof COMMENT;  
    showLabel: boolean;
    label: string;
    readonly?: boolean;
    defaultValue?: string;
}

export default function ControlInputBox({
    name,
    control,
    required = false,
    isTextArea = false,
    showLabel,
    label,
    readonly = false,
    defaultValue = "",
}: ControlInputBoxType) {
    const { field } = useController({
        name,
        control,
        rules: { required: required },
    });

    const { t } = useTranslation();

    return (
        <>
            {showLabel && (
                <div className="formFieldLabel">
                    <label htmlFor="comment-input" aria-label={label}>
                        {t(label)}
                        {required ? <span className="requiredSymbol">*</span> : ''}
                    </label>
                </div>
            )}
            <Controller
                name={field.name}
                control={control}
                rules={{ required }}
                render={({ field: { onChange, value = "", ...args } }) => {
                    return (
                        <InputBox
                            id="comment-input"
                            required={required}
                            value={value}
                            onChange={onChange}
                            isTextArea={isTextArea}
                            readonly={readonly}
                            {...args}
                        />
                    );
                }}
            />
        </>
    );
}
