import { useDispatch, useSelector } from "react-redux";
import { MotifToast } from "@ey-xd/motif-react";
import { resetNotification } from "@/features/form-setup/formSetupSlice";
import { useEffect } from "react";

export default function FormNotifications() {
  const { notifications } = useSelector((state) => state.formSetup);
  const dispatch = useDispatch();

  useEffect(() => {
    // This will reset the notification after 5 seconds
    if (notifications?.info || notifications?.success || notifications?.error) {
      setTimeout(() => {
        dispatch(resetNotification());
      }, 5000);
    }
  }, [notifications, dispatch]);
  return (
    <div className="form-notifications">
      {notifications?.info && (
        <MotifToast
          variant="info"
          supportingText="This message will disappear in 5 seconds"
          onClose={() => {
            dispatch(resetNotification());
          }}
        >
          {notifications.info?.message}
        </MotifToast>
      )}
      {notifications?.success && (
        <MotifToast
          variant="success"
          onClose={() => dispatch(resetNotification())}
          supportingText="This message will disappear in 5 seconds"
        >
          {notifications.success?.message}
        </MotifToast>
      )}
      {notifications?.error && (
        <MotifToast
          variant="error"
          supportingText="This message will disappear in 5 seconds"
          onClose={() => {
            dispatch(resetNotification());
          }}
        >
          {notifications.error?.message}
        </MotifToast>
      )}
    </div>
  );
}
