import { ccwpApi } from "@/services";

export const RulesApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getRulesList: builder.query<any, string>({
      query: (schemeId) => {
        return {
          url:`Rule/ListRulesBySchemeVersion?schemeVersionId=${schemeId}`
        };
      },
    }),
    createRule: builder.mutation({
      query: (ruleData) => ({
        url: "Rule/CreateRule",
        method: "POST",
        body: ruleData,
      }),
    }),
    deleteRule: builder.query({
      query: (id) => ({
        url: `Rule/DeleteRule?id=${id}`,
        method: "DELETE",
      }),
    }),
    readRule: builder.query<any, string>({
      query: (id) => {
        return {
          url:`Rule/ReadRule?id=${id}`
        };
      },
    }),
    updateRule: builder.mutation({
      query: (ruleData) => ({
        url: "Rule/UpdateRule",
        method: "PUT",
        body: ruleData,
      }),
    }),
    updateRuleOrder: builder.mutation({
      query: (ruleData) => ({
        url: "Rule/UpdateRuleSortOrder",
        method: "PUT",
        body: ruleData,
      }),
    }),
  }),
});

export const { useGetRulesListQuery, useCreateRuleMutation,useLazyDeleteRuleQuery, useReadRuleQuery,
  useUpdateRuleMutation,useUpdateRuleOrderMutation} = RulesApi;