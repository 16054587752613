import { j as i } from "../../jsx-runtime-BwWtxLpl.js";
import m from "classnames";
import t from "prop-types";
import { useContext as c } from "react";
import { VerticalNavigationContext as l } from "./vertical-navigation-context.js";
import { MotifIcon as p } from "../icon/icon.js";
import { IconContext as u } from "../icon/icon.context.js";
import { IconoirMenu as f } from "@ey-xd/motif-icon";
import { MotifButton as v } from "../button/button.js";
import "../dropdown-portal/dropdown-portal.js";
import "../button/icon-button/icon-button.js";
const d = {
  menu: f
}, n = ({ onClick: a }) => {
  const { verticalNavigationHeaderButton: r } = c(u), o = { ...d, ...r };
  return /* @__PURE__ */ i.jsx(
    v,
    {
      "aria-label": "Click to open vertical-navigation menu",
      type: "button",
      onClick: a,
      "data-motif-vertical-navigation-header-button": !0,
      variant: "ghost",
      size: "medium",
      children: /* @__PURE__ */ i.jsx(p, { iconFunction: o.menu, strokeWidth: "2px" })
    }
  );
};
n.defaultProps = {
  onClick: () => null
};
n.propTypes = {
  onClick: t.func
};
const g = ({
  ariaLabel: a = "VerticalNavigation menu bar",
  children: r,
  className: o = "",
  collapse: e = !1
}) => {
  const s = m({
    "motif-vertical-navigation": !0,
    "motif-vertical-navigation-expanded-width": !e,
    "motif-vertical-navigation-collapsed": e,
    [o]: !0
  });
  return /* @__PURE__ */ i.jsx(l.Provider, { value: { collapse: e, rootClassName: o }, children: /* @__PURE__ */ i.jsx("nav", { className: s, "aria-label": a, "data-motif-vertical-navigation": !0, children: r }) });
};
g.propTypes = {
  ariaLabel: t.string,
  children: t.node.isRequired,
  className: t.string,
  collapse: t.bool
};
export {
  g as MotifVerticalNavigation,
  n as MotifVerticalNavigationHeaderButton
};
