import { Link } from "react-router-dom";
import { dateFunc } from "@/components/Functions/dateFunc";
import {
  customComparator,
  formatAmountUnitDecimalPlace,
} from "@/utility/common";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useDefaultUnitDetails = () => {
  const { defaultUnit } = useSelector((state: any) => state.case);
  const decimalPlace = defaultUnit?.decimalPlace;
  const defaultUnitName = defaultUnit?.name;
  return { decimalPlace, defaultUnitName };
};

const CustomHeader = () => {
  const { defaultUnitName } = useDefaultUnitDetails();
  const unitName = defaultUnitName;
 return `(${unitName})`;
};

const amountCellRenderer = (field: string) => (params: any) => {
  const { decimalPlace } = useDefaultUnitDetails();
  const amount = params.data[field];
  return formatAmountUnitDecimalPlace(amount, decimalPlace);
};

export const useColumnDefs = ({
  caseId,
  creditorId,
  abbreviation,
}: {
  caseId: string | undefined;
  creditorId: string | undefined;
  abbreviation: string | undefined;
}) => {
  const {t}=useTranslation();
  return [
    {
      cellRenderer: "claimCellRenderer",
      field: "claimReferenceId",
      headerName: t("pages.creditors.creditorClaimTab.tableColumnsDetails.claimId"),
      headercomponentParams: { caseId, creditorId },
      flex: 1,
      width: 200,
      sortable: true,
      comparator: customComparator,
      unSortIcon: true,
    },
    {
      field: "assigneeName",
      headerName: t("pages.creditors.creditorClaimTab.tableColumnsDetails.assignee"),
      flex: 1,
      width: 150,
      sortable: true,
      comparator: customComparator,
      unSortIcon: true,
      cellRenderer: function (param: any) {
        return param.data.assigneeName !== "" ? param.data.assigneeName : "-";
      },
    },
    {
      cellRenderer: amountCellRenderer("currentAmount"),
      field: "currentAmount",
       headerName: t("pages.creditors.creditorClaimTab.tableColumnsDetails.currentAmount",{unitName:CustomHeader()}),
      flex: 1,
      width: 150,
      headerHeight: 150,
      sortable: false,
      cellClass: "align-right",
      headerComponentParams: { params: "Current Amount" },
      headerClass: "align-right",
    },
    {
      field: "claimStageName",
      headerName: t("pages.creditors.creditorClaimTab.tableColumnsDetails.Stage"),
      flex: 1,
      width: 150,
      sortable: true,
      comparator: customComparator,
      unSortIcon: true,
    },
    {
      field: "actionStatusName",
      headerName: t("pages.creditors.creditorClaimTab.tableColumnsDetails.actionStatus"),
      flex: 1,
      width: 150,
      sortable: true,
      comparator: customComparator,
      unSortIcon: true,
    },
    {
      field: "updatedOn",
      headerName: t("pages.creditors.creditorClaimTab.tableColumnsDetails.lastUpdated"),
      flex: 1,
      width: 230,
      sortable: false,
      cellRenderer: function (param: any) {
        return (
          <>
            {dateFunc(param.data.updatedOn).formattedDateTime +
              " " +
              abbreviation}
          </>
        );
      },
    },
  ];
};

type documentConst = {
  data: {
    documentType: string;
    comment: string;
    createdBy: string;
    createdOn: string;
  };
};

export const useColumnDefsCreditor=() =>{
const {t}=useTranslation();
  return[
    {
      cellRenderer: "typeCellRenderer",
      field: "documentType",
      headerName: t("pages.creditors.creditorDocumentUpload.tableColumnsDetails.type"),
      flex: 1,
      unSortIcon: true,
    },
    {
      cellRenderer: "commentCellRenderer",
      field: "comment",
      headerName: t("pages.creditors.creditorDocumentUpload.tableColumnsDetails.comment"),
      flex: 1,
      unSortIcon: true,
    },
    {
      field: "createdBy",
      headerName: t("pages.creditors.creditorDocumentUpload.tableColumnsDetails.createdBy"),
      flex: 1,
      sortable: false,
    },
    {
      field: "createdOn",
      headerName: t("pages.creditors.creditorDocumentUpload.tableColumnsDetails.receivedDate"),
      flex: 1,
      sortable: false,
      cellRenderer: function (params: documentConst) {
        return dateFunc(params.data.createdOn).formattedDateTime;
      },
    },
    {
      headerName: t("pages.creditors.creditorDocumentUpload.tableColumnsDetails.claimId"),
      field: "claimReference",
      flex: 1,
      cellRenderer: (params: any) => {
        if (params.value) {
          const { caseId, creditorId, entityId } = params.data;
          let prefix = `/case/${caseId}/creditor/${creditorId}`;
          return (
            <Link
              to={`${prefix}/claim/${entityId}`}
              state={{ from: params.data.claimId }}
            >
              {params.value}
            </Link>
          );
        }
        return null;
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 2,
      sortable: false,
      cellRenderer: "actionRenderer",
      cellStyle: { justifyContent: "right" },
    },
  ];
}
 
