import { useEffect, useMemo, useState } from "react";
import { useCase } from "@/features/case/use-case";
import { formatAmountUnitDecimalPlace } from "@/utility/common";
import { MotifCheckbox } from "@ey-xd/motif-react";

const useCaseDetails = () => {
	const caseData = useCase();
	const defaultUnit = caseData?.defaultUnit;
	return { defaultUnit };
};

const CustomHeader = (props: any) => {
	const { defaultUnit } = useCaseDetails();
	const [unitName, setUnitName] = useState(defaultUnit?.name);

	useEffect(() => {
		setUnitName(defaultUnit?.name);
	}, [defaultUnit]);

	const { params } = props;
	if (props) return params + ` (${unitName})`;
};

const amountCellRenderer = (field: string) => (params: any) => {
	const { defaultUnit } = useCaseDetails();
	const decimalPlace = useMemo(() => defaultUnit?.decimalPlace, [defaultUnit]);
	const amount = params.data[field];
	return formatAmountUnitDecimalPlace(amount, decimalPlace);
};

export const CreditorColumnDefs = ({
	creditorId,
	setCreditorId,
	setIsPreviewScheme,
}: {
	creditorId: string;
	setCreditorId: (value: string) => void;
	setIsPreviewScheme: (value: boolean) => void;
}) => {
	return [
		{
			field: "creditorName",
			headerName: "Name",
			flex: 2,
			sortable: true,
			unSortIcon: true,
			cellStyle: {
				whiteSpace: "normal",
				lineHeight: "1.5",
			},
			cellRenderer: function (params: any) {
				return (
					<>
						<MotifCheckbox
							checked={params.data.creditorId === creditorId}
							onChange={(event: any) => {
								if (event.target.checked) {
									setCreditorId(params.data.creditorId);
									setIsPreviewScheme(true);
								} else {
									setCreditorId("");
									setIsPreviewScheme(false);
								}
							}}
							value={params.data.creditorId}
						>
							{params.data.creditorName}
						</MotifCheckbox>
					</>
				);
			},
		},
		{
			cellRenderer: "totalClaimsCellRenderers",
			field: "totalClaims",
			headerName: "Total Claims",
			flex: 1,
			sortable: false,
		},
		{
			cellRenderer: amountCellRenderer("originalAmount"),
			field: "originalAmount",
			flex: 1,
			sortable: false,
			headerName: "Original Amount",
			headerComponent: CustomHeader,
			headerComponentParams: { params: "Original Amount" },
			headerHeight: 150,
			cellClass: "align-right",
			headerClass: "align-right",
		},
		{
			cellRenderer: amountCellRenderer("claimAmount"),
			field: "claimAmount",
			headerName: "Claim Amount",
			headerComponent: CustomHeader,
			headerComponentParams: { params: "Claim Amount" },
			headerHeight: 150,
			flex: 1,
			sortable: false,
			cellClass: "align-right",
			headerClass: "align-right",
		},
		{
			cellRenderer: amountCellRenderer("latestCreditorPositionAmount"),
			field: "latestCreditorPositionAmount",
			headerName: "Latest Creditor Position Amount",
			headerComponent: CustomHeader,
			headerComponentParams: { params: "Latest Creditor Position Amount" },
			headerHeight: 150,
			flex: 1,
			sortable: false,
			cellClass: "align-right",
			headerClass: "align-right",
		},
		{
			cellRenderer: amountCellRenderer("latestDebtorPositionAmount"),
			field: "latestDebtorPositionAmount",
			headerName: "Latest Debtor Position Amount",
			headerComponent: CustomHeader,
			headerComponentParams: { params: "Latest Debtor Position Amount" },
			headerHeight: 150,
			flex: 1,
			sortable: false,
			cellClass: "align-right",
			headerClass: "align-right",
		},
		{
			cellRenderer: amountCellRenderer("acceptedForDistribution"),
			field: "acceptedForDistribution",
			headerName: "Resolved Amount",
			headerComponent: CustomHeader,
			headerComponentParams: { params: "Resolved Amount" },
			headerHeight: 150,
			flex: 1,
			sortable: false,
			cellClass: "align-right",
			headerClass: "align-right",
		},
	];
};
