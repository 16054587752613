export interface FormValues {
  payeeName: string;
  accountName: string;
  prefix: string;
  paymentReferenceStartWith: string;
  paymentReferenceEndWith: string;
  issueStartDate: Date | undefined;
  issueEndDate: Date | undefined;
  lowerBound: string;
  upperBound: string;
  postedDateStart: Date | undefined;
  postedDateEnd: Date | undefined;
  paymentStatus: string;
}
export type PaymentStatus =
	| "Draft"
	| "Issued"
	| "Cashed"
	| "Undelivered"
	| "Void"
	| "Stop"
	| "Replaced";

export type StatusMapping = {
	[key in PaymentStatus]: PaymentStatus[];
};


export interface PaymentStatusProps {
	showModal: boolean;
	validationError: string;
	currentStatusLabel: string;
	setShowUpdateStatuModal: (show: boolean) => void;
	selectedPaymentIds: any;
	clearSelectedPayemntIds: () => void;
	availableStatuses: any;
	refreshAfterPaymentUpdate: () => void;
	allPaymentSelected: boolean;
	isFilterActive: boolean;
	filterPayload: any;
}

export enum PaymentStatusValue {
	Draft = 0,
	Issued = 1,
	Cashed = 2,
	Replaced = 3,
	Stop = 4,
	Undelivered = 5,
	Void = 6,
}
export interface PaymentStatusObject {
  id: string,
  name: string,
}
export interface PaymentPrefix {
  id: string,
  name: string,
}
export interface Account {
  name: string;
	id: string;
}

