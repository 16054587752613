import { MotifLabel } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
type CreditorDetailsProps =  { 
    creditorName: string, 
    creditorDescription: string, 
    creditorReferenceId: string, 
    relationShipToDebtor: string 
} 

export default function CreditorDetails({creditorName, creditorDescription, creditorReferenceId, relationShipToDebtor}: CreditorDetailsProps) {
  const {t}=useTranslation()  
  return (
          <>
            <div className="display-flex align-content-center mar-t-15">
              <div className="small-banner">
                <div className="formFieldLabel">
                  <MotifLabel>{t("pages.claims.claimCreate.creditorTab.formLabelsDetails.creditorName")}</MotifLabel>
                </div>
                <div className="motif-body2-default-regular">{creditorName}</div>
              </div>
              <div className="small-banner mar-l-2">
                <div className="formFieldLabel">
                  <MotifLabel>{t("pages.claims.claimCreate.creditorTab.formLabelsDetails.description")}</MotifLabel>
                </div>
                <div className="motif-body2-default-regular">
                  {creditorDescription}
                </div>
              </div>
              <div className="small-banner mar-l-2">
                <div className="formFieldLabel">
                  <MotifLabel>{t("pages.claims.claimCreate.creditorTab.formLabelsDetails.referenceId")}                  </MotifLabel>
                </div>
                <div className="motif-body2-default-regular">
                  {creditorReferenceId}
                </div>
              </div>
              <div className="small-banner mar-l-2">
                <div className="formFieldLabel">
                  <MotifLabel>{t("pages.claims.claimCreate.creditorTab.formLabelsDetails.relationshipToDebtor")}                  </MotifLabel>
                </div>
                <div className="motif-body2-default-regular">
                  {relationShipToDebtor}
                </div>
              </div>
            </div>

            <div className="motif-row mar-t-30">
              <div className="motif-col-lg-6">
              </div>
            </div>
          </>
        
    );
}