import { useTranslation } from "react-i18next";

export const useColumnDefinitions = () => {

	const { t } = useTranslation();
	const usersListHeader = [
    {
      suppressHeaderMenuButton: true,
      field: "displayName",
      type: "displayNameType",
      comparator: () => 0,
      headerName: t("pages.userManagementPage.columnDetails.userName"),
      sortable: true,
      unSortIcon: true,
      headerComponentParams: {
        enableSorting: true,
        showFilter: false,
      },
      editable: false,
      resizable: true,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
      },
      flex: 2,
    },
    {
      cellRenderer: "createdOnRenderer",
      type: "lastUpdatedDateType",
      comparator: () => 0,
      field: "lastUpdatedDate",
      headerName: t("pages.userManagementPage.columnDetails.addedOn"),
      flex: 2,
      sortable: true,
      suppressCellFocus: false,
      suppressHeaderMenuButton: true,
      resizable: false,
      unSortIcon: true,
    },
    {
      cellRenderer: "roleRenderer",

      field: "applicationRole",
      type: "applicationRoleType",
      comparator: () => 0,
      flex: 2,
      headerName: t("pages.userManagementPage.columnDetails.applicationRole"),
      sortable: true,
      editable: true,
      unSortIcon: true,
      suppressCellFocus: true,
      suppressHeaderMenuButton: true,
      resizable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: (e: any) => {
        return {
          values: [t("pages.userManagementPage.basicUser"), t("pages.userManagementPage.caseCreator"), t("pages.userManagementPage.systemAdmin")],
        };
      },
    },
    {
      cellRenderer: "actionCellRenderers",
      field: "",
      flex: 1,
      headerName: "",
      sortable: false,
      editable: false,
      suppressCellFocus: false,
      suppressHeaderMenuButton: true,
      resizable: false,
    },
  ];
	return usersListHeader;
};

export default useColumnDefinitions;