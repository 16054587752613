export const ACCOUNT_LABEL = "Account Name";
export const ACCOUNT_NAME = "accountName";
export const PREFIX_LABEL = "Prefix";
export const UNIT_LABEL = "Unit";
export const STARTING_SEQUENCE_NUMBER_LABEL = "Starting Sequence Number";
export const INSTITUION_NUMBER_LABEL = "Institution Number";
export const TRANSIT_NUMBER_LABEL = "Transit Number";
export const ACCOUNT_NUMBER_LABEL = "Account Number";
export const CLIENT_NUMBER_LABEL = "Client Number";
export const PREFIX= "prefix";
export const UNIT= "paymentUnitId";
export const STARTING_SEQUENCE_NUMBER= "baseSequenceNumber";
export const INSTITUION_NUMBER= "institutionNumber";
export const TRANSIT_NUMBER= "transitNumber";
export const ACCOUNT_NUMBER= "accountNumber";
export const CLIENT_NUMBER= "clientNumber";
export const DESC_LABEL = "Description";
export const DESC_NAME = "descName";
export const ACCOUNT_NAME_FR_LABEL = "Account Name (French)";
export const ACCOUNT_NAME_FR_NAME = "accountNameFr";
export const ACCOUNT_DESC_FR_LABEL = "Description (French)";
export const ACCOUNT_DESC_FR_NAME = "descNameFr";

export const SUBACCOUNT_NAME_LABEL = "Sub Account Name";
export const SUBACCOUNT_NAME = "subAccountName";
export const DESCRIPTION_LABEL = "Description";
export const DESCRIPTION_NAME = "subAccountDesc";
export const SUBACCOUNT_NAME_FR_LABEL = "Sub Account Name (French)";
export const SUBACCOUNT_NAME_FR = "subAccountNameFr";
export const DESCRIPTION_NAME_FR_LABEL = "Description (French)";
export const DESCRIPTION_NAME_FR = "subAccountdescFr";
export const INCLUDE_IN_TAX_REPORT_LABEL = "Include in Tax Report";
export const INCLUDE_IN_TAX_REPORT_NAME = "includeInTaxReport";
export const INCLUDE_IN_PAYMENT_REGISTER_LABEL = "Include in Payment Register";
export const INCLUDE_IN_PAYMENT_REGISTER_NAME = "includeInPaymentRegister";
export const ACCOUNT_DELETE = "Account deleted successfully"
export const SUB_ACCOUNT_DELETE = "Sub Account deleted successfully"