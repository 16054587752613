import {
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
} from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";

type Props = {
  message: string;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  cancelBtnHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  yesBtnHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function DeleteModal({
  message,
  isVisible,
  setIsVisible,
  cancelBtnHandler,
  yesBtnHandler,
}: Props) {
  const { t } = useTranslation();
  return (
    <>
      <MotifModal
        onClose={() => setIsVisible(false)}
        show={isVisible}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <div>
          <MotifModalBody className='confirmationModalWrapper'>
            <span className='discard_heading'>{t("global.globalActions.delete")}</span>
            <div className='discard_message'>{message}</div>
          </MotifModalBody>
          <MotifModalFooter style={{marginLeft: "Auto"}}>
            <MotifButton
              onClick={cancelBtnHandler}
              variant="secondary"
            >
              {t("global.globalActions.cancel")}
            </MotifButton>
            <MotifButton
              onClick={yesBtnHandler}
              variant="primary"
            >
              {t("global.globalActions.yes")}
            </MotifButton>
          </MotifModalFooter>
        </div>
      </MotifModal>
    </>
  );
}
