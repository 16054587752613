export enum ConfigurationTab {
  Units = 0,
  Schemes = 1,
  Attributes = 2,
  Stages = 3,
  CaseTimeZones = 4,
  Category = 5,
  // PayeeType = 5,
}

export interface IConfigurationState {
    currentTab: ConfigurationTab;
}