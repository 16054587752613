import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IAttributeState } from "./type";

export const initialState = {
  id: undefined,
  attributeType: undefined,
  entityType: 1,
  name: undefined,
  description: undefined,
  isRequired: false,
  isDisplayOnCreate: false,
  picklistDefinitions: [],
  metaDataJSON: {},
};

const attributeSlice = createSlice({
  name: "attribute",
  initialState,
  reducers: {
    setAttribute(state: IAttributeState, action: PayloadAction<any>) {
      return { ...action.payload };
    },
  },
});

export const { setAttribute } = attributeSlice.actions;
export default attributeSlice.reducer;