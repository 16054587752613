import { UseFormSetValue } from "react-hook-form";
import {
  ClaimNewCreditorFormType,
  ClaimSetupCreditorFormType,
  ClaimSetupNewCreditorFormType,
  SelectedContactType,
} from "./type";
import { ClaimCreditorContactFormType } from "./Components/ContactForm";
import {
  ADDRESS_1_FIELD,
  ADDRESS_2_FIELD,
  CITY_FIELD,
  // CONTACT_ID_FIELD,
  COUNTRY_NAME,
  EMAIL_FIELD,
  FAX_FIELD,
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  PHONE_NUMBER_FIELD,
  POSTAL_CODE_FIELD,
  PREFIX_NAME,
  PROVINCE_STATE_NAME,
  RELATIONSHIP_TO_CREDITOR_NAME,
  SELECT_A_CONTACT_NAME,
} from "../Constants";
// import { setNewCreditorDetail } from "./creditorSlice";
import { ORGANIZATION } from "@/pages/CaseDetail/Creditor/CreditorSetup/Constants";

//@ts-ignore
export function getCreditorContactTypeOptions(creditorContactData) {
  return creditorContactData
    .filter(
      (item: { firstName: string; lastName: string }) =>
        item.firstName !== "" && item.lastName !== ""
    )
    .sort((a: { isPrimaryContact: any }, b: { isPrimaryContact: any }) => {
      if (a.isPrimaryContact && !b.isPrimaryContact) {
        return -1;
      } else if (!a.isPrimaryContact && b.isPrimaryContact) {
        return 1;
      }
      return 0;
    })
    .map(
      (item: {
        isPrimaryContact: boolean;
        contactId: number;
        firstName: string;
        lastName: string;
      }) => {
        const fullName = item.isPrimaryContact
          ? `${item.firstName} ${item.lastName} (Primary)`
          : `${item.firstName} ${item.lastName}`;
        return {
          label: fullName,
          value: item?.contactId,
        };
      }
    );
}

export const updateformWithSelectedContact = (
  creditorSelectedContactData: SelectedContactType,
  setValue: UseFormSetValue<ClaimCreditorContactFormType>
) => {
  setValue(FIRST_NAME_FIELD, creditorSelectedContactData?.firstName || "");
  setValue(LAST_NAME_FIELD, creditorSelectedContactData?.lastName || "");
  setValue(PREFIX_NAME, creditorSelectedContactData?.prefix || "");
  setValue(
    RELATIONSHIP_TO_CREDITOR_NAME,
    "" + creditorSelectedContactData?.relationshipToCreditor || ""
  );
  setValue(PHONE_NUMBER_FIELD, creditorSelectedContactData?.phone || "");
  setValue(FAX_FIELD, creditorSelectedContactData?.fax || "");
  setValue(ADDRESS_1_FIELD, creditorSelectedContactData?.address1 || "");
  setValue(ADDRESS_2_FIELD, creditorSelectedContactData?.address2 || "");
  setValue(EMAIL_FIELD, creditorSelectedContactData?.email || "");
  setValue(CITY_FIELD, creditorSelectedContactData?.city || "");
  setValue(COUNTRY_NAME, creditorSelectedContactData?.countryId || "");
  setValue(
    PROVINCE_STATE_NAME,
    creditorSelectedContactData?.provinceStateId || ""
  );
  setValue(POSTAL_CODE_FIELD, creditorSelectedContactData?.postalCode || "");
  //setValue(SELECT_A_CONTACT_NAME, creditorSelectedContactData?.creditorContactId || "");
};

export const updateformWithStoreContact = (
  creditorSelectedContactData: ClaimSetupCreditorFormType,
  setValue: UseFormSetValue<ClaimCreditorContactFormType>
) => {
  setValue(FIRST_NAME_FIELD, creditorSelectedContactData?.firstName || "");
  setValue(LAST_NAME_FIELD, creditorSelectedContactData?.lastName || "");
  setValue(PREFIX_NAME, creditorSelectedContactData?.prefix || "");
  setValue(
    RELATIONSHIP_TO_CREDITOR_NAME,
    "" + creditorSelectedContactData?.relationshipToCreditor || ""
  );
  setValue(PHONE_NUMBER_FIELD, creditorSelectedContactData?.phone || "");
  setValue(FAX_FIELD, creditorSelectedContactData?.fax || "");
  setValue(ADDRESS_1_FIELD, creditorSelectedContactData?.address1 || "");
  setValue(ADDRESS_2_FIELD, creditorSelectedContactData?.address2 || "");
  setValue(EMAIL_FIELD, creditorSelectedContactData?.email || "");
  setValue(CITY_FIELD, creditorSelectedContactData?.city || "");
  setValue(COUNTRY_NAME, "" + creditorSelectedContactData?.countryId || "");
  setValue(
    PROVINCE_STATE_NAME,
    creditorSelectedContactData?.provinceStateId || ""
  );
  setValue(POSTAL_CODE_FIELD, creditorSelectedContactData?.postalCode || "");
  //setValue(SELECT_A_CONTACT_NAME, creditorSelectedContactData?.creditorContactId || "");
};

export const resetformContact = (
  setValue: UseFormSetValue<ClaimCreditorContactFormType>
) => {
  setValue(FIRST_NAME_FIELD, "");
  setValue(LAST_NAME_FIELD, "");
  setValue(PREFIX_NAME, "");
  setValue(RELATIONSHIP_TO_CREDITOR_NAME, "");
  setValue(PHONE_NUMBER_FIELD, "");
  setValue(FAX_FIELD, "");
  setValue(ADDRESS_1_FIELD, "");
  setValue(ADDRESS_2_FIELD, "");
  setValue(EMAIL_FIELD, "");
  setValue(CITY_FIELD, "");
  setValue(COUNTRY_NAME, "");
  setValue(PROVINCE_STATE_NAME, "");
  setValue(POSTAL_CODE_FIELD, "");
  setValue(SELECT_A_CONTACT_NAME, "");
};

export const getStateListByCountry = (stateList: any) => {
  let stateListBycounrty: { label: string; value: string }[] = [];
  if (stateList === undefined || stateList.length === 0) {
    return stateListBycounrty;
  }

  stateList.map((stateObj: { id: string; name: string; code: string }) => {
    stateListBycounrty.push({ label: stateObj.name, value: stateObj.id });
    return null;
  });
  return stateListBycounrty;
};

export const getNewCrediotrDetails = (
  data: ClaimNewCreditorFormType,
  stateListArray: { label: string; value: string }[],
  countryArray: { label: string; value: string }[],
  countrySelectedId: string,
  creditorType: string
) => {
  const countryName =
    countryArray.find((option) => option.value === countrySelectedId)?.label ||
    "";
  const stateName =
    stateListArray?.find(
      (option) => option.value === data.contact_provinceStateId
    )?.label || "";
  const newCreditorDetail = {
    isOrg: creditorType === ORGANIZATION,
    prefix: data.prefix,
    firstName: data.firstName,
    lastName: data.lastName,
    businessName: data.businessName,
    description: data.description,
    relationshipToDebtor: data.relationShipToDebtor,
    contact: {
      firstName: data.contact_firstName,
      lastName: data.contact_lastName,
      prefix: data.contact_prefix,
      address1: data.contact_address1,
      address2: data.contact_address2,
      city: data.contact_city,
      provinceStateId: data.contact_provinceStateId,
      provinceState: stateName,
      postalCode: data.contact_postalCode,
      countryId: data.contact_countryId,
      country: countryName,
      phone: data.contact_phone,
      fax: data.contact_fax,
      email: data.contact_email,
      relationshipToCreditor: data.contact_relationshipToCreditor,
    },
  };
  return newCreditorDetail;
};

export const setNewCrediotrDetailsFromStore = (
  newCreditorDetailStore: ClaimSetupNewCreditorFormType,
  data: ClaimNewCreditorFormType,
  stateListArray: { label: string; value: string }[],
  countryArray: { label: string; value: string }[],
  countrySelectedId: string,
  creditorType: string
) => {
  const countryName =
    countryArray.find((option) => option.value === countrySelectedId)?.label ||
    "";
  const stateName =
    stateListArray?.find(
      (option) => option.value === data.contact_provinceStateId
    )?.label || "";
  const newCreditorDetail = {
    isOrg: creditorType === ORGANIZATION,
    prefix: data.prefix,
    firstName: data.firstName,
    lastName: data.lastName,
    businessName: data.businessName,
    description: data.description,
    relationshipToDebtor: data.relationShipToDebtor,
    contact: {
      firstName: data.contact_firstName,
      lastName: data.contact_lastName,
      prefix: data.contact_prefix,
      address1: data.contact_address1,
      address2: data.contact_address2,
      city: data.contact_city,
      provinceStateId: data.contact_provinceStateId,
      provinceState: stateName,
      postalCode: data.contact_postalCode,
      countryId: data.contact_countryId,
      country: countryName,
      phone: data.contact_phone,
      fax: data.contact_fax,
      email: data.contact_email,
      relationshipToCreditor: Number(data.contact_relationshipToCreditor),
    },
  };
  return newCreditorDetail;
};
