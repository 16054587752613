const caseOverView = {
  bannerDetails: {
    caseShortName: "Case Short Name",
    caseName: "Case Name",
    caseStatus: "Status",
  },
  tabPannelDetails: {
    summaryLabel: "Summary",
    debtorsLabel: "Debtors",
    creditorsLabel: "Creditors",
    claimsLabel: "Claims",
    votingLabel: "Voting",
    configurationLabel: "Configuration",
    distributionsLabel: "Distributions",
    importHistoryLabel: "Import History",
    portalConfigurationLabel: "Portal Configuration",
    paymentStatusLabel: "Payment Status",
    reportingLabel: "Reporting",
  },
};

export default caseOverView;
