import React from "react";
import { Control, Controller, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import InputBox from "@/components/Form/InputBox/InputBox";
import '@/components/Form/InputBox/InputBox.scss';
import { useController } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { ClaimSetupCreditorFormType } from "./type";
import { ADDRESS_1_FIELD, ADDRESS_2_FIELD, BUSINESS_NAME_FIELD, CITY_FIELD, CONTACT_ID_FIELD, COUNTRY_NAME, DESCRIPTION_FIELD, EMAIL_FIELD, FAX_FIELD, FIRST_NAME_FIELD, LAST_NAME_FIELD, NEW_CREDITOR_FIRST_NAME, NEW_CREDITOR_LAST_NAME, PHONE_NUMBER_FIELD, POSTAL_CODE_FIELD, PREFIX_NAME, PROVINCE_STATE_NAME, RELATIONSHIP_TO_CREDITOR_NAME } from "@/pages/CaseDetail/Claim/claimSetup/Constants";


type ControlInputBoxType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<ClaimSetupCreditorFormType>;
    name: typeof FIRST_NAME_FIELD | typeof LAST_NAME_FIELD | typeof ADDRESS_1_FIELD | typeof ADDRESS_2_FIELD | typeof CITY_FIELD | 
    typeof POSTAL_CODE_FIELD | typeof PHONE_NUMBER_FIELD | typeof EMAIL_FIELD | typeof FAX_FIELD | typeof PREFIX_NAME | typeof RELATIONSHIP_TO_CREDITOR_NAME | 
    typeof COUNTRY_NAME | typeof PROVINCE_STATE_NAME | typeof CONTACT_ID_FIELD | typeof DESCRIPTION_FIELD | typeof BUSINESS_NAME_FIELD | typeof NEW_CREDITOR_FIRST_NAME | typeof NEW_CREDITOR_LAST_NAME;
    showLabel: boolean;
    label: string;
    value?:string;
    defaultValue?: string;
    setValue?: UseFormSetValue<ClaimSetupCreditorFormType>;
    getValues?: UseFormGetValues<ClaimSetupCreditorFormType>;
    rules?: any;
}

export default function ControlInputBox({ name, control, required = false, isTextArea = false, showLabel, label, value,defaultValue,rules, setValue, getValues}: ControlInputBoxType) {
    const {
        field,
    } = useController({
        name,
        control,
        rules: { required: required, ...rules },
    });
    const { t } = useTranslation();

    return (
        <>
            {
                showLabel && <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={label}>{t(label)}{required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
            <Controller
                name={field.name}
                control={control}
                rules={{ required, ...rules }}
                defaultValue={value ?? ''}
                render={({ field: { onChange, value, ...args } }) => {
                    return (
                        <>
                            <InputBox id={name} required={true} value={value ?? ''} onChange={onChange} isTextArea={isTextArea} {...args} />
                        </>
                    );
                }}
            />
        </>

    );
}
