import { MotifButton, MotifIcon } from "@ey-xd/motif-react";
import { Link } from "react-router-dom";
import { IconoirPlus, IconoirTrash } from "@ey-xd/motif-icon";

export const useCustomCellRenderers = ({
  setShowDeleteClassPopup,
  setDistributionData,
}: {
  setDistributionData: (data: any) => void;
  setShowDeleteClassPopup: (value: boolean) => void;
}) => {
  const caseRenderers = {
    distributionName: function (params: any) {
      return (
        <>
          {
            <Link
              to={`/case/${params.data.caseId}/distribution/${params.data.id}`}
            >
              {params.data.name}
            </Link>
          }
        </>
      );
    },
    actionCellRenderers: function (param: any) {
      return (
        <div className="motif-row">
          <div className="motif-col-lg-6">
            <MotifButton
              size="small"
              variant="primary"
              type="button"
              onClick={() => {
                setDistributionData(param.data);
                setShowDeleteClassPopup(true);
              }}
            >
              <MotifIcon
                iconFunction={IconoirTrash}
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
          </div>
          <div className="motif-col-lg-6">
            <Link
              to={`/case/${param.data.caseId}/duplicate-distribution/create`}
            >
              <MotifButton size="small" type="button" variant="primary">
                <MotifIcon
                  iconFunction={IconoirPlus}
                  stroke="blue"
                  strokeWidth="2"
                  size="24"
                />
              </MotifButton>
            </Link>
          </div>
        </div>
      );
    },
  };
  return caseRenderers;
};
