import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const useColumnBallotDefs = () =>{
 const {t}=useTranslation()
  return [
  {
    cellRenderer: (params: any) => (
      <Link to={`./voting/edit/` + params?.data?.id}>
        {params?.data?.ballotName}
      </Link>
    ),
    comparator: () => 0,
    field: "ballotName",
    type: "ballotNameType",
    headerName: t("pages.voting.tableColumnsDetails.column1"),
    width: 130,
    sortable: true,
    unSortIcon: true,
  },
  {
    comparator: () => 0,
    field: "className",
    type: "classNameType",
    headerName: t("pages.voting.tableColumnsDetails.column2"),
    flex: 1,
    sortable: true,
    unSortIcon: true,
  },
  {
    comparator: () => 0,
    field: "meetingName",
    type: "meetingNameType",
    headerName: t("pages.voting.tableColumnsDetails.column3"),
    flex: 1,
    sortable: true,
    unSortIcon: true,
  },
  {
    comparator: () => 0,
    field: "status",
    type: "statusType",
    headerName: t("pages.voting.tableColumnsDetails.column4"),
    flex: 1,
    cellRenderer: function (params: any) {
      return params.data.status === true ? "Open" : "Closed";
    },
    sortable: true,
    unSortIcon: true,
  },
  {
    cellRenderer: "successConditionCreditorCount",

    comparator: () => 0,
    field: "successConditionCreditorCount",
    type: "successConditionCreditorCountType",
    headerName:t("pages.voting.tableColumnsDetails.column5"),
    flex: 1,
    sortable: true,
    unSortIcon: true,
  },
  {
    cellRenderer: "successConditionClaimValue",

    comparator: () => 0,
    field: "successConditionClaimValue",
    type: "successConditionClaimValueType",
    headerName: t("pages.voting.tableColumnsDetails.column6"),
    flex: 1,
    sortable: true,
    unSortIcon: true,
  },
  {
    cellRenderer: function (params: any) {
      return params.data.isProxyRequired === true ? "Yes" : "No";
    },

    comparator: () => 0,
    field: "isProxyRequired",
    type: "isProxyRequiredType",
    headerName: t("pages.voting.tableColumnsDetails.column7"),
    flex: 1,
    width: 500,
    sortable: true,
    unSortIcon: true,
  },
  {
    cellRenderer: "actionCellRenderers",

    comparator: () => 0,
    field: "action",
    headerName: "",
    flex: 1,
    sortable: false,
  },
]};
