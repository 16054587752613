import TabPanel from "@/components/TabPanel/TabPanel";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import DebtorTabContent from "@/features/debtor/debtorTab/DebtorTabContent";
import ClaimTabContent from "@/features/debtor/claimsTab/claimsTabContent";
import { useLocation } from "react-router-dom";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { Create_SUCCESSFUL, UPDATE_SUCCESSFUL } from "@/Constant";
import { isEmpty } from "@/utility/common";

interface BannerData {
  debtorId: string;
  caseId: string;
  name: string;
  description: string;
  createdBy: string;
  updatedBy: string;
  isActive: boolean;
}

export default function DebtorGrid() {
  let location = useLocation();
  let state = location.state;
  if (!state) {
    state = {showSuccess: false, update: false};
  }
  const message = state.update ? UPDATE_SUCCESSFUL : Create_SUCCESSFUL;
  
  const bannerData: BannerData = {
    "debtorId": "d05e52a1-6b55-4ad2-9c34-f5aaadd806fb",
    "caseId": "536be3c1-f9b0-4c4d-b530-e516d75f347e",
    "name": "Anjana Dharmasiri",
    "description": "Test Anjana ",
    "createdBy": "CA013334848",
    "updatedBy": "CA013334848",
    "isActive": true
  }
  const bannerDataArray: BannerData[] = [bannerData];
  const tabPanelData = {
    title: [
      "Summary",
      "Debtors",
      "Creditors",
      "Claims",
      "Voting",
      "Distributions",
      "Payment Status",
      "Configuration",
      "test1",
      "test2",
    ],
    content: [
      <div>Content for Summary</div>,
      <DebtorTabContent bannerData={bannerDataArray} isSuccess={isEmpty(state.showSuccess)}/>,
      <div>Content for Creditors</div>,
      <ClaimTabContent/>,
    ],
  };
  const breadcrumbItems = [
    {
      label: 'External Submission',
      value: '10 New Creditor Submission',
      elementKey: 'submission',
      text: "Back to Home",
      url: "/",
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
    {
      label: 'External Submission',
      value: '10 New Creditor Submission',
      elementKey: 'submission',
      text: "Debtor 1",
      url: "/",
    },
  ];
  
  return (
    <>
    {state.showSuccess && <NotificationGrid show={state.showSuccess} message={message} type="success" />}
      <div className="ccwp_table_wrapper">
        <Breadcrumb  items={breadcrumbItems} ariaLabel="Breadcrumb navigation"/>
        <div className="ccwp_table_tabpanel">
          <TabPanel data={tabPanelData} defaultActiveKey={1}/>
        </div>
      </div>
    </>
  );
}