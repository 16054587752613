import {
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "@/components/Form/Button/Button";
import { MotifErrorMessage } from "@ey-xd/motif-react";
import { CreateSubFormType, subAcountRecordType } from "./type";
import {
  DESCRIPTION_NAME,
  DESCRIPTION_NAME_FR,

  INCLUDE_IN_PAYMENT_REGISTER_NAME,
  INCLUDE_IN_TAX_REPORT_NAME,
  SUBACCOUNT_NAME,
  SUBACCOUNT_NAME_FR,

} from "./constant";
import ControlInputBox from "./CreateSubAccInputField";
import ControlCheckBox from "./Checkbox";
import { useCreateSubAccountMutation } from "@/services/configuration/configuration";
import { useGetEffectiveSchemesQuery } from "../../Voting/votingReducer";
import { useParams } from "react-router-dom";
import ControlSelect from "../../Voting/VotingForm/VotingFieldSelectBox";
import { useTranslation } from "react-i18next";
export const SubAccountCreation = ({
  subAccShow,
  setSubAccShow,
  refetchSubAccounts,
  selectedStage,
  setStatusMsg,
  setIsDiscardVisible,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFrenchSelected, setIsFrenchSelected] = useState(false);
  const { caseId } = useParams<{ caseId: string;}>();
const {t}=useTranslation()
  const checkBoxHandler = (e?: React.ReactEventHandler) => {
    setIsFrenchSelected(!isFrenchSelected);
  };
  const {
    handleSubmit,
    control,
    formState: { errors ,isDirty},
    reset,
  } = useForm<CreateSubFormType>({
    defaultValues: {
      [SUBACCOUNT_NAME]: "",
      [DESCRIPTION_NAME]: "",
      [SUBACCOUNT_NAME_FR]: "",
      [DESCRIPTION_NAME_FR]: "",
      [INCLUDE_IN_TAX_REPORT_NAME]: false,
      [INCLUDE_IN_PAYMENT_REGISTER_NAME]: false,
    },
  });
  const { data: dataSchemes, isLoading: isLoadingSchemes } =
  useGetEffectiveSchemesQuery({ caseId }, { skip: !caseId });
  const SCHEME_OPTIONS: any[] = useMemo(() => {
    if (dataSchemes && !isLoadingSchemes) {
      return dataSchemes.map((item: subAcountRecordType) => {
        return {
          label: item?.name || "",
          value: item?.schemeVersionId || "",
        };
      });
    }

    return [];
  }, [isLoadingSchemes, dataSchemes]);
  const defaultOption = { label: t("global.globalActions.optionDefault"), value: "" };
  const SchemeOptions = [defaultOption, ...SCHEME_OPTIONS];
  const [createSubAccount] = useCreateSubAccountMutation();
  useEffect(() => {
    if (!subAccShow) {
      reset();
    }
  }, [subAccShow, reset]);
  const onSubmit = async (formData: CreateSubFormType) => {
    let accountCreationData = {
      accountId: selectedStage,
      name: formData.subAccountName,
      description: formData.subAccountDesc,
      nameFrench: formData.subAccountNameFr,
      schemeVersionId: formData.schemeVersionId,
      descriptionFrench: formData.subAccountdescFr,
      isInTaxReport: formData.includeInTaxReport === true ? true : false,
      isInPaymentRegister: formData.includeInPaymentRegister === true ? true : false,
      isActive: true,
    };
    setIsLoading(true);
    setStatusMsg("");
    const response = await createSubAccount(accountCreationData);
    if (response.hasOwnProperty("data")) {
      refetchSubAccounts();
      setSubAccShow(false);
      //@ts-ignore
      setStatusMsg(response?.data?.message);
      setIsLoading(false);
    } else {
      setSubAccShow(false);
      setStatusMsg("global.globalNotifications.unExpectedError");
      setIsLoading(false);
    }
  };
  return (
    <MotifModal
      className="create-edit-stage"
      onClose={() => {
        if(isDirty){
          setIsDiscardVisible(true);

        }
        else{
          setSubAccShow(false);

        }
      }}
      show={subAccShow}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
    >
      <MotifModalHeader>{t("pages.distributions.account.createSubAccountTitle")}</MotifModalHeader>

      <MotifModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-field">
            <ControlInputBox
              name={SUBACCOUNT_NAME}
              control={control}
              required={true}
              isTextArea={false}
              label={"pages.distributions.account.subAccountLabels.subAccountName"}
              showLabel={true}
            />
            {errors[SUBACCOUNT_NAME] &&
              errors[SUBACCOUNT_NAME].type === "required" && (
                <MotifErrorMessage>
{t("pages.distributions.account.subAccountValidationMessages.nameRequired")}       
         </MotifErrorMessage>
              )}
          </div>
          <div className="form-field">
            <ControlInputBox
              name={DESCRIPTION_NAME}
              control={control}
              required={false}
              isTextArea={true}
              label={"pages.distributions.account.subAccountLabels.description"}
              showLabel={true}
            />
          </div>
          <div className="form-field">
            <ControlInputBox
              name={SUBACCOUNT_NAME_FR}
              control={control}
              required={false}
              isTextArea={false}
              label={"pages.distributions.account.subAccountLabels.subAccountNameFrench"}
              showLabel={true}
            />
          </div>
          <div className="form-field">
            <ControlInputBox
              name={DESCRIPTION_NAME_FR}
              control={control}
              required={false}
              isTextArea={true}
              label={"pages.distributions.account.subAccountLabels.descriptionFrench"}
              showLabel={true}
            />
          </div>
          <div className="form-field">
          <ControlSelect
                  name={"schemeVersionId"}
                  //@ts-ignore
                  control={control}
                  required={false}
                  showLabel={true}
                  readonly={false}
                  label={"pages.distributions.account.subAccountLabels.payemntOrderScheme" }
                  placeholder={"placeholder"}
                  options={SchemeOptions}
                />
          </div>
          <div className="form-field">
            <ControlCheckBox
              id="checkbox-french-selection"
              onChange={checkBoxHandler}
              checked={isFrenchSelected}
              value={isFrenchSelected}
              name={INCLUDE_IN_TAX_REPORT_NAME}
              control={control}
              required={false}
              label={t("pages.distributions.account.subAccountLabels.includeTaxReport")}
              setIsFrenchSelected={setIsFrenchSelected}
            />
          </div>
          <div className="form-field">
            <ControlCheckBox
              id="checkbox-french-selection"
              onChange={checkBoxHandler}
              checked={isFrenchSelected}
              value={isFrenchSelected}
              name={INCLUDE_IN_PAYMENT_REGISTER_NAME}
              control={control}
              required={false}
              label={t("pages.distributions.account.subAccountLabels.includePaymentRegister")}
              setIsFrenchSelected={setIsFrenchSelected}
            />
          </div>
        </form>
      </MotifModalBody>

      <MotifModalFooter>
        <Button
          label={t("global.globalActions.save")}
          variant="primary"
          className="motif-button"
          onClickHandler={handleSubmit(onSubmit)}
          type={isLoading ? "loader" : "submit"}
          disabled={isLoading}
        />
      </MotifModalFooter>
    </MotifModal>
  );
};
