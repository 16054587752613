import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  MotifErrorMessage,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "@/components/Form/Button/Button";
import {
 
  SECONDARY,
} from "@/Constant";
import { AddDebtorFormType } from "./type";
import "./FormModal.scss";
import { isNumeric } from "@/utility/validation";
import { capitalizeWordsInSentence } from "@/utility/common";
import { renderOptionList } from "../Form/calculationSummaryHelper";
import {
  useCreateStepClassFilterApiMutation,
  useGetClassListBySchemeQuery,
  useGetSchemeVersionListByCaseQuery,
  useGetStepClassFilterByIdQuery,
  useUpdateStepClassFilterApiMutation,
} from "../calculationStepReducer";
import ControlInputBoxNumber from "@/components/Form/InputBox/ControlInputBoxNumber";
import ChildDropdown from "../Form/ChildDropdown";
import ControlSelectScheme from "./SelectBox/ControlSelectScheme";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";

type Props = {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  formData?: AddDebtorFormType | null;
  caseId: string;
  stepId: string;
  selectedClass: string[];
  setIsRefetch: Dispatch<SetStateAction<boolean>>;
  setSelectedClassId: Dispatch<SetStateAction<string>>;
  selectedClassId: string;
  isNew: boolean;
  selectedScheme: string | undefined;
};

export default function FormModalClassFilter({
  isVisible,
  setIsVisible,
  stepId,
  caseId,
  setIsRefetch,
  selectedClassId,
  setSelectedClassId,
  selectedClass,
  isNew,
  selectedScheme,
}: Props) {
  const { t } = useTranslation();
  const [isVisibleCancel, setIsVisibleCancel] = useState(false);
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });
  let defaultOption = {
    label: "Please Select",
    value: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [childOptions, setChildOptions] = useState<any>([defaultOption]);

  const { data, isLoading: isLoadingData } =
    useGetSchemeVersionListByCaseQuery(caseId);

  const { data: dataClass, isLoading: isLoadingClass ,isFetching:isFetchingClass} =
    useGetStepClassFilterByIdQuery(selectedClassId, {
      skip:
        selectedClassId === "" ||
        selectedClassId === undefined ||
        isNew === true,
    });

  const [schemeList, setSchemeList] = useState<any>([defaultOption]);

   const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<any>();

  const errorList = Object.keys(errors);
  const errorsShow = capitalizeWordsInSentence(errorList.join(", "));
  //@ts-ignore
  const schemeId = watch("scheme");

  useEffect(() => {
    if (data && data.length > 0) {
      const schemeList = renderOptionList(data, "name", "schemeVersionId");
      setSchemeList(schemeList);
    }
  }, [data]);

  //@ts-ignore
  const { data: schemeData, isLoading: schemaIsloading } =
    useGetClassListBySchemeQuery(schemeId, {
      skip: schemeId === "" || schemeId === undefined,
    });

    useEffect(() => {
          setValue("scheme",dataClass?.schemeVersionId)
          if (isVisible === true) {
            if (
              selectedScheme !== "" &&
              selectedScheme !== null &&
              selectedScheme !== undefined
            ) {
              //@ts-ignore
              // setValue("scheme", selectedScheme);
            }
          } else {
            //@ts-ignore
            // setValue("scheme", "");
            setValue("weightingFactor", 1);
          }
        }, [isVisible, selectedScheme, dataClass, setValue]);

  /* Child Dropdown */
  useEffect(() => {
    if (schemeData) {
      const filterClasses = schemeData.filter((item: any) => {
        if (
          selectedClass === undefined ||
          selectedClass?.length === 0 ||
          selectedClass === null
        ) {
          return true;
        }
        //@ts-ignore
        if (selectedClass.includes(item.id)) {
          if (item.id === dataClass?.classId) {
            return true;
          }
          return false;
        }
        return true;
      });
      const classList = renderOptionList(filterClasses, "name", "id");
      // @ts-ignore
      setValue("weightingFactor", dataClass?.weight);
      setChildOptions(classList);
      //@ts-ignore
      setValue("class", dataClass?.classId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemeData, selectedClassId, isVisible, dataClass]);
  /* Child Dropdown */
  let [createStepClassFilterApi] = useCreateStepClassFilterApiMutation();
  let [updateStepClassFilterApi] = useUpdateStepClassFilterApiMutation();

  const onSubmit = async (data: AddDebtorFormType) => {
    setIsLoading(true);
    try {
      const formClassData: any = data;

      let postData = {
        classId: formClassData?.class,
        weight: formClassData?.weightingFactor,
      };
      // @ts-ignore
      const responseApi =
        selectedClassId === ""
          ? await createStepClassFilterApi({
              ...postData,
              caseId: caseId,
              calculationStepId: stepId,
            })
          : await updateStepClassFilterApi({
              ...postData,
              id: selectedClassId,
            });

      if (responseApi.hasOwnProperty("data")) {
        setIsRefetch(true);
        setIsVisible(false);
        setIsLoading(false);
        setSelectedClassId("");
      } else {
        const error = t("global.globalNotifications.unExpectedError");
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          message: t(error),
        });
        setIsLoading(false);
        setSelectedClassId("");
      }
    } catch (error) {
      setShowMessage({ ...showMessage, show: true, type: "error" });
    }
  };
  if (isLoadingData || isLoadingClass || schemaIsloading ||isFetchingClass) {
    return <MotifProgressLoader show={true} />;
  }

  /* Child Dropdown */
  const onSchemeChange = () => {
    //@ts-ignore
    setValue("class", "");
  };
  /* Child Dropdown */
  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    setIsVisibleCancel(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisibleCancel(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };
  return (
    <>
      <MotifModal
        onClose={() => setIsVisible(false)}
        show={isVisible}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formModalWrapper">
            <MotifModalHeader>
              <span className="discard_heading">{t("pages.distributions.distributionStepsTab.classFilterTab.classFilterEditTitle")}</span>
            </MotifModalHeader>
            <MotifModalBody>
              <div className="formModal">
                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    {/*@ts-ignore*/}
                    <ControlSelectScheme
                    /*@ts-ignore*/
                      name={"scheme"}
                      control={control}
                      showLabel={true}
                      label={"pages.distributions.distributionStepsTab.classFilterTab.scheme"}
                      options={schemeList}
                      required={true}
                      setValue={setValue}
                      getValues={getValues}
                      onChangeHandler={onSchemeChange}
                      disabled={selectedScheme !== undefined}
                    />
                  </div>
                </div>
                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ChildDropdown
                      control={control}
                      //@ts-ignore
                      setValue={setValue}
                      //@ts-ignore
                      getValues={getValues}
                      name="class"
                      label="pages.distributions.distributionStepsTab.classFilterTab.class"
                      required={true}
                      options={childOptions}
                    />
                  </div>
                </div>

                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlInputBoxNumber
                      name={"weightingFactor"}
                      // @ts-ignore
                      control={control}
                      required={true}
                      isTextArea={false}
                      label={"pages.distributions.distributionStepsTab.classFilterTab.weightFactor"}
                      showLabel={true}
                      // @ts-ignore
                      setValue={setValue}
                      isNumber={true}
                      digitAfterDecimal={2}
                      isAmount={true}
                      onChangeAmount={() => {}}
                      validate={isNumeric}
                    />
                  </div>
                </div>
              </div>
              <div className="error-container">
                <MotifErrorMessage>
                  {showMessage.show && showMessage.message}
                  {errorList.length > 0 &&
                    t("pages.distributions.distributionStepsTab.classFilterTab.validationMessage",{fieldNames:errorsShow})
                    }
                </MotifErrorMessage>
              </div>
            </MotifModalBody>
            <MotifModalFooter>
              <div className="button-container-add">
                <Button label={t("global.globalActions.save")} disabled={isLoading} />
                <Button
                  label={t("global.globalActions.cancel")}
                  type="button"
                  variant={SECONDARY}
                  className="cancelBtn"
                  onClickHandler={() => {
                    setIsVisibleCancel(true);
                    setShowMessage({
                      ...showMessage,
                      show: false,
                      type: "error",
                    });
                    setSelectedClassId("");
                  }}
                />
              </div>
            </MotifModalFooter>
          </div>
        </form>
      </MotifModal>
      <ConfirmationModal
        isVisible={isVisibleCancel}
        setIsVisible={setIsVisibleCancel}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
    </>
  );
}
