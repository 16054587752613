import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { useBlocker, useNavigate } from "react-router-dom";

import {
  resetConfirmation,
  setConfirmation,
  setIsVisible,
} from "@/features/confirmation/confirmation-slice";
import { setDiscardChangesData } from "@/components/Modal/ConfirmationModal/discardchanges";

export default function GlobalConfirmationModal() {
  const confirmation = useSelector((state: any) => state.confirmation);
  const { isVisible, blockNavigation, action, heading, message } = confirmation;
  const dispatch = useDispatch();
  let discardDetails = useSelector((e: any) => e.discardChangesDatas.data);
  const nav = useNavigate();
  const blocker: any = useBlocker((e) => {
    if (blockNavigation) {
      dispatch(
        setConfirmation({
          isVisible: true,
          blockNavigation: true,
        })
      );
      return true;
    }
    if (action) {
      // do not block when custom action is used
      return false;
    }
    return discardDetails.editMode;
  });

  const { proceed, reset } = blocker;

  const setIsVisibleHandler = (isVisible: boolean) => {
    dispatch(setIsVisible(isVisible));
  };

  const handleApprove = () => {
    dispatch(
      setConfirmation({
        ...confirmation,
        isVisible: false,
        blockNavigation: false,
        status: "approved",
      })
    );
    if (proceed) {
      proceed();
      dispatch(resetConfirmation());
    }
  };

  const handleReject = () => {
    dispatch(
      setConfirmation({
        ...confirmation,
        isVisible: false,
        blockNavigation: blockNavigation,
        status: "rejected",
      })
    );
    if (blockNavigation && reset) {
      reset();
      dispatch(resetConfirmation());
    }
  };
  function handleClose() {
    if (blocker?.proceed) {
      blocker.proceed();
    }
    if (discardDetails.navPath) {
      nav(discardDetails.navPath);
    }

    dispatch(
      setDiscardChangesData({
        showModal: false,
        editMode: false,
        navPath: "",
        navState: null,
        cancelButtunTriggered: false,
      })
    );
  }
  function handleDiscardReject() {
    if (blocker?.reset) {
      blocker.reset();
    }

    dispatch(
      setDiscardChangesData({
        showModal: false,
        editMode: true,
        navPath: "",
        navState: null,
        cancelButtunTriggered: true,
      })
    );
  }
  function handleDiscardApprove() {
    if (blocker?.proceed) {
      blocker.proceed();
    }
    if (discardDetails.navPath) {
      nav(discardDetails.navPath);
    }

    dispatch(
      setDiscardChangesData({
        showModal: false,
        editMode: false,
        navPath: "",
        navState: null,
        cancelButtunTriggered: false,
      })
    );
  }

  return (
    <>
      <ConfirmationModal
        isVisible={isVisible}
        setIsVisible={setIsVisibleHandler}
        cancelBtnApproveHandler={handleApprove}
        cancelBtnRejectHandler={handleReject}
        heading={heading}
        message={message}
        loading={confirmation.status === "approved"}
      />

      <ConfirmationModal
        isVisible={
          (blocker?.state === "blocked" && discardDetails.editMode) ||
          discardDetails.showModal
        }
        setIsVisible={handleClose}
        cancelBtnApproveHandler={handleDiscardApprove}
        cancelBtnRejectHandler={handleDiscardReject}
      />
    </>
  );
}


