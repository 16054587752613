import { useCase } from "@/features/case/use-case";
import CalculationStepHeader from "./CalculationStepHeader";
import { useGetCalculationStepDataQuery } from "./calculationStepReducer";
import CalculationForm from "./Form/calculationForm";
import { renderAccountList } from "./Form/calculationSummaryHelper";
import { useParams } from "react-router-dom";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import { useTranslation } from "react-i18next";

function CalculationStepCreate() {
    const {caseId} = useCase();
    const { distributionId } = useParams();
  const {data, isLoading} = useGetCalculationStepDataQuery({ caseId: caseId });
  const {t}=useTranslation()
  // const [isEdit, setIsEdit] = useState(false);

  if (isLoading) {
    return <div>{t("global.globalNotifications.loadingMessage")}</div>;
  }
  const accountList = renderAccountList(data);
  const summaryData = {
    stepName: "",
    account: "",
    subAccount: "",
    description: "",
    payables: "",
    calculationGroupBy: "",
    comment:"",
    payeeType:""
  }
  const breadcrumbItems = [
    {
      label: "Back",
      elementKey: "Back",
      text: t("global.globalActions.back"),
      url: `/case/${caseId}/distribution/${distributionId}?activeTab=1`, 
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];
  
    return (
        <div>
            <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
            <CalculationStepHeader  /> 
            <div className="mar-t-30"></div>
            <CalculationForm
                summaryData={summaryData}  
                // setIsEdit={setIsEdit}
                accountList={accountList}
                caseId={caseId}
                distributionId={distributionId || ""}
            />
            {/* <DataSource caseId={caseId} resolvedStatus={false} stepId={"4FD51B65-77C1-43C4-9E61-08DCD3D8C105"}/>
            <ClassFilter caseId={caseId} resolvedStatus={false} stepId={"4FD51B65-77C1-43C4-9E61-08DCD3D8C105"}  /> */}
        </div>
    );
}

export default CalculationStepCreate;   