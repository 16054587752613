import React from 'react';
import {useTranslation} from 'react-i18next';

const SystemSettings = () =>{
  const {t} = useTranslation(); 
  return (
    <div>{t("System Settings")}</div>
  )
}

export default SystemSettings;