import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import DistributionSetupForm from "./DistributionSetupForm";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DistributionSetupGrid = () => {
  const { caseId } = useParams();
const {t}=useTranslation()
  const breadcrumbItems = [
    {
      label: "External Submission",
      value: "10 New Creditor Submission",
      elementKey: "submission",
      text: t("global.globalActions.back"),
      url: `/case/${caseId}/?activeTab=6`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];
  return (
    <>
      <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
      <h1>{t("pages.distributions.distribution.distributionSetup")}</h1>
      <DistributionSetupForm />
    </>
  );
};

export default DistributionSetupGrid;
