import { useNavigate } from "react-router-dom";
import { MotifButton, MotifIcon } from "@ey-xd/motif-react";
import {
	IconoirEditPencil,
	IconoirTrash,
	IconoirArrowUp,
	IconoirArrowDown,
} from "@ey-xd/motif-icon";

export const useCustomCellRenderers = ({
	handleDeleteEvent,
	schemeName,
	handleShiftRow,
}: {
	handleDeleteEvent: (ruleId: string) => void;
	schemeName: string;
	handleShiftRow: (rowIndex: number, direction: "up" | "down") => void;
}) => {
	const navigate = useNavigate();
	const caseRenderers = {
		actionCellRenderers: function (param: any) {
			return (
				<div className="motif-row">
					<div className="motif-col-lg-6">
						<MotifButton
							size="small"
							variant="primary"
							onClick={() => {
								handleDeleteEvent(param.data.id);
							}}
						>
							<MotifIcon
								iconFunction={IconoirTrash}
								stroke="blue"
								strokeWidth="2"
								size="24"
							/>
						</MotifButton>
					</div>
					<div className="motif-col-lg-6">
						<MotifButton
							onClick={() => {
								const ruleId = param.data.id;
								const className = param.data.className;
								navigate(`./edit-rule`, {
									state: { ruleId, className, schemeName },
								});
							}}
							size="small"
							type="submit"
							variant="primary"
						>
							<MotifIcon
								iconFunction={IconoirEditPencil}
								stroke="blue"
								strokeWidth="2"
								size="24"
							/>
						</MotifButton>
					</div>
				</div>
			);
		},
		sortingCellRenderers: function (param: any) {
			const { isDraft } = param;
			return (
				<div className="motif-row">
					<div className="motif-col-lg-6">
						<MotifButton
							size="small"
							variant="secondary"
							type="button"
							disabled={!isDraft}
							onClick={() => handleShiftRow(param.rowIndex, "up")}
						>
							<MotifIcon iconFunction={IconoirArrowUp} size="24" />
						</MotifButton>
					</div>
					<div className="motif-col-lg-6">
						<MotifButton
							onClick={() => handleShiftRow(param.rowIndex, "down")}
							size="small"
							type="button"
							variant="secondary"
							disabled={!isDraft}
						>
							<MotifIcon iconFunction={IconoirArrowDown} size="24" />
						</MotifButton>
					</div>
				</div>
			);
		},
	};
	return caseRenderers;
};
