import React, { useEffect, useState } from "react";
import {
  MotifButton,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifProgressLoader,
  MotifTabControl,
  MotifTabNavigation,
  MotifToast,
} from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  useLazyGetClaimStageStatusQuery,
  useLazyGetValidStageMovesQuery,
} from "@/services/stages/stagesReducer";
import "./ClaimStatusUpdate.scss";
import { useBulkUpdateClaimStagesAndStatusesMutation } from "@/services/claimDetail/claimDetailReducer";
import NotificationGrid from "@/components/Notification/NotificationGrid";

interface ClaimStageStatusProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  title: string;
  onClose: () => void;
  isError: string;
  selectedClaimIds: any;
  clearSelectedClaimIds:(closeTrigger:boolean)=>void;
  isAdvanceSearchActive: boolean;
  advanceSearchPayload: any;
  isBasicSearchActive: boolean;
  basicSearchPayload: any;
  isSearchActive: boolean;
  paginationObj: any;
  allClaimsSelected: boolean;
}

const UpdateStatus = ({
  showModal,
  setShowModal,
  title,
  onClose,
  isError,
  selectedClaimIds,
  clearSelectedClaimIds,
  isAdvanceSearchActive,
  advanceSearchPayload,
  isBasicSearchActive,
  basicSearchPayload,
  isSearchActive,
  paginationObj,
  allClaimsSelected,
}: ClaimStageStatusProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [stagesData, setStagesData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStageId, setCurrentStageId] = useState("");
  const [getCurrentClaimDataQuery] = useLazyGetClaimStageStatusQuery();
  const [statusData, setStatusData] = useState<any[]>([]);
  const [currentStatusId, setCurrentStatusId] = useState("");
  const [currentInstanceData, setCurrentInstanceData] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentStatusData, setCurrentStatusData] = useState<any>();
  const [docRequiredMessage, setDocRequiredMessage] = useState("");
  const [docReleaseMessage, setDocReleaseMessage] = useState("");
  const [docResolveMessage, setDocResolveMessage] = useState("");

  const [getValidStagesMoveQuery] = useLazyGetValidStageMovesQuery();
  const claimId = selectedClaimIds[0].claimId;

  const { caseId } = useParams<{
    caseId: string;
  }>();
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });
  const [bulkUpdateClaimStagesAndStatuses] =
    useBulkUpdateClaimStagesAndStatusesMutation();

  useEffect(() => {
    if (showModal) {
      setIsLoading(true);
      getValidStagesMoveQuery({
        CaseId: caseId,
        ClaimId: claimId,
      }).then((response) => {
        setStagesData(response.data);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [showModal]);

  useEffect(() => {
    if (stagesData.length !== 0) {
      setInitialValues();
    }
    // eslint-disable-next-line
  }, [stagesData, showModal]);

  useEffect(() => {
    const tempCurrentStatus = statusData.filter((status: any) => {
      return status.id === currentStatusId;
    });
    setCurrentStatusData(tempCurrentStatus[0]);

    setDocRequiredMessage("");
    setDocReleaseMessage("");
    setDocResolveMessage("");
    const currentStatus = statusData.find(
      (status) => status.id === currentStatusId
    );
    if (currentStatus?.isDocReleaseStatus) {
      setDocReleaseMessage(
        "pages.claims.claimsTable.bulkUpdateStatus.attachedDocumentsMessage"          
      );
    }
    if (currentStatus?.isResolveStatus) {
      setDocResolveMessage(
        "pages.claims.claimsTable.bulkUpdateStatus.resolveStatusMessage"          

      );
    }
  }, [statusData, currentStatusId]);

  const setInitialValues = () => {
    if (stagesData) {
      getCurrentClaimDataQuery(claimId).then((response) => {
        setCurrentInstanceData(response.data);
        setCurrentStageId(response.data.definitionIds.claimStageDefinitionId);
        setCurrentStatusId(response.data.definitionIds.claimStatusDefinitionId);
        onStageChange();
      });
    }
  };

  useEffect(() => {
    onStageChange();
    // eslint-disable-next-line
  }, [currentStageId]);

  const onStageChange = () => {
    if (stagesData && currentInstanceData) {
      const currentStageData = stagesData.filter((stage: any) => {
        return stage.id === currentStageId;
      });
      if (currentStageData && currentStageData.length !== 0) {
        const statuses = [...currentStageData[0].claimStatuses];
        setStatusData(statuses);

        if (
          currentStageId !==
          currentInstanceData.definitionIds.claimStageDefinitionId
        ) {
          const tempCurrentStatus = statuses.sort(
            (a: any, b: any) => a.sortOrder - b.sortOrder
          );
          tempCurrentStatus.length !== 0 &&
            setCurrentStatusId(tempCurrentStatus[0].id);
        } else {
          setCurrentStatusId(
            currentInstanceData.definitionIds.claimStatusDefinitionId
          );
        }
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };
  const renderMessage = () => {
    if (isError) {
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={showMessage.type}
        >
          {" "}
          {t(isError)}
        </MotifToast>
      );
    }
  };

  const handleSubmitNew = async () => {
    setIsUpdating(true);

        // eslint-disable-next-line array-callback-return
        Object.keys(basicSearchPayload).map(function (key) {
          if (basicSearchPayload[key] === "") {
            basicSearchPayload[key] = null;
          }
      });
    const requestBody = {
      ...(allClaimsSelected
        ? {}
        : {
            claimStageStatusRequests: selectedClaimIds.map((claim: any) => ({
              caseId,
              claimId: claim.claimId,
              claimStageDefinitionId: currentStageId,
              claimStatusDefinitionId: currentStatusId,
              claimStageInstanceId: claim?.claimStageId,
              comment: "", // Add your comment here if needed
            })),
          }),
      requestForAllStageStatusUpdate: {
        caseId,
        isExportAll: allClaimsSelected,
        searchType: isAdvanceSearchActive ? 2 : isBasicSearchActive ? 1 : 0,
        claimStageDefinitionId: currentStageId,
        claimStatusDefinitionId: currentStatusId,
        ...(isAdvanceSearchActive && {
          advanceSearchRequest: {
            ...advanceSearchPayload,
          },
        }),
        ...(isBasicSearchActive && {
          basicSearchRequest: {
            ...basicSearchPayload,
          },
        }),
        ...(isSearchActive && {
          quickSearchRequest: {
            ...paginationObj,
          },
        }),
      },
    };

    try {
      const response = await bulkUpdateClaimStagesAndStatuses(requestBody).unwrap();
      if (response && response[0].success) {
        setIsUpdating(false);
        handleClose();
        clearSelectedClaimIds(true);
      } else if (response && !response[0].success) {
        const claimsWithoutDocuments = response[0].claimsWithoutDocuments.join(", ");
        setDocRequiredMessage(
          `Please ensure all selected claims have a document attached in order to set this status. (optional) Please update [${claimsWithoutDocuments}].`
        );
        setIsUpdating(false);
      }
    } catch (error) {
      console.error("Bulk update failed:", error);
      setIsUpdating(false);
    }
  };

  return (
    <>
      <MotifModal
        onClose={handleClose}
        className="stage-status-popup-container"
        show={showModal}
        size="lg"
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Custom Close Button title",
          }}
        >
          <span>{t(title)}</span>
        </MotifModalHeader>
        {!isError ? (
          <>
            <MotifModalBody className="pad-t-10">
              {isLoading ? (
                <MotifProgressLoader show variant="default" />
              ) : (
                <>
                  <MotifTabNavigation controlled={true}>
                    {[...stagesData]
                      .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
                      .map((stage: any) => {
                        return (
                          <MotifTabControl
                            className={
                              currentStageId === stage.id ? "motif-active" : ""
                            }
                            onClick={() => setCurrentStageId(stage.id)}
                            key={stage.id}
                          >
                            {stage.name}
                          </MotifTabControl>
                        );
                      })}
                  </MotifTabNavigation>
                  {statusData.length !== 0 && (
                    <div className="stage-buttons-container">
                      {[...statusData]
                        .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
                        .map((status: any) => {
                          return (
                            <MotifButton
                              className={
                                "stage-button " +
                                (currentStatusId === status.id
                                  ? "active-stage"
                                  : "")
                              }
                              onClick={() => setCurrentStatusId(status.id)}
                              variant="primary-alt"
                              disabled={
                                !status.isValidMove &&
                                status.id !== currentStatusId &&
                                currentInstanceData.definitionIds &&
                                status.id !==
                                  currentInstanceData.definitionIds
                                    .claimStatusDefinitionId
                              }
                            >
                              {status.name}
                            </MotifButton>
                          );
                        })}
                    </div>
                  )}
                  {docReleaseMessage && !docResolveMessage && (
                    <div className="toast-message-div">
                      <NotificationGrid
                        show={true}
                        message={
"pages.claims.claimsTable.bulkUpdateStatus.attachedDocumentsMessage"          
              }
                        type="info"
                      />
                    </div>
                  )}
                  {docResolveMessage && !docReleaseMessage && (
                    <div className="toast-message-div">
                      <NotificationGrid
                        show={true}
                        message={
                          "pages.claims.stageStatusPopup.docResolveMessage"
                        }
                        type="info"
                      />
                    </div>
                  )}
                  {docReleaseMessage && docResolveMessage && (
                    <div className="toast-message-div">
                      <NotificationGrid
                        show={true}
                        message={
"pages.claims.claimsTable.bulkUpdateStatus.attachedDocumentsMessage"          
                        }
                        supportingText={t(
                          "pages.claims.stageStatusPopup.docResolveMessage"
                        )}
                        type="info"
                      />
                    </div>
                  )}
                  
                  {docRequiredMessage && (
                    <div className="toast-message-div">
                      <NotificationGrid
                        show={true}
                        message={docRequiredMessage}
                        type="error"
                      />
                    </div>
                  )}
                </>
              )}
            </MotifModalBody>

            <MotifModalFooter>
              <div className="document-footer display-flex ">
                <MotifButton
                  type="button"
                  variant="secondary"
                  className="cancelBtn mar-r-10"
                  onClick={handleClose}
                >
                  {t("global.globalActions.cancel")}
                </MotifButton>
                <MotifButton
                  onClick={handleSubmitNew}
                  variant="primary"
                  className="cancelBtn"
                  disabled={isUpdating}
                >
                  {isUpdating ? t("global.globalActions.updating") : t("global.globalActions.update")}
                </MotifButton>
              </div>
            </MotifModalFooter>
          </>
        ) : (
          renderMessage()
        )}
      </MotifModal>
    </>
  );
};

export default UpdateStatus;
