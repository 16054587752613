import { dateFunc } from "@/components/Functions/dateFunc";
import { MotifButton } from "@ey-xd/motif-react";
import { ClaimData } from "@/features/debtor/claimsTab/Constant/claimTabType";

export const caseRenderers = (onEditClick: () => void) =>({
  updatedDateRenderer: function (param: ClaimData) {
    return <>{dateFunc(param.data.updatedOn).formattedDateOnly}</>;
  },
  excludeRenderer: function () {
    return (
      <>
        <MotifButton
          onClick={onEditClick}
          size="small"
          type="submit"
          variant="secondary"
        >
          Exclude
        </MotifButton>
      </>
    );
  },
});
