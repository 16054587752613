import { dateFunc } from "@/components/Functions/dateFunc";
// import helpCircleImg from "@/features/columnList/images/help-circle.svg";
import { useTranslation } from 'react-i18next';


const useColumnDefinitions = () => {
  const { t } = useTranslation();


  const caselistcolheader = [
    {
      cellRenderer: "caseNameRenderer",
      field: "caseName",
      comparator: () => 0,
      type: "caseNameType",
      headerName: t(`pages.home.columnList.caseName`),
      sortable: true,
      editable: false,
      resizable: false,
      unSortIcon: true,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
      },
      flex: 7,
    },
    {
      cellRenderer: "shortNameRenderer",
      field: "shortName",
      comparator: () => 0,
      type: "shortNameType",
      headerName: t("pages.home.columnList.caseShortName"),
      sortable: true,
      editable: false,
      resizable: false,
      unSortIcon: true,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
      },
      flex: 7,
    },
    {
      cellRenderer: "dotCellRenderer",
      field: "caseStatus",
      type: "caseStatusType",
      headerName: t("pages.home.columnList.caseStatus"),
      flex: 3,
      sortable: true,
      comparator: () => 0,
      editable: false,
      unSortIcon: true,
      suppressHeaderMenuButton: true,
      resizable: false,
    },
    // {
    //   cellRenderer: "adminMembersCellRenderer",
    //   field: "adminmembers",
    //   type: "adminmembersType",
    //   flex: 4,
    //   headerName: t("Admin Members"),
    //   sortable: false,
    //   comparator: customComparator,
    //   editable: false,
    //   suppressCellFocus: false,
    //   suppressHeaderMenuButton: true,
    //   resizable: false,
    //   headerComponentParams: {
    //     suppressHeaderFilterButton: true,
    //     sortable: true,
    //     enableSorting: true,
    //     template: `<span class="ccwp_header_helpCircle">
    //       ${t("Admin Members")} <img src="${helpCircleImg}"/>
    //     </span>`,
    //   },
    // },
    {
      cellRenderer: function (params: any) {
        return (
          dateFunc(params.data.lastUpdatedDate).formattedDateTime +
          " " +
          params.data.abbreviation
        );
      },
      field: "lastUpdatedDate",
      filter: false,
      sortable: true,
      comparator: () => 0,
      editable: false,
      unSortIcon: true,
      resizable: false,
      flex: 5,
      headerName: t("pages.home.columnList.caseLastUpdateOn"),
      type: "lastUpdatedDateType",
    },
    {
      sort: null,
      headerName: "",
      flex: 1,
      width: 60,
    },
  ];
  return caselistcolheader;
};
export default useColumnDefinitions;