import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  MotifTabNavigation,
  MotifTabControl,
} from "@ey-xd/motif-react";

import { setCurrentTab } from "./PortalConfigurationSlice";
import { PortanConfigTab } from "./PortalConfigType";
import Distribution from "./FormManagement/FormManagement";
const FormName = "Form Management";
export default function PortalConfiguration() {
  const dispatch = useDispatch();
  const selectedTab = useSelector(
    (state: any) => state.distributions.currentTab
  );
  const renderTabContent = () => {
    switch (selectedTab) {
      case PortanConfigTab.FormManagement:
        return <Distribution />;

      default:
        return <></>;
    }
  };
  return (
    <>
      <div className="distributions-container">
        <MotifTabNavigation
          defaultActiveKey={selectedTab}
          orientation="vertical"
          controlled={true}
        >
          <MotifTabControl
            className={
              selectedTab === PortanConfigTab.FormManagement
                ? "motif-active"
                : ""
            }
            key={PortanConfigTab.FormManagement}
            onClick={() =>
              dispatch(setCurrentTab(PortanConfigTab.FormManagement))
            }
          >
            {/* {t("pages.distributions.distribution.title")} */}
            {FormName}
          </MotifTabControl>
        </MotifTabNavigation>

        <div className="distributions-tab-content">{renderTabContent()}</div>
      </div>
    </>
  );
}
