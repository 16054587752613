import React from 'react'
import './pageSubTitle.scss';
import {useTranslation} from 'react-i18next';

type PageTitleType = {
    title: string,
    description?: string,
}
const PageSubTitle  = ({title, description}: PageTitleType) => {
    const {t} = useTranslation();
    return (
        <>
        <div className='motif-body1-default-regular page-sub-title'>{t(title)}</div>
        <div className='motif-body2-default-regular page-description'>{description && t(description)}</div>
        </>
    )
}

export default PageSubTitle;