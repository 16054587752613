import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifFormField,
  MotifLabel,
  MotifInput,
  MotifTextArea,
} from "@/libs/@ey-xd/motif-react";
import {
  useCreateStatusMutation,
  useUpdateStatusMutation,
} from "@/services/stages/stagesReducer";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";

import { StatusType } from "./StagesType";

export default function CreateEditStatus({
  show,
  setShow,
  statusData,
  isEdit,
  getStageData,
  numStatuses,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  statusData: StatusType;
  isEdit: boolean;
  getStageData: (isCreate: boolean) => void;
  numStatuses: number;
}) {
  const { t } = useTranslation();
  const [inputValues, setInputValues] = useState(statusData);
  const [isLoading, setIsLoading] = useState(false);
  const [createStatusQuery] = useCreateStatusMutation();
  const [updateStatusQuery] = useUpdateStatusMutation();
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setInputValues(statusData);
    }
    // eslint-disable-next-line
  }, [show]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (isEdit) {
      const requestBody = { ...inputValues };
      await updateStatusQuery(requestBody).then(() => {
        handleClose();
        getStageData(false);
      });
    } else {
      const requestBody = {
        ...inputValues,
        sortOrder: numStatuses + 1,
      };
      delete requestBody.id;
      await createStatusQuery(requestBody).then(() => {
        handleClose();
        getStageData(false);
      });
    }
  };

  const handleClose = () => {
    setIsLoading(false);
    setIsDiscardVisible(false);
    setShow(false);
  };

  return (
    <>
      <MotifModal
        className="create-edit-status"
        onClose={() =>  {if(JSON.stringify(statusData)!==JSON.stringify(inputValues)){
          setIsDiscardVisible(true)

        }
        else{
          handleClose()
        }}}
        show={show}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <MotifModalHeader>
          {isEdit ? (
            <span>{t("pages.configuration.stages.editStatus")}</span>
          ) : (
            <span>{t("pages.configuration.stages.addStatus")}</span>
          )}
        </MotifModalHeader>

        <MotifModalBody>
          <MotifLabel>
            {t("pages.configuration.stages.statusName")}
            <span className="required-input">*</span>
          </MotifLabel>
          <MotifFormField>
            <MotifInput
              onChange={(e) =>
                setInputValues({ ...inputValues, name: e.target.value })
              }
              value={inputValues.name}
            />
          </MotifFormField>
          <MotifLabel>
            {t("pages.configuration.stages.statusNameFrench")}
          </MotifLabel>
          <MotifFormField>
            <MotifInput
              onChange={(e) =>
                setInputValues({ ...inputValues, nameFrench: e.target.value })
              }
              value={inputValues.nameFrench}
            />
          </MotifFormField>
          <MotifLabel>
            {t("pages.configuration.stages.statusDescription")}
          </MotifLabel>
          <MotifFormField>
            <MotifTextArea
              onChange={(e) =>
                setInputValues({ ...inputValues, description: e.target.value })
              }
              value={inputValues.description}
            />
          </MotifFormField>
          <MotifLabel>
            {t("pages.configuration.stages.statusDescriptionFrench")}
          </MotifLabel>
          <MotifFormField>
            <MotifTextArea
              onChange={(e) =>
                setInputValues({
                  ...inputValues,
                  descriptionFrench: e.target.value,
                })
              }
              value={inputValues.descriptionFrench}
            />
          </MotifFormField>
        </MotifModalBody>

        <MotifModalFooter>
          <MotifButton
            onClick={handleSubmit}
            type={isLoading ? "loader" : "submit"}
            variant="primary"
            disabled={!inputValues.name}
          >
            {isEdit
              ? t("pages.configuration.stages.edit")
              : t("pages.configuration.stages.add")}
          </MotifButton>
        </MotifModalFooter>
      </MotifModal>
      <ConfirmationModal isVisible={isDiscardVisible} setIsVisible={setIsDiscardVisible} cancelBtnApproveHandler={handleClose} cancelBtnRejectHandler={() => setIsDiscardVisible(false)}/>
    </>
  );
}
