import TabPanel from "@/components/TabPanel/TabPanel";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import CreditorTabContent from "@/features/creditor/creditorsTab/creditorTabContent";
import ClaimTabContent from "@/features/creditor/claimsTab/claimsTabContent";
import "@/features/creditor/claimsTab/style.scss";

export default function CreditorGrid() {

  const breadcrumbItems = [
    {
      label: 'External Submission',
      value: '10 New Creditor Submission',
      elementKey: 'submission',
      text: "Aldo group",
      url: "/",
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
    {
      label: 'External Submission',
      value: '10 New Creditor Submission',
      elementKey: 'submission',
      text: "Ashleigh Franklin",
      url: "/",
    },
  ];
  const tabPanelData = {
    title: ["Summary","Debtors", "Creditors", "Claims", "Voting", "Distributions", "Payment Status","Configuration"],
    content: [
      <div>Content for Summary</div>,
      <div>Content for Debtors</div>,
      <CreditorTabContent/>,
      <ClaimTabContent/>,
      <div>Content for Voting</div>,
      <div>Content for Distributions</div>,
      <div>Content for Payment Status</div>,
      <div>Content for Configuration</div>,
    ],
  };
  return (
    <>
      <div className="ccwp_table_wrapper ccwp_table_dropdown_wrapper">
        <Breadcrumb  items={breadcrumbItems} ariaLabel="Breadcrumb navigation"/>
        {/* <Banner
          bannerName={t(creditorBanner.creditorTtl)}
          bannerNameValue={t(creditorBanner.creditorName)}
          elements={bannerElements}
        /> */}
        {/* <GridBanner/> */}
        <div className="ccwp_table_tabpanel">
          <TabPanel data={tabPanelData} defaultActiveKey={2}/>
        </div>
      </div>
    </>
  );
}
