
import "@/features/claimsummary/style.scss";
import { MotifIcon, MotifProgressLoader } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Banner, { BannerElement } from "@/components/Banner/Banner";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { Summary } from "@/features/claimsummary/summary/summary";
import TabPanel from "@/components/TabPanel/TabPanel";
import { useParams } from "react-router-dom";
import {
  useGetclaimStageAndDocumentApiDataQuery,
  useGetClaimStatusApiDataQuery,
  useGetClaimSummaryViewApiDataQuery,
} from "@/services/claimSummary/caseSummaryReducer";
import { EditSummary } from "./editSummary/editSummary";
import { useState } from "react";
import { useCase } from "../case/use-case";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import AttributeHistoryTab from "./historyClaims/attributeHistory";
import ClaimsHistoryTab from "./historyClaims/clamiHistory";
import { useTranslation } from "react-i18next";

export const ClaimSummary = () => {
  const { caseId, creditorId, debtorId, claimId } = useParams();
  const caseData = useCase();
  const {t}=useTranslation();
  const [showEditPage, setEditPage] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const { data: claimStageData } =
    useGetclaimStageAndDocumentApiDataQuery(claimId);
  const { data: getClaimStatus } = useGetClaimStatusApiDataQuery(
    claimStageData?.definitionIds.claimStatusDefinitionId,
    { skip: !claimStageData }
  );
  const { data, isLoading } = useGetClaimSummaryViewApiDataQuery(claimId);
  const creditorBanner = {
    creditorTtl: "pages.claims.bannerDetails.title",
    creditorName: data?.claimSummary?.claimReference,
  };

  const bannerElements: BannerElement[] = [
    {
      label: "pages.claims.bannerDetails.label1",
      value: data?.claimSummary?.creditorName
        ? data?.claimSummary?.creditorName
        : "-",
      elementKey: "caseID",
      id: data?.claimSummary?.creditorId ? data?.claimSummary?.creditorId : "",
    },
    {
      label: "pages.claims.bannerDetails.label2",
      value: data?.claimSummary?.claimAmount.toLocaleString("en-CA", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
        ? data?.claimSummary?.claimAmount.toLocaleString("en-CA", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
          " " +
          caseData?.defaultUnit?.name
        : "-",
      addedClassName: "align-right",
    },
    {
      label: "pages.claims.bannerDetails.label3",
      value: claimStageData?.instanceData?.claimStageName
        ? claimStageData?.instanceData?.claimStageName
        : "-",
    },
  ];

  let breadcrumbParent = {
    label: "Case",
    value: caseData?.caseName,
    elementKey: "case",
    text: caseData?.caseName,
    url: `/case/${caseId}/?activeTab=3`,
    icon: <MotifIcon src={IconoirArrowLeft} />,
  };
  if (debtorId) {
    breadcrumbParent = {
      label: "Debtor",
      value: data?.claimSummary?.debtorName,
      elementKey: "debtor",
      text: data?.claimSummary?.debtorName,
      url: `/case/${caseId}/debtor/edit/${debtorId}?activeTab=1`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    };
  }
  if (creditorId) {
    breadcrumbParent = {
      label: "Creditor",
      value: data?.claimSummary?.creditorName,
      elementKey: "creditor",
      text: data?.claimSummary?.creditorName,
      url: `/case/${caseId}/creditor/${creditorId}?activeTab=1`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    };
  }
  const breadcrumbItems = [
    breadcrumbParent,
    {
      label: "Current Claim",
      value: data?.claimSummary?.claimReference,
      elementKey: "claim",
      text: t("pages.claims.breadCrums.claimId",{refernceId:data?.claimSummary?.claimReference}),
      url: "/",
      disabled: true,
    },
  ];
  const tabPanelData = {
    title: ["pages.claims.tabPannelDetails.summary", "pages.claims.tabPannelDetails.claimHistory", "pages.claims.tabPannelDetails.attributeHistory"],
    content: [
      <div>
        {showEditPage ? (
          <EditSummary
            setEditPage={setEditPage}
            setSuccessMsg={setSuccessMsg}
            resolvedStatus={getClaimStatus?.isResolveStatus}
          />
        ) : (
          <Summary
            setEditPage={setEditPage}
            unitName={caseData?.defaultUnit?.name}
            resolvedStatus={getClaimStatus?.isResolveStatus}
          />
        )}
      </div>,
      <div>
        <ClaimsHistoryTab />
      </div>,
      <div>
        <AttributeHistoryTab />
      </div>,
    ],
  };
  return (
    <>
      {successMsg && (
        <NotificationGrid
          show={true}
          type="success"
          message={"pages.claims.commonFieldLabels.claimUpdatedSuccesfully"}
        />
      )}
      {isLoading ? (
        <MotifProgressLoader variant="circular" size="xs" />
      ) : (
        <>
          <div >
            <Breadcrumb
              items={breadcrumbItems}
              ariaLabel="Breadcrumb navigation"
            />
          </div>
          <Banner
            bannerName={creditorBanner.creditorTtl}
            bannerNameValue={creditorBanner.creditorName}
            elements={bannerElements}
          />
        </>
      )}

      <div className="ccwp_table_tabpanel ccwp_claim_summary_view">
        <TabPanel data={tabPanelData} defaultActiveKey={0} />
      </div>
    </>
  );
};