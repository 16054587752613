export const FORM_ID = "name";
export const FORM_NAME = "Form Name(English)";

export const FORM_ID_FRENCH = "nameFrench";
export const FORM_NAME_FRENCH = "From Name(French)";

export const FORM_ID_DESCRIPTION = "description";
export const FORM_NAME_DESCRIPTION = "Form Description";

export const FORM_DESCRIPTION_FRENCH_ID = "descriptionFrench";
export const FORM_DESCRIPTION_FRENCH = "From Description(French)";

export const FORM_TYPE = "type";
export const FORM_TYPE_NAME = "From Type";

export const FORM_ACTION_TYPE = "actionSubType";
export const FORM_ACTION_TYPE_NAME = "From Action Type";

export const FORM_STATUS = "status";
export const FORM_STATUS_NAME = "From Status";

export const FORM_PUBLISH_DATE = "publishedDate";
export const FORM_PUBLISH_DATE_NAME = "From Publish Date";

export const FORM_DUE_DATE = "dueDate";
export const FORM_DUE_DATE_NAME = "From Due Date";

export const FORM_STAGE = "stage";
export const FORM_STAGE_NAME = "From Stage";

export const FORM_PAST_DUE_DATE = "pastDueAction";
export const FORM_PAST_DUE_DATE_NAME = " Past Due Submission";

export const FORM_IS_REVIEW = "isReview";
export const FORM_IS_REVIEW_NAME =
  " Allow users to review form details before submission";

export const FORM_IS_SIGNATURE = "isSignature";
export const FORM_IS_SIGNATURE_NAME =
  "Request users to sign the document and upload";

export const FORM_PUBLISH_LOGIC = "publishingLogic";
export const FORM_PUBLISH_LOGIC_NAME = "From Publishing Logic";
