import { Link } from "react-router-dom";
import { formatAmountUnitDecimalPlace } from "@/utility/common";
import { useParams } from "react-router-dom";
import { useGetUnitByCaseIdDataApiDataQuery } from "@/services/claimSummary/caseSummaryReducer";
import { dateFunc } from "@/components/Functions/dateFunc";
import { useTranslation } from "react-i18next";

const useDefaultUnitDetails = () => {
  const { caseId } = useParams<{
    caseId: string;
  }>();
  const { data : defaultUnitData,} = useGetUnitByCaseIdDataApiDataQuery(caseId);
  const decimalPlace = defaultUnitData?.decimalPlace;
  const defaultUnitName = defaultUnitData?.name;
  return { decimalPlace,defaultUnitName };
};

const CustomHeader = () => {
  const { defaultUnitName } = useDefaultUnitDetails();
  const unitName = defaultUnitName;
  return  ` (${unitName})`;
};
const amountCellRenderer = (field: string) => (params: any) => {
  const { decimalPlace } = useDefaultUnitDetails();
  const amount = params.data[field];
  return formatAmountUnitDecimalPlace(amount, decimalPlace);
};

export const useColumnDefs = ({
  caseId,
  debtorId,
  editId,
  abbreviation,
}: {
  caseId: string | undefined;
  debtorId: string | undefined;
  editId: string | undefined;
  abbreviation: string | undefined;
}) => {
  const {t}=useTranslation()
  return [
    {
      cellRenderer: function (params: any) {
        const thisId = debtorId ? debtorId : editId;
        const prefix = thisId
          ? `/case/${caseId}/debtor/${thisId}`
          : `/case/${caseId}`;
        return (
          <>
            <Link
              to={`${prefix}/claim/${params.data.claimId}`}
              state={{ from: params.data.claimId }}
            >
              {params.data.claimReferenceId}
            </Link>
          </>
        );
      },
      			comparator: () => 0,
      field: "claimReferenceId",
      type:"claimReferenceIdType",
       
      headerName: t("pages.debtors.debtorClaimsSection.columnsDetails.claimId"),
      unSortIcon: true,
      sortable: true,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
      },
      
    },
    {
      cellRenderer: "creditorCellRenderer",
      			comparator: () => 0,
      field: "creditorName",
      type:"creditorNameType",
      headerName: t("pages.debtors.debtorClaimsSection.columnsDetails.creditor"),
      unSortIcon: true,
      sortable: true,      
    },
    {
      			comparator: () => 0,
      field: "assigneeName",
      type:"assigneeNameType",
      headerName: t("pages.debtors.debtorClaimsSection.columnsDetails.assignee"),
      unSortIcon: true,
      sortable: true,
      cellRenderer: function (param: any) {
        return param.data.assigneeName !== "" ? param.data.assigneeName : "-";
      },
      
       

    },
    {
      cellRenderer: amountCellRenderer('currentAmount'),
      			comparator: () => 0,
      field: "currentAmount",
      type:"currentAmountType",
      headerClass: "align-right",
      unSortIcon: true,
      sortable: true,      
      cellClass: 'align-right',
      headerName: t("pages.debtors.debtorClaimsSection.columnsDetails.currentAmount",{unitName:CustomHeader()}),
      headerComponentParams: { params: "Current Amount" },
    },
    {
      			comparator: () => 0,
      field: "claimStageName",
      type:"claimStageNameType",
      headerName: t("pages.debtors.debtorClaimsSection.columnsDetails.stage"),
       
      unSortIcon: true,
      sortable: true,
      
    },
    {
      			comparator: () => 0,
      field: "actionStatusName",
      type:"actionStatusNameType",
      headerName: t("pages.debtors.debtorClaimsSection.columnsDetails.status"),
       
      unSortIcon: true,
      sortable: true,
      
    },
    {
      			comparator: () => 0,
      field: "updatedOn",
      type:"updatedOnType",
      headerName: t("pages.debtors.debtorClaimsSection.columnsDetails.lastUpdated"),
      unSortIcon: true,
      sortable: true,      
      cellRenderer: function (param: any) {
        return dateFunc(param.data.updatedOn).formattedDateTime + " " + abbreviation;
      },
    },
    {
      			comparator: () => 0,
            flex:1,
      field: "exclude",
      headerName: "",
       cellRenderer: "excludeRenderer",
      
     },
  ];
};