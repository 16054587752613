import Table from '@/components/Table/table'
import React, {useCallback,useEffect,useState } from 'react'
import TitleComplement from "@/features/debtor/debtorTab/components/titleComplement";
import {
  useGetDebtorListQuery
} from "@/services/debtors/debtorsReducers";
import { useColumnDefs } from "@/features/debtor/debtorTab/Constant/debtorHeaderConst";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCustomCellRenderers } from "@/features/debtor/debtorTab/Constant/debtorCustomCellConst";
import {
  ActiveClaimModel,
  NoClaimModel,
} from "@/features/debtor/debtorTab/modelBox";
import { useCase } from "@/features/case/use-case";
import CustomPagination from '@/components/Pagination/CustomPagination';
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from '@/components/Table/CustomPaginationConstants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface BannerData {
  debtorId: string;
  caseId: string;
  name: string;
  description: string;
  createdBy: string;
  updatedBy: string;
  isActive: boolean;
}

interface DebtorTabContentProps {
  bannerData: BannerData[];
  isSuccess?: boolean;
}

const DebtorTabContent = ({ bannerData }: DebtorTabContentProps) => {
  const { t }=useTranslation()
  const [showModal, setShowModal] = useState(false);
  const [activeClaim, setActiveClaim] = useState(undefined);
  const [debtorId, setDebtorId] = useState();
  const { caseId } = useParams<{ caseId: string }>();
  const caseData = useCase(caseId);
   let tableLoadingData=customTableLoadingData
  const paginationPageSize: number = customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
  const [paginationObj, setPaginationObj] = useState<any>({
    CaseID: caseId || caseData.caseId,
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",
 
  });
  let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({
    nameType:{sort:null}
  })

  const permissions = useSelector((state: any) => state.permissions);
  const { Debtor } = permissions;

  function handlePaginationData(data:any,columnTypesData:any){
    setPaginationObj({...data})
    setSortedColumnTypes({...columnTypesData})
  }
  const { data, error, isLoading,isFetching, refetch } = useGetDebtorListQuery(
    paginationObj
  );
  // const [triggerSearch, { isLoading: searchLoading, data: searchData }] =
  //   useLazySearchDebtorListDataQuery();
  /// eslint-disable-next-line no-use-before-define
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  let columnDefs=useColumnDefs(caseData)
let resetSortStatus=useCallback(()=>{
  let json:any={...sortedColumnTypes}
  for (const key in json) {
                json[key]={sort:null}
  }
  setSortedColumnTypes({...json})
},[sortedColumnTypes])
  const handleSearch = useCallback(
    (searchValue: string) => {
      if (searchValue.trim() === "") {
        setPaginationObj({
          CaseID: caseId || caseData.caseId,
          PageNumber: 1,
          PageSize: paginationPageSize,
          SearchText: ""
        })
       
        resetSortStatus()
            } else {
      setPaginationObj({
          CaseID: caseId || caseData.caseId,
          PageNumber: 1,
          PageSize: paginationPageSize,
          SearchText: searchValue
        })
       
        resetSortStatus()        // triggerSearch({
        //   caseId: caseId || caseData.caseId,
        //   SearchText: searchValue,
        // })
        //   .unwrap()
        //   .catch((error) => {
        //   });
      }
      setSearchQuery(searchValue);
    },
    [caseId,caseData,paginationPageSize,resetSortStatus]
  );

  const handleQuickSearchClear = useCallback(() => {
    setSearchQuery("");
  
    setPaginationObj({
      CaseID: caseId || caseData.caseId,
      PageNumber: 1,
      PageSize: paginationPageSize,
      SearchText: "",
    })
    resetSortStatus()        // triggerSearch({


  }, [caseId,caseData.caseId,paginationPageSize,resetSortStatus]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditClick = useCallback(() => {
    setIsEdit(true);
  }, []);

  useEffect(() => {
    if (isEdit) {
      navigate(`/case/${caseId}/debtor/edit`, { state: { bannerData } });
    }
  }, [isEdit, caseId, navigate, bannerData]);

  const renderers = useCustomCellRenderers(
    showModal,
    setShowModal,
    setActiveClaim,
    setDebtorId,
    handleEditClick,
    Debtor,
  );

  const headerData = {
    title: "pages.debtors.debtorsTableTile",
    description: "pages.debtors.debtorsDescription",
  };

  if (isLoading ) {
    return <div> {t("global.globalNotifications.loadingMessage")} </div>;
  }
  const tableData =  data?.results?data.results:[];
  return (
    <div className={"ccwp_claim_claimlisttable"}>
      {activeClaim === 0 ? (
        <NoClaimModel
          showModal={showModal}
          setShowModal={setShowModal}
          activeClaim={activeClaim}
          setActiveClaim={setActiveClaim}
          debtorId={debtorId}
          setDebtorId={setDebtorId}
          refetch={refetch}
        />
      ) : (
        <ActiveClaimModel
          showModal={showModal}
          setShowModal={setShowModal}
          activeClaim={activeClaim}
          setActiveClaim={setActiveClaim}
        />
      )}
      <>
      <Table
        columnDefs={columnDefs}
        columnTypes={{...sortedColumnTypes}}
        handlePaginationData={handlePaginationData}
            paginationObj={paginationObj}
        cellRenderers={isFetching?[]:tableData}
        TitleComplement={() => (
          <TitleComplement
            onSearch={handleSearch}
            handleQuickSearchClear={handleQuickSearchClear}
            searchQuery={searchQuery}
          />
        )}
        title={headerData.title}
        description={headerData.description}
        caseRenderers={renderers}
        // pagination={true}
        overlayNoRowsTemplate={isFetching?t(tableLoadingData):error?t("global.globalNotifications.wentWrong")
          : t("global.globalNotifications.noRecords")}
      />
         <CustomPagination
          currentpage={paginationObj.PageNumber}
          pageSize={paginationObj.PageSize}
          disableNext={!data?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
      </>
    </div>
  );
};

export default DebtorTabContent;
