import {
  GETACCOUNTDATABYID,
  GETACCOUNTLIST,
  GETSINGLECATEGORY,
  GETSUBACCOUNTDATABYID,
  GETSUBACCOUNTLIST,
} from "@/services/constant/apiPath";
import { ccwpApi } from "..";

export const configGetCategoryApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategoryByIdApiData: builder.query<any, any>({
      query: (categoryId) => GETSINGLECATEGORY + categoryId,
    }),
  }),
});
export const { useGetCategoryByIdApiDataQuery } = configGetCategoryApi;

export const configGetAccountApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccountByIdApiData: builder.query<any, any>({
      query: (caseId) => GETACCOUNTLIST + caseId,
    }),
    createAccount: builder.mutation({
      query: (data) => ({
        url: "/Account/CreateAccount",
        method: "POST",
        body: data,
      }),
    }),
    updateAccount: builder.mutation({
      query: (data) => ({
        url: "/Account/UpdateAccount",
        method: "PUT",
        body: data,
      }),
    }),
    updateSubAccount: builder.mutation({
      query: (data) => ({
        url: "/Account/UpdateSubAccount",
        method: "PUT",
        body: data,
      }),
    }),
    createSubAccount: builder.mutation({
      query: (data) => ({
        url: "/Account/CreateSubAccount",
        method: "POST",
        body: data,
      }),
    }),
    deleteAccount: builder.query({
      query: (id) => ({
        url: `Account/DeleteAccount?accountId=${id}`,
        method: "DELETE",
      }),
    }),
    deleteSubAccount: builder.query({
      query: (id) => ({
        url: `Account/DeleteSubAccount?subAccountId=${id}`,
        method: "DELETE",
      }),
    }),
  }),
});
export const { useGetAccountByIdApiDataQuery, useCreateAccountMutation, useUpdateAccountMutation, useUpdateSubAccountMutation, useCreateSubAccountMutation,useLazyDeleteAccountQuery,useLazyDeleteSubAccountQuery } = configGetAccountApi;

export const configGetSubAccountApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubAccountByIdApiData: builder.query<any, any>({
      query: (accoundId) => GETSUBACCOUNTLIST + accoundId,
    }),
  }),
});
export const { useGetSubAccountByIdApiDataQuery } = configGetSubAccountApi;

export const configGetAccountDataByIDApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccountDataByIdApiData: builder.query<any, any>({
      query: (accoundId) => GETACCOUNTDATABYID + accoundId,
    }),
  }),
});
export const { useGetAccountDataByIdApiDataQuery } =
  configGetAccountDataByIDApi;

export const configGetSubAccountDataByIDApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubAccountDataByIdApiData: builder.query<any, any>({
      query: (subAccountId) => GETSUBACCOUNTDATABYID + subAccountId,
    }),
  }),
});
export const { useGetSubAccountDataByIdApiDataQuery } =
  configGetSubAccountDataByIDApi;
