export const PAGE_TITLE = "Case details"
export const PAGE_DESCRIPTION = "Descriptive text that describes your page or content."
export const CASE_NAME = "Case Name";
export const CASE_ID = "caseName";
export const CASE_ID_F = "caseNameFrench";
export const CASE_DESCRIPTION = "Case Description";
export const CASE_DESCRIPTION_ID = "caseDescription";
export const CASE_DESCRIPTION_ID_F = "caseDescriptionFrench";
export const CASE__SHORT_NAME = "Case Short Name";
export const CASE_SHORT_ID = "caseShortName";

export const CASE_IDENTIFIERS = "Case Identifiers";
export const defaultSubAccount = { value: "", label: "Select Sub Account"};
export const defaultAccount = { value: "", label: "Select Account"};
export const defaultClass = { value: "", label: "Please Select"}; 

export const statusOptions = [
    {
      "label": "Open",
      "value": "1",
    }, 
    {
      "label": "Close",
      "value": "0",
    },
  ];

  export const ENTITLEMENT = "Entitlement";
  export const SETTLEMENT = "Settlement";

  export const  dataSourceOptions = [
    {
      value: "0",
      label: "Accepted claim"
    }, 
    {
      value: "1",
      label: "Prior calculation step"
    },
    {
      value: "2",
      label: "Manual data source"
    },
   ];

   export const  entitlementOptions = [
    {
      value: "0",
      label: "Entitlement"
    }, 
    {
      value: "1",
      label: "Settlement"
    },
   ];
  
   export const claculationGroupByOptions = [
    { label: "Select Calculation Group By", value: "" },
    { label: "Creditors", value: "0" },
    { label: "Claims", value: "1" },
    { label: "Assignee/Creditor", value: "2" },
    { label: "Case", value: "3" },
  ];

