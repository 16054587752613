import {
  MotifCard,
  MotifCardBody,
  MotifCardFooter,
  MotifCardHeader,
} from "@ey-xd/motif-react";
import { Link } from "react-router-dom";
import { QuickAction } from "./caseListType";
import {quickActions, quickActionHeading} from "./Constants/quickActionConst";
import {useTranslation} from 'react-i18next';
import threeDot from "@/features/caselist/images/more_horiz.svg"

export default function QuickActions() {
  const {t} = useTranslation(); 
  return (
    <div className="ccwp_quickaction_wrapper">
      <h6 className="motif-h6-default-regular">{t(quickActionHeading)}</h6>
      <div className="motif-row">
        {quickActions &&
          quickActions.map((items: QuickAction, index: number) => {
            return (
              <div className="motif-col-sm" key={index}>
                <MotifCard orientation="horizontal" variant="card-border">
                  <MotifCardHeader>
                    <div className="motif-card-subheader">
                      <div className="ccwp_quickaction_carheader">
                        <span className="motif-h6-default-regular ccwp_quickaction_heading">
                          {t(items.header)}
                        </span>
                        <span className="ccwp_quickaction_menu">
                          <img src={threeDot} alt="threeDot"/>
                        </span>
                      </div>
                    </div>
                  </MotifCardHeader>
                  <MotifCardBody>
                    <p className="motif-body1-default-light">{t(items.body)}</p>
                  </MotifCardBody>
                  <MotifCardFooter>
                    <Link to={items.link}>{t(items.footer)}</Link>
                  </MotifCardFooter>
                </MotifCard>
              </div>
            );
          })}
      </div>
    </div>
  );
}
