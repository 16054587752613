import { useState, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	MotifIcon,
	MotifProgressLoader,
	MotifErrorMessage,
} from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import PageTitle from "@/components/Form/PageTitle/PageTitle";
import Table from "@/components/Table/table";
import { CreditorColumnDefs } from "./creditorTable/CreditorColumnDefinations";
import TitleComplement from "./creditorTable/TitleComplement";
import {
	useGetBasicSearchCreditorListApiDataQuery,
	useLazySearchCreditorListDataQuery,
} from "@/services/creditors/creditorsReducers";
import { useLazyGetPreviewSchemeQuery } from "@/services/scheme/schemeReducer";
import { BasicSearch } from "@/features/creditor/creditorsTab/basicSearch";
import Claim from "./claim/Claim";
const SchemePreview = () => {
	const { t } = useTranslation();
	const { caseId, schemeId } = useParams();
	const [showBasicSearch, setShowBasicSearch] = useState(false);
	const [clearSearch, setClearSearch] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [isSearchActive, setIsSearchActive] = useState(false);
	const [isPreviewScheme, setIsPreviewScheme] = useState(false);
	const [creditorId, setCreditorId] = useState("");
	const [searchUrl, setSearchUrl] = useState<any>({
		caseId: caseId,
	});
	const location = useLocation();
	const schemeVersion = location.state?.schemeVersion;
	const breadcrumbItems = [
		{
			label: "Back",
			elementKey: "Back",
			text: "Back",
			url: `/case/${caseId}/scheme-manage/${schemeId}`,
			icon: <MotifIcon src={IconoirArrowLeft} />,
		},
	];
	const [formValues, setFormValues] = useState<any>({
		claimId: "",
		creditorId: "",
		creditorName: "",
		debtorName: "",
		country: "",
		province: "",
		postalCode: "",
		phoneNumber: "",
		claimUpdateDate: undefined,
	});
	const [triggerSearch, { isLoading: searchLoading, data: searchData }] =
		useLazySearchCreditorListDataQuery();
	const [
		triggerPreviewScheme,
		{ isLoading: isPreviewLoading, data: previewData, error: previewError },
	] = useLazyGetPreviewSchemeQuery();
	function clearBasicSearch() {
		setSearchUrl({
			caseId: caseId,
		});
		setFormValues((prevValues: any) => ({
			...prevValues,
			claimId: "",
			creditorId: "",
			creditorName: "",
			debtorName: "",
			country: "",
			province: "",
			postalCode: "",
			phoneNumber: "",
			claimUpdateDate: undefined,
		}));
	}

	const { data, error, isLoading, refetch } =
		useGetBasicSearchCreditorListApiDataQuery(searchUrl, {
			skip: !searchUrl.caseId,
			refetchOnMountOrArgChange: true,
		});

	const handleChange = (name: any, value: any) => {
		setFormValues({ ...formValues, [name]: value });
	};

	//Submit the form value in URL
	function handleSubmit() {
		setSearchUrl({
			...formValues,
			caseId: caseId,
		});
		if (formValues?.claimUpdateDate) {
			// Create a Date object
			const date = new Date(formValues?.claimUpdateDate);

			// Extract day, month, and year
			const day = ("0" + date.getDate()).slice(-2); // Ensure two digits
			const month = ("0" + (date.getMonth() + 1)).slice(-2); // Ensure two digits, month is 0-indexed
			const year = date.getFullYear();

			// Format in dd-mm-yyyy
			const updateDate = `${year}-${month}-${day}`;
			setSearchUrl((prevValues: any) => ({
				...prevValues,
				updateDate,
			}));
		}
		setFormValues((prevValues: any) => ({
			...prevValues,
			claimId: "",
			creditorId: "",
			creditorName: "",
			debtorName: "",
			country: "",
			province: "",
			postalCode: "",
			phoneNumber: "",
			claimUpdateDate: undefined,
		}));
		setClearSearch(true);
	}
	const handleQuickSearchClear = useCallback(() => {
		setSearchQuery("");
		setIsSearchActive(false);
		refetch();
	}, [refetch]);

	const handleSearch = useCallback(
		(searchValue: string) => {
			setSearchQuery(searchValue);
			if (searchValue.trim() === "") {
				setIsSearchActive(false);
			} else {
				triggerSearch({
					CaseId: caseId,
					SearchText: searchValue,
				})
					.unwrap()
					.catch((error) => {
						console.error("Search failed:", error);
					});
				setIsSearchActive(true);
			}
		},
		[triggerSearch, caseId]
	);

	const handlePreviewSearch = () => {
		triggerPreviewScheme({ schemeVersion, creditorId })
			.unwrap()
			.catch((error) => {
				console.error("Preview Scheme failed:", error);
			});
	};
	if (searchLoading || isLoading) {
		return <MotifProgressLoader show variant="default" />;
	}
	if (error) {
		return <MotifErrorMessage>Data could not be loaded</MotifErrorMessage>;
	}

	return (
		<>
			<Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
			<PageTitle
				title={t("pages.configuration.scheme.previewTitle")}
				description={t("pages.configuration.scheme.previewDescription")}
			/>
			<div className="ccwp_table_wrapper ccwp_table_dropdown_wrapper">
				{isSearchActive ? (
					<div className={"ccwp_claim_claimlisttable"}>
						<Table
							columnDefs={CreditorColumnDefs({
								creditorId,
								setCreditorId,
								setIsPreviewScheme,
							})}
							cellRenderers={searchData}
							TitleComplement={() => (
								<TitleComplement
									showBasicSearch={showBasicSearch}
									setShowBasicSearch={setShowBasicSearch}
									clearBasicSearch={clearBasicSearch}
									clearSearch={clearSearch}
									setClearSearch={setClearSearch}
									onSearch={handleSearch}
									handleQuickSearchClear={handleQuickSearchClear}
									searchQuery={searchQuery}
									isPreviewScheme={isPreviewScheme}
									handlePreviewSearch={handlePreviewSearch}
								/>
							)}
							title={"Creditor"}
							description={"Creditors list related to the case"}
							pagination={true}
							overlayNoRowsTemplate={t("pages.configuration.scheme.noRecord")}
						/>
					</div>
				) : (
					<div
						className={
							"ccwp_claim_claimlisttable" +
							(clearSearch ? " ccwp_creditor_header" : "") +
							(showBasicSearch ? " hide_claims_table" : "")
						}
					>
						<Table
							columnDefs={CreditorColumnDefs({
								creditorId,
								setCreditorId,
								setIsPreviewScheme,
							})}
							cellRenderers={data}
							TitleComplement={() => (
								<TitleComplement
									showBasicSearch={showBasicSearch}
									setShowBasicSearch={setShowBasicSearch}
									clearBasicSearch={clearBasicSearch}
									clearSearch={clearSearch}
									setClearSearch={setClearSearch}
									onSearch={handleSearch}
									handleQuickSearchClear={handleQuickSearchClear}
									searchQuery={searchQuery}
									isPreviewScheme={isPreviewScheme}
									handlePreviewSearch={handlePreviewSearch}
								/>
							)}
							title={"Creditor"}
							description={"Creditors list related to the case"}
							pagination={true}
							overlayNoRowsTemplate={t("pages.configuration.scheme.noRecord")}
						/>

						{showBasicSearch && (
							<BasicSearch
								handleChange={handleChange}
								creditorName={formValues.creditorName}
								creditorId={formValues.creditorId}
								claimId={formValues.claimId}
								debtorName={formValues.debtorName}
								country={formValues.country}
								province={formValues.province}
								postalCode={formValues.postalCode}
								phoneNumber={formValues.phoneNumber}
								claimUpdateDate={formValues.claimUpdateDate}
								setShowBasicSearch={setShowBasicSearch}
								handleSubmit={handleSubmit}
							/>
						)}
					</div>
				)}
			</div>
			{isPreviewLoading ? (
				<div style={{ minHeight: "300px" }}>
					<MotifProgressLoader show variant="default">
						Loading claim item details...
					</MotifProgressLoader>
				</div>
			) : previewError ? (
				<MotifErrorMessage>Data could not be loaded</MotifErrorMessage>
			) : previewData ? (
				previewData.length > 0 ? (
					<Claim previewData={previewData} />
				) : (
					<div>There is no claim data</div>
				)
			) : null}
		</>
	);
};
export default SchemePreview;
