import React, { Dispatch, SetStateAction } from "react";
import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import {useTranslation} from 'react-i18next';
import { CurrencyConfigurationType } from "@/pages/CaseDetail/CaseSetup/CaseSetupType";
import { NubmerErrorType } from "@/pages/CaseDetail/CaseSetup/CurrencyConfiguration/type";
import InputBoxNumber from "./InputBoxNumber";
 

type ControlInputBoxType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<CurrencyConfigurationType>;
    name: string;
    showLabel: boolean;
    label: string;
    isNumber: boolean;
    setNumberError?: Dispatch<SetStateAction<NubmerErrorType[]>>;
    digitAfterDecimal?: number; 
    isAmount?: boolean;
    onChangeAmount?: (value: string) => void;
    validate?: (value: string | number) => boolean;
    readonly?: boolean;
}


export default function ControlInputBoxNumber ({name, control, required=false, isTextArea=false, showLabel, label, digitAfterDecimal, isAmount, onChangeAmount, validate, readonly}: ControlInputBoxType) {
  
  const {
        field,
      } = useController({
        // @ts-ignore
        name,
        control,
        // @ts-ignore
        rules: { required: required, validate: validate },
      });
    const {t} = useTranslation();

    return (
        <> 
            {
                showLabel && <div className="formFieldLabel">
                    <label  htmlFor="input" aria-label={label}>{t(label)}{ required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
           <Controller
                name={field.name}
                control={control}
                render={({ field: { onChange, value, ...args } }) => {
                          return (
                          <>
                            {/*@ts-ignore*/}
                            <InputBoxNumber   id={`InputBoxNumber-${field.name}`} required={true} value={value || ""} onChange={onChange} isTextArea={isTextArea} {...args} digitAfterDecimal={digitAfterDecimal} isAmount={isAmount} onChangeAmount={onChangeAmount} readonly={readonly}/>
                          </>
                          );
                    }}
            />
        </>
        
    );
}
