import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { MotifProgressLoader, MotifButton } from "@ey-xd/motif-react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import getMSALCache from "./lib/getMSALCache";
import logger from "@/libs/logger";

import "./auth.scss";

const Login = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [loginMessage, setLoginMessage] = useState("Loading...");
  const userState = useSelector((state: any) => state.user);
  const location = useLocation();
  const navigate = useNavigate();

  function handleLogin() {
    sessionStorage.clear();
    window.location.reload();
  }

  useEffect(() => {
    setTimeout(() => {
      setLoginMessage(
        "Sorry, there was an error logging in. Please click Login to try again."
      );
    }, 5000);
  }, []);

  useEffect(() => {
    if (!userState || !userState.id) {
      sessionStorage.setItem(
        "return_uri",
        window.location.pathname + window.location.search
      );
    }
  }, [userState]);

  useEffect(() => {
    if (userState && userState.id) {
      const returnUri = sessionStorage.getItem("return_uri") || "/";
      navigate(returnUri);
      sessionStorage.removeItem("return_uri");
    }
  }, [navigate, userState]);

  useEffect(() => {
    if (accounts.length > 0) {
    } else if (inProgress !== "none") {
      // do nothing
    } else {
      const checkMSALSession = () => {
        if (
          !sessionStorage.getItem("msal.login.request") &&
          location.pathname !== "/logout"
        ) {
          sessionStorage.setItem("msal.login.request", "true");
          instance
            .loginRedirect()
            .then()
            .catch((err) => {
              console.trace(err);
              logger.info(`MSAL Login Error Code: ${err.errorCode}`);
              if (err.errorCode === "interaction_in_progress") {
                sessionStorage.clear();
              }
              if (err.errorCode === "invalid_request") {
                sessionStorage.clear();
              }
            });
        } else {
          const token = getMSALCache("accesstoken", "openid");
          if (token) {
            sessionStorage.removeItem("msal.login.request");
          }
        }
      };
      checkMSALSession();
    }

    return () => {
      const token = getMSALCache("accesstoken", "default");
      if (token) {
        sessionStorage.removeItem("msal.login.request");
      }
    };
  }, [accounts, inProgress, instance, location.pathname]);

  return (
    <div className="login-container">
      <p className="motif-h1">{loginMessage}</p>
      {loginMessage.length <= 10 ? (
        <MotifProgressLoader show={true} />
      ) : (
        <MotifButton variant="primary" type="button" onClick={handleLogin}>
          Login
        </MotifButton>
      )}
    </div>
  );
};

export default Login;
