import { useTranslation } from "react-i18next";
export const usePaymentSummaryColumnDefs = (handleCellClick: (params: any) => void,) => {
	const { t } = useTranslation();
	return [
		{
			field: "claimItemReference",
			type: "claimIdType",
			headerName: t(
				"pages.paymentStatus.paymentSummary.tableColumnHeader.claimItemId"
			),
			flex: 1,
			width: 150,
			sortable: true,
			unSortIcon: true,
			onCellClicked: handleCellClick,
		},
		{
			field: "entitlementAmount",
			type: "entitlementAmountType",
			headerName: t(
				"pages.paymentStatus.paymentSummary.tableColumnHeader.entitlementAmount"
			),
			flex: 1,
			width: 150,
			sortable: true,
			unSortIcon: true,
		},
		{
			field: "priorSettledAmount",
			type: "priorSettledAmountType",
			headerName: t(
				"pages.paymentStatus.paymentSummary.tableColumnHeader.priorSettledAmount"
			),
			flex: 1,
			width: 150,
			sortable: true,
			unSortIcon: true,
		},
		{
			field: "settlementAmount",
			type: "settlementAmountType",
			headerName: t(
				"pages.paymentStatus.paymentSummary.tableColumnHeader.settlementAmount"
			),
			sortable: true,
			unSortIcon: true,
			flex: 1,
			width: 150,
		},
	];
};
