import { MotifAccordion, MotifAccordionContent, MotifAccordionTrigger, MotifErrorMessage, MotifToast } from "@ey-xd/motif-react";
import { ADD_ORIGINAL_CURRENCY, CURRENCY_CONFIGURATION, CURRENCY_CONFIGURATION_DESCRIPTION, SELECT_DISPLAY_CURRENCY } from "../Constants";
import {useTranslation} from 'react-i18next';
import { useFieldArray, useForm } from "react-hook-form";
import { ADD_ANOTHER, DEFAULT_CURRENCY_ID, DEFAULT_CURRENCY_NAME, DEFAULT_DECIMAL_POINT, DEFAULT_DECIMAL_POINT_ID, DEFAULT_EXCHANGE_RATE, DEFAULT_EXCHANGE_RATE_ID, ORIGINAL_CURRENCY_Id, ORIGINAL_DECIMAL_POINT_ID, ORIGINAL_EXCHANGE_RATE_ID } from "./Constants";
import ControlInputBoxCurrency from "./ControlInputBoxCurrency";
import { useEffect, useState } from "react";
import { CurrencyConfigurationType } from "./type";
import Button from "@/components/Form/Button/Button";
import "./currencyConfiguration.scss";
import { CANCEL, ENEXPECTED_ERROR, NEXT, SECONDARY, UPDATE_SUCCESSFUL } from "@/Constant";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { CurrencyRowType } from "../CaseSetupType";
import ControlInputBoxNumber from "./ControlInputBoxNumber";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@/app/store";
import { resetCaseSetup, setCurrencyConfigurationData, updateFormSubmitIndex, updateStage } from "../caseSetupSlice";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
 
export default function CurrencyConfiguration() {
    const [isVisible, setIsVisible] = useState(false);
    const [showMessage, setShowMessage] = useState({
        show: false,
        type: "error",
    });
    const CaseSetupData = useSelector(
        (state: RootState) => state.caseSetup
      );
      const dispatch = useDispatch();
    const {currencyConfiguration} = CaseSetupData;
    const formSubmitIndex = CaseSetupData.formSubmitIndex;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [isFormOpen, setIsFormOpen] = useState(true);
    const [isFormOpenOrg, setIsFormOpenOrg] = useState(true);
    const {handleSubmit, control ,watch,formState: { errors }} = useForm<CurrencyConfigurationType>(
       {
        mode: 'onChange',
        defaultValues: {
            defaultCurrency: currencyConfiguration?.defaultCurrency || '',
            defaultExchangeRate: '1',
            defaultDecimalPoint: currencyConfiguration?.defaultDecimalPoint || '',
            fieldArray: currencyConfiguration?.fieldArray || []
        }
       }
    );

      const { fields, append, remove  } = useFieldArray({
        control,
        name: "fieldArray"
      });
      const watchFieldArray = watch("fieldArray");
    const errorList = Object.keys(errors);
    //const errorsShow = errorList.join(", ").replace(FIELD_ARRAY, `${ORIGINAL_CURRENCY_NAME} ${ORIGINAL_EXCHANGE_RATE} ${ORIGINAL_DECIMAL_POINT}`);
    const onSubmit = async (data:CurrencyConfigurationType) => {
        dispatch(setCurrencyConfigurationData(data));
        dispatch(updateStage(3));
        formSubmitIndex !== 3 && dispatch(updateFormSubmitIndex(2));
    }
    const controlledFields = fields.map((field, index) => {
        return {
          ...field,
          ...watchFieldArray[index]
        };
      });
      
  const cancelBtnApproveHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsVisible(false);
    setShowMessage({...showMessage, show: false, type: "error"});
    dispatch(resetCaseSetup());
    navigate('/')
  }

  const cancelBtnRejectHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsVisible(false);
    setShowMessage({...showMessage, show: false, type: "error"});
    dispatch(setDiscardEditMode({ editMode: true }));

  }

const closeMessageHandler = () => { 
  setShowMessage({...showMessage, show: false, type: "error"});
}
useEffect(() => {
  dispatch(setDiscardEditMode({ editMode: true }));
  return () => {
    dispatch(setDiscardEditMode({ editMode: false }));
  };
}, [dispatch]);
const renderMessage = () => {
  if(showMessage.show && !isVisible) {
    window.scrollTo(0, 0);
    return (
      <MotifToast
        onClose={closeMessageHandler}
        //@ts-ignore
        variant={showMessage.type}
      > {showMessage.type === "error" ? t(ENEXPECTED_ERROR): t(UPDATE_SUCCESSFUL)} 
      </MotifToast>
    )
  }
}
      const addMoreCurrencyRow = () => {
        append({
            originalCurrency: "",
            originalExchangeRate: "",
            originalDecimalPoint: ""
        })
      }
      const removeCurrencyRow = (index: number) => {
        remove(index);
      }

      const renderCurrencyRow = (field :CurrencyRowType, index: number) => {
        return (
            <div className="motif-row heading pad-b-20 ">
                <div className="motif-col-xl-3">
                    {/**@ts-ignore */}
                    <ControlInputBoxCurrency key={field.id} name={`fieldArray.${index}.originalCurrency`} control={control} required={true} isTextArea={false} label={t(ORIGINAL_CURRENCY_Id)} showLabel={true} />
                </div>
                <div className="motif-col-xl-2">
                    <ControlInputBoxNumber key={field.id} name={`fieldArray.${index}.originalExchangeRate`} control={control} required={true} isTextArea={false} label={t(ORIGINAL_EXCHANGE_RATE_ID)} showLabel={true} readonly={false}/>
                </div>
                <div className="motif-col-xl-2">
                    
                    <ControlInputBoxNumber key={field.id} name={`fieldArray.${index}.originalDecimalPoint`} control={control} required={true} isTextArea={false} label={t(ORIGINAL_DECIMAL_POINT_ID)} showLabel={true} isNumber={true}/>
                </div>
                <div className="motif-col-xl-3 pad-t-20">
                    <Button type="button" variant="text" label="Remove" onClickHandler={()=>{removeCurrencyRow(index)}}/>
                </div>
            </div>
        );
      }
    return (
        <>
            {renderMessage() }
            <form onSubmit={handleSubmit(onSubmit)}>      
                <div className="motif-col-lg-12  main-container currency-configuration-container">
                    <div className="motif-row t-b-padding-21"> 
                        <div className="motif-col-xs-4">        
                            <p className='form-title'>{t(CURRENCY_CONFIGURATION)}</p>
                            <p className='form-description'>{t(CURRENCY_CONFIGURATION_DESCRIPTION)}</p>
                        </div>
                    </div>
                    <div className="motif-row t-b-padding-21"> 
                        <div className="motif-col-xs-4"> 
                            <div
                            style={{
                                alignItems: 'flex-start',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '15px'
                            }}
                            >
                            
                                <MotifAccordion
                                onClose={()=>{setIsFormOpen(false)}}
                                onOpen={()=>{setIsFormOpen(true)}}
                                style={{
                                    flex: '1'
                                }}
                                open={isFormOpen}
                                useChevronIcon={true}
                                alignIconRight={true}
                                >
                                <MotifAccordionTrigger>
                                    {t(SELECT_DISPLAY_CURRENCY)}
                                </MotifAccordionTrigger>
                                <MotifAccordionContent>
                                    <div className="motif-container pad-l-40 pad-r-20">
                                        <div className="motif-row heading"> 
                                            <div className="motif-col-xl-3">
                                            {/*@ts-ignore */}   
                                            <ControlInputBoxCurrency name={t(DEFAULT_CURRENCY_NAME)} control={control} required={true} isTextArea={false} label={t(DEFAULT_CURRENCY_ID)} showLabel={true} />
                                            </div>
                                            <div className="motif-col-xl-2">
                                            <ControlInputBoxNumber name={t(DEFAULT_EXCHANGE_RATE)} control={control} required={true} isTextArea={false} label={t(DEFAULT_EXCHANGE_RATE_ID)} showLabel={true} readonly={true} />
                                            </div>
                                            <div className="motif-col-xl-2">
                                            <ControlInputBoxNumber name={t(DEFAULT_DECIMAL_POINT)} control={control} required={true} isTextArea={false} label={t(DEFAULT_DECIMAL_POINT_ID)} showLabel={true} readonly={false} integerOnly={true}/>
                                            </div>
                                            <div className="motif-col-xl-2">
                                            </div>
                                        </div>
                                    </div>
                                </MotifAccordionContent>
                                </MotifAccordion>
                            </div>
                        </div>
                    </div>
                    <div className="motif-row t-b-padding-21"> 
                        <div className="motif-col-xs-4"> 
                            <div
                            style={{
                                alignItems: 'flex-start',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '15px'
                            }}
                            >
                            
                                <MotifAccordion
                                onClose={()=>{setIsFormOpenOrg(false)}}
                                onOpen={()=>{setIsFormOpenOrg(true)}}
                                style={{
                                    flex: '1'
                                }}
                                open={isFormOpenOrg}
                                useChevronIcon={true}
                                alignIconRight={true}
                                >
                                <MotifAccordionTrigger>
                                    {t(ADD_ORIGINAL_CURRENCY)}
                                </MotifAccordionTrigger>
                                <MotifAccordionContent>
                                    <div className="motif-container"> 
                                        {controlledFields.map((field, index) => {
                                            return renderCurrencyRow(field, index);
                                        })}     
                                    </div>
                                    
                                    <div className="add-another-btn">
                                        <Button label={t(ADD_ANOTHER)}  type="button" onClickHandler={addMoreCurrencyRow}/>
                                    </div>
                                </MotifAccordionContent>
                               
                                </MotifAccordion>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="error-container">
                    <MotifErrorMessage>
                        {
                            errorList.length > 0 ? t('Please ensure all required fields are filled and input format is valid. ') : ''
                        //errorList.length > 0 ? t(`${REQUIRED_MESSAGE} ${errorsShow} cannot be left blank or invaid Number.`) : ''
                        }
                    </MotifErrorMessage>
                </div>
                <div className="button-container"> 
                    <Button label={t(NEXT)}  />
                    <Button label={t(CANCEL)} type="button" variant={SECONDARY} className="cancelBtn" onClickHandler={()=>{
                        setIsVisible(true);
                        setShowMessage({...showMessage, show: false, type: "error"});
                        dispatch(setDiscardEditMode({ editMode: false }));

                        }}  />
                </div>
                <ConfirmationModal isVisible={isVisible} setIsVisible={setIsVisible} cancelBtnApproveHandler={cancelBtnApproveHandler} cancelBtnRejectHandler={cancelBtnRejectHandler}/>
            </form>
        </>
    );
}