import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./UserManagement.scss";
import {
  MotifButton,
  MotifFormField,
  MotifLabel,
  MotifOption,
  MotifSelect,
  MotifTable,
  MotifErrorMessage,
  MotifToast,
  MotifModal,
  MotifModalFooter,
  MotifModalBody,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { useForm, Controller } from "react-hook-form";
import useColumnDefinitions from "@/features/usermanagement/components/TableDetails/colHeaderConst";
import useCustomCellRenderers from "@/features/usermanagement/components/TableDetails/customCellConst";
import { roleType } from "@/features/usermanagement/components/userTypes";
import {
  useGetUsersListMutation,
  useCreateUserMutation,
  useGetListUsersQuery,
  useDeleteUserMutation,
  useUpdateRoleMutation,
} from "@/services/userManagement/userReducers";
import {
  createType,
  deleteUserType,
  modalRoleType,
  modalType,
  toastType,
  updateRoleType,
  userSearchType,
} from "./UsermanagementTypes";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import SearchInput from "@/components/SearchInput/SearchInput";

const UserManagement = () => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();

  let rolesList: roleType[] = [
    { roleName: "pages.userManagementPage.basicUser", roleId: "0" },
    { roleName: "pages.userManagementPage.caseCreator", roleId: "1" },
    { roleName: "pages.userManagementPage.systemAdmin", roleId: "2" },
  ];
  const commonErrorMessage = "global.globalNotifications.wentWrong";
  let tableLoadingData=customTableLoadingData
  const paginationPageSize: number = customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;

  const cellRenderers = useCustomCellRenderers(handleDelete);
  const [
    getUsersList,
    {
      data: serachUsersData,
      isLoading: loadingUserList,
      isError: errorUserList,
      error: searchUserError,
    },
  ] = useGetUsersListMutation();
  const [paginationObj, setPaginationObj] = useState({
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",



  });
  const searchUserErrorDetails: any = searchUserError;
  let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({
  })

  function handlePaginationData(data:any,columnTypesData:any){
    setPaginationObj({...data})
    setSortedColumnTypes({...columnTypesData})
  }

  const columnDefs = useColumnDefinitions();

  const [createUser, { isLoading: loadingCreateUser }] =
    useCreateUserMutation();

  const {
    refetch,
    data: listOfUsers,
    isLoading: loadingUsers,
    isError: errorUsers,
    isFetching:fetchingUsers
  } = useGetListUsersQuery(paginationObj);

  let userGroupList: any = useMemo(
    () => (listOfUsers ? JSON.parse(JSON.stringify(listOfUsers)) : ""),
    [listOfUsers]
  );

  const [deleteUser, { isLoading: loadingDeleteUser }] =
    useDeleteUserMutation();
  const [UpdateRole, { isLoading: loadingUpdateRoleUser }] =
    useUpdateRoleMutation();
  const modalJson: modalType = {
    show: false,
    userId: "",
    oid: "",
    displayName: "",
  };
  const modalRoleJson: modalRoleType = {
    show: false,
    data: "",
    rowNo: "",
    roleDescription: "",
  };
  const [modalData, setModalData] = useState<modalType>({ ...modalJson });
  const [roleUpdateModal, setRoleUpdateModal] = useState<modalRoleType>({
    ...modalRoleJson,
  });
  const timeIntervalValue = useRef<number | null>(null);
  const userSerachTimeValue = useRef<number | null>();
  const abortFunRef = useRef<any>();
  const showLoadingMessage = useRef<boolean>(false);
  const { control, handleSubmit, setValue, getValues, formState, reset } =
    useForm<userSearchType>({
      defaultValues: {
        enteredName: "",
        roleId: "",
        oid: "",
      },
    });

  const toastJson: toastType = {
    showToast: false,
    showMessage: "",
    toastType: "",
  };
  const [showSuccessMessage, setShowSuccessMessage] = useState<toastType>({
    ...toastJson,
  });
  const [optionsListAll, setOptionsListAll] = useState<any>([]);

  function getOptions(optionsData: any) {
    let userEnteredValue: any = getValues("enteredName");
    let userNames: any[] = [];
    if (userEnteredValue && optionsData) {
      userNames = optionsData.map((ele: any) => {
        let optionJson = {
          searchTerm: `${ele.displayName} | ${ele.email}`,
          oid: ele.oid,
        };
        return optionJson;
      });
    }
    setOptionsListAll([...userNames]);
  }

  async function userSearch(enteredValue: string, searchTime: number) {
    if (userSerachTimeValue.current) {
      clearTimeout(userSerachTimeValue.current);
    }
    showLoadingMessage.current = true;
    setOptionsListAll([]);
    if (enteredValue) {
      let val = setTimeout(async () => {
        const data = getUsersList({
          searchText: enteredValue,
          limit: 0,
        });
        abortFunRef.current = data;

        let postData: any = await data;
        getOptions(postData?.data);
        showLoadingMessage.current = false;
      }, searchTime);
      userSerachTimeValue.current = +val;
    } else {
      showLoadingMessage.current = false;
    }
  }

  useEffect(() => {
    return () => {
      if (timeIntervalValue && timeIntervalValue.current) {
        clearTimeout(timeIntervalValue.current);
      }
      if (userSerachTimeValue && userSerachTimeValue.current) {
        clearTimeout(userSerachTimeValue.current);
      }
    };
  }, []);
  function removeToast() {
    let timeOutVal = setTimeout(() => {
      setShowSuccessMessage({ ...toastJson });
      timeIntervalValue.current = null;
    }, 2000);
    timeIntervalValue.current = +timeOutVal;
  }
  function userDelete() {
    let userDeleteJson: deleteUserType = {
      id: modalData.userId,
      oid: modalData.oid,
    };
    deleteUser(userDeleteJson).then((value: any) => {
      if (value.data) {
        refetch();
        setShowSuccessMessage({
          showToast: true,
          showMessage: "global.globalNotifications.userDeletedMessage",
          toastType: "success",
        });
      } else {
        setShowSuccessMessage({
          showToast: true,
          showMessage: commonErrorMessage,
          toastType: "error",
        });
      }
      setModalData({ ...modalJson });

      removeToast();
    });
  }

  function handleDelete(
    modalShow: boolean,
    userId: string,
    oid: string,
    displayName: string
  ) {
    setModalData({
      show: modalShow,
      userId: userId,
      oid: oid,
      displayName: displayName,
    });
  }
  async function onSubmit(data: any) {
    let userSlectedDetails: userSearchType = { ...data };
    let displayName = userSlectedDetails.enteredName
      .toString()
      .split(" | ")[0]
      .trim();

    let userData: createType = {
      displayName: displayName,
      oid: userSlectedDetails.oid,
      applicationRole: +userSlectedDetails.roleId,
    };
    await createUser(userData)
      .then((value: any) => {
        let messageJson: toastType = { ...toastJson };
        if (value?.error) {
          messageJson = {
            showToast: true,
            showMessage: value?.error?.data?.message
              ? value.error.data.message
              : commonErrorMessage,
            toastType: "error",
          };
        } else {
          messageJson = {
            showToast: true,
            showMessage: value.data.message
              ? value.data.message
              : "pages.userManagementPage.userCreateMessage",
            toastType: "success",
          };
          setOptionsListAll([]);
          refetch();
          reset();
        }

        setShowSuccessMessage({ ...messageJson });
        removeToast();
      })
      .catch(() => {
        setShowSuccessMessage({
          showToast: true,
          showMessage: commonErrorMessage,
          toastType: "error",
        });
        removeToast();
      });
  }
  function handleInviteUserErrorMsg() {
    if (loadingUserList || showLoadingMessage.current)
      return <MotifLabel>   
                {t("global.globalNotifications.loadingMessage")}
             </MotifLabel>;
    else if (errorUserList && searchUserErrorDetails?.name !== "AbortError")
      return <MotifErrorMessage>{t(commonErrorMessage)}</MotifErrorMessage>;
    else if (
      serachUsersData &&
      serachUsersData.length === 0 &&
      getValues("enteredName")
    )
      return (
        <MotifErrorMessage>
          {t("global.globalNotifications.noUserMatchMessage")}
          </MotifErrorMessage>
      );
    else if (formState && formState.errors.oid)
      return (
        <MotifErrorMessage>
          {t(`${formState.errors.oid.message}`)}
        </MotifErrorMessage>
      );
    return <></>;
  }

  function handleSelectSearch(enterdvalue: any) {
    setValue("enteredName", enterdvalue.searchTerm);
  }
  function handleChangeSearch(enterdvalue: any) {
    enterdvalue = enterdvalue?.split(" | ")[0]?.trimStart();

    if (abortFunRef && abortFunRef.current) {
      abortFunRef.current.abort();
    }
    setValue("enteredName", enterdvalue);
    setValue("oid", "");
    userSearch(enterdvalue, 400);
  }
  function handleUpdateRole(rowData: any, newValue: string, oldValue: string) {
    let roleData: any = {
      Basic_User: 0,
      Case_Creator: 1,
      System_Admin: 2,
    };
    let changedvalue = newValue.replace(" ", "_");
    let roleUpdateJson: updateRoleType = {
      applicationRole: roleData?.[changedvalue],
      displayName: rowData.displayName,
      oid: rowData.oid,
      userId: rowData.userId,
    };
    let modalJson = {
      show: true,
      data: roleUpdateJson,
      rowNo: roleUpdateModal.rowNo,
      roleDescription: oldValue,
    };
    setRoleUpdateModal({ ...modalJson });
  }
  function updatingUserRole() {
    UpdateRole(roleUpdateModal.data)
      .then((value: any) => {
        if (value?.error) {
          handleRevertRole();

          setShowSuccessMessage({
            showToast: true,
            showMessage: commonErrorMessage,
            toastType: "error",
          });
          removeToast();
        } else {
          refetch();
          setShowSuccessMessage({
            showToast: true,
            showMessage: "pages.userManagementPage.updatedRoleMessage",
            toastType: "success",
          });
          removeToast();
        }
        setRoleUpdateModal({ ...modalRoleJson });
      })
      .catch(() => {
        handleRevertRole();

        setShowSuccessMessage({
          showToast: true,
          showMessage: commonErrorMessage,
          toastType: "error",
        });
        removeToast();
      });
  }
  let defaultval = {
    flex: 1,
    minWidth: 110,
    editable: true,
  };
  function handleMotifSorting(e: any, paginationObj: any) {
    let sortedColumnsLength: any = e?.columns ? e.columns.length : "";
    if (sortedColumnsLength !== "") {
      let coumnName = e?.columns?.[sortedColumnsLength - 1]?.colId;
      let sortName = e?.columns?.[sortedColumnsLength - 1]?.sort;
      let typeName = e?.columns?.[sortedColumnsLength - 1]?.colDef?.type;
      let paginationJson: any = { ...paginationObj };
      let columnTypesCustom: any = {};
      if (typeName) {
        columnTypesCustom[typeName] = { sort: sortName };
      }
      paginationJson.SortOrder = sortName;
      paginationJson.SortColumn =
        coumnName?.charAt(0)?.toUpperCase() + coumnName?.slice(1);
      if (!sortName) {
        delete paginationJson.SortOrder;
        delete paginationJson.SortColumn;
      }
      if (e?.columns[0]?.colDef?.field) {
        if (handlePaginationData) {
          handlePaginationData(paginationJson, columnTypesCustom);
        }
      }
    }
  }
  function handleRevertRole() {
    let roleData = userGroupList;
    roleData.results[roleUpdateModal.rowNo].applicationRole =
      roleUpdateModal.roleDescription;
    userGroupList = roleData;
  }
  const listOfUsersData = userGroupList?.results ? userGroupList?.results : [];
  return (
    <div className="ccwp_usermanagment_wrapper">
      <header className="ccwp_usermanagment_header">
        <h1 className="motif-h3-default-bold">{t("pages.userManagementPage.title")} </h1>
        <p className="motif-body2-default-light">{t("pages.userManagementPage.greetings")}</p>
      </header>
      <main>
        {showSuccessMessage.showToast && (
          <MotifToast
            onClose={() => setShowSuccessMessage({ ...toastJson })}
            variant={showSuccessMessage.toastType}
          >
            {t(showSuccessMessage.showMessage)}
          </MotifToast>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="motif-row mar-t-30">
            <div className="motif-col-lg-5">
              <MotifLabel>{t("pages.userManagementPage.invite")}</MotifLabel>
              <MotifFormField>
                {/*@ts-ignore */}
                <Controller
                  control={control}
                  rules={{
                    required: "pages.userManagementPage.userInviteError",
                  }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <SearchInput
                        aria-label={t(
                          "Search for Team Member within App Directory"
                        )}
                        items={optionsListAll}
                        value={getValues("enteredName") || ""}
                        open={!loadingUserList}
                        hideClearButton={true}
                        placeholder={t("pages.userManagementPage.searchUserPlaceholder")}
                        onChange={(e: any) => {
                          handleChangeSearch(e.target.value);
                        }}
                        filterPropFunction={(e: any) => {
                          return e?.searchTerm;
                        }}
                        onSelect={(value: any) => {
                          handleSelectSearch(value);
                          return onChange(value.oid);
                        }}
                      />
                    );
                  }}
                  name="oid"
                />
              </MotifFormField>

              <MotifErrorMessage className="error-message-con">
                {handleInviteUserErrorMsg()}
              </MotifErrorMessage>
            </div>

            <div className="motif-col-lg-7">
              <div className="motif-row pad-r-10" style={{justifyContent:"flex-end" ,alignItems:"center"}}>
                <div className="motif-col-lg-5 mar-r-15" >                
              <MotifLabel>{t("pages.userManagementPage.role")}</MotifLabel>
              <MotifFormField>
                <Controller
                  control={control}
                  rules={{
                    required: "pages.userManagementPage.rolePlaceholder",
                  }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <MotifSelect
                        aria-label={t("Select Role")}
                        placeholder={t("pages.userManagementPage.rolePlaceholder")}
                        visibleOptions={3}
                        value={value}
                        onChange={onChange}
                        triggerButtonProps={{ type: "button" }}
                      >
                        {rolesList.map((option) => (
                          <MotifOption
                            key={option.roleId}
                            value={option.roleId}
                          >
                            {t(option.roleName)}
                          </MotifOption>
                        ))}
                      </MotifSelect>
                    );
                  }}
                  name="roleId"
                />
              </MotifFormField>
              <MotifErrorMessage className="error-message-con">
                {formState && formState.errors.roleId
                  ? t(`${formState.errors.roleId.message}`)
                  : ""}
              </MotifErrorMessage>
              </div>
                <div className="motif-row mar-t-15" >
                <MotifButton
                  type="submit"
                  size="medium"
                  loading={loadingCreateUser}
                  variant="primary"
                  className="mar-r-15"
                >
                  {t("pages.userManagementPage.addUserButton")}
                </MotifButton>
                {/* Button for Bulk Upload, Commented for later usage */}
                {/* <MotifButton
                  type="button"
                  size="medium"
                  variant="secondary"
                  className="mar-r-15"
                >
                  {t("pages.userManagementPage.bulkUploadButton")}
                </MotifButton> */}
              <MotifErrorMessage className="error-message-con">
                {""}
              </MotifErrorMessage>
              </div>          
            </div>
            </div>
          </div>
        </form>
        <div
          style={
            !userGroupList ||
            userGroupList?.results?.length === 0 ||
            fetchingUsers
              ? { height: "180px" }
              : {}
          }
          className="ccwp_usermanagment_tablecon"
        >
          {loadingUsers ? (
            <MotifProgressLoader size="xs"></MotifProgressLoader>
          ) : (
            <>
              <MotifTable
                ref={gridRef.current}
                defaultColDef={defaultval}
                paginateChildRows={true}
                columnDefs={columnDefs}
                components={cellRenderers}
                columnTypes={{ ...sortedColumnTypes }}
                onSortChanged={(e: any) => {
                  handleMotifSorting(e, paginationObj);
                }}
                paginationAutoPageSize={false}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
                rowData={fetchingUsers ? [] : listOfUsersData}
                overlayNoRowsTemplate={
                  fetchingUsers
                    ? t(tableLoadingData)
                    : errorUsers
                    ? t("global.globalNotifications.wentWrong")
                  : t("global.globalNotifications.noRecords")
                }
                onCellEditingStarted={(e) => {
                  setRoleUpdateModal({
                    show: false,
                    data: "",
                    rowNo: e.rowIndex,
                    roleDescription: "",
                  });
                }}
                onCellEditingStopped={(e) => {
                  if (e.valueChanged) {
                    let rowData = e.data;
                    let changedvalue = e.newValue;
                    let oldValu = e.oldValue;
                    handleUpdateRole(rowData, changedvalue, oldValu);
                  }
                }}
              />
              <CustomPagination
                currentpage={paginationObj.PageNumber}
                pageSize={paginationObj.PageSize}
                disableNext={!userGroupList?.hasMoreRecords}
                onChangePageSize={(page: number) => {
                  setPaginationObj({
                    ...paginationObj,
                    PageSize: page,
                    PageNumber: 1,
                  });
                }}
                onChangeNextPage={() => {
                  setPaginationObj({
                    ...paginationObj,
                    PageNumber: paginationObj.PageNumber + 1,
                  });
                }}
                onChangePreviousPage={() => {
                  setPaginationObj({
                    ...paginationObj,
                    PageNumber: paginationObj.PageNumber - 1,
                  });
                }}
                pageSizeSelector={paginationPageSizeSelector}
              />
            </>
          )}
        </div>
      </main>
      <MotifModal
        onClose={() => setModalData({ ...modalJson })}
        show={modalData.show}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        size="sm"
      >
        <div className="confirmationModalWrapper creditor_disabling_model">
          <br></br>

          <MotifModalBody style={{ paddingTop: "0px" }}>
            <span style={{ fontWeight: "300" }}>
            {t("pages.userManagementPage.userDeleteMessage", {
                userName: modalData.displayName,
              })}
            </span>
          </MotifModalBody>
          <MotifModalFooter>
            <MotifButton
              onClick={() => userDelete()}
              loading={loadingDeleteUser}
            >
              {t("global.globalActions.yes")}
              </MotifButton>

            <MotifButton
              className=""
              onClick={() => setModalData({ ...modalJson })}
              size="small"
              type="button"
            >
              {t("global.globalActions.no")}
              </MotifButton>
          </MotifModalFooter>
        </div>
      </MotifModal>
      <MotifModal
        onClose={() => setRoleUpdateModal({ ...modalRoleJson })}
        show={roleUpdateModal.show}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        size="sm"
      >
        <div className="confirmationModalWrapper creditor_disabling_model">
          <br></br>

          <MotifModalBody style={{ paddingTop: "0px" }}>
            <span style={{ fontWeight: "300" }}>
            {t("global.globalNotifications.roleChangeMessage")}
            </span>
          </MotifModalBody>
          <MotifModalFooter>
            <MotifButton
              onClick={() => updatingUserRole()}
              loading={loadingUpdateRoleUser}
            >
              {t("global.globalActions.yes")}
              </MotifButton>

            <MotifButton
              className=""
              onClick={() => {
                handleRevertRole();
                setRoleUpdateModal({ ...modalRoleJson });
              }}
              size="small"
              type="button"
            >
              {t("global.globalActions.no")}
              </MotifButton>
          </MotifModalFooter>
        </div>
      </MotifModal>
    </div>
  );
};
export default UserManagement;
