import { useCase } from "@/features/case/use-case";
import { useGetUnitByCaseIdDataApiDataQuery } from "@/services/claimSummary/caseSummaryReducer";
import { formatAmountUnitDecimalPlace } from "@/utility/common";
import { useParams } from "react-router-dom";

const useDefaultUnitDetails = () => {
	const { caseId } = useParams<{
		caseId: string;
	}>();
	const { data: defaultUnitData } = useGetUnitByCaseIdDataApiDataQuery(caseId);
	const decimalPlace = defaultUnitData?.decimalPlace;
	return { decimalPlace };
};

const useCaseDetails = () => {
	const caseData = useCase();
	const defaultUnit = caseData?.defaultUnit;
	return { defaultUnit };
};

const CustomHeader = (props: any) => {
	const { defaultUnit } = useCaseDetails();
	const unitName = defaultUnit?.name;
	const { params } = props;
	if (props) return params + ` (${unitName})`;
};

const AmountCellRenderer = ({ amount }: { amount: number }) => {
	const { decimalPlace } = useDefaultUnitDetails();
	if (amount === undefined || amount === null || isNaN(amount)) {
		return <span>-</span>;
	}
	return (
		<span>{formatAmountUnitDecimalPlace(Number(amount), decimalPlace)}</span>
	);
};

export const ClaimItemColumnDefs = [
	{
		cellRenderer: "claimItemReference",
		field: "claimItemReference",
		headerName: "Claim Item ID",
		flex: 1,
		cellStyle: {
			whiteSpace: "normal",
			lineHeight: "1.5",
		},
		autoHeight: true,
	},
	{
		field: "category",
		headerName: "Category",
		flex: 1,
		cellStyle: {
			whiteSpace: "normal",
			lineHeight: "1.5",
		},
		autoHeight: true,
	},
	{
		field: "originalAmt",
		headerName: "Original",
		flex: 1,
		cellRenderer: (param: {
			data: { originalAmt: number };
			node: {
				rowPinned: boolean;
				data: {
					columnField3: React.ReactElement<{ children: React.ReactNode }>;
				};
			};
		}) => {
			if (param.node.rowPinned) {
				return (
					<AmountCellRenderer
						amount={Number(param.node.data.columnField3.props.children)}
					/>
				);
			} else {
				return <AmountCellRenderer amount={Number(param.data.originalAmt)} />;
			}
		},
		cellStyle: {
			whiteSpace: "normal",
			lineHeight: "1.5",
		},
		autoHeight: true,
		headerComponent: CustomHeader,
		headerComponentParams: { params: "Original" },
		headerHeight: 150,
		cellClass: "align-right",
		headerClass: "ccwp_table_header_right_align",
	},
	{
		field: "currentAmt",
		headerName: "Current",
		cellRenderer: (param: {
			data: { currentAmt: number };
			node: {
				rowPinned: boolean;
				data: {
					columnField4: React.ReactElement<{ children: React.ReactNode }>;
				};
			};
		}) => {
			if (param.node.rowPinned) {
				return (
					<AmountCellRenderer
						amount={Number(param.node.data.columnField4.props.children)}
					/>
				);
			} else {
				return <AmountCellRenderer amount={Number(param.data.currentAmt)} />;
			}
		},
		cellStyle: {
			whiteSpace: "normal",
			lineHeight: "1.5",
		},
		autoHeight: true,
		headerComponent: CustomHeader,
		headerComponentParams: { params: "Current" },
		headerHeight: 150,
		cellClass: "align-right",
		headerClass: "ccwp_table_header_right_align",
	},
	{
		field: "latestCreditorPositionAmt",
		headerName: "Latest Creditor Position",
		flex: 1,
		autoHeight: true,
		headerComponent: CustomHeader,
		headerComponentParams: { params: "Latest Creditor Position" },
		headerHeight: 150,
		cellRenderer: (param: {
			data: { latestCreditorPositionAmt: number; resolvedAmt: number };
			node: {
				rowPinned: boolean;
				data: {
					columnField5: React.ReactElement<{ children: React.ReactNode }>;
				};
			};
		}) => {
			if (param.node.rowPinned) {
				return (
					<AmountCellRenderer
						amount={Number(param.node.data.columnField5.props.children)}
					/>
				);
			} else {
				return (
					<div>
						{param.data?.resolvedAmt === null ||
						param.data?.resolvedAmt === 0 ? (
							<AmountCellRenderer
								amount={Number(param.data.latestCreditorPositionAmt)}
							/>
						) : (
							"-"
						)}
					</div>
				);
			}
		},
		cellClass: "align-right",
		headerClass: "align-right",
	},
	{
		field: "latestDebtorPositionAmt",
		headerName: "Latest Debtor Position",
		flex: 1,
		cellRenderer: (param: {
			data: { latestDebtorPositionAmt: number; resolvedAmt: number };
			node: {
				rowPinned: boolean;
				data: {
					columnField6: React.ReactElement<{ children: React.ReactNode }>;
				};
			};
		}) => {
			if (param.node.rowPinned) {
				return (
					<AmountCellRenderer
						amount={Number(param.node.data.columnField6.props.children)}
					/>
				);
			} else {
				return (
					<div>
						{param.data?.resolvedAmt === null ||
						param.data?.resolvedAmt === 0 ? (
							<AmountCellRenderer
								amount={Number(param.data.latestDebtorPositionAmt)}
							/>
						) : (
							"-"
						)}
					</div>
				);
			}
		},
		headerComponent: CustomHeader,
		headerComponentParams: { params: "Latest Debtor Position" },
		headerHeight: 150,
		cellClass: "align-right",
		headerClass: "align-right",
	},
	{
		field: "resolvedAmt",
		headerName: "Resolved",
		flex: 1,
		cellRenderer: (param: {
			data: { resolvedAmt: number };
			node: {
				rowPinned: boolean;
				data: {
					columnField7: React.ReactElement<{ children: React.ReactNode }>;
				};
			};
		}) => {
			if (param.node.rowPinned) {
				return (
					<AmountCellRenderer
						amount={Number(param.node.data.columnField7.props.children)}
					/>
				);
			} else {
				return (
					<div>
						{param.data.resolvedAmt ? (
							<AmountCellRenderer amount={Number(param.data.resolvedAmt)} />
						) : (
							"-"
						)}
					</div>
				);
			}
		},
		autoHeight: true,
		headerComponent: CustomHeader,
		headerComponentParams: { params: "Resolved" },
		headerHeight: 150,
		cellClass: "align-right",
		headerClass: "ccwp_table_header_right_align",
	},
	{
		field: "schemeName",
		headerName: "Scheme",
		flex: 1,
		cellRenderer: function (params: any) {
			return (
				<div>
					{params.data?.schemeName === null ? "-" : params.data?.schemeName}
				</div>
			);
		},
	},
	{
		field: "className",
		headerName: "Class",
		flex: 1,
		autoHeight: true,
		cellRenderer: function (params: any) {
			return (
				<div>
					{params.data?.className === null ? "-" : params.data?.className}
				</div>
			);
		},
	},
];
