const userManagementPage = {
  searchUserPlaceholder: "Search for Team Member within App Directory",
  rolePlaceholder: "Select Role",
  addUserButton: "Add User",
  bulkUploadButton: "Bulk Upload",
  invite: "Invite",
  role: "Role",
  title: "User Management",
  greetings: `Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed
    aliquam ligula placerat massa. At interdum lectus auctor nibh dui massa
    tristique. Eget nisi potenti aenean ultrices nunc sit quam leo. Non
    risus donec et enim semper.`,
  userDeleteMessage: "Do you want to remove the user: {{userName}}",
  userInviteError: "Select a user",
  basicUser: "Basic User",
  caseCreator: "Case Creator",
  updatedRoleMessage: "User Role Updated Successfully",
  systemAdmin: "System Admin",
  userCreateMessage: "User Created Successfully",
  columnDetails: {
    userName: "User Name",
    addedOn: "Added On",
    applicationRole: "Application Role",
  },
};

export default userManagementPage;
