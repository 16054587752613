import { MotifButton, MotifIcon } from "@ey-xd/motif-react";
import { IconoirPlus } from "@ey-xd/motif-icon";
import { useTranslation } from "react-i18next";
export default function AddCreditorButton({ handleNewCreditorClick }: { handleNewCreditorClick: () => void }) {
  const {t}=useTranslation()
  return (
    <div className="new-creditor-button">
      <MotifButton
        onClick={handleNewCreditorClick}
        size="small"
        type="button"
        variant="secondary"
      >
        <MotifIcon iconFunction={IconoirPlus} title="Create New Creditor" />
      {t("pages.claims.claimCreate.creditorTab.formLabelsDetails.newCreditor")}
      </MotifButton>
    </div>
  );
}