import React from "react";
import { MotifFormField, MotifInput, MotifTextArea  } from '@ey-xd/motif-react';
import './InputBox.scss';
import { ltrimString } from "@/utility/common";

type InputBoxType = {
  id?: string | null;
  required?: boolean;
  value: string | number | boolean;
  isTextArea?: boolean;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  placeholder?: string;
  type?: "number" | "email" | "text" | "tel" | "date" | "password" | undefined;
  readonly?: boolean;
  disabled?: boolean;
  maxLength?: string;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
};

export default function InputBox({
  id,
  type,
  required = false,
  value,
  isTextArea,
  onChange,
  placeholder,
  readonly,
  disabled,
  maxLength,
  onFocus,
  onBlur,
  ...args
}: InputBoxType) {
  value = ltrimString(String(value)) || "";
  return (
    <>
      <MotifFormField role="group" aria-label={id}>
        {/*@ts-ignore */}
        {isTextArea ? (
          <MotifTextArea
            {...args}
            // @ts-ignore
            id={id}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={readonly}
            maxLength={maxLength || "1000"}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        ) : (
          <MotifInput
            type={type}
            hideClearButton={true}
            {...args}
            // @ts-ignore
            id={id}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled || readonly}
            maxLength={maxLength || "100"}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )}
      </MotifFormField>
    </>
  );
}