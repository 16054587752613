import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	MotifModal,
	MotifModalHeader,
	MotifModalBody,
	MotifModalFooter,
	MotifButton,
} from "@ey-xd/motif-react";
import { useLazyDeleteClassQuery } from "@/services/class/classReducer";

interface DeleteClassProps {
	show: boolean;
	setShow: (value: boolean) => void;
	id: string | undefined;
	setDeleteErrorMessage: (message: string) => void;
	refetch: () => void;
}

const DeleteClass = ({
	show,
	setShow,
	id,
	setDeleteErrorMessage,
	refetch,
}: DeleteClassProps) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [deleteClass] = useLazyDeleteClassQuery();

	const handleCancel = () => {
		setShow(false);
		setDeleteErrorMessage("");
	};

	const handleDelete = () => {
		setIsLoading(true);
		setDeleteErrorMessage("");
		deleteClass(id).then((response: any) => {
			if (response.data) {
				refetch();
			} else {
				const errorMessage = response.error?.data?.message;
				setDeleteErrorMessage(errorMessage);
			}
			setIsLoading(false);
			setShow(false);
		});
	};
	return (
		<MotifModal
			onClose={handleCancel}
			show={show}
			focusTrapOptions={{
				tabbableOptions: {
					displayCheck: "none",
				},
			}}
		>
			<MotifModalHeader>
				{t("pages.configuration.scheme.class.deleteClass")}
			</MotifModalHeader>

			<MotifModalBody>
				{t("pages.configuration.scheme.class.deleteClassMessage")}
			</MotifModalBody>

			<MotifModalFooter>
				<MotifButton onClick={handleCancel} type={"button"} variant="secondary">
					{t("pages.configuration.scheme.class.cancel")}
				</MotifButton>
				<MotifButton
					onClick={handleDelete}
					type={isLoading ? "loader" : "submit"}
					variant="primary"
					disabled={isLoading}
				>
					{t("pages.configuration.scheme.class.confirm")}
				</MotifButton>
			</MotifModalFooter>
		</MotifModal>
	);
};
export default DeleteClass;
