import Table from "@/components/Table/table";
import { useColumnTaskDefs } from "@/features/claimsummary/summary/constant/claimFormHeaderConst";
import { useGetTaskByIdApiDataQuery, useGetTaskViewDataQuery, useDeleteClaimTaskMutation } from "@/services/claimSummary/caseSummaryReducer";
import { IconoirEditPencil, IconoirTrash } from "@ey-xd/motif-icon";
import {
  MotifButton,
  MotifIcon,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { CreateTaskAllInputFields } from "./createTaskAllInputs";
import { IconoirWarningTriangle } from "@ey-xd/motif-icon";
import { EditTaskAllInputFields } from "./EditTaskAllInputs";
import { useCase } from "@/features/case/use-case";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useTranslation } from "react-i18next";

type taskConst = {
  data: {
    name: string;
    taskDueDate: string;
    status: number;
    type: number;
    createdOn: string;
    createdBy: string;
    action: string;
    id: string;
  };
};
export const useCustomCellRenderers = (
  setClaimTaskId: any,
  setDeleteModal: any,
  setEditTask: any,
  setShowModal: any
) => {
  const customActionHeader = {
    statusRenderer: function (param: taskConst) {
      return (
        <>
          {param.data.status === 0 ? (
            <>Open</>
          ) : param.data.status === 1 ? (
            <>Closed</>
          ) : (
            <>Completed</>
          )}
        </>
      );
    },
    actionRenderer: function (param: taskConst) {
      return (
        <>
          <MotifButton
            size="small"
            type="submit"
            variant="secondary"
            style={{ padding: "0px 8px" }}
            onClick={() => {
              setClaimTaskId(param.data.id);
              setEditTask(true);
              setShowModal(true);
            }}
          >
            <MotifIcon
              iconFunction={IconoirEditPencil}
              stroke="blue"
              strokeWidth="2"
              size="20"
            />
          </MotifButton>
          &nbsp;&nbsp;
          <MotifButton
            size="small"
            type="submit"
            variant="secondary"
            style={{ padding: "0px 8px" }}
            onClick={() => {
              setClaimTaskId(param.data.id);
              setDeleteModal(true);
            }}
          >
            <MotifIcon
              iconFunction={IconoirTrash}
              stroke="blue"
              strokeWidth="2"
              size="20"
            />
          </MotifButton>
        </>
      );
    },
    typeRenderer: function (param: taskConst) {
      return (
        <>
          {param.data.type === 0 ? (
            <>Other</>
          ) : param.data.type === 1 ? (
            <>Cash Management</>
          ) : (
            <>Legal</>
          )}
        </>
      );
    },
  };
  return customActionHeader;
};

export const Tasks = () => {
  const { t } = useTranslation();
  const parameter = useParams();
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editTask, setEditTask] = useState(false);
  const [claimTaskId, setClaimTaskId] = useState();
  const caseData = useCase();
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);
  function handleReject(flag:any){
setShowModal(flag)  }

const columnTaskDefs=useColumnTaskDefs()

  const { data: getTaskById } = useGetTaskByIdApiDataQuery(claimTaskId, {
    skip: !claimTaskId,
  });
  const caseRenderers = useCustomCellRenderers(
    setClaimTaskId,
    setDeleteModal,
    setEditTask,
    setShowModal
  );
  // @ts-ignore
  const getDataByUrl = { caseId: caseData.caseId, claimId: parameter?.claimId };
  const { data, isLoading, refetch } = useGetTaskViewDataQuery(getDataByUrl);
  const [deleteClaimTask] = useDeleteClaimTaskMutation();
  async function deleteClaimItem() {
    let jsonData = {
      claimTaskId: claimTaskId,
    };
    const response = await deleteClaimTask(jsonData);
    if (response.hasOwnProperty("data")) {
      setDeleteModal(false);
      refetch();
    } else {
      setDeleteModal(false);
      refetch();
    }
  }
  function TitleComplement() {
    return (
      <MotifButton
        onClick={() => {
          setShowModal(true);
          setEditTask(false);
        }}
        size="small"
        type="submit"
        variant="secondary"
      >
        {t("global.globalActions.addNew")}
      </MotifButton>
    );
  }
  return (
    <div className="mar-b-20">
      <MotifModal
        onClose={() => setDeleteModal(false)}
        show={deleteModal}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <div className="confirmationModalWrapper creditor_disabling_model">
          <MotifModalHeader
            headerIcon={
              <>
                <span
                  style={{
                    backgroundColor: "rgb(252, 245, 243)",
                    borderRadius: "50px",
                    padding: "5px 8px 2px 8px",
                  }}
                >
                  <MotifIcon
                    iconFunction={IconoirWarningTriangle}
                    strokeWidth="1"
                    size="15"
                    stroke="red"
                  />{" "}
                </span>
                <span
                  style={{
                    fontSize: 16,
                    display: "block",
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
        {t("global.globalNotifications.warning")}
        </span>
              </>
            }
          ></MotifModalHeader>

          <MotifModalBody style={{ paddingTop: "0px" }}>
            <span style={{ fontWeight: "300" }}>
            {t("pages.claims.taskTab.deleteMessage")}

            </span>
          </MotifModalBody>
          <MotifModalFooter>
            <MotifButton
              size="small"
              type="submit"
              variant="secondary"
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              onClick={deleteClaimItem}
            >
                      {t("global.globalActions.confirm")}

            </MotifButton>

            <MotifButton
              className=""
              onClick={() => setDeleteModal(false)}
              size="small"
              type="button"
            >
                      {t("global.globalActions.cancel")}
                      </MotifButton>
          </MotifModalFooter>
        </div>
      </MotifModal>
      <MotifModal
        onClose={() => setIsDiscardVisible(true)}
        show={showModal}
        size={"xl"}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <MotifModalHeader>
          {!editTask ?t("pages.claims.taskTab.addNewTask")
 :t("pages.claims.taskTab.editTask")
}
        </MotifModalHeader>
        <div className="confirmationModalWrapper creditor_disabling_model">
          <MotifModalBody style={{ paddingTop: "20px" }}>
            {!editTask ? (
              <CreateTaskAllInputFields setShowModal={handleReject} />
            ) : (
              <EditTaskAllInputFields setShowModal={handleReject} getTaskById={getTaskById}/>
            )}
          </MotifModalBody>
        </div>
      </MotifModal>
      {isLoading ? (
        <MotifProgressLoader show variant="default" className="mar-b-20" />
      ) : (
        <Table
          columnDefs={columnTaskDefs}
          cellRenderers={data}
          TitleComplement={TitleComplement}
          title={"pages.claims.taskTab.title"}
          description={"pages.claims.taskTab.description"}
          caseRenderers={caseRenderers}
          pagination={false}
          overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
        />
      )}
        <ConfirmationModal
        isVisible={isDiscardVisible}
        setIsVisible={setIsDiscardVisible}
        cancelBtnApproveHandler={() => {setShowModal(false);setIsDiscardVisible(false)}}
        cancelBtnRejectHandler={() => setIsDiscardVisible(false)}
      />
    </div>
  );
};
