import { dateFunc } from "@/components/Functions/dateFunc";
import { useCase } from "@/features/case/use-case";
import { useGetClaimSummaryViewApiDataQuery } from "@/services/claimSummary/caseSummaryReducer";
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifChip,
  MotifErrorMessage,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export const CreditorsContacts = () => {
  const {t}=useTranslation()
  const parameter = useParams();
  const { data, error, isLoading } = useGetClaimSummaryViewApiDataQuery(
    parameter?.claimId
  );
  const { caseId } = useCase();
  const navigate = useNavigate();
  // type creditorContactDetailListConst = {
  //   contactId: string;
  //   firstName: string;
  //   lastName: string;
  //   address1: string;
  //   address2: string;
  //   city: string;
  //   provinceState: string;
  //   postalCode: string;
  //   phone: string;
  //   email: string;
  //   isPrimaryContact: boolean;
  //   updatedBy: string;
  //   updatedOn: string;
  // };
  function handlecreditornav(creditorId: string, pathName: string) {
    if (creditorId) {
      navigate(pathName, { state: { from: creditorId } });
    }
  }
  return (
    <>
      <div className="motif-container case-summary-form pad-v-20">
        <div className="motif-body1-default-regular page-title">
        {t("pages.claims.commonFieldLabels.creditorContactsTitle")}        </div>
        <div className="motif-body2-default-regular page-description">
        {t("pages.claims.commonFieldLabels.creditorContactsDescription")}         </div>
      </div>
      <div className="motif-container main-container ccwp_claim_summary_form ccwp_claim_summary_white_accordian pad-t-20 mar-b-20 pad-b-20">
        {error ? (
          <MotifErrorMessage
            style={{
              textAlign: "center",
              display: "block",
              alignItem: "center",
            }}
          >
{t("global.globalNotifications.wentWrong")}           </MotifErrorMessage>
        ) : isLoading ? (
          <MotifProgressLoader show variant="default" />
        ) : data ? (
          <MotifAccordion
            onClose={function Ua() {}}
            onOpen={function Ua() {}}
            style={{
              flex: "1",
            }}
            alignIconRight={true}
            useChevronIcon={true}
            className="mar-b-20"
          >
            <MotifAccordionTrigger>
              <div className="ccwp_claim_summary_white">
                <ul>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.name")}</label>
                    <p>
                      {data?.creditorContact.firstName}{" "}
                      {data?.creditorContact.lastName}
                    </p>
                  </li>
                  <li>
                    {data?.creditorContact.isPrimaryContact === true && (
                      <MotifChip dotSide="left">{t("pages.claims.commonFieldLabels.primary")}</MotifChip>
                    )}
                  </li>
                </ul>
              </div>
            </MotifAccordionTrigger>
            <MotifAccordionContent>
              <ul className="ccwp_claim_summary_details">
                <li>
                  <label>{t("pages.claims.commonFieldLabels.firstName")}</label>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handlecreditornav(
                        data?.claimSummary?.creditorId,
                        `/case/${caseId}/creditor/${data?.claimSummary?.creditorId}`
                      )
                    }
                  >
                    {!data?.creditorContact?.firstName
                      ? "-"
                      : data?.creditorContact?.firstName}
                  </p>
                </li>
                <li>
                  <label>{t("pages.claims.commonFieldLabels.lastName")}</label>
                  <p>
                    {!data?.creditorContact?.lastName
                      ? "-"
                      : data?.creditorContact?.lastName}
                  </p>
                </li>
                <li>
                  <label>{t("pages.claims.commonFieldLabels.address1")}</label>
                  <p>
                    {!data?.creditorContact?.address1
                      ? "-"
                      : data?.creditorContact?.address1}
                  </p>
                </li>
                <li>
                  <label>{t("pages.claims.commonFieldLabels.address2")}</label>
                  <p>
                    {!data?.creditorContact?.address2
                      ? "-"
                      : data?.creditorContact?.address2}
                  </p>
                </li>
                <li>
                  <label>{t("pages.claims.commonFieldLabels.city")}</label>
                  <p>
                    {!data?.creditorContact?.city
                      ? "-"
                      : data?.creditorContact?.city}
                  </p>
                </li>
                <li>
                  <label>{t("pages.claims.commonFieldLabels.provincestate")}</label>
                  <p>
                    {!data?.creditorContact?.provinceState
                      ? "-"
                      : data?.creditorContact?.provinceState}
                  </p>
                </li>
                <li>
                  <label>{t("pages.claims.commonFieldLabels.postalCode")}</label>
                  <p>
                    {!data?.creditorContact?.postalCode
                      ? "-"
                      : data?.creditorContact?.postalCode}
                  </p>
                </li>
                <li>
                  <label>{t("pages.claims.commonFieldLabels.phone")}</label>
                  <p>
                    {!data?.creditorContact?.phone
                      ? "-"
                      : data?.creditorContact?.phone}
                  </p>
                </li>
                <li>
                  <label>{t("pages.claims.commonFieldLabels.email")}</label>
                  <p>
                    {!data?.creditorContact?.email
                      ? "-"
                      : data?.creditorContact?.email}
                  </p>
                </li>
                <li>
                  <label>{t("pages.claims.commonFieldLabels.lastUpdatedOn")}</label>
                  <p>
                    {!data?.creditorContact?.updatedOn
                      ? "-"
                      : dateFunc(data?.creditorContact?.updatedOn)
                          .formattedDateOnly}
                  </p>
                </li>
                <li>
                  <label>{t("pages.claims.commonFieldLabels.lastUpdatedBy")}</label>
                  <p>
                    {!data?.creditorContact?.updatedBy
                      ? "-"
                      : data?.creditorContact?.updatedBy}
                  </p>
                </li>
              </ul>
            </MotifAccordionContent>
          </MotifAccordion>
        ) : null}
      </div>
    </>
  );
};
