const debtors = {
  debtorsTableTile: "All Debtors",
  debtorsDescription: "Debtors list related to the case",
  debtorBannerDetails: {
    debtor: "Debtor",
    totalClaims: "Total Claims",
  },
  debtorTabPanelDetails: {
    summary: "Summary",
    claims: "Claims",
  },
  debtorDisablePopup: {
    disableDebtorMessage: "Are you sure to disable this debtor?",
    disableSuccessMessage: "Debtor disabled successfully.",
    cannotDeleteDebtor: "Cannot delete an active debtor.",
  },
  debtorsTabelColumnsDetails: {
    name: "Name",
    totalClaims: "Total Claims {{unitName}}",
    originalClaims: "Original Claims {{unitName}}",
    latestCreditorPosition: "Latest Creditor Position {{unitName}}",
    latestDebtorPositionAmount: "Latest Debtor Position Amount {{unitName}}",
    resolvedAmount: "Resolved Amount {{unitName}}",
  },
  debtorForm: {
    formTitle: "Debtor Setup",
    formDescription: "Descriptive text that describes your page or content.",
    formSubTitle: "Debtor Details",
    formSubDescription: "Descriptive text that describes your page or content.",
    formFieldLabels: {
      debtorName: "Debtor Name",
      debtorDescription: "Debtor Description",
    },
  },
  debtorClaimsSection: {
    tableTitle: "Claims",
    tableDescription: "List of all claim under debtor",
    columnsDetails: {
      claimId: "Claim ID",
      creditor: "Creditor",
      assignee: "Assignee",
      currentAmount: "Current Amount {{unitName}}",
      stage: "Stage",
      status: "Status",
      lastUpdated: "Last Updated",
      claimDetails: "Claim Details",
    },
    bulkUpload: "Bulk Upload",
    addNewClaim: "Add New Claim",
  },
};

export default debtors;
