import s from "react";
const l = s.createContext({
  disabled: !1,
  formElementType: null,
  hasContent: !1,
  hasPlaceholder: !1,
  id: "",
  idErrorMessage: "",
  invalid: !1,
  isFocused: !1,
  isActive: !1,
  required: !1,
  hasLabel: !1,
  iconPrefix: !1,
  iconSuffix: !1,
  value: "",
  setDisabled: () => null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFormElementType: (e) => null,
  setHasPlaceholder: () => null,
  setHasLabel: () => null,
  setHasContent: () => null,
  setId: (e) => null,
  setIdErrorMessage: () => null,
  setInvalid: (e) => null,
  setRequired: () => null,
  setValue: () => null,
  toggleFocus: () => null,
  setActive: () => null,
  setIconPrefix: () => null,
  setIconSuffix: () => null
}), t = l.Provider, a = l.Consumer;
export {
  a as FormFieldConsumer,
  l as FormFieldContext,
  t as FormFieldProvider
};
