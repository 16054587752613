import {
  MotifButton,
  MotifErrorMessage,
  MotifProgressLoader,
  MotifToast,
} from "@ey-xd/motif-react";
import React, { useEffect, useState } from "react";
import { useGetCaseSummaryQuery } from "@/services/caseSummary/caseSummaryReducer";
import SummaryForm from "../SummaryForm/SummaryForm";
import "./SummaryCaseDetails.scss";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCase } from "@/features/case/use-case";
import { useGetCaseUsersListQuery } from "../SummaryForm/summaryHelper";
import { havingEditPermission } from "@/utility/common";
import { useSelector } from "react-redux";

export default function SummaryCaseDetails() {
  const [isEdit, setIsEdit] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "success",
  });

  const { t } = useTranslation();

  const permissions = useSelector((state: any) => state.permissions);
  const { CaseType } = permissions;

  const { caseId } = useParams<{ caseId: string }>();
  const caseData = useCase();
  const { data, error, isLoading, refetch } = useGetCaseSummaryQuery(
    caseId || caseData?.caseId
  );
  let { data: groupList, isFetching: fetchingListUsers } =
    useGetCaseUsersListQuery(caseData.caseId || "");
  useEffect(() => {
    refetch();
    //eslint-disable-next-line
  }, [notification]);

  const caseDetails = [
    {
      title: "pages.caseSummary.fieldLabelDetails.caseName",
      value: data?.caseName,
    },

    {
      title: "pages.caseSummary.fieldLabelDetails.caseDescription",
      value: data?.caseDescription,
    },
    {
      title: "pages.caseSummary.fieldLabelDetails.caseShortName",
      value: data?.caseShortName,
    },
    {
      title: "pages.caseSummary.fieldLabelDetails.status",
      value:
        data?.caseStatus === false
          ? t("pages.caseSummary.fieldLabelDetails.statusClose")
          : t("pages.caseSummary.fieldLabelDetails.statusOpen"),
    },
    {
      title: "pages.caseSummary.fieldLabelDetails.caseNameFrench",
      value: data?.caseNameFrench,
    },
    {
      title: "pages.caseSummary.fieldLabelDetails.caseDescriptionFrench",
      value: data?.caseDescriptionFrench,
    },
  ];

  const closeMessageHandler = () => {
    setNotification({ ...notification, show: false });
  };

  const renderMessage = () => {
    if (notification.show) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={notification.type}
        >
          {" "}
          {notification.type === "error"
            ? t("global.globalNotifications.unExpectedError")
            : t("global.globalNotifications.updatedSuccessfully")}
        </MotifToast>
      );
    }
  };

  if (isLoading) {
    return <MotifProgressLoader show variant="default" />;
  }
  if (error) {
    return <MotifErrorMessage>Data could not be loaded</MotifErrorMessage>;
  }

  if (isEdit) {
    //@ts-ignore
    return (
      <SummaryForm
        summaryData={data}
        setIsEdit={setIsEdit}
        notification={notification}
        setNotification={setNotification}
      />
    );
  }

  interface CaseDetail {
    title: string;
    value: string;
  }

  const numberOfColumns = 4;

  const chunkArray = (array: CaseDetail[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const caseDetailsFields = chunkArray(caseDetails, numberOfColumns);

  return (
    <>
      {renderMessage()}
      <div className="motif-container case-summary-form pad-v-20">
        <div className="motif-row justify-content-between align-items-center">
          <div className="motif-col-4">
            <div className="motif-body1-default-regular page-title">
              {t("pages.caseSummary.title")}
            </div>
            <div className="motif-body2-default-regular page-description">
              {t("pages.caseSummary.description")}
            </div>
          </div>
          <div className="motif-col-4">
           { havingEditPermission(CaseType) && <MotifButton
              onClick={() => setIsEdit(true)}
              size="small"
              type="submit"
              variant="secondary"
            >
              {t("global.globalActions.edit")}
            </MotifButton>
            }
          </div>
        </div>
      </div>
      <div className="motif-container main-container pad-v-40">
        {caseDetailsFields.map((caseDetailsField, rowIndex) => (
          <div
            key={rowIndex}
            className={`motif-row ${rowIndex > 0 ? "pad-t-30" : ""}`}
          >
            {caseDetailsField.map((detail, colIndex) => (
              <div key={colIndex} className="motif-col-lg-3">
                <div>
                  <div className="title">{t(detail.title)}</div>
                  <div className="motif-body2-default-bold">{detail.value}</div>
                </div>
              </div>
            ))}
          </div>
        ))}
        <div className="motif-row form-title">
          <div className="motif-col-xs-4">
            <div className="form-field">
              <div className="motif-body2-default-bold title">
                {t("pages.caseSummary.fieldLabelDetails.caseTeam")}
              </div>
            </div>
          </div>
        </div>

        {fetchingListUsers ? (
          <MotifProgressLoader show variant="default" />
        ) : (
          <div className="motif-row rowdata">
            <div className="celldata">
              <div className="motif-col-xs-1 title">
                {t("pages.caseSummary.fieldLabelDetails.teamMember")}
              </div>
              <div className="motif-col-xs-1 title">
                {t("pages.caseSummary.fieldLabelDetails.role")}
              </div>
            </div>
            {groupList &&
              groupList?.results.map((memberDetail: any, index: number) => (
                <div key={index} className="celldata">
                  <div className="motif-col-xs-1 motif-body2-default-bold">
                    {memberDetail.userName}
                  </div>
                  <div className="motif-col-xs-1 motif-body2-default-bold">
                    {memberDetail.role}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
}
