import Button from '@/components/Form/Button/Button';
import Table from '@/components/Table/table';
import { useSchemeColumnDefinition } from './SchemeTable/SchemeColumnDefinition';
import { useGetSchemesListQuery } from '@/services/scheme/schemeReducer';
import { useParams, useNavigate } from 'react-router-dom';
import { useCustomCellRenderers } from './SchemeTable/SchemeColumnActions';
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from '@/components/Table/CustomPaginationConstants';
import { useState } from 'react';
import CustomPagination from '@/components/Pagination/CustomPagination';

const Schemes = () => {

    const headerData = {
        title: "Schemes",
        description: "Description text that describes your page or content.",
    };

    const { caseId } = useParams();
    const navigate = useNavigate();
    const paginationPageSize: number =customPaginationPageSize;
    const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
    let tableLoadingData=customTableLoadingData
    const [paginationObj, setPaginationObj] = useState({
        CaseId: caseId,
      PageNumber: 1,
      PageSize: paginationPageSize,
      SearchText: "",

    });
    
 
        let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({    })
  
    function handlePaginationData(data:any,columnTypesData:any){
      setPaginationObj({...data})
      setSortedColumnTypes({...columnTypesData})
    }
    const SchemeColumnDefinition=useSchemeColumnDefinition()

    const {
        data: schemeDataByCase,
        isLoading: isSchemenDataByCaseLoading,
        isFetching,
        error
    } = useGetSchemesListQuery(paginationObj);

    const rowData = schemeDataByCase?.results?schemeDataByCase.results:[];
    const handleEditClick = (id:string) => {
        navigate(`/case/${caseId}/scheme-manage/${id}`);
    };

    const caseRenderers = useCustomCellRenderers(handleEditClick);
    
    if (isSchemenDataByCaseLoading) {
        return <div>Loading...</div>
    }

    const handleCreateNew = () => {
        navigate(`/case/${caseId}/configuration/scheme-setup`);
    }
    return (
        <div>
            <Table
                columnDefs={SchemeColumnDefinition}
                columnTypes={{...sortedColumnTypes}}
                handlePaginationData={handlePaginationData}
                    paginationObj={paginationObj}
                cellRenderers={isFetching?[]:rowData}
                TitleComplement={() => (
                    <Button label='Create New Scheme' variant='secondary' onClickHandler={handleCreateNew}/>
                )}
                title={headerData.title}
                description={headerData.description}
                caseRenderers={caseRenderers}
                pagination={false}
                overlayNoRowsTemplate={isFetching?tableLoadingData:error?"Something Went Wrong":"No records found"}
                />
            <CustomPagination
          currentpage={paginationObj.PageNumber}
          pageSize={paginationObj.PageSize}
          disableNext={!schemeDataByCase?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
        </div>
    )
}

export default Schemes;