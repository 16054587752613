import ControlSelect from "@/components/Form/SelectBox/ControlSelect";
import { useTranslation } from "react-i18next";
import { renderSubAccountList } from "./calculationSummaryHelper";
import { useEffect } from "react";
import { useGetCalculationStepSubAccountDataQuery } from "../calculationStepReducer";
 
//@ts-ignore
function SubAccount({ control, setValue, getValues, accountId, setResetSubAccount, resetSubAccount, selectedId }) {
    const { t } = useTranslation();
   
    const {data, isLoading} = useGetCalculationStepSubAccountDataQuery({ accountId: accountId });
    useEffect(() => {
        if (accountId === "") {
            setValue("subAccount", "");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId]);

    useEffect(() => {
        if (data && data.length > 0) { 
            setValue("subAccount", selectedId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, setValue]);

    useEffect(() => {
        if (resetSubAccount) {
            setValue("subAccount", "");
            setResetSubAccount(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[resetSubAccount]);


    if (isLoading) {
        //return <div>Loading...</div>;
    }
    const subAccountList = renderSubAccountList(data);
    return (
        <ControlSelect
            name={t("subAccount")}
            control={control}
            showLabel={true}
            label={t(`pages.distributions.distributionStepsTab.stepFormLabels.subAccount`)}
            options={subAccountList}
            required={true}
            setValue={setValue}
            getValues={getValues}
        />
    );
}

export default SubAccount;