import { dateFunc } from "@/components/Functions/dateFunc";
import {
  customComparator,
  formatAmountUnitDecimalPlace,
} from "@/utility/common";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const useDefaultUnitDetails = () => {
  const { defaultUnit } = useSelector((state: any) => state.case);
  const decimalPlace = defaultUnit?.decimalPlace;
  return { decimalPlace };
};

// const useCaseDetails = () => {
//   const caseData = useCase();
//   const defaultUnit = caseData?.defaultUnit;
//   return { defaultUnit };
// };

// const CustomHeader = (props: any) => {
//   const { defaultUnit } = useCaseDetails();
//   const unitName = defaultUnit?.name;
//   const { params } = props;
//   if (props) return params + ` (${unitName})`;
// };

const AmountCellRenderer = ({ amount }: { amount: number }) => {
  const { decimalPlace } = useDefaultUnitDetails();
  if (amount === undefined || amount === null || isNaN(amount)) {
    return <span>-</span>;
  }
  return (
    <span>{formatAmountUnitDecimalPlace(Number(amount), decimalPlace)}</span>
  );
};

type documentConst = {
  data: {
    documentType: string;
    comment: string;
    createdBy: string;
    createdOn: string;
  };
};

export const useColumnDefs=()=>{
const {t}=useTranslation()
return [
  {
    cellRenderer: "typeCellRenderer",
    field: "displayName",
    headerName: t("pages.claims.claimsDoumentsTab.tableColumnsDetails.column1"),
    flex: 1,
    unSortIcon: true,
  },
  {
    cellRenderer: "typeCellRenderer",
    field: "documentType",
    headerName: t("pages.claims.claimsDoumentsTab.tableColumnsDetails.column2"),
    flex: 1,
    unSortIcon: true,
  },
  {
    cellRenderer: "commentCellRenderer",
    field: "comment",
    headerName: t("pages.claims.claimsDoumentsTab.tableColumnsDetails.column3"),
    flex: 1,
    unSortIcon: true,
  },
  {
    field: "createdBy",
    headerName: t("pages.claims.claimsDoumentsTab.tableColumnsDetails.column4"),
    flex: 1,
    sortable: false,
  },
  {
    field: "createdOn",
    headerName: t("pages.claims.claimsDoumentsTab.tableColumnsDetails.column5"),
    flex: 1,
    sortable: false,
    cellRenderer: function (params: documentConst) {
      return dateFunc(params.data.createdOn).formattedDateOnly;
    },
  },
  {
    field: "action",
    headerName: "",
    flex: 2,
    sortable: false,
    cellRenderer: "actionRenderer",
    cellStyle: { justifyContent: "right" },
  },
]};

type taskConst = {
  data: {
    name: string;
    dueDate: string;
    status: string;
    type: string;
    createdOn: string;
    createdBy: string;
  };
};

// function convertDateTimeinDate(getDate: any) {
//   const date = new Date(getDate);

//   const day = date.getDate().toString().padStart(2, '0');
//   const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-indexed
//   const year = date.getUTCFullYear();

//   const formattedDate = `${day}-${month}-${year}`;

//   return formattedDate; // Outputs: 2024-10-08
// }

export const useColumnTaskDefs=()=> 
{
  const {t}=useTranslation()
  return [
  {
    cellRenderer: "taskCellRenderer",
    field: "name",
    headerName: t("pages.claims.taskTab.tableColumnsDetails.column1"),
    comparator: customComparator,
    flex: 1,
    unSortIcon: true,
  },
  {
    field: "dueDate",
    headerName: t("pages.claims.taskTab.tableColumnsDetails.column2"),
    flex: 1,
    unSortIcon: true,
    cellRenderer: function (params: taskConst) {
      return dateFunc(params.data.dueDate).formattedDateOnly;
    },
  },
  {
    field: "status",
    headerName: t("pages.claims.taskTab.tableColumnsDetails.column3"),
    flex: 1,
    sortable: false,
    cellRenderer: "statusRenderer",
  },
  {
    field: "type",
    headerName: t("pages.claims.taskTab.tableColumnsDetails.column4"),
    flex: 1,
    sortable: false,
    cellRenderer: "typeRenderer",
  },
  {
    field: "taskCreatedOn",
    headerName: t("pages.claims.taskTab.tableColumnsDetails.column5"),
    flex: 1,
    sortable: false,
    cellRenderer: function (params: taskConst) {
      return dateFunc(params.data.createdOn).formattedDateOnly;
    },
  },
  {
    field: "createdBy",
    headerName: t("pages.claims.taskTab.tableColumnsDetails.column6"),
    flex: 1,
    sortable: false,
  },
  {
    field: "action",
    headerName: "",
    flex: 2,
    sortable: false,
    cellRenderer: "actionRenderer",
    cellStyle: { justifyContent: "right" },
  },
]};

export const useColumnCalimItemsDefs =(caseDataDetails:any)=>{
  const {t}=useTranslation()
const useCaseDetails = () => {
  const caseData = caseDataDetails;
  const defaultUnit = caseData?.defaultUnit;
  return { defaultUnit };
};

const CustomHeader1 = () => {
  const { defaultUnit } = useCaseDetails();
  const unitName = defaultUnit?.name;
  return `(${unitName})`;
};
  return [
  {
    cellRenderer: "claimItemReference",
    field: "claimItemReference",
    headerName:  t("pages.claims.claimIteamsTab.tablecolumns.column1"),
    flex: 1,
    cellStyle: {
      whiteSpace: "normal",
      lineHeight: "1.5",
    },
    autoHeight: true,
  },
  {
    cellRenderer: "categoryName",
    field: "categoryName",
    headerName: t("pages.claims.claimIteamsTab.tablecolumns.column2"),
    flex: 1,
    cellStyle: {
      whiteSpace: "normal",
      lineHeight: "1.5",
    },
    autoHeight: true,
  },
  {
    field: "originalAmt",
    headerName: t("pages.claims.claimIteamsTab.tablecolumns.column3",{unitName:CustomHeader1()}),
    flex: 1,
    cellRenderer: (param: {
      data: { originalAmt: number };
      node: {
        rowPinned: boolean;
        data: {
          columnField3: React.ReactElement<{ children: React.ReactNode }>;
        };
      };
    }) => {
      if (param.node.rowPinned) {
        return param.node.data.columnField3.props.children !== null ? (
          <AmountCellRenderer
            amount={Number(param.node.data.columnField3.props.children)}
          />
        ) : (
          "-"
        );
      } else {
        return param?.data?.originalAmt !== null ? (
          <AmountCellRenderer amount={Number(param.data.originalAmt)} />
        ) : (
          "-"
        );
      }
    },
    cellStyle: {
      whiteSpace: "normal",
      lineHeight: "1.5",
    },
    autoHeight: true,
    headerComponentParams: { params: "Original" },
    headerHeight: 150,
    cellClass: "align-right",
    headerClass: "ccwp_table_header_right_align",
  },
  {
    field: "currentAmt",
    headerName:  t("pages.claims.claimIteamsTab.tablecolumns.column4",{unitName:CustomHeader1()}),
    flex: 1,
    cellRenderer: (param: {
      data: { currentAmt: number };
      node: {
        rowPinned: boolean;
        data: {
          columnField4: React.ReactElement<{ children: React.ReactNode }>;
        };
      };
    }) => {
      if (param.node.rowPinned) {
        return (
          param.node.data.columnField4.props.children!==null?<AmountCellRenderer
            amount={Number(param.node.data.columnField4.props.children)}
          />:"-"
        );
      } else {
        return <AmountCellRenderer amount={Number(param.data.currentAmt)} />;
      }
    },
    cellStyle: {
      whiteSpace: "normal",
      lineHeight: "1.5",
    },
    autoHeight: true,
    headerComponentParams: { params: "Current" },
    headerHeight: 150,
    cellClass: "align-right",
    headerClass: "ccwp_table_header_right_align",
  },
  {
    field: "latestCreditorPositionAmt",
    headerName:  t("pages.claims.claimIteamsTab.tablecolumns.column5",{unitName:CustomHeader1()}),
    flex: 1,
    autoHeight: true,
    headerComponentParams: { params: "Latest Creditor Position" },
    headerHeight: 150,
    cellRenderer: (param: {
      data: { latestCreditorPositionAmt: number; resolvedAmt: number };
      node: {
        rowPinned: boolean;
        data: {
          columnField5: React.ReactElement<{ children: React.ReactNode }>;
        };
      };
    }) => {
      if (param.node.rowPinned) {
        return param.node.data.columnField5.props.children !== null ? (
          <AmountCellRenderer
            amount={Number(param.node.data.columnField5.props.children)}
          />
        ) : (
          "-"
        );
      } else {
        return (
          <div>
            {(param.data?.resolvedAmt === null ||
              param.data?.resolvedAmt === 0) &&
            param.data.latestCreditorPositionAmt !== null ? (
              <AmountCellRenderer
                amount={Number(param.data.latestCreditorPositionAmt)}
              />
            ) : (
              "-"
            )}
          </div>
        );
      }
    },
    cellClass: "align-right",
    headerClass: "align-right",
  },
  {
    field: "latestDebtorPositionAmt",
    headerName: t("pages.claims.claimIteamsTab.tablecolumns.column6",{unitName:CustomHeader1()}),
    flex: 1,
    cellRenderer: (param: {
      data: { latestDebtorPositionAmt: number; resolvedAmt: number };
      node: {
        rowPinned: boolean;
        data: {
          columnField6: React.ReactElement<{ children: React.ReactNode }>;
        };
      };
    }) => {
      if (param.node.rowPinned) {
        return param.node.data.columnField6.props.children !== null ? (
          <AmountCellRenderer
            amount={Number(param.node.data.columnField6.props.children)}
          />
        ) : (
          "-"
        );
      } else {
        return (
          <div>
            {(param.data?.resolvedAmt === null ||
              param.data?.resolvedAmt === 0) &&
            param.data.latestDebtorPositionAmt !== null ? (
              <AmountCellRenderer
                amount={Number(param.data.latestDebtorPositionAmt)}
              />
            ) : (
              "-"
            )}
          </div>
        );
      }
    },
    headerComponentParams: { params: "Latest Debtor Position" },
    headerHeight: 150,
    cellClass: "align-right",
    headerClass: "align-right",
  },
  {
    field: "resolvedAmt",
    headerName:  t("pages.claims.claimIteamsTab.tablecolumns.column7",{unitName:CustomHeader1()}),
    flex: 1,
    cellRenderer: (param: {
      data: { resolvedAmt: number };
      node: {
        rowPinned: boolean;
        data: {
          columnField7: React.ReactElement<{ children: React.ReactNode }>;
        };
      };
    }) => {
      if (param.node.rowPinned) {
        return param.node.data.columnField7.props.children !== null ? (
          <AmountCellRenderer
            amount={Number(param.node.data.columnField7.props.children)}
          />
        ) : (
          "-"
        );
      } else {
        return (
          <div>
            {param.data.resolvedAmt ? (
              <AmountCellRenderer amount={Number(param.data.resolvedAmt)} />
            ) : (
              "-"
            )}
          </div>
        );
      }
    },
    autoHeight: true,
    headerComponentParams: { params: "Resolved" },
    headerHeight: 150,
    cellClass: "align-right",
    headerClass: "ccwp_table_header_right_align",
  },
  {
    field: "action",
    headerName: "",
    flex: 1,
    cellRenderer: "actionRenderer",
    cellStyle: { justifyContent: "right" },
  },
]};

type commentConst = {
  data: {
    commentMsg: string;
    createdOn: string;
    createdBy: string;
  };
};

export const useColumnCommentHistoryDefs = (timeZone: string) => {
  const {t}=useTranslation();
  return [
  {
    cellRenderer: "commentRenderer",
    field: "commentMsg",
    headerName: t("commonComponent.comment.tableCoumnsDetails.comment"),
    flex: 2,
    unSortIcon: true,
    cellStyle: {
      whiteSpace: "normal",
      wordBreak: "break-word",
      lineHeight: 1.5,
    },
  },
  {
    field: "commentCreatedOn",
    headerName: t("commonComponent.comment.tableCoumnsDetails.postedOn"),
    flex: 1,
    cellRendererParams: {
      timeZoneName: timeZone,
    },
    cellRenderer: function (params: commentConst) {
      return dateFunc(params.data.createdOn).formattedDateTime + " " + timeZone;
    },
  },
  {
    field: "createdBy",
    headerName:t("commonComponent.comment.tableCoumnsDetails.postedBy"),
    flex: 1,
  },
  {
    field: "action",
    headerName: "",
    flex: 1,
    sortable: false,
    cellRenderer: "actionRenderer",
    cellStyle: { justifyContent: "right" },
  },
]};
