import React, { useState } from "react";
import Table from "@/components/Table/table";
import { useColumnDistribution } from "./DIstributionHeader";
import { useGetDistributionByCaseIDQuery } from "@/services/distribution/distributionReducers";
import { useNavigate, useParams } from "react-router-dom";
import { MotifButton, MotifProgressLoader } from "@ey-xd/motif-react";
import { useCustomCellRenderers } from "./constants/customCellRender";
import DeleteDistribution from "./DeleteDistribution";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import { useCase } from "@/features/case/use-case";
import { useTranslation } from "react-i18next";

const TitleComplement = () => {
  const { caseId } = useParams<{
    caseId: string;
  }>();
  const {t}=useTranslation()

  const navigate = useNavigate();
  return (
    <MotifButton
      size="small"
      type="submit"
      variant="secondary"
      onClick={() => {
        navigate(`/case/${caseId}/distribution/create`);
      }}
    >

{t("pages.distributions.distribution.addNewDistribution")}    </MotifButton>
  );
};

interface DistributionData {
  id: number;
  name: string;
  value: number;
}

export default function Distribution() {
  const { caseId } = useParams<{
    caseId: string;
  }>();
  const {t}=useTranslation()
  const caseData = useCase();
  const paginationPageSize: number =customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;

  let tableLoadingData=customTableLoadingData
  const [paginationObj, setPaginationObj] = useState({
    CaseId:caseId,
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",
    SortOrder: "desc",
    SortColumn: "CreatedOn"

  });

      let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({
        "createdOnType":{sort:"desc"}
      })
    
      function handlePaginationData(data:any,columnTypesData:any){
        setPaginationObj({...data})
        setSortedColumnTypes({...columnTypesData})
      }
    
  const columnDistribution=useColumnDistribution(caseData?.abbreviation);
  const {
    data,
    isLoading,
    isFetching,error,
    refetch: distributionRefetch,
  } = useGetDistributionByCaseIDQuery(paginationObj);
  const [showDeleteClassPopup, setShowDeleteClassPopup] = useState(false);
  const [distributionData, setDistributionData] =
    useState<DistributionData | null>(null);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const caseRenderers = useCustomCellRenderers({
    setDistributionData: setDistributionData,
    setShowDeleteClassPopup: setShowDeleteClassPopup,
  });
 
  return (
    <>
      <div className="mar-b-20">
        {deleteErrorMessage && (
          <NotificationGrid
            show={true}
            message={deleteErrorMessage}
            type="error"
          />
        )}
      </div>
      <div>
        {isLoading ? (
          <MotifProgressLoader show variant="default" className="mar-b-20" />
        ) : (
          <div className="mar-b-20">
            <Table
              columnDefs={columnDistribution}
              columnTypes={{...sortedColumnTypes}}
              handlePaginationData={handlePaginationData}
                  paginationObj={paginationObj}
              cellRenderers={isFetching?[]:data?data.results:[]}
              TitleComplement={TitleComplement}
              title={"pages.distributions.distribution.title"}
              description={
                "pages.distributions.distribution.tableDescription" }
              pagination={false}
              caseRenderers={caseRenderers}
              overlayNoRowsTemplate={isFetching?t(tableLoadingData):error?t("global.globalNotifications.wentWrong")
                : t("global.globalNotifications.noRecords")}

            />
             <CustomPagination
          currentpage={paginationObj.PageNumber}
          pageSize={paginationObj.PageSize}
          disableNext={!data?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
          </div>
        )}
      </div>
      {showDeleteClassPopup && (
        <DeleteDistribution
          show={true}
          setShow={setShowDeleteClassPopup}
          id={distributionData?.id?.toString()}
          setDeleteErrorMessage={setDeleteErrorMessage}
          refetch={distributionRefetch}
        />
      )}
    </>
  );
}
