import { useState, useEffect } from "react";
import {
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifButton,
    MotifErrorMessage,
} from "@ey-xd/motif-react";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useForm } from "react-hook-form";
import { CaseEditFormType } from "@/type";
import ControlInputBox from "@/features/claimsummary/summary/component/controlInputBox";
import { useTranslation } from "react-i18next";

export default function EditCommentModal({
    show,
    setShow,
    comment,
    onSave,
    isEdit,
}: {
    show: boolean;
    setShow: (isVisible: boolean) => void;
    comment: any;
    onSave: (updatedComment: any) => void;
    isEdit: boolean;
}) {
    const [showDiscardMessage, setShowDiscardMessage] = useState(false);
    const { t } = useTranslation();

    const { control, handleSubmit, setValue,formState:{errors} } = useForm<CaseEditFormType>({
        defaultValues: {
            comment: comment?.commentMsg || "",
        },
    });

    useEffect(() => {
        if (isEdit && comment) {
            setValue("comment", comment?.commentMsg || "");
        }
    }, [comment, isEdit, setValue]);

    const handleSave = (data: any) => {
        const updatedData = {
            commentId: comment.commentId,
            commentMsg: data.comment || "",
        };
        onSave(updatedData);
        setShow(false);
    };
    return (
        <>
            <MotifModal
                show={show}
                onClose={() => setShowDiscardMessage(true)}
                focusTrapOptions={{
                    tabbableOptions: {
                        displayCheck: "none",
                    },
                }}
            >
                <MotifModalHeader>{t("commonComponent.comment.commentEdit.title")}</MotifModalHeader>
                <MotifModalBody>
                    <div className="form-field mar-b-20">
                        <ControlInputBox
                            name={"comment"}
                            control={control}
                            isTextArea={true}
                            label={"commonComponent.comment.commentEdit.label"}
                            showLabel={true}
                            required={true}
                            defaultValue={comment?.commentMsg || ""}
                        />
                    </div>
                    {errors && errors?.comment && errors?.comment?.type==="required"?<MotifErrorMessage>{t("commonComponent.comment.commentEdit.requiredMessage")}</MotifErrorMessage>:<></>}
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton variant="secondary" onClick={() => setShowDiscardMessage(true)}>
                        {t("global.globalActions.cancel")}
                    </MotifButton>
                    <MotifButton
                        variant="primary"
                        onClick={handleSubmit(handleSave)}
                        disabled={!comment}
                    >
                        {t("global.globalActions.save")}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>

            <ConfirmationModal
                isVisible={showDiscardMessage}
                setIsVisible={setShowDiscardMessage}
                cancelBtnApproveHandler={() => setShow(false)}
                cancelBtnRejectHandler={() => setShowDiscardMessage(false)}
            />
        </>
    );
}
