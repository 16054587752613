import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ACCOUNT_DELETE, SUB_ACCOUNT_DELETE } from "./constant";
import {
	MotifModal,
	MotifModalHeader,
	MotifModalBody,
	MotifModalFooter,
	MotifButton,
} from "@/libs/@ey-xd/motif-react";
import {
	useLazyDeleteAccountQuery,
	useLazyDeleteSubAccountQuery,
} from "@/services/configuration/configuration";

interface ApiResponse {
	data?: any;
	error?: any;
}

const DeleteAccountSubAccount = ({
	show,
	setShow,
	id,
	isAccount,
	setDeleteErrorMessage,
	refetch,
}: {
	show: boolean;
	setShow: (show: boolean) => void;
	id: any;
	isAccount: boolean;
	setDeleteErrorMessage: (message: string) => void;
	refetch: () => void;
}) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [deleteAccountQuery] = useLazyDeleteAccountQuery();
	const [deleteSubAccountQuery] = useLazyDeleteSubAccountQuery();

	const handleDelete = () => {
		const deleteQuery = isAccount ? deleteAccountQuery : deleteSubAccountQuery;
		setDeleteErrorMessage("");
		setIsLoading(true);
		deleteQuery(id).then((response: ApiResponse) => {
			if (response.data) {
				const successMessage = isAccount
					? ACCOUNT_DELETE
					: SUB_ACCOUNT_DELETE;
				setDeleteErrorMessage(successMessage);
				refetch();
			} else {
				const errorMessage = response.error.data.message;
				setDeleteErrorMessage(errorMessage);
			}
			setIsLoading(false);
			setShow(false);
		});
	};

	const handleCancel = () => {
		setShow(false);
		setDeleteErrorMessage("");
	};

	return (
		<>
			<MotifModal
				onClose={handleCancel}
				show={show}
				focusTrapOptions={{
					tabbableOptions: {
						displayCheck: "none",
					},
				}}
			>
				<MotifModalHeader>
					{isAccount
						? t("pages.configuration.accounts.deleteAccount")
						: t("pages.configuration.accounts.deleteSubAccount")}
				</MotifModalHeader>

				<MotifModalBody>
					{isAccount
						? t("pages.configuration.accounts.deleteAccountMessage")
						: t("pages.configuration.accounts.deleteSubAccountMessage")}
				</MotifModalBody>

				<MotifModalFooter>
					<MotifButton onClick={handleCancel} type="button" variant="secondary">
						{t("pages.configuration.stages.cancel")}
					</MotifButton>
					<MotifButton
						onClick={handleDelete}
						type={isLoading ? "loader" : "submit"}
						variant="primary"
					>
						{t("pages.configuration.stages.confirm")}
					</MotifButton>
				</MotifModalFooter>
			</MotifModal>
		</>
	);
};
export default DeleteAccountSubAccount;
