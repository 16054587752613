import Table from "@/components/Table/table";
import { useBallotResultDef } from "./BallotResulDef";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { useCase } from "@/features/case/use-case";

export default function BallotResult({ ballotResult,isFetching }: any) {
  const { t } = useTranslation();
  const TitleComplement = () => {
    return <></>;
  };
  const caseData = useCase("",true);

  const ballotResultDef=useBallotResultDef(caseData)
  if (isFetching) {
    return <MotifProgressLoader show variant="default" className="mar-b-20" />;
  }
  return (
    <div className="ccwp_claim_claimlisttable">
      <Table
        columnDefs={ballotResultDef}
        cellRenderers={ballotResult?.voteDetailsList}
        TitleComplement={TitleComplement}
        title={"pages.voting.ballotResult.tableTitle"}
        description={"pages.voting.ballotResult.tableDescription"}
        overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
      />
    </div>
  );
}
