import { useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Banner from "@/components/Banner/Banner";
import Table from "@/components/Table/table";
import { usePaymentSummaryColumnDefs } from "./summaryTable/PaymentSummaryColumnDefs";
import {
	customPaginationPageSize,
	customPaginationPageSizeSelector,
	customTableLoadingData,
} from "@/components/Table/CustomPaginationConstants";
import { useGetPaymentSettlementListQuery } from "@/services/paymentStatus/paymentStatusReducer";
import CustomPagination from "@/components/Pagination/CustomPagination";
const PaymentSummary = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { caseId, paymentId } = useParams();
	const location = useLocation();
	let [sortedColumnTypes, setSortedColumnTypes] = useState<any>({});
	const paymentReference = location.state?.paymentReference;
	const amount = location.state?.amount;
	const accountName = location.state?.accountName;
	const subAccountName = location.state?.subAccountName;
	const breadcrumbItems = [
		{
			label: "Back",
			elementKey: "Back",
			text: "Back",
			url: `/case/${caseId}/?activeTab=9`,
			icon: <MotifIcon src={IconoirArrowLeft} />,
		},
	];
	let tableLoadingData = customTableLoadingData;
	const paginationPageSize: number = customPaginationPageSize;
	const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
	const [paginationObj, setPaginationObj] = useState<any>({
		CaseId: caseId,
		PageNumber: 1,
		PageSize: paginationPageSize,
		SearchText: "",
	});
	const {
		data: paymentSettlementData,
		isLoading: isSettlementLoading,
		error: SettlementListError,
	} = useGetPaymentSettlementListQuery({
		PaymentId: paymentId,
		...paginationObj,
	});
	const bannerElements: { label: string; value: string }[] = [
		{
			label: t("pages.paymentStatus.paymentSummary.account"),
			value: accountName,
		},
		{
			label: t("pages.paymentStatus.paymentSummary.subAccount"),
			value: subAccountName,
		},
		{
			label: t("pages.paymentStatus.paymentSummary.amount"),
			value: amount.toString(),
		},
	];
	const tableDataFetching = isSettlementLoading;
	const tableDataError = SettlementListError;
	const tableDataHasRecords = paymentSettlementData?.hasMoreRecords;
	const tableData = paymentSettlementData?.results;
	function handlePaginationData(data: any, columnTypesData: any) {
		setPaginationObj({ ...data });
		setSortedColumnTypes({ ...columnTypesData });
	}
	const handleCellClick = (param: any) => {
		navigate(
			`/case/${caseId}/claim/details/` +
				param?.data?.claimItemId +
				`?claimId=${param?.data?.claimId}&creditorName=${param?.data?.creditorName}&updatedOn=${param?.data?.updatedOn}&claimReference=${param?.data?.claimReference}&creditorId=${param?.data?.creditorId}`,
			{
				state: {
					paymentId,
				},
			}
		);
	};
	return (
		<>
			<div>
				<Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
			</div>
			<Banner
				bannerName={t("pages.paymentStatus.paymentSummary.paymentReference")}
				bannerNameValue={paymentReference.toString()}
				elements={bannerElements}
			/>
			<div className="mar-t-40">
				<Table
					columnDefs={usePaymentSummaryColumnDefs(handleCellClick)}
					paginationObj={paginationObj}
					columnTypes={{ ...sortedColumnTypes }}
					handlePaginationData={handlePaginationData}
					cellRenderers={tableDataFetching ? [] : tableData ? tableData : []}
					TitleComplement={() => <></>}
					title={t("pages.paymentStatus.paymentSummary.tableTitle")}
					description={t("pages.paymentStatus.paymentSummary.tableDescription")}
					overlayNoRowsTemplate={
						tableDataFetching
							? tableLoadingData
							: tableDataError
							? t("pages.paymentStatus.somethingWentWrong")
							: t("pages.paymentStatus.noRecord")
					}
					pagination={false}
				/>
				<CustomPagination
					currentpage={paginationObj.PageNumber}
					pageSize={paginationObj.PageSize}
					disableNext={!tableDataHasRecords}
					onChangePageSize={(page: number) => {
						setPaginationObj({
							...paginationObj,
							PageSize: page,
							PageNumber: 1,
						});
					}}
					onChangeNextPage={() => {
						setPaginationObj({
							...paginationObj,
							PageNumber: paginationObj.PageNumber + 1,
						});
					}}
					onChangePreviousPage={() => {
						setPaginationObj({
							...paginationObj,
							PageNumber: paginationObj.PageNumber - 1,
						});
					}}
					pageSizeSelector={paginationPageSizeSelector}
				/>
			</div>
		</>
	);
};
export default PaymentSummary;
