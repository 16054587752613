import { MotifDatePicker } from "@ey-xd/motif-react";
import { useEffect } from "react";
import "@/pages/UserManagement/UserManagement.scss";
interface FormattedDatePickerProps {
    onBlur?: (value: any) => void;
    onChange: (value: any) => void;
    dayPlaceholder?: string;
    monthPlaceholder?: string;
    yearPlaceholder?: string;
    disabled?: boolean;
    format?: string;
    minDate?: Date;
    range?: boolean;
    todayMark?: boolean;
    value?: any;
    className?: string;
    
}

const FormattedDatePicker: React.FC<FormattedDatePickerProps> = ({
    dayPlaceholder='DD',
    monthPlaceholder='MM',
    yearPlaceholder='YYYY',
    format='dd-MMM-yyyy',
    onChange,
    ...args
}) => {
    useEffect(() => {
        const monthSelectors = document.querySelectorAll('.react-daterange-picker__inputGroup__month');
        
        monthSelectors.forEach((monthSelector) => {
            monthSelector.setAttribute('disabled', 'true');
            monthSelector.setAttribute('style', 'opacity: 1;');
        });
        const monthSelectors1 = document.querySelectorAll('.react-daterange-picker__inputGroup');
        monthSelectors1.forEach((monthSelector) => {
            monthSelector.setAttribute('style', 'display: flex;align-items:center');
        });
    }, []);

    return (
        <MotifDatePicker
        className="formatted-date-picker"
            dayPlaceholder={dayPlaceholder}
            monthPlaceholder={monthPlaceholder}
            yearPlaceholder={yearPlaceholder}
            format={format}
            onChange={onChange}
            {...args}
        />
    );
}

export default FormattedDatePicker;