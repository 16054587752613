const navBar = {
  collapse: "Collapse",
  moduleDetails: {
    homeLabel: "Home",
    userManagementLabel: "User Management",
    caseLabel: "Case Detail",
    caseSubModuleDetails: {
      summaryLabel: "Summary",

      debtorLabel: "Debtor",

      creditorLabel: "Creditor",

      claimLabel: "Claim",

      configurationLabel: "Configuration",

      distributionLabel: "Distribution",

      votingLabel: "Voting",

      importLabel: "Import & Export",

      portalLabel: "Portal Configuration",

      paymentLabel: "Payment Status",
    },
  },
};

export default navBar;
