import React from "react";
import { Control, Controller } from "react-hook-form";
import '@/components/Form/InputBox/InputBox.scss';
import { useController } from "react-hook-form";
import {useTranslation} from 'react-i18next';
import { AddDebtorFormType } from "./type";
import InputBox from "@/components/Form/InputBox/InputBox";
import { DEBTOR_DESCRIPTION, LEGAL_DEBTOR_NAME } from "./constants";

type ControlInputBoxType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<AddDebtorFormType>;
    name: typeof LEGAL_DEBTOR_NAME | typeof DEBTOR_DESCRIPTION;
    showLabel?: boolean;
    label?: string;
    placeholder?: string;
}

export default function ControlInputAddDebtor ({name, control, required=false, isTextArea=false, showLabel, label, placeholder=''}: ControlInputBoxType) {
    const {
        field,
      } = useController({
        name,
        control,
        rules: { required: required },
      });
    const {t} = useTranslation();

    return (
        <> 
            {
                showLabel && <div className="formFieldLabel">
                    { /* @ts-ignore*/}
                    <label  htmlFor="input" aria-label={label}>{t(label)}{ required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
           <Controller
                name={field.name}
                control={control}
                rules={{ required }}
                render={({ field: { onChange, value, ...args }}) => {
                          return (
                          <>
                            <InputBox   id={LEGAL_DEBTOR_NAME} required={true} value={value} onChange={onChange} isTextArea={isTextArea} placeholder={placeholder} {...args}/>
                          </>
                          );
                    }}
            />
        </>
        
    );
}
