import { MotifIcon } from "@ey-xd/motif-react";
import {
  //   IconoirLock,
  //   IconoirDrag,
  IconoirTrash,
  IconoirEditPencil,
} from "@ey-xd/motif-icon";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmation } from "@/features/confirmation/confirmation-slice";

export default function SectionItem(params: any) {
  const { data, currentSectionId, handleDelete, setTempSection } = params;
  const { blockNavigation } = useSelector((state: any) => state.confirmation);
  const dispatch = useDispatch();

  function handleEdit() {
    setTempSection(data);
    dispatch(
      setConfirmation({
        isVisible: blockNavigation,
        blockNavigation: blockNavigation,
        action: "SectionItem.edit",
      })
    );
  }

  return (
    <div className={currentSectionId === data.id ? "active" : ""}>
      {/* <MotifIcon src={IconoirLock} /> */}
      <span> {data.name}</span>
      <div className="section-actions">
        <button
          name="edit"
          onClick={handleEdit}
          disabled={data.id === currentSectionId}
        >
          <MotifIcon src={IconoirEditPencil} />
        </button>
        <button
          name="delete"
          onClick={() => {
            handleDelete(data);
          }}
        >
          <MotifIcon src={IconoirTrash} />
        </button>
      </div>
    </div>
  );
}
