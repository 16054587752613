import React, { Dispatch, SetStateAction, useState } from "react";
import {
    MotifModal,
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader,
    MotifErrorMessage,
} from "@ey-xd/motif-react";
import { useForm } from "react-hook-form";
import Button from "@/components/Form/Button/Button";
import ControlInputBox from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import {
    SECONDARY,
    REQUIRED_MESSAGE,
} from "@/Constant";
import "./FormModal.scss";
import ControlInputBoxNumberWithRange from "@/components/Form/InputBox/ControlInputBoxNumberWithRange";
import { useCreateRateTierTemplateMutation } from "@/services/rateTierTemplate/RateTierTemplateReducer";
import { useTranslation } from "react-i18next";

type Props = {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    caseId: string;
    stepId: string;
    onTemplateSave: (templateName: string, year: string, jurisdiction: string) => void;
};

const FormModalCreateTemplate = ({ isVisible, setIsVisible, caseId, stepId, onTemplateSave  }: Props) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            name: "",
            jurisdiction: "",
            year: "",
        },
    });
const {t}=useTranslation()
    const [createRateTierTemplate] = useCreateRateTierTemplateMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = async (data: any) => {
        if (!data.name) {
            setErrorMessage(REQUIRED_MESSAGE + " Name");
            return;
        }

        setIsLoading(true);

        const payload = {
            calculationStepId: stepId,
            caseId: caseId,
            name: data.name,
            year: Number(data.year) || null,
            jurisdiction: data.jurisdiction || null,
        };

        try {
            const response = await createRateTierTemplate(payload).unwrap();
            if (response) {
                reset();
                setIsVisible(false);
                setErrorMessage("");
                onTemplateSave(data.name, data.year, data.jurisdiction || "");
            }
        } catch (err) {
            setErrorMessage("global.globalNotifications.unExpectedError");
        } finally {
            setIsLoading(false);
        }
    };

    const cancelHandler = () => {
        reset();
        setErrorMessage("");
        setIsVisible(false);
    };

    return (
        <MotifModal
            onClose={cancelHandler}
            show={isVisible}
            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: "none",
                },
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formModalWrapper">
                    <MotifModalHeader>
                        <span>{t("pages.distributions.distributionStepsTab.rateTierTab.createNewTemplate.title")}</span>
                    </MotifModalHeader>
                    <MotifModalBody>
                        <div className="formModal">
                            <div className="motif-row mar-t-20">
                                <div className="motif-col-md-5">
                                    <ControlInputBox
                                        name="name"
                                        control={control}
                                        required
                                        isTextArea={false}
                                        label="pages.distributions.distributionStepsTab.rateTierTab.createNewTemplate.name"
                                        showLabel
                                    />
                                </div>
                            </div>
                            <div className="motif-row mar-t-20">
                                <div className="motif-col-md-5">
                                    <ControlInputBoxNumberWithRange
                                        name="year"
                                        control={control}
                                        label="pages.distributions.distributionStepsTab.rateTierTab.createNewTemplate.year"
                                        showLabel
                                        min={1900}
                                        max={2100}
                                        digitAfterDecimal={0}
                                        required
                                    />
                                    {errors.year && (
                                        <MotifErrorMessage>
                                            {errors.year.message || t("pages.distributions.distributionStepsTab.rateTierTab.createNewTemplate.validationMessages.yearRequired")}
                                        </MotifErrorMessage>
                                    )}
                                </div>
                            </div>
                            <div className="motif-row mar-t-20">
                                <div className="motif-col-md-5">
                                    <ControlInputBox
                                        name="jurisdiction"
                                        control={control}
                                        required={false}
                                        isTextArea={false}
                                        label="pages.distributions.distributionStepsTab.rateTierTab.createNewTemplate.jurisdiction"
                                        showLabel
                                    />
                                </div>
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="error-container">
                                <MotifErrorMessage>{errorMessage}</MotifErrorMessage>
                            </div>
                        )}
                    </MotifModalBody>
                    <MotifModalFooter>
                        <div className="button-container-add">
                            <Button
                                label={t("global.globalActions.save")}
                                disabled={isLoading}
                            />
                            <Button
                                label={t("global.globalActions.cancel")}
                                type="button"
                                variant={SECONDARY}
                                className="cancelBtn"
                                onClickHandler={cancelHandler}
                            />
                        </div>
                    </MotifModalFooter>
                </div>
            </form>
        </MotifModal>
    );
};

export default FormModalCreateTemplate;
