import { dateFunc } from "@/components/Functions/dateFunc";
import { ClaimData } from "@/features/creditor/claimsTab/Constant/claimTabType";
import { Link } from "react-router-dom";

export const caseRenderers = {
  claimCellRenderer: function (param: {
    colDef: any;
    data: {
      claimId: any;
      claimReferenceId: any;
    };
  }) {
    const {
      colDef: {
        headercomponentParams: { caseId, creditorId },
      },
    } = param;
    let prefix = creditorId
      ? `/case/${caseId}/creditor/${creditorId}`
      : `/case/${caseId}`;
    return (
      <Link
        to={`${prefix}/claim/${param.data.claimId}`}
        state={{ from: param.data.claimId }}
      >
        {param.data.claimReferenceId}
      </Link>
    );
  },
  lastUpdatedRenderer: function (param: ClaimData) {
    return <>{dateFunc(param.data.updatedOn).formattedDateOnly}</>;
  },
  // excludeRenderer: function () {
  //   return (
  //     <>
  //       <MotifButton
  //         onClick={function Ga() {}}
  //         size="small"
  //         type="submit"
  //         variant="secondary"
  //       >
  //         Exclude
  //       </MotifButton>
  //     </>
  //   );
  // },
};
