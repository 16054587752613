import {
  MotifButton,
  MotifDropdown,
  MotifFormField,
  MotifIcon,
} from "@ey-xd/motif-react";
import { IconoirPlus } from "@ey-xd/motif-icon";
import { useTranslation } from "react-i18next";
import { memo, SetStateAction, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SearchInput from "@/components/SearchInput/SearchInput";
import { useSelector } from "react-redux";
import { havingAddPermission } from "@/utility/common";

interface TitleComplementProps {
  onSearch: (searchValue: string) => void;
  handleQuickSearchClear: () => void;
  searchQuery: string;
}

const TitleComplement = memo(
  ({ onSearch, handleQuickSearchClear, searchQuery }: TitleComplementProps) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState("");
    const { caseId } = useParams<{ caseId: string }>();
    const navigate = useNavigate();
    const permissions = useSelector((state: any) => state.permissions);
    const { Debtor } = permissions;
    // const debtorTitleMenu = ["Add New Debtor", "Bulk Upload", "Export"];

    const handleSearchChange = useCallback(
      (event: { target: { value: SetStateAction<string> } }) => {
        setSearchValue(event.target.value);
      },
      []
    );

    const handleOnSearch = useCallback(() => {
      onSearch(searchValue);
    }, [onSearch, searchValue]);

    return (
      <>
        <MotifFormField>
          <SearchInput
            aria-label="Search"
            onChange={handleSearchChange}
            onClear={handleQuickSearchClear}
            placeholder={t("global.globalPlaceholder.quickSearchPlaceHolder")}
            onFocusShow={false}
            value={searchQuery}
            onEnter={handleOnSearch}
          />
          <MotifDropdown
            ariaLabelledby="dropdown-trigger-1"
            id="dropdown-1"
            trigger={
              <MotifButton
                aria-label="Search Dropdown"
                id="dropdown-trigger-1"
                type="button"
                variant="text"
              
                
              >
                { havingAddPermission(Debtor) &&
                  <div className="pad-t-10" onClick={() =>
                  navigate(`/case/${caseId}/debtor/create`)
                  } >
                    <MotifIcon iconFunction={IconoirPlus} />
                  </div>
                }
              </MotifButton>
            }
          >
            <ul role="menu">
              {/* {debtorTitleMenu.map((menuName: string) => {
                if (menuName === "Add New Debtor")
                  return (
                    <li role="menuitem">
                      <MotifDropdownItem
                        indexItem={1}
                        onClick={() =>
                          navigate(`/case/${caseId}/debtor/create`)
                        }
                      >
                        {t(menuName)}
                      </MotifDropdownItem>
                    </li>
                  );
                else
                  return (
                    <li role="menuitem">
                      <MotifDropdownItem
                        indexItem={1}
                        onClick={function Ga() {}}
                      >
                        {t(menuName)}
                      </MotifDropdownItem>
                    </li>
                  );
              })} */}
            </ul>
          </MotifDropdown>
        </MotifFormField>
      </>
    );
  }
);

export default TitleComplement;
