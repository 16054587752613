import {formatDateToDDMMYYYY} from "@/utility/common";

export function dateFunc(date: any){

    if(!date){
        return {
            formattedDateTime: "-",
            formattedDateOnly: "-"
        };
    }

    var dateObject = new Date(date);

    // Extract the parts of the date
    var day = ("0" + dateObject.getDate()).slice(-2); // Add leading zero if necessary
    var month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Add leading zero if necessary, month is 0-indexed
    var year = dateObject.getFullYear().toString().slice(-2); // Get last two digits of year

    // Extract the parts of the time
    var hours = dateObject.getHours();
    var minutes = ("0" + dateObject.getMinutes()).slice(-2); // Add leading zero if necessary
    //var seconds = ("0" + dateObject.getSeconds()).slice(-2); // Add leading zero if necessary
    var ampm = hours >= 12 ? "pm" : "am";

    // Convert 24-hour time to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    // hours = ("0" + hours).slice(-2); // Add leading zero if necessary

    // Combine the parts into the desired format
    var formattedDate = day + "-" + month + "-" + year;
    var formattedTime = hours + ":" + minutes + ampm;
    return {
        formattedDateTime: formatDateToDDMMYYYY(formattedDate) + ", " + formattedTime,
        formattedDateOnly: formatDateToDDMMYYYY(formattedDate)
    };
}

export const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${day}-${month}-${year}`;
  }