import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputBox from "@/components/Form/InputBox/InputBox";
import { CreateSubFormType } from "./type";
import { SUBACCOUNT_NAME, DESCRIPTION_NAME_FR, SUBACCOUNT_NAME_FR, DESCRIPTION_NAME } from "./constant";

type ControlInputBoxType = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<CreateSubFormType>;
  name:
    | typeof SUBACCOUNT_NAME
    | typeof DESCRIPTION_NAME
    | typeof SUBACCOUNT_NAME_FR
    | typeof DESCRIPTION_NAME_FR;
  showLabel: boolean;
  label: string;
  readonly?: boolean;
  value?:any;
};

export default function ControlInputBox({
  name,
  control,
  required = false,
  isTextArea = false,
  showLabel,
  label,
  readonly,
  value
}: ControlInputBoxType) {
  const { field } = useController({
    name,
    control,
    rules: { required: required },
  });
  const { t } = useTranslation();

  return (
    <>
      {showLabel && (
        <div className="formFieldLabel">
          <label htmlFor="input" aria-label={label}>
            {t(label)}
            {required ? <span className="requiredSymbol">*</span> : ""}
          </label>
        </div>
      )}
      <Controller
        name={field.name}
        control={control}
        rules={{ required }}
        render={({ field: { onChange, value, ...args } }) => {
          return (
            <>
              <InputBox
                id={SUBACCOUNT_NAME}
                required={true}
                value={value || ""}
                onChange={onChange}
                isTextArea={isTextArea}
                readonly={readonly}
                {...args}
              />
            </>
          );
        }}
      />
    </>
  );
}
