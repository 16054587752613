import {
  LISTCLASSBYSCHEMEVERSIONID,
  LISTOFMEETING,
  READBALLOT,
} from "@/services/constant/apiPath";
import { ccwpApi } from "@/services";

// Define a service using a base URL and expected endpoints
export const VotingBallotByCaseIdApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getVotingBallotByCaseID: builder.query<any, any>({
      query: (arg) => ({url:"Ballot/ListBallotByCaseId",method:"GET",params:{...arg}}),
    }),
  }),
});

export const {
  useGetVotingBallotByCaseIDQuery,
  useLazyGetVotingBallotByCaseIDQuery,
} = VotingBallotByCaseIdApi;

export const ListEffectiveByCaseIdApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getEffectiveSchemes: builder.query<any, any>({
      query: (opts) =>
        `Scheme/ListEffectiveSchemesByCase?caseId=${opts.caseId}`,
    }),
  }),
});

export const { useGetEffectiveSchemesQuery, useLazyGetEffectiveSchemesQuery } =
  ListEffectiveByCaseIdApi;

export const ListSchemeByVersionIdApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getListSchemeBySchemeVersionID: builder.query<any, any>({
      query: (arg) =>
        arg.schemeVersionId && LISTCLASSBYSCHEMEVERSIONID + arg.schemeVersionId,
    }),
  }),
});

export const {
  useGetListSchemeBySchemeVersionIDQuery,
  useLazyGetListSchemeBySchemeVersionIDQuery,
} = ListSchemeByVersionIdApi;

export const ListOfMeetingApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getListOfMeeting: builder.query<any, any>({
      query: (arg) => LISTOFMEETING + arg.caseId,
    }),
    createMeeting: builder.mutation<any, any>({
      query: (meetingData) => ({
        url: "/Ballot/CreateMeeting",
        method: "POST",
        body: meetingData,
      }),
    }),
  }),
});

export const {
  useGetListOfMeetingQuery,
  useLazyGetListOfMeetingQuery,
  useCreateMeetingMutation,
} = ListOfMeetingApi;

export const ReadBallotgApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getReadBallot: builder.query<any, any>({
      query: (arg) => READBALLOT + arg.ballotId,
    }),
  }),
});
export const { useGetReadBallotQuery, useLazyGetReadBallotQuery } =
  ReadBallotgApi;

export const VotesByBallotIdApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getVotesByBallotId: builder.query<any, any>({
      query: (ballotId) => ({
        url: `Ballot/ListCreditorVote`,
        method: "GET",
        params: { ...ballotId },
      }),
    }),
    updateBallot: builder.mutation<any, any>({
      query: (ballotData) => ({
        url: "/Ballot/UpdateBallot",
        method: "PUT",
        body: ballotData,
      }),
    }),
    createBallot: builder.mutation<any, any>({
      query: (ballotData) => ({
        url: "/Ballot/CreateBallot",
        method: "POST",
        body: ballotData,
      }),
    }),
  }),
});

export const {
  useGetVotesByBallotIdQuery,
  useLazyGetVotesByBallotIdQuery,
  useUpdateBallotMutation,
  useCreateBallotMutation,
} = VotesByBallotIdApi;

export const searchCreditorsByBallotIdAPi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    searchCreditorsByBallotId: builder.query<any, any>({
      query: ({ ballotId }) =>
        `Ballot/GetCreditorListByBallotClass?BallotId=${ballotId}`,
    }),
  }),
});

export const { useSearchCreditorsByBallotIdQuery } =
  searchCreditorsByBallotIdAPi;

export const createVoteApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    CreateCreditorVote: builder.mutation<any, any>({
      query: (voteData) => ({
        url: "/Ballot/CreateCreditorVote",
        method: "POST",
        body: voteData,
      }),
    }),
  }),
});
export const { useCreateCreditorVoteMutation } = createVoteApi;

export const BallotResultApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getBallotResultById: builder.query<any, any>({
      query: ({ ballotId }) => ({
        url: `Ballot/RefreshBallotAndCreditorVotes?BallotId=${ballotId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetBallotResultByIdQuery } = BallotResultApi;

export const ListBallotVersionsByBallotApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getListBallotVersionsByBallot: builder.query<any, any>({
      query: ({ ballotId }) => ({
        url: `/Ballot/ListBallotVersionsByBallot?ballotId=${ballotId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetListBallotVersionsByBallotQuery } =
  ListBallotVersionsByBallotApi;
export const ListReadBallotHistoryApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getListReadBallotHistory: builder.query<
      any,
      { ballotId: string; ballotSequenceId: string }
    >({
      query: ({ ballotId, ballotSequenceId }) => ({
        url: `Ballot/ReadBallotHistory?ballotId=${ballotId}&ballotSequenceId=${ballotSequenceId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetListReadBallotHistoryQuery } = ListReadBallotHistoryApi;
