import { useEffect, useState } from "react";
import "./CreditorSummary.scss";
import { useGetCreditorSummaryDataQuery } from "./CreditorSummaryReducer";
import { useNavigate, useParams } from "react-router-dom";
import { CommentHistory } from "@/features/claimsummary/summary/component/commentHistory";
import CustomAttributeValue from "@/components/CustomAttribute/CustomAttributeValue";
import { ENTITYTYPE } from "@/appConstants";
import { useCase } from "@/features/case/use-case";
import { CreditorFileuploader } from "@/features/creditor/summary/components/CreditorFileuploader";
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifButton,
  MotifChip,
} from "@ey-xd/motif-react";
import {
  useGetPayeeTypeApiDataQuery,
} from "@/features/distributions/steps/calculationStepReducer";
import MaskedInput from "../MaskedInput";
import { useTranslation } from "react-i18next";

function CreditorSummary() {
  const {t}=useTranslation()
  const { caseId, creditorId } = useParams<{
    caseId: string;
    creditorId: string;
  }>();
  const caseData = useCase(caseId);
  const propData = {
    caseId: caseData.caseId,
    creditorId: creditorId || "",
  };
  const navigate = useNavigate();
  const { data, error, isLoading, refetch } =
    useGetCreditorSummaryDataQuery(propData);
    const { data: payeeTypeData, isLoading: isPayeeTypeDataLoading } =
      useGetPayeeTypeApiDataQuery({
        CaseId: caseData.caseId,
      });

  const [PaymentDetailsOpen, setPaymentDetailsOpen] = useState(false);
  const [showField, setShowField] = useState<any>({})
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || isPayeeTypeDataLoading) return <div>{t("global.globalNotifications.loadingMessage")}</div>;
  if (error) return <div>{t("global.globalNotifications.unExpectedError")}</div>;

  // const convertDate = (date: Date) => {
  //   return new Date(date).toISOString().split("T")[0];
  // };

  const handleViewIcon = (name: string) => {
    setShowField((prev: any) => ({ ...prev, [name]: !prev[name] }));
  };

  const renderPayeeName = (id: string) => {
    if (payeeTypeData !== undefined) {
      const payee = payeeTypeData?.results?.find((payee:any) => payee.id === id);
      return payee?.name;
    }
  }

  return (
    <>
      <div className="motif-container case-summary-form pad-v-20">
        <div className="motif-row justify-content-between align-items-center">
          <div className="motif-col-4">
            <div className="motif-body1-default-regular page-title">
              {t("pages.creditors.creditorView.creditorViewTitle")}
            </div>
            <div className="motif-body2-default-regular page-description">
            {t("pages.creditors.creditorView.creditorViewDescription")}
            </div>
          </div>
          <div className="motif-col-4">
            <MotifButton
              onClick={() => {
                navigate(`/case/${caseId}/creditor/${creditorId}/edit`);
              }}
              size="small"
              type="submit"
              variant="secondary"
            >
              {t("global.globalActions.edit")}
            </MotifButton>
          </div>
        </div>
      </div>
      <div className="motif-container main-container creditor-summary-view">
        <div className="motif-container section-container pad-t-20 pad-b-20 pad-l-40">
          <div className="motif-row">
            <div className="motif-col-lg-4">
              <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.creditorId")}</div>
              <div className="motif-body2-default-bold">
                {data.creditorIdentifier.creditorReference === null
                  ? "-"
                  : data.creditorIdentifier.creditorReference}
              </div>
            </div>
            <div className="motif-col-lg-4">
              <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.creditorType")}</div>
              <div className="motif-body2-default-bold">
                {data.creditorIdentifier.isOrganization
                  ? "organization"
                  : "individual"}
              </div>
            </div>
            <div
              className={`motif-col-lg-4 ${
                data.creditorIdentifier.isOrganization ? "hide" : ""
              }`}
            >
              <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.prefix")}</div>
              <div className="motif-body2-default-bold">
                {data?.creditorIdentifier?.prefix || "-"}
              </div>
            </div>
          </div>
          <div className="motif-row pad-t-30">
            <div
              className={`motif-col-lg-4 ${
                data.creditorIdentifier.isOrganization ? "hide" : ""
              }`}
            >
              <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.firstName")}</div>
              <div className="motif-body2-default-bold">
                {data?.creditorIdentifier?.firstName}
              </div>
            </div>
            <div
              className={`motif-col-lg-4 ${
                data.creditorIdentifier.isOrganization ? "hide" : ""
              }`}
            >
              <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.lastName")}</div>
              <div className="motif-body2-default-bold">
                {data?.creditorIdentifier?.lastName}{" "}
              </div>
            </div>
            <div
              className={`motif-col-lg-4 ${
                data.creditorIdentifier.isOrganization ? "" : "hide"
              }`}
            >
              <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.businessName")}</div>
              <div className="motif-body2-default-bold">
                {data?.creditorIdentifier?.businessName}
              </div>
            </div>
            <div className="motif-col-lg-4">
              <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.displayName")}</div>
              <div className="motif-body2-default-bold">
                {data?.creditorIdentifier?.displayName}{" "}
              </div>
            </div>
          </div>
          <div className="motif-row pad-t-30">
            <div className="motif-col-lg-4">
              <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.description")}</div>
              <div className="motif-body2-default-bold">
                {data?.creditorIdentifier?.description}
              </div>
            </div>
            <div className="motif-col-lg-4">
              <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.relationshipToDebtor")}</div>
              <div className="motif-body2-default-bold">
                {" "}
                {data?.creditorIdentifier?.relationshipToDebtor}
              </div>
            </div>
          </div>
        </div>

        {data.customAttributes.length !== 0 ? (
          <div className="heading">
            <h1>{t("pages.creditors.creditorView.creditorAttributesTitle")}</h1>
            <div className="motif-container section-container-border pad-t-20 pad-b-20 pad-l-40">
              <div className="motif-row">
                {data?.customAttributes.map((attribute: any) => {
                  return (
                    <div className="motif-col-lg-4 attribute-field">
                      <div className="title">{attribute.name}</div>
                      <div className="motif-body2-default-bold">
                        <CustomAttributeValue
                          attributeType={attribute.value.attributeType}
                          value={attribute.value}
                          picklistDefinitions={attribute.picklistDefinitions}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="heading">
          <h1> {t("pages.creditors.creditorView.contactDetailsTitle")}</h1>
          {
            //@ts-ignore
            data &&
              data?.creditorContactList &&
              data?.creditorContactList?.map((contact: any, index: number) => (
                <>
                  <div
                    className={`contact-section-container ${
                      index !== 0 ? " pad-t-30 mar-t-30" : ""
                    }`}
                  >
                    <div className="motif-container section-container-border contact-details pad-t-20 pad-b-30 pad-l-40">
                      <div className="motif-row pad-b-10 contact-selection" style={{alignItems:"center"}}>
                        <div className="motif-col-lg-3">
                          <div className="motif-body2-default-bold">
                          {t("pages.creditors.creditorView.name", {creditorName: contact.firstName + " " + contact.lastName})}
                          </div>
                        </div>
                        <div className="motif-col-lg-4">
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={
                              contact.contactId ===
                              data?.creditorIdentifier?.primaryContactId
                                ? true
                                : false
                            }
                          />
                          <span className="primary-contact">
                            {t("pages.creditors.creditorForm.formLabelDetails.checkBoxPrimaryContact")}
                          </span>
                        </div>
                        <div className="motif-col-lg-3">
                          {contact?.usedAsAdditionalPayeeType ? (
                            <MotifChip
                              dotSide="left"
                              type="chip"
                              variant="monochrome-dark"
                            >
                              {t("pages.creditors.creditorView.additionalPayee")}
                            </MotifChip>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="motif-row pad-t-30">
                      {contact?.usedAsAdditionalPayeeType && <div className="motif-col-lg-3">
                          <div className="title">{t("pages.creditors.creditorView.additionalPayeeType")}</div>
                          <div className="motif-body2-default-bold">
                            {renderPayeeName(contact?.additionalPayeeTypeId)}
                          </div>
                        </div>}
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.firstName")}</div>
                          <div className="motif-body2-default-bold">
                            {contact.firstName}
                          </div>
                        </div>
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.lastName")}</div>
                          <div className="motif-body2-default-bold">
                            {contact.lastName}
                          </div>
                        </div>
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.prefix")}</div>
                          <div className="motif-body2-default-bold">
                            {contact.prefix || "-"}
                          </div>
                        </div>
                        <div className="motif-col-lg-3"></div>
                      </div>
                      <div className="motif-row pad-t-30">
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.email")}</div>
                          <div className="motif-body2-default-bold line-break">
                            {contact.email}
                          </div>
                        </div>
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.phoneNumber")}</div>
                          <div className="motif-body2-default-bold">
                            {contact.phone}
                          </div>
                        </div>
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.fax")}</div>
                          <div className="motif-body2-default-bold">
                            {contact.fax}
                          </div>
                        </div>
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.relationshipToCreditor")}</div>
                          <div className="motif-body2-default-bold">
                            {contact.relationshipToCreditor}
                          </div>
                        </div>
                        <div className="motif-col-lg-3"></div>
                      </div>
                    </div>
                  </div>

                  <div className="motif-container ccwp_claim_summary_white_accordian mar-t-20 pad-0">
                    <MotifAccordion
                      onClose={function Ua() {}}
                      onOpen={function Ua() {}}
                      style={{
                        flex: "1",
                      }}
                      alignIconRight={true}
                      useChevronIcon={true}
                      className="mar-b-20"
                      open={true}
                    >
                      <MotifAccordionTrigger>
                        <div className="ccwp_claim_summary_white">
                          <label>{t("pages.creditors.creditorForm.formLabelDetails.address")}</label>
                        </div>
                      </MotifAccordionTrigger>
                      <MotifAccordionContent>
                        <div className="motif-row">
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.address1")}</div>
                            <div className="motif-body2-default-bold">
                              {contact.address1}
                            </div>
                          </div>
                          <div className="motif-col-lg-3"></div>
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.address2")}</div>
                            <div className="motif-body2-default-bold">
                              {contact.address2}
                            </div>
                          </div>
                          <div className="motif-col-lg-3"></div>
                        </div>
                        <div className="motif-row pad-t-30">
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.city")}</div>
                            <div className="motif-body2-default-bold">
                              {contact.city}
                            </div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.provinceState")}</div>
                            <div className="motif-body2-default-bold">
                              {contact.provinceState}
                            </div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.postalCode")}</div>
                            <div className="motif-body2-default-bold">
                              {contact.postalCode}
                            </div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.country")}</div>
                            <div className="motif-body2-default-bold">
                              {contact.country}
                            </div>
                          </div>
                        </div>
                      </MotifAccordionContent>
                    </MotifAccordion>
                  </div>
                  <div className="motif-container ccwp_claim_summary_white_accordian mar-t-20 pad-0">
                    <MotifAccordion
                      onClose={() => setPaymentDetailsOpen(false)}
                      onOpen={() => setPaymentDetailsOpen(true)}
                      style={{
                        flex: "1",
                      }}
                      alignIconRight={true}
                      useChevronIcon={true}
                      className="mar-b-20"
                      open={PaymentDetailsOpen}
                    >
                      <MotifAccordionTrigger>
                        <div className="ccwp_claim_summary_white">
                          <label>{t("pages.creditors.creditorView.payementDetails")}</label>
                        </div>
                      </MotifAccordionTrigger>
                      <MotifAccordionContent>
                        <div className="motif-row payment-fields">
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.institutionNumber")}</div>
                            <MaskedInput
                              value={contact.institutionNumber}
                              name={"institutionNumber"}
                              index={index}
                              handleViewIcon={handleViewIcon}
                              showField={showField}
                            />
                          </div>
                          <div className="motif-col-lg-2">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.transitNumber")}</div>
                            <MaskedInput
                              value={contact.transitNumber}
                              name={"transitNumber"}
                              index={index}
                              handleViewIcon={handleViewIcon}
                              showField={showField}
                            />
                          </div>
                          <div className="motif-col-lg-2">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.accountNumber")}</div>
                            <MaskedInput
                              value={contact.accountNumber}
                              name={"accountNumber"}
                              index={index}
                              handleViewIcon={handleViewIcon}
                              showField={showField}
                            />
                          </div>
                          <div className="motif-col-lg-2">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.IBANNumber")}</div>
                            <MaskedInput
                              value={contact.ibanNumber}
                              name={"ibanNumber"}
                              index={index}
                              handleViewIcon={handleViewIcon}
                              showField={showField}
                            />
                          </div>
                          <div className="motif-col-lg-2">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.routingCode")}</div>
                            <MaskedInput
                              value={contact.routingCode}
                              name={"routingCode"}
                              index={index}
                              handleViewIcon={handleViewIcon}
                              showField={showField}
                            />
                          </div>
                        </div>
                        <div className="motif-row pad-t-30 payment-fields">
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.SWIFTCode")}</div>
                            <MaskedInput
                              value={contact.swiftCode}
                              name={"swiftCode"}
                              index={index}
                              handleViewIcon={handleViewIcon}
                              showField={showField}
                            />
                          </div>
                          <div className="motif-col-lg-2">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.ACHCode")}</div>
                            <MaskedInput
                              value={contact.achCode}
                              name={"achCode"}
                              index={index}
                              handleViewIcon={handleViewIcon}
                              showField={showField}
                            />
                          </div>
                        </div>
                      </MotifAccordionContent>
                    </MotifAccordion>
                  </div>
                </>
              ))
          }
        </div>
      </div>
      <CreditorFileuploader />
      <CommentHistory
        entityType={ENTITYTYPE.creditor}
        dataId={creditorId || ""}
      />
    </>
  );
}

export default CreditorSummary;
