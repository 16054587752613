import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifAccordion,
  MotifAccordionTrigger,
  MotifAccordionContent,
  MotifIcon,
  MotifFormField,
} from "@ey-xd/motif-react";
import "./fields.scss";
import logger from "@/libs/logger";
import {
  SectionCustomAttributeDefaults,
  SectionFieldsData,
  useListCustomAttributesQuery,
} from "@/services/formSetup/formSetupReducers";
import { IconoirPlusCircle } from "@ey-xd/motif-icon";
import { useParams } from "react-router-dom";
import { SectionAttributeFormData } from "./fields-helper";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "@/features/form-setup/formSetupSlice";
import SearchInput from "@/components/SearchInput/SearchInput";

interface FieldsProps {
  fields: any;
  append: (data: SectionAttributeFormData) => void;
  prepend: (data: SectionAttributeFormData) => void;
  remove: (index: number) => void;
  swap: (indexA: number, indexB: number) => void;
  move: (from: number, to: number) => void;
  insert: (index: number, data: SectionAttributeFormData) => void;
}

const Fields: React.FC<FieldsProps> = ({
  fields,
  append,
  // prepend,
  // remove,
  // swap,
  // move,
  // insert,
}) => {
  const { t } = useTranslation();
  const { caseId } = useParams();
  const dispatch = useDispatch();
  const { sectionId, sections } = useSelector((state: any) => state.formSetup);
  const entityType =
    sections?.find((section: any) => section.id === sectionId)
      ?.sectionMapping || 1;
  // enum, 1 = creditor, 2 = claim, 3 = claimItem
  // TODO: this data will be returned by section list api
  // API is not ready yet, default to 0 for creditor

  const { data: customFieldsDefaults } = useListCustomAttributesQuery(
    {
      caseId,
      entityType,
    },
    { skip: !caseId }
  );

  const [activePanels, setActivePanels] = useState<{ [key: string]: boolean }>({
    section: true,
    fields: true,
    settings: true,
  });

  const [searchText, setSearchText] = useState<string>("");
  const [filteredFields, setFilteredFields] = useState<
    SectionCustomAttributeDefaults[]
  >([]);

  function removeAddedFields(
    allFields: SectionCustomAttributeDefaults[],
    addedFields: SectionAttributeFormData[]
  ) {
    const idsAdded = addedFields.map(
      (field: SectionAttributeFormData) => field.customAttributeDefinitionId
    );
    const filtered = allFields.filter(
      (field: SectionCustomAttributeDefaults) =>
        !idsAdded.includes(field.attributeId) ||
        field.attributeName === "Custom Display Field"
    );
    return filtered;
  }

  function handlePanelClick(panel: string, override?: boolean) {
    setActivePanels({
      ...activePanels,
      [panel]: override ? override : !activePanels[panel],
    });
  }

  function onAddAttribute(attribute: SectionCustomAttributeDefaults) {
    const newAttribute: SectionFieldsData = {
      caseId: caseId || "",
      sectionDefinitionId: sectionId || "",
      customAttributeDefinitionId: attribute.attributeId,
      displayName: attribute.attributeName,
      displayNameFrench: attribute.attributeFrenchName || "",
      tooltip: null,
      tooltipFrench: null,
      sortOrder: fields.length,
      isRequired: attribute.isRequired,
    };
    append(newAttribute);
  }

  useEffect(() => {
    if (customFieldsDefaults) {
      dispatch(
        updateForm({ key: "customFieldsDefaults", value: customFieldsDefaults })
      );
    }
    if (customFieldsDefaults && fields?.length > 0) {
      const filtered = removeAddedFields(customFieldsDefaults, fields);
      setFilteredFields(filtered);
    } else {
      setFilteredFields(customFieldsDefaults || []);
    }
  }, [customFieldsDefaults, fields, dispatch]);

  const handleSearch = () => {
    logger.info("filtered", searchText, customFieldsDefaults);
    if (customFieldsDefaults && customFieldsDefaults?.length >= 0) {
      const filtered = customFieldsDefaults?.filter(
        (field: SectionCustomAttributeDefaults) =>
          field.attributeName.toLowerCase().includes(searchText.toLowerCase())
      );
      const removeExisting = removeAddedFields(filtered, fields);
      logger.info("filtered", removeExisting);
      setFilteredFields(removeExisting || []);
    }
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredFields(customFieldsDefaults || []);
  };

  useEffect(() => {
    console.log(fields);
  }, [fields]);

  return (
    <div className="fields">
      <MotifAccordion
        useChevronIcon={true}
        alignIconRight={true}
        open={activePanels["fields"]}
        className="mar-h-0 pad-h-0"
        onOpen={() => {}}
        onClose={() => {}}
      >
        <MotifAccordionTrigger
          triggerButtonProps={{
            title: "Custom Trigger Button title",
            "aria-label": "Custom Trigger Button aria-label",
            onClick: () => handlePanelClick("fields"),
          }}
        >
          <span className="motif-body1-default-light">
            {t("pages.portalConfig.formSetup.fields.heading")}
          </span>
        </MotifAccordionTrigger>
        <MotifAccordionContent>
          <div className="panel">
            <div className="panel-header">
              <h3>Add Field</h3>
              <MotifFormField className="search">
                <SearchInput
                  aria-label={t("Search")}
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                  onClear={handleClearSearch}
                  onEnter={handleSearch}
                  placeholder={t("Search")}
                />
              </MotifFormField>
            </div>
            <div className="panel-body">
              <ul>
                {filteredFields.length > 0 ? (
                  filteredFields.map(
                    (field: SectionCustomAttributeDefaults, index: number) => (
                      <li key={`field-${index}`} className="field-item">
                        <span>{field.attributeName}</span>
                        <MotifIcon
                          src={IconoirPlusCircle}
                          className="field-icon"
                          //@ts-ignore
                          onClick={(e: any) => onAddAttribute(field)}
                        />
                      </li>
                    )
                  )
                ) : (
                  <li className="no-data-found">{t("No data found")}</li>
                )}
              </ul>
            </div>
          </div>
        </MotifAccordionContent>
      </MotifAccordion>
    </div>
  );
};

export default Fields;
