import React from "react";
import { Control, Controller, useController, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import {useTranslation} from 'react-i18next';
import SelectBox from "@/components/Form/SelectBox/SelectBox";
import { ClaimSetupDebtor } from "./type";


type ControlSelectType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<ClaimSetupDebtor>;
    name: string;
    showLabel: boolean;
    label: string;
    options: {
        label: string,
        value: string,
    }[];
    setValue: UseFormSetValue<ClaimSetupDebtor>;
    getValues: UseFormGetValues<ClaimSetupDebtor>;
    defaultValue?: string ;
    filter?: boolean | ((x?: any) => void) | undefined;
    visibleOptions?: number;
    onChange?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function ControlSelect ({name, control, required=false, options, showLabel, label, setValue, getValues, defaultValue, visibleOptions, filter,onChange}: ControlSelectType) {
    const {
        field,
      } = useController({
        // @ts-ignore
        name,
        control,
        rules: { required: required },
      });
    const {t} = useTranslation();
    const translatedOptions = options.map(option => { 
        return {
            label: option.label,
            value: option.value,
        };
    })
    return (
        <> 
            {
                showLabel && <div className="formFieldLabel">
                    <label  htmlFor="input" aria-label={label}>{t(label)}{ required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
           <Controller
                name={field.name}
                control={control}
                defaultValue={defaultValue}
                rules={{ required }}
                render={({ field: { onChange: controllerOnChange, value }}) => {
                          return (
                          <>                        
                            <SelectBox 
                                filter={filter} 
                                visibleOptions={visibleOptions} 
                                options={translatedOptions} 
                                value={value || defaultValue || ''} 
                                onChange={(selectedValue) => {
                                    controllerOnChange(selectedValue);
                                    if (onChange) {
                                        onChange(selectedValue);
                                    }
                                }} />
                          </>
                          );
                    }}
            />
        </>
        
    );
}
