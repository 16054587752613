import React, { useEffect, useState } from "react";
import {
  MotifButton,
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifIcon,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";
import {
  actionIcDescription24px,
} from "@ey-xd/motif-react/assets/icons";
import { useTranslation } from "react-i18next";
import {
  useLazyGetFileImportProgressStatusQuery,
  useUploadManualDataSourceDocumentMutation,
} from "@/services/manualDataSource/ManualDataSourceReducer";
import { useParams } from "react-router-dom";
import SuccessMessage from "@/pages/CaseDetail/Distributions/ManualDataSource/SuccessMessage/SuccessMessage";
import DataSourceUploadStatus from "@/pages/CaseDetail/Distributions/ManualDataSource/UploadStatus/DataSoureUploadStatus";
import ErrorMessage from "@/pages/CaseDetail/Distributions/ManualDataSource/ErrorMessage/ErrorMessage";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { fileExtensions } from "@/services/documents/documentsReducer";

interface BulkUploadProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  title: string;
  uploadLabel: string;
  uploadDescription: string;
  maxFiles: number;
  onClose: () => void;
}

const BulkChequeImport = ({
  showModal,
  setShowModal,
  title,
  uploadLabel,
  uploadDescription,
  maxFiles,
  onClose,
}: BulkUploadProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { caseId } = useParams<{ caseId: string }>();
  const { t } = useTranslation();
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const [showProgress, setShowProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [importJobId, setImportJobId] = useState<string | null>(null);
  const [getFileImportProgressStatus, { data: importStatusData }] =
    useLazyGetFileImportProgressStatusQuery();
  const [uploadDocument] = useUploadManualDataSourceDocumentMutation();
  const [discardDetails, setDiscardDetails] = useState(false);

  function handleDiscardReject() {
    setDiscardDetails(false);
  }
  function handleDiscardApprove() {
    setDiscardDetails(false);
    handleClose();
  }
  const handleFileChange = (files: any) => {
    setFile(files[0]);
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (
      showProgress &&
      importJobId &&
      importStatusData?.statusName !== "Success"
    ) {
      intervalId = setInterval(() => {
        getFileImportProgressStatus({ importJobId });
      }, 10000);
    }
    if (importStatusData?.statusName === "Failed") {
      setUploadStatus("error");
      setErrorMessage(importStatusData?.errorMessage);
      setShowProgress(false);
    } else if (importStatusData?.statusName === "Success") {
      setUploadStatus("Success");
      setShowProgress(false);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    showProgress,
    importJobId,
    importStatusData,
    getFileImportProgressStatus,
  ]);

  const handleSubmit = async () => {
    setIsUploading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("DisplayName", file.name.replace(/\.[^/.]+$/, ""));
      formData.append("Entitytype", "6");
      if (caseId) {
        formData.append("CaseId", caseId);
      }
      try {
        const response = await uploadDocument(formData).unwrap();
        const importJobId = response;
        setImportJobId(importJobId);
        await getFileImportProgressStatus({ importJobId });
        setShowProgress(true);
        setUploadStatus("inProgress");
      } catch (error: any) {
        setUploadStatus("error");
        setErrorMessage(error?.data ?? undefined);
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <>
      <MotifModal
        onClose={() => {
          if (
            file &&
            (!uploadStatus || 
              (uploadStatus !== "Success" && uploadStatus !== "error" && uploadStatus!=="inProgress") )
          ) {
            setDiscardDetails(true);
          } else {
            handleClose();
          }
        }}
        show={showModal}
        size="lg"
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Custom Close Button title",
          }}
        >
          <span>{title}</span>
        </MotifModalHeader>
        {uploadStatus === "Success" ? (
          <SuccessMessage />
        ) : uploadStatus === "error" ? (
          <ErrorMessage errorMessage={errorMessage} />
        ) : !showProgress ? (
          <>
            <MotifModalBody className="pad-t-10">
              <div className="pad-v-20">
                <MotifFileUploader
                  id="bulk-upload"
                  label={uploadLabel}
                  labelDescription={uploadDescription}
                  onDrop={handleFileChange}
                  accept={fileExtensions.zip}
                  maxFiles={maxFiles}
                >
                  {file && (
                    <MotifFileUploaderItem
                      fileIcon={<MotifIcon src={actionIcDescription24px} />}
                      fileName={file.name}
                      uploaded={true}
                      uploadedText={
                        t("pages.claims.documentPopup.displayName") + file.name
                      }
                      error={
                        (file && file.size >= fileExtensions.zipMaxFileSize) ||
                        false
                      }
                      errorText={t(
                        "pages.claims.stageStatusPopup.filesizeExceedsLimit",
                        {
                          filesize: "500",
                        }
                      )}
                    />
                  )}
                </MotifFileUploader>
              </div>
            </MotifModalBody>

            <MotifModalFooter>
              <div className="document-footer display-flex ">
                <MotifButton
                  type="button"
                  variant="secondary"
                  className="cancelBtn mar-r-10"
                  onClick={() => {
                    if (file) {
                      setDiscardDetails(true);
                    } else {
                      handleClose();
                    }
                  }}
                >
                  {t("global.globalActions.cancel")}
                </MotifButton>
                <MotifButton
                  onClick={handleSubmit}
                  variant="primary"
                  className="cancelBtn"
                  disabled={
                    isUploading || !file ||
                    (file && file.size >= fileExtensions.zipMaxFileSize) ||
                    false
                  }
                >
                  {isUploading ? t("global.globalActions.uploading") : t("global.globalActions.upload")}
                </MotifButton>
              </div>
            </MotifModalFooter>
          </>
        ) : (
          <DataSourceUploadStatus importStatus={importStatusData} />
        )}
      </MotifModal>
      <ConfirmationModal
        isVisible={discardDetails}
        setIsVisible={handleDiscardReject}
        cancelBtnApproveHandler={handleDiscardApprove}
        cancelBtnRejectHandler={handleDiscardReject}
      />
    </>
  );
};

export default BulkChequeImport;
