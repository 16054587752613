import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EditSummaryFormType } from "./type";
import { ADDRESSONE_NAME, ADDRESSTWO_NAME, CITY_NAME, EMAIL_NAME, FAX_NAME, FIRSTN_NAME, LASTN_NAME, PHONE_NAME, POSTAL_NAME, PREFIX_NAME } from "./constant";
import InputBox from "@/components/Form/InputBox/InputBox";

type ControlInputBoxType = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<EditSummaryFormType>;
  name: typeof FIRSTN_NAME | typeof LASTN_NAME | typeof ADDRESSONE_NAME | typeof ADDRESSTWO_NAME | typeof CITY_NAME | typeof POSTAL_NAME | typeof PHONE_NAME | typeof EMAIL_NAME | typeof FAX_NAME | typeof PREFIX_NAME;
  showLabel: boolean;
  label: string;
  readonly?:boolean;
};

export default function ControlInputBox({
  name,
  control,
  required = false,
  isTextArea = false,
  showLabel,
  label,
  readonly
}: ControlInputBoxType) {
  const { field } = useController({
    name,
    control,
    rules: { required: required },
  });
  const { t } = useTranslation();

  return (
    <>
      {showLabel && (
        <div className="formFieldLabel">
          <label htmlFor="input" aria-label={label}>
            {t(label)}
            {required ? <span className="requiredSymbol">*</span> : ""}
          </label>
        </div>
      )}
      <Controller
        name={field.name}
        control={control}
        rules={{ required }}
        render={({ field: { onChange, value, ...args } }) => {
          return (
            <>
              <InputBox
                id={FIRSTN_NAME}
                required={true}
                value={value || ""}
                onChange={onChange}
                isTextArea={isTextArea}
                readonly={readonly}
                {...args}
              />
            </>
          );
        }}
      />
    </>
  );
}
