import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClaimSetupSelectAssignee,ClaimSetupNewAssigneeFormType } from './type'

export interface AssigneeSearchData {
    selectAssigneeItems: ClaimSetupSelectAssignee,
    selectedAssigneeCompleteDetail: any;
    isNewAssignee: boolean;
    newAssigneeDetail: ClaimSetupNewAssigneeFormType;
}

const initialState: AssigneeSearchData = {
    selectAssigneeItems: { assigneeItem: "" },
    selectedAssigneeCompleteDetail: "",
    isNewAssignee: false,
    newAssigneeDetail: {
        isOrg: false,
        prefix: "",
        firstName: "",
        lastName: "",
        businessName: "",
        description: "",
        relationshipToDebtor: "",
        contact: {
          firstName: "",
          lastName: "",
          prefix: "",
          address1: "",
          address2: "",
          city: "",
          provinceStateId: null,
          provinceState: "",
          postalCode: "",
          countryId: "",
          country: "",
          phone: "",
          fax: "",
          email: "",
          relationshipToCreditor: "",
        },
      },
};

export const claimSetupAssigneeSearch = createSlice({
    name: 'claimSetupAssigneeSearch',
    initialState,
    reducers: {
        setSearchAssigneeValue: (state, action: PayloadAction<ClaimSetupSelectAssignee>) => {
            state.selectAssigneeItems = action.payload;
        },
        setSelectedAssigneeCompleteDetail: (state, action: PayloadAction<any>) => {
            state.selectedAssigneeCompleteDetail = action.payload;
        },
        resetAssigneeState: (state) => {
            return initialState;
        },
        setIsNewAssigneeType: (state, action: PayloadAction<boolean>) => {
            state.isNewAssignee = action.payload;
        },
        setNewAssigneeDetail: (state, action: PayloadAction<ClaimSetupNewAssigneeFormType>) => {
            state.newAssigneeDetail = action.payload;
        },  
        resetNewAssigneeDetail: (state) => {
            state.newAssigneeDetail = initialState.newAssigneeDetail;
        },     
    },
});

export const {  setSearchAssigneeValue, 
                setSelectedAssigneeCompleteDetail,
                resetAssigneeState,
                setIsNewAssigneeType,
                setNewAssigneeDetail,
                resetNewAssigneeDetail,
            } = claimSetupAssigneeSearch.actions;

export default claimSetupAssigneeSearch.reducer;