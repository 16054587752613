import Table from "@/components/Table/table";
import { useColumnHistoryDefs } from "@/features/creditor/claimsTab/Constant/AttributeHistoryClaims";
import { useParams } from "react-router-dom";
import { useGetCreditorAttributeHistoryDataQuery } from "../CreditorSummaryUpdate/CreditorSummaryReducer";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { useCase } from "@/features/case/use-case";
import { useTranslation } from "react-i18next";

export const TitleComplement = () => {
  return <></>;
};

export default function CreditorAttributeHistoryTab() {
  const { creditorId } = useParams<{
    creditorId: string;
  }>();
  const {t}=useTranslation()
  const getDataByUrl = { creditorId: creditorId };
  const { data, isLoading } =
    useGetCreditorAttributeHistoryDataQuery(getDataByUrl);

  const sortedData = data?.slice().sort((a: { createdDate: string | number | Date; }, b: { createdDate: string | number | Date; }) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());

  const headerData = {
    title: "Attribute History X",
    description: "Descriptive text that describes your page or content.",
  };
  const caseData = useCase();
  const timeZone = caseData?.abbreviation;
  const columnHistoryDefs=useColumnHistoryDefs(timeZone)

  return (
    <>
      {isLoading ? (
        <MotifProgressLoader show variant="default" className="mar-b-20" />
      ) : (
        <div className="mar-b-20">
          <Table
            columnDefs={columnHistoryDefs}
            cellRenderers={sortedData}
            TitleComplement={TitleComplement}
            title={"pages.creditors.creditorAttributeTab.tableTitle"}
            description={
              "pages.creditors.creditorAttributeTab.tableDescription"
            }
            caseRenderers={headerData}
            pagination={true}
            overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}

          />
        </div>
      )}
    </>
  );
}
