import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifLabel,
  MotifFormField,
  MotifInput,
  MotifSelect,
  MotifOption,
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifIcon,
  MotifErrorMessage,
} from "@/libs/@ey-xd/motif-react";
import { MotifActionIcDescription24px } from "@ey-xd/motif-icon";
import { AttributeType } from "@/pages/CaseDetail/Configuration/Attributes/type";
import {
  CustomAttributeFormats,
  CustomAttributeProps,
} from "./CustomAttributeType";
import "./CustomAttribute.css";
import FormattedDatePicker from "../FormattedDatePicker/FormattedDatePicker";
import { fileExtensions } from "@/services/documents/documentsReducer";

export default function CustomAttribute({
  customAttributeDetails,
  customAttributeType,
  attributeValue,
  setAttributeValue,
  index,
  setIntialAttributesValue,
}: CustomAttributeProps) {
  const { t } = useTranslation();
  const [valid, setValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (
      customAttributeDetails.metadataJSON.default &&
      customAttributeDetails.metadataJSON.default !== "" &&
      attributeValue
    ) {
      if (
        customAttributeType === AttributeType.Boolean &&
        attributeValue[index].value.booleanValue === null
      ) {
        if (customAttributeDetails.metadataJSON.default === "true") {
          handleDefaultChange(true);
        } else {
          handleDefaultChange(false);
        }
      } else {
        if (customAttributeDetails.picklistDefinitions) {
          const optionId = customAttributeDetails.picklistDefinitions.filter(
            (x: any) => {
              return x.name === customAttributeDetails.metadataJSON.default;
            }
          );
          if (optionId.length !== 0) {
            if (
              customAttributeType === AttributeType.SingleSelect &&
              attributeValue[index].value.selectedValue === null
            ) {
              handleDefaultChange(optionId[0].id);
            } else if (
              customAttributeType === AttributeType.MutliSelect &&
              attributeValue[index].value.selectedValues.length === 0
            ) {
              handleDefaultChange([optionId[0].id]);
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleDefaultChange = (value: any) => {
    const updatedValue = attributeValue.map((x: any, i: any) => {
      if (i === index) {
        switch (customAttributeType) {
          case AttributeType.Boolean:
            return { ...x, value: { ...x.value, booleanValue: value } };
          case AttributeType.SingleSelect:
            return { ...x, value: { ...x.value, selectedValue: value } };
          case AttributeType.MutliSelect:
            return { ...x, value: { ...x.value, selectedValues: value } };
          default:
            return x;
        }
      } else {
        return x;
      }
    });
    setAttributeValue(updatedValue);
    if (setIntialAttributesValue) {
      setIntialAttributesValue(JSON.parse(JSON.stringify(updatedValue)));
    }
  };

  const validateValue = (value: any) => {
    if (customAttributeDetails.isRequired && value === "") {
      setErrorMessage(
        customAttributeDetails.name +
          " " +
          t("pages.configuration.attributes.requiredError")
      );
      return false;
    } else {
      switch (customAttributeType) {
        case AttributeType.Number:
          if (
            customAttributeDetails.metadataJSON.minimum !== undefined &&
            customAttributeDetails.metadataJSON.maximum !== undefined
          ) {
            setErrorMessage(
              t("pages.configuration.attributes.numberError") +
                customAttributeDetails.metadataJSON.minimum +
                " & " +
                customAttributeDetails.metadataJSON.maximum
            );
            return (
              Number(value) >=
                Number(customAttributeDetails.metadataJSON.minimum) &&
              Number(value) <=
                Number(customAttributeDetails.metadataJSON.maximum)
            );
          }
          return true;

        case AttributeType.FreeText:
          switch (customAttributeDetails.metadataJSON.format) {
            case CustomAttributeFormats.SIN:
              setErrorMessage(
                customAttributeDetails.name +
                  " " +
                  t("pages.configuration.attributes.sinError")
              );
              return value.match(/^(\d{3}-\d{3}-\d{3})|(\d{9})$/);

            case CustomAttributeFormats.Email:
              setErrorMessage(t("pages.configuration.attributes.emailError"));
              // eslint-disable-next-line
              return value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

            // case CustomAttributeFormats.Regex:
            //   try {
            //     setErrorMessage(t("pages.configuration.attributes.regexError"));
            //     return value.match(
            //       new RegExp("") //Need to add user inputted Regex here
            //     );
            //   } catch (e) {
            //     return true;
            //   }

            case CustomAttributeFormats.Phone:
              setErrorMessage(
                customAttributeDetails.name +
                  " " +
                  t("pages.configuration.attributes.phoneError")
              );
              return value.match(
                /^(\d{3}-?\s?\d{3}-?\s?\d{4}\s?)?(ext\.\d{4})?$/
              );

            case CustomAttributeFormats.Postal:
              setErrorMessage(
                customAttributeDetails.name +
                  " " +
                  t("pages.configuration.attributes.postalError")
              );
              return value.match(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);

            default:
              return true;
          }

        default:
          return true;
      }
    }
  };

  const handleChange = (value: any) => {
    const updatedValue = attributeValue.map((x: any, i: any) => {
      if (i === index) {
        switch (customAttributeType) {
          case AttributeType.FreeText:
            return { ...x, value: { ...x.value, textValue: value } };
          case AttributeType.Number:
            return { ...x, value: { ...x.value, numberValue: value } };
          case AttributeType.Boolean:
            return { ...x, value: { ...x.value, booleanValue: value } };
          case AttributeType.Date:
            return { ...x, value: { ...x.value, dateValue: value } };
          case AttributeType.SingleSelect:
            return { ...x, value: { ...x.value, selectedValue: value } };
          case AttributeType.MutliSelect:
            return { ...x, value: { ...x.value, selectedValues: value } };
          case AttributeType.Currency:
            return { ...x, value: { ...x.value, amount: value } };
          case AttributeType.Document:
            return { ...x, value: { ...x.value, displayName: value } };
          default:
            return x;
        }
      } else {
        return x;
      }
    });

    setAttributeValue(updatedValue);
    setValid(validateValue(value));
  };

  const handleFileChange = (files: any) => {
    const file = files[0];
    if (file) {
      handleChange(file.name);

      const fileType = file.name.split(".").pop();
      if (
        customAttributeDetails.metadataJSON.fileFormat !== undefined &&
        customAttributeDetails.metadataJSON.fileFormat !== ""
      ) {
        setErrorMessage(t("pages.configuration.attributes.fileError"));
        setValid(
          customAttributeDetails.metadataJSON.fileFormat!.includes(fileType)
        );
      }
    }
  };

  const renderField = () => {
    switch (customAttributeType) {
      case AttributeType.FreeText:
        return (
          <MotifInput
            value={
              attributeValue[index].value.textValue
                ? attributeValue[index].value.textValue
                : ""
            }
            onChange={(e) => handleChange(e.target.value)}
            type={
              customAttributeDetails.metadataJSON.maskInput
                ? "password"
                : "text"
            }
            maxLength={customAttributeDetails.metadataJSON.maxCharacter}
          />
        );

      case AttributeType.SingleSelect:
        return (
          <MotifSelect
            value={
              attributeValue[index].value.selectedValue
                ? attributeValue[index].value.selectedValue
                : ""
            }
            onChange={(value) => handleChange(value)}
          >
            {customAttributeDetails.picklistDefinitions.length !== 0 &&
              customAttributeDetails.picklistDefinitions.map(
                (option: any, index: number) => (
                  <MotifOption value={option.id} key={index}>
                    {option.name}
                  </MotifOption>
                )
              )}
          </MotifSelect>
        );

      case AttributeType.MutliSelect:
        return (
          <MotifSelect
            value={
              attributeValue[index].value.selectedValues
                ? attributeValue[index].value.selectedValues
                : ""
            }
            onChange={(value) => handleChange(value)}
            multiple={true}
          >
            {customAttributeDetails.picklistDefinitions.length !== 0 &&
              customAttributeDetails.picklistDefinitions.map(
                (option: any, index: number) => (
                  <MotifOption value={option.id} key={index}>
                    {option.name}
                  </MotifOption>
                )
              )}
          </MotifSelect>
        );

      case AttributeType.Boolean:
        return (
          <MotifSelect
            value={
              attributeValue[index].value.booleanValue !== null &&
              attributeValue[index].value.booleanValue !== undefined
                ? attributeValue[index].value.booleanValue.toString()
                : ""
            }
            onChange={(value) => {
              if (value === "true") {
                handleChange(true);
              } else {
                handleChange(false);
              }
            }}
          >
            <MotifOption value="true">
              {t("pages.configuration.attributes.createAttribute.yes")}
            </MotifOption>
            <MotifOption value="false">
              {t("pages.configuration.attributes.createAttribute.no")}
            </MotifOption>
          </MotifSelect>
        );

      case AttributeType.Number:
        return (
          <MotifInput
            value={
              attributeValue[index].value.numberValue
                ? attributeValue[index].value.numberValue
                : ""
            }
            onChange={(e) => handleChange(Number(e.target.value))}
            type="number"
            maxLength="100"
            onKeyPress={(e: any) =>
              customAttributeDetails.metadataJSON.format ===
                CustomAttributeFormats.Integer &&
              /\./.test(e.key) &&
              e.preventDefault()
            }
          />
        );

      case AttributeType.Document:
        console.log("attributeValue[index].value", attributeValue[index].value);
        return (
          <MotifFileUploader
            label="Drag and drop a document or upload"
            accept={customAttributeDetails.metadataJSON.fileFormat}
            onDrop={(files) => handleFileChange(files)}
            maxFiles={1}
          >
            {attributeValue[index].value.documentId && (
              <MotifFileUploaderItem
                fileIcon={<MotifIcon src={MotifActionIcDescription24px} />}
                fileName={
                  attributeValue[index].value.displayName
                    ? attributeValue[index].value.displayName
                    : ""
                }
                onRemove={() => setAttributeValue(null)}
                removable
                error={
                  (attributeValue[index].value &&
                    attributeValue[index].value?.size >=
                      fileExtensions.maxFileSize) ||
                  false
                }
                errorText={t(
                  "pages.claims.stageStatusPopup.filesizeExceedsLimit",
                  {
                    filesize: "250",
                  }
                )}
              />
            )}
          </MotifFileUploader>
        );

      case AttributeType.Date:
        return (
          <FormattedDatePicker
            onChange={(value) =>
              handleChange(value.toISOString().substring(0, 10))
            }
            value={
              attributeValue[index].value.dateValue
                ? new Date(attributeValue[index].value.dateValue + " ")
                : undefined
            }
            todayMark={true}
          />
        );

      case AttributeType.Currency:
        return (
          <MotifInput
            value={
              attributeValue[index].value.amount ||
              attributeValue[index].value.amount === 0
                ? attributeValue[index].value.amount
                : ""
            }
            onChange={(e) =>
              handleChange(
                e.target.value || e.target.value === "0"
                  ? Number(e.target.value)
                  : ""
              )
            }
            type="text"
            maxLength="100"
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {attributeValue ? (
        <div className="custom-attribute-value">
          <MotifLabel aria-label="Input" for="input">
            {customAttributeDetails.name}
            {customAttributeDetails.isRequired && (
              <span className="required-input">*</span>
            )}
          </MotifLabel>
          <MotifFormField aria-label="Input" role="group">
            {renderField()}
            {!valid && (
              <MotifErrorMessage id="error" aria-label="Error Message">
                {errorMessage}
              </MotifErrorMessage>
            )}
          </MotifFormField>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
