import {
  MotifCard,
  MotifCardHeader,
  MotifIcon,
} from "@/libs/@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import "../../Configuration/Attributes/Attributes.css";
import { CreateCategoryAllInputs } from "./createCategoryAllInputs";
import { useCase } from "@/features/case/use-case";
import { useTranslation } from "react-i18next";
export default function CreateCategory() {
  const { t } = useTranslation();
  const caseData = useCase();
  return (
    <div className="attribute-setup-container">
      <MotifCard variant="card-no-border">
        <MotifCardHeader>
          <div className="attributes-back-button">
            <Breadcrumb
              items={[
                {
                  text: t("global.globalActions.back"),
                  icon: <MotifIcon src={IconoirArrowLeft} />,
                  url: `/case/${caseData.caseId}?activeTab=5`,
                },
              ]}
              ariaLabel="Breadcrumb navigation"
            />
          </div>
          <div className="motif-h3-default-bold">{t("pages.configuration.category.categoryCreateTitle")}</div>
          <p style={{ margin: "0px" }}>
          {t("pages.configuration.category.categoryCreateDescription")}
          </p>
        </MotifCardHeader>
        <CreateCategoryAllInputs />
      </MotifCard>
    </div>
  );
}
