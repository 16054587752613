import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifFormField,
  MotifLabel,
  MotifInput,
  MotifTextArea,
  MotifSelect,
  MotifOption,
} from "@/libs/@ey-xd/motif-react";
import "./Stages.css";
import { StageType, PositionType, EmptyStatus } from "./StagesType";
import {
  useLazyCreateStageQuery,
  useUpdateStageMutation,
  useCreateStatusMutation
} from "@/services/stages/stagesReducer";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";

export default function CreateEditStage({
  show,
  setShow,
  stageData,
  isEdit,
  getStageData,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  stageData: StageType;
  isEdit: boolean;
  getStageData: (isCreate: boolean) => void;
}) {
  const { t } = useTranslation();
  const [inputValues, setInputValues] = useState(stageData);

  const [isLoading, setIsLoading] = useState(false);
  const [createStageQuery] = useLazyCreateStageQuery();
  const [updateStageQuery] = useUpdateStageMutation();
  const [createStatusQuery] = useCreateStatusMutation();
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setInputValues(stageData);
    }
    // eslint-disable-next-line
  }, [show]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (isEdit) {
      const requestBody = { ...inputValues };
      await updateStageQuery(requestBody).then(() => {
        handleClose();
        getStageData(false);
      });
    } else {
      const requestBody = {
        ...inputValues,
      };
      delete requestBody.id;
      await createStageQuery(requestBody).then((response: any) => {
        if(response.isSuccess) {
          const newStatus = {
            ...EmptyStatus,
            name: requestBody.name,
            caseId: stageData.caseId,
            claimStageDefinitionId: response.data,
          };
          delete newStatus.id;
          createStatusQuery(newStatus).then(() => {
            handleClose();
            getStageData(true);
          });
        }
        handleClose();
      });
    }
  };

  const handleClose = () => {
    setIsLoading(false);
    setIsDiscardVisible(false);
    setShow(false);
  };

  return (
    <>
    <MotifModal
      className="create-edit-stage"
      onClose={() => {
        if(JSON.stringify(stageData)!==JSON.stringify(inputValues)){
          setIsDiscardVisible(true)

        }
        else{
          handleClose()
        }
      }}
      show={show}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
    >
      <MotifModalHeader>
        {isEdit ? (
          <span>{t("pages.configuration.stages.editStage")}</span>
        ) : (
          <span>{t("pages.configuration.stages.addStage")}</span>
        )}
      </MotifModalHeader>

      <MotifModalBody>
        <MotifLabel>
          {t("pages.configuration.stages.stageName")}
          <span className="required-input">*</span>
        </MotifLabel>
        <MotifFormField>
          <MotifInput
            onChange={(e) =>
              setInputValues({ ...inputValues, name: e.target.value })
            }
            value={inputValues.name}
          />
        </MotifFormField>
        <MotifLabel>
          {t("pages.configuration.stages.stageNameFrench")}
        </MotifLabel>
        <MotifFormField>
          <MotifInput
            onChange={(e) =>
              setInputValues({ ...inputValues, nameFrench: e.target.value })
            }
            value={inputValues.nameFrench}
          />
        </MotifFormField>
        <MotifLabel>
          {t("pages.configuration.stages.stageDescription")}
        </MotifLabel>
        <MotifFormField>
          <MotifTextArea
            onChange={(e) =>
              setInputValues({ ...inputValues, description: e.target.value })
            }
            value={inputValues.description}
          />
        </MotifFormField>
        <MotifLabel>
          {t("pages.configuration.stages.stageDescriptionFrench")}
        </MotifLabel>
        <MotifFormField>
          <MotifTextArea
            onChange={(e) =>
              setInputValues({
                ...inputValues,
                descriptionFrench: e.target.value,
              })
            }
            value={inputValues.descriptionFrench}
          />
        </MotifFormField>

        <MotifFormField>
          <MotifLabel>
            {t("pages.configuration.stages.position")}
            <span className="required-input">*</span>
          </MotifLabel>
          <MotifSelect
            onChange={(v) => setInputValues({ ...inputValues, position: Number(v) })}
            value={inputValues.position?.toString()}
          >
            <MotifOption value={PositionType.DebtorPosition.toString()}>
              {t("pages.configuration.stages.debtorPosition")}
            </MotifOption>
            <MotifOption value={PositionType.CreditorPosition.toString()}>
              {t("pages.configuration.stages.creditorPosition")}
            </MotifOption>
          </MotifSelect>
        </MotifFormField>
      </MotifModalBody>

      <MotifModalFooter>
        <MotifButton
          onClick={handleSubmit}
          type={isLoading ? "loader" : "submit"}
          variant="primary"
          disabled={!inputValues.name || (inputValues.position !== PositionType.DebtorPosition && inputValues.position !== PositionType.CreditorPosition)}
        >
          {isEdit
            ? t("pages.configuration.stages.edit")
            : t("pages.configuration.stages.add")}
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
    <ConfirmationModal isVisible={isDiscardVisible} setIsVisible={setIsDiscardVisible} cancelBtnApproveHandler={handleClose} cancelBtnRejectHandler={() => setIsDiscardVisible(false)}/>
    </>
  );
}
