import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  MotifAccordion,
  MotifAccordionTrigger,
  MotifAccordionContent,
  MotifIcon,
  MotifTable,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { IconoirPlusCircle } from "@ey-xd/motif-icon";
import {
  setNotification,
  updateForm,
} from "@/features/form-setup/formSetupSlice";
import logger from "@/libs/logger";

import "./sections.scss";
import { SectionPopup } from "./SectionPopup";
import SectionItem from "./SectionItem";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  SectionItemData,
  SectionItemResponse,
  useDeleteSectionMutation,
  useListSectionsQuery,
  useSortSectionsMutation,
} from "@/services/formSetup/formSetupReducers";
import {
  resetConfirmation,
  setConfirmation,
  setIsVisible,
} from "@/features/confirmation/confirmation-slice";
import handleDragOrder from "@/libs/sort/handleDragOrder";

// make it draggable: https://codesandbox.io/p/sandbox/framer-motion-2-drag-to-reorder-fc4rt?file=%2Fsrc%2Fuse-measure-position.js%3A26%2C1
const Sections = ({ reset }: { reset: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { action, blockNavigation, status } = useSelector(
    (state: any) => state.confirmation
  );
  const formSetup = useSelector((state: any) => state.formSetup);
  const { sections: sectionData, sectionId } = formSetup;
  const { editId: formId } = useParams();
  const searchString = useLocation().search;
  const searchparams = useMemo(() => {
    return new URLSearchParams(searchString);
  }, [searchString]);
  const currentSectionId = sectionId || searchparams.get("sectionId");
  const {
    data: fetchedData,
    isLoading,
    refetch,
  } = useListSectionsQuery(formId, {
    skip: !formId,
  });
  const [deleteSection] = useDeleteSectionMutation();
  const [sortSections] = useSortSectionsMutation();
  const [rowData, setRowData] = useState<any>(sectionData);
  const [tempSection, setTempSection] = useState<any>(null);
  const [activePanels, setActivePanels] = useState<{ [key: string]: boolean }>({
    section: true,
    showPopup: false,
  });

  function handlePanelClick(panel: string, override?: boolean) {
    setActivePanels({
      ...activePanels,
      [panel]: override ? override : !activePanels[panel],
    });
  }

  function handleAddSection(show: boolean) {
    setActivePanels({
      ...activePanels,
      showPopup: show,
    });
  }

  function handleDrag(params: any) {
    const targetIndex = params.overIndex;
    const currentSectionId = params.node.data.id;
    const currentSectionIndex = sectionData.findIndex((section: any) => {
      return section.id === currentSectionId;
    });
    const sections = handleDragOrder(
      sectionData,
      currentSectionIndex,
      targetIndex
    );
    const sortedSectionRequest = sections.map((section: any, index: number) => {
      return {
        sectionDefinitionId: section.id,
        sortOrder: index,
      };
    });
    params.api.setFocusedCell(targetIndex || 0, "name");
    sortSections(sortedSectionRequest)
      .unwrap()
      .then((response) => {
        if (response) {
          dispatch(
            setNotification({
              type: "success",
              notifications: {
                message: t(
                  "pages.portalConfig.formSetup.messages.sections.sort.success"
                ),
              },
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        refetch();
      });
  }

  function handleSectionFocus(event: any) {
    logger.info("auto focus");
    if (rowData?.length > 0) {
      const currentSectionIndex = rowData.findIndex((section: any) => {
        return section.isSelected || section.id === currentSectionId;
      });
      logger.info("currentSectionIndex", currentSectionIndex);
      event.api.setFocusedCell(currentSectionIndex || 0, "name");
    } else {
      event.api.setFocusedCell(0, "name");
    }
  }

  function handleSelectChange(event: any) {
    if (
      blockNavigation &&
      action === "SectionItem.edit" &&
      status !== "approved"
    ) {
      const prevSelectedIndex = sectionData.findIndex((section: any) => {
        return section.id === currentSectionId;
      });
      logger.info("prevSelectedIndex", prevSelectedIndex);
      event.api.setFocusedCell(prevSelectedIndex || 0, "name");
    }
    if (!blockNavigation) {
      const selectedSectionId = event.data.id;
      navigate(`?activeTab=1&sectionId=${selectedSectionId}`);
      dispatch(updateForm({ key: "sectionId", value: selectedSectionId }));
    }
  }

  function handleDelete(sectionData: SectionItemData) {
    // dispatch(setIsVisible(true));
    dispatch(
      setConfirmation({
        isVisible: true,
        blockNavigation: false,
        action: "SectionItem.delete",
        heading: "Delete Section",
        message: `Are you sure you want to delete ${sectionData.name}?`,
      })
    );
    setTempSection(sectionData);
  }

  const doDeleteSection = useCallback(async () => {
    try {
      await deleteSection(tempSection.id).unwrap();
      dispatch(
        setNotification({
          type: "success",
          notifications: {
            message: t(
              "pages.portalConfig.formSetup.messages.sections.delete.success"
            ),
          },
        })
      );
      dispatch(setIsVisible(false));
    } catch (error) {
      dispatch(
        setNotification({
          type: "error",
          notifications: {
            message: t(
              "pages.portalConfig.formSetup.messages.sections.delete.error"
            ),
          },
        })
      );
    } finally {
      setTempSection(null);
      refetch();
    }
  }, [deleteSection, t, tempSection, dispatch, refetch]);

  // perform delete if action is approved
  useEffect(() => {
    if (
      action === "SectionItem.delete" &&
      status === "approved" &&
      tempSection?.id &&
      sectionData?.find((s: any) => s.id === tempSection?.id)
    ) {
      doDeleteSection();
    }

    if (
      action === "SectionItem.edit" &&
      status === "approved" &&
      tempSection?.id
    ) {
      const updateRowSelected = rowData.map((row: any) => {
        row.isSelected = row.id === tempSection?.id;
        return row;
      });
      setRowData(updateRowSelected);
      dispatch(updateForm({ key: "sectionId", value: tempSection?.id }));
      dispatch(resetConfirmation());
      navigate(`?activeTab=1&sectionId=${tempSection?.id}`);
      reset();
    }

    if (status === "rejected") {
      dispatch(setIsVisible(false));
    }
  }, [
    status,
    action,
    tempSection,
    doDeleteSection,
    dispatch,
    navigate,
    rowData,
    sectionData,
    reset,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetConfirmation());
    };
  }, [dispatch]);

  useEffect(() => {
    if (sectionData?.length > 0) {
      setRowData(
        sectionData.map((section: any) => ({
          ...section,
          isEditing: section.sectionId === currentSectionId,
        }))
      );
    }
  }, [sectionData, currentSectionId]);

  useEffect(() => {
    if (fetchedData && fetchedData?.length > 0) {
      logger.info("Fetched sections", fetchedData);
      const sectionState: SectionItemData[] = fetchedData.map(
        (section: SectionItemResponse) => ({
          id: section.id,
          name: section.name,
          nameFrench: section.nameFrench,
          description: section.description,
          columns: section.columns,
          mapping: section.sectionMapping,
          sortOrder: section.sortOrder,
        })
      );
      dispatch(
        updateForm({
          key: "sections",
          value: sectionState,
        })
      );
    }
  }, [fetchedData, dispatch]);

  // auto select first section
  useEffect(() => {
    if (sectionData?.length > 0 && !currentSectionId) {
      const firstSectionId = sectionData[0].id;
      navigate(`?activeTab=1&sectionId=${firstSectionId}`);
      dispatch(updateForm({ key: "sectionId", value: firstSectionId }));
    }
  }, [sectionData, navigate, currentSectionId, dispatch, formId]);

  return (
    <div className="sections">
      <SectionPopup
        show={activePanels["showPopup"]}
        setShow={handleAddSection}
      />
      <MotifAccordion
        useChevronIcon={true}
        alignIconRight={true}
        open={activePanels["section"]}
        onOpen={() => {}}
        onClose={() => {}}
        className="mar-h-0 pad-h-0"
      >
        <MotifAccordionTrigger
          triggerButtonProps={{
            title: "Toggle Section Panel",
            "aria-label": "Toggle Section Panel",
            onClick: () => handlePanelClick("section"),
          }}
        >
          <span className="motif-body1-default-light">
            {t("pages.portalConfig.formSetup.sections.heading")}
          </span>
        </MotifAccordionTrigger>
        <MotifAccordionContent>
          <div className="panel">
            <div className="panel-header">
              <h3>Section List</h3>
              <button onClick={() => handleAddSection(true)}>
                <MotifIcon src={IconoirPlusCircle} />
              </button>
            </div>

            <div className="panel-body">
              {isLoading && <MotifProgressLoader />}
              {rowData?.length <= 0 ? (
                <div className="empty">Please add a section</div>
              ) : (
                <MotifTable
                  // @ts-ignore
                  onGridReady={handleSectionFocus}
                  onRowDataUpdated={handleSectionFocus}
                  onCellClicked={handleSelectChange}
                  rowDragManaged={true}
                  rowDragEntireRow={false}
                  onRowDragEnd={handleDrag}
                  columnDefs={[
                    {
                      headerName: "name",
                      field: "name",
                      rowDrag: true,
                      flex: 2,
                      cellRenderer: SectionItem,
                      cellRendererParams: {
                        setTempSection,
                        currentSectionId,
                        handleDelete,
                      },
                    },
                  ]}
                  rowHeight={43}
                  rowData={rowData}
                />
              )}
            </div>
          </div>
        </MotifAccordionContent>
      </MotifAccordion>
    </div>
  );
};

export default Sections;
