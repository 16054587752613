import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import ClaimDetailsHeader from "./components/ClaimDetailsHeader";
import ClaimDetailsContent from "./ClaimDetailsContent";
import { useLocation, useParams } from "react-router-dom";
import { isEmpty } from "@/utility/common";
import ClaimItemCreate from "./ClaimItemCreate";
import { useSearchParams } from "react-router-dom";
import {
  useGetclaimStageAndDocumentApiDataQuery,
  useGetClaimStatusApiDataQuery,
} from "@/services/claimSummary/caseSummaryReducer";
import { TabComponent } from "./components/tabComponent";
import { useGetClaimItemDetailsQuery } from "@/services/claimDetail/claimDetailReducer";
import { useTranslation } from "react-i18next";

export default function ClaimDetails() {
  const {t}=useTranslation();
  const { claimItemId, caseId } = useParams();
  const [searchParams] = useSearchParams();
  let claimId = searchParams.get("claimId");
  let creditorId=searchParams.get("creditorId");
  const creditorName = searchParams.get("creditorName");
  const updatedOn = searchParams.get("updatedOn");
  const { data: claimStageData } =
    useGetclaimStageAndDocumentApiDataQuery(claimId);
  const { data: getClaimStatus } = useGetClaimStatusApiDataQuery(
    claimStageData?.definitionIds.claimStatusDefinitionId,
    { skip: !claimStageData }
  );
  const {
    data: claimItem
  } = useGetClaimItemDetailsQuery({ claimItemId: claimItemId, caseId: caseId });
  let location = useLocation();
  let state = location.state;
  const headerparam = {
    claimId: "-",
    creditorName: "-",
    updatedOn: "-",
    claimItemId: "-",
    claimReference: "-",
    claimRefId:"-",
    creditorId:"",
  };
  if (!isEmpty(claimItemId)) {
    headerparam.claimId = claimId || "";
    headerparam.creditorName = creditorName || "";
    headerparam.updatedOn = updatedOn || "";
    headerparam.claimItemId = claimItemId || "";
    headerparam.claimReference = claimItem?.claimItemReference || "";
    headerparam.claimRefId = searchParams.get("claimReference") || "";
    headerparam.creditorId=creditorId?creditorId:""

  } else {
    claimId = state?.claimId;
  }
  const renderComponent = !isEmpty(claimItemId) ? (
    <ClaimDetailsContent
      claimItemId={claimItemId || ""}
      claimId={claimId === null ? "" : claimId}
      getClaimItemstatus={getClaimStatus?.isResolveStatus ? true : false}
      claimStagePosition={claimStageData?.instanceData?.position}
    />
  ) : (
    <ClaimItemCreate claimId={claimId === null ? "" : claimId} />
  );
  const breadcrumbItems = [
    {
      text: t("global.globalActions.back"),
      url: `/case/${caseId}/claim/${claimId}`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];

  const tabPanelData = {
    title: [t("pages.claims.claimItemTabPannel.summary"), t("pages.claims.claimItemTabPannel.classification")],
    content: [<>{renderComponent}</>, <div>{t("pages.claims.claimItemTabPannel.classification")}</div>],
  };
  return (
    <>
      <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />

      <ClaimDetailsHeader create={true} headerparam={headerparam}/>
      <div className="ccwp_table_wrapper ccwp_table_dropdown_wrapper">
        {/* <TabPanel data={tabPanelData} /> */}
        <TabComponent
          tabPanelData={tabPanelData}
          renderComponent={renderComponent}
        />
      </div>
    </>
  );
}
