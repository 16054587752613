import { ccwpApi } from "@/services";
export const PaymentStatusApi = ccwpApi.injectEndpoints({
    endpoints: (builder) => ({
      getPaymentStatusList: builder.query<any, any>({
        query: (args) => {
          return {
            url:`Payment/GetPaymentRecordsList`,
            method:"GET",
            params:{...args}
          };
        },
      }),
      updatePaymentStatus: builder.mutation({
        query: (statusData) => ({
          url: "Payment/UpdatePaymentStatus",
          method: "POST",
          body: statusData,
        }),
      }),
      getPaymentFilter: builder.query({
        query: ({
          CaseId,
          PayeeName,
          AccountName,
          Prefix,
          PaymentReferenceStartWith,
          PaymentReferenceEndWith,
          StartDate,
          EndDate,
          LowerBound,
          upperBound,
          PostedDateStart,
          PostedDateEnd,
          PaymentStatus,
          PageNumber,
          PageSize,
          SearchText,
        }) => ({
          url: "Payment/GetPaymentFilterList",
          method: "GET",
          params: {
            CaseId,
            PayeeName,
            AccountName,
            Prefix,
            PaymentReferenceStartWith,
            PaymentReferenceEndWith,
            StartDate,
            EndDate,
            LowerBound,
            upperBound,
            PostedDateStart,
            PostedDateEnd,
            PaymentStatus,
            PageNumber,
            PageSize,
            SearchText,
          },
        }),
      }),
      deletePayment: builder.query({
        query: (id) => ({
          url: `Payment/DeletePayment?paymentId=${id}`,
          method: "DELETE",
        }),
      }),
      updateBulkPaymentStatus: builder.mutation({
        query: (bulkStatusData) => ({
          url: "Payment/BulkUpdatePaymentStatus",
          method: "POST",
          body: bulkStatusData,
        }),
      }),
      getPaymentSettlementList: builder.query<any, any>({
        query: (args) => {
          return {
            url:`Payment/SettlementListByPayment`,
            method:"GET",
            params:{...args}
          };
        },
      }),
    }),
});
export const { useGetPaymentStatusListQuery, useUpdatePaymentStatusMutation, useLazyGetPaymentFilterQuery,
  useLazyDeletePaymentQuery,useUpdateBulkPaymentStatusMutation,useGetPaymentSettlementListQuery } = PaymentStatusApi;