import { INDIVIDUAL, ORGANIZATION } from "@/pages/CaseDetail/Creditor/CreditorSetup/Constants";
import { MotifRadioButton, MotifRadioButtonGroup } from "@ey-xd/motif-react";
import { SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";

type MotifRadioButtonGroupProps = {
    name: string;
    required: boolean;
    defaultValue: string;
    options: string[];
    setCreditorType: (value: SetStateAction<string>) => void;
    creditorType: string;
}
export default function RadioButtonGroup ({name, required, defaultValue, options, creditorType, setCreditorType}: MotifRadioButtonGroupProps) {
  const {t} = useTranslation();

  useEffect(() => {
    if(defaultValue){
      setCreditorType(defaultValue);
    }    
  }, [defaultValue, setCreditorType]);
  
    return (
        <div>
            <MotifRadioButtonGroup
                name={name}
                required={required}
                defaultValue={defaultValue}
                options={options}
              >
                <MotifRadioButton
                  checked={creditorType === INDIVIDUAL}
                  onChange={() => setCreditorType(INDIVIDUAL)}
                  value={INDIVIDUAL}
                >
                  {t("pages.claims.claimCreate.creditorTab.formLabelsDetails.radiobuttonsLabels.individual")}
                </MotifRadioButton>
                <MotifRadioButton
                  checked={creditorType === ORGANIZATION}
                  onChange={() => setCreditorType(ORGANIZATION)}
                  value={ORGANIZATION}
                >
                  {t("pages.claims.claimCreate.creditorTab.formLabelsDetails.radiobuttonsLabels.organization")}
                  </MotifRadioButton>
              </MotifRadioButtonGroup>
        </div>
    );

}