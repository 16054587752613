import {
  MotifActionIcDashboard24px,
  // IconoirPage,
  IconoirFolder,
  MotifHardwareIcSecurity24px,
  // MotifActionIcHelp24px,
  // MotifSocialIcPerson24px,
  // MotifActionIcSettings24px,
} from "@ey-xd/motif-icon";

import { MotifIcon } from "@ey-xd/motif-react";

export interface MenuDataItem {
  label: string;
  path: string;
  icon?: JSX.Element;
  selected?: boolean;
  subMenus?: MenuDataItem[];
}

const menuData = (location: any): MenuDataItem[] => {
  const menu = [
    {
      label: "pages.navBar.moduleDetails.homeLabel",
      path: "/",
      icon: <MotifIcon src={MotifActionIcDashboard24px} />,
      selected: true,
    },
    {
      label: "pages.navBar.moduleDetails.userManagementLabel",
      path: "/user-management",
      icon: <MotifIcon src={MotifHardwareIcSecurity24px} />,
    },
    // {
    //   label: "My Profile",
    //   path: "/my-profile",
    //   icon: <MotifIcon src={MotifSocialIcPerson24px} />,
    // },
    // {
    //   label: "System Settings",
    //   path: "/system-settings",
    //   icon: <MotifIcon src={MotifActionIcSettings24px} />,
    // },
    // {
    //   label: "Help & Support",
    //   path: "/help-support",
    //   icon: <MotifIcon src={MotifActionIcHelp24px} />,
    // },
  ];

  if (
    (location && location.pathname.includes("/case/")) ||
    (location && location.pathname.includes("/portal-config/"))
  ) {
    const caseId = location.pathname.split("/")[2];
    menu.splice(1, 0, {
      label: "pages.navBar.moduleDetails.caseLabel",
      path: `/case/${caseId}`,
      icon: <MotifIcon src={IconoirFolder} />,
      // @ts-ignore
      subMenus: [
        {
          label: "pages.navBar.moduleDetails.caseSubModuleDetails.summaryLabel",
          path: `/case/${caseId}`,
        },
        {
          label: "pages.navBar.moduleDetails.caseSubModuleDetails.debtorLabel",
          path: `/case/${caseId}/?activeTab=1`,
        },
        {
          label:
            "pages.navBar.moduleDetails.caseSubModuleDetails.creditorLabel",
          path: `/case/${caseId}/?activeTab=2`,
        },
        {
          label: "pages.navBar.moduleDetails.caseSubModuleDetails.claimLabel",
          path: `/case/${caseId}/?activeTab=3`,
        },
        {
          label:
            "pages.navBar.moduleDetails.caseSubModuleDetails.configurationLabel",
          path: `/case/${caseId}/?activeTab=4`,
        },
        {
          label:
            "pages.navBar.moduleDetails.caseSubModuleDetails.distributionLabel",
          path: `/case/${caseId}/?activeTab=5`,
        },
        {
          label: "pages.navBar.moduleDetails.caseSubModuleDetails.votingLabel",
          path: `/case/${caseId}/?activeTab=7`,
        },
        {
          label: "pages.navBar.moduleDetails.caseSubModuleDetails.importLabel",
          path: `/case/${caseId}/?activeTab=7`,
        },
        {
          label: "pages.navBar.moduleDetails.caseSubModuleDetails.portalLabel",
          path: `/case/${caseId}/?activeTab=8`,
        },
        {
          label: "pages.navBar.moduleDetails.caseSubModuleDetails.paymentLabel",
          path: `/case/${caseId}/?activeTab=9`,
        },
      ],
    });
    // menu.splice(2, 0, {
    //   label: "Portal Config",
    //   path: `/case/${caseId}?activeTab=8`,
    //   icon: <MotifIcon src={IconoirPage} />,
    //   // @ts-ignore
    //   subMenus: [
    //     {
    //       label: "Form Management",
    //       path: `/portal-config/${caseId}/form/formId123`,
    //     },
    //     {
    //       label: "Portal Management",
    //       path: `/case/${caseId}?activeTab=8`,
    //     },
    //   ],
    // });
  }
  return menu;
};

export default menuData;
