import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MotifButton } from "@ey-xd/motif-react";
import Table from "@/components/Table/table";
import { getClassColumnDefs } from "./ClassColumnDefination";
import { useCustomCellRenderers } from "./ClassColumnActions";
import { ClassType, EmptyClass } from "../type";
import CreateEditClass from "./CreateEditClass";
import DeleteClass from "./DeleteClass";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { useUpdateClassMutation } from "@/services/class/classReducer";

const Class = ({
	schemeVersionId,
	initialClassData,
	classRefetch,
	isDraft,
}: {
	schemeVersionId: string;
	initialClassData: any;
	classRefetch: () => void;
	isDraft: boolean;
}) => {
	const { t } = useTranslation();
	const [classRowData, setClassRowData] = useState(
		[...initialClassData].sort((a, b) => a.displayOrder - b.displayOrder)
	);
	const [showCreateClassPopup, setShowCreateClassPopup] = useState(false);
	const [showEditClassPopup, setShowEditClassPopup] = useState(false);
	const [showDeleteClassPopup, setShowDeleteClassPopup] = useState(false);
	const [editClassData, setEditClassData] = useState<ClassType | null>(null);
	const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
	const [updateClass] = useUpdateClassMutation();

	useEffect(() => {
		setClassRowData(
			[...initialClassData].sort((a, b) => a.displayOrder - b.displayOrder)
		);
	}, [initialClassData]);

	const handleShiftRow = (index: number, direction: "up" | "down") => {
		setClassRowData((prevClassData: any[]) => {
			const newClassData = [...prevClassData];
			if (direction === "up" && index > 0) {
				newClassData.splice(index - 1, 0, newClassData.splice(index, 1)[0]);
				const upperRow = { ...newClassData[index - 1], displayOrder: index };
				const lowerRow = { ...newClassData[index], displayOrder: index + 1 };
				newClassData[index - 1] = upperRow;
				newClassData[index] = lowerRow;
				updateClassSortOrder([upperRow, lowerRow]);
			} else if (direction === "down" && index < newClassData.length - 1) {
				newClassData.splice(index + 1, 0, newClassData.splice(index, 1)[0]);
				const upperRow = { ...newClassData[index], displayOrder: index + 1 };
				const lowerRow = {
					...newClassData[index + 1],
					displayOrder: index + 2,
				};
				newClassData[index] = upperRow;
				newClassData[index + 1] = lowerRow;
				updateClassSortOrder([upperRow, lowerRow]);
			}
			return newClassData;
		});
	};
	const updateClassSortOrder = (updatedRows: any) => {
		updatedRows.map(async (eachRow: any) => {
			const requestBody = {
				...eachRow,
			};
			delete requestBody.schemeVersionId;
			delete requestBody.ruleCount;
			await updateClass(requestBody);
		});
	};

	const caseRenderers = useCustomCellRenderers({
		setShowEditClassPopup: setShowEditClassPopup,
		setEditClassData: setEditClassData,
		setShowDeleteClassPopup: setShowDeleteClassPopup,
		handleShiftRow: handleShiftRow,
	});
	return (
		<>
			<div className="mar-t-20">
				{deleteErrorMessage && (
					<NotificationGrid
						show={true}
						message={deleteErrorMessage}
						type="error"
					/>
				)}
				<Table
					columnDefs={getClassColumnDefs(isDraft)}
					cellRenderers={classRowData}
					TitleComplement={() => (
						<MotifButton
							variant="secondary"
							type="button"
							style={{ display: isDraft ? "inline-block" : "none" }}
							onClick={() => setShowCreateClassPopup(true)}
						>
							{t("pages.configuration.scheme.newClassButton")}
						</MotifButton>
					)}
					title={t("pages.configuration.scheme.classes")}
					description={t("pages.configuration.scheme.detailDescription")}
					caseRenderers={caseRenderers}
					pagination={false}
					overlayNoRowsTemplate={t("pages.configuration.scheme.noRecord")}
				/>
			</div>
			{showCreateClassPopup && (
				<CreateEditClass
					show={true}
					setShow={setShowCreateClassPopup}
					classData={{ ...EmptyClass, schemeVersionId: schemeVersionId }}
					isEdit={false}
					refetch={classRefetch}
					classRowData={classRowData}
				/>
			)}
			{showEditClassPopup && (
				<CreateEditClass
					show={true}
					setShow={setShowEditClassPopup}
					classData={{ ...editClassData }}
					isEdit={true}
					refetch={classRefetch}
					classRowData={classRowData}
				/>
			)}
			{showDeleteClassPopup && (
				<DeleteClass
					show={true}
					setShow={setShowDeleteClassPopup}
					id={editClassData?.id}
					setDeleteErrorMessage={setDeleteErrorMessage}
					refetch={classRefetch}
				/>
			)}
		</>
	);
};
export default Class;
