import { ccwpApi } from "@/services";

export const creditorContactUpdateApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    updateCreditorContact: builder.mutation({
      query: (creditorContactData) => ({
        url: "CreditorContact/UpdateCreditorContact",
        method: "PUT",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(creditorContactData), // Explicitly serialize the body
      }),
    }),
  }),
});

export const { useUpdateCreditorContactMutation } = creditorContactUpdateApi;
