import { useNavigate } from "react-router-dom";
import { MotifButton, MotifIcon } from "@ey-xd/motif-react";
import {
	IconoirEditPencil,
	IconoirTrash,
	IconoirArrowUp,
	IconoirArrowDown,
} from "@ey-xd/motif-icon";

export const useCustomCellRenderers = ({
	setShowEditClassPopup,
	setEditClassData,
	setShowDeleteClassPopup,
	handleShiftRow,
}: {
	setShowEditClassPopup: (value: boolean) => void;
	setEditClassData: (data: any) => void;
	setShowDeleteClassPopup: (value: boolean) => void;
	handleShiftRow: (rowIndex: number, direction: "up" | "down") => void;
}) => {
	const navigate = useNavigate();
	const caseRenderers = {
		ruleLinkCellRenderers: function (param: any) {
			return (
				<MotifButton
					onClick={() => {
						const { ruleCount, ...classData } = param.data;
						navigate(`./create-rule`, {
							state: { classData },
						});
					}}
					size="small"
					type="button"
					variant="text-alt"
				>
					Add new rule
				</MotifButton>
			);
		},
		actionCellRenderers: function (param: any) {
			return (
				<div className="motif-row">
					<div className="motif-col-lg-6">
						<MotifButton
							size="small"
							variant="primary"
							type="button"
							onClick={() => {
								const { ruleCount, ...remainingData } = param.data;
								setEditClassData(remainingData);
								setShowDeleteClassPopup(true);
							}}
						>
							<MotifIcon
								iconFunction={IconoirTrash}
								stroke="blue"
								strokeWidth="2"
								size="24"
							/>
						</MotifButton>
					</div>
					<div className="motif-col-lg-6">
						<MotifButton
							onClick={() => {
								const { ruleCount, ...remainingData } = param.data;
								setEditClassData(remainingData);
								setShowEditClassPopup(true);
							}}
							size="small"
							type="button"
							variant="primary"
						>
							<MotifIcon
								iconFunction={IconoirEditPencil}
								stroke="blue"
								strokeWidth="2"
								size="24"
							/>
						</MotifButton>
					</div>
				</div>
			);
		},
		sortingCellRenderers: function (param: any) {
			const { isDraft } = param;
			return (
				<div className="motif-row">
					<div className="motif-col-lg-6">
						<MotifButton
							size="small"
							variant="secondary"
							disabled={!isDraft}
							onClick={() => handleShiftRow(param.rowIndex, "up")}
						>
							<MotifIcon iconFunction={IconoirArrowUp} size="24" />
						</MotifButton>
					</div>
					<div className="motif-col-lg-6">
						<MotifButton
							onClick={() => handleShiftRow(param.rowIndex, "down")}
							size="small"
							type="button"
							variant="secondary"
							disabled={!isDraft}
						>
							<MotifIcon iconFunction={IconoirArrowDown} size="24" />
						</MotifButton>
					</div>
				</div>
			);
		},
	};
	return caseRenderers;
};
