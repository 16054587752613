import ControlInputBox from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import { useState } from "react";
import {
  DISTRIBUTION_DESCRIPTION_ID,
  DISTRIBUTION_DESCRIPTION_ID_FRENCH,
  DISTRIBUTION_ID,
  DISTRIBUTION_ID_FRENCH,
  DISTRIBUTION_NAME,
  DISTRIBUTION_NAME_FRENCH,
  DISTRIBUTION_DESCRIPTION_LABEL,
  DISTRIBUTION_DESCRIPTION_LABEL_FRENCH
} from "../constants/Constants";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import Button from "@/components/Form/Button/Button";
import { CANCEL, SAVE, SECONDARY } from "@/Constant";
import { useTranslation } from "react-i18next";
import { MotifErrorMessage, MotifIcon } from "@ey-xd/motif-react";
import { DistributionSetupFormType } from "../constants/type";
import { useForm } from "react-hook-form";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import { useParams } from "react-router-dom";

const DuplicateDistribution = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<DistributionSetupFormType>();
  const onSubmit = async (data: DistributionSetupFormType) => {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const { caseId } = useParams<{ caseId: string }>();

  const breadcrumbItems = [
    {
      label: "External Submission",
      value: "10 New Creditor Submission",
      elementKey: "submission",
      text: "Back",
      url: `/case/${caseId}/?activeTab=6`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];
  return (
    <div>
      <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
      <h1>Distribution Setup</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-row pad-v-40">
          <div className="motif-col-md-3 ">
            <div className="form-field ">
              <ControlInputBox
                name={DISTRIBUTION_ID}
                control={control}
                required={true}
                isTextArea={false}
                label={DISTRIBUTION_NAME}
                showLabel={true}
              />
              {errors[DISTRIBUTION_ID] &&
                errors[DISTRIBUTION_ID].type === "required" && (
                  <MotifErrorMessage>
                    Disrtribution Name is required
                  </MotifErrorMessage>
                )}
            </div>
          </div>
          <div className="motif-col-md-3">
            <div className="form-field">
              <ControlInputBox
                name={DISTRIBUTION_ID_FRENCH}
                control={control}
                isTextArea={false}
                label={DISTRIBUTION_NAME_FRENCH}
                showLabel={true}
              />
            </div>
          </div>
        </div>

        <div className="motif-row next-row">
          <div className="motif-col-md-3">
            <div className="form-field">
              <ControlTextAreaWithCharLimit
                name={DISTRIBUTION_DESCRIPTION_ID}
                control={control}
                isTextArea={true}
                label={DISTRIBUTION_DESCRIPTION_LABEL}
                showLabel={true}
              />
            </div>
          </div>
          <div className="motif-col-md-3">
            <div className="form-field ">
              <ControlTextAreaWithCharLimit
                name={DISTRIBUTION_DESCRIPTION_ID_FRENCH}
                control={control}
                isTextArea={true}
                label={DISTRIBUTION_DESCRIPTION_LABEL_FRENCH}
                showLabel={true}
              />
            </div>
          </div>
        </div>
        <div className="button-container">
          <Button
            label={t(SAVE)}
            type={isLoading ? "loader" : "submit"}
            disabled={isLoading}
          />
          <Button
            type="button"
            label={t(CANCEL)}
            variant={SECONDARY}
            className="cancelBtn"
          />
        </div>
      </form>
    </div>
  );
};

export default DuplicateDistribution;
