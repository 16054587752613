import {
  Control,
  Controller,
  useController,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import SelectBox from "@/components/Form/SelectBox/SelectBox";
import { ClaimSetupDescription } from "./type";

type ControlSelectType = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<ClaimSetupDescription>;
  name: string;
  showLabel: boolean;
  label: string;
  readonly: boolean;
  options: {
    label: string;
    value: string;
  }[];
  setValue: UseFormSetValue<ClaimSetupDescription>;
  getValues: UseFormGetValues<ClaimSetupDescription>;
  disabled:boolean
  onChange:any;
};

export default function ControlSelect({
  name,
  control,
  required = false,
  options,
  showLabel,
  label,
  readonly,
  disabled,
  onChange
}: ControlSelectType) {
  const { field } = useController({
    // @ts-ignore
    name,
    control,
    rules: { required: required },
    readonly,
    onChange
  });
  const { t } = useTranslation();
  const translatedOptions = options.map((option) => {
    return {
      label: option.label,
      value: option.value,
    };
  });
  return (
    <>
      {showLabel && (
        <div className="formFieldLabel">
          <label htmlFor="input" aria-label={label}>
            {t(label)}
            {required ? <span className="requiredSymbol">*</span> : ""}
          </label>
        </div>
      )}
      <Controller
        name={field.name}
        control={control}
        rules={{ required }}
        defaultValue={options[0].value}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <SelectBox
                options={translatedOptions}
                value={value}
                onChange={onChange}
                disabled={disabled}
              />
            </>
          );
        }}
      />
    </>
  );
}
