import { useState, useEffect } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	MotifIcon,
	MotifInput,
	MotifLabel,
	MotifFormField,
	MotifButton,
	MotifErrorMessage,
	MotifProgressLoader,
} from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Tree from "@/components/Tree/Tree";
import { useGetClaimListAttributeQuery } from "@/services/advanceSearch/advanceSearchReducer";
import {
	useGetRulesListQuery,
	useCreateRuleMutation,
} from "@/services/rules/rulesReducer";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { processTreeRows } from "@/utility/common";
import "./Rules.scss";

const RulesSetup = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { caseId, schemeId } = useParams();
	const location = useLocation();
	const { classData } = location.state;
	const [attributes, setAttributes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isDiscardVisible, setIsDiscardVisible] = useState(false);

	const { data: rulesTableData } = useGetRulesListQuery(
		classData.schemeVersionId || ""
	);
	const {
		data: attributesData,
		isLoading: isAttributesLoading,
		error: attributesError,
	} = useGetClaimListAttributeQuery(caseId, { skip: !caseId });
	const [createRule] = useCreateRuleMutation();
	const breadcrumbItems = [
		{
			label: "Back",
			elementKey: "Back",
			text: "Back",
			url: `/case/${caseId}/scheme-manage/${schemeId}`,
			icon: <MotifIcon src={IconoirArrowLeft} />,
		},
	];
	const methods = useForm({
		defaultValues: {
			ruleName: "",
			description: "",
			sortOrder: 0,
			logicalOperator: "0",
			treeRows: [{ attribute: "", operator: "", value: "", type: "row" }],
		},
	});
	const {
		handleSubmit,
		formState: { errors },
	} = methods;

	const errorList = Object.keys(errors);

	useEffect(() => {
		if (attributesData) {
			setAttributes(attributesData);
		}
	}, [attributesData]);
	if (attributesError) {
		return <MotifErrorMessage>Data could not be loaded</MotifErrorMessage>;
	}
	const onSubmit = async (data: any) => {
		const { rules, groups } = processTreeRows(data.treeRows, caseId);
		const payload = {
			outputClassId: classData.id,
			CaseId: caseId,
			name: data.ruleName,
			description: data.description,
			sortOrder: rulesTableData.length + 1,
			pageNumber: 1,
			pageSize: 100,
			searchRuleGroup: {
				rules,
				groups: groups.length > 0 ? groups : null,
				logicalOperator: parseInt(data.logicalOperator),
				CaseId: caseId,
			},
		};
		setErrorMessage("");
		setIsLoading(true);
		await createRule(payload).then((response: any) => {
			if (response.data) {
				handleClose();
			} else {
				if (response.error.data?.title) {
					setErrorMessage(response.error.data.title);
				} else {
					setErrorMessage(response.error.data.message);
				}
			}
			setIsLoading(false);
		});
	};

	const handleClose = () => {
		setIsDiscardVisible(false);
		navigate(`/case/${caseId}/scheme-manage/${schemeId}`);
	};
	return (
		<>
			<div>
				<Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
			</div>
			<div className="motif-body1-default-regular page-title">
				{t("pages.configuration.scheme.rule.ruleConfiguration")}
			</div>
			<div className="rules-setup-container">
				<div className="tree-div">
					{isAttributesLoading ? (
						<div style={{ minHeight: "300px" }}>
							<MotifProgressLoader show variant="default">
								Loading rule configuration...
							</MotifProgressLoader>
						</div>
					) : (
						<FormProvider {...methods}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="motif-row">
									<div className="motif-col-md-2 motif-col-lg-4">
										<MotifLabel aria-label="Input" htmlFor="ruleName">
											{t("pages.configuration.scheme.rule.ruleName")}
											<span className="required-input">*</span>
										</MotifLabel>
										<MotifFormField aria-label="Input" role="group">
											<Controller
												name="ruleName"
												control={methods.control}
												rules={{ required: true }}
												render={({ field }) => <MotifInput {...field} />}
											/>
										</MotifFormField>
									</div>
									<div className="motif-col-md-2 motif-col-lg-4">
										<MotifLabel aria-label="Input" htmlFor="description">
											{t("pages.configuration.scheme.rule.description")}
										</MotifLabel>
										<MotifFormField aria-label="Input" role="group">
											<Controller
												name="description"
												control={methods.control}
												render={({ field }) => <MotifInput {...field} />}
											/>
										</MotifFormField>
									</div>
								</div>
								<div>
									<div className="rule-tree-header-section">
										<div className="motif-row justify-content-between align-items-center">
											<div className="motif-col-4">
												<div className="motif-body1-default-regular rule-title">
													{t("pages.configuration.scheme.rule.ruleLogic")}
												</div>
												<div className="motif-body2-default-regular rule-description">
													{t(
														"pages.configuration.scheme.rule.ruleLogicDescription"
													)}
												</div>
											</div>
										</div>
									</div>
									{isAttributesLoading ? (
										<div style={{ minHeight: "300px" }}>
											<MotifProgressLoader show variant="default">
												Loading rule configuration...
											</MotifProgressLoader>
										</div>
									) : (
										<div className="rule-tree-details">
											<Tree attributes={attributes} />
										</div>
									)}
								</div>
							</form>
						</FormProvider>
					)}
					{errorMessage && (
						<div className="mar-t-20">
							<NotificationGrid
								show={true}
								message={errorMessage}
								type={"error"}
							/>
						</div>
					)}
					<div className="error-container">
						<MotifErrorMessage>
							{errorList.length
								? t("pages.configuration.scheme.rule.validationError")
								: ""}
						</MotifErrorMessage>
					</div>
				</div>
				<div className="button-div">
					<div className="rules-footer-buttons">
						<MotifButton
							variant="secondary"
							onClick={() => setIsDiscardVisible(true)}
						>
							{t("pages.configuration.scheme.rule.cancel")}
						</MotifButton>
						<MotifButton
							variant="primary"
							type={isLoading ? "loader" : "submit"}
							onClick={handleSubmit(onSubmit)}
							disabled={isLoading}
						>
							{t("pages.configuration.scheme.rule.save")}
						</MotifButton>
					</div>
				</div>
			</div>
			<ConfirmationModal
				isVisible={isDiscardVisible}
				setIsVisible={setIsDiscardVisible}
				cancelBtnApproveHandler={handleClose}
				cancelBtnRejectHandler={() => setIsDiscardVisible(false)}
			/>
		</>
	);
};

export default RulesSetup;
