import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
} from "@ey-xd/motif-react";
import { useLazyDeleteDistributionQuery } from "@/services/distribution/distributionReducers";

interface DeleteDistributionProps {
  show: boolean;
  setShow: (value: boolean) => void;
  id: string | undefined;
  setDeleteErrorMessage: (message: string) => void;
  refetch: () => void;
}

const DeleteDistribution = ({
  show,
  setShow,
  id,
  setDeleteErrorMessage,
  refetch,
}: DeleteDistributionProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDistribution] = useLazyDeleteDistributionQuery();

  const handleCancel = () => {
    setShow(false);
    setDeleteErrorMessage("");
  };

  const handleDelete = () => {
    setIsLoading(true);
    setDeleteErrorMessage("");
    deleteDistribution(id).then((response: any) => {
      if (response.data) {
        refetch();
      } else {
        const errorMessage = response.error?.data?.message;
        setDeleteErrorMessage(errorMessage);
      }
      setIsLoading(false);
      setShow(false);
    });
  };
  return (
    <MotifModal
      onClose={handleCancel}
      show={show}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
    >
      <MotifModalHeader>{t("pages.distributions.distribution.deleteDistribution")}</MotifModalHeader>

      <MotifModalBody>
        {t("pages.distributions.distribution.warningMessage")}
      </MotifModalBody>

      <MotifModalFooter>
        <MotifButton onClick={handleCancel} type={"button"} variant="secondary">
          {t("global.globalActions.cancel")}
        </MotifButton>
        <MotifButton
          onClick={handleDelete}
          type={isLoading ? "loader" : "submit"}
          variant="primary"
          disabled={isLoading}
        >
    {t("global.globalActions.confirm")}        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
};
export default DeleteDistribution;
