import { GET_DISTRIBUTION_SUMMARY_BY_ID, LISTOFSTEPCALCULATION } from "@/services/constant/apiPath";
import { ccwpApi } from "@/services";

export const DisributionByCaseIdApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getDistributionByCaseID: builder.query<any, any>({
      query: (arg) => ({url:"Distribution/GetDistributionList",method:"GET",params:{...arg}}),
    }),
    getDistributionSummaryById: builder.query<any, any>({
      query: (arg) => GET_DISTRIBUTION_SUMMARY_BY_ID + arg.id,
    }),
    listCalculationSteps: builder.query<any, any>({
      query: (arg) => ({url:"CalculationStep/ListCalculationSteps",method:"GET",params:{...arg}}),
    }),
    createDistribution: builder.mutation({
      query: (data) => ({
        url: `Distribution/CreateDistribution`,
        method: "POST",
        body: data,
      }),
    }),
    updateDistribution: builder.mutation({
      query: (data) => ({
        url: `Distribution/UpdateDistribution`,
        method: "PUT",
        body: data,
      }),
    }),

    deleteDistribution: builder.query({
      query: (id) => ({
        url: `Distribution/DeleteDistribution?DistributionId=${id}`,
        method: "DELETE",
      }),
    }),
    reCalculateEntitlement: builder.query({
      query: (distributionId) => ({
        url: `Distribution/ReCalculateEntitlement?distributionId=${distributionId}`,
        method: "GET",
      }),
    }),
    calculateSettlement: builder.query({
      query: (distributionId) => ({
        url: `Distribution/CalculateSettlement?distributionId=${distributionId}`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useGetDistributionByCaseIDQuery,
  useGetDistributionSummaryByIdQuery,
  useListCalculationStepsQuery,
  useCreateDistributionMutation,
  useUpdateDistributionMutation,
  useLazyDeleteDistributionQuery,
  useLazyReCalculateEntitlementQuery,
  useLazyCalculateSettlementQuery
} = DisributionByCaseIdApi;

export const configGetStepCalculationDataByIDApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getStepCalculationDataByIdApiData: builder.query<any, any>({
      query: (stepId) => LISTOFSTEPCALCULATION + stepId,
    }),
  }),
});
export const { useGetStepCalculationDataByIdApiDataQuery } =
configGetStepCalculationDataByIDApi;