import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ConfirmationState = {
  isVisible: boolean;
  blockNavigation: boolean;
  heading?: null | string;
  message?: null | string;
  status?: null | "approved" | "rejected";
  action?: null | string;
};

const confirmation = createSlice({
  name: "confirmation",
  initialState: {
    isVisible: false,
    blockNavigation: false,
    heading: null,
    message: null,
    status: null,
    action: null,
  },
  reducers: {
    setIsVisible: (
      state: ConfirmationState,
      action: PayloadAction<boolean>
    ) => {
      state.isVisible = action.payload;
    },
    setBlockNavigation: (
      state: ConfirmationState,
      action: PayloadAction<boolean>
    ) => {
      state.blockNavigation = action.payload;
    },
    setConfirmation: (
      state: ConfirmationState,
      action: PayloadAction<ConfirmationState>
    ) => {
      const {
        isVisible,
        blockNavigation,
        heading,
        message,
        action: actionName,
        status,
      } = action.payload;
      state.isVisible = isVisible;
      state.blockNavigation = blockNavigation;
      state.heading = heading;
      state.message = message;
      state.action = actionName;
      state.status = status;
    },
    setAction: (state: ConfirmationState, action: PayloadAction<string>) => {
      state.action = action.payload;
    },
    resetConfirmation: (state: ConfirmationState) => {
      state.isVisible = false;
      state.blockNavigation = false;
      state.action = null;
      state.heading = null;
      state.message = null;
      state.status = null;
    },
  },
});

export const {
  setConfirmation,
  resetConfirmation,
  setBlockNavigation,
  setIsVisible,
  setAction,
} = confirmation.actions;
export default confirmation.reducer;
