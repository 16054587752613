import { dateFunc } from "@/components/Functions/dateFunc";
import "./attributeHistory.scss";
import { useTranslation } from "react-i18next";
export const useColumnHistoryDefs = (timeZone: string) => {
  const {t}=useTranslation();
  return [
  {
    cellRenderer: "attributeName",
    field: "attributeName",
    headerName: t("pages.creditors.creditorAttributeTab.tableColumnsDetails.attributeName"),
    width: 150,
    sortable: true,
    unSortIcon: true,
  },
  {
    field: "createdDate",
    headerName: t("pages.creditors.creditorAttributeTab.tableColumnsDetails.updatedOn"),
    flex: 1,
    width: 150,
    cellRendererParams: {
			timeZoneName: timeZone,
		},
    cellRenderer: function (params: any) { 
      return dateFunc(params.data.createdDate).formattedDateTime + " " +params.timeZoneName;
    }
  },
  {
    field: "createdBy",
    headerName: t("pages.creditors.creditorAttributeTab.tableColumnsDetails.updatedBy"),
    flex: 1,
    width: 150,
  },

  {
    field: "oldValue",
    headerName: t("pages.creditors.creditorAttributeTab.tableColumnsDetails.oldValue"),
    flex: 1,
    width: 350,
    cellRenderer: function (params: any) {
      return <div className="text-wrap ">{params.data.oldValue || "-"}</div>;
    },
  },
  {
    field: "newValue",
    headerName: t("pages.creditors.creditorAttributeTab.tableColumnsDetails.newValue"),
    flex: 1,
    width: 350,
    cellRenderer: function (params: any) {
      return <div className="text-wrap ">{params.data.newValue || "-"}</div>;
    },
  },
]};

export const columnHistoryDefs = [
  {
    cellRenderer: "attributeName",
    field: "attributeName",
    headerName: "Attibute Name",
    width: 150,
    sortable: true,
    unSortIcon: true,
  },
  {
    field: "createdDate",
    headerName: "Updated On",
    flex: 1,
    width: 150,

    cellRenderer: function (params: any) {
      return dateFunc(params.data.createdDate).formattedDateTime ;
    }
  },
  {
    field: "createdBy",
    headerName: "Updated By",
    flex: 1,
    width: 150,
  },

  {
    field: "oldValue",
    headerName: "Old Value",
    flex: 1,
    width: 350,
    cellRenderer: function (params: any) {
      return <div className="text-wrap ">{params.data.oldValue || "-"}</div>;
    },
  },
  {
    field: "newValue",
    headerName: "New Value",
    flex: 1,
    width: 350,
    cellRenderer: function (params: any) {
      return <div className="text-wrap ">{params.data.newValue || "-"}</div>;
    },
  },
];
