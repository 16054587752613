import React from "react";
import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import {useTranslation} from 'react-i18next';
import InputBox from "@/components/Form/InputBox/InputBox";
import { CurrencyConfigurationType } from "./type";
import { DEFAULT_CURRENCY_ID } from "./Constants";

type ControlInputBoxType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<CurrencyConfigurationType>;
    name: CurrencyConfigurationType;
    showLabel: boolean;
    label: string;
}

export default function ControlInputBox ({name, control, required=false, isTextArea=false, showLabel, label}: ControlInputBoxType) {
    const {
        field,
      } = useController({
        // @ts-ignore
        name,
        control,
        rules: { required: required },
      });
    const {t} = useTranslation();

    return (
        <> 
            {
                showLabel && <div className="formFieldLabel">
                    <label  htmlFor="input" aria-label={label}>{t(label)}{ required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
           <Controller
                name={field.name}
                control={control}
                rules={{ required }}
                render={({ field: { onChange, value, ...args } }) => {
                          return (
                          <>
                          {/*@ts-ignore */}
                            <InputBox   id={DEFAULT_CURRENCY_ID} required={true} value={value || ""} onChange={onChange} isTextArea={isTextArea} {...args}/>
                          </>
                          );
                    }}
            />
        </>
        
    );
}
