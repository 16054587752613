import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  MotifIcon,
  MotifVerticalNavigation,
  MotifVerticalNavigationContent,
  MotifVerticalNavigationMenu,
  MotifVerticalNavigationMenuItem,
  MotifVerticalNavigationSubmenu,
} from "@/libs/@ey-xd/motif-react";
import { IconoirFastArrowLeft, IconoirFastArrowRight } from "@ey-xd/motif-icon";
import menuData, { MenuDataItem } from "@/menuData";
import "@/index.scss";
import "./navbar.scss";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface NavBarProps {
  collapse: boolean;
  toggleCollapse: () => void;
}

const NavBar: React.FC<NavBarProps> = React.memo(
  ({ collapse, toggleCollapse }) => {
    const location = useLocation();
    const params = useParams();
    const { caseId } = useParams();
    const [menus, setMenus] = useState<MenuDataItem[]>(menuData(location));
    const [selectedRoute, setSelectedRoute] = useState("/");
    const [isHomePage, setIsHomePage] = useState(false);
    const navigate = useNavigate();

    const { t } = useTranslation();
    // Initialize selectedRoute based on the current URL path
    // @ts-ignore
    useEffect(() => {
      const currentPath = location.pathname;
      setSelectedRoute(currentPath);
      setIsHomePage(currentPath === "/");
      setMenus(() => {
        const newMenu = menuData(location);
        return newMenu.map((menu) => ({
          ...menu,
          selected: menu.path.startsWith(currentPath),
          subMenus: menu.subMenus?.map((subMenu) => {
            return {
              ...subMenu,
              selected: currentPath === subMenu.path,
            };
          }),
        }));
      });
    }, [caseId, location]);

    const handleMenuClick = useCallback(
      (menuIndex: number, subMenuIndex: number | null = null) => {
        setMenus((prevMenus) =>
          prevMenus.map((menu, index) => {
            const isSelected = index === menuIndex && subMenuIndex === null;
            const isSubSelected = index === menuIndex && subMenuIndex !== null;
            return {
              ...menu,
              selected: isSelected,
              subMenus: menu.subMenus?.map((subMenu, subIndex) => ({
                ...subMenu,
                selected: isSubSelected && subIndex === subMenuIndex,
              })),
            };
          })
        );

        const path =
          subMenuIndex !== null
            ? menus[menuIndex]?.subMenus?.[subMenuIndex]?.path
            : menus[menuIndex]?.path;

        if (path) {
          setSelectedRoute(path);
          navigate(path);
        }
      },
      [menus, navigate]
    );
    const navBarMenus = useMemo(() => {
      const filteredMenus =
        !location.pathname.includes("/case/") &&
        !location.pathname.includes("/portal-config/")
          ? menus.filter(
              (menu) => t(menu.label, { lng: "en" }) !== "Case Detail"
            )
          : menus;
      return filteredMenus.map((menu) => ({
        ...menu,
        label: menu.label,
        subMenus: menu.subMenus?.map((subMenu) => ({
          ...subMenu,
          label: subMenu.label,
        })),
      }));
      // eslint-disable-next-line
    }, [menus, params, location, caseId, t, location, isHomePage]);
    return (
      <>
        <MotifVerticalNavigation
          className="sidebar motif-no-padding"
          collapse={collapse}
        >
          <div className="sidebar-nav-collapse-button">
            <button
              onClick={toggleCollapse}
              className="motif-button motif-button-primary"
            >
              {collapse ? (
                <MotifIcon src={IconoirFastArrowRight} />
              ) : (
                <>
                  <MotifIcon src={IconoirFastArrowLeft} />{" "}
                  <span className="motif-body2-underlined-regular">
                    {" "}
                    {t("pages.navBar.collapse")}
                  </span>
                </>
              )}
            </button>
          </div>
          <MotifVerticalNavigationContent className="sidebar-nav-content">
            <MotifVerticalNavigationMenu className="sidebar-nav-menu">
              {navBarMenus.map((route, menuIndex) => {
                if (route.subMenus && route.subMenus.length > 0) {
                  return (
                    <MotifVerticalNavigationSubmenu
                      className="sidebar-nav-menu-item sidebar-nav-submenu"
                      key={`route-0-${menuIndex}`}
                      icons={{ title: route.icon, expandToggle: null }}
                      selected={selectedRoute.startsWith(route.path)}
                      title={t(route.label)}
                    >
                      {route.subMenus.map((subRoute, subMenuIndex) => (
                        <MotifVerticalNavigationMenuItem
                          className="sidebar-nav-submenu-item"
                          key={`route-1-${subMenuIndex}`}
                          icon={subRoute.icon}
                          selected={selectedRoute === subRoute.path}
                          onClick={(event) =>
                            handleMenuClick(menuIndex, subMenuIndex)
                          }
                        >
                          {t(subRoute.label)}
                        </MotifVerticalNavigationMenuItem>
                      ))}
                    </MotifVerticalNavigationSubmenu>
                  );
                }
                return (
                  <MotifVerticalNavigationMenuItem
                    className="sidebar-nav-menu-item"
                    key={route.path}
                    icon={route.icon}
                    selected={location?.pathname === route.path}
                    onClick={(event) => handleMenuClick(menuIndex)}
                    label={route.label}
                  >
                    {t(route.label)}
                  </MotifVerticalNavigationMenuItem>
                );
              })}
            </MotifVerticalNavigationMenu>
          </MotifVerticalNavigationContent>
        </MotifVerticalNavigation>
      </>
    );
  }
);

export default NavBar;
