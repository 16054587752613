import { useSelector } from "react-redux";
import Table from "@/components/Table/table";
import TitleComplement from "@/features/debtor/claimsTab/components/titleComplement";
import { caseRenderers } from "@/features/debtor/claimsTab/Constant/debtorCustomCellConst";
import { useColumnDefs } from "@/features/debtor/claimsTab/Constant/debtorHeaderConst";
import { useGetDebtorClaimListApiDataQuery } from "@/services/cases/casesReducer";
import { MotifErrorMessage, MotifProgressLoader } from "@ey-xd/motif-react";
import BasicSearch from "../../claims/claimsTab/components/basicSearch";
import { useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import { useCase } from "@/features/case/use-case";
import { useTranslation } from "react-i18next";


export default function ClaimTabContent() {
  const {t}=useTranslation()
  const { caseId, debtorId, editId } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const paginationPageSize: number =customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
  let tableLoadingData=customTableLoadingData;
  const caseData = useCase();
  const abbreviation = caseData?.abbreviation;

  const [paginationObj, setPaginationObj] = useState({
    CaseID: caseId,
    DebtorId:editId,
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",
 
  });
  let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({
  })
  let resetSortStatus=useCallback(()=>{

    let json:any={...sortedColumnTypes}
    for (const key in json) {
                  json[key]={sort:null}
    }
    setSortedColumnTypes({...json})
  },[sortedColumnTypes])

  function handlePaginationData(data:any,columnTypesData:any){
    setPaginationObj({...data})
    setSortedColumnTypes({...columnTypesData})
  }
  const columnDefs = useColumnDefs({ caseId, debtorId, editId, abbreviation });

  const { data, error, isLoading,isFetching } = useGetDebtorClaimListApiDataQuery(
    paginationObj
  );

 
  const showBasicSearch = useSelector(
    (state: any) => state.claims.showBasicSearch
  );

  const handleSearch = useCallback(
    (searchValue: string) => {
      setSearchQuery(searchValue);
      if (searchValue.trim() === "") {
        setPaginationObj({
          CaseID: caseId,
          DebtorId:editId,
          PageNumber: 1,
          PageSize: paginationPageSize,
          SearchText: ""
        })
        resetSortStatus()  
          } else {
        setPaginationObj({
          CaseID: caseId,
          DebtorId:editId,
          PageNumber: 1,
          PageSize: paginationPageSize,
          SearchText: searchValue,
        
        })
        resetSortStatus()  
     
      }
    },
    [ caseId, editId,paginationPageSize,resetSortStatus]
  );

  const handleQuickSearchClear = useCallback(() => {
    setSearchQuery("");
    setPaginationObj({
      CaseID: caseId,
      DebtorId:editId,
      PageNumber: 1,
      PageSize: paginationPageSize,
      SearchText: "",
  
    })
    resetSortStatus()  
  }, [caseId,editId,paginationPageSize,resetSortStatus]);

  const headerData = {
    title: "pages.debtors.debtorClaimsSection.tableTitle",
    description: "pages.debtors.debtorClaimsSection.tableDescription",
  };

 
 

  const tableData = data?.results ? data?.results  : [];
  return (
    <>
      <div
        className={
          "ccwp_claim_claimlisttable " +
          (showBasicSearch ? "hide_claims_table" : "")
        }
      >
        {error ? (
          <MotifErrorMessage
            style={{
              textAlign: "center",
              display: "block",
              alignItem: "center",
            }}
          >
{t("global.globalNotifications.wentWrong")}      
    </MotifErrorMessage>
        ) : isLoading ? (
          <MotifProgressLoader show variant="default" />
        ) : tableData ? (
          <>
          <Table
            columnDefs={columnDefs}
            columnTypes={{...sortedColumnTypes}}
            handlePaginationData={handlePaginationData}
                paginationObj={paginationObj}
            cellRenderers={isFetching?[]:tableData}
            TitleComplement={() => (
              <TitleComplement
                onSearch={handleSearch}
                caseId={caseId || ""}
                debtorId={editId || ""}
                handleQuickSearchClear={handleQuickSearchClear}
                searchQuery={searchQuery}
                isBasicSearch={false}
                handleClearBasicSearch={() => {}}
              />
            )}
            title={headerData.title}
            description={headerData.description}
            caseRenderers={caseRenderers}
            pagination={false}
            overlayNoRowsTemplate={isFetching?t(tableLoadingData):error?t("global.globalNotifications.wentWrong")
              : t("global.globalNotifications.noRecords")}
          />
           <CustomPagination
          currentpage={paginationObj.PageNumber}
          pageSize={paginationObj.PageSize}
          disableNext={!data?.hasMoreRecords}
          onChangePageSize={(page: number) => {
            setPaginationObj({
              ...paginationObj,
              PageSize: page,
              PageNumber: 1,
            });
          }}
          onChangeNextPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber + 1,
            });
          }}
          onChangePreviousPage={() => {
            setPaginationObj({
              ...paginationObj,
              PageNumber: paginationObj.PageNumber - 1,
            });
          }}
          pageSizeSelector={paginationPageSizeSelector}
        />
          </>
        ) : null}
      </div>
      {showBasicSearch ? <BasicSearch onApply={() => {}} /> : <></>}
    </>
  );
}
