import {
  CATEGORY_DESC_NAME,
  CATEGORY_NAME,
  CATEGORY_DESC_FR_NAME,
  CATEGORY_NAME_FR,
} from "./constant";
import { useForm } from "react-hook-form";
import { EditSummaryFormType } from "./type";
import ControlInputBox from "./createCategoryInputField";
import Button from "@/components/Form/Button/Button";
import {
  MotifButton,
  MotifCardBody,
  MotifCardFooter,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useCase } from "@/features/case/use-case";
import { useCreateClaimItemCategoryMutation } from "@/services/claimSummary/caseSummaryReducer";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
import { useTranslation } from "react-i18next";

export const CreateCategoryAllInputs = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);
  const caseData = useCase();
  const dispatch=useDispatch()
  const {t}=useTranslation()
 
  const {
    handleSubmit,
    control,
    formState: { errors,isDirty },
  } = useForm<EditSummaryFormType>({
    defaultValues: { 
      categoryName: "",
      categoryFrName: "",
      caetegoryDescName: "",
      categoryDescFrName: "",
     }
  });
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,isDirty]);
  const [createClaimItemCategory] = useCreateClaimItemCategoryMutation();
  const onSubmit = async (formData: EditSummaryFormType) => {
    let categoryData = {
      caseId: caseData.caseId,
      name: formData.categoryName,
      nameFrench: formData.categoryFrName,
      description: formData.caetegoryDescName,
      descriptionFrench: formData.categoryDescFrName,
    };
    setIsLoading(true);
    dispatch(setDiscardEditMode({ editMode: false }));

    const response = await createClaimItemCategory(categoryData);
    if (response.hasOwnProperty("data")) {
      navigate(`/case/${caseData.caseId}?activeTab=5`);
      setIsLoading(false);
    } else {
      dispatch(setDiscardEditMode({ editMode: true }));

      setIsLoading(false);
      console.log("error in creating category");
    }
  };
  return (
    <>
      {" "}
      <MotifCardBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="motif-col-md-4  mar-t-20">
            {" "}
            <div className="form-field mar-b-20">
              <ControlInputBox
                name={CATEGORY_NAME}
                control={control}
                required={true}
                isTextArea={false}
                label={"pages.configuration.category.formLabels.categoryName"}
                showLabel={true}
              />
              {errors[CATEGORY_NAME] &&
                errors[CATEGORY_NAME].type === "required" && (
                  <MotifErrorMessage>{t("pages.configuration.category.validationMessages.categoryRequired")}</MotifErrorMessage>
                )}
            </div>
            <div className="form-field mar-b-20">
              <ControlInputBox
                name={CATEGORY_DESC_NAME}
                control={control}
                isTextArea={true}
                label={"pages.configuration.category.formLabels.categoryDescription"}
                showLabel={true}
              />
            </div>
            <div className="form-field mar-b-20">
              <ControlInputBox
                name={CATEGORY_NAME_FR}
                control={control}
                isTextArea={false}
                label={"pages.configuration.category.formLabels.categoryNameFrench"}
                showLabel={true}
              />
              {errors[CATEGORY_NAME_FR] &&
                errors[CATEGORY_NAME_FR].type === "required" && (
                  <MotifErrorMessage>Task Name is required</MotifErrorMessage>
                )}
            </div>
            <div className="form-field">
              <ControlInputBox
                name={CATEGORY_DESC_FR_NAME}
                control={control}
                isTextArea={true}
                label={"pages.configuration.category.formLabels.categoryDescriptionFrench"}
                showLabel={true}
              />
              {errors[CATEGORY_DESC_FR_NAME] &&
                errors[CATEGORY_DESC_FR_NAME].type === "required" && (
                  <MotifErrorMessage>
                    Task Description is required
                  </MotifErrorMessage>
                )}
            </div>
          </div>
        </form>
      </MotifCardBody>{" "}
      <MotifCardFooter>
        <div className="attributes-footer-buttons">
          <MotifButton
              onClick={() => {
                if(isDirty){
                setIsDiscardVisible(true);      dispatch(setDiscardEditMode({ editMode: false }));
                }
                else{
                  navigate(`/case/${caseData.caseId}?activeTab=5`)
                }
              }}
            size="small"
            variant="ghost"
            style={{ marginTop: "10px" }}
          >
            {t("global.globalActions.cancel")}
          </MotifButton>
          <Button
            label={t("global.globalActions.save")}
            variant="primary"
            className="motif-button"
            onClickHandler={handleSubmit(onSubmit)}
            type={isLoading ? "loader" : "submit"}
            disabled={isLoading}
          />
        </div>
      </MotifCardFooter>
      <ConfirmationModal isVisible={isDiscardVisible} setIsVisible={setIsDiscardVisible} cancelBtnApproveHandler={() => {navigate(`/case/${caseData.caseId}?activeTab=5`)}} cancelBtnRejectHandler={() => {setIsDiscardVisible(false);      dispatch(setDiscardEditMode({ editMode: true }));
}} />
    </>
  );
};
