import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  MotifFormField,
  MotifLabel,
  MotifSelect,
  MotifOption,
  MotifTable,
  MotifButton,
  MotifIcon,
  MotifProgressLoader,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@/libs/@ey-xd/motif-react";
import {
  actionIcDelete24px,
  contentIcCreate24px,
} from "@ey-xd/motif-react/assets/icons";

import "./Attributes.css";
import { setAttribute, initialState } from "./AttributesSlice";
import {
  useLazyListAttributesQuery,
  useLazyAttributeByIdQuery,
  useDeleteAttributeMutation,
} from "@/services/customAtrributes/customAttributesReducer";
import { EntityType, AttributeType } from "./type";
import { customComparator } from "@/utility/common";

export default function AttributesManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { caseId } = useParams();
  const dispatch = useDispatch();
  const [entityType, setEntityType] = useState(EntityType.Creditor);
  const [data, setData] = useState<any[]>([]);
  const [rowData, setRowData] = useState<any[]>([]);
  const [listAttributesQuery] = useLazyListAttributesQuery();
  const [attributeByIdQuery] = useLazyAttributeByIdQuery();
  const [deleteAttribute] = useDeleteAttributeMutation();
  const [currentId, setCurrentId] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCreateNew = () => {
    dispatch(setAttribute({ ...initialState, entityType: entityType }));
    navigate(`/case/${caseId}/configuration/attributes`);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const requestBody = {
      id: currentId,
    };
    try {
      await deleteAttribute(requestBody).unwrap();
      const newData = data.filter((x) => x.id !== currentId);
      setData(newData);
      setShowDelete(false);
    } catch (error) {
      setIsErrorVisible(true);
      const errorMessage = (error as { data?: { message?: string } }).data?.message || "Attribute cannot be deleted";
      setErrorMessage(errorMessage);
      setShowDelete(false)
      if ((error as { status?: number })?.status === 400) {
        console.error("Error 400: Bad Request", errorMessage);
      } else {
        console.error("Failed to delete the attribute", errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (id: string) => {
    attributeByIdQuery(id).then((response: any) => {
      const attribute = {
        ...response.data,
        metaDataJSON: JSON.parse(response.data.metadataJSON),
        id: id,
      };
      dispatch(setAttribute(attribute));
      navigate(`/case/${caseId}/configuration/attributes`);
    });
  };

  const updateRowData = () => {
    const rows = [];
    for (let i = 0; i < data?.length; i++) {
      const attribute = data[i];

      if (attribute.entityType === entityType) {
        rows.push({
          id: attribute.id,
          entityType: attribute.entityType,
          type: AttributeType[attribute.attributeType],
          name: attribute.name,
          description: attribute.description,
          hide: false,
        });
      }
    }
    setRowData(rows);
  };

  const getData = () => {
    if (caseId) {
      listAttributesQuery(caseId).then((response) => {
        setData(response.data);
      });
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateRowData();
    // eslint-disable-next-line
  }, [data, entityType]);

  const ToggleCellRenderer = (_props: any) => {
    return <></>;
  };

  const ActionsCellRenderer = (props: any) => {
    return (
      <>
        <MotifButton
          className="action-delete-button"
          size="small"
          variant="primary"
        >
          <MotifIcon
            fill="none"
            iconFunction={actionIcDelete24px}
            onClick={() => {
              setShowDelete(true);
              setCurrentId(props.data.id);
            }}
          />
        </MotifButton>

        <MotifButton
          size="small"
          variant="primary"
          onClick={() => handleEdit(props.data.id)}
        >
          <MotifIcon fill="none" iconFunction={contentIcCreate24px} />
        </MotifButton>
      </>
    );
  };

  const columnDefs = (handleEdit: (params: any) => void)=> [
    {
      headerName: t("pages.configuration.attributes.attributeTableName"),
      field: "name",
      sortable: true,
      comparator: customComparator,
      flex: 1,
	  	onCellClicked:(params:any)=>handleEdit(params?.data?.id),
      cellStyle: { cursor: "pointer" },
    },
    {
      headerName: t("pages.configuration.attributes.attributeTableType"),
      field: "type",
      sortable: true,
      comparator: customComparator,
      flex: 1,
    },
    {
      headerName: t("pages.configuration.attributes.attributeTableDescription"),
      field: "description",
      sortable: false,
      wrapText: true,
      autoHeight: true,
      flex: 1,
    },
    {
      headerName: "",
      field: "hide",
      sortable: false,
      cellRenderer: ToggleCellRenderer,
      maxWidth: 130,
    },
    {
      headerName: "",
      field: "actions",
      sortable: false,
      cellRenderer: ActionsCellRenderer,
      maxWidth: 115,
    },
  ];

  return (
    <div className="attributes-management-container">
      <div className="motif-body1-default-regular attribute-management-title">
        {t("pages.configuration.attributes.subtitle")}
      </div>
      <div>
        <MotifFormField className="attribute-type-select">
          <MotifLabel>
            {t("pages.configuration.attributes.attributeType")}
          </MotifLabel>
          <MotifSelect
            onChange={(value) => setEntityType(value)}
            value={entityType}
            visibleOptions={3}
          >
            <MotifOption value={EntityType.Creditor}>
              {t("pages.configuration.attributes.attributeTypeCreditor")}
            </MotifOption>
            <MotifOption value={EntityType.Claim}>
              {t("pages.configuration.attributes.attributeTypeClaim")}
            </MotifOption>
            <MotifOption value={EntityType.ClaimItem}>
              {t("pages.configuration.attributes.attributeTypeClaimItem")}
            </MotifOption>
          </MotifSelect>
        </MotifFormField>
      </div>

      {!data ? (
        <MotifProgressLoader show variant="default" />
      ) : (
        <>
          <div className="attribute-management-table">
            <MotifTable
              columnDefs={columnDefs(handleEdit)}
              rowData={rowData}
              title={
                EntityType[entityType] +
                " " +
                t("pages.configuration.attributes.title")
              }
              titleComplement={
                <MotifButton
                  size="small"
                  variant="secondary"
                  onClick={handleCreateNew}
                >
                  {t("pages.configuration.attributes.createAttributeButton")}
                </MotifButton>
              }
              suppressRowClickSelection
              suppressCellFocus
            />
          </div>
        </>
      )}

      <MotifModal
        onClose={() => setShowDelete(false)}
        show={showDelete}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <MotifModalHeader>
          {t("pages.configuration.attributes.deleteAttribute")}
        </MotifModalHeader>

        <MotifModalBody>
          {t("pages.configuration.attributes.deleteAttributeMessage")}
        </MotifModalBody>

        <MotifModalFooter>
          <MotifButton
            onClick={handleDelete}
            type={isLoading ? "loader" : "submit"}
            variant="primary"
          >
            {t("pages.configuration.attributes.deleteButton")}
          </MotifButton>
        </MotifModalFooter>
      </MotifModal>

      <MotifModal
        onClose={() => setIsErrorVisible(false)}
        show={isErrorVisible}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >

        <MotifModalBody>
          {errorMessage}
        </MotifModalBody>

        <MotifModalFooter>
          <MotifButton
            onClick={() => {
              setIsErrorVisible(false)
              setShowDelete(false)
            }}
            variant="primary"
          >
            Close
          </MotifButton>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
}