import { dateFunc } from "@/components/Functions/dateFunc";
import {  getStatusDescription } from "@/utility/common";
import { useTranslation } from "react-i18next";

export const useColumnDistribution=(timeZone: string) =>{
  const {t}=useTranslation()
  return [
  {
    cellRenderer: "distributionName",
    			comparator: () => 0,
    field: "name",
    type:"nameType",
    headerName: t("pages.distributions.distribution.tableColumnDetails.column1"),
    sortable: true,
    unSortIcon: true,
    width:"200px",
    cellClass: 'align-left',
    headerClass: 'align-left',
  },
  {
    			comparator: () => 0,
    field: "distributionStatus",
    type:"distributionStatusType",
    cellRenderer: function (params: any) {
      return getStatusDescription(params.data.distributionStatus);
    },
    headerName: t("pages.distributions.distribution.tableColumnDetails.column2"),
    flex:1,
    sortable: true,
    unSortIcon: true,
    cellClass: 'align-left', 
    headerClass: 'align-left',

  },
  {
    cellRenderer: function (params: any) {      
      return dateFunc(params.data.effectiveDate).formattedDateOnly;
    },
    			comparator: () => 0,
    field: "effectiveDate",
    type:"effectiveDateType",
    headerName: t("pages.distributions.distribution.tableColumnDetails.column3"),
    flex: 1,
    cellClass: 'align-left',
    headerClass: 'align-left',
    sortable: true,
    unSortIcon: true,

    // headerComponent:CustomHeaderImport

  },
  {
    cellRendererParams: {
			timeZoneName: timeZone, 
		},
    cellRenderer: function (params: any) {
      return dateFunc(params.data.createdOn).formattedDateTime + " " +params.timeZoneName;
    },
    			comparator: () => 0,
    field: "createdOn",
    type:"createdOnType",
    headerName:t("pages.distributions.distribution.tableColumnDetails.column4"),
    flex: 1.5,
    cellClass: 'align-left',
    headerClass: 'align-left',
    sortable: true,
    unSortIcon: true,

  },
  {
    cellRenderer: "actionCellRenderers",
    field: "action",
    headerName: "",
    flex: 1,
    sortable: false,
  },
]};
