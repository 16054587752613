import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifTabNavigation,
  MotifTabControl,
  MotifFormField,
  MotifLabel,
  MotifInput,
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifIcon,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import {
  actionIcDescription24px,
  editorIcVerticalAlignBottom24px,
} from "@ey-xd/motif-react/assets/icons";

import {
  useLazyGetValidStageMovesQuery,
  useLazyGetClaimStageStatusQuery,
  useUpdateClaimStageStatusMutation,
  useCreateClaimStageStatusMutation,
} from "@/services/stages/stagesReducer";

import config from "@/config";

import "./stageStatusPopup.css";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { useCase } from "@/features/case/use-case";
import { fileExtensions } from "@/services/documents/documentsReducer";

export default function StageStatusPopup({
  show,
  setShow,
  claimId,
}: {
  show: boolean;
  setShow: (isVisible: boolean) => void;
  claimId: string;
}) {
  const { t } = useTranslation();
  const formData = new FormData();
  const caseData = useCase();
  const [stagesData, setStagesData] = useState<any[]>([]);
  const [getValidStagesMoveQuery] = useLazyGetValidStageMovesQuery();
  const [getCurrentClaimDataQuery] = useLazyGetClaimStageStatusQuery();
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState("");
  const [currentStageId, setCurrentStageId] = useState("");
  const [statusData, setStatusData] = useState<any[]>([]);
  const [currentStatusId, setCurrentStatusId] = useState("");
  const [currentStatusData, setCurrentStatusData] = useState<any>();
  const [currentInstanceData, setCurrentInstanceData] = useState<any>();
  const [comment, setComment] = useState("");
  const [updateStageStatusQuery] = useUpdateClaimStageStatusMutation();
  const [createStageStatusQuery] = useCreateClaimStageStatusMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [docRequiredMessage, setDocRequiredMessage] = useState("");
  const [docReleaseMessage, setDocReleaseMessage] = useState("");
  const [docResolveMessage, setDocResolveMessage] = useState("");

  useEffect(() => {
    if(show) {
      setIsLoading(true)
      getValidStagesMoveQuery({
        CaseId: caseData.caseId,
        ClaimId: claimId,
        }).then((response) => {
          if(response?.data){
            setStagesData([...response.data]);

          }
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [show])

  useEffect(() => {
    if(stagesData.length !== 0){
      setInitialValues();
    }
    // eslint-disable-next-line
  }, [stagesData, show]);

  useEffect(() => {
    onStageChange()
    // eslint-disable-next-line
  }, [currentStageId]);

  useEffect(() => {
    const tempCurrentStatus = statusData.filter((status: any) => {
      return status.id === currentStatusId;
    });
    setCurrentStatusData(tempCurrentStatus[0]);

    if (currentInstanceData) {
      if (
        currentStatusId !==
        currentInstanceData.definitionIds.claimStatusDefinitionId
      ) {
        setComment("");
      } else {
        setComment(currentInstanceData.instanceData.comment);
      }
    }
    setDocRequiredMessage("");
    setDocReleaseMessage("");
    setDocResolveMessage("");
    const currentStatus = statusData.find(
      (status) => status.id === currentStatusId
    );
    if (currentStatus?.isDocReleaseStatus) {
      setDocReleaseMessage(t("pages.claims.stageStatusPopup.docReleaseMessage"));
    }
    if (currentStatus?.isResolveStatus) {
      setDocResolveMessage(t("pages.claims.stageStatusPopup.docResolveMessage"));
    }
    // eslint-disable-next-line
  }, [statusData, currentStatusId]);

  const setInitialValues = () => {
    if (stagesData) {
      getCurrentClaimDataQuery(claimId).then((response) => {
        setCurrentInstanceData(response.data);
        setCurrentStageId(response.data.definitionIds.claimStageDefinitionId);
        setCurrentStatusId(response.data.definitionIds.claimStatusDefinitionId);
        setComment(response.data.instanceData.comment);
        setFileName(response.data.instanceData.stageDocumentName);
        setFile(null);
        onStageChange();
      });
    }
  };

  const onStageChange = () => {
    if (stagesData && currentInstanceData) {
      const currentStageData = stagesData.filter((stage: any) => {
        return stage.id === currentStageId;
      });
      if (currentStageData && currentStageData.length !== 0) {
        const statuses = [...currentStageData[0].claimStatuses];
        setStatusData(statuses);

        if (
          currentStageId !==
          currentInstanceData.definitionIds.claimStageDefinitionId
        ) {
          const tempCurrentStatus = statuses.sort(
            (a: any, b: any) => a.sortOrder - b.sortOrder
          );
          tempCurrentStatus.length !== 0 &&
            setCurrentStatusId(tempCurrentStatus[0].id);
          setFileName("");
          setFile(null);
        } else {
          setCurrentStatusId(
            currentInstanceData.definitionIds.claimStatusDefinitionId
          );
          setFileName(currentInstanceData.instanceData.stageDocumentName);
        }
      }
    }
  }

  const handleFileChange = (files: any) => {
    const file = files[0];
    if (file) {
      setFile(file);
      setFileName(file.name);
    }
  };

  const handleFileDownload = () => {
    if (file) {
      const filePath = window.URL.createObjectURL(file);
      window.location.assign(filePath);
    } else {
      const filePath = `${config.backend.baseUrl}/Document/Download?CaseId=${caseData.caseId}&DocumentId=${currentInstanceData.instanceData.stageDocumentId}`;
      window.location.assign(filePath);
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    if (currentStatusData.isDocRequiredStatus && !fileName) {
      setDocRequiredMessage(t("pages.claims.stageStatusPopup.docRequiredMessage"));
      setIsSubmitting(false);
      return;
    }
    if (
      currentStageId ===
        currentInstanceData.definitionIds.claimStageDefinitionId &&
      currentStatusId ===
        currentInstanceData.definitionIds.claimStatusDefinitionId
    ) {
      formData.append(
        "ClaimStatusInstanceId",
        currentInstanceData.instanceIds.claimStatusInstanceId
      );
      if (comment && comment.trim().length > 0) {
        formData.append("Comment", comment);
      }
      if (file) {
        formData.append("file", file);
      }

      updateStageStatusQuery(formData).then(() => {
        setIsSubmitting(false);
        setShow(false);
      });
    } else {
      formData.append("CaseId", caseData.caseId);
      formData.append("ClaimId", claimId);
      formData.append("ClaimStageDefinitionId", currentStageId);
      formData.append("ClaimStatusDefinitionId", currentStatusId);
      if (comment && comment.trim().length > 0) {
        formData.append("Comment", comment);
      }
      if (file) {
        formData.append("file", file);
      }

      if (
        currentStageId ===
        currentInstanceData.definitionIds.claimStageDefinitionId
      ) {
        formData.append(
          "ClaimStageInstanceId",
          currentInstanceData.instanceIds.claimStageInstanceId
        );
      }

      createStageStatusQuery(formData).then(() => {
        setIsSubmitting(false);
        setShow(false);
      });
    }
  };

  return (
    <>
      <div>
        <MotifModal
          className="stage-status-popup-container"
          onClose={() => setShow(false)}
          show={show}
          focusTrapOptions={{
            tabbableOptions: {
              displayCheck: "none",
            },
          }}
        >
          <MotifModalHeader>
            {t("pages.claims.stageStatusPopup.title")}
          </MotifModalHeader>

          <MotifModalBody>
            {isLoading ? (
              <MotifProgressLoader show variant="default" />
            ) : (
              <>
                <MotifTabNavigation controlled={true}>
                  {Array.isArray(stagesData) &&
                    stagesData
                      .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
                      .map((stage: any) => {
                        return (
                          <MotifTabControl
                            className={
                              currentStageId === stage.id ? "motif-active" : ""
                            }
                            onClick={() => setCurrentStageId(stage.id)}
                            key={stage.id}
                          >
                            {stage.name}
                          </MotifTabControl>
                        );
                      })}
                </MotifTabNavigation>

                {statusData.length !== 0 ? (
                  <>
                    <div className="stage-buttons-container">
                      {[...statusData]
                        .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
                        .map((status: any) => {
                          return (
                            <MotifButton
                              className={
                                "stage-button " +
                                (currentStatusId === status.id
                                  ? "active-stage"
                                  : "")
                              }
                              onClick={() => setCurrentStatusId(status.id)}
                              variant="primary-alt"
                              disabled={
                                !status.isValidMove &&
                                status.id !== currentStatusId &&
                                currentInstanceData.definitionIds &&
                                status.id !==
                                  currentInstanceData.definitionIds
                                    .claimStatusDefinitionId
                              }
                            >
                              {status.name}
                            </MotifButton>
                          );
                        })}
                    </div>

                    <div className="status-content">
                      <div className="external-portal-container">
                        <span className="motif-body2-default-light">
                          {t("pages.claims.stageStatusPopup.status")}
                        </span>
                        <span className="motif-body2-default-bold">
                          {currentStatusData &&
                          currentStatusData.portalDisplayStatus
                            ? currentStatusData.portalDisplayStatus
                            : ""}
                        </span>
                      </div>
                      <div className="status-content-input">
                        <MotifLabel>
                          {t("pages.claims.stageStatusPopup.timeLimit")}
                        </MotifLabel>
                        <MotifFormField>
                          <MotifInput
                            // @ts-ignore
                            value={
                              currentStatusData &&
                              currentStatusData.timeLimitDays
                                ? currentStatusData.timeLimitDays
                                : ""
                            }
                            disabled={true}
                          />
                        </MotifFormField>
                      </div>
                      <div className="status-content-input">
                        <MotifLabel>
                          {t("pages.claims.stageStatusPopup.changeMemo")}
                        </MotifLabel>
                        <MotifFormField>
                          <MotifInput
                            // @ts-ignore
                            onChange={(e) => setComment(e.target.value)}
                            value={comment}
                          />
                        </MotifFormField>
                      </div>
                    </div>
                    {docReleaseMessage && !docResolveMessage && (
                      <div className="toast-message-div">
                        <NotificationGrid
                          show={true}
                          message={t(
                            "pages.claims.stageStatusPopup.docReleaseMessage"
                          )}
                          type="info"
                        />
                      </div>
                    )}
                    {docResolveMessage && !docReleaseMessage && (
                      <div className="toast-message-div">
                        <NotificationGrid
                          show={true}
                          message={t(
                            "pages.claims.stageStatusPopup.docResolveMessage"
                          )}
                          type="info"
                        />
                      </div>
                    )}
                    {docReleaseMessage && docResolveMessage && (
                      <div className="toast-message-div">
                        <NotificationGrid
                          show={true}
                          message={t(
                            "pages.claims.stageStatusPopup.docReleaseMessage"
                          )}
                          supportingText={t(
                            "pages.claims.stageStatusPopup.docResolveMessage"
                          )}
                          type="info"
                        />
                      </div>
                    )}
                    {docRequiredMessage && (
                      <div className="toast-message-div">
                        <NotificationGrid
                          show={true}
                          message={docRequiredMessage}
                          type="error"
                        />
                      </div>
                    )}
                    <div>
                      <MotifFileUploader
                        label={t("pages.claims.stageStatusPopup.fileLabel")}
                        labelDescription={t(
                          "pages.claims.stageStatusPopup.fileDescription"
                        )}
                        onDrop={(files) => handleFileChange(files)}
                        maxFiles={1}
                        accept={fileExtensions.default}
                      >
                        {(file || fileName) && (
                          <MotifFileUploaderItem
                            fileIcon={
                              <MotifIcon src={actionIcDescription24px} />
                            }
                            customButton={
                              <MotifButton
                                onClick={handleFileDownload}
                                variant="primary-alt"
                              >
                                <MotifIcon
                                  fill="none"
                                  iconFunction={editorIcVerticalAlignBottom24px}
                                />
                              </MotifButton>
                            }
                            fileName={fileName}
                            onRemove={() => setFile(null)}
                            error={
                              (file &&
                                file.size >= fileExtensions.maxFileSize) ||
                              false
                            }
                            errorText={t(
                              "pages.claims.stageStatusPopup.filesizeExceedsLimit",
                              {
                                filesize: "250",
                              }
                            )}
                          />
                        )}
                      </MotifFileUploader>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </MotifModalBody>
          <MotifModalFooter>
            <div>
              <MotifButton
                onClick={handleSubmit}
                variant="primary"
                type={isSubmitting ? "loader" : "submit"}
                disabled={isSubmitting}
              >
                {t("pages.claims.stageStatusPopup.setButton")}
              </MotifButton>
            </div>
          </MotifModalFooter>
        </MotifModal>
      </div>
    </>
  );
}
