import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { MotifIcon, MotifButton, MotifErrorMessage } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Button from "@/components/Form/Button/Button";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import PageTitle from "@/components/Form/PageTitle/PageTitle";
import PageSubTitle from "@/components/Form/PageSubTitle/PageSubTitle";
import {
	SCHEME_NAME,
	SCHEME_LABEL,
	SCHEME_DESC_LABEL,
	SCHEME_DESC_NAME,
	SCHEME_NAME_FR,
	SCHEME_NAME_LABEL_FR,
	SCHEME_DESC_FR_LABEL,
	SCHEME_DESC_FR_NAME,
} from "./Constants";
import { CreateSchemeFormType } from "./type";
import ControlInputBox from "./ControlInputBoxScheme";
import {
	useCreateSchemeMutation,
} from "@/services/scheme/schemeReducer";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

const SchemeSetup = () => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const { caseId } = useParams();
	const [createStageQuery] = useCreateSchemeMutation();
	const [errorMessage, setErrorMessage] = useState("");
	const [isDiscardVisible, setIsDiscardVisible] = useState(false);
	const navigate = useNavigate();
	const dispatch=useDispatch()
	
	const {
		handleSubmit,
		control,
		formState: { errors ,isDirty},
	} = useForm<CreateSchemeFormType>({
		defaultValues: {
			[SCHEME_NAME]: "",
			[SCHEME_NAME_FR]: "",
			[SCHEME_DESC_NAME]: "",
			[SCHEME_DESC_FR_NAME]: "",
		},
	});
useEffect(() => {
	dispatch(setDiscardEditMode({ editMode: isDirty }));
	return () => {
	  dispatch(setDiscardEditMode({ editMode: false }));
	};
  }, [dispatch,isDirty]);
	const onSubmit = async (formData: CreateSchemeFormType) => {
		setErrorMessage("");
		setIsLoading(true);
		const schemeData = {
			caseId: caseId,
			name: formData.schemeName,
			nameFrench: formData.schemeFrName,
			description: formData.schemeDescName,
			descriptionFrench: formData.shemeDescFrName,
		};
		dispatch(setDiscardEditMode({ editMode: false }));

		await createStageQuery(schemeData).then((response: any) => {
			if (response.data) {
				const schemeId = response.data;
				navigate(`/case/${caseId}/scheme-manage/${schemeId}`);
			} else {
				dispatch(setDiscardEditMode({ editMode: true }));

				setErrorMessage(response.error.data.message);
			}
			setIsLoading(false);
		});
	};
	const discardCancelBtnHandler = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
		setIsDiscardVisible(false);
		dispatch(setDiscardEditMode({ editMode: true }));

	};

	const discardConfirmBtnHandler = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
		navigate(`/case/${caseId}/?activeTab=5`);
		setIsDiscardVisible(false);
	};

	const handleSchemeCancel = (e:any) => {
		if(isDirty){
			setIsDiscardVisible(true);
			dispatch(setDiscardEditMode({ editMode: false }));
		}
		else{
			discardConfirmBtnHandler(e)
		}
	

	};

	return (
		<>
			{errorMessage && (
				<NotificationGrid show={true} message={errorMessage} type="error" />
			)}
			<div
				style={{ cursor: "pointer" }}
				onClick={(e:any) =>{ 
					
					if(isDirty){
						setIsDiscardVisible(true);
						dispatch(setDiscardEditMode({ editMode: false }));
					}
					else{
						discardConfirmBtnHandler(e)
					}			}}
			>
				<Breadcrumb
					items={[
						{
							text: t("pages.configuration.attributes.backButton"),
							url: "",
							icon: <MotifIcon src={IconoirArrowLeft} />,
						},
					]}
					ariaLabel="Breadcrumb navigation"
				/>
			</div>
			<PageTitle
				title={t("pages.configuration.scheme.primaryTitle")}
				description={t("pages.configuration.scheme.primaryDescription")}
			/>
			<PageSubTitle
				title={t("pages.configuration.scheme.secondaryTitle")}
				description={t("pages.configuration.scheme.secondaryDescription")}
			/>

			<div className="motif-col-md-4  mar-t-20">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-field mar-b-20">
						<ControlInputBox
							name={SCHEME_NAME}
							control={control}
							required={true}
							isTextArea={false}
							label={SCHEME_LABEL}
							showLabel={true}
							value={""}
						/>
						{errors[SCHEME_NAME] && errors[SCHEME_NAME].type === "required" && (
							<MotifErrorMessage>Scheme Name is required</MotifErrorMessage>
						)}
					</div>
					<div className="form-field mar-b-20">
						<ControlInputBox
							name={SCHEME_NAME_FR}
							control={control}
							isTextArea={false}
							label={SCHEME_NAME_LABEL_FR}
							showLabel={true}
							value={""}
						/>
					</div>
					<div className="form-field mar-b-20">
						  <ControlTextAreaWithCharLimit
							name={SCHEME_DESC_NAME}
							control={control}
							isTextArea={true}
							label={SCHEME_DESC_LABEL}
							showLabel={true}							
							/>
					</div>
					<div className="form-field">
						<ControlTextAreaWithCharLimit
							name={SCHEME_DESC_FR_NAME}
							control={control}
							isTextArea={true}
							label={SCHEME_DESC_FR_LABEL}
							showLabel={true}
						/>
					</div>
				</form>
			</div>
			<div className="motif-row justify-content-end button-container">
				<div className="pad-r-10 mar-t-20">
					<MotifButton
						type="button"
						variant="secondary"
						className="motif-button btn"
						onClick={handleSchemeCancel}
					>
						Cancel
					</MotifButton>
				</div>
				<div className="pad-r-10 mar-t-20">
					<Button
						label="Save"
						variant="primary"
						className="motif-button"
						type={isLoading ? "loader" : "submit"}
						onClickHandler={handleSubmit(onSubmit)}
					/>
				</div>
			</div>
			<ConfirmationModal
				isVisible={isDiscardVisible}
				setIsVisible={setIsDiscardVisible}
				cancelBtnApproveHandler={discardConfirmBtnHandler}
				cancelBtnRejectHandler={discardCancelBtnHandler}
			/>
		</>
	);
};
export default SchemeSetup;
