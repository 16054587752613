import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useFieldArray } from "react-hook-form";
import {
	MotifButton,
	MotifFormField,
	MotifLabel,
	MotifIcon,
	MotifSelect,
	MotifOption,
	MotifDropdown,
	MotifDropdownItem,
} from "@ey-xd/motif-react";
import {
	MotifActionIcDelete24px,
	IconoirNavArrowDown,
} from "@ey-xd/motif-icon";
import ControlSelectBoxAttribute from "./ControlSelectBoxAttribute";
import ControlSelectBoxOperator from "./ControlSelectBoxOperator";
import ValueElement from "./ValueElement";
import { useAttributeOperators } from "@/services/advanceSearch/useAttributeOperators";
import Group from "./Group";
import "./Tree.css";

type TreeProps = {
	attributes: {
		propertyName: string;
		entityName: string;
		attributeType: number;
		entityCategory: number;
		displayName?: string;
		parentEntityName?: string;
	}[];
};
const Tree = ({ attributes }: TreeProps) => {
	const { t } = useTranslation();
	const [selectedAttribute, setSelectedAttribute] = useState<null | {
		propertyName?: string;
		entityName?: string;
		attributeType?: number;
		entityCategory?: number;
		parentEntityName?: string;
	}>(null);
	const attributeOperators = useAttributeOperators();
	const [showMenu, setShowMenu] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState<null | number>(null);
	const [selectedGroupRowIndex, setSelectedGroupRowIndex] = useState<
		null | number
	>(null);
	const { control, setValue, watch, clearErrors } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: "treeRows",
	});
	const logicalOperator = watch("logicalOperator");
	const treeRows = watch("treeRows");

	const handleAttributeChange = (
		index: number,
		attribute: string,
		groupIndex?: number
	) => {
		const selectedAttr = attributes.find(
			(attr) => attr.displayName === attribute
		);
		if (selectedAttr) {
			setSelectedAttribute(selectedAttr);
			setSelectedIndex(index);
			setSelectedGroupRowIndex(groupIndex !== undefined ? groupIndex : null);
			const basePath =
				groupIndex !== undefined
					? `treeRows.${index}.treeRows.${groupIndex}`
					: `treeRows.${index}`;
			setValue(`${basePath}.operator`, "");
			setValue(`${basePath}.value`, "");
		}
	};

	const handleOperatorChange = (
		index: number,
		operator: string,
		groupIndex?: number
	) => {
		const basePath =
			groupIndex !== undefined
				? `treeRows.${index}.treeRows.${groupIndex}`
				: `treeRows.${index}`;
		setValue(`${basePath}.operator`, operator);
		setValue(`${basePath}.value`, "");
	};

	useEffect(() => {
		if (treeRows.length > 0) {
			treeRows.forEach((item: any, index: number) => {
				if (item.type === "row") {
					const initialAttribute = attributes.find(
						(attr) => attr.displayName === item.attribute
					);
					if (initialAttribute) {
						setSelectedAttribute(initialAttribute);
						setSelectedIndex(index);
						setSelectedGroupRowIndex(null);
					}
				} else if (
					item.type === "group" &&
					item.treeRows &&
					item.treeRows.length > 0
				) {
					item.treeRows.forEach((subItem: any, subIndex: number) => {
						const initialSubAttribute = attributes.find(
							(attr) => attr.displayName === subItem.attribute
						);
						if (initialSubAttribute) {
							setSelectedAttribute(initialSubAttribute);
							setSelectedIndex(index);
							setSelectedGroupRowIndex(subIndex);
						}
					});
				}
			});
		}
	}, [attributes, treeRows]);

	useEffect(() => {
		if (selectedAttribute) {
			const basePath =
				selectedGroupRowIndex !== null
					? `treeRows.${selectedIndex}.treeRows.${selectedGroupRowIndex}`
					: `treeRows.${selectedIndex}`;
			setValue(`${basePath}.attributeType`, selectedAttribute.attributeType);
			setValue(`${basePath}.entityName`, selectedAttribute.entityName);
			setValue(`${basePath}.entityCategory`, selectedAttribute.entityCategory);
			setValue(
				`${basePath}.parentEntityName`,
				selectedAttribute.parentEntityName
			);
			setValue(`${basePath}.propertyName`, selectedAttribute.propertyName);
		}
	}, [selectedAttribute, selectedIndex, selectedGroupRowIndex, setValue]);

	useEffect(() => {
		treeRows.forEach((item: any, index: number) => {
			if (item.type === "row" && shouldHideValueElement(item.operator)) {
				setValue(`treeRows.${index}.value`, "");
				clearErrors(`treeRows.${index}.value`);
			}
			if (item.treeRows && item.treeRows.length > 0) {
				item.treeRows.forEach((subItem: any, subIndex: number) => {
					if (
						subItem.type === "row" &&
						shouldHideValueElement(subItem.operator)
					) {
						setValue(`treeRows.${index}.treeRows.${subIndex}.value`, "");
						clearErrors(`treeRows.${index}.treeRows.${subIndex}.value`);
					}
				});
			}
		});
	}, [treeRows, setValue, clearErrors]);

	const shouldHideValueElement = (operator: string) => {
		return [
			"Contains data",
			"Does not contain data",
			"Last 7 days",
			"Last 30 days",
		].includes(operator);
	};
	const addGroup = () => {
		append({
			type: "group",
			logicalOperator: "0",
			treeRows: [{ attribute: "", operator: "", value: "", type: "row" }],
		});
	};

	if (Object.keys(attributeOperators).length === 0) {
		return null;
	}
	return (
		<div className="tree-container">
			<div className="motif-row">
				<div className="motif-col-md-2 motif-col-lg-2">
					<MotifSelect
						ariaLabelledBy="select-label"
						onChange={(value) => {
							setValue("logicalOperator", value);
						}}
						value={logicalOperator.toString()}
						visibleOptions={3}
						className="select-box-width"
					>
						<MotifOption value="0">
							{t("pages.configuration.scheme.rule.and")}
						</MotifOption>
						<MotifOption value="1">
							{t("pages.configuration.scheme.rule.or")}
						</MotifOption>
					</MotifSelect>
				</div>
			</div>
			{fields.map((field: any, index) => (
				<div key={field.id}>
					{field.type === "row" ? (
						<div className="motif-row">
							<div className="motif-col-md-1 motif-col-lg-1 horizontal-line-container">
								<div className="vertical-line"></div>
								<div className="horizontal-line mar-b-20"></div>
							</div>
							<div className="motif-col-md-2 mar-b-20">
								<ControlSelectBoxAttribute
									index={index}
									control={control}
									attributes={attributes}
									handleAttributeChange={handleAttributeChange}
								/>
							</div>
							<div className="motif-col-md-2 mar-b-20">
								<ControlSelectBoxOperator
									index={index}
									control={control}
									attributeOperators={attributeOperators}
									treeRows={treeRows}
									handleOperatorChange={handleOperatorChange}
								/>
							</div>
							<div className="motif-col-md-2 mar-b-20">
								{!shouldHideValueElement(treeRows[index]?.operator) && (
									<MotifLabel aria-label="Input" htmlFor="input">
										{t("pages.configuration.scheme.rule.value")}
									</MotifLabel>
								)}

								<MotifFormField aria-label="Input" role="group">
									{!shouldHideValueElement(treeRows[index]?.operator) && (
										<ValueElement
											index={index}
											control={control}
											treeRows={treeRows}
										/>
									)}
								</MotifFormField>
							</div>
							{index > 0 && (
								<div className="motif-col-md-1 mar-b-20">
									<MotifButton
										onClick={() => remove(index)}
										size="small"
										type="button"
										variant="secondary"
										className="delete-button"
									>
										<MotifIcon iconFunction={MotifActionIcDelete24px} />
									</MotifButton>
								</div>
							)}
						</div>
					) : (
						<Group
							index={index}
							setValue={setValue}
							field={field}
							control={control}
							remove={remove}
							attributes={attributes}
							handleAttributeChange={handleAttributeChange}
							handleOperatorChange={handleOperatorChange}
							treeRows={treeRows}
							shouldHideValueElement={shouldHideValueElement}
							attributeOperators={attributeOperators}
						/>
					)}
				</div>
			))}
			<div className="motif-row">
				<div className="motif-col-md-2 motif-col-lg-2">
					<MotifDropdown
						open={showMenu}
						ariaLabelledby={"AddDropdown"}
						hideArrow={true}
						handleClickOutside={() => setShowMenu(false)}
						trigger={
							<MotifButton
								type="button"
								onClick={() => setShowMenu(!showMenu)}
								id={"addId"}
								aria-label="Add"
								variant="secondary"
							>
								{t("pages.configuration.scheme.rule.add")}
								<MotifIcon iconFunction={IconoirNavArrowDown} />
							</MotifButton>
						}
					>
						<MotifDropdownItem
							className="add-dropdown-width"
							onClick={() =>
								append({ type: "row", attribute: "", operator: "", value: "" })
							}
						>
							{t("pages.configuration.scheme.rule.addRow")}
						</MotifDropdownItem>
						<MotifDropdownItem
							className="add-dropdown-width"
							onClick={addGroup}
						>
							{t("pages.configuration.scheme.rule.addGroup")}
						</MotifDropdownItem>
					</MotifDropdown>
				</div>
			</div>
		</div>
	);
};

export default Tree;
