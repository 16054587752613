import { ccwpApi } from "@/services";

export const CalculationStepViewApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getCalculationStepData: builder.query<any, any>({
      query: (arg) => "/Account/GetAccountListByCase?CaseId=" + arg.caseId ,
      transformResponse: (response: { results: any[] }) => response.results,
    }),
    getCalculationStepSubAccountData: builder.query<any, any>({
      query: (arg) => `/Account/GetSubAccountListByAccount?AccountId=${arg.accountId}`,
      transformResponse: (response: { results: any[] }) => response.results,
    }),
    getListCalculationStep: builder.query<any, any>({
      query: (arg) => `/CalculationStep/ListCalculationSteps?distributionId=${arg.distributionId}`,
    }),
    getAccountDetailById: builder.query<any, any>({
      query: (arg) => `/Account/ReadAccountById?accountId=${arg.accountId}`,
    }),
    updateCalCulcationStep: builder.mutation({
      query: (data) => ({
        url: `/CalculationStep/UpdateCalCulcationStep`,
        method: "PUT",
        body: data,
      }),
    }),
    updateCapAndFloorLimit: builder.mutation({
      query: (data) => ({
        url: `/CalculationStep/UpdateCapAndFloorLimit`,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});


export const { useGetCalculationStepDataQuery, useGetCalculationStepSubAccountDataQuery, useGetListCalculationStepQuery, useGetAccountDetailByIdQuery, useUpdateCalCulcationStepMutation, useUpdateCapAndFloorLimitMutation } = CalculationStepViewApi;


export const dataSourceSummaryViewApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getDataSourceSummaryViewApiData: builder.query<any, any>({
      query: (stepId) => `/StepDataSource/GetStepDataSourceSummaryList?CalculationStepId=${stepId}`,
    }),
    getDataSourceSummaryEditApiData: builder.query<any, any>({
      query: (editId) => `/StepDataSource/GetStepDataSourceSummaryById?stepDataSourceId=${editId}`,
    }),
    deleteDataSource: builder.mutation({
      query: (dataSourceId) => ({
        url: `/StepDataSource/DeleteStepDataSource?stepDataSourceId=${dataSourceId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useGetDataSourceSummaryViewApiDataQuery, useGetDataSourceSummaryEditApiDataQuery, useDeleteDataSourceMutation } = dataSourceSummaryViewApi;

export const classFilterViewApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getClassFilterViewApiData: builder.query<any, any>({
      query: (stepId) =>
        `/StepClassFilter/GetStepClassFilterList?calculationStepId=${stepId}`,
    }),
    getClassListByScheme: builder.query<any, any>({
      query: (schemeId) =>
        `/Class/ListClassBySchemeVersion?schemeVersionId=${schemeId}`,
    }),
    getSchemeVersionListByCase: builder.query<any, any>({
      query: (caseId) => `/Scheme/ListEffectiveSchemesByCase?caseId=${caseId}`,
    }),
    getStepClassFilterById: builder.query<any, any>({
      query: (stepClassFilterId) =>
        `/StepClassFilter/GetStepClassFilterById?stepClassFilterId=${stepClassFilterId}`,
    }),
    getRateTierListByCase: builder.query<any, { CalculationStepId: string; CaseId: string }>({
      query: ({ CalculationStepId, CaseId }) =>
        `/RateTier/GetRateTierList?CalculationStepId=${CalculationStepId}&CaseId=${CaseId}`,
    }),
    createCalculationStep: builder.mutation({
      query: (data) => ({
        url: `/CalculationStep/CreateStep`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: data,
      }),
    }),
    deleteClassFilter: builder.mutation({
      query: (id) => ({
        url: `/StepClassFilter/DeleteStepClassFilter?StepClassFilteId=${id}`,
        method: "DELETE",
      }),
    }),
    deleteRateTier: builder.mutation({
      query: ({id, calculationStepId }) => ({
        url: `/RateTier/DeleteRateTier?rateTierId=${id}&calculationStepId=${calculationStepId}`,
        method: "DELETE",
      }),
    }),
    updateRateTier: builder.mutation({
      query: (data) => ({
        url: `/RateTier/UpdateRateTier`,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});
export const { useGetClassFilterViewApiDataQuery, useGetClassListBySchemeQuery, useGetSchemeVersionListByCaseQuery, useGetRateTierListByCaseQuery, useGetStepClassFilterByIdQuery, useCreateCalculationStepMutation, useDeleteClassFilterMutation, useDeleteRateTierMutation, useUpdateRateTierMutation } = classFilterViewApi;

export const calculationStepList = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getCalculationStepApiData: builder.query<any, any>({
      query: (stepId) => `/CalculationSettings/GetCalculationSettingsList?CalculationStepId=${stepId}`,
    }),
    createCalculationSettings: builder.mutation({
      query: (data) => ({
        url: `/CalculationSettings/CreateCalculationSettings`,
        method: "POST",
        body: data,
      }),
    }),
    createStepDataSourceApi: builder.mutation({
      query: (data) => ({
        url: `/StepDataSource/CreateStepDataSource`,
        method: "POST",
        body: data,
      }),
    }),
    
    updateStepDataSourceApi: builder.mutation({
      query: (data) => ({
        url: `/StepDataSource/UpdateStepDataSource`,
        method: "PUT",
        body: data,
      }),
    }),
    createStepClassFilterApi: builder.mutation({
      query: (data) => ({
        url: `/StepClassFilter/CreateStepClassFilter`,
        method: "POST",
        body: data,
      }),
    }),
    updateStepClassFilterApi: builder.mutation({
      query: (data) => ({
        url: `/StepClassFilter/UpdateStepClassFilter`,
        method: "PUT",
        body: data,
      }),
    }),
    createRateTierApi: builder.mutation({
      query: (data) => ({
        url: `/RateTier/CreateRateTier`,
        method: "POST",
        body: data,
      }),
    }),
    

  }),
});

export const { useGetCalculationStepApiDataQuery, useCreateCalculationSettingsMutation,useCreateStepDataSourceApiMutation,useUpdateStepDataSourceApiMutation,useCreateStepClassFilterApiMutation,useUpdateStepClassFilterApiMutation,useCreateRateTierApiMutation } = calculationStepList;

export const calculationStepSummary = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getCalculationStepSummaryApiData: builder.query<any, any>({
      query: (stepId) => `CalculationSettings/GetCalculationSettingsSummary?CalculationSettingsId=${stepId}`,
    }),
  }),
});

export const { useGetCalculationStepSummaryApiDataQuery } = calculationStepSummary;

export const manualDataSource = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getManualDataSourceApiData: builder.query<any, any>({
      query: (caseId) => `ManualDataSource/GetManualDataSourceListByCase?caseId=${caseId}`,
    }),
  }),
});

export const { useGetManualDataSourceApiDataQuery } = manualDataSource;

export const calculationStepLimitAmount = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    createCalculationSetting: builder.mutation({
      query: (data) => ({
        url: `/CalculationSettings/CreateCalculationSettings`,
        method: "POST",
        body: data,
      }),
    }),
    updateCalculationSetting: builder.mutation({
      query: (data) => ({
        url: `/CalculationSettings/UpdateCalculationSettings`,
        method: "PUT",
        body: data,
      }),
    }),
    
    deleteLimitAmount: builder.mutation({
      query: (limitAmountId) => ({
        url: `StepLimitAmount/DeleteStepLimitAmount?stepLimitAmountId=${limitAmountId}`,
        method: "DELETE",
      }),
    }),
    getCalculationStepLimitAmountApiData: builder.query<any, any>({
      query: (stepId) => `StepLimitAmount/GetStepLimitAmountList?calculationStepId=${stepId}`,
    }),
    getLimitAmountSummaryApiData: builder.query<any, any>({
      query: (arg) => `StepLimitAmount/GetStepLimitAmountSummary?id=${arg.limitAmountId}`,
    }),
    createLimitAmount: builder.mutation({
      query: (data) => ({
        url: `StepLimitAmount/CreateStepLimitAmount`,
        method: "POST",
        body: data,
      }),
    }),
    updateLimitAmount: builder.mutation({
      query: (data) => ({
        url: `StepLimitAmount/UpdateStepLimitAmount`,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const { useGetCalculationStepLimitAmountApiDataQuery, useGetLimitAmountSummaryApiDataQuery,useCreateLimitAmountMutation, useCreateCalculationSettingMutation, useUpdateCalculationSettingMutation, useUpdateLimitAmountMutation, useDeleteLimitAmountMutation } = calculationStepLimitAmount;

export const distribution = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getDistributionApiData: builder.query<any, any>({
      query: (caseId) => `Distribution/GetDistributionList?CaseId=${caseId}`,
    }),
  }),
});

export const { useGetDistributionApiDataQuery } = distribution;

export const payeeType = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getPayeeTypeApiData: builder.query<any, any>({
      query: (args) => ({url:`PayeeType/ListPayeeTypeByCase`,method:"GET",params:{...args}}),
    }),
    getPayeeTypeSummaryDataApiData: builder.query<any, any>({
      query: (payeeId) => `PayeeType/ReadPayeeTypeById?PayeeTypeId=${payeeId}`,
    }),
    createPayeeTypeData: builder.mutation({
      query: (data) => ({
        url: `PayeeType/CreatePayeeType`,
        method: "POST",
        body: data,
      }),
    }),
    editPayeeTypeData: builder.mutation({
      query: (data) => ({
        url: `PayeeType/UpdatePayeeType`,
        method: "PUT",
        body: data,
      }),
    }),
    deletePayeeType: builder.mutation({
      query: (payeeId) => ({
        url: `PayeeType/DeletePayeeType?payeeTypeId=${payeeId}`,
        method: "DELETE",
      }),
    }),
    getPayeeTypeSummaryApiData: builder.query<any, any>({
      query: (stepId) => `CalculationStep/ReadResultSetting?calculationStepId=${stepId}`,
    }),
    createPayeeType: builder.mutation({
      query: (data) => ({
        url: `CalculationStep/CreateResultSetting`,
        method: "POST",
        body: data,
      }),
    }),
    
    editPayeeType: builder.mutation({
      query: (data) => ({
        url: `CalculationStep/UpdateResultSetting`,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const { useGetPayeeTypeApiDataQuery,useGetPayeeTypeSummaryDataApiDataQuery,useLazyGetPayeeTypeSummaryDataApiDataQuery, useCreatePayeeTypeDataMutation,useEditPayeeTypeDataMutation, useDeletePayeeTypeMutation, useGetPayeeTypeSummaryApiDataQuery, useCreatePayeeTypeMutation, useEditPayeeTypeMutation } = payeeType;