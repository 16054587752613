import {
  ACCOUNT_DESC_FR_NAME,
  ACCOUNT_NAME_FR_NAME,
  DESC_NAME,
  ACCOUNT_NAME,
  ACCOUNT_NUMBER,
  ACCOUNT_NUMBER_LABEL,
  CLIENT_NUMBER,
  INSTITUION_NUMBER,
  INSTITUION_NUMBER_LABEL,
  PREFIX,
  PREFIX_LABEL,
  STARTING_SEQUENCE_NUMBER,
  STARTING_SEQUENCE_NUMBER_LABEL,
  TRANSIT_NUMBER,
  TRANSIT_NUMBER_LABEL,
  UNIT,
  UNIT_LABEL,
} from "./constant";
import { Controller, useForm } from "react-hook-form";
import { EditSummaryFormType } from "./type";
import Button from "@/components/Form/Button/Button";
import {
  MotifErrorMessage,
  MotifFormField,
  MotifIcon,
  MotifInput,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifOption,
  MotifProgressLoader,
  MotifSelect,
} from "@ey-xd/motif-react";
import { useEffect, useState } from "react";
import ControlInputBox from "./createAccountInputField";
import {
  useGetAccountDataByIdApiDataQuery,
  useUpdateAccountMutation,
} from "@/services/configuration/configuration";
import { useTranslation } from "react-i18next";
import {
  MotifActionIcVisibilityOff24px,
  MotifActionIcVisibility24px,
} from "@ey-xd/motif-icon";
import { useCase } from "@/features/case/use-case";
import "./Account.scss";
import { useGetUnitsQuery } from "@/services/unit/unitReducers";

export const EditAccountCreation = ({
  editShow,
  setEditShow,
  refetch,
  setStatusMsg,
  accountId,
  setIsDiscardVisible,
}: any) => {
  const caseData = useCase();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const prefixDropDown = [
    {
      prfixName: t("pages.distributions.account.accountLabels.prefixDropDownLabels.label1"),
      prfixValue: "0",
    },
    {
      prfixName: t("pages.distributions.account.accountLabels.prefixDropDownLabels.label2"),
      prfixValue: "1",
    },
    {
      prfixName: t("pages.distributions.account.accountLabels.prefixDropDownLabels.label3"),
      prfixValue: "2",
    },
  ];
  const [changeinputType, setChangeinputType] = useState({
    institutionNumber: false,
    transitNumber: false,
    accountNumber: false,
  });
  const {
    data: accountDataById,
    isFetching: fetchingAccountData,
    error: errorData,
  } = useGetAccountDataByIdApiDataQuery(accountId);
  const { data, isFetching, error } = useGetUnitsQuery(caseData.caseId);
  let unitData: any = [];
  if( Array.isArray(data)){
  unitData=data?.map((ele: any) => {
      let json = {
        unitName: ele.isDefaultCurrency ? `${ele.name} (Default)` : ele.name,
        unitId: ele.id,
      };
     return json;
    });
  }
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors,isDirty },
  } = useForm<EditSummaryFormType>({
    defaultValues: {
      accountName: "",
      descName: "",
      accountNameFr: "",
      descNameFr: "",
      baseSequenceNumber: undefined,
      prefix: undefined,
      transitNumber: "",
      institutionNumber: "",
      clientNumber: "",
      paymentUnitId: "",
      accountNumber: "",
    },
  });
  const [editAccount] = useUpdateAccountMutation();
  const onSubmit = async (formData: EditSummaryFormType) => {
    let editAccountData = {
      id: accountId,
      name: formData.accountName,
      description: formData.descName,
      nameFrench: formData.accountNameFr,
      descriptionFrench: formData.descNameFr,
      baseSequenceNumber: formData?.baseSequenceNumber || formData?.baseSequenceNumber===0 ?+formData.baseSequenceNumber:null,
      prefix: formData?.prefix?+formData.prefix:null,
      transitNumber: formData?.transitNumber?formData.transitNumber:null,
      institutionNumber: formData?.institutionNumber?formData.institutionNumber:null,
      clientNumber: formData?.clientNumber?formData.clientNumber:null,
      paymentUnitId: formData?.paymentUnitId?formData.paymentUnitId:null,
      accountNumber: formData?.accountNumber?formData.accountNumber:null,
      isActive: true,
    };
    setIsLoading(true);
    setStatusMsg("");
    const response = await editAccount(editAccountData);
    if (response.hasOwnProperty("data")) {
      refetch();
      setEditShow(false);
      //@ts-ignore
      setStatusMsg(response?.data?.message);
      setIsLoading(false);
    } else {
      setEditShow(false);
      setIsLoading(false);
      setStatusMsg("global.globalNotifications.unExpectedError");
    }
  };
  useEffect(() => {
    reset({
      "accountName":accountDataById?.name,
      "descName": accountDataById?.description,
      "accountNameFr": accountDataById?.nameFrench,
      "descNameFr": accountDataById?.descriptionFrench,
      "baseSequenceNumber": accountDataById?.baseSequenceNumber || accountDataById?.baseSequenceNumber === 0?accountDataById?.baseSequenceNumber:"",
      "prefix": accountDataById?.prefix || accountDataById?.prefix===0 ?accountDataById?.prefix?.toString():"",
      "transitNumber": accountDataById?.transitNumber?accountDataById?.transitNumber:"",
      "institutionNumber": accountDataById?.institutionNumber?accountDataById?.institutionNumber:"",
      "clientNumber": accountDataById?.clientNumber? accountDataById?.clientNumber:"",
      "paymentUnitId": accountDataById?.paymentUnitId?accountDataById?.paymentUnitId:"",
      "accountNumber": accountDataById?.accountNumber?accountDataById?.accountNumber:"",
    })

    return () => {
      reset();
    };
  }, [accountDataById, setValue,reset]);
  function handleInputTypeChnage(keyname: string, trig?: boolean) {
    let jsonData: any = { ...changeinputType };
    jsonData[keyname] = !jsonData[keyname];
    setChangeinputType({ ...jsonData });
  }
function stopAutoFilling(){
  //this function is used to stop auto filling of password fields
 return  <MotifInput 
                //@ts-ignore
                autoComplete="new-password"
                style={{display:"none"}}
                type="password" ></MotifInput>
}
  return (
    <MotifModal
      size="xl"
      className="create-edit-stage"
      onClose={() => {
        if(isDirty){
          setIsDiscardVisible(true);
        }
        else{
          setEditShow(false);
        }
      }}
      show={editShow}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
    >
      <MotifModalHeader>{t("pages.distributions.account.editAccountTitle")}</MotifModalHeader>

      <MotifModalBody>
        {fetchingAccountData || isFetching ? (
          <MotifProgressLoader size="xs"></MotifProgressLoader>
        ) : errorData || error ? (
          <MotifErrorMessage>{t("global.globalNotifications.wentWrong")}</MotifErrorMessage>
        ) : (
          <div className="motif-container">
            <form 
          
               autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}>
              <div className="motif-row">
                
            {stopAutoFilling()}
                <div className="form-field motif-col-md-4">
                  <ControlInputBox
                    name={ACCOUNT_NAME}
                    control={control}
                    required={true}
                    isTextArea={false}
                    label={"pages.distributions.account.accountLabels.accountName"}
                    showLabel={true}
                  />
                  {errors[ACCOUNT_NAME] &&
                    errors[ACCOUNT_NAME].type === "required" && (
                      <MotifErrorMessage>
{t("pages.distributions.account.accountLabels.validationMessages.accountNameReq")}                      
</MotifErrorMessage>
                    )}
                </div>
                <div className="form-field  motif-col-md-4">
                  <ControlInputBox
                    name={ACCOUNT_NAME_FR_NAME}
                    control={control}
                    required={false}
                    isTextArea={false}
                    label={"pages.distributions.account.accountLabels.accountNameFrench"}
                    showLabel={true}
                  />
                </div>
              </div>
              <div className="motif-row">
                <div className="form-field  motif-col-md-4">
                  <ControlInputBox
                    name={DESC_NAME}
                    control={control}
                    required={false}
                    isTextArea={true}
                    label={"pages.distributions.account.accountLabels.description"}
                    showLabel={true}
                  />
                </div>

                <div className="form-field  motif-col-md-4">
                  <ControlInputBox
                    name={ACCOUNT_DESC_FR_NAME}
                    control={control}
                    required={false}
                    isTextArea={true}
                    label={"pages.distributions.account.accountLabels.descriptionFrench"}
                    showLabel={true}
                  />
                </div>
              </div>
              <div className="motif-row">
                <div className="form-field motif-col-xs">
                  <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={PREFIX_LABEL}>
                    {t("pages.distributions.account.accountLabels.prefix")}
                    </label>
                  </div>
                  <MotifFormField>
                    <Controller
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <MotifSelect
                            aria-label={t("Select Prefix")}
                            placeholder={t("global.globalPlaceholder.pleaseSelect")}
                            visibleOptions={3}
                            value={value}
                            onChange={onChange}
                            triggerButtonProps={{ type: "button" }}
                          >
                            {prefixDropDown.map((option) => (
                              <MotifOption
                                key={option.prfixValue}
                                value={option.prfixValue}
                              >
                                {option.prfixName}
                              </MotifOption>
                            ))}
                          </MotifSelect>
                        );
                      }}
                      name={PREFIX}
                    />
                  </MotifFormField>
                </div>
                <div className="form-field motif-col-xs">
                  <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={UNIT_LABEL}>
                    {t("pages.distributions.account.accountLabels.unit")}
                    </label>
                  </div>
                  <MotifFormField>
                    <Controller
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <MotifSelect
                            aria-label={t("Select Unit")}
                            placeholder={t("global.globalPlaceholder.pleaseSelect")}
                            visibleOptions={3}
                            value={value}
                            onChange={onChange}
                            triggerButtonProps={{ type: "button" }}
                          >
                            {unitData.map((option: any) => (
                              <MotifOption
                                key={option.unitId}
                                value={option.unitId}
                              >
                                {option.unitName}
                              </MotifOption>
                            ))}
                          </MotifSelect>
                        );
                      }}
                      name={UNIT}
                    />
                  </MotifFormField>
                </div>
                <div className="form-field  motif-col-xs">
                  <div className="formFieldLabel">
                    <label
                      htmlFor="input"
                      aria-label={STARTING_SEQUENCE_NUMBER_LABEL}
                    >
                      {t("pages.distributions.account.accountLabels.startingSequenceNumber")}
                      <span className="requiredSymbol">*</span>
                    </label>
                  </div>
                  <Controller
                    name={STARTING_SEQUENCE_NUMBER}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "pages.distributions.account.accountLabels.validationMessages.sequenceNumberPattern",
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <MotifInput
                          //@ts-ignore
                          type={"text"}
                          value={value}
                          onChange={onChange}
                          hideClearButton={true}
                        ></MotifInput>
                      );
                    }}
                  ></Controller>

                  {errors[STARTING_SEQUENCE_NUMBER] &&
                    errors[STARTING_SEQUENCE_NUMBER].type === "required" && (
                      <MotifErrorMessage>
                        {t("pages.distributions.account.accountLabels.validationMessages.sequenceNumberReq")}                      
                        </MotifErrorMessage>
                    )}

                  {errors[STARTING_SEQUENCE_NUMBER] &&
                    errors[STARTING_SEQUENCE_NUMBER].type === "pattern" && (
                      <MotifErrorMessage>
  {t("pages.distributions.account.accountLabels.validationMessages.sequenceNumberPattern")}                      
  </MotifErrorMessage>
                    )}
                </div>
              </div>
              <div className="motif-row">
                <div className="form-field motif-col-xs">
                  <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={INSTITUION_NUMBER_LABEL}>
                    {t("pages.distributions.account.accountLabels.institutionNumber")}
                    </label>
                  </div>
                  <div className="unmaskedFiledscon">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <MotifInput
                            //@ts-ignore
                            type={
                              changeinputType[INSTITUION_NUMBER]
                                ? "text"
                                : "password"
                            }
                            className="unmaskedFiledsinput"
                            value={value}
                            onChange={onChange}
                            hideClearButton={true}
                          ></MotifInput>
                        );
                      }}
                      name={INSTITUION_NUMBER}
                    ></Controller>
                    <div
                      className="unmaskedFiledsEye"
                      onClick={() => handleInputTypeChnage(INSTITUION_NUMBER)}
                    >
                      <MotifIcon
                        size="24"
                        src={
                          changeinputType[INSTITUION_NUMBER]
                            ? MotifActionIcVisibilityOff24px
                            : MotifActionIcVisibility24px
                        }
                      ></MotifIcon>
                    </div>
                  </div>
                  {errors[INSTITUION_NUMBER] &&
                    errors[INSTITUION_NUMBER].type === "required" && (
                      <MotifErrorMessage>
  {t("pages.distributions.account.accountLabels.validationMessages.instituteNumberReq")}                      
  </MotifErrorMessage>
                    )}{" "}
                </div>
                <div className="form-field  motif-col-xs">
                  <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={TRANSIT_NUMBER_LABEL}>
                    {t("pages.distributions.account.accountLabels.transitNumber")}
                    </label>
                  </div>
                  <div className="unmaskedFiledscon">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <MotifInput
                            //@ts-ignore
                            type={
                              changeinputType[TRANSIT_NUMBER]
                                ? "text"
                                : "password"
                            }
                            className="unmaskedFiledsinput"
                            value={value}
                            onChange={onChange}
                            hideClearButton={true}
                          ></MotifInput>
                        );
                      }}
                      name={TRANSIT_NUMBER}
                    ></Controller>
                    <div
                      className="unmaskedFiledsEye"
                      onClick={() => handleInputTypeChnage(TRANSIT_NUMBER)}
                    >
                      <MotifIcon
                        size="24"
                        src={
                          changeinputType[TRANSIT_NUMBER]
                            ? MotifActionIcVisibilityOff24px
                            : MotifActionIcVisibility24px
                        }
                      ></MotifIcon>
                    </div>
                  </div>
                </div>
                <div className="form-field  motif-col-xs">
                  <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={ACCOUNT_NUMBER_LABEL}>
                    {t("pages.distributions.account.accountLabels.accountNumber")}
                    </label>
                  </div>
                  <div className="unmaskedFiledscon">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <MotifInput
                            //@ts-ignore
                            type={
                              changeinputType[ACCOUNT_NUMBER]
                                ? "text"
                                : "password"
                            }
                            className="unmaskedFiledsinput"
                            value={value}
                            onChange={onChange}
                            hideClearButton={true}
                          ></MotifInput>
                        );
                      }}
                      name={ACCOUNT_NUMBER}
                    ></Controller>
                    <div
                      className="unmaskedFiledsEye"
                      onClick={() => handleInputTypeChnage(ACCOUNT_NUMBER)}
                    >
                      <MotifIcon
                        size="24"
                        src={
                          changeinputType[ACCOUNT_NUMBER]
                            ? MotifActionIcVisibilityOff24px
                            : MotifActionIcVisibility24px
                        }
                      ></MotifIcon>
                    </div>
                  </div>
                </div>
              </div>

              <div className="motif-row clientNumberCon">
                <div className="form-field  motif-col-lg-4">
                  <ControlInputBox
                    name={CLIENT_NUMBER}
                    control={control}
                    required={false}
                    isTextArea={false}
                    label={"pages.distributions.account.accountLabels.clientNumber"}
                    showLabel={true}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </MotifModalBody>

      <MotifModalFooter>
        <Button
          label={t("global.globalActions.save")}
          variant="primary"
          className="motif-button"
          onClickHandler={handleSubmit(onSubmit)}
          type={isLoading ? "loader" : "submit"}
          disabled={isLoading}
        />
      </MotifModalFooter>
    </MotifModal>
  );
};
