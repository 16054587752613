import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	MotifModal,
	MotifModalHeader,
	MotifModalBody,
	MotifModalFooter,
	MotifButton,
} from "@ey-xd/motif-react";
import { useLazyDeleteRuleQuery } from "@/services/rules/rulesReducer";
interface DeleteRuleProps {
	show: boolean;
	setShow: (value: boolean) => void;
	id: string | undefined;
	setDeleteErrorMessage: (message: string) => void;
	ruleRefetch: () => void;
	classRefetch: () => void;
}

const DeleteRule = ({
	show,
	setShow,
	id,
	setDeleteErrorMessage,
	ruleRefetch,
	classRefetch,
}: DeleteRuleProps) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [deleteRule] = useLazyDeleteRuleQuery();

	const handleCancel = () => {
		setShow(false);
		setDeleteErrorMessage("");
	};

	const handleDelete = () => {
		setIsLoading(true);
		setDeleteErrorMessage("");
		deleteRule(id).then((response: any) => {
			if (response.data) {
				ruleRefetch();
				classRefetch();
			} else {
				setDeleteErrorMessage("Error while deleting rule");
			}
			setIsLoading(false);
			setShow(false);
		});
	};
	return (
		<MotifModal
			onClose={handleCancel}
			show={show}
			focusTrapOptions={{
				tabbableOptions: {
					displayCheck: "none",
				},
			}}
		>
			<MotifModalHeader>
				{t("pages.configuration.scheme.rule.deleteRule")}
			</MotifModalHeader>

			<MotifModalBody>
				{t("pages.configuration.scheme.rule.deleteRuleMessage")}
			</MotifModalBody>

			<MotifModalFooter>
				<MotifButton onClick={handleCancel} type={"button"} variant="secondary">
					{t("pages.configuration.scheme.rule.cancel")}
				</MotifButton>
				<MotifButton
					onClick={handleDelete}
					type={isLoading ? "loader" : "submit"}
					variant="primary"
					disabled={isLoading}
				>
					{t("pages.configuration.scheme.class.confirm")}
				</MotifButton>
			</MotifModalFooter>
		</MotifModal>
	);
};
export default DeleteRule;
