export const UNIT_LABEL = "Unit Name";
export const UNIT_NAME = "unitName";
export const UNIT_RATE_LABEL = "Rate";
export const UNIT_RATE_NAME = "unitRate";
export const UNIT_DECIMAL_PLACE_LABEL = "Decimal Place";
export const UNIT_DECIMAL_PLACE_NAME = "unitDecimalPlace";
export const UNIT_DESCRIPTION_LABEL = "Unit Description";
export const UNIT_DESCRIPTION_NAME = "unitDescription";
export const IS_DEFAULT_CURRENCY_LABEL = "Make this the default currency";
export const IS_DEFAULT_CURRENCY_NAME = "isDefaultCurrency";
export const IS_FRENCH_LABEL = "Is French";
export const IS_FRENCH_NAME = "isFrench";
export const UNIT_LABEL_FRENCH = "Unit Name (French)";
export const UNIT_NAME_FRENCH = "unitNameFrench";
export const UNIT_DESCRIPTION_LABEL_FRENCH = "Unit Description (French)";
export const UNIT_DESCRIPTION_NAME_FRENCH = "unitDescriptionFrench";

export const PRIMARY_TITLE = "Unit Setup";
export const PRIMARY_DESCRIPTION = "Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed aliquam ligula placerat massa. At interdum lectus auctor nibh dui massa tristique. Eget nisi potenti aenean ultrices nunc sit quam leo. Non risus donec et enim semper."
export const SECONDARY_TITLE = "Unit Details";
export const SECONDARY_DESCRIPTION = "Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed aliquam ligula placerat massa. At interdum lectus auctor nibh dui massa tristique. Eget nisi potenti aenean ultrices nunc sit quam leo. Non risus donec et enim semper."
