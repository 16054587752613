export const PAGE_TITLE = "Case details";
export const PAGE_DESCRIPTION =
  "Descriptive text that describes your page or content.";
export const CASE_NAME = "Case Name";
export const CASE_ID = "caseName";
export const CASE_ID_F = "caseNameFrench";
export const CASE_DESCRIPTION = "Case Description";
export const CASE_DESCRIPTION_ID = "caseDescription";
export const CASE_DESCRIPTION_ID_F = "caseDescriptionFrench";
export const CASE__SHORT_NAME = "Case Short Name";
export const CASE_SHORT_ID = "caseShortName";

export const CASE_IDENTIFIERS = "Case Identifiers";

export const CASE_TEAM_SETUP = "Case Team Setup";
export const CURRENT_TEAM_MEMEBERS = "Current Team Members";
export const TEAM_MEMBER = "Team Member";
export const ROLE = "Role";
export const statusOptions = [
  {
    label: "pages.caseSummary.fieldLabelDetails.statusOpen",
    value: "1",
  },
  {
    label: "pages.caseSummary.fieldLabelDetails.statusClose",
    value: "0",
  },
];
