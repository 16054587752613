import { Control, Controller } from "react-hook-form";
import { ClaimCreditorContactFormType } from "../ContactForm";
import { PROVINCE_STATE_NAME } from "../../../Constants";
import { useTranslation } from "react-i18next";
import SelectBox from "@/components/Form/SelectBox/SelectBox";

type ControlSelectType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<ClaimCreditorContactFormType, any>;
    name: typeof PROVINCE_STATE_NAME;
    showLabel: boolean;
    label: string;
    options: {
        label: string,
        value: string,
    }[];
    index?: number;
    filter?: boolean | ((x?: any) => void) | undefined;
    searchPlaceholder? : string;
}
 

export default function ControlSelectState ({name, control, required=false, options, showLabel, label, filter, searchPlaceholder}: ControlSelectType) {
    const {t} = useTranslation();
    let defaultOption = {
        label: t("global.globalActions.optionDefault"),
        value: "",
      };
    if (options === undefined) {
        options =[defaultOption];
    }
  
    const translatedOptions = options.map(option => { 
        return {
            label: option.label,
            value: option.value,
        };
    })
    return (
        <> 
            {
                showLabel && <div className="formFieldLabel">
                    <label  htmlFor="input" aria-label={label}>{t(label)}{ required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
           <Controller
                name={name}
                control={control}
                rules={{ required }}
                defaultValue={translatedOptions[0]?.value}
                render={({ field: { onChange, value }}) => {
                            if (translatedOptions[0]?.value === undefined || translatedOptions[0]?.value === "" || translatedOptions[0]?.value === null) {
                                value = ""
                            }
                            return (
                            <>
                                <SelectBox   options={translatedOptions} value={value} onChange={onChange} filter={filter} searchPlaceholder={searchPlaceholder}/>
                            </>
                        );
                    }}
            />
        </>
        
    );
}
