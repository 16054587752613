import { MotifButton, MotifIcon } from "@ey-xd/motif-react";
import { IconoirEditPencil, IconoirTrash } from "@ey-xd/motif-icon";
import { Link } from "react-router-dom";
import { havingDeletePermission, havingEditPermission } from "@/utility/common";

export const useCustomCellRenderers = (
  isVisible: any,
  setShowModal: any,
  setActiveClaim: any,
  setDebtorId: any,
  handleEditClick: any,
  Debtor: string[]
) => {
  const caseRenderers = {
    actionCellRenderers: function (param: any) {
      return (
        <>
          <div className="pad-r-10">
            {  havingDeletePermission(Debtor) && 
            <MotifButton
              size="small"
              variant="primary"
              onClick={() => {
                setShowModal(true);
                setActiveClaim(param.data.totalClaims);
                setDebtorId(param.data.debtorId);
              }}
              // disabled={param.data.isActive == true ? false : true}
            >
              <MotifIcon
                iconFunction={IconoirTrash}
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
            }
          </div>
          { havingEditPermission(Debtor) &&
            <Link to={`./debtor/edit/` + param.data.debtorId}>
            <MotifButton
              onClick={() => {
                setShowModal(true);
                setActiveClaim(param.data.totalNumberOfClaims);
                setDebtorId(param.data.creditorId);
                handleEditClick();
              }}
              size="small"
              type="submit"
              variant="primary"
              // disabled={param.data.isActive == true ? false : true}
            >
              <MotifIcon
                iconFunction={IconoirEditPencil}
                // fill="#2E2E38"
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
          </Link>
          }
        </>
      );
    },
  };
  return caseRenderers;
};
