import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AttributesItemsState {
  attributes: any[];
}

const initialState = {
  data: {
    showModal: false,
    editMode: false,
    navPath: "",
    navState:null,
    cancelButtunTriggered:false
  },
};

const discardChangesData = createSlice({
  name: "discardChangesDatas",
  initialState,
  reducers: {
    setDiscardChangesData: (state, action: PayloadAction<any>) => {
      state.data = { ...action.payload };
    },
    setDiscardEditMode: (state, action: PayloadAction<any>) => {
      state.data = { ...state.data,...action.payload };
    },
  },
});
export const { setDiscardChangesData,setDiscardEditMode } = discardChangesData.actions;
export default discardChangesData.reducer;
