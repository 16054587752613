import { useDispatch, useSelector } from "react-redux";
import Table from "@/components/Table/table";
import TitleComplement from "@/features/creditor/claimsTab/components/titleComplement";
import BasicSearch from "../../claims/claimsTab/components/basicSearch";
import { caseRenderers } from "@/features/creditor/claimsTab/Constant/creditorCustomCellConst";
import { useColumnDefs } from "@/features/creditor/claimsTab/Constant/creditorHeaderConst";
import { useGetCreditorClaimListApiDataQuery, useLazyGetCreditorClaimListByCaseQuickSearchQuery } from "@/services/cases/casesReducer";
import { MotifErrorMessage, MotifProgressLoader } from "@ey-xd/motif-react";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {setShowBasicSeach} from "@/features/claims/claimsSlice";
import { useCase } from "@/features/case/use-case";
import { useTranslation } from "react-i18next";

export default function ClaimTabContent() {
  const { caseId, creditorId } = useParams();
  const {t}=useTranslation()
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState(""); 
  const [isSearchActive, setIsSearchActive] = useState(false);
  const caseData = useCase();
  const abbreviation = caseData?.abbreviation; 
  const columnDefs = useColumnDefs({ caseId, creditorId, abbreviation });
  const { data, error, isLoading, refetch } = useGetCreditorClaimListApiDataQuery({
    caseId,
    creditorId,
  });

   const [
      triggerSearch,
      {
        data: searchClaimDataByCase,
        isLoading: searchClaimDataByCaseLoading,
        isFetching: isSearchClaimDataByCaseFetching,
      },
    ] = useLazyGetCreditorClaimListByCaseQuickSearchQuery();

  const showBasicSearch = useSelector(
    (state: any) => state.claims.showBasicSearch
  );

  useEffect(() => {
    if (showBasicSearch) {
      dispatch(setShowBasicSeach(false));
    }
  }, [dispatch, showBasicSearch]);
  
  const handleSearch = useCallback(
    (searchValue: string) => {
      setSearchQuery(searchValue);
      if (searchValue.trim() === "") {
        setIsSearchActive(false);
      } else {
        triggerSearch({
          CaseId: caseId,
          CreditorId: creditorId,
          SearchText: searchValue,
        })
          .unwrap()
          //@ts-ignore
          .catch((error) => {
            console.error("Search failed:", error);
          });
        setIsSearchActive(true);
      }
    },
    [triggerSearch, caseId, creditorId]
  );

  const handleQuickSearchClear = useCallback(() => {
    setSearchQuery("");
    setIsSearchActive(false);
    refetch();
  }, [refetch]);

  const headerData = {
    title: "pages.creditors.creditorClaimTab.tableTitle",
    description: "pages.creditors.creditorClaimTab.tableDescription",
  };

  if (searchClaimDataByCaseLoading) {
    return <MotifProgressLoader show variant="default" />;
  }
  if (!searchClaimDataByCaseLoading && isSearchClaimDataByCaseFetching) {
    return <MotifProgressLoader show variant="default" />;
  }

  const tableData = isSearchActive ? searchClaimDataByCase  : data;

  return (
    <>
      <div
        className={
          "ccwp_table_tabpanel " + (showBasicSearch ? "hide_claims_table" : "")
        }
      >
        {error ? (
          <MotifErrorMessage
            style={{
              textAlign: "center",
              display: "block",
              alignItem: "center",
            }}
          >
            {t("global.globalNotifications.wentWrong")}
          </MotifErrorMessage>
        ) : isLoading ? (
          <MotifProgressLoader show variant="default" />
        ) : tableData ? (
          <Table
            columnDefs={columnDefs}
            cellRenderers={tableData}
            TitleComplement={() => (<TitleComplement 
              onSearch={handleSearch}
              caseId={caseId || ""}
              handleQuickSearchClear={handleQuickSearchClear}
              searchQuery={searchQuery} 
              isBasicSearch={false} 
              handleClearBasicSearch={() => {}}           
            />)}
            title={headerData.title}
            description={headerData.description}
            caseRenderers={caseRenderers}
            overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
          />
        ) : null}
      </div>
      {showBasicSearch ? <BasicSearch onApply={() =>{}}/> : <></>}
    </>
  );
}