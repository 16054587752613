import { IMSALAccessToken } from "@/features/auth/user-slice";

export enum MSALCacheScope {
  openid = "openid",
  default = "default",
}

export default function getMSALCache(
  cacheName: string = "accesstoken",
  scope: string = "default"
): null | IMSALAccessToken {
  const cacheKey: string | undefined = Object.keys(sessionStorage).find(
    (key) => key.includes(cacheName) && key.includes(scope)
  );
  if (cacheKey) {
    const cacheContent: string | null = sessionStorage.getItem(cacheKey);
    if (cacheContent) {
      const content: IMSALAccessToken = JSON.parse(cacheContent);
      return content;
    }
  }
  return null;
}
