import React, { useEffect, useState } from "react";
import { MotifErrorMessage } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "@/components/Form/Button/Button";
import {  SECONDARY } from "@/Constant";
import "./FormModal.scss";
import { isNumeric } from "@/utility/validation";
import ControlInputBoxNumber from "@/components/Form/InputBox/ControlInputBoxNumber";
import ControlSelect from "./SelectBox/editFieldSelectBox";
import {
  useCreatePayeeTypeMutation,
  useEditPayeeTypeMutation,
  // useGetPayeeTypeApiDataQuery,
} from "../calculationStepReducer";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
// import { renderOptionList } from "../Form/calculationSummaryHelper";

type Props = {
  createShow: boolean;
  setCreateShow: React.Dispatch<React.SetStateAction<boolean>>;
  caseId: string;
  stepId: string;
  refetch: any;
  editId: any;
  payeeTypeSummary: any;
};

export default function FormModalResultSetting({
  setCreateShow,
  stepId,
  refetch,
  editId,
  payeeTypeSummary,
}: Props) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleCancel, setIsVisibleCancel] = useState(false);
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });
  // const { data: resultSetting } = useGetPayeeTypeApiDataQuery(caseId);
  // const resultSettingList = renderOptionList(
  //   resultSetting?.results,
  //   "name",
  //   "id"
  // );
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<any>();

  const [createPayeeType] = useCreatePayeeTypeMutation();
  const [editPayeeType] = useEditPayeeTypeMutation();

  const onSubmit = async (data: any) => {
    let formData = {
      calculationStepId: stepId,
      denomination: Number(data?.issuesInMultipleOf),
      minimumIssueAmount: Number(data?.minIssueAmount),
      rounding: Number(data?.rounding),
      // payeeTypeId: data?.payeeType,
      // paymentGrouping: Number(data?.paymentGrouping),
    };
    let editFormData = {
      calculationStepId: stepId,
      denomination: Number(data?.issuesInMultipleOf),
      minimumIssueAmount: Number(data?.minIssueAmount),
      rounding: Number(data?.rounding),
      // payeeTypeId: data?.payeeType,
      // paymentGrouping: Number(data?.paymentGrouping),
    };
    setIsLoading(true);
    if (editId !== "") {
      const response = await editPayeeType(editFormData);
      if (response.hasOwnProperty("data")) {
        setCreateShow(false);
        setIsLoading(false);
        refetch();
      } else {
        setIsLoading(false);
        setCreateShow(false);
      }
    } else {
      const response = await createPayeeType(formData);
      if (response.hasOwnProperty("data")) {
        setCreateShow(false);
        setIsLoading(false);
        refetch();
      } else {
        setIsLoading(false);
        setCreateShow(false);
      }
    }
  };
  useEffect(() => {
    setValue("issuesInMultipleOf", payeeTypeSummary?.denomination);
    setValue("minIssueAmount", payeeTypeSummary?.minimumIssueAmount);
    setValue("rounding", payeeTypeSummary?.rounding?.toString());
    // setValue("payeeType", payeeTypeSummary?.payeeTypeId);
    setValue("paymentGrouping", payeeTypeSummary?.paymentGrouping?.toString());
  }, [payeeTypeSummary, setValue]);
  useEffect(() => {
    if (payeeTypeSummary?.paymentGrouping === null) {
      setValue("rounding", "0");
      setValue("paymentGrouping", "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId]);
  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setCreateShow(false);
    setIsVisibleCancel(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisibleCancel(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };
  return (
    <>
      {/*@ts-ignore*/}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"formModalWrapper"}>
          <div className="formModal">
            <div className="motif-row">
              <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
                <ControlInputBoxNumber
                  name={"issuesInMultipleOf"}
                  // @ts-ignore
                  control={control}
                  isTextArea={false}
                  label={"pages.distributions.distributionStepsTab.resultSettingTab.formLabels.multipleIssue"}
                  showLabel={true}
                  isNumber={true}
                  digitAfterDecimal={2}
                  isAmount={true}
                  onChangeAmount={() => {}}
                  required={true}
                />
                {errors["issuesInMultipleOf"] &&
                  errors["issuesInMultipleOf"].type === "required" && (
                    <MotifErrorMessage>
{t("pages.distributions.distributionStepsTab.resultSettingTab.validationMessage.multipleIssueRequired")}                    </MotifErrorMessage>
                  )}
              </div>
              <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
                <ControlSelect
                  name={"rounding"}
                  //@ts-ignore
                  control={control}
                  required={true}
                  showLabel={true}
                  readonly={true}
                  label={"pages.distributions.distributionStepsTab.resultSettingTab.formLabels.rounding"}
                  //@ts-ignore
                  placeholder={"placeholder"}
                  options={[
                    { label: t("pages.distributions.distributionStepsTab.resultSettingTab.roundingOptions.label"), value: "" },
                    { label: t("pages.distributions.distributionStepsTab.resultSettingTab.roundingOptions.label1"), value: "0" },
                    { label: t("pages.distributions.distributionStepsTab.resultSettingTab.roundingOptions.label2"), value: "1" },
                    { label: t("pages.distributions.distributionStepsTab.resultSettingTab.roundingOptions.label3"), value: "2" },
                  ]}
                />
                {errors["rounding"] &&
                  errors["rounding"].type === "required" && (
                    <MotifErrorMessage>
{t("pages.distributions.distributionStepsTab.resultSettingTab.validationMessage.roundRequired")}                   
                      </MotifErrorMessage>
                  )}
              </div>
              <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4">
                <ControlInputBoxNumber
                  name={"minIssueAmount"}
                  // @ts-ignore
                  control={control}
                  isTextArea={false}
                  label={"pages.distributions.distributionStepsTab.resultSettingTab.formLabels.minimumIssueAmount"}
                  showLabel={true}
                  isNumber={true}
                  digitAfterDecimal={2}
                  isAmount={true}
                  onChangeAmount={() => {}}
                  validate={isNumeric}
                  required={true}
                />
                {errors["minIssueAmount"] &&
                  errors["minIssueAmount"].type === "required" && (
                    <MotifErrorMessage>
{t("pages.distributions.distributionStepsTab.resultSettingTab.validationMessage.minimumIssueRequired")}                   
</MotifErrorMessage>
                  )}
              </div>
              {/* <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4 mar-t-20">
                <ControlSelect
                  name={"payeeType"}
                  //@ts-ignore
                  control={control}
                  required={true}
                  showLabel={true}
                  readonly={true}
                  label={t("Payee Type")}
                  //@ts-ignore
                  placeholder={"placeholder"}
                  options={resultSettingList}
                  getValues={getValues}
                />
                {errors["payeeType"] &&
                  errors["payeeType"].type === "required" && (
                    <MotifErrorMessage>
                      Payee Type is required
                    </MotifErrorMessage>
                  )}
              </div> */}
              {/* <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4 mar-t-20">
                <ControlSelect
                  name={"paymentGrouping"}
                  //@ts-ignore
                  control={control}
                  required={true}
                  showLabel={true}
                  readonly={true}
                  label={t("Payment Grouping")}
                  //@ts-ignore
                  placeholder={"placeholder"}
                  options={[
                    { label: "Select Payment Grouping", value: "" },
                    { label: "Split by Creditor", value: "0" },
                    { label: "Split by Payee", value: "1" },
                    { label: "Claim Owner", value: "2" },
                  ]}
                />
                {errors["paymentGrouping"] &&
                  errors["paymentGrouping"].type === "required" && (
                    <MotifErrorMessage>
                      Payee Type is required
                    </MotifErrorMessage>
                  )}
              </div> */}
            </div>
          </div>
          <div className="button-container-add">
            <Button label={t("global.globalActions.save")} disabled={isLoading} />
            <Button
              label={t("global.globalActions.cancel")}
              type="button"
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={() => {
                setIsVisibleCancel(true);
                // setCreateShow(false);
              }}
            />
          </div>
        </div>
      </form>
      <ConfirmationModal
        isVisible={isVisibleCancel}
        setIsVisible={setIsVisibleCancel}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
    </>
  );
}
