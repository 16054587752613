import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {
  setUser,
  IUserState,
  IMSALAccessToken,
} from "@/features/auth/user-slice";
import getMSALCache from "@/features/auth/lib/getMSALCache";
import {
  useLazyGetUserPhotoQuery,
  useCreateUserSessionMutation,
} from "./user-api";
import { useLocation, useNavigate } from "react-router-dom";
import config from "@/config";
import { SilentRequest } from "@azure/msal-browser";
import logger from "@/libs/logger";
import { setNotification } from "../form-setup/formSetupSlice";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

const { backend, features } = config;

export const useUser = () => {
  const { accounts, instance } = useMsal();
  const userState: IUserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const [getUserPhoto, { data: photo }] = useLazyGetUserPhotoQuery();
  const [createUserSession] = useCreateUserSessionMutation();
  const [sessionPopupVisible, setSessionPopupVisible] = useState(false);
  const sessionExpiration = sessionStorage.getItem("sessionExpiration");
  const popupDismissed = sessionStorage.getItem("popupDismissed");
  const location = useLocation();
  const navigate = useNavigate();

  const debug = false; // set to true to enable debug logs
  const warningTime = debug ? 58 * 60 : 5 * 60; // 5 minutes

  const fetchAccessToken = useCallback(
    async (scope: "graph" | "backend") => {
      if (!accounts || !accounts[0]) {
        return;
      }

      const scopes = {
        graph: ["profile openid email User.Read User.ReadBasic.All"],
        backend: [backend.scope],
      };

      const request: SilentRequest = {
        scopes: scopes[scope],
        forceRefresh: debug || scope === "backend",
        account: {
          homeAccountId: accounts[0].homeAccountId,
          environment: accounts[0].environment,
          tenantId: accounts[0].tenantId,
          username: accounts[0].username,
          localAccountId: accounts[0].localAccountId,
        },
      };

      try {
        await instance.acquireTokenSilent(request);
        if (scope === "graph") {
          return getMSALCache();
        }
        if (scope === "backend") {
          return getMSALCache("accesstoken", "default");
        }
      } catch (error) {
        dispatch(
          setNotification({
            type: "error",
            notifications: {
              message: "Session expired. Logging out in 3 seconds.",
            },
          })
        );
        //setting discrad popup to false if session is getting expires
        dispatch(setDiscardEditMode({ editMode: false }));
        setTimeout(() => {
          sessionStorage.clear();
          navigate("/logout");
        }, 3000);
      }
      return null;
    },
    [accounts, instance, dispatch, debug, navigate]
  );

  // triggers pop-up when session is a set duration (warningTime) away from expiring
  useEffect(() => {
    let checkInterval = setInterval(() => {
      const sessionExpiration: null | string =
        sessionStorage.getItem("sessionExpiration") || "";

      // if the user has dismissed the popup, don't show it again until next session expire
      if (sessionStorage.getItem("popupDismissed") === "false") {
        if (
          sessionExpiration &&
          Date.parse(sessionExpiration) / 1000 -
            Math.round(Date.now() / 1000) <=
            warningTime
        ) {
          //setting discrad popup to false if session is getting expires
          dispatch(setDiscardEditMode({ editMode: false }));

          setSessionPopupVisible(true);
        }
      }

      // if the session has expired, log the user out
      if (
        Date.parse(sessionExpiration) / 1000 - Math.round(Date.now() / 1000) <=
        0
      ) {
        //setting discrad popup to false if session is  expires
        dispatch(setDiscardEditMode({ editMode: false }));

        navigate("/logout");
      }
    }, 5000); // check session every 5 second

    return () => {
      clearInterval(checkInterval);
    };
  }, [sessionExpiration, popupDismissed, warningTime, navigate, dispatch]);

  useEffect(() => {
    // do nothing on logout page
    if (location.pathname.includes("logout")) {
      return;
    }
    if (accounts.length > 0 && userState && !userState.id) {
      const currentUser: IUserState = {
        id: accounts[0].localAccountId,
        name: accounts[0].name,
        email: accounts[0].username,
        accessToken: undefined,
        backendAccessToken: undefined,
        sessionActive: false,
      };
      const accessToken: null | IMSALAccessToken = getMSALCache(
        "accesstoken",
        "openid"
      );

      setTimeout(() => {
        if (accessToken) {
          currentUser.accessToken = accessToken;
          Promise.all([
            fetchAccessToken("backend"),
            getUserPhoto("")
              .unwrap()
              .catch(() => null),
          ]).then(async ([backendAccessToken, photo]) => {
            if (backendAccessToken) {
              currentUser.backendAccessToken = backendAccessToken;
            }
            if (photo) {
              currentUser.photo = photo;
            }

            if (features.session) {
              const session = await createUserSession()
                .unwrap()
                .catch(() => null);
              if (session) {
                currentUser.session = session;
                currentUser.sessionActive = true;
                sessionStorage.setItem("sessionStart", session.sessionStart);
                sessionStorage.setItem(
                  "sessionExpiration",
                  session.sessionExpiration
                );
              }
              if (debug) {
                //setting discrad popup to false if session is getting expires
                dispatch(setDiscardEditMode({ editMode: false }));

                setSessionPopupVisible(true);
              }
            }

            dispatch(setUser(currentUser));
          });
        }
        // fix pca not initialized error by delaying the call
      }, 200);
    }
  }, [
    accounts,
    userState,
    dispatch,
    fetchAccessToken,
    getUserPhoto,
    createUserSession,
    location.pathname,
    navigate,
    debug,
  ]);

  const setSessionTimers = useCallback(
    async (renewNow = false) => {
      // if the token has expired, refresh and try to start a session again
      const sessionRenewThreshold = 30; // in minutes
      const sessionExpiringInMins =
        (Number(sessionStorage.getItem("sessionExpiration")) -
          Date.now() / 1000) /
        60;

      if (!renewNow) {
        if (features.session && userState && userState.accessToken) {
          // if session is still active for more than 5 min, do nothing

          if (sessionExpiringInMins >= sessionRenewThreshold) {
            return;
          }
        }
      }

      logger.info("session is expiring soon, renewing session");
      const currentUser = { ...userState };

      const accessTokenExpiresInMin =
        (Number(currentUser.accessToken?.extendedExpiresOn) -
          Date.now() / 1000) /
        60;
      const accessTokenExpiring = sessionExpiringInMins < sessionRenewThreshold;

      if (debug || (currentUser && accessTokenExpiring)) {
        logger.info(
          `session is expiring in ${accessTokenExpiresInMin} minutes, renewing graph api token.`
        );
        const newBackendToken = await fetchAccessToken("backend");
        const newToken = await fetchAccessToken("graph");

        if (newToken && newBackendToken) {
          currentUser.accessToken = newToken;
          currentUser.backendAccessToken = newBackendToken;
          logger.info("renewed graph and backend token", currentUser);
        }
      }

      const session = await createUserSession()
        .unwrap()
        .catch(() => {
          // cannot renew session, logs user out
          dispatch(
            setNotification({
              type: "error",
              notifications: {
                message: "Session expired. Logging out in 3 seconds.",
              },
            })
          );
         //setting discrad popup to false if session is getting expires
         dispatch(setDiscardEditMode({ editMode: false }));

          setTimeout(() => {
            navigate("/logout");
          }, 3000);
        });

      if (session?.sessionStart && session?.sessionExpiration) {
        currentUser.session = session;
        setSessionPopupVisible(false);
        dispatch(setUser(currentUser));

        sessionStorage.setItem("popupDismissed", "false");
        sessionStorage.setItem("sessionStart", session.sessionStart);
        sessionStorage.setItem("sessionExpiration", session.sessionExpiration);
      }
    },
    [createUserSession, userState, dispatch, fetchAccessToken, debug, navigate]
  );

  // sets an interval of 5 mins to collect number of clicks or touch
  // when timer ends, if the user has more than 0 clicks or touch
  // then extend the session
  useEffect(() => {
    let activity = 0;
    const timer = debug ? 2000 : 5 * 60 * 1000; // 5 minutes
    const increment = () => {
      activity++;
    };
    let sessionTimer = setInterval(() => {
      if (activity > 0) {
        logger.info("user is active");
        setSessionTimers(true);
        activity = 0;
      }
    }, timer);

    document.addEventListener("click", increment);
    document.addEventListener("touchstart", increment);

    return () => {
      activity = 0;
      clearInterval(sessionTimer);
      document.removeEventListener("click", increment);
      document.removeEventListener("touchstart", increment);
    };
  }, [setSessionTimers, debug]);

  return {
    user: userState,
    photo,
    setSessionTimers,
    sessionPopupVisible,
    setSessionPopupVisible,
    warningTime,
  };
};

export default useUser;
