import React, { useEffect } from "react";
import { Control, Controller, useController, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import SelectBox from "@/components/Form/SelectBox/SelectBox";
import { ClaimSetupClaimItemsType } from "./type";

type ControlSelectType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<ClaimSetupClaimItemsType>;
    name: "fieldArray" | "claimItemsCompleteDetail" | `fieldArray.${number}` | `fieldArray.${number}.categoryName` | `fieldArray.${number}.natureOfClaimName` | `fieldArray.${number}.claimAmount` | `fieldArray.${number}.currency` | `fieldArray.${number}.showAssigneeSearch` | `fieldArray.${number}.assigneeData` | `fieldArray.${number}.id`;
    showLabel: boolean;
    label: string;
    options: {
        label: string,
        value: string,
    }[];
    setValue: UseFormSetValue<ClaimSetupClaimItemsType>;
    getValues: UseFormGetValues<ClaimSetupClaimItemsType>;
    defaultValue?: string;
    visibleOptions?: number;
    filter?: boolean;
    searchPlaceholder?: string;
    multiple?: boolean;
    onChange?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function ControlSelect({ name, control, required = false, options, showLabel, label, defaultValue,visibleOptions, setValue, filter,searchPlaceholder, multiple = false, onChange}: ControlSelectType) {
    const {
        field,
    } = useController({
        name,
        control,
        rules: { required: required },
        defaultValue: defaultValue,
    });
    const { t } = useTranslation();
    const translatedOptions = options.map(option => {
        return {
            label: option.label,
            value: option.value,
        };
    })
    useEffect(() => {
        if (defaultValue) {
            setValue(name as "fieldArray" | `fieldArray.${number}` | `fieldArray.${number}.categoryName` | `fieldArray.${number}.natureOfClaimName` | `fieldArray.${number}.claimAmount` | `fieldArray.${number}.currency` | `fieldArray.${number}.showAssigneeSearch` | `fieldArray.${number}.assigneeData` | `fieldArray.${number}.id`, defaultValue);
        }
    }, [name, defaultValue, setValue]);
    return (
        <>
            {
                showLabel && <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={label}>{t(label)}{required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
            <Controller
                name={field.name}
                control={control}
                rules={{ required }}
                defaultValue={defaultValue ? defaultValue : options[0]?.value}
                render={({ field: { onChange, value, ref } }) => {
                    return (
                        <>
                            {/*@ts-ignore */}
                            <SelectBox multiple={multiple} filter={filter} searchPlaceholder={searchPlaceholder} visibleOptions={visibleOptions} options={translatedOptions} value={value} onChange={onChange} ref={ref} />
                        </>
                    );
                }}
            />
        </>

    );
}