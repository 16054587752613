import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@/components/Form/Button/Button";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import ControlSelectCountry from "./ControlSelectCountry";
import {
  MotifErrorMessage,
  MotifProgressLoader,
} from "@/libs/@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import { SECONDARY } from "@/Constant";
import { useForm } from "react-hook-form";
import ControlSelect from "../../Claim/ClaimDetails/ControlSelect";
import ControlInputBox from "../../Creditor/CreditorSetup/Form/ControlInputBox";
import {
  useCreatePayeeTypeDataMutation,
  useEditPayeeTypeDataMutation,
  useLazyGetPayeeTypeSummaryDataApiDataQuery,
} from "@/features/distributions/steps/calculationStepReducer";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import ContactSelect from "../../Claim/claimSetup/Creditor/Components/ContactFormFields/ContactSelect";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useGetCountryStateListDataQuery } from "@/services/countryState/countryStateReducer";
import { countryRecordType } from "../../Creditor/CreditorSetup/type";
import Banner, { BannerElement } from "@/components/Banner/Banner";
import { MotifIcon, MotifAccordion,MotifAccordionContent,MotifAccordionTrigger } from "@ey-xd/motif-react";
import ControlSelectState from "./ControlSelectState";
import { dateFunc } from "@/components/Functions/dateFunc";

import {
  ACCOUNT_NUMBER,
  ACH_CODE,
  ADDRESS1,
  ADDRESS2,
  CITY,
  COUNTRY,
  EMAIL,
  FAX,
  FIRST_NAME,
  IBAN_NUMBER,
  INSTITUTION_NUMBER,
  LAST_NAME,
  POSTAL_CODE,
  PREFIX,
  ROUTING_CODE,
  STATE,
  SWIFT_CODE,
  TRANSIT_NUMBER,
} from "../../Creditor/CreditorSetup/Constants";
import { useLazyGetProvincesQuery } from "@/services/creditors/creditorsReducers";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
import PaymentInputField from "../../Creditor/CreditorSetup/PaymentInputField";
export default function PayeeTypeForm() {
  const { t } = useTranslation();
  const parameter = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultPayeeLevel = searchParams.get('payeeLevel');
  const PREFIX_OPTIONS_LIST = [
    { 
        label: t("global.globalActions.optionDefault"), 
        value: "" },
    {
        label:t("global.globalActions.prefixOptionsListLabels.mr"),
        value: "Mr.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.ms"),
        value: "Ms.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.mrs"),
        value: "Mrs.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.miss"),
        value: "Miss.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.other"),
        value: "other",
    }
]
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    setError,
    reset,
    formState: { errors ,isDirty},  
  } = useForm<any>({
    defaultValues:{
      payeeLevel: defaultPayeeLevel?.toString() === "0" ? "0":"",
      "name": "",
      "nameFr": "",
      "desc": "",
       "prefix":"",
      "firstName":"",
      "lastName":"",
      "email":"",
      "address1":"",
      "address2":"",
      "city":"",
      "postalCode":"",
      "country":"",
      "state":"",
      "phoneNumber":"",
      "fax":"",
    }
  });
  const [showContactsSection, setshowContactsSection] = useState(false);
  const [getProvinces] = useLazyGetProvincesQuery();
  // const [getVotes, { data: votersData, isLoading, isFetching }]
  const [
    getSummary,
    { data: payeeTypeSummary, isLoading: payeeTypeSummaryLoading },
  ] = useLazyGetPayeeTypeSummaryDataApiDataQuery();
  const { data: countryList } = useGetCountryStateListDataQuery("");

  const [createPayeeType, { isLoading: payeeCreateLoading }] =
    useCreatePayeeTypeDataMutation();
  const [editPayeeType, { isLoading: payeeUpdateLoading }] =
    useEditPayeeTypeDataMutation();
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);
  const [countryArray, setCountryArry] = useState([
    {
      label: t("global.globalActions.optionDefault"),
      value: "",
    },
  ]);

  const [stateListBycounrty, setStateListBycounrty] = useState([
    {
      label:  t("global.globalActions.optionDefault"),
      value: "",
    },
  ]);
  const [PaymentDetailsOpen, setPaymentDetailsOpen] = useState(false);
  const [showField, setShowField] = useState<any>({})

  const dispatch=useDispatch()
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,isDirty]);
  const payeeLevel = watch("payeeLevel");

  const onChangeHandlerCountry = useCallback(
    async (index: number, countryId: string) => {
      if (!countryId) {
        return;
      }

      // @ts-ignore
      const responseApi = await getProvinces(countryId).unwrap();
      if (responseApi.length > 0) {
        let stateListBycounrty: { label: string; value: string }[] = [];
        // eslint-disable-next-line array-callback-return

        responseApi.forEach(
          (stateObj: { id: string; name: string; code: string }) => {
            stateListBycounrty.push({
              label: stateObj.name,
              value: stateObj.id,
            });
          }
        );

        setStateListBycounrty(stateListBycounrty);
      } else {
        setStateListBycounrty([]);
        setValue("stateoptions", "");
        setValue("state", null);
      }
    },
    [getProvinces, setStateListBycounrty, setValue]
  );
  useEffect(() => {
    if (parameter?.payeeId) {
      getSummary(parameter?.payeeId);
    }
  }, [parameter?.payeeId, getSummary]);

  const defaultOption = useMemo(
    () => ({
      label: t("global.globalActions.optionDefault"),
      value: "",
    }),
    [t]
  );

  useEffect(() => {
    if(parameter?.payeeId){
          if (Number(payeeTypeSummary?.payeeLevel) === 1) {

      reset({
        "name": payeeTypeSummary?.name,
    "nameFr": payeeTypeSummary?.nameFrench,
    "payeeLevel": payeeTypeSummary?.payeeLevel.toString(),
    "desc": payeeTypeSummary?.description,
      "prefix": payeeTypeSummary?.prefix,
      "firstName": payeeTypeSummary?.firstName,
      "lastName": payeeTypeSummary?.lastName,
      "email": payeeTypeSummary?.email,
      "address1": payeeTypeSummary?.address1,
      "address2": payeeTypeSummary?.address2,
      "city": payeeTypeSummary?.city,
      "postalCode": payeeTypeSummary?.postalCode,
      "country": payeeTypeSummary?.countryId,
      "state": payeeTypeSummary?.provinceStateId,
      "phoneNumber": payeeTypeSummary?.phone,
      "fax": payeeTypeSummary?.fax,
      transitNumber:  payeeTypeSummary?.transitNumber,
      ibanNumber:payeeTypeSummary?.ibanNumber,
      institutionNumber:payeeTypeSummary?.institutionNumber,
      routingCode: payeeTypeSummary?.routingCode,
      swiftCode:payeeTypeSummary?.swiftCode,
      achCode:payeeTypeSummary?.achCode,
      accountNumber:payeeTypeSummary?.accountNumber,
    
      })
    }
    else{
      reset({
        "name": payeeTypeSummary?.name,
    "nameFr": payeeTypeSummary?.nameFrench,
    "payeeLevel": payeeTypeSummary?.payeeLevel.toString(),
    "desc": payeeTypeSummary?.description,
    
      })
    }
 
    onChangeHandlerCountry(0, payeeTypeSummary?.countryId);
  }
  }, [payeeTypeSummary, setValue, onChangeHandlerCountry,reset, parameter?.payeeId]);

  
  useEffect(() => {
    if (payeeLevel === "1") setshowContactsSection(true);
    else setshowContactsSection(false);
  }, [payeeLevel]);

  const onSubmit = async (data: any) => {
    if(data.payeeLevel === "0" && !data.name.trim()){
      setError("name", {
        type: "required",
      });
      return
    }

    if(data.payeeLevel === "1" &&( !data.name.trim()|| !data.firstName.trim()||!data.lastName.trim())){
      if(!data.name.trim()){
        setError("name", {
          type: "required",
        });
      }
      if(!data.firstName.trim()){
        setError("firstName", {
          type: "required",
        });
      }
      if(!data.lastName.trim()){
        setError("lastName", {
          type: "required",
        });
        
      }
      return;
    }

    let contactDetailsData = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      prefix: data?.prefix,
      address1: data?.address1,
      address2: data?.address2,
      city: data?.city,
      postalCode: data?.postalCode,
      countryId: data.country || null,
      provinceStateId: data.state || null,
      phone: data.phoneNumber,
      fax: data?.fax,
      email: data?.email,
      transitNumber:  data?.transitNumber,
      ibanNumber:data?.ibanNumber,
      institutionNumber:data?.institutionNumber,
      routingCode: data?.routingCode,
      swiftCode:data?.swiftCode,
      achCode:data?.achCode,
      accountNumber:data?.accountNumber,
    };

    let formData = {
      caseId: parameter?.caseId,
      name: data?.name,
      nameFrench: data?.nameFr,
      description: data?.desc,
      payeeLevel: Number(data?.payeeLevel),
      ...contactDetailsData,
    };

    let EditData = {
      id: parameter?.payeeId,
      name: data?.name,
      nameFrench: data?.nameFr,
      payeeLevel: Number(data?.payeeLevel),
      description: data?.desc,
      singlePayeeContactId: payeeTypeSummary?.singlePayeeContactId,
      ...contactDetailsData,
    };
    dispatch(setDiscardEditMode({ editMode: false }));

    if (parameter?.payeeId) {
      editPayeeType(EditData)
        .unwrap()
        .then(() => {
          getSummary(parameter?.payeeId);
          reset(getValues(),{keepDirty: false});
        })
        .catch((error) => {
          dispatch(setDiscardEditMode({ editMode: true }));

        });
    } else {
      createPayeeType(formData)
        .unwrap()
        .then((res: any) => {
          navigate(
            `/case/${parameter?.caseId}/configuration/payee/edit/${res.payeeTypeId}`
          );
        })
        .catch((error) => {
          dispatch(setDiscardEditMode({ editMode: true }));

        });
    }
  };
  useEffect(() => {
    if (countryList !== undefined) {
      // eslint-disable-next-line array-callback-return
      const countryOptions = countryList.map((record: countryRecordType) => {
        return {
          label: record.countryName,
          value: record.countryId,
        };
      });
      // setCountryArry(countryOptions);
      setCountryArry([defaultOption, ...countryOptions]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [payeeTypeSummaryLoading, countryList, defaultOption]);


  const handleViewIcon = (name: string) => {
    setShowField((prev: any) => ({ ...prev, [name]: !prev[name] }));
  };

  const bannerElement: BannerElement[] = [
    {
      label: "pages.distributions.payeeType.bannerDetails.label1",
      value:
        payeeTypeSummary?.payeeLevel === 1
          ? t("pages.distributions.payeeType.bannerDetails.case")
          : payeeTypeSummary?.payeeLevel === 0
          ? t("pages.distributions.payeeType.bannerDetails.creditor")
          : "-",
      elementKey: "caseID",
    },
    {
      label: "pages.distributions.payeeType.bannerDetails.label2",
      value:  dateFunc(payeeTypeSummary?.lastUpdatedDate).formattedDateTime || "-",
      elementKey: "caseID",
    },
  ];
  const breadcrumbItems = [
    {
      label: "External Submission",
      value: "10 New Creditor Submission",
      elementKey: "submission",
      text: t("global.globalActions.back"),
      url: `/case/${parameter?.caseId}/?activeTab=6`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];

  return (
    <>
      <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />

      <Banner
        bannerName={"pages.distributions.payeeType.bannerDetails.title"}
        bannerNameValue={payeeTypeSummary?.name}
        elements={bannerElement}
      />
      <div className="motif-container case-summary-form pad-v-20 mar-t-20">
        <div className="motif-body1-default-regular page-title">
          {t("pages.distributions.payeeType.createPayeeTitle")}
        </div>
        <div className="motif-body2-default-regular page-description">
          {t("pages.distributions.payeeType.createPayeeDescription")}
        </div>
      </div>
      {payeeTypeSummaryLoading ? (
        <MotifProgressLoader show />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`motif-container main-container pad-t-20 `}>
            <div className="motif-row">
              <div className="motif-col-lg-4">
                <div className="form-field">
                  <ControlInputBox
                    // @ts-ignore
                    name={"name"}
                    control={control}
                    isTextArea={false}
                    label={"pages.distributions.payeeType.formLabels.name"}
                    required={true}
                    showLabel={true}
                  />
                  {errors["name"] && errors["name"].type === "required" && (
                    <MotifErrorMessage>{t("pages.distributions.payeeType.validationMessages.nameRequired")}</MotifErrorMessage>
                  )}
                </div>
              </div>
              <div className="motif-col-lg-4">
                <div className="form-field">
                  <ControlInputBox
                    // @ts-ignore
                    name={"nameFr"}
                    control={control}
                    isTextArea={false}
                    label={"pages.distributions.payeeType.formLabels.nameFrench"}
                    showLabel={true}
                  />
                </div>
              </div>
              <div className="motif-col-lg-4">
                <div className="form-field">
                  <ControlSelect
                    name={"payeeLevel"}
                    //@ts-ignore
                    control={control}
                    required={true}
                    showLabel={true}
                    //@ts-ignore
                    readonly={true}
                    label={"pages.distributions.payeeType.formLabels.payeeLevel"}
                    //@ts-ignore
                    placeholder={"placeholder"}
                    options={[
                      { label: t("pages.distributions.payeeType.payeeLevelOptions.label1"), value: "" },
                      { label: t("pages.distributions.payeeType.payeeLevelOptions.label2"), value: "0" },
                      { label: t("pages.distributions.payeeType.payeeLevelOptions.label3"), value: "1" },
                    ]}
                  />
                  {errors["payeeLevel"] &&
                    errors["payeeLevel"].type === "required" && (
                      <MotifErrorMessage>
                        {t("pages.distributions.payeeType.validationMessages.payeeRequired")}
                      </MotifErrorMessage>
                    )}
                </div>
              </div>
            </div>
            <div className="motif-row mar-b-20">
              <div className="motif-col-lg-4 mar-t-20">
                <ControlTextAreaWithCharLimit
                  name={"desc"}
                  control={control}
                  required={false}
                  isTextArea={true}
                  label={"pages.distributions.payeeType.formLabels.description"}
                  showLabel={true}
                />
              </div>
            </div>
            {showContactsSection ? (
              <div className="heading">
                <div className="motif-container"></div>
                <h1 className="mar-t-0">{t("pages.distributions.payeeType.formLabels.contactDetails")}</h1>

                {/* row 1 */}
                <div className="motif-row section-border">
                  <div className="motif-col-lg-4 mar-t-20">
                    <ContactSelect
                      label={"pages.distributions.payeeType.formLabels.prefix"}
                      name={PREFIX}
                      options={PREFIX_OPTIONS_LIST}
                      control={control}
                    />
                  </div>

                  <div className="motif-col-lg-4 mar-t-20">
                    <ControlInputBox
                      // @ts-ignore
                      name={FIRST_NAME}
                      control={control}
                      isTextArea={false}
                      label={"pages.distributions.payeeType.formLabels.firstName"}
                      showLabel={true}
                      required={showContactsSection ? true : false}
                    />
                    {errors[t(FIRST_NAME)]?.type === "required" && (
                      <MotifErrorMessage>
                        {t("pages.distributions.payeeType.validationMessages.firstNameRequired")}
                      </MotifErrorMessage>
                    )}
                  </div>

                  <div className="motif-col-lg-4 mar-t-20">
                    <ControlInputBox
                      // @ts-ignore
                      name={LAST_NAME}
                      control={control}
                      isTextArea={false}
                      label={"pages.distributions.payeeType.formLabels.lastName"}
                      showLabel={true}
                      required={showContactsSection ? true : false}
                    />
                    {errors[t(LAST_NAME)]?.type === "required" && (
                      <MotifErrorMessage>
                        {t("pages.distributions.payeeType.validationMessages.lastNameRequired")}
                        </MotifErrorMessage>
                    )}
                  </div>
                </div>

                {/* row 2 */}
                <div className="motif-row">
                  <div className="motif-col-lg-4 mar-t-20">
                    <ControlInputBox
                      // @ts-ignore
                      name={EMAIL}
                      control={control}
                      isTextArea={false}
                      label={"pages.distributions.payeeType.formLabels.email"}
                      showLabel={true}
                    />
                  </div>

                  <div className="motif-col-lg-4 mar-t-20">
                    <ControlInputBox
                      // @ts-ignore
                      name={ADDRESS1}
                      control={control}
                      isTextArea={false}
                      label={"pages.distributions.payeeType.formLabels.address1"}
                      showLabel={true}
                    />
                  </div>

                  <div className="motif-col-lg-4 mar-t-20">
                    <ControlInputBox
                      // @ts-ignore
                      name={ADDRESS2}
                      control={control}
                      isTextArea={false}
                      label={"pages.distributions.payeeType.formLabels.address2"}
                      showLabel={true}
                    />
                  </div>
                </div>

                {/* row 3 */}
                <div className="motif-row">
                  <div className="motif-col-lg-4 mar-t-20">
                    <ControlInputBox
                      // @ts-ignore
                      name={FAX}
                      control={control}
                      isTextArea={false}
                      label={"pages.distributions.payeeType.formLabels.fax"}
                      showLabel={true}
                    />
                  </div>

                  <div className="motif-col-lg-4 mar-t-20">
                    <ControlInputBox
                      // @ts-ignore
                      name={CITY}
                      control={control}
                      isTextArea={false}
                      label={"pages.distributions.payeeType.formLabels.city"}
                      showLabel={true}
                    />
                  </div>

                  <div className="motif-col-lg-4 mar-t-20">
                    <ControlSelectCountry
                      name={COUNTRY}
                      control={control}
                      required={false}
                      showLabel={true}
                      label={"pages.distributions.payeeType.formLabels.country"}
                      options={countryArray}
                      setValue={setValue}
                      getValues={getValues}
                      onChangeHandlerCountry={onChangeHandlerCountry}
                      filter={true}
                    />
                  </div>
                </div>

                {/* row 4 */}
                <div className="motif-row mar-b-20">
                  <div className="motif-col-lg-4 mar-t-20">
                    <ControlInputBox
                      // @ts-ignore
                      name={"phoneNumber"}
                      control={control}
                      isTextArea={false}
                      label={"pages.distributions.payeeType.formLabels.phoneNumber"}
                      showLabel={true}
                    />
                  </div>

                  <div className="motif-col-lg-4 mar-t-20">
                    <ControlInputBox
                      // @ts-ignore
                      name={POSTAL_CODE}
                      control={control}
                      isTextArea={false}
                      label={"pages.distributions.payeeType.formLabels.postalCode"}
                      showLabel={true}
                    />
                  </div>

                  <div className="motif-col-lg-4 mar-t-20">
                    <div className="form-field">
                      {/**@ts-ignore */}
                      <ControlSelectState
                        name={STATE}
                        control={control}
                        required={false}
                        showLabel={true}
                        label={"pages.distributions.payeeType.formLabels.provinceState"}
                        options={stateListBycounrty}
                        filter={true}
                      />
                      {/**@ts-ignore */}
                      <div className="hide">
                        <ControlInputBox
                          name={"stateoptions"}
                          control={control}
                          required={false}
                          isTextArea={false}
                          label={""}
                          showLabel={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="motif-container ccwp_claim_summary_white_accordian mar-t-20 pad-0">
                  <MotifAccordion
                    onClose={() => setPaymentDetailsOpen(false)}
                    onOpen={() => setPaymentDetailsOpen(true)}
                    style={{
                      flex: "1",
                    }}
                    alignIconRight={true}
                    useChevronIcon={true}
                    className="mar-b-20"
                    open={PaymentDetailsOpen}
                  >
                    {/*  Payment details start */}

                    <MotifAccordionTrigger>
                      <div className="ccwp_claim_summary_white">
                        <label>{t("pages.distributions.payeeType.formLabels.paymentDetails")}</label>
                      </div>
                    </MotifAccordionTrigger>
                    <MotifAccordionContent className="sub-accordian payment-details-wrapper">
                      <div className="motif-row">
                        <div className="motif-col-lg-3">
                          <div className="title">
                            {t("pages.distributions.payeeType.formLabels.institutionNumber")}
                          </div>
                          <PaymentInputField
                            control={control}
                            showField={showField}
                            handleViewIcon={handleViewIcon}
                            name={INSTITUTION_NUMBER}
                          />
                        </div>
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.distributions.payeeType.formLabels.transitNumber")}</div>
                          <PaymentInputField
                            control={control}
                            showField={showField}
                            handleViewIcon={handleViewIcon}
                            name={TRANSIT_NUMBER}
                          />
                        </div>
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.distributions.payeeType.formLabels.accountNumber")}</div>
                          <PaymentInputField
                            control={control}
                            showField={showField}
                            handleViewIcon={handleViewIcon}
                            name={ACCOUNT_NUMBER}
                          />
                        </div>
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.distributions.payeeType.formLabels.IBANNumber")}</div>
                          <PaymentInputField
                            control={control}
                            showField={showField}
                            handleViewIcon={handleViewIcon}
                            name={IBAN_NUMBER}
                          />
                        </div>
                      </div>
                      <div className="motif-row pad-t-20">
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.distributions.payeeType.formLabels.routingCode")}</div>
                          <PaymentInputField
                            control={control}
                            showField={showField}
                            handleViewIcon={handleViewIcon}
                            name={ROUTING_CODE}
                          />
                        </div>
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.distributions.payeeType.formLabels.SWIFTCode")}</div>
                          <PaymentInputField
                            control={control}
                            showField={showField}
                            handleViewIcon={handleViewIcon}
                            name={SWIFT_CODE}
                          />
                        </div>
                        <div className="motif-col-lg-3">
                          <div className="title">{t("pages.distributions.payeeType.formLabels.ACHCode")}</div>
                          <PaymentInputField
                            control={control}
                            showField={showField}
                            handleViewIcon={handleViewIcon}
                            name={ACH_CODE}
                          />
                        </div>
                      </div>
                    </MotifAccordionContent>
                  </MotifAccordion>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="">
              <Button
                label={t("global.globalActions.save")}
                type={
                  payeeCreateLoading || payeeUpdateLoading ? "loader" : "submit"
                }
                disabled={payeeCreateLoading || payeeUpdateLoading}
              />
              <Button
                type="button"
                label={t("global.globalActions.cancel")}
                variant={SECONDARY}
                className="cancelBtn"
                onClickHandler={() => {
                  if (isDirty) {
                    setIsDiscardVisible(true);
                    dispatch(setDiscardEditMode({ editMode: false }));
                  } else {
                    navigate(`/case/${parameter?.caseId}?activeTab=6`);
                  }
                }}
              />
            </div>
          </div>
        </form>
      )}
      <ConfirmationModal
        isVisible={isDiscardVisible}
        setIsVisible={setIsDiscardVisible}
        cancelBtnApproveHandler={() => {
          navigate(`/case/${parameter?.caseId}?activeTab=6`);
        }}
        cancelBtnRejectHandler={() => {
          dispatch(setDiscardEditMode({ editMode: true }));

          setIsDiscardVisible(false);
        }}
      />
    </>
  );
}
