import Table from "@/components/Table/table";
import { useColumnCalimItemsDefs } from "@/features/claimsummary/summary/constant/claimFormHeaderConst";
import {
  MotifButton,
  MotifErrorMessage,
  MotifIcon,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import {
  useGetCaseSummaryDataQuery,
  useGetClaimSummaryViewApiDataQuery,
  useDeleteClaimItemMutation,
} from "@/services/claimSummary/caseSummaryReducer";
import { useParams } from "react-router-dom";
import { useCustomCellRenderers } from "@/features/claimsummary/summary/constant/customHeader";
import { useNavigate } from "react-router-dom";
import { IconoirWarningTriangle } from "@ey-xd/motif-icon";
import { useState } from "react";
import { useCase } from "@/features/case/use-case";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { useTranslation } from "react-i18next";

export const ClaimItems = ({ resolvedStatus }: any) => {
   const TitleComplement = () => {
    const navigate = useNavigate();
    const { claimId, caseId } = useParams<{
      claimId: string;
      caseId: string;
    }>();
    return (
      <MotifButton
        onClick={() => {
          navigate(`/case/${caseId}/claim/details/create`, {
            state: { claimId },
          });
        }}
        size="small"
        type="submit"
        variant="secondary"
        disabled={resolvedStatus}
      >
{t("pages.claims.claimIteamsTab.addButton")}      </MotifButton>
    );
  };
  const { claimId, caseId } = useParams<{
    claimId: string;
    caseId: string;
  }>();
  const {t}=useTranslation()
  const caseData = useCase(caseId,true);
  const columnCalimItemsDefs=useColumnCalimItemsDefs(caseData)
  const [claimItemId, setClaimItemId] = useState<any>("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteNotiy, setShowDeleteNotiy] = useState(false);
  // @ts-ignore
  const getDataByUrl = { caseId: caseId || caseData.caseId, claimId: claimId };
  const { data, error, isLoading, refetch } =
    useGetCaseSummaryDataQuery(getDataByUrl);
  const { data: claimSummaryData } =
    useGetClaimSummaryViewApiDataQuery(claimId);
    const [deleteClaimItemMutation] = useDeleteClaimItemMutation();

  const caseRenderers = useCustomCellRenderers(
    caseId,
    setClaimItemId,
    setShowModal,
    resolvedStatus
  );
  const pinnedBottomRowData = [
    {
      columnField1: <></>,
      columnField2: <>{t("pages.claims.claimIteamsTab.tablecolumns.columnTotal")}</>,
      columnField3: <>{data && data?.claimItemTotalAmount?.totalOriginalAmt}</>,
      columnField4: (
        <>{data && data?.claimItemTotalAmount?.totalClaimItemCurrentAmt}</>
      ),
      columnField5: (
        <>
          {data && data?.claimItemTotalAmount?.totalLatestCreditorPositionAmt}
        </>
      ),
      columnField6: (
        <>{data && data?.claimItemTotalAmount?.totalLatestDebtorPositionAmt}</>
      ),
      columnField7: <>{data && data?.claimItemTotalAmount?.totalResolvedAmt}</>,
    },
  ];

  if (error) {
    return (
      <MotifErrorMessage
        style={{
          textAlign: "center",
          display: "block",
          alignItem: "center",
        }}
      >
          {t("global.globalNotifications.wentWrong")}
            </MotifErrorMessage>
    );
  }
  if (isLoading) {
    return <MotifProgressLoader show variant="default" />;
  }
  
  async function deleteClaimItem() {
    let jsonData = {
      claimItemId: claimItemId,
    };

    const response = await deleteClaimItemMutation(jsonData);
    if (response.hasOwnProperty("data")) {
      setShowModal(false);
      setShowDeleteNotiy(true);
      refetch();
    }
  }
  if (data && claimSummaryData && data?.claimItems) {
    return (
      <>        
        <div className="mar-b-20">
          {showDeleteNotiy && (
            <div className="mar-b-20">
              <NotificationGrid show={true} message="pages.claims.claimIteamsTab.deleteMessage" type="success" />
            </div>
          )}
          <MotifModal
            onClose={() => setShowModal(false)}
            show={showModal}
            focusTrapOptions={{
              tabbableOptions: {
                displayCheck: "none",
              },
            }}
          >
            <div className="confirmationModalWrapper creditor_disabling_model">
              <MotifModalHeader
                headerIcon={
                  <>
                    <span
                      style={{
                        backgroundColor: "rgb(252, 245, 243)",
                        borderRadius: "50px",
                        padding: "5px 8px 2px 8px",
                      }}
                    >
                      <MotifIcon
                        iconFunction={IconoirWarningTriangle}
                        strokeWidth="1"
                        size="15"
                        stroke="red"
                      />{" "}
                    </span>
                    <span
                      style={{
                        fontSize: 16,
                        display: "block",
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      {t("global.globalNotifications.warning")}
                    </span>
                  </>
                }
              ></MotifModalHeader>

              <MotifModalBody style={{ paddingTop: "0px" }}>
                <span style={{ fontWeight: "300" }}>
                  {claimItemId !== ""
                    ? t("pages.claims.claimIteamsTab.deletewarning")
                    :  t("pages.claims.claimIteamsTab.cannotDelete")}
                </span>
              </MotifModalBody>
              <MotifModalFooter>
                {claimItemId !== "" && (
                  <MotifButton
                    size="small"
                    type="submit"
                    variant="secondary"
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                    onClick={deleteClaimItem}
                  >
                    {t("global.globalActions.confirm")}
                  </MotifButton>
                )}

                <MotifButton
                  className=""
                  onClick={() => setShowModal(false)}
                  size="small"
                  type="button"
                >
                                      {t("global.globalActions.cancel")}

                </MotifButton>
              </MotifModalFooter>
            </div>
          </MotifModal>
          <Table
            columnDefs={columnCalimItemsDefs}
            cellRenderers={data && data?.claimItems}
            TitleComplement={TitleComplement}
            title={"pages.claims.claimIteamsTab.tableTitle"}
            description={
"pages.claims.claimIteamsTab.tableDescription"            }      
            caseRenderers={caseRenderers}
            pagination={false}
            pinnedBottomRowData={
              data?.claimItems && data?.claimItems.length > 0
                ? pinnedBottomRowData
                : null
            }
            overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}

          />
        </div>
      </>
    );
  }
  return null;
};
