import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  MotifButton,
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifFormField,
  MotifIcon,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";
import { useUpdateClaimStageStatusMutation } from "@/services/stages/stagesReducer";
import {
  actionIcDescription24px,
  editorIcVerticalAlignBottom24px,
} from "@ey-xd/motif-react/assets/icons";

import "./style.scss";
import {
  useLazyDownloadDocumentQuery,
  fileExtensions,
} from "@/services/documents/documentsReducer";

export const DocumentPopUp = ({
  show,
  setShow,
  caseId,
  currentInstanceData,
  resolvedStatus,
}: any) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [filename, setFilename] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [updateStageStatusQuery] = useUpdateClaimStageStatusMutation();
  const [downloadDocument] = useLazyDownloadDocumentQuery();
  const parameter = useParams();

  const handleFileChange = (files: any) => {
    const file = files[0];
    if (file) {
      setFile(file);
      setFilename(file.name);
    }
  };

  const handleSave = () => {
    const formData = new FormData();
    if (file && parameter.claimId && currentInstanceData) {
      setIsLoading(true);
      formData.append("CaseId", caseId);
      formData.append("ClaimId", parameter?.claimId);
      formData.append(
        "ClaimStageDefinitionId",
        currentInstanceData.definitionIds.claimStageDefinitionId
      );
      formData.append(
        "ClaimStatusDefinitionId",
        currentInstanceData.definitionIds.claimStatusDefinitionId
      );
      formData.append(
        "ClaimStatusInstanceId",
        currentInstanceData.instanceIds.claimStatusInstanceId
      );
      formData.append(
        "ClaimStageInstanceId",
        currentInstanceData.instanceIds.claimStageInstanceId
      );
      formData.append("file", file);
      updateStageStatusQuery(formData).then(() => {
        setFile(null);
        setIsLoading(false);
        setShow(false);
      });
    }
  };

  const handleFileDownload = () => {
    if (file) {
      const filePath = window.URL.createObjectURL(file);
      window.open(filePath);
    } else {
      downloadDocument({
        caseId: caseId,
        documentId: currentInstanceData.instanceData.stageDocumentId,
        fileName: currentInstanceData.instanceData.stageDocumentName,
      });
    }
  };

  useEffect(() => {
    if (currentInstanceData) {
      const docName =
        currentInstanceData?.instanceData?.stageDocumentName || "";
      setFilename(docName);
    }
  }, [currentInstanceData]);

  let claimStageName = currentInstanceData?.instanceData?.claimStageName ?? "";

  return (
    <div>
      <MotifModal
        show={show}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        className="document_popup_modal_wrapper"
        size={"xl"}
      >
        <MotifModalHeader
          className="document_popup_header_wrapper"
          headerIcon={false}
        >
          Stage Document{": " + claimStageName || ""}
        </MotifModalHeader>

        <MotifModalBody>
          <MotifFormField>
            <MotifFileUploader
              id="my-id"
              label={t("pages.claims.stageStatusPopup.fileLabel")}
              labelDescription={t(
                "pages.claims.stageStatusPopup.fileDescription"
              )}
              onDrop={(files) => handleFileChange(files)}
              accept={fileExtensions.default}
              maxFiles={1}
              disabled={resolvedStatus}
            >
              {(file || filename) && (
                <MotifFileUploaderItem
                  fileIcon={<MotifIcon src={actionIcDescription24px} />}
                  customButton={
                    <MotifButton
                      onClick={handleFileDownload}
                      variant="primary-alt"
                    >
                      <MotifIcon
                        fill="none"
                        iconFunction={editorIcVerticalAlignBottom24px}
                      />
                    </MotifButton>
                  }
                  fileName={filename}
                  fileSize={file?.size || 0}
                  onRemove={() => setFile(null)}
                  error={
                    (file && file.size >= fileExtensions.maxFileSize) || false
                  }
                  errorText={t(
                    "pages.claims.stageStatusPopup.filesizeExceedsLimit",
                    {
                      filesize: "250",
                    }
                  )}
                />
              )}
            </MotifFileUploader>
          </MotifFormField>
        </MotifModalBody>

        <MotifModalFooter>
          <div className="motif-row">
            <MotifButton variant="secondary" onClick={() => setShow(false)}>
              {t("global.globalActions.cancel")}
            </MotifButton>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <MotifButton
              variant="primary"
              onClick={handleSave}
              disabled={!file || file?.size >= fileExtensions.maxFileSize} // 250 MB in bytes
              type={isLoading ? "loader" : "submit"}
            >
              {t("global.globalActions.save")}
            </MotifButton>
          </div>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
};
