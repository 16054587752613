import { useState } from "react";
import "./DebtorForm.scss";
import { MotifIcon, MotifProgressLoader } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import Banner, { BannerElement } from "@/components/Banner/Banner";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import TabPanel from "@/components/TabPanel/TabPanel";
import DebtorForm from "./DebtorForm";
import { useGetDebtorEditViewApiDataQuery } from "@/services/debtors/debtorsReducers";
import ClaimTabContent from "@/features/debtor/claimsTab/claimsTabContent";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useCase } from "@/features/case/use-case";

const DebtorFormGrid = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { caseId, editId } = useParams();
  const caseData = useCase(caseId);
  const editView = editId ? true : false;
  const { data, isLoading } = useGetDebtorEditViewApiDataQuery(editId, {
    refetchOnMountOrArgChange: true,
  });
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);
  const debtorData = data ? data : {};

  interface BannerData {
    totalClaims: string;
    name: string;
  }

  const bannerData: BannerData = {
    totalClaims: data?.totalClaims ? data?.totalClaims.toString() : "",
    name: data?.name || "",
  };
  const debtorBanner = {
    debtorTtl: "pages.debtors.debtorBannerDetails.debtor",
    debtorName: bannerData.name,
  };

  const bannerElements: BannerElement[] = [
    {
      label: "pages.debtors.debtorBannerDetails.totalClaims",
      value: bannerData.totalClaims,
      elementKey: "caseID",
    },
  ];

  const tabPanelData = {
    title: ["pages.debtors.debtorTabPanelDetails.summary", "pages.debtors.debtorTabPanelDetails.claims"],
    content: [
      <DebtorForm debtorData={{ editView: editView, ...debtorData }} />,
      <div className="ccwp_table_wrapper">
        <ClaimTabContent />
      </div>,
    ],
  };
  const breadcrumbItems = editView
    ? [
        {
          label: "External Submission",
          value: "10 New Creditor Submission",
          elementKey: "submission",
          text: caseData?.caseName || t("global.globalActions.backToCase"),
          url: `/case/${caseData?.caseId}?activeTab=1`,
          icon: <MotifIcon src={IconoirArrowLeft} />,
        },
        {
          label: "External Submission",
          value: "10 New Creditor Submission",
          elementKey: "submission",
          text: debtorBanner.debtorName,
          url: "/",
          disabled: true,
        },
      ]
    : [
        {
          label: "External Submission",
          value: "10 New Creditor Submission",
          elementKey: "submission",
          text: t("global.globalActions.back"),
          url: "",
          icon: <MotifIcon src={IconoirArrowLeft} />,
        },
      ];

  const defaultDebtorData = {
    debtorId: "97a19c21-0870-44fa-aea1-6d2e42f51076",
    caseId: "",
    name: "",
    description: "",
    createdBy: "CA013334848",
    updatedBy: "CA013334848",
    isActive: true,
    editView: false,
  };

  if (!editView) {
    return (
      <>
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>navigate(`/case/${caseId}?activeTab=1`) }
        >
          <Breadcrumb
            items={breadcrumbItems}
            ariaLabel="Breadcrumb navigation"
          />
        </div>
        <DebtorForm debtorData={defaultDebtorData} />
        <ConfirmationModal
          isVisible={isDiscardVisible}
          setIsVisible={setIsDiscardVisible}
          cancelBtnApproveHandler={() => {
            navigate(`/case/${caseId}?activeTab=1`);
          }}
          cancelBtnRejectHandler={() => setIsDiscardVisible(false)}
        />
      </>
    );
  }

  if (isLoading) {
    return <MotifProgressLoader />;
  }

  return (
    <>
      <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
      <Banner
        bannerName={debtorBanner.debtorTtl}
        bannerNameValue={debtorBanner.debtorName}
        elements={bannerElements}
      />
      <div className="ccwp_table_tabpanel">
        <TabPanel data={tabPanelData} defaultActiveKey={0} />
      </div>
    </>
  );
};

export default DebtorFormGrid;
