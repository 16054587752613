export const columnImportSummaryDefs = [
  {
    cellRenderer: "rowNumber",
    			comparator: () => 0,
    field: "rowNumber",
    type:"rowNumberType",
    headerName: "Row Number",
    sortable: true,
    unSortIcon: true,
  },
  {
    			comparator: () => 0,
    field: "status",
    type:"statusType",
    headerName: "Status",
    sortable: true,
    unSortIcon: true,
    cellRenderer: function (params:any) {
      return params.data.status === 0 ? 'Failed' :
             params.data.status === 1 ? 'Success' :
             "-"; 
    },
    
  },
  {
    			comparator: () => 0,
    field: "errorMessage",
    type:"errorMessageType",
    headerName: "Error Message",
    flex: 1,
    sortable: true,
    unSortIcon: true,
    cellRenderer: function (params: any) {
      return <div className="text-wrap ">{params.data.errorMessage}</div>;
    },
  },
];
