import React from "react";
import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputBox from "@/components/Form/InputBox/InputBox";
import "@/components/Form/InputBox/InputBox.scss";
import { FormSummaryEditFormType } from "../type";
import {
  FORM_ID,
  FORM_ID_FRENCH,
  FORM_TYPE,
  FORM_ACTION_TYPE,
  FORM_STATUS,
  FORM_PUBLISH_DATE,
  FORM_DUE_DATE,
  FORM_STAGE,
  FORM_PAST_DUE_DATE,
} from "../Constant";

type ControlInputBoxType = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<FormSummaryEditFormType>;
  name:
    | typeof FORM_ID
    | typeof FORM_ID_FRENCH
    | typeof FORM_TYPE
    | typeof FORM_ACTION_TYPE
    | typeof FORM_STATUS
    | typeof FORM_PUBLISH_DATE
    | typeof FORM_DUE_DATE
    | typeof FORM_PAST_DUE_DATE
    | typeof FORM_STAGE;

  showLabel: boolean;
  readonly?: boolean;
  label: string;
};

export default function ControlInputBoxVoting({
  name,
  control,
  required = false,
  isTextArea = false,
  showLabel,
  label,
  readonly,
}: ControlInputBoxType) {
  const {
    field,
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: { required: required },
  });
  const { t } = useTranslation();

  return (
    <>
      {showLabel && (
        <div className="formFieldLabel">
          <label htmlFor="input" aria-label={label}>
            {t(label)}
            {required ? <span className="requiredSymbol">*</span> : ""}
          </label>
        </div>
      )}
      <Controller
        name={field.name || ""}
        control={control}
        rules={{ required }}
        render={({ field: { onChange, value, ...args } }) => {
          return (
            <>
              <InputBox
                id={FORM_ID}
                required={true}
                value={value || ""}
                onChange={onChange}
                isTextArea={isTextArea}
                readonly={readonly}
                placeholder=""
                {...args}
              />
            </>
          );
        }}
      />
    </>
  );
}
