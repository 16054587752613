import { j as s } from "../../../jsx-runtime-BwWtxLpl.js";
import "react";
import r from "prop-types";
import m from "classnames";
const i = ({ children: e, variant: a = "default", className: t = "", ...o }) => {
  const d = m({
    "motif-card-header": !0,
    "motif-card-header-alt": a === "alt",
    "motif-card-header-dark": a === "dark",
    [t]: !0
  });
  return /* @__PURE__ */ s.jsx("header", { className: d, ...o, "data-motif-card-header": !0, children: e });
};
i.propTypes = {
  children: r.node,
  variant: r.oneOf(["alt", "default", "dark"]),
  className: r.string
};
export {
  i as MotifCardHeader
};
