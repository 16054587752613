import { MotifTabNavigation, MotifTabControl, MotifDropdown, MotifButton, MotifIcon, MotifDropdownItem } from '@ey-xd/motif-react';
import React  from 'react';
import { useTranslation } from 'react-i18next';
import { IconoirNavArrowDown } from '@ey-xd/motif-icon';
import TabPanelContent from '@/components/TabPanelContent/TabPanelContent';
import { TABPANEL } from '@/appConstants';
import './TabPanelCase.scss';
import { RootState } from '@/app/store';
import { useSelector, useDispatch } from 'react-redux';
import { updateStage } from '../creditorSetupSlice';
interface TabPanelProps {
  data: {
    title: string[];
    content: React.ReactNode[];
    defaultActiveKey?: number;
  };
  defaultActiveKey?: number;
  orientation?: "horizontal" | "vertical" | undefined;
  isReview?: boolean;
}

const TabPanelCreditor = ({ data, orientation='horizontal'}: TabPanelProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visibleTabs = data.title.slice(0, TABPANEL.maxTabs);
  const dropdownTabs = data.title.slice(TABPANEL.maxTabs);
  const creditorSetup = useSelector(
    (state: RootState) => state.creditorSetup
  );
  const { stage, formSubmitIndex } = creditorSetup;
  const activeTab = stage;
  const handleTabClick = (index: number) => {
    dispatch(updateStage(index));
  };

  return (
    <>
      <div className='display-flex pad-t-20'>
        <MotifTabNavigation defaultActiveKey={activeTab} orientation={orientation}  currentTab={activeTab} controlled={true} >
          {visibleTabs.map((title, index) => (
            <MotifTabControl disabled={activeTab < index && formSubmitIndex < index }  className={`${index === activeTab ? 'selectedTab' : ''}`} tabKey={index} key={index} onClick={()=>handleTabClick(index)}>{t(title)}</MotifTabControl>
          ))}
        </MotifTabNavigation>

        {dropdownTabs.length > 0 && (
          <MotifDropdown className='pad-l-30' trigger={
            <MotifButton type="button" aria-label="More Tabs" variant="text">
              <MotifIcon iconFunction={IconoirNavArrowDown} strokeWidth="2px" />
            </MotifButton>}>
            <ul role="menu">
              {dropdownTabs.map((title, index) => (
                <li key={index} role="menuitem">
                  <MotifDropdownItem onClick={() => handleTabClick(index + TABPANEL.maxTabs)}>{t(title)}</MotifDropdownItem>
                </li>
              ))}
            </ul>
          </MotifDropdown>
        )}
      </div>
      <TabPanelContent content={data.content[activeTab]} />
    </>
  );
}

export default TabPanelCreditor;