import {
  MotifButton,
  MotifIcon,
} from "@ey-xd/motif-react";
import { IconoirEditPencil, IconoirTrash } from "@ey-xd/motif-icon";
import { Link } from "react-router-dom";

type CreditorsCell = {
  data: {
    creditorName: String;
    totalClaimAmount: Number;
    totalDistributedAmount: Number;
    totalClaims: Number;
    totalVoteAmount: Number;
    creditorId: String;
    isActive: boolean;
  };
};
export const useCustomCellRenderers = (
  setShowModal: any,
  setActiveClaim: any,
  setCreditor: any
) => {
  const caseRenderers = {
    nameCellRenderer: function (param: CreditorsCell) {
      return (
        <>
          {
            <Link
              to={`./creditor/${param.data.creditorId}`}
              state={{ from: param.data.creditorId }}
            >
              {param.data.creditorName}
            </Link>
          }
        </>
      );
    },
    actionCellRenderers: function (param: CreditorsCell) {
      return (
        <>
          <div className="pad-r-10">
            <MotifButton
              size="small"
              variant="primary"
              onClick={() => {
                setShowModal(true);
                setActiveClaim(param.data.totalClaims);
                setCreditor({
                  creditorId: param.data.creditorId,
                  creditorName: param.data.creditorName,
                });
              }}
              disabled={param.data.isActive === true ? false : true}
            >
              <MotifIcon
                iconFunction={IconoirTrash}
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
          </div>
          <Link
            to={`./creditor/${param.data.creditorId}/edit`}
            state={{ from: param.data.creditorId }}
          >
            <MotifButton
              size="small"
              type="submit"
              variant="primary"
              // disabled={param.data.isActive == true ? false : true}
            >
              <MotifIcon
                iconFunction={IconoirEditPencil}
                // fill="#2E2E38"
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
          </Link>
        </>
      );
    },
  };
  return caseRenderers;
};
