import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  MotifErrorMessage,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "@/components/Form/Button/Button";
import {
  SECONDARY,
} from "@/Constant";
import { AddDebtorFormType } from "./type";
import "./FormModal.scss";

import { capitalizeWordsInSentence } from "@/utility/common";
import ControlSelect from "./SelectBox/editFieldSelectBox";
import { isNumeric } from "@/utility/validation";
import ControlInputBoxNumber from "@/pages/CaseDetail/CaseSetup/CurrencyConfiguration/ControlInputBoxNumber";
import ControlCheckBox from "@/components/Form/CheckBox/ControlCheckBox";
import {
  getDataSourceValue,
  postDataMappingSource,
  renderOptionList,
} from "../Form/calculationSummaryHelper";
import {
  useCreateStepDataSourceApiMutation,
  useGetDataSourceSummaryEditApiDataQuery,
  useGetDistributionApiDataQuery,
  useGetListCalculationStepQuery,
  useGetManualDataSourceApiDataQuery,
  useUpdateStepDataSourceApiMutation,
} from "../calculationStepReducer";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";

type Props = {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  formData?: AddDebtorFormType | null;
  caseId: string;
  stepId: string;
  setIsRefetch: Dispatch<SetStateAction<boolean>>;
  setFormData: Dispatch<SetStateAction<AddDebtorFormType | null>>;
  editId: any;
};

export default function FormModal({
  isVisible,
  formData,
  setIsVisible,
  stepId,
  caseId,
  setIsRefetch,
  setFormData,
  editId,
}: Props) {
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [isFrenchSelected, setIsFrenchSelected] = useState(false);
  const [isVisibleCancel, setIsVisibleCancel] = useState(false);
const [intialValues,setintialValues]=useState<any>(null);
  const onChangeAmount = () => {};

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm<any>();

  useEffect(() => {
    if (formData !== null) {
      //@ts-ignore
      setValue("dataSource", getDataSourceValue(formData?.dataSource));
      //@ts-ignore
      setValue("weightingFactor", formData.weight);
      //@ts-ignore
      setValue(
        "filterApplicableClasses",
        // @ts-ignore
        formData?.applicableClasses === true ? true : false
      );
      //("filterApplicableClasses", formData.applicableClasses);
    } else {
      //@ts-ignore
      setValue("dataSource", "0");
      //@ts-ignore
      setValue("weightingFactor", "1");
      //@ts-ignore
      setValue("filterApplicableClasses", false);
    }
    setintialValues(getValues())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData,setValue]);

  const errorList = Object.keys(errors);
  const errorsShow = capitalizeWordsInSentence(errorList.join(", "));
  let [createStepDataSourceApi] = useCreateStepDataSourceApiMutation();
  let [updateStepDataSourceApi] = useUpdateStepDataSourceApiMutation();

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      // @ts-ignore
      let postData = postDataMappingSource(data);
      let formDetails: any = formData || null;
      const responseApi =
        formData === null
          ? await createStepDataSourceApi({
              ...postData,
              calculationStepId: stepId,
              caseId: caseId,
              sourceManualDataSourceId: data?.manualDataSource,
              sourceCalculationStepId: data?.calculationStep,
            })
          : await updateStepDataSourceApi({
              ...postData,
              stepDataSourceId: formDetails?.stepDataSourceId,
              calculationStepId: stepId,
              caseId: caseId,
              sourceManualDataSourceId: data?.manualDataSource,
              sourceCalculationStepId: data?.calculationStep,
            });
      if (responseApi.hasOwnProperty("data")) {
        setIsRefetch(true);
        setIsVisible(false);
        setIsLoading(false);
        setFormData(null);
        setShowMessage({
          ...showMessage,
          show: false,
          type: "error",
          message: t(""),
        });
      } else {
        const error =  t("global.globalNotifications.unExpectedError");
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          message: t(error),
        });
        setIsLoading(false);
      }
    } catch (error) {
      setShowMessage({ ...showMessage, show: true, type: "error" });
      setIsLoading(false);
    }
  };
  const getDataSource = watch("dataSource");
  const distributionId = watch("distribution");
  const entitleMentSet = watch("entitleMentSet");
  const { data: calculationStep } = useGetListCalculationStepQuery({
    distributionId: distributionId,
  }, { skip: !distributionId });
  let filterStepData = [];
  const calculationStepData = calculationStep?.results?calculationStep.results:[];
  if (calculationStepData.length === 0) {
  } else {
    const index = calculationStepData.findIndex((step: any) => step.id === stepId);
    filterStepData = calculationStepData.slice(0, index);
  }
  const calculationStepList = renderOptionList(filterStepData, "name", "id");
  const { data: manualDataSource } = useGetManualDataSourceApiDataQuery(caseId);
  const { data: dataSourceById } =
    useGetDataSourceSummaryEditApiDataQuery(editId);
  const manualDataSourceList = renderOptionList(manualDataSource, "name", "id");
  const { data: distribution } = useGetDistributionApiDataQuery(caseId);
  const distributionList = renderOptionList(distribution?.results?distribution?.results:[], "name", "id");

  useEffect(() => {
    setValue("manualDataSource", dataSourceById?.sourceManualDataSourceId);
    setValue("distribution", dataSourceById?.sourceDistributionId);
    setValue("calculationStep", dataSourceById?.sourceCalculationStepId);
    setValue(
      "entitleMentSet",
      dataSourceById?.isEntitlement === true ? "0" : "1"
    );
    setintialValues(getValues())

  }, [dataSourceById, setValue,getValues]);

  useEffect(() => {
    if (getDataSource === "1" && (entitleMentSet === undefined || entitleMentSet === null)) {
      setValue("entitleMentSet", "1");
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataSource]);
  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    setIsVisibleCancel(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
    // setIsEdit(false);
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisibleCancel(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };
  return (
    <>
      <MotifModal
        onClose={() =>
          { 
            if(JSON.stringify(intialValues) !== JSON.stringify(getValues())){
              setIsVisibleCancel(true);
            }
            else{
              setIsVisible(false);
              setIsVisibleCancel(false);
              setShowMessage({ ...showMessage, show: false, type: "error" });
            }
           }}
        show={isVisible}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formModalWrapper">
            <MotifModalHeader>
              <span className="discard_heading">{t("pages.distributions.distributionStepsTab.dataSourceTab.dataSourceEditTitle")}</span>
            </MotifModalHeader>
            <MotifModalBody>
              <div className="formModal">
                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlSelect
                      //@ts-ignore
                      name={"dataSource"}
                      //@ts-ignore
                      control={control}
                      required={true}
                      showLabel={true}
                      readonly={true}
                      label={"pages.distributions.distributionStepsTab.dataSourceTab.formLabels.dataSource"}
                      //@ts-ignore
                      placeholder={"placeholder"}
                      options={[
                        { label:  t("pages.distributions.distributionStepsTab.dataSourceTab.dataSourceOptions.label1"), value: "" },
                        { label: t("pages.distributions.distributionStepsTab.dataSourceTab.dataSourceOptions.label2"), value: "0" },
                        { label: t("pages.distributions.distributionStepsTab.dataSourceTab.dataSourceOptions.label3"), value: "1" },
                        { label: t("pages.distributions.distributionStepsTab.dataSourceTab.dataSourceOptions.label4"), value: "2" },
                      ]}
                    />
                  </div>
                </div>
                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlInputBoxNumber
                      name={"weightingFactor"}
                      // @ts-ignore
                      control={control}
                      required={true}
                      isTextArea={false}
                      label={"pages.distributions.distributionStepsTab.dataSourceTab.formLabels.weightingFactor"}
                      showLabel={true}
                      //@ts-ignore
                      setValue={setValue}
                      isNumber={true}
                      maxLength={getValues("weightingFactor")?.toString().includes(".") ? 9 : 10}
                      isAmount={true}
                      onChangeAmount={onChangeAmount}
                      validate={isNumeric}
                    />
                  </div>
                </div>
                {getDataSource === "1" && (
                  <>
                    <div className="motif-row mar-t-20">
                      <div className="motif-col-md-5">
                        <ControlSelect
                          // @ts-ignore
                          name={"distribution"}
                          //@ts-ignore
                          control={control}
                          required={true}
                          showLabel={true}
                          readonly={true}
                          label={"pages.distributions.distributionStepsTab.dataSourceTab.formLabels.distribution"}
                          //@ts-ignore
                          placeholder={"placeholder"}
                          options={distributionList}
                        />
                      </div>
                    </div>
                    <div className="motif-row mar-t-20">
                      <div className="motif-col-md-5">
                        <ControlSelect
                          // @ts-ignore
                          name={"calculationStep"}
                          //@ts-ignore
                          control={control}
                          required={true}
                          showLabel={true}
                          readonly={true}
                          label={"pages.distributions.distributionStepsTab.dataSourceTab.formLabels.calculationStep"}
                          //@ts-ignore
                          placeholder={"placeholder"}
                          options={calculationStepList}
                        />
                      </div>
                    </div>
                    <div className="motif-row mar-t-20">
                      <div className="motif-col-md-5">
                        <ControlSelect
                          //@ts-ignore
                          name={"entitleMentSet"}
                          //@ts-ignore
                          control={control}
                          required={true}
                          showLabel={true}
                          readonly={true}
                          label={"pages.distributions.distributionStepsTab.dataSourceTab.formLabels.entitlement"}
                          //@ts-ignore
                          placeholder={"placeholder"}
                          options={[
                            { label: t("pages.distributions.distributionStepsTab.dataSourceTab.entitlementOptions.label1"), value: "" },
                            { label: t("pages.distributions.distributionStepsTab.dataSourceTab.entitlementOptions.label2"), value: "0" },
                            { label: t("pages.distributions.distributionStepsTab.dataSourceTab.entitlementOptions.label3"), value: "1" },
                          ]}
                        />
                        {errors["entitleMentSet"] &&
                          errors["entitleMentSet"].type === "required" && (
                            <MotifErrorMessage>
                            { t("pages.distributions.distributionStepsTab.dataSourceTab.validationMessages.entitlementReq" ,{fieldNames:errorsShow})}
                             </MotifErrorMessage>
                          )}
                      </div>
                    </div>
                  </>
                )}

                {getDataSource === "2" && (
                  <div className="motif-row mar-t-20">
                    <div className="motif-col-md-5">
                      <ControlSelect
                        // @ts-ignore
                        name={"manualDataSource"}
                        //@ts-ignore
                        control={control}
                        required={true}
                        showLabel={true}
                        readonly={true}
                        label={"pages.distributions.distributionStepsTab.dataSourceTab.formLabels.manualDataSource"}
                        //@ts-ignore
                        placeholder={"placeholder"}
                        options={manualDataSourceList}
                      />
                    </div>
                  </div>
                )}
                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    {/*@ts-ignore*/}
                    <ControlCheckBox
                      name={"filterApplicableClasses"}
                      control={control}
                      // @ts-ignore
                      showLabel={true}
                      label={t("pages.distributions.distributionStepsTab.dataSourceTab.formLabels.applicableClass")}
                      setValue={setValue}
                      getValues={getValues}
                      isFrenchSelected={isFrenchSelected}
                      setIsFrenchSelected={setIsFrenchSelected}
                    />
                  </div>
                </div>
              </div>
              <div className="error-container">
                <MotifErrorMessage>
                  {showMessage.show && showMessage.message}
                  {errorList.length > 0 &&
                        t("pages.distributions.distributionStepsTab.dataSourceTab.validationMessages.mainMessage" ,{fieldNames:errorsShow})}
                </MotifErrorMessage>
              </div>
            </MotifModalBody>
            <MotifModalFooter>
              <div className="button-container-add">
                <Button label={t("global.globalActions.save")} disabled={isLoading} />
                <Button
                  label={t("global.globalActions.cancel")}
                  type="button"
                  variant={SECONDARY}
                  className="cancelBtn"
                  onClickHandler={() => {
                    if(JSON.stringify(intialValues) !== JSON.stringify(getValues())){
                      setIsVisibleCancel(true);

                    }
                    else{
                      setIsVisible(false);
                      setShowMessage({
                        ...showMessage,
                        show: false,
                        type: "error",
                      });
                      setFormData(null);
                    }

                   
                  }}
                />
              </div>
            </MotifModalFooter>
          </div>
        </form>
      </MotifModal>
      <ConfirmationModal
        isVisible={isVisibleCancel}
        setIsVisible={setIsVisibleCancel}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
    </>
  );
}
