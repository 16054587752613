import { MotifInput, MotifIcon } from "@ey-xd/motif-react";
import {
  MotifActionIcVisibilityOff24px,
  MotifActionIcVisibility24px,
} from "@ey-xd/motif-icon";

type MaskedInputProps = {
  value: string;
  name: string;
  showField: any;
  handleViewIcon: (name: string) => void;
  index: number;
  };

function MaskedInput({value, name, showField, handleViewIcon, index }: MaskedInputProps) {
  return (
      <div style={{ position: "relative" }}>
        <MotifInput
        // @ts-ignore
          value={value || "-"}
          type={
            !value
              ? "text"
              : showField[`${name}-${index}`]
              ? "text"
              : "password"
          }
          hideClearButton={true}
        />
        {value && (
          <div
            className="visibilityIcon"
            onClick={() => handleViewIcon(`${name}-${index}`)}
          >
            <MotifIcon
              src={
                showField[`${name}-${index}`]
                  ? MotifActionIcVisibilityOff24px
                  : MotifActionIcVisibility24px
              }
            ></MotifIcon>
          </div>
        )}
      </div>
  );
}

export default MaskedInput;
