import {
  useGetBasicSearchCreditorTabListApiDataQuery,
  useDisableCrediotrActionMutation,
  useLazySearchCreditorTabListDataQuery,
} from "@/services/creditors/creditorsReducers";
import Table from "@/components/Table/table";
import TitleComplement from "@/features/creditor/creditorsTab/components/titleComplement";
import { useColumnDefs } from "@/features/creditor/creditorsTab/Constant/creditorHeaderConst";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import {  useEffect, useState } from "react";
import { ActiveClaimModel, NoClaimModel } from "./modelBox";
import { useCustomCellRenderers } from "./Constant/creditorCustomCellConst";
import { BasicSearch } from "./basicSearch";
import "./style.css";
import { useParams } from "react-router-dom";
import AdvanceSearch from "./AdvanceSearch";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import { useGetCreditorAdvanceSearchDataMutation } from "@/services/advanceSearch/advanceSearchReducer";
import { useCase } from "@/features/case/use-case";
import { useTranslation } from "react-i18next";

export default function CreditorTabContent() {
  const { caseId } = useParams();
  const caseData = useCase();
  const {t}=useTranslation()
  const columnDefs:any = useColumnDefs(caseData);
  const [showModal, setShowModal] = useState(false);
  const [activeClaim, setActiveClaim] = useState(null);
  const [creditor, setCreditor] = useState<{
    creditorId: string;
    creditorName: string;
  }>();
  const [showBasicSearch, setShowBasicSearch] = useState(false);
  const [isBasicSearchActive, setIsBasicSearchActive] = useState(false);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [searchUrl, setSearchUrl] = useState<any>({
    caseId: caseId,
  });
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [formValues, setFormValues] = useState<any>({
    claimId: "",
    creditorId: "",
    creditorName: "",
    debtorName: "",
    country: "",
    province: "",
    postalCode: "",
    phoneNumber: "",
    claimUpdateDate: undefined,
  });
  let tableLoadingData = customTableLoadingData;
  const paginationPageSize: number = customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
  const [paginationObj, setPaginationObj] = useState<any>({
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",
    // SortOrder: "desc",
    // SortColumn: "UpdatedOn",
  });
  
  let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({
  })

  function handlePaginationData(data:any,columnTypesData:any){
    setPaginationObj({...data})
    setSortedColumnTypes({...columnTypesData})
  }
  let resetSortStatus=()=>{
    let json:any={...sortedColumnTypes}
    for (const key in json) {
                  json[key]={sort:null}
    }
    setSortedColumnTypes({...json})
  }

  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [advanceSearchData, setAdvanceSearchData] = useState<any>(null);
  const [basicSearchPayload, setBasicSearchPayload] = useState({});
  const [advanceSearchPayload, setAdvanceSearchPayload] = useState({});
  const [isAdvanceSearchActive, setIsAdvanceSearchActive] = useState(false);
  const [
    triggerSearch,
    {
      isFetching: quickSearchDataFetching,
      data: searchData,
      error: searchError
    },
  ] = useLazySearchCreditorTabListDataQuery();
  const [getCreditorAdvanceSearchData,{data:avdavancedData,isLoading:advancedSearchLoading,error:advancedDataError}] =
  useGetCreditorAdvanceSearchDataMutation();
  const handleSearch = 
    (searchValue: string) => {
      resetSortStatus()
      setSearchQuery(searchValue);
      if (searchValue.trim() === "") {
        setIsSearchActive(false);
        setPaginationObj({
          PageNumber: 1,
          PageSize: paginationPageSize,
          SearchText: "",
        })
      } else {
        setPaginationObj({
          PageNumber: 1,
          PageSize: paginationPageSize,
          SearchText: searchValue,
        })
       setClearSearch(false)
        setIsSearchActive(true);
        setIsBasicSearchActive(false);
        setIsAdvanceSearchActive(false);
      }
    }
  const [disableCreditorAction] = useDisableCrediotrActionMutation();
  const disableCreditor = async () => {
    const response = await disableCreditorAction(creditor?.creditorId);
    //@ts-ignore
    if (response.hasOwnProperty("data") && response?.data === true) {
      setShowModal(false);
      setSuccessPopUp(true);
      refetch();
    } else {
      setShowModal(false);
      setSuccessPopUp(false);
    }
  };

  //Clear basic search
  function clearBasicSearch() {
    setSearchUrl({
      caseId: caseId,
    });
    resetSortStatus()

    setPaginationObj({
      PageNumber: 1,
      PageSize: paginationPageSize,
    });
    setFormValues((prevValues: any) => ({
      ...prevValues,
      claimId: "",
      creditorId: "",
      creditorName: "",
      debtorName: "",
      country: "",
      province: "",
      postalCode: "",
      phoneNumber: "",
      claimUpdateDate: undefined,
    }));
    setAdvanceSearchData(null);
    setIsBasicSearchActive(false);
    setIsAdvanceSearchActive(false);
  }

  //Set up the form value
  const handleChange = (name: any, value: any) => {
    setFormValues({ ...formValues, [name]: value });
  };

  //Submit the form value in URL
  function handleSubmit() {
    setSearchUrl({
      ...formValues,
      caseId: caseId,
    });
    resetSortStatus()
    setSearchQuery("");
setIsSearchActive(false);
    setPaginationObj({
      PageNumber: 1,
      PageSize: paginationPageSize,
      SearchText: "",
    })
    
    setBasicSearchPayload({ ...formValues, caseId: caseId });
    if (formValues?.claimUpdateDate) {
      // Create a Date object
      const date = new Date(formValues?.claimUpdateDate);

      // Extract day, month, and year
      const day = ("0" + date.getDate()).slice(-2); // Ensure two digits
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Ensure two digits, month is 0-indexed
      const year = date.getFullYear();

      // Format in dd-mm-yyyy
      const updateDate = `${year}-${month}-${day}`;
      setSearchUrl((prevValues: any) => ({
        ...prevValues,
        updateDate,
      }));
      setBasicSearchPayload({
        ...formValues,
        caseId,
        claimUpdateDate: updateDate,
      });
    }
    setIsBasicSearchActive(true);
    setFormValues((prevValues: any) => ({
      ...prevValues,
      claimId: "",
      creditorId: "",
      creditorName: "",
      debtorName: "",
      country: "",
      province: "",
      postalCode: "",
      phoneNumber: "",
      claimUpdateDate: undefined,
    }));
    setClearSearch(true);
  }
  function handleResetPaginationandQuickSearch(){
    resetSortStatus()
    setSearchQuery("")
    setIsSearchActive(false)
  }
let basicSearchPayloadJson = {...searchUrl,...paginationObj};
  //Get data from API
  const { data, error, isFetching, isLoading, refetch } =
  useGetBasicSearchCreditorTabListApiDataQuery({...basicSearchPayloadJson}, {
      skip: !searchUrl.caseId || isAdvanceSearchActive || isSearchActive,
      refetchOnMountOrArgChange: true,
    });
  const handleQuickSearchClear = () => {
    setSearchQuery("");
    setIsSearchActive(false);

    setPaginationObj({
      PageNumber: 1,
      PageSize: paginationPageSize,
      SearchText: "",
    })
    setClearSearch(false);
    setAdvanceSearchData(null);
    setIsBasicSearchActive(false);
    setIsAdvanceSearchActive(false);
    // refetch();
  }

  const caseRenderers = useCustomCellRenderers(
    setShowModal,
    setActiveClaim,
    setCreditor
  );
  const headerData = {
    title: "pages.creditors.creditorTableTitle",
    description: "pages.creditors.creditorTableDescription",
  };
  useEffect(() => {

    if(isAdvanceSearchActive){
      getCreditorAdvanceSearchData({...advanceSearchPayload,...paginationObj}).then((response: any) => {   
      }).catch(((error)=>null));
    }
    else if (isSearchActive){
      triggerSearch({
        CaseId: caseId,
...paginationObj
      })
        .unwrap()
    }
else{
  refetch()

}
    

  },[paginationObj,caseId,getCreditorAdvanceSearchData,refetch,triggerSearch,advanceSearchPayload,isAdvanceSearchActive,isSearchActive]);

  if (isLoading ) {
    return <MotifProgressLoader show variant="default" />;
  }
  
  let tableDataFetching=isFetching || quickSearchDataFetching || advancedSearchLoading
  let tableData= tableDataFetching?[]: isAdvanceSearchActive ? avdavancedData?.results:isSearchActive?searchData?.results : data?.results ? data?.results : []
  let tableError=avdavancedData?advancedDataError:isSearchActive?searchError : error
  let nextDis=avdavancedData?avdavancedData?.hasMoreRecords:isSearchActive?searchData?.hasMoreRecords : data?.hasMoreRecords
  return (
    <div
      className={
        (clearSearch ? " ccwp_creditor_header" : "") +
        (showBasicSearch || showAdvanceSearch ? " hide_claims_table" : "")

      }
    >
      {activeClaim === 0 ? (
        <NoClaimModel
          showModal={showModal}
          setShowModal={setShowModal}
          disableCreditor={disableCreditor}
          successPopUp={successPopUp}
          setSuccessPopUp={setSuccessPopUp}
          itemName={creditor?.creditorName}
        />
      ) : (
        <ActiveClaimModel
          showModal={showModal}
          setShowModal={setShowModal}
          activeClaim={activeClaim}
          setActiveClaim={setActiveClaim}
        />
      )}
      <Table
        columnDefs={columnDefs}
        cellRenderers={
          tableData
        }
        columnTypes={{...sortedColumnTypes}}
        handlePaginationData={handlePaginationData}
            paginationObj={paginationObj}
        TitleComplement={() => (
          <TitleComplement
            caseId={caseId}
            showBasicSearch={showBasicSearch}
            setShowBasicSearch={setShowBasicSearch}
            clearBasicSearch={clearBasicSearch}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            onSearch={handleSearch}
            handleQuickSearchClear={handleQuickSearchClear}
            resetSortStatus={resetSortStatus}
            searchQuery={searchQuery}
            showAdvanceSearch={showAdvanceSearch}
            setShowAdvanceSearch={setShowAdvanceSearch}
            basicSearchPayload={basicSearchPayload}
            isBasicSearchActive={isBasicSearchActive}
            advanceSearchPayload={advanceSearchPayload}
            isAdvanceSearch={isAdvanceSearchActive}
          />
        )}
        overlayNoRowsTemplate={
          tableDataFetching
          ? t(tableLoadingData):tableError
            ? t("global.globalNotifications.wentWrong")
            : t("global.globalNotifications.noRecords")}
        title={headerData.title}
        description={headerData.description}
        pagination={false}
        caseRenderers={caseRenderers}
      />
       {!(showBasicSearch || showAdvanceSearch) && <CustomPagination
              currentpage={paginationObj.PageNumber}
              pageSize={paginationObj.PageSize}
              disableNext={!nextDis}
              onChangePageSize={(page: number) => {
                setPaginationObj({
                  ...paginationObj,
                  PageSize: page,
                  PageNumber: 1,
                });
              }}
              onChangeNextPage={() => {
                setPaginationObj({
                  ...paginationObj,
                  PageNumber: paginationObj.PageNumber + 1,
                });
              }}
              onChangePreviousPage={() => {
                setPaginationObj({
                  ...paginationObj,
                  PageNumber: paginationObj.PageNumber - 1,
                });
              }}
              
              pageSizeSelector={paginationPageSizeSelector}
            />
}
      {showBasicSearch && (
        <BasicSearch
          handleChange={handleChange}
          creditorName={formValues.creditorName}
          creditorId={formValues.creditorId}
          claimId={formValues.claimId}
          debtorName={formValues.debtorName}
          country={formValues.country}
          province={formValues.province}
          postalCode={formValues.postalCode}
          phoneNumber={formValues.phoneNumber}
          claimUpdateDate={formValues.claimUpdateDate}
          setShowBasicSearch={setShowBasicSearch}
          handleSubmit={handleSubmit}
        />
      )}
      {showAdvanceSearch && (
        <AdvanceSearch
        setAdvanceSearchData={setAdvanceSearchData}
        advanceSearchData={advanceSearchData}
          setShowAdvanceSearch={setShowAdvanceSearch}
          setClearSearch={setClearSearch}
          handleResetPaginationandQuickSearch={handleResetPaginationandQuickSearch}
          setAdvanceSearchPayload={setAdvanceSearchPayload}
          setIsAdvanceSearchActive={setIsAdvanceSearchActive}
          setPaginationObj={setPaginationObj}
        />
      )}
    </div>
  );
}
