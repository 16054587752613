import { useTranslation } from "react-i18next";

const useHeaderDefinitions = () => {
  const { t } = useTranslation();
  const caselistcolheader = [
    {
      suppressHeaderMenuButton: true,
      field: "userName",
      headerName: t("pages.caseSummary.fieldLabelDetails.teamMember"),
      sortable: false,
      unSortIcon: false,
      suppressCellFocus: true,
      headerComponentParams: {
        enableSorting: false,
        showFilter: false,
      },
      editable: false,
      resizable: false,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
      },
      flex: 3,
    },
    {
      field: "role",
      headerName: t("pages.caseSummary.fieldLabelDetails.role"),
      flex: 3,
      sortable: false,
      editable: true,
      suppressCellFocus: true,
      suppressHeaderMenuButton: true,
      resizable: false,
      headerComponentParams: {
        enableSorting: false,
      },
      cellEditor: "agSelectCellEditor",
      cellEditorParams: (e: any) => {
        return {
          values: [t("pages.caseSummary.fieldLabelDetails.caseViewer"), t("pages.caseSummary.fieldLabelDetails.caseContributor"), t("pages.caseSummary.fieldLabelDetails.caseManager")],
        };
      },
    },
    {
      cellRenderer: "actionCellRenderers",
      field: "",
      flex: 2,
      headerName: "",
      sortable: false,
      editable: false,
      suppressCellFocus: true,
      suppressHeaderMenuButton: true,
      resizable: false,
    },
  ];
  return caselistcolheader;
};
export default useHeaderDefinitions;
