import { useTranslation } from "react-i18next";
import { AttributeType } from "@/pages/CaseDetail/Configuration/Attributes/type";
import { dateFunc } from "../Functions/dateFunc";

export default function CustomAttributeValue({
  attributeType,
  value,
  picklistDefinitions,
}: {
  attributeType: AttributeType;
  value: any;
  picklistDefinitions: any;
}) {
  const { t } = useTranslation();

  const returnValue = () => {
    switch (attributeType) {
      case AttributeType.FreeText:
        return value.textValue ? value.textValue : "-";
      case AttributeType.Number:
        return value.numberValue ? value.numberValue : "-";
      case AttributeType.Boolean:
        if(value.booleanValue === null) {
          return "-"
        } else {
          if(value.booleanValue) {
            return t("pages.configuration.attributes.createAttribute.yes")
          } else {
            return t("pages.configuration.attributes.createAttribute.no")
          }
        }
      case AttributeType.Date:
        return value.dateValue ? dateFunc(value.dateValue).formattedDateOnly : "-";
      case AttributeType.SingleSelect:
        const selected = picklistDefinitions.filter((x: any) => {
          return x.id === value.selectedValue;
        });
        return selected.length !== 0 ? selected[0].name : "-";
      case AttributeType.MutliSelect:
        const multiSelected = picklistDefinitions.filter((x: any) => {
          return value.selectedValues.includes(x.id);
        });

        return multiSelected.length !== 0
          ? multiSelected.map((x: any) => x.name).join()
          : "-";
      case AttributeType.Currency:
        return value.amount || value.amount === 0? value.amount : "-";
      case AttributeType.Document:
        return value.displayName ? value.displayName : "-";
      default:
        return "-";
    }
  };

  return <span>{returnValue()}</span>;
}
