import { j as l } from "../../jsx-runtime-BwWtxLpl.js";
import "react";
import r from "prop-types";
import s from "classnames";
const a = ({ children: e, className: i, orientation: t = "vertical", variant: o = "card-border", ...d }) => {
  const c = s({
    "motif-card-border": o === "card-border",
    "motif-card-no-border": o === "card-no-border",
    "motif-card-full-width": o === "full-width",
    "motif-card-orientation-vertical": t === "vertical",
    "motif-card-orientation-horizontal": t === "horizontal",
    [i]: !0
  });
  return /* @__PURE__ */ l.jsx("div", { className: c, ...d, "data-motif-card": !0, children: e });
};
a.propTypes = {
  /**
   * The content of the component.
   */
  children: r.node,
  /**
   * Custom CSS classes to be added to the card.
   */
  className: r.string,
  /**
   * The orientation of the card.
   */
  orientation: r.oneOf(["vertical", "horizontal"]),
  /**
   * The variant of the card.
   */
  variant: r.oneOf(["full-width", "card-border", "card-no-border"])
};
a.defaultProps = {
  children: null,
  className: ""
};
export {
  a as MotifCard
};
