import { MotifAlertIcErrorOutline24px } from "@ey-xd/motif-icon";
import "./ErrorMessage.scss";
import { MotifIcon } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";

function ErrorMessage({ errorMessage }: { errorMessage?: string }) {
  const {t}=useTranslation();
  return (
    <div className="error-message">
      <div className="icon-container">
        <MotifIcon
          iconFunction={MotifAlertIcErrorOutline24px}
          stroke="#FF3D00"
          strokeWidth="1"
          size="28"
        />
      </div>
      <div className="message-title">
      {t("pages.claims.claimsTable.bulkImport.uploadFailed")}    

      </div>
      <div className="message-description">
      {errorMessage || t("pages.claims.claimsTable.bulkImport.uploadFailedDescriptionAlt")}
      </div>
    </div>
  );
}

export default ErrorMessage;
