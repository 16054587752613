import { MotifIcon, MotifButton } from "@ey-xd/motif-react";
import { IconoirEditPencil, IconoirTrash } from "@ey-xd/motif-icon";

type deleteType = (
  modalShow: boolean,
  userId: string,
  userName: string
) => void;

const useCellRenderers = (handleDelete: deleteType) => {
	const caseRenderers = {
		actionCellRenderers: function (params: any) {
			return (
        <div className="motif-row">
          <div className="motif-col-md-4">
            <MotifButton
              size="small"
              variant="primary"
              type="button"
              onClick={() =>
                handleDelete(true, params.data.userCaseId, params.data.userName)
              }
            >
              <MotifIcon
                iconFunction={IconoirTrash}
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
          </div>
          <div className="motif-col-lg-4">
            <MotifButton
              onClick={() => {
                params.api.startEditingCell({
                  rowIndex: params.rowIndex,
                  colKey: "role",
                });
              }}
              size="small"
              type="button"
              variant="primary"
            >
              <MotifIcon
                iconFunction={IconoirEditPencil}
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
          </div>
        </div>
      );
		},
	};
	return caseRenderers;
};
export default useCellRenderers;
