import { useDispatch, useSelector } from "react-redux";
import {
  MotifModal,
  MotifModalBody,
  MotifModalHeader,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { MotifErrorMessage } from "@ey-xd/motif-react";

import "./global-errors.scss";
import { resetError, setErrorUI } from "./errors-slice";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function GlobalErrorPopup() {
  const { t } = useTranslation();
  const errors = useSelector((state: any) => state.errors);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    dispatch(resetError());
  };

  useEffect(() => {
    // handle session error
    if (errors[401]?.length > 0) {
      dispatch(
        setErrorUI({
          show: true,
          heading: "Session Expired",
          message:
            "Your session has expired. Redirecting to logout page in a few seconds...",
        })
      );
      setTimeout(() => {
        dispatch(resetError());
        navigate("/logout");
      }, 3000);
    }
  }, [errors, dispatch, navigate]);

  if (location.pathname === "/logout") {
    return null;
  }

  return (
    <MotifModal
      onClose={handleClose}
      show={errors.ui.show}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      className="global-error-modal"
    >
      <MotifModalHeader>
        {errors.ui.heading || t("global.errors.heading")}
      </MotifModalHeader>
      <MotifModalBody>
        <MotifErrorMessage>
          {errors.ui.message || t("global.errors.message")}
        </MotifErrorMessage>
        {errors[401]?.length > 0 && <MotifProgressLoader />}
      </MotifModalBody>
    </MotifModal>
  );
}
