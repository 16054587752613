import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputBox from "@/components/Form/InputBox/InputBox";
import { EditSummaryFormType } from "./type";
import { ACCOUNT_DESC_FR_NAME, ACCOUNT_NAME, ACCOUNT_NAME_FR_NAME, CLIENT_NUMBER, DESC_NAME, INSTITUION_NUMBER, PREFIX, STARTING_SEQUENCE_NUMBER, TRANSIT_NUMBER, UNIT } from "./constant";

type ControlInputBoxType = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<EditSummaryFormType>;
  name:
    | typeof ACCOUNT_NAME
    | typeof DESC_NAME
    | typeof ACCOUNT_NAME_FR_NAME
    | typeof ACCOUNT_DESC_FR_NAME
    | typeof PREFIX
    | typeof UNIT
    | typeof STARTING_SEQUENCE_NUMBER
    | typeof CLIENT_NUMBER
    | typeof INSTITUION_NUMBER
    | typeof TRANSIT_NUMBER
  showLabel: boolean;
  label: string;
  readonly?: boolean;
  type?: "number" | "email" | "text" | "tel" | "date" | "password" | undefined;

};

export default function ControlInputBox({
  name,
  control,
  required = false,
  isTextArea = false,
  showLabel,
  label,
  readonly,
  type
  
}: ControlInputBoxType) {
  const { field } = useController({
    name,
    control,
    rules: { required: required },
  });
  const { t } = useTranslation();

  return (
    <>
      {showLabel && (
        <div className="formFieldLabel">
          <label htmlFor="input" aria-label={label}>
            {t(label)}
            {required ? <span className="requiredSymbol">*</span> : ""}
          </label>
        </div>
      )}
      <Controller
        name={field.name}
        control={control}
        rules={{ required }}
        render={({ field: { onChange, value, ...args } }) => {
          return (
            <>
              <InputBox
                id={ACCOUNT_NAME}
                required={true}
                value={value || ""}
                onChange={onChange}
                isTextArea={isTextArea}
                readonly={readonly}
                type={type}
                {...args}
              />
            </>
          );
        }}
      />
    </>
  );
}
