import { MotifChip } from "@ey-xd/motif-react";
import {
  AdminMemberCell,
  CaseData,
  StatusCell,
} from "@/features/caselist/components/caseListType";
import { useTranslation } from "react-i18next";
import { dateFunc } from "@/components/Functions/dateFunc";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setActiveTab } from "@/components/TabPanel/TabPanelSlice";

const useCustomCellRenderers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.clear();
  }, []);
  const caseRenderers = {
    caseNameRenderer: function (params: CaseData) {
      const { caseName, caseId } = params.data;
      return (
        <>
          <div className="ccwp_casename_wrapper">
            <Link
              onClick={() => {
                dispatch(setActiveTab(0));
              }}
              to={`/case/${caseId}`}
            >
              <h4 className="motif-body2-default-regular">{caseName}</h4>
            </Link>
          </div>
        </>
      );
    },
    dotCellRenderer: function (row: StatusCell) {
      return (
        <>
          {row.value === true ? (
            <MotifChip dotSide="left" variant="success">
              {t("pages.home.rowList.statusOpen")}
            </MotifChip>
          ) : (
            <MotifChip dotSide="left" variant="warning">
              {t("pages.home.rowList.statusClose")}
            </MotifChip>
          )}
        </>
      );
    },
    adminMembersCellRenderer: function (row: AdminMemberCell) {
      return <>-</>;
    },
    lastUpdatedBy: function (row: CaseData) {
      const { lastUpdatedDate } = row.data;
      return (
        <>
          <div className="ccwp_casename_wrapper ccwp_lastupdated_case">
            <h4 className="motif-body2-default-regular">
              {dateFunc(lastUpdatedDate).formattedDateOnly}
            </h4>
          </div>
        </>
      );
    },
    abbreviation: function (row: any) {
      return (
        <>
          <div className="ccwp_casename_wrapper ccwp_lastupdated_case">
            <h4 className="motif-body2-default-regular">{row.value}</h4>
          </div>
        </>
      );
    },
  };
  return caseRenderers;
};
export default useCustomCellRenderers;
