import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DescriptionItemsState {
  selectedStageCompleteDetail: any;
  claimDocument: {
    caseId?: string;
    documentTypeId: string;
    documentType: string;
    displayName: string;
    comment?: string;
    document?: File | null;
  }[];
  stageDocument: null | {
    caseId?: string;
    fileName: string;
    documentType: string;
    displayName: string;
    comment?: string;
    document?: File | null;
  }
  otherDocuments: any;
}

const initialState: DescriptionItemsState = {
  selectedStageCompleteDetail: "",
  claimDocument: [],
  otherDocuments: null,
  stageDocument: null,
};

export const claimSetupDescription = createSlice({
  name: "claimSetupDescription",
  initialState,
  reducers: {
    setStageSelectedItems: (state, action: PayloadAction<any>) => {
      state.selectedStageCompleteDetail = action.payload;
    },
    resetDescriptionState: (state) => {
      return initialState;
    },
    setClaimDocument: (state, action: PayloadAction<any>) => { 
      state.claimDocument = action.payload;
    },
    setStageDocument: (state, action: PayloadAction<any>) => {
      state.stageDocument = action.payload;
    }
  },
});

export const {
  setStageSelectedItems,
  resetDescriptionState,
  setClaimDocument,
  setStageDocument
} = claimSetupDescription.actions;

export default claimSetupDescription.reducer;
