import { MotifErrorMessage, MotifProgressLoader } from "@ey-xd/motif-react";
import {  ADDRESS_1_FIELD, ADDRESS_2_FIELD, CITY_FIELD, COUNTRY_NAME, EMAIL_FIELD, FAX_FIELD,  FIRST_NAME_FIELD, LAST_NAME_FIELD, PHONE_NUMBER_FIELD, POSTAL_CODE_FIELD, PREFIX_NAME, PROVINCE_STATE_NAME, RELATIONSHIP_TO_CREDITOR_NAME, SELECT_A_CONTACT_NAME } from "../../Constants";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useListCreditorContactQuery } from "@/services/creditorContactList/creditorContactListReducer";
import { getCreditorContactTypeOptions, getStateListByCountry, resetformContact, updateformWithSelectedContact, updateformWithStoreContact } from "../CreditorHelper";
import ContactSelect from "./ContactFormFields/ContactSelect";
import ContactFormInput from "./ContactFormFields/ContactFormInput";
import {  SECONDARY } from "@/Constant";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useGetCountryStateListDataQuery, useGetStateByCountryIdQuery } from "@/services/countryState/countryStateReducer";
import { countryRecordType } from "@/pages/CaseDetail/Creditor/CreditorSetup/type";
import StateDropdown from "./ContactFormFields/StateDropdown";
import { useGetcreditorContactsDataApiDataQuery } from "@/services/claimSummary/caseSummaryReducer";
import Button from "@/components/Form/Button/Button";
import { CreditorSearchData, setClaimSetupCreditorContactDetailsData, setSelectedCountryState, setSelectedCreditorCompleteDetail } from "../creditorSlice";
import { useDispatch, useSelector } from "react-redux";

export type ClaimCreditorContactFormType = {
  [SELECT_A_CONTACT_NAME]: string;
  [FIRST_NAME_FIELD]: string;
  [LAST_NAME_FIELD]: string;
  [PREFIX_NAME]: string;
  [RELATIONSHIP_TO_CREDITOR_NAME]: string;
  [PHONE_NUMBER_FIELD]: string;
  [FAX_FIELD]: string;
  [ADDRESS_1_FIELD]: string;
  [ADDRESS_2_FIELD]: string;
  [EMAIL_FIELD]: string;
  [CITY_FIELD]: string;
  [COUNTRY_NAME]: string;
  [PROVINCE_STATE_NAME]: string;
  [POSTAL_CODE_FIELD]: string;
};

interface RootState {
  claimSetupCreditorSearch: CreditorSearchData;
}

type ContactFormProps = {
  creditorId: string;
  clearContactForm: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  goToNextTab: () => void;
  changeCreditor: boolean;
  setDataInStore: Dispatch<SetStateAction<boolean>>;
  dataInStore: boolean;
};

export default function ContactForm({
  creditorId,
  clearContactForm,
  setIsVisible,
  goToNextTab,
  dataInStore,
  setDataInStore,
}: ContactFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [countryArray, setCountryArry] = useState([
    {
      label: t("global.globalActions.optionDefault"),
      value: "",
    },
  ]);
  const {
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<ClaimCreditorContactFormType>({ mode: "onChange" });
  let SELECT_A_CONTACT_OPTIONS = useMemo(() => [{ label: "", value: "" }], []);
  const PREFIX_OPTIONS_LIST = [
    { 
        label: t("global.globalActions.optionDefault"), 
        value: "" },
    {
        label:t("global.globalActions.prefixOptionsListLabels.mr"),
        value: "Mr.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.ms"),
        value: "Ms.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.mrs"),
        value: "Mrs.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.miss"),
        value: "Miss.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.other"),
        value: "other",
    }
]
  const { data: creditorContactData, isLoading: creditorContactIsLoading } =
    useListCreditorContactQuery(creditorId, { skip: !creditorId });
  const { data, isLoading } = useGetCountryStateListDataQuery("");

  const selectedCreditorContactDetailsFromStore = useSelector(
    (state: RootState) => {
      return state.claimSetupCreditorSearch.searchCreditorContactDetailsData;
    }
  );

  const contactSelectedID = watch(SELECT_A_CONTACT_NAME);

  const onChangeHandler = (contactId: string) => {
    setValue(SELECT_A_CONTACT_NAME, contactId);
  };

  useEffect(() => {
    if (data !== undefined) {
      const countryOptions = data.map((record: countryRecordType) => {
        return {
          label: record.countryName,
          value: record.countryId,
        };
      });
      setCountryArry(countryOptions);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (clearContactForm) {
      reset({
        [SELECT_A_CONTACT_NAME]: "",
        [FIRST_NAME_FIELD]: "",
        [LAST_NAME_FIELD]: "",
        [PREFIX_NAME]: "",
        [RELATIONSHIP_TO_CREDITOR_NAME]: "",
        [PHONE_NUMBER_FIELD]: "",
        [FAX_FIELD]: "",
        [ADDRESS_1_FIELD]: "",
        [ADDRESS_2_FIELD]: "",
        [EMAIL_FIELD]: "",
        [CITY_FIELD]: "",
        [COUNTRY_NAME]: "",
        [PROVINCE_STATE_NAME]: "",
        [POSTAL_CODE_FIELD]: "",
      });
    }
  }, [clearContactForm, reset]);

  if (creditorContactData && creditorContactData.length > 0) {
    SELECT_A_CONTACT_OPTIONS =
      getCreditorContactTypeOptions(creditorContactData);
  }

  useEffect(() => {
    if (
      SELECT_A_CONTACT_OPTIONS &&
      SELECT_A_CONTACT_OPTIONS.length > 0 &&
      contactSelectedID === "" &&
      dataInStore === true
    ) {
      setValue(
        SELECT_A_CONTACT_NAME,
        selectedCreditorContactDetailsFromStore?.creditorContactId
      );
    }
    if (
      SELECT_A_CONTACT_OPTIONS &&
      SELECT_A_CONTACT_OPTIONS.length > 0 &&
      contactSelectedID === "" &&
      dataInStore === false
    ) {
      setValue(SELECT_A_CONTACT_NAME, SELECT_A_CONTACT_OPTIONS[0].value);
    }
  }, [
    SELECT_A_CONTACT_OPTIONS,
    contactSelectedID,
    dataInStore,
    selectedCreditorContactDetailsFromStore?.creditorContactId,
    setValue,
  ]);

  const {
    data: creditorSelectedContactData,
    isLoading: isCreditorSelectedContactLoading,
  } = useGetcreditorContactsDataApiDataQuery(contactSelectedID, {
    skip: contactSelectedID === "" || !contactSelectedID,
  });

  useEffect(() => {
    if (selectedCreditorContactDetailsFromStore.creditorId) {
      updateformWithStoreContact(
        selectedCreditorContactDetailsFromStore,
        setValue
      );
    } else if (creditorSelectedContactData) {
      updateformWithSelectedContact(creditorSelectedContactData, setValue);
    }
  }, [
    selectedCreditorContactDetailsFromStore,
    creditorSelectedContactData,
    contactSelectedID,
    setValue,
  ]);

  useEffect(() => {
    resetformContact(setValue);
    setDataInStore(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [clearContactForm]);

  const countrySelectedId = watch(COUNTRY_NAME);

  const { data: stateList, isLoading: isLoadingStateList } =
    useGetStateByCountryIdQuery(countrySelectedId, {
      skip: !countrySelectedId,
    });

  const stateListBycounrty = getStateListByCountry(stateList);
  const selectedState = watch(PROVINCE_STATE_NAME);

  const onSubmit = (data: ClaimCreditorContactFormType) => {
    if (creditorId) {
      dispatch(setSelectedCreditorCompleteDetail(creditorId));
    }

    const countryName =
      countryArray.find((option) => option.value === countrySelectedId)
        ?.label || "";
    const stateName =
      stateListBycounrty?.find(
        (option) =>
          option.value === selectedState
      )?.label || "";

    dispatch(
      setSelectedCountryState({
        countryName: countryName,
        stateName: stateName,
      })
    );
    dispatch(
      setClaimSetupCreditorContactDetailsData({
        creditorContactId: creditorSelectedContactData?.contactId,
        creditorId: creditorSelectedContactData?.creditorId,
        firstName: data.firstName,
        lastName: data.lastName,
        prefix: data.prefix,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        provinceStateId: data.provinceState,
        provinceState: stateName,
        postalCode: data.postalCode,
        countryId: data.country,
        country: countryName,
        phone: data.phone,
        fax: data.fax,
        email: data.email,
        relationshipToCreditor: data.relationshipToCreditor,
        isPrimaryContact: creditorSelectedContactData?.isPrimaryContact,
      })
    );
    goToNextTab();
  };

  if (
    isCreditorSelectedContactLoading ||
    creditorContactIsLoading ||
    isLoadingStateList
  ) {
    return <MotifProgressLoader show variant="default" />;
  }

  if (!creditorSelectedContactData?.contactId || !creditorId) {
    return null;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-row">
          <div className="motif-col-lg-6">
            <ContactSelect
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.selectContact"}
              name={SELECT_A_CONTACT_NAME}
              options={SELECT_A_CONTACT_OPTIONS}
              control={control}
              selectedId={contactSelectedID}
              onChangeHandler={onChangeHandler}
            />
            {errors[SELECT_A_CONTACT_NAME] &&
              errors[SELECT_A_CONTACT_NAME].type === "required" && (
                <MotifErrorMessage>
                  {t("pages.claims.claimCreate.creditorTab.validationMessages.selectContactMessage")}
                </MotifErrorMessage>
              )}
          </div>
        </div>
        <>
          <div>
            <div className="motif-row mar-t-30">
              <div className="motif-col-lg-6">
                {/* <ContactFormInput name={CONTACT_ID_FIELD} control={control} label={t("")}/> */}
                <ContactFormInput
                  name={"firstName"}
                  control={control}
                  label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.firstName"}
                  required={true}
                />
                {errors[FIRST_NAME_FIELD] &&
                  errors[FIRST_NAME_FIELD].type === "required" && (
                    <MotifErrorMessage>
                  {t("pages.claims.claimCreate.creditorTab.validationMessages.firstNameMessage")}
                  </MotifErrorMessage>
                  )}
              </div>
              <div className="motif-col-lg-6">
                <ContactFormInput
                  name={LAST_NAME_FIELD}
                  control={control}
                  label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.lastName"}
                  required={true}
                />
                {errors[LAST_NAME_FIELD] &&
                  errors[LAST_NAME_FIELD].type === "required" && (
                    <MotifErrorMessage>
                  {t("pages.claims.claimCreate.creditorTab.validationMessages.lastNameMessage")}
                  </MotifErrorMessage>
                  )}
              </div>
            </div>
            <div className="motif-row mar-t-30">
              <div className="motif-col-lg-6">
                <ContactSelect
                  label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.prefix"}
                  name={PREFIX_NAME}
                  options={PREFIX_OPTIONS_LIST}
                  control={control}
                />
                {errors[PREFIX_NAME] &&
                  errors[PREFIX_NAME].type === "required" && (
                    <MotifErrorMessage>
                  {t("pages.claims.claimCreate.creditorTab.validationMessages.prefixMessage")}
                  </MotifErrorMessage>
                  )}
              </div>
              <div className="motif-col-lg-6">
                <ContactFormInput
                  name={RELATIONSHIP_TO_CREDITOR_NAME}
                  control={control}
                  label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.relationshipToCreditor"}
                  required={false}
                />
                {errors[RELATIONSHIP_TO_CREDITOR_NAME] &&
                  errors[RELATIONSHIP_TO_CREDITOR_NAME].type === "required" && (
                    <MotifErrorMessage>
                  {t("pages.claims.claimCreate.creditorTab.validationMessages.creditorRelationMessage")}
                  </MotifErrorMessage>
                  )}
              </div>
            </div>
            <div className="motif-row mar-t-30">
              <div className="motif-col-lg-6">
                <ContactFormInput
                  name={PHONE_NUMBER_FIELD}
                  control={control}
                  label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.phoneNumber"}
                  isNumber={false}
                  required={false}
                />
                {errors[PHONE_NUMBER_FIELD] &&
                  errors[PHONE_NUMBER_FIELD].type === "pattern" && (
                    <MotifErrorMessage>
                      {t("Phone number must be a number")}
                    </MotifErrorMessage>
                  )}
              </div>
              <div className="motif-col-lg-6">
                <ContactFormInput
                  name={FAX_FIELD}
                  control={control}
                  label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.fax"}
                  isNumber={true}
                  required={false}
                />
                {errors[FAX_FIELD] && errors[FAX_FIELD].type === "pattern" && (
                  <MotifErrorMessage>
                    {t("pages.claims.claimCreate.creditorTab.validationMessages.faxMessage")}
                  </MotifErrorMessage>
                )}
              </div>
            </div>
            <div className="motif-row mar-t-30">
              <div className="motif-col-lg-6">
                <ContactFormInput
                  name={ADDRESS_1_FIELD}
                  control={control}
                  label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.address1"}
                />
              </div>
              <div className="motif-col-lg-6">
                <ContactFormInput
                  name={ADDRESS_2_FIELD}
                  control={control}
                  label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.address2"}
                  required={false}
                />
              </div>
            </div>
            <div className="motif-row mar-t-30">
              <div className="motif-col-lg-6">
                <ContactFormInput
                  name={EMAIL_FIELD}
                  control={control}
                  label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.email"}
                  required={false}
                />
              </div>
              <div className="motif-col-lg-3">
                <ContactFormInput
                  name={CITY_FIELD}
                  control={control}
                  label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.city"}
                />
              </div>
              <div className="motif-col-lg-3">
                <ContactSelect
                  label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.country"}
                  name={COUNTRY_NAME}
                  options={countryArray}
                  control={control}
                  selectedId={""}
                  filter={true}
                  placeHolder={t("global.globalPlaceholder.countryPlaceholder")}
                  required={false}
                />
              </div>
            </div>
            <div className="motif-row justify-content-end mar-t-30">
              <div className="motif-col-lg-3">
                <StateDropdown
                  countryID={countrySelectedId}
                  control={control}
                  stateId={selectedState}
                  stateListByCountry={stateListBycounrty}
                  required={false}
                />
              </div>
              <div className="motif-col-lg-3">
                <ContactFormInput
                  name={POSTAL_CODE_FIELD}
                  control={control}
                  label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.postalCode"}
                />
              </div>
            </div>
          </div>
        </>

        <div className="display-flex justify-content-end mar-t-70">
          <div className="pad-r-10">
            <Button
              type="button"
              label={t("global.globalActions.cancel")}
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={() => setIsVisible(true)}
            />
          </div>
          <div className="">
            <Button label={t("global.globalActions.next")} variant="primary" />
          </div>
        </div>
      </form>
    </div>
  );
}