import { createType, deleteUserType, searchType, updateRoleType } from "@/pages/UserManagement/UsermanagementTypes";
import { ccwpApi } from "..";


export const UsersApi=ccwpApi.injectEndpoints({
    endpoints:(builder)=>({
        getUsersList: builder.mutation<any,Partial<searchType>>({
            
            query: (searchData) => ({
              url:  "/User/GetADUsers",
              method: "POST",
              body:searchData,
              keepUnusedDataFor:0             
            }),
          
          
        }),
        createUser: builder.mutation({
            query: (newUserData:createType) => ({
              url:  "/User/CreateUser",
              method: "POST",
              body:newUserData,
              keepUnusedDataFor: 0,
            }),
            
            
          
          
        }),
        getListUsers: builder.query({
            query: (paramData) => ({
              url:  "/User/GetUsers",
              method: "GET"   ,
              params:{...paramData}          
            }),
          
          
        }),
        deleteUser: builder.mutation({
            query: (deleteUserData:deleteUserType) => ({
              url:  "/User/DeleteUser",
              method: "DELETE",
              body:deleteUserData
             
            }),
          
          
        }),
        UpdateRole: builder.mutation({
          query: (roleUserData:updateRoleType) => ({
            url:  "/User/UpdateUser",
            method: "PUT",
            body:roleUserData
           
          }),
        
        
      }),


    })
})

export const{useGetUsersListMutation,useCreateUserMutation,useGetListUsersQuery,useDeleteUserMutation,useUpdateRoleMutation}=UsersApi
