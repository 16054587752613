import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "@/config";
import getMSALCache from "@/features/auth/lib/getMSALCache";

const {
  backend: { baseUrl },
  features: { session },
} = config;

// Define a service using a base URL and expected endpoints
export const ccwpApi = createApi({
  reducerPath: "ccwpApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const backendAccessToken = getMSALCache("accesstoken", "default");
      if (session && backendAccessToken?.secret) {
        headers.set("Authorization", `Bearer ${backendAccessToken.secret}`);
      }
      return headers;
    },
  }),
  keepUnusedDataFor: 1,
  tagTypes: [
    "CreditorContact",
    "CreditorList",
    "DebtorList",
    "CreditorSummary",
    "SchemeList",
    "DebtorEditView",
  ],
  endpoints: (builder) => ({
    healthcheck: builder.query<any, void>({
      query: () => "/case/health",
    }),
  }),
});

export const { useLazyHealthcheckQuery } = ccwpApi;
