export interface StageType {
  id?: string;
  caseId?: string,
  name?: string;
  description?: string;
  nameFrench?: string;
  descriptionFrench?: string;
  position?: PositionType;
  sortOrder: number;
  isActive: boolean;
}

export interface StatusType {
  caseId?: string;
  claimStageDefinitionId?: string;
  id?: string;
  name?: string;
  description?: string;
  nameFrench?: string;
  descriptionFrench?: string;
  sortOrder: number;
  isInitialStatus: boolean;
  isInitialPortalStatus: boolean;
  isDocReleaseStatus: boolean;
  isDocRequiredStatus: boolean;
  isResolveStatus: boolean;
  timeLimitDays?: number;
  timeLimitDate?: string;
  portalDisplayStatus?: string;
}

export const EmptyStage : StageType = {
  id: undefined,
  caseId: undefined,
  name: undefined,
  description: "",
  nameFrench: "",
  descriptionFrench: "",
  position: undefined,
  sortOrder: 0,
  isActive: true,
};

export const EmptyStatus : StatusType = {
  id: undefined,
  name: undefined,
  description: "",
  nameFrench: "",
  descriptionFrench: "",
  sortOrder: 1,
  isInitialStatus: false,
  isInitialPortalStatus: false,
  isDocReleaseStatus: false,
  isDocRequiredStatus: false,
  isResolveStatus: false,
  portalDisplayStatus: "",
};

export enum DueDateType {
  None = 0,
  TimeLimit = 1,
  Deadline = 2,
}

export enum PositionType {
  DebtorPosition = 0,
  CreditorPosition = 1,
}

export enum OrderChange {
  Up = 0,
  Down = 1,
}
