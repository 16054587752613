import { ccwpApi } from "@/services";
import { LIST_CLAIM_STAGES_BY_CASE } from "@/services/constant/apiPath";

export const claimStageByCase = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    listClaimStagesByCase: builder.query<any, string>({
      query: (caseId) => ({
        url: LIST_CLAIM_STAGES_BY_CASE + caseId,
        method: "GET",
      }),
    }),
  }),
});

export const { useListClaimStagesByCaseQuery } = claimStageByCase;
