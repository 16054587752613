const configuration = {
  backButton: "Back to Home",
  units: {
    title: "Units",
    mainTitle: "Unit Conversion Management",
    tableTitle: "Units",
    tableDescription: "List of all units",
    tableColumnDetails: {
      column1: "Default",

      column2: "Unit Name",

      column3: "Description",

      column4: "Rate",

      column5: "Decimal Place",
    },
    createUnit: {
      primaryTitle: "Unit Setup",
      primaryDescription:
        "Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed aliquam ligula placerat massa. At interdum lectus auctor",
      secondaryTitle: "Unit Details",
      secondaryDescription:
        "Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio",
    },
    formLabels: {
      unitIdentification: "Unit Identification",
      unitName: "Unit Name",
      rate: "Rate",
      decimalRate: "Decimal Place",
      unitDescription: "Unit Description",
      defaultCurrency: "Make this the default currency",
      includeFrench: " I want to include case identifiers in French.",
      french: "French",
      unitNameFrench: "Unit Name (French)",
      unitDescriptionFrench: "Unit Description (French)",
      removeSection: "Remove section",
      currencyUnselect:
        "Are you sure to unselect this unit as Default Currency?",
      currencySelect: "Are you sure to select this unit as Default Currency?",
    },
    validationMessages: {
      unitNameRequired: "Unit Name is required",
      unitExists:
        "Entered Unit Name already existing, please enter other Unit Name",
      validName: "Please enter a valid Unit Name",
      rateRequired: "Rate is required",
      rateMustNumber: "Rate must be a number",
      decimalPlaceRequired: "Decimal Place is required",
      unitDescriptionRequired: "Unit Description is required",
      exchangeRateMessage: "Default Unit's Exchange Rate must be '1'",
      decimalMustNumber: "Decimal Place must be a number",
    },
  },
  caseZone: {
    title: "Case Time Zones",
    description: "Descriptive text that describes your page or content.",
    timeZone: "Time Zone",
    timeZoneRequired: "The Time Zone cannot be empty or contain only spaces.",
  },
  schemes: {
    title: "Schemes",
  },
  caseTimeZones: {
    title: "Case Time Zones",
  },
  attributes: {
    title: "Attributes",
    subtitle: "Attribute Management",
    attributeTableName: "Attribute Name",
    attributeTableType: "Type",
    attributeTableDescription: "Description",
    attributeType: "Attribute Type",
    attributeTypeCreditor: "Creditor",
    attributeTypeClaim: "Claim",
    attributeTypeClaimItem: "Claim Item",
    createAttributeButton: "Create New Attribute",
    backButton: "Back",
    attributeSetup: "Attribute Setup",
    cancelButton: "Cancel",
    reviewButton: "Review and Submit",
    submitButton: "Submit",
    deleteButton: "Delete",
    deleteAttribute: "Delete Attribute",
    deleteAttributeMessage: "Are you sure you want to delete this Attribute?",
    requiredFields: "Please fill out all required attribute fields.",
    createAttribute: {
      title1: "Create",
      title2: "Attribute",
      attributeName: "Attribute Name",
      attributeNameFrench: "French Attribute Name",
      attributeDescription: "Attribute Description",
      attributeDescriptionFrench: "French Attribute Description",
      attributeType: "Attribute Type",
      freeText: "Free-Text",
      date: "Date",
      singleSelect: "Single Select",
      multiSelect: "Multi Select",
      boolean: "Boolean",
      currency: "Currency",
      number: "Number",
      document: "Document",
      requiredAttribute: "Make this attribute required",
      displayAttribute: "Display this attribute during creation",
      format: "Format",
      sinNumber: "SIN Number Format",
      email: "Email Format",
      regularExpression: "Regular Expression",
      telephone: "North American Telephone Format",
      postalCode: "Canadian Postal Code Format",
      none: "None",
      maxCharacter: "Max Character Limit",
      maskInput: "Mask input when displayed",
      value: "Value",
      option: "Option",
      addValue: "Add Another Value",
      defaultOption: "Default Option",
      defaultValue: "Default Value",
      yes: "Yes",
      no: "No",
      numberFormat: "Number Format",
      integer: "Integer",
      decimal: "Decimal",
      maxNumber: "Maximum Number",
      minNumber: "Minimum Number",
      fileFormat: "Accepted File Format",
      attributeDetails: "Attribute Details",
    },
    requiredError:
      "is a required field, please resolve the error before saving.",
    numberError: "Please enter a number between ",
    sinError: "format needs to be 9 digits long.",
    emailError: "Email format is invalid.",
    regexError: "Expression is in invalid format.",
    phoneError: "format needs to be 10 digits long.",
    postalError:
      "format needs to be  'ANA NAN', where 'A' represents a letter and 'N' represents a number.",
    fileError: "File format is invalid.",
  },
  stages: {
    title: "Stages",
    subtitle: "Stage Management",
    addNewButton: "Add New",
    status: "Status",
    initialPortal: "Initial status",
    requireDocument: "Require document attachment",
    releaseDocument: "Release document attached in this status",
    resolveStatus: "Resolve status",
    portalStatus: "Portal Status",
    dueDateType: "Due Date Type",
    timeLimit: "Time Limit",
    deadline: "Deadline",
    timeLimitDays: "Time Limit (in Days)",
    deadlineDate: "Deadline Date",
    addStage: "Add New Stage",
    editStage: "Edit Stage",
    stageName: "Stage Name",
    stageNameFrench: "Stage Name (French)",
    stageDescription: "Stage Description",
    stageDescriptionFrench: "Stage Description (French)",
    position: "Position",
    creditorPosition: "Creditor Position",
    debtorPosition: "Debtor Position",
    addStatus: "Add New Status",
    editStatus: "Edit Status",
    statusName: "Status Name",
    statusNameFrench: "Status Name (French)",
    statusDescription: "Status Description",
    statusDescriptionFrench: "Status Description (French)",
    deleteStage: "Delete Stage",
    deleteStageMessage: "Are you sure you want to delete this Stage?",
    deleteStatus: "Delete Status",
    deleteStatusMessage: "Are you sure you want to delete this Status?",
    edit: "Save",
    add: "Add",
    confirm: "Confirm",
    cancel: "Cancel",
    initialStatusUsedMessage: "Cannot delete an initial status",
  },
  category: {
    title: "Category",
    tableTitle: "Category",
    createCategory: "Create Category",
    deleteMesseage: "Unable to delete category as it is currently in use.",
    deleteSuccessfully: "Category deleted successfully.",
    tableDescription: "Descriptive text that describes your page or content.",
    columnDetails: {
      column1: "Category Name",

      column2: "Description",
    },
    validationMessages: {
      categoryRequired: "Category Name is required",
    },
    formLabels: {
      categoryName: "Category Name",
      categoryDescription: "Category Description",
      categoryNameFrench: "Category Name (French)",
      categoryDescriptionFrench: "Category Description (French)",
    },
    categoryEditTitle: "Edit Category",
    categoryCreateTitle: "Category Setup",
    categoryCreateDescription: `Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book`,
  },
  scheme: {
    primaryTitle: "Scheme Setup",
    primaryDescription:
      "Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed aliquam ligula placerat massa. At interdum lectus auctor nibh dui massa tristique. Eget nisi potenti aenean ultrices nunc sit quam leo. Non risus donec et enim semper.",
    secondaryTitle: "Create Scheme",
    secondaryDescription:
      "Lorem ipsum dolor sit amet consectetur. Fringilla pulvinar in sed volutpat. Nunc iaculis cras amet condimentum. Bibendum.",
    manageSetup: "Manage Scheme",
    schemeDetails: "Scheme Details",
    detailDescription: "Descriptive text that describes your page or content.",
    schemeEffective: "Make this scheme effective",
    edit: "Edit",
    manageRuleAndClass: "Manage Classes & Rules",
    manageRulesAndClassDescription:
      "Lorem ipsum dolor sit amet consectetur. Fringilla pulvinar in sed volutpat. Nunc iaculis cras amet condimentum. Bibendum.",
    preview: "Preview",
    viewVersion: "View Version",
    classes: "Classes",
    rules: "Rules",
    newClassButton: "Add a new class",
    noRecord: "No record found.",
    makeSchemeEffectiveErrorMessage:
      "Please ensure valid Classes and Rules are configured.",
    previewTitle: "Preview",
    previewDescription:
      "Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed aliquam ligula placerat massa. At interdum lectus auctor nibh dui massa tristique. Eget nisi potenti aenean ultrices nunc sit quam leo. Non risus donec et enim semper.",
    rule: {
      ruleConfiguration: "Rule Configuration",
      description: "Description",
      ruleName: "Rule Name",
      ruleLogic: "Rule Logic",
      ruleLogicDescription: "Creditors list related to the case",
      attributes: "Attributes",
      operator: "Operator",
      value: "Value",
      add: "+Add",
      and: "AND",
      or: "OR",
      cancel: "Cancel",
      save: "Save",
      close: "Close",
      deleteRule: "Delete Rule",
      deleteRuleMessage: "Are you sure you want to delete this Rule?",
      validationError: "Please ensure all fields are filled.",
      addRow: "Add Row",
      addGroup: "Add Group",
    },
    class: {
      addClass: "Add a New Class",
      editClass: "Edit Class",
      className: "Class Name",
      classNameFrench: "Class Name (French)",
      classDescriptionFrench: "Class Description (French)",
      classDescription: "Class Description",
      save: "Save",
      cancel: "Cancel",
      deleteClass: "Delete Class",
      deleteClassMessage: "Are you sure you want to delete this Class?",
      confirm: "Confirm",
      defaultClass: "Make this the default class",
    },
  },
  accounts: {
    deleteAccount: "Delete Account",
    deleteAccountMessage: "Are you sure you want to delete this account?",
    deleteSubAccount: "Delete Sub Acoount",
    deleteSubAccountMessage:
      "Are you sure you want to delete this sub account?",
  },
};

export default configuration;
