export enum DistributionsTab {
    Distribution = 0,
    ManageDataSource = 1,
    AccountSetUp = 2,
    PayeeType = 3,
    RateTierTemplate = 4,
  }
  
  export interface IDistributionsState {
    distributionStatus: any;
    currentTab: DistributionsTab;
  }
  