import React from "react";
import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { UNIT_DECIMAL_PLACE_NAME, UNIT_DESCRIPTION_NAME, UNIT_NAME, UNIT_RATE_NAME, UNIT_NAME_FRENCH, UNIT_DESCRIPTION_NAME_FRENCH } from "./Constants";
import { UnitDetailsForm } from "./type";
import InputBox from "@/components/Form/InputBox/InputBox";
import "@/components/Form/InputBox/InputBox.scss";

type ControlInputBoxType = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<UnitDetailsForm>;
  name:
    | typeof UNIT_NAME
    | typeof UNIT_RATE_NAME
    | typeof UNIT_DECIMAL_PLACE_NAME
    | typeof UNIT_DESCRIPTION_NAME
    | typeof UNIT_NAME_FRENCH
    | typeof UNIT_DESCRIPTION_NAME_FRENCH;
  showLabel: boolean;
  label: string;
  value: string | number;
  type?: "number" | "email" | "text" | "tel" | "date" | "password" | undefined;
  rules?: any;
  disabled?: boolean;
};

export default function ControlInputBox({ name, type, control, required = false, isTextArea = false, showLabel, label, value, rules, disabled = false }: ControlInputBoxType) {
    const {
        field,
    } = useController({
        name,
        control,
        rules: { required, ...rules },
    });
    const { t } = useTranslation();

    return (
        <>
            {
                showLabel && <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={label}>{t(label)}{required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
            <Controller
                name={field.name}
                control={control}
                disabled={disabled}
                rules={{ required, ...rules }}
                render={({ field: { onChange, value, ...args }, fieldState: { error } }) => {
                    return (
                        <>
                            <InputBox id={name} type={type} required={true} value={value} onChange={field.onChange} isTextArea={isTextArea} disabled={disabled} {...args} />
                            {error && <span className="error-message">{error.message}</span>}
                        </>
                    );
                }}
            />
        </>

    );
}
