import React from 'react';
import './CustomTabPanel.scss';

interface CustomTab {
    name: string;
    label: string;
    component: JSX.Element;
}

interface CustomTabPanelProps {
    tabs: CustomTab[];
    activeTabIndex: number;
    onTabChange: (index: number) => void;
    formSubmissionStatus: { [key: string]: boolean };
}

const CustomTabPanel: React.FC<CustomTabPanelProps> = ({ tabs, activeTabIndex, onTabChange,formSubmissionStatus }) => {

    const handleTabClick = (tabIndex: number) => {
        if (tabIndex <= activeTabIndex || formSubmissionStatus[tabs[tabIndex].name]) {
            onTabChange(tabIndex);
        }
    };


    return (
        <div className='custom-tab'>
            <div className="custom-tab-buttons">
                {tabs.map((tab, index) => (
                    <button
                        key={tab.name}
                        onClick={() => handleTabClick(index)}
                        disabled={index > activeTabIndex && !formSubmissionStatus[tab.name]}
                        className={`custom-tab-button motif-body1-default-regular ${activeTabIndex === index ? 'active' : ''}`}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className="custom-tab-content">
                {typeof activeTabIndex !== 'undefined' && tabs[activeTabIndex] && (
                    <div>
                        {tabs[activeTabIndex].component}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomTabPanel;
