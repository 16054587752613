import React, { useEffect } from "react";
import { MotifSelect, MotifFormField, MotifOption } from "@ey-xd/motif-react";

type SelectBoxType = {
  options: { label: string; value: string }[];
  value: string;
  onChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  filter?: boolean | ((x?: any) => void) | undefined;
  searchPlaceholder?: string;
  defaultValue?: string;
  visibleOptions?: number;
  placeholder?: string;
  disabled?: boolean;
  onChangeHandler: (schemeVersionId: string) => void;
};

export default function SelectBox({
  options,
  value,
  defaultValue,
  onChange,
  filter,
  searchPlaceholder,
  visibleOptions,
  placeholder,
  disabled,
  onChangeHandler,
}: SelectBoxType) {
  useEffect(() => {
    const btn = document.querySelectorAll(
      ".motif-select-input"
    ) as NodeListOf<Element>;
    btn.forEach((element: Element) => {
      (element as HTMLElement).setAttribute("type", "button");
    });
  }, []);
  const selectedValue = value || defaultValue || "";
  const onChangeHandlerEvent = (value: string) => {
    onChangeHandler(value);
    //@ts-ignore
    onChange(value);
  };
  return (
    <>
      <MotifFormField role="group">
        {/*@ts-ignore */}
        <MotifFormField>
          <MotifSelect
            ariaLabelledBy="select-label"
            onChange={onChangeHandlerEvent}
            value={selectedValue}
            filter={filter}
            searchPlaceholder={searchPlaceholder}
            visibleOptions={visibleOptions}
            placeholder={placeholder}
            disabled={disabled === true ? true : false}
          >
            {options.map((option) => (
              <MotifOption
                isSelected={option.value === selectedValue}
                key={option.value}
                disabled={option.value === "" ? true : false}
                value={option.value}
              >
                {option.label}
              </MotifOption>
            ))}
          </MotifSelect>
        </MotifFormField>
      </MotifFormField>
    </>
  );
}
