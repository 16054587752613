export default function handleDragOrder(
  data: any,
  currentIndex: number,
  targetIndex: number
) {
  const newData = [...data];
  const [removed] = newData.splice(currentIndex, 1);
  newData.splice(targetIndex, 0, removed);
  return newData;
}
