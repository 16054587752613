import Table from "@/components/Table/table";
import {
  MotifButton,
  MotifIcon,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { IconoirWarningTriangle } from "@ey-xd/motif-icon";
import { useEffect, useState } from "react";
import {
  actionIcDelete24px,
  contentIcCreate24px,
} from "@ey-xd/motif-react/assets/icons";
import { useGetClassFilterViewApiDataQuery, useDeleteClassFilterMutation } from "./calculationStepReducer";
import { ClassFilterType } from "./type";
import FormModalClassFilter from "./FormModal/FormModalClassFilter";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import FormModalClassFilterNew from "./FormModal/FormModalClassFilterNew";
import { useTranslation } from "react-i18next";

type ClassFilterProps = {
  resolvedStatus: boolean;
  caseId: string; 
  stepId: string;
};

export const ClassFilter = ({ resolvedStatus, caseId, stepId }: ClassFilterProps) => {
  const [isVisibleCancel, setIsVisibleCancel] = useState(false);
  const {t}=useTranslation()
  const [isVisible, setIsVisible] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectedClassId, setSelectedClassId] = useState(""); 
 const [isNew, setIsNew] = useState(false);
  const TitleComplement = () => {
    return (
      <MotifButton
        onClick={() => {
          setIsVisible(true); 
          setIsNew(true);
        }}
        size="small"
        type="submit"
        variant="secondary"
        disabled={resolvedStatus}
      >
       + 
      </MotifButton>
    );
  };
  
  const { data, isLoading, refetch } = useGetClassFilterViewApiDataQuery(stepId);
 
  const selectedScheme = data && data.length > 0 ? data[0].schemeVersionId : undefined;
   
  const selectedClass = data && data.length > 0 ? 
  data.map((item: any) => item.classId)
   :  null; 

  const sourceData: ClassFilterType[] = [];

  useEffect(() => {
    if (isRefetch) {
      refetch();
      setIsRefetch(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isRefetch]);

  if (data && data.length > 0) {
    // eslint-disable-next-line array-callback-return
    data.map((item: any) => {
      let obj = {
        weight: "",
        className: "",
        id: "",
      };
      obj.className = item?.className ? item?.className : "-";
      obj.weight = item.weight ? item.weight : "-";
      obj.id = item.id ? item.id : "";
      sourceData.push(obj);
    });  
  }
const [deleteClassFilter] = useDeleteClassFilterMutation();
  async function deleteClassFilterHandler(Id: string) {
    const response = await deleteClassFilter(Id);
    if (response.hasOwnProperty("data")) {
      refetch();
    } else {
      console.log("Error in deleting class filter");
    }
  }

  const headerData = {
    title: "Class Filters",
    description: "Descriptive text that describes your page or content.",
  };

  const handleEdit = (id: any) => {
    setIsVisible(true);
    setSelectedClassId(id);
    setIsNew(false);
  };

const ActionsCellRenderer = (params: any) => {
  return (
    <>
      <div className="pad-r-10">
        <MotifButton
          className="action-delete-button"
          size="small"
          variant="primary"
          onClick={() => {
            setShowModal(true);
            setDeleteId(params?.data?.id);
          }}
          disabled={resolvedStatus}
        >
          <MotifIcon title='Disable Unit' fill="none" iconFunction={actionIcDelete24px} />
        </MotifButton>
      </div>
      <div className="pad-r-10">
          <MotifButton
            size="small"
            variant="primary"
            onClick={() => handleEdit(params?.data.id)}
            disabled={resolvedStatus}
          >
            <MotifIcon fill="none" iconFunction={contentIcCreate24px} />
          </MotifButton>
      </div>
    </>
  );
};

  const columnClassFilterDefs = [
    {
      field: "className",
      headerName:t("pages.distributions.distributionStepsTab.classFilterTab.columnDetails.column1"),
      sortable: true,
      unSortIcon: true,
      flex: 2,
      cellClass: "align-left",
    },
  
    {
      field: t("pages.distributions.distributionStepsTab.classFilterTab.columnDetails.column1"),
      headerName: "Weighting Factor",
      flex: 1,
    },
    {
      cellRenderer: ActionsCellRenderer,
      field: "action",
      headerName: "",
      flex: 1,
      sortable: false,
      cellClass: "align-right",
    },
  ];
  
 
  if (isLoading) {
    return <MotifProgressLoader show variant="default" />;
  }

  const cancelBtnApproveHandler = () => {
    setIsVisibleCancel(false);
};

return (
  <>
    <div>
      {isLoading ? (
        <MotifProgressLoader show variant="default" className="mar-b-20" />
      ) : (
        <div className="mar-b-20 mar-t-20">
          <MotifModal
            onClose={() => setShowModal(false)}
            show={showModal}
            focusTrapOptions={{
              tabbableOptions: {
                displayCheck: "none",
              },
            }}
          >
            <div className="confirmationModalWrapper creditor_disabling_model">
              <MotifModalHeader
                headerIcon={
                  <>
                    <span
                      style={{
                        backgroundColor: "rgb(252, 245, 243)",
                        borderRadius: "50px",
                        padding: "5px 8px 2px 8px",
                      }}
                    >
                      <MotifIcon
                        iconFunction={IconoirWarningTriangle}
                        strokeWidth="1"
                        size="15"
                        stroke="red"
                      />{" "}
                    </span>
                    <span
                      style={{
                        fontSize: 16,
                        display: "block",
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
{t("global.globalNotifications.warning")}                    </span>
                  </>
                }
              ></MotifModalHeader>

              <MotifModalBody style={{ paddingTop: "0px" }}>
                <span style={{ fontWeight: "300" }}>
{t("pages.distributions.distributionStepsTab.classFilterTab.deleteWarningMessage")}                </span>
              </MotifModalBody>
              <MotifModalFooter>
                <MotifButton
                  size="small"
                  type="submit"
                  variant="secondary"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                  onClick={() => {deleteClassFilterHandler(deleteId);setShowModal(false);}}
                >
                  {t("global.globalActions.yes")}
                </MotifButton>
                <MotifButton
                  className=""
                  onClick={() => setShowModal(false)}
                  size="small"
                  type="button"
                >
                  {t("global.globalActions.cancel")}
                </MotifButton>
              </MotifModalFooter>
            </div>
          </MotifModal>
          <Table
            columnDefs={columnClassFilterDefs}
            cellRenderers={sourceData}
            TitleComplement={TitleComplement}
            title={"pages.distributions.distributionStepsTab.classFilterTab.tableTitle"}
            description={
"pages.distributions.distributionStepsTab.classFilterTab.tableDescription"            }
            caseRenderers={headerData}
            pagination={false}
            overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
          />
        </div>
      )}
      <ConfirmationModal
        isVisible={isVisibleCancel}
        setIsVisible={setIsVisibleCancel}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={() => {
          setIsVisibleCancel(false);
        }}
      />
      {
        isNew !== true ?  (
          <FormModalClassFilter 
        isVisible={isVisible} 
        setIsVisible={setIsVisible}  
        formData={null} 
        caseId={caseId} 
        stepId={stepId} 
        selectedClass={selectedClass} 
        setIsRefetch={setIsRefetch}
        selectedClassId={selectedClassId} 
        setSelectedClassId={setSelectedClassId}
        isNew={isNew}
        selectedScheme={selectedScheme}
      />
        ) : (<FormModalClassFilterNew 
          isVisible={isVisible} 
          setIsVisible={setIsVisible}  
          formData={null} 
          caseId={caseId} 
          stepId={stepId} 
          selectedClass={selectedClass} 
          setIsRefetch={setIsRefetch}
          setSelectedClassId={setSelectedClassId}
          isNew={isNew}
          selectedScheme={selectedScheme}
        />) 
      }
      
    </div>
  </>
);
};
export default ClassFilter;