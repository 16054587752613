import { j as e } from "../../../jsx-runtime-BwWtxLpl.js";
import "react";
import r from "prop-types";
import m from "classnames";
const i = ({ children: o, className: s = "", ...t }) => {
  const a = m({
    "motif-card-body": !0,
    [s]: !0
  });
  return /* @__PURE__ */ e.jsx("main", { className: a, ...t, "data-motif-card-body": !0, children: o });
};
i.propTypes = {
  children: r.node,
  className: r.string
};
export {
  i as MotifCardBody
};
