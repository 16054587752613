import { MotifIcon } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { editorIcVerticalAlignBottom24px } from "@ey-xd/motif-react/assets/icons";

export const useReportingHeaderConst = () => {
  const { t } = useTranslation();

 return ([
    {
        cellRenderer: "documentNameCellRenderer",
        field: "documentName",
        headerName:t("Exported File"),
        sortable:false,
        unSortIcon: true,
    
      },
      {
        cellRenderer: "createdByCellRenderer",
        field: "createdBy",
        headerName:t("User Name"),
        sortable:false,
        unSortIcon: true,
    
      },
      {
        cellRenderer: "createdOnCellRenderer",
        field: "createdOn",
        headerName:t("Exported On"),
        sortable:false,
        unSortIcon: true,
    
      },
      {
        cellRenderer: ()=><MotifIcon  size="24" src={editorIcVerticalAlignBottom24px}></MotifIcon> ,
        field: "",
        headerName:"",
        sortable:false,
        unSortIcon: true,
        cellStyle: {
          cursor: "pointer",
        },
    
      },
 ])
};

