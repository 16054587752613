import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  MotifIcon,
  MotifButton,
  MotifCard,
  MotifCardHeader,
  MotifCardBody,
  MotifCardFooter,
} from "@/libs/@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";

import "./Attributes.css";
import CreateAttributes from "./CreateAttributes";
import ReviewAttribute from "./ReviewAttributes";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import {
  useCreateAttributeMutation,
  useUpdateAttributeMutation,
} from "@/services/customAtrributes/customAttributesReducer";
import { SetupStage, AttributeType } from "./type";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

export default function AttributeSetup() {
  const navigate = useNavigate();
  const { caseId } = useParams();
  const { t } = useTranslation();
  const attributeData = useSelector((state: any) => state.attribute);
  let [intialState] = useState<any>(JSON.stringify(attributeData));
  const [setupStage, setSetupStage] = useState(SetupStage.Create);
  const [createAttribute] = useCreateAttributeMutation();
  const [updateAttribute] = useUpdateAttributeMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);

  const handleSubmit = async () => {
    if (setupStage === SetupStage.Create) {
      setSetupStage(SetupStage.Review);
    } else {
      setIsLoading(true);
      if (attributeData.id) {
        const requestBody = {
          ...attributeData,
          metaDataJSON: JSON.stringify(attributeData.metaDataJSON),
        };
        dispatch(setDiscardEditMode({ editMode: false }));
        await updateAttribute(requestBody).then(() => {
          setIsLoading(false);
          navigate(`/case/${caseId}?activeTab=5`);
        });
      } else {
        const requestBody = {
          ...attributeData,
          metaDataJSON: JSON.stringify(attributeData.metaDataJSON),
          caseId: caseId,
        };
        delete requestBody.id;
        dispatch(setDiscardEditMode({ editMode: false }));
        await createAttribute(requestBody).then(() => {
          setIsLoading(false);
          navigate(`/case/${caseId}?activeTab=5`);
        });
      }
    }
  };

  const isDisabled = () => {
    const emptyOptions = attributeData.picklistDefinitions.filter((x: any) => {
      return x.name === "";
    });
    return (
      !attributeData.name ||
      !attributeData.attributeType ||
      (attributeData.attributeType === AttributeType.Number &&
        (!attributeData.metaDataJSON ||
          !attributeData.metaDataJSON.minimum ||
          !attributeData.metaDataJSON.maximum)) ||
      (attributeData.attributeType === AttributeType.FreeText &&
        (!attributeData.metaDataJSON.format ||
          (attributeData.metaDataJSON.format === "None" &&
            !attributeData.metaDataJSON.maxCharacter))) ||
      ((attributeData.attributeType === AttributeType.SingleSelect ||
        attributeData.attributeType === AttributeType.MutliSelect) &&
        (attributeData.picklistDefinitions.length === 0 ||
          emptyOptions.length !== 0))
    );
  };

  const handleBackButton = () => {
    if (setupStage === SetupStage.Create) {
      if(disacardTrig){
        dispatch(setDiscardEditMode({ editMode: false }));

        setIsDiscardVisible(true);
      }
      else{
        navigate(`/case/${caseId}?activeTab=5`);

      }
    
    } else {
      setSetupStage(SetupStage.Create);
    }
  };
  let disacardTrig=intialState!==JSON.stringify(attributeData)
  const dispatch=useDispatch()
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: disacardTrig }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,disacardTrig]);
  return (
    <div className="attribute-setup-container">
      <MotifCard variant="card-no-border">
        <MotifCardHeader>
          <div className="attributes-back-button" onClick={handleBackButton}>
            <Breadcrumb
              items={[
                {
                  text: t("pages.configuration.attributes.backButton"),
                  icon: <MotifIcon src={IconoirArrowLeft} />,
                },
              ]}
              ariaLabel="Breadcrumb navigation"
            />
          </div>
          <div className="motif-h3-default-bold">
            {t("pages.configuration.attributes.attributeSetup")}
          </div>
        </MotifCardHeader>

        <MotifCardBody>
          {setupStage === SetupStage.Create ? (
            <CreateAttributes />
          ) : (
            <ReviewAttribute />
          )}
        </MotifCardBody>

        <MotifCardFooter>
          <div className="attributes-footer-buttons">
            <MotifButton
              onClick={() => {
                if(disacardTrig){
                  dispatch(setDiscardEditMode({ editMode: false }));
          
                  setIsDiscardVisible(true);
                }
                else{
                  navigate(`/case/${caseId}?activeTab=5`);
          
                }
            }}
              size="small"
              variant="ghost"
            >
              {t("pages.configuration.attributes.cancelButton")}
            </MotifButton>
            <MotifButton
              onClick={handleSubmit}
              size="small"
              variant="primary"
              type={isLoading ? "loader" : "submit"}
              disabled={isDisabled()}
            >
              {setupStage === SetupStage.Create ? (
                <span>{t("pages.configuration.attributes.reviewButton")}</span>
              ) : (
                <span>{t("pages.configuration.attributes.submitButton")}</span>
              )}
            </MotifButton>
          </div>
        </MotifCardFooter>
      </MotifCard>
      <ConfirmationModal
        isVisible={isDiscardVisible}
        setIsVisible={setIsDiscardVisible}
        cancelBtnApproveHandler={() => {
          navigate(`/case/${caseId}?activeTab=5`);

        }}
        cancelBtnRejectHandler={() => {
          setIsDiscardVisible(false);
          dispatch(setDiscardEditMode({ editMode: true }));

        }}
      />
    </div>
  );
}
