import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useSearchCreditorsByBallotIdQuery,
  useCreateCreditorVoteMutation,
} from "./votingReducer";
import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifLabel,
  MotifInput,
  MotifFormField,
  MotifOption,
  MotifSelect,
} from "@ey-xd/motif-react";
import CreditorDetails from "@/pages/CaseDetail/Claim/claimSetup/Creditor/Components/CreditorDetails";

interface RecordVoteProps {
  readBallot: any;
  showModal: boolean;
  setShowModal: any;
}

const RecordVote = ({
  readBallot,
  showModal,
  setShowModal,
}: RecordVoteProps) => {
  const { editId } = useParams<{
    editId: string;
  }>();
  const { t } = useTranslation();

  const [selectedCreditorDetail, setSelectedCreditorDetail] = useState({});
  const [voteType, setVoteType] = useState("");
  const [proxy, setProxy] = useState("");

  const { data: creditorData } = useSearchCreditorsByBallotIdQuery({
    ballotId: editId,
  });

  const [createVoteQuery, { isLoading: createVoteLoading }] =
    useCreateCreditorVoteMutation();

  const handleCreditorSearch: any = (value: string) => {
    const selectedCreditorDetail = creditorData.find(
      (creditor: { creditorName: string }) => creditor.creditorName === value
    );
    setSelectedCreditorDetail(
      selectedCreditorDetail ? selectedCreditorDetail : {}
    );
  };

  function renderCreditorDetails(selectedCreditorDetail: {
    creditorName?: any;
    description?: any;
    creditorReference?: any;
    relationShipToDebtor?: any;
  }) {
    if (
      !selectedCreditorDetail ||
      !Object.keys(selectedCreditorDetail).length
    ) {
      return null;
    }
    const {
      creditorName,
      description,
      creditorReference,
      relationShipToDebtor,
    } = selectedCreditorDetail;
    return (
      <>
        <CreditorDetails
          creditorName={creditorName}
          creditorDescription={description}
          creditorReferenceId={creditorReference}
          relationShipToDebtor={relationShipToDebtor}
        />
      </>
    );
  }

  const resetModalValues = () => {
    setSelectedCreditorDetail({});
    setVoteType("");
    setProxy("");
  };

  const handleClose = () => {
    setShowModal(false);
    resetModalValues();
  };

  const handleVoteSubmit: any = async () => {
    const {
      caseId,
      ballotId,
      creditorId,
      creditorName,
    }: { [key: string]: string } = selectedCreditorDetail;

    const requestBody = {
      creditorId,
      ballotId: ballotId,
      caseId: caseId,
      name: creditorName,
      proxyName: proxy,
      voteResult: voteType === "Yes" ? 0 : 1,
    };

    await createVoteQuery(requestBody).then((response: any) => {
      if (response.data) {
        setShowModal(false);
        resetModalValues();
        readBallot();
      }
    });
  };

  return (
    <MotifModal
      onClose={handleClose}
      show={showModal}
      size="lg"
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
    >
      <MotifModalHeader
        closeButtonProps={{
          "aria-label": "Custom Close Button aria-label",
          title: "Custom Close Button title",
        }}
      >
        <span>{t("pages.voting.creditorVotes.manualRecording.title")}</span>
      </MotifModalHeader>
      <MotifModalBody className="pad-t-10">
        <>
          <div className="motif-row  mar-b-10">
            <div className="motif-col-lg-6">
              <div className="formFieldLabel">
                <MotifLabel>
{t("pages.voting.creditorVotes.manualRecording.creditorSearch")}                  <span className="requiredSymbol">*</span>
                </MotifLabel>
              </div>
              <div className="">
                <MotifFormField>
                  <MotifSelect
                    onChange={handleCreditorSearch}
                    filter={true}
                    visibleOptions={3}
                    searchPlaceholder={t("global.globalPlaceholder.quickSearchPlaceHolder")}
                  >
                    {creditorData?.length
                      ? creditorData?.map(
                          ({ creditorName, creditorId }: any) => (
                            <MotifOption key={creditorId} value={creditorName}>
                              {creditorName}
                            </MotifOption>
                          )
                        )
                      : []}
                  </MotifSelect>
                </MotifFormField>
              </div>
            </div>
          </div>
          <div className="motif-row">
            {renderCreditorDetails(selectedCreditorDetail)}
          </div>
          <div className="motif-row mar-t-10">
            <div className="motif-col-lg-6 ">
              <div className="formFieldLabel">
                <MotifLabel>
                {t("pages.voting.creditorVotes.manualRecording.vote")}  
                  
                  <span className="requiredSymbol">*</span>
                </MotifLabel>
                <MotifSelect
                  onChange={(value) => setVoteType(value)}
                  value={voteType}
                >
                  <MotifOption value={"Yes"}>{t("pages.voting.creditorVotes.manualRecording.yes")}</MotifOption>
                  <MotifOption value={"No"}>{t("pages.voting.creditorVotes.manualRecording.no")}</MotifOption>
                </MotifSelect>
              </div>
            </div>

            <div className="motif-col-lg-6">
              <div className="formFieldLabel">
                <MotifLabel>{t("pages.voting.creditorVotes.manualRecording.proxy")}</MotifLabel>
                <MotifInput
                  // @ts-ignore
                  onChange={(e) => setProxy(e.target.value)}
                  value={proxy}
                  hideClearButton={true}
                />
              </div>
            </div>
          </div>
        </>
      </MotifModalBody>
      <MotifModalFooter>
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <MotifButton
            onClick={handleClose}
            size="small"
            type="button"
            variant="text"
          >
            {t("global.globalActions.cancel")}
          </MotifButton>
          <MotifButton
            onClick={handleVoteSubmit}
            size="small"
            type={createVoteLoading ? "loader" : "button"}
            disabled={
              Object.keys(selectedCreditorDetail).length === 0 || !voteType
            }
          >
            {t("global.globalActions.save")}
          </MotifButton>
        </div>
      </MotifModalFooter>
    </MotifModal>
  );
};
export default RecordVote;
