export const getRuleColumnDefs = (isDraft: boolean) => [
	{
		cellRenderer: "sortingCellRenderers",
		field: "action",
		headerName: "",
		flex: 1,
		sortable: false,
		cellRendererParams: {
			isDraft: isDraft,
		},
	},
	{
		field: "name",
		headerName: "Rule Name",
		flex: 1,
		sortable: false,
	},
	{
		field: "description",
		headerName: "Description",
		flex: 1,
		sortable: false,
	},
	{
		field: "className",
		flex: 1,
		sortable: false,
		headerName: "Class Name",
	},
	{
		cellRenderer: "actionCellRenderers",
		field: "action",
		headerName: "",
		flex: 1,
		sortable: false,
		hide: !isDraft,
	},
];
