import { MotifModal, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react'
import React from 'react'
import "./confirmationModal.scss";
import Button from '@/components/Form/Button/Button';
import {  PRIMARY, SECONDARY } from '@/Constant';
import {useTranslation} from 'react-i18next';

type Props = {
  isVisible: boolean;
  setIsVisible: any;
  cancelBtnRejectHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  cancelBtnApproveHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: number;
  heading?: string;
  message?: string;
  loading?: boolean;
};

export default function ConfirmationModal({
  isVisible,
  setIsVisible,
  cancelBtnRejectHandler,
  cancelBtnApproveHandler,
  type = 2,
  heading,
  message,
  loading = false,
}: Props) {
  const { t } = useTranslation();
  const Btn_cancel_lable = type === 1 ? "global.globalActions.cancel" : "global.globalActions.no";
  const Btn_Aproove_lable = type === 1 ? "global.globalActions.discard" : "global.globalActions.yes";
  return (
    <>
      <MotifModal
        onClose={() => setIsVisible(false)}
        show={isVisible}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <div className="confirmationModalWrapper">
          <MotifModalBody>
            <span className="discard_heading">
              {heading || t("global.globalNotifications.unsavedChanges")}
            </span>
            <div className="discard_message">
              {message || t("global.globalNotifications.discardMessage")}
            </div>
          </MotifModalBody>
          <MotifModalFooter>
            <Button
              label={t(Btn_cancel_lable)}
              variant={SECONDARY}
              className="cancelBtnModal"
              onClickHandler={cancelBtnRejectHandler}
            />
            <Button
              label={t(Btn_Aproove_lable)}
              variant={PRIMARY}
              className="delBtn"
              loading={loading}
              onClickHandler={cancelBtnApproveHandler}
            />
          </MotifModalFooter>
        </div>
      </MotifModal>
    </>
  );
}

