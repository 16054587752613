import {
  MotifButton,
  MotifCard,
  MotifCardBody,
  MotifIcon,
  MotifIconButton,
} from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { IconoirEditPencil, IconoirTrash } from "@ey-xd/motif-icon";
import {
  useGetAccountByIdApiDataQuery,
  useGetSubAccountByIdApiDataQuery,
} from "@/services/configuration/configuration";
import { AccountCreation } from "./AccountCreation";
import { useEffect, useState } from "react";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { EditAccountCreation } from "./editAccountAllInputs";
import { useCase } from "@/features/case/use-case";
import SubAccountTile from "./SubAccountSetUp";
import { SubAccountCreation } from "./SubAccountCreation";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import DeleteAccountSubAccount from "./DeleteAccountSubAccount";

export const AccountSetUp = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [subAccShow, setSubAccShow] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [accountId, setEditAccountId] = useState("");
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [editSubAccount, setEditSubAccount] = useState(false);

	const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const [deleteAccountId, setDeleteAccountId] = useState("");
  const caseData = useCase();
  const { data: accountList, refetch } = useGetAccountByIdApiDataQuery(
    caseData.caseId
  );
  const [selectedStage, setSelectedStage] = useState("");
  const { data: subAccountList, refetch: refetchSubAccounts } =
    useGetSubAccountByIdApiDataQuery(selectedStage, { skip: !selectedStage });
  useEffect(() => {
    setSelectedStage(accountList?.results[0]?.accountId);
  }, [accountList]);
  const handleClose = () => {
    setIsDiscardVisible(false);
    setShow(false);
    setEditShow(false)
    setSubAccShow(false);
    setEditSubAccount(false);

  };
  return (
    <div className="stage-management-container">
      {/* {window.scrollTo(0, 0)} */}
      {statusMsg && (
        <NotificationGrid
          show={true}
          message={statusMsg}
          type={
            statusMsg === "A Account with the same name already exists."
              ? "error"
              : "success"
          }
        />
      )}
      <div
        className="motif-body1-default-regular stage-management-title"
        style={{ marginTop: "15px" }}
      >
        {t("pages.distributions.account.title")}
      </div>
      <div className="stage-management-body">
        <div className="stages-container">
          <div className="stages-header">
            <div className="motif-body1-default-bold">        {t("pages.distributions.account.accountTitle")}
            </div>
            <MotifButton
              onClick={() => setShow(true)}
              size="small"
              type="submit"
              variant="text-alt"
            >
              {t("pages.configuration.stages.addNewButton")}
            </MotifButton>
          </div>
          {deleteErrorMessage && (
            <NotificationGrid
              show={true}
              message={deleteErrorMessage}
              type="error"
            />
			    )}
          {accountList?.results?.map((items: any) => {
            return (
              <div className="stage-tile-container">
                <MotifCard
                  className={
                    "stage-tile" +
                    (selectedStage === items.accountId ? " active" : "")
                  }
                  orientation="horizontal"
                  variant="card-border"
                >
                  <MotifCardBody className="stage-tile-body">
                    <div
                      className="stage-tile-header"
                      onClick={() => setSelectedStage(items.accountId!)}
                    >
                      <span className="motif-body1-default-bold">
                        {items.accountName}
                      </span>
                    </div>
                    <div className="stage-tile-actions">
                      <div
                        className="action-icons"
                        style={{ paddingRight: "0px" }}
                      >
                        <MotifIconButton type="button" 
                          onClick={() => {
                            setShowDeleteAccount(true);
                            setDeleteAccountId(items?.accountId);
                        }}>
                          <MotifIcon iconFunction={IconoirTrash} />
                        </MotifIconButton>
                        <MotifIconButton
                          onClick={() => {
                            setEditShow(true);
                            setEditAccountId(items?.accountId);
                          }}
                          type="button"
                        >
                          <MotifIcon iconFunction={IconoirEditPencil} />
                        </MotifIconButton>
                      </div>
                    </div>
                  </MotifCardBody>
                </MotifCard>
              </div>
            );
          })}
        </div>
        <div className="status-container">
          <div className="status-header">
            <div className="motif-body1-default-bold"> {t("pages.distributions.account.subAccountTitle")}</div>
            <MotifButton
              onClick={() => setSubAccShow(true)}
              size="small"
              type="submit"
              variant="text-alt"
            >
              {t("pages.configuration.stages.addNewButton")}
            </MotifButton>
          </div>
          <SubAccountTile
            selectedStage={selectedStage}
            subAccountList={subAccountList?.results}
            setStatusMsg={setStatusMsg}
            refetchSubAccounts={refetchSubAccounts}
            setIsDiscardVisible={setIsDiscardVisible}
            setEditSubAccount={setEditSubAccount}
            editSubAccount={editSubAccount}
          />
        </div>
      </div>
     {show && <AccountCreation
        show={show}
        setShow={setShow}
        refetch={refetch}
        setStatusMsg={setStatusMsg}
        setIsDiscardVisible={setIsDiscardVisible}
      />}
      {editShow && <EditAccountCreation
        editShow={editShow}
        setEditShow={setEditShow}
        refetch={refetch}
        accountId={accountId}
        setStatusMsg={setStatusMsg}
        setIsDiscardVisible={setIsDiscardVisible}
      />}
      <SubAccountCreation
        subAccShow={subAccShow}
        setSubAccShow={setSubAccShow}
        refetchSubAccounts={refetchSubAccounts}
        setStatusMsg={setStatusMsg}
        selectedStage={selectedStage}
        setIsDiscardVisible={setIsDiscardVisible}
      />
      <DeleteAccountSubAccount
        show={showDeleteAccount}
        setShow={setShowDeleteAccount}
        id={deleteAccountId}
        isAccount={true}
        setDeleteErrorMessage={setDeleteErrorMessage}
        refetch={refetch}
      />
      <ConfirmationModal isVisible={isDiscardVisible} setIsVisible={setIsDiscardVisible} cancelBtnApproveHandler={handleClose} cancelBtnRejectHandler={() => setIsDiscardVisible(false)}/>
    </div>
  );
};
