import React from "react";
import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputBox from "@/components/Form/InputBox/InputBox";
import "@/components/Form/InputBox/InputBox.scss";
import { VotingEditFormType } from "./type";
import {
  BALLOT_ID,
  BALLOT_ID_FRENCH,
  BALLOT_DESCRIPTION_ID,
  BALLOT_DESCRIPTION_FRENCH_ID,
  BALLOT_STATUS,
  BALLOT_VOTED_ID,
  BALLOT_CLAIM_ID,
} from "./Constant";

type ControlInputBoxType = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<VotingEditFormType>;
  name:
    | typeof BALLOT_ID
    | typeof BALLOT_ID_FRENCH
    | typeof BALLOT_DESCRIPTION_ID
    | typeof BALLOT_DESCRIPTION_FRENCH_ID
    | typeof BALLOT_STATUS
    | typeof BALLOT_VOTED_ID
    | typeof BALLOT_CLAIM_ID;

  showLabel: boolean;
  readonly?: boolean;
  label: string;
};

export default function ControlInputBoxVoting({
  name,
  control,
  required = false,
  isTextArea = false,
  showLabel,
  label,
  readonly,
}: ControlInputBoxType) {
  const {
    field,
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: { required: required },
  });
  const { t } = useTranslation();

  return (
    <>
      {showLabel && (
        <div className="formFieldLabel">
          <label htmlFor="input" aria-label={label}>
            {t(label)}
            {required ? <span className="requiredSymbol">*</span> : ""}
          </label>
        </div>
      )}
      <Controller
        name={field.name || ""}
        control={control}
        rules={{ required }}
        render={({ field: { onChange, value, ...args } }) => {
          return (
            <>
              <InputBox
                id={BALLOT_ID}
                required={true}
                value={value || ""}
                onChange={onChange}
                isTextArea={isTextArea}
                readonly={readonly}
                placeholder=""
                {...args}
              />
            </>
          );
        }}
      />
    </>
  );
}
