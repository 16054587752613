import { ccwpApi } from "@/services";
import { LIST_SCHEMES_BY_CASE } from "@/services/constant/apiPath";

export const SchemesApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getSchemesList: builder.query<any, any>({
      query: (paramsData) => {
        return {
          url: LIST_SCHEMES_BY_CASE +paramsData.CaseId,
					params:{...paramsData}
        };
      },
      providesTags: ["SchemeList"],
    }),
    createScheme: builder.mutation({
      query: (schemeData) => ({
        url: "Scheme/CreateScheme",
        method: "POST",
        body: schemeData,
      }),
    }),
    updateScheme: builder.mutation({
      query: (schemeData) => ({
        url: "Scheme/UpdateScheme",
        method: "PUT",
        body: schemeData,
      }),
    }),
    readScheme: builder.query<any, string>({
      query: (schemeId) => {
        return {
          url: `Scheme/ReadScheme?id=${schemeId}`,
        };
      },
    }),
    listSchemeVersions: builder.query<any, string>({
      query: (schemeId) => {
        return {
          url: `Scheme/ListSchemeVersionsByScheme?schemeId=${schemeId}`,
        };
      },
    }),
    updateSchemeEffective: builder.mutation({
      query: (schemeVersionId) => ({
        url: `Scheme/MakeSchemeEffective?schemeVersionId=${schemeVersionId}`,
        method: "PUT",
      }),
    }),
    getPreviewScheme: builder.query<
      any,
      { schemeVersion: string; creditorId: string }
    >({
      query: ({ schemeVersion, creditorId }) => {
        return {
          url: `Scheme/PreviewScheme?SchemeVersionId=${schemeVersion}&CreditorId=${creditorId}&PageNumber=1&PageSize=1000`,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      keepUnusedDataFor: 1,
    }),
  }),
});

export const {
  useGetSchemesListQuery,
  useCreateSchemeMutation,
  useReadSchemeQuery,
  useListSchemeVersionsQuery,
  useUpdateSchemeEffectiveMutation,
  useUpdateSchemeMutation,
  useGetPreviewSchemeQuery,
  useLazyGetPreviewSchemeQuery,
} = SchemesApi;
