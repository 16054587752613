import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import {
  MotifAccordion,
  MotifAccordionTrigger,
  MotifAccordionContent,
  MotifFormField,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import ControlInputBox from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import ControlSelect from "@/components/Form/SelectBox/ControlSelect";
import "./SectionConfig.scss";
import { useSelector } from "react-redux";
import { SectionMapping } from "@/services/formSetup/formSetupReducers";
import ControlInputBoxNumberWithRange from "@/components/Form/InputBox/ControlInputBoxNumberWithRange";

type SectionFormData = {
  name: string;
  nameFrench: string;
  description: string;
  mapping: number;
  columns: number;
  id: string;
  sortOrder: number;
};

const SectionConfig = ({
  control,
  setValue,
  getValues,
  setError,
  clearErrors,
  errors,
}: any) => {
  const { t } = useTranslation();
  const [activePanels, setActivePanels] = useState<{ [key: string]: boolean }>({
    sectionConfig: true,
  });
  const formSetup = useSelector((state: any) => state.formSetup);
  const selectedSection = formSetup.sections.find(
    (section: SectionFormData) => {
      return section.id === formSetup.sectionId;
    }
  );

  useEffect(() => {
    if (selectedSection) {
      setValue("name", selectedSection.name || "");
      setValue("nameFrench", selectedSection.nameFrench || "");
      setValue("description", selectedSection.description || "");
      setValue(
        "mapping",
        SectionMapping[selectedSection.mapping as keyof typeof SectionMapping]
      );
      setValue("columns", selectedSection.columns || 2);
    }
  }, [selectedSection, setValue]);

  const handlePanelClick = (panel: string, override?: boolean) => {
    setActivePanels((prevPanels) => ({
      ...prevPanels,
      [panel]: override !== undefined ? override : !prevPanels[panel],
    }));
  };

  const columnsValue = useWatch({ control, name: "columns" });
  useEffect(() => {
    if (columnsValue < 1 || columnsValue > 4) {
      setError("columns", {
        type: "manual",
        message: "Value must be between 1 and 4",
      });
    } else {
      clearErrors("columns");
    }
  }, [columnsValue, setError, clearErrors]);

  const mappingOptions = [
    {
      label: t("pages.portalConfig.sectionMapping.creditor"),
      value: "creditor",
    },
    { label: t("pages.portalConfig.sectionMapping.claim"), value: "claim" },
    {
      label: t("pages.portalConfig.sectionMapping.claimItem"),
      value: "claimItem",
    },
  ];

  return (
    <div className="section-config">
      <MotifAccordion
        useChevronIcon
        alignIconRight
        open={activePanels["sectionConfig"]}
        className="mar-h-0 pad-h-0"
        onOpen={() => {}}
        onClose={() => {}}
      >
        <MotifAccordionTrigger
          triggerButtonProps={{
            title: "Toggle Section Config Panel",
            "aria-label": "Toggle Section Config Panel",
            onClick: () => handlePanelClick("sectionConfig"),
          }}
        >
          <span className="motif-body1-default-light">
            {t("pages.portalConfig.formSetup.sectionConfig.heading")}
          </span>
        </MotifAccordionTrigger>
        <MotifAccordionContent>
          <div className="panel">
            <div className="panel-body">
              <MotifFormField>
                <ControlSelect
                  name="mapping"
                  control={control}
                  options={mappingOptions}
                  showLabel
                  label={t("pages.portalConfig.sectionLabels.selectMapping")}
                  setValue={setValue}
                  getValues={getValues}
                  disabled={true}
                />
              </MotifFormField>

              <p>
                {t("pages.portalConfig.formSetup.sectionConfig.fieldsDesc")}
              </p>

              <MotifFormField className="mar-b-10">
                <ControlInputBox
                  name="name"
                  control={control}
                  required
                  isTextArea={false}
                  label="pages.portalConfig.sectionLabels.displayName"
                  showLabel
                />
              </MotifFormField>

              <MotifFormField className="mar-b-10">
                <ControlInputBox
                  name="nameFrench"
                  control={control}
                  isTextArea={false}
                  required
                  label="pages.portalConfig.sectionLabels.displayNameFR"
                  showLabel
                />
              </MotifFormField>

              <MotifFormField className="mar-b-10">
                <ControlInputBox
                  name="description"
                  control={control}
                  isTextArea
                  required={false}
                  label="pages.portalConfig.sectionLabels.description"
                  showLabel
                />
              </MotifFormField>

              <MotifFormField className="mar-b-10">
                <ControlInputBoxNumberWithRange
                  name="columns"
                  control={control}
                  required
                  label="pages.portalConfig.sectionLabels.columns"
                  showLabel
                  min={1}
                  max={4}
                  digitAfterDecimal={0}
                />
                {errors.columns && (
                  <MotifErrorMessage>
                    {errors.columns.message}
                  </MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
          </div>
        </MotifAccordionContent>
      </MotifAccordion>
    </div>
  );
};

export default SectionConfig;
