// import { ENEXPECTED_ERROR, UPDATE_SUCCESSFUL } from "@/Constant";
import { MotifToast } from "@ey-xd/motif-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
type NotificationProps = {
  show?: boolean;
  message?: string;
  type?: string;
  supportingText?: string;
};

export default function NotificationGrid({
  show = true,
  message = "",
  type = "error",
  supportingText = "",
}: NotificationProps) {
  const [showMessage, setShowMessage] = useState({
    show: show,
    type: type,
    message: message,
    supportingText: supportingText,
  });
  const { t } = useTranslation();
  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const notificationMessage =
    type === "error"
      ? message === ""
        ? "global.globalNotifications.unExpectedError"
        : message
      : message === ""
      ? "global.globalNotifications.updatedSuccessfully"
      : message;
  if (!showMessage.show) {
    return null;
  }

  return (
    <>
      {window.scrollTo(0, 0)}
      <MotifToast
        onClose={closeMessageHandler}
        //@ts-ignore
        variant={showMessage.type}
        supportingText={showMessage.supportingText}
      >
        {" "}
        {t(notificationMessage)}
      </MotifToast>
    </>
  );
}
