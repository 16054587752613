const claims = {
  claimsTable: {
    title: "Claims",
    description: "Descriptive text that describes your page or content.",
    tableColumnsDetails: {
      claimId: "Claim ID",

      creditor: "Creditor",

      assignee: "Assignee",

      originalAmount: `Original Amount {{unitName}}`,

      currentAmount: `Current Amount {{unitName}}`,

      stage: "Stage",

      status: "Status",

      lastUpdated: "Last updated",
    },
    bulkImport: {
      title: "Claim Creation Upload",
      description: "Download template to create the import file",
      uploadLabel: "Upload Import File",
      uploadDescription:
        "The accepted document format is XLSX only. The maximum size per file is 250 MB.",
      accept: ".pdf,.jpg,.jpeg,.doc,.docx",
      progress: "Import In Progress",
      fileName: "Import File Name",
      status: "Status",
      recordsSuccessful: "Records Successful",
      recordsFailed: "Records Failed",
      sucessMessage: "Success!",
      successDescription:
        "You can view your import record in the Import & Export History tab under the case.",
      uploadFailed: "File Upload Failed",
      uploadFailedDescriptionAlt:
        "There was a problem with your import. Please check the file and try again.",
    },
    moreOptions: {
      bulkImport: "Bulk Import",

      bulkStatusUpdate: "Bulk Status Update",

      export: "Export",
    },
    bulkUpdateStatus: {
      title: "Bulk Status Update",
      bulkStatusUpdateMessage:
        "All selected claims need to be in the same Stage & Status",
      resolveStatusMessage:
        "By setting this Status, the current claim item amounts will become the resolved amounts",
      attachedDocumentsMessage:
        "By setting this status, the attached documents in the selected claims will be released to the Creditors through the Portal",
    },
  },
  breadCrums: {
    claimId: "Claim ID:{{refernceId}}",
  },
  tabPannelDetails: {
    summary: "Summary",
    claimHistory: "Claim History",
    attributeHistory: "Attribute History",
  },
  bannerDetails: {
    title: "Claim ID",
    label1: "Creditor",
    label2: "Current Amount",
    label3: "Stage",
  },
  claimsviewTab: {
    title: "Claim Details",
    description: "Descriptive text that describes your page or content.",
    formLabelDetails: {
      claimStage: "Claim Stage",
      actionStatus: "Action Status",
      stageDocument: "Stage Document",
      noDocument: "No Document",
      changeStatusDate: "Status Change Date",
    },
  },
  claimsDoumentsTab: {
    tableTitle: "Documents",
    tableDescription: "Descriptive text that describes your page or content.",
    uploadButton: "Upload New Document",
    tableColumnsDetails: {
      column1: "File Display Name",

      column2: "Type",

      column3: "Comment",

      column4: "Created By",

      column5: "Received Date",
    },
  },
  claimIteamsTab: {
    tableTitle: "Claims Items",
    tableDescription: "Descriptive text that describes your page or content.",
    deleteMessage: "Claim Item deleted successfully.",
    deletewarning: "Are you sure to delete this claim item?",
    cannotDelete: "Cannot delete a resolved claim item.",
    addButton: "Add New Claim Item",
    tablecolumns: {
      column1: "Claim Item ID",

      column2: "Category",

      column3: "Original {{unitName}}",

      column4: "Current {{unitName}}",

      column5: "Latest Creditor Position {{unitName}}",

      column6: "Latest Debtor Position {{unitName}}",

      column7: "Resolved {{unitName}}",
      columnTotal: "Total",
    },
  },
  taskTab: {
    title: "Tasks",
    description: "Descriptive text that describes your page or content.",
    deleteMessage: "Are you sure you want to delete this claim task?",
    addNewTask: "Add New Task",
    editTask: "Edit Task",
    validationMessages: {
      taskNameReq: "Task Name is required",
      typeReq: "Type is required",
      dueDate: "Due Date is required",
      statusReq: "Status is required",
    },
    formLabels: {
      taskName: "Task Name",
      dueDate: "Due Date",
      type: "Type",
      status: "Status",
      typeOptionsLabel: {
        selectType: "Select Type",
        other: "Other",
        cashManagement: "Cash Management",
        legal: "Legal",
      },
      statusLabels: {
        selectStatus: "Select Status",
        open: "Open",
        closed: "Closed",
        complete: "Complete",
      },
    },
    tableColumnsDetails: {
      column1: "Name",

      column2: "Due date",

      column3: "Status",

      column4: "Type",

      column5: "Created On",

      column6: "Created By",
    },
  },
  claimHistory: {
    claimHistoryTable: {
      title: "Claim History",
      description: "Descriptive text that describes your page or content.",
      tableColumnsDetails: {
        column1: "Iteration",

        column2: "Stage",

        column3: "Status",

        column4: "Memo",

        column5: "EOD Current Amount",

        column6: "EOD Resolved Amount",

        column7: "EOD of #Claims Item",

        column8: "EOD Of #Resolved Item",

        column9: "Modified By",

        column10: "Modified On",
      },
    },
    claimItemHistoryTable: {
      title: "Claim Item History",
      description: "Descriptive text that describes your page or content.",
      tableColumnsDetails: {
        column1: "Claim Item ID #",

        column2: "Category",

        column3: "Original Amount",

        column4: "Current Amount",

        column5: "Latest Creditor Position Amount",

        column6: "Latest Debtor Position Amount",

        column7: "Resolved Amount",

        column8: "Modified By",

        column9: "Modified On",
      },
    },
  },
  attributeHistoryTab: {
    title: "Attribute History",
    description: "Descriptive text that describes your page or content.",
    tableColumnsDetails: {
      column1: "Attibute Name",

      column2: "Updated On",

      column3: "Updated By",

      column4: "Old Value",

      column5: "New Value",
    },
  },
  claimSummaryEdit: {
    title: "Claim Summary Edit",
    description: "Descriptive text that describes your page or content.",
    formLabelDetails: {
      claimStage: "Claim Stage",
      actionStatus: "Action Status",
      stageDocument: "Stage Document",
      noDocument: "No Document",
      changeStatusDate: "Status Change Date",
    },
    editedSuccessfully: "Claim Edited Successfully",
  },
  claimsEditTab: {
    title: "Claim Details",
    description: "Descriptive text that describes your page or content.",
    formLabelDetails: {
      createdOn: "Created On",
      claimStage: "Claim Stage",
      actionStatus: "Action Status",
      stageDocument: "Stage Document",
      noDocument: "No Document",
      changeStatusDate: "Status Change Date",
      debtor: "Debtor",
      defaultAssignee: "Default Assignee",
      editCreditorContactsTitle: "Creditor Contacts",
      editCreditorContactsDescription:
        "Descriptive text that describes your page or content.",
      selectClaimContact: " Select a Claim Contact",
    },
    editedSuccessfully: "Claim Edited Successfully",
  },
  commonFieldLabels: {
    creditorContactsTitle: "Creditor Contacts",
    creditorContactsDescription:
      "Descriptive text that describes your page or content.",
    claimUpdatedSuccesfully: "Claim summary updated successfully.",
    claimId: "Claim ID",
    name: "Name",
    primary: "Primary",
    attributes: "Attributes",
    creditorName: "Creditor Name",
    debtorName: "Debtor Name",
    source: "Source",
    lastUpdatedDate: "Last Updated Date",
    initialFileDate: "Initial File Date",
    assignees: "Assignees",
    asigneeName: "Asignee Name",
    firstName: "First Name",
    lastName: "Last Name",
    address1: "Address 1",
    address2: "Address 2",
    city: "City",
    provincestate: "Province/State",
    postalCode: "Postal/ZIP Code",
    phone: "Phone",
    email: "Email",
    lastUpdatedOn: "Last Updated On",
    lastUpdatedBy: "Last Updated By",
  },

  claimItemTabPannel: {
    summary: "Summary",
    classification: "Classification",
  },
  claimItemCreate: {
    bannerDetails: {
      title: "Claim Item Reference ID",
      label1: "Claim ID",
      label2: "Creditor",
      label3: "Last Updated",
    },
    title: "Claim Item Details",

    subTitle1: "Claim Item Identification",
    subTitle2: "Claim Item Amount",
  },
  claimItemsClassificationTab: {
    tableTitle: "Classification",
    tableDescription: "Descriptive text that describes your page or content.",
    tableColumnsDetails: {
      column1: "Scheme",
      column2: "Class",
    },
  },
  claimItemEdit: {
    title: "Claim Item Details",
    bannerDetails: {
      title: "Claim Item ID",
    },

    subTitle1: "Claim Item Identification",
    subTitle2: "Claim Item Amount",
    subTitle3: "Comments",
    ammountUpdateWarningMessage: `The claim is currently in a creditor position Stage, are you
                sure you would like to update the claim item amount in the
                current Stage? If not, please update the Claim Stage first.`,
  },
  claimItemCommonLabels: {
    assignee: "Assignee",
    category: "Category",
    claimNature: "Nature of Claim",
    originalAmount: "Original Amount",
    stageAmount: "Stage Amount",
    latestCreditorPosition: "Latest Creditor Position",
    latestDebtorPosition: "Latest Debtor Position",
    resolvedAmount: "Resolved Amount",
    Entered: "Entered",
    currentAmount: "Current Amount",
    decimalErrorMessage:
      "Cannot exceed the configured decimal places or Invalid number.",
    unit: "Unit",
    resolveStatus: "Resolve Status",
    unresolved: "Unresolved",
    resolved: "Resolved",
    requiredMessage:
      "Please complete all required fields to continue. {{filedNames}} cannot be left blank or Invalid number .",
  },
  claimCreate: {
    title: "Claim Create",
    description:
      "Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed aliquam ligula placerat massa. At interdum lectus auctor nibh dui massa tristique. Eget nisi potenti aenean ultrices nunc sit quam leo. Non risus donec et enim semper.",
    sideTabPanelDetails: {
      details: "Details",

      debtor: "Debtor",

      creditor: "Creditor",

      assignee: "Select an Assignee",

      claimIteams: "Claim Items",

      claimAttribute: "Claim Attribute",

      uploadDocumnet: "Upload Document",
    },
    detailsTab: {
      title: "Details",
      description:
        "Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed aliquam ligula placerat massa. At interdum lectus auctor nibh dui massa tristique. Eget nisi potenti aenean ultrices nunc sit quam leo. Non risus donec et enim semper.",
      stageDocumentTitle: "Upload Stage Document",
      formLabelsDetails: {
        claimStage: "Claim Stage",
        displayFileName: "File Display Name",
        comment: "Comment",
        uploadTitle: "Click to upload or drag and drop",
        UploadDescription:
          "The accepted document format are XLSX, XLS, CSV, PDF, DOC, DOCX, TXT, JPG, JPEG, PNG, GIF, TIFF OR ZIP. The maximum size per file is 250 MB.",
      },
      validationMessages: {
        claimStageRequired: "Claim Stage is required",
      },
    },
    debtorTab: {
      title: "Debtor Detail",
      description:
        "Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed aliquam ligula placerat massa. At interdum lectus auctor nibh dui massa tristique. Eget nisi potenti aenean ultrices nunc sit quam leo. Non risus donec et enim semper.",
      formLabelsDetails: {
        searchDebtor: "Search for a Debtor",
        debtorName: "Debtor Name",
        debtorDescription: "Debtor Description",
      },
      validationMessages: {
        debtorSectionMessage: "Debtor Selection is required",
      },
    },
    creditorTab: {
      title: "Creditor Detail",
      description:
        "Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed aliquam ligula placerat massa. At interdum lectus auctor nibh dui massa tristique. Eget nisi potenti aenean ultrices nunc sit quam leo. Non risus donec et enim semper.",
      newCreditorDescription:
        "Please select the type of creditor you are filing a claim for.",
      newCreditorContactDetailsTile: "Contact Details",
      formLabelsDetails: {
        radiobuttonsLabels: {
          individual: "Individual",
          organization: "Organization",
        },
        selectContact: "Select a Contact",
        contactDetails: "Contact Details",
        searchCreditor: "Search for a Creditor",
        creditorName: "Creditor Name",
        creditorDescription: "Creditor Description",
        newCreditor: "New Creditor",
        creditorIdentification: "Creditor Identification",
        creditorId: "Creditor ID",
        prefix: "Prefix",
        primary: "Primary",
        name: "Name",
        displayName: "Display Name",
        creditorType: "Creditor Type",
        firstName: "First Name",
        lastName: "Last Name",
        businessName: "Business Name",
        description: "Description",
        referenceId: "Reference ID",
        relationshipToDebtor: "Relationship to Debtor",
        checkBoxPrimaryContact: "Set as Primary Contact",
        checkBoxAdditionalPayee: "Use as Additional Payeee",
        additionalPayeeType: "Additional Payee Type",
        email: "Email",
        phoneNumber: "Phone Number",
        fax: "Fax",
        relationshipToCreditor: "Relationship to Creditor",
        relationship: "Relationship",
        country: "Country",
        city: "City",
        address1: "Address 1",
        address2: "Address 2",
        address: "Address",
        payementDetails: "Payment Details",
        postalCode: "Postal Code",
        paymentCheckbox: "Add payment details for this contact",
        provinceState: "Province State",
        primaryContactDetails: "Primary Contact Details",
        otherContactDetails: "Other Contact Details",
        addAnotherContact: "Add Another Contact",
      },

      validationMessages: {
        faxMessage: "Fax number must be a number",
        creditorRelationMessage: "Relation to Creditor is required",
        selectContactMessage: "Contact Selection is required",
        creditorSectionMessage: "Please select a Creditor",
        prefixMessage: "Prefix selection is required",
        requiredPrimaryContact: "Primary contact is required",
        firstNameMessage: "First Name is required",
        additionalPayeeTypeMessage: "Additional Payee Type is required",
        lastNameMessage: "Last Name is required",
        businessNameMessage: "Business Name is required",
        descriptionMessage: "Description is required",
        mainMessage:
          "Please complete all required fields to continue. {{fieldNames}} cannot be left blank",
        contactDetailsMessage:
          "Please complete all required fields to continue. Contact Details required field cannot be left blank.",
      },
    },
    assigneeTab: {
      title: "Search for an Assignee",
      description:
        "If the claim has been assigned, search for the assignee as an existing creditor, or create a new creditor for the assignee. If the claim is not assigned, just press the Next button",
      newAssigneeDescription:
        "Please select the type of creditor you are filing a claim for.",
      formLabelsDetails: {
        searchAssignee: "Search for an Assignee",
        serachWarningMessage: "Please enter at least 3 characters",
        assigneeSearchPlaceholder: "Search for Assignee...",
        newAssignee: "New Assignee",
        assigneeName: "Assignee Name",
        referenceId: "Reference ID",
        description: "Description",
        relationshipToDebtor: "Relationship to Debtor",
        radiobuttonsLabels: {
          individual: "Individual",
          organization: "Organization",
        },
        selectContact: "Select a Contact",
        contactDetails: "Contact Details",
        searchCreditor: "Search for a Creditor",
        creditorName: "Creditor Name",
        creditorDescription: "Creditor Description",
        newCreditor: "New Creditor",
        creditorIdentification: "Creditor Identification",
        creditorId: "Creditor ID",
        prefix: "Prefix",
        primary: "Primary",
        name: "Name",
        displayName: "Display Name",
        creditorType: "Creditor Type",
        firstName: "First Name",
        lastName: "Last Name",
        businessName: "Business Name",
        checkBoxPrimaryContact: "Set as Primary Contact",
        checkBoxAdditionalPayee: "Use as Additional Payeee",
        additionalPayeeType: "Additional Payee Type",
        email: "Email",
        phoneNumber: "Phone Number",
        fax: "Fax",
        relationshipToCreditor: "Relationship to Creditor",
        relationship: "Relationship",
        country: "Country",
        city: "City",
        address1: "Address 1",
        address2: "Address 2",
        address: "Address",
        payementDetails: "Payment Details",
        postalCode: "Postal Code",
        paymentCheckbox: "Add payment details for this contact",
        provinceState: "Province State",
        primaryContactDetails: "Primary Contact Details",
        otherContactDetails: "Other Contact Details",
        addAnotherContact: "Add Another Contact",
        // assigneeName:"Assignee Name",
        // assigneeDescription:"Assignee Description",
      },
      validationMessages: {
        emailPatternMessage: "Invalid email address",
        creditorRelationMessage: "Relation to Creditor is required",
        selectContactMessage: "Contact Selection is required",
        creditorSectionMessage: "Please select a Creditor",
        prefixMessage: "Prefix selection is required",
        requiredPrimaryContact: "Primary contact is required",
        firstNameMessage: "First Name is required",
        additionalPayeeTypeMessage: "Additional Payee Type is required",
        lastNameMessage: "Last Name is required",
        businessNameMessage: "Business Name is required",
        descriptionMessage: "Description is required",
        faxPatternMessage: "Fax number must be a number",
        mainMessage:
          "Please complete all required fields to continue. {{fieldNames}} cannot be left blank",
        contactDetailsMessage:
          "Please complete all required fields to continue. Contact Details required field cannot be left blank.",
      },
    },
    cliemItemsTab: {
      title: "Claim Items",
      description:
        "Lorem ipsum dolor sit amet consectetur. Fringilla pulvinar in sed volutpat. Nunc iaculis cras amet condimentum. Bibendum.",
      formLabelsDetails: {
        newClaimItem: "+ New Claim Item",
        category: "Category",
        claimNature: "Nature of Claim",
        claimAmount: "Claim Amount",
        currency: "Currency",
        addAssignee: "Add Assignee",
        assigneeSearch: "Search for an Assignee",
      },
      validationMessages: {
        categorySelectMessage: "Category Selection is required",
        claimNatureMessage: "Nature of Claim Selection is required",
        claimAmountPatter: "Claim Amount must be a number",
        claimAmmountMessage: "Claim Amount is Required",
        currencyMessage: "Currency Selection is required",
      },
    },
    cliemAttributeTab: {
      title: "Claim Attributes",
      description:
        "Lorem ipsum dolor sit amet consectetur. Fringilla pulvinar in sed volutpat. Nunc iaculis cras amet condimentum. Bibendum.",
    },
    uploadDocumentTab: {
      title: "Upload Documents",
      description: "",
      uploadLabel: "Click to upload or drag and drop",
      uploadDescription:
        "The accepted document format are XLSX, XLS, CSV, PDF, DOC, DOCX, TXT, JPG, JPEG, PNG, GIF, TIFF OR ZIP. The maximum size per file is 250 MB.",
    },
    reviewAndSubmitTab: {
      title: "Review and Submit",
      description:
        "Lorem ipsum dolor sit amet consectetur. Fringilla pulvinar in sed volutpat. Nunc iaculis cras amet condimentum. Bibendum.",
      formLabelsDetails: {
        assignee: "Assignee",
        description: "Description",
        stage: "Stage",
        documentDisplayName: "Document Display Name",
        documentComment: "Document Comment",
        documentFileName: "Document File Name",
        debtorName: "Debtor Name",
        debtorConfirmation: "Debtor Confirmation",
        creditorConfirmation: "Creditor Confirmation",
        creditorName: "Creditor Name",
        firstName: "First Name",
        lastName: "Last Name",
        prefix: "Prefix",
        address1: "Address 1",
        address2: "Address 2",
        city: "City",
        email: "Email",
        postalCode: "Postal Code",
        relationshipToCreditor: "Relationship To Creditor",
        provinceState: "Province State",
        country: "Country",
        phone: "Phone",
        fax: "Fax",
        assigneeName: "Assignee Name",
        referenceID: "Reference ID",
        relationshipToDebtor: "Relationship to Debtor",
        claimItems: "Claim Items",
        category: "Category",
        claimNature: "Nature of Claim",
        claimAmount: "Claim Amount",
        currency: "Currency",
        claimAttributes: "Claim Attributes",
        documents: "Documents",
        documentSeries: "Document {{docNo}}",
      },
    },
  },

  search: {
    title: "Search",
    placeholder: "Search for...",
  },
  basicSearch: {
    title: "Basic Search",
    currentStage: "Current Stage",
    currentActionStatus: "Current Action Status",
    debtorName: "Debtor Name",
    claimReferenceNumber: "Claim ID",
    creditorName: "Creditor Name",
    category: "Category",
    pastDeadline: "Past Deadline",
    pastTimeLimit: "Past Time Limit",
    overdue: "Overdue",
    claimUpdatedDate: "Claim Updated Date",
    source: "Source",
    task: "Task",
    taskStatus: "Task Status",
    natureOfClaim: "Nature of Claim",
    cancelButton: "Cancel",
    applyButton: "Apply",
    yes: "Yes",
    no: "No",
    external: "External",
    internal: "Internal",
    clearSearch: "Clear Search",
  },
  advancedSearch: {
    title: "Advanced Search",
    cancel: "Cancel",
    apply: "Apply",
  },
  stageStatusPopup: {
    title: "Claims and Action",
    status: "External Portal Status",
    underReview: "Under Review",
    timeLimit: "Time Limit (Days After Issue)",
    changeMemo: "Change Memo",
    fileLabel: "Click to upload or drag and drop.",
    fileDescription:
      "The accepted document format are XLSX, XLS, CSV, PDF, DOC, DOCX, TXT, JPG, JPEG, PNG, GIF, TIFF OR ZIP. The maximum size per file is 250 MB.",
    setButton: "Set Current Status",
    filesizeExceedsLimit:
      "This file exceeds the upload limit of {{filesize}} MB.",
    docRequiredMessage:
      "A document is required to set this as the current status.",
    docReleaseMessage:
      "By setting this status, the below document will be released to the Creditor through the Portal",
    docResolveMessage:
      "By setting this Status, the current claim item amounts will become the resolved amounts",
  },
  createClaim: {
    creditorType: "Creditor Type",
    prefix: "Prefix",
    firstName: "First Name",
    lastName: "Last Name",
    businessName: "Business Name",
    description: "Description",
    relationshipToDebtor: "Relationship to Debtor",
    relationshipToCreditor: "Relationship to Creditor",
    address1: "Address 1",
    address2: "Address 2",
    city: "City",
    provinceState: "Province/State",
    country: "Country",
    postalCode: "PostalCode",
    phone: "Phone",
    email: "Email",
    fax: "Fax",
    primaryContact: "Primary Contact:",
  },

  documentPopup: {
    title: "Upload Documents",
    editTitle: "Edit Documents",
    subtitle:
      "Lorem ipsum dolor sit amet consectetur. Fringilla pulvinar in sed volutpat. Nunc iaculis cras amet condimentum. Bibendum.",
    documentType: "Document Type",
    fileName: "File Display Name",
    comment: "Comment",
    uploadText: "Click to upload or drag and drop",
    uploadDescription:
      "The accepted document format are XLSX, XLS, CSV, PDF, DOC, DOCX, TXT, JPG, JPEG, PNG, GIF, TIFF OR ZIP. The maximum size per file is 250 MB.",
    uploadDescriptionForXlsx:
      "The accepted document format is XLSX only. The maximum size per file is 250 MB.",

    displayName: "Display Name: ",
    upload: "Upload",
    edit: "Edit",
    save: "Save",
    cancel: "Cancel",
    deleteMessage: "Are you sure you want to disable this document?",
  },
  commentHistory: {
    deleteMessage: "Are you sure you want to delete this comment?",
  },
  noAttributesLabel: "No attributes data available to display.",
  claimItemAttributes: "Claim Item Attributes",
};
export default claims;
