import { ccwpApi } from "@/services";

export const ClaimListByCaseIdIdApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getClaimListByCaseId: builder.query<any, any>({
      query: (arg) =>
       ({url:`CustomForm/GetCustomFormListByCaseId`,method:"GET",params:{...arg}}),
    }),
  }),
});

export const { useGetClaimListByCaseIdQuery } = ClaimListByCaseIdIdApi;

export const CreateClaimApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    createClaimForm: builder.mutation<any, any>({
      query: (ballotData) => ({
        url: "CustomForm/CreateCustomForm",
        method: "POST",
        body: ballotData,
      }),
    }),
  }),
});

export const { useCreateClaimFormMutation } = CreateClaimApi;

export const UpdateClaimApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    updateClaimForm: builder.mutation<any, any>({
      query: (ballotData) => ({
        url: "/CustomForm/UpdateCustomForm",
        method: "PUT",
        body: ballotData,
      }),
    }),
  }),
});

export const { useUpdateClaimFormMutation } = UpdateClaimApi;

export const ReadCustomFormApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getReadCustomFormApi: builder.query<any, any>({
      query: (customFormId) =>
        `CustomForm/ReadCustomForm?customFormId=${customFormId}`,
    }),
  }),
});

export const { useGetReadCustomFormApiQuery } = ReadCustomFormApi;
