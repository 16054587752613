import Banner from "@/components/Banner/Banner";
import { dateFunc } from "@/components/Functions/dateFunc";
import { isEmpty } from "@/utility/common";
import { useTranslation } from "react-i18next";

export default function ClaimDetailsHeader({
  create = false,
  headerparam,
}: {
  create: boolean;
  headerparam: {
    claimId: string;
    creditorName: string;
    updatedOn: string;
    claimItemId: string;
    claimReference: string;
    claimRefId: string;
    creditorId?:any
  };
}) {
  const {t}=useTranslation();
  const bannerElements: { label: string; value: string,id?:any }[] = [
    {
      label: "pages.claims.claimItemCreate.bannerDetails.label1",
      value: `${
        isEmpty(headerparam?.claimRefId) ? "-" : headerparam?.claimRefId
      }`,
    },
    {
      label: "pages.claims.claimItemCreate.bannerDetails.label2",
      value: `${
        isEmpty(headerparam?.creditorName) ? "-" : headerparam?.creditorName
      }`,
      id:headerparam?.creditorId?headerparam.creditorId:""
    },
    {
      label: "pages.claims.claimItemCreate.bannerDetails.label3",
      value: `${
        headerparam?.updatedOn === "-" || isEmpty(headerparam?.updatedOn)
          ? "-"
          : dateFunc(headerparam.updatedOn).formattedDateOnly
      }`,
    },
  ];
  if (create) {
    return (
      <div className="claim-details-header">
        <div className="claim-details-header__title">
          <h1>{t("pages.claims.claimItemCreate.title")}</h1>
          <Banner
            bannerName="pages.claims.claimItemCreate.bannerDetails.title"
            bannerNameValue={`${
              isEmpty(headerparam?.claimReference)
                ? "-"
                : headerparam?.claimReference
            }`}
            elements={bannerElements}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="claim-details-header">
      <div className="claim-details-header__title">
      <h1>{t("pages.claims.claimItemEdit.title")}</h1>
      <Banner
          bannerName="pages.claims.claimItemEdit.bannerDetails.title"
          bannerNameValue={" - "}
          elements={bannerElements}
        />
      </div>
    </div>
  );
}
