import Table from "@/components/Table/table";
import { IconoirEditPencil, IconoirTrash } from "@ey-xd/motif-icon";
import { useState } from "react";
import {
  MotifButton,
  MotifIcon,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { useCase } from "@/features/case/use-case";
import {
  useDeletePayeeTypeMutation,
  useGetPayeeTypeApiDataQuery,
} from "@/features/distributions/steps/calculationStepReducer";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { IconoirWarningTriangle } from "@ey-xd/motif-icon";
import { useNavigate, useParams, Link } from "react-router-dom";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import { MotifTooltip } from "@/libs/@ey-xd/motif-react/main";
import { useTranslation } from "react-i18next";

export const PayeeType = () => {
  const caseData = useCase();
  const navigate = useNavigate();
  const { caseId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [statusMsg, setStatusMsg] = useState<any>(null);
const { t } = useTranslation();
  const [editId, setEditId] = useState<any>("");
  const [showModal, setShowModal] = useState<any>(null);
  const [deletePayeeType] = useDeletePayeeTypeMutation();
  const paginationPageSize: number = customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
  let tableLoadingData = customTableLoadingData
  const [paginationObj, setPaginationObj] = useState({
    CaseId: caseData.caseId,
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: ""
  });
  const { data, isLoading: isPayeeTypeDataLoading, refetch, isFetching, error } = useGetPayeeTypeApiDataQuery(
    paginationObj
  );

  let [sortedColumnTypes, setSortedColumnTypes] = useState<any>({})

  function handlePaginationData(data: any, columnTypesData: any) {
    setPaginationObj({ ...data })
    setSortedColumnTypes({ ...columnTypesData })
  }

  async function deletePayee() {
    setStatusMsg(null);
    setIsLoading(true);
    await deletePayeeType(editId).then((response: any) => {
      if (response.data) {
        refetch();
      } else {
        const errorMessage = response.error.data.message;
        setStatusMsg(errorMessage);
      }
      setIsLoading(false);
      setShowModal(false);
    });
  }

  const caseRenderers = {
    actionCellRenderer: function (params: any) {
      return (
        <>
          <div className="pad-r-10">
            <MotifButton
              size="small"
              variant="primary"
              onClick={() => {
                setEditId(params.data.id);
                setShowModal(true);
              }}
            >
              <MotifIcon
                iconFunction={IconoirTrash}
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
          </div>
          <MotifButton
            size="small"
            type="submit"
            variant="primary"
            onClick={() => {
              setEditId(params.data.id);
              navigate(`/case/${caseId}/configuration/payee/edit/${params.data.id}`);
            }}
          >
            <MotifIcon
              iconFunction={IconoirEditPencil}
              stroke="blue"
              strokeWidth="2"
              size="24"
            />
          </MotifButton>
        </>
      );
    },
    // descriptionCellRenderer: function (params: any) {
    //   const description = params?.data?.description || "";
    //   const isLongText = description.length > 50;
    //   return (
    //     <div
    //       title={isLongText ? description : undefined}
    //       style={{
    //         whiteSpace: "nowrap",
    //         overflow: "hidden",
    //         textOverflow: "ellipsis",
    //       }}
    //     >
    //       {description}
    //     </div>
    //   );
    // },
    descriptionCellRenderer: function (params: any) {
      const description = params?.data?.description || "";
      return (
        <MotifTooltip
          placement="top"
          hideCloseButton
          allowHover
          trigger={
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              }}
            >
              {description}
            </div>
          }
        >
          <div style={{
            maxWidth: "500px",
            wordWrap: "break-word",
          }}>{description}</div>
        </MotifTooltip>
      );
    },
  };
  const columnDefs = [
    {
      cellRenderer: (params: any) => <Link to={`./configuration/payee/edit/${params?.data?.id}`} >{params?.data?.name}</Link>,
      comparator: () => 0,
      field: "name",
      type: "nameType",
      headerName: t("pages.distributions.payeeType.columnDetails.column1"),
      sortable: true,
      unSortIcon: true,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "1.5",
      },
      autoHeight: true,
      flex: 1,
    },
    {
      comparator: () => 0,
      field: "payeeLevel",
      type: "payeeLevelType",
      headerName: t("pages.distributions.payeeType.columnDetails.column2"),
      sortable: true,
      unSortIcon: true,
      flex: 1,
      cellRenderer: function (params: any) {
        return (
          <>{params?.data.payeeLevel === 0 ? "Creditor Level" : "Case Level"}</>
        );
      },
    },
    {
      cellRenderer: "descriptionCellRenderer",
      comparator: () => 0,
      field: "description",
      type: "descriptionType",
      headerName: t("pages.distributions.payeeType.columnDetails.column3"),
      sortable: true,
      unSortIcon: true,

      flex: 1,
    },
    {
      cellRenderer: "actionCellRenderer",
      field: "action",
      headerName: "",
      sortable: false,
      cellStyle: {
        "justify-content": "right",
      },
    },
  ];
  function TitleComplement() {
    const {t}=useTranslation();
    const handleCreateNew = () => {
      navigate(`/case/${caseId}/configuration/payee/create`);
    }
    return (
      <MotifButton variant="secondary" onClick={handleCreateNew}>
        {t("pages.distributions.payeeType.createNewPayeeType")}
      </MotifButton>
    );
  }
  return (
    <>
      <div className="stage-management-container">
        <div className="motif-body1-default-regular stage-management-title mar-b-20">
        {t("pages.distributions.payeeType.title")}        </div>
        <MotifModal
          onClose={() => setShowModal(false)}
          show={showModal}
          focusTrapOptions={{
            tabbableOptions: {
              displayCheck: "none",
            },
          }}
        >
          <div className="confirmationModalWrapper creditor_disabling_model">
            <MotifModalHeader
              headerIcon={
                <>
                  <span
                    style={{
                      backgroundColor: "rgb(252, 245, 243)",
                      borderRadius: "50px",
                      padding: "5px 8px 2px 8px",
                    }}
                  >
                    <MotifIcon
                      iconFunction={IconoirWarningTriangle}
                      strokeWidth="1"
                      size="15"
                      stroke="red"
                    />{" "}
                  </span>
                  <span
                    style={{
                      fontSize: 16,
                      display: "block",
                      width: "100%",
                      marginLeft: "10px",
                    }}
                  >
                    {t("global.globalNotifications.warning")}
                  </span>
                </>
              }
            ></MotifModalHeader>

            <MotifModalBody style={{ paddingTop: "0px" }}>
              <span style={{ fontWeight: "300" }}>
{t("pages.distributions.payeeType.validationMessages.deleteWarningMessage")}              </span>
            </MotifModalBody>
            <MotifModalFooter>
              <MotifButton
                size="small"
                type={isLoading ? "loader" : "submit"}
                variant="primary"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                onClick={() => {
                  deletePayee();

                }}
              >
                {t("global.globalActions.yes")}
              </MotifButton>
              <MotifButton
                className=""
                onClick={() => setShowModal(false)}
                size="small"
                type="button"
                variant="secondary"
              >
                                {t("global.globalActions.cancel")}

              </MotifButton>
            </MotifModalFooter>
          </div>
        </MotifModal>
        {window.scrollTo(0, 0)}
        {statusMsg && (
          <div className="mar-b-20">
            <NotificationGrid
              show={true}
              message={statusMsg}
              type="error"
            />
          </div>
        )}
        {isPayeeTypeDataLoading ? (
          <MotifProgressLoader show variant="default" />
        ) : (
          <>
            <Table
              columnDefs={columnDefs}
              columnTypes={{ ...sortedColumnTypes }}
              handlePaginationData={handlePaginationData}
              paginationObj={paginationObj}
              cellRenderers={isFetching ? [] : data?.results || []}
              TitleComplement={TitleComplement}
              title={"pages.distributions.payeeType.tableTitle"}
              description={"pages.distributions.payeeType.tableDescription"}
              pagination={false}
              caseRenderers={caseRenderers}
              overlayNoRowsTemplate={isFetching ? tableLoadingData : error ? "Something Went Wrong" : "No records found"}

            />
            <CustomPagination
              currentpage={paginationObj.PageNumber}
              pageSize={paginationObj.PageSize}
              disableNext={!data?.hasMoreRecords}
              onChangePageSize={(page: number) => {
                setPaginationObj({
                  ...paginationObj,
                  PageSize: page,
                  PageNumber: 1,
                });
              }}
              onChangeNextPage={() => {
                setPaginationObj({
                  ...paginationObj,
                  PageNumber: paginationObj.PageNumber + 1,
                });
              }}
              onChangePreviousPage={() => {
                setPaginationObj({
                  ...paginationObj,
                  PageNumber: paginationObj.PageNumber - 1,
                });
              }}
              pageSizeSelector={paginationPageSizeSelector}
            />
          </>
        )}
      </div>
    </>
  );
};
