import Button from "@/components/Form/Button/Button";
import PageTitle from "@/components/Form/PageTitle/PageTitle";
import ControlSelect from "@/components/Form/SelectBox/ControlSelect";
import { SECONDARY } from "@/Constant";
import { useTranslation } from "react-i18next";
import { CASE_TIME_ZONE } from "../../CaseSetup/Constants";
import { getTimezones } from "@/utility/common";
import { useForm } from "react-hook-form";
import { useCase } from "@/features/case/use-case";
import { useUpdateCaseMutation } from "@/services/cases/casesReducer";
import { useState } from "react";
import { MotifToast } from "@ey-xd/motif-react";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useGetTimeZoneListQuery } from "./caseTimeZoneReducer";
export type TimeZoneFormType = {
    caseTimeZone: string;
}
export default function CaseTimeZones() {
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [updateCase] = useUpdateCaseMutation();
    const {data, isLoading} =
    useGetTimeZoneListQuery({});
    type showMessageType = {
      show: boolean;
      type: string;
      message: string;
    };
  
    const [showMessage, setShowMessage] = useState<showMessageType>({
      show: false,
      type: "error",
      message: "",
    });
    const { t } = useTranslation();

    const defaultOption=[{label:t("global.globalActions.optionDefault"), value:""}]
    const timeZones = getTimezones(data,defaultOption);
    const caseData = useCase();

    const { control, getValues,setValue, handleSubmit, watch, reset } = useForm<TimeZoneFormType>(
      {
        defaultValues: {
          caseTimeZone: caseData?.timeZone,
        }
      }
    );
    const timeZoneSeleced = watch(CASE_TIME_ZONE);    

  const onSubmit = async (data:TimeZoneFormType) => {
    setLoading(true);
    if (!data.caseTimeZone || data.caseTimeZone === null || data.caseTimeZone === '') {
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          message: "pages.configuration.caseZone.timeZoneRequired",
        });
        setLoading(false);
        return;
    }
    const caseTimeZone = Number(data.caseTimeZone);
    
    const timezonePostData = { 
        caseDescription: caseData?.caseDescription, 
        caseDescriptionFrench: caseData?.caseDescriptionFrench, 
        caseId: caseData?.caseId, 
        caseName: caseData?.caseName, 
        caseNameFrench: caseData?.caseNameFrench, 
        caseShortName: caseData?.caseShortName, 
        caseStatus: caseData?.caseStatus, 
        timeZone: caseTimeZone 
    };
    
    try {
        //@ts-ignore
        const responseApi = await updateCase(timezonePostData).unwrap();
  
        if (responseApi?.caseId) {
          setShowMessage({ ...showMessage, show: true, type: "success" });
        }
      } catch (error: any) {
        const {
          data: { message: errorMessage },
        } = error;
  
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          message: t(errorMessage),
        });
      }
  
      setLoading(false);
  } 

    if (isLoading) {
        return <div>{t("global.globalNotifications.loadingMessage")}</div>;
    }
    const closeMessageHandler = () => {
        setShowMessage({ ...showMessage, show: false, type: "error" });
    };

    const cancelBtnApproveHandler = (
        event: React.MouseEvent<HTMLButtonElement>
      ) => {
        event.preventDefault();
        reset({
          caseTimeZone: ""+ caseData?.timeZone ,
        });
        setIsVisible(false);
        setShowMessage({ ...showMessage, show: false, type: "error" });
    };

    const cancelBtnRejectHandler = (
        event: React.MouseEvent<HTMLButtonElement>
      ) => {
        event.preventDefault();
        setIsVisible(false);
        setShowMessage({ ...showMessage, show: false, type: "error" });
      };

    const renderMessage = () => {
        if (showMessage.show && !isVisible) {
          window.scrollTo(0, 0);
          return (
            <MotifToast
              onClose={closeMessageHandler}
              //@ts-ignore
              variant={showMessage.type}
            >
              {" "}
              {showMessage.type === "error"
                ? t(showMessage.message)
                : t("global.globalNotifications.updatedSuccessfully")}
            </MotifToast>
          );
        }
      };
      
    return (
        <div>
        {renderMessage()}
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-container case-summary-form">
          <div className="motif-row t-b-padding-21">
            <div className="motif-col-xs-4">
              <PageTitle
                title={"pages.configuration.caseZone.title"}
                description={"pages.configuration.caseZone.description"}
              />
            </div>
          </div>
        </div>
        <div className="motif-container main-container summary-form">
        <div className="motif-row pad-t-20 pad-b-20">
            <div className="motif-col-md-3">
              <div className="form-field">
              <ControlSelect name={CASE_TIME_ZONE}  control={control} required={true} label={t("pages.configuration.caseZone.timeZone")} options={timeZones} showLabel={true} getValues={getValues} setValue={setValue} filter={true} value={timeZoneSeleced}/>
              </div>
            </div>
          </div>
        </div>
        
        <div className="button-container">
          <Button label={t("global.globalActions.save")} loading={loading}  disabled={!timeZoneSeleced || timeZoneSeleced === "" || loading} />
          <Button
            type="button"
            label={t("global.globalActions.cancel")}
            variant={SECONDARY}
            className="cancelBtn"
            onClickHandler={() => {
                setIsVisible(true);
                setShowMessage({ ...showMessage, show: false, type: "error" });
            }}
          />
        </div>
      </form>
      <ConfirmationModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
        </div>
    )
}