import React from "react";
import { Control, Controller } from "react-hook-form";
import CheckBox from "./CheckBox";
import { useController } from "react-hook-form";
import { CaseSetupFormType } from "@/pages/CaseDetail/CaseSetup/CaseSetupType";

type CheckBoxType = {
    id?: string | undefined;
    className?: string | undefined;
    name: string;
    checked: boolean;
    required?: boolean;
    value: boolean;
    label?: string | undefined;
    onChange: () => void;
    control: Control<CaseSetupFormType>;
    setIsFrenchSelected: (value: React.SetStateAction<boolean>) => void;
    isFrenchSelected?: boolean;
}

export default function ControlCheckBox ({id, className, name, checked, required, label, control, setIsFrenchSelected}: CheckBoxType) {
    const {
        field,
      } = useController({
        // @ts-ignore
        name,
        control,
        rules: { required: required },
      });

    return (
        <> 
           <Controller
                name={field.name}
                control={control}
                rules={{ required }}
                render={({ field: { onChange, value, ...args }}) => {
                          return (
                          <>
                            <CheckBox   id={id} className={className} checked={checked} required={required} value={value ? true : false} onChange={onChange} label={label}  setIsFrenchSelected={setIsFrenchSelected} {...args}/>
                          </> 
                          );
                    }}
            />
        </>
        
    );
}
