import React from 'react';
import {useReportingHeaderConst} from "./ReportingHeaderConst"
import Table from '@/components/Table/table';
import { MotifButton, MotifErrorMessage, MotifFormField, MotifOption, MotifSelect } from '@ey-xd/motif-react';
import { useTranslation } from 'react-i18next';
import './Reporting.scss'
import { Controller, useForm } from 'react-hook-form';
import { useGetReportsListQuery, useLazyDownloadPaymentDocumentQuery } from './ReportingHelpers';
import { useCase } from '@/features/case/use-case';
import { useParams } from 'react-router-dom';
import { customTableLoadingData } from '@/components/Table/CustomPaginationConstants';
const Reporting: React.FC = () => {
    const { t } = useTranslation();
    const { caseId } = useParams<{ caseId: string }>();

    const columnDefs=useReportingHeaderConst()
    let reportsList:any[] = [ 
        { reportName: "Payment Register", reportId: "1" },
      ];
      const { control, handleSubmit, formState: { errors } } =
      useForm({
        defaultValues: {
          reportId: "1",
        },
      });
      const caseData = useCase();

      let {data:reportList,error,isFetching}=useGetReportsListQuery({CaseId:caseData.caseId||caseId})
      let tableLoadingData=customTableLoadingData
      const [generatePaymentReport] = useLazyDownloadPaymentDocumentQuery();
      async function onSubmit(data: any) {
        const { reportId } = data;
        if (reportId === "1") 
          generatePaymentReport({CaseId:caseId}); 
      }
    return (
        <div >
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className='motif-row align-items-start'>
            <div className='motif-col-md-3'>
            <MotifFormField>
            <Controller
                  control={control}
                  rules={{
                    required: "Select a Report",
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
            <MotifSelect
                        aria-label={t("Select Report")}
                        placeholder={t("Select Report")}
                        visibleOptions={3}
                        value={value}
                        onChange={onChange}
                        triggerButtonProps={{ type: "button" }}
                      >
                        {reportsList.map((option) => (
                          <MotifOption
                            key={option.reportId}
                            value={option.reportId}
                          >
                            {t(option.reportName)}
                          </MotifOption>
                        ))}
                      </MotifSelect>
                        );
                        }
                        }
                        name="reportId"
                        />
                      </MotifFormField>
                      {errors?.reportId && <MotifErrorMessage>{t(`${errors.reportId.message}`)}</MotifErrorMessage>}

            </div>
                 <div className='motif-col-md-1 '>
                  
                    <MotifButton className='generateReportsBtn'>{t("Generate Report")}</MotifButton>

            </div></div>
            </form>
            <br></br>
           
            <Table
            columnDefs={columnDefs}
            cellRenderers={reportList?.results && !isFetching?reportList?.results:[]}
            TitleComplement={() => (
           <></>
            )}
            title={t("Report Export History")}
            description={t("Descriptive Text that describes your page or content")}
            caseRenderers={[]}
            overlayNoRowsTemplate={isFetching?t(tableLoadingData):error?t("Something Went Wrong"):t("No records found")}
            />
          </div>
       
    );
};

export default Reporting;