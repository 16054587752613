import React from "react";
import { Control, Controller, useController, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import {useTranslation} from 'react-i18next';
import SelectBox from "@/components/Form/SelectBox/SelectBox";
import { ClaimSetupCreditorFormType } from "./type";


type ControlSelectType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<ClaimSetupCreditorFormType>;
    name: string;
    showLabel: boolean;
    label: string;
    options: {
        label: string,
        value: string,
    }[];
    setValue: UseFormSetValue<ClaimSetupCreditorFormType>;
    getValues: UseFormGetValues<ClaimSetupCreditorFormType>;
    value?:string,
    onChange?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    filter?: boolean | ((x?: any) => void) | undefined;
    searchPlaceholder? : string;
    visibleOptions?: number;
}

export default function ControlSelect ({name, control, required=false, options, showLabel, label, setValue, getValues,value,onChange, filter, searchPlaceholder,visibleOptions}: ControlSelectType) {
    const {
        field,
      } = useController({
        // @ts-ignore
        name,
        control,
        rules: { required: required },
        defaultValue: value ?? '',
      });
    const {t} = useTranslation();
    const translatedOptions = options.map(option => { 
        return {
            label: option.label,
            value: option.value,
        };
    })
    return (
        <> 
            {
                showLabel && <div className="formFieldLabel">
                    <label  htmlFor="input" aria-label={label}>{t(label)}{ required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
           <Controller
                name={field.name}                
                control={control}
                rules={{ required }}
                defaultValue={options[0].value}
                render={({ field: { onChange:controllerOnChange, value }}) => {
                          return (
                          <>                        
                            <SelectBox 
                                searchPlaceholder={searchPlaceholder} 
                                visibleOptions={visibleOptions}  
                                filter={filter} 
                                options={translatedOptions} 
                                value={value?.toString() ?? ''}  
                                onChange={(selectedValue) => {
                                    controllerOnChange(selectedValue);
                                    if (onChange) {
                                        onChange(selectedValue);
                                    }
                                }} />
                          </>
                          );
                    }}
            />
        </>
        
    );
}
