import { ccwpApi } from "@/services";
import {
  DOWNLOADDOCUMENT,
  UPLOAD_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
} from "../constant/apiPath";

export const fileExtensions = {
  //The accepted document format are JPG, JPEG, PNG, DOC, DOCX or PDF
  //The accepted document format are XLSX, XLS, CSV, PDF, DOC, DOCX, TXT, JPG, JPEG, PNG, GIF, TIFF, ZIP
  maxFileSize: 262175457,
  zipMaxFileSize: 500 * 1024 * 1024,
  default: {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
    "application/vnd.ms-excel": [".xls"],
    "text/csv": [".csv"],
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "text/plain": [".txt"],
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "image/gif": [".gif"],
    "image/tiff": [".tiff"],
    "application/zip": [".zip"],
  },
  import: {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
  },
  zip: {
    "application/zip": [".zip"]
  },
};

export const documentsApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    downloadDocument: builder.query({
      query: ({ caseId, documentId, fileName = "downloaded_file" }) => ({
        url: DOWNLOADDOCUMENT,
        method: "GET",
        responseHandler: async (response) => {
          const url = URL.createObjectURL(await response.blob());
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          const downloadControls =
            document.getElementsByClassName("motif-modal-content")[0] ||
            document.body;
          downloadControls.appendChild(a);
          a.click();
          downloadControls.removeChild(a);
          URL.revokeObjectURL(url);
        },
        params: { caseId, documentId },
        cache: "no-cache",
      }),
    }),

    getDocumentTypes: builder.query<any, void>({
      query: () => ({
        url: "Document/ListDocumentTypes",
        headers: {
          accept: "application/json",
        },
        method: "GET",
      }),
    }),

    uploadDocument: builder.mutation({
      query: (formData) => ({
        url: UPLOAD_DOCUMENT,
        method: "POST",
        body: formData,
      }),
    }),

    updateDocument: builder.mutation({
      query: (formData) => ({
        url: UPDATE_DOCUMENT,
        method: "PUT",
        body: formData,
      }),
    }),

    deleteDocument: builder.mutation({
      query: ({ CaseId, DocumentId }) => ({
        url: DELETE_DOCUMENT,
        method: "DELETE",
        params: { CaseId, DocumentId },
      }),
    }),
  }),
});

export const {
  useLazyDownloadDocumentQuery,
  useLazyGetDocumentTypesQuery,
  useUploadDocumentMutation,
  useUpdateDocumentMutation,
  useDeleteDocumentMutation,
} = documentsApi;
