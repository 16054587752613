import React, { useEffect } from "react";
import { MotifSelect, MotifFormField, MotifOption } from '@ey-xd/motif-react';
 
type SelectBoxType<T> = {
    options: { label: string, value: T }[];
    value: T | T[];
    onChange: (selectedValue: T | T[]) => void;
    filter?: boolean | ((x?: any) => void) | undefined;
    searchPlaceholder?: string;
    defaultValue?: T | T[];
    visibleOptions?: number;
    placeholder?: string;
    disabled?: boolean;
    multiple?: boolean;
}
 
export default function SelectBox<T extends string | {} | string[] | undefined>({ options, value, defaultValue, onChange, filter, searchPlaceholder, visibleOptions, placeholder, disabled, multiple }: SelectBoxType<T>) {
    useEffect(() => {
        const btn = document.querySelectorAll('.motif-select-input') as NodeListOf<Element>;
        btn.forEach((element: Element) => {
            (element as HTMLElement).setAttribute('type', 'button');
        });
    }, []);
 
    const selectedValue = value || defaultValue;
 
    const handleChange = (selectedOptions: any) => {
        onChange(multiple ? selectedOptions : selectedOptions[0]);
    };
 
    return (
        <>
            <MotifFormField role="group">            
                <MotifFormField>
                    <MotifSelect
                        ariaLabelledBy="select-label"
                        onChange={handleChange}
                        value={selectedValue}
                        filter={filter}
                        searchPlaceholder={searchPlaceholder}
                        visibleOptions={visibleOptions}
                        placeholder={placeholder}
                        disabled={disabled === true ? true : false}
                        multiple={multiple}
                    >
                        {
                            options.map(option => (
                                <MotifOption                                    
                                    isSelected={multiple ? (selectedValue as T[]).includes(option.value) : option.value === selectedValue}
                                    key={option.value as any}
                                    disabled={option.value === "" ? true : false}
                                    value={option.value}
                                >
                                    {option.label}
                                </MotifOption>
                            ))
                        }
                    </MotifSelect>
                </MotifFormField>
            </MotifFormField>
        </>
    );
}