import { useEffect, useMemo, useState } from 'react';
import { formatAmountUnitDecimalPlace } from '@/utility/common';
import { dateFunc } from '@/components/Functions/dateFunc';
import { useTranslation } from 'react-i18next';
export const useColumnDefs=(caseData:any)=>{
  const {t}=useTranslation();
  const CustomHeader = () => {
    const { defaultUnit } = caseData;
    const [unitName, setUnitName] = useState(defaultUnit?.name);
  
    useEffect(() => {
      setUnitName(defaultUnit?.name);
    }, [defaultUnit]);
  
     return  `(${unitName})`;
  };
  
  const AmountCellRenderer = ({ field, params }: { field: string, params: any }) => {
    const { defaultUnit } =caseData;
    const decimalPlace = useMemo(() => defaultUnit?.decimalPlace, [defaultUnit]);
    const amount = params.data[field];
    return <span>{formatAmountUnitDecimalPlace(amount, decimalPlace)}</span>;
  };

  return [
  {
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    width:20,
    suppressSizeToFit: true,
  },
  {
    cellRenderer: "claimCellRenderer",
    field: "claimReferenceId",
    type: "claimReferenceIdType",
    headerName: t("pages.claims.claimsTable.tableColumnsDetails.claimId"),
    
    
    unSortIcon: true,
sortable: true,
comparator: () => 0,

  },
  {
    cellRenderer: "creditorCellRenderer",
    field: "creditorName",
    type: "creditorNameType",
    headerName: t("pages.claims.claimsTable.tableColumnsDetails.creditor"),
    
    unSortIcon: true,
    sortable: true,
    comparator: () => 0,

  },
  {
    field: "assigneeName",
    type: "assigneeNameType",
    headerName: t("pages.claims.claimsTable.tableColumnsDetails.assignee"),

    
    comparator: () => 0,
    unSortIcon: true,
    sortable: true,
    cellStyle: {
      whiteSpace: "normal",
      lineHeight: "1.5",
    },
    autoHeight: true,
    cellRenderer: function (param: any) {
      return param.data.assigneeName ? param.data.assigneeName : "-";
    },
  },
  {
    cellRenderer: (params: any) => <AmountCellRenderer field="originalAmount" params={params} />,
    field: "originalAmount",
    type: "originalAmountType",
    
    
    unSortIcon: true,
    sortable: true,    cellStyle: {
      whiteSpace: "normal",
      lineHeight: "1.5",
    },
    comparator: () => 0,
    autoHeight: true,
    headerName: t("pages.claims.claimsTable.tableColumnsDetails.originalAmount",{unitName:CustomHeader()}),
    cellClass: "align-right",
    headerClass: "align-right",
  },
  {
    cellRenderer: (params: any) => <AmountCellRenderer field="currentAmount" params={params} />,
    field: "currentAmount",
    type: "currentAmountType",
    
    
    unSortIcon: true,
    sortable: true, 
    headerName: t("pages.claims.claimsTable.tableColumnsDetails.currentAmount",{unitName:CustomHeader()}),
    comparator: () => 0,
    cellClass: "align-right",
    headerClass: "align-right",
  },
  {
    field: "claimStageName",
    type: "claimStageNameType",
    headerName: t("pages.claims.claimsTable.tableColumnsDetails.stage"),
    
    
    unSortIcon: true,
    comparator: () => 0,
  },
  {
    field: "actionStatusName",
    type: "actionStatusNameType",
    headerName: t("pages.claims.claimsTable.tableColumnsDetails.status"),
    
    
    unSortIcon: true,
    sortable: true, 
    comparator: () => 0,

  },
  {
    field: "updatedOn",
    type: "lastUpdatedDateType",
    headerName: t("pages.claims.claimsTable.tableColumnsDetails.lastUpdated"),
    comparator: () => 0,
minWidth: 200,
flex:1,
    unSortIcon: true,
    sortable: true, 
    cellRenderer: function (param: any) {
      return <>{dateFunc(param.data.updatedOn).formattedDateTime + " " + caseData?.abbreviation}</>;
    },
  },
]}
