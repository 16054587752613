import { dateFunc } from "@/components/Functions/dateFunc";

export const useSchemeColumnDefinition=()=> [
    {
        cellRenderer: "nameCellRenderer",
        headerName: "Scheme Name",
        			comparator: () => 0,

        field: "name",
        type:"nameType",
        flex: 1,
        sortable: true,
        unSortIcon: true},
    {
        headerName: "Description",
        			comparator: () => 0,

        field: "description",
        type:"descriptionType",
        flex: 1,
        sortable: true,
        unSortIcon: true
    },
    {
        headerName: "Effective Date",
        			comparator: () => 0,

        field: "effectiveDate",
        type:"effectiveDateType",
        flex: 1,
        sortable: true,
        unSortIcon: true,
        cellRenderer: function (params: any) {  
			return dateFunc(params.data.effectiveDate).formattedDateOnly;
		},
    },
    {
        headerName: "",

        field: "actions",
        cellRenderer:"actionCellRenderers",
        flex: 1,
        sortable: false,
    },
];