import { CLAIM_CATEGORY_NATURE_OF_CLAIM } from "../constant/apiPath";
import { ccwpApi } from "@/services";

export const ClaimCategoryAndNatureOfClaimListApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getClaimCategoryAndNatureOfClaimList: builder.query<any, any>({
      query: (caseId) => `${CLAIM_CATEGORY_NATURE_OF_CLAIM}${caseId}`,
    }),
  }),
});

export const { useGetClaimCategoryAndNatureOfClaimListQuery } =
  ClaimCategoryAndNatureOfClaimListApi;
