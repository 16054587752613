import SearchInput from "@/components/SearchInput/SearchInput";
import { useCase } from "@/features/case/use-case";
import { useGetCreditorQuickSearchApiDataQuery } from "@/services/claimSummary/caseSummaryReducer";
import { MotifFormField, MotifProgressLoader } from "@ey-xd/motif-react";

export const SearchCreditor = ({
  handleChange,
  creditorData,
  resolvedStatus,
  setSelectedAssigneeId,
}: any) => {
  const caseData = useCase();
  const { data, isLoading }: any = useGetCreditorQuickSearchApiDataQuery({
    caseId: caseData.caseId,
  });
  const creditorNames =
    (data &&
      data?.map(
        (items: { creditorName: string; id: any }) => items.creditorName
      )) ||
    [];
  function getCreditorId(props: any) {
    const creditorID = data?.filter(
      (items: { creditorName: string; id: any }) => {
        if (items.creditorName === props) return true;
        else return false;
      }
    );
    handleChange(
      "defaultAssigneeId",
      creditorID[0] && creditorID[0]?.creditorId
    );
    setSelectedAssigneeId(creditorID[0] && creditorID[0]?.creditorId);
  }
  return (
    <div>
      {isLoading ? (
        <MotifProgressLoader variant="circular" size="xs" />
      ) : (
        <MotifFormField>
          <SearchInput
            aria-label="Search"
            id="states-search"
            items={creditorNames}
            onBlur={function Ua() {}}
            onChange={(e: any) => getCreditorId(e.target.value)}
            onClear={function Ua() {}}
            onClose={function Ua() {}}
            onEnter={function Ua() {}}
            onFocus={function Ua() {}}
            onOpen={function Ua() {}}
            onSelect={(e: any) => getCreditorId(e)}
            placeholder="Search for..."
            value={creditorData.claimAssignee?.assigneeName}
            disabled={resolvedStatus}
          />
        </MotifFormField>
      )}
    </div>
  );
};
