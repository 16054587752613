import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IClaimsState {
  showBasicSearch: boolean;
}

export const initialState = {
  showBasicSearch: false
};

const ClaimsSlice = createSlice({
  name: "creditorClaims",
  initialState,
  reducers: {
    setShowBasicSeach(state: IClaimsState, action: PayloadAction<any>) {
      state.showBasicSearch = action.payload;
    },
  },
});

export const { setShowBasicSeach } = ClaimsSlice.actions;
export default ClaimsSlice.reducer;