import { MotifButton } from "@ey-xd/motif-react";
import { ClassFilter } from "./ClassFilter";
import { DataSource } from "./DataSource";
import { useCase } from "@/features/case/use-case";
import CalculationEditForm from "./Form/calculationEditForm";
import { RateTier } from "./RateTier";
import { useLazyExportDocumentQuery } from "@/services/exports/exportsReducer";
import { CalculationSetting } from "./CalculationSetting";
import { CalculationStepLimitAmount } from "./CalculationStepLimitAmount";
import { ResultSetting } from "./ResultSetting";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const CalculationStepViewSummary = ({
  dataByStepId,
  summaryData,
  setEditForm,
  accountList,
  caseId,
  distributionId,
  stepId,
  calculationStepId,
  editForm,
  refetch,
}: any) => {
  const caseData = useCase();
  const {t}=useTranslation()
  const isDistributionStatusCommitted = useSelector(
    (state: RootState) => state.distributions.distributionStatus === 3
  );
  const [errorMsg, setErrorMsg] = useState(false);
  const [exportDocument] = useLazyExportDocumentQuery();
  // const handleExport = () => {
  //   const { id } = dataByStepId;
  //   exportDocument({
  //     calculationStepId: id,
  //     url: "/Distribution/ExportDataSourceItems",
  //   });
  // };
  function stepPreview() {
    exportDocument({
      CalculationStepId: stepId,
      url: "Distribution/PreviewStep?",
    }).then((error: any) => {
      if (!error?.isSuccess) {
        setErrorMsg(true);
      }
    }).catch((error: any) => {
      setErrorMsg(true);
      console.log(error);
    });
  }
 
  const payeeType = (dataByStepId?.calculationGroupBy === 0 || dataByStepId?.calculationGroupBy === 2) && (dataByStepId?.payeeTypeId === null) ? 'Creditor' : dataByStepId?.payeeTypeName;

  return (
    <>
      {errorMsg && (
        <NotificationGrid
          show={true}
          type="error"
          message="Something went wrong!!"
        />
      )}
      <div className="motif-container case-summary-form pad-v-20 mar-t-20">
        <div className="motif-row justify-content-between align-items-center">
          <div className="motif-col-4">
            <div className="motif-body1-default-regular page-title">
             {t("pages.distributions.distributionStepsTab.stepFormView.title")}
            </div>
            <div className="motif-body2-default-regular page-description">
            {t("pages.distributions.distributionStepsTab.stepFormView.description")}
            </div>
          </div>
          <div className="motif-col-4">
            <MotifButton
              size="small"
              type="submit"
              variant="secondary"
              onClick={() => setEditForm(true)}
              disabled={isDistributionStatusCommitted}
            >
              {t("global.globalActions.edit")}
            </MotifButton>
          </div>
        </div>
      </div>
      <div className="motif-container main-container pad-v-20">
        {editForm ? (
          <CalculationEditForm
            summaryData={summaryData}
            setIsEdit={setEditForm}
            accountList={accountList}
            caseId={caseId || ""}
            distributionId={distributionId || ""}
            stepId={stepId || ""}
            calculationStepId={calculationStepId}
            refetch={refetch}
          />
        ) : (
          <>
            <div className="motif-row ">
              <div className="motif-col-lg-4">
                <div>
                  <div className="title">{t("pages.distributions.distributionStepsTab.stepFormLabels.stepName")}</div>
                  <div className="motif-body2-default-bold">
                    {dataByStepId?.stepName}
                  </div>
                </div>
              </div>
              <div className="motif-col-lg-4">
                <div>
                  <div className="title">{t("pages.distributions.distributionStepsTab.stepFormLabels.account")}</div>
                  <div className="motif-body2-default-bold">
                    {dataByStepId?.accountName}
                  </div>
                </div>
              </div>
              <div className="motif-col-lg-4">
                <div>
                  <div className="title">{t(`pages.distributions.distributionStepsTab.stepFormLabels.subAccount`)}</div>
                  <div className="motif-body2-default-bold">
                    {dataByStepId?.subAccountName}
                  </div>
                </div>
              </div>
            </div>
            <div className="motif-row pad-t-30">
              <div className="motif-col-lg-4">
                <div>
                  <div className="title">{t("pages.distributions.distributionStepsTab.stepFormLabels.calculationGroupBy")}</div>
                  <div className="motif-body2-default-bold">
                    {dataByStepId?.calculationGroupBy === 0
                      ? "Creditors"
                      : dataByStepId?.calculationGroupBy === 1
                      ? "Claims"
                      : dataByStepId?.calculationGroupBy === 2
                      ? "Assignee/Creditor"
                      : "Case"}
                  </div>
                </div>
              </div>
              <div className="motif-col-lg-4">
                  <div>
                    <div className="title">{t("pages.distributions.distributionStepsTab.stepFormLabels.payeeType")}</div>
                    <div className="motif-body2-default-bold">
                      {payeeType} 
                    </div>
                  </div>
              </div>
              <div className="motif-col-lg-4">
                <div>
                  <div className="title">{t("pages.distributions.distributionStepsTab.stepFormLabels.memo")}</div>
                  <div className="motif-body2-default-bold">
                    {dataByStepId?.isMemo ? "Memo" : "Payable"}
                  </div>
                </div>
              </div>
              </div>
              <div className="motif-row pad-t-30">
                <div className="motif-col-lg-4">
                  <div>
                    <div className="title">{t("pages.distributions.distributionStepsTab.stepFormLabels.description")}</div>
                    <div className="motif-body2-default-bold">
                      {dataByStepId?.description}
                    </div>
                  </div>
                </div>
                <div className="motif-col-lg-4">
                <div>
                <div className="title">{t("pages.distributions.distributionStepsTab.stepFormLabels.comment")}</div>
                <div className="motif-body2-default-bold">
                    {dataByStepId?.comment}
                  </div>
                </div>
              </div>
              </div>
          </>
        )}
      </div>
      <DataSource
        caseId={caseData.caseId}
        resolvedStatus={isDistributionStatusCommitted}
        stepId={stepId}
      />
      <ClassFilter
        caseId={caseData.caseId}
        resolvedStatus={isDistributionStatusCommitted}
        stepId={stepId}
      />
      <RateTier
        distributionStatus={isDistributionStatusCommitted}
        caseId={caseId}
        resolvedStatus={isDistributionStatusCommitted}
        stepId={stepId}
      />
      <CalculationStepLimitAmount
        distributionStatus={isDistributionStatusCommitted}
        caseId={caseData.caseId}
        stepId={stepId}
        dataByStepId={dataByStepId}
        summaryData={summaryData}
      />
      <CalculationSetting
        distributionStatus={isDistributionStatusCommitted}
        caseId={caseId}
        stepId={stepId}
      />
      <br />
      <ResultSetting
        distributionStatus={isDistributionStatusCommitted}
        caseId={caseData.caseId}
        stepId={stepId}
      />
      <div className="pad-t-20" style={{ float: "right" }}>
        <div className="motif-row">
          {" "}
          <MotifButton
            disabled={isDistributionStatusCommitted}
            onClick={stepPreview}
          >
{t("pages.distributions.distributionStepsTab.previewEntitlementResult")}          </MotifButton>
          {/* <MotifButton onClick={handleExport}>Export result</MotifButton> */}
        </div>
      </div>
    </>
  );
};
