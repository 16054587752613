/* eslint-disable array-callback-return */
import Table from "@/components/Table/table";
import {
  MotifButton,
  MotifCheckbox,
  MotifIcon,
  MotifErrorMessage,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifProgressLoader,
} from "@ey-xd/motif-react";

import {
  actionIcDelete24px,
  contentIcCreate24px,
} from "@ey-xd/motif-react/assets/icons";
import { useEffect, useState } from "react";

import {
  useGetDataSourceSummaryViewApiDataQuery,
  useDeleteDataSourceMutation,
} from "./calculationStepReducer";
import { ENTITLEMENT, SETTLEMENT } from "./Form/Constant";
import FormModal from "./FormModal/FormModal";
import { IconoirWarningTriangle } from "@ey-xd/motif-icon";
import FormModalNew from "./FormModal/FormModalNew";
import { useTranslation } from "react-i18next";

type DataSourceProps = {
  resolvedStatus: boolean;
  caseId: string;
  stepId: string;
};

export const DataSource = ({
  resolvedStatus,
  caseId,
  stepId,
}: DataSourceProps) => {
  const [isRefetch, setIsRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [formData, setFormData] = useState<any>(null);
  const [editId, setEditId] = useState("");
  const {t}=useTranslation()
  const TitleComplement = () => {
    return (
      <MotifButton
        onClick={() => {
          setIsVisible(true);
          setFormData(null);
          setEditId("");
        }}
        size="small"
        type="submit"
        variant="secondary"
        disabled={resolvedStatus}
      >
        +
      </MotifButton>
    );
  };

  const handleEdit = (data: string) => {
    setFormData(data);
    setIsVisible(true);
  };

  const { data, refetch, isLoading, error } =
    useGetDataSourceSummaryViewApiDataQuery(stepId);

  const headerData = {
    title: "Data Sources",
    description: "Descriptive text that describes your page or content.",
  };

  useEffect(() => {
    if (isRefetch) {
      refetch();
      setIsRefetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetch]);

  const sourceData: any[] = [];
  if (data && data.length > 0) {
    // eslint-disable-next-line array-callback-return
    data.map((item: any) => {
      let obj = {
        dataSource: "",
        sourceManualDataSourceName: "",
        distribution: "",
        stepName: "",
        weight: "",
        entitlement: "",
        applicableClasses: "",
        stepDataSourceId: "",
        sourceCalculationStepName: "",
        sourceDistributionName: "",
      };
      const PriorStep = item?.dataSourceName === "PriorStep";
      if (PriorStep) {
        obj.dataSource = item?.dataSourceName ? item?.dataSourceName : "-";
        obj.sourceManualDataSourceName = item.sourceManualDataSourceName
          ? item.sourceManualDataSourceName
          : "-";
        obj.distribution = item.distribution ? item.distribution : "-";
        obj.stepName = item.stepName ? item.stepName : "-";
        obj.weight = item.weight ? item.weight : "-";
        obj.entitlement = item.isEntitlement ? ENTITLEMENT : SETTLEMENT;
        obj.applicableClasses = item.isFilterApplicableClasses
          ? item.isFilterApplicableClasses
          : "-";
        obj.sourceCalculationStepName = item.sourceCalculationStepName
          ? item.sourceCalculationStepName
          : "-";
        obj.stepDataSourceId = item.stepDataSourceId
          ? item.stepDataSourceId
          : "-";
        obj.sourceDistributionName = item.sourceDistributionName
          ? item.sourceDistributionName
          : "-";
      } else {
        obj.dataSource = item?.dataSourceName ? item?.dataSourceName : "-";
        obj.sourceManualDataSourceName = item.sourceManualDataSourceName
          ? item.sourceManualDataSourceName
          : "-";
        obj.distribution = "-";
        obj.stepName = "-";
        obj.weight = item.weight ? item.weight : "-";
        obj.entitlement = "-";
        obj.applicableClasses = item.isFilterApplicableClasses
          ? item.isFilterApplicableClasses
          : "-";
        obj.sourceCalculationStepName = "-";
        obj.stepDataSourceId = item.stepDataSourceId ? item.stepDataSourceId : "-";
          obj.sourceDistributionName = "-";
      }
     
      sourceData.push(obj);
    });
  }

  console.log("sourceData", sourceData);
  const [deleteDataSource] = useDeleteDataSourceMutation();
  async function deleteDataSourceAction(dataSourceId: string) {
    const response = await deleteDataSource(dataSourceId);
    if (response.hasOwnProperty("data")) {
      refetch();
    } else {
      console.log("Error in deleting data source");
    }
  }

  const ActionsCellRenderer = (params: any) => {
    return (
      <>
        <div className="pad-r-10">
          <MotifButton
            className="action-delete-button"
            size="small"
            variant="primary"
            onClick={() => {
              setShowModal(true);
              setDeleteId(params?.data?.stepDataSourceId);
            }}
            disabled={resolvedStatus}
          >
            <MotifIcon
              title="Disable Unit"
              fill="none"
              iconFunction={actionIcDelete24px}
            />
          </MotifButton>
        </div>
        <div className="pad-r-10">
          <MotifButton
            size="small"
            disabled={resolvedStatus}
            variant="primary"
            onClick={() => {
              setEditId(params?.data?.stepDataSourceId);
              handleEdit(params?.data);
            }}
          >
            <MotifIcon fill="none" iconFunction={contentIcCreate24px} />
          </MotifButton>
        </div>
      </>
    );
  };

  const ApplicableClassesRenderer = (params: any) => {
    return (
      <>
        <div className="pad-r-10">
          <MotifCheckbox
            id="checkbox-example"
            name="checkbox-example"
            onChange={function Ua() {}}
            value="checkbox-result-example"
            checked={params?.data?.applicableClasses === true ? true : false}
          >
            <span></span>
          </MotifCheckbox>
        </div>
      </>
    );
  };

  const columnDataSourceDefs = [
    {
      field: "dataSource",
      headerName: t("pages.distributions.distributionStepsTab.dataSourceTab.columnDetails.column1"),
      sortable: true,
      unSortIcon: true,
      flex: 1,
    },
    {
      field: "sourceManualDataSourceName",
      headerName: t("pages.distributions.distributionStepsTab.dataSourceTab.columnDetails.column2"),
      flex: 1,
    },
    {
      field: "sourceDistributionName",
      headerName:t("pages.distributions.distributionStepsTab.dataSourceTab.columnDetails.column3"),
      flex: 1,
    },
    {
      field: "sourceCalculationStepName",
      headerName: t("pages.distributions.distributionStepsTab.dataSourceTab.columnDetails.column4"),
      flex: 1,
    },
    {
      field: "weight",
      headerName: t("pages.distributions.distributionStepsTab.dataSourceTab.columnDetails.column5"),
      flex: 1,
    },
    {
      field: "entitlement",
      headerName: t("pages.distributions.distributionStepsTab.dataSourceTab.columnDetails.column6"),
      flex: 1,
    },
    {
      field: "applicableClasses",
      headerName: t("pages.distributions.distributionStepsTab.dataSourceTab.columnDetails.column7"),
      flex: 1,
      cellRenderer: ApplicableClassesRenderer,
    },
    {
      cellRenderer: ActionsCellRenderer,
      field: "action",
      headerName: "",
      flex: 1,
      sortable: false,
      cellClass: "align-right",
    },
  ];

  if (error) {
    return (
      <MotifErrorMessage
        style={{
          textAlign: "center",
          display: "block",
          alignItem: "center",
        }}
      >
{t("global.globalNotifications.wentWrong")}      </MotifErrorMessage>
    );
  }

  if (isLoading) {
    return <MotifProgressLoader show variant="default" />;
  }

  return (
    <>
      <div>
        {isLoading ? (
          <MotifProgressLoader show variant="default" className="mar-b-20" />
        ) : (
          <div className="mar-b-20 mar-t-20">
            <MotifModal
              onClose={() => setShowModal(false)}
              show={showModal}
              focusTrapOptions={{
                tabbableOptions: {
                  displayCheck: "none",
                },
              }}
            >
              <div className="confirmationModalWrapper creditor_disabling_model">
                <MotifModalHeader
                  headerIcon={
                    <>
                      <span
                        style={{
                          backgroundColor: "rgb(252, 245, 243)",
                          borderRadius: "50px",
                          padding: "5px 8px 2px 8px",
                        }}
                      >
                        <MotifIcon
                          iconFunction={IconoirWarningTriangle}
                          strokeWidth="1"
                          size="15"
                          stroke="red"
                        />{" "}
                      </span>
                      <span
                        style={{
                          fontSize: 16,
                          display: "block",
                          width: "100%",
                          marginLeft: "10px",
                        }}
                      >
{t("global.globalNotifications.warning")}                      </span>
                    </>
                  }
                ></MotifModalHeader>

                <MotifModalBody style={{ paddingTop: "0px" }}>
                  <span style={{ fontWeight: "300" }}>
                    {t("pages.distributions.distributionStepsTab.dataSourceTab.deleteWarningMessage")}
                  </span>
                </MotifModalBody>
                <MotifModalFooter>
                  <MotifButton
                    size="small"
                    type="submit"
                    variant="secondary"
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      deleteDataSourceAction(deleteId);
                      setShowModal(false);
                    }}
                  >
                    {t("global.globalActions.yes")}   
                  </MotifButton>
                  <MotifButton
                    className=""
                    onClick={() => setShowModal(false)}
                    size="small"
                    type="button"
                  >
                     {t("global.globalActions.cancel")}   
                  </MotifButton>
                </MotifModalFooter>
              </div>
            </MotifModal>
            <Table
              columnDefs={columnDataSourceDefs}
              cellRenderers={sourceData}
              TitleComplement={TitleComplement}
              title={"pages.distributions.distributionStepsTab.dataSourceTab.tableTitle"}
              description={
                "pages.distributions.distributionStepsTab.dataSourceTab.tableDescription"              }
              caseRenderers={headerData}
              pagination={false}
              overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
            />
          </div>
        )}
        {
          editId && editId !== "" ? (<FormModal
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            formData={formData}
            caseId={caseId}
            stepId={stepId}
            setIsRefetch={setIsRefetch}
            setFormData={setFormData}
            editId={editId}
          />) : (<FormModalNew
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            caseId={caseId}
            stepId={stepId}
            setIsRefetch={setIsRefetch}
            setFormData={setFormData}
          />)
        }
        
      </div>
    </>
  );
};
