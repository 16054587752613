import { ccwpApi } from "@/services";

const responseHandler = async (response:any) => {
  if(response?.status === 200){
    const url = URL.createObjectURL(await response.blob());
    const a = document.createElement("a");
    a.href = url;
    a.download = "downloaded_file";
    const downloadControls =
      document.getElementsByClassName("motif-modal-content")[0] ||
      document.body;
    downloadControls.appendChild(a);
    a.click();
    downloadControls.removeChild(a);
    URL.revokeObjectURL(url);
  }
  }
  
export const ExportsApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
      exportAdvanceSearch: builder.mutation({
        query: ({url,data}) => ({
          url,
          body:data,
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          responseHandler: responseHandler,
          cache: "no-cache",
        }),
      }),

      exportDocument: builder.query({
        query: ({url,...args}) => ({
          url,
          params:{...args},
          method: "GET",
          responseHandler: responseHandler,
          cache: "no-cache",
        }),
      })
  }),
});

export const { 
              useExportAdvanceSearchMutation,
              useLazyExportDocumentQuery
            } = ExportsApi;
