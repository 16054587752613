import { Control, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import {
  CATEGORY_FIELD,
  NATURE_OF_CLAIM_FIELD,
} from "../Constants";
import ControlSelect from "../ControlSelect";
import ControlMultiSelect from "@/components/Form/MultiSelectBox/ControlMultiSelect";
import { ClaimDetailsType } from "../type";
import { useTranslation } from "react-i18next";
import { useGetClaimCategoryNatureOfClaimQuery } from "@/services/claimDetail/claimDetailReducer";
import {
  getCategoryOptions,
  getNatureOfClaimsOptions,
} from "../ClaimDetailsHelper";
import { useEffect, useState } from "react";
import { useCase } from "@/features/case/use-case";

interface PropsType {
  control: Control<ClaimDetailsType, any>;
  setValue: UseFormSetValue<ClaimDetailsType>;
  getValues: UseFormGetValues<ClaimDetailsType>;
  editView?: boolean;
  selectedItem?: {
    categoryId: string;
    natureOfClaim: string;
    getClaimItemstatus: any;
  };
}

export default function ClaimItemIdentification({
  control,
  setValue,
  getValues,
  selectedItem,
}: PropsType) {
  const { t } = useTranslation();
  const caseData = useCase();
  const caseId = caseData.caseId;
  const { data, error, isLoading } =
    useGetClaimCategoryNatureOfClaimQuery(caseId);
  const [checkDelete, setCheckDelete] = useState(false);

  useEffect(() => {
    if (
      data &&
      data?.categories?.length > 0 &&
      selectedItem?.categoryId !== "" &&
      checkDelete === false
    ) {
      //@ts-ignore
      const selection = categories?.find(
        (item:any) => item.claimCategoryId === selectedItem?.categoryId
      );
      if (selection === undefined) {
        setValue(CATEGORY_FIELD, "");
        setCheckDelete(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDelete, data]);

  if (isLoading) return <div>{t("global.globalNotifications.loadingMessage")}</div>;
  if (error) return <div></div>;
  const { categories, natureOfClaim } = data;
  const categoryOptions = categories && getCategoryOptions(categories);
  let natureOfClaimsOptions =
    natureOfClaim && getNatureOfClaimsOptions(natureOfClaim);

    const handleNOCChange = (selectedValue: string[]) => {    
      let selectedOptions = [...selectedValue]; 
  
      const hasSecured = selectedOptions.map(value => value.toLowerCase()).includes("secured");
      const hasUnsecured = selectedOptions.map(value => value.toLowerCase()).includes("unsecured");
    
      if (hasSecured && hasUnsecured) {
        const lastValue = selectedOptions[selectedOptions.length - 1];
  
        selectedOptions = selectedOptions.filter((value) => {
          if (value === "Secured" && lastValue === "Unsecured") {
            return false;
          } else if (value === "Unsecured" && lastValue === "Secured") {
            return false;
          }
          return true;
        });
      }
      //@ts-ignore
      setValue(`natureOfClaim`, selectedOptions, { shouldValidate: true });
    };

  return (
    <>
      <div className="motif-col-lg-4">
        <div className="form-field">
          <ControlSelect
            name={CATEGORY_FIELD}
            control={control}
            required={true}
            showLabel={true}
            label={"pages.claims.claimItemCommonLabels.category"}
            options={categoryOptions}
            setValue={setValue}
            getValues={getValues}
            value={selectedItem?.categoryId}
            disabled={selectedItem?.getClaimItemstatus}
          />
        </div>
      </div>
      <div className="motif-col-lg-4">
        <div className="form-field">
          <ControlMultiSelect
            name={NATURE_OF_CLAIM_FIELD}
            control={control}
            required={true}
            showLabel={true}
            label={"pages.claims.claimItemCommonLabels.claimNature"}
            options={natureOfClaimsOptions}
            setValue={setValue}
            getValues={getValues}
            value={selectedItem?.natureOfClaim || []}
            disabled={selectedItem?.getClaimItemstatus}
            multiple={true}
            // @ts-ignore
            onChange={(selectedValue)=>handleNOCChange(selectedValue)}
          />
        </div>
      </div>
    </>
  );
}
