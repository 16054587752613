import React, { useEffect, useState } from "react";
//import "./CrediotrSetup.scss";
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifCheckbox,
} from "@ey-xd/motif-react";
import Button from "@/components/Form/Button/Button";
import { useFieldArray, useForm } from "react-hook-form";
import { MotifErrorMessage } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import {

  SECONDARY,
} from "@/Constant";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { ContactDetailsRowType, ContactDetailsType } from "./type";
import {
  ADDRESS1,
  ADDRESS2,
  CITY,
  COUNTRY,
  EMAIL,
  FAX,
  FIRST_NAME,
  LAST_NAME,
  PHONE,
  POSTAL_CODE,
  PREFIX,
  RELATIONSHIP_TO_CREDITOR,
  SET_AS_PRIMARY_CONTACT,
  INSTITUTION_NUMBER,
  TRANSIT_NUMBER,
  ACCOUNT_NUMBER,
  ROUTING_CODE,
  SWIFT_CODE,
  IBAN_NUMBER,
  ACH_CODE,

} from "./Constants";
import ControlInputBox from "./Form/ControlInputBox";
import "./CreditorSetup.scss";
import { useGetCountryStateListDataQuery } from "@/services/countryState/countryStateReducer";
import ControlSelect from "@/components/Form/SelectBox/ControlSelect";
import ControlSelectCountry from "./Form/SelectBox/ControlSelectCountry";
import { useNavigate, useParams } from "react-router-dom";
import { NonEmptyString } from "@/utility/common";
import { RootState } from "@/app/store";
import { useSelector, useDispatch } from "react-redux";
import {
  resetCreditorSetup,
  setContactDetails,
  updateFormSubmitIndex,
  updateStage,
} from "./creditorSetupSlice";
import StateDropdown from "./StateDropdown";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
import PaymentInputField from "./PaymentInputField";
interface countryRecordType {
  countryCode: string;
  countryName: string;
  countryId: string;
}

export default function ContactDetails() {
  const { t } = useTranslation();
  const PREFIX_OPTIONS_LIST = [
    { 
        label: t("global.globalActions.optionDefault"), 
        value: "" },
    {
        label:t("global.globalActions.prefixOptionsListLabels.mr"),
        value: "Mr.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.ms"),
        value: "Ms.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.mrs"),
        value: "Mrs.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.miss"),
        value: "Miss.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.other"),
        value: "other",
    }
]

  const [isVisible, setIsVisible] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(true);
  const [countryArray, setCountryArry] = useState([
    
      {
        label: t("global.globalActions.optionDefault"),
        value: "",
      },
    
  ]);
  const [showField, setShowField] = useState<any>({})

  const dispatch = useDispatch();
  const CreditorSetupData = useSelector(
    (state: RootState) => state.creditorSetup
  );
  const formSubmitIndex = CreditorSetupData.formSubmitIndex;
  const { contactDetails } = CreditorSetupData;
  const { caseId } = useParams();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState({
    index: parseInt("0"),
    value: true,
  });
  let defaultOption = {
    label: t("global.globalActions.optionDefault"),
    value: "",
  };
  const { data, isLoading } = useGetCountryStateListDataQuery("");
  const [stateOptionArray, setStateOptionArray] = useState([[defaultOption]]);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ContactDetailsType>({
    defaultValues: {
      fieldArray: contactDetails?.fieldArray || [],
    },
  });

  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: true }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch]);
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "fieldArray",
  });
  const watchFieldArray = watch("fieldArray");

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  useEffect(() => {
    if (data !== undefined) {
      // eslint-disable-next-line array-callback-return
      const countryOptions = data.map((record: countryRecordType) => {
        return {
          label: record.countryName,
          value: record.countryId,
        };
      });
      setCountryArry(countryOptions);
      const controlledFields = watch("fieldArray");
      controlledFields.map((field, index) => {
        if (NonEmptyString(field.country)) {
          onChangeHandlerCountry(index, field.country);
        }
        return true;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  const addMoreRow = () => {
    append({
      firstName: "",
      lastName: "",
      prefix: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      email: "",
      phone: "",
      fax: "",
      postalCode: "",
      setAsPrimaryContact: false,
      relationShipToCreditor: "",
      stateName: "",
      countryName: "",
      addPaymentDetails:false,
      institutionNumber:"",
      transitNumber:"",
      accountNumber:"",
      ibanNumber:"",
      swiftCode:"",
      routingCode:"",
      achCode:"",
      usedAsAdditionalPayeeType:false,
      additionalPayeeTypeId:"",
    });
    
    const newstateOptions = [...stateOptionArray, [defaultOption]];
    setStateOptionArray(newstateOptions);
  };
  const removeRow = (index: number) => {
    remove(index);
  };

  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    dispatch(resetCreditorSetup());
    dispatch(updateStage(0));
    controlledFields.map((field, index) => {
      field && remove(index);
      return true;
    });
    setIsVisible(false);
    navigate(`/case/${caseId}?activeTab=2`);
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));

  };

  const errorList = Object.keys(errors);
  //@ts-ignore
  const onSubmit = (data) => {
    // data.fieldArray[isChecked.index].setAsPrimaryContact = true;
    const updatedFieldArray = data.fieldArray.map((item: any, idx: any) => ({
      ...item,
      setAsPrimaryContact: idx === isChecked.index,
    }));
    //setCreditorSetupData({ ...CreditorSetupData, contactDetails: data });
    dispatch(
      setContactDetails({
        ...data,
        fieldArray: updatedFieldArray,
      })
    );
    dispatch(updateStage(2));
    formSubmitIndex !== 3 && dispatch(updateFormSubmitIndex(1));
  };
  const checkBoxHandler = (e?: React.ChangeEvent<HTMLInputElement>) => {
    const index = e?.target.name.split("-")[1];
    if (index === undefined) {
      return false;
    }
    e?.target.checked
      ? setIsChecked({ index: parseInt(index), value: true })
      : setIsChecked({ index: 0, value: true });
  };

  const checkBoxIsChecked = (index: number) => {
    return isChecked.index === index && isChecked.value;
  };

  const addPaymentDetailsHandler = (e?: React.ChangeEvent<HTMLInputElement>) => {
    const index = e?.target.name.split("-")[1];

     const watchFieldArray = watch("fieldArray");
     if(index !== undefined){
        const controlObj = watchFieldArray[Number(index)];
      //@ts-ignore
        update(Number(index), { ...controlObj, addPaymentDetails: e?.target.checked });
  
        if(!e?.target.checked){
      //@ts-ignore
          update(Number(index), { ...controlObj, addPaymentDetails: e?.target.checked, institutionNumber: "", transitNumber: "", accountNumber: "", ibanNumber: "", swiftCode: "", routingCode: "", achCode: "", bankBranch: "" });
     }
    }    
  };

  const PaymentCheckBoxIsChecked = (index: number) => {
    const watchFieldArray = watch("fieldArray");

    const controlObj = watchFieldArray[Number(index)];

    return controlObj.addPaymentDetails;
  };
  
  const onChangeHandlerCountry = async (index: number, countryId: string) => {
    const countrySelected = countryArray.find((country) => {
      //@ts-ignore
      return country?.value === countryId;
    });

    const watchFieldArray = watch("fieldArray");
    const controlObj = watchFieldArray[index];
    //@ts-ignore
    update(index, { ...controlObj, countryName: countrySelected?.label || "" });
  };

  const onChangeHandlerState = async (
    index: number,
    stateId: string,
    stateOptions: { label: string; value: string }[]
  ) => {
    const stateSelected = stateOptions.find((state) => {
      return state?.value === stateId;
    });
    const watchFieldArray = watch("fieldArray");
    const controlObj = watchFieldArray[index];
    update(index, { ...controlObj, stateName: stateSelected?.label || "" });
  };

  const handleViewIcon = (name: string) => {
    setShowField((prev: any) => ({ ...prev, [name]: !prev[name] }));
  };

  const renderContactRow = (field: ContactDetailsRowType, index: number) => {
    return (
      <MotifAccordion
        onClose={() => setIsFormOpen(false)}
        onOpen={() => setIsFormOpen(true)}
        style={{
          flex: "1",
        }}
        open={isFormOpen}
        useChevronIcon={true}
        alignIconRight={true}
        key={field.id}
      >
        <MotifAccordionTrigger>
          {`${
            index === 0 ? t("pages.creditors.creditorForm.formLabelDetails.primaryContactDetails") : t("pages.creditors.creditorForm.formLabelDetails.otherContactDetails")
          }`}
        </MotifAccordionTrigger>
        <MotifAccordionContent>
          <div
            className={`motif-row pad-t-20 pad-b-20 ${
              index === 0 ? "hide" : ""
            }`}
          >
            <div className="motif-col-xl-6">
              <MotifCheckbox
                id={`${SET_AS_PRIMARY_CONTACT}-${index}`}
                name={`${SET_AS_PRIMARY_CONTACT}-${index}`}
                //@ts-ignore
                onChange={checkBoxHandler}
                value={"setAsPrimaryContact"}
                checked={checkBoxIsChecked(index)}
              >
                {t("pages.creditors.creditorForm.formLabelDetails.checkBoxPrimaryContact")}
              </MotifCheckbox>
            </div>
          </div>

          <div className="motif-row">
            <div className="motif-col-xl-6">
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlSelect
                  //@ts-ignore
                  name={`fieldArray.${index}.${PREFIX}`}
                  //@ts-ignore
                  control={control}
                  required={false}
                  showLabel={true}
                  label={t("pages.creditors.creditorForm.formLabelDetails.prefix")}
                  options={PREFIX_OPTIONS_LIST}
                  //@ts-ignore
                  setValue={setValue}
                  //@ts-ignore
                  getValues={getValues}
                />
              </div>
            </div>
            <div className="motif-col-xl-6">
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlInputBox
                  name={`fieldArray.${index}.${FIRST_NAME}`}
                  //@ts-ignore
                  control={control}
                  required={true}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.firstName"}
                  showLabel={true}
                />
                {errors &&
                  errors?.fieldArray?.[index]?.[FIRST_NAME] &&
                  errors?.fieldArray?.[index]?.[FIRST_NAME]?.type ===
                    "required" && (
                    <MotifErrorMessage>
                      {t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.firstNameMessage")}
                    </MotifErrorMessage>
                  )}
              </div>
            </div>
          </div>
          <div className="motif-row pad-t-20">
            <div className="motif-col-xl-6">
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlInputBox
                  name={`fieldArray.${index}.${LAST_NAME}`}
                  //@ts-ignore
                  control={control}
                  required={true}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.lastName"}
                  showLabel={true}
                />
                {errors &&
                  errors?.fieldArray?.[index]?.[LAST_NAME] &&
                  errors?.fieldArray?.[index]?.[LAST_NAME]?.type ===
                    "required" && (
                    <MotifErrorMessage>
                      {t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.lastNameMessage")}
                    </MotifErrorMessage>
                  )}
              </div>
            </div>
            <div className="motif-col-xl-6">
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlInputBox
                  name={`fieldArray.${index}.${EMAIL}`}
                  //@ts-ignore
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.email"}
                  showLabel={true}
                />
              </div>
            </div>
          </div>
          <div className="motif-row pad-t-20">
            <div className="motif-col-xl-6">
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlInputBox
                  name={`fieldArray.${index}.${PHONE}`}
                  //@ts-ignore
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.phoneNumber"}
                  showLabel={true}
                />
              </div>
            </div>
            <div className="motif-col-xl-6">
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlInputBox
                  name={`fieldArray.${index}.${FAX}`}
                  //@ts-ignore
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.fax"}
                  showLabel={true}
                />
              </div>
            </div>
          </div>
          <div className="motif-row pad-t-20">
            <div className="motif-col-xl-6">
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlInputBox
                  name={`fieldArray.${index}.${RELATIONSHIP_TO_CREDITOR}`}
                  //@ts-ignore
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.relationshipToCreditor"}
                  showLabel={true}
                />
              </div>
            </div>
            <div className="motif-col-xl-6">
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlSelectCountry
                  //@ts-ignore
                  name={`fieldArray.${index}.${COUNTRY}`}
                  //@ts-ignore
                  control={control}
                  required={false}
                  showLabel={true}
                  label={"pages.creditors.creditorForm.formLabelDetails.country"}
                  //@ts-ignore
                  options={countryArray}
                  //@ts-ignore
                  setValue={setValue}
                  //@ts-ignore
                  getValues={getValues}
                  onChangeHandlerCountry={onChangeHandlerCountry}
                  index={index}
                  defaultValue={field.country}
                  filter={true}
                />
                <div className="hide">
                  {/**@ts-ignore */}
                  <ControlInputBox
                    name={`fieldArray.${index}.${"countryName"}`}
                    //@ts-ignore
                    control={control}
                    required={false}
                    isTextArea={false}
                    label={""}
                    showLabel={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="motif-row pad-t-20">
            <div className="motif-col-xl-6">
              <div className="form-field">
                <StateDropdown
                  countryID={field.country}
                  index={index}
                  onChangeHandlerState={onChangeHandlerState}
                  control={control}
                  stateId={field.state}
                />
                <div className="hide">
                  {/**@ts-ignore */}
                  <ControlInputBox
                    name={`fieldArray.${index}.${"stateName"}`}
                    //@ts-ignore
                    control={control}
                    required={false}
                    isTextArea={false}
                    label={""}
                    showLabel={false}
                  />
                </div>
              </div>
            </div>
            <div className="motif-col-xl-6">
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlInputBox
                  name={`fieldArray.${index}.${CITY}`}
                  //@ts-ignore
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.city"}
                  showLabel={true}
                />
              </div>
            </div>
          </div>
          <div className="motif-row pad-t-20">
            <div className="motif-col-xl-6">
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlInputBox
                  name={`fieldArray.${index}.${ADDRESS1}`}
                  //@ts-ignore
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.address1"}
                  showLabel={true}
                />
              </div>
            </div>
            <div className="motif-col-xl-6">
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlInputBox
                  name={`fieldArray.${index}.${ADDRESS2}`}
                  //@ts-ignore
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.address2"}
                  showLabel={true}
                />
              </div>
            </div>
          </div>
          <div className="motif-row pad-t-20">
            <div className="motif-col-xl-6">
              <div className="form-field">
                {/**@ts-ignore */}
                <ControlInputBox
                  name={`fieldArray.${index}.${POSTAL_CODE}`}
                  //@ts-ignore
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.creditors.creditorForm.formLabelDetails.postalCode"}
                  showLabel={true}
                />
              </div>
            </div>
          </div>
          <div className="motif-row pad-t-20">
            <div className="motif-col-xl-6">
              <MotifCheckbox
                id={`addPaymentDetails-${index}`}
                name={`addPaymentDetails-${index}`}
                //@ts-ignore
                onChange={addPaymentDetailsHandler}
                value={"addPaymentDetails"}
                checked={PaymentCheckBoxIsChecked(index)}
              >
                {t("pages.creditors.creditorForm.formLabelDetails.paymentCheckbox")}
              </MotifCheckbox>
            </div>
            </div>
            {PaymentCheckBoxIsChecked(index) ? (
            <div className="motif-row pad-t-20">
              <div className="payment-details-wrapper">
                <div className="motif-row pad-t-20">
                  <div className="motif-col-lg-3">
                    <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.institutionNumber")}</div>
                    <PaymentInputField
                      control={control}
                      showField={showField}
                      handleViewIcon={handleViewIcon}
                      name={`fieldArray.${index}.${INSTITUTION_NUMBER}`}
                    />
                  </div>
                  <div className="motif-col-lg-3">
                    <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.transitNumber")}</div>
                    <PaymentInputField
                      control={control}
                      showField={showField}
                      handleViewIcon={handleViewIcon}
                      name={`fieldArray.${index}.${TRANSIT_NUMBER}`}
                    />
                  </div>
                  <div className="motif-col-lg-3">
                    <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.accountNumber")}</div>
                    <PaymentInputField
                      control={control}
                      showField={showField}
                      handleViewIcon={handleViewIcon}
                      name={`fieldArray.${index}.${ACCOUNT_NUMBER}`}
                    />
                  </div>
                  <div className="motif-col-lg-3">
                    <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.IBANNumber")}</div>
                    <PaymentInputField
                      control={control}
                      showField={showField}
                      handleViewIcon={handleViewIcon}
                      name={`fieldArray.${index}.${IBAN_NUMBER}`}
                    />
                  </div>
                </div>
                <div className="motif-row pad-t-20">
                  <div className="motif-col-lg-3">
                    <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.SWIFTCode")}</div>
                    <PaymentInputField
                      control={control}
                      showField={showField}
                      handleViewIcon={handleViewIcon}
                      name={`fieldArray.${index}.${SWIFT_CODE}`}
                    />
                  </div>
                  <div className="motif-col-lg-3">
                    <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.routingCode")}</div>
                    <PaymentInputField
                      control={control}
                      showField={showField}
                      handleViewIcon={handleViewIcon}
                      name={`fieldArray.${index}.${ROUTING_CODE}`}
                    />
                  </div>
                  <div className="motif-col-lg-3">
                    <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.ACHCode")}</div>
                    <PaymentInputField
                      control={control}
                      showField={showField}
                      handleViewIcon={handleViewIcon}
                      name={`fieldArray.${index}.${ACH_CODE}`}
                    />
                  </div>
                </div>
              </div>
              </div>
            ) : (
              ""
            )}

          {index > 0 && (
            <div className="motif-row pad-t-20">
              <div className="motif-col-xl-6 btn-left">
                <Button
                  type="button"
                  variant="secondary"
                  label="Remove"
                  onClickHandler={() => {
                    removeRow(index);
                  }}
                />
              </div>
            </div>
          )}
        </MotifAccordionContent>
      </MotifAccordion>
    );
  };

  return (
    <>
      <div className="motif-container pad-l-40 pad-r-20">
        <div className="motif-row">
          <div className="motif-col-lg-12  main-container">
            <div className="motif-row t-b-padding-21 pad-b-20">
              <div className="motif-col-xs-4">
                <p className="form-title">{t("pages.creditors.creditorForm.contactDetailsTitle")}</p>
                <p className="form-description">
                  {t("pages.creditors.creditorForm.contactDetailsDescription")}
                </p>
              </div>
            </div>
            <div className="motif-row t-b-padding-21">
              <div className="motif-col-xs-4">
                <div
                  style={{
                    alignItems: "flex-start",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="motif-container">
                      {controlledFields.map((field, index) => {
                        return renderContactRow(field, index);
                      })}
                    </div>
                    <MotifAccordion
                      onClose={() => setIsFormOpen(false)}
                      onOpen={() => setIsFormOpen(true)}
                      style={{padding:"0 24px",flex:1}}
                      open={isFormOpen}
                      useChevronIcon={true}
                      alignIconRight={true}
                    >
                      <MotifAccordionTrigger>
                        { t("pages.creditors.creditorForm.formLabelDetails.otherContactDetails")}
                      </MotifAccordionTrigger>
                      <MotifAccordionContent>
                        <div className="motif-row">
                          <div className="motif-col-xl-6 btn-left">
                            <Button
                              type="button"
                              label={t("pages.creditors.creditorForm.formLabelDetails.addAnotherContact")}
                              variant={SECONDARY}
                              onClickHandler={addMoreRow}
                            />
                          </div>
                        </div>
                      </MotifAccordionContent>
                    </MotifAccordion>
                    <div className="error-container">
                      <MotifErrorMessage>
                        {errorList.length > 0 && !isVisible
                          ? t(
"pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.contactDetailsMessage"                            )
                          : ""}
                      </MotifErrorMessage>
                    </div>
                    <div className="button-container">
                      <Button label={t("global.globalActions.next")} />
                      <Button
                        type="button"
                        label={t("global.globalActions.cancel")}
                        variant={SECONDARY}
                        className="cancelBtn"
                        onClickHandler={(e) => {
                          setIsVisible(true); 
                          dispatch(setDiscardEditMode({ editMode: false }));
                        }}
                      />
                    </div>
                  </form>
                  <ConfirmationModal
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    cancelBtnApproveHandler={cancelBtnApproveHandler}
                    cancelBtnRejectHandler={cancelBtnRejectHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
