import { useTranslation } from "react-i18next";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import CaseSetup from "./CaseSetup";
import "./CaseSetup.scss";
import PageTitle from "@/components/Form/PageTitle/PageTitle";
import { PAGE_DESCRIPTION, PAGE_TITLE } from "./Constants";
import DebtorInfomation from "./DebtorInformation/DebtorInfomation";
import CurrencyConfiguration from "./CurrencyConfiguration/CurrencyConfiguration";
import { useState } from "react";
import ReviewForm from "./ReviewForm/ReviewForm";
import TabPanelCase from "./TabPanelCase/TabPanelCase";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

export default function CaseSetupGrid() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);
  let discardDetails = useSelector((e: any) => e.discardChangesDatas.data);

  const stage = useSelector(
    (state: RootState) => state.caseSetup.stage
  );

  const tabPanelData = {
    title: [
      "Case Details",
      "Debtor Information",
      "Currency configuration",
    ],
    content: [
      <CaseSetup />,
      <DebtorInfomation />,
      <CurrencyConfiguration />,
    ],
  };
  const breadcrumbItems = [
    {
      label: 'External Submission',
      value: '10 New Creditor Submission',
      elementKey: 'submission',
      text: t("global.globalActions.backToHome"),
      url: "",
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];

  return (
    <>
      <div className="ccwp_case-setup_wrapper">
        <div style={{ cursor: "pointer" }} onClick={() => {
          if(discardDetails.editMode){
            setIsDiscardVisible(true);  dispatch(setDiscardEditMode({ editMode: false }));

          }
          else{
            navigate('/') 
          }
}}>
          <Breadcrumb  items={breadcrumbItems} ariaLabel="Breadcrumb navigation"/>
        </div>
        <div className="motif-container case-setup pad-l-60 pad-t-40 pad-r-40">
          <div className="motif-row t-b-padding-21"> 
            <div className="motif-col-xs-4">
              <PageTitle title={t(PAGE_TITLE)} description={t(PAGE_DESCRIPTION)} />
            </div>
          </div>
        </div>
        <div className="ccwp_case_setup_tabpanel   pad-t-60">
          {
            stage > 2 ? 
            <ReviewForm /> 
            : <TabPanelCase data={tabPanelData}  orientation='vertical'/>
          }
        </div>
      </div>
      <ConfirmationModal isVisible={isDiscardVisible} setIsVisible={setIsDiscardVisible} cancelBtnApproveHandler={() => {navigate('/')}} cancelBtnRejectHandler={() => {setIsDiscardVisible(false);  dispatch(setDiscardEditMode({ editMode: true }))}}/>
    </>
  );
}