import { ccwpApi } from "@/services";
import {
  BULKIMPORTLIST,
  IMPORTSUMMARYHEADER,
  READIMPORTSUMMARY,
} from "@/services/constant/apiPath";

const commonParams = {
  Type: "quick search",
  OrderBy: "desc",
  PageNo: 1,
  PageSize: 20,
};

// Define a service using a base URL and expected endpoints
export const ImportAndExportListApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getImportAndExportList: builder.query<any, any>({
      query: (caseId) => BULKIMPORTLIST + caseId,
      transformResponse: (response: { results: any[] }) => response.results,
      providesTags: ["DebtorList"],
    }),
    SearchImportAndExportListData: builder.query<
      any,
      { caseId: string; SearchText?: string }
    >({
      query: ({ caseId, SearchText }) => {
        return {
          url: BULKIMPORTLIST + caseId,
          params: {
            ...commonParams,
            SearchText,
          },
        };
      },
      transformResponse: (response: { results: any[] }) => response.results,
    }),
    getImportAndExportListData: builder.query<any, any>({
      query: (args) => ({url:"Import/ReadCaseLevelBulkImportList",method:"GET",params:{...args}}),
      providesTags: ["DebtorList"],
    }),
  }),
});

export const {
  useGetImportAndExportListDataQuery,
  useLazySearchImportAndExportListDataQuery,
} = ImportAndExportListApi;

export const ImportSummaryHeaders = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getImportSummaryHeaderData: builder.query<any, any>({
      query: (id) => IMPORTSUMMARYHEADER + id,
      transformResponse: (response: any) => response,
    }),
  }),
});
export const { useGetImportSummaryHeaderDataQuery } = ImportSummaryHeaders;

export const ReadImportSummaryListApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getReadImportSummaryList: builder.query<any, any>({
      query: (importJobId) => READIMPORTSUMMARY + importJobId,
      transformResponse: (response) => response,
      providesTags: ["DebtorList"],
    }),
    SearchReadImportSummaryListData: builder.query<
      any,
      { caseId: string; SearchText?: string }
    >({
      query: ({ caseId, SearchText }) => {
        return {
          url: READIMPORTSUMMARY + caseId,
          params: {
            ...commonParams,
            SearchText,
          },
        };
      },
      transformResponse: (response: { results: any[] }) => response.results,
    }),
    getReadImportSummaryListData: builder.query<any, any>({
      query: (importData) =>{return {url:"Import/ReadImportSummaryList",params: {
        ImportJobId:importData.ImportJobId,
        OrderBy: "desc",
        Type: "",
        PageNumber: importData.currentPage,
        PageSize: importData.pageSize,
        IsStatusFilter:importData.isStatusFilter,
        IsSuccess:importData.isSuccess
      }}},
      transformResponse: (response: { results: any }) => response,
      providesTags: ["DebtorList"],
    }),
  }),
});

export const {
  useGetReadImportSummaryListDataQuery,
  useGetReadImportSummaryListQuery,
  useLazySearchReadImportSummaryListDataQuery,
  useLazyGetReadImportSummaryListDataQuery,
} = ReadImportSummaryListApi;
