import React, { useEffect, useState } from "react";
import {
  DISTRIBUTION_DATE,
  DISTRIBUTION_DESCRIPTION_ID,
  DISTRIBUTION_DESCRIPTION_ID_FRENCH,
  DISTRIBUTION_ID,
  DISTRIBUTION_ID_FRENCH,
} from "../constants/Constants";
import { DistributionSetupFormType } from "../constants/type";
import { useForm } from "react-hook-form";
import ControlInputBox from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import Button from "@/components/Form/Button/Button";
import { SECONDARY } from "@/Constant";
import { useTranslation } from "react-i18next";
import { MotifErrorMessage, MotifToast } from "@ey-xd/motif-react";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateDistributionMutation } from "@/services/distribution/distributionReducers";
import FormattedDatePicker from "@/components/FormattedDatePicker/FormattedDatePicker";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

const DistributionSetupForm = () => {
  const { t } = useTranslation();
  const { caseId } = useParams<{ caseId: string }>();
  const {
    handleSubmit,
    control,
    formState: { errors,isDirty },
    setValue,
    watch
  } = useForm<DistributionSetupFormType>({
    defaultValues: {
      [DISTRIBUTION_ID]: "",
      [DISTRIBUTION_ID_FRENCH]: "",
      [DISTRIBUTION_DESCRIPTION_ID]: "",
      [DISTRIBUTION_DESCRIPTION_ID_FRENCH]: "",
      [DISTRIBUTION_DATE]:null
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });
  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };
  const renderMessage = () => {
    if (showMessage.show) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={showMessage.type}
        >
          {" "}
          {showMessage.type === "error"
            ? t(showMessage.message)
            : t("global.globalNotifications.updatedSuccessfully")}
        </MotifToast>
      );
    }
  };
  const dispatch=useDispatch()
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,isDirty]);
  const navigate = useNavigate();
  const [createDistribution] = useCreateDistributionMutation();
  const onSubmit = async (data: DistributionSetupFormType) => {
    setIsLoading(true);
    const requestBody = {
      caseId,
      name: data.name,
      description: data.description,
      nameFrench: data.nameFrench,
      descriptionFrench: data.descriptionFrench,
      isActive: true,
      IsDistributionCreationFromScratch:true,
      distributionDate:data?.distributionDate?data.distributionDate:null,

    };
    dispatch(setDiscardEditMode({ editMode: false }));

    await createDistribution(requestBody).then((response: any) => {
      if (response.data) {
        navigate(
          `/case/${caseId}/distribution/${response.data.distributionId}`,
          {
            state: {
              showMessage: true,
              message: response.data.message,
              type: "success",
            },
          }
        );
      }
      else if (response.error) {
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          message:
            response.error.data.message || "global.globalNotifications.unExpectedError",
        });
        setIsLoading(false);
        dispatch(setDiscardEditMode({ editMode: true }));

      }
    });
  };
  function handleChange(value: any) {
    var selectedDate = new Date(value);
setValue("distributionDate",`${selectedDate.getFullYear()}-${selectedDate.getMonth()+1}-${selectedDate.getDate()?.toString()?.padStart(2,`0`)}`,{shouldDirty:true});
  }
  return (
    <>
      {renderMessage()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-row">
          <div className="motif-col-md-3 ">
            <div className="form-field ">
              <ControlInputBox
                name={DISTRIBUTION_ID}
                control={control}
                required={true}
                isTextArea={false}
                label={"pages.distributions.distribution.distributionFormLabels.distributionName"}
                showLabel={true}
              />
              {errors[DISTRIBUTION_ID] &&
                errors[DISTRIBUTION_ID].type === "required" && (
                  <MotifErrorMessage>
{t("pages.distributions.distribution.validationMessages.distributionNameReq")}                  </MotifErrorMessage>
                )}
            </div>
          </div>
          <div className="motif-col-md-3">
            <div className="form-field">
              <ControlInputBox
                name={DISTRIBUTION_ID_FRENCH}
                control={control}
                isTextArea={false}
                label={"pages.distributions.distribution.distributionFormLabels.distributionNameFrench"}
                showLabel={true}
              />
            </div>
          </div>
        </div>
        <br></br>

        <div className="motif-row next-row">
          <div className="motif-col-md-3">
            <div className="form-field">
              <ControlTextAreaWithCharLimit
                name={DISTRIBUTION_DESCRIPTION_ID}
                control={control}
                isTextArea={true}
                label={"pages.distributions.distribution.distributionFormLabels.description"}
                showLabel={true}
              />
            </div>
          </div>
          <div className="motif-col-md-3">
            <div className="form-field ">
              <ControlTextAreaWithCharLimit
                name={DISTRIBUTION_DESCRIPTION_ID_FRENCH}
                control={control}
                isTextArea={true}
                label={"pages.distributions.distribution.distributionFormLabels.descriptionFrench"}
                showLabel={true}
              />
            </div>
          </div>
        </div>
                
        <div className="motif-row next-row">
        <div className="motif-col-md-3">
        <div className="form-field ">
        <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={"Distribution Date"}>
                        {t("pages.distributions.distribution.distributionFormLabels.distributionDate")}
                        {/* {required ? <span className="requiredSymbol">*</span> : ''} */}
                    </label>
                </div>
        <FormattedDatePicker
            onChange={(value) =>{
              handleChange(value)
            }
            }
            value={
               watch("distributionDate")
                ? new Date( watch("distributionDate")+"")
                : undefined
            }          />
          </div>
          </div>
          </div>
        <div className="button-container">
          <Button
            label={t("global.globalActions.save")}
            type={isLoading ? "loader" : "submit"}
            disabled={isLoading}
          />
          <Button
            type="button"
            label={t("global.globalActions.cancel")}
            variant={SECONDARY}
            className="cancelBtn"
            onClickHandler={()=>{navigate(`/case/${caseId}/?activeTab=6`)}}
          />
        </div>
      </form>
    </>
  );
};

export default DistributionSetupForm;
