import Banner from "@/components/Banner/Banner";

export default function CalculationStepHeader() { 
 
    const bannerElements: {label: string, value: string}[] = [
        {
          label: "pages.distributions.distributionStepsTab.distributionBanner.label1",
          value: `-`,
        },
        {
            label: "pages.distributions.distributionStepsTab.distributionBanner.label2",
            value: `-`,
          },
    ];
 
    return (
        <div className="claim-details-header mar-b-20">
            <div className="claim-details-header__title">
                <Banner
                    bannerName="pages.distributions.distributionStepsTab.distributionBanner.title"
                    bannerNameValue={`-`}
                    elements={bannerElements}
                />  
            </div>
        </div>
    );
}