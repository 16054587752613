import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Table from "@/components/Table/table";
import { useColumnDefs } from "@/features/claimsummary/summary/constant/claimFormHeaderConst";
import {
  MotifButton,
  MotifIcon,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import {
  IconoirEditPencil,
  IconoirCloudDownload,
  IconoirTrash,
} from "@ey-xd/motif-icon";
import { useGetDocumentViewDataQuery } from "@/services/claimSummary/caseSummaryReducer";
import UploadEditDocument from "./uploadEditDocument/uploadEditDocument";
import { useCase } from "@/features/case/use-case";
import { useLazyDownloadDocumentQuery, useDeleteDocumentMutation } from "@/services/documents/documentsReducer";
import DeleteModal from "@/components/Modal/DeleteModal/DeleteModal";
import Button from "@/components/Form/Button/Button";

export const Documents = () => {
  const parameter = useParams();
  const { t } = useTranslation();
  const caseData = useCase();
  const { caseId } = caseData;
  // @ts-ignore
  const getDataByUrl = { caseId: caseId, claimId: parameter?.claimId };
  const { data, isLoading, refetch } =
    useGetDocumentViewDataQuery(getDataByUrl);
  const [showUploadEdit, setShowUploadEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedDocument, setSelectedDocuement] = useState<any>({});
  const [downloadDocument] = useLazyDownloadDocumentQuery();
  const [deleteDocument] = useDeleteDocumentMutation();
const columnDefs=useColumnDefs()
  useEffect(() => {
    if (showUploadEdit === false) {
      refetch();
    }
    // eslint-disable-next-line
  }, [showUploadEdit]);

  function TitleComplement() {
    return (
      <MotifButton
        onClick={handleUploadNew}
        size="small"
        type="submit"
        variant="secondary"
      >
{t("pages.claims.claimsDoumentsTab.uploadButton")}      </MotifButton>
    );
  }

  const customActionHeader = {
    actionRenderer: function (props: any) {
      return (
        <>
          <div className="ccwp_document_customheader_wrapper">
            <Button
              onClickHandler={function Ua() {}}             
              type="submit"
              variant="secondary"
              label="View"
              className="mar-t-0"
            />
            <MotifButton
              onClick={() => handleDownload(props.data)}
              size="small"
              type="submit"
              variant="primary"
            >
              <MotifIcon fill="none" iconFunction={IconoirCloudDownload} />
            </MotifButton>
            <MotifButton
              onClick={() => {
                setSelectedDocuement(props.data);
                setShowDelete(true);
              }}
              size="small"
              type="submit"
              variant="primary"
            >
              <MotifIcon fill="none" iconFunction={IconoirTrash} />
            </MotifButton>
            <MotifButton
              onClick={() => handleEdit(props.data)}
              size="small"
              type="submit"
              variant="primary"
            >
              <MotifIcon fill="none" iconFunction={IconoirEditPencil} />
            </MotifButton>
          </div>
        </>
      );
    },
  };

  const handleUploadNew = () => {
    setIsEdit(false);
    setSelectedDocuement({});
    setShowUploadEdit(true);
  };

  const handleEdit = (documentData: any) => {
    setIsEdit(true);
    setSelectedDocuement(documentData);
    setShowUploadEdit(true);
  };

  const handleDownload = (documentData: any) => {
    downloadDocument({
      caseId: caseId,
      documentId: documentData.id,
      fileName: documentData.fileName,
    });
  };

  const handleDelete = () => {
    deleteDocument({
      CaseId: caseId,
      DocumentId: selectedDocument.id,
    }).then(() => {
      setShowDelete(false);
      refetch();
    })
  };

  return (
    <>
      <div className="mar-b-20">
        {isLoading ? (
          <MotifProgressLoader show variant="default" className="mar-b-20" />
        ) : (
          <Table
            columnDefs={columnDefs}
            cellRenderers={data}
            TitleComplement={TitleComplement}
            title={"pages.claims.claimsDoumentsTab.tableTitle"    }
            description={
"pages.claims.claimsDoumentsTab.tableDescription"            }
            caseRenderers={customActionHeader}
            pagination={false}
            overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
          />
        )}
      </div>

      {showUploadEdit && (
        <UploadEditDocument
          show={showUploadEdit}
          setShow={setShowUploadEdit}
          claimId={parameter?.claimId!}
          isEdit={isEdit}
          document={selectedDocument}
        />
      )}

      <DeleteModal
        message={t("pages.claims.documentPopup.deleteMessage")}
        isVisible={showDelete}
        setIsVisible={setShowDelete}
        cancelBtnHandler={() => setShowDelete(false)}
        yesBtnHandler={handleDelete}
      />
    </>
  );
};
