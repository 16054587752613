import ControlInputBox from "@/features/claimsummary/editSummary/editSummaryInputFields";
import {
  ADDRESSONE_LABEL,
  ADDRESSONE_NAME,
  ADDRESSTWO_LABEL,
  ADDRESSTWO_NAME,
  CITY_LABEL,
  CITY_NAME,
  COUNTRYID,
  CREDITORCONTACTID,
  CREDITORID,
  EMAIL_LABEL,
  EMAIL_NAME,
  FAX_LABEL,
  FAX_NAME,
  FIRSTN_NAME,
  FIRSTNAME_LABEL,
  LASTN_NAME,
  LASTNAME_LABEL,
  PHONE_LABEL,
  PHONE_NAME,
  POSTAL_LABEL,
  POSTAL_NAME,
  PREFIX_LABEL,
  PREFIX_NAME,
  PROVINCESTATEID,
  RELATIONSHIPTOCREDITOR,
} from "./constant";
import { useForm } from "react-hook-form";
import { EditSummaryFormType } from "./type";
import { useEffect } from "react";
import { useUpdateCreditorContactMutation } from "@/services/creditorContactUpdate/creditorContactUpdateReducer";

export const EditContactFields = ({ getCreditorApiData }: any) => {
  const { handleSubmit, control, setValue } = useForm<EditSummaryFormType>();
  useEffect(() => {
    setValue(FIRSTN_NAME, getCreditorApiData?.firstName);
    setValue(LASTN_NAME, getCreditorApiData?.lastName);
    setValue(ADDRESSONE_NAME, getCreditorApiData?.address1);
    setValue(ADDRESSTWO_NAME, getCreditorApiData?.address2);
    setValue(CITY_NAME, getCreditorApiData?.city);
    setValue(POSTAL_NAME, getCreditorApiData?.postalCode);
    setValue(PHONE_NAME, getCreditorApiData?.phone);
    setValue(EMAIL_NAME, getCreditorApiData?.email);
    setValue(FAX_NAME, getCreditorApiData?.fax);
    setValue(PREFIX_NAME, getCreditorApiData?.prefix);
    setValue(CREDITORCONTACTID, getCreditorApiData?.contactId);
    setValue(CREDITORID, getCreditorApiData?.creditorId);
    setValue(PROVINCESTATEID, getCreditorApiData?.provinceStateId);
    setValue(COUNTRYID, getCreditorApiData?.countryId);
    setValue(
      RELATIONSHIPTOCREDITOR,
      getCreditorApiData?.relationshipToCreditor
    );
  }, [getCreditorApiData, setValue]);

  const [updateCreditorContact] = useUpdateCreditorContactMutation();

  const onSubmit = async (formData: EditSummaryFormType) => {
    updateCreditorContact(formData);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="motif-row">
        <div className="motif-col-md-2">
          <div className="form-field">
            {/* <ControlSelect
              name={PREFIX_NAME}
              @ts-ignore
              control={control}
              required={true}
              showLabel={true}
              readonly={true}
              label={PREFIX_LABEL}
              options={[
                { label: "Mr.", value: "Mr." },
                { label: "Ms.", value: "Ms." },
              ]}
            /> */}
            <ControlInputBox
              name={PREFIX_NAME}
              control={control}
              required={true}
              isTextArea={false}
              label={PREFIX_LABEL}
              showLabel={true}
              readonly={true}
            />
          </div>
        </div>
        <div className="motif-col-md-2">
          <div className="form-field">
            <ControlInputBox
              name={FIRSTN_NAME}
              control={control}
              required={true}
              isTextArea={false}
              label={FIRSTNAME_LABEL}
              showLabel={true}
              readonly={true}
            />
          </div>
        </div>
        <div className="motif-col-md-2">
          <div className="form-field">
            <ControlInputBox
              name={LASTN_NAME}
              control={control}
              required={true}
              isTextArea={false}
              label={LASTNAME_LABEL}
              showLabel={true}
              readonly={true}
            />
          </div>
        </div>
        <div className="motif-col-md-2">
          <div className="form-field">
            <ControlInputBox
              name={ADDRESSONE_NAME}
              control={control}
              required={true}
              isTextArea={false}
              label={ADDRESSONE_LABEL}
              showLabel={true}
              readonly={true}
            />
          </div>
        </div>
      </div>
      <div className="motif-row mar-t-20">
        <div className="motif-col-md-2">
          <div className="form-field">
            <ControlInputBox
              name={ADDRESSTWO_NAME}
              control={control}
              required={false}
              isTextArea={false}
              label={ADDRESSTWO_LABEL}
              showLabel={true}
              readonly={true}
            />
          </div>
        </div>
        <div className="motif-col-md-2">
          <div className="form-field">
            <ControlInputBox
              name={CITY_NAME}
              control={control}
              required={true}
              isTextArea={false}
              label={CITY_LABEL}
              showLabel={true}
              readonly={true}
            />
          </div>
        </div>
        <div className="motif-col-md-2">
          <div className="form-field">
            <ControlInputBox
              name={POSTAL_NAME}
              control={control}
              required={true}
              isTextArea={false}
              label={POSTAL_LABEL}
              showLabel={true}
              readonly={true}
            />
          </div>
        </div>
        <div className="motif-col-md-2">
          <div className="form-field">
            <ControlInputBox
              name={PHONE_NAME}
              control={control}
              required={true}
              isTextArea={false}
              label={PHONE_LABEL}
              showLabel={true}
              readonly={true}
            />
          </div>
        </div>
      </div>
      <div className="motif-row mar-t-20">
        <div className="motif-col-md-2">
          <div className="form-field">
            <ControlInputBox
              name={FAX_NAME}
              control={control}
              required={false}
              isTextArea={false}
              label={FAX_LABEL}
              showLabel={true}
              readonly={true}
            />
          </div>
        </div>
        <div className="motif-col-md-2">
          <div className="form-field">
            <ControlInputBox
              name={EMAIL_NAME}
              control={control}
              required={true}
              isTextArea={false}
              label={EMAIL_LABEL}
              showLabel={true}
              readonly={true}
            />
          </div>
        </div>
      </div>
      {/* <Button
        label="Save"
        variant="primary"
        disabled={!isValid}
        className="motif-button"
        onClickHandler={handleSubmit(onSubmit)}
      /> */}
    </form>
  );
};
