const creditors = {
  creditorTableTitle: "All creditors",
  creditorTableDescription: "Creditors list related to the case",
  creditorDisablePopupDetails: {
    warningMessage: " Are you sure to disable this record: {{creditorName}}?",
    disabledMessage: "Creditor disabled successfully.",
    cannotDeletMessage: "Cannot delete an active creditor.",
  },
  searchOptions: {
    basicSearch: "Basic Search",
    advancedSearch: "Advanced Search",
  },
  moreOptions: {
    bulkStatusUpdate: "Bulk Status Update",
    export: "Export",
    bulkUpload: "Bulk Upload",
  },
  creditorColumnsDetails: {
    name: "Name",
    totalClaims: "Total Claims",
    originalAmount: `Original Amount {{unitName}}`,
    claimAmount: `Claim Amount {{unitName}}`,
    latestCreditorPositionAmount: `Latest Creditor Position Amount {{unitName}}`,
    latestDebtorPositionAmount: `Latest Debtor Position Amount  {{unitName}}`,
    resolvedAmount: `Resolved Amount {{unitName}}`,
  },
  creditorBulkImport: {
    title: "Creditor Creation Upload",
    description: "Download template to create the import file",
    uploadLabel: "Upload Import File",
    uploadDescription:
      "The accepted document format are XLSX, XLS, CSV, PDF, DOC, DOCX, TXT, JPG, JPEG, PNG, GIF, TIFF OR ZIP. The maximum size per file is 250 MB.",
  },
  creditorForm: {
    successfullyUpdatedMessage: "Successfully Updated",
    successfullyCreatedMessage: "Successfully Created",
    creditorNameAlreadyExists: "Creditor Name already exists",
    createFormTitle: "Creditor Setup",
    createFormDescription:
      "Lorem ipsum dolor sit amet consectetur. Praesent sit nulla lacus nullam posuere diam velit eu nulla. Feugiat pretium massa non justo at aliquam semper purus nisl. Ut eget proin hendrerit dignissim neque lacus iaculis convallis non. Arcu eget porta.",
    tabPannelDetails: {
      creditorDetails: "Creditor Details",
      contactDetails: "Contact Details",
      creditorAttributes: "Creditor Attributes",
    },
    formLabelDetails: {
      radiobuttonsLabels: {
        individual: "Individual",
        organization: "Organization",
      },
      requiredFieldsMessages: {
        requiredPrimaryContact: "Primary contact is required",
        firstNameMessage: "First Name is required",
        additionalPayeeTypeMessage: "Additional Payee Type is required",
        lastNameMessage: "Last Name is required",
        businessNameMessage: "Business Name is required",
        mainMessage:
          "Please complete all required fields to continue. {{fieldNames}} cannot be left blank",
        contactDetailsMessage:
          "Please complete all required fields to continue. Contact Details required field cannot be left blank.",
      },
      creditorIdentification: "Creditor Identification",
      creditorId: "Creditor ID",
      prefix: "Prefix",
      primary: "Primary",
      name: "Name",
      displayName: "Display Name",
      creditorType: "Creditor Type",
      firstName: "First Name",
      lastName: "Last Name",
      businessName: "Business Name",
      description: "Description",
      relationshipToDebtor: "Relationship to Debtor",
      checkBoxPrimaryContact: "Set as Primary Contact",
      checkBoxAdditionalPayee: "Use as Additional Payeee",
      additionalPayeeType: "Additional Payee Type",
      email: "Email",
      phoneNumber: "Phone Number",
      fax: "Fax",
      relationshipToCreditor: "Relationship to Creditor",
      relationship: "Relationship",
      country: "Country",
      city: "City",
      address1: "Address 1",
      address2: "Address 2",
      address: "Address",
      payementDetails: "Payment Details",
      postalCode: "Postal Code",
      paymentCheckbox: "Add payment details for this contact",
      provinceState: "Province State",
      primaryContactDetails: "Primary Contact Details",
      otherContactDetails: "Other Contact Details",
      addAnotherContact: "Add Another Contact",
      institutionNumber: "Institution Number",
      transitNumber: "Transit Number",
      accountNumber: "Account Number",
      IBANNumber: "IBAN Number",
      SWIFTCode: "SWIFT Code",
      routingCode: "Routing Code",
      ACHCode: "ACH Code",
      creditorAttributes: "Creditor Attributes",
      attributeConfiguration: "Attribute Configuration",
    },
    reviewTitle: "Review",
    reviewDescription:
      "Lorem ipsum dolor sit amet consectetur. Praesent sit nulla lacus nullam posuere diam velit eu nulla.",
    contactDetailsTitle: "Contact Details",
    contactDetailsDescription:
      "Lorem ipsum dolor sit amet consectetur. Praesent sit nulla lacus nullam posuere diam velit eu nulla.",
    creditorAttributesTitle: "Creditor Attributes",
    creditorAttributesDescription:
      "Lorem ipsum dolor sit amet consectetur. Praesent sit nulla lacus nullam posuere diam velit eu nulla.",
    creditorDetailsTitle: "Creditor Details",
    creditorDetailsDescription:
      "Please select the type of creditor you are filing a claim for.",
  },
  creditorView: {
    payementDetails: "Payment Details",
    additionalPayee: "Additional Payee",
    additionalPayeeType: "Additional Payee Type",
    creditorViewTitle: "Creditor Details",
    creditorViewDescription:
      "Descriptive text that describes your page or content.",
    creditorAttributesTitle: "Creditor Attributes",
    contactDetailsTitle: "Contact Details",
    name: "Name: {{creditorName}}",
    tabPannelDetails: {
      summary: "Summary",
      claims: "Claims",
      attributeHistory: "Attribute History",
    },
  },
  creditorUpdate: {
    tabPannelDetails: {
      summary: "Summary",
      claims: "Claims",
    },
    title: "Creditor details",
    description: "Descriptive text that describes your page or content.",
    creditorAttributesTitle: "Creditor Attributes",
    creditorContactDetailsTitle: "Contact Details",
  },

  creditorDocumentUpload: {
    documentPopup: {
      title: "Upload Documents",
      editTitle: "Edit Documents",
      subtitle:
        "Lorem ipsum dolor sit amet consectetur. Fringilla pulvinar in sed volutpat. Nunc iaculis cras amet condimentum. Bibendum.",
      documentType: "Document Type",
      fileName: "File Display Name",
      comment: "Comment",
      uploadText: "Click to upload or drag and drop",
      uploadDescription:
        "The accepted document format are XLSX, XLS, CSV, PDF, DOC, DOCX, TXT, JPG, JPEG, PNG, GIF, TIFF OR ZIP. The maximum size per file is 250 MB.",
      uploadDescriptionForXlsx:
        "The accepted document format is XLSX only. The maximum size per file is 250 MB.",

      displayName: "Display Name: ",
      upload: "Upload",
      edit: "Edit",
      save: "Save",
      cancel: "Cancel",
      deleteMessage: "Are you sure you want to disable this document?",
    },
    creditorDocumentUploadTitle: "Documents",
    creditorDocumentUploadDescription:
      "Descriptive text that describes your page or content.",
    tableColumnsDetails: {
      type: "Type",
      comment: "Comment",
      createdBy: "Created By",
      receivedDate: "Received Date",
      claimId: "Claim ID",
    },
  },
  creditorSummaryBreadCrums: {
    backToCase: "Back to case",
  },
  creditorSummaryBanner: {
    bannerTitle: "Creditor",
    creditorId: "Creditor ID",
    distributionStatus: "Distribution Status",
    totalClaims: "Total Claims",
  },
  creditorBasicSearch: {
    creditorName: "Creditor Name",
    creditorId: "Creditor ID",
    claimId: "Claim ID",
    debtorName: "Debtor Name",
    country: "Country",
    provinceState: "Province State",
    postalCode: "Postal Code",
    phoneNumber: "Phone Number",
    claimUpdatedDate: "Claim Updated Date",
  },
  creditorClaimTab: {
    tableTitle: "Claims",
    tableDescription: "Descriptive text that describes your page or content.",
    moreOptions: {
      bulkUpload: "Bulk Upload",
      addNewClaim: "Add New Claim",
      bulkStatusUpdate: "Bulk Status Update",
      export: "Export",
    },
    tableColumnsDetails: {
      claimId: "Claim ID",
      assignee: "Assignee",
      currentAmount: "Current Amount {{unitName}}",
      Stage: "Stage",
      actionStatus: "Action status",
      lastUpdated: "Last updated",
    },
  },
  creditorAttributeTab: {
    tableTitle: "Attribute History",
    tableDescription: "Descriptive text that describes your page or content.",
    tableColumnsDetails: {
      attributeName: "Attribute Name",

      updatedOn: "Updated On",

      updatedBy: "Updated By",

      oldValue: "Old Value",

      newValue: "New Value",
    },
  },
};

export default creditors;
