import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "@/index.scss";
import Header from "@/components/Layouts/Header/Header";
import NavBar from "@/components/Layouts/NavBar/NavBar";
import Main from "@/components/Layouts/Main/Main";
import AppMsalProvider from "@/features/auth/msal-provider";
import config from "@/config";
import AuthRouter from "@/features/auth/auth-router";
import { useLocation } from "react-router-dom";
import ErrorBoundary from "@/pages/ErrorBoundary/ErrorBoundary";
import GlobalErrorPopup from "@/features/errors/GlobalErrorPopup";

const { features } = config;

function App() {
  const [collapse, setCollapse] = useState(false);
  const location = useLocation();
  const user = useSelector((state: any) => state.user);
  const showAvatar =
    !!user?.accessToken || !location.pathname.includes("logout");

  useEffect(() => {
    document.body.classList.toggle("sidebar-collapsed", collapse);
    return () => {
      document.body.classList.remove("sidebar-collapsed");
    };
  }, [collapse]);

  useEffect(() => {
    const originalConsoleError = console.error;

    console.error = (...args: any[]) => {
      if (typeof args[0] === "string" && /defaultProps/.test(args[0])) {
        return;
      }

      if (
        typeof args[0] === "string" &&
        /Did you mean to use React.forwardRef()/.test(args[0])
      ) {
        return;
      }
      originalConsoleError(...args);
    };

    return () => {
      console.error = originalConsoleError;
    };
  }, []);

  if (features.sso) {
    return (
      <AppMsalProvider>
        <div className="motif-theme-light">
          <GlobalErrorPopup />
          <Header
            toggleCollapse={() => setCollapse(!collapse)}
            showAvatar={showAvatar}
          />

          {user &&
            user.accessToken &&
            !location.pathname.includes("logout") && (
              <NavBar
                toggleCollapse={() => setCollapse(!collapse)}
                collapse={collapse}
              />
            )}
          <ErrorBoundary>
            <AuthRouter>
              <Main />
            </AuthRouter>
          </ErrorBoundary>
        </div>
      </AppMsalProvider>
    );
  }
  return (
    <div className="motif-theme-light">
      <Header
        toggleCollapse={() => setCollapse(!collapse)}
        showAvatar={false}
      />
      <NavBar
        toggleCollapse={() => setCollapse(!collapse)}
        collapse={collapse}
      />
      <Main />
    </div>
  );
}

export default App;
