import TabPanel from '@/components/TabPanel/TabPanel';
import ClaimTabContent from '@/features/creditor/claimsTab/claimsTabContent';
import CreditorSummaryHeader from './CreditorSummaryHeader';
import { useParams } from 'react-router-dom';
import CreditiorUpdate from './CreditiorUpdate';
import { useCase } from '@/features/case/use-case';

export default function CreditorSummaryUpdate() {
    const caseData = useCase();
    const parameter = useParams();
      const tabPanelData = {
        title: [
        "pages.creditors.creditorUpdate.tabPannelDetails.summary",
         "pages.creditors.creditorUpdate.tabPannelDetails.claims"
        ],
        content: [
          <CreditiorUpdate/>, 
          <ClaimTabContent/>, 
        ]
    
      };
      const propData = {
          caseId: caseData.caseId,
          creditorId: parameter.creditorId || ""
      }
  return (
    <>
      
      <CreditorSummaryHeader caseId={propData.caseId} creditorId={propData.creditorId || ''}/>
      <div className='ccwp_table_wrapper ccwp_table_dropdown_wrapper'><TabPanel data={tabPanelData} /></div>   
    </>
  )
}
