import { MotifIcon } from "@ey-xd/motif-react";
import {
  IconoirArrowUp,
  IconoirArrowDown,
  IconoirArrowSeparateVertical,
} from "@ey-xd/motif-icon";

export const customPaginationPageSize: number = 10;
export const customPaginationPageSizeSelector: number[] = [10, 20, 50];
export const customTableLoadingData =
  "Loading Data";

export const CustomHeader = function ({
  props,
  handlePaginationData,
  columnOrderStatus,
  paginationObj,
}: any) {
  const { displayName } = props;
  const onSortRequested = (order: any, colName: any) => {
    let json: any = {};
    json[colName] = order;
    let paginationJson: any = { ...paginationObj };
    paginationJson.SortOrder = order;
    paginationJson.SortColumn =
      colName?.charAt(0)?.toUpperCase() + colName?.slice(1);
    if (order === "none") {
      delete paginationJson.SortOrder;
      delete paginationJson.SortColumn;
    }
    handlePaginationData(paginationJson, json);
  };
  return (
    <div className="server-side-sorting custom-header">
      <>
        {columnOrderStatus?.[props?.column?.colId] === "asc" ? (
          <span
            className="sortIconDesc"
            onClick={() => {
              onSortRequested("desc", props?.column?.colId);
            }}
          >
            {displayName}
            <MotifIcon iconFunction={IconoirArrowUp} size="13" />
          </span>
        ) : columnOrderStatus?.[props?.column?.colId] === "desc" ? (
          <span
            className="sortIconAsc"
            onClick={() => {
              onSortRequested("none", props?.column?.colId);
            }}
          >
            {displayName}
            <MotifIcon iconFunction={IconoirArrowDown} size="13" />
          </span>
        ) : (
          <span
            className="sortIconAsc"
            onClick={() => {
              onSortRequested("asc", props?.column?.colId);
            }}
          >
            {displayName}
            <MotifIcon iconFunction={IconoirArrowSeparateVertical} size="13" />
          </span>
        )}
        {/* <div
            className={
                columnOrderStatus?.[props?.column?.colId] === "asc"
                ? "sortAsc sort-buttons"
                : columnOrderStatus?.[props?.column?.colId] === "desc"
                ? "sortDsc sort-buttons"
                : "sortDsc sort-buttons"
            }
          >
            <span
              className="sortIconDesc"
              onClick={() => {onSortRequested("desc", props?.column?.colId,columnOrderStatus)}}
            >
              {displayName}
              <MotifIcon iconFunction={IconoirArrowUp} size="13" />
            </span>
            <span
              className="sortIconAsc"
              onClick={() => {onSortRequested("asc", props?.column?.colId,columnOrderStatus)}}
            >
              {displayName}
              <MotifIcon iconFunction={IconoirArrowDown} size="13" />
            </span>
          </div> */}
      </>
    </div>
  );
};
