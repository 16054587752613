import React from "react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import InputBox from "./InputBox";
import './InputBox.scss';
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { STATUS_FIELD } from "@/Constant";
import { CASE_DESCRIPTION_ID, CASE_DESCRIPTION_ID_F, CASE_ID, CASE_ID_F, CASE_NAME, CASE_SHORT_ID } from "@/pages/CaseDetail/Summary/SummaryForm/Constant";
import { ClaimDetailsType } from "@/pages/CaseDetail/Claim/ClaimDetails/type";

type ControlInputBoxType = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<any>;
  name:
    | string
    | typeof CASE_ID
    | typeof CASE_ID_F
    | typeof CASE_DESCRIPTION_ID
    | typeof CASE_DESCRIPTION_ID_F
    | typeof CASE_SHORT_ID
    | typeof STATUS_FIELD;
  showLabel: boolean;
  label: string;
  readonly?: boolean;
  defaultValue?: string;
  setValue?: UseFormSetValue<ClaimDetailsType>;
  maxLength?: string;
  onChange?: (e: any) => void;
};

export default function ControlInputBox({
  name,
  control,
  required = false,
  isTextArea = false,
  showLabel,
  label,
  readonly = false,
  defaultValue,
  setValue,
  maxLength,
  onChange,
  ...inputArgs
}: ControlInputBoxType) {
  const { field } = useController({
    name,
    control,
    rules: { required: required },
  });

  const { t } = useTranslation();

  return (
    <>
      {showLabel && (
        <div className="formFieldLabel">
          <label htmlFor="input" aria-label={label}>
            {t(label)}
            {required ? <span className="requiredSymbol">*</span> : ""}
          </label>
        </div>
      )}
      <Controller
        name={field.name}
        control={control}
        rules={{ required }}
        render={({ field: { onChange: onFieldChange, value, ...args } }) => {
          return (
            <>
              <InputBox
                id={CASE_NAME}
                required={true}
                value={value || ""}
                onChange={(e) => {
                  onFieldChange(e);
                  if (onChange) {
                    onChange(e);
                  }
                }}
                isTextArea={isTextArea}
                readonly={readonly}
                maxLength={maxLength}
                {...args}
                {...inputArgs}
              />
            </>
          );
        }}
      />
    </>
  );
}
