import { useState, useCallback, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MotifButton, MotifIcon } from "@ey-xd/motif-react";
import { IconoirNavArrowDown, IconoirNavArrowUp } from "@ey-xd/motif-icon";
import {
	customPaginationPageSize,
	customPaginationPageSizeSelector,
	customTableLoadingData,
} from "@/components/Table/CustomPaginationConstants";
import Table from "@/components/Table/table";
import TitleComplement from "./statusTable/TitleComplement";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { getPaymentStatusColumnDefs } from "./statusTable/PaymentStatusColumnDefinations";
import {
	useGetPaymentStatusListQuery,
	useLazyGetPaymentFilterQuery,
} from "@/services/paymentStatus/paymentStatusReducer";
import { useGetCalculationStepDataQuery } from "@/features/distributions/steps/calculationStepReducer";
import { Filter } from "./Filter";
import { FormValues } from "./type";
import "./PaymentStatus.css";
import DeletePayment from "./DeletePayment";
import NotificationGrid from "@/components/Notification/NotificationGrid";

const PaymentStatus = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { caseId } = useParams();
	const [showFilter, setShowFilter] = useState(false);
	const [isFilterActive, setIsFilterActive] = useState(false);
	const [filterData, setFilterData] = useState<any>(null);
	const [filterPayload, setFilterPayload] = useState<any>({});
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
	const [paymentId, setPaymentId] = useState("");
	let tableLoadingData = customTableLoadingData;
	const paginationPageSize: number = customPaginationPageSize;
	const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
	const [paginationObj, setPaginationObj] = useState<any>({
		CaseId: caseId,
		PageNumber: 1,
		PageSize: paginationPageSize,
		SearchText: "",
	});
	const [selectedPaymentIds, setSelectedPaymentIds] = useState<
		{ paymentId: string }[]
	>([]);
	let [sortedColumnTypes, setSortedColumnTypes] = useState<any>({});
	const [allPaymentSelected, setAllPaymentSelected] = useState(false);
	const {
		data: paymentStatusData,
		isLoading: isPaymentListLoading,
		error: PaymentListError,
		refetch: refetchPaymentList,
	} = useGetPaymentStatusListQuery(paginationObj, { skip: isFilterActive });

	function handlePaginationData(data: any, columnTypesData: any) {
		setPaginationObj({ ...data });
		setSortedColumnTypes({ ...columnTypesData });
	}
	let resetSortStatus = useCallback(() => {
		let json: any = { ...sortedColumnTypes };
		for (const key in json) {
			json[key] = { sort: null };
		}
		setSortedColumnTypes({ ...json });
	}, [sortedColumnTypes]);
	const [
		triggerPaymentFilter,
		{ data: paymentFilterData, isFetching: filterFetching, error: filterError },
	] = useLazyGetPaymentFilterQuery();

	const { data: accountData } = useGetCalculationStepDataQuery({
		caseId: caseId,
	});
	const accountList =
		(accountData &&
			accountData?.map((item: any) => ({
				name: item.accountName || item.name,
				id: item.accountId || item.id,
			}))) ||
		[];
	const [formValues, setFormValues] = useState<FormValues>({
		payeeName: "",
		accountName: "",
		prefix: "",
		paymentReferenceStartWith: "",
		paymentReferenceEndWith: "",
		issueStartDate: undefined,
		issueEndDate: undefined,
		lowerBound: "",
		upperBound: "",
		postedDateStart: undefined,
		postedDateEnd: undefined,
		paymentStatus: "",
	});
	const tableDataFetching = isPaymentListLoading || filterFetching;
	const tableDataError = PaymentListError || filterError;
	const tableDataHasRecords = isFilterActive
		? paymentFilterData?.hasMoreRecords
		: paymentStatusData?.hasMoreRecords;
	const tableData = isFilterActive
		? filterData?.results
		: paymentStatusData?.results;

	useEffect(() => {
		setSelectedPaymentIds([]);
		if (isFilterActive) {
			const pageData = {
				...paginationObj,
				PageNumber: paginationObj.PageNumber,
			};
			triggerPaymentFilter({ ...filterPayload, ...pageData })
				.then((response) => {
					setFilterData(response.data);
				})
				.catch((error) => {
					console.error("filter", error);
				});
		} else {
			refetchPaymentList();
		}
	}, [
		paginationObj,
		filterPayload,
		refetchPaymentList,
		isFilterActive,
		triggerPaymentFilter,
	]);

	const clearSelectedPayemntIds = () => {
		setSelectedPaymentIds([]);
	};
	const refreshAfterPaymentUpdate = () => {
		resetSortStatus();
		setPaginationObj({
			CaseId: caseId,
			PageNumber: 1,
			PageSize: paginationPageSize,
			SearchText: "",
		});
	};
	const onRowSelected = (selectedRows: any) => {
		const selectedNode = selectedRows.node;
		const selectedData = selectedNode.data;
		const isSelected = selectedNode.isSelected();
		const { paymentId, paymentStatus } = selectedData;
		setSelectedPaymentIds((prevSelectedIds) =>
			isSelected
				? [...prevSelectedIds, { paymentId, paymentStatus }]
				: prevSelectedIds.filter((item) => item.paymentId !== paymentId)
		);
	};
	const handleFilter = useCallback(
		(searchParams: any) => {
			setFilterPayload(searchParams);
			setPaginationObj({
				...paginationObj,
				PageNumber: 1,
			});
			resetSortStatus();
			setIsFilterActive(true);
		},
		[paginationObj, resetSortStatus]
	);
	const clearFilter = () => {
		setFormValues((prevValues: any) => ({
			...prevValues,
			payeeName: "",
			accountName: "",
			prefix: "",
			paymentReferenceStartWith: "",
			paymentReferenceEndWith: "",
			issueStartDate: undefined,
			issueEndDate: undefined,
			lowerBound: "",
			upperBound: "",
			postedDateStart: undefined,
			postedDateEnd: undefined,
			paymentStatus: "",
		}));
		setFilterData(null);
		setIsFilterActive(false);
		setPaginationObj({
			...paginationObj,
			PageNumber: 1,
		});
		resetSortStatus();
	};
	const handleCellClick = (params: any) => {
		const paymentReference = params.data?.paymentReference;
		const amount = params.data?.amount;
		const accountName = params.data?.accountName;
		const subAccountName = params.data?.subAccountName;
		navigate(`/case/${caseId}/payment/${params.data?.paymentId}`, {
			state: { paymentReference, accountName, amount, subAccountName },
		});
	};
	const handleDeleteEvent = (paymentId: string) => {
		setShowDeletePopup(true);
		setPaymentId(paymentId);
	};
	const handleAllPaymentSelected = (isSelected: boolean) => {
		setAllPaymentSelected(isSelected);
	};
	const onSelectionChanged = (event: any) => {
		const allSelected =
			event.api.getSelectedNodes().length === event.api.getDisplayedRowCount();
		handleAllPaymentSelected(allSelected);
	};
	return (
		<div className="payment-status-container">
			<div className="mar-b-20">
				<div className="motif-table-title-bar ">
					<div className="motif-table-info">
						<span className="motif-table-title">
							{t("pages.paymentStatus.filterForm.title")}
						</span>
						<span className="motif-table-description">
							{t("pages.paymentStatus.filterForm.description")}
						</span>
					</div>
					<MotifButton
						type="button"
						onClick={() => {
							setShowFilter((prev) => !prev);
						}}
						variant="secondary"
						size="small"
					>
						{t("pages.paymentStatus.filterForm.filterButton")}
						<MotifIcon
							aria-label="Dropdown icon"
							fill="none"
							iconFunction={
								showFilter ? IconoirNavArrowUp : IconoirNavArrowDown
							}
						/>
					</MotifButton>
				</div>
				{showFilter && (
					<Filter
						accountList={accountList}
						setShowFilter={setShowFilter}
						onApply={handleFilter}
						formValues={formValues}
						setFormValues={setFormValues}
						clearFilter={clearFilter}
					/>
				)}
			</div>
			{deleteErrorMessage && (
				<div className="mar-t-20">
					<NotificationGrid
						show={true}
						message={deleteErrorMessage}
						type={"error"}
					/>
				</div>
			)}
			<Table
				columnDefs={getPaymentStatusColumnDefs(
					handleCellClick,
					handleDeleteEvent
				)}
				paginationObj={paginationObj}
				columnTypes={{ ...sortedColumnTypes }}
				handlePaginationData={handlePaginationData}
				cellRenderers={tableDataFetching ? [] : tableData ? tableData : []}
				TitleComplement={() => (
					<TitleComplement
						selectedPaymentIds={selectedPaymentIds}
						clearSelectedPayemntIds={clearSelectedPayemntIds}
						refreshAfterPaymentUpdate={refreshAfterPaymentUpdate}
						clearFilter={clearFilter}
						isFilterActive={isFilterActive}
						allPaymentSelected={allPaymentSelected}
						filterPayload={filterPayload}
					/>
				)}
				title={t("pages.paymentStatus.statusGridTitle")}
				description={t("pages.paymentStatus.statusGridDescription")}
				overlayNoRowsTemplate={
					tableDataFetching
						? tableLoadingData
						: tableDataError
						? t("pages.paymentStatus.somethingWentWrong")
						: t("pages.paymentStatus.noRecord")
				}
				rowSelection="multiple"
				onRowSelected={onRowSelected}
				pagination={false}
				onSelectionChanged={onSelectionChanged}
			/>
			<CustomPagination
				currentpage={paginationObj.PageNumber}
				pageSize={paginationObj.PageSize}
				disableNext={!tableDataHasRecords}
				onChangePageSize={(page: number) => {
					setPaginationObj({
						...paginationObj,
						PageSize: page,
						PageNumber: 1,
					});
				}}
				onChangeNextPage={() => {
					clearSelectedPayemntIds();
					setPaginationObj({
						...paginationObj,
						PageNumber: paginationObj.PageNumber + 1,
					});
				}}
				onChangePreviousPage={() => {
					clearSelectedPayemntIds();
					setPaginationObj({
						...paginationObj,
						PageNumber: paginationObj.PageNumber - 1,
					});
				}}
				pageSizeSelector={paginationPageSizeSelector}
			/>
			{showDeletePopup && (
				<DeletePayment
					show={showDeletePopup}
					setShow={setShowDeletePopup}
					id={paymentId}
					setDeleteErrorMessage={setDeleteErrorMessage}
					refetchPaymentList={refreshAfterPaymentUpdate}
				/>
			)}
		</div>
	);
};
export default PaymentStatus;
