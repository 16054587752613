import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputBox from "@/components/Form/InputBox/InputBox";
import { CreateSchemeFormType } from "./type";
import {
	SCHEME_NAME,
	SCHEME_DESC_FR_NAME,
	SCHEME_DESC_NAME,
	SCHEME_NAME_FR,
} from "./Constants";

type ControlInputBoxType = {
	required?: boolean;
	isTextArea?: boolean;
	control: Control<CreateSchemeFormType>;
	name:
		| typeof SCHEME_NAME
		| typeof SCHEME_NAME_FR
		| typeof SCHEME_DESC_NAME
		| typeof SCHEME_DESC_FR_NAME;
	showLabel: boolean;
	label: string;
	value: string;
};

export default function ControlInputBox({
	name,
	control,
	required = false,
	isTextArea = false,
	showLabel,
	label,
}: ControlInputBoxType) {
	const { field } = useController({
		name,
		control,
		rules: { required: required },
	});
	const { t } = useTranslation();

	return (
		<>
			{showLabel && (
				<div className="formFieldLabel">
					<label htmlFor="input" aria-label={label}>
						{t(label)}
						{required ? <span className="requiredSymbol">*</span> : ""}
					</label>
				</div>
			)}
			<Controller
				name={field.name}
				control={control}
				rules={{ required }}
				render={({ field: { onChange, value, ...args } }) => {
					return (
						<>
							<InputBox
								id={name}
								required={true}
								onChange={onChange}
								value={value || ""}
								isTextArea={isTextArea}
								{...args}
							/>
						</>
					);
				}}
			/>
		</>
	);
}
