import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	MotifModal,
	MotifModalHeader,
	MotifModalBody,
	MotifModalFooter,
	MotifButton,
} from "@ey-xd/motif-react";
import { useLazyDeletePaymentQuery } from "@/services/paymentStatus/paymentStatusReducer";
interface DeletePaymentProps {
	show: boolean;
	setShow: (value: boolean) => void;
	id: string | undefined;
	setDeleteErrorMessage: (message: string) => void;
	refetchPaymentList: () => void;
}

const DeletePayment = ({
	show,
	setShow,
	id,
	setDeleteErrorMessage,
	refetchPaymentList,
}: DeletePaymentProps) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [deletePayment] = useLazyDeletePaymentQuery();

	const handleCancel = () => {
		setShow(false);
		setDeleteErrorMessage("");
	};

	const handleDelete = () => {
		setIsLoading(true);
		setDeleteErrorMessage("");
		deletePayment(id).then((response: any) => {
			if (response.data) {
				refetchPaymentList();
			} else {
				setDeleteErrorMessage(response?.error?.data?.message);
			}
			setIsLoading(false);
			setShow(false);
		});
	};
	return (
		<MotifModal
			onClose={handleCancel}
			show={show}
			focusTrapOptions={{
				tabbableOptions: {
					displayCheck: "none",
				},
			}}
		>
			<MotifModalHeader>
				{t("pages.paymentStatus.deletePayment")}
			</MotifModalHeader>

			<MotifModalBody>
				{t("pages.paymentStatus.deletePaymentMessage")}
			</MotifModalBody>

			<MotifModalFooter>
				<MotifButton onClick={handleCancel} type={"button"} variant="secondary">
					{t("pages.paymentStatus.filterForm.cancel")}
				</MotifButton>
				<MotifButton
					onClick={handleDelete}
					type={isLoading ? "loader" : "submit"}
					variant="primary"
					disabled={isLoading}
				>
					{t("pages.paymentStatus.confirm")}
				</MotifButton>
			</MotifModalFooter>
		</MotifModal>
	);
};
export default DeletePayment;
