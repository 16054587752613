import React, { useEffect } from "react";
import { MotifSelect, MotifFormField,  MotifOption} from '@ey-xd/motif-react';
import {useTranslation} from 'react-i18next';

type SelectBoxType = {
    options: {label : string, value: string}[]; 
    value: string;
    onChange: (val: string) => void;
    onChangeHandlerCountry?: (index:number, countryId: string) => void;
    index?: number;
    onChangeHandlerState?: (index:number, stateId: string, stateOptions:{label:string, value: string}[]) => void;
    filter?: boolean | ((x?: any) => void) | undefined;
    searchPlaceholder? : string;
}

export default function SelectBox ({ options, value, onChange, onChangeHandlerCountry, onChangeHandlerState, index, filter, searchPlaceholder}: SelectBoxType) { 
    const {t} = useTranslation();      
    const placeHolderText = searchPlaceholder || t("global.globalActions.optionDefault");
    const onChangeHandlerEvent = (val : string) => {
        onChangeHandlerCountry && onChangeHandlerCountry(index || 0, val);
        onChangeHandlerState && onChangeHandlerState(index || 0, val, options);
        onChange(val);
    }
    useEffect(() => {
        const btn = document.querySelectorAll('.motif-select-input') as NodeListOf<Element>;
        btn.forEach((element: Element) => {
            (element as HTMLElement).setAttribute('type', 'button');
        });
    },[])
    return (
        <> 
            <MotifFormField role="group"> 
                {/*@ts-ignore */}
                <MotifFormField>
                    <MotifSelect
                        ariaLabelledBy="select-label"
                        onChange={onChangeHandlerEvent}
                        value={value}
                        placeholder={placeHolderText}
                        filter={filter || false}
                    >
                        {
                            options.map( option => (
                                <MotifOption isSelected={option.value === value} key={option.value} value={option.value}>{option.label}</MotifOption>
                            ))
                        }
                    </MotifSelect>
                </MotifFormField> 
            </MotifFormField>
        </>
        
    );
}