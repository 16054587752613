import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
	MotifIcon,
	MotifErrorMessage,
	MotifButton,
	MotifProgressLoader,
} from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Button from "@/components/Form/Button/Button";
import Banner from "@/components/Banner/Banner";
import Table from "@/components/Table/table";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { dateFunc } from "@/components/Functions/dateFunc";
import ControlInputBox from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import {
	useGetManualDataSourceSummaryQuery,
	useUpdateManualSourceDataMutation,
	useGetListManualDataSourceUsageQuery,
	useLazyDownloadManualSourceDocumentQuery
} from "@/services/manualDataSource/ManualDataSourceReducer";

import {
	SOURCE_NAME,
	SOURCE_NAME_FR,
	SOURCE_COMMENT,
} from "./Constants";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

const ManualDataSourceEdit = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { caseId, manualDataSourceId } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [updateManualSourceData] = useUpdateManualSourceDataMutation();
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [isDiscardVisible, setIsDiscardVisible] = useState(false);
    const [downloadManualSourceDocument] = useLazyDownloadManualSourceDocumentQuery();

	const {
		data,
		isLoading: viewSourceLoading,
		error: viewError,
		refetch,
	} = useGetManualDataSourceSummaryQuery(manualDataSourceId ?? "");

	const {
		data: listUsageData,
		isLoading: viewlistUsageDataLoading,
		error: listUsgaeError,
	} = useGetListManualDataSourceUsageQuery(manualDataSourceId ?? "");

	const breadcrumbItems = [
		{
			label: "Back",
			elementKey: "Back",
			text: t("global.globalActions.back"),
			url: `/case/${caseId}/?activeTab=6`,
			icon: <MotifIcon src={IconoirArrowLeft} />,
		},
	];
	const bannerElements: { label: string; value: string }[] = [
		{
			label: "pages.distributions.manualDataSource.bannerDetails.label1",
			value: data?.totalRecords.toString(),
		},
		{
			label: "pages.distributions.manualDataSource.bannerDetails.label2",
			value: dateFunc(data?.updatedOn).formattedDateOnly,
		},
	];

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors,isDirty },
	} = useForm({
		defaultValues: {
			id: "",
			name: "",
			nameFrench: "",
			comment: "",
		},
	});
	const dispatch=useDispatch()
	useEffect(() => {
	  dispatch(setDiscardEditMode({ editMode: isDirty }));
	  return () => {
		dispatch(setDiscardEditMode({ editMode: false }));
	  };
	}, [dispatch,isDirty]);
	useEffect(() => {
		reset({
			"id": data?.id,
			"name": data?.name || "",
			"nameFrench": data?.nameFrench || "",
			"comment": data?.comment || "",
				})
	}, [data, reset]);

    const handleDownload = () => {
        downloadManualSourceDocument({
            manualDataSourceRecordId: data?.id,
        });
    };

	const onSubmit = async (data: any) => {
		const requestBody = {
			...data,
		};
		setErrorMessage("");
		setSuccessMessage("");
		setIsLoading(true);
		try {
			await updateManualSourceData(requestBody).then((response: any) => {
				if (response.data) {
					refetch();
					setSuccessMessage("pages.distributions.manualDataSource.editManualSource.sucessMessage");
				} else {
					setErrorMessage(response.error.data.message);
				}
				setIsLoading(false);
			});
		} catch {
			setErrorMessage(
				"pages.distributions.manualDataSource.editManualSource.errorMessage"
			);
			setIsLoading(false);
		}
	};

	const columnDefs = [
		{
			field: "distributionName",
			headerName: t("pages.distributions.manualDataSource.editManualSource.columnDetails.column1"),
			flex: 2,
			unSortIcon: true,
		},
		{
			field: "calculationStepName",
			headerName: t("pages.distributions.manualDataSource.editManualSource.columnDetails.column2"),
			flex: 2,
		},
	];

	const discardCancelBtnHandler = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
		setIsDiscardVisible(false);
		dispatch(setDiscardEditMode({ editMode: false }));

	};

	const discardConfirmBtnHandler = (
		event: any
	) => {
		event.preventDefault();
		navigate(`/case/${caseId}/?activeTab=6`);
		setIsDiscardVisible(false);
	};

	return (
		<>
			<div>
				<Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
			</div>
			<Banner
				bannerName={"pages.distributions.manualDataSource.bannerDetails.title"}
				bannerNameValue={data?.name}
				elements={bannerElements}
			/>
			<div className="mar-t-20">
				{errorMessage && (
					<NotificationGrid show={true} message={errorMessage} type="error" />
				)}
				{successMessage && (
					<NotificationGrid
						show={true}
						message={successMessage}
						type="success"
					/>
				)}
			</div>
			<div className="mar-b-20">
				<div className="motif-table-title-bar ">
					<div className="motif-table-info">
						<span className="motif-table-title">
							{t("pages.distributions.manualDataSource.editManualSource.title")}
						</span>
						<span className="motif-table-description">
						{t("pages.distributions.manualDataSource.editManualSource.description")}
						</span>
					</div>
					<MotifButton
						type="button"
						variant="secondary"
						className="motif-button btn"
						onClick={handleDownload}
					>
						{t("pages.distributions.manualDataSource.editManualSource.download")}
						</MotifButton>
				</div>
				{viewSourceLoading ? (
					<MotifProgressLoader show variant="default" />
				) : viewError ? (
					<MotifErrorMessage>{t("global.globalNotifications.wentWrong")}</MotifErrorMessage>
				) : (
					<div className="claims-basic-search-container">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="motif-row pad-t-20 pad-l-10">
								<div className="motif-col-md-3">
									<ControlInputBox
										name={SOURCE_NAME}
										control={control}
										required={true}
										isTextArea={false}
										label={"pages.distributions.manualDataSource.editManualSource.name"}
										showLabel={true}
									/>
									{errors[SOURCE_NAME] &&
										errors[SOURCE_NAME].type === "required" && (
											<MotifErrorMessage>
{t("pages.distributions.manualDataSource.editManualSource.nameReq")}												</MotifErrorMessage>
										)}
								</div>
								<div className="motif-col-md-3">
									<ControlInputBox
										name={SOURCE_NAME_FR}
										control={control}
										required={false}
										isTextArea={false}
										label={"pages.distributions.manualDataSource.editManualSource.nameFrench"}
										showLabel={true}
									/>
								</div>
							</div>
							<div className="motif-row pad-t-20 pad-l-10">
								<div className="motif-col-md-3">
									<ControlTextAreaWithCharLimit
										name={SOURCE_COMMENT}
										control={control}
										isTextArea={true}
										label={"pages.distributions.manualDataSource.editManualSource.comment"}
										showLabel={true}
									/>
								</div>
							</div>
							<div className="basic-search-buttons">
								<MotifButton
									type="button"
									variant="secondary"
									className="motif-button btn"
									onClick={(e) => {
										if(isDirty){
											setIsDiscardVisible(true)
											dispatch(setDiscardEditMode({ editMode: false }));

										}
										else{
											discardConfirmBtnHandler(e)
										}
									}}
								>
									{t("global.globalActions.cancel")}
								</MotifButton>
								<Button
									label={t("global.globalActions.save")}
									variant="primary"
									className="motif-button"
									type={isLoading ? "loader" : "submit"}
								/>
							</div>
						</form>
					</div>
				)}
			</div>
			<ConfirmationModal
				isVisible={isDiscardVisible}
				setIsVisible={setIsDiscardVisible}
				cancelBtnApproveHandler={discardConfirmBtnHandler}
				cancelBtnRejectHandler={discardCancelBtnHandler}
			/>
			<div className="mar-t-20">
				{viewlistUsageDataLoading ? (
					<MotifProgressLoader show variant="default" className="mar-t-20" />
				) : listUsgaeError ? (
					<MotifErrorMessage>{t("global.globalNotifications.wentWrong")}</MotifErrorMessage>
				) : (
					<Table
						columnDefs={columnDefs}
						cellRenderers={listUsageData}
						TitleComplement={() => <></>}
						title={"pages.distributions.manualDataSource.editManualSource.usage"}
						description={
							"pages.distributions.manualDataSource.editManualSource.usageDescription"
						}
						caseRenderers={<></>}
						pagination={false}
						overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
					/>
				)}
			</div>
		</>
	);
};
export default ManualDataSourceEdit;
