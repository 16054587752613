import { MotifButton } from "@ey-xd/motif-react";
import FormModalCalculationSetting from "./FormModal/FormModalCalculationSetting";
import { useState } from "react";
import { useGetCalculationStepApiDataQuery } from "./calculationStepReducer";
import { useTranslation } from "react-i18next";

export const CalculationSetting = ({
  caseId,
  stepId,
  distributionStatus,
}: any) => {
  const [createShow, setCreateShow] = useState(false);
  const [editId, setEditId] = useState("");
  const { data, refetch } = useGetCalculationStepApiDataQuery(stepId, {
    skip: !stepId,
  });
  const {t}=useTranslation()

  return (
    <>
      <div className="motif-container case-summary-form pad-v-20 mar-t-20">
        <div className="motif-row justify-content-between align-items-center">
          <div className="motif-col-4">
            <div className="motif-body1-default-regular page-title">
             {t("pages.distributions.distributionStepsTab.cap_Floor_AmountTab.title")}
            </div>
            <div className="motif-body2-default-regular page-description">
            {t("pages.distributions.distributionStepsTab.cap_Floor_AmountTab.description")}
            </div>
          </div>
          <div className="motif-col-4">
            {data?.length > 0 && Array.isArray(data) ? (
              <MotifButton
                onClick={() => {
                  setCreateShow(true);
                  setEditId(data[0]?.id);
                }}
                size="small"
                type="submit"
                variant="secondary"
                disabled={distributionStatus}
              >
                {t("global.globalActions.edit")}
              </MotifButton>
            ) : (
              <MotifButton
                onClick={() => {
                  setCreateShow(true);
                }}
                size="small"
                type="submit"
                variant="secondary"
              >
                {t("global.globalActions.edit")}
              </MotifButton>
            )}
          </div>
        </div>
      </div>
      <div
        className={
          createShow ? "ccwp_createshow_wrapper" : "ccwp_createhide_wrapper"
        }
      >
        {createShow ? (
          <FormModalCalculationSetting
            createShow={createShow}
            setCreateShow={setCreateShow}
            caseId={caseId}
            stepId={stepId}
            refetch={refetch}
            editId={editId}
          />
        ) : (
          <div className="motif-row ">
            <div className="motif-col-lg-4">
              <div>
                <div className="title">{t("pages.distributions.distributionStepsTab.cap_Floor_AmountTab.formLabels.capAmount")}
                </div>
                <div className="motif-body2-default-bold">
                  {(data && data[0]?.capAmount) || "-"}
                </div>
              </div>
            </div>
            <div className="motif-col-lg-4">
              <div>
                <div className="title">
                {t("pages.distributions.distributionStepsTab.cap_Floor_AmountTab.formLabels.floorAmount")}            
                      </div>
                <div className="motif-body2-default-bold">
                  {(data && data[0]?.floorAmount) || "-"}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
