import { ccwpApi } from "@/services";

export const ReportsApi=ccwpApi.injectEndpoints({
    endpoints:(builder)=>({
        getReportsList: builder.query<any,Partial<any>>({
            query: (arags) => ({
              url:  "/Report/GetReportsList",
              method: "GET",
              params:{...arags},
            }),
        }),
        downloadPaymentDocument: builder.query({
            query: ({ CaseId, fileName = "downloaded_file" }) => ({
              url: "/Payment/ExportPaymentRegister", 
              method: "GET",
              responseHandler: async (response) => {
                const url = URL.createObjectURL(await response.blob());
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                const downloadControls =
                  document.getElementsByClassName("motif-modal-content")[0] ||
                  document.body;
                downloadControls.appendChild(a);
                a.click();
                downloadControls.removeChild(a);
                URL.revokeObjectURL(url);
              },
              params: { CaseId },
              cache: "no-cache",
            }),
          }),
    })
})

export const{useGetReportsListQuery, useLazyDownloadPaymentDocumentQuery}=ReportsApi
