import Banner, { BannerElement } from "@/components/Banner/Banner";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { MotifIcon } from "@ey-xd/motif-react";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import TabPanel from "@/components/TabPanel/TabPanel";
import { useParams } from "react-router-dom";
import { useGetDistributionSummaryByIdQuery } from "@/services/distribution/distributionReducers";
import Summary from "./Summary";
import DistributionSteps from "../steps/DistributionSteps";
import { dateFunc } from "@/components/Functions/dateFunc";
import { useTranslation } from "react-i18next";

const DistributionSummary = () => {
  const { distributionId,caseId } = useParams();
  const { data } = useGetDistributionSummaryByIdQuery({
    id: distributionId,
  });
  const { t } = useTranslation();
  const breadcrumbItems = [
    {
      label: "Back",
      elementKey: "Back",
      text: t("global.globalActions.back"),
      url: `/case/${caseId}/?activeTab=6`,
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];
  const creditorBanner = {
    creditorTtl: "pages.distributions.distribution.distrbutionBanner.title",
    creditorName: data?.name,
  };
  const bannerElements: BannerElement[] = [
    {
      label: "pages.distributions.distribution.distrbutionBanner.label",
      value: dateFunc(data?.createdOn).formattedDateOnly,
      elementKey: "caseID",
    },
  ];

  const tabPanelData = {
    title: ["pages.distributions.distribution.tabPannelDetails.tab1", "pages.distributions.distribution.tabPannelDetails.tab2"],
    content: [
      <div>
        <Summary />
      </div>,
      <div>
        <DistributionSteps />
      </div>,
    ],
  };
  return (
    <>
      <div>
        <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
      </div>
      <Banner
        bannerName={creditorBanner.creditorTtl}
        bannerNameValue={creditorBanner.creditorName}
        elements={bannerElements}
      />
      <div className="ccwp_table_tabpanel ccwp_claim_summary_view">
        <TabPanel data={tabPanelData} defaultActiveKey={0} />
      </div>
    </>
  );
};

export default DistributionSummary;
