import ControlInputBox from "@/components/Form/InputBox/ControlInputBox";
import ControlSelect from "@/components/Form/SelectBox/ControlSelect";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function CustomDisplayBoolean({
  index,
  control,
}: {
  index: number;
  control: Control<any>;
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className="motif-row">
        <div className="motif-col-lg-4">
          <div className="form-field ">
            <ControlInputBox
              name={`fields.[${index}].displayName`}
              control={control}
              required={false}
              isTextArea={false}
              label={t("pages.portalConfig.labels.displayName")}
              showLabel={true}
            />
          </div>
        </div>
        <div className="motif-col-lg-4">
          <div className="form-field">
            <ControlInputBox
              name={`fields.[${index}].displayNameFrench`}
              control={control}
              isTextArea={false}
              label={t("pages.portalConfig.labels.displayNameFR")}
              showLabel={true}
            />
          </div>
        </div>
        <div className="motif-col-lg-3">
          <div className="form-field">
            <ControlSelect
              name={`fields.[${index}].defaultValue`}
              control={control}
              required={false}
              label={t("pages.portalConfig.labels.defaultValue")}
              showLabel={true}
              options={[
                {
                  value: true,
                  label: t("pages.portalConfig.labels.yes"),
                },
                {
                  value: false,
                  label: t("pages.portalConfig.labels.no"),
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="motif-row">
        <div className="motif-col-lg-4">
          <div className="form-field ">
            <ControlInputBox
              name={`fields.[${index}].tooltip`}
              control={control}
              required={false}
              isTextArea={false}
              label={t("pages.portalConfig.labels.tooltip")}
              showLabel={true}
            />
          </div>
        </div>
        <div className="motif-col-lg-4">
          <div className="form-field">
            <ControlInputBox
              name={`fields.[${index}].tooltipFrench`}
              control={control}
              isTextArea={false}
              label={t("pages.portalConfig.labels.tooltipFR")}
              showLabel={true}
            />
          </div>
        </div>
        <div className="motif-col-lg-3"></div>
      </div>
    </>
  );
}
