import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import attributeReducer from "@/pages/CaseDetail/Configuration/Attributes/AttributesSlice";
import claimsReducer from "@/features/claims/claimsSlice";
import configurationReducer from "@/pages/CaseDetail/Configuration/ConfigurationSlice";
import distributionsReducer from "@/pages/CaseDetail/Distributions/DistributionsSlice";
import TabPanelReducer from "@/components/TabPanel/TabPanelSlice";
import claimSetupDescriptionReducer from "@/pages/CaseDetail/Claim/claimSetup/Description/descriptionSlice";
import claimSetupClaimItemsReducer from "@/pages/CaseDetail/Claim/claimSetup/ClaimItems/claimItemsSlice";
import claimSetupDebtorSearchReducer from "@/pages/CaseDetail/Claim/claimSetup/Debtor/debtorSlice";
import claimSetupCreditorReducer from "@/pages/CaseDetail/Claim/claimSetup/Creditor/creditorSlice";
import claimSetupAssigneeReducer from "@/pages/CaseDetail/Claim/claimSetup/SelectAssignee/selectAssigneeSlice";
import claimSetupAttributesReducer from "@/pages/CaseDetail/Claim/claimSetup/Attributes/attributeSlice";
import userReducer from "@/features/auth/user-slice";
import { userApi } from "@/features/auth/user-api";
import creditorAttributesReducer from "@/pages/CaseDetail/Creditor/CreditorSetup/CreditorAttributesSlice";
import caseReducer from "@/features/case/case-slice";
import { ccwpApi } from "@/services";
import caseSetupSlice from "@/pages/CaseDetail/CaseSetup/caseSetupSlice";
import creditorSetupSlice from "@/pages/CaseDetail/Creditor/CreditorSetup/creditorSetupSlice";
import formDefaultsReducer from "@/features/formDefaults/formDefaultsSlice";
import formSetupReducer from "@/features/form-setup/formSetupSlice";
import confirmationReducer from "@/features/confirmation/confirmation-slice";
import { timeZoneSlice } from "@/pages/CaseDetail/Configuration/CaseTimeZones/caseTimeZoneSlice";
import discardChangesData from "@/components/Modal/ConfirmationModal/discardchanges";
import permissionReducer from "@/services/cases/permissionReducer";
import errorsReducer, {
  rtkQueryErrorLogger,
} from "@/features/errors/errors-slice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    errors: errorsReducer,
    confirmation: confirmationReducer,
    formDefaults: formDefaultsReducer,
    formSetup: formSetupReducer,
    userApi: userApi.reducer,
    ccwpApi: ccwpApi.reducer,
    case: caseReducer,
    attribute: attributeReducer,
    claims: claimsReducer,
    claimSetupDescription: claimSetupDescriptionReducer,
    claimSetupClaimItems: claimSetupClaimItemsReducer,
    claimSetupDebtorSearch: claimSetupDebtorSearchReducer,
    claimSetupCreditorSearch: claimSetupCreditorReducer,
    claimSetupAssigneeSearch: claimSetupAssigneeReducer,
    claimSetupAttributes: claimSetupAttributesReducer,
    configuration: configurationReducer,
    distributions: distributionsReducer,
    tabPanel: TabPanelReducer,
    creditorAttributes: creditorAttributesReducer,
    caseSetup: caseSetupSlice,
    creditorSetup: creditorSetupSlice,
    timezone: timeZoneSlice.reducer,
    discardChangesDatas: discardChangesData,
    permissions: permissionReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      ccwpApi.middleware,
      rtkQueryErrorLogger // put this after other API middleware to catch errors
    ),
});
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
