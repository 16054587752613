import { IconoirCheckCircle } from "@ey-xd/motif-icon";
import "./SuccessMessage.scss";
import { MotifIcon } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";

function SuccessMessage() {
  const {t}=useTranslation();
  return (
    <div className="success-message">
      <div className="icon-container">
        <MotifIcon
          iconFunction={IconoirCheckCircle}
          stroke="#4CAF50"
          strokeWidth="1"
          size="28" 
        />
      </div>
      <div className="message-title">{t("pages.claims.claimsTable.bulkImport.sucessMessage")}</div>
      <div className="message-description">
      {t("pages.claims.claimsTable.bulkImport.successDescription")}    
        </div>
    </div>
  );
}

export default SuccessMessage;
