import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { MotifInput, MotifSelect, MotifOption } from "@ey-xd/motif-react";

type ValueElementProps = {
	index: number;
	groupIndex?: number;
	control: any;
	treeRows: any[];
};

const ValueElement = ({
	index,
	groupIndex,
	control,
	treeRows,
}: ValueElementProps) => {
	const path =
		groupIndex !== undefined
			? `treeRows.${index}.treeRows.${groupIndex}`
			: `treeRows.${index}`;
	const attributeType =
		groupIndex !== undefined
			? treeRows[index]?.treeRows[groupIndex]?.attributeType
			: treeRows[index]?.attributeType;

	useEffect(() => {
		const btn = document.querySelectorAll(
			".motif-select-input"
		) as NodeListOf<Element>;
		btn.forEach((element: Element) => {
			if (attributeType === 3) {
				(element as HTMLElement).setAttribute("type", "button");
			}
		});
	}, [attributeType]);

	return (
		<Controller
			name={`${path}.value`}
			control={control}
			rules={{ required: "Value is required" }}
			render={({ field, fieldState: { error } }) => {
				switch (attributeType) {
					case 1:
						return (
							<MotifInput
								//@ts-ignore
								type="number"
								onChange={field.onChange}
								value={field.value}
								hideClearButton={true}
								
							/>
						);
					case 2:
						return (
							<MotifInput
								//@ts-ignore
								type="date"
								hideClearButton={true}
								onChange={field.onChange}
								value={field.value}
							/>
						);
					case 3:
						return (
							<MotifSelect
								onChange={field.onChange}
								value={field.value}
								placeholder="Select"
							>
								<MotifOption value="True">True</MotifOption>
								<MotifOption value="False">False</MotifOption>
							</MotifSelect>
						);
					default:
						return (
							<MotifInput
								//@ts-ignore
								onChange={field.onChange}
								value={field.value}
								hideClearButton={true}
							/>
						);
				}
			}}
		/>
	);
};

export default ValueElement;
