import React from 'react'
import './Label.scss';
import {useTranslation} from 'react-i18next';

type LabelType = {
    title: string,
    required?: boolean,
}
const Label  = ({title, required=false}: LabelType) => {
    const {t} = useTranslation(); 
    return (
        <>
            <div className='motif-body2-default-regular title'>{t(title)} {required ? <span className="requiredSymbol">*</span> : ''}</div>
        </>
    )
}

export default Label;