import SearchInput from "@/components/SearchInput/SearchInput";
import { useCase } from "@/features/case/use-case";
import { useGetDebtorQuickSearchApiDataQuery } from "@/services/claimSummary/caseSummaryReducer";
import { MotifFormField, MotifProgressLoader } from "@ey-xd/motif-react";

export const SearchDebtor = ({
  handleChange,
  debtorData,
  resolvedStatus,
}: any) => {
  // const getData: any = localStorage.getItem("caseDetails");
  // const caseData = JSON.parse(getData);
  const caseData = useCase();
  const { data, isLoading }: any = useGetDebtorQuickSearchApiDataQuery({
    caseId: caseData.caseId,
  });
  const debtorNames =
    (data && data?.map((items: { name: string; id: any }) => items.name)) || [];
  console.log("Debtor Names", debtorNames);
  function getDebtorId(props: any) {
    const debtorID = data?.filter((items: { name: string; id: any }) => {
      if (items.name === props) return true;
      else return false;
    });
    handleChange("debtorId", debtorID[0] && debtorID[0]?.debtorId);
  }
  return (
    <div>
      {isLoading ? (
        <MotifProgressLoader variant="circular" size="xs" />
      ) : (
        <MotifFormField>
          <SearchInput
            aria-label="Search"
            id="states-search"
            items={debtorNames}
            onBlur={function Ua() {}}
            onChange={(e: any) => getDebtorId(e.target.value)}
            onSelect={(e: any) => getDebtorId(e)}
            placeholder="Search for..."
            value={debtorData.claimSummary?.debtorName}
            disabled={resolvedStatus}
          />
        </MotifFormField>
      )}
    </div>
  );
};
