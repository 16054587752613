import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FormDefaults {
  claim: ClaimDefaults;
}

interface ClaimDefaults {
  documentTypes: DocumentType[];
}

interface DocumentType {
  id: string;
  name: string;
}

const formDefaults = createSlice({
  name: "formDefaults",
  initialState: {
    claim: {
      documentTypes: null,
    },
  },
  reducers: {
    setFormDefaults: (state: any, action: PayloadAction<FormDefaults>) => {
      state = action.payload;
      return state;
    },
    setClaim: (state: any, action: PayloadAction<ClaimDefaults>) => {
      state.claim = action.payload;
    },
  },
});

export const { setFormDefaults, setClaim } = formDefaults.actions;
export default formDefaults.reducer;
