import { AccountType, CalculationDataSourceFormType, CalculationStepEditFormType, SubAccountType } from "./type";
import { defaultAccount, defaultSubAccount } from "./Constant";
import { defaultClass } from "@/pages/CaseDetail/CalculationStep/Form/Constant";

export const postDataMapping = (data: CalculationStepEditFormType, caseId: string, distributionId: string) => {
    return {
        name: data.stepName,
        subAccountId: data.subAccount,
        description: data.description,
        isMemo: data.payables === "1",
        caseId: caseId,
        distributionId: distributionId,
        calculationGroupBy:Number(data.calculationGroupBy),
        comment:data.comment,
        payeeTypeId: data?.payeeType === "-1" || data?.payeeType === "" ? null : data?.payeeType,
    }
}




export const postDataMappingSource = (data: CalculationDataSourceFormType) => {
  return {
      type: Number(data.dataSource),
      isEntitlement: Number(data.entitleMentSet) === 0 ? true : false,
      isFilterApplicableClasses: data.filterApplicableClasses,
      weight: Number(data.weightingFactor),
      sourceManualDataSourceId:data?.sourceManualDataSourceId
  }
}











export const renderAccountList = (list: AccountType[]) => {
  if (!list || list.length === 0) {
    return [defaultAccount];
  }
  return list.map((item) => {
    return {
      value: item.accountId,
      label: item.accountName,
    };
  });
};

export const renderSubAccountList = (list: SubAccountType[]) => {
  if (!list || list.length === 0) {
    return [defaultSubAccount];
  }
  return list.map((item) => {
    return {
      value: item.subAccountId,
      label: item.subAccountName,
    };
  });
};

export const renderOptionList = (list: any[], key:string, val:string) => {
  if (!list || list.length === 0) {
    return [defaultClass];
  }
  const optionList =  list.map((item) => {
    return {
      value: item[val],
      label: item[key],
    };
  });
  return [defaultClass, ...optionList];
}

export const getDataSourceValue = (label: string) => {
  if (label === "AcceptedClaims") {
    return "0";
  } else if (label === "PriorStep") {
      return "1";
  } else if (label === "Manual") {
      return "2";
    } else {
      return "0";
  }
}