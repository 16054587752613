export const PAGE_TITLE = "Case Setup"
export const PAGE_DESCRIPTION = "Lorem ipsum dolor sit amet consectetur. Praesent sit nulla lacus nullam posuere diam velit eu nulla. Feugiat pretium massa non justo at aliquam semper purus nisl. Ut eget proin hendrerit dignissim neque lacus iaculis convallis non. Arcu eget porta."
export const FORM_TITLE = "Case Details"
export const FORM_DESCRIPTION = "Lorem ipsum dolor sit amet consectetur. Praesent sit nulla lacus nullam posuere diam velit eu nulla."
export const CASE_NAME = "Case Name";
export const CASE_ID = "caseName";
export const CASE_ID_F = "caseNameInFrench";
export const CASE_DESCRIPTION = "Case Description";
export const CASE_DESCRIPTION_ID = "caseDescription";
export const CASE_DESCRIPTION_ID_F = "caseDescriptionInFrench";
export const CASE__SHORT_NAME = "Case Short Name";
export const CASE_SHORT_ID = "caseShortName";
export const CASE_IDENTIFIERS = "Case Identifiers";
export const CASE_IDENTIFIERS_SELECTION = "I want to include case identifiers in French on the form";
export const CASE_CONFIGURATION = "Case Configuration";
export const CASE_TIME_ZONE = "caseTimeZone";
export const CASE_TIME_ZONE_LABEL = "Case Time Zone";
export const DEBTOR_INFORMATION = "Debtor Information"
export const DEBTOR_DESCRIPTION = "Lorem ipsum dolor sit amet consectetur. Praesent sit nulla lacus nullam posuere diam velit eu nulla."
export const ADD_DEBTORS = "Add Debtors"
export const ADD_DEBTOR_BUTTON = "Add a Debtor"
export const DEBTOR_NAME = "Debtor Name"
export const DESCRIPTION = "Description"
export const REMOVE = "Remove"
export const CURRENCY_CONFIGURATION = "Currency Configuration"
export const CURRENCY_CONFIGURATION_DESCRIPTION = "Lorem ipsum dolor sit amet consectetur. Praesent sit nulla lacus nullam posuere diam velit eu nulla."
export const SELECT_DISPLAY_CURRENCY = "Add Default Currency";
export const ADD_ORIGINAL_CURRENCY = "Add Additonal Currency";
export const EXCHANGE_RATE = "Exchange Rate";
export const DECIMAL_POINT = "Decimal Point";
export const CURRENCY_NAME = "Currency Name";
export const CURRENCY_NAME_FRENCH = "Currency Name (French)";
export const FORM_TITLE_REVIEW = "Review";
export const FORM_DESCRIPTON_REVIEW = "Lorem ipsum dolor sit amet consectetur. Praesent sit nulla lacus nullam posuere diam velit eu nulla."
export const DEBTOR_DESCRIPTION_TITLE = "Debtor Description";
export const DISPLAY_CURRENCY = "Default Currency";
export const ADDITIONAL_CURRENCY = "Additional Currency";
export const IS_FRENCH_SELECTED = "isFrenchSelected";

export const statusOptions = [
    {
      "label": "open",
      "value": "1",
    }, 
    {
      "label": "close",
      "value": "0",
    },
  ];