import { Control, Controller, FieldValues, Path, PathValue } from "react-hook-form";
import {useTranslation} from 'react-i18next';
import SelectBox from "./SelectBox";

type ControlSelectType<T extends FieldValues> = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<T>;
    name: Path<T> ;
    showLabel: boolean;
    label: string;
    options: {
        label: string,
        value: string,
    }[];
    setValue: any;
    getValues: any;
    onChangeHandlerState?: (index: number, stateId: string, stateOptions:{label:string, value: string}[]) => void;
    filter?: boolean | ((x?: any) => void) | undefined;
    searchPlaceholder? : string;
    
}

export default function ControlSelectState<T extends FieldValues>({name, control, required=false, options, showLabel, label, onChangeHandlerState, filter, searchPlaceholder}:ControlSelectType<T>) {
    const {t} = useTranslation();
    let defaultOption = {
        label: t("global.globalActions.optionDefault"),
        value: "",
      };
    if (options === undefined) {
        options =[defaultOption];
    }
  
    const translatedOptions = options.map((option:any) => { 
        return {
            label: option.label,
            value: option.value,
        };
    })
    return (
        <> 
            {
                showLabel && <div className="formFieldLabel">
                    <label  htmlFor="input" aria-label={label}>{t(label)}{ required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
           <Controller
                name={name}
                control={control}
                rules={{ required }}
                defaultValue={translatedOptions[0]?.value as PathValue<T, Path<T>>}
                render={({ field: { onChange, value }}) => {
                            if (translatedOptions[0]?.value === undefined || translatedOptions[0]?.value === "" || translatedOptions[0]?.value === null) {
                                value = "" as PathValue<T, Path<T>>
                            }
                            return (
                            <>
                                <SelectBox   options={translatedOptions} value={value} onChange={onChange} onChangeHandlerState={onChangeHandlerState} filter={filter} searchPlaceholder={searchPlaceholder}/>
                            </>
                        );
                    }}
            />
        </>
        
    );
}
