const config = {
  environment: process.env.REACT_APP_ENV || "development",
  features: {
    sso: process.env.REACT_APP_FEATURES_SSO
      ? process.env.REACT_APP_FEATURES_SSO === "true"
      : true,
    session: process.env.REACT_APP_FEATURES_SSO
      ? process.env.REACT_APP_FEATURES_SSO === "true"
      : true,
  },
  backend: {
    baseUrl: process.env.REACT_APP_API_BASE_URL || "http://localhost:5189/api",
    scope: process.env.REACT_APP_API_SCOPE || "api://APP_ID/.default",
  },

  msalConfig: {
    auth: {
      clientId: process.env.REACT_APP_FE_CLIENT_ID || "",
      authority:
        "https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c",
      redirectUri:
        // full asbolute url path is required for redirectUri
        process.env.REACT_APP_REDIRECT_URI || "http://localhost:3000",
    },
  },
};

export default config;
