import {
  TASK_NAME,
  TYPE_NAME,
  STATUS_NAME,
  DUEDATE_NAME,

} from "./constant";
import { useForm } from "react-hook-form";
import { EditSummaryFormType } from "./type";
import ControlInputBox from "./createTaskInputField";
import Button from "@/components/Form/Button/Button";
import { MotifErrorMessage } from "@ey-xd/motif-react";
import ControlSelect from "./editFieldSelectBox";
import { useParams } from "react-router-dom";
import { useGetTaskViewDataQuery, useUpdateClaimTaskMutation } from "@/services/claimSummary/caseSummaryReducer";
import { useEffect, useState } from "react";
import FormattedDatePicker from "@/components/FormattedDatePicker/FormattedDatePicker";
import { useCase } from "@/features/case/use-case";
import { useTranslation } from "react-i18next";

export const EditTaskAllInputFields = ({ setShowModal, getTaskById }: any) => {
  const parameter = useParams();
  const [updatedDueDate, setUpdatedDueDate] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const caseData = useCase();
  const {t}=useTranslation();
  const getDataByUrl = { caseId: caseData.caseId, claimId: parameter?.claimId };
  const { refetch } = useGetTaskViewDataQuery(getDataByUrl);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<EditSummaryFormType>();
  const [updateClaimTask] = useUpdateClaimTaskMutation();
  const onSubmit = async (formData: EditSummaryFormType) => {
    let taskData = {
      claimTaskId: getTaskById?.id,
      name: formData.taskName,
      dueDate: formData.dueDateName,
      status: Number(formData.statusName),
      type: Number(formData.typeName),
    };
    setIsLoading(true);
    const response = await updateClaimTask(taskData);
    if (response.hasOwnProperty("data")) {
      refetch();
      setShowModal(false);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setShowModal(false);
    }
  };
  useEffect(() => {
    setValue(TASK_NAME, getTaskById?.name);
    setValue(STATUS_NAME, getTaskById?.status.toString());
    setValue(TYPE_NAME, getTaskById?.type.toString());
    setValue(DUEDATE_NAME, getTaskById?.dueDate);
  }, [getTaskById, setValue]);
  useEffect(() => {
    setValue(DUEDATE_NAME, updatedDueDate);
  }, [updatedDueDate, setValue]);
  return (
    <>
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-row">
          <div className="motif-col-md-4">
            <div className="form-field">
              <ControlInputBox
                name={TASK_NAME}
                control={control}
                required={true}
                isTextArea={false}
                label={"pages.claims.taskTab.formLabels.taskName"}
                showLabel={true}
              />
              {errors[TASK_NAME] && errors[TASK_NAME].type === "required" && (
              <MotifErrorMessage>{t("pages.claims.taskTab.validationMessages.taskNameReq")}</MotifErrorMessage>
            )}
            </div>
          </div>
          <div className="motif-col-md-4">
            <div className="form-field">
              <div className="hidedatepicker">
                <ControlInputBox
                  name={DUEDATE_NAME}
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={"pages.claims.taskTab.formLabels.dueDate"}
                  showLabel={true}
                />
              </div>
              <FormattedDatePicker
                todayMark={true}
                //@ts-ignore
                value={updatedDueDate !== undefined ? updatedDueDate : getTaskById?.dueDate}
                onChange={(value: any) => {
                  // Create a Date object from the selected value
                  const selectedDate = new Date(value);

                  // Add one day to the selected date
                  selectedDate.setDate(selectedDate.getDate() + 1);
                  // Convert the updated date to ISO string and take the first 10 characters (YYYY-MM-DD)
                  const updatedDate: any = selectedDate
                    .toISOString()
                    .substring(0, 10);
                  setUpdatedDueDate(updatedDate);
                }}
              />
              {errors[DUEDATE_NAME] && (
              <MotifErrorMessage>{t("pages.claims.taskTab.validationMessages.dueDate")}</MotifErrorMessage>
            )}
            </div>
          </div>
        </div>
        <div className="motif-row mar-t-20">
          <div className="motif-col-md-4">
            <div className="form-field">
              <ControlSelect
                name={TYPE_NAME}
                //@ts-ignore
                control={control}
                required={true}
                showLabel={true}
                readonly={true}
                label={"pages.claims.taskTab.formLabels.type"}
                options={[
                  { label: t("pages.claims.taskTab.formLabels.typeOptionsLabel.selectType"), value: "" },
                  { label: t("pages.claims.taskTab.formLabels.typeOptionsLabel.other"), value: "0" },
                  { label: t("pages.claims.taskTab.formLabels.typeOptionsLabel.cashManagement"), value: "1" },
                  { label: t("pages.claims.taskTab.formLabels.typeOptionsLabel.legal"), value: "2" },
                ]}
              />
              {errors[TYPE_NAME] && errors[TYPE_NAME].type === "required" && (
              <MotifErrorMessage>{t("pages.claims.taskTab.validationMessages.typeReq")}</MotifErrorMessage>
            )}
            </div>
          </div>
          <div className="motif-col-md-4">
            <div className="form-field">
              <ControlSelect
                name={STATUS_NAME}
                //@ts-ignore
                control={control}
                required={true}
                showLabel={true}
                readonly={true}
                label={"pages.claims.taskTab.formLabels.status"}
              options={[
                { label: t("pages.claims.taskTab.formLabels.statusLabels.selectStatus"), value: "" },
                { value: "0", label:  t("pages.claims.taskTab.formLabels.statusLabels.open") },
                { value: "1", label: t("pages.claims.taskTab.formLabels.statusLabels.closed") },
                { value: "2", label: t("pages.claims.taskTab.formLabels.statusLabels.complete") },
              ]}
              />
              {errors[STATUS_NAME] &&
                errors[STATUS_NAME].type === "required" && (
                  <MotifErrorMessage>{t("pages.claims.taskTab.validationMessages.statusReq")}</MotifErrorMessage>
                )}
            </div>
          </div>
        </div>
      </form>{" "}
      <Button
        label={t("global.globalActions.save")}
        variant="primary"
        className="motif-button"
        onClickHandler={handleSubmit(onSubmit)}
        type={isLoading ? "loader" : "submit"}
        disabled={isLoading}
      />
    </>
  );
};
 

