import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	MotifButton,
	MotifDropdown,
	MotifDropdownItem,
	MotifIcon,
	MotifChip,
} from "@ey-xd/motif-react";
import { IconoirMoreHoriz } from "@ey-xd/motif-icon";
import { PaymentStatus, StatusMapping } from "../type";
import { getPaymentStatus } from "@/utility/common";
import UpdatePaymentStatus from "./UpdatePaymentStatus";
import BulkChequeImport from "./BulkChequeImport";
const TitleComplement = ({
	selectedPaymentIds,
	clearSelectedPayemntIds,
	refreshAfterPaymentUpdate,
	clearFilter,
	isFilterActive,
	allPaymentSelected,
	filterPayload,
}: {
	selectedPaymentIds: any;
	clearSelectedPayemntIds: () => void;
	refreshAfterPaymentUpdate: () => void;
	clearFilter: () => void;
	isFilterActive: boolean;
	allPaymentSelected: boolean;
	filterPayload: any;
}) => {
	const { t } = useTranslation();
	const [showUpdateStatuModal, setShowUpdateStatuModal] = useState(false);
	const [showBulkChequeModal, setShowBulkChequeModal] = useState(false);
	const [validationError, setValidationError] = useState("");
	const [availableStatuses, setAvailableStatuses] = useState<PaymentStatus[]>(
		[]
	);
	const statusTitleMenu = [
		// "Export",
		// "Import Bank Activity",
		// "Payment Documents",
		"Upload Cheque Images"
	];
	const statusMapping: StatusMapping = {
		Draft: ["Issued", "Void"],
		Issued: ["Cashed", "Undelivered", "Void", "Stop"],
		Cashed: [],
		Undelivered: ["Issued", "Void"],
		Void: ["Replaced"],
		Stop: ["Issued"],
		Replaced: [],
	};

	const handleMenuClick = (index: number) => {
		if (index === 0) {
			setShowBulkChequeModal(true);
			return;
		}
	};

	const validateSelectedPayments = (selectedpayments: any[]) => {
		const { paymentStatus } = selectedpayments[0];
		for (let i = 1; i < selectedpayments.length; i++) {
			if (
				getPaymentStatus(selectedpayments[i].paymentStatus) !==
				getPaymentStatus(paymentStatus)
			) {
				return false;
			}
		}
		return true;
	};

	const handleUpdateStatusPopup = () => {
		if (!validateSelectedPayments(selectedPaymentIds)) {
			setValidationError(t("pages.paymentStatus.sameStatusValidationMessage"));
		} else {
			const paymentStatus = getPaymentStatus(
				selectedPaymentIds[0].paymentStatus
			);
			const availableStatuses =
				statusMapping[paymentStatus as PaymentStatus] || [];
			if (availableStatuses.length === 0) {
				setValidationError(
					t("pages.paymentStatus.emptyStatusValidationMessage", {
						paymentStatus: paymentStatus,
					})
				);
			} else {
				setValidationError("");
				setAvailableStatuses(availableStatuses);
			}
		}
		setShowUpdateStatuModal(true);
	};
	return (
		<>
			<div className="status-title-header">
				{isFilterActive && (
					<MotifChip
						className="clear-search-chip"
						onClick={clearFilter}
						variant="monochrome-dark"
					>
						{t("pages.paymentStatus.clearFilter")}
					</MotifChip>
				)}
				<MotifButton
					onClick={handleUpdateStatusPopup}
					size="small"
					type="submit"
					variant="secondary"
					disabled={!selectedPaymentIds.length}
				>
					{t("pages.paymentStatus.updateStatusButton")}
				</MotifButton>
				<MotifDropdown
					ariaLabelledby="dropdown-trigger-1"
					id="dropdown-1"
					trigger={
						<MotifButton
							aria-label="dropdpwn"
							id="dropdown-trigger-1"
							onClick={function Ga() { }}
							type="button"
							variant="text"
						>
							<MotifIcon iconFunction={IconoirMoreHoriz} />
						</MotifButton>
					}
				>
					<ul role="menu">
						{statusTitleMenu.map((menuName: string, index: number) => {
							return (
								<li role="menuitem" key={index}>
									<MotifDropdownItem indexItem={1} onClick={() => handleMenuClick(index)}>
										{t(menuName)}
									</MotifDropdownItem>
								</li>
							);
						})}
					</ul>
				</MotifDropdown>
				{/* Commented for later use */}
				{/* <div style={{ "cursor": "pointer", "opacity": 0.8 }}
					onClick={() => {}}>
					<MotifIcon size="19" iconFunction={IconoirPlus} />
				</div> */}
			</div>
			{showUpdateStatuModal && (
				<UpdatePaymentStatus
					showModal={true}
					validationError={validationError}
					currentStatusLabel={getPaymentStatus(
						selectedPaymentIds[0].paymentStatus
					)}
					setShowUpdateStatuModal={setShowUpdateStatuModal}
					selectedPaymentIds={selectedPaymentIds}
					clearSelectedPayemntIds={clearSelectedPayemntIds}
					availableStatuses={availableStatuses}
					refreshAfterPaymentUpdate={refreshAfterPaymentUpdate}
					allPaymentSelected={allPaymentSelected}
					isFilterActive={isFilterActive}
					filterPayload={filterPayload}
				/>
			)}
			{showBulkChequeModal && (
				<BulkChequeImport
					showModal={true}
					setShowModal={setShowBulkChequeModal}
					title={t("pages.paymentStatus.bulkChequeImport.title")}
					uploadLabel={t("pages.claims.claimsTable.bulkImport.uploadLabel")}
					uploadDescription={t(
						"pages.paymentStatus.bulkChequeImport.uploadDescription"
					)}
					maxFiles={1}
					onClose={() => setShowBulkChequeModal(false)}
				/>
			)}
		</>
	);
};
export default TitleComplement;
