import "@/pages/CaseDetail/Summary/summary.scss";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { MotifIcon } from "@ey-xd/motif-react";
import "./SummaryForm/SummaryForm.scss";
import DefinedTabPanel from "@/components/TabPanel/DefinedTabPanel";
import { GridBanner } from "@/components/GridBanner/GridBanner";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationGrid from "@/components/Notification/NotificationGrid";
// import { Create_SUCCESSFUL, UPDATE_SUCCESSFUL } from "@/Constant";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Summary() {
  const { t }=useTranslation();
  let location = useLocation();
  const navigate = useNavigate();
  let state = location.state;
  if (!state) {
    state = { showMessage: false, defaultActiveKey: 0, update: false };
  }

  useEffect(() => {
    if (state.showMessage) {
      setTimeout(() => {
        navigate(location, { ...state, showMessage: false });
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const searchParams = new URLSearchParams(window.location.search);
  let activeTab = searchParams.get("activeTab");
  if (activeTab) {
    state.defaultActiveKey = parseInt(activeTab);
  }

  const breadcrumbItems = [
    {
      text: t("global.globalActions.backToHome"),
      url: "/",
      icon: <MotifIcon src={IconoirArrowLeft} />,
    },
  ];

  return (
    <div>
      <Breadcrumb items={breadcrumbItems} ariaLabel="Breadcrumb navigation" />
      <GridBanner />
      {state.showMessage && (
        <NotificationGrid
          show={state.showMessage}
          message={
            state.update
              ? "global.globalNotifications.updatedSuccessfully"
              : "global.globalNotifications.createdSuccessfully"
          }
          type="success"
        />
      )}
      <DefinedTabPanel defaultActiveKey={state.defaultActiveKey} />
    </div>
  );
}
