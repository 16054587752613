import PageSubTitle from "@/components/Form/PageSubTitle/PageSubTitle";
import React, { useCallback, useEffect, useState } from "react";
import {
  DEBTOR_NAME,
} from "../Constants";
import { MotifLabel } from "@ey-xd/motif-react";
import { useDispatch, useSelector } from "react-redux";
import {
  DebtorSearchData,
  setSelectedDebtorCompleteDetail,
  resetDebtorState,
} from "./debtorSlice";
import {
  useGetDebtorListDataQuery,
  useGetDebtorEditViewApiDataQuery,
} from "@/services/debtors/debtorsReducers";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Button from "@/components/Form/Button/Button";
import { SECONDARY } from "@/Constant";
import { useTranslation } from "react-i18next";
import { MotifErrorMessage } from "@ey-xd/motif-react";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import "./Debtor.scss";
import ControlSelect from "./ControlSelectClaimSetupDebtor";
import { ClaimSetupDebtor } from "./type";
import { useForm } from "react-hook-form";
import useResetAllClaimItemsStore from "../useResetAllClaimItemsStore";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

interface DebtorProps {
  goToNextTab: () => void;
  DebtorID?: string;
}

interface RootState {
  claimSetupDebtorSearch: DebtorSearchData;
}

export default function Debtor({ goToNextTab, DebtorID }: DebtorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { caseId } = useParams();
  const { search } = useLocation();
  const debtorId = new URLSearchParams(search).get("debtorId");
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [debtorName, setDebtorName] = useState("");
  const [debtorDescription, setDebtorDescription] = useState("");
  const resetAllClaimItemsStore = useResetAllClaimItemsStore();
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: true }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch]);
  const claimSetupDebtorData = useSelector(
    (state: RootState) =>
      state.claimSetupDebtorSearch.selectedDebtorCompleteDetail
  );
  const debtorNameFromStore = useSelector(
    (state: RootState) =>
      state.claimSetupDebtorSearch.selectedDebtorCompleteDetail.name
  );

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    watch,
    reset,
  } = useForm<ClaimSetupDebtor>({});

  const { data: debtorDataByCase, isLoading: isDebtorDataByCaseLoading } =
    useGetDebtorListDataQuery(caseId);

  const {
    data: debtorDataByDebtorId,
    isLoading: isDebtorDataByDebtorIdLoading,
  } = useGetDebtorEditViewApiDataQuery(DebtorID, {
    skip: !DebtorID,
  });

  const [selectedDebtor, setSelectedDebtor] = useState(
    claimSetupDebtorData || debtorDataByDebtorId
  );

  let DEBTOR_OPTIONS = [];

  if (debtorDataByCase && debtorDataByCase.length > 0) {
    DEBTOR_OPTIONS = debtorDataByCase.map(
      (item: { name: string; debtorId: string }) => ({
        label: item?.name,
        value: item?.debtorId,
      })
    );
  } else {
    DEBTOR_OPTIONS = [{ label: "", value: "" }];
  }

  let selectedDebtorID = watch(DEBTOR_NAME);

  useEffect(() => {
    let selectedDebtorDetail;
    if (claimSetupDebtorData) {
      selectedDebtorDetail = claimSetupDebtorData;
    } else if (DebtorID && debtorDataByDebtorId) {
      selectedDebtorDetail = debtorDataByDebtorId;
    } else if (selectedDebtorID || debtorDataByCase) {
      selectedDebtorDetail = debtorDataByCase?.find(
        (item: { debtorId: string }) => item.debtorId === selectedDebtorID
      );
    }

    if (selectedDebtorDetail) {
      setSelectedDebtor(selectedDebtorDetail);
      setDebtorName(selectedDebtorDetail.name);
      setDebtorDescription(selectedDebtorDetail.description);
      setShowGrid(true);
    } else {
      setDebtorName("");
      setDebtorDescription("");
      setShowGrid(false);
    }
  }, [
    DebtorID,
    debtorDataByDebtorId,
    claimSetupDebtorData,
    debtorDataByCase,
    selectedDebtorID,
    setValue,
  ]);

  useEffect(() => {
    if (selectedDebtorID !== undefined && debtorDataByCase) {
      const debtorDetail = debtorDataByCase?.find(
        (item: { debtorId: string }) => item.debtorId === selectedDebtorID
      );
      if (debtorDetail) {
        setSelectedDebtor(debtorDetail);
        setDebtorName(debtorDetail.name);
        setDebtorDescription(debtorDetail.description);
        setShowGrid(true);
      }
    }
  }, [selectedDebtorID, debtorDataByCase, setValue]);

  const handleDebtorSelection = useCallback(
    (value: any) => {
      setShowGrid(value.length >= 0);
      const debtorDetail = debtorDataByCase?.find(
        (item: { debtorId: string }) => item?.debtorId === value
      );
      if (debtorDetail) {
        setSelectedDebtor(debtorDetail);
        setDebtorName(debtorDetail.name);
        setDebtorDescription(debtorDetail.description);
      }
      setShowGrid(value.length >= 0);
    },
    [debtorDataByCase]
  );

  const handleDebtorDataCancel = () => {
    setIsVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }))
  };

  const discardHandle = () => {
    dispatch(resetDebtorState());
    setIsVisible(false);
    reset({
      debtor: "",
    });
    resetAllClaimItemsStore();
    navigate(`/case/${caseId}?activeTab=3`);
  };

  const onSubmit = () => {
    if (selectedDebtor) {
      dispatch(setSelectedDebtorCompleteDetail(selectedDebtor));
      goToNextTab();
      setShowGrid(false);
    }
  };

  const selectedDebtorName = watch(DEBTOR_NAME);

  useEffect(() => {
    if (selectedDebtorName === "") {
      setShowGrid(false);
    }
  }, [selectedDebtorName]);

  useEffect(() => {
    if (
      (debtorNameFromStore || debtorDataByDebtorId) &&
      debtorDataByCase &&
      !isDebtorDataByCaseLoading
    ) {
      const debtor =
        claimSetupDebtorData?.debtorId || debtorDataByDebtorId?.debtorId;
      setValue(DEBTOR_NAME, debtor || "");
    }
  }, [
    debtorNameFromStore,
    debtorDataByDebtorId,
    setValue,
    debtorDataByCase,
    isDebtorDataByCaseLoading,
    claimSetupDebtorData?.debtorId,
  ]);

  useEffect(() => {
    if (debtorId) {
      setValue(DEBTOR_NAME, debtorId);
    }
  }, [debtorId, setValue]);

  if (isDebtorDataByCaseLoading || isDebtorDataByDebtorIdLoading) {
    return <div>{t("global.globalNotifications.loadingMessage")}</div>;
  }

  return (
    <>
      <PageSubTitle
        title={"pages.claims.claimCreate.debtorTab.title"}
        description={"pages.claims.claimCreate.debtorTab.description"}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-row">
          <div className="motif-col-lg-6">
            <ControlSelect
              name={DEBTOR_NAME}
              control={control}
              required={true}
              options={DEBTOR_OPTIONS}
              showLabel={true}
              label={"pages.claims.claimCreate.debtorTab.formLabelsDetails.searchDebtor"}
              filter={true}
              visibleOptions={3}
              onChange={handleDebtorSelection}
              setValue={setValue}
              getValues={getValues}
            />
            {errors[DEBTOR_NAME] && errors[DEBTOR_NAME].type === "required" && (
              <MotifErrorMessage>
                {t("pages.claims.claimCreate.debtorTab.validationMessages.debtorSectionMessage")}
              </MotifErrorMessage>
            )}
          </div>
        </div>
        {showGrid && (
          <div className="display-flex align-content-center mar-t-15">
            <div className="small-banner">
              <div className="formFieldLabel">
                <MotifLabel>{t("pages.claims.claimCreate.debtorTab.formLabelsDetails.debtorName")}</MotifLabel>
              </div>
              <div className="motif-body2-default-regular">{debtorName}</div>
            </div>
            <div className="small-banner mar-l-2">
              <div className="formFieldLabel">
                <MotifLabel>{t("pages.claims.claimCreate.debtorTab.formLabelsDetails.debtorDescription")}</MotifLabel>
              </div>
              <div className="motif-body2-default-regular">
                {debtorDescription}
              </div>
            </div>
          </div>
        )}

        <div className="display-flex justify-content-end mar-t-70">
          <div className="pad-r-10">
            <Button
              type="button"
              label={t("global.globalActions.cancel")}
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={() =>{setIsVisible(true);      dispatch(setDiscardEditMode({ editMode: false }));
            }}
            />
          </div>
          <div className="">
            <Button label={t("global.globalActions.next")} variant="primary" />
          </div>
        </div>
        <ConfirmationModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          cancelBtnApproveHandler={discardHandle}
          cancelBtnRejectHandler={handleDebtorDataCancel}
        />
      </form>
    </>
  );
}