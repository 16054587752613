import React from "react";
import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputBox from "@/components/Form/InputBox/InputBox";
import "@/components/Form/InputBox/InputBox.scss";
import { VotingMeetingFormType } from "./type";
import { BALLOT_MEETING_NAME_ID, BALLOT_MEETING_TIME_ID } from "./Constant";

type ControlInputBoxType = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<VotingMeetingFormType>;
  name: typeof BALLOT_MEETING_NAME_ID | typeof BALLOT_MEETING_TIME_ID;
  showLabel: boolean;
  label: string;
};

export default function ControlInputBoxVoting({
  name,
  control,
  required = false,
  isTextArea = false,
  showLabel,
  label,
}: ControlInputBoxType) {
  const {
    field,
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: { required: required },
  });
  const { t } = useTranslation();

  return (
    <>
      {showLabel && (
        <div className="formFieldLabel">
          <label htmlFor="input" aria-label={label}>
            {t(label)}
            {required ? <span className="requiredSymbol">*</span> : ""}
          </label>
        </div>
      )}
      <Controller
        name={field.name || ""}
        control={control}
        rules={{ required }}
        render={({ field: { onChange, value, ...args } }) => {
          return (
            <>
              <InputBox
                id={BALLOT_MEETING_NAME_ID}
                required={true}
                value={value || ""}
                onChange={onChange}
                isTextArea={isTextArea}
                placeholder={field.name}
                {...args}
              />
            </>
          );
        }}
      />
    </>
  );
}
