import PageTitle from "@/components/Form/PageTitle/PageTitle";
import ControlInputBox from "@/components/Form/InputBox/ControlInputBox";
import React, { useEffect, useState } from "react";
import "./calculationForm.scss";
import { CASE_NAME, CASE_ID_F } from "./Constant";
import Button from "@/components/Form/Button/Button";
import { useForm } from "react-hook-form";
import ControlSelect from "@/components/Form/SelectBox/ControlSelect";
import { MotifErrorMessage, MotifToast } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import {
  FRENCH,
  SECONDARY,
} from "@/Constant";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { postDataMapping, renderOptionList } from "./calculationSummaryHelper";
import { objectTrim } from "@/utility/common";
import SubAccount from "./SubAccount";
import ControlSelectAccount from "./SelectBox/ControlSelectAccount";
import { useNavigate } from "react-router-dom";
import {
  useCreateCalculationStepMutation,
  useGetPayeeTypeApiDataQuery,
} from "../calculationStepReducer";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

type summaryData = {
  calculationGroupBy: any;
  stepName: string;
  account: string;
  subAccount: string;
  description: string;
  payables: string;
  comment: any;
  payeeType: any;
};

type CalculationFormType = {
  summaryData: summaryData;
  // setIsEdit: Dispatch<SetStateAction<boolean>>;
  accountList: any;
  caseId: string;
  distributionId: string;
  stepId?: string;
};
const CalculationForm = ({
  summaryData,
  // setIsEdit,
  accountList,
  caseId,
  distributionId,
}: CalculationFormType) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

   const claculationGroupByOptions = [

    { label: t("pages.distributions.distributionStepsTab.claculationGroupByOptions.option1"), value: "" },
    { label: t("pages.distributions.distributionStepsTab.claculationGroupByOptions.option2"), value: "0" },
    { label:t("pages.distributions.distributionStepsTab.claculationGroupByOptions.option3"), value: "1" },
    { label: t("pages.distributions.distributionStepsTab.claculationGroupByOptions.option4"), value: "2" },
    { label: t("pages.distributions.distributionStepsTab.claculationGroupByOptions.option5"), value: "3" },
  ];
  const [loading, setLoading] = useState(false);
  const [resetSubAccount, setResetSubAccount] = useState(false);
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });
  const payableOptions = [
    { value: "0", label: t("pages.distributions.distributionStepsTab.payableOptions.option1") },
    { value: "1", label: t("pages.distributions.distributionStepsTab.payableOptions.option2")},
  ];
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors ,dirtyFields},
    setValue,
    getValues,
    watch,
  } = useForm<any>({
    defaultValues: {
      stepName: summaryData.stepName || "",
      account: summaryData.account || accountList[0].value,
      subAccount: summaryData.subAccount || "",
      description: summaryData.description || "",
      payables: summaryData.payables || payableOptions[0].value,
      calculationGroupBy: Number(summaryData.calculationGroupBy) || "0",
      comment: summaryData.comment,
      payeeType: summaryData.payeeType,
    },
  });
  const { data: resultSetting } = useGetPayeeTypeApiDataQuery({CaseId:caseId});

  const resetForm = () => {
    reset({
      stepName: "",
      account: accountList[0].value,
      subAccount: "",
      description: "",
      payables: "0",
      calculationGroupBy: "0",
      comment: "",
    });
  };

  const selectedId = summaryData.subAccount;
  const errorList = Object.keys(errors);
  const errorsShow = errorList
    .join(", ")
    .replace(CASE_ID_F, `${CASE_NAME} (${FRENCH})`);
    const dispatch=useDispatch()
    let dirtyFieldsLength = Object?.keys(dirtyFields).length;
    useEffect(() => {
      dispatch(setDiscardEditMode({ editMode: dirtyFieldsLength> 0?true:false }));
      return () => {
        dispatch(setDiscardEditMode({ editMode: false }));
      };
    }, [dispatch,dirtyFieldsLength]);
  const [createCalculationMutation] = useCreateCalculationStepMutation();
  const onSubmit = async (data: any) => {
    setLoading(true);

    //@ts-ignore
    data = objectTrim(data);

    if (!data.stepName || data.stepName.trim() === "") {
      setShowMessage({
        ...showMessage,
        show: true,
        type: "error",
        message: "pages.distributions.distributionStepsTab.validationMessages.stepNameRequired",
      });
      setLoading(false);
      return;
    }
    dispatch(setDiscardEditMode({ editMode: false }));

    try {
      const postData = postDataMapping(data, caseId, distributionId);
      const response: any = await createCalculationMutation(postData);
      if (response.hasOwnProperty("data")) {
        setShowMessage({ ...showMessage, show: true, type: "success" });
        // setIsEdit(false);
        resetForm();
        //@ts-ignore
        navigate(
          `/case/${caseId}/distribution/${distributionId}/calculation-step/${response?.data?.id}/step-summary`
        );
      } else {
        const error = "global.globalNotifications.unExpectedError";
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          message: error,
        });
        navigate(`/case/${caseId}/distribtion/${distributionId}`, {
          state: { showMessage: true },
        });
        setLoading(false);
      }
    } catch (error) {
      setShowMessage({ ...showMessage, show: true, type: "error" });
      dispatch(setDiscardEditMode({ editMode: true }));

    }
    setLoading(false);
  };

  const cancelBtnApproveHandler = (
    event: any
  ) => {
    event.preventDefault();
    reset({
      stepName: summaryData.stepName,
      account: summaryData.account,
      subAccount: summaryData.subAccount,
      description: summaryData.description,
      payables: summaryData.payables,
      calculationGroupBy: Number(summaryData.calculationGroupBy),
      comment: summaryData.comment,
    });
    setIsVisible(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
    // setIsEdit(false);
    navigate(`/case/${caseId}/distribution/${distributionId}?activeTab=1`);
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
    dispatch(setDiscardEditMode({ editMode: true }));

  };

  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const renderMessage = () => {
    if (showMessage.show && !isVisible) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={showMessage.type}
        >
          {" "}
          {showMessage.type === "error"
            ? t(showMessage.message)
            : t("global.globalNotifications.updatedSuccessfully")}
        </MotifToast>
      );
    }
  };

  const onAccountChange = (accountId: string) => {
    setResetSubAccount(true);
  };

  const calculationGroupByValue = watch("calculationGroupBy");
  const allowedPayeeTypeGroup = ["0", "2", "3"] ; 
  const displayPayee = allowedPayeeTypeGroup.includes(calculationGroupByValue);
  function filterPayeeType() {
    const getFilteredData = resultSetting?.results.filter((items: any) => {
      if (calculationGroupByValue === "") return false;
      if ((Number(calculationGroupByValue) === 0 || Number(calculationGroupByValue) === 2) && items.payeeLevel === 0) 
        return true;
      if ((Number(calculationGroupByValue) === 3 ) && items.payeeLevel === 1) 
        return true;
      else return false;
    });
    return getFilteredData;
  }
  let resultSettingList = renderOptionList(
    filterPayeeType(),
    "name",
    "id"
  );

  if (calculationGroupByValue === "0" ||  calculationGroupByValue === "2")
     resultSettingList.splice(1, 0, { label: "Creditor", value: "-1" })
 

  useEffect(() => {
    if (calculationGroupByValue !== summaryData.calculationGroupBy.toString()) {
      setValue("payeeType", "");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculationGroupByValue]);
  return (
    <>
      {renderMessage()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-container calculation-step-form">
          <div className="motif-row t-b-padding-21">
            <div className="motif-col-xs-4">
              <PageTitle
                title={"pages.distributions.distributionStepsTab.distributionCreate.title"}
                description={"pages.distributions.distributionStepsTab.distributionCreate.description"}
              />
            </div>
          </div>
        </div>
        <div className="motif-container main-container step-form">
          <div className="motif-row mar-t-20">
            <div className="motif-col-lg-4">
              <div className="form-field">
                <ControlInputBox
                  name={"stepName"}
                  //@ts-ignore
                  control={control}
                  required={true}
                  isTextArea={false}
                  label={"pages.distributions.distributionStepsTab.stepFormLabels.stepName"}
                  showLabel={true}
                  readonly={false}
                />
              </div>
            </div>
            <div className="motif-col-lg-4">
              <div className="form-field">
                <ControlSelectAccount
                  name={"account"}
                  //@ts-ignore
                  control={control}
                  showLabel={true}
                  label={"pages.distributions.distributionStepsTab.stepFormLabels.account"}
                  options={accountList}
                  required={true}
                  //@ts-ignore
                  setValue={setValue}
                  //@ts-ignore
                  getValues={getValues}
                  onChangeHandler={onAccountChange}
                />
              </div>
            </div>
            <div className="motif-col-lg-4">
              <div className="form-field">
                <SubAccount
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  accountId={watch("account")}
                  setResetSubAccount={setResetSubAccount}
                  resetSubAccount={resetSubAccount}
                  selectedId={selectedId}
                  
                />
              </div>
            </div>
            <div className="motif-col-lg-4 mar-t-20">
              <div className="form-field">
                <ControlSelect
                  name={"calculationGroupBy"}
                  //@ts-ignore
                  control={control}
                  showLabel={true}
                  label={t("pages.distributions.distributionStepsTab.stepFormLabels.calculationGroupBy")}
                  options={claculationGroupByOptions}
                  required={true}
                  //@ts-ignore
                  setValue={setValue}
                  //@ts-ignore
                  getValues={getValues}
                />
              </div>
            </div>
            {displayPayee ? (
              <div className="motif-col-xs-1  motif-col-md-2 motif-col-lg-4 mar-t-20">
                <ControlSelect
                  //@ts-ignore
                  name={"payeeType"}
                  //@ts-ignore
                  control={control}
                  required={true}
                  showLabel={true}
                  label={t("pages.distributions.distributionStepsTab.stepFormLabels.payeeType")}
                  //@ts-ignore
                  placeholder={"placeholder"}
                  //@ts-ignore
                  options={resultSettingList}
                  getValues={getValues}
                  //@ts-ignore
                  setValue={setValue}
                />
                {errors["payeeType"] &&
                  errors["payeeType"].type === "required" && (
                    <MotifErrorMessage>
                      {t("pages.distributions.distributionStepsTab.validationMessages.payeeTypeRequired")}
                    </MotifErrorMessage>
                  )}
              </div>
            ) : (
              ""
            )}
            <div className="motif-col-lg-4 mar-t-20">
              <div className="form-field">
                <ControlSelect
                  name={"payables"}
                  //@ts-ignore
                  control={control}
                  showLabel={true}
                  label={t("pages.distributions.distributionStepsTab.stepFormLabels.memo")}
                  options={payableOptions}
                  required={true}
                  //@ts-ignore
                  setValue={setValue}
                  //@ts-ignore
                  getValues={getValues}
                />
              </div>
            </div>
            <div className="motif-col-lg-6 mar-t-20">
              <div className="form-field">
                <ControlTextAreaWithCharLimit
                  name={"comment"}
                  //@ts-ignore
                  control={control}
                  isTextArea={true}
                  label={"pages.distributions.distributionStepsTab.stepFormLabels.comment"}
                  showLabel={true}
                />
              </div>
            </div>

            <div className="motif-col-lg-6 mar-t-20">
              <div className="form-field">
                <ControlTextAreaWithCharLimit
                  name={"description"}
                  //@ts-ignore
                  control={control}
                  isTextArea={true}
                  label={"pages.distributions.distributionStepsTab.stepFormLabels.description"}
                  showLabel={true}
                />
              </div>
            </div>
          </div>
          <div className="error-container">
            <MotifErrorMessage>
              {errorList.length > 0
                ?t("pages.distributions.distributionStepsTab.validationMessages.mainMessage",{fieldNames:errorsShow})

                : ""}
            </MotifErrorMessage>
          </div>
          <div className="button-container">
            <Button label={t("global.globalActions.save")} loading={loading} disabled={loading} />
            <Button
              type="button"
              label={t("global.globalActions.cancel")}
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={(e) => {
                if(Object?.keys(dirtyFields).length > 0){
                setIsVisible(true);
                setShowMessage({ ...showMessage, show: false, type: "error" });
                dispatch(setDiscardEditMode({ editMode: false }));
                }
                else{
                  cancelBtnApproveHandler(e)
                }

              }}
            />
          </div>
          <div className="motif-row next-row">
            <div className="motif-col-lg-4">
              <div className="form-title-field"></div>
            </div>
          </div>
          <div className="motif-row next-row"></div>
        </div>
      </form>
      <ConfirmationModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
    </>
  );
};

export default CalculationForm;
