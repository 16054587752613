import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifToast,
} from "@ey-xd/motif-react";
import {

  SECONDARY,

} from "@/Constant";
import Button from "@/components/Form/Button/Button";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import EditIcon from "./EditIcon";

import {
  CREDITOR_DUPLICATE,
  FORM_SUBMITTED_DESCRIPTION,
  FORM_SUBMITTED_TITLE,
  ORGANIZATION,
} from "../Constants";
import { postCreditorSetupData } from "../CreditorSetupHelper";
import { useNavigate, useParams } from "react-router-dom";
import CustomAttributeValue from "@/components/CustomAttribute/CustomAttributeValue";
import { resetCreditorSetup, updateStage } from "../creditorSetupSlice";
import { RootState } from "@/app/store";
import { MotifChip } from "@ey-xd/motif-react";
import { useCreateCreditorMutation } from "@/services/creditors/creditorsReducers";
import  MaskedInput  from "@/pages/CaseDetail/Creditor/MaskedInput";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

export default function ReviewForm() {
  const CreditorSetupData = useSelector(
    (state: RootState) => state.creditorSetup
  );
  const { creditorSetupForm, contactDetails } = CreditorSetupData;
  const {
    creditorType,
    firstName,
    lastName,
    prefix,
    description,
    businessName,
    relationShipToDebtor,
  } = creditorSetupForm;
  const { t } = useTranslation();
  const [createCreditor] = useCreateCreditorMutation();
  const { caseId } = useParams<{ caseId: string }>();
  const [isFormOpen, setIsFormOpen] = useState(true);
  const [isFormOpenContact, setIsFormOpenContact] = useState(true);
  const [isFormOpenAttribute, setIsFormOpenAttribute] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [credtorID, setCreditorID] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const creditorAttributesData = useSelector(
    (state: any) => state.creditorAttributes.attributes
  );
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "success",
  });
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
  });
  const [showField, setShowField] = useState<any>({})
  const isOrg = creditorType === ORGANIZATION ? true : false;
  const returnUri = caseId ? `/case/${caseId}?activeTab=2` : "/";
  const cancelBtnApproveHandler = () => {
    setIsVisible(false);
    navigate(returnUri);
    dispatch(resetCreditorSetup());
    dispatch(updateStage(0));
  };
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: true }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch]);
  const saveBtnHandler = async () => {
    setIsLoading(true);
    const attributes = creditorAttributesData.map((value: any) => value.value);
    try {
      const creditorData = postCreditorSetupData({
        ...CreditorSetupData,
        customAttributes: attributes,
        caseId: caseId || "",
      });
      dispatch(setDiscardEditMode({ editMode: false }));

      const responseApi = await createCreditor(creditorData).unwrap();
      if (responseApi?.creditorId) {
        dispatch(resetCreditorSetup());
        setShowMessage({ ...showMessage, show: true, type: "success" });
        setNotification({
          ...notification,
          ...notification,
          show: true,
          message: t("pages.creditors.creditorForm.createSuccessMessage"),
          type: "success",
        });

        setCreditorID(responseApi.creditorId);
        setIsSubmitted(true);
        setIsLoading(false);
        navigate(`/case/${caseId}/creditor/${responseApi.creditorId}`, {
          state: { showMessage: true, caseCreated: true },
        });
      } else {
        const error_message =
          responseApi.data.message === CREDITOR_DUPLICATE
            ? t("pages.creditors.creditorForm.creditorNameAlreadyExists")
            : t("global.globalNotifications.unExpectedError");
        setShowMessage({ ...showMessage, show: true, type: "error" });
        setNotification({
          ...notification,
          ...notification,
          show: true,
          message: t(error_message),
          type: "error",
        });
        setIsLoading(false);
        dispatch(setDiscardEditMode({ editMode: true }));

      }
    } catch (error) {
      setShowMessage({ ...showMessage, show: true, type: "error" });
      setIsLoading(false);
      dispatch(setDiscardEditMode({ editMode: true }));

    }
  };

  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const moveToSelectedTab = (tabNum: number) => {
    dispatch(updateStage(tabNum));
  };

  const handleViewIcon = (name: string) => {
    setShowField((prev: any) => ({ ...prev, [name]: !prev[name] }));
  };

  const renderMessage = () => {
    if (showMessage.show) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={showMessage.type}
        >
          {" "}
          {showMessage.type === "error"
            ? notification.message
            : t("pages.creditors.creditorForm.successfullyUpdatedMessage")}
        </MotifToast>
      );
    }
  };

  if (isSubmitted) {
    return (
      <div className="motif-container pad-l-40 pad-r-20">
        <div className="motif-row">
          <div className="motif-col-lg-12  main-container">
            <div className="motif-row t-b-padding-21">
              <div className="motif-col-xs-4">
                <p className="form-title">{t(FORM_SUBMITTED_TITLE)}</p>
                <p className="form-description">
                  {t(FORM_SUBMITTED_DESCRIPTION)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="motif-row">
          <div className="motif-col-lg-12  main-container">
            <Button
              label={t("View Creditor")}
              type="button"
              onClickHandler={() => {
                navigate(`/case/${caseId}/creditor/${credtorID}`);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="motif-container pad-l-40 pad-r-20">
        {renderMessage()}
        <div className="motif-row">
          <div className="motif-col-lg-12  main-container">
            <div className="motif-row t-b-padding-21">
              <div className="motif-col-xs-4">
                <p className="form-title">{t("pages.creditors.creditorForm.reviewTitle")}</p>
                <p className="form-description">{t("pages.creditors.creditorForm.reviewDescription")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="motif-row t-b-padding-21">
          <div className="motif-col-xs-4">
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              <MotifAccordion
                onClose={() => setIsFormOpen(true)}
                onOpen={() => setIsFormOpen(true)}
                style={{
                  flex: "1",
                }}
                open={isFormOpen}
                useChevronIcon={true}
                alignIconRight={true}
              >
                <MotifAccordionTrigger>
                  <span
                    className="pad-t-10"
                    onClick={() => moveToSelectedTab(0)}
                  >
                    <EditIcon />
                  </span>
                  {t("pages.creditors.creditorForm.creditorDetailsTitle")}
                </MotifAccordionTrigger>
                <MotifAccordionContent>
                  <div className="motif-row">
                    <div className="motif-col-lg-3">
                      <div>
                        <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.creditorType")}</div>
                        <div className="motif-body2-default-bold">
                          {creditorType}
                        </div>
                      </div>
                    </div>
                    <div className={`motif-col-lg-3 ${isOrg ? "hide" : ""}`}>
                      <div>
                        <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.prefix")}</div>
                        <div className="motif-body2-default-bold">
                          {prefix || "-"}
                        </div>
                      </div>
                    </div>
                    <div className={`motif-col-lg-3 ${isOrg ? "hide" : ""}`}>
                      <div>
                        <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.firstName")}</div>
                        <div className="motif-body2-default-bold">
                          {firstName}
                        </div>
                      </div>
                    </div>
                    <div className={`motif-col-lg-3 ${isOrg ? "hide" : ""}`}>
                      <div>
                        <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.lastName")}</div>
                        <div className="motif-body2-default-bold">
                          {lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="motif-row pad-t-30">
                    <div className={`motif-col-lg-3 ${!isOrg ? "hide" : ""}`}>
                      <div>
                        <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.businessName")}</div>
                        <div className="motif-body2-default-bold">
                          {businessName}
                        </div>
                      </div>
                    </div>
                    <div className="motif-col-lg-3">
                      <div>
                        <div className="title">
                          {t("pages.creditors.creditorForm.formLabelDetails.relationshipToDebtor")}
                        </div>
                        <div className="motif-body2-default-bold">
                          {
                            relationShipToDebtor
                          }
                        </div>
                      </div>
                    </div>
                    <div className="motif-col-lg-6">
                      <div>
                        <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.description")}</div>
                        <div className="motif-body2-default-bold">
                          {description}
                        </div>
                      </div>
                    </div>
                  </div>
                </MotifAccordionContent>
              </MotifAccordion>
            </div>
          </div>
        </div>
        <div className="motif-row t-b-padding-21 mar-t-30">
          <div className="motif-col-xs-4">
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              <MotifAccordion
                onClose={() => setIsFormOpenContact(true)}
                onOpen={() => setIsFormOpenContact(true)}
                style={{
                  flex: "1",
                }}
                open={isFormOpenContact}
                useChevronIcon={true}
                alignIconRight={true}
              >
                <MotifAccordionTrigger>
                  <span
                    className="pad-t-10"
                    onClick={() => moveToSelectedTab(1)}
                  >
                    <EditIcon />
                  </span>
                  {t("pages.creditors.creditorForm.contactDetailsTitle")}
                </MotifAccordionTrigger>
                <MotifAccordionContent>
                  {contactDetails.fieldArray.map(
                    (
                      {
                        setAsPrimaryContact,
                        prefix,
                        firstName,
                        lastName,
                        address1,
                        address2,
                        relationShipToCreditor,
                        city,
                        stateName,
                        phone,
                        countryName,
                        fax,
                        postalCode,
                        email,
                        institutionNumber,
                        transitNumber,
                        accountNumber,
                        achCode,
                        ibanNumber,
                        swiftCode,
                        routingCode,
                      },
                      colIndex
                    ) => (
                      <div
                        key={`${colIndex}-${firstName}`}
                        className={`${colIndex > 0 ? "mar-t-10 sep-line" : ""}`}
                      >
                        <div className="motif-container contact-details-wrapper mar-t-20" key={`${colIndex}-${firstName}-contact-details`} >
                        {setAsPrimaryContact && (
                          <div
                            className={`motif-col-lg-12 pad-h-0 ${
                              colIndex > 0 ? "mar-t-10" : "mar-b-10"
                            }`}
                          >
                            <MotifChip dotSide="left">{t("pages.creditors.creditorForm.formLabelDetails.primary")}</MotifChip>
                          </div>
                        )}
                        <div className={`motif-row`}>
                          <div
                            className={`motif-col-lg-3 ${
                              colIndex > 0 ? "pad-t-20" : ""
                            }`}
                          >
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.name")}</div>
                          </div>
                          <div
                            className={`motif-col-lg-3 ${
                              colIndex > 0 ? "pad-t-20" : ""
                            }`}
                          >
                            <div className="motif-body2-default-bold">
                              {` ${prefix} ${firstName} ${lastName}`}
                            </div>
                          </div>
                          <div
                            className={`motif-col-lg-3 ${
                              colIndex > 0 ? "pad-t-20" : ""
                            }`}
                          >
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.address1")}</div>
                          </div>
                          <div
                            className={`motif-col-lg-3 ${
                              colIndex > 0 ? "pad-t-20" : ""
                            }`}
                          >
                            <div className="motif-body2-default-bold">
                              {address1}
                            </div>
                          </div>
                        </div>
                        <div className="motif-row mar-t-10">
                          <div className="motif-col-lg-3 ">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.relationship")}</div>
                          </div>
                          <div className="motif-col-lg-3 ">
                            <div className="motif-body2-default-bold">
                              {relationShipToCreditor || "-"}
                            </div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.address2")}</div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="motif-body2-default-bold">
                              {address2}
                            </div>
                          </div>
                        </div>
                        <div className="motif-row mar-t-10">
                          <div className="motif-col-lg-3 ">
                            <div></div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div>
                              <div className="motif-body2-default-bold"></div>
                            </div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.city")}</div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="motif-body2-default-bold">
                              {city}
                            </div>
                          </div>
                        </div>
                        <div className="motif-row mar-t-10">
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.email")}</div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="motif-body2-default-bold">
                              {email}
                            </div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.provinceState")}</div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div>
                              <div className="motif-body2-default-bold">
                                {stateName}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`motif-row mar-t-10`}>
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.phoneNumber")}</div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="motif-body2-default-bold">
                              {phone}
                            </div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.country")}</div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="motif-body2-default-bold">
                              {countryName}
                            </div>
                          </div>
                        </div>
                        <div className={`motif-row mar-t-10`}>
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.fax")}</div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="title"></div>
                            <div className="motif-body2-default-bold">
                              {fax}
                            </div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.postalCode")}</div>
                          </div>
                          <div className="motif-col-lg-3">
                            <div className="motif-body2-default-bold">
                              {postalCode}
                            </div>
                          </div>
                        </div>
                        </div>
                        <div className="contact-details-wrapper mar-t-30 mar-b-20 payment-fields" >
                        <div className="motif-row mar-t-10" key={colIndex}>
                          <div className="motif-col-lg-3 label-wrapper">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.institutionNumber")}</div>
                          </div>
                          <div className="motif-col-lg-3 ">
                          <MaskedInput
                              value={institutionNumber}
                              name={"institutionNumber"}
                              index={colIndex}
                              handleViewIcon={handleViewIcon}
                              showField={showField}
                            />
                        
                          </div>
                          <div className="motif-col-lg-3 label-wrapper">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.transitNumber")}</div>
                          </div>
                          <div className="motif-col-lg-3">
                          <MaskedInput
                              value={transitNumber}
                              name={"transitNumber"}
                              index={colIndex}
                              handleViewIcon={handleViewIcon}
                              showField={showField}
                            />
                            
                          </div>
                        </div>
                        <div className="motif-row mar-t-10" key={colIndex}>
                          <div className="motif-col-lg-3 label-wrapper">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.accountNumber")}</div>
                          </div>
                          <div className="motif-col-lg-3 ">
                          <MaskedInput
                              value={accountNumber}
                              name={"accountNumber"}
                              index={colIndex}
                              handleViewIcon={handleViewIcon}
                              showField={showField}
                            />
                            
                          </div>
                          <div className="motif-col-lg-3 label-wrapper">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.IBANNumber")}</div>
                          </div>
                          <div className="motif-col-lg-3">
                            <MaskedInput
                              value={ibanNumber}
                              name={"ibanNumber"}
                              index={colIndex}
                              handleViewIcon={handleViewIcon}
                              showField={showField}/>
                          </div>
                        </div>
                        <div className="motif-row mar-t-10" key={colIndex}>
                          <div className="motif-col-lg-3 label-wrapper ">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.SWIFTCode")}</div>
                          </div>
                          <div className="motif-col-lg-3 ">
                            <MaskedInput value={swiftCode} name={"swiftCode"} index={colIndex} handleViewIcon={handleViewIcon} showField={showField}/>
                          </div>
                          <div className="motif-col-lg-3 label-wrapper">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.routingCode")}</div>
                          </div>
                          <div className="motif-col-lg-3">
                            <MaskedInput value={routingCode} name={"routingCode"} index={colIndex} handleViewIcon={handleViewIcon} showField={showField}/>
                          </div>
                        </div>
                        <div className="motif-row mar-t-10" key={colIndex}>
                          <div className="motif-col-lg-3 label-wrapper ">
                            <div className="title">{t("pages.creditors.creditorForm.formLabelDetails.ACHCode")}</div>
                          </div>
                          <div className="motif-col-lg-3 ">
                            <MaskedInput value={achCode} name={"achCode"} index={colIndex} handleViewIcon={handleViewIcon} showField={showField}/>
                          </div>
                        </div>
                        </div>
                      </div>
                    )
                  )}
                </MotifAccordionContent>
              </MotifAccordion>
            </div>
          </div>
        </div>
        <div className="motif-row t-b-padding-21 mar-t-30">
          <div className="motif-col-xs-4">
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              <MotifAccordion
                onClose={() => setIsFormOpenAttribute(true)}
                onOpen={() => setIsFormOpenAttribute(true)}
                style={{
                  flex: "1",
                }}
                open={isFormOpenAttribute}
                useChevronIcon={true}
                alignIconRight={true}
              >
                <MotifAccordionTrigger>
                  <span
                    className="pad-t-10"
                    onClick={() => moveToSelectedTab(2)}
                  >
                    <EditIcon />
                  </span>
                  {t("pages.creditors.creditorForm.formLabelDetails.attributeConfiguration")}
                </MotifAccordionTrigger>
                <MotifAccordionContent>
                  {
                    <div className={"motif-row mar-t-20"}>
                      {creditorAttributesData.map((attribute: any) => {
                        return (
                          <div className="motif-col-md-2">
                            <div className="title">{attribute.name}</div>
                            <div className="motif-body2-default-bold">
                              <CustomAttributeValue
                                attributeType={attribute.value.attributeType}
                                value={attribute.value}
                                picklistDefinitions={
                                  attribute.picklistDefinitions
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  }
                </MotifAccordionContent>
              </MotifAccordion>
            </div>
          </div>
        </div>
        <div className="button-container">
          <Button
            label={t("global.globalActions.save")}
            onClickHandler={() => saveBtnHandler()}
            type={isLoading ? "loader" : "submit"}
            disabled={isLoading}
          />
          <Button
            type="button"
            label={t("global.globalActions.cancel")}
            variant={SECONDARY}
            className="cancelBtn"
            onClickHandler={() => {setIsVisible(true);      dispatch(setDiscardEditMode({ editMode: false }));
          }}
          />
        </div>
        <ConfirmationModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          cancelBtnApproveHandler={cancelBtnApproveHandler}
          cancelBtnRejectHandler={() => {setIsVisible(false);      dispatch(setDiscardEditMode({ editMode: true }));
        }}
          type={2}
        />
      </div>
    </>
  );
}
