import { MotifTable } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import "@/components/Table/style.scss";
import { useMemo } from "react";
import PaginationContext from "@/contexts/PaginationContext";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

type TableParams = {
  columnDefs: Array<Object>;
  cellRenderers: Array<Object>;
  TitleComplement: React.FC,
  title: string;
  description: string;
  caseRenderers?: Object;
  pagination?: boolean;
  defaultColDef?: any;
  getRowStyle?:any;
  grandTotalRow?: any;
  autoGroupColumnDef?:any;
  gridRef?:any;
  onGridReady?: any;
  pinnedBottomRowData?:any;
  overlayNoRowsTemplate?:any;
  onRowClicked?: (params: any) => void; 
  onRowSelected?: (params: any) => void;
  onSelectionChanged?: (params: any) => void;
  rowSelection?: "single" | "multiple";
  columnTypes?:any;
  handlePaginationData?:(data:any,columnTypesData:any)=>void,
  paginationObj?:any,
  titleExt?: string;
};

export default function Table({
  columnDefs,
  cellRenderers,
  TitleComplement,
  title,
  description,
  caseRenderers,
  defaultColDef,
  pagination,
  grandTotalRow,
  autoGroupColumnDef,
  gridRef,
  onGridReady,
  pinnedBottomRowData,
  overlayNoRowsTemplate,
  onRowClicked,
  onRowSelected,
  onSelectionChanged,
  rowSelection,
  columnTypes,
  handlePaginationData,
  paginationObj,
  titleExt
}: TableParams) {

  const { t } = useTranslation();
  // sets 10 rows per page (default is 100)
  const paginationPageSize = 10;
  // allows the user to select the page size from a predefined list of page sizes
  const paginationPageSizeSelector = [10, 20, 50];

  const contextValue = useMemo(() => ({ paginationPageSize }), [paginationPageSize]);
  function handleMotifSorting(e:any,paginationObj:any){
      let sortedColumnsLength:any=e?.columns?e.columns.length:""
      if(sortedColumnsLength!==""){
    let coumnName=e?.columns?.[sortedColumnsLength-1]?.colId
    let sortName=e?.columns?.[sortedColumnsLength-1]?.sort
    let typeName=e?.columns?.[sortedColumnsLength-1]?.colDef?.type
      let paginationJson:any={...paginationObj}
      let columnTypesCustom:any={}
      if(typeName){
        columnTypesCustom[typeName]={sort:sortName}
      }
      paginationJson.SortOrder=sortName
      paginationJson.SortColumn=coumnName?.charAt(0)?.toUpperCase() + coumnName?.slice(1) 
      if(!sortName){
       delete paginationJson.SortOrder 
       delete paginationJson.SortColumn
      }
      if(e?.columns[0]?.colDef?.field){ 
      if(handlePaginationData){
      handlePaginationData(paginationJson,columnTypesCustom)
      }
    }
  }
     
  }
  // this validation will triger only if custom sorting is required
  if(handlePaginationData){
    if(defaultColDef){
      defaultColDef={...defaultColDef,comparator: () => 0,
      }}
      else{
        defaultColDef={comparator: () => 0}
    }
    }
    let titleName=t(title)
    if(titleExt || titleExt===""){
      titleName=t(title, { titleExt:titleExt })
    }


  return (
    <PaginationContext.Provider value={contextValue}>
      <MotifTable
              columnTypes={columnTypes?{...columnTypes}:{}}
            onSortChanged={(e:any) => {
              if(handlePaginationData){
                handleMotifSorting(e,paginationObj)
              }
                  }}

      columnDefs={columnDefs}
      components={caseRenderers}
      description={t(description)}
      onFilterChanged={function Ga() {}}
      pagination={pagination}
      paginationPageSize={paginationPageSize}
      paginationPageSizeSelector={paginationPageSizeSelector}
      rowData={cellRenderers}
      suppressRowClickSelection
      title={titleName}
      titleComplement={<TitleComplement />}
      defaultColDef={defaultColDef}
      domLayout="autoHeight"
      grandTotalRow={grandTotalRow}
      autoGroupColumnDef={autoGroupColumnDef}
      ref={gridRef}
      onGridReady={onGridReady}
      pinnedBottomRowData= {pinnedBottomRowData}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      onRowClicked={onRowClicked}
      onRowSelected={onRowSelected}
      rowSelection={rowSelection}
      onSelectionChanged={onSelectionChanged}
      />
    </PaginationContext.Provider>
  );
}
