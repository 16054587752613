import {
  MotifButton,
  MotifIcon,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifToast,
} from "@ey-xd/motif-react";
import { IconoirWarningTriangle } from "@ey-xd/motif-icon";
import { useState } from "react";
import { useDisableDebtorMutation } from "@/services/debtors/debtorsReducers";
import { useTranslation } from "react-i18next";

type NoClaimConst = {
  showModal?: boolean;
  setShowModal?: any;
  activeClaim?: Number;
  setActiveClaim?: any;
  debtorId?: Number;
  setDebtorId?: any;
  refetch?:any;
};

export function NoClaimModel({
  showModal,
  setShowModal,
  debtorId,
  refetch
}: NoClaimConst) {
  const { t }=useTranslation()
  const [successPopUp, setSuccessPopUp] = useState(false);

  const [disableDebtor] = useDisableDebtorMutation();
  function disabledebtor() {
    const response = disableDebtor(debtorId);
    if (response.hasOwnProperty("data")) {
      setShowModal(false);
      setSuccessPopUp(true);
      refetch();
    } else {
      setShowModal(false);
      refetch();
    }
  }
  return (
    <>
      <MotifModal
        onClose={() => setShowModal(false)}
        show={showModal}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <div className="confirmationModalWrapper creditor_disabling_model">
          <MotifModalHeader
            headerIcon={
              <>
                <span
                  style={{
                    backgroundColor: "rgb(252, 245, 243)",
                    borderRadius: "50px",
                    padding: "5px 8px 2px 8px",
                  }}
                >
                  <MotifIcon
                    iconFunction={IconoirWarningTriangle}
                    strokeWidth="1"
                    size="15"
                    stroke="red"
                  />{" "}
                </span>
                <span
                  style={{
                    fontSize: 16,
                    display: "block",
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  {t("global.globalNotifications.warning")}
                </span>
              </>
            }
          ></MotifModalHeader>

          <MotifModalBody style={{ paddingTop: "0px" }}>
            <span style={{ fontWeight: "300" }}>
{t("pages.debtors.debtorDisablePopup.disableDebtorMessage")}         
   </span>
          </MotifModalBody>
          <MotifModalFooter>
            <MotifButton
              size="small"
              type="submit"
              variant="secondary"
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              onClick={disabledebtor}
            >
              {t("global.globalActions.yes")}
              
            </MotifButton>
            <MotifButton
              className=""
              onClick={() => setShowModal(false)}
              size="small"
              type="button"
            >
              {t("global.globalActions.cancel")}
            </MotifButton>
          </MotifModalFooter>
        </div>
      </MotifModal>
      {successPopUp && (
        <MotifToast
          onClose={() => setSuccessPopUp(false)}
          variant="success"
          className="mar-b-20"
        >
          {t("pages.debtors.debtorDisablePopup.disableSuccessMessage")}
        </MotifToast>
      )}
    </>
  );
}

export function ActiveClaimModel({ showModal, setShowModal }: NoClaimConst) {
  const {t}=useTranslation()
  return (
    <MotifModal
      onClose={() => setShowModal(false)}
      show={showModal}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
    >
      <div className="confirmationModalWrapper creditor_disabling_model">
        <MotifModalHeader
          headerIcon={
            <>
              <span
                style={{
                  backgroundColor: "rgb(252, 245, 243)",
                  borderRadius: "50px",
                  padding: "5px 8px 2px 8px",
                }}
              >
                <MotifIcon
                  iconFunction={IconoirWarningTriangle}
                  strokeWidth="1"
                  size="15"
                  stroke="red"
                />{" "}
              </span>
              <span
                style={{
                  fontSize: 16,
                  display: "block",
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                {t("global.globalNotifications.warning")}
              </span>
            </>
          }
        ></MotifModalHeader>
        <MotifModalBody style={{ paddingTop: "0px" }}>
          <span style={{ fontWeight: "300" }}>
            {t("pages.debtors.debtorDisablePopup.cannotDeleteDebtor")}
          </span>
        </MotifModalBody>
        <MotifModalFooter>
          <MotifButton
            className=""
            onClick={() => setShowModal(false)}
            size="small"
            type="button"
          >
            {t("global.globalActions.cancel")}
          </MotifButton>
        </MotifModalFooter>
      </div>
    </MotifModal>
  );
}
