import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import {
	MotifButton,
	MotifFormField,
	MotifLabel,
	MotifIcon,
	MotifSelect,
	MotifOption,
} from "@ey-xd/motif-react";
import {
	MotifActionIcDelete24px,
} from "@ey-xd/motif-icon";
import ControlSelectBoxAttribute from "./ControlSelectBoxAttribute";
import ControlSelectBoxOperator from "./ControlSelectBoxOperator";
import ValueElement from "./ValueElement";

const Group = ({
	index,
	setValue,
	field,
	control,
	remove,
	attributes,
	handleAttributeChange,
	handleOperatorChange,
	treeRows,
	shouldHideValueElement,
	attributeOperators,
}: any) => {
	const { t } = useTranslation();
	const nestedFieldArray = useFieldArray({
		control,
		name: `treeRows.${index}.treeRows`,
	});
	const addRow = () => {
		nestedFieldArray.append({
			attribute: "",
			operator: "",
			value: "",
			type: "row",
		});
	};
	const removeRow = (groupIndex: number) => {
		nestedFieldArray.remove(groupIndex);
	};
	return (
		<div className="motif-row">
			<div className="motif-col-md-1 motif-col-lg-1 horizontal-line-container">
				<div className="vertical-line"></div>
				<div className="horizontal-line mar-b-20"></div>
			</div>
			<div className="motif-col-md-12 motif-col-lg-10 mar-b-20 group-container">
				<div
					className="motif-row"
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					<div className="motif-col-md-2 motif-col-lg-2">
						<MotifSelect
							ariaLabelledBy="select-label"
							onChange={(value) => {
								setValue(`treeRows.${index}.logicalOperator`, value);
							}}
							value={field.logicalOperator.toString()}
							visibleOptions={3}
							className="select-box-width"
						>
							<MotifOption value="0">
								{t("pages.configuration.scheme.rule.and")}
							</MotifOption>
							<MotifOption value="1">
								{t("pages.configuration.scheme.rule.or")}
							</MotifOption>
						</MotifSelect>
					</div>
					<div className="motif-col-md-1 motif-col-lg-1">
						<MotifButton
							onClick={() => remove(index)}
							size="small"
							type="button"
							variant="secondary"
						>
							<MotifIcon iconFunction={MotifActionIcDelete24px} />
						</MotifButton>
					</div>
				</div>
				{nestedFieldArray.fields.map((row: any, rowIndex: number) => {
					const operator =
						rowIndex !== undefined
							? treeRows[index]?.treeRows[rowIndex]?.operator
							: treeRows[index]?.operator;

					return (
						<div className="motif-row" key={row.id}>
							<div className="motif-col-md-1 motif-col-lg-1 horizontal-line-container">
								<div className="vertical-line"></div>
								<div className="horizontal-line mar-b-20"></div>
							</div>
							<div className="motif-col-md-2 mar-b-20">
								<ControlSelectBoxAttribute
									index={index}
									groupIndex={rowIndex}
									control={control}
									attributes={attributes}
									handleAttributeChange={handleAttributeChange}
								/>
							</div>
							<div className="motif-col-md-2 mar-b-20">
								<ControlSelectBoxOperator
									index={index}
									groupIndex={rowIndex}
									control={control}
									attributeOperators={attributeOperators}
									treeRows={treeRows}
									handleOperatorChange={handleOperatorChange}
								/>
							</div>
							<div className="motif-col-md-2 mar-b-20">
								{!shouldHideValueElement(operator) && (
									<MotifLabel aria-label="Input" htmlFor="input">
										{t("pages.configuration.scheme.rule.value")}
									</MotifLabel>
								)}

								<MotifFormField aria-label="Input" role="group">
									{!shouldHideValueElement(operator) && (
										<ValueElement
											index={index}
											groupIndex={rowIndex}
											control={control}
											treeRows={treeRows}
										/>
									)}
								</MotifFormField>
							</div>
							{rowIndex > 0 && (
								<div className="motif-col-md-1 mar-b-20">
									<MotifButton
										onClick={() => removeRow(rowIndex)}
										size="small"
										type="button"
										variant="secondary"
										className="delete-button"
									>
										<MotifIcon iconFunction={MotifActionIcDelete24px} />
									</MotifButton>
								</div>
							)}
						</div>
					);
				})}
				<div className="motif-row">
					<div className="motif-col-md-2 motif-col-lg-2">
						<MotifButton
							onClick={() => addRow()}
							size="small"
							type="button"
							variant="secondary"
						>
							{t("pages.configuration.scheme.rule.add")}
						</MotifButton>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Group;
