import React from 'react';
import { Link } from 'react-router-dom';
import { MotifBreadcrumb, MotifBreadcrumbItem } from '@ey-xd/motif-react';

type BreadcrumbItem = {
    text: string;
    url?: string;
    icon?: JSX.Element;
    variant?: 'default' | 'alt';
    disabled?: boolean;
};

type BreadcrumbProps = {
  items: BreadcrumbItem[];
  ariaLabel?: string;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, ariaLabel }) => {
  return (
    <MotifBreadcrumb aria-label={ariaLabel}>
      {items.map((item, index) => (
        <MotifBreadcrumbItem
          key={index}
          variant={item.variant || "default"}
          disabled={item.disabled}
        >
          {item.url ? (
            <Link
              to={item.url}
              className="display-flex align-items-center breadcrumb-link"
            >
              {item.icon && index === 0 && <span>{item.icon}</span>}
              <span
                className={`${
                  item.icon && index === 0 ? "pad-l-10" : "margin-left-0"
                }`}
              >
                {item.text}
              </span>
            </Link>
          ) : (
            <div className="display-flex align-items-center">
              {item.icon && <span>{item.icon}</span>}
              <span className={`pad-l-10 ${item.icon ? "" : "margin-left-0"}`}>
                {item.text}
              </span>
            </div>
          )}
        </MotifBreadcrumbItem>
      ))}
    </MotifBreadcrumb>
  );
};

export default Breadcrumb;