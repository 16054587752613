export const quickActionHeading ="pages.home.quickActions.quickActionHeading";
export const quickActions = [
    {
      header: "pages.home.quickActions.quickActionsList.newCaseSetup.header",
      body: "pages.home.quickActions.quickActionsList.newCaseSetup.body",
      footer: "pages.home.quickActions.quickActionsList.newCaseSetup.footer",
      link: "/case-setup",
    },
    {
      header: "pages.home.quickActions.quickActionsList.applicationAdminSettings.header",
      body: "pages.home.quickActions.quickActionsList.applicationAdminSettings.body",
      footer: "pages.home.quickActions.quickActionsList.applicationAdminSettings.footer",
      link: "/user-management",
    },
    {
      header: "pages.home.quickActions.quickActionsList.formConfiguration.header",
      body: "pages.home.quickActions.quickActionsList.formConfiguration.body",
      footer: "pages.home.quickActions.quickActionsList.formConfiguration.footer",
      link: "",
    },
  ];