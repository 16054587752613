import { dateFunc } from "@/components/Functions/dateFunc";
import {
  useGetClaimSummaryViewApiDataQuery,
  useGetcreditorContactsApiDataQuery,
  useGetcreditorContactsDataApiDataQuery,
} from "@/services/claimSummary/caseSummaryReducer";
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifChip,
  MotifLabel,
} from "@ey-xd/motif-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditContactFields } from "./editContactFields";
import { useTranslation } from "react-i18next";

export const CreditorContactsEdit = ({formSubmitted,resolvedStatus}:any) => {
  const [contactId, setContactId] = useState("");
  const [edit, setEdit] = useState(false);
  const parameter = useParams();
  const { data } = useGetClaimSummaryViewApiDataQuery(
    parameter?.claimId
  );
  const {t}=useTranslation()
  const {
    data: getCreditorData,
  } = useGetcreditorContactsApiDataQuery(
    data.claimSummary?.creditorId || ""
  );
  const {
    data: getCreditorApiData,
  } = useGetcreditorContactsDataApiDataQuery(contactId || "");
  useEffect(()=>{
    setContactId(data?.creditorContact?.contactId)
    // eslint-disable-next-line
  },[data?.creditorContact?.contactId !== ""])
  return (
    <>
      <div className="motif-container case-summary-form pad-v-20">
        <div className="motif-body1-default-regular page-title">
        {t("pages.claims.claimsEditTab.formLabelDetails.editCreditorContactsTitle")}        </div>
        <div className="motif-body2-default-regular page-description">
        {t("pages.claims.claimsEditTab.formLabelDetails.editCreditorContactsDescription")}       
        </div>
      </div>
      <div className="motif-container main-container ccwp_claim_summary_form ccwp_claim_summary_white_accordian pad-t-20 mar-b-20 pad-b-20">
        <div className="ccwp_claim_creditor_contacts_edit">
          <MotifLabel id="select-label" className="mar-b-10">
          {t("pages.claims.claimsEditTab.formLabelDetails.selectClaimContact")}       
          </MotifLabel>
          <select
            onChange={(e: any) => setContactId(e.target.value)}
            className="mar-b-20"
            value={data?.creditorContact?.contactId}
            disabled={resolvedStatus}
            style={resolvedStatus ? { background: "#f3f3f5" } : {}}
          >
            <option>
            {t("pages.claims.claimsEditTab.formLabelDetails.selectClaimContact")}       
            </option>
            {getCreditorData &&
              getCreditorData?.map((items: any) => {
                return (
                  <option
                    value={items.contactId}
                    selected={
                      items.contactId === data?.creditorContact?.contactId
                    }
                  >
                    {items.firstName + " " + items.lastName}
                  </option>
                );
              })}
          </select>
          {getCreditorApiData?.firstName && !edit && (
            <MotifAccordion
              onClose={function Ua() {}}
              onOpen={function Ua() {}}
              style={{
                flex: "1",
              }}
              alignIconRight={true}
              useChevronIcon={true}
              className="mar-b-20"
            >
              <MotifAccordionTrigger>
                <div className="ccwp_claim_summary_white">
                  <ul>
                    <li>
                      <label>{t("pages.claims.commonFieldLabels.name")}</label>
                      <p>
                        {getCreditorApiData?.firstName}{" "}
                        {getCreditorApiData?.lastName}
                      </p>
                    </li>
                    <li>
                      {getCreditorApiData?.isPrimaryContact === true && (
                        <MotifChip dotSide="left">{t("pages.claims.commonFieldLabels.primary")}</MotifChip>
                      )}
                    </li>
                    <li onClick={() => setEdit(true)}>
                      {/* <MotifIcon
                        iconFunction={IconoirEditPencil}
                        stroke="#656579"
                        strokeWidth="2"
                        size="20"
                      /> */}
                    </li>
                  </ul>
                </div>
              </MotifAccordionTrigger>
              <MotifAccordionContent>
                <ul className="ccwp_claim_summary_details">
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.firstName")}</label>
                    <p>
                      {!getCreditorApiData?.firstName
                        ? "-"
                        : getCreditorApiData?.firstName}
                    </p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.lastName")}</label>
                    <p>
                      {!getCreditorApiData?.lastName
                        ? "-"
                        : getCreditorApiData?.lastName}
                    </p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.address1")}</label>
                    <p>
                      {!getCreditorApiData?.address1
                        ? "-"
                        : getCreditorApiData?.address1}
                    </p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.address2")}</label>
                    <p>
                      {!getCreditorApiData?.address2
                        ? "-"
                        : getCreditorApiData?.address2}
                    </p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.city")}</label>
                    <p>
                      {!getCreditorApiData?.city
                        ? "-"
                        : getCreditorApiData?.city}
                    </p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.provincestate")}</label>
                    <p>
                      {!getCreditorApiData?.provinceState
                        ? "-"
                        : getCreditorApiData?.provinceState}
                    </p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.postalCode")}</label>
                    <p>
                      {!getCreditorApiData?.postalCode
                        ? "-"
                        : getCreditorApiData?.postalCode}
                    </p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.phone")}</label>
                    <p>
                      {!getCreditorApiData?.phone
                        ? "-"
                        : getCreditorApiData?.phone}
                    </p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.email")}</label>
                    <p>
                      {!getCreditorApiData?.email
                        ? "-"
                        : getCreditorApiData?.email}
                    </p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.lastUpdatedOn")}</label>
                    <p>
                      {!getCreditorApiData?.updatedOn
                        ? "-"
                        : dateFunc(getCreditorApiData?.updatedOn).formattedDateOnly}
                    </p>
                  </li>
                  <li>
                    <label>{t("pages.claims.commonFieldLabels.lastUpdatedBy")}</label>
                    <p>
                      {!getCreditorApiData?.updatedBy
                        ? "-"
                        : getCreditorApiData?.updatedBy}
                    </p>
                  </li>
                </ul>
              </MotifAccordionContent>
            </MotifAccordion>
          )}
          {edit && (
            <EditContactFields
              claiSummaryData={data}
              getCreditorApiData={getCreditorApiData}
              formSubmitted={formSubmitted}
            />
          )}
        </div>
      </div>
    </>
  );
};
