import ControlInputBox from "../../ControlInputBoxClaimSetupCreditor";
type ContactFormInputProps = {
    name: string;
    control: any;
    label: string;
    isNumber?: boolean;
    required?: boolean;
    isHidden?: boolean;
    isTextArea?: boolean;
}

const ContactFormInput = ({ name, control, label, isNumber = false, required=false, isHidden, isTextArea= false }:ContactFormInputProps) => {
    const rules = isNumber ? {
                    pattern: {
                    value: /^[.0-9]+$/,
                    },
                } : {};
    return (
        <div className={isHidden ? 'hide' : ''}>
        <ControlInputBox
            // @ts-ignore
            name={name}
            // @ts-ignore
            control={control}
            isTextArea={isTextArea}
            label={label}
            showLabel={true}
            required={required}
            rules={rules}
        />
        </div>
    );
}
export default ContactFormInput;