import { MotifErrorMessage } from "@ey-xd/motif-react";
import { POSTAL_CODE_FIELD, PREFIX_NAME } from "../../Constants";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  getNewCrediotrDetails,
  getStateListByCountry,
} from "../CreditorHelper";
import ContactSelect from "./ContactFormFields/ContactSelect";
import ContactFormInput from "./ContactFormFields/ContactFormInput";
import {
  SECONDARY,
} from "@/Constant";
import { useEffect, useState } from "react";
import {
  useGetCountryStateListDataQuery,
  useGetStateByCountryIdQuery,
} from "@/services/countryState/countryStateReducer";
import { countryRecordType } from "@/pages/CaseDetail/Creditor/CreditorSetup/type";
import StateDropdown from "./ContactFormFields/StateDropdown";
import {
  INDIVIDUAL,
  ORGANIZATION,
  CREDITOR_TYPE,
  BUSINESS_NAME,
  DESCRIPTION,
  RELATIONSHIP_TO_DEBTOR,
  FIRST_NAME as CREDITOR_FIRST_NAME,
  LAST_NAME as CREDITOR_LAST_NAME,
} from "@/pages/CaseDetail/Creditor/CreditorSetup/Constants";
import RadioButtonGroup from "./ContactFormFields/RadioButtonGroup";
import PageSubTitle from "@/components/Form/PageSubTitle/PageSubTitle";
import Button from "@/components/Form/Button/Button";

import { Dispatch, SetStateAction } from "react";
import {
  CreditorSearchData,
  setIsNewCreditorType,
  setNewCreditorDetail,
} from "../creditorSlice";
import { useDispatch, useSelector } from "react-redux";
// import useResetAllClaimItemsStore from "../../useResetAllClaimItemsStore";
import { ClaimNewCreditorFormType } from "../type";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";

type NewContactFormProps = {
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  goToNextTab: () => void;
};

interface RootState {
  claimSetupCreditorSearch: CreditorSearchData;
}

export default function NewContactForm({
  setIsVisible,
  goToNextTab,
}: NewContactFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [creditorType, setCreditorType] = useState(INDIVIDUAL);
  const defaultOptions = [{ label: "Please Select", value: "" }];
  const [countryArray, setCountryArry] = useState(defaultOptions);
  // const resetAllClaimItemsStore = useResetAllClaimItemsStore();

  const newCreditorDetailStore = useSelector(
    (state: RootState) => state.claimSetupCreditorSearch.newCreditorDetail
  );

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({ mode: "onChange" });

  const countrySelectedId = String(watch(`contact_countryId`));

  const { data, isLoading } = useGetCountryStateListDataQuery("");

  const { data: stateList } = useGetStateByCountryIdQuery(countrySelectedId, {
    skip:
      countrySelectedId === "" ||
      countrySelectedId === undefined ||
      countrySelectedId === "0",
  });

  useEffect(() => {
    if (data !== undefined) {
      // eslint-disable-next-line array-callback-return
      const countryOptions = data.map((record: countryRecordType) => {
        return {
          label: record.countryName,
          value: record.countryId,
        };
      });
      setCountryArry(countryOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  useEffect(() => {
    if (creditorType === INDIVIDUAL) {
      setValue("businessName", "");
    } else {
      setValue("prefix", "");
      setValue("firstName", "");
      setValue("lastName", "");
    }
  }, [creditorType, setValue]);

  useEffect(() => {
    if (newCreditorDetailStore !== undefined) {
      setValue(`prefix`, newCreditorDetailStore.prefix);
      setValue(`firstName`, newCreditorDetailStore.firstName);
      setValue(`lastName`, newCreditorDetailStore.lastName);
      setValue(`businessName`, newCreditorDetailStore.businessName);
      setValue(`description`, newCreditorDetailStore.description);
      setValue(
        `relationShipToDebtor`,
        newCreditorDetailStore.relationshipToDebtor
      );
      setValue(`contact_firstName`, newCreditorDetailStore.contact.firstName);
      setValue(`contact_lastName`, newCreditorDetailStore.contact.lastName);
      setValue(`contact_prefix`, newCreditorDetailStore.contact.prefix);
      setValue(`contact_address1`, newCreditorDetailStore.contact.address1);
      setValue(`contact_address2`, newCreditorDetailStore.contact.address2);
      setValue(`contact_city`, newCreditorDetailStore.contact.city);
      setValue(
        `contact_provinceStateId`,
        newCreditorDetailStore.contact.provinceStateId
      );
      setValue(`contact_postalCode`, newCreditorDetailStore.contact.postalCode);
      setValue(`contact_countryId`, newCreditorDetailStore.contact.countryId);
      setValue(`contact_phone`, newCreditorDetailStore.contact.phone);
      setValue(`contact_fax`, newCreditorDetailStore.contact.fax);
      setValue(`contact_email`, newCreditorDetailStore.contact.email);
      setValue(
        `contact_relationshipToCreditor`,
        newCreditorDetailStore.contact.relationshipToCreditor
      );
    }
  }, [newCreditorDetailStore, setValue]);

  // useEffect(() => {
  //   if (stateList !== undefined && stateList.length > 0) {
  //     const stateListBycounrty = getStateListByCountry(stateList);
  //     setValue("provinceStateOptions", stateListBycounrty);
  //   } else {
  //     setValue("provinceStateOptions", defaultOptions);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoadingStateList, stateList]);

  // if (isLoadingStateList) {
  //   return <MotifProgressLoader show variant="default" />;
  // }
  const PREFIX_OPTIONS_LIST = [
    { 
        label: t("global.globalActions.optionDefault"), 
        value: "" },
    {
        label:t("global.globalActions.prefixOptionsListLabels.mr"),
        value: "Mr.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.ms"),
        value: "Ms.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.mrs"),
        value: "Mrs.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.miss"),
        value: "Miss.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.other"),
        value: "other",
    }
]
  const stateListBycounrty = getStateListByCountry(stateList);
  const stateId = stateListBycounrty && stateListBycounrty?.length > 0 ?
    stateListBycounrty.find(
      (option) => option.value === data?.contact_provinceStateId
    )?.value || "" : "";
  const onSubmit = (data: ClaimNewCreditorFormType) => {
    const newCreditorDetail = getNewCrediotrDetails(
      data,
      stateListBycounrty,
      countryArray,
      countrySelectedId,
      creditorType
    );
    dispatch(setIsNewCreditorType(true));
    dispatch(setNewCreditorDetail(newCreditorDetail));
    goToNextTab();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="new-creditor-container">
          <div>
            <p className="form-description">{t("pages.claims.claimCreate.creditorTab.newCreditorDescription")}</p>
          </div>
          <div>
            <RadioButtonGroup
              name={CREDITOR_TYPE}
              required={true}
              defaultValue={
                newCreditorDetailStore.isOrg === true
                  ? ORGANIZATION
                  : INDIVIDUAL
              }
              options={[INDIVIDUAL, ORGANIZATION]}
              creditorType={creditorType}
              setCreditorType={setCreditorType}
            />
          </div>
          <div
            className={`motif-row ${creditorType === ORGANIZATION ? "hide" : ""
              }`}
          >
            <div className="motif-col-lg-6">
              {/*@ts-ignore*/}
              <ContactSelect
                label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.prefix"}
                name={PREFIX_NAME}
                options={PREFIX_OPTIONS_LIST}
                control={control}
              />
              {errors[PREFIX_NAME] &&
                errors[PREFIX_NAME].type === "required" && (
                  <MotifErrorMessage>
                    {t("pages.claims.claimCreate.creditorTab.validationMessages.prefixMessage")}
                  </MotifErrorMessage>
                )}
            </div>
            <div className="motif-col-lg-6">
              <ContactFormInput
                name={CREDITOR_FIRST_NAME}
                control={control}
                label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.firstName"}
                required={creditorType === ORGANIZATION ? false : true}
              />
              {errors[CREDITOR_FIRST_NAME] &&
                errors[CREDITOR_FIRST_NAME].type === "required" && (
                  <MotifErrorMessage>
                    {t("pages.claims.claimCreate.creditorTab.validationMessages.firstNameMessage")}
                    </MotifErrorMessage>
                )}
            </div>
          </div>
          <div className="motif-row pad-t-30">
            <div
              className={`motif-col-lg-6 ${creditorType === ORGANIZATION ? "hide" : ""
                }`}
            >
              <ContactFormInput
                name={CREDITOR_LAST_NAME}
                control={control}
                label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.lastName"}
                required={creditorType === ORGANIZATION ? false : true}
              />
              {errors[CREDITOR_LAST_NAME] &&
                errors[CREDITOR_LAST_NAME].type === "required" && (
                  <MotifErrorMessage>
                    {t("pages.claims.claimCreate.creditorTab.validationMessages.lastNameMessage")}
                    </MotifErrorMessage>
                )}
            </div>
            <div
              className={`motif-col-lg-6 ${creditorType === ORGANIZATION ? "" : "hide"
                }`}
            >
              <ContactFormInput
                name={BUSINESS_NAME}
                control={control}
                label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.businessName"}
                required={creditorType === ORGANIZATION ? true : false}
              />
              {errors[BUSINESS_NAME] &&
                errors[BUSINESS_NAME].type === "required" && (
                  <MotifErrorMessage>
                    {t("pages.claims.claimCreate.creditorTab.validationMessages.businessNameMessage")}
                    </MotifErrorMessage>
                )}
            </div>
          </div>
          <div className="motif-row pad-t-30">
            <div className={`motif-col-lg-6`}>
              <ControlTextAreaWithCharLimit
                name={DESCRIPTION}
                control={control}
                label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.description"}
                required={false}
                isTextArea={true}
                showLabel={true}
              />
              {errors[DESCRIPTION] &&
                errors[DESCRIPTION].type === "required" && (
                  <MotifErrorMessage>
                    {t("pages.claims.claimCreate.creditorTab.validationMessages.descriptionMessage")}
                    </MotifErrorMessage>
                )}
            </div>
            <div className={`motif-col-lg-6`}>
              {/*@ts-ignore*/}
              <ContactFormInput
                name={RELATIONSHIP_TO_DEBTOR}
                control={control}
                label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.relationshipToDebtor"}
                required={false}
              />
            </div>
          </div>
          <PageSubTitle title={"pages.claims.claimCreate.creditorTab.newCreditorContactDetailsTile"} />
          <div className="motif-body1-default-regular primary-contact-title">
            {t("pages.claims.claimCreate.creditorTab.formLabelsDetails.primaryContactDetails")}
          </div>
        </div>
        <div className="motif-row mar-t-30">
          <div className="motif-col-lg-6">
            <ContactFormInput
              name={`contact_firstName`}
              control={control}
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.firstName"}
              required={true}
            />
            {errors[`contact_firstName`] &&
              errors[`contact_firstName`].type === "required" && (
                <MotifErrorMessage>
                    {t("pages.claims.claimCreate.creditorTab.validationMessages.firstNameMessage")}
                    </MotifErrorMessage>
              )}
          </div>
          <div className="motif-col-lg-6">
            <ContactFormInput
              name={`contact_lastName`}
              control={control}
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.lastName"}
              required={true}
            />
            {errors[`contact_lastName`] &&
              errors[`contact_lastName`].type === "required" && (
                <MotifErrorMessage>
                    {t("pages.claims.claimCreate.creditorTab.validationMessages.lastNameMessage")}
                    </MotifErrorMessage>
              )}
          </div>
        </div>
        <div className="motif-row mar-t-30">
          <div className="motif-col-lg-6">
            {/*@ts-ignore*/}
            <ContactSelect
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.prefix"}
              name={`contact_prefix`}
              options={PREFIX_OPTIONS_LIST}
              control={control}
            />
            {errors[`contact_prefix`] &&
              errors[`contact_prefix`].type === "required" && (
                <MotifErrorMessage>
                    {t("pages.claims.claimCreate.creditorTab.validationMessages.prefixMessage")}
                    </MotifErrorMessage>
              )}
          </div>
          <div className="motif-col-lg-6">
            {/*@ts-ignore*/}
            <ContactFormInput
              name={`contact_relationshipToCreditor`}
              control={control}
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.relationshipToCreditor"}
              required={false}
            />
          </div>
        </div>
        <div className="motif-row mar-t-30">
          <div className="motif-col-lg-6">
            <ContactFormInput
              name={`contact_phone`}
              control={control}
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.phoneNumber"}
              isNumber={false}
              required={false}
            />
          </div>
          <div className="motif-col-lg-6">
            <ContactFormInput
              name={`contact_fax`}
              control={control}
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.fax"}
              isNumber={true}
              required={false}
            />
          </div>
        </div>
        <div className="motif-row mar-t-30">
          <div className="motif-col-lg-6">
            <ContactFormInput
              name={`contact_address1`}
              control={control}
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.address1"}
              required={false}
            />
          </div>
          <div className="motif-col-lg-6">
            <ContactFormInput
              name={`contact_address2`}
              control={control}
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.address2"}
              required={false}
            />
          </div>
        </div>
        <div className="motif-row mar-t-30">
          <div className="motif-col-lg-6">
            <ContactFormInput
              name={`contact_email`}
              control={control}
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.email"}
              required={false}
            />
          </div>
          <div className="motif-col-lg-3">
            <ContactFormInput
              name={`contact_city`}
              control={control}
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.city"}
              required={false}
            />
          </div>
          <div className="motif-col-lg-3">
            {/*@ts-ignore*/}
            <ContactSelect
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.country"}
              name={`contact_countryId`}
              options={countryArray}
              control={control}
              selectedId={countrySelectedId}
              required={false}
              filter={true}
              placeHolder={t("global.globalPlaceholder.countryPlaceholder")}
            />
          </div>
        </div>
        <div className="motif-row justify-content-end mar-t-30">
          <div className="motif-col-lg-3">
            {/*@ts-ignore*/}
            <StateDropdown
              countryID={countrySelectedId}
              control={control}
              stateId={stateId}
              stateListByCountry={stateListBycounrty}
              required={false}
              name={`contact_provinceStateId`}
            />
          </div>
          <div className="motif-col-lg-3">
            <ContactFormInput
              name={`contact_${POSTAL_CODE_FIELD}`}
              control={control}
              label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.postalCode"}
            />
          </div>
        </div>
        <div className="display-flex justify-content-end mar-t-70">
          <div className="pad-r-10">
            <Button
              type="button"
              label={t("global.globalActions.cancel")}
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={() => setIsVisible(true)}
            />
          </div>
          <div className="">
            <Button label={t("global.globalActions.next")} variant="primary" />
          </div>
        </div>
      </form>
    </>
  );
}