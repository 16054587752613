import PageTitle from '@/components/Form/PageTitle/PageTitle';
import React, { useEffect, useState } from 'react'
import './DebtorForm.scss';
import Button from '@/components/Form/Button/Button';
import { useForm } from "react-hook-form";
import { DebtorEditFormType } from "./type";
import { MotifErrorMessage, MotifToast } from '@ey-xd/motif-react';
import {useTranslation} from 'react-i18next';
import {  REQUIRED_MESSAGE,  SECONDARY } from "@/Constant"
import ConfirmationModal from '@/components/Modal/ConfirmationModal/ConfirmationModal';
import { DEBTOR_DESCRIPTION_ID, DEBTOR_ID } from './Constant';
import ControlInputBoxDebtor from "@/pages/CaseDetail/Debtor/DebtorForm/ControlInputBoxDebtor";
import PageSubTitle from "@/components/Form/PageSubTitle/PageSubTitle";
import { useNavigate, useParams } from "react-router-dom";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import { useCase } from "@/features/case/use-case";
import {
  useCreateDebtorMutation,
  useUpdateDebtorMutation,
} from "@/services/debtors/debtorsReducers";
import { useDispatch } from 'react-redux';
import { setDiscardEditMode } from '@/components/Modal/ConfirmationModal/discardchanges';

//import { useUpdateDebtorMutation } from '@/app/services/debtor';

interface BannerData {
  debtorId: string;
  caseId: string;
  name: string;
  description: string;
  createdBy: string;
  updatedBy: string;
  isActive: boolean;
  editView: boolean;
}

interface DebtorFormProps {
  debtorData: BannerData;
}

const DebtorForm = ({ debtorData }: DebtorFormProps) => {
  const { caseId } = useParams<{ caseId: string }>();
  const [isVisible, setIsVisible] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });
  const { t } = useTranslation();
  const editView = debtorData?.editView ? true : false;
  const navigate = useNavigate();
  const [createDebtor] = useCreateDebtorMutation();
  const [updateDebtor] = useUpdateDebtorMutation();

  const {
    handleSubmit,
    control,
    formState: { errors,isDirty},
  } = useForm<DebtorEditFormType>({
    defaultValues:{
      name:debtorData?.name,
      description:debtorData?.description
    }
  });
  // useEffect(() => {
  //   if (editView) {
  //     setValue("name", debtorData?.name);
  //     setValue("description", debtorData?.description);
  //   }
  // }, [debtorData, editView, setValue, watch]);
  const errorList = Object.keys(errors);
  const errorsShow = errorList.join(", ");
  const caseData = useCase();
  const returnUri = caseId ? `/case/${caseId}?activeTab=1` : "/";

  const onSubmit = async (data: DebtorEditFormType) => {
    setIsLoading(true);
    setIsCancel(false);
    const debtorApiParam = {
      debtorId: editView ? debtorData?.debtorId : "",
      caseId: caseData.caseId,
      isActive: true,
      name: data?.name || "",
      description: data?.description || "",
    };
    dispatch(setDiscardEditMode({ editMode: false }));

    try {
      // const responseApi = await sendDebtorFormApi(debtorApiParam, editView);
      const responseApi = editView
        ? await updateDebtor(debtorApiParam).unwrap()
        : await createDebtor(debtorApiParam).unwrap();
      if (responseApi) {
        navigate(returnUri);
        setIsLoading(false);
      } else {
        const errorMessage = responseApi?.data?.message;
        setShowMessage({
          ...showMessage,
          show: true,
          type: "error",
          message: errorMessage,
        });
        setIsLoading(false);
        dispatch(setDiscardEditMode({ editMode: true }));

      }
    } catch (error) {
      setShowMessage({
        ...showMessage,
        show: true,
        type: "error",
        message: "",
      });
      setIsLoading(false);
      dispatch(setDiscardEditMode({ editMode: true }));

    }
  };

  const cancelBtnApproveHandler = (
    event: any
  ) => {
    event.preventDefault();
    navigate(returnUri, {
      state: { showMessage: false, defaultActiveKey: 1 },
    });
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsCancel(true);
    setIsVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));

  };

  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false, type: "error", message: "" });
  };

  const renderMessage = () => {
    if (showMessage.show && !isVisible && !isCancel) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={showMessage.type}
        >
          {" "}
          {showMessage.message !== ""
            ? showMessage.message
            : showMessage.type === "error"
            ? t("global.globalNotifications.unExpectedError")
            : t("global.globalNotifications.updatedSuccessfully")}
        </MotifToast>
      );
    }
  };

  const renderPageTitle = () => {
    return (
      <div className="motif-container debtor-summary-form">
        <div className="motif-row t-b-padding-21">
          <div className="motif-col-xs-4">
            <PageTitle
              title={"pages.debtors.debtorForm.formTitle"}
              description={"pages.debtors.debtorForm.formDescription"}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPageSubTitle = () => {
    return (
      <div className="motif-row t-b-padding-21">
        <div className="motif-col-xs-4">
          <PageSubTitle
            title={"pages.debtors.debtorForm.formSubTitle"}
            description={"pages.debtors.debtorForm.formSubDescription"}
          />
        </div>
      </div>
    );
  };
 
  const dispatch=useDispatch()
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,isDirty]);
  return (
    <>
      {renderMessage()}
      <form onSubmit={handleSubmit(onSubmit)}>
        {!editView && renderPageTitle()}
        <div
          className={`motif-container main-container debtor-form pad-t-20 ${
            !editView ? "create" : ""
          }`}
        >
          {renderPageSubTitle()}
          <div className="motif-row">
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlInputBoxDebtor
                  name={DEBTOR_ID}
                  control={control}
                  required={true}
                  isTextArea={false}
                  label={"pages.debtors.debtorForm.formFieldLabels.debtorName"}
                  showLabel={true}
                />
              </div>
            </div>
          </div>
          <div className="motif-row next-row">
            <div className="motif-col-md-3">
              <div className="form-field">
                {/* <ControlInputBoxDebtor
                  name={DEBTOR_DESCRIPTION_ID}
                  control={control}
                  required={true}
                  isTextArea={true}
                  label={t(DEBTOR_DESCRIPTION)}
                  showLabel={true}
                /> */}
                <ControlTextAreaWithCharLimit
                  name={DEBTOR_DESCRIPTION_ID}
                  control={control}
                  required={true}
                  isTextArea={true}
                  label={"pages.debtors.debtorForm.formFieldLabels.debtorDescription"}
                  showLabel={true}
                />
              </div>
            </div>
          </div>
          <div className="motif-row next-row"></div>
        </div>
        <div className="error-container">
          <MotifErrorMessage>
            {errorList.length > 0
              ? t(`${REQUIRED_MESSAGE} ${errorsShow} cannot be left blank.`)
              : ""}
          </MotifErrorMessage>
        </div>
        <div className="button-container">
          <Button
            label={t("global.globalActions.save")}
            type={isLoading ? "loader" : "submit"}
            disabled={isLoading}
          />
          <Button
            type="button"
            label={t("global.globalActions.cancel")}
            variant={SECONDARY}
            className="cancelBtn"
            onClickHandler={(e) => {
              if(isDirty){
                setIsVisible(true);
                dispatch(setDiscardEditMode({ editMode: false }));
              }
              else{
                cancelBtnApproveHandler(e) 
              }
            
            }}
          />
        </div>
      </form>
      <ConfirmationModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
    </>
  );
};

export default DebtorForm;