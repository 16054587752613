import { ccwpApi } from "@/services";

export const claimItemsApi = ccwpApi.injectEndpoints({ 
    endpoints: (builder) => ({
        createPost: builder.mutation({
            query: (postData) => ({
              url: "Comment/CreateComment",
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
              },
              body: postData,
            }),
        }),
        getClaimItemClassificationList: builder.query<any, string>({
          query: (claimItemId) => {
            return {
              url:`Claim/GetListClaimItemClassification?claimItemId=${claimItemId}`
            };
          },
        }),
    }),
  });

  export const {
      useCreatePostMutation,
      useGetClaimItemClassificationListQuery,
    } = claimItemsApi;
