import PageTitle from "@/components/Form/PageTitle/PageTitle";
import ControlInputBox from "@/components/Form/InputBox/ControlInputBox";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./SummaryForm.scss";
import {
  CASE_NAME,
  CASE_ID,
  CASE_DESCRIPTION_ID,
  CASE_SHORT_ID,
  CASE_ID_F,
  CASE_DESCRIPTION_ID_F,
  TEAM_MEMBER,
  ROLE,
} from "./Constant";

import Label from "@/components/Form/Label/Label";
import Button from "@/components/Form/Button/Button";
import { useForm } from "react-hook-form";
import ControlSelect from "@/components/Form/SelectBox/ControlSelect";
import {
  CaseEditFormType,
  ModalType,
  InviteUserType,
  DirectoryType,
  roleType,
  toastType,
} from "./type";

import {
  MotifButton,
  MotifErrorMessage,
  MotifToast,
  MotifSelect,
  MotifOption,
  MotifFormField,
  MotifLabel,
  MotifTable,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { FRENCH, SECONDARY, STATUS_FIELD, UPDATE_SUCCESSFUL } from "@/Constant";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import {
  useGetCaseGroupUsersListMutation,
  useCreateInviteUserMutation,
  useGetCaseUsersListQuery,
  useDeleteUserFromCaseMutation,
  useUpdateCaseInviteRoleMutation,
} from "./summaryHelper";
import { objectTrim } from "@/utility/common";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import { useCase } from "@/features/case/use-case";
import useHeaderDefinitions from "./ColHeaderDetails";
import useCellRenderers from "./TabelCellDetails";
import { useUpdateCaseMutation } from "@/services/cases/casesReducer";
import SearchInput from "@/components/SearchInput/SearchInput";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

//@ts-ignore
const SummaryForm = ({
  summaryData,
  setIsEdit,
  setNotification,
  notification,
}: {
  summaryData: any;
  setIsEdit: any;
  setNotification: any;
  notification: any;
}) => {
  const { t } = useTranslation();
  const statusOptions = [
    {
      label: t("pages.caseSummary.fieldLabelDetails.statusOpen"),
      value: "1",
    },
    {
      label: t("pages.caseSummary.fieldLabelDetails.statusClose"),
      value: "0",
    },
  ];
  let rolesList: roleType[] = [
    { roleName: "pages.caseSummary.fieldLabelDetails.caseViewer", roleId: "0" },
    {
      roleName: "pages.caseSummary.fieldLabelDetails.caseContributor",
      roleId: "1",
    },
    {
      roleName: "pages.caseSummary.fieldLabelDetails.caseManager",
      roleId: "2",
    },
  ];
  let directoryList: DirectoryType[] = [
    {
      directoryName: "pages.caseSummary.fieldLabelDetails.appDirectory",
      directoryId: "0",
    },
    {
      directoryName: "pages.caseSummary.fieldLabelDetails.eyDirectory",
      directoryId: "1",
    },
  ];

  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const showLoadingMessage = useRef<boolean>(false);
  type showMessageType = {
    show: boolean;
    type: string;
    message: string;
  };

  const [showMessage, setShowMessage] = useState<showMessageType>({
    show: false,
    type: "error",
    message: "",
  });
  const commonErrorMessage = "global.globalNotifications.wentWrong";

  const timeIntervalValue = useRef<number | null>();
  const searchUserRef = useRef<number | null>();
  const userEnteredValueRef = useRef<string>("");
  const abortFunRef = useRef<any>();
  const inviteUserJson: InviteUserType = {
    userName: "",
    roleId: "",
    directoryId: "0",
    azureUserId: "",
    userId: "",
  };
  const [inviteUserDetails, setInviteUserDetails] = useState<InviteUserType>({
    userName: "",
    roleId: "",
    directoryId: "0",
    azureUserId: "",
    userId: "",
  });
  const caseData = useCase();
  let [
    getCaseGroupUsersList,
    {
      isLoading: userApiLoading,
      isError: userApiError,
      data: usersdata,
      error: userError,
    },
  ] = useGetCaseGroupUsersListMutation();
  let userErrorDetails: any = userError;
  let [createInviteUser, { isLoading: createUserLoading }] =
    useCreateInviteUserMutation();
  let {
    refetch: refetchUserList,
    isError: userListError,
    data: groupList,
  } = useGetCaseUsersListQuery(caseData.caseId || "");
  let userGroupList: any = useMemo(
    () => (groupList ? JSON.parse(JSON.stringify(groupList)) : ""),
    [groupList]
  );
  let [
    deleteUserFromCase,
    { isLoading: deleteUserLoading, isError: deleteError },
  ] = useDeleteUserFromCaseMutation();
  const [updateCaseInviteRole, { isLoading: loadingUpdateRoleUser }] =
    useUpdateCaseInviteRoleMutation();
  const [checkInviteUserFileds, setcheckInviteUserFileds] = useState<string[]>(
    []
  );
  const columnDefs = useHeaderDefinitions();
  const cellRenderers = useCellRenderers(handleDelete);
  const toastJson: toastType = {
    showToast: false,
    showMessage: "",
    toastType: "",
  };
  const [showSuccessMessage, setShowSuccessMessage] = useState<toastType>({
    ...toastJson,
  });
  const modalJson: ModalType = {
    show: false,
    userId: "",
    userName: "",
  };
  const [modalData, setModalData] = useState<ModalType>({ ...modalJson });
  const modalRoleJson = {
    show: false,
    data: "",
    rowNo: "",
    roleDescription: "",
  };
  const [roleUpdateModal, setRoleUpdateModal] = useState<any>({
    ...modalRoleJson,
  });
  const [updateCase] = useUpdateCaseMutation();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
    setValue,
    getValues,
  } = useForm<CaseEditFormType>({
    defaultValues: {
      caseName: summaryData.caseName || "",
      caseNameFrench: summaryData.caseNameFrench || "",
      caseDescription: summaryData.caseDescription || "",
      caseDescriptionFrench: summaryData.caseDescriptionFrench || "",
      caseShortName: summaryData.caseShortName || "",
      status: summaryData.caseStatus === true ? "1" : "0",
    },
  });

  useEffect(() => {
    return () => {
      if (timeIntervalValue && timeIntervalValue.current) {
        clearTimeout(timeIntervalValue.current);
      }
      if (searchUserRef && searchUserRef.current) {
        clearTimeout(searchUserRef.current);
      }
    };
  }, []);

  function handleDelete(modalShow: boolean, userId: string, userName: string) {
    setModalData({ show: modalShow, userId: userId, userName: userName });
  }

  async function deleteuser() {
    deleteUserFromCase(modalData.userId)
      .then((data: any) => {
        if (!data?.error) {
          setShowSuccessMessage({
            showToast: true,
            showMessage: "global.globalNotifications.userDeletedMessage",
            toastType: "success",
          });
          removeToast();

          refetchUserList();
          setModalData({ ...modalJson });
        } else {
          setShowSuccessMessage({
            showToast: true,
            showMessage: commonErrorMessage,
            toastType: "error",
          });
          removeToast();
        }
      })
      .catch(() => {
        setShowSuccessMessage({
          showToast: true,
          showMessage: commonErrorMessage,
          toastType: "error",
        });
        removeToast();
      });
  }

  const errorList = Object.keys(errors);
  const errorsShow = errorList
    .join(", ")
    .replace(CASE_ID_F, `${CASE_NAME} (${FRENCH})`);

  const onSubmit = async (data: CaseEditFormType) => {
    setLoading(true);

    //@ts-ignore
    data = objectTrim(data);

    if (!data.caseName || data.caseName.trim() === "") {
      setShowMessage({
        ...showMessage,
        show: true,
        type: "error",
        message: t("The case name cannot be empty or contain only spaces."),
      });
      setLoading(false);
      return;
    }

    if (data.status === "0") {
      const newCaseData = { ...caseData, caseStatus: false };

      localStorage.setItem("caseDetails", JSON.stringify(newCaseData));
    } else {
      const newCaseData = { ...caseData, caseStatus: true };

      localStorage.setItem("caseDetails", JSON.stringify(newCaseData));
    }

    const res = {
      caseId: summaryData.caseId,
      caseStatus: data.status === "1" ? true : false,
      ...data,
    };

    const { status, ...postData } = res;

    try {
      const responseApi = await updateCase(postData).unwrap();

      if (responseApi?.caseId) {
        setShowMessage({ ...showMessage, show: true, type: "success" });

        setIsEdit(false);

        setNotification({
          ...notification,
          show: true,
          message: t(UPDATE_SUCCESSFUL),
          type: "success",
        });
      }
    } catch (error: any) {
      const {
        data: { message: errorMessage },
      } = error;

      setShowMessage({
        ...showMessage,
        show: true,
        type: "error",
        message: t(errorMessage),
      });

      setNotification({
        ...notification,
        show: true,
        message: t(errorMessage),
        type: "error",
      });
    }

    setLoading(false);
  };

  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    reset({
      caseName: summaryData?.caseName || "",
      caseNameFrench: summaryData?.caseNameFrench || "",
      caseDescription: summaryData?.caseDescription || "",
      caseDescriptionFrench: summaryData?.caseDescriptionFrench || "",
      caseShortName: summaryData?.caseShortName || "",
      status: summaryData?.caseStatus || "1",
    });
    setIsVisible(false);

    setShowMessage({ ...showMessage, show: false, type: "error" });
    setIsEdit(false);
    setNotification({
      ...notification,
      ...notification,
      show: false,
      message: "",
      type: "success",
    });
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    setIsVisible(false);

    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const closeMessageHandler = () => {
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  async function handleSelectSearchUser(value: any, keyname: string) {
    setInviteUserDetails({
      ...inviteUserDetails,
      [keyname]: value.searchTerm,
      userId: value.userId,
      azureUserId: value.azureUserId,
    });
  }
  async function handleSearchUser(value: any, keyname: string) {
    value = value.split(" | ")[0].trimStart();
    setInviteUserDetails({
      ...inviteUserDetails,
      [keyname]: value,
      userId: "",
      azureUserId: "",
    });
    userEnteredValueRef.current = value;
    if (abortFunRef && abortFunRef.current) {
      abortFunRef.current.abort();
    }
    if (value) {
      await handleOptions(value, inviteUserDetails.directoryId, 400);
    }
  }

  async function handleSelect(value: any, keyname: string) {
    setInviteUserDetails({ ...inviteUserDetails, [keyname]: value });
  }
  const renderMessage = () => {
    if (showMessage.show && !isVisible) {
      window.scrollTo(0, 0);
      return (
        <MotifToast
          onClose={closeMessageHandler}
          //@ts-ignore
          variant={showMessage.type}
        >
          {" "}
          {showMessage.type === "error"
            ? t(showMessage.message)
            : t(UPDATE_SUCCESSFUL)}
        </MotifToast>
      );
    }
  };

  function getUserOptionsList(data: any) {
    let usersDataOpt: object[] = [];
    if (Array.isArray(data) && data.length > 0 && userEnteredValueRef.current) {
      usersDataOpt = data.map((ele) => {
        let optionJson = {
          searchTerm: ele?.email
            ? `${ele.displayName} | ${ele.email}`
            : `${ele.displayName}`,
          userId: ele.userId,
          azureUserId: ele.azureUserId,
          email: ele?.email ? ele?.email : "",
        };
        return optionJson;
      });
    }
    return usersDataOpt;
  }
  async function handleOptions(
    userEnteredValue: string,
    directoryVaue: string,
    timeValue: number
  ) {
    if (searchUserRef.current) {
      clearTimeout(searchUserRef.current);
    }

    if (userEnteredValue) {
      showLoadingMessage.current = true;
      let timeoutVal = setTimeout(async () => {
        let dataFun = getCaseGroupUsersList;
        let data = dataFun({
          searchText: userEnteredValue,
          userSearchDirectory: +directoryVaue,
        });
        abortFunRef.current = data;
        showLoadingMessage.current = false;
      }, timeValue);
      searchUserRef.current = +timeoutVal;
    } else {
      showLoadingMessage.current = false;
    }
  }
  function checkFileds() {
    let filedLabeldetails: any = {
      userId: "Team Member",
      roleId: "Role",
      directoryId: "Directory",
    };
    let labels = [];
    let userDeatils: any = { ...inviteUserDetails };
    for (const key in userDeatils) {
      if (!userDeatils[key] && filedLabeldetails.hasOwnProperty(key)) {
        labels.push(filedLabeldetails[key]);
      }
    }
    return labels;
  }
  function removeToast() {
    let timeOutVal = setTimeout(() => {
      setShowSuccessMessage({ ...toastJson });
      timeIntervalValue.current = null;
    }, 2000);
    timeIntervalValue.current = +timeOutVal;
  }

  async function handleSaveInviteTeamMember() {
    if (timeIntervalValue.current) {
      clearTimeout(timeIntervalValue.current);
    }
    let labelsData = [...checkFileds()];
    if (labelsData.length === 0) {
      let displayName = inviteUserDetails.userName
        .toString()
        .split(" | ")[0]
        .trim();

      let userJson = {
        caseId: caseData.caseId,
        userid: inviteUserDetails.userId,
        role: +inviteUserDetails.roleId,
        userSearchDirectory: +inviteUserDetails.directoryId,
        azureUserId: inviteUserDetails.azureUserId,
        displayName: displayName,
      };

      await createInviteUser(userJson)
        .then((value: any) => {
          let messageJson: toastType = { ...toastJson };
          if (value?.error && value?.error?.status) {
            messageJson = {
              showToast: true,
              showMessage: value?.error?.data?.message
                ? "pages.caseSummary.fieldLabelDetails.userExistMessage"
                : commonErrorMessage,
              toastType: "error",
            };
          } else {
            messageJson = {
              showToast: true,
              showMessage: value.data?.message
                ? value.data.message
                : "pages.caseSummary.fieldLabelDetails.userInvitedMessage",
              toastType: "success",
            };
            setInviteUserDetails({ ...inviteUserJson });
            userEnteredValueRef.current = "";
            refetchUserList();
          }
          setShowSuccessMessage({ ...messageJson });
          removeToast();
        })
        .catch(() => {
          setShowSuccessMessage({
            showToast: true,
            showMessage: commonErrorMessage,
            toastType: "error",
          });

          removeToast();
        });
    }
    setcheckInviteUserFileds([...labelsData]);
  }

  function handleInviteUserErrorMsg() {
    let labelData = [...checkFileds()];
    if (userApiLoading || showLoadingMessage.current)
      return (
        <MotifLabel>
          {t("global.globalNotifications.loadingMessage")}
        </MotifLabel>
      );
    else if (userApiError && userErrorDetails?.name !== "AbortError")
      return <MotifErrorMessage>{t(commonErrorMessage)}</MotifErrorMessage>;
    else if (usersdata && usersdata.length === 0 && inviteUserDetails.userName)
      return (
        <MotifErrorMessage>
          {t("global.globalNotifications.noUserMatchMessage")}
        </MotifErrorMessage>
      );
    else if (checkInviteUserFileds.length > 0 && labelData.length > 0)
      return (
        <MotifErrorMessage>
          {t("pages.caseSummary.fieldLabelDetails.caseTeamSetupErrorMessages", {
            notFilledFields: labelData.toString().replace(",", " and "),
          })}
        </MotifErrorMessage>
      );
  }
  function handleSelectDirectory(selectedValue: string) {
    let enterdValue: string = inviteUserDetails.userName
      .toString()
      .split(" | ")[0]
      .trim();

    setInviteUserDetails({
      ...inviteUserDetails,
      directoryId: selectedValue,
      userName: enterdValue,
      userId: "",
      azureUserId: "",
    });
    if (abortFunRef && abortFunRef.current) {
      abortFunRef.current.abort();
    }
    if (enterdValue) {
      handleOptions(enterdValue, selectedValue, 0);
    }
  }
  function updatingUserRole() {
    updateCaseInviteRole(roleUpdateModal.data)
      .then((value: any) => {
        if (value?.error) {
          handleRevertRole();

          setShowSuccessMessage({
            showToast: true,
            showMessage: commonErrorMessage,
            toastType: "error",
          });
          removeToast();
        } else {
          refetchUserList();
          setShowSuccessMessage({
            showToast: true,
            showMessage:
              "pages.caseSummary.fieldLabelDetails.updatedRoleMessage",
            toastType: "success",
          });
          removeToast();
        }
        setRoleUpdateModal({ ...modalRoleJson });
      })
      .catch(() => {
        handleRevertRole();

        setShowSuccessMessage({
          showToast: true,
          showMessage: commonErrorMessage,
          toastType: "error",
        });
        removeToast();
      });
  }
  function handleChangeRole(rowData: any, newValue: string, oldValue: string) {
    let roleData: any = {
      Case_Viewer: 0,
      Case_Contributor: 1,
      Case_Manager: 2,
    };
    let changedvalue = newValue.replace(" ", "_");
    let roleUpdateJson = {
      role: roleData[changedvalue],
      caseId: rowData.caseId,
      userCaseId: rowData.userCaseId,
      userid: rowData.userId,
    };
    let modalJson = {
      show: true,
      data: roleUpdateJson,
      rowNo: roleUpdateModal.rowNo,
      roleDescription: oldValue,
    };
    setRoleUpdateModal({ ...modalJson });
  }
  function handleRevertRole() {
    let roleData = userGroupList;
    roleData.results[roleUpdateModal.rowNo].role =
      roleUpdateModal.roleDescription;
    userGroupList = roleData;
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch, isDirty]);
  return (
    <>
      {renderMessage()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="motif-container case-summary-form">
          <div className="motif-row t-b-padding-21">
            <div className="motif-col-xs-4">
              <PageTitle
                title={"pages.caseSummary.title"}
                description={"pages.caseSummary.description"}
              />
            </div>
          </div>
        </div>
        <div className="motif-container main-container summary-form">
          <div className="motif-row form-title">
            <div className="motif-col-xs-4">
              <div className="form-field">
                <Label
                  title={"pages.caseSummary.fieldLabelDetails.caseIdentifiers"}
                />
              </div>
            </div>
          </div>
          <div className="motif-row">
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlInputBox
                  name={CASE_ID}
                  control={control}
                  required={true}
                  isTextArea={false}
                  label={"pages.caseSummary.fieldLabelDetails.caseName"}
                  showLabel={true}
                  readonly={false}
                />
              </div>
            </div>
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlInputBox
                  name={CASE_ID_F}
                  control={control}
                  required={false}
                  isTextArea={false}
                  label={`pages.caseSummary.fieldLabelDetails.caseNameFrench`}
                  showLabel={true}
                  readonly={false}
                />
              </div>
            </div>
            <div className="motif-col-md-2">
              <div className="form-field"></div>
            </div>
          </div>
          <div className="motif-row next-row">
            <div className="motif-col-md-3">
              <div className="form-field">
                {/* <ControlInputBox
                  name={t(CASE_DESCRIPTION_ID)}
                  control={control}
                  isTextArea={true}
                  label={t(CASE_DESCRIPTION)}
                  showLabel={true}
                /> */}
                <ControlTextAreaWithCharLimit
                  name={CASE_DESCRIPTION_ID}
                  control={control}
                  isTextArea={true}
                  label={"pages.caseSummary.fieldLabelDetails.caseDescription"}
                  showLabel={true}
                />
              </div>
            </div>
            <div className="motif-col-md-3">
              <div className="form-field">
                {/* <ControlInputBox
                  name={t(CASE_DESCRIPTION_ID_F)}
                  control={control}
                  isTextArea={true}
                  label={t(`${CASE_DESCRIPTION} (${FRENCH})`)}
                  showLabel={true}
                /> */}
                <ControlTextAreaWithCharLimit
                  name={CASE_DESCRIPTION_ID_F}
                  control={control}
                  isTextArea={true}
                  label={`pages.caseSummary.fieldLabelDetails.caseDescriptionFrench`}
                  showLabel={true}
                />
              </div>
            </div>
            <div className="motif-col-md-2">
              <div className="form-title-field"></div>
            </div>
          </div>
          <div className="motif-row next-row">
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlInputBox
                  name={CASE_SHORT_ID}
                  control={control}
                  required={true}
                  label={"pages.caseSummary.fieldLabelDetails.caseShortName"}
                  showLabel={true}
                />
              </div>
            </div>
            <div className="motif-col-md-3">
              <div className="form-field">
                <ControlSelect
                  name={STATUS_FIELD}
                  control={control}
                  showLabel={true}
                  label={t("pages.caseSummary.fieldLabelDetails.status")}
                  options={statusOptions}
                  required={true}
                  setValue={setValue}
                  getValues={getValues}
                />
              </div>
            </div>
            <div className="motif-col-md-2">
              <div className="form-title-field"></div>
            </div>
          </div>
          <div className="motif-row next-row"></div>

          <div className="motif-row form-title">
            <div className="motif-col-xs-4">
              <div className="form-field">
                <Label
                  title={"pages.caseSummary.fieldLabelDetails.caseTeamSetup"}
                />
              </div>
            </div>
          </div>
          {showSuccessMessage.showToast && (
            <MotifToast
              onClose={() => setShowSuccessMessage({ ...toastJson })}
              variant={showSuccessMessage.toastType}
            >
              {t(showSuccessMessage.showMessage)}
            </MotifToast>
          )}
          <div className="motif-row  no-gutter" style={{alignItems:"flex-end"}}>
            <div className="motif-col-md-5">
              <div className="formFieldLabel">
                <label htmlFor="input" aria-label={TEAM_MEMBER}>
                  {t("pages.caseSummary.fieldLabelDetails.teamMember")}
                </label>
              </div>
              <div className="case-user-search-maincon">
                <div className="user-search-con">
                  <MotifFormField>
                    <SearchInput
                      hideClearButton={true}
                      open={!userApiLoading}
                      visibleOptions={5}
                      value={inviteUserDetails.userName}
                      onSelect={(e: any) => {
                        handleSelectSearchUser(e, "userName");
                      }}
                      onChange={(e: any) => {
                        handleSearchUser(e.target.value, "userName");
                      }}
                      items={getUserOptionsList(
                        !showLoadingMessage.current && usersdata
                          ? usersdata
                          : []
                      )}
                      filterPropFunction={(e: any) => {
                        return e?.searchTerm;
                      }}
                      placeholder={`${t(
                        "pages.caseSummary.fieldLabelDetails.inviteUserPlaceholder"
                      )} ${
                        inviteUserDetails.directoryId === "0"
                          ? t(
                              "pages.caseSummary.fieldLabelDetails.appDirectory"
                            )
                          : t("pages.caseSummary.fieldLabelDetails.eyDirectory")
                      }`}
                      variant="standard"
                      className="user-search"
                    />
                  </MotifFormField>
                </div>
                <div>|</div>

                <div className="directory-con">
                  <MotifFormField>
                    <MotifSelect
                      onChange={(e) => {
                        handleSelectDirectory(e);
                      }}
                      placeholder={t(
                        "pages.caseSummary.fieldLabelDetails.directoryPlaceholder"
                      )}
                      value={inviteUserDetails.directoryId}
                      className="directory-select"
                    >
                      {directoryList.map((ele) => (
                        <MotifOption
                          key={ele.directoryId}
                          value={ele.directoryId}
                        >
                          {t(ele.directoryName)}
                        </MotifOption>
                      ))}
                    </MotifSelect>
                  </MotifFormField>
                </div>
              </div>
            </div>
            <div className="motif-col-md-2 role-con">
              <div className="formFieldLabel">
                <label htmlFor="input" aria-label={ROLE}>
                  {t("pages.caseSummary.fieldLabelDetails.role")}
                </label>
              </div>
              <MotifFormField>
                <MotifSelect
                  onChange={(e) => {
                    handleSelect(e, "roleId");
                  }}
                  placeholder={t(
                    "pages.caseSummary.fieldLabelDetails.rolePlaceHolder"
                  )}
                  visibleOptions={3}
                  value={
                    inviteUserDetails.roleId ? inviteUserDetails.roleId : ""
                  }
                  triggerButtonProps={{ type: "button" }}
                >
                  {rolesList.map((option) => (
                    <MotifOption key={option.roleId} value={option.roleId}>
                      {t(option.roleName)}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
            </div>
            <div className="motif-col-md-1 user-invite-button-con">
              <div className="motif-row no-gutter ">
                <MotifButton
                  size="medium"
                  loading={createUserLoading}
                  type="button"
                  onClick={() => handleSaveInviteTeamMember()}
                  variant="secondary"
                >
                  {t("global.globalActions.add")}
                </MotifButton>
                {/* Not been used, kept for later usage */}
                {/* <MotifDropdown
                  ariaLabelledby="dropdown-trigger-1"
                  id="dropdown-1"
                  trigger={
                    <MotifButton
                      aria-label="Search Dropdown"
                      id="dropdown-trigger-1"
                      onClick={function Ga() {}}
                      type="button"
                      variant="text"
                    >
                      <MotifIcon iconFunction={IconoirPlus} />
                    </MotifButton>
                  }
                >
                  <ul role="menu">
                    <MotifDropdownItem>...</MotifDropdownItem>
                  </ul>
                </MotifDropdown> */}
              </div>
            </div>
          </div>

          {handleInviteUserErrorMsg()}

          <div className="motif-row next-row"></div>

          <div className="motif-row ">
            <div className="motif-col-xs-4">
              <div className="form-field">
                <Label
                  title={
                    "pages.caseSummary.fieldLabelDetails.currentTeamMembers"
                  }
                />
              </div>
            </div>
          </div>
          <div className="motif-row"></div>

          <div
            className="userGroupTable"
            style={
              !userGroupList || userGroupList?.results?.length === 0
                ? { height: "150px" }
                : {}
            }
          >
            {userListError ? (
              <MotifErrorMessage>{t(commonErrorMessage)}</MotifErrorMessage>
            ) : !userGroupList ? (
              <MotifProgressLoader size="xs"></MotifProgressLoader>
            ) : (
              <MotifTable
                paginateChildRows={true}
                columnDefs={columnDefs}
                components={cellRenderers}
                rowData={
                  userGroupList && userGroupList?.results?.length > 0
                    ? userGroupList?.results
                    : []
                }
                onCellEditingStarted={(e) => {
                  setRoleUpdateModal({
                    show: false,
                    data: "",
                    rowNo: e.rowIndex,
                    roleDescription: "",
                  });
                }}
                overlayNoRowsTemplate={t(
                  "global.globalNotifications.noRecords"
                )}
                onCellEditingStopped={(e) => {
                  if (e.valueChanged) {
                    let rowData = e.data;
                    let changedvalue = e.newValue;
                    let oldValu = e.oldValue;

                    handleChangeRole(rowData, changedvalue, oldValu);
                  }
                }}
              />
            )}
          </div>
          <div className="motif-row next-row"></div>
        </div>
        <div className="error-container">
          <MotifErrorMessage>
            {errorList.length > 0
              ? t(`pages.caseSummary.fieldLabelDetails.fillAllFieldsMessage`, {
                  notFilledFields: errorsShow,
                })
              : ""}
          </MotifErrorMessage>
        </div>
        <div className="button-container">
          <Button
            label={t("global.globalActions.save")}
            loading={loading}
            disabled={loading}
          />
          <Button
            type="button"
            label={t("global.globalActions.cancel")}
            variant={SECONDARY}
            className="cancelBtn"
            onClickHandler={(e) => {
              if (isDirty) {
                setIsVisible(true);
              } else {
                cancelBtnApproveHandler(e);
              }

              setShowMessage({ ...showMessage, show: false, type: "error" });
            }}
          />
        </div>
      </form>
      <ConfirmationModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
      <MotifModal
        onClose={() => setModalData({ ...modalJson })}
        show={modalData.show}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        size="sm"
      >
        <div className="confirmationModalWrapper creditor_disabling_model">
          <br></br>

          <MotifModalBody style={{ paddingTop: "0px" }}>
            <span style={{ fontWeight: "300" }}>
              {t("pages.caseSummary.fieldLabelDetails.userDeleteMessage", {
                userName: modalData.userName,
              })}
            </span>
            {deleteError && (
              <MotifErrorMessage>
                {t("global.globalNotifications.tryAgainMessage")}
              </MotifErrorMessage>
            )}
          </MotifModalBody>
          <MotifModalFooter>
            <MotifButton
              onClick={() => deleteuser()}
              loading={deleteUserLoading}
            >
              {t("global.globalActions.yes")}
            </MotifButton>

            <MotifButton
              className=""
              onClick={() => setModalData({ ...modalJson })}
              size="small"
              type="button"
            >
              {t("global.globalActions.no")}
            </MotifButton>
          </MotifModalFooter>
        </div>
      </MotifModal>
      <MotifModal
        onClose={() => setRoleUpdateModal({ ...modalRoleJson })}
        show={roleUpdateModal.show}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        size="sm"
      >
        <div className="confirmationModalWrapper creditor_disabling_model">
          <br></br>

          <MotifModalBody style={{ paddingTop: "0px" }}>
            <span style={{ fontWeight: "300" }}>
              {t("global.globalNotifications.roleChangeMessage")}
            </span>
          </MotifModalBody>
          <MotifModalFooter>
            <MotifButton
              onClick={() => updatingUserRole()}
              loading={loadingUpdateRoleUser}
            >
              {t("global.globalActions.yes")}
            </MotifButton>

            <MotifButton
              className=""
              onClick={() => {
                handleRevertRole();

                setRoleUpdateModal({ ...modalRoleJson });
              }}
              size="small"
              type="button"
            >
              {t("global.globalActions.no")}
            </MotifButton>
          </MotifModalFooter>
        </div>
      </MotifModal>
    </>
  );
};

export default SummaryForm;
