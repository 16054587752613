import { j as o } from "../../jsx-runtime-BwWtxLpl.js";
import "react";
import t from "prop-types";
import a from "classnames";
const n = ({ className: e = "", children: r }) => {
  const i = a({
    "motif-vertical-navigation-content": !0,
    "motif-vertical-navigation-scrolls": !0,
    [e]: !0
  });
  return /* @__PURE__ */ o.jsx("div", { className: i, "data-motif-vertical-navigation-content": !0, children: r });
};
n.propTypes = {
  className: t.string,
  children: t.oneOfType([t.element, t.arrayOf(t.element)]).isRequired
};
export {
  n as MotifVerticalNavigationContent
};
