export const PAGE_TITLE = "Creditor Setup";
export const PAGE_DESCRIPTION =
  "Lorem ipsum dolor sit amet consectetur. Praesent sit nulla lacus nullam posuere diam velit eu nulla. Feugiat pretium massa non justo at aliquam semper purus nisl. Ut eget proin hendrerit dignissim neque lacus iaculis convallis non. Arcu eget porta.";
export const FORM_TITLE = "Creditor Details";
export const FORM_DESCRIPTION =
  "Please select the type of creditor you are filing a claim for.";
export const FORM_SUBMITTED_TITLE = "Creditor submitted successfully";
export const FORM_SUBMITTED_DESCRIPTION =
  "Lorem ipsum dolor sit amet consectetur. Proin euismod commodo fermentum facilisis sed. Urna enim pharetra massa id velit. Porta amet habitant ipsum a et ipsum. Blandit viverra neque tellus orci morbi. Purus nisi non dui neque eget molestie viverra morbi. Penatibus pharetra in morbi arcu at ullamcorper tristique pellentesque. Nulla aliquet at volutpat scelerisque.";
export const CREDTIOR_IDENTIFIERS = "Creditor Identification";
export const BUSINESS_NAME = "businessName";
export const BUSINESS_NAME_LABEL = "Business Name";
export const DESCRIPTION = "description";
export const DESCRIPTION_LABEL = "Description";
export const RELATIONSHIP_TO_DEBTOR = "relationShipToDebtor";
export const RELATIONSHIP_TO_DEBTOR_LABEL = "Relationship to Debtor";
export const RELATIONSHIP_TO_CREDITOR = "relationShipToCreditor";
export const RELATIONSHIP_TO_CREDITOR_LABEL = "Relationship to Creditor";
export const CREDITOR_TYPE = "creditorType";
export const CREDITOR_TYPE_LABEL = "Creditor Type";
export const INDIVIDUAL = "individual";
export const ORGANIZATION = "organization";
export const INDIVIDUAL_LABEL = "Individual";
export const ORGANIZATION_LABEL = "Organization";
export const FORM_TITLE_CONTACT = "Contact Details";
export const FORM_DESCRIPTION_CONTACT =
  "Lorem ipsum dolor sit amet consectetur. Praesent sit nulla lacus nullam posuere diam velit eu nulla.";
export const FORM_TITLE_ATTRIBUTES = "Creditor Attributes";
export const FORM_DESCRIPTION_ATTRIBUTES =
  "Lorem ipsum dolor sit amet consectetur. Praesent sit nulla lacus nullam posuere diam velit eu nulla.";
export const FIRST_NAME = "firstName";
export const FIRST_NAME_LABEL = "First Name";
export const LAST_NAME = "lastName";
export const LAST_NAME_LABEL = "Last Name";
export const NAME_LABEL = "Name";
export const PREFIX = "prefix";
export const PREFIX_LABEL = "Prefix";
export const RELATIONSHIP_LABEL = "Relationship";
export const ADDRESS1 = "address1";
export const ADDRESS1_LABEL = "Address 1";
export const ADDRESS2 = "address2";
export const ADDRESS2_LABEL = "Address 2";
export const PHONE = "phone";
export const PHONE_LABEL = "Phone Number";
export const FAX = "fax";
export const FAX_LABEL = "Fax";
export const EMAIL = "email";
export const EMAIL_LABEL = "Email";
export const CITY = "city";
export const CITY_LABEL = "City";
export const STATE = "state";
export const STATE_LABEL = "Province State";
export const COUNTRY = "country";
export const COUNTRY_LABEL = "Country";
export const POSTAL_CODE = "postalCode";
export const POSTAL_CODE_LABEL = "Postal Code";
export const INSTITUTION_NUMBER ="institutionNumber";
export const INSTITUTION_NUMBER_LABEL ="Institution Number";
export const TRANSIT_NUMBER ="transitNumber";
export const TRANSIT_NUMBER_LABEL ="Transit Number";
export const ACCOUNT_NUMBER ="accountNumber";
export const ACCOUNT_NUMBER_LABEL ="Account Number";
export const IBAN_NUMBER ="ibanNumber";
export const IBAN_NUMBER_LABEL ="IBAN Number";
export const SWIFT_CODE ="swiftCode";
export const SWIFT_CODE_LABEL ="SWIFT Code";
export const ROUTING_CODE ="routingCode";
export const ROUTING_CODE_LABEL ="Routing Code";
export const ACH_CODE_LABEL ="ACH Code";
export const ACH_CODE ="achCode";
export const BANK_BRANCH_LABEL ="Bank Branch";
export const CONTACT_IDENTIFIERS = "Primary Contact Details";
export const OTHER_CONTACT_IDENTIFIERS = "Other Contact Details";
export const ADD_ANOTHER_CONTACT = "Add Another Contact";
export const SET_AS_PRIMARY_CONTACT = "setAsPrimaryContact";
export const SET_AS_PRIMARY_CONTACT_LABEL = "Set as Primary Contact";
export const USE_AS_ADDITIONAL_PAYEE="usedAsAdditionalPayeeType";
export const ADDITIONAL_PAYEE_TYPE="additionalPayeeTypeId"
export const ADDITIONAL_PAYEE_TYPE_LABEL="Additional Payee Type"
export const USE_AS_ADDITIONAL_PAYEE_LABEL="Use as additional Payee"
export const ATTRIBUTE_CONFIGURATION = "Attribute Configuration";
export const RELATIONSHIP_OPTIONS = [
  { label: "Yes", value: "1" },
  { label: "No", value: "0" },
];
export const CONTACT_DETAILS = "Contact Details";
export const CREDITOR_DUPLICATE =
  "A creditor with the same name already exists.";
export const CREDITOR_NAME_ALREADY_EXISTS = "Creditor Name already exists";
export const PRIMARY_CONTACT_REQUIRED = "Primary contact is required";
export const IS_REQUIRED = " is required";
