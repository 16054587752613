import Table from "@/components/Table/table";
import { useCase } from "@/features/case/use-case";
import { useColumnCommentHistoryDefs  } from "@/features/claimsummary/summary/constant/claimFormHeaderConst";
import { useGetCommentHistoryViewApiDataQuery, useCreateCommentMutation } from "@/services/claimSummary/caseSummaryReducer";

import {
  MotifButton,
  MotifFormField,
  MotifIcon,
  MotifInput,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import {
  IconoirEditPencil,
  IconoirTrash,
} from "@ey-xd/motif-icon";
import { useEffect, useState } from "react";
import DeleteModal from "@/components/Modal/DeleteModal/DeleteModal";
import EditCommentModal from "@/components/Modal/EditCommentModal/EditCommentModal";
import { useDeleteCommentMutation, useUpdateCommentMutation } from "@/services/comments/commentsReducer";
import { useTranslation } from "react-i18next";

type commentHistoryConst = {
  data: {
    comment: string;
    commentCreatedOn: string;
    commentCreatedBy: string;
  };
};


export const TitleComplement = () => {
  return <></>;
};
export const CommentHistory = ({ entityType, dataId }: any) => {
  const caseData = useCase();
  // @ts-ignore
  const { t } = useTranslation();
  const getDataByUrl = { caseId: caseData.caseId, claimId: dataId };
  const [commentText, setCommentText] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedComment, setSelectedComment] = useState<any>(null);
  const [deleteComment] = useDeleteCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const { data, isLoading, refetch } =
    useGetCommentHistoryViewApiDataQuery(getDataByUrl);
  const [createComment] = useCreateCommentMutation();
  let commentData = {
    caseId: caseData.caseId,
    entityId: dataId,
    entityType: entityType,
    commentMsg: commentText,
  };

  useEffect(() => {
    if (!showEdit && !showDelete) {
      refetch();
    }
  }, [showEdit, showDelete, refetch]);

  const handleSaveEdit = (updatedComment: any) => {
    updateComment({
      commentId: updatedComment.commentId,
      commentMsg: updatedComment.commentMsg, 
    }).then(() => {
      setShowEdit(false);
      refetch();
    });
  };

  const handleEdit = (commentData: any) => {
    setSelectedComment(commentData);
    setShowEdit(true);
  };

  const handleDelete = () => {
    deleteComment({
      CommentId: selectedComment?.commentId,
    }).then(() => {
      setShowDelete(false);
      refetch();
    });
  };

  async function postComment() {
    const response = await createComment(commentData);  
    if (response.hasOwnProperty("data")) {
      refetch();
    }
  }

  const customActionHeader = {
    postedOnRenderer: function (param: commentHistoryConst) {
      return <>{param.data.commentCreatedOn}</>;
    },
    actionRenderer: function (props: any) {
      return (
        <>
          <div className="ccwp_comment_customheader_wrapper">
            <MotifButton
              onClick={() => {
                setSelectedComment(props.data);
                setShowDelete(true);
              }}
              size="small"
              type="button"
              variant="primary"
            >
              <MotifIcon fill="none" iconFunction={IconoirTrash} />
            </MotifButton>
            <MotifButton
              onClick={() => handleEdit(props.data)}
              size="small"
              type="button"
              variant="primary"
            >
              <MotifIcon fill="none" iconFunction={IconoirEditPencil} />
            </MotifButton>
          </div>
        </>
      );
    },
  };
  const columnCommentHistoryDefs=useColumnCommentHistoryDefs(caseData?.abbreviation);
  return (
    <div className="mar-b-20">
      <h3 className="ccwp_comment_heading">{t("commonComponent.comment.title")}</h3>
      <div className="ccwp_comment_commentpost pad-b-20">
        <MotifFormField aria-label="Input" role="group">
          <MotifInput
            /**@ts-ignore **/
            id="input"
            placeholder={t("commonComponent.comment.commentPlaceholder")}
            onBlur={function Ua() { }}
            onChange={(event: any) => setCommentText(event.target.value)}
            onFocus={function Ua() { }}
            value={commentText}
            hideClearButton={true}
          />
        </MotifFormField>
        <MotifButton
          onClick={() => {
            postComment();
            setCommentText("");
          }}
          size="small"
          type="submit"
          variant="primary"
          disabled={!commentText ? true : false}
        >
          {t("global.globalActions.post")}
        </MotifButton>
      </div>
      {isLoading ? (
        <MotifProgressLoader show variant="default" className="mar-b-20" />
      ) : (
        <Table
          columnDefs={columnCommentHistoryDefs}
          cellRenderers={data}
          TitleComplement={TitleComplement}
          title={"commonComponent.comment.tableTitle"}
          description={"commonComponent.comment.tableDescription"}
          caseRenderers={customActionHeader}
          pagination={false}
          overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
        />
      )}

      <DeleteModal
        message={t("commonComponent.comment.deleteMessage")}
        isVisible={showDelete}
        setIsVisible={setShowDelete}
        cancelBtnHandler={() => setShowDelete(false)}
        yesBtnHandler={handleDelete}
      />

      {showEdit && (
        <EditCommentModal
          show={showEdit}
          setShow={setShowEdit}
          comment={selectedComment}
          onSave={handleSaveEdit}
          isEdit={true}
        />
      )}

    </div>
  );
};
