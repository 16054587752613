import React from "react";
import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import {useTranslation} from 'react-i18next';
import InputBox from "@/components/Form/InputBox/InputBox";
import '@/components/Form/InputBox/InputBox.scss';
import { DebtorEditFormType } from "./type";
import { DEBTOR_DESCRIPTION_ID, DEBTOR_ID } from "./Constant";

type ControlInputBoxType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<DebtorEditFormType>;
    name: typeof DEBTOR_ID | typeof DEBTOR_DESCRIPTION_ID;
    showLabel: boolean;
    label: string;
}

export default function ControlInputBoxDebtor ({name, control, required=false, isTextArea=false, showLabel, label}: ControlInputBoxType) {
    const {
        field,
        // fieldState: { invalid, isTouched, isDirty },
        // formState: { touchedFields, dirtyFields }
      } = useController({
        name,
        control,
        rules: { required: required },
      });
    const {t} = useTranslation();

    return (
      <>
        {showLabel && (
          <div className="formFieldLabel">
            <label htmlFor="input" aria-label={label}>
              {t(label)}
              {required ? <span className="requiredSymbol">*</span> : ""}
            </label>
          </div>
        )}
        <Controller
          name={field.name || ""}
          control={control}
          rules={{ required }}
          render={({ field: { onChange, value, ...args } }) => {
            return (
              <>
                <InputBox
                  id={DEBTOR_ID}
                  required={true}
                  value={value || ""}
                  onChange={onChange}
                  isTextArea={isTextArea}
                  placeholder={field.name}
                  {...args}
                />
              </>
            );
          }}
        />
      </>
    );
}
