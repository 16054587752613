import { j as t } from "../../../jsx-runtime-BwWtxLpl.js";
import u from "classnames";
import e from "prop-types";
import { useState as c, useEffect as T } from "react";
import { createPortal as C } from "react-dom";
import { usePopper as R } from "react-popper";
import { useVerticalNavigationContext as I } from "../vertical-navigation-context.js";
const L = {
  placement: "right-start",
  modifiers: [
    // Modifier allows to hide open popper if trigger is not visible. This
    // happens if popper is open and user scrolls container until trigger
    // is hidden
    { name: "hide" },
    {
      name: "offset",
      options: {
        offset: [0, 18]
      }
    }
  ]
}, S = ({
  child: f = !1,
  children: i,
  className: o = "",
  icon: n = null,
  label: v = "",
  onClick: m = () => null,
  selected: r = !1
}) => {
  const { collapse: a, rootClassName: d } = I(), [g, b] = c(null), [h, x] = c(null), [p, s] = c(!1), { styles: y, attributes: E } = R(g, h, L);
  T(() => {
    a && s(!1);
  }, [a]);
  const N = () => {
    s(!0);
  }, j = () => {
    s(!1);
  }, P = (l) => {
    l.keyCode === 13 && m();
  };
  if (a && f) {
    const l = u({
      "motif-vertical-navigation-menuitem": !0,
      "motif-vertical-navigation-submenuitem": !0,
      "motif-vertical-navigation-submenuitem-selected": r,
      [o]: !0
    });
    return /* @__PURE__ */ t.jsx(
      "button",
      {
        "aria-label": v,
        className: l,
        "data-motif-vertical-navigation-menu-item": !0,
        "data-testid": "motif-vertical-navigation-menuitem",
        onClick: m,
        role: "menuitem",
        onKeyDown: P,
        children: /* @__PURE__ */ t.jsx("div", { className: "motif-vertical-navigation-menuitem-label", children: i })
      }
    );
  }
  const M = u({
    "motif-vertical-navigation-menuitem-withouticon": !n,
    "motif-vertical-navigation-menuitem": !0,
    "motif-vertical-navigation-menuitem-child": f,
    "motif-vertical-navigation-menuitem-selected": r,
    "motif-vertical-navigation-menuitem-hover": p,
    [o]: !0
  }), O = u({
    "motif-vertical-navigation-menuitem-portal": !0,
    "motif-vertical-navigation-menuitem-selected": r,
    [o]: !0,
    [d]: !0
  });
  return /* @__PURE__ */ t.jsxs(
    "button",
    {
      "aria-label": v,
      className: M,
      "data-testid": "motif-vertical-navigation-menuitem",
      onClick: m,
      onMouseEnter: N,
      onMouseLeave: j,
      role: "menuitem",
      children: [
        n && /* @__PURE__ */ t.jsx("div", { className: "motif-vertical-navigation-menuitem-iconcontainer", ref: b, children: n }),
        i && a ? p && C(
          /* @__PURE__ */ t.jsx(
            "div",
            {
              ref: x,
              style: y.popper,
              ...E.popper,
              className: O,
              "data-testid": "motif-vertical-navigation-menuitem-portal",
              children: /* @__PURE__ */ t.jsx("div", { className: "motif-vertical-navigation-menuitem-portal-label", children: i })
            }
          ),
          document.querySelector("body")
        ) : /* @__PURE__ */ t.jsx("div", { className: "motif-vertical-navigation-menuitem-label", children: i })
      ]
    }
  );
};
S.propTypes = {
  child: e.bool,
  children: e.oneOfType([e.node, e.element, e.arrayOf(e.element)]).isRequired,
  className: e.string,
  icon: e.node,
  label: e.string,
  onClick: e.func,
  selected: e.bool
};
export {
  L as DEFAULT_POPPER_OPTIONS,
  S as MotifVerticalNavigationMenuItem
};
