import { useCallback, useEffect, useState } from "react";
import { MotifProgressLoader, MotifButton } from "@ey-xd/motif-react";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useEndUserSessionMutation } from "./user-api";
import config from "@/config";
import { IUserState, setUser } from "./user-slice";

const { features } = config;

function Logout() {
  const [success, setSuccess] = useState(false);
  const { instance, accounts, inProgress } = useMsal();
  const [endUserSession] = useEndUserSessionMutation();
  const dispatch = useDispatch();

  const clearSession = () => {
    sessionStorage.clear();
  };

  const handleLogout = useCallback(async () => {
    if (features.session) {
      await endUserSession();
    }

    if (accounts && accounts.length > 0 && inProgress === "none") {
      sessionStorage.removeItem("msal.login.request");
      setTimeout(() => {
        dispatch(setUser({}));
        instance.logoutRedirect({
          account: accounts[0],
          postLogoutRedirectUri: `${window.location.origin}/logout`,
          onRedirectNavigate: () => {
            sessionStorage.clear();
            return false;
          },
        });
      }, 1000);
    }
  }, [endUserSession, accounts, inProgress, instance, dispatch]);

  useEffect(() => {
    if (!success && accounts && accounts.length === 0) {
      const emptyUser: IUserState = {
        id: "",
        name: "",
        email: "",
        photo: undefined,
        accessToken: undefined,
        sessionActive: false,
      };
      dispatch(setUser(emptyUser));
      setTimeout(() => {
        setSuccess(true);
      }, 1000);
    }

    if (!success && accounts && accounts.length > 0 && inProgress === "none") {
      handleLogout();
    }
  }, [dispatch, instance, accounts, success, inProgress, handleLogout]);

  return success ? (
    <div className="logout-container">
      <p className="motif-h1">You have logged out successfully.</p>
      <a
        href="/"
        className="motif-text-link"
        title="retry"
        onClick={clearSession}
      >
        <MotifButton variant="primary">Login</MotifButton>
      </a>
    </div>
  ) : (
    <div className="logout-container">
      <p className="motif-h1">Logging out...</p>
      <MotifProgressLoader show={true} />
    </div>
  );
}

export default Logout;
