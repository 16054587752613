import React from "react";
import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { CLAIM_AMOUNT } from "../Constants";
import { ClaimSetupClaimItemsType } from "./type";
import InputBox from "@/components/Form/InputBox/InputBox";
import "@/components/Form/InputBox/InputBox.scss";

type ControlInputBoxType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<ClaimSetupClaimItemsType>;
    name: string;
    showLabel: boolean;
    label: string;
    rules?: any;
}

export default function ControlInputBox({ name, control, required = false, isTextArea = false, showLabel, label, rules }: ControlInputBoxType) {
    const {
        field,
    } = useController({
        // @ts-ignore
        name,
        control,
        rules: { required: required, ...rules },
    });
    const { t } = useTranslation();

    return (
        <>
            {
                showLabel && <div className="formFieldLabel">
                    <label htmlFor="input" aria-label={label}>{t(label)}{required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
            <Controller
                name={field.name}
                control={control}
                rules={{ required, ...rules }}
                render={({ field: { onChange, value, ...args } }) => {
                    return (
                        <>
                            {/*@ts-ignore */}
                            <InputBox id={CLAIM_AMOUNT} required={true} value={value} onChange={onChange} isTextArea={isTextArea} {...args} />
                        </>
                    );
                }}
            />
        </>

    );
}
