import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifLabel,
  MotifInput,
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifIcon,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import { actionIcDescription24px } from "@ey-xd/motif-react/assets/icons";
import "./uploadEditDocument.css";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useCase } from "@/features/case/use-case";
import {
  useLazyGetDocumentTypesQuery,
  useUploadDocumentMutation,
  useUpdateDocumentMutation,
  fileExtensions,
} from "@/services/documents/documentsReducer";

export default function UploadEditDocument({
  show,
  setShow,
  claimId,
  isEdit,
  document,
}: {
  show: boolean;
  setShow: (isVisible: boolean) => void;
  claimId: string;
  isEdit: boolean;
  document: any;
}) {
  const { t } = useTranslation();
  const caseData = useCase();
  const { caseId } = caseData;
  const formData = new FormData();
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [showDiscardMessage, setShowDiscardMessage] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [comment, setComment] = useState("");
  const [fileName, setFileName] = useState("");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [getDocumentTypesQuery] = useLazyGetDocumentTypesQuery();
  const [uploadDocument] = useUploadDocumentMutation();
  const [updateDocument] = useUpdateDocumentMutation();

  useEffect(() => {
    getDocumentTypesQuery().then((response) => {
      if (response.data) {
        setDocumentTypes(response.data);
      }
    });
    // eslint-disable-next-line
  }, []);
let [editFormData,setEditFormData]=useState({
  fileName:"",
  documentType:"",
  comment:"",
  file:new File([""], "")
})
  useEffect(() => {
    if (isEdit) {
      const type: any = documentTypes.filter((x: any) => {
        return x.name === document.documentType;
      });
      setFileName(document.displayName);
      setDocumentType(type.length !== 0 ? type[0].id : "");
      setComment(document.comment);
      setFile(new File([""], document.fileName));
      setEditFormData({
        fileName:document.displayName,
        documentType:type.length !== 0 ? type[0].id : "",
        comment:document.comment,
        file:new File([""], document.fileName)
      })
    }
    // eslint-disable-next-line
  }, [documentTypes]);

  const handleFileChange = (files: any) => {
    const file = files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleSubmit = () => {
    setIsUploading(true);
    formData.append("CaseId", caseId);
    formData.append("DisplayName", fileName);
    formData.append("Comment", comment);
    formData.append("DocumentTypeId", documentType);

    if (isEdit) {
      formData.append("DocumentId", document.id);
      if (file && file.size !== 0) {
        formData.append("file", file);
      }
      updateDocument(formData).then(() => {
        setIsUploading(false);
        setShow(false);
      });
    } else {
      formData.append("EntityId", claimId);
      formData.append("EntityType", "2");

      if (file) {
        formData.append("file", file);
      }
      uploadDocument(formData).then(() => {
        setIsUploading(false);
        setShow(false);
      });
    }
  };

  let discardtrig=false
  if(((file !== null || fileName !== "" || documentType !== "" || comment !== "") && !isEdit)){
    discardtrig=true
  }
  else if(!((JSON.stringify(file) ===JSON.stringify(editFormData.file ) || fileName ===editFormData.fileName  || documentType ===editFormData.documentType  || comment ===editFormData.comment)  && isEdit)){
    discardtrig=true

  }
  return (
    <>
      <MotifModal
        className="upload-edit-document-container"
        show={show}
        onClose={() => {
          if (discardtrig) {
            setShowDiscardMessage(true);
          } else {
            setShow(false);
          }
        }}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <MotifModalHeader>
          {isEdit
            ? t("pages.claims.documentPopup.editTitle")
            : t("pages.claims.documentPopup.title")}
        </MotifModalHeader>
        <MotifModalBody>
          {documentTypes.length === 0 ? (
            <MotifProgressLoader show variant="default" />
          ) : (
            <>
              {t("pages.claims.documentPopup.subtitle")}
              <div className="motif-row document-inputs">
                <div className="motif-col-md-2 motif-col-lg-4">
                  <MotifLabel aria-label="Input" for="input">
                    {t("pages.claims.documentPopup.fileName")}
                    <span className="required-input">*</span>
                  </MotifLabel>
                  <MotifFormField aria-label="Input" role="group">
                    <MotifInput
                      // @ts-ignore
                      onChange={(e) => setFileName(e.target.value)}
                      onFocus={function Ua() {}}
                      value={fileName}
                    />
                  </MotifFormField>
                </div>
                <div className="motif-col-md-2 motif-col-lg-4">
                  <MotifFormField>
                    <MotifLabel id="select-label">
                      {t("pages.claims.documentPopup.documentType")}
                      <span className="required-input">*</span>
                    </MotifLabel>
                    <MotifSelect
                      onChange={(value) => setDocumentType(value)}
                      value={documentType}
                      visibleOptions={3}
                    >
                      {documentTypes.map((option: any) => {
                        return (
                          <MotifOption value={option.id} key={option.id}>
                            {option.name}
                          </MotifOption>
                        );
                      })}
                    </MotifSelect>
                  </MotifFormField>
                </div>
                <div className="motif-col-md-2 motif-col-lg-4">
                  <MotifLabel aria-label="Input" for="input">
                    {t("pages.claims.documentPopup.comment")}
                  </MotifLabel>
                  <MotifFormField aria-label="Input" role="group">
                    <MotifInput
                      // @ts-ignore
                      onChange={(e) => setComment(e.target.value)}
                      onFocus={function Ua() {}}
                      value={comment}
                    />
                  </MotifFormField>
                </div>
              </div>

              <div className="document-uploader">
                <MotifFileUploader
                  label={t("pages.claims.documentPopup.uploadText")}
                  labelDescription={t(
                    "pages.claims.documentPopup.uploadDescription"
                  )}
                  onDrop={(files) => handleFileChange(files)}
                  maxFiles={1}
                  accept={fileExtensions.default}
                >
                  {file && (
                    <MotifFileUploaderItem
                      fileIcon={<MotifIcon src={actionIcDescription24px} />}
                      fileName={file.name}
                      uploaded={true}
                      uploadedText={
                        t("pages.claims.documentPopup.displayName") + fileName
                      }
                      error={
                        (file && file.size >= fileExtensions.maxFileSize) ||
                        false
                      }
                      errorText={t(
                        "pages.claims.stageStatusPopup.filesizeExceedsLimit",
                        {
                          filesize: "250",
                        }
                      )}
                    />
                  )}
                </MotifFileUploader>
              </div>
            </>
          )}
        </MotifModalBody>
        <MotifModalFooter>
          <div className="document-footer">
            <MotifButton
              onClick={() => {
                if (discardtrig) {
                  setShowDiscardMessage(true);
                } else {
                  setShow(false);
                }
              }}
              variant="secondary"
            >
              {t("pages.claims.documentPopup.cancel")}
            </MotifButton>

            <MotifButton
              onClick={handleSubmit}
              variant="primary"
              type={isUploading ? "loader" : "submit"}
              disabled={
                (file && file.size >= fileExtensions.maxFileSize) ||
                documentType === "" ||
                file === null ||
                fileName === "" ||
                isUploading
              }
            >
              {isEdit
                ? t("pages.claims.documentPopup.save")
                : t("pages.claims.documentPopup.upload")}
            </MotifButton>
          </div>
        </MotifModalFooter>
      </MotifModal>

      <ConfirmationModal
        isVisible={showDiscardMessage}
        setIsVisible={setShowDiscardMessage}
        cancelBtnApproveHandler={() => setShow(false)}
        cancelBtnRejectHandler={() => setShowDiscardMessage(false)}
      />
    </>
  );
}
