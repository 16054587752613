import React from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ClaimDetailsType } from "./type";
import SelectBox from "@/components/Form/SelectBox/SelectBox";
import { NonEmptyString } from "@/utility/common";

type ControlSelectType = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<ClaimDetailsType>;
  name: string;
  showLabel: boolean;
  label: string;
  options: {
    label: string;
    value: string;
  }[];
  setValue: UseFormSetValue<ClaimDetailsType>;
  getValues: UseFormGetValues<ClaimDetailsType>;
  value?: string;
  disabled?: boolean;
  multiple?: boolean;
};

export default function ControlSelect({
  name,
  control,
  required = false,
  options,
  showLabel,
  label,
  value,
  disabled,
  multiple,

}: ControlSelectType) {
  const { t } = useTranslation();
  if (options === undefined) {
    return null;
  }

  const translatedOptions =
    options &&
    options.map((option) => {
      return {
        label: option.label,
        value: option.value,
      };
    });

  const defaultValue = NonEmptyString(value) ? value : options[0]?.value;
  return (
    <>
      {showLabel && (
        <div className="formFieldLabel">
          <label htmlFor="input" aria-label={label}>
            {t(label)}
            {required ? <span className="requiredSymbol">*</span> : ""}
          </label>
        </div>
      )}
      <Controller
        // @ts-ignore
        name={name}
        control={control}
        rules={{ required }}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <SelectBox
                options={translatedOptions}
                value={value}
                onChange={onChange}
                disabled={disabled}
                multiple={multiple}
              />
            </>
          );
        }}
      />
    </>
  );
}
