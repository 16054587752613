import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "@/pages/Home/Home";
import UserManagement from "@/pages/UserManagement/UserManagement";
import MyProfile from "@/pages/MyProfile/MyProfile";
import SystemSettings from "@/pages/SystemSettings/SystemSettings";
import HelpSupport from "@/pages/HelpSupport/HelpSupport";
import "./main.scss";
import Logout from "@/features/auth/logout";
import CaseRoutes from "./CaseRoutes";
import CaseSetupGrid from "@/pages/CaseDetail/CaseSetup/CaseSetupGrid";
import GlobalConfirmationModal from "./GlobalConfirmationModal";

export default function Main() {
  return (
    <main className="main pad-l-60 pad-v-40 pad-r-40">
      <GlobalConfirmationModal />
      <Routes>
        <Route path="/" element={<Home />} index />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/system-settings" element={<SystemSettings />} />
        <Route path="/help-support" element={<HelpSupport />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/case/*" element={<CaseRoutes />} />
        <Route path="/case-setup" element={<CaseSetupGrid />} />
        <Route path="*" element={<>Page not found</>} />
      </Routes>
    </main>
  );
}
