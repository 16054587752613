 import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CaseSetupFormType, CaseSetupType, CurrencyConfigurationType } from './CaseSetupType';
import { DebtorData } from './DebtorInformation/type';

export const initialState:CaseSetupType = {
    caseSetupForm: {
        caseName: "",
        caseDescription: "",
        caseDescriptionInFrench: "",
        caseShortName: "",
        status: "",
        caseNameInFrench: "",
        isFrenchSelected: false,
        caseTimeZone: "",
        caseTimeZoneName: "",

    },
    debtorData: [],
    currencyConfiguration: {
        defaultCurrency: "",
        defaultExchangeRate: "",
        defaultDecimalPoint: "",
        fieldArray: [
        ]
    },
    stage: 0,
    formSubmitIndex: 0,
}

export const CaseSetupSlice = createSlice({
    name: 'caseSetup',
    initialState,
    reducers: { 
      setCaseIdentifier: (state, action: PayloadAction<CaseSetupFormType>) => {
        return {...state, caseSetupForm: action.payload}; 
      },
      resetCaseSetup: () => {
        return initialState;
      },
      updateStage: (state, action: PayloadAction<number>) => {
        return {...state, stage: action.payload};
      },
      setDebtorInformation: (state, action: PayloadAction<DebtorData[]>) => {
        return {...state, debtorData: action.payload}; 
      },
      setCurrencyConfigurationData: (state, action: PayloadAction<CurrencyConfigurationType>) => {
        return {...state, currencyConfiguration: action.payload}; 
      },
      updateFormSubmitIndex: (state, action: PayloadAction<number>) => {
        return {...state, formSubmitIndex: action.payload};
      },
    },
})

export const { setCaseIdentifier, resetCaseSetup, updateStage, setDebtorInformation, setCurrencyConfigurationData, updateFormSubmitIndex } = CaseSetupSlice.actions;
export default CaseSetupSlice.reducer;