import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ConfigurationTab, IConfigurationState } from "./ConfigurationType";

export const initialState: IConfigurationState = {
  currentTab: ConfigurationTab.Units,
};

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    setCurrentTab(state: IConfigurationState, action: PayloadAction<ConfigurationTab>) {
      return { currentTab: action.payload };
    },
  },
});

export const {setCurrentTab} = configurationSlice.actions;
export default configurationSlice.reducer;