import { j as r } from "../../jsx-runtime-BwWtxLpl.js";
import w, { useRef as b, useEffect as R } from "react";
import H from "classnames";
import { MotifTableTitleBar as j } from "./title-bar/title-bar.js";
import { AgGridReact as T } from "ag-grid-react";
const y = (a) => a ? { headerHeight: 43, rowHeight: 46 } : { headerHeight: 43, rowHeight: 72 }, M = w.forwardRef(
  ({
    className: a = "",
    title: e = "",
    description: o = "",
    localeText: s = {},
    firstColumnBorderRight: N = !1,
    compact: l = !1,
    paginationSelect: S = null,
    rowData: i = [],
    paginationPageSize: f = 4,
    pagination: n = !1,
    onFilterChanged: z,
    zebra: c = !1,
    titleComplement: h = null,
    ...m
  }, p) => {
    const { headerHeight: u, rowHeight: g } = y(l), t = b(null);
    R(() => {
      t.current && (e.length > 0 ? t.current.style.setProperty("--wrapper-border-radius", "0 0 8px 8px") : t.current.style.setProperty("--wrapper-border-radius", "8px"));
    }, [e]);
    const d = H({
      "ag-theme-quartz": !0,
      "motif-table": !0,
      "motif-table-has-title": e,
      "motif-table-zebra": c
    }), x = {
      page: " ",
      of: "/",
      ariaRowSelectAll: "Select all checkbox",
      ...s
    };
    return /* @__PURE__ */ r.jsxs(r.Fragment, { children: [
      e && /* @__PURE__ */ r.jsx(j, { title: e, description: o, titleComplement: h }),
      /* @__PURE__ */ r.jsx("div", { ref: t, className: d, style: { height: "100%" }, children: /* @__PURE__ */ r.jsx(
        T,
        {
          ref: p,
          rowHeight: g,
          headerHeight: u,
          localeText: x,
          rowData: i,
          pagination: n,
          paginationPageSize: f,
          ...m
        }
      ) })
    ] });
  }
);
export {
  M as MotifTable,
  y as getRowsHeight
};
