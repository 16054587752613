import { ccwpApi } from "@/services";

export const AdvanceSearchApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getCreditorListAttribute: builder.query<any, string | undefined>({
      query: (caseId) => {
        return {
          url: `CreditorAdvancedSearch/ListAttributes?caseId=${caseId}`,
        };
      },
    }),
    getOperatorList: builder.query<any, number>({
      keepUnusedDataFor: 60 * 60 * 8, // 8 hrs in seconds
      query: (attributeType) => {
        return {
          url: `Search/ListOperators?attributeType=${attributeType}`,
        };
      },
    }),
    getCreditorAdvanceSearchData: builder.mutation({
      query: (searchData) => ({
        url: "CreditorAdvancedSearch/GetSearchResults",
        method: "POST",
        body: searchData,
      }),
    }),
    getClaimListAttribute: builder.query<any, string | undefined>({
      query: (caseId) => {
        return {
          url: `ClaimAdvancedSearch/ListAttributes?caseId=${caseId}`,
        };
      },
    }),
    getClaimAdvanceSearchData: builder.mutation({
      query: (searchData) => ({
        url: "ClaimAdvancedSearch/GetSearchResults",
        method: "POST",
        body: searchData,
      }),
    }),
  }),
});

export const {
  useGetCreditorListAttributeQuery,
  useGetOperatorListQuery,
  useGetCreditorAdvanceSearchDataMutation,
  useGetClaimListAttributeQuery,
  useGetClaimAdvanceSearchDataMutation,
  useLazyGetOperatorListQuery,
} = AdvanceSearchApi;
