import Table from "@/components/Table/table";
import {
  MotifButton,
  MotifCheckbox,
  MotifIcon,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
  MotifToggleSwitch
} from "@ey-xd/motif-react";
import { IconoirPlus } from "@ey-xd/motif-icon";
import {
  useDeleteLimitAmountMutation,
  useGetCalculationStepLimitAmountApiDataQuery,
  useGetManualDataSourceApiDataQuery,
  useUpdateCapAndFloorLimitMutation,
} from "./calculationStepReducer";
import { useEffect, useState } from "react";
import LimitAmountForm from "./FormModal/LimitAmountForm";
import {
  IconoirEditPencil,
  IconoirTrash,
  IconoirWarningTriangle,
} from "@ey-xd/motif-icon";
import LimitAmountFormNew from "./FormModal/LimitAmountFormNew";
import { useTranslation } from "react-i18next";

export const CalculationStepLimitAmount = ({
  caseId,
  stepId,
  dataByStepId,
  summaryData,
  distributionStatus
}: any) => {
  const {t}=useTranslation()
  const [createShow, setCreateShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [editId, setEditId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const { data, refetch } =
    useGetCalculationStepLimitAmountApiDataQuery(stepId);
  const { data: manualDataSource } = useGetManualDataSourceApiDataQuery(caseId);

  const [updateCapAndFloorLimit] = useUpdateCapAndFloorLimitMutation();
  const [isZeroLimit, setIsZeroLimit] = useState({
    limitAsCap: summaryData?.isCapZeroLimit,
    limitAsFloor: summaryData?.isFloorZeroLimit,
    limitAmountOrder : summaryData?.limitAmountOrder
  });

  const handleZeroLimitChange = (name: any, checked: any) => {
    setIsZeroLimit({ ...isZeroLimit, [name]: checked });
    const capAndFloorData = {
      calculationStepId: stepId,
      isCapZeroLimit: name === "limitAsCap" ? checked : isZeroLimit.limitAsCap,
      isFloorZeroLimit: name === "limitAsFloor" ? checked : isZeroLimit.limitAsFloor,
      limitAmountOrder: name === "limitAmountOrder" ? checked ? 1: 0 : isZeroLimit.limitAmountOrder
    };
    updateCapAndFloorLimit(capAndFloorData).then((response: any) => {
      if (response.hasOwnProperty("data")) {
        refetch();
      }
    });
  };
  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [dataByStepId]);
  const TitleComplement = () => {
    const {t}=useTranslation()
    return (
      <>
        <MotifCheckbox
          onChange={(e: any) => handleZeroLimitChange("limitAsCap", e.target.checked)}
          checked={isZeroLimit?.limitAsCap}
          /*@ts-ignore */
          value={isZeroLimit?.limitAsCap}
          disabled={distributionStatus}
        >
{t("pages.distributions.distributionStepsTab.limitAmountTab.limitCapCheckBox")}        </MotifCheckbox>
        <MotifCheckbox
          onChange={(e: any) => handleZeroLimitChange("limitAsFloor", e.target.checked)}
          checked={isZeroLimit?.limitAsFloor}
          /*@ts-ignore */
          value={isZeroLimit?.limitAsFloor}
          disabled={distributionStatus}
        >
{t("pages.distributions.distributionStepsTab.limitAmountTab.limitFloorCheckBox")}        </MotifCheckbox>
        <MotifToggleSwitch
          id="applyCapOrFloorFirst"
          onLabel={t("pages.distributions.distributionStepsTab.limitAmountTab.applyCapFirst")}
          offLabel={t("pages.distributions.distributionStepsTab.limitAmountTab.applyFloorFirst")}
          onChange={
            (e: any) => handleZeroLimitChange("limitAmountOrder", e.target.checked)
          }
          checked={isZeroLimit?.limitAmountOrder ? true : false}
        />
        <MotifButton
          onClick={() => {
            setCreateShow(true);
            setEditId("");
          }}
          disabled={distributionStatus}
          size="small"
          type="submit"
          variant="secondary"
        >
          <MotifIcon iconFunction={IconoirPlus} />
        </MotifButton>
      </>
    );
  };
  const columnClassFilterDefs = [
    {
      field: "limitType",
      headerName: t("pages.distributions.distributionStepsTab.limitAmountTab.columnDetails.column1"),
      width: "150",
      cellRenderer: function (params: any) {
        return <>{params?.data.limitType === 0 ? "Floor" : "Cap"}</>;
      },
    },
    // {
    //   field: "isZeroLimit",
    //   headerName: "Use zero limit",
    //   width: "150",
    // },
    {
      field: "dataSourceType",
      headerName: t("pages.distributions.distributionStepsTab.limitAmountTab.columnDetails.column2"),
      width: "200",
      cellRenderer: function (params: any) {
        return (
          <>
            {params?.data.dataSourceType === 0
              ? "Accepted Claims"
              : params?.data.dataSourceType === 1
              ? "Prior Step"
              : "Manual"}
          </>
        );
      },
    },
    {
      field: "weight",
      headerName: t("pages.distributions.distributionStepsTab.limitAmountTab.columnDetails.column3"),
      width: "150",
    },
    {
      field: "isFilterApplicableClasses",
      headerName: t("pages.distributions.distributionStepsTab.limitAmountTab.columnDetails.column4"),
      width: "250",
    },
    {
      field: "sourceManualDataSourceName",
      headerName: t("pages.distributions.distributionStepsTab.limitAmountTab.columnDetails.column5"),
      width: "250",
      cellRenderer: function (params: any) {
        return (
          <>
            {params?.data.sourceManualDataSourceName === null
              ? "-"
              : params?.data.sourceManualDataSourceName}
          </>
        );
      },
    },
    {
      field: "sourceCalculationStepName",
      headerName: t("pages.distributions.distributionStepsTab.limitAmountTab.columnDetails.column6"),
      width: "200",
      cellRenderer: function (params: any) {
        return (
          <>
            {params?.data.sourceCalculationStepName === null
              ? "-"
              : params?.data.sourceCalculationStepName}
          </>
        );
      },
    },

    {
      field: "action",
      headerName: "",
      width: "150",
      cellRenderer: function (params: any) {
        return (
          <>
            <MotifButton
              className="action-delete-button"
              size="small"
              variant="primary"
              onClick={() => {
                setShowModal(true);
                setDeleteId(params?.data?.id);
              }}
              disabled={distributionStatus}
            >
              <MotifIcon
                title="Disable Unit"
                fill="none"
                iconFunction={IconoirTrash}
              />
            </MotifButton>
            <MotifButton
              size="small"
              variant="primary"
              disabled={distributionStatus}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setUpdateShow(true);
                setEditId(params?.data?.id);
              }}
            >
              <MotifIcon
                iconFunction={IconoirEditPencil}
                stroke="blue"
                strokeWidth="2"
                size="20"
              />
            </MotifButton>
          </>
        );
      },
    },
  ];
  const [deleteLimitAmount] = useDeleteLimitAmountMutation();
  async function deleteClassFilterHandler(Id: string) {
    const response = await deleteLimitAmount(Id);
    if (response.hasOwnProperty("data")) {
      refetch();
    } else {
      console.log("Error in deleting class filter");
    }
  }
  return (
    <>
      <MotifModal
        onClose={() => setShowModal(false)}
        show={showModal}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <div className="confirmationModalWrapper creditor_disabling_model">
          <MotifModalHeader
            headerIcon={
              <>
                <span
                  style={{
                    backgroundColor: "rgb(252, 245, 243)",
                    borderRadius: "50px",
                    padding: "5px 8px 2px 8px",
                  }}
                >
                  <MotifIcon
                    iconFunction={IconoirWarningTriangle}
                    strokeWidth="1"
                    size="15"
                    stroke="red"
                  />{" "}
                </span>
                <span
                  style={{
                    fontSize: 16,
                    display: "block",
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  {t("global.globalNotifications.warning")}
                </span>
              </>
            }
          ></MotifModalHeader>

          <MotifModalBody style={{ paddingTop: "0px" }}>
            <span style={{ fontWeight: "300" }}>
              {t("pages.distributions.distributionStepsTab.limitAmountTab.deleteWarningMessage")}
            </span>
          </MotifModalBody>
          <MotifModalFooter>
            <MotifButton
              size="small"
              type="submit"
              variant="secondary"
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              onClick={() => {
                deleteClassFilterHandler(deleteId);
                setShowModal(false);
              }}
            >
              {t("global.globalActions.yes")}
            </MotifButton>
            <MotifButton
              className=""
              onClick={() => setShowModal(false)}
              size="small"
              type="button"
            >
                            {t("global.globalActions.cancel")}

            </MotifButton>
          </MotifModalFooter>
        </div>
      </MotifModal>
      <Table
        columnDefs={columnClassFilterDefs}
        cellRenderers={data}
        TitleComplement={TitleComplement}
        title={"pages.distributions.distributionStepsTab.limitAmountTab.tableTitle"}
        description={"pages.distributions.distributionStepsTab.limitAmountTab.tableDescription"}
        pagination={false}
        overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
      />
      {updateShow && (editId && editId !== "") ? (
        <LimitAmountForm
          caseId={caseId}
          stepId={stepId}
          updateShow={updateShow}
          setUpdateShow={setUpdateShow}
          refetch={refetch}
          manualDataSource={manualDataSource}
          setEditId={setEditId}
          editId={editId}
        />
      ) : (
        <LimitAmountFormNew
          caseId={caseId}
          stepId={stepId}
          createShow={createShow}
          setCreateShow={setCreateShow}
          refetch={refetch}
          manualDataSource={manualDataSource}
        />
      )}
    </>
  );
};
