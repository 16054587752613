import { useParams } from "react-router-dom";
import {
    MotifProgressLoader,
    MotifToggleSwitch,
    MotifFormField,
} from "@ey-xd/motif-react";
import { useListRateTierTemplateQuery, useToggleRateTierStatusMutation } from "@/services/rateTierTemplate/RateTierTemplateReducer";
import { useGetColumnDefsRateTierTemplate } from "./rateTierTemplateConst";
import Table from "@/components/Table/table";
import { useState } from "react";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { customPaginationPageSize, customPaginationPageSizeSelector, customTableLoadingData } from "@/components/Table/CustomPaginationConstants";
import { useCase } from "@/features/case/use-case";
import { useTranslation } from "react-i18next";


export const RateTierTemplate = () => {
    const parameter = useParams();
    const caseData = useCase();
    const {t}=useTranslation();
    const paginationPageSize: number = customPaginationPageSize;
    const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
    let tableLoadingData = customTableLoadingData
    const [paginationObj, setPaginationObj] = useState({
        CaseId: parameter?.caseId ?? "",
        PageNumber: 1,
        PageSize: paginationPageSize,
        SearchText: "",
        SortOrder: "desc",
        SortColumn: "Year"
    });
const getColumnDefsRateTierTemplate=useGetColumnDefsRateTierTemplate(caseData?.abbreviation)
    const { data, isLoading, refetch, isFetching, error } = useListRateTierTemplateQuery(
        paginationObj
    );

    let [sortedColumnTypes, setSortedColumnTypes] = useState<any>({ yearType: { sort: "desc" } })

    function handlePaginationData(data: any, columnTypesData: any) {
        setPaginationObj({ ...data })
        setSortedColumnTypes({ ...columnTypesData })
    }

    const [toggleRateTierStatus] = useToggleRateTierStatusMutation();

    const handleToggle = async (id: string, isActive: boolean) => {
        await toggleRateTierStatus({ id, isActive: !isActive });
        refetch();
    };

    const TitleComplement = () => {
        return <></>;
    };

    const customActionHeader = {
        actionRenderer: function (props: any) {
            return (
                <MotifFormField>
                    <MotifToggleSwitch
                        id={`toggle-${props.data.id}`}
                        onLabel=""
                        offLabel=""
                        checked={props.data.isActive}
                        onChange={() => handleToggle(props.data.id, props.data.isActive)}
                    />
                </MotifFormField>
            );
        },
    };

    return (
        <div className="mar-b-20">
            {isLoading ? (
                <MotifProgressLoader show variant="default" className="mar-b-20" />
            ) : (
                <>
                    <Table
                        columnDefs={getColumnDefsRateTierTemplate}
                        columnTypes={{ ...sortedColumnTypes }}
                        handlePaginationData={handlePaginationData}
                        paginationObj={paginationObj}
                        cellRenderers={isFetching ? [] : data?.results || []}
                        TitleComplement={TitleComplement}
                        title={"pages.distributions.rateTire.tableTitle"}
                        description={"pages.distributions.rateTire.tableDescription"}
                        caseRenderers={customActionHeader}
                        pagination={false}
                        overlayNoRowsTemplate={isFetching ? t(tableLoadingData) : error ?  t("global.globalNotifications.wentWrong")
                         : t("global.globalNotifications.noRecords")}
                    />
                    <CustomPagination
                        currentpage={paginationObj.PageNumber}
                        pageSize={paginationObj.PageSize}
                        disableNext={!data?.hasMoreRecords}
                        onChangePageSize={(page: number) => {
                            setPaginationObj({
                                ...paginationObj,
                                PageSize: page,
                                PageNumber: 1,
                            });
                        }}
                        onChangeNextPage={() => {
                            setPaginationObj({
                                ...paginationObj,
                                PageNumber: paginationObj.PageNumber + 1,
                            });
                        }}
                        onChangePreviousPage={() => {
                            setPaginationObj({
                                ...paginationObj,
                                PageNumber: paginationObj.PageNumber - 1,
                            });
                        }}
                        pageSizeSelector={paginationPageSizeSelector}
                    />
                </>
            )}
        </div>
    );
};

export default RateTierTemplate;
