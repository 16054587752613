
// @ts-check
import { useDispatch, useSelector } from "react-redux";
import PageSubTitle from "@/components/Form/PageSubTitle/PageSubTitle";
import { useEffect, useState } from "react";
import Button from "@/components/Form/Button/Button";
import { SECONDARY } from "@/Constant";
import { useTranslation } from "react-i18next";
import { useListAttributesByEntityQuery } from "@/services/customAtrributes/customAttributesReducer";
import { setClaimItemsAttributes } from "./attributeSlice";
import CustomAttribute from "@/components/CustomAttribute/CustomAtrribute";
import { AttributeType } from "@/pages/CaseDetail/Configuration/Attributes/type";
import { MotifErrorMessage, MotifProgressLoader } from "@/libs/@ey-xd/motif-react";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { useCase } from "@/features/case/use-case";
import useResetAllClaimItemsStore from "../useResetAllClaimItemsStore";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

interface DebtorProps {
  goToNextTab: () => void;
}

export default function Attributes({ goToNextTab }: DebtorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const caseData = useCase();
  const resetAllClaimItemsStore = useResetAllClaimItemsStore();

  const { data, isLoading, error, refetch } = useListAttributesByEntityQuery({
    CaseId: caseData.caseId,
    EntityType: 2,
    IsCreate: true,
  });
  const attributesValue = useSelector(
    (state: any) => state.claimSetupAttributes.attributes
  );

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (data && attributesValue.length === 0) {
      dispatch(setClaimItemsAttributes(data));
    }
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: true }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  const setAttributesValues = (value: any) => {
    dispatch(setClaimItemsAttributes(value));
  };

  const isDisabled = () => {
    var disabled = false;
    const required = attributesValue.filter((x: any) => {
      return x.isRequired;
    });

    required.forEach((x: any) => {
      switch (x.value.attributeType) {
        case AttributeType.FreeText:
          disabled = !x.value.textValue;
          break;
        case AttributeType.Number:
          disabled = !x.value.numberValue;
          break;
        case AttributeType.Boolean:
          disabled = !x.value.booleanValue;
          break;
        case AttributeType.Date:
          disabled = !x.value.dateValue;
          break;
        case AttributeType.SingleSelect:
          disabled = !x.value.selectedValue;
          break;
        case AttributeType.MutliSelect:
          disabled =
            !x.value.selectedValues || x.value.selectedValues.length === 0;
          break;
        case AttributeType.Currency:
          disabled =x.value.amount===0 || x.value.amount?false:true;
          break;
        case AttributeType.Document:
          disabled = !x.value.displayName;
          break;
        default:
          disabled = false;
      }
    });

    return disabled;
  };

  const handleNextClick = () => {
    if (isDisabled()) {
      setIsError(true);
    } else {
      goToNextTab();
    }
  };

  const confirmationModalCancelHandler = () => {
    setIsVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));

  };

  const confirmationModalDeleteHandler = () => {
    setIsVisible(false);
    resetAllClaimItemsStore();
    navigate(`/case/${caseData.caseId}/?activeTab=3`);
  };
  if (isLoading) {
    return <MotifProgressLoader />;
  }
  if (error) {
    return <div>...Error</div>; 
  }
  
  return (
    <>
      <PageSubTitle
        title={"pages.claims.claimCreate.cliemAttributeTab.title"}
        description={"pages.claims.claimCreate.cliemAttributeTab.description"}
      />

      <div className="pad-v-25 motif-row">
        {data && attributesValue.length === data.length ? (
          data.map((attribute: any, index: number) => {
            return (
              <div className="motif-col-md-4">
                <CustomAttribute
                  customAttributeDetails={{
                    ...attribute,
                    metadataJSON: JSON.parse(attribute.metadataJSON),
                  }}
                  customAttributeType={attribute.value.attributeType}
                  attributeValue={attributesValue}
                  setAttributeValue={setAttributesValues}
                  index={index}
                  key={index}
                />
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>

      <div>
        {isError ? (
          <MotifErrorMessage>
            {t("pages.configuration.attributes.requiredFields")}
          </MotifErrorMessage>
        ) : (
          <></>
        )}
      </div>

      <div className="motif-row justify-content-end mar-t-70">
        <div className="display-flex">
          <div className="pad-r-10">
            <Button
              type="button"
              label={t("global.globalActions.cancel")}
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={() =>{setIsVisible(true);      dispatch(setDiscardEditMode({ editMode: false }));
            }}
            />
          </div>
          <div className="pad-r-10">
            <Button
              label={t("global.globalActions.next")}
              variant="primary"
              onClickHandler={handleNextClick}
            />
          </div>
        </div>
        <ConfirmationModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          cancelBtnApproveHandler={confirmationModalDeleteHandler}
          cancelBtnRejectHandler={confirmationModalCancelHandler}
        />
      </div>
    </>
  );
}
