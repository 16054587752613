import React, { useEffect, useState } from 'react'
import "./CaseSetup.scss";
import { MotifAccordion, MotifAccordionContent, MotifAccordionTrigger } from '@ey-xd/motif-react';
import ControlInputBox from   "./Form/ControlInputBox"
import Button from '@/components/Form/Button/Button';
import { useForm } from "react-hook-form";
import { MotifErrorMessage } from '@ey-xd/motif-react';
import {useTranslation} from 'react-i18next';
import { CaseSetupFormType } from './Form/type';
import { CANCEL, ENGLISH, FRENCH, NEXT, REQUIRED_MESSAGE, SECONDARY } from '@/Constant';
import { CASE_CONFIGURATION, CASE_DESCRIPTION, CASE_DESCRIPTION_ID, CASE_DESCRIPTION_ID_F, CASE_ID, CASE_IDENTIFIERS, CASE_IDENTIFIERS_SELECTION, CASE_ID_F, CASE_NAME, CASE_SHORT_ID, CASE_TIME_ZONE, CASE_TIME_ZONE_LABEL, CASE__SHORT_NAME, FORM_DESCRIPTION, FORM_TITLE, IS_FRENCH_SELECTED } from './Constants';
import ConfirmationModal from '@/components/Modal/ConfirmationModal/ConfirmationModal';
import ControlCheckBox from '@/components/Form/CheckBox/ControlCheckBox';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { capitalizeWordsInSentence, getTimezones } from '@/utility/common';
import { RootState } from '@/app/store';
import { initialState, setCaseIdentifier, updateFormSubmitIndex, updateStage } from './caseSetupSlice';
import ControlTextAreaWithCharLimit from '@/components/Form/InputBox/ControlTextAreaWithCharLimit';
import ControlSelect from '@/components/Form/SelectBox/ControlSelect';
import { useGetTimeZoneListQuery } from '../Configuration/CaseTimeZones/caseTimeZoneReducer';
import { setDiscardEditMode } from '@/components/Modal/ConfirmationModal/discardchanges';

export default function CaseSetup() { 
  const [isVisible, setIsVisible] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(true);
  const [showError, setShowError] = useState(false);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const CaseSetupData = useSelector(
    (state: RootState) => state.caseSetup
  );
  // const timeZoneList = useSelector(
  //   (state: RootState) => state.timezone
  // );
  const caseSetupForm = CaseSetupData.caseSetupForm;
  const formSubmitIndex = CaseSetupData.formSubmitIndex;

  const {data, isLoading} =
  useGetTimeZoneListQuery({});

  const [isFrenchSelected, setIsFrenchSelected] = useState(caseSetupForm.isFrenchSelected || false);

  const { handleSubmit, control, reset,getValues, setValue, formState: { errors,isDirty }} = useForm<CaseSetupFormType>(
    {
      defaultValues: {
        caseName: caseSetupForm.caseName,
        caseDescription: caseSetupForm.caseDescription,
        caseDescriptionInFrench: caseSetupForm.caseDescriptionInFrench,
        caseShortName: caseSetupForm.caseShortName,
        caseNameInFrench: caseSetupForm.caseNameInFrench,
        status: caseSetupForm.status,
        isFrenchSelected: caseSetupForm.isFrenchSelected,
        caseTimeZone: caseSetupForm.caseTimeZone,
      }
    }
  );
  const checkBoxHandler = (e?: React.ReactEventHandler) => {
    setIsFrenchSelected(!isFrenchSelected);
  }

  const cancelBtnApproveHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const {caseSetupForm} = initialState;
    reset({
        caseName: caseSetupForm.caseName,
        caseDescription: caseSetupForm.caseDescription,
        caseDescriptionInFrench: caseSetupForm.caseDescriptionInFrench,
        caseShortName: caseSetupForm.caseShortName,
        caseNameInFrench: caseSetupForm.caseNameInFrench,
        status: caseSetupForm.status,
        isFrenchSelected: caseSetupForm.isFrenchSelected,
        caseTimeZone: caseSetupForm.caseTimeZone,
    });
    dispatch(setCaseIdentifier(caseSetupForm));
    navigate('/');
  }

  const errorList = Object.keys(errors);
  let errorsShow = capitalizeWordsInSentence(errorList.join(", "));

  const onSubmit = (formData:CaseSetupFormType) => {
    if (!formData.caseName || formData.caseName.trim() === "") {
      setShowError(true);
      return;
    }
    const caseTimeZoneName = timeZones.find((timeZone) => Number(timeZone.value) === Number(formData.caseTimeZone))?.label;
    dispatch(setCaseIdentifier({...formData, caseTimeZoneName}));
    dispatch(updateStage(1));
    formSubmitIndex !== 3 && dispatch(updateFormSubmitIndex(0));
  } 
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,isDirty]);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  }, [showError]);
  if (isLoading) {
    return <div>Loading...</div>
  }

  const timeZones = getTimezones(data);
  
  return (
    <>
      <div className="motif-container pad-l-40 pad-r-20">
        <div className="motif-row"> 
            <div className="motif-col-lg-12  main-container">
              <div className="motif-row t-b-padding-21"> 
                <div className="motif-col-xs-4">        
                  <p className='form-title'>{t(FORM_TITLE)}</p>
                  <p className='form-description'>{t(FORM_DESCRIPTION)}</p>
                </div>
              </div>
              <div className="motif-row t-b-padding-21"> 
                <div className="motif-col-xs-4"> 
                  <div
                    style={{
                      alignItems: 'flex-start',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '15px'
                    }}
                  >
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <MotifAccordion
                        onClose={()=>setIsFormOpen(false)}
                        onOpen={()=>setIsFormOpen(true)}
                        style={{
                          flex: '1'
                        }}
                        open={isFormOpen}
                        useChevronIcon={true}
                        alignIconRight={true}
                      >
                        <MotifAccordionTrigger>
                          {t(CASE_IDENTIFIERS)} ({t(ENGLISH)})
                        </MotifAccordionTrigger>
                        <MotifAccordionContent>
                          <div className="motif-row"> 
                            <div className="motif-col-xl-6">
                              <div className='form-field'> 
                                <ControlInputBox name={CASE_ID} control={control} required={true} isTextArea={false} label={t(CASE_NAME)} showLabel={true} />
                              </div>
                            </div>
                            <div className="motif-col-xl-6">
                              <div className='form-field'> 
                                {/* <ControlInputBox name={CASE_DESCRIPTION_ID} control={control} required={true} isTextArea={true} label={t(CASE_DESCRIPTION)} showLabel={true} /> */}
                                <ControlTextAreaWithCharLimit
                                    name={CASE_DESCRIPTION_ID} 
                                    control={control} 
                                    required={true} 
                                    isTextArea={true} 
                                    label={t(CASE_DESCRIPTION)} 
                                    showLabel={true}
                                  />
                              </div>
                            </div>
                          </div>
                          <div className="motif-row next-row"> 
                            <div className="motif-col-md-4">
                              <div className='form-field'> 
                                <ControlInputBox name={CASE_SHORT_ID} control={control} required={true}  label={t(CASE__SHORT_NAME)} showLabel={true} />
                              </div>
                            </div>
                          </div>
                          <div className="motif-row next-row pad-t-20 mar-t-20"> 
                            <div className="motif-col-md-12">
                              <div className='form-field'> 
                                <ControlCheckBox id="checkbox-french-selection"  
                                  onChange={checkBoxHandler} 
                                  checked={isFrenchSelected}  
                                  value={isFrenchSelected}
                                  name={IS_FRENCH_SELECTED} 
                                  control={control} 
                                  required={false}  
                                  label={t(CASE_IDENTIFIERS_SELECTION)}  
                                  setIsFrenchSelected={setIsFrenchSelected} 
                                  /> 
                              </div>
                            </div>
                          </div>
                          <div className={`motif-row pad-t-20 mar-t-20 ${ !isFrenchSelected ? 'hide' : ''}`}> 
                            <div className="motif-col-md-4">
                              <div className='form-field'> 
                              <ControlInputBox name={CASE_ID_F} control={control} required={isFrenchSelected ? true : false} isTextArea={false} label={t(`${CASE_NAME} (${FRENCH})`)} showLabel={true} />
                              </div>
                            </div>
                            <div className="motif-col-md-4">
                              <div className='form-field'>
                                {/* <ControlInputBox name={CASE_DESCRIPTION_ID_F} control={control} required={isFrenchSelected ? true : false}  isTextArea={true} label={t(`${CASE_DESCRIPTION} (${FRENCH})`)} showLabel={true} /> */}
                                <ControlTextAreaWithCharLimit
                                   name={CASE_DESCRIPTION_ID_F} 
                                   control={control} 
                                   required={isFrenchSelected ? true : false}  
                                   isTextArea={true} 
                                   label={t(`${CASE_DESCRIPTION} (${FRENCH})`)} 
                                   showLabel={true} 
                                  />
                              </div>
                            </div>
                          </div>
                        </MotifAccordionContent>
                      </MotifAccordion>
                      <MotifAccordion
                        onClose={()=>setIsFormOpen(false)}
                        onOpen={()=>setIsFormOpen(true)}
                        style={{
                          flex: '1'
                        }}
                        open={isFormOpen}
                        useChevronIcon={true}
                        alignIconRight={true}
                      >
                        <MotifAccordionTrigger>
                          {t(CASE_CONFIGURATION)} 
                        </MotifAccordionTrigger>
                        <MotifAccordionContent>
                          <div className="motif-row"> 
                            <div className="motif-col-xl-6">
                              <div className='form-field'> 
                                <ControlSelect name={CASE_TIME_ZONE} control={control} required={true} label={CASE_TIME_ZONE_LABEL} options={timeZones} showLabel={true} getValues={getValues} setValue={setValue} filter={true}/>
                              </div>
                            </div>
                          </div>
                        </MotifAccordionContent>
                      </MotifAccordion>
                      <div className="error-container">
                        <MotifErrorMessage>
                          {errorList.length > 0 && !isVisible ? t(`${REQUIRED_MESSAGE} ${errorsShow} cannot be left blank.`) : ''}
                        </MotifErrorMessage>
                        <MotifErrorMessage>
                          {showError && t(`The case name cannot be empty or contain only spaces.`)}
                        </MotifErrorMessage>
                      </div>
                      <div className="button-container"> 
                        <Button label={t(NEXT)}  />
                        <Button type='button' label={t(CANCEL)} variant={SECONDARY} className="cancelBtn" onClickHandler={(e)=>{
                          if(isDirty){
                            setIsVisible(true);      dispatch(setDiscardEditMode({ editMode: false }
                            
                            ));
                          }
                          else{
                            cancelBtnApproveHandler(e)
                          }
                         
}}  />
                      </div>
                    </form>
                    <ConfirmationModal isVisible={isVisible} setIsVisible={setIsVisible} cancelBtnApproveHandler={cancelBtnApproveHandler} cancelBtnRejectHandler={()=>{setIsVisible(false);      dispatch(setDiscardEditMode({ editMode: true }));
}}/>
                  </div>
                </div>
              </div>
            </div>
            </div>
      </div>
    </>
  )
}