import React, { useEffect, useState } from "react";
import {
  MotifButton,
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifIcon,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";
import {
  actionIcDescription24px,
  editorIcVerticalAlignBottom24px,
} from "@ey-xd/motif-react/assets/icons";
import { useTranslation } from "react-i18next";
//import "./VotingForm.scss";
import {
  useLazyGetFileImportProgressStatusQuery,
  useUploadManualDataSourceDocumentMutation,
} from "@/services/manualDataSource/ManualDataSourceReducer";
import { useParams } from "react-router-dom";
import SuccessMessage from "@/pages/CaseDetail/Distributions/ManualDataSource/SuccessMessage/SuccessMessage";
import DataSourceUploadStatus from "@/pages/CaseDetail/Distributions/ManualDataSource/UploadStatus/DataSoureUploadStatus";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { fileExtensions } from "@/services/documents/documentsReducer";

interface BulkUploadProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  title: string;
  description: string;
  uploadLabel: string;
  uploadDescription: string;
  accept: string;
  maxFiles: number;
  onDownload: () => void;
  // ballotId:string;
  onClose: () => void;
  // setBulkimportFileStatus: (fileStatus:string) => void;
}

const CreditorBulkImport = ({
  showModal,
  setShowModal,
  title,
  description,
  uploadLabel,
  uploadDescription,
  accept,
  maxFiles,
  onDownload,
  // ballotId,
  onClose,
}: // setBulkimportFileStatus
BulkUploadProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [discardDetails, setDiscardDetails] = useState(false);
  const { caseId } = useParams<{
    caseId: string;
  }>();
  const { t } = useTranslation();

  const [getFileImportProgressStatus, { data: importStatusData }] =
    useLazyGetFileImportProgressStatusQuery();
  const handleFileChange = (files: any) => {
    setFile(files[0]);
  };
  const handleClose = () => {
    onClose();
  };
  function handleDiscardReject() {
    setDiscardDetails(false);
  }
  function handleDiscardApprove() {
    setDiscardDetails(false);
    handleClose();
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [importStatus, setImportStatus] = useState<any>(null);
  const [showProgress, setShowProgress] = useState(false);

  const [uploadDocument] = useUploadManualDataSourceDocumentMutation();
  const [importJobId, setDocumentId] = useState<string | null>(null); // State to hold the documentId
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    // setBulkimportFileStatus(importStatusData?.statusName)
    if (
      showProgress &&
      importJobId &&
      importStatusData?.statusName !== "Success"
    ) {
      intervalId = setInterval(() => {
        getFileImportProgressStatus({ importJobId });
      }, 10000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showProgress,
    setDocumentId,
    importStatusData,
    getFileImportProgressStatus,
  ]);

  const handleSubmit = async () => {
    setIsUploading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("DisplayName", "test");
      formData.append("Entitytype", "4");
      //formData.append("EntityId", ballotId);
      if (caseId) {
        formData.append("CaseId", caseId);
      }
      try {
        const response = await uploadDocument(formData).unwrap();
        const importJobId = response;
        setDocumentId(importJobId);
        await getFileImportProgressStatus({ importJobId });
        setImportStatus(importStatusData);
        setShowProgress(true);
        setIsUploading(false);
      } catch (error) {
        setIsUploading(false);
      }
    }
  };

  return (
    <>
      <MotifModal
        onClose={() => {
          if (
            file &&
            (!importStatusData || 
              (importStatusData.statusName !== "Success" && 
               importStatusData.statusName !== "Failed"))
          ) {
            setDiscardDetails(true);
          } else {
            handleClose();
          }
        }}
        show={showModal}
        size="lg"
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Custom Close Button title",
          }}
        >
          <span>{title}</span>
        </MotifModalHeader>
        {importStatusData ? (
          importStatusData.statusName === "Success" ? (
            <SuccessMessage />
          ) : (
            <DataSourceUploadStatus importStatus={importStatusData} />
          )
        ) : (
          <>
            <MotifModalBody className="pad-t-10">
              <div className="motif-container case-summary-form pad-v-20 mar-t-20">
                <div className="motif-row justify-content-between align-items-center">
                  <div className="motif-col-4">
                    <div className="motif-body1-default-regular page-title">
                      {title}
                    </div>
                    <div className="motif-body2-default-regular page-description">
                      {description}
                    </div>
                  </div>
                  <div>
                    <MotifButton
                      onClick={onDownload}
                      size="small"
                      type="submit"
                      variant="primary"
                    >
                      <MotifIcon
                        fill="none"
                        iconFunction={editorIcVerticalAlignBottom24px}
                      />
                    </MotifButton>
                  </div>
                </div>
              </div>
              <div className="pad-v-20 mar-t-20">
                <div className="motif-body1-default-regular pad-v-20">
                  {uploadLabel}
                </div>
                <MotifFileUploader
                  id="bulk-upload"
                  label={uploadLabel}
                  labelDescription={uploadDescription}
                  onDrop={handleFileChange}
                  accept={fileExtensions.import}
                  maxFiles={maxFiles}
                >
                  {file && (
                    <MotifFileUploaderItem
                      fileIcon={<MotifIcon src={actionIcDescription24px} />}
                      fileName={file.name}
                      uploaded={true}
                      uploadedText={
                        t("pages.claims.documentPopup.displayName") + file.name
                      }
                      error={
                        (file && file.size >= fileExtensions.maxFileSize) ||
                        false
                      }
                      errorText={t(
                        "pages.claims.stageStatusPopup.filesizeExceedsLimit",
                        {
                          filesize: "250",
                        }
                      )}
                    />
                  )}
                </MotifFileUploader>
              </div>
            </MotifModalBody>

            <MotifModalFooter>
              <div className="document-footer display-flex ">
                <MotifButton
                  type="button"
                  variant="secondary"
                  className="cancelBtn mar-r-10"
                  onClick={() => {
                    if (file) {
                      setDiscardDetails(true);
                    } else {
                      handleClose();
                    }
                  }}
                >
                  {t("global.globalActions.cancel")}
                </MotifButton>
                <MotifButton
                  onClick={handleSubmit}
                  variant="primary"
                  className="cancelBtn"
                  disabled={isUploading}
                >
                  {isUploading ? t("global.globalActions.uploading") : t("global.globalActions.upload")}
                </MotifButton>
              </div>
            </MotifModalFooter>
          </>
        )}
      </MotifModal>
      <ConfirmationModal
        isVisible={discardDetails}
        setIsVisible={handleDiscardReject}
        cancelBtnApproveHandler={handleDiscardApprove}
        cancelBtnRejectHandler={handleDiscardReject}
      />
    </>
  );
};

export default CreditorBulkImport;
