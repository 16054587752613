export const CLAIM_ITEM_IDENTIFICATION = 'Claim Item Identification';
export const CATEGORY = 'Category';
export const CATEGORY_FIELD = 'category';
export const NATURE_OF_CLAIM = 'Nature of Claim';
export const NATURE_OF_CLAIM_FIELD = 'natureOfClaim';
export const ASSIGNEE = 'Assignee';
export const ASSIGNEE_FIELD = 'assignee';
export const GUARANTOR = 'Guarantor';
export const GUARANTOR_FIELD = 'guarantor';
export const CLAIM_ITEM_AMOUNT = 'Claim Item Amount';
export const ORIGINAL_AMOUNT = 'Original Amount';
export const CURRENT_AMOUNT = 'Current Amount';
export const STAGE_AMOUNT = 'Stage Amount';
export const ORIGINAL_AMOUNT_DEFAULT = 'Original Amount (Default)';
export const AMOUNT_PER_PROOF_OF_CLAIM = 'Amount per Proof of Claim';
export const AMOUNT_PER_PROOF_OF_CLAIM_DEFAULT = 'Amount per Proof of Claim (Default)';
export const LATEST_CREDITOR_POSITION = 'Latest Creditor Position';
export const LATEST_CREDITOR_POSITION_DEFAULT = 'Latest Creditor Position (Default)';
export const LATEST_DEBTOR_POSITION = 'Latest Debtor Position';
export const LATEST_DEBTOR_POSITION_DEFAULT = 'Latest Debtor Position (Default)';
export const AMOUNT_PER_PROOF_OF_CLAIM_LABEL = 'Current Amount';
export const AMOUNT_PER_PROOF_OF_CLAIM_FIELD = 'amountPerProofOfClaim';
export const RESOLVE_STATUS_LABEL = 'Resolve Status';
export const RESOLVE_STATUS_FIELD = 'resolveStatus';
export const Comments = 'Comments';
export const RESOLVED_AMOUNT_LABEL = 'Resolved Amount';
export const RESOLVED_AMOUNT_LABEL_DEFAULT = 'Resolved Amount (Default)';
export const UNIT = 'Unit';
