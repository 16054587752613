import { useGetStateByCountryIdQuery } from "@/services/countryState/countryStateReducer";
import ControlSelectState from "./Form/SelectBox/ControlSelectState";
import { STATE } from "./Constants";
import { Control } from "react-hook-form";
import { ContactDetailsType } from "./type";
import { useTranslation } from "react-i18next";

type StateDropdownProps = {
    countryID: string;
    index: number;
    onChangeHandlerState: (index: number, value: string, stateOptions:{label:string, value: string}[]) => void;
    control:Control<ContactDetailsType, any>;
    stateId?: string;
}

export default function StateDropdown({countryID, index, onChangeHandlerState, control}: StateDropdownProps) {
  const {t} = useTranslation();

 let defaultOption = {
    label: t("global.globalActions.optionDefault"),
    value: "",
  };
 const { data, isLoading, error } = useGetStateByCountryIdQuery(countryID, {
   skip: !countryID,
 });

 if (isLoading || error || data === undefined || data?.length === 0) {
    return <ControlSelectState
        //@ts-ignore
        name={`fieldArray.${index}.${STATE}`}
        //@ts-ignore
        control={control}
        required={false}
        showLabel={true}
        label={"pages.creditors.creditorForm.formLabelDetails.provinceState"}
        options={[defaultOption]}
        onChangeHandlerState={onChangeHandlerState}
        index={index}
        filter={true}
    />;
 }
 let stateListBycounrty: { label: string; value: string }[] = [];
 // eslint-disable-next-line array-callback-return
 data.map((stateObj: { id: string; name: string; code: string }) => {
   stateListBycounrty.push({ label: stateObj.name, value: stateObj.id });
 });

 return (
    <>
        <ControlSelectState
            //@ts-ignore
            name={`fieldArray.${index}.${STATE}`}
            //@ts-ignore
            control={control}
            required={false}
            showLabel={true}
            label={"pages.creditors.creditorForm.formLabelDetails.provinceState"}
            options={stateListBycounrty}
            onChangeHandlerState={onChangeHandlerState}
            index={index}
            filter={true}
        />
    </>
 );

}