import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import {
	MotifFormField,
	MotifLabel,
	MotifSelect,
	MotifOption,
} from "@ey-xd/motif-react";

type ControlSelectBoxAttributeProps = {
	index: number;
	groupIndex?: number;
	control: any;
	attributes: {
		propertyName: string;
		displayName?: string;
	}[];
	handleAttributeChange: (
		index: number,
		attribute: string,
		groupIndex?: number
	) => void;
};

const ControlSelectBoxAttribute = ({
	index,
	groupIndex,
	control,
	attributes,
	handleAttributeChange,
}: ControlSelectBoxAttributeProps) => {
	const { t } = useTranslation();

	useEffect(() => {
		const btn = document.querySelectorAll(
			".motif-select-input"
		) as NodeListOf<Element>;
		btn.forEach((element: Element) => {
			(element as HTMLElement).setAttribute("type", "button");
		});
	}, []);
	const path =
		groupIndex !== undefined
			? `treeRows.${index}.treeRows.${groupIndex}`
			: `treeRows.${index}`;
	return (
		<MotifFormField>
			<MotifLabel id="select-label">
				{t("pages.configuration.scheme.rule.attributes")}
			</MotifLabel>
			<Controller
				name={`${path}.attribute`}
				control={control}
				rules={{ required: true }}
				render={({ field, fieldState: { error } }) => (
					<MotifSelect
						ariaLabelledBy="select-label"
						onChange={(value) => {
							field.onChange(value);
							handleAttributeChange(index, value, groupIndex);
						}}
						placeholder="Select"
						value={field.value}
						filter={true}
						visibleOptions={5}					>
						{attributes.map((attr) => (
							<MotifOption key={attr.displayName} value={attr.displayName}>
								{attr.displayName}
							</MotifOption>
						))}
					</MotifSelect>
				)}
			/>
		</MotifFormField>
	);
};

export default ControlSelectBoxAttribute;
