import { ClaimDetailsType, ClaimItemSetupPostData, UnitType } from "./type";

export const getCategoryOptions = (
  category: { claimCategoryId: string; claimCategoryName: string }[]
) => {
  const categoryOptions = category.map(
    (item: { claimCategoryId: string; claimCategoryName: string }) => {
      return {
        label: item.claimCategoryName,
        value: item.claimCategoryId,
      };
    }
  );
  categoryOptions.unshift({ label: "Please Select", value: "" });
  return categoryOptions;
};

export const getNatureOfClaimsOptions = (natureOfClaim: string[]) => {
  const natureOfClaimsOptions = natureOfClaim.map((item) => {
    return {
      label: item,
      value: item,
    };
  });
  //natureOfClaimsOptions.unshift({label:"Please Select", value: ""});
  return natureOfClaimsOptions;
};

export const getCurrencyOptions = (currencyList: UnitType[], caseData: any) => {
  const uniqeItems: { value: string; label: string }[] = [];
  // eslint-disable-next-line array-callback-return
  currencyList.map((item: any) => {
    if (uniqeItems.indexOf(item.name) === -1) {
      if (item.id === caseData?.defaultUnit?.id)
        uniqeItems.push({
          label: item.name + " (Default)",
          value: item.id,
        });
      else
        uniqeItems.push({
          label: item.name,
          value: item.id,
        });
    }
  });
  return uniqeItems;
};

export function postClaimItemData(
  data: ClaimDetailsType
): ClaimItemSetupPostData {
  const postData: ClaimItemSetupPostData = {
    categoryId: data.category,
    natureOfClaim: data.natureOfClaim,
    currentAmount: Number(data.amountPerProofOfClaim),
    currentAmountUnitId: data.Unit,
    assigneeId: data.assignee,
    isResolved: data.resolveStatus === "Resolved" ? true : false,
    customAttributes: data.attributes,
    caseId: data.caseId,
  };
  return postData;
}
