import React from "react";
import { MotifFormField, MotifInput, MotifTextArea  } from '@ey-xd/motif-react';
import './InputBox.scss';
import { getNumbersAndDecimals, ltrimString } from "@/utility/common";
type InputBoxType = {
  id?: string;
  required?: boolean;
  value: string | number;
  isTextArea?: boolean;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: "number" | "text" | "tel" | "email" | "date" | "password" | undefined;
  readonly?: boolean;
  digitAfterDecimal?: number;
  isAmount?: boolean;
  onChangeAmount?: (value: string) => void;
};

export default function InputBoxNumber({
  id,
  type,
  required = false,
  value,
  isTextArea,
  onChange,
  placeholder,
  readonly,
  digitAfterDecimal,
  isAmount,
  onChangeAmount,
  ...args
}: InputBoxType) {
  value = ltrimString(String(value)) || "";
  const digitAfterDecimalCurrent = getNumbersAndDecimals(value);
  if (digitAfterDecimal && digitAfterDecimalCurrent > digitAfterDecimal) {
    value = value.toString();
    const decimalIndex = value.indexOf(".");
    value = value.toString().slice(0, decimalIndex + digitAfterDecimal + 1);
  }
  const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (isAmount) {
      onChangeAmount && onChangeAmount(value);
    }
    onChange(e);
  };
  return (
    <>
      <MotifFormField role="group" aria-label={id}>
        {isTextArea ? (
          <MotifTextArea
            {...args}
            id={id}
            value={value}
            maxLength="1000"
            onChange={onChange}
            placeholder={placeholder}
            disabled={readonly}
          />
        ) : (
          <MotifInput
            type={type}
            hideClearButton={true}
            {...args}
            //@ts-ignore
            id={id}
            value={value}
            maxLength="100"
            onChange={onChangeHandler}
            placeholder={placeholder}
            disabled={readonly}
          />
        )}
      </MotifFormField>
    </>
  );
}