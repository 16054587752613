const distributions = {
  payeeType: {
    title: "Payee Type Management",
    tableTitle: "Payee Type",
    tableDescription: "Descriptive text that describes your page or content.",
    createNewPayeeType: "Create Payee Type",
    createPayeeTitle: "Payee Type Details",
    createPayeeDescription:
      "Descriptive text that describes your page or content.",
    payeeLevelOptions: {
      label1: "Select Payee Level",
      label2: "Creditor Level",
      label3: "Case Level",
    },
    formLabels: {
      name: "Name",
      nameFrench: "Name (French)",
      payeeLevel: "Payee Level",
      description: "Description",
      paymentDetails: "Payment Details",
      contactDetails: "Contact Details",
      institutionNumber: "Institution Number",
      transitNumber: "Transit Number",
      accountNumber: "Account Number",
      IBANNumber: "IBAN Number",
      SWIFTCode: "SWIFT Code",
      routingCode: "Routing Code",
      ACHCode: "ACH Code",
      prefix: "Prefix",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      phoneNumber: "Phone Number",
      fax: "Fax",
      address1: "Address 1",
      address2: "Address 2",
      country: "Country",
      city: "City",
      postalCode: "Postal Code",
      provinceState: "Province State",
    },
    validationMessages: {
      deleteWarningMessage: "Are you sure you want to delete this payee type?",
      nameRequired: "Name is required",
      payeeRequired: "Payee Level is required",
      firstNameRequired: "First Name is required",
      lastNameRequired: "Last Name is required",
    },
    bannerDetails: {
      title: "Payee Type Name",
      label1: "Payee level",
      case: "Case",
      creditor: "Creditor",
      label2: "Last Updated",
    },

    columnDetails: {
      column1: "Payee Type Name",

      column2: "Payee Level",

      column3: "Description",
    },
  },
  manualDataSource: {
    bannerDetails: {
      title: "Manual Data Source Name",
      label1: "Total Records",
      label2: "Updated On",
    },
    editManualSource: {
      columnDetails: {
        column1: "Distribution",

        column2: "Step",
      },

      title: "Manual Data Source Details",
      description: "Descriptive text that describes your page or content.",
      usage: "Usage",
      usageDescription: "Descriptive text that describes your page or content.",
      name: "Name",
      download: "Download",
      nameFrench: "Name (French)",
      comment: "Comment",
      nameReq: "Name is required",
      sucessMessage: "Manual Data Source updated successfully.",
      errorMessage: "An error occurred while updating the manual data source.",
    },
    dataSourceUploader: {
      title: "Manual Data Source Upload",
      labels: {
        name: "Data Source Name",
        nameFrench: "Data Source Name (French)",
        comment: "Comment",
      },
      displayName: "Display Name: {{fileName}}",
      filesizeExceedsLimit:
        "This file exceeds the upload limit of {{filesize}} MB.",
      templateTitle: "Manual Data Source Upload Template",
      templateDescription: "Download template to create the import file",
      uploadText: "Click to upload or drag and drop",
      uploadDescription:
        "The accepted document format is XLSX only. The maximum size per file is 250 MB.",
    },
    tableTitle: "Manual Uploads",
    tableDescription: "Descriptive text that describes your page or content.",
    columnDetails: {
      column1: "Manual Data Source Name",
      column2: "Distribution Name",
      column3: "Total Records",
      column4: "Updated On",
    },
  },
  rateTire: {
    tableTitle: "Rate Tier Template",
    tableDescription: "Descriptive text that describes your page or content.",
    tableColumnDetails: {
      column1: "Name",
      column2: "Year",
      column3: "Jurisdiction",
      column4: "Created On",
      column5: "Created By",
      column6: "Active",
    },
  },
  account: {
    title: "Account Management",
    accountTitle: "Account",
    subAccountTitle: "Sub Account",
    createAccountTitle: "Create Account",
    editAccountTitle: "Edit Account",
    createSubAccountTitle: "Create Sub Account",
    editSubAccountTitle: "Edit Sub Account",
    accountAlreadyExits: "A Account with the same name already exists.",
    subAccountValidationMessages: {
      nameRequired: "Sub Account Name is required",
    },
    accountLabels: {
      validationMessages: {
        accountNameReq: "Account Name is required",
        sequenceNumberReq: "Starting Sequence Number is required",
        sequenceNumberPattern: "Only number are allowed",
        instituteNumberReq: "Institute Number is required",
      },
      prefixDropDownLabels: {
        label1: "Cheque",

        label2: "Statement",

        label3: "Wire",
      },
      accountName: "Account Name",
      accountNameFrench: "Account Name (French)",
      description: "Description",
      descriptionFrench: "Description (French)",
      prefix: "Prefix",
      unit: "Unit",
      startingSequenceNumber: "Starting Sequence Number",
      institutionNumber: "Institution Number",
      transitNumber: "Transit Number",
      accountNumber: "Account Number",
      clientNumber: "Client Number",
    },
    subAccountLabels: {
      subAccountName: "Sub Account Name",
      subAccountNameFrench: "Sub Account Name (French)",

      description: "Description",
      name: "Name",
      nameFrench: "Name (French)",
      descriptionFrench: "Description (French)",
      payemntOrderScheme: "Payment Order Scheme",
      includeTaxReport: "Include in Tax Report",
      includePaymentRegister: "Include in Payment Register",
    },
  },
  backButton: "Back to Home",
  distribution: {
    title: "Distribution",
    tableTitle: "Distribution",
    tableDescription: "Descriptive text that describes your page or content.",
    addNewDistribution: "Add New Distribution",
    tableColumnDetails: {
      column1: "Distribution Name",

      column2: "Status",

      column3: "Distribution Date",

      column4: "Creation On",
    },
    distributionEditPage: {
      title: "Distribution Summary",
      description: "Descriptive text that describes your page or content.",
    },
    distributionViewPage: {
      title: "Distribution Summary",
      description: "Descriptive text that describes your page or content.",
    },
    statusOptions: {
      unlocked: "Unlocked",
      locked: "Locked",
      committed: "Committed",
    },
    tabPannelDetails: {
      tab1: "Summary",
      tab2: "Steps",
    },
    generateDraftSettlementButton: "Generate Draft Settlement",
    generateDraftEntitlementButton: "Generate Draft Entitlement",
    distrbutionBanner: {
      title: "Distribution Name",
      label: "Last Updated Date",
    },

    distributionFormLabels: {
      distributionName: "Distribution Name",
      createdFromPreviousDistribution: "Created from Previous Distribution",
      distributionNameFrench: "Distribution Name (French)",
      status: "Status",
      distributionDate: "Distribution Date",
      description: "Description",
      descriptionFrench: "Description (French)",
      effectiveDate: "Effective Date",
      createdOn: "Created On",
      entitlementCalculation: "Last Entitlement Calculation",
      lastSettlementCalculation: "Last Settlement Calculation",
    },
    validationMessages: {
      distributionNameReq: "Please enter a distribution name",
    },
    distributionSetup: "Distribution Setup",
    deleteDistribution: "Delete Distribution",
    warningMessage: "Are you sure you want to delete this distribution?",
  },
  distributionStepsTab: {
    stepBannerDetails: {
      title: "Calculation Step Name",
      label1: "Distribution Name",
      label2: "Last Updated Date",
    },
    previewEntitlementResult: "Preview Entitlement Result",
    addNewStep: "Add New Step",
    tableTitle: `Steps`,
    tableDescription: `Descriptive text that describes your page or content.`,
    validationMessages: {
      stepNameRequired: "The Step name cannot be empty or contain only spaces.",
      payeeTypeRequired: "Payee Type is required",
      mainMessage:
        "Please complete all required fields to continue. , {{fieldNames}} cannot be left blank.",
    },
    stepFormView: {
      title: "Step summary",
      description: "Descriptive text that describes your page or content.",
    },
    claculationGroupByOptions: {
      option1: "Select Calculation Group By",
      option2: "Creditors",
      option3: "Claims",
      option4: "Assignee/Creditor",
      option5: "Case",
    },
    payableOptions: {
      option1: "Payable",
      option2: "Memo",
    },
    columnDetails: {
      column1: "Order",

      column2: "Step Name",

      column3: "Sub Account Name",

      column4: "Payable/Memo",

      column5: "Payment Grouping",

      column6: "Payee Type",

      column7: "Updated On",

      column8: "Updated By",
    },
    distributionBanner: {
      title: "Calculation Step Name",
      label1: "Distribution Name",
      label2: "Last Updated Date",
    },
    stepFormLabels: {
      stepName: "Step Name",
      account: "Account",
      subAccount: "Sub Account",
      subAccountNew: "Sub Account New",

      calculationGroupBy: "Calculation Group By",
      payeeType: "Payee Type",
      memo: "Payable/Memo",
      comment: "Comment",
      description: "Description",
    },
    resultSettingTab: {
      title: "Result Setting",
      description: " Descriptive text that describes your page or content.",
      formLabels: {
        multipleIssue: "Issue in multiples of",
        rounding: "Rounding",
        minimumIssueAmount: "Minimum Issue amount",
      },
      validationMessage: {
        multipleIssueRequired: "Issue in multiples of is required",
        roundRequired: "Rounding is required",
        minimumIssueRequired: "Minimum Issue amount is required",
      },
      roundingOptions: {
        label: "Select Rounding",
        label1: "Round up",
        label2: "Round down",
        label3: "Round to nearest",
      },
    },
    cap_Floor_AmountTab: {
      title: "Cap & Floor Amount",
      description: "Descriptive text that describes your page or content.",
      formLabels: {
        capAmount: "Cap Amount",
        floorAmount: "Floor Amount",
      },
      validationMessage: {
        capAmountRequired:
          "Please ensure Cap Amount is greater than Floor Amount",
      },
    },
    limitAmountTab: {
      tableTitle: "Limit Amount",
      tableDescription: "Descriptive text that describes your page or content.",
      limitCapCheckBox: "Use Zero Limit as Cap",
      limitFloorCheckBox: "Use Zero Limit as Floor",
      applyCapFirst: "Apply Cap First",
      applyFloorFirst: "Apply Floor First",
      deleteWarningMessage:
        "Are you sure you want to delete this limit amount?",
      columnDetails: {
        column1: "Type",
        column2: "Data Source",

        column3: "Weighting factor",

        column4: "Filter applicable classes",

        column5: "Manual data source",

        column6: "Calculation Step Name",
      },
      limitFormCreate: {
        title: "Limit Amount",
      },
      limitFormEdit: {
        title: "Edit Limit Amount",
      },
      typeOptions: {
        label: "Select Type",
        label1: "Cap",
        label2: "Floor",
      },
      dataSourceOptions: {
        label1: "Select Type",
        label2: "Accepted Claims",
        label3: "Prior Step",
        label4: "Manual Data Sources",
      },
      entitlementOptions: {
        label1: "Select Type",
        label2: "Entitlement",
        label3: "Settlement",
      },
      validationMessages: {
        typeRequired: "Type is required",
        dataSourceRequired: "Data Source is required",
        entitlementRequired: "Entitlement/Settlement is required",
        manualDataSourceRequired: "Manual Data Source is required",
        distributionRequired: "Distribution is required",
        caluclationStepRequired: "Calculation Step is required",
        weighingRequired: "Weighting Factor is required",
        weighingNumericRequired: "Weighting Factor is not numeric",
        applicableRequired: "Filter Applicable Classes is required",
      },
      formLabels: {
        type: "Type",
        datasource: "Data Source",
        entitlement: "Entitlement/Settlement",
        manualDataSource: "Manual Data Source",
        distribution: "Distribution",
        calculationStep: "Calculation Step",
        weightingFactor: "Weighting factor",
        applicable: "Filter Applicable Classes",
      },
    },
    rateTierTab: {
      tableTitle: "Rate Tier {{titleExt}}",
      tableDescription: "Descriptive text that describes your page or content.",
      createTemplate: "Create from Template",
      saveTemplete: "Save as Template",
      successMessage: "Rate tier updated successfully.",
      deleteWarningMessage: "Are you sure you want to delete this rate tier?",
      columnDetails: {
        column1: "Tier Max Amount",

        column2: "Tier Floor Amount",

        column3: "Rate",

        column4: "Adjustment",
      },
      maxAmountWarningMessage:
        "Please ensure Tier Max Amount is greater than Tier Floor Amount",
      validationMessages: {
        rateRequired: "Rate is required",
        adjustmentRequired: "Adjustment is required",
        mainMessage: "Please fill all the required fields",
      },
      createNewTemplate: {
        validationMessages: {
          yearRequired: "Year must be between 1900 and 2100",
        },
        title: "Create New Template",
        name: "Name",
        year: "Year",
        jurisdiction: "Jurisdiction",
      },
      formLabels: {
        createTitle: "Rate Tier",
        editTitle: "Edit Rate Tier",
        tierMaxAmount: "Tier Max Amount",
        tierMinAmount: "Tier Min Amount",
        tierFloorAmount: "Tier Floor Amount",
        rate: "Rate",
        adjustment: "Adjustment",
      },
    },
    classFilterTab: {
      tableTitle: "Class Filter",
      classFilterCreateTitle: "Class Filter",
      classFilterEditTitle: "Class Filter",

      tableDescription: "Descriptive text that describes your page or content.",
      deleteWarningMessage: "Are you sure to delete this class filter?",
      classFilter: "Class Filter",
      weightFactor: "Weighting Factor",
      validationMessage:
        "Please complete all required fields to continue. {{fieldNames}} cannot be left blank Or invalid",
      class: "Class",
      scheme: "Scheme",
      columnDetails: {
        column1: "Class Name",
        column2: "Weighting Factor",
      },
    },
    dataSourceTab: {
      tableTitle: "Data Sources",
      tableDescription: "Descriptive text that describes your page or content.",
      deleteWarningMessage: "Are you sure to delete this data source?",
      columnDetails: {
        column1: "Data Source",
        column2: "Manual DataSource",
        column3: "Distribution",
        column4: "Step",
        column5: "Weighting Factor",
        column6: "Entitlement/Settlement",
        column7: "Filter Applicable Classes",
      },
      dataSourceCreateTitle: "Data Source",
      dataSourceEditTitle: "Data Source",

      validationMessages: {
        mainMessage:
          "Please complete all required fields to continue. {{fieldNames}} cannot be left blank Or invalid",
        entitlementReq: "Entitlement/Settlement is required",
      },
      dataSourceOptions: {
        label1: "Select Type",
        label2: "Accepted Claims",
        label3: "Prior Step",
        label4: "Manual Data Sources",
      },
      entitlementOptions: {
        label1: "Select Type",
        label2: "Entitlement",
        label3: "Settlement",
      },
      formLabels: {
        dataSource: "Data source",
        weightingFactor: "Weighting Factor",
        distribution: "Distribution",
        calculationStep: "Calculation Step",
        entitlement: "Entitlement/Settlement",
        manualDataSource: "Manual DataSource",
        applicableClass: "Filter applicable classes",
      },
    },
    distributionCreate: {
      title: "Step summary",
      description: "Descriptive text that describes your page or content.",
    },
  },
  manageDataSource: {
    title: "Manual Data Sources",
    deatails: "Manual Data Source Details",
    description: "Descriptive text that describes your page or content.",
    usage: "Usage",
    usageDescription: "Descriptive text that describes your page or content.",
    cancel: "Cancel",
    download: "Download",
  },
  accounts: {
    title: "Accounts",
  },
  PayeeType: {
    title: "Payee Type",
  },
  rateTierTemplate: {
    title: "Rate Tier Template",
  },
};

export default distributions;
