import TabPanel from "@/components/TabPanel/TabPanel";
import ClaimTabContent from "@/features/creditor/claimsTab/claimsTabContent";
import CreditorSummary from "./CreditiorSummary";
import CreditorSummaryHeader from "./CreditorSummaryHeader";
import CreditorAttributeHistoryTab from "../CreditorHistoryClaims/creditorAttributeHistory";
import { useParams } from "react-router-dom";
import { useCase } from "@/features/case/use-case";

export default function CreditorSummaryView() {
  const parameter = useParams();
  const tabPanelData = {
    title: ["pages.creditors.creditorView.tabPannelDetails.summary", "pages.creditors.creditorView.tabPannelDetails.claims", "pages.creditors.creditorView.tabPannelDetails.attributeHistory"],
    content: [
      <CreditorSummary />,
      <ClaimTabContent />,
      <CreditorAttributeHistoryTab />,
    ],
  };
  const caseData = useCase();
  const propData = {
    caseId: caseData.caseId,
    creditorId: parameter.creditorId || "",
  };
  return (
    <>
      <CreditorSummaryHeader
        caseId={propData.caseId}
        creditorId={propData.creditorId || ""}
      />
      <div className="ccwp_table_wrapper ccwp_table_dropdown_wrapper">
        <TabPanel data={tabPanelData} />
      </div>
    </>
  );
}
