import {
  MotifCard,
  MotifCardBody,
  MotifCardHeader,
  MotifIconButton,
  MotifIcon,
  MotifLabel,
  MotifFormField,
  MotifInput,
  MotifCheckbox,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import { IconoirTrash, IconoirEditPencil } from "@ey-xd/motif-icon";
import { useMemo, useState } from "react";
import { EditSubAccount } from "./EditSubAccount";
import { useGetSubAccountDataByIdApiDataQuery } from "@/services/configuration/configuration";
import DeleteAccountSubAccount from "./DeleteAccountSubAccount";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { subAcountRecordType } from "./type";
import { useGetEffectiveSchemesQuery } from "../../Voting/votingReducer";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SubAccountTile({ subAccountList, setStatusMsg, refetchSubAccounts,setIsDiscardVisible,editSubAccount,setEditSubAccount }: any) {
  const [editId, setEditId] = useState("");
  const {t}=useTranslation()
  const [showDeleteSubAccount, setShowDeleteSubAccount] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const { data: getSubAccountById, refetch: editRefetch } =
    useGetSubAccountDataByIdApiDataQuery(editId.toString(), { skip: !editId });
  const [subAccountFields, setSubAccountFields] = useState<any>({
    name: "",
    desc: "",
    nameFr: "",
    descFr: "",
    schemeVersionId: "",
    includeIntaxReport: false,
    includeInPaymentRegister: false,
  });
  //Set up the form value
  const handleChange = (name: any, value: any) => {
    setSubAccountFields({ ...subAccountFields, [name]: value });
  };
  const { caseId } = useParams<{ caseId: string }>();
  const { data: dataSchemes, isLoading: isLoadingSchemes } =
    useGetEffectiveSchemesQuery({ caseId }, { skip: !caseId });
  const SCHEME_OPTIONS: any[] = useMemo(() => {
    if (dataSchemes && !isLoadingSchemes) {
      return dataSchemes.map((item: subAcountRecordType) => {
        return {
          label: item?.name || "",
          value: item?.schemeVersionId || "",
        };
      });
    }

    return [];
  }, [isLoadingSchemes, dataSchemes]);
  const defaultOption = { label: "Select Type", value: "" };
  const SchemeOptions = [defaultOption, ...SCHEME_OPTIONS];

  return (
    <>
      <div className="status-body">
        {deleteErrorMessage && (
          <NotificationGrid
            show={true}
            message={deleteErrorMessage}
            type="error"
          />
        )}
        {subAccountList &&
          subAccountList.map((items: any) => {
            return (
              <div className="status-tile-container">
                <MotifCard
                  className="status-tile"
                  orientation="horizontal"
                  variant="card-border"
                >
                  <MotifCardHeader>
                    <span className="motif-body1-default-bold">
                      {items?.subAccountName}
                    </span>
                    <div className="status-tile-actions">
                      <div
                        className="action-icons"
                        style={{ borderRight: "0px", paddingRight: "0px" }}
                      >
                        <MotifIconButton
                          type="button"
                          onClick={() => {
                            setEditSubAccount(true);
                            setEditId(items?.subAccountId);
                          }}
                        >
                          <MotifIcon iconFunction={IconoirEditPencil} />
                        </MotifIconButton>
                        <MotifIconButton
                          type="button"
                          onClick={() => {
                            setShowDeleteSubAccount(true);
                            setDeleteId(items?.subAccountId);
                          }}
                        >
                          <MotifIcon iconFunction={IconoirTrash} />
                        </MotifIconButton>
                      </div>
                    </div>
                  </MotifCardHeader>
                  <MotifCardBody>
                    <div className="status-tile-body">
                      <div className="motif-row mar-b-10">
                        <div className="motif-col-sm-2">
                          <MotifLabel
                            className="mar-b-10"
                            style={{ display: "block" }}
                          >
                            {t("pages.distributions.account.subAccountLabels.name")}
                          </MotifLabel>
                          <MotifFormField>
                            <MotifInput
                              /**@ts-ignore **/
                              placeholder="Name"
                              disabled
                              onBlur={function Ua() {}}
                              onFocus={function Ua() {}}
                              hideClearButton={true}
                              value={
                                subAccountFields?.name || items?.subAccountName
                              }
                              onChange={(e: any) =>
                                handleChange("name", e.target.value)
                              }
                            />
                          </MotifFormField>
                        </div>
                        <div className="motif-col-sm-2 mar-b-10">
                          <MotifLabel
                            className="mar-b-10"
                            style={{ display: "block" }}
                          >
                          {t("pages.distributions.account.subAccountLabels.nameFrench")}

                          </MotifLabel>
                          <MotifFormField>
                            <MotifInput
                              /**@ts-ignore **/
                              placeholder="Name (French)"
                              disabled
                              onBlur={function Ua() {}}
                              onFocus={function Ua() {}}
                              hideClearButton={true}
                              value={
                                subAccountFields?.nameFr ||
                                items?.nameFrench ||
                                ""
                              }
                              onChange={(e: any) =>
                                handleChange("nameFr", e.target.value)
                              }
                            />
                          </MotifFormField>
                        </div>
                      </div>
                      <MotifLabel className="mar-b-10">
                      {t("pages.distributions.account.subAccountLabels.description")}

                      </MotifLabel>
                      <MotifFormField className="mar-b-10">
                        <MotifInput
                          /**@ts-ignore **/
                          placeholder="Description"
                          disabled
                          onBlur={function Ua() {}}
                          onFocus={function Ua() {}}
                          hideClearButton={true}
                          value={
                            subAccountFields?.desc ||
                            items?.subAccountDescription ||
                            ""
                          }
                          onChange={(e: any) =>
                            handleChange("desc", e.target.value)
                          }
                        />
                      </MotifFormField>
                      <MotifLabel className="mar-b-10">
                      {t("pages.distributions.account.subAccountLabels.descriptionFrench")}
                      </MotifLabel>
                      <MotifFormField className="mar-b-10">
                        <MotifInput
                          /**@ts-ignore **/
                          placeholder="Description (French)"
                          disabled
                          value={
                            subAccountFields?.descFr ||
                            items?.descriptionFrench ||
                            ""
                          }
                          onChange={(e: any) =>
                            handleChange("descFr", e.target.value)
                          }
                        />
                      </MotifFormField>
                      <div className="form-field">
                        <MotifLabel className="mar-b-10">
                        {t("pages.distributions.account.subAccountLabels.payemntOrderScheme")}
                        </MotifLabel>
                        <MotifSelect
                          placeholder=""
                          value={
                            subAccountFields?.schemeVersionId ||
                            items?.schemeVersionId
                          }
                          disabled
                        >
                          {SchemeOptions.map((option) => (
                            <MotifOption
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MotifOption>
                          ))}
                        </MotifSelect>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <MotifCheckbox
                          style={{ marginTop: "10px" }}
                          checked={
                            subAccountFields?.includeIntaxReport ||
                            items?.isInTaxReport
                          }
                          onChange={(e: any) =>
                            handleChange("includeIntaxReport", e.target.value)
                          }
                        >
                        {t("pages.distributions.account.subAccountLabels.includeTaxReport")}
                        </MotifCheckbox>
                      </div>
                      <MotifCheckbox
                        checked={
                          subAccountFields?.includeInPaymentRegister ||
                          items?.isInPaymentRegister
                        }
                        onChange={(e: any) =>
                          handleChange(
                            "includeInPaymentRegister",
                            e.target.value
                          )
                        }
                      >
                        {t("pages.distributions.account.subAccountLabels.includePaymentRegister")}
                        </MotifCheckbox>
                    </div>
                  </MotifCardBody>
                </MotifCard>
              </div>
            );
          })}
      </div>
      <DeleteAccountSubAccount
        show={showDeleteSubAccount}
        setShow={setShowDeleteSubAccount}
        id={deleteId}
        isAccount={false}
        setDeleteErrorMessage={setDeleteErrorMessage}
        refetch={refetchSubAccounts}
      />
      <EditSubAccount
        setEditSubAccount={setEditSubAccount}
        editSubAccount={editSubAccount}
        getSubAccountById={getSubAccountById}
        editRefetch={editRefetch}
        refetchSubAccounts={refetchSubAccounts}
        editId={editId}
        setStatusMsg={setStatusMsg}
        setIsDiscardVisible={setIsDiscardVisible}
      />
    </>
  );
}
