import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListAttributesByEntityQuery } from "@/services/customAtrributes/customAttributesReducer";
import { setCreditorAttributes } from "./CreditorAttributesSlice";
import { useTranslation } from "react-i18next";
import CustomAttribute from "@/components/CustomAttribute/CustomAtrribute";

import {  SECONDARY } from "@/Constant";
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifErrorMessage,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import Button from "@/components/Form/Button/Button";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { AttributeType } from "../../Configuration/Attributes/type";
import { useNavigate } from 'react-router-dom';
import { useCase } from "@/features/case/use-case";
import { resetCreditorSetup, updateFormSubmitIndex, updateStage } from "./creditorSetupSlice";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

export default function CreditorAttributes() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const caseData = useCase();
  const [isFormOpen, setIsFormOpen] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [isError, setIsError] = useState(false);

  const { data: attributesData } = useListAttributesByEntityQuery({
    CaseId: caseData.caseId,
    EntityType: 1,
    IsCreate: true,
  });

  const attributesValue = useSelector(
    (state: any) => state.creditorAttributes.attributes
  );

  const formSubmitIndex = useSelector(
    (state: any) => state.formSubmitIndex
  );
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: true }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch]);
  useEffect(() => {
    if (attributesData && attributesValue.length === 0) {
      dispatch(setCreditorAttributes(attributesData));
    }
    // eslint-disable-next-line
  }, [attributesData]);

  const setAttributesValues = (value: any) => {
    dispatch(setCreditorAttributes(value));
  };

  const isDisabled = () => {
    var disabled = false;
    const required = attributesValue.filter((x: any) => {
      return x.isRequired;
    });

    required.forEach((x: any) => {
      switch (x.value.attributeType) {
        case AttributeType.FreeText:
          disabled = !x.value.textValue;
          break;
        case AttributeType.Number:
          disabled = !x.value.numberValue;
          break;
        case AttributeType.Boolean:
          disabled = !x.value.booleanValue;
          break;
        case AttributeType.Date:
          disabled = !x.value.dateValue;
          break;
        case AttributeType.SingleSelect:
          disabled = !x.value.selectedValue;
          break;
        case AttributeType.MutliSelect:
          disabled =
            !x.value.selectedValues || x.value.selectedValues.length === 0;
          break;
        case AttributeType.Currency:
          disabled = !x.value.amount;
          break;
        case AttributeType.Document:
          disabled = !x.value.displayName;
          break;
        default:
          disabled = false;
      }
    });

    return disabled;
  };

  const handleNext = () => {
    if (isDisabled()) {
      setIsError(true);
    } else {
      dispatch(updateStage(3));
      formSubmitIndex !== 3 && dispatch(updateFormSubmitIndex(2)); 
    }
  };

  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    dispatch(resetCreditorSetup());
    dispatch(updateStage(0));
    dispatch(setCreditorAttributes(attributesData));
    setIsVisible(false);
    navigate(`/case/${caseData?.caseId || ""}/?activeTab=2`);
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));

  };

  return (
    <div className="motif-container pad-l-40 pad-r-20">
      <div className="motif-row t-b-padding-21">
        <div className="motif-col-xs-4">
          <p className="form-title motif-h5-default-regular">
            {t("pages.creditors.creditorForm.creditorAttributesTitle")}
          </p>
          <p className="form-description">{t("pages.creditors.creditorForm.creditorAttributesDescription")}</p>
        </div>
      </div>
      <MotifAccordion
        onClose={() => setIsFormOpen(false)}
        onOpen={() => setIsFormOpen(true)}
        style={{
          flex: "1",
        }}
        open={isFormOpen}
        useChevronIcon={true}
        alignIconRight={true}
      >
        <MotifAccordionTrigger>
          {t("pages.creditors.creditorForm.formLabelDetails.creditorAttributes")}
        </MotifAccordionTrigger>
        <MotifAccordionContent>
          {!attributesData ? (
            <MotifProgressLoader show variant="default" />
          ) : (
            <div className="pad-v-25 motif-row">
              {attributesData &&
              attributesValue.length === attributesData.length ? (
                attributesData.map((attribute: any, index: number) => {
                  return (
                    <div className="motif-col-md-4">
                      <CustomAttribute
                        customAttributeDetails={{
                          ...attribute,
                          metadataJSON: JSON.parse(attribute.metadataJSON),
                        }}
                        customAttributeType={attribute.value.attributeType}
                        attributeValue={attributesValue}
                        setAttributeValue={setAttributesValues}
                        index={index}
                        key={index}
                      />
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          )}
        </MotifAccordionContent>
      </MotifAccordion>

      <div>
        {isError ? (
          <MotifErrorMessage>
            {t("pages.configuration.attributes.requiredFields")}
          </MotifErrorMessage>
        ) : (
          <></>
        )}
      </div>

      <div className="button-container">
        <Button label={t("global.globalActions.next")} onClickHandler={handleNext} />
        <Button
          type="button"
          label={t("global.globalActions.cancel")}
          variant={SECONDARY}
          className="cancelBtn"
          onClickHandler={(e) => {
            setIsVisible(true);
            dispatch(setDiscardEditMode({ editMode: false }));

          }}
        />
      </div>

      <ConfirmationModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
    </div>
  );
}
