export const HEADER ={
    applicationNameInHeader:"Creditor Claims Portal"
}

export const HOME ={
    userName: "Andrew",
    userInformation: "Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed aliquam ligula placerat massa. At interdum lectus auctor nibh dui massa tristique. Eget nisi potenti aenean ultrices nunc sit quam leo. Non risus donec et enim semper."
}

export const TABPANEL ={
    maxTabs : 7,
} 

export const ENTITYTYPE = {
    creditor: 1,
    claim: 2,
    claimItem: 3,
}