import { j as e } from "../../../jsx-runtime-BwWtxLpl.js";
import l from "classnames";
import t from "prop-types";
import a, { useEffect as _ } from "react";
import I from "react-animate-height";
import { createPortal as q } from "react-dom";
import { usePopper as A } from "react-popper";
import { useOutsideClickRef as F } from "rooks";
import { DEFAULT_POPPER_OPTIONS as M } from "./vertical-navigation-menu-item.js";
import { useVerticalNavigationContext as k } from "../vertical-navigation-context.js";
import { MotifIcon as D } from "../../icon/icon.js";
import "../../icon/icon.context.js";
import { IconoirNavArrowDown as L } from "@ey-xd/motif-icon";
const v = "motif-vertical-navigation-submenu", i = `${v}-`, d = `${i}portal`, V = ({
  children: c,
  className: m = "",
  icons: u = {},
  onClick: g = () => null,
  onMouseEnter: b = () => null,
  onMouseLeave: x = () => null,
  onOutsideClick: h = () => null,
  open: r = !1,
  selected: N = !1,
  title: s
}) => {
  const { collapse: f, rootClassName: j } = k(), n = a.useRef("vertical_navigation_motif_button_" + Date.now()).current, p = a.useRef(`${i}${n}`).current, [$, y] = a.useState(null), [E, P] = a.useState(null), [R] = F(h), { styles: C, attributes: O } = A($, E, M), T = l({
    [v]: !0,
    [`${i}open`]: r,
    [`${i}selected`]: N,
    [m]: !0
  }), w = l({
    [`${i}arrow`]: !0,
    [`${i}arrow-open`]: r
  }), S = l({
    [d]: !0,
    [m]: !0,
    [j]: !0
  });
  return _(() => {
    const o = document.querySelector(".rah-static");
    o && o.setAttribute("role", "menuitem");
  }, []), /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
    /* @__PURE__ */ e.jsxs(
      "button",
      {
        "aria-controls": p,
        role: "menuitem",
        "aria-expanded": r,
        "aria-label": s,
        className: T,
        id: n,
        onClick: g,
        onMouseEnter: b,
        onMouseLeave: x,
        children: [
          /* @__PURE__ */ e.jsx("div", { className: "motif-vertical-navigation-submenu-iconcontainer", ref: y, children: u.title }),
          f ? r && q(
            /* @__PURE__ */ e.jsxs(
              "div",
              {
                ref: (o) => {
                  o && P(o);
                },
                style: C.popper,
                ...O.popper,
                className: S,
                "data-testid": d,
                children: [
                  /* @__PURE__ */ e.jsx("div", { className: "motif-vertical-navigation-submenu-label", children: /* @__PURE__ */ e.jsx("div", { className: "motif-vertical-navigation-submenu-float-title", children: s }) }),
                  /* @__PURE__ */ e.jsx("div", { className: "motif-vertical-navigation-submenu-children motif-vertical-navigation-scrolls", children: c })
                ]
              }
            ),
            document.querySelector(`#motif-submenu-popper-${n}`)
          ) : /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
            /* @__PURE__ */ e.jsx(
              "div",
              {
                ref: (o) => {
                  o && R(o);
                },
                className: "motif-vertical-navigation-submenu-label",
                children: s
              }
            ),
            u.expandToggle || /* @__PURE__ */ e.jsx("div", { className: w, children: /* @__PURE__ */ e.jsx(D, { iconFunction: L }) })
          ] })
        ]
      }
    ),
    !f && /* @__PURE__ */ e.jsx(I, { duration: 150, height: r ? "auto" : 0, children: /* @__PURE__ */ e.jsx(
      "nav",
      {
        id: p,
        role: "menubar",
        "aria-labelledby": n,
        className: "motif-vertical-navigation-animateheight motif-vertical-navigation-scrolls",
        children: c
      }
    ) }),
    /* @__PURE__ */ e.jsx("div", { id: `motif-submenu-popper-${n}`, children: " " })
  ] });
};
V.propTypes = {
  children: t.oneOfType([t.node, t.element, t.arrayOf(t.element)]).isRequired,
  className: t.string,
  icons: t.shape({
    expandToggle: t.node,
    title: t.node
  }),
  onClick: t.func,
  onMouseEnter: t.func,
  onMouseLeave: t.func,
  onOutsideClick: t.func,
  open: t.bool,
  selected: t.bool,
  title: t.string.isRequired
};
export {
  V as MotifVerticalNavigationSubmenu
};
