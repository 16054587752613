// a redux slice to manage case
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CaseState {
  caseId: string | null;
  caseName?: string;
  caseNameFrench?: string;
  caseDescription?: string;
  caseDescriptionFrench?: string;
  caseStatus?: boolean;
  caseShortName?: string;
  timeZone?: number;
  abbreviation?: string;
  caseData?: any;
  caseUnits?: any;
  defaultUnit?: any;
}

const initialState: CaseState = {
  caseId: null,
  caseName: "",
  caseNameFrench: "",
  caseDescription: "",
  caseDescriptionFrench: "",
  caseStatus: false,
  caseShortName: "",
  timeZone: 0,
  abbreviation: "",
  caseUnits: undefined,
  defaultUnit: undefined,
};

export const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    setCase: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    updateCase: (
      state,
      action: PayloadAction<{ key: keyof CaseState; value: any }[]>
    ) => {
      action.payload.forEach((item) => {
        state[item.key] = item.value;
      });
      return state;
    },
    resetCase: (state) => {
      return initialState;
    },
  },
});

export const { setCase, updateCase, resetCase } = caseSlice.actions;

export default caseSlice.reducer;
