import React, { Dispatch, SetStateAction } from "react";
import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import {useTranslation} from 'react-i18next';
import InputBox from "@/components/Form/InputBox/InputBox";
import { CurrencyConfigurationType, NubmerErrorType } from "./type";
import { DEFAULT_CURRENCY_ID } from "./Constants";
import { isNumeric } from "@/utility/validation";
import { checkIntegerError } from "@/utility/common";

type ControlInputBoxType = {
    required?: boolean;
    isTextArea?: boolean;
    control: Control<CurrencyConfigurationType>;
    name: string;
    showLabel: boolean;
    label: string;
    isNumber?: boolean;
    setNumberError?: Dispatch<SetStateAction<NubmerErrorType[]>>;
    readonly?: boolean;
    integerOnly?: boolean;
    maxLength?: number;
    
}


export default function ControlInputBoxNumber ({name, control, required=false, isTextArea=false, showLabel, label, readonly, integerOnly,maxLength}: ControlInputBoxType) {
    const validate = (value: string) => {
        value = value.toString();
        if(integerOnly) {
          return !checkIntegerError(value);
        }
        return isNumeric(value);
    };  
  const {
        field,
      } = useController({
        // @ts-ignore
        name,
        control,
        // @ts-ignore
        rules: { required: required, validate  },
      });
    const {t} = useTranslation();

    return (
        <> 
            {
                showLabel && <div className="formFieldLabel">
                    <label  htmlFor="input" aria-label={label}>{t(label)}{ required ? <span className="requiredSymbol">*</span> : ''}</label>
                </div>
            }
           <Controller
                name={field.name}
                control={control}
                rules={{ required }}
                render={({ field: { onChange, value, ...args } }) => {
                          return (
                          <>
                          {/*@ts-ignore */}
                            <InputBox   id={DEFAULT_CURRENCY_ID} required={true} value={value || ""} onChange={onChange} maxLength={maxLength} isTextArea={isTextArea} readonly={readonly} {...args}/>
                          </>
                          );
                    }}
            />
        </>
        
    );
}
