import { MotifModal, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react'
import React from 'react'
import '@/components/Modal/ConfirmationModal/confirmationModal.scss';
import Button from '@/components/Form/Button/Button';
import { PRIMARY, SECONDARY} from '@/Constant';
import {useTranslation} from 'react-i18next';

type Props = {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  cancelBtnHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  confirmBtnHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  confirmationMessage: string;
}

export default function ConfirmationModalUnit({isVisible, setIsVisible, cancelBtnHandler, confirmBtnHandler, confirmationMessage}: Props) {
  const {t} = useTranslation();

  return (
  <>
    <MotifModal 
      onClose={() => setIsVisible(false)}
      show={isVisible}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        }
      }}
    >
      <div className='confirmationModalWrapper'>
        <MotifModalBody>
          <div className='discard_message'>{t(confirmationMessage)}</div>
        </MotifModalBody>
        <MotifModalFooter>
          <Button label={t("global.globalActions.no")} variant={SECONDARY} className="cancelBtnModal" onClickHandler={cancelBtnHandler} />
          <Button label={t("global.globalActions.yes")} variant={PRIMARY} className="delBtn" onClickHandler={confirmBtnHandler}/>
        </MotifModalFooter>
      </div>
    </MotifModal>
  </>
)}

