import { AttributeType } from "@/pages/CaseDetail/Configuration/Attributes/type";

export enum CustomAttributeFormats {
  None = "None",
  SIN = "SIN",
  Email = "Email",
  Phone = "Phone",
  Regex = "Regex",
  Postal = "Postal",
  Integer = "Integer",
  Decimal = "Decimal",
}
export interface CustomAttributeProps {
  customAttributeDetails: any;
  customAttributeType: AttributeType;
  attributeValue: any;
  setAttributeValue: (value: any) => void;
  index: number;
  setIntialAttributesValue?: (value: any) => void;
}
