import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
	MotifErrorMessage,
	MotifModalBody,
	MotifModalFooter,
	MotifModal,
	MotifModalHeader,
} from "@ey-xd/motif-react";
import Button from "@/components/Form/Button/Button";
import {
	SCHEME_NAME,
	SCHEME_LABEL,
	SCHEME_DESC_LABEL,
	SCHEME_DESC_NAME,
	SCHEME_NAME_FR,
	SCHEME_NAME_LABEL_FR,
	SCHEME_DESC_FR_LABEL,
	SCHEME_DESC_FR_NAME,
} from "./Constants";
import { CreateSchemeFormType } from "./type";
import ControlInputBox from "./ControlInputBoxScheme";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import { SAVE } from "@/Constant";
import { useUpdateSchemeMutation } from "@/services/scheme/schemeReducer";

const EditScheme = ({
	show,
	setShow,
	editData,
	refetch,
	setStatusMsg,
}: any) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isDiscardVisible, setIsDiscardVisible] = useState(false);
	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<CreateSchemeFormType>({
		defaultValues: {
			[SCHEME_NAME]: "",
			[SCHEME_NAME_FR]: "",
			[SCHEME_DESC_NAME]: "",
			[SCHEME_DESC_FR_NAME]: "",
		},
	});
	const handleClose = () => {
		setIsDiscardVisible(false);
		setShow(false);
	};
	const [updateScheme] = useUpdateSchemeMutation();
	const onSubmit = async (formData: CreateSchemeFormType) => {
		let schemeEditData = {
			id: editData?.id,
			name: formData.schemeName,
			nameFrench: formData.schemeFrName,
			description: formData.schemeDescName,
			descriptionFrench: formData.shemeDescFrName,
		};
		setIsLoading(true);
		const response = await updateScheme(schemeEditData);
		//@ts-ignore
		if (response.data === true) {
			refetch();
			setStatusMsg("Scheme updated successfully");
			setShow(false);
			setIsLoading(false);
		} else {
			setStatusMsg("Failed to update scheme");
			setIsLoading(false);
		}
	};
	useEffect(() => {
		setValue("schemeName", editData?.name);
		setValue("schemeDescName", editData?.description);
		setValue("schemeFrName", editData?.nameFrench);
		setValue("shemeDescFrName", editData?.descriptionFrench);
	}, [editData, setValue]);
	return (
		<>
			<MotifModal
				className="create-edit-stage"
				onClose={() => {
					setIsDiscardVisible(true);
				}}
				show={show}
				focusTrapOptions={{
					tabbableOptions: {
						displayCheck: "none",
					},
				}}
			>
				<MotifModalHeader>Edit Scheme</MotifModalHeader>

				<MotifModalBody>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form-field mar-b-20">
							<ControlInputBox
								name={SCHEME_NAME}
								control={control}
								required={true}
								isTextArea={false}
								label={SCHEME_LABEL}
								showLabel={true}
								value={""}
							/>
							{errors[SCHEME_NAME] &&
								errors[SCHEME_NAME].type === "required" && (
									<MotifErrorMessage>Scheme Name is required</MotifErrorMessage>
								)}
						</div>
						<div className="form-field mar-b-20">
							<ControlInputBox
								name={SCHEME_NAME_FR}
								control={control}
								isTextArea={false}
								label={SCHEME_NAME_LABEL_FR}
								showLabel={true}
								value={""}
							/>
						</div>
						<div className="form-field mar-b-20">
							<ControlTextAreaWithCharLimit
								name={SCHEME_DESC_NAME}
								control={control}
								isTextArea={true}
								label={SCHEME_DESC_LABEL}
								showLabel={true}
							/>
						</div>
						<div className="form-field">
							<ControlTextAreaWithCharLimit
								name={SCHEME_DESC_FR_NAME}
								control={control}
								isTextArea={true}
								label={SCHEME_DESC_FR_LABEL}
								showLabel={true}
							/>
						</div>
					</form>
				</MotifModalBody>

				<MotifModalFooter>
					<Button
						label={SAVE}
						variant="primary"
						className="motif-button"
						onClickHandler={handleSubmit(onSubmit)}
						type={isLoading ? "loader" : "submit"}
						disabled={isLoading}
					/>
				</MotifModalFooter>
			</MotifModal>
			<ConfirmationModal
				isVisible={isDiscardVisible}
				setIsVisible={setIsDiscardVisible}
				cancelBtnApproveHandler={handleClose}
				cancelBtnRejectHandler={() => setIsDiscardVisible(false)}
			/>
		</>
	);
};
export default EditScheme;
