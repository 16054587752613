import { ccwpApi } from "@/services";
import {
  ATTRIBUTESLIST,
  CREATEATTRIBUTE,
  ATTRIBUTEBYID,
  UPDATEATTRIBUTE,
  DELETEATTRIBUTE,
  LIST_ATTRIBUTES_BY_ENTITY,
} from "@/services/constant/apiPath";

export const customAttributesApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    listAttributes: builder.query<any, string>({
      query: (caseId) => ({
        url: ATTRIBUTESLIST + caseId,
        method: "GET",
      }),
    }),

    createAttribute: builder.mutation({
      query: (attributeData) => ({
        url: CREATEATTRIBUTE,
        method: "POST",
        body: attributeData,
      }),
    }),

    attributeById: builder.query({
      query: (attributeId) => ({
        url: ATTRIBUTEBYID + attributeId,
        method: "GET",
      }),
    }),

    updateAttribute: builder.mutation({
      query: (attributeData) => ({
        url: UPDATEATTRIBUTE,
        method: "PUT",
        body: attributeData,
      }),
    }),

    deleteAttribute: builder.mutation({
      query: (attributeData) => ({
        url: DELETEATTRIBUTE,
        method: "DELETE",
        body: attributeData,
      }),
    }),

    listAttributesByEntity: builder.query({
      query: ({ CaseId, EntityType, IsCreate }) => ({
        url: LIST_ATTRIBUTES_BY_ENTITY,
        method: "GET",
        params: { CaseId, EntityType, IsCreate },
      }),
    }),
  }),
});

export const {
  useLazyListAttributesQuery,
  useCreateAttributeMutation,
  useLazyAttributeByIdQuery,
  useUpdateAttributeMutation,
  useDeleteAttributeMutation,
  useListAttributesByEntityQuery,
} = customAttributesApi;
