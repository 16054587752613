import { useEffect, useState } from 'react';
import {  formatAmountUnitDecimalPlace } from '@/utility/common';
import { Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
export const useColumnDefs=(caseData:any)=>{
const {t}=useTranslation()
  const amountCellRenderer = (field: string) => (params: any) => {
    const { defaultUnit } = caseData;
    const decimalPlace =  defaultUnit?.decimalPlace;
    const amount = params.data[field];
    return formatAmountUnitDecimalPlace(amount, decimalPlace);
  };
  const CustomHeader = () => {
    const { defaultUnit } =caseData;
    const [unitName, setUnitName] = useState(defaultUnit?.name);
  
    useEffect(() => {
      setUnitName(defaultUnit?.name);
    }, [defaultUnit]);
  
    return `(${unitName})`;
  };

  return [
  {
    flex:1,

    field: "name",
    headerName: t("pages.debtors.debtorsTabelColumnsDetails.name"),
    type: "nameType",
    sortable: true,
    resizable: true,
    comparator: () => 0,
    unSortIcon: true,
    cellStyle: {
      whiteSpace: "normal",
      lineHeight: "1.5",
    },
    cellRenderer: (params:any)=> <Link to={`./debtor/edit/` + params?.data?.debtorId}>{params?.data?.name}</Link>
  },
  {
    flex:1.3,

    cellRenderer: "totalClaims",
    			comparator: () => 0,
    field: "totalClaims",
    type: "totalClaimsType",
       sortable: true,
    unSortIcon: true,
    headerName:t("pages.debtors.debtorsTabelColumnsDetails.totalClaims", { unitName: CustomHeader() }),
    resizable: true,
    cellClass: 'align-right',
    headerClass: 'align-right',    
  },
  {
    flex:1.3,

    cellRenderer: amountCellRenderer('originalAmount'),
    			comparator: () => 0,
    field: "originalAmount",
    type: "originalAmountType",
       sortable: true,
    unSortIcon: true,
    headerName:t("pages.debtors.debtorsTabelColumnsDetails.originalClaims", { unitName: CustomHeader() }),
    cellClass: 'align-right',
    headerClass: 'align-right',
  },
  {
    flex:1.2,

    cellRenderer: amountCellRenderer('latestCreditorPositionAmount'),
    			comparator: () => 0,
    field: "latestCreditorPositionAmount",
    type: "latestCreditorPositionAmountType",

    sortable: true,
    unSortIcon: true,
    headerName:t("pages.debtors.debtorsTabelColumnsDetails.latestCreditorPosition", { unitName: CustomHeader() }),
    cellClass: 'align-right',
    headerClass: 'align-right',
  },
  {
    flex:1.9,

    cellRenderer: amountCellRenderer('latestDebtorPositionAmount'),
    			comparator: () => 0,
    field: "latestDebtorPositionAmount",
    type: "latestDebtorPositionAmountType",
    headerName: t("pages.debtors.debtorsTabelColumnsDetails.latestDebtorPositionAmount", { unitName: CustomHeader() }),
       sortable: true,
    unSortIcon: true,
    cellClass: 'align-right',
    headerClass: 'align-right',
  },
  {
    flex:1.4,

    cellRenderer: amountCellRenderer('resolvedAmount'),
    			comparator: () => 0,
    field: "resolvedAmount",
    type: "resolvedAmountType",
    sortable: true,
    unSortIcon: true,
    headerName: t("pages.debtors.debtorsTabelColumnsDetails.resolvedAmount", { unitName: CustomHeader() }),
    cellClass: 'align-right',
    headerClass: 'align-right',
  },
  {
    cellRenderer: "actionCellRenderers",
    			comparator: () => 0,
          flex:1.2,
    field: "action",
    headerName: "",
   sortable: false,

       
  },
]};