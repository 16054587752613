import React, { useEffect, useState,useCallback } from "react";
import {
  MotifErrorMessage,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "@/components/Form/Button/Button";
import { SECONDARY } from "@/Constant";
import { AddDebtorFormType } from "./type";
import "./FormModal.scss";
import { compareNumeric, isNumeric } from "@/utility/validation";
import ControlInputBoxNumber from "@/components/Form/InputBox/ControlInputBoxNumber";
import { useUpdateRateTierMutation } from "../calculationStepReducer";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";

type Props = {
  isEditVisible: boolean;
  setEditVisible: React.Dispatch<React.SetStateAction<boolean>>;
  formData?: AddDebtorFormType | null;
  caseId: string;
  stepId: string;
  refetch: any;
  editData: any;
  setSuccessMsg: any;
};

export default function RateTierEditForm({
  isEditVisible,
  setEditVisible,
  stepId,
  editData,
  setSuccessMsg,
  refetch,
}: Props) {
  const { t } = useTranslation();
  const [isVisibleCancel, setIsVisibleCancel] = useState(false);
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: "",
  });

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, isDirty },
  } = useForm<any>();

  const resetData = useCallback(() => {
    setValue(
      "maxAmount",
      editData?.maxAmount === 0 ? "0" : editData?.maxAmount
    );
    setValue(
      "minAmount",
      editData?.minAmount === 0 ? "0" : editData?.minAmount
    );
    setValue("rate", editData?.rate);
    setValue("adjustment", editData?.adjustment);
    setValue("rateTierGroupBy", editData?.rateTierGroupBy?.toString());
  }, [editData, setValue]);

  useEffect(() => {
    resetData();
  }, [editData, resetData]);

  const [UpdateRateTier, { isLoading }] = useUpdateRateTierMutation();
  const setFormat = (value: any) => {
    return value === "" || value == null || value === 0 ? null : Number(value);
  };

  const onSubmit = async (data: any) => {
    let formData = {
      id: editData?.id,
      calculationStepId: stepId,
      maxAmount: setFormat(data?.maxAmount),
      minAmount: setFormat(data?.minAmount),
      rate: data?.rate === "" ? 1 : Number(data?.rate),
      adjustment: data?.adjustment === "" ? null : Number(data?.adjustment),
      // rateTierGroupBy: Number(data?.rateTierGroupBy),
    };
    const diff = compareNumeric(data?.maxAmount, data?.minAmount);
    if (diff < 0) {
      setError("maxAmount", {
        type: "manual",
        message: "greater",
      });
      return;
    }
    const response = await UpdateRateTier(formData).unwrap();
    if (response) {
      setEditVisible(false);
      setSuccessMsg(true);
      refetch();
      //reset()

    } else {
      setEditVisible(false);
      console.log("error in update rate tier");
    }
  };
  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setEditVisible(false);
    setIsVisibleCancel(false);
    resetData();
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisibleCancel(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };
  return (
    <>
      <MotifModal
        onClose={() =>{ setEditVisible(false);resetData();}}
        show={isEditVisible}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        {/*@ts-ignore*/}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formModalWrapper">
            <MotifModalHeader>
              <span className="discard_heading">{t("pages.distributions.distributionStepsTab.rateTierTab.formLabels.editTitle")}</span>
            </MotifModalHeader>
            <MotifModalBody>
              <div className="formModal">
                {/* <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlSelect
                      name={"rateTierGroupBy"}
                      //@ts-ignore
                      control={control}
                      required={true}
                      showLabel={true}
                      readonly={true}
                      label={t("Type")}
                      //@ts-ignore
                      placeholder={"placeholder"}
                      options={[
                        { label: "Select Type", value: "" },
                        { label: "Creditor", value: "0" },
                        { label: "Claim", value: "1" },
                        { label: "Creditor/Assignee", value: "2" },
                      ]}
                    />
                  </div>
                </div> */}
                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlInputBoxNumber
                      name={"maxAmount"}
                      // @ts-ignore
                      control={control}
                      // @ts-ignore
                      setValue={setValue}
                      required={false}
                      isTextArea={false}
                      label={"pages.distributions.distributionStepsTab.rateTierTab.formLabels.tierMaxAmount"}
                      showLabel={true}
                      isNumber={true}
                      // digitAfterDecimal={2}
                      isAmount={false}
                      onChangeAmount={() => {}}
                    />
                  </div>
                </div>
                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlInputBoxNumber
                      name={"minAmount"}
                      // @ts-ignore
                      control={control}
                      required={false}
                      isTextArea={false}
                      label={"pages.distributions.distributionStepsTab.rateTierTab.formLabels.tierFloorAmount"}
                      showLabel={true}
                      isNumber={true}
                      // digitAfterDecimal={2}
                      isAmount={true}
                      onChangeAmount={() => {}}
                      // validate={isNumeric}
                    />
                  </div>
                </div>
                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlInputBoxNumber
                      name={"rate"}
                      // @ts-ignore
                      control={control}
                      required={true}
                      isTextArea={false}
                      label={"pages.distributions.distributionStepsTab.rateTierTab.formLabels.rate"}
                      showLabel={true}
                      isNumber={true}
                      digitAfterDecimal={2}
                      isAmount={true}
                      onChangeAmount={() => {}}
                      validate={isNumeric}
                    />
                    {errors["rate"] && errors["rate"].type === "required" && (
                      <MotifErrorMessage>
{t("pages.distributions.distributionStepsTab.rateTierTab.validationMessages.rateRequired")}                   
     </MotifErrorMessage>                    )}
                  </div>
                </div>
                <div className="motif-row mar-t-20">
                  <div className="motif-col-md-5">
                    <ControlInputBoxNumber
                      name={"adjustment"}
                      // @ts-ignore
                      control={control}
                      required={false}
                      isTextArea={false}
                      label={"pages.distributions.distributionStepsTab.rateTierTab.formLabels.adjustment"}
                      showLabel={true}
                      isNumber={true}
                      digitAfterDecimal={2}
                      isAmount={true}
                      onChangeAmount={() => {}}
                    />
                    {errors["adjustment"] &&
                      errors["adjustment"].type === "required" && (
                        <MotifErrorMessage>
{t("pages.distributions.distributionStepsTab.rateTierTab.validationMessages.adjustmentRequired")}                   
     </MotifErrorMessage>
                      )}
                  </div>
                </div>
                <div className="error-container">
                  {isDirty && errors["maxAmount"]?.message === "greater" && (
                    <MotifErrorMessage>
                                                          {t("pages.distributions.distributionStepsTab.rateTierTab.maxAmountWarningMessage")}

                    </MotifErrorMessage>
                  )}
                </div>
              </div>
            </MotifModalBody>
            <MotifModalFooter>
              <div className="button-container-add">
                <Button label={t("global.globalActions.save")} disabled={isLoading} />
                <Button
                  label={t("global.globalActions.cancel")}
                  type="button"
                  variant={SECONDARY}
                  className="cancelBtn"
                  onClickHandler={() => {
                    setIsVisibleCancel(true);
                  }}
                />
              </div>
            </MotifModalFooter>
          </div>
        </form>
      </MotifModal>
      <ConfirmationModal
        isVisible={isVisibleCancel}
        setIsVisible={setIsVisibleCancel}
        cancelBtnApproveHandler={cancelBtnApproveHandler}
        cancelBtnRejectHandler={cancelBtnRejectHandler}
      />
    </>
  );
}
