import React from "react";
import { Control, Controller } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CreditorSetupFormType } from "../type";
import InputBox from "@/components/Form/InputBox/InputBox";

type ControlInputBoxType = {
  required?: boolean;
  isTextArea?: boolean;
  control: Control<CreditorSetupFormType>;
  name: string;
  showLabel: boolean;
  label: string;
  prefix?: boolean;
  prefixComponent?: JSX.Element;
  withCountry?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  type?: "number" | "text" | "email" | "tel" | "date" | "password" | undefined;
};

export default function ControlInputBox({
  name,
  control,
  required = false,
  isTextArea = false,
  showLabel,
  label,
  withCountry,
  readonly,
  disabled,
  type,
}: ControlInputBoxType) {
  const { field } = useController({
    //@ts-ignore
    name,
    control,
    rules: { required: required },
  });
  const { t } = useTranslation();

  return (
    <>
      {showLabel && (
        <div className="formFieldLabel">
          <label htmlFor="input" aria-label={label}>
            {t(label)}
            {required ? <span className="requiredSymbol">*</span> : ""}
          </label>
        </div>
      )}
      <Controller
        name={field.name}
        control={control}
        rules={{ required }}
        render={({ field: { onChange, value, ...args } }) => {
          return (
            <>
              {/*@ts-ignore*/}
              <InputBox
                id={name}
                required={true}
                value={value || ""}
                onChange={onChange}
                isTextArea={isTextArea}
                // withCountry={withCountry}
                readonly={readonly}
                disabled={disabled}
                type={type}
                {...args}
              />
            </>
          );
        }}
      />
    </>
  );
}
