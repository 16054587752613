import { fileExtensions } from "@/services/documents/documentsReducer";
import {
  MotifButton,
  MotifFileUploaderItem,
  MotifIcon,
} from "@ey-xd/motif-react";
import {
  actionIcDescription24px,
  editorIcVerticalAlignBottom24px,
} from "@ey-xd/motif-react/assets/icons";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
type FileListProps = {
  file: File;
  index: number;
  removeItem: (index: number) => void;
  handleFileDownload: (
    e: MouseEventHandler<HTMLButtonElement>,
    index: number
  ) => void;
};

export default function FileList({
  file,
  index,
  removeItem,
  handleFileDownload,
}: FileListProps) {
  const { t } = useTranslation();
  if (!file?.name) {
    return null;
  }
  return (
    <div className="mar-t-30">
      <MotifFileUploaderItem
        fileIcon={<MotifIcon src={actionIcDescription24px} />}
        customButton={
          <MotifButton
            //@ts-ignore
            onClick={(e) => handleFileDownload(e, index)}
            variant="primary-alt"
          >
            <MotifIcon
              fill="none"
              iconFunction={editorIcVerticalAlignBottom24px}
            />
          </MotifButton>
        }
        removable={true}
        fileName={file.name}
        onRemove={() => {
          removeItem(index);
        }}
        error={(file && file.size >= fileExtensions.maxFileSize) || false}
        errorText={t("pages.claims.stageStatusPopup.filesizeExceedsLimit", {
          filesize: "250",
        })}
      />
    </div>
  );
}
