import { useCase } from "@/features/case/use-case";
import {
  useLazySearchCreditorListDataQuery,
  useLazyGetCreditorListByCreditorIdAndCaseIdQuery,
} from "@/services/creditors/creditorsReducers";
import {
  MotifErrorMessage,
  MotifFormField,
  MotifLabel,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import CreditorDetails from "./CreditorDetails";
import AddCreditorButton from "./AddCreditorButton";
import ContactForm from "./ContactForm";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";

import { useDispatch, useSelector } from "react-redux";

import { CreditorSearchData, resetCreditorState } from "../creditorSlice";

import useResetAllClaimItemsStore from "../../useResetAllClaimItemsStore";
import { useNavigate } from "react-router-dom";
import NewContactForm from "./NewContactForm";
import SearchInput from "@/components/SearchInput/SearchInput";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
import { useTranslation } from "react-i18next";

interface RootState {
  claimSetupCreditorSearch: CreditorSearchData;
}

type CreditorSearchProps = {
  setDataInStore: Dispatch<SetStateAction<boolean>>;
  dataInStore: boolean;
  goToNextTab: () => void;
  CreditorDataFromCreditorTab: {
    caseId: string;
    creditorId: string;
  };
};

function renderCreditorDetails(selectedCreditorDetail: {
  creditorName?: any;
  description?: any;
  creditorReference?: any;
  relationShipToDebtor?: any;
}) {
  if (!selectedCreditorDetail) {
    return null;
  }
  const { creditorName, description, creditorReference, relationShipToDebtor } =
    selectedCreditorDetail;
  return (
    <>
      <CreditorDetails
        creditorName={creditorName}
        creditorDescription={description}
        creditorReferenceId={creditorReference}
        relationShipToDebtor={relationShipToDebtor}
      />
    </>
  );
}

export default function CreditorSearch({
  dataInStore,
  setDataInStore,
  goToNextTab,
  CreditorDataFromCreditorTab,
}: CreditorSearchProps) {
  const { caseId } = useCase();
  const [
    searchCreditors,
    { data: creditorDataByCase, isLoading: isCreditorDataByCaseLoading },
  ] = useLazySearchCreditorListDataQuery();

  const selectedCreditorContactDetailsFromStore = useSelector(
    (state: RootState) => {
      return state.claimSetupCreditorSearch.searchCreditorContactDetailsData;
    }
  );

  const selectedCreditorId = useSelector(
    (state: RootState) =>
      state.claimSetupCreditorSearch.selectedCreditorCompleteDetail
  );

  // const newCreditorDetail = useSelector(
  //   (state: RootState) => state.claimSetupCreditorSearch.newCreditorDetail
  // );

  const isNewCreditorData = useSelector(
    (state: RootState) => state.claimSetupCreditorSearch.isNewCreditor
  );

  const [
    getCreditor,
    {
      data: creditorDataByCreditorIdAndCaseID,
      isLoading: isCreditorDataByCreditorIdAndCaseIDLoading,
    },
  ] = useLazyGetCreditorListByCreditorIdAndCaseIdQuery();

  useEffect(() => {
    if (
      caseId &&
      (CreditorDataFromCreditorTab?.creditorId ||
        selectedCreditorContactDetailsFromStore.creditorId)
    ) {
      getCreditor({
        caseId: CreditorDataFromCreditorTab
          ? CreditorDataFromCreditorTab.caseId
          : caseId,
        creditorId: CreditorDataFromCreditorTab
          ? CreditorDataFromCreditorTab.creditorId
          : selectedCreditorContactDetailsFromStore.creditorId,
      });
    }
  }, [
    caseId,
    CreditorDataFromCreditorTab,
    getCreditor,
    selectedCreditorContactDetailsFromStore.creditorId,
  ]);

  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
const {t}=useTranslation()
  const [inputValue, setInputValue] = useState("");
  const [changeCreditor, setChangeCreditor] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [selectedCreditorDetail, setSelectedCreditorDetail] = useState<any>({});
  const [isNewCreditor, setIsNewCreditor] = useState(isNewCreditorData);
  const [clearContactForm, setClearContactForm] = useState(false);
  const resetAllClaimItemsStore = useResetAllClaimItemsStore();

  let searchItems = [];
  if (creditorDataByCase && creditorDataByCase.length > 0) {
    searchItems = creditorDataByCase.map(
      (creditor: { creditorName: string }) => `${creditor?.creditorName}`
    );
  }
  if(isVisible) {
    dispatch(setDiscardEditMode({ editMode: false }));
  }
  const handleCreditorCancel = () => {
    setIsVisible(false);
    dispatch(setDiscardEditMode({ editMode: true}));

  };

  const handleCreditorReset = () => {

    setSelectedCreditorDetail({});
    dispatch(resetCreditorState());
    setIsVisible(false);
    resetAllClaimItemsStore();
    navigate(`/case/${caseId}?activeTab=3`);
  };

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    setCharCount(inputValue.length);
    setChangeCreditor(false);
  };

  const handleSearchInputEnter = () => {
    setClearContactForm(false);
    setIsNewCreditor(false);
    setDataInStore(false);
  };
  const handleSearchInputSelect = (value: string) => {
    const selectedCreditorDetail = creditorDataByCase.find(
      (creditor: { creditorName: string }) => creditor.creditorName === value
    );

    setClearContactForm(false);
    setIsNewCreditor(false);
    setDataInStore(false);
    setSelectedCreditorDetail(selectedCreditorDetail);
    setChangeCreditor(true);
  };
  const handleSearchInputClear = () => {
    setCharCount(0);
    setClearContactForm(true);
    dispatch(resetCreditorState());
    setClearContactForm(true);
    setChangeCreditor(false);
    setDataInStore(false);
  };

  const showError = false;
  const showErrorMessage = "pages.claims.claimCreate.creditorTab.validationMessages.creditorSectionMessage";

  const handleNewCreditorClick = () => {
    setInputValue("");
    setIsNewCreditor(true);
    dispatch(resetCreditorState());
  };

  const creditorId = selectedCreditorDetail?.creditorId;

  useEffect(() => {
    if (caseId) {
      searchCreditors({ CaseId: caseId });
    }
  }, [caseId, searchCreditors]);
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: true }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch]);
  useEffect(() => {
    if (selectedCreditorId && creditorDataByCase) {
      const creditorStore =
        creditorDataByCase &&
        creditorDataByCase.length > 0 &&
        creditorDataByCase.find(
          (creditor: any) =>
            creditor.creditorId ===
              selectedCreditorContactDetailsFromStore.creditorId ||
            selectedCreditorId
        );
      setSelectedCreditorDetail(creditorStore);
      setInputValue(creditorStore?.creditorName);
    }
  }, [
    selectedCreditorId,
    creditorDataByCase,
    selectedCreditorContactDetailsFromStore.creditorId,
  ]);

  useEffect(() => {
    if (
      creditorDataByCreditorIdAndCaseID !== undefined ||
      creditorDataByCreditorIdAndCaseID?.length > 0
    ) {
      setInputValue(creditorDataByCreditorIdAndCaseID[0]?.creditorName);
      setSelectedCreditorDetail(creditorDataByCreditorIdAndCaseID[0]);
    }
  }, [creditorDataByCreditorIdAndCaseID]);

  useEffect(() => {
    if (isNewCreditorData === true) {
      setInputValue("");
    }
  }, [isNewCreditorData]);

  if (
    isCreditorDataByCaseLoading ||
    isCreditorDataByCreditorIdAndCaseIDLoading
  ) {
    return <MotifProgressLoader />;
  }
 

  return (
    <div className="">
      <div className="motif-row">
        <div className="motif-col-lg-6">
          <div className="formFieldLabel">
            <MotifLabel>
             {t("pages.claims.claimCreate.creditorTab.formLabelsDetails.searchCreditor")}<span className="requiredSymbol">*</span>
            </MotifLabel>
          </div>
          <div className="">
            <MotifFormField>
              <SearchInput
                placeholder="Search for a Creditor"
                aria-label="Search for a Creditor"
                items={searchItems}
                className={`search-dropdown ${
                  charCount >= 1 ? "show-suggestions" : ""
                }`}
                onChange={handleSearchInputChange}
                onSelect={handleSearchInputSelect}
                onEnter={handleSearchInputEnter}
                onClear={handleSearchInputClear}
                value={isNewCreditorData ? "" : inputValue}
                visibleOptions={3}
              />
            </MotifFormField>
          </div>
        </div>
      </div>
      {showError && <MotifErrorMessage>{t(showErrorMessage)}</MotifErrorMessage>}
      {!isNewCreditor && (
        <AddCreditorButton handleNewCreditorClick={handleNewCreditorClick} />
      )}
      {isNewCreditor && (
        <NewContactForm setIsVisible={setIsVisible} goToNextTab={goToNextTab} />
      )}
      {!isNewCreditor &&
        selectedCreditorDetail?.creditorId &&
        renderCreditorDetails(selectedCreditorDetail)}
      <ContactForm
        creditorId={creditorId}
        clearContactForm={clearContactForm}
        setIsVisible={setIsVisible}
        goToNextTab={goToNextTab}
        changeCreditor={changeCreditor}
        dataInStore={dataInStore}
        setDataInStore={setDataInStore}
      />

      <ConfirmationModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        cancelBtnApproveHandler={handleCreditorReset}
        cancelBtnRejectHandler={handleCreditorCancel}
      />
    </div>
  );
}