import { useLazyGetDocumentTypesQuery } from "@/services/documents/documentsReducer";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setClaim } from "./formDefaultsSlice";

const useFormDefaults = () => {
  const formDefaults = useSelector((state: any) => state.formDefaults);
  const { claim } = formDefaults;
  const { documentTypes } = claim;
  const dispatch = useDispatch();
  const [getDocumentTypes, { data: documentTypesData, isSuccess }] =
    useLazyGetDocumentTypesQuery();

  useEffect(() => {
    if (!documentTypes) {
      getDocumentTypes();
    }

    if (!documentTypes && documentTypesData && isSuccess) {
      dispatch(
        setClaim({
          ...claim,
          documentTypes: documentTypesData,
        })
      );
    }
  }, [
    documentTypes,
    documentTypesData,
    claim,
    isSuccess,
    dispatch,
    getDocumentTypes,
  ]);

  return formDefaults;
};

export default useFormDefaults;
