import { dateFunc } from "@/components/Functions/dateFunc";
import { customComparator } from "@/utility/common";
import { useTranslation } from "react-i18next";

export const useColumnDistributionSteps=() =>{
  const {t}=useTranslation()
  return [
  {
    field: "sortOrder",
    headerName: t("pages.distributions.distributionStepsTab.columnDetails.column1"),
    sortable: false,
    unSortIcon: true,
    cellRenderer: "orderCellRenderer",
    cellClass: "align-left",
    headerClass: "align-left",
  },
  {
    field: "name",
    headerName: t("pages.distributions.distributionStepsTab.columnDetails.column2"),
    sortable: false,
    comparator: customComparator,
    unSortIcon: true,
    cellClass: "align-left",
    headerClass: "align-left",
    editable: true,
    cellRenderer: "stepNameRenderer",
  },
  {
    field: "subAccountName",
    headerName: t("pages.distributions.distributionStepsTab.columnDetails.column3"),
    sortable: false,
    comparator: customComparator,
    unSortIcon: true,
    cellClass: "align-left",
    headerClass: "align-left",
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["SubAccount 1", "SubAccount 2", "SubAccount 3"],
    },
    editable: true,
  },
  {
    field: "isMemo",
    headerName: t("pages.distributions.distributionStepsTab.columnDetails.column4"),
    sortable: false,
    unSortIcon: true,
    cellClass: "align-left",
    headerClass: "align-left",
    cellRenderer: function (params: any) {
      return params.value ? "Memo" : "Payable";
    },
  },
  {
    field: "paymentGrouping",
    headerName: t("pages.distributions.distributionStepsTab.columnDetails.column5"),
    sortable: false,
    comparator: customComparator,
    unSortIcon: true,
    cellClass: "align-left",
    headerClass: "align-left",
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["Split by Creditor", "Split by Payee", "Claim Owner"],
    },
    cellRenderer: function (params: any) {
      switch (params.value) {
        case 1:
          return "Split by Creditor";
        case 2:
          return "Split by Payee";
        case 3:
          return "Claim Owner";
        default:
          return "";
      }
    },
    editable: true,
  },
  {
    field: "payeeTypeName",
    headerName: t("pages.distributions.distributionStepsTab.columnDetails.column6"),
    sortable: false,
    unSortIcon: true,
    cellClass: "align-left",
    headerClass: "align-left",
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["Provincial tax authority (case level)", "Other Payee Type"],
    },
    editable: true,
  },
  {
    field: "updatedOn",
    headerName: t("pages.distributions.distributionStepsTab.columnDetails.column7"),
    sortable: false,
    unSortIcon: true,
    cellClass: "align-left",
    headerClass: "align-left",
    cellRenderer: function (params: any) {
      return dateFunc(params.value).formattedDateOnly;
    },
  },
  {
    field: "updatedBy",
    headerName: t("pages.distributions.distributionStepsTab.columnDetails.column8"),
    sortable: false,
    unSortIcon: true,
    cellClass: "align-left",
    headerClass: "align-left",
  },
  {
    cellRenderer: "actionCellRenderers",
    field: "action",
    headerName: "",
    flex: 1,
    sortable: false,
  },



]};
