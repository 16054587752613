import getMSALCache from "@/features/auth/lib/getMSALCache";
import logger from "@/libs/logger";

export default async function fetchFn(
  request: string | Request,
  options: any = {}
): Promise<Response> {
  const headers: HeadersInit = {};
  let token = null;
  const hostname =
    typeof request === "string"
      ? new URL(request).hostname
      : new URL(request.url).hostname;

  if (hostname === "graph.microsoft.com") {
    token = getMSALCache("accesstoken", "openid");
  } else {
    token = getMSALCache("accesstoken", "default");
  }

  if (token) {
    logger.info("fetchFn: token detected; adding to request headers");
    headers.authorization = `Bearer ${token.secret}`;
  }

  logger.info("fetchFn: fetching request", { request, options });
  const res: Response = await fetch(request, { headers });
  const contentType = res.headers.get("content-type");

  if (contentType?.includes("image")) {
    logger.info("fetchFn: image response detected; converting to base64");
    const blob = await res.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(new Response(JSON.stringify({ data: base64data })));
      };
    });
  }

  // if not json and not an image binary, return the response as is
  return res;
}
