import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Table from "@/components/Table/table";
import {
  MotifButton,
  MotifIcon,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import {
  IconoirEditPencil,
  IconoirCloudDownload,
  IconoirTrash,
} from "@ey-xd/motif-icon";
import UploadEditDocument from "./uploadCreditorEditDoc/UploadEditCreditorDoc";
import { useCase } from "@/features/case/use-case";
import {
  useLazyDownloadDocumentQuery,
  useDeleteDocumentMutation,
} from "@/services/documents/documentsReducer";
import DeleteModal from "@/components/Modal/DeleteModal/DeleteModal";
import { useListDocumentsQuery } from "@/pages/CaseDetail/Creditor/CreditorSummaryView/CreditorSummaryReducer";
import { useColumnDefsCreditor } from "../../claimsTab/Constant/creditorHeaderConst";

export const CreditorFileuploader = () => {
  const parameter = useParams();
  const { t } = useTranslation();
  const caseData = useCase();
  const { caseId } = caseData;
  const { data, isLoading, refetch } = useListDocumentsQuery({
    creditorId: parameter?.creditorId ?? "",
  });

  const processedData = data?.map((doc: any) => {
    const updatedDoc = {
      ...doc,
      creditorId: parameter?.creditorId,
      caseId: caseId,
    };
    if (updatedDoc.entityType === 2) {
      return { ...updatedDoc, claimId: updatedDoc.entityId };
    }
    return updatedDoc;
  });

  const [showUploadEdit, setShowUploadEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedDocument, setSelectedDocuement] = useState<any>({});
  const [downloadDocument] = useLazyDownloadDocumentQuery();
  const [deleteDocument] = useDeleteDocumentMutation();

  useEffect(() => {
    if (showUploadEdit === false) {
      refetch();
    }
    // eslint-disable-next-line
  }, [showUploadEdit]);

  function TitleComplement() {
    return (
      <MotifButton
        onClick={handleUploadNew}
        size="small"
        type="submit"
        variant="secondary"
      >
{t("global.globalActions.uploadNewDocument")}      </MotifButton>
    );
  }

  const customActionHeader = {
    actionRenderer: function (props: any) {
      return (
        <>
          <div className="ccwp_document_customheader_wrapper">
            <MotifButton
              onClick={() => handleDownload(props.data)}
              size="small"
              type="submit"
              variant="primary"
            >
              <MotifIcon fill="none" iconFunction={IconoirCloudDownload} />
            </MotifButton>
            <MotifButton
              onClick={() => {
                setSelectedDocuement(props.data);
                setShowDelete(true);
              }}
              size="small"
              type="submit"
              variant="primary"
              disabled={props.data.claimReference}
            >
              <MotifIcon fill="none" iconFunction={IconoirTrash} />
            </MotifButton>
            <MotifButton
              onClick={() => handleEdit(props.data)}
              size="small"
              type="submit"
              variant="primary"
            >
              <MotifIcon fill="none" iconFunction={IconoirEditPencil} />
            </MotifButton>
          </div>
        </>
      );
    },
  };

  const handleUploadNew = () => {
    setIsEdit(false);
    setSelectedDocuement({});
    setShowUploadEdit(true);
  };

  const handleEdit = (documentData: any) => {
    setIsEdit(true);
    setSelectedDocuement(documentData);
    setShowUploadEdit(true);
  };

  const handleDownload = (documentData: any) => {
    downloadDocument({
      caseId: caseId,
      documentId: documentData.id,
      fileName: documentData.fileName,
    });
  };

  const handleDelete = () => {
    deleteDocument({
      CaseId: caseId,
      DocumentId: selectedDocument.id,
    }).then(() => {
      setShowDelete(false);
      refetch();
    });
  };
  const columnDefsCreditor=useColumnDefsCreditor();

  return (
    <>
      <div className="mar-b-20">
        {isLoading ? (
          <MotifProgressLoader show variant="default" className="mar-b-20" />
        ) : (
          <Table
            columnDefs={columnDefsCreditor}
            cellRenderers={processedData}
            TitleComplement={TitleComplement}
            title={"pages.creditors.creditorDocumentUpload.creditorDocumentUploadTitle"}
            description={
"pages.creditors.creditorDocumentUpload.creditorDocumentUploadDescription"            }
            overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
            caseRenderers={customActionHeader}
            pagination={false}
          />
        )}
      </div>

      {showUploadEdit && (
        <UploadEditDocument
          show={showUploadEdit}
          setShow={setShowUploadEdit}
          creditorId={parameter?.creditorId!}
          isEdit={isEdit}
          document={selectedDocument}
        />
      )}

      <DeleteModal
        message={t("pages.claims.documentPopup.deleteMessage")}
        isVisible={showDelete}
        setIsVisible={setShowDelete}
        cancelBtnHandler={() => setShowDelete(false)}
        yesBtnHandler={handleDelete}
      />
    </>
  );
};
