export const PAGE_TITLE = "Debtor Setup"
export const PAGE_DESCRIPTION = "Descriptive text that describes your page or content."
export const DEBTOR_NAME = "Debtor Name";
export const DEBTOR_ID = "name";
export const DEBTOR_ID_F = "debtorNameInFrench";
export const DEBTOR_DESCRIPTION = "Debtor Description";
export const DEBTOR_DESCRIPTION_ID = "description";
export const DEBTOR_DESCRIPTION_ID_F = "debtorDescriptionInFrench";
export const DEBTOR_IDENTIFIERS = "Debtor Details";
export const FORM_TITLE = "Debtor Details"
export const FORM_DESCRIPTION = "Descriptive text that describes your page or content."
