import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function GreetingSection() {
  const user = useSelector((state: any) => state.user);
  const firstname = (user && user.name && user.name.split(" ")[0]) || "";
  const { t } = useTranslation();
  return (
    <div className="ccwp_caselist_innerwrapper">
      <h3 className="motif-h3-default-bold">
        {t("pages.home.hello", { name: firstname })},
      </h3>
      <p className="motif-body2-default-light">{t("pages.home.greetings")}</p>
    </div>
  );
}
