import { CaseSetupPostData, CaseSetupType } from "./CaseSetupType";
import { trimString } from "@/utility/common";

export default function CaseSetupHelper() {
  const data: CaseSetupType = {
    caseSetupForm: {
      caseName: "",
      caseDescription: "",
      caseDescriptionInFrench: "",
      caseShortName: "",
      status: "",
      caseNameInFrench: "",
      isFrenchSelected: false,
      caseTimeZone: "",
    },
    debtorData: [],
    currencyConfiguration: {
      defaultCurrency: "",
      defaultExchangeRate: "",
      defaultDecimalPoint: "",
      fieldArray: [],
    },
    stage: 0,
    formSubmitIndex: 0,
  };
  return data;
}
function debtorPostData(data: CaseSetupType) {
  return data.debtorData.map((debtor) => {
    return {
      name: debtor.legalDebtorName,
      description: debtor.debtorDescription,
    };
  });
}

function defaulturrencyPostData(data: CaseSetupType) {
  const currencyData = {
    name: data.currencyConfiguration.defaultCurrency,
    exchangeRate: Number(data.currencyConfiguration.defaultExchangeRate),
    decimalPlace: Number(data.currencyConfiguration.defaultDecimalPoint),
    isDefaultCurrency: true,
  };
  return currencyData;
}

function currencyPostData(data: CaseSetupType) {
  const currencyData = {
    fieldArray: data.currencyConfiguration.fieldArray.map((currency) => {
      return {
        name: currency.originalCurrency,
        exchangeRate: Number(currency.originalExchangeRate),
        decimalPlace: Number(currency.originalDecimalPoint),
        isDefaultCurrency: false,
      };
    }),
  };
  return currencyData.fieldArray;
}

export function postCaseSetupData(data: CaseSetupType) {
  const postData: CaseSetupPostData = {
    name: trimString(data.caseSetupForm.caseName),
    nameFrench: data.caseSetupForm.caseNameInFrench,
    description: data.caseSetupForm.caseDescription,
    descriptionFrench: data.caseSetupForm.caseDescriptionInFrench,
    shortName: data.caseSetupForm.caseShortName,
    isActive: true,
    debtors: debtorPostData(data),
    displayCurrency: defaulturrencyPostData(data),
    originalCurrencies: currencyPostData(data),
    isFrenchCaseChecked: data.caseSetupForm.isFrenchSelected || false,
    timeZone: Number(data.caseSetupForm.caseTimeZone),
  };
  return postData;
}
