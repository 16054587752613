export const STATUS = "Status";
export const ROLE="Role"
export const STATUS_FIELD = "status";
export const COMMENT = "comment";
export const FRENCH = "French";
export const SAVE = "Save";
export const DELETE = "Read";
export const DISCARD = "Discard";
export const CANCEL = "Cancel";
export const REFRESH="Refresh"
export const SECONDARY = "secondary";
export const PRIMARY = "primary";
export const REQUIRED_MESSAGE = "Please complete all required fields to continue.";
export const SAVE_OR_DISCARD = "Are you sure you want to discard your changes?";
export const UNSAVED_CHANGES = "Unsaved Changes";
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ENEXPECTED_ERROR = "An unexpected error occurred";
export const CASE_NAME_ALREADY_EXISTS = "Case Name already exists";
export const UPDATE_SUCCESSFUL = "Successfully updated";
export const Create_SUCCESSFUL = "Successfully Created";
export const SAVE_DRAFT = "Save Draft";
export const REVIEW_AND_SUBMIT = "Review and Submit";
export const HEADER_CASEID = "5D6621F3-B65F-4B02-A528-05605434F0F8";
export const HEADER_CASENAME = "ALDO group";
export const ENGLISH = "English";
export const NEXT = "Next";
export const EDIT = "Edit";
export const DEFAULT_CURRENCY_SELECT_CONFIRMATION = "Are you sure to select this unit as Default Currency?";
export const DEFAULT_CURRENCY_UNSELECT_CONFIRMATION = "Are you sure to unselect this unit as Default Currency?"
export const CONFIRM = "Confirm";
export const YES = "Yes";
export const NO = "No";
export const OK = "OK";
export const DEBTOR_ALREADY_EXIST = "Debtor already exist.";
export const RELATION_TO_DEBTOR_OPTIONS = ["Other","Creditor", "Employee"];
export const RELATIONSHIP_TO_DEBTOR = "Relationship to Debtor";
export const RELATIONSHIP_OPTIONS_DEBTOR = [{
    label: "Other",
    value: "0"
    },
    {
    label: "Creditor",
    value: "1"
    },
    {
    label: "Employee",
    value: "2"
}];
export const DECIMAL_ERROR_MESSAGE = "Cannot exceed the configured decimal places or Invalid number.";  
export const PREFIX_OPTIONS_LIST = [
    { 
        label: "Please Select", 
        value: "" },
    {
        label: "Mr.",
        value: "Mr.",
    },
    {
        label: "Ms.",
        value: "Ms.",
    },
    {
        label: "Mrs.",
        value: "Mrs.",
    },
    {
        label: "Miss.",
        value: "Miss.",
    },
    {
        label: "Other",
        value: "other",
    }
]
export const characterLimitationOfTextArea = 1000;

export const paginationPageSize = 10;

export const paginationPageSizeSelector = [10, 20, 50];

export const InitialPermission = {
    "Creditor": ["Read"],
    "Debtor": ["Read"],
    "Unit": ["Read"],
    "ClaimItemCategory": ["Read"],
    "ClaimStageDefinition": ["Read"],
    "ClaimStatusDefinition": ["Read"],
    "RateTier": ["Read"],
    "RateTierTemplate": ["Read"],
    "CaseType": ["Read"],
    "User": ["Read"],
    "UserCaseGroup": ["Read"],
    "AttributeDefinition": ["Read"],
    "AttributeValue": ["Read"],
    "Scheme": ["Read"],
    "SchemeVersion": ["Read"],
    "Class": ["Read"],
    "Rule": ["Read"],
    "ClaimStageInstance": ["Read"],
    "ClaimStatusInstance": ["Read"],
    "Claim": ["Read"],
    "ClaimItem": ["Read"],
    "ClaimHistorySnapshot": ["Read"],
    "ClaimItemHistorySnapshot": ["Read"],
    "Account": ["Read"],
    "CalculationStep": ["Read"],
    "CalculationSettings": ["Read"],
    "Distribution": ["Read"],
    "StepClassFilter": ["Read"],
    "StepDataSource": ["Read"],
    "StepLimitAmount": ["Read"],
    "PayeeType": ["Read"],
    "Ballot": ["Read"]
};
export const READ_PERMISSION = 'Read';
export const WRITE_PERMISSION = 'Write';
export const DELETE_PERMISSION = 'Delete';
export const ADD_PERMISSION = 'Add';    