import React from "react";
import { MotifButton } from "@ey-xd/motif-react";
import "./Button.scss";
type ButttonType = {
  label: string;
  disabled?: boolean;
  variant?:
    | "primary"
    | "primary-alt"
    | "secondary"
    | "ghost"
    | "text"
    | "text-alt"
    | "warn";
  className?: string;
  onClickHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "submit" | "reset" | "button" | "loader";
  loading?: boolean;
};

export default function Button({
  label,
  variant = "primary",
  className,
  disabled,
  onClickHandler,
  type = "submit",
  loading = false,
}: ButttonType) {
  return (
    <>
      <MotifButton
        onClick={onClickHandler}
        size="small"
        type={type}
        variant={variant}
        className={`btn ${className} `}
        disabled={disabled}
        loading={loading}
      >
        {label}
      </MotifButton>
    </>
  );
}
