export const CASELIST = "Case/GetCaseList";
export const DEBTORLIST_1 = "Debtor/GetDebtorListByCase?CaseID=";
export const DEBTORLIST_2 = "&pageNumber=1&pageSize=5&sortOrder=asc";
//export const CREDITORLIST = "Creditor/GetCreditorSearchList?Type=quick%20search&CaseId=5D6621F3-B65F-4B02-A528-05605434F0F8&OrderBy=desc&Keyword=bm&PageNo=1&PageSize=5";
export const CREDITOR_SEARCH_LIST = "Creditor/GetCreditorSearchList";
export const CREDITORLIST = "Creditor/GetCreditorBasicSearchList?CaseId=";
export const CASESUMMARYDETAILS = "Case/GetCaseSummaryById";
export const CLAIMBYCASE = "Claim/GetListClaims?CaseId=";
export const CLAIMBYCREDITOR = "Claim/GetListClaims?CaseId=";
export const CLAIMBYDEBTOR = "Claim/GetListClaims?CaseId=";
export const EXPORT_CLAIMS = "/Claim/ExportClaims";
export const ATTRIBUTESLIST = "CustomAttribute/ListByCase?CaseId=";
export const CREATEATTRIBUTE = "CustomAttribute/Create";
export const ATTRIBUTEBYID = "CustomAttribute/ReadById?Id=";
export const UPDATEATTRIBUTE = "CustomAttribute/Update";
export const DELETEATTRIBUTE = "CustomAttribute/Delete";
export const LIST_ATTRIBUTES_BY_ENTITY = "CustomAttribute/ListByEntityType?";
export const CLAIMITEMINCASESUMMARY =
  "Claim/GetClaimSummeryClaimItemDetails?CaseId=";
export const TASKCASESUMMARY = "ClaimTask/ListClaimTasksByClaim";
export const DOCUMENTCASESUMMARY = "Claim/GetClaimSummeryDocumentDetails?";
export const COMMENTHISTORY = "Comment/ListCommentsByEntity?";
export const CLAIMSUMMARY = "Claim/GetClaimDetailsByClaimId?ClaimId=";
export const GETUNITBYCASEID = "Unit/GetUnitByCaseId?caseId=";
export const GETSTAGES = "ClaimStage/ListClaimStagesByCase?CaseId=";
export const CREATESTAGE = "ClaimStage/CreateClaimStage";
export const UPDATESTAGE = "ClaimStage/UpdateClaimStage";
export const CREATESTATUS = "ClaimStage/CreateClaimStatus";
export const UPDATESTATUS = "ClaimStage/UpdateClaimStatus";
export const BASICSEARCHCREDITORLIST =
  "Creditor/GetCreditorBasicSearchList?CaseId=";
export const DEBTORQUICKSEARCH = "Debtor/GetDebtorListByCase?CaseID=";
export const CREDITORQUICKSEARCH =
  "Creditor/GetCreditorSearchList?Type=quick search&CaseId=";
export const LIST_CLAIM_STAGES_BY_CASE =
  "ClaimStage/ListClaimStagesByCase?CaseId=";
export const DEBTOREDITVIEW = "Debtor/GetDebtorById/";
export const GETCLAIMCATEGORYNATUREOFCLAIM =
  "Claim/GetClaimCategoryAndNatureOfClaimList?CaseId=";
export const GETCLAIMITEMDETAILS =
  "/Claim/GetClaimSummeryClaimItemDetails?CaseId=";
export const GETCLAIMITEM = "/Claim/GetClaimItem?claimItemId=";
export const CLAIMSTAGEANDSTATUS = "Claim/GetClaimStageAndStatus?claimId=";
export const CREDITOR_CONTACT = "CreditorContact/ListCreditorContact";
export const GETCLAIMSTAGESTATUS = "Claim/GetClaimStageAndStatus?claimId=";
export const GETVALIDSTAGEMOVES = "Claim/ListClaimValidStageStatusMoves";
export const UPDATECLAIMSTAGESTATUS = "Claim/UpdateExistingClaimStageAndStatus";
export const CREATECLAIMSTAGESTATUS = "Claim/SetClaimStageAndStatus";
export const DOWNLOADDOCUMENT = "Document/Download";
export const CREDITORCONTACTS = "CreditorContact/ListCreditorContact/";
export const CREDITORCONTACTSDATA = "CreditorContact/ReadCreditorContact/";
export const CREATECONTACT = "Comment/CreateComment";
export const CLAIM_CATEGORY_NATURE_OF_CLAIM =
  "Claim/GetClaimCategoryAndNatureOfClaimList?CaseId=";
export const DEFAULTUNITBYCASE = "Unit/GetDefaultUnitByCaseId?caseId=";
export const CATEGORYCONFIG =
  "ClaimItemCategory/ListClaimItemCategoriesByCase?CaseId=";
export const LIST_PROVINCE_STATES_BY_COUNTRY =
  "Common/ListProvinceStatesByCountry?CountryId=";
export const GETSINGLECATEGORY = "ClaimItemCategory/ReadClaimItemCategory?id=";
export const GETACCOUNTLIST = "Account/GetAccountListByCase?CaseId=";
export const GETSUBACCOUNTLIST =
  "Account/GetSubAccountListByAccount?AccountId=";
export const GETACCOUNTDATABYID = "Account/ReadAccountById?accountId=";
export const GETSUBACCOUNTDATABYID = "Account/ReadSubAccountById?subAccountId=";
export const GETTASKBYID = "ClaimTask/ReadClaimTask?ClaimTaskId=";
export const CLAIM_BASIC_SEARCH = "Claim/GetClaimBasicSearchList";
export const GEt_CLAIM_ATTRIBUTE_HISTORY =
  "Claim/GetClaimAttributeHistoryList?ClaimId=";
export const GETCLAIMSTATUS =
  "ClaimStage/ReadClaimStatus?ClaimStatusDefinitionId=";
export const LIST_DOCUMENT_TYPES = "Document/ListDocumentTypes";
export const UPLOAD_DOCUMENT = "Document/Upload";
export const UPDATE_DOCUMENT = "Document/Update";
export const DELETE_DOCUMENT = "Document/Delete";
export const LIST_SCHEMES_BY_CASE = "Scheme/ListSchemesByCase?CaseId=";
export const DELETE_UNIT = "Unit/DeleteUnit?unitId=";
export const CREDITOR_ATTRIBUTE_HISTORY =
  "Creditor/GetCreditorAttributeHistoryList?CreditorId=";
export const GET_CLAIMS_HISTORY = "Claim/ListClaimHistory?ClaimId=";
export const CREDITOR_CLAIM_ITEM_HISTORY =
  "Claim/ListClaimItemHistory?ClaimHistorySnapshotId=";
export const LIST_BALLOT_BY_CASEID = "Ballot/ListBallotByCaseId?caseId=";
export const LIST_DISTIBUTION_BY_CASEID =
  "Distribution/GetDistributionList?CaseId=";
export const GET_DISTRIBUTION_SUMMARY_BY_ID =
  "Distribution/GetDistributionSummaryById?Id=";
export const CREATEDISTRIBUTION = "Distribution/GetDistributionSummaryById?Id=";
export const LIST_CALCULATION_STEPS =
  "CalculationStep/ListCalculationSteps?distributionId=";
export const LISTEFFECTIVESCHEMEBYCADEID =
  "Scheme/ListEffectiveSchemesByCase?caseId=";
export const LISTCLASSBYSCHEMEVERSIONID =
  "Class/ListClassBySchemeVersion?schemeVersionId=";
export const LISTOFMEETING = "Ballot/ListMeeting?caseId=";
export const READBALLOT = "/Ballot/ReadBallot?ballotId=";
export const LIST_VOTES_BY_BALLOT_ID = "Ballot/ListCreditorVote?ballotId=";
export const CREDITOR_LIST_BY_BALLOT_CLASS =
  "Ballot/GetCreditorListByBallotClass?BallotId=";
export const CREATE_CREDITOR_VOTE = "/Ballot/CreateCreditorVote";
export const LISTOFSTEPCALCULATION =
  "CalculationStep/GetStepCalculationSummary?stepCalculationId=";
export const DELETE_COMMENT = "Comment/DeleteComment";
export const UPDATE_COMMENT = "Comment/UpdateComment";
export const BULKIMPORTLIST = "Import/ReadCaseLevelBulkImportList?CaseId=";
export const IMPORTSUMMARYHEADER =
  "Import/ReadImportSummaryHeader?ImportJobId=";
export const READIMPORTSUMMARY = "Import/ReadImportSummaryList?ImportJobId=";
export const UPLOAD_MANUAL_DATA_SOURCE_DOCUMENT = "Document/BulkImport";
