import {
  MotifButton,
  MotifButtonGroup,
  MotifChip,
  MotifDropdown,
  MotifDropdownItem,
  MotifDropdownPortal,
  MotifFormField,
  MotifIcon,
} from "@ey-xd/motif-react";
import {
  IconoirMoreVert,
  IconoirNavArrowDown,
  IconoirPlus,
} from "@ey-xd/motif-icon";
import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  useExportAdvanceSearchMutation,
  useLazyExportDocumentQuery,
} from "@/services/exports/exportsReducer";
import CreditorBulkImport from "./CreditorBulkImport";
import SearchInput from "@/components/SearchInput/SearchInput";
import { useDispatch } from "react-redux";
import { resetCreditorSetup, updateStage } from "@/pages/CaseDetail/Creditor/CreditorSetup/creditorSetupSlice";

interface TitleComplementProps {
  caseId: string | undefined;
  setShowBasicSearch: any;
  showBasicSearch: any;
  clearBasicSearch: any;
  clearSearch: any;
  setClearSearch: any;
  onSearch: (searchValue: string) => void;
  handleQuickSearchClear: () => void;
  searchQuery: string;
  setShowAdvanceSearch: (isVisible: boolean) => void;
  showAdvanceSearch: boolean;
  basicSearchPayload: any;
  isBasicSearchActive: boolean;
  advanceSearchPayload: any;
  isAdvanceSearch: boolean;
  resetSortStatus?: () => void;
}

export default function TitleComplement({
  setShowBasicSearch,
  showBasicSearch,
  clearBasicSearch,
  clearSearch,
  setClearSearch,
  onSearch,
  caseId,
  handleQuickSearchClear,
  searchQuery,
  showAdvanceSearch,
  setShowAdvanceSearch,
  basicSearchPayload,
  isBasicSearchActive,
  advanceSearchPayload,
  isAdvanceSearch,
  resetSortStatus
}: TitleComplementProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [exportDocument] = useLazyExportDocumentQuery();
  const [exportAdvanceSearch] = useExportAdvanceSearchMutation();
  const [showMenu, setShowMenu] = useState(false);
  const [searchValue, setSearchValue] = useState(searchQuery);
  const searchDropMenu = ["pages.creditors.searchOptions.basicSearch", "pages.creditors.searchOptions.advancedSearch"];
  const debtorTitleMenu = ["pages.creditors.moreOptions.export","pages.creditors.moreOptions.bulkUpload"];// "pages.creditors.moreOptions.bulkStatusUpdate", Removed bulk status update button temporarily
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const handleDownloadBulkImportTemplate = () => {
    // downloadDocument({
    //      entityType:"4"
    //     });
  };

  // const creditorTitleMenu = [
  //   "Bulk Upload",
  //   "Add New Creditor",
  //   // "Bulk Status Update",
  //   // "Export",
  // ];
  const navigate = useNavigate();
  const handleSearchChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearchValue(event.target.value);
  };

  function handleOnSearch() {
    onSearch(searchValue);
  }

  function basicSearch(getIndex: number) {
    if (getIndex === 0) {
      handleQuickSearchClear();
      setShowBasicSearch(true);
      setShowAdvanceSearch(false);
    } else {
      setShowBasicSearch(false);
      setShowAdvanceSearch(true);
    }
  }

  const handleMenuClick = (indexNo:number) => {
    if (indexNo === 0) {
      if (isAdvanceSearch && !searchQuery) {
        exportAdvanceSearch({
          url: "/CreditorAdvancedSearch/ExportCreditorsAdvancedSearch",
          data: advanceSearchPayload,
        })
          .unwrap()
          .catch((error) => {
            console.error("Download failed", error);
          });
        return;
      }
      exportDocument(
        isBasicSearchActive && !searchQuery
          ? {
              url: "/Creditor/ExportCreditors",
              ...basicSearchPayload,
            }
          : {
              url: "/Creditor/ExportCreditorslistAndQuickSearch",
              caseId,
              SearchText: searchQuery,
            }
      )
        .unwrap()
        .catch((error) => {
          console.error("Download failed", error);
        });
      return;
    }
    if (indexNo === 1) {
      setShowBulkUploadModal(true);
      return;
    }
   
  };
  return (
    <>
      <div
        className={
          showBasicSearch ? "show-and-hide-quicksearch" : "show-quick-search"
        }
      >
        <MotifFormField>
          <div
            style={
              !showBasicSearch || !showAdvanceSearch
                ? { visibility: "visible" }
                : { visibility: "hidden" }
            }
          >
            {!showBasicSearch && !showAdvanceSearch ? (
              <SearchInput
                aria-label="Search"
                onChange={handleSearchChange}
                placeholder={t("global.globalPlaceholder.quickSearchPlaceHolder")}
                onFocusShow={false}
                value={searchQuery}
                onEnter={handleOnSearch}
                onClear={()=>{
                  handleQuickSearchClear();
                  if(resetSortStatus){
                  resetSortStatus()
                  }
                
                }}
              />
            ) : (
              ""
            )}
          </div>
          <MotifButtonGroup>
            <MotifButton variant="secondary" onClick={handleOnSearch}>
              {t("global.globalPlaceholder.quickSearchPlaceHolder")}
            </MotifButton>
            <MotifDropdownPortal
              open={showMenu}
              hideArrow={true}
              handleClickOutside={() => setShowMenu(false)}
              trigger={
                <MotifButton
                  type="button"
                  onClick={() => setShowMenu(!showMenu)}
                  aria-label="Dropdown button"
                  aria-haspopup="true"
                  variant="secondary"
                >
                  <MotifIcon
                    aria-label="Dropdown icon"
                    fill="none"
                    iconFunction={IconoirNavArrowDown}
                  />
                </MotifButton>
              }
            >
              {searchDropMenu.map((dropMenu: string, index: number) => {
                return (
                  <MotifDropdownItem onClick={() => basicSearch(index)}>
                    {t(dropMenu)}
                  </MotifDropdownItem>
                );
              })}
            </MotifDropdownPortal>
          </MotifButtonGroup>
          {clearSearch ? (
            <MotifChip
              onClick={() => {
                clearBasicSearch();
                setClearSearch(false);
              }}
              title="Clear Search"
              variant="monochrome-dark"
            >
              {t("global.globalActions.clearSearch")}
            </MotifChip>
          ) : (
            ""
          )}
          <MotifDropdown
            ariaLabelledby="dropdown-trigger-1"
            id="dropdown-1"
            trigger={
              <MotifButton
                aria-label="Search Dropdown"
                id="dropdown-trigger-1"
                onClick={function Ga() {}}
                type="button"
                variant="text"
              >
                <MotifIcon iconFunction={IconoirMoreVert} />
              </MotifButton>
            }
          >
            <ul role="menu">
              {debtorTitleMenu.map((menuName: string,indexNo:number) => {
                return (
                  <li role="menuitem">
                    <MotifDropdownItem
                      indexItem={1}
                      onClick={() => handleMenuClick(indexNo)}
                    >
                      {t(menuName)}
                    </MotifDropdownItem>
                  </li>
                );
              })}
            </ul>
          </MotifDropdown>
          <div style={{"marginTop":"14px","cursor":"pointer","opacity":0.8}}  onClick={()=>{
                  navigate(`/case/${caseId}/creditor/creditor-setup`);
                  dispatch(resetCreditorSetup());
                  dispatch(updateStage(0));
                }}>
                <MotifIcon size="19" iconFunction={IconoirPlus} />
                </div>
          {/* <MotifDropdown
            ariaLabelledby="dropdown-trigger-1"
            id="dropdown-1"
            trigger={
              <MotifButton
                aria-label="Search Dropdown"
                id="dropdown-trigger-1"
                onClick={function Ga() {}}
                type="button"
                variant="text"
                
              >
                <MotifIcon iconFunction={IconoirPlus} />
              </MotifButton>
            }
          >
            <ul role="menu">
              {creditorTitleMenu.map((menuName: string, index: number) => {
                return (
                  <li role="menuitem">
                    <MotifDropdownItem
                      indexItem={1}
                      onClick={() => handleMenuClick(menuName)}
                    >
                      {t(menuName)}
                    </MotifDropdownItem>
                  </li>
                );
              })}
            </ul>
          </MotifDropdown> */}
        </MotifFormField>
      </div>
      {showBulkUploadModal && (
        <CreditorBulkImport
          showModal={true}
          setShowModal={setShowBulkUploadModal}
          title={t("pages.creditors.creditorBulkImport.title")}
          description={t("pages.creditors.creditorBulkImport.description")}
          onDownload={handleDownloadBulkImportTemplate}
          uploadLabel={t("pages.creditors.creditorBulkImport.uploadLabel")}
          uploadDescription={t("pages.creditors.creditorBulkImport.uploadDescription")}    
          accept=".pdf,.jpg,.jpeg,.doc,.docx"
          maxFiles={1}
          //  ballotId={editId || ""}
          onClose={() => {
            setShowBulkUploadModal(false);
          }}
          //  setBulkimportFileStatus={setBulkimportFileStatus}
        />
      )}
    </>
  );
}
