import { useParams } from "react-router-dom";
import {
	MotifProgressLoader,
	MotifErrorMessage,
} from "@ey-xd/motif-react";
import Table from "@/components/Table/table";
import { useGetClaimItemClassificationListQuery } from "@/services/claimSummary/claimItem";
import { useTranslation } from "react-i18next";

const Classification = () => {
	const {t}=useTranslation()
	const { claimItemId } = useParams();
	const { data, error, isLoading } = useGetClaimItemClassificationListQuery(
		claimItemId ?? ""
	);

	if (isLoading) {
		return <MotifProgressLoader show variant="default" />;
	}
	if (error) {
		<MotifErrorMessage>{t("global.globalNotifications.wentWrong")}</MotifErrorMessage>;
	}
	const columnDefs = [
		{
			headerName: t("pages.claims.claimItemsClassificationTab.tableColumnsDetails.column1"),
			field: "schemeName",
			sortable: true,
			unSortIcon: true,
			flex: 2,
		},
		{
			headerName: t("pages.claims.claimItemsClassificationTab.tableColumnsDetails.column2"),
			field: "className",
			sortable: true,
			flex: 2,
		},
	];

	return (
		<Table
			columnDefs={columnDefs}
			cellRenderers={data}
			TitleComplement={() => <></>}
			title={"pages.claims.claimItemsClassificationTab.tableTitle"}
			description={"pages.claims.claimItemsClassificationTab.tableDescription"}
			pagination={false}
			overlayNoRowsTemplate={t("global.globalNotifications.noRecords")}
		/>
	);
};
export default Classification;
