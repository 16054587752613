import { dateFunc } from "@/components/Functions/dateFunc";
import { useTranslation } from "react-i18next";

export const useGetColumnDefsManualDataSource = (timeZone: string) => {
  const {t} = useTranslation();
  return [
    {
      field: "manualDataSourceName",
      type:"manualDataSourceNameType",
      headerName: t("pages.distributions.manualDataSource.columnDetails.column1"),
      comparator: () => 0,
      flex: 2,
      unSortIcon: true,
      cellRenderer: "editCellRenderer",
    },
    {
      field: "distributionNames",
      type:"distributionNamesType",
      headerName: t("pages.distributions.manualDataSource.columnDetails.column2"),
      comparator: () => 0,
      flex: 2,
      unSortIcon: true,
      valueGetter: (params: any) =>
        params.data?.distributionNames,
    },
    {
      field: "totalRecords",
      type:"totalRecordsType",
      headerName: t("pages.distributions.manualDataSource.columnDetails.column3"),
      comparator: () => 0,
      flex: 1,
      sortable: true,
      unSortIcon: true,
      cellClass: 'align-right',
      headerClass: 'align-right',
      valueGetter: (params: any) => params.data.totalRecords,
    },
    {
      field: "updatedOn",
      comparator: () => 0,
      type:"updatedOnType",
      headerName: t("pages.distributions.manualDataSource.columnDetails.column4"),
      flex: 3,
      sortable: true,
      unSortIcon: true,
      cellRendererParams: {
        timeZoneName: timeZone, 
      },
      cellRenderer: function (params: any) {
        return dateFunc(params.data.updatedOn).formattedDateTime + " " + params.timeZoneName;
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 2,
      sortable: false,
      cellRenderer: "actionRenderer",
      cellStyle: { justifyContent: "right" },
    },
  ]};
  