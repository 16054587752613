import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  MotifTabNavigation,
  MotifTabControl,
  MotifIcon,
} from "@ey-xd/motif-react";

import AttributesManagement from "./Attributes/AttributesManagement";
import UnitConversionManagement from "./Units/UnitConversionManagement";
import StageManagement from "./Stages/StageManagement";
import "./Configuration.css";
import { ConfigurationTab } from "./ConfigurationType";
import { setCurrentTab } from "./ConfigurationSlice";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import { CategoryGrid } from "./category/category";
import Schemes from "./Schemes/Schemes";
import CaseTimeZones from "./CaseTimeZones/caseTimeZones";

export default function Configuration() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTab = useSelector(
    (state: any) => state.configuration.currentTab
  );

  const renderTabContent = () => {
    switch (selectedTab) {
      case ConfigurationTab.Units:
        return <UnitConversionManagement />;
      case ConfigurationTab.Schemes:
        return <Schemes />;
      case ConfigurationTab.Attributes:
        return <AttributesManagement />;
      case ConfigurationTab.Stages:
        return <StageManagement />;
      case ConfigurationTab.Category:
        return <CategoryGrid />;
      case ConfigurationTab.CaseTimeZones:
        return <CaseTimeZones />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {window.location.pathname.includes("/configuration") && (
        <Breadcrumb
          items={[
            {
              text: t("pages.configuration.attributes.backButton"),
              url: "/",
              icon: <MotifIcon src={IconoirArrowLeft} />,
            },
          ]}
        />
      )}
      <div className="configuration-container">
        <MotifTabNavigation
          defaultActiveKey={selectedTab}
          orientation="vertical"
          controlled={true}
        >
          <MotifTabControl
            className={
              selectedTab === ConfigurationTab.Units ? "motif-active" : ""
            }
            key={ConfigurationTab.Units}
            onClick={() => dispatch(setCurrentTab(ConfigurationTab.Units))}
          >
            {t("pages.configuration.units.title")}
          </MotifTabControl>

          <MotifTabControl
            className={
              selectedTab === ConfigurationTab.Schemes ? "motif-active" : ""
            }
            key={ConfigurationTab.Schemes}
            onClick={() => dispatch(setCurrentTab(ConfigurationTab.Schemes))}
          >
            {t("pages.configuration.schemes.title")}
          </MotifTabControl>

          <MotifTabControl
            className={
              selectedTab === ConfigurationTab.Attributes ? "motif-active" : ""
            }
            key={ConfigurationTab.Attributes}
            onClick={() => dispatch(setCurrentTab(ConfigurationTab.Attributes))}
          >
            {t("pages.configuration.attributes.title")}
          </MotifTabControl>

          <MotifTabControl
            className={
              selectedTab === ConfigurationTab.Stages ? "motif-active" : ""
            }
            key={ConfigurationTab.Stages}
            onClick={() => dispatch(setCurrentTab(ConfigurationTab.Stages))}
          >
            {t("pages.configuration.stages.title")}
          </MotifTabControl>
          <MotifTabControl
            className={
              selectedTab === ConfigurationTab.CaseTimeZones ? "motif-active" : ""
            }
            key={ConfigurationTab.CaseTimeZones}
            onClick={() => dispatch(setCurrentTab(ConfigurationTab.CaseTimeZones))}
          >
            {t("pages.configuration.caseTimeZones.title")}
          </MotifTabControl>
          <MotifTabControl
            className={
              selectedTab === ConfigurationTab.Category ? "motif-active" : ""
            }
            key={ConfigurationTab.Category}
            onClick={() => dispatch(setCurrentTab(ConfigurationTab.Category))}
          >
            {t("pages.configuration.category.title")}
          </MotifTabControl>
        </MotifTabNavigation>

        <div className="configuration-tab-content">{renderTabContent()}</div>
      </div>
    </>
  );
}
