import { ccwpApi } from "@/services";
import {userJsonType, usersRequestType } from "./type";
export const caseInviteUserApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    getCaseGroupUsersList: builder.mutation<any,Partial<usersRequestType>>({
      query: (searchData) => ({
        url: "/UserCaseGroup/SearchUser",
        method: "POST",
        body: searchData,
      }),
      transformResponse: (response:any) => response,
    }),
    createInviteUser: builder.mutation<any,Partial<userJsonType>>({
      query: (searchData) => ({
        url: "/UserCaseGroup/CreateUserCaseGroup",
        method: "POST",
        body: searchData,
      }),
      transformResponse: (response) => response,
    }),
    getCaseUsersList: builder.query({
      query: (caseId:string) => ({
        url: `/UserCaseGroup/GetUserCaseGroupList?caseId=${caseId}`,
        method: "GET",
       
      }),
    }),
    deleteUserFromCase: builder.mutation({
      query: (userId:string) => ({
        url: `/UserCaseGroup/DeleteUserCaseGroup?id=${userId}`,
        method: "DELETE",
       
      }),
    }),
    updateCaseInviteRole: builder.mutation({
      query: (userData) => ({
        url: "/UserCaseGroup/UpdateUserCaseGroup",
        method: "POST",
        body:userData
       
      }),
    }),
    
  }),
  
  
});

export const {useGetCaseGroupUsersListMutation,useCreateInviteUserMutation,useDeleteUserFromCaseMutation,useGetCaseUsersListQuery,useUpdateCaseInviteRoleMutation}=caseInviteUserApi;