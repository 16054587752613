import React, { useEffect, useState } from 'react';
import { MotifErrorMessage, MotifModal, MotifModalBody, MotifModalFooter, MotifModalHeader } from '@ey-xd/motif-react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Button from '@/components/Form/Button/Button';
import { CANCEL, REQUIRED_MESSAGE, SAVE, SECONDARY } from '@/Constant';
import { ADD_DEBTOR_BUTTON } from '@/pages/CaseDetail/CaseSetup/Constants';
import { AddDebtorFormType } from './type';
import "./FormModal.scss";
import { DEBTOR_DESCRIPTION, DEBTOR_DESCRIPTION_LABEL, LEGAL_DEBTOR_LABEL, LEGAL_DEBTOR_NAME } from './constants';
import ControlInputAddDebtor from './ControlInputAddDebtor';
import { capitalizeWordsInSentence } from '@/utility/common';
import { isEmptyOrSpaces } from "@/utility/validation";
import ControlTextAreaWithCharLimit from '@/components/Form/InputBox/ControlTextAreaWithCharLimit';

type Props = {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setPostData: (data: AddDebtorFormType, create: boolean) => void;
  create: boolean;
  formData?: AddDebtorFormType | null;
}

export default function FormModal({isVisible, setIsVisible,  setPostData, create, formData}: Props) {
  const {t} = useTranslation();
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
    message: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const defaultValuesObj = {
      legalDebtorName: "",
      debtorDescription: "",
  }

  const { handleSubmit, control, setValue, watch, formState: { errors }} = useForm<AddDebtorFormType>({
    defaultValues: defaultValuesObj
  });

  const errorList = Object.keys(errors);
  const errorsShow = capitalizeWordsInSentence(errorList.join(", "));

  const onSubmit = async (data:AddDebtorFormType) => {
    const { legalDebtorName, debtorDescription } = data;

    if (isEmptyOrSpaces(legalDebtorName) || isEmptyOrSpaces(debtorDescription)) {
      setShowMessage({
        show: true,
        type: "error",
        message: t(`${REQUIRED_MESSAGE} Legal Debtor Name, Debtor Description cannot be empty or contain only spaces.`),
      });
      return;
    }

    setIsLoading(true)
    setShowMessage({ show: false, type: "error", message: '' }); // Clear previous error message
    setPostData(data, create);
    setIsVisible(false);
    setValue(LEGAL_DEBTOR_NAME, "");
    setValue(DEBTOR_DESCRIPTION, "");
    setIsLoading(false)
  }

  useEffect(() => {
    if (isVisible  && !create) {
      setValue(LEGAL_DEBTOR_NAME, formData?.legalDebtorName || "");
      setValue(DEBTOR_DESCRIPTION, formData?.debtorDescription || "");
    } else {
      setValue(LEGAL_DEBTOR_NAME, defaultValuesObj?.legalDebtorName || "");
      setValue(DEBTOR_DESCRIPTION, defaultValuesObj?.debtorDescription || "");
    }
    //eslint-disable-next-line
  }, [isVisible, create, formData, defaultValuesObj, watch]);
  return (
  <>
    <MotifModal
      onClose={() => setIsVisible(false)}
      show={isVisible}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        }
      }}
    >
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='formModalWrapper'>
       <MotifModalHeader>
          <span className='discard_heading'>{t(ADD_DEBTOR_BUTTON)}</span>
        </MotifModalHeader>
        <MotifModalBody>
          <div className='formModal'>
            <div className="motif-col-md-12 heading">
            </div>
            <div className="motif-col-md-12 field-item">
                <div className='form-field'>
                  <ControlInputAddDebtor name={LEGAL_DEBTOR_NAME} control={control}  isTextArea={false} label={LEGAL_DEBTOR_LABEL}  showLabel={true} placeholder='Legal Debtor Name' required={true}/>
                </div>
            </div>
            <div className="motif-col-md-12 field-item">
                <div className='form-field'> 
                  <ControlTextAreaWithCharLimit
                      name={DEBTOR_DESCRIPTION} 
                      control={control} 
                      label={DEBTOR_DESCRIPTION_LABEL}  
                      isTextArea={true}   
                      showLabel={true} 
                      required={true}
                    />
                </div>
            </div>
            <div className='seperator'></div>
          </div>
          <div className="error-container">
          <MotifErrorMessage>
            {showMessage.show && showMessage.message}
            {errorList.length > 0 && t(`${REQUIRED_MESSAGE} ${errorsShow} cannot be left blank.`)}
          </MotifErrorMessage>
        </div>
        </MotifModalBody>
        <MotifModalFooter>
        <div className="button-container-add">
          <Button label={t(SAVE)}  disabled={isLoading}/>
          <Button label={t(CANCEL)} type='button' variant={SECONDARY} className="cancelBtn" onClickHandler={()=> setIsVisible(false)} />
        </div>
        </MotifModalFooter>
      </div>
      </form>
    </MotifModal>
  </>
)}

