import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifFormField,
  MotifLabel,
  MotifInput,
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifIcon,
} from "@ey-xd/motif-react";
import {
  actionIcDescription24px,
  editorIcVerticalAlignBottom24px,
} from "@ey-xd/motif-react/assets/icons";
import "./ManualDataSourceUploader.css";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import DataSourceUploadStatus from "../../Distributions/ManualDataSource/UploadStatus/DataSoureUploadStatus";
import SuccessMessage from "../../Distributions/ManualDataSource/SuccessMessage/SuccessMessage";
import ErrorMessage from "../../Distributions/ManualDataSource/ErrorMessage/ErrorMessage";
import { useCase } from "@/features/case/use-case";
import {
  useLazyDownloadTemplateDocumentQuery,
  useUploadManualDataSourceDocumentMutation,
  useLazyGetFileImportProgressStatusQuery,
  useCreateManualDataSourceMutation,
} from "@/services/manualDataSource/ManualDataSourceReducer";
import { fileExtensions } from "@/services/documents/documentsReducer";

export default function ManualDataSourceUploader({
  show,
  setShow,
  entityType = 5,
}: {
  show: boolean;
  setShow: (isVisible: boolean) => void;
  entityType?: number;
}) {
  const { t } = useTranslation();
  const caseData = useCase();
  const { caseId } = caseData;

  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [showDiscardMessage, setShowDiscardMessage] = useState(false);
  const [name, setName] = useState("");
  const [nameFrench, setNameFrench] = useState("");
  const [comment, setComment] = useState("");
  const [uploadStatus, setUploadStatus] = useState<any>(null);
  const [showProgress, setShowProgress] = useState(false);
  const [importJobId, setImportJobId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [downloadTemplate] = useLazyDownloadTemplateDocumentQuery();
  const [uploadDocument] = useUploadManualDataSourceDocumentMutation();
  const [getFileImportProgressStatus, { data: importStatusData }] =
    useLazyGetFileImportProgressStatusQuery();
  const [createManualDataSource] = useCreateManualDataSourceMutation();

  const handleFileChange = (files: any) => {
    const file = files[0];
    if (file) setFile(file);
  };

  const handleSubmit = async () => {
    try {
      setIsUploading(true);

      const createResponse = await createManualDataSource({
        caseId,
        name,
        nameFrench,
        comment,
        isActive: true,
      }).unwrap();

      const entityId = createResponse;

      const formData = new FormData();
      formData.append("CaseId", caseId);
      formData.append("DisplayName", name);
      formData.append("NameFrench", nameFrench);
      formData.append("Comment", comment);
      formData.append("EntityId", entityId);
      formData.append("EntityType", "5");

      if (file) formData.append("file", file);

      const uploadResponse = await uploadDocument(formData).unwrap();
      const jobId = uploadResponse;
      setImportJobId(jobId);

      await getFileImportProgressStatus({ importJobId: jobId });
      setShowProgress(true);
      setUploadStatus("inProgress");
    } catch (error: any) {
      setUploadStatus("error");
      setErrorMessage(error?.data ?? undefined);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (
      showProgress &&
      importJobId &&
      importStatusData?.statusName !== "Success"
    ) {
      intervalId = setInterval(() => {
        getFileImportProgressStatus({ importJobId });
      }, 10000);
    }

    if (importStatusData?.statusName === "Failed") {
      setUploadStatus("error");
      setErrorMessage(importStatusData?.errorMessage);
      setShowProgress(false);
    } else if (importStatusData?.statusName === "Success") {
      setUploadStatus("Success");
      setShowProgress(false);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [
    showProgress,
    importJobId,
    importStatusData,
    getFileImportProgressStatus,
  ]);

  const handleDownloadTemplate = () => {
    downloadTemplate({ entityType });
  };

  const shouldHideButtons =
    uploadStatus === "inProgress" ||
    uploadStatus === "Success" ||
    uploadStatus === "error";

  return (
    <>
      <MotifModal
        className="upload-edit-document-container"
        show={show}
        onClose={() => {
          if (uploadStatus === "Success" || uploadStatus === "error" || uploadStatus === "inProgress" || showProgress) {
            setShow(false);
          } else if (file || name || nameFrench || comment) {
            setShowDiscardMessage(true);
          } else {
            setShow(false);
          }
        }}
        focusTrapOptions={{ tabbableOptions: { displayCheck: "none" } }}
      >
        <MotifModalHeader>{t("pages.distributions.manualDataSource.dataSourceUploader.title")}</MotifModalHeader>
        <MotifModalBody>
          {uploadStatus === "Success" ? (
            <SuccessMessage />
          ) : uploadStatus === "error" ? (
            <ErrorMessage errorMessage={errorMessage} />
          ) : !showProgress ? (
            <>
              <div className="motif-row document-inputs">
                <div className="motif-col-md-2 motif-col-lg-4">
                  <MotifLabel>
                    {t("pages.distributions.manualDataSource.dataSourceUploader.labels.name")}
                    <span className="required-input">*</span>
                  </MotifLabel>
                  <MotifFormField>
                    <MotifInput
                      //@ts-ignore
                      onChange={(e: any) => setName(e.target.value)}
                      value={name}
                    />
                  </MotifFormField>
                </div>
                <div className="motif-col-md-2 motif-col-lg-4">
                  <MotifLabel>
                  {t("pages.distributions.manualDataSource.dataSourceUploader.labels.nameFrench")}
                  </MotifLabel>
                  <MotifFormField>
                    <MotifInput
                      //@ts-ignore
                      onChange={(e: any) => setNameFrench(e.target.value)}
                      value={nameFrench}
                    />
                  </MotifFormField>
                </div>
                <div className="motif-col-md-2 motif-col-lg-4">
                  <MotifLabel>
                  {t("pages.distributions.manualDataSource.dataSourceUploader.labels.comment")}
                  </MotifLabel>
                  <MotifFormField>
                    <MotifInput
                      //@ts-ignore
                      onChange={(e: any) => setComment(e.target.value)}
                      value={comment}
                    />
                  </MotifFormField>
                </div>
              </div>

              <div className="motif-container case-summary-form pad-v-20 mar-t-20">
                <div className="motif-row justify-content-between align-items-center">
                  <div className="motif-col-4">
                    <div className="motif-body1-default-regular page-title">
                      {t("pages.distributions.manualDataSource.dataSourceUploader.templateTitle")   }
                    </div>
                    <div className="motif-body2-default-regular page-description">
                      {t("pages.distributions.manualDataSource.dataSourceUploader.templateDescription")   }
                    </div>
                  </div>
                  <div>
                    <MotifButton
                      onClick={handleDownloadTemplate}
                      size="small"
                      type="submit"
                      variant="primary"
                    >
                      <MotifIcon
                        fill="none"
                        iconFunction={editorIcVerticalAlignBottom24px}
                      />
                    </MotifButton>
                  </div>
                </div>
              </div>

              <div className="document-uploader">
                <MotifFileUploader
                  label={t("pages.distributions.manualDataSource.dataSourceUploader.uploadText"   )}
                  labelDescription={t(
"pages.distributions.manualDataSource.dataSourceUploader.uploadDescription"                  )}
                  onDrop={handleFileChange}
                  maxFiles={1}
                  accept={fileExtensions.import}
                >
                  {file && (
                    <MotifFileUploaderItem
                      fileIcon={<MotifIcon src={actionIcDescription24px} />}
                      fileName={file.name}
                      uploaded={true}
                      uploadedText={t(
                        "pages.distributions.manualDataSource.dataSourceUploader.displayName",{fileName:name}
                      )}
                      error={
                        (file && file.size >= fileExtensions.maxFileSize) ||
                        false
                      }
                      errorText={t(
                       "pages.distributions.manualDataSource.dataSourceUploader.filesizeExceedsLimit" ,
                        {
                          filesize: "250",
                        }
                      )}
                    />
                  )}
                </MotifFileUploader>
              </div>
            </>
          ) : (
            <DataSourceUploadStatus importStatus={importStatusData} />
          )}
        </MotifModalBody>
        <MotifModalFooter>
          {!shouldHideButtons && (
            <>
              <MotifButton
                onClick={() => {
                  if (file || name || nameFrench || comment) {
                    setShowDiscardMessage(true);
                  } else {
                    setShow(false);
                  }
                }}
                variant="secondary"
              >
                {t("global.globalActions.cancel")}
              </MotifButton>
              <MotifButton
                onClick={handleSubmit}
                variant="primary"
                type={isUploading ? "loader" : "submit"}
                disabled={
                  !name ||
                  !file ||
                  (file && file.size > fileExtensions.maxFileSize) ||
                  isUploading
                }
              >
                {isUploading ? t("global.globalActions.uploading") : t("global.globalActions.upload")}
              </MotifButton>
            </>
          )}
        </MotifModalFooter>
      </MotifModal>

      <ConfirmationModal
        isVisible={showDiscardMessage}
        setIsVisible={setShowDiscardMessage}
        cancelBtnApproveHandler={() => setShow(false)}
        cancelBtnRejectHandler={() => setShowDiscardMessage(false)}
      />
    </>
  );
}
