import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  MotifButton,
  MotifFormField,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";

import { useForm } from "react-hook-form";
import ControlInputBox from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import ControlSelect from "@/components/Form/SelectBox/ControlSelect";
import logger from "@/libs/logger";
import {
  useCreateSectionMutation,
  SectionMapping,
  SectionRequestBody,
} from "@/services/formSetup/formSetupReducers";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { setForm, setNotification } from "@/features/form-setup/formSetupSlice";

type SectionPopupProps = {
  show: boolean;
  setShow: (show: boolean) => void;
};

type SectionFormData = {
  displayName: string;
  displayNameFR: string;
  description: string;
  selectMapping: string;
};

export const SectionPopup = ({
  show = false,
  setShow = () => {},
}: SectionPopupProps) => {
  const { t } = useTranslation();
  const { caseId, editId: formId } = useParams();
  const dispatch = useDispatch();
  const formSetup = useSelector((state: any) => state.formSetup);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm<SectionFormData>();
  const [confirmationVisible, setConfirmationVisible] =
    useState<boolean>(false);

  const [createSection] = useCreateSectionMutation();

  const mappingOptions = [
    {
      label: t("pages.portalConfig.sectionMapping.creditor"),
      value: "creditor",
    },
    {
      label: t("pages.portalConfig.sectionMapping.claim"),
      value: "claim",
    },
    {
      label: t("pages.portalConfig.sectionMapping.claimItem"),
      value: "claimItem",
    },
  ];

  function setupPayload(formData: SectionFormData) {
    return {
      caseId: caseId || "",
      formDefinitionId: formId || "",
      name: formData.displayName,
      nameFrench: formData.displayNameFR || "",
      description: formData.description || "",
      mapping:
        SectionMapping[formData.selectMapping as keyof typeof SectionMapping],
      columns: 2,
    };
  }

  const handleCancel = () => {
    if (isDirty) {
      setConfirmationVisible(true);
    } else {
      setShow(false);
      reset();
    }
  };

  const handleSave = async () => {
    if (caseId && formId) {
      const sectionPayload: SectionRequestBody = setupPayload(getValues());
      if (sectionPayload) {
        try {
          logger.info("sectionPayload", sectionPayload);
          const response = await createSection(sectionPayload).unwrap();
          reset();
          const newSections = [
            ...formSetup.sections,
            {
              id: response.sectionId,
              name: sectionPayload.name,
              nameFrench: sectionPayload.nameFrench,
              description: sectionPayload.description,
              mapping: sectionPayload.mapping,
              columns: sectionPayload.columns,
            },
          ];
          setShow(false);
          dispatch(
            setForm({
              ...formSetup,
              sections: newSections,
            })
          );
          dispatch(
            setNotification({
              type: "success",
              notifications: {
                message: t(
                  "pages.portalConfig.formSetup.messages.sections.create.success"
                ),
              },
            })
          );
        } catch (error) {
          dispatch(
            setNotification({
              type: "error",
              notifications: {
                message: t(
                  "pages.portalConfig.formSetup.messages.sections.create.error"
                ),
              },
            })
          );
        } finally {
          reset();
        }
      }
    }
  };

  return (
    <MotifModal
      show={show}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={handleCancel}
      size={"xl"}
    >
      <div className="section-popup">
        <form onSubmit={handleSubmit(handleSave)}>
          <MotifModalHeader headerIcon={false}>
            {t("pages.portalConfig.formSetup.sections.popup.title")}
          </MotifModalHeader>
          <MotifModalBody>
            <MotifFormField>
              <ControlInputBox
                name={"displayName"}
                control={control}
                required={true}
                isTextArea={false}
                label={"pages.portalConfig.labels.displayName"}
                showLabel={true}
                readonly={false}
              />
            </MotifFormField>
            <MotifFormField>
              <ControlInputBox
                name={"displayNameFR"}
                label={"pages.portalConfig.labels.displayNameFR"}
                control={control}
                isTextArea={false}
                required={true}
                showLabel={true}
              />
            </MotifFormField>
            <MotifFormField>
              <ControlInputBox
                name={"description"}
                label={"pages.portalConfig.labels.description"}
                control={control}
                isTextArea={true}
                required={false}
                showLabel={true}
              />
            </MotifFormField>
            <MotifFormField>
              <ControlSelect
                name={"selectMapping"}
                control={control}
                required={true}
                showLabel={true}
                label={t("pages.portalConfig.labels.selectMapping")}
                setValue={setValue}
                getValues={getValues}
                options={mappingOptions}
              />
            </MotifFormField>
          </MotifModalBody>
          <MotifModalFooter>
            <div className="motif-row">
              <MotifButton
                variant="secondary"
                onClick={handleCancel}
                type="button"
              >
                Cancel
              </MotifButton>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <MotifButton
                variant="primary"
                disabled={!isValid || !isDirty}
                loading={isSubmitting}
                type={isSubmitting ? "loader" : "submit"}
              >
                Save
              </MotifButton>
            </div>
          </MotifModalFooter>
        </form>
      </div>
      <ConfirmationModal
        isVisible={confirmationVisible}
        setIsVisible={setConfirmationVisible}
        cancelBtnRejectHandler={() => {
          setConfirmationVisible(false);
        }}
        cancelBtnApproveHandler={() => {
          setShow(false);
          reset();
          setConfirmationVisible(false);
        }}
      />
    </MotifModal>
  );
};
