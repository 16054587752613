const home = {
  title: "Home",
  hello: `Hello {{name}}`,
  greetings: `Lorem ipsum dolor sit amet consectetur. Pulvinar aliquet a odio sed
          aliquam ligula placerat massa. At interdum lectus auctor nibh dui massa
          tristique. Eget nisi potenti aenean ultrices nunc sit quam leo. Non
          risus donec et enim semper.`,
  quickActions: {
    quickActionHeading: "Quick Actions",
    quickActionsList: {
      newCaseSetup: {
        header: "New Case Setup",
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
        footer: "Set up a Case",
      },
      applicationAdminSettings: {
        header: "Application Admin Settings",
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
        footer: "Manage Users",
      },
      formConfiguration: {
        header: "Form Configuration",
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
        footer: "Create a Form",
      },
    },
  },
  tableTile: "All Cases",
  tableDescription: "Descriptive text that describes your page or content.",
  columnList: {
    caseName: "Case Name",
    caseShortName: "Case Short Name",
    caseStatus: "Status",
    caseLastUpdateOn: "Last Updated On",
  },
  rowList: {
    statusOpen: "Open",
    statusClose: "Close",
  },
};

export default home;
