import Button from '@/components/Form/Button/Button';
import { useLazyCalculateSettlementQuery, useLazyReCalculateEntitlementQuery } from '@/services/distribution/distributionReducers';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { useTranslation } from 'react-i18next';
function GenerateDraftCalculation ({
  handleSummaryApiAfterSettlementDone
}:{
  handleSummaryApiAfterSettlementDone?: () => void
}) {
    const { distributionId } = useParams();
    const distributionStatus = useSelector((state: RootState) => state.distributions.distributionStatus);
const { t } = useTranslation();
    const [calculateSettlement, { isLoading: isCalculating }] =
    useLazyCalculateSettlementQuery();
    const [reCalculateEntitlement, { isLoading: isRecalculating }] =
    useLazyReCalculateEntitlementQuery();
    const handleRecalculateEntitlement = async () => {
        try {
          await reCalculateEntitlement(distributionId).then(() => {
            if(handleSummaryApiAfterSettlementDone){
            handleSummaryApiAfterSettlementDone()
            }
          });
        } catch (error) {}
      };
      async function  handleCalculateSettlement() {
        try {
          await calculateSettlement(distributionId).then(() => {
            if(handleSummaryApiAfterSettlementDone){
              handleSummaryApiAfterSettlementDone()
              }
          });
        } catch (error) {}
      }

    return (
        <>
           <div className="mar-b-30">
          <Button
              type="button"
              label={t("pages.distributions.distribution.generateDraftSettlementButton")}
              loading={isCalculating}
              variant="primary"
              className="refreshancelBtn"
              onClickHandler={handleCalculateSettlement}
              disabled={distributionStatus === 3}
            />
            <Button
              type="button"
              label={t("pages.distributions.distribution.generateDraftEntitlementButton")}
              loading={isRecalculating}
              variant="primary"
              className="refreshancelBtn"
              onClickHandler={handleRecalculateEntitlement}
              disabled={distributionStatus === 3}
            />
            </div>
        </>
    );
};

export default GenerateDraftCalculation;