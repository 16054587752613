import { ccwpApi } from "@/services";

export const ClaimSetupApi = ccwpApi.injectEndpoints({
  endpoints: (builder) => ({
    createClaim: builder.mutation({
      query: (data) => ({
        url: `/ClaimWizard/CreateClaim`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});


export const { useCreateClaimMutation } = ClaimSetupApi;