import { MotifAccordion, MotifAccordionContent, MotifAccordionTrigger } from "@ey-xd/motif-react";
import { ADD_DEBTORS, ADD_DEBTOR_BUTTON, DEBTOR_DESCRIPTION, DEBTOR_INFORMATION, DEBTOR_NAME, DESCRIPTION, REMOVE } from "../Constants";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { CANCEL, EDIT, NEXT, SECONDARY } from "@/Constant";
import Button from "@/components/Form/Button/Button";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import "./DebtorInfomation.scss";
import FormModal from "@/pages/CaseDetail/CaseSetup/FormModal/FormModal";
import { DebtorData } from "./type";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@/app/store";
import { resetCaseSetup, setDebtorInformation, updateFormSubmitIndex, updateStage } from "../caseSetupSlice";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";


export default function DebtorInfomation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const MODAL_TYPE = {
    CANCEL: 'CANCEL',
    REMOVE_DEBTOR: 'REMOVE_DEBTOR',
  };

  const [isVisible, setIsVisible] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [currentModalType, setCurrentModalType] = useState(null);

  const [removeItemIndex, setRemoveItemIndex] = useState(-1);
  const CaseSetupData = useSelector(
    (state: RootState) => state.caseSetup
  );
  const formSubmitIndex = CaseSetupData.formSubmitIndex;
  const dispatch = useDispatch();
  const [debtorData, setDebtorData] = useState<DebtorData[]>(CaseSetupData?.debtorData || []);
  const [formData, setFormData] = useState<DebtorData | null>(null);
  const [formDataIndex, setFormDataIndex] = useState(-1);

  const setPostData = (data: DebtorData, create: boolean) => {
    if (create) {
      setDebtorData([...debtorData, data]);
      setFormData(null);
    } else {
      const debtorDataCopy = [...debtorData];
      debtorDataCopy[formDataIndex] = data;
      setDebtorData(debtorDataCopy);
      setFormData(null);
      setFormDataIndex(-1);
    }
  }
  const editBtnHandler = (index: number) => {
    setIsVisible(true);
    setFormData(debtorData[index]);
    setFormDataIndex(index);
  }

  // Handler functions for different modal types
  const handleCancel = () => {
    dispatch(resetCaseSetup());
    navigate('/');
  };

  const handleRemoveDebtor = () => {
    const data = [...debtorData];
    data.splice(removeItemIndex, 1);
    setDebtorData(data);
    setRemoveItemIndex(-1);
  };
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: true }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch]);

  // Unified function to open the modal with the correct type
  const openConfirmationModal = (type: any) => {
    setCurrentModalType(type);
    setConfirmationModalVisible(true);
  };

  // Unified function to handle modal confirmation based on the type
  const handleModalConfirm = () => {
    if (currentModalType === MODAL_TYPE.CANCEL) {
      handleCancel();
    } else if (currentModalType === MODAL_TYPE.REMOVE_DEBTOR) {
      handleRemoveDebtor();
    }
    setConfirmationModalVisible(false);
  };

  // Unified function to handle modal cancellation
  const handleModalCancel = () => {
    setConfirmationModalVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));

  };

  const addBtnHandler = () => {
    setIsVisible(true);
    setFormData(null);
  }

  const moveToNext = () => {
    dispatch(setDebtorInformation(debtorData));
    dispatch(updateStage(2));
    formSubmitIndex !== 3 && dispatch(updateFormSubmitIndex(1));
  }

  const renderDebtorData = debtorData.map((debtor, index) => {
    return (
      <div className="motif-row list-item-row" key={index}>
        <div className="motif-col-xl-4 list-item">
          <div className="item-content">
            <p className="item-heading">{t(debtor.legalDebtorName)}</p>
          </div>
        </div>
        <div className="motif-col-xl-6 list-item">
          <div className="item-content">
            <p className="item-heading">{t(debtor.debtorDescription)}</p>
          </div>
        </div>
        <div className="motif-col-xl-2 btn-container">
          <Button variant={SECONDARY} label={t(EDIT)} className="editBtn" onClickHandler={() => editBtnHandler(index)} />
          <Button className="mar-l-30" variant="ghost" label={t(REMOVE)} onClickHandler={() => { setRemoveItemIndex(index); openConfirmationModal(MODAL_TYPE.REMOVE_DEBTOR) }} />
        </div>
      </div>
    );
  })
  return (
    <>
      <div className="motif-col-lg-12  main-container debtor-info-container">
        <div className="motif-row t-b-padding-21">
          <div className="motif-col-xs-4">
            <p className='form-title'>{t(DEBTOR_INFORMATION)}</p>
            <p className='form-description'>{t(DEBTOR_DESCRIPTION)}</p>
          </div>
        </div>
        <div className="motif-row t-b-padding-21">
          <div className="motif-col-xs-4">
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '15px'
              }}
            >

              <MotifAccordion
                onClose={() => { }}
                onOpen={() => { }}
                style={{
                  flex: '1'
                }}
                open={true}
                useChevronIcon={true}
                alignIconRight={true}
              >
                <MotifAccordionTrigger>
                  {t(ADD_DEBTORS)}
                </MotifAccordionTrigger>
                <MotifAccordionContent>
                  <div className="motif-container pad-l-40 pad-r-20">
                    <div className="motif-row heading">
                      <div className="motif-col-xl-4">
                        {t(DEBTOR_NAME)}
                      </div>
                      <div className="motif-col-xl-7">
                        {t(DESCRIPTION)}
                      </div>
                      <div className="motif-col-xl-1">
                      </div>
                    </div>
                    {renderDebtorData}
                    <Button variant={SECONDARY} label={t(ADD_DEBTOR_BUTTON)} className="addBtn" onClickHandler={addBtnHandler} />
                  </div>
                </MotifAccordionContent>
              </MotifAccordion>
            </div>
          </div>
        </div>
      </div>
      <div className="button-container">
        <Button label={t(NEXT)} type="button" onClickHandler={moveToNext} />
        <Button type="button" label={t(CANCEL)} variant={SECONDARY} className="cancelBtn" onClickHandler={() => {openConfirmationModal(MODAL_TYPE.CANCEL);      dispatch(setDiscardEditMode({ editMode: false }));
}} />
      </div>
      <ConfirmationModal
        isVisible={confirmationModalVisible}
        setIsVisible={setConfirmationModalVisible}
        cancelBtnApproveHandler={handleModalConfirm}
        cancelBtnRejectHandler={handleModalCancel}
      />
      <FormModal isVisible={isVisible} setIsVisible={setIsVisible} setPostData={setPostData} create={formData === null ? true : false} formData={formData} />
    </>
  );
}