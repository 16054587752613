import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "@/features/auth/login";
import config from "@/config";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useSelector } from "react-redux";
import Logout from "./logout";
import { IUserState } from "./user-slice";
import FormNotifications from "@/pages/CaseDetail/PortalConfiguration/FormManagement/FormSetup/FormNotifications";

const { features } = config;

export default function AuthRouter({
  children,
}: {
  children: React.ReactNode;
}) {
  const user: IUserState = useSelector((state: any) => state.user);
  const [showUI, setShowUI] = React.useState(false);

  useEffect(() => {
    if (user?.backendAccessToken && !showUI) {
      if (features.session) {
        if (user?.session?.sessionExpiration) {
          const sessionExpiry = new Date(user.session.sessionExpiration);
          const currentTime = new Date();
          if (currentTime > sessionExpiry) {
            setShowUI(false);
          } else {
            setShowUI(true);
          }
        }
      } else {
        setShowUI(true);
      }
    }
  }, [showUI, user?.backendAccessToken, user?.session]);

  return (
    <>
      <FormNotifications />
      {features.sso ? (
        <>
          <UnauthenticatedTemplate>
            <Routes>
              <Route path="/logout" element={<Logout />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </UnauthenticatedTemplate>
          {showUI && <AuthenticatedTemplate>{children}</AuthenticatedTemplate>}
        </>
      ) : (
        children
      )}
    </>
  );
}
