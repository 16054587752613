import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PortanConfigTab, PortalConfigState } from "./PortalConfigType";

export const initialState: PortalConfigState = {
  currentTab: PortanConfigTab.FormManagement,
};

const distributionsSlice = createSlice({
  name: "distributions",
  initialState,
  reducers: {
    setCurrentTab(state: PortalConfigState, action: PayloadAction<PortanConfigTab>) {
      return { currentTab: action.payload };
    },
  },
});

export const { setCurrentTab } = distributionsSlice.actions;
export default distributionsSlice.reducer;
