// @ts-nocheck
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifErrorMessage,
  MotifProgressLoader,
  MotifButton,
  MotifIcon,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";
import { IconoirWarningTriangle } from "@ey-xd/motif-icon";
import React, { useEffect, useState } from "react";
import {
  AMOUNT_PER_PROOF_OF_CLAIM_FIELD,
  ASSIGNEE,
  ASSIGNEE_FIELD,
  RESOLVE_STATUS_FIELD,
  UNIT,
} from "./Constants";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ClaimDetailsType } from "./type";
import ControlSelect from "./ControlSelect";
import "./ClaimDetailsContent.scss";
import Button from "@/components/Form/Button/Button";
import {

  SECONDARY,
} from "@/Constant";
import {
  useGetClaimItemDetailsQuery,
  useGetUnitByCaseIdQuery,
} from "@/services/claimDetail/claimDetailReducer";
import { SearchCreditor } from "./searchCreditor";
import ClaimItemIdentification from "./components/ClaimItemIdentification";
import "./ClaimDetailsContent.scss";
import ControlInputBox from "@/components/Form/InputBox/ControlInputBox";
import { CommentHistory } from "@/features/claimsummary/summary/component/commentHistory";
import { checkAmountDecimalError, formatAmountWithCommas } from "@/utility/common";
import { getCurrencyOptions } from "./ClaimDetailsHelper";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import {
  useGetCreditorQuickSearchApiDataQuery,
  useUpdateClaimItemMutation,
} from "@/services/claimSummary/caseSummaryReducer";
import { ENTITYTYPE } from "@/appConstants";
import CustomAttribute from "@/components/CustomAttribute/CustomAtrribute";
import { AttributeType } from "../../Configuration/Attributes/type";
import ControlInputBoxNumber from "@/components/Form/InputBox/ControlInputBoxNumber";
import { isNumeric } from "@/utility/validation";
import { useCase } from "@/features/case/use-case";
import { postClaimItemData } from "./ClaimDetailsHelper";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

export default function ClaimDetailsContent({
  claimItemId,
  claimId,
  getClaimItemstatus,
  claimStagePosition,
}: {
  claimItemId: string;
  claimId: string;
  getClaimItemstatus: any;
  claimStagePosition: any;
}) {
  const [isIdentificationTabOpen, setIsIdentificationTabOpen] =
    React.useState(true);
  const [isAmountTabOpen, setIsAmountTabOpen] = React.useState(true);
  const [isCommentTabOpen, setIsCommentTabOpen] = React.useState(true);
  const [claimStagePopUp, setClaimStagePopUp] = useState(false);
  const [disableStagePopUp, setDisableStagePopUp] = useState(false);
  const [isAttributesTabOpen, setIsAttributesTabOpen] = React.useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [attributesValue, setAttributesValue] = useState([]);
  const [intialAttributesValue, setIntiaslAttributesValue] = useState([]);

  const [isAttributesError, setIsAttributesError] = useState(false);
  const [isDecimalError, setIsDecimalError] = useState(false);
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: "error",
  });
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const caseData = useCase();
  const caseId = caseData.caseId;
  const editView = true;
  const [updateClaimItem] = useUpdateClaimItemMutation();
  const {
    data: claimItem,
    error,
    isLoading,
  } = useGetClaimItemDetailsQuery({ claimItemId: claimItemId, caseId: caseId });
  const {
    data: currencyData,
    error: curencyError,
    isLoading: currencyIsLoading,
  } = useGetUnitByCaseIdQuery(caseId);
  const {
    data: assigneeList,
    error: assigneeError,
    isLoadingAssignee,
  }: any = useGetCreditorQuickSearchApiDataQuery({
    caseId: caseId,
  });
  const currencyOptions =
    currencyData && getCurrencyOptions(currencyData, caseData);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors,isDirty },
  } = useForm<ClaimDetailsType>({
    defaultValues:{
      amountPerProofOfClaim: claimItem?.currentAmount,
      assignee: claimItem?.assigneeId,
    }
  });
  const dispatch=useDispatch()

  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty || !(JSON.stringify(intialAttributesValue)===JSON.stringify(attributesValue)) }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };  
  }, [dispatch,isDirty,attributesValue,intialAttributesValue]);
  const [decimalLimit, setDecimalLimit] = useState(
    caseData?.defaultUnitDecimal
  );
  useEffect(() => {
    setValue(AMOUNT_PER_PROOF_OF_CLAIM_FIELD, claimItem?.currentAmount);
    if (claimItem) {
      setAttributesValue(claimItem.customAttributes);
      setIntiaslAttributesValue(claimItem.customAttributes)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimItem, setValue]);

  useEffect(() => {
    if (claimItem) {
      setValue(ASSIGNEE_FIELD, claimItem?.assigneeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimItem]);

  const [currenctUnit, amountPerProofOfClaim] = watch([
    "Unit",
    "amountPerProofOfClaim",
  ]);
  useEffect(() => {
    const currenctUnit = watch("Unit");
    //@ts-ignore
    currencyData &&
      currencyData.forEach((item) => {
        if (item.id === currenctUnit) {
          setDecimalLimit(item.decimalPlace);
          const currentAmountLatest = watch("amountPerProofOfClaim");
          if (
            currentAmountLatest &&
            checkAmountDecimalError(currentAmountLatest, item.decimalPlace)
          ) {
            setIsDecimalError(true);
            setTimeout(() => {
              setIsDecimalError(false);
            }, 5000);
          } else {
            setIsDecimalError(false);
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currenctUnit, amountPerProofOfClaim]);

  const selectedAssignee =
    assigneeList &&
    assigneeList?.find(
      (item: any) => item?.creditorId === claimItem?.assigneeId
    );

 

  const onChangeAmount = (value: string) => {
    if (value && checkAmountDecimalError(value, decimalLimit)) {
      setIsDecimalError(true);
      setTimeout(() => {
        setIsDecimalError(false);
      }, 5000);
    } else {
      setIsDecimalError(false);
    }
    if (claimStagePosition === 1) {
      setClaimStagePopUp(true);
    }
    if (disableStagePopUp === true) {
      setClaimStagePopUp(false);
    }
  };
  if (isLoading || currencyIsLoading || isLoadingAssignee)
    return <MotifProgressLoader show variant="default" />;
  if (error || curencyError || assigneeError)
    return <MotifErrorMessage>{t("global.globalNotifications.unExpectedError")}</MotifErrorMessage>;

  const defaultUnit = currencyData?.find((item: any) => item.isDefaultCurrency);
  const receivedUnit = currencyData?.find(
    (item: any) => item.id === claimItem?.currentAmountUnitId
  );

  const decimalPlaceOfUnit = (name) =>{
    const currentUnit = currencyData?.find((item: any) => item.name === name);
    return currentUnit?.decimalPlace;
  }

  const handleChange = (name: string, value: string) => {
    setValue(ASSIGNEE_FIELD, value);
  };
  const attributesError = () => {
    var isError = false;
    const required = attributesValue.filter((x: any) => {
      return x.isRequired;
    });

    required.forEach((x: any) => {
      switch (x.value.attributeType) {
        case AttributeType.FreeText:
          isError = !x.value.textValue;
          break;
        case AttributeType.Number:
          isError = !x.value.numberValue;
          break;
        case AttributeType.Boolean:
          isError = !x.value.booleanValue;
          break;
        case AttributeType.Date:
          isError = !x.value.dateValue;
          break;
        case AttributeType.SingleSelect:
          isError = !x.value.selectedValue;
          break;
        case AttributeType.MutliSelect:
          isError =
            !x.value.selectedValues || x.value.selectedValues.length === 0;
          break;
        case AttributeType.Currency:
          isError = !x.value.amount;
          break;
        case AttributeType.Document:
          isError = !x.value.displayName;
          break;
        default:
          isError = false;
      }
    });

    return isError;
  };

  const errorList = Object.keys(errors);
  const errorsShow = errorList
    .join(", ")
    .replace(AMOUNT_PER_PROOF_OF_CLAIM_FIELD, "Current Amount");

  const onSubmit = async (data: ClaimDetailsType) => {
    if (attributesError()) {
      setIsAttributesError(true);
    } else {
      setIsSaveLoading(true);
      setIsAttributesError(false);
      const attributes = attributesValue.map((value: any) => value.value);
      try {
        const itemData = postClaimItemData({ ...data, attributes: attributes });
        itemData.claimItemId = claimItemId;
        dispatch(setDiscardEditMode({ editMode: false }));

        const responseApi = await updateClaimItem(itemData).unwrap();

        if (responseApi) {
          setIsSaveLoading(false);
          setShowMessage({ ...showMessage, show: true, type: "success" });
          navigate(`/case/${caseId}/claim/${claimId}`, {
            state: { showMessage: true, update: true, defaultActiveKey: 3 },
          });
          
        } else {
          setShowMessage({ ...showMessage, show: true, type: "error" });
          setIsSaveLoading(false);
          dispatch(setDiscardEditMode({ editMode: true }));


        }
      } catch (error) {
        setShowMessage({ ...showMessage, show: true, type: "error" });
        setIsSaveLoading(false);
        dispatch(setDiscardEditMode({ editMode: true }));

      }
    }
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
  };

  const cancelBtnApproveHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    setShowMessage({ ...showMessage, show: false, type: "error" });
    navigate(-1);
  };

  return (
    <>
      {showMessage && showMessage.type === "error" && (
        <NotificationGrid show={showMessage.show} type={showMessage.type} />
      )}
      <div className="claim-details-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <MotifAccordion
            onClose={() => setIsIdentificationTabOpen(false)}
            onOpen={() => setIsIdentificationTabOpen(true)}
            style={{
              flex: "1",
            }}
            open={isIdentificationTabOpen}
            useChevronIcon={true}
            alignIconRight={true}
          >
            <MotifAccordionTrigger>
              {t("pages.claims.claimItemEdit.subTitle1")}
            </MotifAccordionTrigger>
            <MotifAccordionContent>
              <div className="motif-row">
                <ClaimItemIdentification
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  editView={editView}
                  selectedItem={{
                    categoryId: claimItem?.categoryId,
                    natureOfClaim: claimItem?.natureOfClaim.filter(
                      (value) => value !== ""
                    ),
                    getClaimItemstatus: getClaimItemstatus
                      ? getClaimItemstatus
                      : claimItem?.isResolved,
                  }}
                />
                <div className="motif-col-lg-4">
                  <div className="formFieldLabel">
                    <label aria-label={ASSIGNEE}>{t("pages.claims.claimItemCommonLabels.assignee")}</label>
                  </div>
                  <div className="form-field search-creditor">
                    <SearchCreditor
                      handleChange={handleChange}
                      value={selectedAssignee?.creditorName}
                      getClaimItemstatus={
                        getClaimItemstatus
                          ? getClaimItemstatus
                          : claimItem?.isResolved
                      }
                    />
                    <span className="hide">
                      {/*@ts-ignore*/}
                      <ControlInputBox
                        name={ASSIGNEE_FIELD}
                        control={control}
                        required={false}
                        isTextArea={false}
                        label={t("")}
                        showLabel={false}
                        setValue={setValue}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </MotifAccordionContent>
          </MotifAccordion>
          <MotifAccordion
            onClose={() => setIsAmountTabOpen(false)}
            onOpen={() => setIsAmountTabOpen(true)}
            style={{
              flex: "1",
            }}
            open={isAmountTabOpen}
            useChevronIcon={true}
            alignIconRight={true}
          >
            <MotifAccordionTrigger>
            {t("pages.claims.claimItemEdit.subTitle2")}
            </MotifAccordionTrigger>
            <MotifAccordionContent>
              {/* row 1 */}
              <div className="motif-row">
                <div className="motif-col-sm"></div>
                <div className="motif-col-sm">
                  <div className="title align-right">{t("pages.claims.claimItemCommonLabels.originalAmount")}</div>
                </div>
                <div className="motif-col-sm">
                  <div className="title align-right">{t("pages.claims.claimItemCommonLabels.stageAmount")}</div>
                </div>
                <div className="motif-col-sm">
                  <div className="title align-right">
                  {t("pages.claims.claimItemCommonLabels.latestCreditorPosition")}                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="title align-right">
                  {t("pages.claims.claimItemCommonLabels.latestDebtorPosition")}                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="title align-right">
                  {t("pages.claims.claimItemCommonLabels.resolvedAmount")}                  </div>
                </div>
              </div>
              {/* row 2 */}
              <div className="motif-row pad-t-10">
                <div className="motif-col-sm">{defaultUnit?.name}</div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    {`${
                      claimItem.baseOriginalAmount === null ||
                      claimItem.baseOriginalAmount === undefined
                        ? "-"
                        : formatAmountWithCommas(
                            claimItem.baseOriginalAmount,
                            defaultUnit?.decimalPlace
                          )
                    } ${defaultUnit.name}`}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    {`${
                      claimItem.baseCurrentAmount === null ||
                      claimItem.baseCurrentAmount === undefined
                        ? "-"
                        : formatAmountWithCommas(
                            claimItem.baseCurrentAmount,
                            defaultUnit?.decimalPlace
                          )
                    } ${defaultUnit?.name}`}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    {`${
                      claimItem?.isResolved ||
                      claimItem?.baseUnresolvedCreditorAmount === null ||
                      claimItem?.baseUnresolvedCreditorAmount === undefined
                        ? "-"
                        : formatAmountWithCommas(
                            claimItem.baseUnresolvedCreditorAmount,
                            defaultUnit?.decimalPlace
                          )
                    } ${defaultUnit?.name}`}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    {`${
                      claimItem?.isResolved ||
                      claimItem?.baseUnresolvedDebtorAmount === null ||
                      claimItem?.baseUnresolvedDebtorAmount === undefined
                      ? "-"
                      : formatAmountWithCommas(
                        claimItem.baseUnresolvedDebtorAmount,
                        defaultUnit?.decimalPlace
                        )
                    } ${defaultUnit?.name}`}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    {`${
                      claimItem.baseResolvedAmount === null ||
                      claimItem.baseResolvedAmount === undefined
                        ? "-"
                        : formatAmountWithCommas(
                            claimItem.baseResolvedAmount,
                            defaultUnit?.decimalPlace
                          )
                    } ${claimItem?.resolvedAmountUnit}`}
                  </div>
                </div>
              </div>
              {/* row 3 */}
              <div className="motif-row pad-t-10">
                <div className="motif-col-sm">{t("pages.claims.claimItemCommonLabels.Entered")}</div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    {`${
                      claimItem.originalAmount === null ||
                      claimItem.originalAmount === undefined
                        ? "-"
                        : formatAmountWithCommas(
                            claimItem.originalAmount,
                            //receivedUnit?.decimalPlace
                            decimalPlaceOfUnit(claimItem?.originalAmountUnit)
                          )
                    } ${claimItem?.originalAmountUnit}`}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    {`${
                      claimItem.currentAmount === null ||
                      claimItem.currentAmount === undefined
                        ? "-"
                        : formatAmountWithCommas(
                            claimItem.currentAmount,
                            //receivedUnit?.decimalPlace
                            decimalPlaceOfUnit(claimItem?.currentAmountUnit)
                          )
                    } ${claimItem?.currentAmountUnit}`}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    {`${
                      claimItem?.isResolved ||
                      claimItem?.unresolvedCreditorAmount === null ||
                      claimItem?.unresolvedCreditorAmount === undefined
                        ? "-"
                        : formatAmountWithCommas(
                            claimItem.unresolvedCreditorAmount,
                            //receivedUnit?.decimalPlace
                            decimalPlaceOfUnit(claimItem?.unresolvedCreditorAmountUnit)
                          )
                    } ${claimItem?.unresolvedCreditorAmountUnit}`}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    {`${
                      claimItem?.isResolved ||
                      claimItem?.unresolvedDebtorAmount === null ||
                      claimItem?.unresolvedDebtorAmount === undefined
                        ? "-"
                        : formatAmountWithCommas(
                            claimItem.unresolvedDebtorAmount,
                            //receivedUnit?.decimalPlace
                            decimalPlaceOfUnit(claimItem?.unresolvedDebtorAmountUnit)
                          )
                    } ${claimItem?.unresolvedDebtorAmountUnit}`}
                  </div>
                </div>
                <div className="motif-col-sm">
                  <div className="motif-body2-default-bold align-right">
                    {`${
                      claimItem.resolvedAmount === null ||
                      claimItem.resolvedAmount === undefined
                        ? "-"
                        : formatAmountWithCommas(
                            claimItem.resolvedAmount,
                            //receivedUnit?.decimalPlace
                            decimalPlaceOfUnit(claimItem?.resolvedAmountUnit)
                          )
                    } ${claimItem?.resolvedAmountUnit}`}
                  </div>
                </div>
              </div>
              <div className={`motif-row pad-t-20`}>
                <div className="motif-col-lg-4">
                  <div className="form-field">
                    {/*@ts-ignore*/}
                    <ControlInputBoxNumber
                      name={AMOUNT_PER_PROOF_OF_CLAIM_FIELD}
                      control={control}
                      required={true}
                      isTextArea={false}
                      label={"pages.claims.claimItemCommonLabels.stageAmount"}
                      showLabel={true}
                      setValue={setValue}
                      isNumber={true}
                      digitAfterDecimal={decimalLimit}
                      isAmount={true}
                      onChangeAmount={onChangeAmount}
                      validate={isNumeric}
                      readonly={
                        getClaimItemstatus
                          ? getClaimItemstatus
                          : claimItem?.isResolved
                      }
                    />
                    <MotifErrorMessage>
                      {isDecimalError ? t("pages.claims.claimItemCommonLabels.decimalErrorMessage") : ""}
                    </MotifErrorMessage>
                  </div>
                </div>
                <div className="motif-col-lg-2">
                  <div className="form-field">
                    <ControlSelect
                      name={UNIT}
                      control={control}
                      required={true}
                      showLabel={true}
                      label={"pages.claims.claimItemCommonLabels.unit"}
                      options={currencyOptions}
                      setValue={setValue}
                      getValues={getValues}
                      value={receivedUnit?.id}
                      disabled={
                        getClaimItemstatus
                          ? getClaimItemstatus
                          : claimItem?.isResolved
                      }
                    />
                  </div>
                </div>
                <div className="motif-col-lg-4">
                  <div className="form-field">
                    <ControlSelect
                      name={RESOLVE_STATUS_FIELD}
                      control={control}
                      required={true}
                      showLabel={true}
                      label={"pages.claims.claimItemCommonLabels.resolveStatus"}
                      options={[
                        { label: t("pages.claims.claimItemCommonLabels.unresolved"), value: "Unresolved" },
                        { label: t("pages.claims.claimItemCommonLabels.resolved"), value: "Resolved" },
                      ]}
                      disabled={getClaimItemstatus === true}
                      setValue={setValue}
                      getValues={getValues}
                      value={claimItem?.isResolved ? "Resolved" : "Unresolved"}
                    />
                  </div>
                </div>
              </div>
            </MotifAccordionContent>
          </MotifAccordion>

          <MotifAccordion
            onClose={() => setIsAttributesTabOpen(false)}
            onOpen={() => setIsAttributesTabOpen(true)}
            style={{
              flex: "1",
            }}
            open={isAttributesTabOpen}
            useChevronIcon={true}
            alignIconRight={true}
          >
            <MotifAccordionTrigger>
              {t("pages.claims.claimItemAttributes")}
            </MotifAccordionTrigger>
            <MotifAccordionContent>
              {claimItem.customAttributes &&
              claimItem.customAttributes === 0 ? (
                <span>{t("pages.claims.noAttributesLabel")}</span>
              ) : (
                <div className="motif-row pad-t-20 mar-b-20">
                  {claimItem.customAttributes &&
                  attributesValue.length ===
                    claimItem.customAttributes.length ? (
                    claimItem.customAttributes.map(
                      (attribute: any, index: number) => {
                        return (
                          <div className="motif-col-md-4">
                            <CustomAttribute
                              customAttributeDetails={{
                                ...attribute,
                                metadataJSON: JSON.parse(
                                  attribute.metadataJSON
                                ),
                              }}
                              customAttributeType={
                                attribute.value.attributeType
                              }
                              attributeValue={attributesValue}
                              setAttributeValue={setAttributesValue}
                              index={index}
                              key={index}
                            />
                          </div>
                        );
                      }
                    )
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </MotifAccordionContent>
          </MotifAccordion>

          <MotifAccordion
            onClose={() => setIsCommentTabOpen(false)}
            onOpen={() => setIsCommentTabOpen(true)}
            style={{
              flex: "1",
            }}
            open={isCommentTabOpen}
            useChevronIcon={true}
            alignIconRight={true}
          >
            <MotifAccordionTrigger>{t("pages.claims.claimItemEdit.subTitle3")}</MotifAccordionTrigger>
            <MotifAccordionContent>
              <div className="pad-t-20">
                <CommentHistory
                  entityType={ENTITYTYPE.claimItem}
                  dataId={claimItemId}
                />
              </div>
            </MotifAccordionContent>
          </MotifAccordion>
          <div className="error-container">
            <MotifErrorMessage>
              {errorList.length > 0
                ? t("pages.claims.claimItemCommonLabels.requiredMessage",{filedNames:errorsShow}
                )
                : ""}
            </MotifErrorMessage>
            <MotifErrorMessage>
              {isAttributesError
                ? t("pages.configuration.attributes.requiredFields")
                : ""}
            </MotifErrorMessage>
          </div>
          <div className="button-container">
            <Button
              label={t("global.globalActions.save")}
              type={isSaveLoading ? "loader" : "submit"}
              disabled={isSaveLoading}
            />
            <Button
              type="button"
              label={t("global.globalActions.cancel")}
              variant={SECONDARY}
              className="cancelBtn"
              onClickHandler={(e) => {
                if(isDirty || !(JSON.stringify(intialAttributesValue)===JSON.stringify(attributesValue)) ){
                  setIsVisible(true);
                  dispatch(setDiscardEditMode({ editMode: false }));
                }
                else{
                  cancelBtnApproveHandler(e)
                }
               

                setShowMessage({ ...showMessage, show: false, type: "error" });
              }}
            />
          </div>
        </form>
        <ConfirmationModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          cancelBtnApproveHandler={cancelBtnApproveHandler}
          cancelBtnRejectHandler={cancelBtnRejectHandler}
        />
        <MotifModal
          onClose={() => setClaimStagePopUp(false)}
          show={claimStagePopUp}
          focusTrapOptions={{
            tabbableOptions: {
              displayCheck: "none",
            },
          }}
        >
          <div className="confirmationModalWrapper creditor_disabling_model">
            <MotifModalHeader
              headerIcon={
                <>
                  <span
                    style={{
                      backgroundColor: "rgb(252, 245, 243)",
                      borderRadius: "50px",
                      padding: "5px 8px 2px 8px",
                    }}
                  >
                    <MotifIcon
                      iconFunction={IconoirWarningTriangle}
                      strokeWidth="1"
                      size="15"
                      stroke="red"
                    />{" "}
                  </span>
                  <span
                    style={{
                      fontSize: 16,
                      display: "block",
                      width: "100%",
                      marginLeft: "10px",
                    }}
                  >
                    {t("global.globalNotifications.warning")}
                  </span>
                </>
              }
            ></MotifModalHeader>

            <MotifModalBody style={{ paddingTop: "0px" }}>
              <span style={{ fontWeight: "300" }}>
{t("pages.claims.claimItemEdit.ammountUpdateWarningMessage")}
              </span>
            </MotifModalBody>
            <MotifModalFooter>
              <MotifButton
                className=""
                onClick={() => {
                  setClaimStagePopUp(false);
                  setDisableStagePopUp(true);
                }}
                size="small"
                type="button"
              >
                {t("global.globalActions.confirm")}
              </MotifButton>
            </MotifModalFooter>
          </div>
        </MotifModal>
      </div>
    </>
  );
}
