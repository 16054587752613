import { j as ut } from "../../jsx-runtime-BwWtxLpl.js";
import { createContext as It, useState as t } from "react";
import { MotifNavigationIcChevronRight24px as d, IconoirNavArrowLeft as n, IconoirNavArrowRight as c, IconoirPause as W, IconoirPlay as O, IconoirCheck as s, IconoirMinus as mt, MotifNavigationIcClose24px as o, MotifContentIcReply24px as V, IconoirCalendar as X, MotifAlertIcError24px as l, IconoirCloudUpload as q, IconoirWarningCircle as z, IconoirXmark as a, IconoirTrash as J, IconoirEmptyPage as K, IconoirSearch as i, IconoirNavArrowDown as r, MotifActionIcDescription24px as Q, MotifActionIcLanguage24px as Y, IconoirHelpCircle as gt, MotifContentIcRemove24px as p, MotifContentIcAdd24px as u, IconoirFastArrowLeft as Z, IconoirFastArrowRight as _, IconoirWarningTriangle as $, IconoirArrowSeparateVertical as ee, IconoirNavArrowUp as te, IconoirFilterList as oe, MotifActionIcCheckCircle24px as ae, MotifAlertIcWarning24px as re, MotifActionIcInfo24px as ne, IconoirMenu as ht, MotifImageIcNavigateBefore24px as ft, MotifImageIcNavigateNext24px as Mt, MotifNavigationIcCheck24px as xt, MotifActionIcHelpOutline24px as Ct } from "@ey-xd/motif-icon";
const ce = It({
  breadcrumbItem: {
    separator: d
  },
  button: {
    previous: n,
    next: c
  },
  carousel: {
    next: c,
    pause: W,
    play: O,
    previous: n
  },
  checkbox: {
    checked: s,
    indeterminate: mt
  },
  chip: {
    close: o
  },
  comment: {
    reply: V
  },
  datePicker: {
    calendar: X
  },
  errorMessage: {
    error: l
  },
  fileUploader: {
    upload: q,
    uploadError: z
  },
  fileUploaderItem: {
    close: a,
    delete: J,
    check: s,
    file: K
  },
  header: {
    close: a,
    search: i
  },
  input: {
    clear: a
  },
  menu: {
    close: a
  },
  menuItemGroup: {
    expandToggle: r
  },
  metadata: {
    close: o,
    file: Q,
    link: Y
  },
  modal: {
    icon: gt
  },
  modalHeader: {
    close: o
  },
  numericInput: {
    decrease: p,
    increase: u
  },
  pagination: {
    first: Z,
    last: _,
    next: c,
    previous: n
  },
  paginationSelect: {
    openToggle: r
  },
  pinEntry: {
    clear: o
  },
  progressIndicatorStep: {
    complete: s,
    error: $
  },
  search: {
    search: i
  },
  select: {
    clear: o
  },
  table: {
    nosort: ee,
    ascending: te,
    descending: r,
    filter: oe
  },
  toast: {
    error: l,
    success: ae,
    warning: re,
    info: ne,
    close: a
  },
  tooltip: {
    close: a
  },
  tree: {
    arrow: d,
    remove: p,
    add: u
  },
  typeahead: {
    close: a,
    arrowDrop: r,
    search: i
  },
  verticalNavigationHeaderButton: {
    menu: ht
  },
  // Add functions to update values
  updateBreadcrumbItem: () => {
  },
  updateButton: () => {
  },
  updateCarousel: () => {
  },
  updateFileUploaderItem: () => {
  },
  updateHeader: () => {
  },
  updateMenu: () => {
  },
  updateMenuItemGroup: () => {
  },
  updateModal: () => {
  },
  updateModalHeader: () => {
  },
  updatePagination: () => {
  },
  updatePaginationSelect: () => {
  },
  updateSearch: () => {
  },
  updateSelect: () => {
  },
  updateTable: () => {
  },
  updateToast: () => {
  },
  updateTooltip: () => {
  },
  updateTree: () => {
  },
  updateTypeahead: () => {
  }
}), bt = ({ children: se }) => {
  const [I, ie] = t({
    separator: d
  }), [m, pe] = t({
    previous: ft,
    next: Mt
  }), [g, de] = t({
    next: c,
    pause: W,
    play: O,
    previous: n
  }), [h, le] = t({
    checked: xt,
    indeterminate: p
  }), [f, ue] = t({
    close: o
  }), [M, Ie] = t({
    reply: V
  }), [x, me] = t({
    calendar: X
  }), [C, ge] = t({
    error: l
  }), [v, he] = t({
    upload: q,
    uploadError: z
  }), [T, fe] = t({
    close: a,
    delete: J,
    check: s,
    file: K
  }), [P, Me] = t({
    close: a,
    search: i
  }), [b, xe] = t({
    clear: o
  }), [k, Ce] = t({
    close: a
  }), [S, ve] = t({
    expandToggle: r
  }), [y, Te] = t({
    close: o,
    file: Q,
    link: Y
  }), [A, Pe] = t({
    icon: Ct
  }), [w, be] = t({
    close: o
  }), [ke, Se] = t({
    decrease: p,
    increase: u
  }), [N, ye] = t({
    first: Z,
    last: _,
    next: c,
    previous: n
  }), [E, Ae] = t({
    openToggle: r
  }), [H, we] = t({
    clear: o
  }), [U, Ne] = t({
    complete: s,
    error: $
  }), [B, Ee] = t({
    search: i
  }), [F, He] = t({
    close: o
  }), [R, Ue] = t({
    nosort: ee,
    ascending: te,
    descending: r,
    filter: oe
  }), [D, Be] = t({
    error: l,
    success: ae,
    warning: re,
    info: ne,
    close: a
  }), [G, Fe] = t({
    close: a
  }), [L, Re] = t({
    arrow: d,
    remove: p,
    add: u
  }), [j, De] = t({
    close: o
  }), Ge = (e) => {
    ie({ ...I, ...e });
  }, Le = (e) => {
    pe({ ...m, ...e });
  }, je = (e) => {
    de({ ...g, ...e });
  }, We = (e) => {
    fe({ ...T, ...e });
  }, Oe = (e) => {
    le({ ...h, ...e });
  }, Ve = (e) => {
    ue({ ...f, ...e });
  }, Xe = (e) => {
    Ie({ ...M, ...e });
  }, qe = (e) => {
    me({ ...x, ...e });
  }, ze = (e) => {
    ge({ ...C, ...e });
  }, Je = (e) => {
    he({ ...v, ...e });
  }, Ke = (e) => {
    Me({ ...P, ...e });
  }, Qe = (e) => {
    xe({ ...b, ...e });
  }, Ye = (e) => {
    Ce({ ...k, ...e });
  }, Ze = (e) => {
    ve({ ...S, ...e });
  }, _e = (e) => {
    Te({ ...y, ...e });
  }, $e = (e) => {
    Pe({ ...A, ...e });
  }, et = (e) => {
    be({ ...w, ...e });
  }, tt = (e) => {
    ye({ ...N, ...e });
  }, ot = (e) => {
    Ae({ ...E, ...e });
  }, at = (e) => {
    we({ ...H, ...e });
  }, rt = (e) => {
    Ne({ ...U, ...e });
  }, nt = (e) => {
    Ee({ ...B, ...e });
  }, ct = (e) => {
    He({ ...F, ...e });
  }, st = (e) => {
    Ue({ ...R, ...e });
  }, it = (e) => {
    Be({ ...D, ...e });
  }, pt = (e) => {
    Fe({ ...G, ...e });
  }, dt = (e) => {
    Re({ ...L, ...e });
  }, lt = (e) => {
    De({ ...j, ...e });
  };
  return /* @__PURE__ */ ut.jsx(
    ce.Provider,
    {
      value: {
        breadcrumbItem: I,
        button: m,
        carousel: g,
        fileUploaderItem: T,
        checkbox: h,
        chip: f,
        comment: M,
        datePicker: x,
        errorMessage: C,
        fileUploader: v,
        header: P,
        input: b,
        menu: k,
        menuItemGroup: S,
        metadata: y,
        modal: A,
        modalHeader: w,
        numericInput: ke,
        pagination: N,
        paginationSelect: E,
        pinEntry: H,
        progressIndicatorStep: U,
        search: B,
        select: F,
        table: R,
        toast: D,
        tooltip: G,
        tree: L,
        typeahead: j,
        updateBreadcrumbItem: Ge,
        updateButton: Le,
        updateCarousel: je,
        updateFileUploaderItem: We,
        updateCheckbox: Oe,
        updateChip: Ve,
        updateComment: Xe,
        updateDatePicker: qe,
        updateErrorMessage: ze,
        updateFileUploader: Je,
        updateHeader: Ke,
        updateInput: Qe,
        updateMenu: Ye,
        updateMenuItemGroup: Ze,
        updateMetadata: _e,
        updateModal: $e,
        updateModalHeader: et,
        setNumericInput: Se,
        updatePagination: tt,
        updatePaginationSelect: ot,
        updatePinEntry: at,
        updateProgressIndicatorStep: rt,
        updateSearch: nt,
        updateSelect: ct,
        updateTable: st,
        updateToast: it,
        updateTooltip: pt,
        updateTree: dt,
        updateTypeahead: lt
      },
      children: se
    }
  );
}, kt = ce.Consumer;
export {
  kt as IconConsumer,
  ce as IconContext,
  bt as IconProvider
};
