import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import {
	MotifFormField,
	MotifLabel,
	MotifSelect,
	MotifOption,
} from "@ey-xd/motif-react";

import { AttributeOperators } from "@/services/advanceSearch/useAttributeOperators";

type ControlSelectBoxOperatorProps = {
	index: number;
	groupIndex?: number;
	control: any;
	treeRows: any[];
	attributeOperators: AttributeOperators;
	handleOperatorChange: (
		index: number,
		operator: string,
		groupIndex?: number
	) => void;
};

const ControlSelectBoxOperator = ({
	index,
	groupIndex,
	control,
	treeRows,
	attributeOperators,
	handleOperatorChange,
}: ControlSelectBoxOperatorProps) => {
	const { t } = useTranslation();
	const path =
		groupIndex !== undefined
			? `treeRows.${index}.treeRows.${groupIndex}`
			: `treeRows.${index}`;
	const attributeType =
		groupIndex !== undefined
			? treeRows[index]?.treeRows[groupIndex]?.attributeType
			: treeRows[index]?.attributeType;
	return (
		<MotifFormField>
			<MotifLabel id="select-label">
				{t("pages.configuration.scheme.rule.operator")}
			</MotifLabel>
			<Controller
				name={`${path}.operator`}
				control={control}
				rules={{ required: true }}
				render={({ field, fieldState: { error } }) => {
					return (
						<MotifSelect
							ariaLabelledBy="select-label"
							onChange={(value) => {
								field.onChange(value);
								handleOperatorChange(index, value, groupIndex);
							}}
							placeholder="Select"
							value={field.value}
							visibleOptions={5}
						>
							{attributeOperators &&
							Array.isArray(attributeOperators[attributeType]) ? (
								attributeOperators[attributeType].map((op: any) => (
									<MotifOption key={op} value={op}>
										{op}
									</MotifOption>
								))
							) : (
								<MotifOption value={field.value}>
									{field.value || "Select"}
								</MotifOption>
							)}
						</MotifSelect>
					);
				}}
			/>
		</MotifFormField>
	);
};

export default ControlSelectBoxOperator;
