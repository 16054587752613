import { EDIT } from "@/Constant";
import { MotifIcon } from "@ey-xd/motif-react";
import { useTranslation } from 'react-i18next';
import {
    IconoirEdit
  } from "@ey-xd/motif-icon";

export default function EditIcon() {
    const { t } = useTranslation();
    return (
        <span className="mar-t-10 pad-r-10"><MotifIcon  fill={`#fff`} size='24' title={t(EDIT)} src={IconoirEdit} className='editBtn'></MotifIcon></span>
    );
}