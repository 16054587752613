import React, { useEffect, useState } from "react";
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifRadioButton,
  MotifRadioButtonGroup,
} from "@ey-xd/motif-react";
import Button from "@/components/Form/Button/Button";
import { useForm } from "react-hook-form";
import { MotifErrorMessage } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import {
  SECONDARY,
} from "@/Constant";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import { CreditorSetupFormType } from "./type";
import {
  BUSINESS_NAME,
  CREDITOR_TYPE,
  DESCRIPTION,
  FIRST_NAME,
  INDIVIDUAL,
  LAST_NAME,
  ORGANIZATION,
  PREFIX,
  RELATIONSHIP_TO_DEBTOR,

} from "./Constants";
import ControlInputBox from "./Form/ControlInputBox";
import "./CreditorSetup.scss";
import ControlSelect from "./Form/SelectBox/ControlSelect";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@/app/store";
import { resetCreditorSetup, setCreditorSetFormData, updateFormSubmitIndex, updateStage } from "./creditorSetupSlice";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";

export default function CreditorDetails() {
  const { t } = useTranslation();

   const PREFIX_OPTIONS_LIST = [
    { 
        label: t("global.globalActions.optionDefault"), 
        value: "" },
    {
        label:t("global.globalActions.prefixOptionsListLabels.mr"),
        value: "Mr.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.ms"),
        value: "Ms.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.mrs"),
        value: "Mrs.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.miss"),
        value: "Miss.",
    },
    {
        label: t("global.globalActions.prefixOptionsListLabels.other"),
        value: "other",
    }
]
  const [isVisible, setIsVisible] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(true);
  const CreditorSetupData = useSelector((state: RootState) => state.creditorSetup);
  const formSubmitIndex = CreditorSetupData.formSubmitIndex;
  const { creditorSetupForm } = CreditorSetupData;
  const navigate = useNavigate();
  const { caseId } = useParams();
  const [creditorType, setCreditorType] = useState(
    creditorSetupForm.creditorType || INDIVIDUAL
  );
  const dispatch = useDispatch();


  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors ,isDirty},
  } = useForm<CreditorSetupFormType>({
    defaultValues: {
      firstName: creditorSetupForm.firstName,
      lastName: creditorSetupForm.lastName,
      prefix: creditorSetupForm.prefix,
      businessName: creditorSetupForm.businessName,
      description: creditorSetupForm.description,
      relationShipToDebtor: creditorSetupForm.relationShipToDebtor || "",
      creditorType: creditorSetupForm.creditorType || INDIVIDUAL,
    },
  });

  useEffect(() => {
    if (creditorType === INDIVIDUAL) {
      setValue("businessName", "");
    } else {
      setValue("prefix", "");
      setValue("firstName", "");
      setValue("lastName", "");
    }
  }, [creditorType, setValue]);

  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch,isDirty]);
  const cancelBtnApproveHandler = (
    event: any
  ) => {
    event.preventDefault();
     dispatch(resetCreditorSetup());
     dispatch(updateStage(0));
    navigate(`/case/${caseId}?activeTab=2`);
  };

  const cancelBtnRejectHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));

  };

  const errorList = Object.keys(errors);
  const errorsShow = errorList.join(", ");
  //@ts-ignore
  const onSubmit = (data) => {
    data = { ...data, creditorType: creditorType };
    dispatch(setCreditorSetFormData(data));
    dispatch(updateStage(1));
    formSubmitIndex !== 3 && dispatch(updateFormSubmitIndex(0));
  };
  const isOrg = creditorType === ORGANIZATION ? true : false;
  return (
    <>
      <div className="motif-container pad-l-40 pad-r-20">
        <div className="motif-row">
          <div className="motif-col-lg-12  main-container">
            <div className="motif-row t-b-padding-21">
              <div className="motif-col-xs-4">
                <p className="form-title">{t("pages.creditors.creditorForm.creditorDetailsTitle")}</p>
                <p className="form-description">{t("pages.creditors.creditorForm.creditorDetailsDescription")}</p>
              </div>
            </div>
            <div className="motif-row t-b-padding-21">
              <div className="motif-col-xs-4">
                <div
                  style={{
                    alignItems: "flex-start",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="motif-row pad-b-30 pad-t-10 pad-l-30">
                      <MotifRadioButtonGroup
                        name={CREDITOR_TYPE}
                        required={true}
                        defaultValue={INDIVIDUAL}
                        options={[INDIVIDUAL, ORGANIZATION]}
                      >
                        <MotifRadioButton
                          checked={creditorType === INDIVIDUAL}
                          onChange={() => setCreditorType(INDIVIDUAL)}
                          value={INDIVIDUAL}
                        >
                          {t("pages.creditors.creditorForm.formLabelDetails.radiobuttonsLabels.individual")}
                        </MotifRadioButton>
                        <MotifRadioButton
                          checked={creditorType === ORGANIZATION}
                          onChange={() => setCreditorType(ORGANIZATION)}
                          value={ORGANIZATION}
                        >
                          {t("pages.creditors.creditorForm.formLabelDetails.radiobuttonsLabels.organization")}
                        </MotifRadioButton>
                      </MotifRadioButtonGroup>
                    </div>
                    <MotifAccordion
                      onClose={() => setIsFormOpen(false)}
                      onOpen={() => setIsFormOpen(true)}
                      style={{
                        flex: "1",
                      }}
                      open={isFormOpen}
                      useChevronIcon={true}
                      alignIconRight={true}
                    >
                      <MotifAccordionTrigger>
                        {t("pages.creditors.creditorForm.formLabelDetails.creditorIdentification")}
                      </MotifAccordionTrigger>
                      <MotifAccordionContent>
                        <div className={`motif-row ${isOrg ? "hide" : ""}`}>
                          <div className="motif-col-xl-6">
                            <div className="form-field">
                              {/*@ts-ignore*/}
                              <ControlSelect
                                //@ts-ignore
                                name={PREFIX}
                                //@ts-ignore
                                control={control}
                                required={false}
                                showLabel={true}
                                label={"pages.creditors.creditorForm.formLabelDetails.prefix"}
                                options={PREFIX_OPTIONS_LIST}
                                //@ts-ignore
                                setValue={()=>{}}
                                //@ts-ignore
                                getValues={getValues}
                              />
                            </div>
                          </div>
                          <div className="motif-col-xl-6">
                            <div className="form-field">
                              <ControlInputBox
                                name={FIRST_NAME}
                                control={control}
                                required={isOrg ? false : true}
                                isTextArea={false}
                                label={"pages.creditors.creditorForm.formLabelDetails.firstName"}
                                showLabel={true}
                              />
                              {errors[FIRST_NAME] &&
                                errors[FIRST_NAME].type === "required" && (
                                  <MotifErrorMessage>
                                    {t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.firstNameMessage")}
                                  </MotifErrorMessage>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="motif-row pad-t-20">
                          <div
                            className={`motif-col-xl-6 ${isOrg ? "hide" : ""}`}
                          >
                            <div className="form-field">
                              <ControlInputBox
                                name={LAST_NAME}
                                control={control}
                                required={isOrg ? false : true}
                                isTextArea={false}
                                label={"pages.creditors.creditorForm.formLabelDetails.lastName"}
                                showLabel={true}
                              />
                              {errors[LAST_NAME] &&
                                errors[LAST_NAME].type === "required" && (
                                  <MotifErrorMessage>
                                    {t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.lastNameMessage")}
                                    </MotifErrorMessage>
                                )}
                            </div>
                          </div>
                          <div
                            className={`motif-col-xl-6 ${isOrg ? "" : "hide"}`}
                          >
                            <div className="form-field">
                              <ControlInputBox
                                name={BUSINESS_NAME}
                                control={control}
                                required={isOrg ? true : false}
                                isTextArea={false}
                                label={"pages.creditors.creditorForm.formLabelDetails.businessName"}
                                showLabel={true}
                              />
                              {errors[BUSINESS_NAME] &&
                                errors[BUSINESS_NAME].type === "required" && (
                                  <MotifErrorMessage>
                                    {t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.businessNameMessage")}
                                    </MotifErrorMessage>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="motif-row pad-t-20">
                          <div className="motif-col-xl-6">
                            <div className="form-field">
                              {/* <ControlInputBox
                                name={DESCRIPTION}
                                control={control}
                                required={false}
                                isTextArea={true}
                                label={t(DESCRIPTION_LABEL)}
                                showLabel={true}
                              /> */}
                              <ControlTextAreaWithCharLimit
                                name={DESCRIPTION}
                                control={control}
                                required={false}
                                isTextArea={true}
                                label={"pages.creditors.creditorForm.formLabelDetails.description"}
                                showLabel={true}
                              />
                            </div>
                          </div>
                          <div className="motif-col-xl-6">
                            <div className="form-field">
                              {/*@ts-ignore*/}
                              <ControlInputBox
                                name={RELATIONSHIP_TO_DEBTOR}
                                control={control}
                                required={false}
                                isTextArea={false}
                                label={"pages.creditors.creditorForm.formLabelDetails.relationshipToDebtor"}
                                showLabel={true}
                              />
                            </div>
                          </div>
                        </div>
                      </MotifAccordionContent>
                    </MotifAccordion>
                    <div className="error-container">
                      <MotifErrorMessage>
                        {errorList.length > 0 && !isVisible
                          ? t("pages.creditors.creditorForm.formLabelDetails.requiredFieldsMessages.mainMessage", {fieldNames:errorsShow})               
                          : ""}
                          
                      </MotifErrorMessage>
                    </div>
                    <div className="button-container">
                      <Button label={t("global.globalActions.next")} />
                      <Button
                        type="button"
                        label={t("global.globalActions.cancel")}
                        variant={SECONDARY}
                        className="cancelBtn"
                        onClickHandler={(e) => {
                          if(isDirty){
                            setIsVisible(true);
                            dispatch(setDiscardEditMode({ editMode: false }));
                          }
                          else{
                            cancelBtnApproveHandler(e)
                          }
                          
                        }}
                      />
                    </div>
                  </form>
                  <ConfirmationModal
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    cancelBtnApproveHandler={cancelBtnApproveHandler}
                    cancelBtnRejectHandler={cancelBtnRejectHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
