import { useSelector } from "react-redux";
import Table from "@/components/Table/table";
import { useColumnDefs } from "@/features/claims/claimsTab/Constant/claimHeaderConst";
import TitleComplement from "@/features/claims/claimsTab/components/titleComplement";
import {
  useGetCaseClaimListDataQuery,
  useLazyGetClaimListByCaseQuickSearchQuery,
  useLazyGetClaimBasicSearchQuery,
} from "@/services/cases/casesReducer";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { caseRenderers } from "@/features/claims/claimsTab/Constant/customCellRenderer";
import BasicSearch from "../../claims/claimsTab/components/basicSearch";
import { useCallback, useEffect, useState } from "react";
import { useCase } from "@/features/case/use-case";
import AdvanceSearch from "./components/AdvanceSearch";
import {
  customPaginationPageSize,
  customPaginationPageSizeSelector,
  customTableLoadingData,
} from "@/components/Table/CustomPaginationConstants";
import { useGetClaimAdvanceSearchDataMutation } from "@/services/advanceSearch/advanceSearchReducer";
import CustomPagination from "@/components/Pagination/CustomPagination";
import { cleanPayload } from "@/utility/common";
import { useTranslation } from "react-i18next";

export default function ClaimsTabContent() {
  const caseData = useCase();
  const { caseId } = caseData;
  let tableLoadingData = customTableLoadingData;
  const paginationPageSize: number = customPaginationPageSize;
  const paginationPageSizeSelector: number[] = customPaginationPageSizeSelector;
  const [paginationObj, setPaginationObj] = useState<any>({
    CaseId: caseId,
    PageNumber: 1,
    PageSize: paginationPageSize,
    SearchText: "",
    SortOrder: "desc",
    SortColumn: "UpdatedOn",
  });

  let [sortedColumnTypes,setSortedColumnTypes]=useState<any>({
    lastUpdatedDateType:{sort:"desc"}
  })

  function handlePaginationData(data:any,columnTypesData:any){
    setPaginationObj({...data})
    setSortedColumnTypes({...columnTypesData})
  }
  let resetSortStatus=useCallback((defaultSort:string)=>{
    let json:any={...sortedColumnTypes}
    for (const key in json) {
                  json[key]={sort:null}
    }
    json[defaultSort]={sort:"desc"}
    setSortedColumnTypes({...json})
  },[sortedColumnTypes])
  let columnDefs = useColumnDefs(
    caseData
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isBasicSearchActive, setIsBasicSearchActive] = useState(false);
  const [basicSearchData, setBasicSearchData] = useState();
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [isAdvanceSearchActive, setIsAdvanceSearchActive] = useState(false);
  const [advanceSearchData, setAdvanceSearchData] = useState<any>(null);
  const [advanceSearchPayload, setAdvanceSearchPayload] = useState({});
  const [basicSearchPayload, setBasicSearchPayload] = useState({});
  const { data, error, isLoading, isFetching, refetch } =
    useGetCaseClaimListDataQuery(paginationObj, {
      skip: isBasicSearchActive || isAdvanceSearchActive || isSearchActive,
    });
const {t}=useTranslation();
  const [selectedClaimIds, setSelectedClaimIds] = useState<{
    claimReferenceId: string;
  }[]>([]);
  const [allClaimsSelected, setAllClaimsSelected] = useState(false);

  const clearSelectedClaimIds = (closeTrigger: boolean) => {
    setSelectedClaimIds([]);
    if (closeTrigger) {
      setPaginationObj({
        CaseId: caseId,
        PageNumber: 1,
        PageSize: paginationPageSize,
        SearchText: paginationObj.SearchText,
        SortOrder: "desc",
        SortColumn: "UpdatedOn",
      });
      resetSortStatus("lastUpdatedDateType")
    }
  };

  const [
    triggerSearch,
    {
      data: searchClaimDataByCase,
      isFetching: isSearchClaimDataByCaseFetching,
      error: isSearchClaimDataByCaseerror,
    },
  ] = useLazyGetClaimListByCaseQuickSearchQuery();
  const [
    getClaimAdvanceSearchData,
    { data: advanceTypeSearchData, isLoading: advanceLoading, error: advanceSearchError },
  ] = useGetClaimAdvanceSearchDataMutation();

  const [
    triggerBasicSearch,
    { data: searchBasicData, isFetching: basicSearchFetching, error: basicSearchError },
  ] = useLazyGetClaimBasicSearchQuery();

  const showBasicSearch = useSelector((state: any) => state.claims.showBasicSearch);
  const headerData = {
    title: "pages.claims.claimsTable.title",
    description: "pages.claims.claimsTable.description",
  };
  const handleSearch = useCallback(
    (searchValue: string) => {
      setSearchQuery(searchValue);
      setIsBasicSearchActive(false);
      setIsAdvanceSearchActive(false);
      setAdvanceSearchData(null);
      resetSortStatus("lastUpdatedDateType")
            if (searchValue.trim() === "") {
        setPaginationObj({
          CaseId: caseId,
          PageNumber: 1,
          PageSize: paginationPageSize,
          SearchText: "",
          SortOrder: "desc",
          SortColumn: "UpdatedOn",
        });
        resetSortStatus("lastUpdatedDateType")

        setIsSearchActive(false);
      } else {
        setPaginationObj({
          CaseId: caseId,
          PageNumber: 1,
          PageSize: paginationPageSize,
          SearchText: searchValue,
          SortOrder: "desc",
          SortColumn: "UpdatedOn",
        });
        resetSortStatus("lastUpdatedDateType")

        setIsSearchActive(true);
      }
    },
    [caseId, paginationPageSize,resetSortStatus]
  );

  useEffect(() => {
    setSelectedClaimIds([]);
    let pageData = { ...paginationObj };
    delete pageData?.CaseId;
    if (isBasicSearchActive) {
      const cleanedBasicSearchPayload = cleanPayload(basicSearchPayload);
      triggerBasicSearch({ ...cleanedBasicSearchPayload, ...pageData })
        .then((response) => {
          // @ts-ignore
          const newData = response?.data?.results.map((item: any) => {
            return {
              claimReferenceId: item.claimReference,
              claimStageName: item.claimStage,
              actionStatusName: item.claimStatus,
              ...item,
            };
          });
          // @ts-ignore
          setBasicSearchData(newData);
        })
        .catch((error) => {
          console.error("Basic Search Failed", error);
        });
    } else if (isAdvanceSearchActive) {
      getClaimAdvanceSearchData({ ...advanceSearchPayload, ...pageData }).then(
        (response: any) => {
          if (response.data) {
            setAdvanceSearchData(response.data?.results);
            setShowAdvanceSearch(false);
            setIsAdvanceSearchActive(true);
          } else {
          }
        }
      );
    } else if (isSearchActive) {
      triggerSearch({
        ...paginationObj,
      })
        .unwrap()
        .catch((error) => {
          console.error("Search failed:", error);
        });
    } else {
      refetch();
    }
  }, [
    paginationObj,
    refetch,
    isAdvanceSearchActive,
    isSearchActive,
    advanceSearchPayload,
    basicSearchPayload,
    isBasicSearchActive,
    triggerBasicSearch,
    triggerSearch,
    getClaimAdvanceSearchData,
  ]);

  const handleBasicSearch = useCallback(
    (searchParams: any) => {
      setPaginationObj({
        CaseId: caseId,
        PageNumber: 1,
        PageSize: paginationPageSize,
        SearchText: "",
        SortOrder: "desc",
        SortColumn: "UpdatedOn",
      });
      setSearchQuery("");
      resetSortStatus("lastUpdatedDateType")
      setBasicSearchPayload(searchParams);
      setIsSearchActive(false);
      setIsBasicSearchActive(true);
    },
    [caseId, paginationPageSize,resetSortStatus]
  );

  const handleQuickSearchClear = useCallback(() => {
    setSearchQuery("");
    setIsSearchActive(false);
    setPaginationObj({
      CaseId: caseId,
      PageNumber: 1,
      PageSize: paginationPageSize,
      SearchText: "",
      SortOrder: "desc",
      SortColumn: "UpdatedOn",
    });
    resetSortStatus("lastUpdatedDateType")
  }, [caseId, paginationPageSize,resetSortStatus]);
  const handleBasicSearchClear = useCallback(() => {
    setIsBasicSearchActive(false);
    setIsAdvanceSearchActive(false);
    setAdvanceSearchData(null);
    setPaginationObj({
      CaseId: caseId,
      PageNumber: 1,
      PageSize: paginationPageSize,
      SearchText: "",
      SortOrder: "desc",
      SortColumn: "UpdatedOn",
    });
    resetSortStatus("lastUpdatedDateType")
  }, [caseId, paginationPageSize,resetSortStatus]);
  function handleReset() {
    setPaginationObj({
      CaseId: caseId,
      PageNumber: 1,
      PageSize: paginationPageSize,
      SearchText: "",
      SortOrder: "desc",
      SortColumn: "UpdatedOn",
    });
    setSearchQuery("");
    setIsBasicSearchActive(false);
    setIsSearchActive(false);
    resetSortStatus("lastUpdatedDateType")
  }

  const tableDataFetching =
    advanceLoading ||
    basicSearchFetching ||
    isFetching ||
    isSearchClaimDataByCaseFetching;
  const tableDataError =
    advanceSearchError ||
    basicSearchError ||
    error ||
    isSearchClaimDataByCaseerror;
  const tableDataHasRecords = isAdvanceSearchActive
    ? advanceTypeSearchData?.hasMoreRecords
    : isSearchActive
    ? searchClaimDataByCase?.hasMoreRecords
    : isBasicSearchActive
    ? searchBasicData?.hasMoreRecords
    : data?.hasMoreRecords;
  const handleRowSelected = (event: any) => {
    const selectedNode = event.node;
    const selectedData = selectedNode.data;
    const isSelected = selectedNode.isSelected();

    setSelectedClaimIds((prevSelectedClaimIds) =>
      isSelected
        ? [...prevSelectedClaimIds, selectedData]
        : prevSelectedClaimIds.filter(
            (item) => item.claimReferenceId !== selectedData.claimReferenceId
          )
    );
  };
  if (isLoading) {
    return <MotifProgressLoader show variant="default" />;
  }

  const handleAllClaimsSelected = (isSelected: boolean) => {
    setAllClaimsSelected(isSelected);
  };

  const onSelectionChanged = (event: any) => {
    const allSelected =
      event.api.getSelectedNodes().length === event.api.getDisplayedRowCount();
    handleAllClaimsSelected(allSelected);
  };

  const tableData = isAdvanceSearchActive
    ? advanceSearchData
    : isSearchActive
    ? searchClaimDataByCase?.results
    : isBasicSearchActive
    ? basicSearchData
    : data?.results;

  return (
    <>
      <div
        className={
          "ccwp_claim_claimlisttable " +
          (showBasicSearch || showAdvanceSearch ? "hide_claims_table" : "")
        }
      >
        <>
          <Table
            columnDefs={columnDefs}
            columnTypes={{...sortedColumnTypes}}
            handlePaginationData={handlePaginationData}
                paginationObj={paginationObj}
            cellRenderers={tableDataFetching ? [] : tableData ? tableData : []}
            TitleComplement={() => (
              <TitleComplement
                caseId={caseId || caseData.caseId}
                onSearch={handleSearch}
                handleQuickSearchClear={handleQuickSearchClear}
                searchQuery={searchQuery}
                isBasicSearch={isBasicSearchActive}
                handleClearBasicSearch={handleBasicSearchClear}
                setShowAdvanceSearch={setShowAdvanceSearch}
                isAdvanceSearch={isAdvanceSearchActive}
                showAdvanceSearch={showAdvanceSearch}
                showBasicSearch={showBasicSearch}
                advanceSearchPayload={advanceSearchPayload}
                basicSearchPayload={basicSearchPayload}
                selectedClaimIds={selectedClaimIds}
                clearSelectedClaimIds={clearSelectedClaimIds}
                allClaimsSelected={allClaimsSelected}
                isAdvanceSearchActive={isAdvanceSearchActive}
                isBasicSearchActive={isBasicSearchActive}
                isSearchActive={isSearchActive}
                paginationObj={paginationObj}
              />
            )}
            title={headerData.title}
            description={headerData.description}
            caseRenderers={caseRenderers}
            onRowSelected={handleRowSelected}
            rowSelection="multiple"
            pagination={false}
            overlayNoRowsTemplate={
              tableDataFetching
                ? t(tableLoadingData)
                : tableDataError
                ? t("global.globalNotifications.wentWrong")
                  : t("global.globalNotifications.noRecords")
            }
            onSelectionChanged={onSelectionChanged}
          />
          {!showBasicSearch && !showAdvanceSearch && (
            <CustomPagination
              currentpage={paginationObj.PageNumber}
              pageSize={paginationObj.PageSize}
              disableNext={!tableDataHasRecords}
              onChangePageSize={(page: number) => {
                setPaginationObj({
                  ...paginationObj,
                  PageSize: page,
                  PageNumber: 1,
                });
              }}
              onChangeNextPage={() => {
                setPaginationObj({
                  ...paginationObj,
                  PageNumber: paginationObj.PageNumber + 1,
                });
              }}
              onChangePreviousPage={() => {
                setPaginationObj({
                  ...paginationObj,
                  PageNumber: paginationObj.PageNumber - 1,
                });
              }}
              pageSizeSelector={paginationPageSizeSelector}
            />
          )}
        </>
      </div>
      {showBasicSearch ? <BasicSearch onApply={handleBasicSearch} /> : <></>}
      {showAdvanceSearch && (
        <AdvanceSearch
          setShowAdvanceSearch={setShowAdvanceSearch}
          setAdvanceSearchData={setAdvanceSearchData}
          setIsAdvanceSearchActive={setIsAdvanceSearchActive}
          setAdvanceSearchPayload={setAdvanceSearchPayload}
          handleReset={handleReset}
        />
      )}
    </>
  );
}
