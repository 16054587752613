
const paymentStatus = {
    statusGridTitle:"Payment Status",
    statusGridDescription:"Descriptive text that describes your page or content.",
    noRecord: "No record found.",
    updateStatusButton: "Update payment status",
    updateStatusLabel:"Payment Status Update",
    updateStatusLabelWithStatus:"Payment Status Update - Current Status: {{currentStatus}}",
    sameStatusValidationMessage: "Selected payments must have the same Payment Status.",
    updateStatusToLabel: "Update status to:",
    emptyStatusValidationMessage:"No payment status available if status is {{paymentStatus}}",
    clearFilter: "Clear Filter",
    postedDate: "Posted / Replaced Date",
    somethingWentWrong: "Something went wrong.",
    deletePayment: "Delete Payment",
    deletePaymentMessage: "Are you sure you want to delete this payment?",
    confirm: "Confirm",
    filterForm:{
        title: "Payment Filters",
        description: "Creditors list related to the case",
        filterButton: "Filters",
        payeeName: "Payee Name",
        accountName: "Account Name",
        prefix: "Prefix",
        paymentReferenceStart: "Payment Reference (Begins With)",
        paymentReferenceEnd: "Payment Reference (Ends With)",
        issueDate: "Issue Date",
        amountInLowerBound: "Amount (Lower Bound)",
        amountInUpperBound: "Amount (Upper Bound)",
        postedDate: "Posted / Replaced Date",
        addressUpdateDate: "Address Update Date",
        status: "Status",
        cancel: "Cancel",
        apply: "Apply",
        save: "Save",
    },
    paymentSummary:{
        tableTitle:"Settlements",
        tableDescription:"Descriptive text that describes your page or content.",
        paymentReference: "Payment Reference",
        account: "Account",
        subAccount: "Sub Account",
        amount: "Amount",
        tableColumnHeader:{
            claimItemId:"Claim Item ID",
            entitlementAmount:"Entitlement Amount",
            priorSettledAmount:"Prior Settled Amount",
            settlementAmount: "Settlement Amount",
        }
    },
    bulkChequeImport: {
        title: "Upload Cheque Images",
        uploadDescription:
        "The accepted document format is ZIP only. The maximum size per file is 500 MB.",
        accept: ".zip",
    }
};
export default paymentStatus;