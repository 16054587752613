import { MotifIcon, MotifButton } from "@ey-xd/motif-react";
import { IconoirTrash, IconoirEditPencil } from "@ey-xd/motif-icon";

import "../style.scss";
import { dateFunc } from "@/components/Functions/dateFunc";
type deleteType = (
  modalShow: boolean,
  userId: string,
  oid: string,
  displayName: string
) => void;
export const useCustomCellRenderers = (deleteFun: deleteType) => {
	const userRenderers = {
		createdOnRenderer: function (params: any) {
			const { data } = params;
			return <>{dateFunc(data?.lastUpdatedDate).formattedDateTime}</>;
		},
		actionCellRenderers: function (params: any) {
			const { data } = params;
			return (
        <div className="motif-row">
          <div className="motif-col-md-4">
            <MotifButton
              size="small"
              variant="primary"
              onClick={() =>
                deleteFun(true, data?.userId, data?.oid, data?.displayName)
              }
            >
              <MotifIcon
                iconFunction={IconoirTrash}
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
          </div>
          <div className="motif-col-lg-4">
            <MotifButton
              onClick={() => {
                params.api.startEditingCell({
                  rowIndex: params.rowIndex,
                  colKey: "applicationRole",
                });
              }}
              size="small"
              type="button"
              variant="primary"
            >
              <MotifIcon
                iconFunction={IconoirEditPencil}
                stroke="blue"
                strokeWidth="2"
                size="24"
              />
            </MotifButton>
          </div>
        </div>
      );
		},
	};
	return userRenderers;
};
export default useCustomCellRenderers;
