import { useState, useEffect } from "react";
import { useLazyGetOperatorListQuery } from "@/services/advanceSearch/advanceSearchReducer";

const enum SearchAttributeTypeEnum {
  String = 0,
  Number = 1,
  Date = 2,
  Boolean = 3,
}

export type AttributeOperators = {
  [key: number]: string[];
};

const defaultAttributeTypes = [0, 1, 2, 3];

// takes in an array of attributeType and return a map of operatorList using attributeType as key
export const useAttributeOperators = (
  attributeTypes: SearchAttributeTypeEnum[] = defaultAttributeTypes
) => {
  const [operatorList, setOperatorList] = useState<AttributeOperators>({});
  const [getOperator] = useLazyGetOperatorListQuery();

  useEffect(() => {
    let fetchNewList = 0;
    const newOperatorList: AttributeOperators = {};
    if (attributeTypes.length === 0) return;

    if (operatorList) {
      attributeTypes.forEach((attributeType) => {
        if (!operatorList[attributeType]) {
          fetchNewList += 1;
        }
      });
    }
    if (
      fetchNewList > 0 &&
      Object.keys(operatorList).length < attributeTypes.length
    ) {
      const apis = Promise.all(
        attributeTypes.map(async (attributeType) => {
          const operators = await getOperator(attributeType).unwrap();
          newOperatorList[attributeType] = operators;
        })
      );
      apis.then(() => {
        attributeTypes.forEach(
          async (attributeType: SearchAttributeTypeEnum) => {
            const operators: string[] = await getOperator(
              attributeType
            ).unwrap();
            newOperatorList[attributeType] = operators;
          }
        );
        setOperatorList(newOperatorList);
      });
    }
  }, [attributeTypes, getOperator, operatorList]);

  return operatorList;
};
