import { Control } from "react-hook-form";
import { ClaimCreditorContactFormType } from "../ContactForm";
import { PROVINCE_STATE_NAME } from "../../../Constants";
import ControlSelectState from "./ControlSelectState";

type StateDropdownProps = {
    countryID: string;
    control: Control<ClaimCreditorContactFormType, any>;
    stateId?: string;
    name?: string;
    stateListByCountry: { label: string; value: string }[];
    required?: boolean;
}

export default function StateDropdown({control, name = PROVINCE_STATE_NAME, stateListByCountry, stateId,required }: StateDropdownProps) {


 return (
    <>
        <ControlSelectState
            //@ts-ignore
            name={name}
            //@ts-ignore
            control={control}
            required={required}
            showLabel={true}
            label={"pages.claims.claimCreate.creditorTab.formLabelsDetails.provinceState"}
            options={stateListByCountry}
            filter={true}
            value={stateId}
        />
    </>
 );

}