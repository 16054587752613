import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Button from "@/components/Form/Button/Button";
import PageSubTitle from "@/components/Form/PageSubTitle/PageSubTitle";
import PageTitle from "@/components/Form/PageTitle/PageTitle";

import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifButton,
  MotifCheckbox,
  MotifErrorMessage,
  MotifIcon,
} from "@ey-xd/motif-react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconoirArrowLeft } from "@ey-xd/motif-icon";
import ControlInputBox from "./ControlInputBoxUnit";
import ConfirmationModalUnit from "./ConfimationModalUnit";
import {
  UNIT_NAME,
  UNIT_NAME_FRENCH,
  UNIT_RATE_NAME,
  UNIT_DECIMAL_PLACE_NAME,
  UNIT_DESCRIPTION_NAME,
  UNIT_DESCRIPTION_NAME_FRENCH,
  IS_DEFAULT_CURRENCY_NAME,
} from "./Constants";
import { UnitDetailsForm } from "./type";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { postUnitConfiguration } from "./UnitSetupHelper";
import NotificationGrid from "@/components/Notification/NotificationGrid";
import {
  useCreateUnitMutation,
  useGetUnitsQuery,
  useUpdateUnitMutation,
} from "@/services/unit/unitReducers";
import ConfirmationModal from "@/components/Modal/ConfirmationModal/ConfirmationModal";
import ControlTextAreaWithCharLimit from "@/components/Form/InputBox/ControlTextAreaWithCharLimit";
import { useCase } from "@/features/case/use-case";
import { useDispatch } from "react-redux";
import { setDiscardEditMode } from "@/components/Modal/ConfirmationModal/discardchanges";
import { resetCase } from "@/features/case/case-slice";

export default function UnitSetup() {
  const [isVisible, setIsVisible] = useState(false);
  const [isDiscardVisible, setIsDiscardVisible] = useState(false);
  // @ts-ignore
  // const [isCancel, setIsCancel] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data;

  const caseData = useCase();
  const { caseId } = caseData;

  const isNewUnit = data === undefined ? true : false;

  const [showFrenchCaseSection, setShowFrenchCaseSection] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(true);
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    getValues,
    setValue,
  } = useForm<UnitDetailsForm>({
    defaultValues: {
      [UNIT_NAME]: data?.name || "",
      [UNIT_RATE_NAME]: data?.exchangeRate || "",
      [UNIT_DECIMAL_PLACE_NAME]: data?.decimalPlace || "",
      [UNIT_DESCRIPTION_NAME]: data?.description || "",
      [UNIT_NAME_FRENCH]: data?.nameFrench || "",
      [IS_DEFAULT_CURRENCY_NAME]: data?.isDefaultCurrency || false,
      [UNIT_DESCRIPTION_NAME_FRENCH]: data?.descriptionFrench || "",
    },
  });
  const { data: unitData } = useGetUnitsQuery(caseId);
  const [updateUnit] = useUpdateUnitMutation();
  const [createUnit] = useCreateUnitMutation();

  const [isDefaultCurrency, setIsDefaultCurrency] = useState(
    data?.isDefaultCurrency || false
  );
  const [isFrenchCaseChecked, setIsFrenchCaseChecked] = useState(
    data?.isFrenchCaseChecked || false
  );
  const isCheckboxDisabled = !isNewUnit && data?.isDefaultCurrency;
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [isSelectingDefaultCurrency, setIsSelectingDefaultCurrency] =
    useState(false);

  const unitNameExists = useCallback(
    (unitName: string) => {
      const lowerCaseUnitName = unitName.toLowerCase();
      return (
        unitData &&
        !unitData.some(
          (unit: { name: string }) =>
            unit.name.toLowerCase() === lowerCaseUnitName
        )
      );
    },
    [unitData]
  );

  const onSubmit = async (formData: UnitDetailsForm) => {
    try {
      const fullData = {
        ...formData,
        unitRate: isDefaultCurrency ? "1" : formData.unitRate,
        isDefaultCurrency: isDefaultCurrency,
        isFrenchCaseChecked: isFrenchCaseChecked,
        caseId: caseId,
        isNewUnit: isNewUnit,
      };
      const dataWithID =
        isNewUnit === true ? fullData : { ...fullData, id: data.id };
      dispatch(setDiscardEditMode({ editMode: false }));
      const unitData = postUnitConfiguration(dataWithID);

      if (isNewUnit) {
        await createUnit(unitData);
      } else {
        await updateUnit(unitData);
      }

      dispatch(resetCase());
      setNotificationVisible(true);
      navigate(`/case/${caseId}?activeTab=5`);
    } catch (error) {
      dispatch(setDiscardEditMode({ editMode: true }));

      console.log("Error from Unit", error);
    }
  };

  const handleCheckboxSelection = (e?: React.ReactEventHandler) => {
    if (isDefaultCurrency) {
      setConfirmationMessage(
        "pages.configuration.units.formLabels.currencyUnselect"
      );
      setIsSelectingDefaultCurrency(false);
    } else {
      setConfirmationMessage(
        "pages.configuration.units.formLabels.currencySelect"
      );
      setIsSelectingDefaultCurrency(true);
    }
    setIsVisible(true);
  };

  useEffect(() => {
    if (isDefaultCurrency) {
      setValue(UNIT_RATE_NAME, "1");
    } else {
      setValue(UNIT_RATE_NAME, getValues(UNIT_RATE_NAME));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefaultCurrency]);

  const confirmBtnHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsDefaultCurrency(isSelectingDefaultCurrency);
    setIsVisible(false);
  };

  const cancelBtnHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsVisible(false);
  };

  const discardCancelBtnHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsDiscardVisible(false);
    dispatch(setDiscardEditMode({ editMode: true }));
  };

  const discardConfirmBtnHandler = (event: any) => {
    event.preventDefault();
    navigate(`/case/${caseId}/?activeTab=5`);
    setIsDiscardVisible(false);
  };

  const handleFrenchCheckboxSelection = () => {
    setIsFrenchCaseChecked(!isFrenchCaseChecked);
    setShowFrenchCaseSection(!showFrenchCaseSection);
  };

  const handleRemoveFrenchSection = () => {
    setShowFrenchCaseSection(false);
    setIsFrenchCaseChecked(false);
  };

  const handleUnitCancel = (e: any) => {
    if (isDirty) {
      dispatch(setDiscardEditMode({ editMode: false }));

      setIsDiscardVisible(true);
    } else {
      discardConfirmBtnHandler(e);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDiscardEditMode({ editMode: isDirty }));
    return () => {
      dispatch(setDiscardEditMode({ editMode: false }));
    };
  }, [dispatch, isDirty]);
  return (
    <>
      {notificationVisible && (
        <NotificationGrid
          show={true}
          message={"global.globalNotifications.updatedSuccessfully"}
          type="success"
        />
      )}
      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          if (isDirty) {
            dispatch(setDiscardEditMode({ editMode: false }));

            setIsDiscardVisible(true);
          } else {
            discardConfirmBtnHandler(e);
          }
        }}
      >
        <Breadcrumb
          items={[
            {
              text: t("pages.configuration.attributes.backButton"),
              url: "",
              icon: <MotifIcon src={IconoirArrowLeft} />,
            },
          ]}
          ariaLabel="Breadcrumb navigation"
        />
      </div>

      <PageTitle
        title={"pages.configuration.units.createUnit.primaryTitle"}
        description={"pages.configuration.units.createUnit.primaryDescription"}
      />

      <PageSubTitle
        title={"pages.configuration.units.createUnit.secondaryTitle"}
        description={
          "pages.configuration.units.createUnit.secondaryDescription"
        }
      />

      <MotifAccordion
        alignIconRight={true}
        useChevronIcon={true}
        className="accordion"
        onClose={() => {
          setAccordionOpen(false);
        }}
        onOpen={() => {
          setAccordionOpen(true);
        }}
        open={accordionOpen}
      >
        <MotifAccordionTrigger className="accordion-trigger-button">
          {t("pages.configuration.units.formLabels.unitIdentification")}{" "}
        </MotifAccordionTrigger>
        <MotifAccordionContent className="pad-h-0 pad-v-20">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="motif-container pad-h-0">
              <div className="motif-row mar-b-30">
                <div className="motif-col-lg-6 motif-col-md-8">
                  <ControlInputBox
                    name={UNIT_NAME}
                    control={control}
                    required={true}
                    isTextArea={false}
                    label={"pages.configuration.units.formLabels.unitName"}
                    showLabel={true}
                    value={data?.unit || ""}
                    rules={
                      isNewUnit
                        ? {
                            validate: {
                              unitNameExists: (value: any) =>
                                unitNameExists(value),
                              invalidUnit: (value: {
                                trim: () => {
                                  (): any;
                                  new (): any;
                                  length: number;
                                };
                              }) => value.trim().length > 0,
                            },
                          }
                        : {}
                    }
                  />
                  {errors[UNIT_NAME] &&
                    errors[UNIT_NAME].type === "required" && (
                      <MotifErrorMessage>
                        {t(
                          "pages.configuration.units.validationMessages.unitNameRequired"
                        )}
                      </MotifErrorMessage>
                    )}

                  {errors[UNIT_NAME] &&
                    errors[UNIT_NAME].type === "unitNameExists" && (
                      <MotifErrorMessage>
                        {t(
                          "pages.configuration.units.validationMessages.unitExists"
                        )}
                      </MotifErrorMessage>
                    )}
                  {errors[UNIT_NAME] &&
                    errors[UNIT_NAME].type === "invalidUnit" && (
                      <MotifErrorMessage>
                        {t(
                          "pages.configuration.units.validationMessages.validName"
                        )}
                      </MotifErrorMessage>
                    )}
                </div>
              </div>
              <div className="motif-row mar-b-30">
                <div className="motif-col-lg-6 motif-col-md-8">
                  <ControlInputBox
                    name={UNIT_RATE_NAME}
                    control={control}
                    required={true}
                    isTextArea={false}
                    label={"pages.configuration.units.formLabels.rate"}
                    showLabel={true}
                    value={data?.exchangeRate || ""}
                    disabled={isDefaultCurrency}
                    rules={{
                      pattern: {
                        value: /^[.0-9]+$/,
                      },
                    }}
                  />
                  {isDefaultCurrency && (
                    <div className="motif-caption-italic-regular">
                      {t(
                          "pages.configuration.units.validationMessages.exchangeRateMessage"
                        )}
                    </div>
                  )}
                  {errors[UNIT_RATE_NAME] &&
                    errors[UNIT_RATE_NAME].type === "required" && (
                      <MotifErrorMessage>
                        {t("pages.configuration.units.validationMessages.rateRequired")}
                      </MotifErrorMessage>
                    )}

                  {errors[UNIT_RATE_NAME] &&
                    errors[UNIT_RATE_NAME].type === "pattern" && (
                      <MotifErrorMessage>
                        {t("pages.configuration.units.validationMessages.rateMustNumber")}
                      </MotifErrorMessage>
                    )}
                  {errors[UNIT_RATE_NAME] &&
                    errors[UNIT_RATE_NAME].type === "defaultCurrencyRate" && (
                      <MotifErrorMessage>
                        {errors[UNIT_RATE_NAME].message}
                      </MotifErrorMessage>
                    )}
                </div>
              </div>
              <div className="motif-row mar-b-30">
                <div className="motif-col-lg-6 motif-col-md-8">
                  <ControlInputBox
                    name={UNIT_DECIMAL_PLACE_NAME}
                    control={control}
                    required={true}
                    isTextArea={false}
                    label={"pages.configuration.units.formLabels.decimalRate"}
                    showLabel={true}
                    value={data?.decimalPlace || ""}
                    rules={{
                      pattern: {
                        value: /^[0-9]+$/,
                      },
                    }}
                  />
                  {errors[UNIT_DECIMAL_PLACE_NAME] &&
                    errors[UNIT_DECIMAL_PLACE_NAME].type === "required" && (
                      <MotifErrorMessage>
                      {t("pages.configuration.units.validationMessages.decimalPlaceRequired")}
                      </MotifErrorMessage>
                    )}

                  {errors[UNIT_DECIMAL_PLACE_NAME] &&
                    errors[UNIT_DECIMAL_PLACE_NAME].type === "pattern" && (
                      <MotifErrorMessage>
                      {t("pages.configuration.units.validationMessages.decimalMustNumber")}
                      </MotifErrorMessage>
                    )}
                </div>
              </div>
              <div className="motif-row mar-b-30">
                <div className="motif-col-lg-6 motif-col-md-8">
                  {/* <ControlInputBox
                    name={UNIT_DESCRIPTION_NAME}
                    control={control}
                    required={false}
                    isTextArea={false}
                    label={t(UNIT_DESCRIPTION_LABEL)}
                    showLabel={true}
                    value="testing"
                  /> */}
                  <ControlTextAreaWithCharLimit
                    name={UNIT_DESCRIPTION_NAME}
                    control={control}
                    required={false}
                    isTextArea={true}
                    label={
                      "pages.configuration.units.formLabels.unitDescription"
                    }
                    showLabel={true}
                  />
                </div>
              </div>
              <div className="motif-row mar-b-30">
                <div className="motif-col-lg-6 motif-col-md-8">
                  <MotifCheckbox
                    id="presentation-currency-checkbox"
                    name="presentation-currency-checkbox"
                    onChange={handleCheckboxSelection}
                    value="presentation-currency-checkbox"
                    checked={isDefaultCurrency}
                    disabled={isCheckboxDisabled}
                  >
                    {t("pages.configuration.units.formLabels.defaultCurrency")}{" "}
                  </MotifCheckbox>
                </div>
              </div>
              <div className="motif-row mar-b-30">
                <div className="motif-col-lg-6 motif-col-md-8">
                  <MotifCheckbox
                    id="french-case-identifiers-checkbox"
                    name="french-case-identifiers-checkbox"
                    onChange={handleFrenchCheckboxSelection}
                    value="french-case-identifiers-checkbox"
                    checked={isFrenchCaseChecked}
                  >
                    {t("pages.configuration.units.formLabels.includeFrench")}{" "}
                  </MotifCheckbox>
                </div>
              </div>
              {showFrenchCaseSection && (
                <>
                  <div className="motif-row mar-b-30 justify-content-between align-items-center">
                    <div className="motif-col-2">
                      {t("pages.configuration.units.formLabels.french")}
                    </div>
                    <div className="motif-col-10">
                      <Button
                        label={t(
                          "pages.configuration.units.formLabels.removeSection"
                        )}
                        variant="text"
                        onClickHandler={handleRemoveFrenchSection}
                        className="motif-button motif-body2-underlined-regular "
                      />
                    </div>
                  </div>
                  <div className="motif-row mar-b-30">
                    <div className="motif-col-lg-6 motif-col-md-8">
                      <ControlInputBox
                        name={UNIT_NAME_FRENCH}
                        control={control}
                        required={false}
                        isTextArea={false}
                        label={
                          "pages.configuration.units.formLabels.unitNameFrench"
                        }
                        showLabel={true}
                        value={"Test"}
                      />
                    </div>
                  </div>
                  <div className="motif-row mar-b-30">
                    <div className="motif-col-lg-6 motif-col-md-8">
                      {/* <ControlInputBox
                        name={UNIT_DESCRIPTION_NAME_FRENCH}
                        control={control}
                        required={false}
                        isTextArea={false}
                        label={t(UNIT_DESCRIPTION_LABEL_FRENCH)}
                        showLabel={true}
                        value={"Test"}
                      /> */}
                      <ControlTextAreaWithCharLimit
                        name={UNIT_DESCRIPTION_NAME_FRENCH}
                        control={control}
                        required={false}
                        isTextArea={true}
                        label={
                          "pages.configuration.units.formLabels.unitDescriptionFrench"
                        }
                        showLabel={true}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="motif-row justify-content-end button-container">
                <div className="pad-r-10 mar-t-20">
                  <MotifButton
                    type="button"
                    variant="secondary"
                    className="motif-button btn"
                    onClick={(e) => handleUnitCancel(e)}
                  >
                    {t("global.globalActions.cancel")}
                  </MotifButton>
                </div>
                <div className="pad-r-10 mar-t-20">
                  <Button
                    label={t("global.globalActions.save")}
                    variant="primary"
                    className="motif-button"
                    onClickHandler={handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </div>
            <ConfirmationModalUnit
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              cancelBtnHandler={cancelBtnHandler}
              confirmBtnHandler={confirmBtnHandler}
              confirmationMessage={confirmationMessage}
            />
            <ConfirmationModal
              isVisible={isDiscardVisible}
              setIsVisible={setIsDiscardVisible}
              cancelBtnApproveHandler={discardConfirmBtnHandler}
              cancelBtnRejectHandler={discardCancelBtnHandler}
            />
          </form>
        </MotifAccordionContent>
      </MotifAccordion>
    </>
  );
}
