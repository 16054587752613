import { j as m } from "../../../jsx-runtime-BwWtxLpl.js";
import "react";
import o from "prop-types";
import i from "classnames";
const p = ({ children: r, className: t = "", tabs: e = null, ...s }) => {
  const a = i({
    "motif-card-footer": !0,
    [t]: !0
  });
  return /* @__PURE__ */ m.jsx("footer", { className: a, ...s, "data-motif-card-footer": !0, children: e || r });
};
p.propTypes = {
  children: o.node,
  tabs: o.node,
  className: o.string
};
export {
  p as MotifCardFooter
};
